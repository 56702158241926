import React from "react";
import './new-school-connect-flow.scss';
import SharedProfile from "../../../../shared-storage/profile";
import SharedStaticText from "../../../../shared-storage/static-text-store";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import { Modal, ModalBody } from "reactstrap";
import SchoolInfoConfirm from "./school-info-confirm/school-info-confirm";

export default class NewSchoolConnectFlow extends React.Component {

    constructor(props) {
        super(props);
        this.staticText = SharedStaticText.getStaticText('newSchoolConnectFlow');

        this.userInfo = SharedProfile.getProfile();
        this.meta = this.props.meta;

        if (this.meta && this.meta.payload && this.meta.payload.name) {
            this.userName = this.meta.payload.name
        }
    }

    state = {
    }

    componentDidMount() {
    }

    onPressConfirm = () => {
        // this.props.navigation.replace('NewSchoolInfoConfirmScreenAfterLogin', { meta: this.meta });
        this.setState({showSchoolInfoConfirmPopup : true, schoolInfoMeta : this.meta})
    }

    onPressIncorrectDetails = () => {
        this.props.delegate && this.props.delegate.close && this.props.delegate.close()
    }

    render() {

        if(this.state.showSchoolInfoConfirmPopup) {
            return(
                <SchoolInfoConfirm delegate = {this} meta = {this.state.schoolInfoMeta} />
            )
        }

        return (
            <Modal className="custom-new-school-connect-popup" isOpen={true}>
                <ModalBody>
                    <div>
                        <div>
                            <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                            <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onPressIncorrectDetails()} />
                        </div>
                        <div className="title">{`Verify Your Name`}</div>
                        <div className="mt-5 pl-5">
                            <div className="header-text">{this.staticText && this.staticText.title ? this.staticText.title : "Are you"}</div>
                            <div className="user-name">{this.userName} ?</div>
                        </div>
                        <div className="button-wrapper d-flex justify-content-center mt-4">

                            <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.onPressConfirm}>
                                {this.staticText && this.staticText.confirmButton ? this.staticText.confirmButton : "YES, THAT'S CORRECT"}
                            </div>

                            <div className="connect-later-button d-flex justify-content-center cursor-pointer" onClick={this.onPressIncorrectDetails}>
                                {this.staticText && this.staticText.incorrectDetailsButton ? this.staticText.incorrectDetailsButton : "THAT'S NOT ME"}
                            </div>

                        </div>
                        <div className="">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}
import React from "react";
import './passcode-update-screen.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import CustomToaster from "../../common/custom-toaster/custom-toaster";

class PasscodeUpdateScreen extends React.Component {

    state = {
        newPasscodeBox1: '',
        newPasscodeBox2: '',
        newPasscodeBox3: '',
        newPasscodeBox4: '',
        reEnteredPasscodeBox1: '',
        reEnteredPasscodeBox2: '',
        reEnteredPasscodeBox3: '',
        reEnteredPasscodeBox4: "",
        newPassCode: '',
        reEnteredPassCode: '',
    }


    onKeyUpEvent(index, event, boxType) {
        const eventOtp = event.which || event.keyCode;
        if (this.getOtpBoxElement(index, boxType).value.length === 1) {
            if (index !== 4) {
                this.getOtpBoxElement(index + 1, boxType).focus()
                this.getOtpBoxElement(index + 1, boxType).select();
            } else {
                this.getOtpBoxElement(index, boxType).blur();
            }
        }
        if (eventOtp === 8 && index !== 1) {
            this.props.confirmPasscode && this.props.confirmPasscode(this.state.reEnteredPassCode, false)
            this.setState({ invalidOtp: false, confirmPasscode: false, invalidPsscode: false })
            this.getOtpBoxElement(index - 1, boxType).focus()
            this.getOtpBoxElement(index - 1, boxType).select();
        }
    }


    getOtpBoxElement(index, boxType) {
        return document.getElementById(boxType + index);
    }

    onFocusEvent(index, boxType) {
        if (this.state.isLoginFailed) {
            this.setState({ isLoginFailed: false })
        }
        for (let item = 1; item < index; item++) {
            const currentElement = this.getOtpBoxElement(item, boxType);
            if (!currentElement.value) {
                currentElement.focus();
                currentElement.select()
                break;
            }
        }
    }


    validateOTP() {

        if (this.state.otpBox1 && this.state.otpBox2 && this.state.otpBox3 && this.state.otpBox4) {
            return true
        }
        return false
    }


    onChangeHandler = (event, key) => {
        if (key === "mobileNumber") {
            if (!this.allowOnlyNumbers(event.target.value)) return
            this.setState({
                [key]: event.target.value,
                isInvalidMobile: false,
                isMobileNumberEdit: false,
                isMobileNumberNotRegistered: false,
                showNewPasscodeSetText: false,
                userListToSetPasscode: [],
                isValidMobileNumber: this.validateMobile(event.target.value)
            });
        } else {
            this.setState({ [key]: event.target.value }, () => {

                if (key.startsWith('passcodeBox') === true) {
                    this.setState({
                        passCode: `${this.state.passcodeBox1 + this.state.passcodeBox2 + this.state.passcodeBox3 + this.state.passcodeBox4}`,
                        // isLoginEnable: this.validateOTP()
                    })
                } else if (key.startsWith('newPasscodeBox') === true) {
                    this.setState({
                        newPassCode: `${this.state.newPasscodeBox1 + this.state.newPasscodeBox2 + this.state.newPasscodeBox3 + this.state.newPasscodeBox4}`,
                        // isLoginEnable: this.validateOTP()
                    })
                } else if (key.startsWith('reEnteredPasscodeBox') === true) {
                    this.setState({
                        reEnteredPassCode: `${this.state.reEnteredPasscodeBox1 + this.state.reEnteredPasscodeBox2 + this.state.reEnteredPasscodeBox3 + this.state.reEnteredPasscodeBox4}`
                    })
                } else {
                    this.props.delegate && this.props.delegate.onOtpChange(`${this.state.otpBox1 + this.state.otpBox2 + this.state.otpBox3 + this.state.otpBox4}`)
                }

                if (this.state.reEnteredPasscodeBox4) {
                    this.matchPasscode()
                }

            });
        }
    }

    matchPasscode() {
        let newPassCode = `${this.state.newPasscodeBox1 + this.state.newPasscodeBox2 + this.state.newPasscodeBox3 + this.state.newPasscodeBox4}`
        let reEnteredPassCode = `${this.state.reEnteredPasscodeBox1 + this.state.reEnteredPasscodeBox2 + this.state.reEnteredPasscodeBox3 + this.state.reEnteredPasscodeBox4}`

        // //console.log(newPassCode, reEnteredPassCode)

        if (newPassCode === reEnteredPassCode) {
            this.setState({ confirmPasscode: true, newPassCode, reEnteredPassCode})
        } else {
            this.setState({ showPasscodeNotMatch: true }, () => {
                setTimeout(() => {
                    this.setState({ showPasscodeNotMatch: false })
                }, 2000)
            })
            return false
        }

    }



    resetNewPasscodeInputBoxs() {
        this.setState({
            newPasscodeBox1: '',
            newPasscodeBox2: '',
            newPasscodeBox3: '',
            newPasscodeBox4: '',
            newPassCode: '',
        })
    }


    resetReEnteredPasscodeInputBoxs() {
        this.setState({
            reEnteredPasscodeBox1: '',
            reEnteredPasscodeBox2: '',
            reEnteredPasscodeBox3: '',
            reEnteredPasscodeBox4: '',
            reEnteredPassCode: '',
        })
    }

    updatePasscode() {
        this.props.delegate && this.props.delegate.updatePasscode && 
        this.props.delegate.updatePasscode(this.state.newPassCode, this.state.reEnteredPassCode)
    }


    renderNewPassCodeInputBoxes() {
        return (
            <div className="passcode-boxes">
                <input autoFocus id="newPasscodeBox1" className='input-one-margin-left' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(1, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox1')} value={this.state.newPasscodeBox1} />
                <input id="newPasscodeBox2" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(2, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox2')} value={this.state.newPasscodeBox2} />
                <input id="newPasscodeBox3" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(3, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox3')} value={this.state.newPasscodeBox3} />
                <input id="newPasscodeBox4" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(4, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox4')} value={this.state.newPasscodeBox4} />
            </div>)
    }

    renderReEnterPassCodeInputBoxes() {
        return (
            <div className="passcode-boxes">
                <input id="reEnteredPasscodeBox1" className='input-one-margin-left' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(1, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox1')} value={this.state.reEnteredPasscodeBox1} />
                <input id="reEnteredPasscodeBox2" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(2, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox2')} value={this.state.reEnteredPasscodeBox2} />
                <input id="reEnteredPasscodeBox3" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(3, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox3')} value={this.state.reEnteredPasscodeBox3} />
                <input id="reEnteredPasscodeBox4" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(4, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox4')} value={this.state.reEnteredPasscodeBox4} />
            </div>)
    }




    render() {
        return (
            <div className="passcode-update container-fluid">
                <div className="container-height">
                    <div className="header-abs-div">
                        <img className="login-header" src={getImageSource(ImageConstants.LOGIN_HEADER)} />
                    </div>
                    <div className="update-passcode-wrapper">
                        <div className="update-passcode-text">Update Passcode</div>
                        <div className="pass-code">
                            <label className="cw-input-label-1">Create New Passcode</label>
                            {this.renderNewPassCodeInputBoxes()}
                            <div className="text-left enter-passcode">Enter new 4 digit passcode. You can use this passcode to login when you do not have a phone.</div>
                            <label className="cw-input-label-1 mt-4">Re-enter Passcode</label>
                            {this.renderReEnterPassCodeInputBoxes()}
                            {
                                this.state.showPasscodeNotMatch ?
                                    <div className="custom-toast-for-invalid-passcode">
                                        <CustomToaster toasterMessage="Passcode does mot match" type="error" />
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    {
                        this.state.confirmPasscode ?
                            <div className="d-flex justify-content-center ">
                                <div className="update-button cursor-pointer" onClick={() => this.updatePasscode()}>Update Passcode</div>
                            </div>
                            :
                            <div className="d-flex justify-content-center ">
                                <div className="update-button-disabled">Update Passcode</div>
                            </div>
                    }
                    <div className="footer-abs-div">
                        <img className="login-footer" src={getImageSource(ImageConstants.LOGIN_FOOTER)} />
                    </div>
                </div>
            </div>
        )
    }
}

export default PasscodeUpdateScreen;
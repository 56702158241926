import Axios from 'axios';
import HttpClient from './http-client';
import URL from './url';

export default class ExamPrepKitApi {

    static updatePrepkitIntro(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_PREP_KIT_INTRO, null, payload);
    }

    static getMultipleChapterProgress(payload) {

        let url = URL.BASE_URL + URL.MULTIPLE_CHAPTER_PROGRESS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getPrepKitChapterList(payload) {

        let url = URL.BASE_URL + URL.PREP_KIT_CHAPTER_LIST;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getPrepKitTableOfContentProgress(payload) {

        let url = URL.BASE_URL + URL.PREP_KIT_TABLE_OF_CONTENT_PROGRESS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getCheatSheet(payload) {

        let url = URL.BASE_URL + URL.CHEAT_SHEET;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static updatePrepKitProgress(payload) {
        return HttpClient.put(URL.BASE_URL + URL.PREP_KIT_PROGRESS_UPDATE, null, payload);
    }

    static getThingsToRememberContent(payload) {

        let url = URL.BASE_URL + URL.THINGS_TO_REMEMBER;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getThingsToRememberProgress(payload) {

        let url = URL.BASE_URL + URL.THINGS_TO_REMEMBER_PROGRESS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static updatePrepKitContentActivity(payload) {
        return HttpClient.put(URL.BASE_URL + URL.PREP_KIT_CONTENT_ACTIVITY, null, payload);
    }

    static updatePrepKitActivity(payload) {
        return HttpClient.put(URL.BASE_URL + URL.PREP_KIT_ACTIVITY, null, payload);
    }

    static getWorkoutLeanring(payload) {

        let url = URL.BASE_URL + URL.WORKOUT_LEARNING;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getEachStepProgress(payload) {

        let url = URL.BASE_URL + URL.EACH_STEP_PROGRESS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getPrepKitSolvedExample(payload) {

        let url = URL.BASE_URL + URL.PREP_KIT_SOLVED_EXAMPLE;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getPrepKitPractice(payload) {

        let url = URL.BASE_URL + URL.PREP_KIT_PRACTICE;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getApproachToProblemSolvingProgress(payload) {

        let url = URL.BASE_URL + URL.APPROACH_TO_PROBLEM_SOLVING_PROGRESS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getApproachToProblemSolving(payload) {

        let url = URL.BASE_URL + URL.APPROACH_TO_PROBLEM_SOLVING;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getApproachToProblemSolvingEloDetails(payload) {

        let url = URL.BASE_URL + URL.ELO_DETAILS_APPROCH_TO_PROBLEM_SOLVING;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static updatePrepKitContentActivityApproachToProblemSolving(payload) {
        return HttpClient.put(URL.BASE_URL + URL.PREP_KIT_CONTENT_ACTIVITY_APPROACH_TO_PROBLEM_SOLVING, null, payload);
    }

    static prepkitSolvedExample(payload) {

        let url = URL.BASE_URL + URL.PREP_KIT_SOLVED_EXAMPLE_2;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getCommonErrors(payload) {

        let url = URL.BASE_URL + URL.COMMON_ERRORS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getTipsAndTrics(payload) {

        let url = URL.BASE_URL + URL.TIPS_AND_TRICS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    


    

    

    
    
    
    


    

    

    

    
    

    

    

    
    

    


    

}
import React, { Component } from 'react';
import './chapter-card.scss';
import { ImageConstants, Constants } from '../../../../constants/constants';
import { getImageSource } from '../../../../utility/image-source';
import SharedProfile from '../../../../../shared-storage/profile';
import SharedThemeCategory from '../../../../../shared-storage/category-theme';

export default class ChapterCard extends Component {
    constructor(props) {
        super(props);

        // this.theme = this.props.categoryThemeId ? sharedThemecategory.getThemeCategory(this.props.categoryThemeId) : {};

        this.state = {
            bgColor: [
                "#D6D5FD",
                "#80DADF",
                "#54E5C0",
                "#FCD1C1"
            ],
            selectedColor: ''
        };
        this.userInfo = SharedProfile.getProfile();
    }

    componentDidMount() {
        this.getRandomColor()
    }

    getRandomColor() {
        var item = this.state.bgColor[Math.floor(Math.random() * this.state.bgColor.length)];
        this.setState({
            selectedColor: item,
        })
    }

    onPressArrow = (chapter) => {

        this.props.delegate && this.props.delegate.onClickChapter && this.props.delegate.onClickChapter(chapter)
        return


        if (chapter.status == Constants.IS_CHAPTER_NOT_STARTED) {
            this.props.delegate && this.props.delegate.toggleChapterCompletedModal(true, this.props.chapterTitle, this.props.chapter.key, this.props.categoryThemeId, chapter.lockedForTrial, chapter)
            return
        }
        // else if ( this.userInfo.isSubscribed ) {
        //     this.props.delegate && this.props.delegate.showReviseChapterModal(chapter);
        // }
        else {
            this.props.delegate && this.props.delegate.showReviseChapterModal(chapter);
        }
        // else {

        //     this.props.delegate && this.props.delegate.showStartChapterModal(chapter);

        // }



    }

    getFontSize = () => {

        // if (!this.props.biggestCharecter)
        //     return {}


        // if (this.props.biggestCharecter >= 16) {
        //     return Styles.fontSize15
        // } else if (this.props.biggestCharecter >= 14) {
        //     return Styles.fontSize15
        // } else if (this.props.biggestCharecter >= 12) {
        //     return Styles.fontSize15
        // } else {
        //     return {}
        // }
    }

    checkWorldLength = () => {

        if (!this.props.chapterTitle)
            return false

        let slittedTitle = this.props.chapterTitle.split(' ')

        for (let i = 0; i < slittedTitle.length; i++) {

            if (slittedTitle[i].length >= 12)
                return true
        }

        return false

    }

    renderSplittedTitle = () => {

        let slittedTitle = this.props.chapterTitle.split(' ')

        let elements = []

        for (let i = 0; i < slittedTitle.length; i++) {
            elements.push(<span>{slittedTitle[i]}</span>)
        }

        return elements
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId);

        let isTitleWordIsBigger = this.checkWorldLength()

        console.log(isTitleWordIsBigger)

        return (
            <div className=''>

                <div>

                    {/* {this.props.from == 'TRIAL_MY_ASSESSMENT' && this.props.isLocked ? <View style={Styles.arrow}><Image source={getImageSource(ImageConstants.CHAPTER_LOCK_TRIAL)} /></View> : <></>} */}

                    <div className='home-chapter-card' style={this.props.isLocked ? { backgroundColor: "#efeff3" } : { backgroundColor: this.props.color ? this.props.color : 'rgb(232, 226, 255)' }}>


                        <div className='chapter-title-container d-flex align-items-center'>

                            <div className='mr-2'>
                                {
                                    !this.props.image ?
                                        <img className='chapter-image-size' src={getImageSource(ImageConstants.CHAPTER_ICN)} />
                                        :
                                        <img className='chapter-image-size' src={this.props.image} />
                                }
                            </div>
                            <div className='d-flex flex-wrap'>
                                {/* {!isTitleWordIsBigger ?
                                    <span className='chapter-title'>{this.props.chapterTitle}</span> : */}
                                <div className='chapter-title'>{this.props.chapterTitle}</div>
                                {/* } */}
                            </div>
                        </div>
                        {!this.props.isLocked && this.props.progress && this.props.progress.value > 0 &&
                            <div className='d-flex mt-3 align-items-center'>
                                <div className='chapter-progress-bar'>
                                    <div className='chapter-inner-progress' style={{ width: `${this.props.progress.value}%` }}>
                                    </div>
                                </div>
                                <div className='ml-2 progress-value'>{`${this.props.progress.value}%`}</div>
                            </div>
                        }

                        {this.props.isLocked ?
                            <>
                                {!this.props.from || this.props.from != 'TRIAL_MY_ASSESSMENT' ?
                                    <div className='text-right'>
                                        <img className='lock-icon-size' src={getImageSource(ImageConstants.LOCK)} />
                                    </div>
                                    : <></>
                                }
                            </>
                            :
                            <img className="blue-arrow-icon-size" src={getImageSource(ImageConstants.BLUE_ARROW_ICON)} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

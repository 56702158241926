import React from "react";
import './event-help.scss';
import { Modal, ModalBody } from "reactstrap";
import Parser from "../../common/content-parser/custom-parser";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import Path from "../../routes/routes-path";

class EventHelp extends React.Component {
    constructor(props) {
        super(props);
        this.parser = new Parser();
        if (this.props.helpContent)
            this.state.items = this.props.helpContent
    }

    videoPlayerRefs = {};

    state = {
        items: [
            // {
            //     question: "What this Scholarship is all about?",
            //     media: {
            //         url: "",
            //         type: "VIDEO"
            //     },
            //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in quis accumsan, lacinia. Parturient in faucibus purus turpis vitae sodales. Hendrerit enim, accumsan tortor ipsum, turpis gravida lacus cursus integer. Interdum elementum eu sit risus. Parturient in faucibus purus turpis vitae sodales. Hendrerit enim,"
            // },
            // {
            //     question: "Why should you participate in this?",
            //     media: {
            //         url: "",
            //         type: "IMAGE"
            //     },
            //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in quis accumsan, lacinia. Parturient in faucibus purus turpis vitae sodales. Hendrerit enim, accumsan tortor ipsum, turpis gravida lacus cursus integer. Interdum elementum eu sit risus. Parturient in faucibus purus turpis vitae sodales. Hendrerit enim,"
            // },
            // {
            //     question: "How winner are selected & what prize do they get?",
            //     media: {
            //         url: "",
            //         type: "VIDEO"
            //     },
            //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in quis accumsan, lacinia. Parturient in faucibus purus turpis vitae sodales. Hendrerit enim, accumsan tortor ipsum, turpis gravida lacus cursus integer. Interdum elementum eu sit risus. Parturient in faucibus purus turpis vitae sodales. Hendrerit enim,"
            // }
        ],
        activeIndex: 0
    }


    // renderPagination = (activeIndex) => {

    //     return (
    //         <View style={Styles.dotContainer}>
    //             {this.state.items.map((item, index) =>

    //                 <View style={[index === this.state.activeIndex ? Styles.activeDot : Styles.dot, (index < this.state.items.length ? { marginRight: wp(1.25) } : {})]}>
    //                 </View>

    //             )}
    //         </View>
    //     )
    // }


    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    close = () => {
        this.props.back()
    }

    onClickExpand = (index) => {

        let items = this.state.items
        items[index].expand = !items[index].expand

        this.setState({ items })
    }

    // onScrollEnd = (e) => {

    //     let contentOffset = e.nativeEvent.contentOffset;

    //     let viewSize = e.nativeEvent.layoutMeasurement;

    //     let pageNum = Math.ceil(contentOffset.x / viewSize.width);

    //     this.setState({ activeIndex: pageNum })
    // }

    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goToEventList() {
         this.props.back && this.props.back()
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToEventList()}>{`Events >`}</span>
                <span className='title-1 pl-2'>{`Event FAQ`}</span>

                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }




    render() {

        let Styles = {
            descriptionText: {
                fontSize: '16px',
                color: '#455B64'
            },
            descriptionTextBold: {
                fontWeight: '600'
            },
            linkText: {
                fontSize: '17px',
                color: '#0095ff'
            }
        }

        return (
            // <Modal className="custom-help-modal" isOpen={true} size={'lg'}>
            //     <ModalBody className="p-0">
            <div className="event-help">
                <div className="event-list-header mb-3">
                    <div className="text-right">
                        <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                    </div>
                    <div className="header-title-margin-top">
                        {this.renderBreadcrumbForChapterList()}
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="header-text">FAQ's</div>
                        </div>

                        <div>
                            <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                        </div>
                    </div>
                </div>

                
                <div className="">
                    {
                        this.state.items.map((item, index) => (
                            <>
                                <div className="row m-0 p-0 mt-4" >
                                    <div className="col-11 pl-0 cursor-pointer question-title" onClick={() => this.onClickExpand(index)}>
                                        {item.question}
                                    </div>
                                    <div className="col-1 text-right cursor-pointer">
                                        <img onClick={() => this.onClickExpand(index)} className="arrow-size" src={!item.expand ? getImageSource(ImageConstants.ARROW_DOWN_ANGLE) : getImageSource(ImageConstants.ARROW_UP_ANGLE)} />
                                    </div>
                                    {
                                        item.expand ?
                                            <div className="row m-0 p-0 mt-2 mb-3">
                                                <div className="col-11 pl-0">
                                                    {item.description ? this.parser.parseWithStyles(item.description, { p: Styles.descriptionText, strong: Styles.descriptionTextBold, link: Styles.linkText }) : <></>}
                                                </div>

                                            </div>
                                            : <></>
                                    }
                                    <div>

                                    </div>
                                    <div className="question-divider-line mt-2"></div>
                                </div>
                            </>
                        ))
                    }
                </div>
            </div>
            //     </ModalBody>
            // </Modal>
        )
    }


}

export default EventHelp;
import React from 'react';
import './concept-list.scss';
import { ImageConstants, Constants, FirebaseEvents } from '../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../utility/image-source';
// import { getRandomNumber } from '../../utility/common-utilities';
import LearningContentWrapper from '../learning/learning-content-wrapper/learning-content-wrapper';
import SharedStaticText from '../../../shared-storage/static-text-store';
import CommonUtils from '../../utility/common-utilities'
import SharedProfile from '../../../shared-storage/profile';
import MasterDataApi from '../../apis/master-data';
import Loading from '../common/loading/loading';
import WorkflowExperience from '../workflow/workflow-experience';
import Path from '../routes/routes-path';
import FireBaseEvent from '../../firebase-events/firebase-events';


class ConceptList extends React.Component {

    constructor(props) {
        super(props);

        this.meta = this.props.params
        this.setState({ loading: true, collapseAll: true, })
        this.getEloStatusListV2();
        this.getChapterProgress();

        this.staticText = SharedStaticText.getStaticText('eloList');
        this.userInfo = SharedProfile.getProfile();

        // this.updateFirstPageActivity()
    }

    state = {
        concepts: [],
        contents: [],
        showShadow: false,
        collapseAll: true,
        chapterCompletedPercentage: 0,
        loading: true,
        showChapterIntroduction: false,
        isChapterIntroAvailable: false,
        showWorkflowExperience: false,
        categoryThemeId: "",
        showChapterConfirmationPopup: false
    };
    staticText = {};
    isChapterCleared = false;
    allowedConceptsForTrial = 0;

    logNavigation(navigationInfo) {
        let payload = {
            type: "CONCEPT",
            info: navigationInfo
        }

        // Api.logUserNavigation(payload, (err, res) => {
        //     if (err) {
        //         return;
        //     }
        //     console.log("navigation logged");
        // });

    }

    componentDidMount() {
        // BackHandler.addEventListener('hardwareBackPress', this.backhandler);
    }

    backhandler = () => {
        // BackHandler.removeEventListener('hardwareBackPress', this.backhandler);
        // this.props.navigation.navigate('Chapters');
        // return true
    }

    // updateFirstPageActivity = () => {


    //     let reqPayload = {
    //         chapterKey: this.meta.chapterKey,
    //         chapterTitle: this.meta.title,
    //         lastVisitedModule: "LEARNING"
    //     }

    //     if (this.meta.isChapterNotCompleted) {
    //         reqPayload.isChapterNotCompleted = true
    //     }

    //     Api.updateFirstPageActivity(reqPayload, (err, res) => {

    //         if (err) {
    //             return;
    //         }
    //     })
    // }

    getEloStatusListV2() {

        if (!this.meta.chapterKey)
            return;

        let chapterKey = this.meta.chapterKey;

        this.setState({ categoryThemeId: this.meta.categoryThemeId })


        let payload = {
            chapterKey
        };

        MasterDataApi.getEloStatusListV2(payload).then((res) => {

            if (res.concepts && res.concepts.length) {
                res.concepts = res.concepts.map(concept => {
                    concept['isCollapse'] = true;
                    return concept;
                })
            }

            if (res.concepts) {
                let clearedConcepts = res.concepts.filter(concept => concept.isConceptCompleted);

                if (clearedConcepts && clearedConcepts.length == res.concepts.length)
                    this.isChapterCleared = true;
            }


            this.setState({
                concepts: res.concepts,
                contents: res.contents,
                learningContent: [{ contents: res.contents, tags: res.tags, categoryThemeId: this.state.categoryThemeId, bookMarkedItem: res.bookMarkedItem }],
                loading: false,
                isChapterIntroAvailable: res.contents && res.contents.length > 0 ? true : false
            });

            this.allowedConceptsForTrial = res.allowedConceptsForTrial;

            if ((res.contents && res.contents.length > 0 && (res.concepts.length == 0 || (this.showChapterIntroValidation(res.concepts)))) || this.meta.navigateChapterIntroduction) {
                this.setState({ showChapterIntroduction: true });
            } else {
                // this.logNavigation(this.meta);
            }

        }).catch(err => {
            if (err) {
                this.setState({ loading: false });
                console.log('elo list error')
                return;
            }
        })
    }

    showChapterIntroValidation(concepts) {

        if (concepts.length === 0) {
            return true
        }
        else if (concepts.length
            && concepts[0]
            && concepts[0].levels.length
            && concepts[0].levels[0].nodes.length
            && concepts[0].levels[0].nodes[0].status == Constants.ELO_STATUS_START)
            return true;
        else {
            return false;
        }

    }

    getChapterProgress = () => {
        let chapterKey = this.meta.chapterKey;

        if (!chapterKey)
            return;

        let payload = {
            chapterKey
        };

        MasterDataApi.getChapterProgress(payload).then((res) => {
            this.setState({ chapterCompletedPercentage: res.value })
        }).catch(err => {
            if (err)
                return;
        })
    }

    showChapterIntro = () => {
        this.setState({ showChapterIntroduction: true, isChapterIntroRevise: true });
    }

    getCircleBasedOnStatus(status, type) {

        switch (status) {

            case Constants.ELO_STATUS_CLEARED:
                return type == 'inner' ? '#71D3CD' : '#71D3CD';

            case Constants.ELO_STATUS_IN_PROGRESS:
                return type == 'inner' ? '#d7f3f1' : '#71D3CD';

            case Constants.TRY_AGIN_IN_PROGRESS:
                return type == 'inner' ? '#F56D77' : '#F56D77';

            case Constants.ELO_STATUS_NOT_CLEARED:
                return type == 'inner' ? '#F56D77' : '#F56D77';

            case Constants.ELO_STATUS_START:
                return type == 'inner' ? '#FFFFFF' : '#71D3CD';

            default:
                return type == 'inner' ? '#c4c4c4' : '#c4c4c4'; /** Locked */
        }

    }


    toggleConcept = (index) => {
        let conceptList = this.state.concepts;

        conceptList[index].isCollapse = !conceptList[index].isCollapse;
        this.setState({ concepts: conceptList })

        let collaped = [];
        let expanded = [];

        for (let i = 0; i < conceptList.length; i++) {
            let concept = conceptList[i];
            if (concept.isCollapse)
                collaped.push(i);
            else
                expanded.push(i);
        }

        if (collaped.length == conceptList.length) {
            this.setState({ collapseAll: true })
        }

        if (expanded.length == conceptList.length) {
            this.setState({ collapseAll: false })
        }
    }

    toggleAll = (value) => {
        let conceptList = this.state.concepts;
        conceptList = conceptList.map(concept => {
            concept['isCollapse'] = value;
            return concept;
        })

        this.setState({ concepts: conceptList, collapseAll: value })
    }

    getConceptList = () => {
        let elements = [];

        if (this.state.isChapterIntroAvailable) {
            elements.push(
                <div className='chapter-intro-container mt-3'>
                    <div className='row ml-0 mr-0 justify-content-between'>
                        <div className='col pl-0 chapter-intro-title-container'>
                            <span className='intro-text'>
                                {`${this.staticText.introductionText} ${this.meta.title}`}
                            </span>
                        </div>
                        <div className='col-2 p-0 chapter-button-container d-flex justify-content-center'>
                            <div className='cursor-pointer chapter-button text-center' onClick={this.showChapterIntro}>
                                <div className='button-text'>
                                    <span className=''>Revise</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        let conceptList = this.state.concepts;

        for (let i = 0; i < conceptList.length; i++) {
            let concept = conceptList[i];
            elements.push(
                <div className='concept-container mt-3'>
                    {/* Commented as per ravi instruction for class 10 Purpose
                    <div><span className='levels-text'>{`LEVEL ${this.getNoOfLevelsCleared(concept.levels)}`}</span></div> */}
                    <div className='d-flex align-items-center mt-2'>
                        <div className='concept-title-container'><span className='concept-text'>{concept.title ? CommonUtils.toCamelCase(concept.title) : ''}</span></div>
                        <div className='expand-icon ml-3'>
                            <div className='cursor-pointer' onClick={() => this.toggleConcept(i)}>
                                {!concept.isCollapse ? <img className='concept-arrow-size' src={getImageSource(ImageConstants.GRAY_UP_ARROW)} ></img>
                                    : <img className='concept-arrow-size' src={getImageSource(ImageConstants.GRAY_DOWN_ARROW)} ></img>}
                            </div>
                        </div>
                    </div>
                    <div className='row ml-0 mr-0 mt-2'>
                        <div className='col ml-0 pl-0 level-bars-container d-flex'>
                            {this.getLevelBars(concept.levels)}
                        </div>
                        <div className='col-2 concept-button-container d-flex justify-content-center'>
                            {concept.isCollapse && this.getButtons(concept.status == Constants.ELO_STATUS_START ? this.getConceptLevelStatus(concept) : concept.status, null, null, true, i, this.getFirstEloInfo(concept, "title"), this.getFirstEloInfo(concept, "skill"))}
                        </div>
                    </div>
                    <div>
                        {
                            !concept.isCollapse && concept.levels.map((level, index) => (
                                <div className='level-pending mt-3'>
                                    <div className='level-header-title'
                                        style={{ backgroundImage: `linear-gradient(90deg, ${this.getGradientColorsBasedOnStatus(level.status)[0]} 0%, ${this.getGradientColorsBasedOnStatus(level.status)[1]} 100%)` }}>
                                        <span>{`Level ${index + 1}`}</span>
                                    </div>

                                    {
                                        level.nodes && level.nodes.length && level.nodes.map(node => (
                                            <div key={node.id}>
                                                {node.elos && node.elos.length && node.elos.map((elo, eloIndex) => (
                                                    <div className='elo-container row ml-0 mr-0 align-items-center mt-3' key={elo.id}>
                                                        <div className='col-1 circle-width mr-0 pr-0'>
                                                            <div className='outer-circle'
                                                                style={{
                                                                    backgroundColor: this.getCircleBasedOnStatus(
                                                                        node.status == Constants.ELO_STATUS_START ? Constants.ELO_STATUS_START :
                                                                            node.status == Constants.ELO_STATUS_IN_PROGRESS ? Constants.ELO_STATUS_IN_PROGRESS : elo.status
                                                                        , 'outer')
                                                                }}>

                                                                <div className='inner-circle'
                                                                    style={
                                                                        {
                                                                            backgroundColor: this.getCircleBasedOnStatus(
                                                                                node.status == Constants.ELO_STATUS_START ? Constants.ELO_STATUS_START :
                                                                                    node.status == Constants.ELO_STATUS_IN_PROGRESS ? Constants.ELO_STATUS_IN_PROGRESS : elo.status
                                                                                , 'inner')
                                                                        }
                                                                    }></div>
                                                            </div>
                                                            {
                                                                // node.elos.length > eloIndex + 1 && <View style={Styles.leftBorderCustom}></View>
                                                            }
                                                        </div>
                                                        <div className='col ml-0 pl-0'><span className='elo-title'>{elo.title}</span></div>
                                                        <div className='col-2 concept-button-container d-flex justify-content-center'>
                                                            {eloIndex == 0 && this.getButtons(node.status, node.id, node.elos, false, i, elo.title, elo.skill)}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            )
        }
        return <div>
            {this.getScrollHeader()}
            {elements}
        </div>;;
    }



    getScrollHeader() {
        return (
            <div className='header-wrapper'>
                {this.renderBreadcrumbForChapterList()}
                <div className='header d-flex justify-content-between align-items-center'>
                    <div className='header-title d-flex align-items-center'>
                        <div className='header-text mr-3'><img className='chapter-image-size' src={this.meta.chapterImageUrl} />  {this.meta.title}</div>
                        <div className='divider-for-title'></div>
                        <div className='ml-3'>
                            <span className='percentage-text'>{this.state.chapterCompletedPercentage}% </span>
                            <span className='completed-text'>
                                {this.staticText.completedText}
                            </span>
                        </div>
                    </div>
                    <div className='large-collapse'>
                        <div className='cursor-pointer' onClick={() => this.toggleAll(!this.state.collapseAll)}>
                            {this.state.collapseAll ? <img className='header-arrow-size' src={getImageSource(ImageConstants.ARROW_DOWN_ANGLE)} ></img>
                                : <img className='header-arrow-size' src={getImageSource(ImageConstants.ARROW_UP_ANGLE)} ></img>}
                        </div>
                    </div>
                </div>
                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.state.categoryThemeId)}></img>
                </div>
                <div className='sub-header-wrapper'>
                    <span className='sub-header-text'>
                        {this.staticText.description}
                    </span>
                </div>
            </div>
        )
    }

    goToHomeScreen() {
        this.props.delegate.props.history.push(Path.HOME)
    }

    goToBrowseChapterList() {
        this.props.back && this.props.back()
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb ml-2 mt-2 mb-1'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToBrowseChapterList()}>{`Browse Chapters >`}</span>
                <span className='pl-2 title-1'>{`${this.meta.title}`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    getLevelBars = (levels) => {
        let elements = [];
        let tryAginAvailable = false;

        for (let i = 0; i < levels.length; i++) {
            let level = levels[i];

            if (level.status == Constants.ELO_STATUS_NOT_CLEARED)
                tryAginAvailable = true;

            elements.push(
                <div
                    style={
                        {
                            width: '7rem',
                            height: '0.8rem',
                            marginRight: i + 1 < levels.length ? '5px' : "",
                            borderTopRightRadius: i + 1 == levels.length ? '10px' : '',
                            borderBottomRightRadius: i + 1 == levels.length ? '10px' : '',
                            borderTopLeftRadius: i == 0 ? '10px' : '',
                            borderBottomLeftRadius: i == 0 ? '10px' : '',
                            backgroundColor: this.getColorBasedOnStatus(
                                Constants.ELO_STATUS_CLEARED == level.status ? !tryAginAvailable ? 'darkGreen' : 'lightGreen' :
                                    Constants.ELO_STATUS_NOT_CLEARED == level.status ? 'red' :
                                        Constants.ELO_STATUS_IN_PROGRESS == level.status ? 'lightGreen' : Constants.TRY_AGIN_IN_PROGRESS === level.status ? 'red' : ''
                            )
                        }
                    }
                ></div>
            )
        }

        return elements;
    }

    getNoOfLevelsCleared = (levels) => {
        let levelsCleared = 0;

        for (let i = 0; i < levels.length; i++) {

            if (levels[i].status == Constants.ELO_STATUS_NOT_CLEARED)
                break;

            if (levels[i].status == Constants.ELO_STATUS_CLEARED) {
                levelsCleared = levelsCleared + 1;
            }
        }
        return levelsCleared == 0 ? 1 : levelsCleared;
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    getConceptLevelStatus = (concept) => {

        if (concept.levels.length && concept.levels[0].nodes.length && concept.levels[0].nodes[0].status == Constants.ELO_STATUS_START) {
            return Constants.ELO_STATUS_START;
        } else {
            return Constants.ELO_STATUS_IN_PROGRESS;

        }

    }

    getFirstEloInfo = (concept, type) => {

        if (type == 'title') {
            return concept.levels[0].nodes[0].elos[0].title
        }

        if (type == 'skill') {
            return concept.levels[0].nodes[0].elos[0].skill
        }

    }

    getButtons = (status, nodeId, elos, isConcept, conceptIndex, eloTitle = '', eloSkill = '') => {

        let eloIds = elos ? elos.map(item => item.id) : [];

        switch (status) {
            case Constants.ELO_STATUS_CLEARED:
                return <div className='cursor-pointer concept-button text-center' onClick={() => this.navigate(status, nodeId, eloIds, isConcept, conceptIndex, eloTitle, eloSkill)}><div className='button-text'><span className=''>Revise</span></div></div>

            case Constants.ELO_STATUS_IN_PROGRESS:
            case Constants.TRY_AGIN_IN_PROGRESS:
                return <div className='cursor-pointer concept-button text-center' onClick={() => this.navigate(status, nodeId, null, isConcept, conceptIndex, eloTitle, eloSkill)}><div className='button-text'><span>Continue</span></div></div>

            case Constants.ELO_STATUS_NOT_CLEARED:
                return <div className='cursor-pointer concept-button text-center' onClick={() => this.navigate(status, nodeId, null, isConcept, conceptIndex, eloTitle, eloSkill)}><div className='button-text'><span>Try Again</span></div></div>

            case Constants.ELO_STATUS_START:
                return <div className='cursor-pointer concept-button text-center' onClick={() => this.navigate(status, nodeId, null, isConcept, conceptIndex, eloTitle, eloSkill)}><div className='button-text'><span>Start</span></div></div>

            default:
                return <div className='lock-icon'><img className='lock-icon-size' src={getImageSource(ImageConstants.LOCK)}></img></div> /** Locked */
        }
    }


    navigate = (status, nodeId, eloIds, isConcept, index, eloTitle, eloSkill) => {


        let conceptToPresent = this.state.concepts[index];


        if (status == Constants.ELO_STATUS_LOCKED)
            return;

        //If the trial user already selected a chapter for trial
        // if (!this.userInfo.isSubscribed && this.meta.trialChapters.length && this.meta.lockedForTrial && status == constants.ELO_STATUS_START) {
        //     this.props.navigation.navigate('SubscriptionScreen');
        //     return;
        // }

        //If the user didn't select any chapter for trial
        // if (!this.userInfo.isSubscribed && this.meta.lockedForTrial && status == constants.ELO_STATUS_START) {
        //     this.setState({ showChapterConfirmationPopup: true, status, nodeId, eloIds, isConcept, index })
        //     return;
        // }

        //ADDED FOR CONCEPT COMPLETION SCREEN
        // let conceptToPresent = this.state.concepts[index];

        if (isConcept) {
            let conceptList = this.state.concepts;
            let value = this.getEloIdAndNodeId(conceptList[index]);

            if (!value) {
                this.toggleConcept(index);
                return;
            } else {
                nodeId = value.nodeId;

                if (!eloIds || !eloIds.length)
                    eloIds = [value.eloId];
            }

        }

        // moengage.logCustomEvent('Elo', { "User Id": this.userInfo.userId, "Elo Name": eloTitle, 'Elo Skill': eloSkill, "Chapter Name": this.meta.title, "Elo Status": status, Concept: conceptToPresent.title, 'Starting Point': 'Browse Chapters', 'Previous Knowlwdge': 'No' });

        FireBaseEvent('Elo', { "User Id": this.userInfo.userId, "Elo Name": eloTitle, 'Elo Skill': eloSkill, "Chapter Name": this.meta.title, "Elo Status": status, Concept: conceptToPresent.title, 'Starting Point': 'Browse Chapters', 'Previous Knowlwdge': 'No' });
        // Facebook.logCustomEvent('Elo', { "User Id": this.userInfo.userId, "Elo Name": eloTitle, 'Elo Skill': eloSkill, "Chapter Name": this.meta.title, "Elo Status": status, Concept: conceptToPresent.title, 'Starting Point': 'Browse Chapters', 'Previous Knowlwdge': 'No' });
        // BranchIO.logCustomEvent('Elo', { "User Id": this.userInfo.userId, "Elo Name": eloTitle, 'Elo Skill': eloSkill, "Chapter Name": this.meta.title, "Elo Status": status, Concept: conceptToPresent.title, 'Starting Point': 'Browse Chapters', 'Previous Knowlwdge': 'No' });

        //Changed Navigate to replace to reload the WorkflowExperience

        this.setState({
            showWorkflowExperience: true,
            workflowMetaInfo: {
                status,
                nodeId,
                eloIds,
                nodes: this.getWorkflowNodes(),
                title: this.meta.title,
                chapterKey: this.meta.chapterKey,
                categoryThemeId: this.meta.categoryThemeId,
                isChapterNotCompleted: this.meta.isChapterNotCompleted ? true : false,
                conceptListMeta: this.meta,
                currentConcept: conceptToPresent,
                masterDataId: this.state.masterDataId,
                isChapterCleared: this.isChapterCleared,
                eloTitle: eloTitle
            }
        })

        // this.props.navigation.replace('WorkflowExperience', {
        //     params: {
        //         status, nodeId, eloIds, nodes: this.getWorkflowNodes(), title: this.meta.title, chapterKey: this.meta.chapterKey, categoryThemeId: this.meta.categoryThemeId,
        //         isChapterNotCompleted: this.meta.isChapterNotCompleted ? true : false, conceptListMeta: this.meta, currentConcept: conceptToPresent, masterDataId: this.state.masterDataId,
        //         isChapterCleared: this.isChapterCleared
        //     }
        // });

    }

    hideWorkflowExperience(conceptListMeta) {
        this.meta = conceptListMeta.meta
        this.setState({ showWorkflowExperience: false, loading: true, collapseAll: true })
        this.getEloStatusListV2();
        this.getChapterProgress();
    }

    getEloIdAndNodeId = (concept) => {

        let nodeIds = [];
        let eloIds = [];

        for (let k = 0; k < concept.levels.length; k++) {

            let level = concept.levels[k];

            for (let i = 0; i < level.nodes.length; i++) {

                let node = level.nodes[i];

                if (node.status == concept.status || node.status == Constants.ELO_STATUS_START) {
                    nodeIds.push(node.id);
                }
                for (let i = 0; i < node.elos.length; i++) {

                    let elo = node.elos[i];

                    if (elo.status == concept.status || elo.status == Constants.ELO_STATUS_START) {
                        eloIds.push(elo.id);
                    }
                }
            }
        }

        if ((nodeIds.length > 1 || eloIds.length > 1) || ((nodeIds.length == 0 || eloIds.length == 0))) {
            return null;
        } else {
            return { nodeId: nodeIds[0], eloId: eloIds[0] };
        }
    }

    getWorkflowNodes() {

        let nodes = []

        let concepts = this.state.concepts;

        // if (!this.userInfo.isSubscribed) {
        //     for (let z = 0; z < this.allowedConceptsForTrial; z++) {

        //         let concept = concepts[z];

        //         for (let k = 0; k < concept.levels.length; k++) {

        //             let level = concept.levels[k];

        //             for (let i = 0; i < level.nodes.length; i++) {

        //                 let node = level.nodes[i];

        //                 let elosBuild = [];

        //                 for (let i = 0; i < node.elos.length; i++) {

        //                     let elo = node.elos[i];
        //                     elosBuild.push({ nodeId: node.id, status: elo.status, id: elo.id, title: elo.title, conceptKey: concept.key, conceptTitle: concept.title });
        //                 }
        //                 nodes.push({ id: node.id, status: node.status, conceptKey: concept.key, conceptTitle: concept.title, elos: elosBuild })
        //             }
        //         }

        //     }

        // }

        // else {
        for (let z = 0; z < concepts.length; z++) {

            let concept = concepts[z];

            for (let k = 0; k < concept.levels.length; k++) {

                let level = concept.levels[k];

                for (let i = 0; i < level.nodes.length; i++) {

                    let node = level.nodes[i];

                    let elosBuild = [];

                    for (let i = 0; i < node.elos.length; i++) {

                        let elo = node.elos[i];
                        elosBuild.push({ nodeId: node.id, status: elo.status, id: elo.id, title: elo.title, conceptKey: concept.key, conceptTitle: concept.title, skill: elo.skill });
                    }
                    nodes.push({ id: node.id, status: node.status, conceptKey: concept.key, conceptTitle: concept.title, elos: elosBuild })

                }
            }

        }
        // }
        console.log("$%$%$%$%$%", nodes)
        return nodes;
    }



    getColorBasedOnStatus(color) {

        switch (color) {

            case 'darkGreen':
                return '#71D3CD';

            case 'red':
                return '#F56D77';

            case 'lightGreen':
                return '#71D3CD';

            default:
                return '#EBEBEB';
        }
    }

    getGradientColorsBasedOnStatus(status) {

        switch (status) {

            case Constants.ELO_STATUS_CLEARED:
            case Constants.ELO_STATUS_IN_PROGRESS:
            case Constants.ELO_STATUS_START:
                return ['rgba(113, 211, 205, 0.3)', '#FFFF'];

            case Constants.TRY_AGIN_IN_PROGRESS:
                return ['rgba(245, 109, 119, 0.3)', '#FFFF'];

            case Constants.ELO_STATUS_NOT_CLEARED:
                return ['rgba(245, 109, 119, 0.3)', '#FFFF'];

            default:
                return ['rgba(196, 196, 196, 0.3)', '#FFFF'];/** Locked */
        }
    }

    hideChapterIntroduction = () => {

        FireBaseEvent(FirebaseEvents.CHAPTER_INTRO_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, chapter: this.meta.title, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // Facebook.logCustomEvent(FirebaseEvents.CHAPTER_INTRO_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, chapter: this.meta.title, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // BranchIO.logCustomEvent(FirebaseEvents.CHAPTER_INTRO_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, chapter: this.meta.title, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        let element = document.getElementById(this.meta.chapterKey)
        // console.log('element', element)
        element.scrollIntoView({ behavior: "instant", block: 'start' })
        this.setState({
            showChapterIntroduction: false
        }, () => {
            this.logNavigation(this.meta);
        })
    }
    goToChapterList = () => {
        //In LATERAL Menu it causes issue because we inject same as new so back has no scope 
        //this.props.navigation.goBack(); 
        this.props.navigation.navigate('Chapters');
    }


    toggleLateralMenu = (flag) => {
        //this.setState({ showLateralMenu: flag });
        this.props.navigation.push('LateralMenu', { params: { backTo: "EloList" } });
    }

    closeChapterConfirmationPopup = () => {
        this.setState({ showChapterConfirmationPopup: false })
    }

    // updateTrialMeta = (status, nodeId, eloIds, isConcept, index) => {

    //     let chapterKey = this.meta.chapterKey;

    //     let payload = {
    //         chapterKey
    //     }

    //     Api.updateTrialMeta(payload, (err, res) => {

    //         if (err) {
    //             this.setState({ showChapterConfirmationPopup: false })
    //             return;
    //         }

    //         this.setState({ showChapterConfirmationPopup: false }, () => {

    //             let conceptToPresent = this.state.concepts[index];

    //             if (isConcept) {
    //                 let conceptList = this.state.concepts;
    //                 let value = this.getEloIdAndNodeId(conceptList[index]);

    //                 if (!value) {
    //                     this.toggleConcept(index);
    //                     return;
    //                 } else {
    //                     nodeId = value.nodeId;
    //                     if (!eloIds || !eloIds.length)
    //                         eloIds = [value.eloId];
    //                 }

    //             }

    //             this.meta.lockedForTrial = false

    //             this.props.navigation.replace('WorkflowExperience', {
    //                 params: {
    //                     status, nodeId, eloIds, nodes: this.getWorkflowNodes(), title: this.meta.title, chapterKey: this.meta.chapterKey, categoryThemeId: this.meta.categoryThemeId,
    //                     isChapterNotCompleted: this.meta.isChapterNotCompleted ? true : false, conceptListMeta: this.meta, currentConcept: conceptToPresent, masterDataId: this.state.masterDataId,
    //                     isChapterCleared: this.isChapterCleared
    //                 }
    //             });
    //         })
    //     })

    // }

    render() {
        // if (this.state.showChapterIntroduction)
        //     return <LearningContentWrapper elo={this.state.learningContent} title={this.meta.title} chapterTitle={this.meta.title} isChapterIntro={true} delegate={this} reviseChapterIntroduction={!this.showChapterIntroValidation(this.state.concepts)} isChapterIntroRevise={this.state.isChapterIntroRevise} navigationInfo={this.meta} />

        if (this.state.showWorkflowExperience) {
            return <WorkflowExperience delegate={this} previousDelegate={this.props.delegate}
                params={this.state.workflowMetaInfo} />
        }

        return (
            <div id={this.meta.chapterKey}>
                {
                    !this.state.showChapterIntroduction &&
                    <div>
                        {
                            !this.state.loading && <div className='elo-list-container'>
                                <div id={this.meta.chapterKey}>
                                    <div>
                                        <img className='header-image' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, this.meta.categoryThemeId)} />
                                        {/* <div onPress={() => { this.toggleLateralMenu(true) }} style={Styles.actionContainerItems}>
                            <View>
                                <Image source={getImageSource(ImageConstants.NAV_LATERAL_MENU)} hitSlop={Styles.hitSlop} />
                            </View>
                        </div> */}
                                    </div>
                                </div>
                                <div className='elo-list mb-5'>
                                    {this.getConceptList()}
                                </div>
                            </div>
                        }
                    </div>
                }

                {
                    this.state.showChapterIntroduction && <LearningContentWrapper elo={this.state.learningContent} title={this.meta.title} chapterTitle={this.meta.title} isChapterIntro={true} delegate={this} reviseChapterIntroduction={!this.showChapterIntroValidation(this.state.concepts)} isChapterIntroRevise={this.state.isChapterIntroRevise} navigationInfo={this.meta} />
                }

                <>
                    {this.state.loading &&
                        <Loading />
                    }
                </>

                {/* {this.state.showChapterConfirmationPopup && !this.userInfo.isSubscribed && this.meta.lockedForTrial && <ChapterConfirmationPopup delegate={this} close={this.closeChapterConfirmationPopup} categoryThemeId={this.state.categoryThemeId} status={this.state.status} nodeId={this.state.nodeId} eloIds={this.state.eloIds} isConcept={this.state.isConcept} index={this.state.index} />} */}
            </div>
        )
    }
}

export default ConceptList
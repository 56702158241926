import React from "react";
import './check-box.scss';
import Parser from "../content-parser/content-parser";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import { Constants, ImageConstants } from "../../../constants/constants";
import CWImageV2 from "../cw-image-2/cw-image-2";
import { getThemeImageSource } from "../../../utility/image-source";

export default class Checkbox extends React.Component {

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.label = this.props.label.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");
        this.label = this.label.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");
    }

    onClick(event) {

        if (this.props.onOptionPressed)
            this.props.onOptionPressed(!this.props.isChecked);
    }


    getLabel() {

        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (this.props.contentType == Constants.CONTENT_TYPE_TEXT) {
            return (
                this.parser.parseWithStyles(`${this.label}`, { p: styles.p, 'cw-math': styles.p, 'cw-blank': styles.p })
            )
        } else if (this.props.contentType == Constants.CONTENT_TYPE_IMAGE) {
            return (
                // <Image source={{ uri: this.props.label }} style={styles.image} resizeMode={'contain'}></Image>
                <CWImageV2 uri={this.props.label} key={this.props.label} />
            )
        }
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId);

        return (
            <div onClick={this.onClick.bind(this)} className = 'cursor-pointer' >
                <div className='checkbox-container d-flex flex-row justify-content-start align-items-center'>
                    <div className='check-box' style={{borderColor : this.theme ? `${!this.props.isReadOnly ? this.theme['secondaryColors']['assessmentOptionsBorder'] : "#FFFFFF"}` : '#353535' }}>
                        {this.props.isChecked === true ? 
                        <img className="tick-mark-size" src={getThemeImageSource(ImageConstants.TICK_MARK_ICON , this.props.categoryThemeId)} />
                        :
                        <></>}
                    </div>
                    <div className='lable-text'>
                        {this.getLabel()}
                    </div>
                </div>
            </div>
        );
    }
}

import React from 'react';
import './schollarship-eligible-popup.scss';
import { ImageConstants, Constants } from '../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../utility/image-source';
import Parser from '../common/content-parser/content-parser';
import SharedProfile from '../../../shared-storage/profile';
import SharedStaticText from '../../../shared-storage/static-text-store';
import CommonUtils from '../../utility/common-utilities';
import { Modal, ModalBody } from 'reactstrap';


export default class ScollarShipEligiblePopUp extends React.Component {

    staticText = {};

    constructor(props) {
        super(props);

        this.userInfo = SharedProfile.getProfile();

        this.parser = new Parser();

        this.staticText = this.props.scholarshipEligibleStaticText ? this.props.scholarshipEligibleStaticText : SharedStaticText.getStaticText('scollarShipEligiblePopUp');

        if (this.props.eventPayload &&
            this.props.eventPayload.isCustomEligibleMessage &&
            this.props.eventPayload.userRange &&
            this.props.eventPayload.customEligibleMessages &&
            this.props.eventPayload.customEligibleMessages[this.props.eventPayload.userRange]) {
            this.staticText.description = this.props.eventPayload.customEligibleMessages[this.props.eventPayload.userRange]

            let stringyFyText = JSON.stringify(this.staticText.description)
            this.staticText.description = JSON.parse(CommonUtils.timeParsar(stringyFyText, this.userInfo.timeZone))
        }

        this.text = CommonUtils.simpleTemplateEngine(this.staticText.description, { name: this.userInfo.name })

        if (!this.text)
            this.text = `<p>Congratulations ${this.userInfo.name}, You are now eligible to take the <strong>SCHOLARSHIP TEST</strong>. Date will be announced shortly.</p><p></p><p>In the meantime, complete the refresher course to prepare for the next year.</p>`

    }
    state = {
    }

    componentDidMount() {
    }

    onPressContinue = () => {
        this.props.delegate && this.props.delegate.onPressContinue && this.props.delegate.onPressContinue();
    }


    render() {
        return (
            <Modal className="cw-common-custom-modal-center-for-schollarship-eligible-popup" isOpen={true} size={'lg'}>
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                    {/* {this.props.showTrialFeatures ? <img className='badge-image' src={getImageSource(ImageConstants.BADGE_IMAGE)} /> : ''} */}
                    {/* <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} /> */}
                </div>
                <ModalBody>
                    <div className="pratice-container">
                        <div className='message d-flex justify-content-center align-items-center'>
                            <div><img className='trophy-icon' src={getImageSource(ImageConstants.TROPHY)} /></div>
                            <div className='header-text ml-2'>
                                {this.staticText.headerText}
                            </div>
                        </div>

                        <div className='hint mb-4'>
                            {this.parser.parseLearningContent(this.text, this, {}, null, null, null)}
                        </div>

                        <div className="d-flex align-items-center justify-content-around mt-2">
                            {/* <div className="continue-button cursor-pointer text-center" onClick={() => this.onClickClose()}>
                                    {`OK`}
                                </div> */}
                            <div className="take-challenge cursor-pointer  text-center" onClick={() => this.onPressContinue()}>
                                {'CONTINUE'}
                            </div>
                        </div>
                        <div className="position">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}




import HttpClient from './http-client';
import URL from './url';

export default class CouponPI {

    static getCoupons(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_COUPONS + "?" + params.join('&') : URL.GET_COUPONS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getCouponDetail(payload = {}) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_COUPON_DETAILS + "?" + params.join('&') : URL.GET_COUPON_DETAILS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getSubcriptions(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SUBSCRIPTION_PLAN_LIST + "?" + params.join('&') : URL.SUBSCRIPTION_PLAN_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


}

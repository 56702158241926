import React from 'react';
import './learning-header.scss';
import { getImageSource } from '../../../../../../utility/image-source';
import Parser from "../../../../../common/content-parser/content-parser";

export default class LearningHeader extends React.Component {

    constructor(props) {
        super(props)
        this.parser = new Parser();
    }

    state = {
        showModal: false
    }

    showIcons = () => {
        this.props.delegate && this.props.delegate.showIcons();
    }

    onClickBookMarkActive = () => {
        this.props.delegate && this.props.delegate.onClickBookMarkActive && this.props.delegate.onClickBookMarkActive(this.props.bookMarkId)
    }

    onClickBookMark = () => {
        this.props.delegate && this.props.delegate.onClickBookMark && this.props.delegate.onClickBookMark()
    }


    render() {
        //console.log(this.props.data.headerIcon)
        return (
            <>
                <div className='learning-header mb-5'>

                    {/* {!this.props.removeHeaderIcon ? <div>
                        <img className='image-width' src={getImageSource(this.props.data.headerIcon)} />
                    </div> : <></>} */}

                    {!this.props.isCommonError ?
                        <div>
                            {!this.props.removeHeaderIcon ?
                                <img className='image-width' src={getImageSource(this.props.data.headerIcon)} />
                                : <></>}
                            {this.props.headerWithStyle ?
                                this.parser.parseLearningContent(this.props.data.title, this.props.delegate, this.props.glossary, null, null, this.props.categoryId) :
                                <span className='title ml-2'>{this.props.data.title}</span>}
                        </div>
                        :
                        <div >
                            {this.props.headerWithStyle ? this.parser.parseWithStyles(this.props.data.title, { p: { color: "#000000", fontWeight: "500" } }, this.props.glossary, this.props.categoryId) :
                                <span >{this.props.data.title}</span>}
                        </div>}

                    {/* {this.props.showBookMark && <View style={Styles.bookMarkIconStyles} >
                        <TouchableOpacity onPress={this.onClickBookMarkActive} hitSlop={Styles.hitSlop}>
                            <Image source={getImageSource(ImageConstants.BOOKMARK_ACTIVE)} />
                        </TouchableOpacity>
                    </View>}

                    {this.props.showInactiveBookmark && < View style={Styles.bookMarkIconStyles} >
                        <TouchableOpacity onPress={this.onClickBookMark} hitSlop={Styles.hitSlop}>
                            <Image source={getImageSource(ImageConstants.BOOKMARK_INACTIVE)} />
                        </TouchableOpacity>
                    </View>} */}




                    {/* <View style={Styles.learningCardHeaderIcons}>
                        <TouchableOpacity onPress={this.showIcons}>
                            <Image style={Styles.icons} source={getImageSource(ImageConstants.LEARNING_CONTENT_MENU)} />
                        </TouchableOpacity>
                    </View> */}

                </div>
                {/* {this.props.meta && <View style={[Styles.cardHeader, Styles.marginBottomHeader]}>
                    <View style={Styles.contentTypeIcon}></View>
                    <View style={Styles.contentType}>
                    {this.props.meta.chapterName ?<Text style={Styles.subText}>{this.props.meta.chapterName}</Text>:<></>}
                        <Text style={Styles.subText}>{this.props.meta.eloName}</Text>
                    </View>
                </View>
                } */}



            </>
        );
    }
}

import React from "react";
import { ImageConstants } from "../../../constants/constants";
import { getImageSource } from "../../../utility/image-source";
import './select-daily-goal.scss';
import audioFile from '../../../assets/audio/button.mp3';
import CwAudio from "../../common/cw-audio/cw-audio";

class DailyGoal extends React.Component {


    state = {
        time: [
            {
                time: 10
            },
            {
                time: 15
            },
            {
                time: 20
            },
            {
                time: 25
            },
            {
                time: 30
            },
            {
                time: 35
            },
            {
                time: 40
            }
        ],
        value: 20,
        reload: true
    }

    getHeight = (value) => {
        switch (value) {
            case 10:
                return 'auto 1.5rem'

            case 15:
                return 'auto 4rem'

            case 20:
                return 'auto 6rem'

            case 25:
                return 'auto 8.5rem'

            case 30:
                return 'auto 10.8rem'

            case 35:
                return 'auto 12.8rem'

            case 40:
                return 'auto 16.5rem'
        }

    }

    getRocketHeight = (value) => {
        switch (value) {
            case 10:
                return 2.5

            case 15:
                return 5

            case 20:
                return 7

            case 25:
                return 9.5

            case 30:
                return 11.8

            case 35:
                return 13.8

            case 40:
                return 18
        }

    }



    setValue = (value) => {
        //console.log(value)
        this.setState({ value, reload: false }, () => {
            this.setState({ reload: true })
        })
    }



    continue = () => {
        this.props.onPressContinue && this.props.onPressContinue(this.state.value)
    }

    getButtons = () => {
        return (
            !this.state.value ?
                <div className="text-center continue-button continue-button-disabled">
                    Continue
                </div>
                :
                <div className="text-center continue-button cursor-pointer" onClick={() => this.continue()}>
                    Continue
                </div>
        )

    }

    goBack() {
        this.props.goBackToSelectTimeScreen && this.props.goBackToSelectTimeScreen()
    }


    render() {

        let lgButton = `linear-gradient(90deg, #ED4264 0%, #FFEDBC 100%)`

        return (
            <div>
                <div className="heading">My daily Goal is</div>
                <div className="sub-heading">You can change it anytime later</div>
                <div className="daily-goal-wrapper">

                    {/* <div className="d-flex justify-content-center align-items-center" > */}
                    <div className="daily-goal-image">
                        <img className="daily-goal-image-size" src={getImageSource(ImageConstants.DAILY_GOAL_BG)} />
                        <div className="scroll-wrapper">
                            <div className="scroll-bar" style={{ backgroundImage: lgButton, backgroundSize: this.getHeight(this.state.value) }}>{''}</div>
                        </div>
                        <div className={`rocket-wrapper`} style={{ bottom: `${this.getRocketHeight(this.state.value)}rem` }}>
                            <img className={`rocket-image-size`} src={getImageSource(ImageConstants.ROCKET)} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex flex-column-reverse align-self-center time-wrapper">
                                {this.state.time.length ? this.state.time.map((item, index) => (
                                    <>
                                        {index % 2 == 0 ?

                                            ""
                                            : <div className={`align-self-start right-side mt-5 ${index == 1 ? 'right-side-second-mt' : ''}`}>
                                                {
                                                    index % 2 != 0 ?
                                                        <div className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => this.setValue(item.time)}>
                                                            <div className={`right-side-selection ${this.state.value === item.time ? 'green-dot' : ''}`}>
                                                                {' '}
                                                            </div>
                                                            <div className="ml-2 min-text">
                                                                {item.time} mins
                                                            </div>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                        }
                                    </>
                                ))
                                    : ''}
                            </div>

                        </div>
                        <div className="d-flex justify-content-center align-items-center position-relative">
                            <div className="d-flex flex-column-reverse align-self-center time-wrapper-left" style={{ position: 'absolute' }}>
                                {this.state.time.length ? this.state.time.map((item, index) => (
                                    <>
                                        {index % 2 == 0 ?

                                            <div className={`align-self-start right-side left-side-margin ${index == 0 ? 'left-side-first-min-mt' : ''}`} >
                                                {
                                                    index % 2 == 0 ?
                                                        <div className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => this.setValue(item.time)}>
                                                            <div className="min-text">
                                                                {item.time} mins
                                                            </div>
                                                            <div className={`right-side-selection ${this.state.value === item.time ? 'green-dot' : ''} ml-2`}>
                                                                {' '}
                                                            </div>

                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                            : ""
                                        }
                                    </>
                                ))
                                    : ''}
                            </div>

                        </div>
                    </div>
                    {/* </div> */}











                    <div className="info-wrapper">
                        <div className="text-center ideal-text">20 mins per day is Ideal</div>
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <div className="days-text">{this.state.value} minutes</div>
                            <div className="week-text">in a day</div>
                        </div>
                    </div>

                </div>


                <div className="d-flex justify-content-center">
                    <div className="text-center back-button cursor-pointer mr-5" onClick={() => this.goBack()}>Back</div>
                    {this.getButtons()}
                </div>

                {this.state.value && this.state.reload ? <CwAudio url={audioFile} /> : ''}
            </div>
        )
    }
}




export default DailyGoal;
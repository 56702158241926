import React from "react";
import './time-list.scss';

class TimeList extends React.Component {

    state = {
        // timeListHash: {
        //     "correct": [{
        //         "userName": "Countingwell School School International School",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "timeTakenInMins": "0.02",
        //         "timeTakenToAnswer": 1405,
        //         "status": "CORRECT"
        //     },
        //     {
        //         "userName": "58:2f:be:86:1c:ee",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "timeTakenInMins": "0.03",
        //         "timeTakenToAnswer": 1405,
        //         "status": "CORRECT"
        //     }],
        //     "incorrect": [
        //         {
        //             "userName": "58:2f:be:86:1c:ee",
        //             "userId": "659b90fa21cb4f53081b46a7",
        //             "timeTakenInMins": "0.02",
        //             "timeTakenToAnswer": 1405,
        //             "status": "IN_CORRECT"
        //         }
        //     ],
        //     "notattempted": [
        //         {
        //             "userName": "ef:3f:4d:11:ef:c7",
        //             "userId": "659b90fa21cb4f53081b46a8",
        //             "status": "NOT_ATTEMPTED"
        //         },
        //         {
        //             "userName": "26:05:e8:5b:ed:d1",
        //             "userId": "659b90fa21cb4f53081b46aa",
        //             "status": "NOT_ATTEMPTED"
        //         },
        //         {
        //             "userName": "98:83:8f:a6:9c:fa",
        //             "userId": "659b90fa21cb4f53081b46ab",
        //             "status": "NOT_ATTEMPTED"
        //         },
        //         {
        //             "userName": "b5:de:e9:93:c5:c2",
        //             "userId": "659b90fa21cb4f53081b46ac",
        //             "status": "NOT_ATTEMPTED"
        //         },
        //         {
        //             "userName": "c9:ee:61:02:61:d8",
        //             "userId": "659b90fa21cb4f53081b46ad",
        //             "status": "NOT_ATTEMPTED"
        //         },
        //         {
        //             "userName": "28:56:46:47:b8:f4",
        //             "userId": "659b90fa21cb4f53081b46ae",
        //             "status": "NOT_ATTEMPTED"
        //         },
        //         {
        //             "userName": "98:95:c3:0d:4b:fa",
        //             "userId": "659b90fa21cb4f53081b46af",
        //             "status": "NOT_ATTEMPTED"
        //         }
        //     ]
        // }
        timeListHash: this.props.timeListHash
    }

    constructor(props) {
        super(props)
    }


    ScoreDisplay = (timeList) => {
        if (!timeList.length) return

        if(timeList[0].status === "CORRECT") {
            timeList = timeList.sort((a, b) => a.timeTakenToAnswer - b.timeTakenToAnswer)
        }

        return (
            <div className="score-board mt-1">
                {timeList.length && timeList.map((item, index) => (
                    <div className="score-item"
                        key={index}
                        style={{ backgroundColor: item.status === 'CORRECT' ? '#37b71d' : item.status === 'IN_CORRECT' ? "#FF6464" : "#003478" }}>
                        {/* <span className="rank">{index + 1}</span> */}
                        <span className="name">{item.userName}{` (${item.studentNumber})`}</span>
                        {
                            item.timeTakenInMins && item.status === 'CORRECT' ? 
                            <span className="score">Score : {10} | Time : {item.timeTakenInMins} S</span> : <></>
                        }
                    </div>
                ))}
            </div>
        );
    };


    renderTimeList() {

        let timeListKeys = Object.keys(this.state.timeListHash)

        return (
            <div>
                {
                    timeListKeys.map(item => (
                        this.ScoreDisplay(this.state.timeListHash[item])
                    ))
                }
            </div>
        )

    }


    render() {
        return (
            <div className="time-list">
                {this.renderTimeList()}
            </div>
        )
    }

}

export default TimeList;
import React from "react";
import './video-list.scss';
import { getImageSource } from "../../utility/image-source";
import { ImageConstants } from "../../constants/constants";
import CwContentViewer from "../common/cw-content-viewer/cw-content-viewer";
import SchoolApi from '../../apis/school';
import PaginationComponent from "../common/cw-pagination/cw-pagination-v2";
import ReactTooltip from 'react-tooltip';
import VideoDetail from "./video-detail/video-detail";
import Dropdown from '../common/dropdown-v2/dropdown';
import MasterDataApi from "../../apis/master-data";
import CouponPI from "../../apis/coupons";
import Loading from "../common/loading/loading";
import ChapterListFilter from "./chapter-list-filter/chapter-list-filter";
import SharedProfile from "../../../shared-storage/profile";
import Path from "../routes/routes-path";

class VideoList extends React.Component {

    currentPage = 1
    limit = 10
    filter = {}

    alphabetHash = {}

    state = {
        reloadDropdown: false,
        showAssessmentFilterModal: false,
        selectedMasterDataId: '5e71c0afe6a6150727ddc842',
        showDropdown: false,
        dropDownList: [],
        conceptsInfo: [],
        selectedConceptInfo: [],
        showVideoDetail: false,
        videoInfo: {},
        selectedConceptIndex: -1,
        selectedVideoIndex: -1,
        chapterIntroductionVideo: [],
        comprehensionKeywordList: [
            // {
            //     "title": "TIMES",
            //     "meaning": "<p><em>Times</em>&nbsp;represents the mathematical operation of multiplication of numbers&nbsp;and is just another name for the operation. For example, the product of 3 and 7 is also termed as &ldquo;3 times 7&rdquo;.</p><p>&nbsp;</p><p>We know that repeated addition is adding equal groups together. It provides a foundation for understanding multiplication. For example, 3 times 5 can be understood as&nbsp;3 groups of 5 counters&nbsp;and then written as 5 + 5 + 5 = 15.</p>"
            // },
            // {
            //     "title": "TIMES",
            //     "meaning": "<p><em>Times</em>&nbsp;represents the mathematical operation of multiplication of numbers&nbsp;and is just another name for the operation. For example, the product of 3 and 7 is also termed as &ldquo;3 times 7&rdquo;.</p><p>&nbsp;</p><p>We know that repeated addition is adding equal groups together. It provides a foundation for understanding multiplication. For example, 3 times 5 can be understood as&nbsp;3 groups of 5 counters&nbsp;and then written as 5 + 5 + 5 = 15.</p>"
            // },
            // {
            //     "title": "TIMES",
            //     "meaning": "<p><em>Times</em>&nbsp;represents the mathematical operation of multiplication of numbers&nbsp;and is just another name for the operation. For example, the product of 3 and 7 is also termed as &ldquo;3 times 7&rdquo;.</p><p>&nbsp;</p><p>We know that repeated addition is adding equal groups together. It provides a foundation for understanding multiplication. For example, 3 times 5 can be understood as&nbsp;3 groups of 5 counters&nbsp;and then written as 5 + 5 + 5 = 15.</p>"
            // },
            // {
            //     "title": "TIMES",
            //     "meaning": "<p><em>Times</em>&nbsp;represents the mathematical operation of multiplication of numbers&nbsp;and is just another name for the operation. For example, the product of 3 and 7 is also termed as &ldquo;3 times 7&rdquo;.</p><p>&nbsp;</p><p>We know that repeated addition is adding equal groups together. It provides a foundation for understanding multiplication. For example, 3 times 5 can be understood as&nbsp;3 groups of 5 counters&nbsp;and then written as 5 + 5 + 5 = 15.</p>"
            // }
        ],
        search: '',
        seletedAlphabet: "",
        selectedAlphabetIndex: -1,
        showKeywordDetailScreen: false,
        selectedKeyword: "",
        selectedKeywordIndex: -1,
        selectedClass: "",
        isLoading: false
    }

    constructor(props) {
        super(props)
        this.userInfo = SharedProfile.getProfile()
        this.getAllClassVideoContent({})
        this.getChapters({})
    }


    componentDidMount() {
        this.getClasses()
    }

    getClasses(payload = {}) {
        SchoolApi.getClassAndBoard(payload)
            .then(school => {
                console.log(school)
                let classes = [], boards = [], classNames = [];

                for (let i = 0; i < school.classes.length; i++) {
                    classes.push({
                        title: school.classes[i].name,
                        value: school.classes[i].name,
                    })
                }

                for (let i = 0; i < school.boards.length; i++) {
                    boards.push({
                        title: school.boards[i].name,
                        value: school.boards[i].name,
                    })
                }


                let board = ''
                if (boards.length) {
                    board = boards[0].value
                }


                classNames = classes.map(c => c.value)

                // if (!this.props.delegate?.props?.location?.state) {
                //     this.getSections({ board, class: classNames })
                // }

                if (boards.length > 1) {
                    this.setState({ boardsDropdownItems: boards })
                } else {
                    this.setState({ selectedBoard: school?.boards[0]?.name })
                }

                console.log(classes)

                this.setState({ classDropdownItems: classes }, () => {
                   
                    this.reloadDropdown()
                })
            }).catch(err => {
                console.log(err)
            })
    }

    getAllClassVideoContent(payload) {

        console.log(this.userInfo)

        if (!payload.class) {
            payload.class = this.userInfo.class
        }

        this.setState({ isLoading: true })


        MasterDataApi.getAllClassVideoContent(payload).then(allVideosData => {
            console.log(allVideosData)

            this.setState({
                conceptsInfo: allVideosData.chapter,
                chapterIntroductionVideo: allVideosData.chapterIntrodutionVideoLearningContent,
                allClassVideoContent: allVideosData.allClassVideoContent,
                allChapterIntroVideos: allVideosData.allChapterIntroVideos,
                isLoading: false
            })

        }).catch(err => {
            this.setState({ isLoading: false })
            console.log(err)
        })
    }

    getChapters(payload = {}) {

        console.log(payload)

        this.setState({ selectedClass: this.userInfo.class, selectedBoard: this.userInfo.board })

        // if (!payload.class) payload.class = this.state.selectedClass
        // if (!payload.board) payload.board = this.state.selectedBoard
        if (!payload.class) {
            payload.class = this.userInfo.class
        }


        MasterDataApi.getAllClassChapterList(payload)
            .then(response => {
                let chapters = [], concepts = []
                response = response.data
                console.log(response)
                // this.setState({ masterDataId: response._id })

                for (let i = 0; i < response.length; i++) {
                    for (let j = 0; j < response[i].chapters.length; j++) {
                        let chapter = response[i].chapters[j]
                        chapters.push({
                            title: chapter.title,
                            value: chapter.key,
                            displayTitle: chapter.title,
                            chapterImageURL: chapter.imageUrl,
                            class: response[i].class,
                            masterDataId: response[i]._id
                        })

                        for (let k = 0; k < chapter.concepts?.length; k++) {
                            let concept = chapter.concepts[k]
                            concepts.push({
                                title: concept.title,
                                displayTitle: concept.title,
                                value: concept.key,
                                chapterKey: chapter.key,
                                chapterTitle: chapter.title,
                                masterDataId: response[i]._id,
                                order: j + 1
                            })
                            // this.buildElosDropdown(concept)
                        }

                        // this.conceptHash[chapter.key] = concepts
                    }
                }

                // chapters.push({value:'ALL',title:'ALL'})
                // console.log(chapters)
                // console.log(concepts)

                let masterDataHash = {}

                chapters.forEach(chapter => {

                    if (!masterDataHash[chapter.masterDataId]) {
                        masterDataHash[chapter.masterDataId] = [chapter]
                    } else {
                        masterDataHash[chapter.masterDataId].push(chapter)
                    }

                })

                console.log(masterDataHash)

                this.setState({
                    chapterList: chapters,
                    orginalChapterList: chapters,
                    selectedChapter: "",
                    conceptsList: concepts,
                    orginalConceptsList: concepts,
                    masterDataHash,
                    selectedMasterDataId : response[0]._id
                })
                this.reloadDropdown()
            }).catch(err => {

                if (err.response && err.response.status === 400) {
                    this.setState({ chapterList: [] })
                }
            })
    }


    reloadDropdown() {
        this.setState({ reloadDropdown: true }, () => {
            this.setState({ reloadDropdown: false })
        })
    }

    onSelectDropDown = (option, key, cb) => {
        console.log(option)
        this.setState({ [key]: option?.value, [`${key}Object`]: option, reloadDropdown: true }, () => {

            this.setState({ reloadDropdown: false })

            if (cb) cb()
            if (key == "selectedClass" || key == "selectedBoard") {
                console.log(this.state.selectedBoard)
                // this.getSections()

                let payload = {}

                if (this.state.selectedClass) {
                    payload.class = this.state.selectedClass
                }
                this.getAllClassVideoContent(payload)
                // this.getChapters({ class: this.state.selectedClass })
            }
        })
    }




    videoEnded(chapterKey, chapterTitle, content, subType, type) {

        console.log(content)

        if (this.watchedVideoRewardAddedList[`${chapterKey}_${content._id}`]) {
            // console.log('returned')
            return;
        }

        let payload = {
            type: type,
            subType: subType,
            chapterKey,
            chapterTitle: this.state.chapterTitle,
            contentTitle: chapterTitle,
            contentId: content._id,
            board: this.state.selectedBoard,
            class: this.state.selectedClass
        }

        CouponPI.addVideoWatchedRewardPoint(payload).then((res) => {
            if (res.data.rewardPointAdded && res.data.chapterKey) {
                this.watchedVideoRewardAddedList[`${res.data.chapterKey}`] = true
            }

            if (type === "CASE_STUDY_ASSIGN" || type === "CHAPTER_ACTIVITY_ASSIGN") {
                this.setState({ rewardAssigned: this.watchedVideoRewardAddedList })
            }

            this.setState({})
        }).catch(err => {
            console.log(err)
        })


    }

    onPageSelected = (selectedPage, startIndex) => {
        this.currentPage = selectedPage
        this.onPageSelected(selectedPage, startIndex)
    }

    onPageSelected = (selectedPage, startIndex) => {
        this.startIndex = startIndex
        this.filter.page = selectedPage
        this.setState({ selectedPage })
        this.getComprehensionKeywords(this.filter, this.filter)
    }

    onChangeHandler(event, key) {
        let value = event.target.value
        this.setState({ [key]: value })
        this.changeChapterList(value)
    }

    changeChapterList(searchText) {
        // console.log(searchText)
        let orginalChapterList = this.state.orginalChapterList
        let orginalConceptsList = this.state.orginalConceptsList
        let orginalAllClassVideoContent = this.state.allClassVideoContent
        let orginalllChapterIntroVideos = this.state.allChapterIntroVideos

        let allVideosList = [...orginalChapterList, ...orginalConceptsList, ...orginalAllClassVideoContent, ...orginalllChapterIntroVideos]

        let value = searchText
        // console.log(orginalChapterList)
        // console.log('1')
        if (value && value.length >= 2) {
            let chapterList = orginalChapterList.filter(option => option[`title`].toLowerCase().startsWith(value.toLowerCase()));
            console.log(chapterList)
            // let chapterKeys = chapterList.map(chapter => `${chapter.masterDataId}_${chapter.value}`)
            // // console.log(chapterKeys)
            // let conceptsList = orginalConceptsList.filter(option => option[`title`].toLowerCase().startsWith(value.toLowerCase()));
            // console.log(conceptsList)
            // let conceptKeys = conceptsList.map(concept => `${concept.masterDataId}_${concept.value}`)
            // // console.log(conceptKeys)
            // let contentsArray = orginalAllClassVideoContent.filter(content => content[`displayTitle`].toLowerCase().startsWith(value.toLowerCase()));
            // console.log(contentsArray)
            // let contentIds = contentsArray.map(content => content.masterDataId_EloId_ContentIdMapping)
            // // console.log(contentIds)
            // let introContentsArray = orginalllChapterIntroVideos.filter(content => content[`displayTitle`].toLowerCase().startsWith(value.toLowerCase()));
            // console.log(introContentsArray)
            // let introContentIds = introContentsArray.map(content => content.masterDataId_ChapterKey_ContentIdMapping)
            // console.log(introContentIds)

            // this.getAllClassVideoContent({
            //     chapterKey: chapterKeys,
            //     class: this.state.selectedClass,
            //     conceptKeys: conceptKeys, contentIds: contentIds, introContentIds
            // })

            // let filteredVideoList = allVideosList.filter(option => option[`title`].toLowerCase().startsWith(value.toLowerCase()))
            let filteredVideoList = allVideosList.filter(option => option[`title`].toLowerCase().includes(value.toLowerCase()))


            let dropDownList = []

            filteredVideoList.forEach(item => {
                let existInDropDown = dropDownList.filter(listItem => listItem.displayTitle === item.displayTitle)
                if (!existInDropDown.length) {
                    dropDownList.push(item)
                }
            })
            this.setState({ chapterList: chapterList, dropDownList: dropDownList, showDropdown: true });
        }

        if (value === '') {
            this.getAllClassVideoContent({})
            this.setState({ chapterList: orginalChapterList, showDropdown: false });
        }
    }

    onSelectCode(item) {
        this.setState({ search: item.displayTitle, showDropdown: false }, () => {
            // this.setState({ isLoginEnable: this.validateMobile(this.state.mobileNumber) })
        })

        this.showSelectedContent(item.displayTitle)
    }

    showSelectedContent(searchText) {
        // console.log(searchText)
        let orginalChapterList = this.state.orginalChapterList
        let orginalConceptsList = this.state.orginalConceptsList
        let orginalAllClassVideoContent = this.state.allClassVideoContent
        let orginalllChapterIntroVideos = this.state.allChapterIntroVideos

        let value = searchText

        let chapterList = orginalChapterList.filter(option => option[`title`].toLowerCase().includes(value.toLowerCase()));
        // console.log(chapterList)
        let chapterKeys = chapterList.map(chapter => `${chapter.masterDataId}_${chapter.value}`)
        // console.log(chapterKeys)
        let conceptsList = orginalConceptsList.filter(option => option[`title`].toLowerCase().includes(value.toLowerCase()));
        // console.log(conceptsList)
        let conceptKeys = conceptsList.map(concept => `${concept.masterDataId}_${concept.value}`)
        // console.log(conceptKeys)
        let contentsArray = orginalAllClassVideoContent.filter(content => content[`displayTitle`].toLowerCase().includes(value.toLowerCase()));
        // console.log(contentsArray)
        let contentIds = contentsArray.map(content => content.masterDataId_EloId_ContentIdMapping)
        // console.log(contentIds)
        let introContentsArray = orginalllChapterIntroVideos.filter(content => content[`displayTitle`].toLowerCase().includes(value.toLowerCase()));
        // console.log(introContentsArray)
        let introContentIds = introContentsArray.map(content => content.masterDataId_ChapterKey_ContentIdMapping)
        // console.log(introContentIds)

        this.getAllClassVideoContent({
            chapterKey: chapterKeys,
            class: this.state.selectedClass,
            conceptKeys: conceptKeys, contentIds: contentIds, introContentIds
        })
        this.setState({ chapterList: chapterList });

    }



    clearFilter = () => {

        this.setState({
            search: "",
            selectedClass: "",
            selectedAlphabetIndex: -1,
            reloadDropdown: true

        }, () => {
            this.setState({ reloadDropdown: false })
            this.onFilterGo()
        })

    }

    onFilterGo() {
        let payload = {}
        if (this.state.search) {
            payload.search = this.state.search
        }

        this.getAllClassVideoContent(payload)

    }

    getAlphabets() {
        let alphabets = []

        for (let i = 65; i <= 90; i++) {

            let character = ""

            if (this.alphabetHash[String.fromCharCode(i)]) {
                character = <div className={`alphabet-wrapper text-center cursor-pointer
             ${this.state.selectedAlphabetIndex === i ? "selected-alphabet-bg" : ""}`}
                    onClick={() => this.setAlphabet(i)}
                >{String.fromCharCode(i)}</div>
            } else {
                character = <div className={`alphabet-wrapper text-center disabled-alphabet
                `}
                >{String.fromCharCode(i)}</div>
            }





            alphabets.push(character)
        }

        return alphabets;
    }

    setAlphabet(index) {
        this.alphabetSearch(String.fromCharCode(index))
        this.setState({ selectedAlphabetIndex: index })
    }

    alphabetSearch(alphabet) {

        let payload = {}
        if (alphabet) {
            payload.alphabetSearch = alphabet
        }

        console.log(payload)

        this.getComprehensionKeywords(payload, this.filter)
    }

    showKeywordDetail(keyword, index) {
        this.setState({ showKeywordDetailScreen: true, selectedKeyword: keyword, selectedKeywordIndex: index })
    }

    hideVideoDetailScreen() {
        let id = this.state.videoInfo._id
        this.setState({ showVideoDetail: false, videoInfo: {} }, () => {
            const element = document.getElementById(id);
            // console.log(id, element)
            element.scrollIntoView({ behavior: "instant", block: 'center' })

        })
    }


    showVideoDetail(concept, videoContent, conceptIndex, videoIndex, introVideo) {

        let videoInfo = {
            ...videoContent,
            conceptTitle: introVideo ? "Chapter Introduction Video" : concept.title,
            chapterTitle: concept.chapterTitle,
            chapterImageUrl: concept.chapterImageUrl,
            class: concept.class,
            chapterKey: concept.chapterKey
        }

        this.setState({ showVideoDetail: true, videoInfo, selectedConceptIndex: conceptIndex, selectedVideoIndex: videoIndex })
    }


    onApplyFilter = (filterPayload) => {

        this.page = 1;

        this.payload = {}

        this.selectedFilters = filterPayload;

        console.log(filterPayload)

        this.setState({ selectedMasterDataId: filterPayload.selectedMasterDataId })

        // if (filterPayload.filterType == 'CHAPTERS') {

        let chapterKeys = filterPayload.selectedItems.map(item => `${item.masterDataId}_${item.value}`)

        this.payload['chapterKey'] = chapterKeys.join(',');

        this.hideAssessmentFilterModal(() => {
            // setTimeout(() => {
            this.getAllClassVideoContent({ chapterKey: chapterKeys })
            // }, 100);

        })
        // }



        if (filterPayload.selectedItems.length > 0) {

            this.setState({ showActiveFilter: true })

        } else {

            this.setState({ showActiveFilter: false })
        }


    }

    goBack = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    showAssessmentFilterModal = () => {
        this.setState({ showAssessmentFilterModal: true })
    }

    hideAssessmentFilterModal = (callback) => {
        this.setState({ showAssessmentFilterModal: false }, () => {
            callback && callback()
        })
    }



    // renderBreadcrumb = () => {
    //     return (
    //         <div className='breadcrumb '>
    //             <span className="bread-crumb-title" onClick={this.goToChapterListScreen}>{`${this.props.selectedClass || ""} >`}</span>
    //             <span className='pl-2 bread-crumb-title' onClick={() => this.toggleBack()}>{`${this.props.chapterTitle || ""}`}</span>
    //             {/* <span className='pl-2'>{this.props.selectedElo?.conceptTitle} {`>`} </span>
    //             <span className='active pl-2'>{this.props.selectedElo?.title}</span> */}
    //         </div>
    //     )
    // }

    renderSolvedExamples() {

        let serialNumber = 0

        // console.log(this.state.conceptsInfo)

        return (
            <div className="row align-items-center justify-content-center mt-5">

                {
                    this.state.chapterIntroductionVideo.length ? this.state.chapterIntroductionVideo.map((introVideoContent, IntroIndex) => (
                        <div id={introVideoContent._id} className='col-4 video-learning-content-wrapper-header mb-3 position-relative'>
                            {/* <img className='blue-dimond-icon' src={getImageSource(ImageConstants.BLUE_DIMOND_ICON)} /> */}
                            <div className='d-flex video-learning-content position-relative flex-column'>
                                <div className="">
                                    <div className='d-flex align-items-start video-learning-content-title cursor-pointer'
                                        onClick={() => this.showVideoDetail(introVideoContent, introVideoContent, 0, IntroIndex, true)} >
                                        {/* <div className='col-2 mr-1 d-flex justify-content-center align-items-center sr-no-bg '>
                                            <div className=' sr-no-bg-color'>{`${serialNumber += 1}`}</div>
                                        </div> */}
                                        <div className='align-self-center sr-no'>{`${introVideoContent.displayTitle.length > 38 ? introVideoContent.displayTitle.slice(0, 38) + "..." : introVideoContent.displayTitle}`} </div>
                                    </div>
                                </div>
                                <div className="">
                                    <img className="video-poster-size cursor-pointer"
                                        src={getImageSource(ImageConstants.VIDEO_POSTER)}
                                        onClick={() => this.showVideoDetail(introVideoContent, introVideoContent, 0, IntroIndex, true)} />
                                    {/* <CwContentViewer from={'video_only'}
                                        content={introVideoContent.content.description}
                                        videoEnded={() => this.videoEnded(introVideoContent.chapterKey, introVideoContent.chapterTitle, introVideoContent, "CHAPTER_INTRODUCTION_VIDEO_WATCHED", "WATCHED_VIDEO_ASSIGN")} /> */}
                                </div>
                                <div className="d-flex mt-2 cursor-pointer chapter-info-wrapper" onClick={() => this.showVideoDetail(introVideoContent, introVideoContent, 0, IntroIndex, true)} >
                                    <div><img className="chapter-image-icon" src={introVideoContent.chapterImageUrl} /></div>
                                    <div className="ml-2 d-flex flex-column">
                                        <div><span className="chapter-title">{introVideoContent.chapterTitle}</span> | {introVideoContent.class}</div>
                                        <div>{"Chapter Introduction Video"}</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    ))
                        : <></>
                }


                {this.state.conceptsInfo && this.state.conceptsInfo.length && this.state.conceptsInfo.map((concept, conceptIndex) => (
                    <>

                        {concept.conceptWiseVideoLearningContent && concept?.conceptWiseVideoLearningContent?.length ?
                            concept?.conceptWiseVideoLearningContent?.map((videoContent, index) => (
                                <div id={videoContent._id} className='col-4 mb-3 video-learning-content-wrapper-header position-relative'>
                                    {/* <img className='blue-dimond-icon' src={getImageSource(ImageConstants.BLUE_DIMOND_ICON)} /> */}

                                    <div className='d-flex video-learning-content flex-column position-relative' >

                                        <div className=" cursor-pointer" onClick={() => this.showVideoDetail(concept, videoContent, conceptIndex, index)}>
                                            <div className={`d-flex align-items-start  
                                            video-learning-content-title`}>
                                                {/* <div className='col-2 mr-2 d-flex justify-content-center align-items-center sr-no-bg '>
                                                <div className=' sr-no-bg-color'>{`${serialNumber += 1}`}</div>
                                            </div> */}
                                                <div className={`col align-self-center sr-no`}>{`${videoContent.displayTitle.length > 38 ? videoContent.displayTitle.slice(0, 38) + "..." : videoContent.displayTitle}`}</div>
                                            </div>

                                        </div>
                                        <div className=" text-right d-flex flex-row">
                                            <img className="video-poster-size cursor-pointer"
                                                src={getImageSource(ImageConstants.VIDEO_POSTER)}
                                                onClick={() => this.showVideoDetail(concept, videoContent, conceptIndex, index)} />
                                            {/* <CwContentViewer from={'video_only'} showOnlyOneVideo={true} content={videoContent.content.description}
                                                videoEnded={() => this.videoEnded(this.props.chapterKey, videoContent.displayTitle, videoContent, "ELO_VIDEO_WATCHED", "WATCHED_VIDEO_ASSIGN")} /> */}
                                        </div>
                                        <div className="d-flex mt-2 cursor-pointer chapter-info-wrapper" onClick={() => this.showVideoDetail(concept, videoContent, conceptIndex, index)} >
                                            <div><img className="chapter-image-icon" src={concept.chapterImageUrl} /></div>
                                            <div className="ml-2 d-flex flex-column">
                                                <div><span className="chapter-title">{concept.chapterTitle}</span> | {concept.class}</div>
                                                <div>{concept.title}</div>
                                            </div>
                                        </div>
                                    </div>


                                </div >
                            )) : this.state.selectedConceptInfo.length ?
                                <div>
                                    <div className='d-flex justify-content-center pb-2'>No Video Content Found for {`${concept.title}`}</div>
                                    {/* <div className='divider'></div> */}
                                </div>
                                : ''

                        }
                    </>
                ))
                }
            </div>
        )

    }

    goToHomeScreen() {
        this.props.history.push(Path.HOME)
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb ml-2 mt-2'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1'>{`Video Library`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }


    render() {


        if (this.state.showVideoDetail) {
            return <VideoDetail delegate={this} videoInfo={this.state.videoInfo} back={() => this.hideVideoDetailScreen()} />
        }


        return (
            <div className="all-video-list ">
                {/* <div className='' >
                    <img className='back-icon cursor-pointer' src={getImageSource(ImageConstants.BACK_ICON)} onClick={() => this.toggleBack()} />
                </div> */}
                {this.renderBreadcrumbForChapterList()}
                <div className="mt-4">
                    <div className='row align-items-center heading-wrapper mt-2 p-2'>
                        {/* <img className="case-study-bg" src={getImageSource(ImageConstants.ACTIVITY_BG)} /> */}
                        <div className='col-5 d-flex align-items-center'>
                            <div>
                                <img className='orange-play-icon mr-2' src={getImageSource(ImageConstants.PLAY_ICON_ORANGE)} />
                            </div>
                            <div>
                                <div className="header-text">{`Video Library`}</div>
                                {/* <div className=''>{this.renderBreadcrumb()}</div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ml-1 mt-4 mb-4 position-relative" onMouseEnter={ReactTooltip.rebuild}>
                    {/* {
                        this.state.selectedClass ? <img className="search-icon-size cursor-pointer" src={getImageSource(ImageConstants.SEARCH_ICON)}
                            onClick={() => this.onFilterGo()}
                            data-tip={"Search"} />
                            :
                            <></>
                    } */}


                    {/* <div className="col-lg-3 col-md-6 col-sm-12">
                        {
                            !this.state.reloadDropdown ? <div>
                                <Dropdown
                                    placeholder='Select a Class'
                                    options={this.state.classDropdownItems}
                                    onSelect={(option) => this.onSelectDropDown(option, "selectedClass")}
                                    text={"title"}
                                    value={this.state.selectedClass}
                                />
                            </div> : <></>
                        }

                    </div> */}

                    <div className="col-8">
                        <div>
                            <input className={`cw-input-for-search`}
                                // disabled={this.state.selectedClass ? false : true}
                                value={this.state.search}
                                onChange={(event) => this.onChangeHandler(event, "search")}
                                placeholder='Search' />
                            {
                                this.state.showDropdown ?
                                    <div className="p-2 dropdown-scroll">
                                        {this.state.dropDownList.length ?
                                            this.state.dropDownList.map(dropdown => (
                                                <div className="dropdown-list cursor-pointer px-3 py-1" onClick={() => { this.onSelectCode(dropdown) }}>
                                                    <div className="row country-name cursor-pointer">
                                                        <div className="col pl-2"> {dropdown.displayTitle}</div>
                                                        {/* <div className="col text-right">  {dropdown.text}</div> */}
                                                    </div>
                                                </div>

                                            ))
                                            : <div className="text-center py-4 no-data">
                                                <label> No Records found</label>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <></>
                            }

                        </div>

                    </div>

                    <div className="col-1">
                        {this.state.showActiveFilter ?
                            <img className="filter-icon-new-size cursor-pointer mt-0"
                                onClick={this.showAssessmentFilterModal}
                                src={getImageSource(ImageConstants.FILTER_ICON_ACTIVE)} />
                            :
                            <img className="filter-icon-new-size cursor-pointer"
                                onClick={this.showAssessmentFilterModal}
                                src={getImageSource(ImageConstants.FILTER_ICON_NEW)} />
                        }

                    </div>

                    <div className="col-1 clear-button-wrapper ml-3">
                        <div className="filter-button  d-inline-block">
                            <button className={`cw-button clear-btn`} onClick={() => this.clearFilter()} >{"CLEAR"}</button>
                        </div>
                    </div>

                </div>

                {
                    !this.state.isLoading ?
                        <div className="solved-examples-card mt-4 ml-2">

                            {
                                this.state.conceptsInfo && this.state.conceptsInfo?.length ? this.renderSolvedExamples()
                                    :
                                    // <div className='d-flex justify-content-center pb-4'>No Video Content Found for this Chapter</div>
                                    <Loading />
                                // <></>
                            }

                        </div>
                        :
                        <Loading />
                }

                {
                    <div className='card-footer' style={{ backgroundColor: "transparent" }}>
                        {
                            this.state.meta?.totalPages ?
                                <PaginationComponent startIndex={this.startIndex} selectedPage={this.currentPage} totalPages={this.state.meta?.totalPages} onPageSelected={this.onPageSelected} /> : ''
                        }
                    </div>
                }
                {
                    this.state.showAssessmentFilterModal ?
                        <ChapterListFilter
                            filter={this.state.orginalChapterList}
                            close={this.hideAssessmentFilterModal}
                            onSelect={this.onSelect} chapters={this.state.chapters}
                            selectedFilters={this.selectedFilters} delegate={this}
                            masterDataHash={this.state.masterDataHash}
                            selectedMasterDataId={this.state.selectedMasterDataId}
                            selectedBoard = {this.userInfo.board} />
                        : <></>
                }
                <ReactTooltip ref={el => this.tooltip = el} multiline={true} place='bottom' type='dark' effect='solid' event="mouseover mouseenter" eventOff="mouseleave mouseout scroll mousewheel blur" />
            </div>
        )
    }

}

export default VideoList;
import React from "react";
import './scholarship-feedback.scss';
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedProfile from "../../../../shared-storage/profile";
import SharedStaticText from "../../../../shared-storage/static-text-store";
import { Constants } from "../../../constants/constants";
import CommonApi from "../../../apis/common";
import EventApi from "../../../apis/event";
import Loading from "../../common/loading/loading";
import Path from "../../routes/routes-path";

export default class ScholarshipFeedback extends React.Component {
    constructor(props) {
        super(props);

        this.state.loading = true
        // const { navigation } = this.props;
        this.meta = this.props.feedbackMeta
        //console.log(this.meta)

        this.getQuestions()

        // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()

        this.staticText = SharedStaticText.getStaticText('scholarshipFeedback');

        if (this.meta && this.meta.type == Constants.MPL_EVENT && this.meta.mplStaticText && this.meta.mplStaticText.feedbackScreen) {
            this.staticText = this.meta.mplStaticText.feedbackScreen
        }

        if (this.meta && this.meta.eventPayload && this.meta.eventPayload.staticText && this.meta.eventPayload.staticText.feedbackScreen) {
            this.staticText = this.meta.eventPayload.staticText.feedbackScreen
        }

        this.userInfo = SharedProfile.getProfile();

        if (this.meta && this.meta.type == Constants.TWO_STEP_EVENT_FINAL) {
            // if (!this.userInfo.isSubscribed)
            //     this.landingScreenInstance && this.landingScreenInstance.pauseUserAppTime()
        }

        // BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
    }

    state = {
        questions: [],
        loading: false
    }

    onSelectSingleSelectOption = (option, questionIndex, optionIndex) => {

        let questions = this.state.questions

        let options = questions[questionIndex].question['options']

        options = options.map(item => ({ ...item, isChecked: false }))

        options[optionIndex].isChecked = true

        questions[questionIndex].question['options'] = options

        this.setState({ questions })
    }

    onSelectMultiSelectOption = (option, questionIndex, optionIndex) => {

        let questions = this.state.questions

        let options = questions[questionIndex].question['options']

        options[optionIndex].isChecked = !options[optionIndex].isChecked

        questions[questionIndex].question['options'] = options

        this.setState({ questions })
    }

    handleBackButtonClick = () => {
        this.props.navigation.navigate('LandingScreen')
        return true
    }

    getQuestions = () => {

        let payload = {
            type: 'SCHOLARSHIP_FEEDBACK_QUESTION',
            sortKey: 'order',
            sortType: "asc"
        }

        if (this.meta && (this.meta.type == Constants.SCHOLARSHIP_EVENT || this.meta.type == Constants.REWARD_EVENT_FINAL || this.meta.type == Constants.TWO_STEP_EVENT_FINAL)) {
            payload.type = this.meta.eventId + '_' + "EVENT"
        } else if (this.meta && this.meta.type == Constants.MPL_EVENT) {
            payload.type = this.meta.eventId + '_' + Constants.MPL_EVENT
        }

        CommonApi.getMiscellaneousList(payload).then((data) => {
            //console.log(data)

            this.setState({ questions: data.response, loading: false })

        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
                return
            }
        })
    }


    renderOptions = (question, index) => {

        switch (question.type) {
            case 'SINGLE_SELECT':
                return this.renderSingleSelectOptions(question, index)

            case 'MULTI_SELECT':
                return this.renderMultiSelectOptions(question, index)

            default:
                return ""
        }
    }

    onPressEndScreen = () => {

        if (this.meta && this.meta.type == Constants.TWO_STEP_EVENT_FINAL) {
            // if (!this.userInfo.isSubscribed)
            //     this.landingScreenInstance && this.landingScreenInstance.resumeUserAppTime()
        }

        this.saveAnswer()
    }

    saveAnswer = () => {

        let answerBuild = []
        let isValid = true

        for (let i = 0; i < this.state.questions.length; i++) {
            let question = this.state.questions[i].question

            let obj = {
                question: {
                    title: question.title,
                    type: question.type
                }
            }

            let answer = []
            for (let j = 0; j < question.options.length; j++) {

                if (question.options[j].isChecked) {
                    answer.push({
                        title: question.options[j].title
                    })
                }
            }

            obj.answer = answer

            answerBuild.push(obj)

            if (!answer.length) {
                isValid = false
                break;
            }
        }


        if (!isValid)
            return

        let payload = { answer: answerBuild }

        if (this.meta && (this.meta.type == Constants.SCHOLARSHIP_EVENT || this.meta.type == Constants.REWARD_EVENT_FINAL || this.meta.type == Constants.TWO_STEP_EVENT_FINAL)) {
            payload.type = Constants.SCHOLARSHIP_EVENT
            payload.eventId = this.meta.eventId
        } else if (this.meta && this.meta.type == Constants.MPL_EVENT) {
            payload.type = Constants.MPL_EVENT
            payload.eventId = this.meta.eventId
        }

        this.setState({ loading: true })
        EventApi.saveScholarshipFeedback(payload).then((data) => {
            this.setState({ loading: false })

            if (this.meta.type == Constants.MPL_EVENT) {
                this.props.navigation.navigate('MPLAssessmentEndScreen', {
                    meta: this.meta
                })
                return
            }

            if (this.meta.type == Constants.REWARD_EVENT_FINAL) {
                this.props.navigation.navigate('RewardEndScreen', {
                    meta: this.meta
                })
                return
            }

            if (this.meta && (this.meta.from == Constants.TWO_STEP_EVENT_FINAL)) {

                this.props.delegate && this.props.delegate.showOneStepEventAssessmentEndScreen && this.props.delegate.showOneStepEventAssessmentEndScreen({
                    meta: this.meta
                })
                return
            }
            this.props.navigation.navigate('ScollarShipEndScreen', {
                meta: this.meta
            })

        }).then(err => {
            if (err) {
                this.setState({ loading: false })
                return
            }
        })


    }

    renderSingleSelectOptions = (question, questionIndex) => {

        return (
            question.options.map((option, index) => (
                <div className="option cursor-pointer d-flex flex-row align-items-center mt-3" onClick={() => this.onSelectSingleSelectOption(option, questionIndex, index)}>
                    <div className={`radio-circle `}>
                        <div className={`${option.isChecked ? 'inner-selected' : ""}`}></div>
                    </div>
                    <div className="option-title ml-2">{option.title}</div>
                </div>
            ))
        )
    }

    // renderMultiSelectOptions = (question, questionIndex) => {

    //     return (
    //         question.options.map((option, index) => (
    //             <TouchableOpacity style={Styles.option} onPress={() => this.onSelectMultiSelectOption(option, questionIndex, index)}>
    //                 <View style={Styles.checkbox}>
    //                     {option.isChecked && <Image style={Styles.tickMark} source={getThemeImageSource(ImageConstants.CHECKBOX_TICK, "1")} />}
    //                 </View>
    //                 <View style={Styles.textContainer}>
    //                     <Text style={Styles.optionTitle}>{option.title}</Text>
    //                 </View>
    //             </TouchableOpacity>
    //         ))
    //     )
    // }


    renderQuestions = () => {

        let elements = [];

        for (let i = 0; i < this.state.questions.length; i++) {

            let question = this.state.questions[i].question

            elements.push(
                <div>
                    <div className="question-title">
                        {(i + 1) + '. ' + question.title}
                    </div>

                    <div className="options">
                        {this.renderOptions(question, i)}
                    </div>

                    {i < this.state.questions.length - 1 ? <img className="question-separator-size mt-3 mb-3" src={getImageSource(ImageConstants.LEARNING_CONTENT_SEPARATOR)}></img> : <></>}
                </div>
            )
        }

        return elements
    }

    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goToEventList() {
        this.props.delegate && this.props.delegate.hideScholarshipFeedback && this.props.delegate.hideScholarshipFeedback()
    }


    renderBreadcrumbForKnowMore = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToEventList()} >{`Events >`}</span>
                <span className='title-1 pl-2'>{`${this.meta.chapterTitle}`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    render() {

        if (this.state.loading) {
            return <Loading loading={true} />
        }

        return (

            <div className="scholarship-feedback">

                {/* <ImageBackground source={getThemeImageSource(ImageConstants.SUCCESS_SCREEN_BACKGROUND, this.categoryThemeId)} style={Styles.imageBackground1} >
                    <Image source={getThemeImageSource(ImageConstants.REVIEW_ANSWERS_PAGE, this.categoryThemeId)} style={Styles.image}  ></Image>

                    <Image source={getImageSource(ImageConstants.SCHOLARSHIP_FEEDBACK_BG)} style={{ position: 'absolute', width: '80%', top: 30, zIndex: 99999, left: 50 }} resizeMode={'contain'}></Image>

                </ImageBackground> */}
                <img className="header-image-size" src={getImageSource(ImageConstants.KNOW_MORE_HEADER)}></img>
                {this.renderBreadcrumbForKnowMore()}
                <div className="text-center">
                    <img className="review-answers-image" src={getImageSource(ImageConstants.REVIEW_ANSWERS_IMAGE)} />
                </div>
                <div className="header-text text-center mt-2" >{this.staticText.headerText}</div>
                <img className="divider-image " src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, 1)} ></img>
                <div className="description-text text-center mt-2 mb-2">

                    {this.staticText.description}

                </div>

                <img className="divider-image mb-3" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, 1)} ></img>


                {this.renderQuestions()}

                <div className="d-flex justify-content-center button-wrapper" >
                    <div className="next-button cursor-pointer" onClick={this.onPressEndScreen}>{this.staticText.buttonText}</div>
                </div>
                <div className="feedback-footer">
                    <img className='footer-image-size' src={getThemeImageSource(ImageConstants.ASSESSMENT_FOOTER, this.props.categoryThemeId)} />
                </div>


            </div>
        )
    }
}
import React from 'react';
import './content-list-screen.scss';
import { ImageConstants, Constants } from '../../../constants/constants';
import { getThemeImageSource, getImageSource } from '../../../utility/image-source';
import SharedStaticText from '../../../../shared-storage/static-text-store';
import ContentListHeader from './content-list-header/content-list-header'
import ContentListPopup from './content-list-popup/content-list-popup'
import SharedProfile from '../../../../shared-storage/profile';

export default class ContentListScreen extends React.Component {

    constructor(props) {
        super(props);

        this.staticText = SharedStaticText.getStaticText('groupTitlesListScreen');

        this.state.chapterTitle = this.props.chapterTitle
        this.state.isChapterNotCompleted = this.props.isChapterNotCompleted
        this.userInfo = SharedProfile.getProfile();
    }

    componentDidMount() {

        if (this.props.data && !this.props.data.isNodeActivityExist)
            this.setState({ showPopup: true })

        this.prepareDisplayContent()
    }

    prepareDisplayContent() {

        if (!this.props.data)
            return;

        let groups = [];
        let eloTitle = ''
        let categoryId = ''
        let eloSkill = ''

        if (!this.state.isChapterNotCompleted) {
            groups.push({
                title: this.staticText.letsAssessText ? this.staticText.letsAssessText : 'Let’s Assess your knowledge',
                isRead: true,
                active: this.props.data.isNodeActivityExist ? false : true
            })

            if (this.props.data.isPkExist) {
                groups.push({
                    title: 'Previous knowledge',
                    isRead: this.props.data.isNodeActivityExist ? true : false,
                    active: this.props.data.isNodeActivityExist ? true : false
                })
            }

            for (let i = 0; i < this.props.data.learningGroups.length; i++) {

                let group = this.props.data.learningGroups[i]

                if (!group.isPreviousKnowledge) {
                    groups.push({
                        title: group.contents && group.contents.length ? group.contents[0].title : '',
                        isRead: this.props.data.isNodeActivityExist && groups.length == 1 && true,
                        active: this.props.data.isNodeActivityExist && groups.length == 1 && true
                    })

                    eloTitle = group.eloTitle
                    eloSkill = group.eloSkill
                    categoryId = '' + group.categoryThemeId
                }

            }
        } else {

            if (this.props.data.isPkExist && !this.props.data.isNodeActivityExist) {

                // groups.push({
                //     title: this.staticText.letsAssessText ? this.staticText.letsAssessText : 'Let’s Assess your knowledge',
                //     isRead: true,
                //     active: true
                // })

            }

            // if (this.props.data.isPkExist) {
            //     groups.push({
            //         title: 'Previous knowledge',
            //         isRead: groups.length == 0 && true,
            //         active: groups.length == 0 && true
            //     })
            // }

            for (let i = 0; i < this.props.data.learningGroups.length; i++) {

                let group = this.props.data.learningGroups[i]

                if (!group.isPreviousKnowledge) {
                    groups.push({
                        title: group.contents && group.contents.length ? group.contents[0].title : '',
                        isRead: groups.length == 0 && true,
                        active: groups.length == 0 && true
                    })

                    eloTitle = group.eloTitle
                    eloSkill = group.eloSkill
                    categoryId = '' + group.categoryThemeId
                }

            }
        }

        let elo = this.state.elo
        elo.contents = groups
        elo.title = eloTitle
        elo.skill = eloSkill
        this.setState({ elo, categoryId })
    }

    state = {
        chapterTitle: '',
        categoryId: "",
        elo: {
            title: "",
            contents: []
        }
    }

    /** Screen Sample Data */
    // state = {
    //     chapterTitle: 'Congruence of Triangles',
    //     categoryId: "6",
    //     elo: {
    //         title: "test etest test",
    //         contents: [
    //             {
    //                 title: 'Group 1 hjbvsdf hjbdsf hjbfv hjsbfv jhbsfdhv hjfb ',
    //                 contents: [],
    //                 isRead: true

    //             },
    //             {
    //                 title: 'Group 2',
    //                 contents: [],
    //                 isRead: true

    //             },
    //             {
    //                 title: 'Group 3',
    //                 contents: [],
    //                 active: true
    //             },
    //             {
    //                 title: 'Group 4',
    //                 contents: []
    //             },
    //             {
    //                 title: 'Group 5',
    //                 contents: []
    //             },
    //             // {
    //             //     title: 'Group 2',
    //             //     contents: [],

    //             // },
    //             // {
    //             //     title: 'Group 3',
    //             //     contents: []
    //             // },
    //             // {
    //             //     title: 'Group 4',
    //             //     contents: []
    //             // },
    //             // {
    //             //     title: 'Group 5',
    //             //     contents: []
    //             // },


    //         ]
    //     }
    // }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    closePopup = () => {
        this.setState({ showPopup: false })
    }

    hideList = () => {
        this.props.delegate && this.props.delegate.hideContentList && this.props.delegate.hideContentList(this.state.elo.title, this.state.elo.skill)
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToRefresherCourseEloList() {
        this.props.delegate && this.props.delegate.hideRefresherCourseExperience && this.props.delegate.hideRefresherCourseExperience()
    }

    render() {

        return (
            <div className='content-list-screen'>
                {
                    this.state.elo.contents && this.state.elo.contents.length ?
                        <>
                            <div>
                                <ContentListHeader delegate={this} isScrolled={this.state.showShadow} title={this.state.elo.title} showActiveFilter={this.state.showActiveFilter} chapterTitle={this.state.chapterTitle} totalDuration={this.props.totalDuration} categoryId={this.state.categoryId} />
                            </div>
                            <div className='mb-4'>
                                {
                                    this.state.elo.contents.map((item, index) => (
                                        <div className={`d-flex ${index === 0 ? 'first-detail-container cursor-pointer' : 'elo-detail-container'}`}
                                            onClick={item.active && this.hideList}>
                                            <div className='d-flex' style={{ marginRight: item.active ? '10px' : 0 }}>
                                                <div className={`${item.isRead ? 'active-content-number mr-2' : 'inactive-content-number mr-2'}`}>{index + 1}.</div>
                                                <div className={`${item.isRead ? 'active-title' : 'inactive-title'}`}>{item.title}</div>
                                            </div>
                                            {item.active ?
                                                <div className={`mr-5 ${index === 0 ? 'first-content-gif-container' : 'gif-container'}`}>
                                                    <img className='navigation-icon' src={getThemeImageSource(ImageConstants.CONTENT_NAVIGATION, this.state.categoryId)} />
                                                    <img className='arrow-icon' src={getImageSource(ImageConstants.RIGHT_ARROW)} />
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                    ))
                                }
                            </div>

                        </>
                        :
                        <></>
                }

                {this.state.showPopup && !this.props.isFromDefaultWorkout && <ContentListPopup delegate={this} showModal={this.state.showPopup} data={{
                    chapterTitle: this.state.chapterTitle,
                    eloTitle: this.state.elo.title,
                    conceptTitle: this.props.data && this.props.data.conceptTitle ? this.props.data.conceptTitle : ""
                }} categoryId={this.state.categoryId} />}
            </div>
        )
    }

}


import React from "react";
import './select-time.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";

class SelectTime extends React.Component {


    state = {
        items: [
            {
                type: 'MORNING',
                description: this.staticText && this.staticText.morningDescription ? this.staticText.morningDescription : '   Mornings, After I finish Glass of Milk',
                inactiveImage: getImageSource(ImageConstants.MORNING),
                activeImage : getImageSource(ImageConstants.MORNING_ACTIVE)
            },
            {
                type: 'EVENING',
                description: this.staticText && this.staticText.eveningDescription ? this.staticText.eveningDescription : '   Evenings, After I am done playing',
                inactiveImage: getImageSource(ImageConstants.EVENING),
                activeImage : getImageSource(ImageConstants.EVENING_ACTIVE)
            },
            {
                type: 'NIGHT',
                description: this.staticText && this.staticText.nightDescription ? this.staticText.nightDescription : '   Nights, Just after I finish yummy dinner',
                inactiveImage: getImageSource(ImageConstants.NIGHT),
                activeImage : getImageSource(ImageConstants.NIGHT_ACTIVE)
            }
        ],

        selectedLevel : 'MORNING',
        selectedIndex : 0

    }



    select = (item, index) => {
        this.setState({ selectedIndex: index, selectedLevel: item.type })
    }


    continue = () => {
        this.props.onPressContinue && this.props.onPressContinue(this.state.selectedLevel)
    }

    getButtons = () => {
        return (
            !this.state.selectedLevel ?
                <div className="text-center continue-button continue-button-disabled">
                    Continue
                </div>
                :
                <div className="text-center continue-button cursor-pointer" onClick={() => this.continue()}>
                    Continue
                </div>
        )

    }

    goBack() {
        this.props.goBackToClassDetailsScreen && this.props.goBackToClassDetailsScreen()
    }


    render() {
        return (
            <div>
                <div className="heading">I like to Practice in</div>
                <div className="sub-heading">You can change it anytime later</div>
                <div className="select-time-wrapper mt-4">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        {this.state.items.length ? this.state.items.map((item, index) => (
                            <div className={`position ${index === 0 ? '' : 'mt-3' }`}>
                                <img onClick={() => this.select(item, index)}
                                    className="select-time-image cursor-pointer"
                                    src={this.state.selectedIndex === index ? item.activeImage : item.inactiveImage} />
                                    <div className="description-text">{item.description}</div>
                            </div>
                        ))
                            : ''}
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="text-center back-button cursor-pointer mr-5" onClick={() => this.goBack()}>Back</div>
                    {this.getButtons()}
                </div>
            </div>
        )
    }
}


export default SelectTime;
import React from "react";
import './my-certificate-list.scss';
import { getImageSource } from "../../utility/image-source";
import { ImageConstants } from "../../constants/constants";
import Path from "../routes/routes-path";
import CommonApi from "../../apis/common";
import ViewCertificate from "./view-certificate/view-certificate";


class MyCertificate extends React.Component {


    state = {
        certificateList: [],
        showCertificate: false,
        eventCertificateDetail: {}
    }

    componentDidMount() {
        this.getEventStudentCertificate()
    }

    getEventStudentCertificate() {

        CommonApi.getEventStudentCertificate().then((res) => {
            //console.log(res)

            this.setState({ certificateList: res.certificateList })


        }).catch(err => {
            //console.log(err)
        })


    }


    showEventCertificate(eventCertificate, index) {

        window.open(eventCertificate.url)
        // window.open(eventCertificate.url, '_blank').focus();

         //this.setState({ showCertificate: true, eventCertificateDetail: eventCertificate, selectedIndex: index })
        // var a = document.createElement('A');
        // var filePath = eventCertificate.url;
        // a.href = filePath;
        // a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

    }

    hideCertificate() {
        this.setState({showCertificate : false})
    }


    goToHomeScreen() {
        return this.props.history.replace({ pathname: Path.HOME })
    }


    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2'>{`My Certificates`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    render() {

        if (this.state.showCertificate) {
            return <ViewCertificate
                certificateInfo={this.state.eventCertificateDetail}
                hideCertificate = {() => this.hideCertificate()}
            />
        }

        return (
            <div className="my-certificate-list">
                <div className="certificate-header">
                    <div className="text-right">
                        <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                    </div>
                    <div className="header-title-margin-top">
                        {this.renderBreadcrumbForChapterList()}
                        <div className="d-flex justify-content-between">
                            <div className="title">My Certificates</div>

                        </div>
                        <div>
                            <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                        </div>
                    </div>

                    {
                        this.state.certificateList && this.state.certificateList.length ?
                            <div className="d-flex card-wrapper">
                                {this.state.certificateList.map((item, index) => (
                                    <div className="certificate-list-card">
                                        <div className="event-name">{item.eventName}</div>
                                        <img className="event-image" src={item.eventImageUrl} />
                                        <div className="d-flex justify-content-center 
                                        align-items-center view-certificate-button-wrapper cursor-pointer"
                                            onClick={() => this.showEventCertificate(item, index)}>
                                            <div className="view-certificate-button">Download Certificate</div>
                                        </div>
                                    </div>

                                ))}
                            </div>
                            :
                            <div className="text-center mt-4">No Certificates Found</div>
                    }

                </div>
            </div>
        )
    }

}

export default MyCertificate;
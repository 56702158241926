import React from "react";
import './quotes-title.scss';
import Parser from "../../../common/content-parser/content-parser";
import TimeList from "../../time-list/time-list";
import ClickerAssessmentLeaderboard from "../../clicker-assessment-leaderboard/clicker-assessment-leaderboard";

class AssessmentQuote extends React.Component {

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.label = this.props.quote.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");
        this.label = this.label.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");
    }

    componentDidMount() {
        console.log(this.props.showQuestionLeaderBoard)
    }


    getQuestionLeaderBoard() {
        console.log(this.props.leaderboardData)

        let leaderboardData = this.props.leaderboardData
        let isQualified = false, isFinalQuestion = false

        if ((this.props.activePage === this.props.questions.length) && this.props.showQuestionLeaderBoard) {
            // leaderboardData = this.props.leaderboardData.slice(0, 4)
            isFinalQuestion = true
        }
        
        return (<div>
            {/* <div className="text-center">Leaderboard</div> */}
            <ClickerAssessmentLeaderboard leaderboardData={leaderboardData} isQualified={isQualified} isFinalQuestion = {isFinalQuestion}  />
        </div>)
    }



    render() {
        let styles = {
            p: { fontSize: '28px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "700" }
        }
        return (
            <div className="quotes-title" style={{
                marginLeft: `${this.props.isFullScreenMode && this.props.showStudentResponse ? this.props.isFFF ? "29rem" : '14rem' :
                    this.props.isFullScreenMode && !this.props.showStudentResponse ? "0.5rem" : this.props.showStudentResponse ? "6rem" : '0rem'}`
            }}>
                {
                    this.props.isFFF ?
                        <div className="d-flex align-items-center mb-3">
                            <div className="quiz-button mr-2">Quiz</div>
                            <div className="subject-text">India Maths Day 2024</div>
                            <div className="question-number ml-2">Q {this.props.activePage}/{this.props.questions.length}</div>
                            {
                                this.props.showQuestionLeaderBoard ? <div className="leaderboard-text">Leader Board</div> : ""
                            }
                        </div>
                        :
                        <div className="d-flex align-items-center mb-3">
                            <div className="quiz-button mr-2">Assessment</div>
                            <div className="subject-text">Science</div>
                            <div className="question-number ml-2">Q {this.props.activePage}/{this.props.questions.length}</div>
                            {
                                this.props.showQuestionLeaderBoard ? <div className="leaderboard-text">Leader Board</div> : ""
                            }
                        </div>
                }
                {
                    this.props.isFFF ?
                        this.props.showQuestionLeaderBoard ?
                            <>{this.getQuestionLeaderBoard()}</>
                            :
                            <div className="">
                                <TimeList timeListHash={this.props.timeListHash} />
                            </div>
                        :
                        <div className="d-flex justify-content-center ">
                            <div className="quote-text-wrapper">
                                <div className="quote-text">
                                    {this.props.quote}
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }

}

export default AssessmentQuote;
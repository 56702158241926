import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import './practice-question-popup.scss';
import CwContentViewer from "../../../common/cw-content-viewer/cw-content-viewer";
import QuestionImage from "../../../common/cw-question-image/cw-question-image";
import { getImageSource, getThemeImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import SummarySoultions from "../../../common/summary-solution/summary-solution";
import RadioGroup from "../../../common/radio/radio-group";
import CWImageV2 from "../../../common/cw-image-2/cw-image-2";
import { Constants } from "../../../../constants/constants";
import Parser from "../../../common/content-parser/content-parser";
import Checkbox from "../../../common/check-box/check-box";
import ActionSheet from "../../../common/action-sheet/action-sheet";
import CommonUtils from "../../../../utility/common-utilities";
import GlossaryParser from '../../../common/content-parser/glossary-parser';
import LearningContent from './learning/learning-content';
import QuestionTitle from "../../../common/question/question-title/question-title";
// import QuestionImage from "../../../common/cw-question-image/cw-question-image";


class PracticeQuestionModal extends React.Component {

    state = {
        showAnswer: false,
        showSolution: false,
    }

    constructor(props) {
        super(props)
        this.parser = new Parser();
        this.glossaryParser = new GlossaryParser();
        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)
        //console.log(this.props.elo)
        this.state = {
            elo: this.props.elo && this.props.elo.length ? this.props.elo : [],
            showLearningContent: false,
            showThingsToRememberBar: false
        }



    }

    componentDidMount() {
        if (this.props.isAnswer) {
            this.viewAnswer(this.props.content.content, this.props.index)
        }
        if (this.props.isSolution) {
            this.viewSolution(this.props.content.content, this.props.index)
        }

        this.getGlossaryContent()



    }

    checkForDPSFLearningContent() {

        let contentExist = false
        if (this.state.glossaryContent && this.state.elo[0].contents) {

            let contentLength = this.state.elo[0].contents.length;

            for (let i = 0; i < contentLength; i++) {

                // if (i != 0) {

                //     element.push(
                //         <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img>

                //     );
                // }
                let contentsPerElo = this.state.elo[0].contents[i].contents;

                let groupTitle = this.state.elo[0].contents[i].title;

                let contentObjArray = contentsPerElo.map(item => ({ id: item._id, objectType: item.type, contentType: item.content.type }))

                let contentObj = { title: groupTitle, contentIds: contentObjArray }

                let bookMarkId = this.state.elo[0]['bookMarkedItem'] && this.props.elo[0]['bookMarkedItem'] && this.props.elo[0]['bookMarkedItem'][i] && this.props.elo[0]['bookMarkedItem'][i].bookMarkId ? this.props.elo[0]['bookMarkedItem'][i].bookMarkId : null

                //console.log(this.state.glossaryContent)



                contentsPerElo.map((data, index) => {
                    if ((data.type === 2) &&
                        (data.content.type === 2 ||
                            data.content.type === 3 ||
                            data.content.type === 4 ||
                            data.content.type === 5)) {

                        contentExist = true

                    }
                }

                )
            }
        }

        if (contentExist) {
            this.setState({ showThingsToRememberBar: contentExist })
        }

    }

    getGlossaryContent = () => {

        // //console.log("eloCategory", this.props.elo)

        if (this.props && this.props.elo && this.props.elo.length > 0) {

            let elos = this.props.elo

            let contents = [];

            for (let i = 0; i < elos.length; i++) {

                let contentsList = elos[i]['contents'];

                for (let j = 0; j < contentsList.length; j++) {

                    contents = contents.concat(contentsList[j].contents)
                }
            }

            if (contents.length > 0) {

                this.glossaryParser.getParsedContentIdsFromContent(contents, (err, glossaryContent) => {
                    //console.log(glossaryContent)
                    this.setState({
                        glossaryContent
                    }, () => {
                        this.checkForDPSFLearningContent()
                        this.setState({ showLearningContent: true })
                    })
                })

            } else {
                this.setState({
                    glossaryContent: {}
                })
            }

        } else {
            this.setState({
                glossaryContent: {}
            })
        }
    };

    renderLearningContent = () => {

        let element = [];
        //console.log(this.state.elo[0].contents)
        // let contentsPerElo = this.state.elo[0].contents[i].contents;
        if (this.state.glossaryContent && this.state.elo[0].contents) {

            let contentLength = this.state.elo[0].contents.length;

            for (let i = 0; i < contentLength; i++) {

                // if (i != 0) {

                //     element.push(
                //         <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img>

                //     );
                // }
                let contentsPerElo = this.state.elo[0].contents[i].contents;

                let groupTitle = this.state.elo[0].contents[i].title;

                let contentObjArray = contentsPerElo.map(item => ({ id: item._id, objectType: item.type, contentType: item.content.type }))

                let contentObj = { title: groupTitle, contentIds: contentObjArray }

                let bookMarkId = this.state.elo[0]['bookMarkedItem'] && this.props.elo[0]['bookMarkedItem'] && this.props.elo[0]['bookMarkedItem'][i] && this.props.elo[0]['bookMarkedItem'][i].bookMarkId ? this.props.elo[0]['bookMarkedItem'][i].bookMarkId : null

                //console.log(this.state.glossaryContent)

                contentsPerElo.map((data, index) => {
                    if ((data.type === 2) &&
                        (data.content.type === 2 ||
                            data.content.type === 3 ||
                            data.content.type === 4 ||
                            data.content.type === 5)) {


                        element.push(
                            <div key={data._id}>
                                {index < contentsPerElo.length - 1 &&
                                    <img className="question-divider-image-width mt-4 mb-3" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img>
                                }
                                <LearningContent
                                    content={data}
                                    delegate={this}
                                    glossary={this.state.glossaryContent}
                                    groupTitle={groupTitle} showTitle={index == 0 ? true : false}
                                    categoryId={this.props.elo[0].categoryThemeId}
                                    contentObj={contentObj} showInactiveBookmark={bookMarkId ? false : true}
                                    showBookMark={bookMarkId ? true : false}
                                    bookMarkId={bookMarkId} selectedIndex={i} />
                                {index < contentsPerElo.length - 1 &&
                                    <img className="question-divider-image-width mt-5 mb-3" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img>
                                }
                            </div>
                        )
                    }
                }

                )
            }
            return element;
        }
    }


    videoPlayerRefs = {};

    viewAnswer = (item, index) => {
        item.showAnswer = true;
        this.setState({ showAnswer: true, showSolution: false })
    }

    viewSolution = (item, index) => {
        item.showSolution = true;
        this.setState({ showAnswer: false, showSolution: true })
    }

    renderReadOnlyItem(option) {
        return (
            <>
                {
                    option["answer"].contentType == Constants.CONTENT_TYPE_TEXT ?
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>
                        :
                        <div className="d-flex align-items-center justify-content-center">

                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>

                }
            </>

        )
    }

    getContentUsingContentType(option, type) {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (option[type].contentType == Constants.CONTENT_TYPE_TEXT) {
            return this.parser.parseWithStyles(`${option[type].content}`, { p: styles.p, 'cw-math': styles.p })
        } else if (option[type].contentType == Constants.CONTENT_TYPE_IMAGE) {
            //console.log(option[type].content)
            return <CWImageV2 uri={option[type].content}></CWImageV2>
        }

    }

    getCheckedStatus = (option) => {
        return option.isCorrect ? true : false
    }


    getBlankOptionValueDropDown(content) {

        let styles = {
            p: { fontSize: '23px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (content && content.items && content.items.length) {
            let correctItem = content.items.filter((item) => item.isCorrect);
            let correctAnswer = correctItem[0].content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");
            if (correctItem[0].content) {
                return this.parser.parseWithStyles(`${correctAnswer}`, { p: styles.p, "cw-math": styles.p })
            } else {
                return <></>
            }
        }
        return <></>
    }

    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer })
    }

    getJsxForBlankTypeDropDown(option, index) {

        //console.log(option, index, this.state.selectedDropDownIndex, this.props.isReadOnly)

        //console.log(this.state.actionSheet)

        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let assessmentBorder = this.theme[`secondaryColors`][`assessmentOptionsBorder`]

        return (
            <div className="position-relative" style={{}}>

                {option.selectedText ?
                    <>
                        <div className="row mb-5 mt-2 mr-0 ml-0" style={{}}>

                            <div className="blank-wrapper-1 padding-for-dropdown" style={{ border: `1px solid ${assessmentBorder}` }}>
                                <div className="dropdown-input">
                                    <div className="d-inline-block alphabet-option ml-2 mr-2">
                                        <div className="circle p-1" style={{ backgroundColor: this.theme['themeColor'] }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                    </div>
                                    <img className="option-separator-2" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />
                                    <div className="d-inline-block ml-2" style={{}}>
                                        {
                                            option.selectedText ?
                                                this.parser.parseWithStyles(`${option.selectedText.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
                                                : <span className="cw-input-for-dropdown" style={{}}>
                                                    {"Select One"}</span>
                                        }
                                    </div>

                                    {/* <div className="float-right mt-2" style={{}}>
                                        <img className="dropdown-arrow-size" src={getImageSource(ImageConstants.DROPDOWN_ARROW_BLACK)} />
                                    </div> */}
                                </div>

                            </div>


                            {/* </LinearGradient> */}
                        </div>
                        {this.state.actionSheet && <ActionSheet modal={this.state.actionSheet} toggleActionSheet={this.toggleActionSheet} options={this.state.dropDownData} onSelect={this.onSelect} categoryThemeId={this.props.categoryThemeId}></ActionSheet>}

                    </>


                    :
                    // <LinearGradient colors={Colors.appTheme.gradient1} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1.2 }} style={styles.linearGradiendCard}>
                    <div className="row  mb-5 mt-2 mr-0 ml-0 cursor-pointer" style={{}}>

                        <div className="blank-wrapper-1 padding-for-dropdown" style={{ border: `1px solid ${assessmentBorder}` }}>
                            <div className="dropdown-input">
                                <div className="d-inline-block alphabet-option ml-2 mr-2">
                                    <div className="circle p-1" style={{ backgroundColor: this.theme['themeColor'] }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                </div>
                                <img className="option-separator-2" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />
                                {
                                    // !this.props.isReadOnly ?
                                    //     <div className="d-inline-block ml-2" style={{}}>
                                    //         {
                                    //             option.selectedText ?
                                    //                 this.parser.parseWithStyles(`${option.selectedText.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
                                    //                 : <span className="cw-input-for-dropdown" style={{}}>
                                    //                     {"Select One"}</span>
                                    //         }
                                    //     </div>
                                    //     :
                                    <div className="d-inline-block ml-2" style={{}}>
                                        {this.getBlankOptionValueDropDown(option)}
                                    </div>
                                }

                                {/* <div className="float-right mt-2" style={{}}>
                                    <img className="dropdown-arrow-size" src={getImageSource(ImageConstants.DROPDOWN_ARROW_BLACK)} />
                                </div> */}
                            </div>

                        </div>


                        {/* </LinearGradient> */}
                    </div>
                }

            </div>

        )
    }

    getBlankOptionValue = (content) => {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (content && content.items && content.items.length) {
            let parsedJsx = this.parser.parseWithStyles(`${content.items[0].content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
            return (
                <div className="d-inline-block ml-2">
                    {parsedJsx}
                </div>
            )
        }
        return <></>
    }

    getJsxForBlank(option, index) {

        let assessmentBorder = this.theme[`secondaryColors`][`assessmentOptionsBorder`]


        return (
            <div className="">

                <div >
                    {/* <LinearGradient colors={Colors.appTheme.gradient1} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1.2 }} style={styles.blank}> */}

                    <div className="row  mb-5 mt-2 mr-0 ml-0">
                        <div className="blank-wrapper d-flex align-items-center" style={{ border: `1px solid ${assessmentBorder}` }}>
                            <div className="blank-option">
                                <div className="d-inline-block alphabet-option ml-2 mr-2">
                                    <div className="circle p-1" style={{ backgroundColor: this.theme['themeColor'] }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                </div>

                                <img className="option-separator" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />

                                {
                                    this.getBlankOptionValue(option)

                                }

                            </div>

                            {/* <TouchableOpacity onPress={() => this.textInput[index].focus()} style={styles.keyboard} >
                                <Image source={getThemeImageSource(ImageConstants.ASSESSMENT_KEYBOARD, this.props.categoryThemeId)} />
                            </TouchableOpacity> */}
                        </div>
                        {/* </LinearGradient> */}
                    </div>
                </div>
            </div >


        )
    }

    getContent(item) {


        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let content = item.content.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");

        content = content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");


        if (item.contentType == Constants.CONTENT_TYPE_TEXT) {
            return (
                this.parser.parseWithStyles(content.includes("<p>") ? `${content}` : `<p>${content}</p>`, { p: styles.p, 'cw-math': styles.p })
            )
        } else if (item.contentType == Constants.CONTENT_TYPE_IMAGE) {
            return (
                <CWImageV2 source={{ uri: item.content }} ></CWImageV2>
            )
        }
    }

    renderItem(item, index) {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]

        return (
            <div className="mb-3 mt-2 mr-0 ml-0" style={{}}>
                <div className="sequence-wrapper padding-for-dropdown" style={{ borderColor: borderColor }}
                    key={item.id}
                    id={item.id}>
                    <div className="dropdown-input cursor-pointer">
                        {/* <div className="d-inline-block alphabet-option mr-2">
                            <div className="circle p-1"><img className="drag-image" src={getThemeImageSource(ImageConstants.DRAG_ICON, this.props.categoryThemeId)} /></div>
                        </div> */}
                        {/* <img className="option-separator" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} /> */}

                        <div className="d-inline-block ml-2 p-2" style={{}}>
                            {
                                this.getContent(item)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getContentUsingContentTypeForGrouping(option, style) {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let content = option.content.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");

        content = content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");

        if (option.contentType == Constants.CONTENT_TYPE_TEXT) {
            return this.parser.parseWithStyles(`${content}`, { p: styles.p, 'cw-math': styles.p })
        } else if (option.contentType == Constants.CONTENT_TYPE_IMAGE) {
            return <CWImageV2 uri={option.content}></CWImageV2>
        }

    }

    renderReadOnlyItemForGrouping = (option) => {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]


        let options = option.items;
        return (
            options.map((option, i) => (
                <div className="answer-card d-flex align-items-center justify-content-center position-relative"
                    style={{ borderColor: borderColor }}>

                    {option.contentType == Constants.CONTENT_TYPE_TEXT ?
                        <div className="">
                            <div className="text-center">{this.getContentUsingContentTypeForGrouping(option, 'answer')}</div>
                        </div>
                        :
                        <div className="">
                            <div className="text-center">{this.getContentUsingContentTypeForGrouping(option, 'answer')}</div>
                        </div>

                    }
                </div>
            ))
        )
    }

    getContentTypeOptionsForMultiStep(content, questionNo) {
        //console.log(content)

        let isMultiStep = content.content.isMultiStep ? content.content.isMultiStep : false

        //console.log(content.content)

        if (isMultiStep) {
            return content.content.steps.map((content, index) => (
                <>
                    <div className='row mt-4 mb-4 align-items-center ml-2'>
                        <div className='col-1 part-title' style={{ backgroundColor: this.theme[`themeColor`] }}>
                            {questionNo + 1} {CommonUtils.getAlphabet(index)}
                        </div>
                        <div className='col part-question'>
                            <CwContentViewer content={content.content.title} />
                        </div>
                    </div>
                    {content.content.images && content.content.images.length ? content.content.images.map((image, imageIndex) =>
                        <img className='image-container mb-4 mr-3' src={image.imageUrl} alt="" />
                    )
                        :
                        ""}
                    {this.renderContentType(content.content.type, content)}
                    <img className="question-divider-image-width mt-4 mb-4" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)}></img>
                </>
            ))
        }
    }





    renderContentType(contentType, content) {
        //console.log(this.props.content)
        let textColor = this.theme['themeColor']
        let borderColor = this.theme[`themeColor`]
        let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }


        // let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        let linearGradientBg = `linear-gradient(${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][0]}, ${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][1]})`

        // let content = this.props.content
        switch (contentType) {
            case 1:
                return (
                    <div className="options">
                        <RadioGroup options={content.content.options} isReadOnly={true} categoryThemeId={this.props.categoryThemeId} />
                    </div>
                )

            case 2:
                return (
                    <div className="option-wrapper d-flex flex-column mt-3 justify-content-between">
                        {content.content.options && content.content.options.map((option, index) => (
                            <div className="option-border"
                                style={{ borderColor: this.theme ? `${this.theme['secondaryColors']['assessmentOptionsBorder']}` : '#353535' }}
                                key={option.id}>
                                <Checkbox label={option.content} isChecked={this.getCheckedStatus(option)} contentType={option.contentType ? option.contentType : 1} categoryThemeId={this.props.categoryThemeId} />
                            </div>
                        ))}

                    </div>
                )

            case 3:
                return (
                    <div className="">

                        {content.content.options && content.content.options.length && content.content.options.map((option, index) => (
                            <React.Fragment key={'fillInTheBlank' + index}>
                                {option.contentType == 3 ?
                                    this.getJsxForBlank(option, index)
                                    : this.getJsxForBlankTypeDropDown(option, index)
                                }
                            </React.Fragment>
                        ))}
                    </div>
                )

            case 4:
                return (
                    <div className="matching-question-container mt-4">
                        {content.content.options.map((option, index) =>
                            <div className="matching-question d-flex flex-row align-items-center mt-3" key={option.id + 'answer'}>

                                <div className="question-card d-flex align-items-center justify-content-center" style={{ borderColor: dragItemBorder }}>
                                    {
                                        option["question"].contentType == Constants.CONTENT_TYPE_TEXT ?
                                            <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                            :
                                            <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                    }

                                </div>
                                <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                <div className={`question-card d-flex align-items-center justify-content-center position-relative`} style={{ borderColor: dragItemBorder }}>
                                    {
                                        this.renderReadOnlyItem(option)
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                )

            case 5:
                return (
                    <div className="options">
                        {
                            content.content.options.map((item) => this.renderItem(item))
                        }
                    </div>
                )

            case 6:
                return (
                    <div className="grouping-question-type-read-only">
                        <div className="matching-question-container d-flex flex-column mt-4">
                            {content.content.options.map((option, index) => (
                                <div key={"dropZone" + index} className="drop-zone mt-3" style={{ backgroundImage: linearGradientBg, borderColor: dragItemBorder }}>
                                    <div className="text-center p-1 drop-zone-title" style={{ background: dragItemBorder }}>
                                        {this.parser.parseWithStyles(`<p>${option.title}</p>`, { p: styles.p, 'cw-math': styles.p })}
                                    </div>
                                    <div className="d-flex flex-row answer-wrapper justify-content-center">
                                        {
                                            this.renderReadOnlyItemForGrouping(option)
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                )

        }
    }

    hideQuestionPopup() {
        this.props.hideQuestionPopup()
    }


    render() {


        let selectedButtonBg = this.theme[`themeColor`]

        return (

            <Modal className="cw-custom-modal-right-for-view-Solution"
                toggle={() => this.hideQuestionPopup()}
                backdrop={false} fade={true}
                isOpen={true} size={'lg'}>
                <div className="pt-4 my-auto text-right"><img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.CROSS)} onClick={() => this.hideQuestionPopup()} /></div>
                <div className="abs-image">
                    <img className='header-image' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_POPUP_HEADER, this.props.categoryThemeId)} />
                </div>

                <ModalBody>
                    {/* <div className='d-flex justify-content-between'>
                       <div className='d-inline-flex content-width'>
                           <span className='mr-2 sr-no'>{`${this.props.index + 1}. `}</span>
                           <CwContentViewer content={this.props.content.question.title}></CwContentViewer>
                       </div>
                       <div className='mr-5'><img className='bookmark' src={getImageSource(ImageConstants.BOOKMARK_ICON)}></img></div>
                   </div>

                   {this.props.content.question.images && this.props.content.question.images.length ?
                       <div className="mb-3">
                           <QuestionImage images={this.props.content.question.images} index={this.props.index} />
                       </div>
                       :
                       ''
                   }

                   <img className="question-divider-image-width mb-3 mt-2" src={getImageSource(ImageConstants.QUESTION_DIVIDER)}></img> */}


                    {/* <div className="d-flex justify-content-center align-items-center">
                       <div>
                           {
                               this.props.answerStatus === "CORRECT_ANSWER" ?  
                               <img className="image-size" src={getImageSource(ImageConstants.CORRECT_ANSWER_TICK_MARK)} />
                               : this.props.answerStatus === "INCORRECT_ANSWER" || this.props.answerStatus === "NOT_ANSWERED" ?
                               <img className="image-size" src={getImageSource(ImageConstants.WRONG_ANSWER_CROSS_MARK)} />
                               : <></>
                           }
                       </div>
                       <div className="answer-status-text">
                           {
                               this.props.answerStatus === "CORRECT_ANSWER" ? "This is Correct Answer" 
                               : this.props.answerStatus === "INCORRECT_ANSWER" ? "This is Incorrect Answer" 
                               : this.props.answerStatus === "NOT_ANSWERED" ? "Not Answered" : ""
                           }
                       </div>
                   </div> */}

                    <div>

                    </div>

                    <div className="scroll-bar">
                        <div className="d-flex row-width justify-content-center align-content-center">
                            {/* {
                           this.state.showAnswer && this.props.content.content.showAnswer ?
                               <div className={`col view-button pt-2 text-center  border-top-right-radius`}
                                   style={{ backgroundColor: `${selectedButtonBg}`, color: '#FFFFFF' }}>{`View Answer`}</div>
                               :
                               <div className="col view-button pt-2 text-center " onClick={() => this.viewAnswer(this.props.content.content, this.props.index)}>
                                   View Answer
                               </div>
                       } */}
                            {
                                this.state.showSolution && this.props.content.content.showSolution ?
                                    <div className="col view-button pt-2 text-center"
                                        style={{ backgroundColor: `${selectedButtonBg}`, color: '#FFFFFF' }}>Solution</div>
                                    :
                                    <div className="col view-button pt-2 text-center " onClick={() => this.viewSolution(this.props.content.content, this.props.index)}>
                                        Solution
                                    </div>
                            }
                        </div>

                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img>

                        {!this.props.isCheckPoint && <QuestionTitle title={this.props.content.content.title}
                            hideBackground={this.props.isMultiStep ? true : false}
                            categoryThemeId={this.props.categoryThemeId}
                            isAssessment={this.props.isAssessment}
                            delegate={this.props.delegate} />}
                        {!this.props.isCheckPoint && <QuestionImage images={this.props.content.content.images} categoryThemeId={this.props.categoryThemeId} />}

                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img>
                        {this.state.showAnswer && this.props.content.content.showAnswer ?
                            // <div className="mt-3 view-answer" style={{ backgroundColor: `${this.theme[`learningContentBackground`]}` }}>
                            <div className="mt-3 view-answer-1" >
                                {/* <CwContentViewer content={this.props.content.question.answer}></CwContentViewer> */}
                                {this.props.content.content.isMultiStep ? this.getContentTypeOptionsForMultiStep(this.props.content, this.props.index) : this.renderContentType(this.props.content.content.type, this.props.content)}
                            </div>
                            : <></>
                        }



                        {this.state.showSolution && this.props.content.content.showSolution ?
                            <div className="mt-3 view-solution">
                                {/* <CwContentViewer content={this.props.content.content.solutionSteps}></CwContentViewer> */}
                                <SummarySoultions forQuestion={true} solutionSteps={this.props.content.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions>
                            </div>
                            : <></>
                        }

                        {/* {
                       this.state.elo.length > 0 ? <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img> : <></>
                   } */}

                        {
                            this.state.showThingsToRememberBar ?
                                <div className="d-flex row-width mb-3 justify-content-center align-content-center">
                                    {/* {
                           this.state.showAnswer && this.props.content.content.showAnswer ?
                               <div className={`col view-button pt-2 text-center  border-top-right-radius`}
                                   style={{ backgroundColor: `${selectedButtonBg}`, color: '#FFFFFF' }}>{`View Answer`}</div>
                               :
                               <div className="col view-button pt-2 text-center " onClick={() => this.viewAnswer(this.props.content.content, this.props.index)}>
                                   View Answer
                               </div>
                       } */}

                                    <div className="col view-button pt-2 text-center"
                                        style={{ backgroundColor: `${selectedButtonBg}`, color: '#FFFFFF' }}>Things To Remember</div>

                                </div>
                                :
                                <></>
                        }





                        {
                            this.state.showLearningContent ? this.renderLearningContent() : <></>
                        }


                    </div>




                </ModalBody>
            </Modal>
        )
    }

}


export default PracticeQuestionModal;
import React, { Component } from 'react';
import './approach-to-problem-solving.scss';
import Loading from '../../../common/loading/loading';
import SharedProfile from '../../../../../shared-storage/profile';
import { getImageSource, getThemeImageSource } from '../../../../utility/image-source';
import { ImageConstants } from '../../../../constants/constants';
import { CircularProgressbar } from 'react-circular-progressbar';
import SharedStaticText from '../../../../../shared-storage/static-text-store';
import ExamPrepKitApi from '../../../../apis/exam-prep-kit';
import ApproachToProblemSolvingExperience from '../approach-to-problem-solving-experience/approach-to-problem-solving-experience';


export default class ApproachToProblemSolving extends Component {
    constructor(props) {
        super(props);
        // const { navigation } = this.props;
        // this.meta = navigation.getParam('meta');
        this.meta = this.props.metaInfo

        this.state = {
            thingsToRememberList: [
                {
                    title: "Calculation",
                    icon: getImageSource(ImageConstants.CALCULATION),
                    percentage: 0,
                    type: "CALCULATION",
                    skill: 'Calculation'
                },
                {
                    title: "Strategy Problems",
                    icon: getImageSource(ImageConstants.MODELING),
                    percentage: 20,
                    type: "STRATEGY_PROBLEMS",
                    skill: 'Modelling'
                },
                {
                    title: "Word Problems",
                    icon: getImageSource(ImageConstants.COMPREHENSION),
                    percentage: 30,
                    type: "APPROACH_TO_PROBLEM_SOLVING",
                    skill: 'Comprehension'
                }
            ],
            loading: true
        };
        this.userInfo = SharedProfile.getProfile();
        this.staticText = SharedStaticText.getStaticText('approachToProblemSolving');


        // this.props.navigation.addListener('didFocus', (data) => {
        this.approachToProblemSolvingProgress()
        // });
    }

    goBack = () => {
        this.props.navigation.goBack();
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    onPressAction = (item) => {

        if (item.type == 'CALCULATION') {
            this.showApproachToProblemSolvingExperience({ meta: {
                title: this.meta.title,
                headerTitle: item.title,
                chapterKey: this.meta.chapterKey,
                skill: item.skill,
                headerIcon: getImageSource(ImageConstants.CALCULATION),
                from: item.type,
                resumeIndex: item.resumeId ? item.resumeId : 0,
                origin: "APPROACH_TO_PROBLEM_SOLVING"
            }})
            // this.props.navigation.navigate("ApproachToProblemSolvingExperience", {
            //     meta: {
            //         title: this.meta.title,
            //         headerTitle: item.title,
            //         chapterKey: this.meta.chapterKey,
            //         skill: item.skill,
            //         headerIcon: getImageSource(ImageConstants.CALCULATION),
            //         from: item.type,
            //         resumeIndex: item.resumeId ? item.resumeId : 0,
            //         origin: "APPROACH_TO_PROBLEM_SOLVING"
            //     }
            // })
        }

        if (item.type == 'STRATEGY_PROBLEMS') {
            this.showApproachToProblemSolvingExperience({ meta: {
                title: this.meta.title,
                headerTitle: item.title,
                chapterKey: this.meta.chapterKey,
                skill: item.skill,
                headerIcon: getImageSource(ImageConstants.MODELING),
                from: "MODELING",
                resumeIndex: item.resumeId ? item.resumeId : 0,
                origin: "APPROACH_TO_PROBLEM_SOLVING"
            }})
            // this.props.navigation.navigate("ApproachToProblemSolvingExperience", {
            //     meta: {
            //         title: this.meta.title,
            //         chapterKey: this.meta.chapterKey,
            //         skill: item.skill,
            //         headerIcon: getImageSource(ImageConstants.MODELING),
            //         from: "MODELING",
            //         resumeIndex: item.resumeId ? item.resumeId : 0,
            //         origin: "APPROACH_TO_PROBLEM_SOLVING"
            //     }
            // })
        }

        if (item.type == 'APPROACH_TO_PROBLEM_SOLVING') {
            this.showApproachToProblemSolvingExperience({ meta: {
                title: this.meta.title,
                headerTitle: item.title,
                chapterKey: this.meta.chapterKey,
                skill: item.skill,
                headerIcon: getImageSource(ImageConstants.COMPREHENSION),
                from: "COMPREHENSION",
                resumeIndex: item.resumeId ? item.resumeId : 0,
                origin: "APPROACH_TO_PROBLEM_SOLVING"
            }})
            // this.props.navigation.navigate("ApproachToProblemSolvingExperience", {
            //     meta: {
            //         title: this.meta.title,
            //         headerTitle: item.title,
            //         chapterKey: this.meta.chapterKey,
            //         skill: item.skill,
            //         headerIcon: getImageSource(ImageConstants.COMPREHENSION),
            //         from: "COMPREHENSION",
            //         resumeIndex: item.resumeId ? item.resumeId : 0,
            //         origin: "APPROACH_TO_PROBLEM_SOLVING"
            //     }
            // })
        }
    }

    showApproachToProblemSolvingExperience(data) {
        this.setState({showApproachToProblemSolvingExperience : true, ...data})
    }

    hideApproachToProblemSolvingExperience() {
        this.setState({showApproachToProblemSolvingExperience : false, meta : {}})
    }

    approachToProblemSolvingProgress = () => {

        let payload = {
            chapterKey: this.meta.chapterKey
        }

        this.setState({ loading: true })

        ExamPrepKitApi.getApproachToProblemSolvingProgress(payload).then((res) => {

            this.setState({ loading: false })
            if (res) {

                let thingsToRememberList = this.state.thingsToRememberList

                for (let i = 0; i < thingsToRememberList.length; i++) {

                    let item = thingsToRememberList[i]

                    if (item.type == 'CALCULATION' && res.calculation) {
                        item.percentage = res.calculation.percentage
                        item.numberOfContent = res.calculation.numberOfContent
                        item.resumeId = res.calculation.resumeId
                    }

                    if (item.type == 'STRATEGY_PROBLEMS' && res.modelling) {
                        item.percentage = res.modelling.percentage
                        item.numberOfContent = res.modelling.numberOfContent
                        item.resumeId = res.modelling.resumeId
                    }

                    if (item.type == 'APPROACH_TO_PROBLEM_SOLVING' && res.comprehension) {
                        item.percentage = res.comprehension.percentage
                        item.numberOfContent = res.comprehension.numberOfContent
                        item.resumeId = res.comprehension.resumeId
                    }
                }

                // console.log(thingsToRememberList)
                this.setState({ thingsToRememberList })
            }
        }).catch(err => {
            this.setState({ loading: false })
            console.log(err);
            if (err) {
                return;
            }
        })
    }

    onClickLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu', { meta: { from: 'PREPKIT' } })
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToExamPrepKit() {
        this.props.delegate && this.props.delegate.goToExamPrepKit && this.props.delegate.goToExamPrepKit()
    }

    goToExamPrepKitChapterList() {
        this.props.showPrepKitChapterList && this.props.showPrepKitChapterList()
    }

    goBackToTableOfContents() {
        this.props.delegate && this.props.delegate.hideApprochToProblemSolving && this.props.delegate.hideApprochToProblemSolving()
    }


    renderThingsToRemember = () => {

        let elements = []

        for (let i = 0; i < this.state.thingsToRememberList.length; i++) {

            let item = this.state.thingsToRememberList[i]

            if (item.numberOfContent) {
                elements.push(
                    <div onClick={() => this.onPressAction(item)}
                        className='d-flex align-items-center justify-content-between chapter-card pl-0 pb-3 pt-3 cursor-pointer'>
                        <div className='d-flex align-items-center'>
                            <div className='mr-2' style={{ width: '30px', height: '30px' }}>
                                <CircularProgressbar value={item.percentage && item.percentage > 0 ? item.percentage : 0}
                                    strokeWidth={15}
                                />
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='mr-2'>
                                    <img className='chapter-icon' src={item.icon}></img>
                                </div>
                                <div>
                                    <div className='table-of-content-title'>{item.title}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img className='arrow-image-size' src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                        </div>
                    </div>
                )
            }
        }

        return elements
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToExamPrepKit()} >{`Prepare`}{` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToTableOfContents()}>{`${this.meta.title}`} {` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToTableOfContents()}>{`${`Table of Contents`}`} {` > `}</span>
                <span className='title-1 pl-2' >{`Approach to Problem Solving`}</span>
            </div>
        )
    }

    render() {

        if(this.state.showApproachToProblemSolvingExperience) {
            return <ApproachToProblemSolvingExperience delegate = {this} metaInfo = {this.state.meta} />
        }


        return (
            <>
                {!this.state.loading ?
                    <div className='approach-to-problem-solving'>
                        {this.renderBreadcrumbForChapterList()}
                        <div>
                            <img className='header-image-size' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, 1)} />
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='mr-2'><img className='things-to-remember-icon' src={getImageSource(ImageConstants.THINGS_TO_REMEMBER_ICON)} /></div>
                            <div className='d-flex flex-column'>
                                <div className='header-text'>Approach to Problem Solving</div>
                                <div className='chapter-title'>{this.meta.title}</div>
                            </div>
                        </div>
                        <div className='mb-2'>
                            <img className='divider-image-width' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, 1)} />
                        </div>
                        <div className='ttr-wrapper'>
                            {this.renderThingsToRemember()}
                        </div>
                        <div className='notes d-flex align-items-center mt-5 mb-5'>
                            <div>
                                <img className='info-icon' src={getImageSource(ImageConstants.INFO)}></img>
                            </div>
                            <div className='ml-2'>
                                <div className='notes-text'>
                                    {this.staticText.note}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <Loading />
                }

            </>

        )
    }

}


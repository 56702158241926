import React from "react";
import './question.scss';
import { Constants } from "../../../constants/constants";
import SingleSelect from "./options/single-select/single-select";
import MultiSelect from "./options/multi-select/multi-select";
import FillInTheBlank from "./options/fill-in-the-blank/fill-in-the-blank";
import Sequencing from "./options/sequence/sequencing";
import Matching from "./options/matching/matching";
import Grouping from "./options/grouping/grouping";
import MultiStep from "./options/multi-step-questions/multi-step-questions";
import Descriptive from "./options/descriptive/descriptive";



class Question extends React.Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {

    }

    getOption(type, content) {

        if (content.content && content.content.isMultiStep) {
            return (
                <MultiStep
                    content={JSON.parse(JSON.stringify(content))}
                    categoryThemeId={this.props.categoryThemeId}
                    answer={this.props.answer}
                    status={this.props.status}
                    questionNumber={this.props.questionNumber}
                    origin={this.props.origin}
                />);
        }

        switch (type) {

            case Constants.SIGNLE_SELECT:
                return <SingleSelect content={content} categoryThemeId={this.props.categoryThemeId} isMultiStep={this.props.isMultiStep ? true : false} answer={this.props.answer} status={this.props.status} />

            case Constants.MULTI_SELECT:
                return <MultiSelect content={content} categoryThemeId={this.props.categoryThemeId} isMultiStep={this.props.isMultiStep ? true : false} answer={this.props.answer} status={this.props.status} />

            case Constants.FILL_IN_THE_BLANK:
                return <FillInTheBlank content={content} categoryThemeId={this.props.categoryThemeId} isMultiStep={this.props.isMultiStep ? true : false} answer={this.props.answer} status={this.props.status} />

            case Constants.MATCHING:
                return <Matching content={content} categoryThemeId={this.props.categoryThemeId} isMultiStep={this.props.isMultiStep ? true : false} answer={this.props.answer} status={this.props.status} />

            case Constants.SEQUENCE:
                return <Sequencing content={content} categoryThemeId={this.props.categoryThemeId} isMultiStep={this.props.isMultiStep ? true : false} answer={this.props.answer} status={this.props.status} />

            case Constants.GROUPING:
                return <Grouping content={content} categoryThemeId={this.props.categoryThemeId} isMultiStep={this.props.isMultiStep ? true : false} answer={this.props.answer} status={this.props.status} />

            case Constants.DESCRIPTIVE:
                return <Descriptive content={content} categoryThemeId={this.props.categoryThemeId} isMultiStep={this.props.isMultiStep ? true : false} answer={this.props.answer} status={this.props.status} />


            // case Constants.LABELING:
            //     return <Labelling content={content} categoryThemeId={this.props.categoryThemeId} isMultiStep={this.props.isMultiStep ? true : false} answer={this.props.answer} status={this.props.status} />

            // case Constants.GEOMETRY_QUESTION:
            //     if (content.content.canvas.type == 'CANVAS_CONSTRUCTIVE')
            //         return <GeometryQuestionContructive content={content} categoryThemeId={this.props.categoryThemeId} isMultiStep={this.props.isMultiStep ? true : false} answer={this.props.answer} status={this.props.status} origin ={this.props.origin} />
            //     else
            //         return <GeometryQuestion content={content} categoryThemeId={this.props.categoryThemeId} isMultiStep={this.props.isMultiStep ? true : false} answer={this.props.answer} status={this.props.status} />


            default:
                return <></>
        }

    }

    render() {

        let content = this.props.content;

        return (
            <div className="question-wrapper">
                {this.getOption(content.content.type, content)}
            </div>
        );
    }
}

export default Question;

import React from "react";
import './clicker-flow-practice.scss';
import StartAssessment from "./start-assessment/start-assessment";
import HomeworkApi from "../../apis/homework";
import StandardClassWorkExperience from './clicker-practice-experience/standard-classwork-experience'
import LayoutShared from "../common/layout-shared/layout-shared";
import BarChart from "./bar-chart/bar-chart";
import SharedProfile from "../../../shared-storage/profile";
import ClickerFlowPracticeStat from "./clicker-flow-practice-stat/clicker-flow-practice-stat";

class ClickerFlowPractice extends React.Component {
    state = {
        // usersList: [
        //     {
        //         teacherUserId: '659b90fa21cb4f53081b46a9',
        //         studentNumber: '100',
        //         userDeviceMacId: 'bf:fc:e2:0e:93:e7',
        //         receivedAnswer: false,
        //         isTeacher: true,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a7',
        //         studentNumber: '21',
        //         userDeviceMacId: '58:2f:be:86:1c:ee',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a8',
        //         studentNumber: '22',
        //         userDeviceMacId: 'ef:3f:4d:11:ef:c7',
        //         receivedAnswer: false,
        //     }
        // ],
        // Production Users List
        usersList : [{
            "userId": "671a31fc45f38957afbf8d67",
            "userName": "Student 1",
            "studentNumber": "1",
            "userDeviceMacId": "54:a4:44:9d:04:db",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d68",
            "userName": "Student 2",
            "studentNumber": "2",
            "userDeviceMacId": "af:35:3a:33:c6:f8",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d69",
            "userName": "Student 3",
            "studentNumber": "3",
            "userDeviceMacId": "ca:b8:60:1f:ce:e0",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d6a",
            "userName": "Student 4",
            "studentNumber": "4",
            "userDeviceMacId": "94:29:50:da:71:ea",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d6b",
            "userName": "Student 5",
            "studentNumber": "5",
            "userDeviceMacId": "b5:de:e9:93:c5:c2",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d6c",
            "userName": "Student 6",
            "studentNumber": "6",
            "userDeviceMacId": "c9:ee:61:02:61:d8",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d6d",
            "userName": "Student 7",
            "studentNumber": "7",
            "userDeviceMacId": "28:56:46:47:b8:f4",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d6e",
            "userName": "Student 8",
            "studentNumber": "8",
            "userDeviceMacId": "98:95:c3:0d:4b:fa",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d6f",
            "userName": "Student 9",
            "studentNumber": "9",
            "userDeviceMacId": "39:76:8d:c4:b5:e3",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d70",
            "userName": "Student 10",
            "studentNumber": "10",
            "userDeviceMacId": "ba:97:f2:01:91:ce",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d71",
            "userName": "Student 11",
            "studentNumber": "11",
            "userDeviceMacId": "05:d0:84:1f:a9:c9",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d72",
            "userName": "Student 12",
            "studentNumber": "12",
            "userDeviceMacId": "bd:53:3b:30:15:ed",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d73",
            "userName": "Student 13",
            "studentNumber": "13",
            "userDeviceMacId": "7a:89:0f:87:42:eb",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d74",
            "userName": "Student 14",
            "studentNumber": "14",
            "userDeviceMacId": "72:87:f4:33:b9:d8",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d75",
            "userName": "Student 15",
            "studentNumber": "15",
            "userDeviceMacId": "09:65:11:75:bd:de",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d76",
            "userName": "Student 16",
            "studentNumber": "16",
            "userDeviceMacId": "d1:e6:91:a3:2e:dd",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d77",
            "userName": "Student 17",
            "studentNumber": "17",
            "userDeviceMacId": "0f:0f:a4:5f:16:ca",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d78",
            "userName": "Student 18",
            "studentNumber": "18",
            "userDeviceMacId": "2c:25:e7:8b:5d:df",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d79",
            "userName": "Student 19",
            "studentNumber": "19",
            "userDeviceMacId": "67:2a:1b:fb:c0:c4",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d7a",
            "userName": "Student 20",
            "studentNumber": "20",
            "userDeviceMacId": "07:70:cf:1c:99:c2",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d7b",
            "userName": "Student 21",
            "studentNumber": "21",
            "userDeviceMacId": "71:6b:5c:79:af:de",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d7c",
            "userName": "Student 22",
            "studentNumber": "22",
            "userDeviceMacId": "7c:9f:d2:4d:d5:f0",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d7d",
            "userName": "Student 23",
            "studentNumber": "23",
            "userDeviceMacId": "87:f2:8a:eb:87:e2",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d7e",
            "userName": "Student 24",
            "studentNumber": "24",
            "userDeviceMacId": "50:1f:c0:c5:13:cf",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d7f",
            "userName": "Student 25",
            "studentNumber": "25",
            "userDeviceMacId": "b6:a8:b6:a0:d8:cf",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d80",
            "userName": "Student 26",
            "studentNumber": "26",
            "userDeviceMacId": "c0:35:78:05:f6:c5",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d81",
            "userName": "Student 27",
            "studentNumber": "27",
            "userDeviceMacId": "3a:65:2e:a2:b3:dd",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d82",
            "userName": "Student 28",
            "studentNumber": "28",
            "userDeviceMacId": "75:63:ec:24:e0:cc",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d83",
            "userName": "Student 29",
            "studentNumber": "29",
            "userDeviceMacId": "59:50:db:fe:cc:f2",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d84",
            "userName": "Student 30",
            "studentNumber": "30",
            "userDeviceMacId": "2c:d7:b5:ce:96:d1",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d85",
            "userName": "Student 31",
            "studentNumber": "31",
            "userDeviceMacId": "a1:32:8e:46:86:dd",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d86",
            "userName": "Student 32",
            "studentNumber": "32",
            "userDeviceMacId": "2e:16:82:81:bf:eb",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d87",
            "userName": "Student 33",
            "studentNumber": "33",
            "userDeviceMacId": "18:47:c4:b0:26:e1",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d88",
            "userName": "Student 34",
            "studentNumber": "34",
            "userDeviceMacId": "15:e4:08:9e:1b:c5",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d89",
            "userName": "Student 35",
            "studentNumber": "35",
            "userDeviceMacId": "fa:6f:7e:d5:48:e8",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d8a",
            "userName": "Student 36",
            "studentNumber": "36",
            "userDeviceMacId": "81:9f:ab:34:7f:c3",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d8b",
            "userName": "Student 37",
            "studentNumber": "37",
            "userDeviceMacId": "35:41:d1:5e:1c:f2",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d8c",
            "userName": "Student 38",
            "studentNumber": "38",
            "userDeviceMacId": "aa:2f:1c:f8:74:e1",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d8d",
            "userName": "Student 39",
            "studentNumber": "39",
            "userDeviceMacId": "75:91:3e:58:db:cb",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d8e",
            "userName": "Student 40",
            "studentNumber": "40",
            "userDeviceMacId": "b8:21:af:b5:9d:eb",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d85",
            "userName": "Student 41",
            "studentNumber": "41",
            "userDeviceMacId": "e2:bc:8d:99:bb:c8",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d86",
            "userName": "Student 42",
            "studentNumber": "42",
            "userDeviceMacId": "f2:bd:da:df:a0:ee",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d87",
            "userName": "Student 43",
            "studentNumber": "43",
            "userDeviceMacId": "e8:10:e8:dc:7c:d8",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d88",
            "userName": "Student 44",
            "studentNumber": "44",
            "userDeviceMacId": "a3:1a:10:8b:9f:e7",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d89",
            "userName": "Student 45",
            "studentNumber": "45",
            "userDeviceMacId": "55:51:40:63:7a:d1",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d8a",
            "userName": "Student 46",
            "studentNumber": "46",
            "userDeviceMacId": "c0:ac:fd:0f:29:d2",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d8b",
            "userName": "Student 47",
            "studentNumber": "47",
            "userDeviceMacId": "7d:82:37:e4:e8:f4",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d8c",
            "userName": "Student 48",
            "studentNumber": "48",
            "userDeviceMacId": "e6:e7:f5:93:c0:fc",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d8d",
            "userName": "Student 49",
            "studentNumber": "49",
            "userDeviceMacId": "90:5e:e6:2e:82:eb",
            "receivedAnswer": false
        },
        {
            "userId": "671a31fc45f38957afbf8d8e",
            "userName": "Student 50",
            "studentNumber": "50",
            "userDeviceMacId": "ca:b8:60:1f:ce:e0",
            "receivedAnswer": false
        },
         {
            "teacherUserId": "659b90fa21cb4f53081b46a9",
            "studentNumber": "100",
            "userDeviceMacId": "d0:f7:26:8a:9d:c0",
            "receivedAnswer": false,
            "isTeacher": true
        }
        ],
        showStartAssessment: true,
        showBarChart: false,
        content: [
            {
                "_id": "66f2a3b81deacfba3822d9e6",
                "userId": "659b90fa21cb4f53081b46a9",
                "transferKey": "USER_BULK_IMPORT_8_1_2024",
                "schoolId": "SCX-004",
                "board": "CBSE",
                "class": "Class 6",
                "masterDataId": "630ee6107dc02179c069fbef",
                "homeworkId": "66f2a3b81deacfba3822d9af",
                "chapterKey": "16619207846261523",
                "startTime": null,
                "finishTime": null,
                "section": null,
                "sectionId": "",
                "type": "STANDARD",
                "status": "ASSIGNED",
                "streamingStatus": "NOVICE",
                "countingwellCreatedTimestamp": "2024-09-24T11:34:16.680Z",
                "countingwellLastUpdateTimestamp": "2024-09-24T11:34:16.680Z",
                "chapterTitle": "Integers (H)",
                "categoryId": "1",
                "chapterImageUrl": "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/dcb376d1-ec26-4b60-8c51-d45a48725973-Integers.png",
                "homeworkStatus": "ACTIVE",
                "approximateTime": 20,
                "approximateQuestions": 11,
                "createdDate": "2024-09-24T11:34:16.463Z",
                "endTime": "2024-09-30T12:59:59.999Z",
                "createdBy": "659b84e121cb4f53081b3f99",
                "remainingDaysToComplete": 7,
                "conceptsCovered": [
                    {
                        "key": "15949704148156387",
                        "elos": [
                            {
                                "id": "630ee6919a43e17a0c3c3935",
                                "title": "Add Integers Using a Number Line"
                            },
                            {
                                "id": "630ee6919a43e17a0c3c3936",
                                "title": "Define and Find the Additive Inverse of an Integer"
                            },
                            {
                                "id": "630ee6919a43e17a0c3c3938",
                                "title": "Addition of Integers"
                            },
                            {
                                "id": "630ee6919a43e17a0c3c393a",
                                "title": "Subtraction of Integers"
                            },
                            {
                                "id": "630ee6919a43e17a0c3c393b",
                                "title": "Simplify Integer Expressions Involving Addition and Subtraction"
                            },
                            {
                                "id": "630ee6919a43e17a0c3c3939",
                                "title": "Subtract Integers Using a Number Line"
                            }
                        ],
                        "title": "Operations on Integers"
                    }
                ]
            },
            {
                "_id": "66f2a3b81deacfba3822d9e6",
                "userId": "659b90fa21cb4f53081b46a9",
                "transferKey": "USER_BULK_IMPORT_8_1_2024",
                "schoolId": "SCX-004",
                "board": "CBSE",
                "class": "Class 10",
                "masterDataId": "668549eb68b8f80eb2ba178c",
                "homeworkId": "66f7fc0df54faa1ce38aa50f",
                "chapterKey": "17211962915345747",
                "startTime": null,
                "finishTime": null,
                "section": null,
                "sectionId": "",
                "type": "STANDARD",
                "status": "ASSIGNED",
                "streamingStatus": "NOVICE",
                "countingwellCreatedTimestamp": "2024-09-24T11:34:16.680Z",
                "countingwellLastUpdateTimestamp": "2024-09-24T11:34:16.680Z",
                "chapterTitle": "Arithmetic Progressions",
                "categoryId": "1",
                "chapterImageUrl": "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/dcb376d1-ec26-4b60-8c51-d45a48725973-Integers.png",
                "homeworkStatus": "ACTIVE",
                "approximateTime": 20,
                "approximateQuestions": 10,
                "createdDate": "2024-09-24T11:34:16.463Z",
                "endTime": "2024-09-30T12:59:59.999Z",
                "createdBy": "659b84e121cb4f53081b3f99",
                "remainingDaysToComplete": 7,
                "conceptsCovered": [
                    {
                        "key": "15949704148156387",
                        "elos": [
                            {
                                "id": "630ee6919a43e17a0c3c3935",
                                "title": "Add Integers Using a Number Line"
                            },
                            {
                                "id": "630ee6919a43e17a0c3c3936",
                                "title": "Define and Find the Additive Inverse of an Integer"
                            },
                            {
                                "id": "630ee6919a43e17a0c3c3938",
                                "title": "Addition of Integers"
                            },
                            {
                                "id": "630ee6919a43e17a0c3c393a",
                                "title": "Subtraction of Integers"
                            },
                            {
                                "id": "630ee6919a43e17a0c3c393b",
                                "title": "Simplify Integer Expressions Involving Addition and Subtraction"
                            },
                            {
                                "id": "630ee6919a43e17a0c3c3939",
                                "title": "Subtract Integers Using a Number Line"
                            }
                        ],
                        "title": "Operations on Integers"
                    }
                ]
            },
            {
                "_id": "671f3dc785c9a6272ae247be",
                "userId": "659b90fa21cb4f53081b473d",
                "transferKey": "USER_BULK_IMPORT_8_1_2024",
                "schoolId": "SCX-004",
                "board": "CBSE",
                "class": "Class 9",
                "masterDataId": "630ee63c7dc02179c069fbf5",
                "homeworkId": "671f3dc685c9a6272ae24787",
                "chapterKey": "16619208289910326",
                "startTime": null,
                "finishTime": null,
                "section": "A",
                "sectionId": "659b87aaa235f61528427b6e",
                "type": "STANDARD",
                "status": "ASSIGNED",
                "streamingStatus": "NOVICE",
                "countingwellCreatedTimestamp": "2024-10-28T07:31:19.153Z",
                "countingwellLastUpdateTimestamp": "2024-10-28T07:31:19.153Z",
                "chapterTitle": "Quadrilaterals (H)",
                "categoryId": "3",
                "homeworkStatus": "ACTIVE",
                "approximateTime": 20,
                "approximateQuestions": 10,
                "createdDate": "2024-10-28T07:31:18.939Z",
                "endTime": "2024-11-04T12:59:59.999Z",
                "createdBy": "659b84e121cb4f53081b3f99",
                "remainingDaysToComplete": 8,
                "conceptsCovered": [
                    {
                        "key": "16461584726301467",
                        "elos": [
                            {
                                "id": "630ee6919a43e17a0c3c3c43",
                                "title": "Find the missing angles of a quadrilateral"
                            }
                        ],
                        "title": "Angle Sum Property of a Quadrilateral"
                    },
                    {
                        "key": "16462043291727980",
                        "elos": [
                            {
                                "id": "630ee6919a43e17a0c3c3c37",
                                "title": "Proofs based on the properties of different quadrilaterals"
                            }
                        ],
                        "title": "Types of Quadrilaterals"
                    },
                    {
                        "key": "16462044063577772",
                        "elos": [
                            {
                                "id": "630ee6919a43e17a0c3c3c3d",
                                "title": "Understand that if in a quadrilateral, each pair of opposite angles is equal, then it is a parallelogram"
                            }
                        ],
                        "title": "Properties of a Parallelogram"
                    },
                    {
                        "key": "16462045920047167",
                        "elos": [
                            {
                                "id": "630ee6919a43e17a0c3c3c40",
                                "title": "Proofs based on the mid-point theorem"
                            }
                        ],
                        "title": "The Mid-Point Theorem"
                    }
                ]
            },
            {
                "_id": "674979aad707ed41f797ae99",
                "userId": "659b90fa21cb4f53081b46a7",
                "transferKey": "USER_BULK_IMPORT_8_1_2024",
                "schoolId": "SCX-004",
                "board": "CBSE",
                "class": "Class 6",
                "masterDataId": "5e71c0afe6a6150727ddc842",
                "homeworkId": "674979aad707ed41f797ae5f",
                "chapterKey": "17328632331676790",
                "startTime": null,
                "finishTime": null,
                "section": "A",
                "sectionId": "659b8798a235f61528427b01",
                "type": "STANDARD",
                "status": "ASSIGNED",
                "streamingStatus": "NOVICE",
                "countingwellCreatedTimestamp": "2024-11-29T08:22:02.637Z",
                "countingwellLastUpdateTimestamp": "2024-11-29T08:22:02.637Z",
                "chapterTitle": "FLC : Financial Literacy (H)",
                "categoryId": "",
                "homeworkStatus": "ACTIVE",
                "approximateTime": 6,
                "approximateQuestions": 6,
                "createdDate": "2024-11-29T08:22:02.390Z",
                "endTime": "2024-12-31T12:59:59.999Z",
                "createdBy": "659b84e121cb4f53081b3f99",
                "remainingDaysToComplete": 33,
                "conceptsCovered": [
                  {
                    "key": "17328632487012191",
                    "elos": [
                      {
                        "id": "6749653c2559f1afd1cf9a3e",
                        "title": "Understand buying on EMI"
                      },
                      {
                        "id": "674965232559f1afd1cf9a37",
                        "title": "Understand terms related to debit cards and credit cards"
                      },
                      {
                        "id": "674965582559f1afd1cf9a47",
                        "title": "Understand loans and borrowing"
                      },
                      {
                        "id": "674965722559f1afd1cf9a50",
                        "title": "Understand Simple Interest and Compound Interest"
                      }
                    ],
                    "title": "Financial Literacy"
                  }
                ]
              }

        ]
    }

    constructor(props) {
        super(props);
        this.layoutShared = LayoutShared.getLayout()
        this.user = SharedProfile.getProfile()

        console.log(this.user.class)

    }

    onClickCard(item) {
        this.getHomeworkStatus(item)
    }


    getHomeworkStatus = (item) => {

        // if (item.type == 'CLASSWORK_ASSESSMENT' || item.type == 'ASSESSMENT') {
        //     this.handleAssessmenets(item)
        //     return
        // }

        console.log('>>>>::', item)

        // let item = {
        //     "_id": "66ec13fe0254ed9fde812fdc",
        //     "userId": "659b90fa21cb4f53081b46a9",
        //     "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //     "schoolId": "SCX-004",
        //     "board": "CBSE",
        //     "class": "Class 6",
        //     "masterDataId": "5e71c0afe6a6150727ddc842",
        //     "homeworkId": "66ec13fe0254ed9fde812fa9",
        //     "chapterKey": "15845131996920700",
        //     "startTime": null,
        //     "finishTime": null,
        //     "section": null,
        //     "sectionId": "",
        //     "type": "STANDARD",
        //     "status": "ASSIGNED",
        //     "streamingStatus": "NOVICE",
        //     "countingwellCreatedTimestamp": "2024-09-19T12:07:26.749Z",
        //     "countingwellLastUpdateTimestamp": "2024-09-19T12:07:26.749Z",
        //     "chapterTitle": "Knowing Our Numbers (H)",
        //     "categoryId": "1",
        //     "chapterImageUrl": "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/4a6148c3-5c39-4b17-90db-327ce4322c24-Knowing%20our%20numbers.png",
        //     "homeworkStatus": "ACTIVE",
        //     "approximateTime": 2,
        //     "approximateQuestions": 2,
        //     "createdDate": "2024-09-19T12:07:26.550Z",
        //     "endTime": "2024-09-30T12:59:59.999Z",
        //     "createdBy": "659b84e121cb4f53081b3f99",
        //     "remainingDaysToComplete": 12,
        //     "conceptsCovered": [
        //         {
        //             "key": "15846112995131712",
        //             "elos": [
        //                 {
        //                     "id": "5e7340315c3254149568b90f",
        //                     "title": "Read and Write Numbers with Place Value "
        //                 },
        //                 {
        //                     "id": "5e7340515c3254149568b911",
        //                     "title": "Form Numbers with Given Digits and Given Conditions"
        //                 }
        //             ],
        //             "title": "Numbers Upto 1 Lakh"
        //         }
        //     ]
        // }

        // let item = {
        //     "_id": "66f2a3b81deacfba3822d9e6",
        //     "userId": "659b90fa21cb4f53081b46a9",
        //     "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //     "schoolId": "SCX-004",
        //     "board": "CBSE",
        //     "class": "Class 6",
        //     "masterDataId": "630ee6107dc02179c069fbef",
        //     "homeworkId": "66f2a3b81deacfba3822d9af",
        //     "chapterKey": "16619207846261523",
        //     "startTime": null,
        //     "finishTime": null,
        //     "section": null,
        //     "sectionId": "",
        //     "type": "STANDARD",
        //     "status": "ASSIGNED",
        //     "streamingStatus": "NOVICE",
        //     "countingwellCreatedTimestamp": "2024-09-24T11:34:16.680Z",
        //     "countingwellLastUpdateTimestamp": "2024-09-24T11:34:16.680Z",
        //     "chapterTitle": "Integers (H)",
        //     "categoryId": "1",
        //     "chapterImageUrl": "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/dcb376d1-ec26-4b60-8c51-d45a48725973-Integers.png",
        //     "homeworkStatus": "ACTIVE",
        //     "approximateTime": 20,
        //     "approximateQuestions": 11,
        //     "createdDate": "2024-09-24T11:34:16.463Z",
        //     "endTime": "2024-09-30T12:59:59.999Z",
        //     "createdBy": "659b84e121cb4f53081b3f99",
        //     "remainingDaysToComplete": 7,
        //     "conceptsCovered": [
        //         {
        //             "key": "15949704148156387",
        //             "elos": [
        //                 {
        //                     "id": "630ee6919a43e17a0c3c3935",
        //                     "title": "Add Integers Using a Number Line"
        //                 },
        //                 {
        //                     "id": "630ee6919a43e17a0c3c3936",
        //                     "title": "Define and Find the Additive Inverse of an Integer"
        //                 },
        //                 {
        //                     "id": "630ee6919a43e17a0c3c3938",
        //                     "title": "Addition of Integers"
        //                 },
        //                 {
        //                     "id": "630ee6919a43e17a0c3c393a",
        //                     "title": "Subtraction of Integers"
        //                 },
        //                 {
        //                     "id": "630ee6919a43e17a0c3c393b",
        //                     "title": "Simplify Integer Expressions Involving Addition and Subtraction"
        //                 },
        //                 {
        //                     "id": "630ee6919a43e17a0c3c3939",
        //                     "title": "Subtract Integers Using a Number Line"
        //                 }
        //             ],
        //             "title": "Operations on Integers"
        //         }
        //     ]
        // }

        // let item = {
        //     "_id": "66f2a3b81deacfba3822d9e6",
        //     "userId": "659b90fa21cb4f53081b46a9",
        //     "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //     "schoolId": "SCX-004",
        //     "board": "CBSE",
        //     "class": "Class 10",
        //     "masterDataId": "668549eb68b8f80eb2ba178c",
        //     "homeworkId": "66f7fc0df54faa1ce38aa50f",
        //     "chapterKey": "17211962915345747",
        //     "startTime": null,
        //     "finishTime": null,
        //     "section": null,
        //     "sectionId": "",
        //     "type": "STANDARD",
        //     "status": "ASSIGNED",
        //     "streamingStatus": "NOVICE",
        //     "countingwellCreatedTimestamp": "2024-09-24T11:34:16.680Z",
        //     "countingwellLastUpdateTimestamp": "2024-09-24T11:34:16.680Z",
        //     "chapterTitle": "Arithmetic Progressions",
        //     "categoryId": "1",
        //     "chapterImageUrl": "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/dcb376d1-ec26-4b60-8c51-d45a48725973-Integers.png",
        //     "homeworkStatus": "ACTIVE",
        //     "approximateTime": 20,
        //     "approximateQuestions": 10,
        //     "createdDate": "2024-09-24T11:34:16.463Z",
        //     "endTime": "2024-09-30T12:59:59.999Z",
        //     "createdBy": "659b84e121cb4f53081b3f99",
        //     "remainingDaysToComplete": 7,
        //     "conceptsCovered": [
        //         {
        //             "key": "15949704148156387",
        //             "elos": [
        //                 {
        //                     "id": "630ee6919a43e17a0c3c3935",
        //                     "title": "Add Integers Using a Number Line"
        //                 },
        //                 {
        //                     "id": "630ee6919a43e17a0c3c3936",
        //                     "title": "Define and Find the Additive Inverse of an Integer"
        //                 },
        //                 {
        //                     "id": "630ee6919a43e17a0c3c3938",
        //                     "title": "Addition of Integers"
        //                 },
        //                 {
        //                     "id": "630ee6919a43e17a0c3c393a",
        //                     "title": "Subtraction of Integers"
        //                 },
        //                 {
        //                     "id": "630ee6919a43e17a0c3c393b",
        //                     "title": "Simplify Integer Expressions Involving Addition and Subtraction"
        //                 },
        //                 {
        //                     "id": "630ee6919a43e17a0c3c3939",
        //                     "title": "Subtract Integers Using a Number Line"
        //                 }
        //             ],
        //             "title": "Operations on Integers"
        //         }
        //     ]
        // }


        let payload = {
            homeworkId: item.homeworkId
        }

        this.toggleFullScreenLoading(true)

        HomeworkApi.getHomeworkStatus(payload).then((res) => {
            let concepts = []

            if (item.conceptsCovered.length) {
                for (let i = 0; i < item.conceptsCovered.length; i++) {
                    concepts.push(item.conceptsCovered[i].title)
                }
            }

            //console.log(res)

            // moengage.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Techer: item.createdBy, 'Class': this.userInfo.class, 'Homework Type': item.type, 'Chapter Key': item.chapterKey ? item.chapterKey : '', 'Chapter': item.chapterTitle, 'Concepts': concepts.toString(), 'Status': this.getHomeworkStatusText(item.status), 'Learning Started': item.status == 'COMPLETED' ? 'Yes' : "No" })
            // FireBaseEvent(Constants.SCHOOL_ASSIGNMENT, { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Techer: item.createdBy, 'Class': this.userInfo.class, 'Assignment Type': item.type.replace('_'," "), 'Chapter Key': item.chapterKey ? item.chapterKey : '', 'Chapter': item.chapterTitle, 'Concepts': concepts.toString(), 'Status': this.getHomeworkStatusText(item.status), 'Learning Started': item.status == 'COMPLETED' ? 'Yes' : "No" })
            // Facebook.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Techer: item.createdBy, 'Class': this.userInfo.class, 'Homework Type': item.type, 'Chapter Key': item.chapterKey ? item.chapterKey : '', 'Chapter': item.chapterTitle, 'Concepts': concepts.toString(), 'Status': this.getHomeworkStatusText(item.status), 'Learning Started': item.status == 'COMPLETED' ? 'Yes' : "No" })
            // BranchIO.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Techer: item.createdBy, 'Class': this.userInfo.class, 'Homework Type': item.type, 'Chapter Key': item.chapterKey ? item.chapterKey : '', 'Chapter': item.chapterTitle, 'Concepts': concepts.toString(), 'Status': this.getHomeworkStatusText(item.status), 'Learning Started': item.status == 'COMPLETED' ? 'Yes' : "No" })

            if (res && res.notAttepmted && res.notAttepmted.length) {

                console.log(res)

                // FireBaseEvent(Constants.SCHOOL_ASSIGNMENT, { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Techer: item.createdBy, 'Class': this.userInfo.class, 'Assignment Type': item.type.replace('_', " "), 'Chapter Key': item.chapterKey ? item.chapterKey : '', 'Chapter': item.chapterTitle, 'Concepts': concepts.toString(), 'Status': this.getHomeworkStatusText(item.status), 'Learning Started': "No" })
                this.layoutShared.toggleSidebar(true, this.props.history.location.pathname)
                this.setState({
                    showStandardClassworkExperience: true,
                    showStartAssessment: false,
                    params: {
                        homeworkId: item.homeworkId,
                        nodeId: res.notAttepmted[0].id,
                        nodes: res.notAttepmted,
                        title: item.chapterTitle,
                        concepts: res.concepts,
                        categoryThemeId: 1,
                        conceptsCovered: concepts,
                        homework: item,
                        typeOfSchoolwork: "CLASSWORK",
                        chapterImageUrl: item.chapterImageUrl
                    }
                })

                // this.props.navigation.navigate("StandardHomeworkExperience", {
                //     params: {
                //         homeworkId: item.homeworkId,
                //         nodeId: res.notAttepmted[0].id,
                //         nodes: res.notAttepmted,
                //         title: item.chapterTitle,
                //         concepts: res.concepts,
                //         categoryThemeId: item.categoryId,
                //         conceptsCovered: concepts,
                //         homework: item
                //     }
                // })

            } else {
                this.setState({ showHomeworkConceptListScreen: true, homeworkMetaInfo: { details: res, conceptsCovered: concepts, homework: item, homeworkId: item.homeworkId, title: item.chapterTitle, categoryThemeId: 1, chapterImageUrl: item.chapterImageUrl } })
            }


        }).catch(err => {
            if (err) {
                return
            }
        })
    }

    toggleFullScreenLoading = (value, callback) => {
        this.setState({ fullScreenLoading: value }, () => {
            callback && callback()
        })
    }



    render() {
        // if (this.state.showStartAssessment) {
        //     return (
        //         <StartAssessment delegate={this}
        //             meta={{ chapterKey: '123', chapterTitle: 'Clicker Practice Flow', categoryThemeId: 1 }}
        //             studentList={this.state.usersList}
        //         />
        //     )
        // }

        if (this.state.showStandardClassworkExperience) {
            return (
                <StandardClassWorkExperience
                    params={this.state.params}
                    delegate={this}
                    studentList={this.state.usersList} />
            )
        }

        if (this.state.showBarChart) {
            return <BarChart />
        }



        return (
            <div className="clicker-flow-practice">
                {
                    this.state.content.map((item, index) => (
                        <div key={item._id} className="card-wrapper">
                            {
                                <ClickerFlowPracticeStat item={item} index={index} delegate={this} categoryId={1} content={this.state.content} status={item.status} />
                            }
                        </div>
                    ))
                }
            </div>
        )
    }

}

export default ClickerFlowPractice;
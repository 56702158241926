
export default class Service {
    static sideMenu


    static setSideMenu(item) {
        this.sideMenu = item
    }

    static getSideMenu() {
        return this.sideMenu
    }
}

import axios from 'axios';
import ServerConfigs from './server-config';

axios.defaults.baseURL = ServerConfigs.url;

function getData(url, payload, callback) {

    let params = [];
    if (payload) {
        Object.keys(payload).forEach(key => {
            params.push(`${key}=${payload[key]}`);
        });
    }

    if (params && params.length) {
        url = url + "?" + params.join('&');
    }

    axios.get(url).then(response => {
        callback(null, response);
    }).catch(error => {
        callback(error, null);
    });
}

function createData(url, body, callback) {

    axios.post(url, body).then(response => {
        callback(null, response);
    }).catch(error => {
        callback(error, null);
    });
}

function updateData(url, body, callback) {

    axios.put(url, body).then(response => {
        callback(null, response);
    }).catch(error => {
        callback(error, null);
    });

}

function deleteData(url, body, callback) {

    axios.delete(url, body).then(response => {
        callback(null, response);
    }).catch(error => {
        callback(error, null);
    });
}


let methods = {

    getData,
    createData,
    updateData,
    deleteData
}

export default methods;
import React from 'react';
import './refresher-course-header.scss';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import { ImageConstants } from '../../../constants/constants';
import SharedProfile from '../../../../shared-storage/profile';
import Path from '../../routes/routes-path';

export default class RefresherCourseHeader extends React.Component {
    constructor(props) {
        super(props);

        this.userInfo = SharedProfile.getProfile();
    }


    state = {
    }

    close = () => {
        this.props.close && this.props.close()
    }

    onPressLateralMenu = () => {
        this.props.delegate.showLateralMenu && this.props.delegate.showLateralMenu()
    }

    goToHomeScreen() {
     this.props.close && this.props.close()
    }

    renderBreadcrumb() {
            return (
                <div className='breadcrumb-for-classwork mt-2'>
                    <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 title-1'>{`Bridge Course`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                    {/* <span className='pl-2 title-1'>{`Start Assessment`}</span> */}
                </div>
            )
    }


    render() {

        return (
            <div className='header-container-wrapper'>
                <div className='header-container'>
                    <div>
                       {this.renderBreadcrumb()}
                    </div>
                </div>
                {
                    !this.props.isScrolled ?
                        <div>
                            <div>
                                <div className='title-text mt-2'>{this.props.title ? this.props.title : this.props.chapterTitle}</div>
                                <div className='sub-title-text'>
                                    {this.userInfo.class}th
                                </div>
                            </div>
                        </div>
                        : <></>
                }

                <img className='header-container-image' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.props.categoryId)} />

            </div>
        )
    }

}
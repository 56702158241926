import React from "react";
import './event-assessment-tip-popup.scss';
import Parser from "../../common/content-parser/content-parser";
import { Modal, ModalBody } from "reactstrap";
import { getThemeImageSource, getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedThemeCategory from "../../../../shared-storage/category-theme";

export default class EventAssessmentTip extends React.Component {

    staticText = {};

    constructor(props) {
        super(props);
        this.parser = new Parser();
        // setCategoryThemeStyles(props.categoryThemeId)
        this.staticText = this.props.staticText

    }

    closeModal = () => {
        this.props.close(false);
    }

    onPressStart = () => {
        this.props.close(true);

    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <Modal className="cw-common-custom-modal-center-for-assessment-tip" isOpen={true} size={'md'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getThemeImageSource(ImageConstants.FLAG_POPUP_HEADER, this.props.categoryThemeId)}></img>
                    <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.closeModal()} />
                </div>
                <ModalBody>

                    <div className="pratice-container">
                        <div className='header-text mt-3 mb-5 text-center'>{this.props.staticText.importantText ? this.props.staticText.importantText : 'Important!'}</div>
                        <div className='header-sub-text mt-2 mb-5 text-center'>{this.parser.parseWithStyles(`${this.props.staticText.eventInstructionText}`, { p: {fontSize : "17px"}, strong: {fontWeight : "500"} })}</div>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <div className="take-challenge cursor-pointer text-center" onClick={() => this.onPressStart()}>
                                {'CONTINUE'}
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <div className="position">
                    <img className="challenge-footer" src={getThemeImageSource(ImageConstants.FLAG_POPUP_FOOTER, this.props.categoryThemeId)}></img>
                </div>
            </Modal>
        );
    }
}
import React from 'react';
import './cw-glossary.scss';
import { ImageConstants } from '../../../constants/constants';
// import { getImageSource,getThemeImageSource } from '../../../utility/image-source';
import Parser from "../content-parser/content-parser";
import { getThemeImageSource } from '../../../utility/image-source';

// import { Colors } from '../../../styles/index';
// import sharedThemecategory from '../../../shared-storage/category-theme'

export default class CWGlossary extends React.Component {
    constructor(props) {
        super(props);
        this.parser = new Parser();
    }


    getDataType = (data, type) => {

        switch (type) {
            case "DEFINITION":
                return this.renderDefinition(data)

            case "PROPERTY":
                return this.renderProperty(data)

            case "STATEMENT":
                return this.renderStatement(data)

            case "FORMULA":
                return this.renderFormula(data)
        }

    }

    renderProperty = (data) => {
        //console.log(data)
        return (
            <div className='row' >
                <div className='col-1 left-border'></div>
                <div className='col' >{this.parser.parseLearningContent(data,this.props.delegate,null,null,true,this.props.categoryId)}</div>
            </div>
        )
    }

    renderFormula = (data) => {

        return (
            <div className='ml-1 formula-card' >
                {this.parser.parseLearningContent(data,this.props.delegate,null,null,true,this.props.categoryId)}
            </div>
        )
    }

    renderDefinition = (data) => {

        return (
            <div className='' style={{position : "relative"}}>
                <img src={getThemeImageSource(ImageConstants.DEFINITION_BORDER_UP,this.props.categoryId)} style={{position : "absolute", top : '-25px', height : '4rem', maxWidth : "100%", objectFit : 'contain'}}   />
                <div className='ml-4'>{this.parser.parseLearningContent(data,this.props.delegate,null,null,true,this.props.categoryId)}</div>
                <img src={getThemeImageSource(ImageConstants.DEFINITION_BORDER_DOWN,this.props.categoryId)} style={{position : "absolute", bottom : '-25px', right : "0px", height : '4rem', maxWidth : "100%", objectFit : 'contain'}} />
            </div>
        )
    }

    renderStatement = (data) => {

        return (
            <div className='statement-card' style={{position : "relative"}}>
                <img src={getThemeImageSource(ImageConstants.STATEMENT_QUOTE_UP,this.props.categoryId)} style={{position : "absolute", top : '-3px', left : '1px',  height : '3rem', maxWidth : "100%", objectFit : 'contain'}} />
                <div className='ml-5' >{this.parser.parseLearningContent(data,this.props.delegate,null,null,true,this.props.categoryId)}</div>
                <img src={getThemeImageSource(ImageConstants.STATEMENT_QUOTE_DOWN,this.props.categoryId)}  style={{position : "absolute", bottom : '-3px', right : "0px", height : '3rem', maxWidth : "100%", objectFit : 'contain'}} />
            </div>
            
        )
    }

    render() {


        return (
            <>
                {this.props.data && this.props.type ?

                    <div className='cw-glossary mt-5 mb-5' style={{flex:1}}>
                        {this.getDataType(this.props.data, this.props.type)}
                    </div>
                    :
                    <></>

                }
            </>
        )
    }


}
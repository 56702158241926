import HttpClient from './http-client';
import URL from './url';

export default class HomeworkApi {

    static getUserHomeworkActivities(payload) {

        let url = URL.BASE_URL + URL.USER_HOMEWORK_ACTIVITY;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getSchoolAssignmentFeedbackStatus(payload) {

        let url = URL.BASE_URL + URL.GET_SCHOOL_ASSIGNMENT_FEEDBACK;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    

    static getHomeworkEloDetails(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.HOMEWORK_ELO_DETAILS + "?" + params.join('&') : URL.HOMEWORK_ELO_DETAILS;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static saveSchoolAssignmentFeedback(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SCHOOL_ASSIGNMENT_FEEDBACK, null, payload);
    }


    static getReenforcementLearningELODetails(payload) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_RE_ENFORCEMENT_ELO_DETAILS + "?" + params.join('&') : URL.GET_RE_ENFORCEMENT_ELO_DETAILS;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);

        
    }

    static homeworkDeeplink(payload) {
        return HttpClient.post(URL.BASE_URL + URL.HOMEWORK_DEEP_LINK, null, payload);
    }

    static getHomeworkFilters(payload) {

        let url = URL.BASE_URL + URL.HOMEWORK_FILTER;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    

    static getHomeworkStatus(payload) {

        let url = URL.BASE_URL + URL.HOMEWORK_STATUS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    

    static getStandardHomeworkAssessment(payload) {

        let url = URL.BASE_URL + URL.STANDARD_HOMEWORK_ASSESSMENT;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }


    static assessStandaredHomeworkNode(payload) {
        return HttpClient.post(URL.BASE_URL + URL.ASSESS_STANDARD_HOMEWORK, null, payload);
    }

    static updateUserHomeworkActivity(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_USER_HOMEWORK_ACTIVITY, null, payload);
    }

    static validateAnswer(payload) {
        return HttpClient.post(URL.BASE_URL + URL.VALIDATE_ANSWER, null, payload);
    }

    static validateAnswerForClickerPractice(payload) {
        return HttpClient.post(URL.BASE_URL + URL.VALIDATE_ANSWER_HOMEWORK_CLICKER_PRACTICE_ASSESSMENT, null, payload);
    }

    

    
    static getStandardHomeworkResult(payload) {

        let url = URL.BASE_URL + URL.STANDARD_HOMEWORK_ANSWER;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    

    static getHomeworkAssessmentContents(payload) {

        return HttpClient.post(URL.BASE_URL + URL.HOMEWORK_ASSESSMENT_CONTENTS, null, payload);
    }

    

    static getHomeworkAssessment(payload) {

        let url = URL.BASE_URL + URL.HOMEWORK_ASSESSMENT;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }


    static getStandardHomeworkClickerPracticeAssessment(payload) {

        let url = URL.BASE_URL + URL.STANDARD_HOMEWORK_CLICKER_PRACTICE_ASSESSMENT;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static updateUserHomeworkClickerPracticeActivity(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_USER_HOMEWORK_CLICKER_PRACTICE_ACTIVITY, null, payload);
    }


    static assessStandaredHomeworkClickerPracticeNode(payload) {
        return HttpClient.post(URL.BASE_URL + URL.ASSESS_STANDARD_HOMEWORK_CLICKER_PRACTICE, null, payload);
    }


    

    

    


    

    
}
let imageCache = {

        purple: {

                "eye_content_block":require('../assets/images/theme-images/purple/eye-blocked.png'),

                'content-navigation' : require('../assets/images/theme-images/purple/content-navigation.gif'),

                'definition-border-up': require('../assets/images/theme-images/purple/definition-border-up.png'),
                'definition-border-down': require('../assets/images/theme-images/purple/definition-border-down.png'),
                'statement-quote-up': require('../assets/images/theme-images/purple/statement-quote-up.png'),
                'statement-quote-down': require('../assets/images/theme-images/purple/statement-quote-down.png'),
                
                "practice_question_header":require('../assets/images/theme-images/purple/practice-question-header.png'),
                "practice_question_header_divider":require('../assets/images/theme-images/purple/practice-question-header-divider.png'),
                "practice_question_divider":require('../assets/images/theme-images/purple/practice-question-divider.png'),
                "practice_question_popup_header":require('../assets/images/theme-images/purple/practice-question-popup-header.png'),
                "take_challenge_popup_header":require('../assets/images/theme-images/purple/take-challenge-popup-header.png'),
                "take_challenge_popup_footer":require('../assets/images/theme-images/purple/take-challenge-popup-footer.png'),
                "take_challenge_popup_male_image":require('../assets/images/theme-images/purple/take-challenge-popup-male-image.png'),
                'exam_pad' : require('../assets/images/theme-images/purple/exam-pad.png'),
                'assessment_footer_bg' : require('../assets/images/theme-images/purple/assessment-footer-bg.png'),
                'tick_mark_icon' : require('../assets/images/theme-images/purple/tick-mark-icon.png'),
                'option_separator' : require('../assets/images/theme-images/purple/option-separator.png'),
                'theme_close_icon' : require('../assets/images/theme-images/purple/theme-close-icon.png'),
                'drag_icon' : require('../assets/images/theme-images/purple/drag-icon.png'),
                "flag_popup_header" : require('../assets/images/theme-images/purple/flag-popup-header.png'),
                "flag_popup_footer" : require('../assets/images/theme-images/purple/flag-popup-footer.png'),
                "flagged_card_footer" : require('../assets/images/theme-images/purple/flagged-card-footer.png'),
                "theme_clock_image" : require('../assets/images/theme-images/purple/theme-clock.png'),
                "header_divider_line" : require('../assets/images/theme-images/purple/header-divider-line.png'),
                'assessment_end_screen_footer' : require('../assets/images/theme-images/purple/assessment-end-screen-footer.png'),
                "assessment_end_screen_vertical_line" : require('../assets/images/theme-images/purple/assessment-end-screen-vertical-line.png'),
                "marks_card_bg" : require('../assets/images/theme-images/purple/marks-card-bg.png'),
                "homework_card_footer": require('../assets/images/theme-images/purple/homework-card-footer.png'),
                "homework_completion_screen_header" : require('../assets/images/theme-images/purple/homework-completion-screen-header.png'),
                "homework_completion_screen_footer" : require('../assets/images/theme-images/purple/homework-completion-screen-footer.png'),
                "down_arrow" : require('../assets/images/theme-images/purple/down-arrow.png'),
                "up_arrow" : require('../assets/images/theme-images/purple/up-arrow.png'),
                "app_image" : require('../assets/images/theme-images/purple/app-image.png'),
                "app_image_with_text" : require('../assets/images/theme-images/purple/app-image-with-text.png'),
        },

        indigo: {
                "practice_question_header":require('../assets/images/theme-images/indigo/practice-question-header.png'),
                "practice_question_header_divider":require('../assets/images/theme-images/indigo/practice-question-header-divider.png'),
                "practice_question_divider":require('../assets/images/theme-images/indigo/practice-question-divider.png'),
                "practice_question_popup_header":require('../assets/images/theme-images/indigo/practice-question-popup-header.png'),
                "take_challenge_popup_header":require('../assets/images/theme-images/indigo/take-challenge-popup-header.png'),
                "take_challenge_popup_footer":require('../assets/images/theme-images/indigo/take-challenge-popup-footer.png'),
                "take_challenge_popup_male_image":require('../assets/images/theme-images/indigo/take-challenge-popup-male-image.png'),
                // 'exam_pad' : require('../assets/images/theme-images/indigo/exam-pad.png'),


                'assessment_footer_bg' : require('../assets/images/theme-images/indigo/assessment-footer-bg.png'),
                "homework_completion_screen_header" : require('../assets/images/theme-images/indigo/homework-completion-screen-header.png'),
                "down_arrow" : require('../assets/images/theme-images/indigo/down-arrow.png'),
                "homework_card_footer": require('../assets/images/theme-images/indigo/homework-card-footer.png'),


                'tick_mark_icon' : require('../assets/images/theme-images/indigo/tick-mark-icon.png'),
                'theme_close_icon' : require('../assets/images/theme-images/indigo/theme-close-icon.png'),
                'drag_icon' : require('../assets/images/theme-images/indigo/drag-icon.png'),
                "up_arrow" : require('../assets/images/theme-images/indigo/up-arrow.png'),
                "homework_completion_screen_footer" : require('../assets/images/theme-images/indigo/homework-completion-screen-footer.png'),

                'assessment_end_screen_footer' : require('../assets/images/theme-images/indigo/assessment-end-screen-footer.png'),
                "assessment_end_screen_vertical_line" : require('../assets/images/theme-images/indigo/assessment-end-screen-vertical-line.png'),
                "marks_card_bg" : require('../assets/images/theme-images/indigo/marks-card-bg.png'),
                'exam_pad' : require('../assets/images/theme-images/indigo/exam-pad.png'),
                "flagged_card_footer" : require('../assets/images/theme-images/indigo/flagged-card-footer.png'),
                "theme_clock_image" : require('../assets/images/theme-images/indigo/theme-clock.png'),
                "app_image" : require('../assets/images/theme-images/indigo/app-image.png'),
                "app_image_with_text" : require('../assets/images/theme-images/indigo/app-image-with-text.png'),
        },

        orange: {
               
                "practice_question_header":require('../assets/images/theme-images/orange/practice-question-header.png'),
                "practice_question_header_divider":require('../assets/images/theme-images/orange/practice-question-header-divider.png'),
                "practice_question_divider":require('../assets/images/theme-images/orange/practice-question-divider.png'),
                "practice_question_popup_header":require('../assets/images/theme-images/orange/practice-question-popup-header.png'),
                "take_challenge_popup_header":require('../assets/images/theme-images/orange/take-challenge-popup-header.png'),
                "take_challenge_popup_footer":require('../assets/images/theme-images/orange/take-challenge-popup-footer.png'),
                "take_challenge_popup_male_image":require('../assets/images/theme-images/orange/take-challenge-popup-male-image.png'),

                'assessment_footer_bg' : require('../assets/images/theme-images/orange/assessment-footer-bg.png'),
                "homework_completion_screen_header" : require('../assets/images/theme-images/orange/homework-completion-screen-header.png'),
                "down_arrow" : require('../assets/images/theme-images/orange/down-arrow.png'),
                "homework_card_footer": require('../assets/images/theme-images/orange/homework-card-footer.png'),

                'tick_mark_icon' : require('../assets/images/theme-images/orange/tick-mark-icon.png'),
                'theme_close_icon' : require('../assets/images/theme-images/orange/theme-close-icon.png'),
                'drag_icon' : require('../assets/images/theme-images/orange/drag-icon.png'),
                "up_arrow" : require('../assets/images/theme-images/orange/up-arrow.png'),
                "homework_completion_screen_footer" : require('../assets/images/theme-images/orange/homework-completion-screen-footer.png'),

                'assessment_end_screen_footer' : require('../assets/images/theme-images/orange/assessment-end-screen-footer.png'),
                "assessment_end_screen_vertical_line" : require('../assets/images/theme-images/orange/assessment-end-screen-vertical-line.png'),
                "marks_card_bg" : require('../assets/images/theme-images/orange/marks-card-bg.png'),
                'exam_pad' : require('../assets/images/theme-images/orange/exam-pad.png'),
                "flagged_card_footer" : require('../assets/images/theme-images/orange/flagged-card-footer.png'),
                "theme_clock_image" : require('../assets/images/theme-images/orange/theme-clock.png'),
                "app_image" : require('../assets/images/theme-images/orange/app-image.png'),
                "app_image_with_text" : require('../assets/images/theme-images/orange/app-image-with-text.png'),
        },

        skyblue: {
                "practice_question_header":require('../assets/images/theme-images/skyblue/practice-question-header.png'),
                "practice_question_header_divider":require('../assets/images/theme-images/skyblue/practice-question-header-divider.png'),
                "practice_question_divider":require('../assets/images/theme-images/skyblue/practice-question-divider.png'),
                "practice_question_popup_header":require('../assets/images/theme-images/skyblue/practice-question-popup-header.png'),
                "take_challenge_popup_header":require('../assets/images/theme-images/skyblue/take-challenge-popup-header.png'),
                "take_challenge_popup_footer":require('../assets/images/theme-images/skyblue/take-challenge-popup-footer.png'),
                "take_challenge_popup_male_image":require('../assets/images/theme-images/skyblue/take-challenge-popup-male-image.png'),


                'assessment_footer_bg' : require('../assets/images/theme-images/skyblue/assessment-footer-bg.png'),
                "homework_completion_screen_header" : require('../assets/images/theme-images/skyblue/homework-completion-screen-header.png'),
                "down_arrow" : require('../assets/images/theme-images/skyblue/down-arrow.png'),
                "homework_card_footer": require('../assets/images/theme-images/skyblue/homework-card-footer.png'),


                'tick_mark_icon' : require('../assets/images/theme-images/skyblue/tick-mark-icon.png'),
                'theme_close_icon' : require('../assets/images/theme-images/skyblue/theme-close-icon.png'),
                'drag_icon' : require('../assets/images/theme-images/skyblue/drag-icon.png'),
                "up_arrow" : require('../assets/images/theme-images/skyblue/up-arrow.png'),
                "homework_completion_screen_footer" : require('../assets/images/theme-images/skyblue/homework-completion-screen-footer.png'),

                'assessment_end_screen_footer' : require('../assets/images/theme-images/skyblue/assessment-end-screen-footer.png'),
                "assessment_end_screen_vertical_line" : require('../assets/images/theme-images/skyblue/assessment-end-screen-vertical-line.png'),
                "marks_card_bg" : require('../assets/images/theme-images/skyblue/marks-card-bg.png'),
                'exam_pad' : require('../assets/images/theme-images/skyblue/exam-pad.png'),
                "flagged_card_footer" : require('../assets/images/theme-images/skyblue/flagged-card-footer.png'),
                "theme_clock_image" : require('../assets/images/theme-images/skyblue/theme-clock.png'),
                "app_image" : require('../assets/images/theme-images/skyblue/app-image.png'),
                "app_image_with_text" : require('../assets/images/theme-images/skyblue/app-image-with-text.png'),

        },

        blue: {
                "practice_question_header":require('../assets/images/theme-images/blue/practice-question-header.png'),
                "practice_question_header_divider":require('../assets/images/theme-images/blue/practice-question-header-divider.png'),
                "practice_question_divider":require('../assets/images/theme-images/blue/practice-question-divider.png'),
                "practice_question_popup_header":require('../assets/images/theme-images/blue/practice-question-popup-header.png'),
                "take_challenge_popup_header":require('../assets/images/theme-images/blue/take-challenge-popup-header.png'),
                "take_challenge_popup_footer":require('../assets/images/theme-images/blue/take-challenge-popup-footer.png'),
                "take_challenge_popup_male_image":require('../assets/images/theme-images/blue/take-challenge-popup-male-image.png'),


                'assessment_footer_bg' : require('../assets/images/theme-images/blue/assessment-footer-bg.png'),
                "homework_completion_screen_header" : require('../assets/images/theme-images/blue/homework-completion-screen-header.png'),
                "down_arrow" : require('../assets/images/theme-images/blue/down-arrow.png'),
                "homework_card_footer": require('../assets/images/theme-images/blue/homework-card-footer.png'),


                'tick_mark_icon' : require('../assets/images/theme-images/blue/tick-mark-icon.png'),
                'theme_close_icon' : require('../assets/images/theme-images/blue/theme-close-icon.png'),
                'drag_icon' : require('../assets/images/theme-images/blue/drag-icon.png'),
                "up_arrow" : require('../assets/images/theme-images/blue/up-arrow.png'),
                "homework_completion_screen_footer" : require('../assets/images/theme-images/blue/homework-completion-screen-footer.png'),

                'assessment_end_screen_footer' : require('../assets/images/theme-images/blue/assessment-end-screen-footer.png'),
                "assessment_end_screen_vertical_line" : require('../assets/images/theme-images/blue/assessment-end-screen-vertical-line.png'),
                "marks_card_bg" : require('../assets/images/theme-images/blue/marks-card-bg.png'),
                'exam_pad' : require('../assets/images/theme-images/blue/exam-pad.png'),
                "flagged_card_footer" : require('../assets/images/theme-images/blue/flagged-card-footer.png'),
                "theme_clock_image" : require('../assets/images/theme-images/blue/theme-clock.png'),
                "app_image" : require('../assets/images/theme-images/blue/app-image.png'),
                "app_image_with_text" : require('../assets/images/theme-images/blue/app-image-with-text.png'),
        },

        yellow: {
           
                "practice_question_header":require('../assets/images/theme-images/yellow/practice-question-header.png'),
                "practice_question_header_divider":require('../assets/images/theme-images/yellow/practice-question-header-divider.png'),
                "practice_question_divider":require('../assets/images/theme-images/yellow/practice-question-divider.png'),
                "practice_question_popup_header":require('../assets/images/theme-images/yellow/practice-question-popup-header.png'),
                "take_challenge_popup_header":require('../assets/images/theme-images/yellow/take-challenge-popup-header.png'),
                "take_challenge_popup_footer":require('../assets/images/theme-images/yellow/take-challenge-popup-footer.png'),
                "take_challenge_popup_male_image":require('../assets/images/theme-images/yellow/take-challenge-popup-male-image.png'),

                'assessment_footer_bg' : require('../assets/images/theme-images/yellow/assessment-footer-bg.png'),
                "homework_completion_screen_header" : require('../assets/images/theme-images/yellow/homework-completion-screen-header.png'),
                "down_arrow" : require('../assets/images/theme-images/yellow/down-arrow.png'),
                "homework_card_footer": require('../assets/images/theme-images/yellow/homework-card-footer.png'),


                'tick_mark_icon' : require('../assets/images/theme-images/yellow/tick-mark-icon.png'),
                'theme_close_icon' : require('../assets/images/theme-images/yellow/theme-close-icon.png'),
                'drag_icon' : require('../assets/images/theme-images/yellow/drag-icon.png'),
                "up_arrow" : require('../assets/images/theme-images/yellow/up-arrow.png'),
                "homework_completion_screen_footer" : require('../assets/images/theme-images/yellow/homework-completion-screen-footer.png'),

                'assessment_end_screen_footer' : require('../assets/images/theme-images/yellow/assessment-end-screen-footer.png'),
                "assessment_end_screen_vertical_line" : require('../assets/images/theme-images/yellow/assessment-end-screen-vertical-line.png'),
                "marks_card_bg" : require('../assets/images/theme-images/yellow/marks-card-bg.png'),
                'exam_pad' : require('../assets/images/theme-images/yellow/exam-pad.png'),
                "flagged_card_footer" : require('../assets/images/theme-images/yellow/flagged-card-footer.png'),
                "theme_clock_image" : require('../assets/images/theme-images/yellow/theme-clock.png'),
                "app_image" : require('../assets/images/theme-images/yellow/app-image.png'),
                "app_image_with_text" : require('../assets/images/theme-images/yellow/app-image-with-text.png'),
        }

};

export default imageCache;

import React from "react";
import './my-classwork-stat.scss';
import SharedStaticText from "../../../../shared-storage/static-text-store";
import Parser from "../../common/content-parser/content-parser";
import CommonUtils from "../../../utility/common-utilities";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedThemeCategory from "../../../../shared-storage/category-theme";

export default class MyClassworkStat extends React.Component {

    constructor(props) {

        super(props);

        //console.log(props)

        this.parser = new Parser();
        this.staticText = SharedStaticText.getStaticText('homeworkCard');
    }

    state = {

    }


    showConceptsPopup = () => {

        if (this.props.item && this.props.item.type == "ASSESSMENT") {
            this.props.delegate && this.props.delegate.showChaptersPopup && this.props.delegate.showChaptersPopup(this.props.item);
            return
        }


        this.props.delegate && this.props.delegate.showConceptsPopup && this.props.delegate.showConceptsPopup(this.props.item);
    }

    viewAnswers = () => {
        this.props.delegate && this.props.delegate.viewAnswers && this.props.delegate.viewAnswers(this.props.item);

    }


    navigate = () => {
        this.props.delegate && this.props.delegate.onClickCard && this.props.delegate.onClickCard(this.props.item)
    }

    getHeaderForAssessment = (item) => {

        if (item.chapters && item.chapters.length > 1) {

            return (
                <div className="d-flex flex-column cursor-pointer" onClick={this.showConceptsPopup}>
                    <div className="" >
                        <span className="chapter-title ">{this.props.item.chapterTitle}</span>
                        <span className="chapters-count"> +{item.chapters.length - 1}</span>
                        <img className="arrow-size  ml-2"  src={getImageSource(ImageConstants.RIGHT_ARROW)} />
                    </div>
                    <div className="d-flex">
                        <div className="assessment-badge px-2 py-1">
                            Assessment
                        </div>
                    </div>
                </div>)
            // <View style={{ flex: 0.63 }}>
            //     <View style={[this.props.item.status != 'COMPLETED' ? Styles.titleContainer : Styles.titleContainer2, { paddingLeft: 0 }]}>

            //         <View style={{ flex: 0.8 }}>
            //             <Text style={Styles.multipleChapterTitle}>{this.props.item.chapterTitle}</Text>
            //         </View>

            //         <View style={{ flex: 0.2, alignSelf: 'center' }}>
            //             <View style={Platform.OS == 'ios' ? Styles.iosMultipleChapterIndication : Styles.multipleChapterIndication}>
            //             <Text style={Styles.multipleChapterText}>+{item.chapters.length - 1}</Text>
            //             </View>
            //         </View>
            //         <View style={[{ flex: 0.2, alignSelf: 'center' }]}>
            //             <Image source={getImageSource(ImageConstants.RIGHT_ARROW_NEW)} resizeMode={'contain'} />
            //         </View>
            //     </View>
            //     <View style={Styles.assessmentBadge}>
            //         <Text style={Styles.assessmentText}>Assessment</Text>
            //     </View>
            // </View>)

        }
        return (
            <div className="d-flex flex-column cursor-pointer" onClick={this.showConceptsPopup}>
                <div>
                    <span className="chapter-title" >{this.props.item.chapterTitle}</span>
                    <img className="arrow-size ml-2"  src={getImageSource(ImageConstants.RIGHT_ARROW)} />
                </div>
                <div className="d-flex">
                    <div className="assessment-badge px-2 py-1">
                        Assessment
                    </div>
                </div>
            </div>
        )
    }

    getButtonTitle = (status) => {
        if (status == 'ASSIGNED')
            return this.staticText.startButtonTitle

        if (status == 'COMPLETED')
            return this.staticText.learnButtonTitle

        if (status == 'IN_PROGRESS')
            return this.staticText.resumeButtonTitle
    }

    parseStudentSCountData = (msg, obj, style) => {

        //console.log('count', obj)

        let jsx = [];
        for (let i = 0; i < msg.length; i++) {

            if (msg[i].msg)
                msg[i].msg = CommonUtils.simpleTemplateEngine(msg[i].msg, obj)

            // if (msg[i].url)
            //     msg[i].url = simpleTemplateEngine(msg[i].url, obj)

            switch (msg[i].style) {
                case 'text': jsx.push(<span style={style.text}>{msg[i].msg}</span>); break;
                case 'bold': jsx.push(<span style={style.strong}>{msg[i].msg}</span>); break;
                // case 'link': jsx.push(<Text onPress={() => Linking.openURL(msg[i].url)} style={style.link}>{msg[i].msg}</Text>); break;
            }
        }

        return <span >{jsx}</span>
    }

    getRemaningDaysDisplay = () => {

        if (this.props.item.isDueDatePassed) {
            return <div className="text-center">
                <img className="icon-size" src={getImageSource(ImageConstants.RED_RIPPLE_ICON)} />
                <span className="due-date-passed">{this.staticText.overDueText}</span>
            </div>
        }

        if (this.props.item && this.props.item.remainingDaysToComplete && this.props.item.remainingDaysToComplete == 1) {
            return <div className="last-date-text text-center" >{this.staticText.lastDateText}</div>
        }

        if (this.props.item && this.props.item.remainingDaysToComplete && this.props.item.remainingDaysToComplete) {
            return <div className="d-flex justify-content-around align-items-center">
                <div className="mr-2">
                    <span className="days-count">{this.props.item.remainingDaysToComplete}</span>
                </div>
                <div >
                    <span className="last-date-text">{"Days to Classwork Completion"}</span>
                </div>
            </div>
        }
    }


    render() {

        //console.log(this.props.item)


        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId)
        let backgroundColor = this.theme[`secondaryColors`][`chapterCardColor`]

        let lgButton = `linear-gradient(#BDFFF5,#BDFFF5)`
        return (
            <div className="stats-card mt-4">
                <div className="chapter-background" style={{ backgroundColor: backgroundColor }}>
                    <div className="row  mr-0 ml-0 justify-content-center align-items-center">
                        <div className="col-3 d-flex align-items-center">
                            {
                                this.props.item.chapterImageUrl ? <img className="chapter-image-size" src={this.props.item.chapterImageUrl} />
                                    :
                                    <img className="chapter-image-size" src={getImageSource(ImageConstants.CHAPTER_ICN)} />
                            }

                            {this.props.item.type == "ASSESSMENT" ? this.getHeaderForAssessment(this.props.item) :

                                <div className="d-flex flex-row">
                                    <div>
                                        <span className="chapter-title cursor-pointer" onClick={this.showConceptsPopup}>{this.props.item.chapterTitle}</span>
                                        <img className="arrow-size cursor-pointer ml-2" onClick={this.showConceptsPopup} src={getImageSource(ImageConstants.RIGHT_ARROW)} />
                                    </div>

                                </div>

                            }


                        </div>
                        {
                            this.props.item.section ? <div className="col text-center">
                            <div>
                                <img className="icon-size" src={getImageSource(ImageConstants.DATE_ICON)} />
                                <span className="status-text">Section</span>
                            </div>
                            <div className="created-date">{this.props.item.section}</div>
                        </div> :<></>
                        }
                        <div className="col text-center">
                            <div>
                                <img className="icon-size" src={getImageSource(ImageConstants.DATE_ICON)} />
                                <span className="status-text">Assigned</span>
                            </div>
                            <div className="created-date">{CommonUtils.getDate(this.props.item.createdDate)}</div>
                        </div>
                        <div className="col text-center">
                            <div>
                                <img className="icon-size" src={getImageSource(ImageConstants.QUESTION_MARK_ICON)} />
                                <span className="status-text">Questions</span>
                            </div>
                            <div className="created-date">{this.props.item.approximateQuestions}</div>
                        </div>
                        <div className="col text-center">
                            <img className="icon-size" src={getImageSource(ImageConstants.CLOCK_ICON_GRAY)} />
                            <span className="status-text">Minutes</span>
                            <div className="created-date">{this.props.item.approximateTime}</div>
                        </div>
                        {
                            this.props.item.status != 'COMPLETED' ?
                                <div className="col text-center">
                                    <div>
                                        <img className="icon-size" src={getImageSource(ImageConstants.DATE_ICON)} />
                                        <span className="status-text">{this.staticText.dueDateText}</span>
                                    </div>
                                    <div className={`created-date ${this.props.item.isDueDatePassed ? 'due-date-passed-date-color' : ''} `}>{CommonUtils.getDate(this.props.item.endTime, true)}</div>
                                </div> : <></>
                        }
                        {this.props.item.finishTime ?
                            <div className="col text-center">
                                <div>
                                    <img className="icon-size" src={getImageSource(ImageConstants.DATE_ICON)} />
                                    <span className="status-text">{this.staticText.submitDateText}</span>
                                </div>
                                <div className="created-date">{` ${CommonUtils.getDate(this.props.item.finishTime)}`}</div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>


                {
                    this.props.item.status == 'COMPLETED' ? this.props.item.type == "ASSESSMENT" ?
                        <div className="assessmentCardDetails mt-3 d-flex justify-content-center align-items-center ">
                            <div className="completedMarkContainer d-flex justify-content-center align-items-center flex-column mr-5">
                                <div className="assessmentScoreDetails">
                                    <img className="exam-pad-size pb-1 mr-1" src={getImageSource(ImageConstants.EXAM_PAD_ICON_GRAY)} />

                                    <span className="assessmentCardText">{this.staticText.marksScoredText}</span>
                                </div>
                                <div className="boldText">{this.props.item.marks ? `${this.props.item.marks} / 100` : "0/100"}</div>
                            </div>
                            {/* {this.props.item.timeTaken && this.props.item.timeTaken > 0 ? <View style={{ marginLeft: 15 }}>
                                <Text style={Styles.boldText}>{this.props.item.timeTaken} {this.staticText.timeText}</Text>
                                <View style={Styles.assessmentDetails}>
                                    <Image source={getImageSource(ImageConstants.CLOCK)} style={Styles.gif} resizeMode="contain" />
                                    <Text style={Styles.assessmentCardText}>{this.staticText.timeTakenText}</Text>
                                </View>
                            </View> :
                                <></>} */}

                            <div className="correctInCorrectContainer d-flex flex-row ">
                                <div style={{ backgroundImage: lgButton }} className="mr-5 correct-button" >
                                    <span className="buttonText">Correct: <span className="answerCountText">{this.props.item.correct}</span></span>
                                </div>
                                <div className="incorrect-button mr-5">
                                    <span className="buttonText">Incorrect: <span className="answerCountText">{this.props.item.inCorrect}</span></span>
                                </div>
                                <div className="not-answered-button mr-5">
                                    <span className="buttonText">Not Answered: <span className="answerCountText">{this.props.item.notAttempted}</span></span>
                                </div>
                            </div>
                            <div className="completedMarkContainer d-flex justify-content-center align-items-center flex-column mr-5">
                                <div className="assessmentScoreDetails">
                                    <img className="exam-pad-size pb-1 mr-1" src={getImageSource(ImageConstants.CLOCK_ICON_GRAY)} />

                                    <span className="assessmentCardText">{`Time Taken`}</span>
                                </div>
                                <div className="boldText">{`${this.props.item.timeTaken ? this.props.item.timeTaken : 0} Mins`}</div>
                            </div>

                        </div>
                        :
                        <div className="assessmentCardDetails mt-3 d-flex justify-content-center align-items-center ">
                            <div className="completedMarkContainer d-flex justify-content-center align-items-center flex-column mr-5">
                                <div className="assessmentScoreDetails">
                                    <img className="exam-pad-size pb-1 mr-1" src={getImageSource(ImageConstants.EXAM_PAD_ICON_GRAY)} />

                                    <span className="assessmentCardText">{this.staticText.marksScoredText}</span>
                                </div>
                                <div className="boldText">{this.props.item.marks ? `${this.props.item.marks} / 100` : "0/100"}</div>
                            </div>
                            {/* {this.props.item.timeTaken && this.props.item.timeTaken > 0 ? <View style={{ marginLeft: 15 }}>
                                        <Text style={Styles.boldText}>{this.props.item.timeTaken} {this.staticText.timeText}</Text>
                                        <View style={Styles.assessmentDetails}>
                                            <Image source={getImageSource(ImageConstants.CLOCK)} style={Styles.gif} resizeMode="contain" />
                                            <Text style={Styles.assessmentCardText}>{this.staticText.timeTakenText}</Text>
                                        </View>
                                    </View> :
                                        <></>} */}

                            <div className="correctInCorrectContainer d-flex flex-row ">
                                <div style={{ backgroundImage: lgButton }} className="mr-5 correct-button" >
                                    <span className="buttonText">Correct: <span className="answerCountText">{this.props.item.correct}</span></span>
                                </div>
                                <div className="incorrect-button">
                                    <span className="buttonText">Incorrect: <span className="answerCountText">{this.props.item.inCorrect}</span></span>
                                </div>
                            </div>

                        </div>
                        :
                        <></>

                }

                <div className="d-flex justify-content-center button-gap">

                    {this.props.item.status == 'COMPLETED' ?
                        <div className="d-flex justify-content-center completed-button-padding">
                            <div onClick={this.viewAnswers} className="view-answer-wrapper cursor-pointer" >
                                <div className="text-center view-answer-button">
                                    {'View Answers'}
                                </div>
                            </div>
                        </div>

                        :
                        <></>
                    }

                    {
                        this.props.item.type == "ASSESSMENT" && this.props.item.status == 'COMPLETED' ?
                            <></>
                            :
                            <div className={`d-flex justify-content-center button-padding ${this.props.item.status == 'COMPLETED' ? "completed-button-padding" : ''}`}>
                                <div className={`button-wrapper ${this.props.item.status == 'COMPLETED' ? "completed-left" : ''} `} >
                                    <div className="text-center start-button cursor-pointer"

                                        onClick={this.navigate}>
                                        {this.getButtonTitle(this.props.item.status)}
                                    </div>
                                </div>
                            </div>

                    }


                </div>

                {/* {this.props.item.status == 'COMPLETED' ? <div onClick={this.viewAnswers} className="view-answer-wrapper cursor-pointer" >
                    <div className="text-center view-answer-button">
                        {'View Answers'}
                    </div>
                </div>
                    :
                    <></>
                }

                {
                    this.props.item.type == "ASSESSMENT" && this.props.item.status == 'COMPLETED' ?
                        <></>
                        :
                        <div className={`d-flex justify-content-center button-padding ${this.props.item.status == 'COMPLETED' ? "completed-button-padding" : ''}`}>
                            <div className={`button-wrapper ${this.props.item.status == 'COMPLETED' ? "completed-left" : ''} `} >
                                <div className="text-center start-button cursor-pointer"

                                    onClick={this.navigate}>
                                    {this.getButtonTitle(this.props.item.status)}
                                </div>
                            </div>
                        </div>

                } */}


                {
                    this.props.item.status != 'COMPLETED' ?
                        <div className="homework-days-text" style={{ backgroundColor: backgroundColor }}>
                            {this.getRemaningDaysDisplay()}
                        </div> :
                        <></>
                }
                <img className="homework-card-footer" src={getThemeImageSource(ImageConstants.HOMEWORK_CARD_FOOTER, this.props.categoryId)} />
            </div>
        )

    }


}
import React from "react";
import './classwork-completion-screen.scss';
import SharedStaticText from "../../../../shared-storage/static-text-store";
import SharedProfile from "../../../../shared-storage/profile";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import HomeworkApi from "../../../apis/homework";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import Path from "../../routes/routes-path";
import CwConfirmationModal from "../../common/cw-confirmation-modal/cw-confirmation-modal";

export default class HomeworkCompletionScreen extends React.Component {

    constructor(props) {
        super(props);
        this.staticText = SharedStaticText.getStaticText('homeworkCompletionScreen');
        this.state.progress = 60
        this.state.categoryId = this.props.categoryThemeId
        this.userInfo = SharedProfile.getProfile();
        this.theme = SharedThemeCategory.getThemeCategory(this.state.categoryId);
        // this.appSounds = sharedAppSounds.getAppSounds();
        // this.playAppSound = CWAppSounds;

        // this.state.concepts = this.props.data.concepts
        //console.log('data', this.props.data)
        this.state.conceptList = this.props.data.concepts
        this.state.concepts = this.props.data.concepts.slice(0, 3);

        // this.conceptList = [
        //     {
        //         title: 'Read and Write Numbers With Place Value',
        //     },
        //     {
        //         title: 'Read and Write Numbers With Place Value',
        //     }

        // ]

        // this.state.conceptList = this.conceptList
        // this.state.concepts = this.conceptList.slice(0, 3);
        this.state.chapterTitle = this.props.data.chapterTitle
        this.state.clearedElos = this.props.data.cleared
        this.state.notClearedElos = this.props.data.notCleared
        this.state.totalElos = this.props.data.cleared.concat(this.props.data.notCleared)
    }

    state = {
        clearedElos: [],
        totalElos: [],
        notClearedElos: [],
        upComing: [{}, {}],
        eloTitle: "",
        time: 0,
        isContinue: true,
        progress: "0",
        fill: 0,
        chapterTitle: '',
        concepts: [],
        conceptList: [],
        showCompletedPopUp: false,
        nodesToPush: []
    }



    componentDidMount() {
        // this.setState({ chapterCompletionAnimation: true }, () => {
        //     this.playAppSound.playSound(this.appSounds.conceptCleared)
        //     setTimeout(() => { this.setState({ chapterCompletionAnimation: false }); }, 3000);
        // })
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    toggleLateralMenu = (flag) => {

        this.props.navigation.push('LateralMenu', { params: { backTo: "LandingScreen" } });
    }

    onContinue = () => {

        let payload = {
            homeworkId: this.props.data.homeworkId
        }

        HomeworkApi.getHomeworkStatus(payload).then((res) => {



            if (res) {
                let cleared = this.groupByConcept(res.cleared, res.concepts)
                let notCleared = this.groupByConcept(res.notCleared, res.concepts)
                let notAttepmted = this.groupByConcept(res.notAttepmted, res.concepts)
                let contentsToRender = ''

                let statusSelected = null
                if (notCleared.length) {
                    statusSelected = 'NOT_CLEARED'
                    contentsToRender = notCleared
                } else if (cleared.length) {
                    statusSelected = 'CLEARED'
                    contentsToRender = cleared
                } else if (notAttepmted.length) {
                    statusSelected = 'NOT_ATTEMPTED'
                    contentsToRender = notAttepmted
                }

                this.setState({
                    cleared,
                    notCleared,
                    notAttepmted,
                    statusSelected,
                    contentsToRender
                }, () => {

                    let concept = notCleared[0]
                    let node = concept.nodes[0]
                    let status = node.status

                    this.navigate(status, node, concept)

                })
            }
        }).catch(err => {
            if (err) {
                return
            }
        })
    }

    groupByConcept = (nodes, concepts) => {

        let conceptGroup = {}
        let conceptOrdered = []

        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i]

            if (conceptGroup[node.conceptKey]) {
                conceptGroup[node.conceptKey].push(node)
            } else {
                conceptGroup[node.conceptKey] = [node]
            }
        }

        for (let i = 0; i < concepts.length; i++) {
            let key = concepts[i].key

            if (conceptGroup[key]) {
                conceptOrdered.push({
                    ...concepts[i],
                    nodes: conceptGroup[key]
                })
            }
        }

        return conceptOrdered
    }


    navigate = (status, node, concept) => {

        let eloIds = node.elos ? node.elos.map(item => item.id) : [];

        this.props.navigation.navigate("StandardHomeworkLearningExperience", {
            params: {
                nodeId: node.id,
                status: status,
                homeworkId: this.props.data.homeworkId,
                chapterKey: node.chapterKey,
                title: node.chapterTitle,
                nodes: concept.nodes,
                categoryThemeId: this.state.categoryId,
                eloIds
            }
        })


    }

    toggleConcepts = () => {
        if (!this.state.showContent) {
            this.setState({ concepts: this.state.conceptList, showContent: !this.state.showContent })
        } else {
            this.setState({ concepts: this.state.conceptList.slice(0, 3), showContent: !this.state.showContent })
        }

    }

    // learnChapter=()=>{
    //     let chapterKey = this.props.homework.chapterKey

    //     if (!chapterKey) {
    //         this.setState({ lmsLoading: false })
    //         this.state.lmsLoading = false
    //         return
    //     }

    //     let payload = {
    //         chapterKey
    //     }

    //     this.setState({ showChapterSelectionStatusLoading: true })

    //     Api.getSingleChapterStatus(payload, (err, res) => {

    //         this.setState({ showChapterSelectionStatusLoading: false })

    //         if (err) {
    //             return;
    //         }

    //         if (res && res.items && res.items.length) {

    //             let data = res.items[0]

    //             let obj = {
    //                 chapter: data,
    //                 color: "",
    //                 arrow: "",
    //                 progress: data.progress,
    //                 key: data.key,
    //                 isLocked: data.isLocked,
    //                 status: data.status,
    //                 categoryThemeId: data.categoryId,
    //                 isFinished: data.isFinished,
    //                 image: data.chapterImage,
    //                 lockedForTrial: data.lockedForTrial
    //             }
    //             this.props.navigation.navigate('ChapterNavigation', { meta: { chapter: obj } })
    //         }
    //     })
    // }

    renderConceptTitle = () => {

        if (this.state.concepts.length === 1) {
            return (
                <div className="concept-name">{this.state.concepts[0].title}</div>
            )
        } else {
            return (
                <>
                    {this.state.concepts.map((item, index) =>
                        <div className="features-item d-flex flex-row justify-content-center align-items-center">
                            <div className="bulletContainer mr-2">
                                <div className="featuresItemBullet"></div>
                            </div>
                            <div className="textContainer">
                                <span className="featuresItemText concept-name">
                                    {item.title}
                                </span>
                            </div>
                        </div>
                    )}

                    {this.state.conceptList && this.state.conceptList.length > 3 ?
                        <div className="" >
                            <span className="moreText mr-1 cursor-pointer" onClick={this.toggleConcepts}>
                                {!this.state.showContent ? this.staticText.moreText : this.staticText.lessText}
                            </span>
                            {this.state.showContent ?
                                <span onClick={this.toggleConcepts} style={{ transform: [{ scaleY: -1 }] }}><img className="cursor-pointer arrow-size" src={getImageSource(ImageConstants.DOWN_ARROW)} /></span>
                                :
                                <img onClick={this.toggleConcepts} className="arrow-size cursor-pointer" src={getImageSource(ImageConstants.DOWN_ARROW)} />
                            }
                        </div>
                        :
                        <></>
                    }

                </>
            )
        }
    }

    onGoHome = () => {
        this.props.navigation.navigate('LandingScreen', { params: { backTo: "LandingScreen" } });
    }

    onPressLearn = () => {
        // moengage.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy, 'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter Key':this.props.homework.chapterKey,'Chapter': this.state.chapterTitle, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'Yes'})
        // FireBaseEvent.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy, 'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter Key':this.props.homework.chapterKey,'Chapter': this.state.chapterTitle, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'Yes'})
        // Facebook.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy, 'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter Key':this.props.homework.chapterKey,'Chapter': this.state.chapterTitle, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'Yes'})
        // BranchIO.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy, 'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter Key':this.props.homework.chapterKey,'Chapter': this.state.chapterTitle, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'Yes'})

        //console.log(this.state.categoryId, this.state.chapterTitle, this.props.data.homeworkId)

        this.props.delegate && this.props.delegate.hideStandardClassworkExperience && this.props.delegate.hideStandardClassworkExperience({
            categoryThemeId: this.state.categoryId,
            title: this.state.chapterTitle,
            homeworkId: this.props.data.homeworkId
        })

        // this.props.navigation.navigate('HomeworkConceptListScreen', {
        //     meta: {
        //         categoryThemeId: this.state.categoryId,
        //         title: this.state.chapterTitle,
        //         homeworkId: this.props.data.homeworkId
        //     }
        // })
    }

    onPressViewAnswers = () => {
        this.props.navigation.navigate('StandardHomeworkAnswers', {
            meta: {
                categoryThemeId: this.state.categoryId,
                title: this.state.chapterTitle,
                homeworkId: this.props.data.homeworkId
            }
        })
    }

    close = () => {
        this.props.navigation.navigate('LandingScreen', { params: { backTo: "LandingScreen" } });
    }

    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({pathname : Path.HOME})
    }

    showCommingSoon() {
        this.setState({showCommingSoon : true})
    }

    hideCommingSoon() {
        this.setState({showCommingSoon : false})
    }




    render() {

        if(this.state.showCommingSoon) {
            return <CwConfirmationModal showInsights = {true} hideInsightsModal = {() => this.hideCommingSoon()}  />
        }

        let lgButton = `linear-gradient(${this.theme[`linearGradient`][`workoutEndCard`][0]}, ${this.theme[`linearGradient`][`workoutEndCard`][1]})`
        let textColor = this.theme[`themeColor`]
        let buttonBg = this.theme[`secondaryColors`][`dragItemBorder`]

        return (
            <>
                <div className="classwork-completion-screen ">
                    <img className="header-image-size" src={getThemeImageSource(ImageConstants.HOMEWORK_COMPLETION_SCREEN_HEADER, this.state.categoryId)} />
                    <div className="d-flex justify-content-center success-screen">
                        {
                            this.state.notClearedElos.length === 0 ?
                                <div className="">
                                    <img className="screen-bg" src={getImageSource(ImageConstants.CLASSWORK_COMPLETION_SCREEN_BG)} />
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <div className="great-message" >{this.staticText.greatTitle} {this.userInfo.name}!</div>
                                        <div className="message">{"Your Classwork is Completed"}</div>

                                        {/* <Image source={getImageSource(ImageConstants.CHAPTER_COMPLETION)} style={Styles.successImage} /> */}
                                        <div className="mt-3">
                                            <img className="success-image-size" src={getImageSource(ImageConstants.SUCCESS_ICON)}></img>
                                        </div>
                                        <div className="text-center chapter-concept-list-card d-flex justify-content-center flex-column mt-4" 
                                        style={{ backgroundImage: this.theme ? lgButton : "" , border : `1px solid ${buttonBg}`}}>
                                            <div className="text-center chapter-title mb-2">{this.state.chapterTitle}</div>
                                            <img className="divider-gray-size mb-2" src={getImageSource(ImageConstants.DIVIDER_GRAY)} />

                                            {this.state.concepts && this.state.concepts.length && this.renderConceptTitle()}
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center mb-3 mt-5">
                                            <div className="go-to-home cursor-pointer text-center mr-4" onClick={() => this.goToHomeScreen()}
                                            style={{color : textColor, border : `1px solid ${this.theme[`themeColor`]}`}}>Go to Home</div>
                                            <div className="continue-learning cursor-pointer text-center" onClick={() => this.onPressLearn()}
                                            style={{backgroundColor : `${this.theme[`themeColor`]}`}}>Continue</div>

                                        </div>

                                        <img className="footer-image-size " src={getThemeImageSource(ImageConstants.HOMEWORK_COMPLETION_SCREEN_FOOTER, this.state.categoryId)}></img>
                                    </div>
                                </div>
                                :
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <div className=" learning-gap mt-2">{this.staticText.foundGapsText ? this.staticText.foundGapsText : "We have identified your Learning Gaps"}</div>
                                    <div className="">
                                        <img className="workout-image-size mt-3" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_MALE_IMAGE, this.state.categoryId)} />
                                    </div>
                                    <div className="mt-2" >
                                        <span className="view-details cursor-pointer" onClick={this.onPressLearn}>{this.staticText.viewDetailsText ? this.staticText.viewDetailsText : "View Details"}</span>
                                    </div>
                                    <div className="lets-fill mt-4">{this.staticText.fillGapsText ? this.staticText.fillGapsText : "Let's fill these gaps"}</div>
                                    <div className="mt-4">
                                        <div className="button text-center mb-2" style={{color : textColor, backgroundColor : buttonBg}}>

                                            <span className="cursor-pointer" onClick={() => this.onPressLearn()}>
                                                {this.staticText.continueButtonTitle ? this.staticText.continueButtonTitle : "CONTINUE"}
                                            </span>

                                        </div>
                                    </div>
                                    
                                </div>

                        }
                    </div>
                    <img className="footer-image-size " src={getThemeImageSource(ImageConstants.HOMEWORK_COMPLETION_SCREEN_FOOTER, this.state.categoryId)}></img>
                </div>
            </>
        )
    }




}

import React, { Component } from 'react';
import './video-checkpoint-question-popup.scss';
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from '../../../../utility/image-source';
import { ImageConstants } from '../../../../constants/constants';
import SharedProfile from '../../../../../shared-storage/profile';
// import PracticeQuestions from '../../check-point-questions/check-point-questions';

class VideoCheckpointQuestionModal extends Component {

    constructor(props) {
        super(props);

        console.log(this.props.content)

    }

    state = {
        enableContinue: false
    }

    onClickOkay = () => {
        this.props.delegate && this.props.delegate.hideModal && this.props.delegate.hideModal();
    }

    onClickClose = () => {
        this.props.delegate && this.props.delegate.hideModal && this.props.delegate.hideModal();
    }

    enableContinueButton() {
        this.setState({enableContinue : true})
    }


    render() {
        return (
            <Modal className="cw-common-custom-modal-center-for-video-checkpoint-2" keyboard = {false} backdrop = 'static' isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                    {this.props.showTrialFeatures ? <img className='badge-image' src={getImageSource(ImageConstants.BADGE_IMAGE)} /> : ''}
                    {/* <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} /> */}
                </div>
                <ModalBody>
                    <div className=' mt-1 justify-content-center  d-flex align-items-center'>
                        {/* <div><img className='chapter-lock-image-size' src={getImageSource(ImageConstants.CHAPTER_LOCK)} /></div> */}
                        <div className='header-text'>{"Please answer the below question to continue watching the video"}</div>
                    </div>
                    <div className="pratice-container">
                        {/* <PracticeQuestions
                            content={this.props.content}
                            groupTitle={this.props.groupTitle}
                            delegate={this.props.delegate}
                            glossary={this.props.glossary}
                            showTitle={this.props.showTitle}
                            categoryId={this.props.categoryId}
                            showBookMark={this.props.showBookMark}
                            bookMarkId={this.props.bookMarkId}
                            contentObj={this.props.contentObj}
                            showInactiveBookmark={this.props.showInactiveBookmark}
                            selectedIndex={this.props.selectedIndex}
                            meta={this.props.meta}
                            enableContinue={() => this.enableContinueButton()} /> */}


                        <div className="d-flex align-items-center justify-content-around mt-2 mb-5">

                            {
                                
                                    <div className="take-challenge cursor-pointer  text-center" onClick={() => this.onClickOkay()}>
                                        {`Okay`}
                                    </div>
                                   

                            }
                        </div>

                    </div>
                </ModalBody>
                <div className="position">
                    <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                </div>
            </Modal>
        );
    }
}

export default VideoCheckpointQuestionModal;

import React from "react";
import './otp.scss';
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import { getImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";

export default class Otp extends React.Component {
    constructor(props) {
        super(props);
        this.staticText = SharedStaticText.getStaticText('otpComponent');
        this.timer(this.staticText.resendOtpTime ? this.staticText.resendOtpTime : 90)
        this.state.seconds = this.staticText.resendOtpTime ? this.staticText.resendOtpTime : 90
    }

    state = {
        otpBox1: '',
        otpBox2: '',
        otpBox3: '',
        otpBox4: '',
        seconds: 90,
        showTimer: true,
    };

    inputs = {};

    onChangeOtpBox(value, key, nextFocusInput, previousFocusInput) {

        if (this.validateInput(+value))
            return;

        this.setState({ [key]: value }, () => {
            this.props.onChange && this.props.onChange(
                this.state.otpBox1 +
                this.state.otpBox2 +
                this.state.otpBox3 +
                this.state.otpBox4
            )
        });

        // if (key == 'otpBox4')
        //     Keyboard.dismiss();

        if (nextFocusInput && value)
            this.inputs[nextFocusInput].focus();
        else if (previousFocusInput && !value)
            this.inputs[previousFocusInput].focus();
    }

    validateInput(value) {
        if (!Number.isInteger(value) || (value < 0 || value > 9))
            return true;
        else
            return false;
    }

    handleDelete(currentBox, previousBox) {
        if (!this.state[currentBox]) {
            this.inputs[previousBox].focus();
        }
    }

    reset = () => {
        this.setState({
            otpBox1: '',
            otpBox2: '',
            otpBox3: '',
            otpBox4: ''
        });
    }


    resendOtp = () => {
        this.setState({ showTimer: true, askAlternate: true }, () => this.timer(this.staticText.resendOtpTime ? this.staticText.resendOtpTime : 90));
        this.reset();
        this.props.delegate && this.props.delegate.resendOtp && this.props.delegate.resendOtp(this.state.alternateActive)
    }


    timer(time) {
        if (time === 0) {
            this.setState({ showTimer: false });
            return;
        }
        this.setState({ seconds: time });
        return setTimeout(() => { this.timer(--time) }, 1000)
    }

    onFocusOtp = (ref) => {

        this.props.otpRef && this.props.otpRef(ref)

        if (this.props.isInvalidOtp)
            this.props.delegate && this.props.delegate.resetInvalidOtp && this.props.delegate.resetInvalidOtp()
    }

    toggleOtpOption = () => {
        this.setState({ alternateActive: !this.state.alternateActive })
    }



    render() {
        return (
            <>
                {!this.props.hideLable && <div className="place-holders">{this.staticText.OTPTitle}</div>}
                <div className="otp-boxes">

                    {/* <div className="passcode-boxes">
                        <input id="otpBox1" className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, "otpBox")} onFocus={() => this.onFocusEvent(1, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox1')} value={this.state.otpBox1} />
                        <input id="otpBox2" className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, "otpBox")} onFocus={() => this.onFocusEvent(2, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox2')} value={this.state.otpBox2} />
                        <input id="otpBox3" className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, "otpBox")} onFocus={() => this.onFocusEvent(3, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox3')} value={this.state.otpBox3} />
                        <input id="otpBox4" className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, "otpBox")} onFocus={() => this.onFocusEvent(4, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox4')} value={this.state.otpBox4} />
                    </div> */}

                    <input id="otpBox1"
                        className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`}
                        type="text"
                        maxlength="1"
                        // onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, "otpBox")} 
                        onFocus={() => this.onFocusOtp(this.inputs['otpBox1'])}
                        onChange={(e) => this.onChangeOtpBox(e.target.value, 'otpBox1', 'otpBox2')}
                        value={this.state.otpBox1}
                        autoFocus={true}
                        onClick={() => this.inputs['otpBox1'].focus()}
                        ref={input => {
                            this.inputs['otpBox1'] = input;
                        }} />

                    <input id="otpBox2"
                        className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`}
                        type="text"
                        maxlength="1"
                        // onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, "otpBox")} 
                        onFocus={() => this.onFocusOtp(this.inputs['otpBox2'])}
                        onChange={(e) => this.onChangeOtpBox(e.target.value, 'otpBox2', 'otpBox3', 'otpBox1')}
                        value={this.state.otpBox2}
                        onClick={() => this.inputs['otpBox2'].focus()}
                        ref={input => {
                            this.inputs['otpBox2'] = input;
                        }}
                        onKeyPress={({ nativeEvent }) => {
                            nativeEvent.key === 'Backspace' && this.handleDelete('otpBox2', 'otpBox1')
                        }} />

                    <input id="otpBox3"
                        className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`}
                        type="text"
                        maxlength="1"
                        // onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, "otpBox")} 
                        onFocus={() => this.onFocusOtp(this.inputs['otpBox3'])}
                        onChange={(e) => this.onChangeOtpBox(e.target.value, 'otpBox3', 'otpBox4', 'otpBox2')}
                        value={this.state.otpBox3}
                        onClick={() => this.inputs['otpBox3'].focus()}
                        ref={input => {
                            this.inputs['otpBox3'] = input;
                        }}
                        onKeyPress={({ nativeEvent }) => {
                            nativeEvent.key === 'Backspace' && this.handleDelete('otpBox3', 'otpBox2')
                        }} />


                    <input id="otpBox4"
                        className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`}
                        type="text"
                        maxlength="1"
                        // onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, "otpBox")} 
                        onFocus={() => this.onFocusOtp(this.inputs['otpBox4'])}
                        onChange={(e) => this.onChangeOtpBox(e.target.value, 'otpBox4', null, 'otpBox3')}
                        value={this.state.otpBox4}

                        onClick={() => this.inputs['otpBox4'].focus()}
                        ref={input => {
                            this.inputs['otpBox4'] = input;
                        }}
                        onKeyPress={({ nativeEvent }) => {
                            nativeEvent.key === 'Backspace' && this.handleDelete('otpBox4', 'otpBox3')
                        }} />
                </div>

                <div className="resend-otp-wrapper">
                    <div>
                        {this.props.isInvalidOtp &&
                            <div className="otp-error-message">
                                {this.staticText.otpErrorMessage}
                            </div>
                        }
                    </div>

                    <div>
                        {!this.state.showTimer ?
                            <div className="d-flex flex-row">
                                {this.state.askAlternate && this.props.showAlternateOtpOptions ?
                                    <div className="">
                                        <div style={{}} onClick={this.toggleOtpOption}>
                                            {this.state.alternateActive ?
                                                <div className="">
                                                    <img src={getImageSource(ImageConstants.BOOKMARK_ACTIVE_TICK)} className="tick-mark" />
                                                </div>
                                                : <div className="check-box"></div>
                                            }
                                        </div>
                                        <div className="option-text-container"><div className="option-text">{this.props.hasEmail ? 'Receive OTP on WhatsApp and Email' : 'Receive OTP on WhatsApp'}</div></div>
                                    </div>
                                    :
                                    <></>
                                }


                                <div className="resend-otp-text cursor-pointer" onClick={this.resendOtp}> {this.staticText.resendOtp}</div>

                            </div>
                            :
                            <div className="resend-otp-timer-text">{`${this.staticText.resendOtpIn} ${this.state.seconds}s`}</div>
                        }
                    </div>
                </div>
            </>
        )

    }
}
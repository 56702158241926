import React from "react";
import './single-select.scss';
import QuestionTitle from "../../../../common/question/question-title/question-title";
import QuestionImage from "../../../../common/cw-question-image/cw-question-image";
import RadioGroup from "../../../../common/radio/radio-group";
import AnswerTab from "../../answer-tab/answer-tab";
import { getThemeImageSource } from "../../../../../utility/image-source";
import { ImageConstants } from "../../../../../constants/constants";
import SharedThemeCategory from "../../../../../../shared-storage/category-theme";


class SingleSelect extends React.Component {

    state = {
        content: null
    }

    constructor(props) {
        super(props);
        let content = JSON.stringify(this.props.content)
        this.state.content = JSON.parse(content);
        if (this.props.showMyAnswer) {
            this.showMyAnswer()
        }
    }

    componentDidMount() {

    }

    updateAnswer = (payload) => {

        let content = this.state.content;
        let options = content.content.options;

        options = options.map(opt => {
            if (opt.id == payload.answer[0].id) {
                opt.isCorrect = true;
            } else {
                opt.isCorrect = false;
            }
            return opt
        });
        content.content.options = options;
        this.setState({ content: content })
    }

    showMyAnswer = () => {
        if (this.props.answer) {
            this.updateAnswer(this.props.answer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.stringify(this.props.content)
        this.setState({ content: JSON.parse(content) })
    }

    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer })
    }

    render() {
        let content = this.state.content;

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)
        let textColor = this.theme['themeColor']
        let borderColor = this.theme[`themeColor`]

        return (

            content ?
                <div>

                    <div className="single-select">

                        <QuestionTitle title={content.content.title} hideBackground={true} categoryThemeId={this.props.categoryThemeId} />

                        <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />

                        {
                            this.props.status != "NOT_ATTEMPTED" ?
                                <div className="view-answer-button ml-1 mt-3 cursor-pointer" style={{color : textColor, borderColor : borderColor}}
                                onClick={this.toggleViewAnswer}>
                                    {
                                        this.state.showCorrectAnswer ? 'Hide Answer' : 'View Answer'
                                    }
                                    {/* <span>View Answer</span> */}
                                    <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} />
                                </div>
                                :
                                <></>
                        }



                        {
                            this.state.showCorrectAnswer ?
                                <>
                                    {
                                        this.props.status != "NOT_ATTEMPTED" ?
                                            <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.props.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.answer}></AnswerTab>
                                            : <></>
                                    }

                                    {
                                        this.props.status != "NOT_ATTEMPTED" ?
                                            <div className="options">
                                                <RadioGroup options={content.content.options} isCheckPoint={this.props.isCheckPoint} isReadOnly={true} categoryThemeId={this.props.categoryThemeId} />
                                            </div>
                                            :
                                            <></>
                                    }
                                </>

                                :

                                <></>
                        }



                    </div>

                </div> : <></>

        );
    }
}


export default SingleSelect;


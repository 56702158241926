import React from "react";
import './select-user.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";


class SelectUser extends React.Component {

    state = {
        selectedUserIndex: '',
        usersList: this.props.usersList?.length ? this.props.usersList : [],
        basePath: this.props.basePath ? this.props.basePath : '',
        showWrongPasscodeErrorMessage: this.props.showWrongPasscodeErrorMessage ? true : false
    }

    users = [
        {
            "name": "Saravanan",
            "nickName": "Rajendiran",
            "username": "saravanan"
        },
        {
            "name": "raja",
            "nickName": "saravanan",
            "username": "raja"
        },
        {
            "name": "THIRU",
            "nickName": "n Thiru",
            "username": "thiru"
        },
        {
            "name": "Sara",
            "nickName": "Sara n",
            "username": "sara"
        },
        {
            "name": "class 10",
            "nickName": "class 10 sara",
            "username": "class10"
        },
        {
            "name": "Thirupathy Rajendiran 3",
            "nickName": "Rajendiran",
            "username": "thirupathyrajendiran3"
        },
        // {
        //     "name": "Thirupathy Rajendiran 6",
        //     "nickName": "Rajendiran",
        //     "username": "thirupathyrajendiran6"
        // },
        // {
        //     "name": "Senior 6",
        //     "nickName": "sen 6",
        //     "username": "senior6"
        // },
        // {
        //     "name": "Senior slot 7",
        //     "nickName": "senior slot 71",
        //     "username": "seniorslot7"
        // },
        // {
        //     "name": "Junior 7 2",
        //     "nickName": "Junior 72",
        //     "username": "junior72"
        // }
    ]

    onSelectUser(user, index) {

        if (this.state.selectedUserIndex === index) {
            this.setState({ selectedUserIndex: '', selectedUser: '', showWrongPasscodeErrorMessage: false })
        } else {
            this.setState({ selectedUserIndex: index, selectedUser: user, showWrongPasscodeErrorMessage: false })
        }
    }

    updateUserLogin() {


        if (this.state.selectedUserIndex !== 0 && !this.state.selectedUserIndex) return

        if (this.props.passcode && !this.props.otp) {

            this.props.delegate && this.props.delegate.updatePasscodeLoginUser && this.props.delegate.updatePasscodeLoginUser(this.state.selectedUser.username)

        } else if (this.props.forgotPasscodeFlow) {

            this.props.delegate && this.props.delegate.showPasscodeResetCard && this.props.delegate.showPasscodeResetCard(this.state.selectedUser.username)

        } else if (this.props.setNewPasscodeFlow) {
            this.props.delegate && this.props.delegate.showSetNewPasscode && this.props.delegate.showSetNewPasscode(this.state.selectedUser)
        } else {
            this.props.delegate && this.props.delegate.updateLoginUser && this.props.delegate.updateLoginUser(this.state.selectedUser.username)
        }

    }



    render() {
        return (
            <div className="select-user container-fluid">
                <div className="container-height">
                    <div className="header-abs-div">
                        <img className="login-header" src={getImageSource(ImageConstants.LOGIN_HEADER)} />
                    </div>
                    <div className="padding-for-content">
                        {/* <div className="row m-0 p-0"> */}
                        <div className="continue-as">Continue as</div>
                        {/* </div> */}

                        {this.props.forgotPasscodeFlow ? <div className="sub-text">Select your profile to change Passcode</div> : ''}
                        {this.props.setNewPasscodeFlow ? <div className="sub-text">Select your profile to Set New Passcode</div> : ''}

                        <div className="student-container" >
                            {this.state.usersList.map((user, index) => (
                                <div className={`student-card cursor-pointer
                                ${index == 0 || index == 1 || index == 2 ? 'std-card-margin-top' : ''}
                                ${this.state.selectedUserIndex === index ? 'selected-user-bg' : ''}`}
                                    onClick={() => this.onSelectUser(user, index)}>
                                    {this.state.selectedUserIndex === index ? <img className="green-tick-mark" src={getImageSource(ImageConstants.GREEN_TICK_MARK)}></img> : ''}
                                    <div className="profile-avatar">
                                        {user.image ? <img src={`${this.state.basePath + user.image}`} /> : <img className="std-image" src={getImageSource(ImageConstants.PROFILE_PICTURE)}></img>}
                                    </div>
                                    <div className="mt-2 user-name text-center px-2">{user.nickName}</div>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div className="d-flex justify-content-center continue-container">
                        <div className={`continue-button pt-2 ${this.state.selectedUserIndex || this.state.selectedUserIndex === 0 ? 'continue-enabled cursor-pointer' : 'continue-disabled'}`} onClick={() => this.updateUserLogin()} >
                            Continue
                        </div>


                    </div>
                    {
                        this.state.showWrongPasscodeErrorMessage ? <div className="text-center text-danger mt-2">Please Select Correct User to Login for the Passcode you entered</div> : <></>
                    }
                    <div className="footer-abs-div">
                        <img className="login-footer" src={getImageSource(ImageConstants.LOGIN_FOOTER)} />
                    </div>
                </div>
            </div>
        )
    }


}


export default SelectUser;
import React from "react";
import './exam-prep-kit-intro-screen.scss';
import CommonApi from "../../../apis/common";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedProfile from "../../../../shared-storage/profile";
import ExamPrepKitApi from '../../../apis/exam-prep-kit'

class ExamPrepKitIntroScreen extends React.Component {

    state = {
        content: ''
    }

    constructor(props) {
        super(props)
        this.userInfo = SharedProfile.getProfile()
        this.getIntroScreenContent()
        this.updatePrepkitIntro()

    }

    getIntroScreenContent = () => {

        CommonApi.getMiscellaneousList({ type: 'PREPKIT_CONTENT' }).then((err, data) => {
            if (data) {
                console.log(data.response)
                // this.toggleLoading(false)
                let res = data.response;

                let content = res.length ? res[0] : {}
                this.setState({ content: content, staticText: content.staticText })
            }
        }).catch(err => {
            console.log(err)
            if (err) {
                // this.toggleLoading(false)
                return
            };
        })
    }

    updatePrepkitIntro = () => {
        ExamPrepKitApi.updatePrepkitIntro({}).then((res) => {
            let userInfo = this.userInfo
            userInfo.isExamPrepkitIntroShowed = true
            SharedProfile.setProfile(userInfo)
        }).catch(err => {
            console.log(err)
            if (err) {
                return
            }
        })
    }

    onStart() {
        this.props.delegate && this.props.delegate.showPrepKitChapterList && this.props.delegate.showPrepKitChapterList()
    }


    render() {
        return (
            <div className="exam-prep-kit-intro-screen">
                <div className="title mt-5">Prepare</div>
                <div className="text-center">
                    <img className="prep-kit-bg-image " src={getImageSource(ImageConstants.PREP_KIT_BG)} />
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <div className="description-text">{this.state.content && this.state.content.description ? this.state.content.description : "Exam prep kit helps you to quickly revise all the important concepts of the chapter including Practice question to score high in your school Exams"}</div>
                </div>
                <div className="mt-4 d-flex justify-content-center">
                    <div className="learning-gap-button text-center cursor-pointer" onClick={() => this.onStart()}>{this.state.staticText && this.state.staticText.buttonTitle ? this.state.staticText.buttonTitle : "GREAT"}</div>
                </div>
            </div>
        )
    }
}

export default ExamPrepKitIntroScreen;
import React, { Component } from 'react';
import './practice-modal.scss';
import Question from '../../../common/question/question';
import AssessmentHeader from './header/header';
import { ImageConstants, Constants } from '../../../../constants/constants';
import ExamPrepKitApi from '../../../../apis/exam-prep-kit';
import Loading from '../../../common/loading/loading';
import SharedProfile from '../../../../../shared-storage/profile';
import { getImageSource } from '../../../../utility/image-source';
import MasterDataApi from '../../../../apis/master-data';
import HomeworkApi from '../../../../apis/homework';


export const QuestionContext = React.createContext({ disableNext: false })

export default class PracticeModal extends Component {

    workflowEngine = null;
    worflowNodes = [];
    questions = [];
    state = {
        index: 0,
        questions: [],
        answers: [],
        learningElos: [],
        showSuccessFailureScreen: false,
        stepIndex: 0,
        showHint: false,
        showSolvedExamplesModal: false,
        showInvalidAnswerModal: false,
        showCorrectIncorrectModal: false,
        showCorrectAnswer: false,
        isReadOnly: false,
        showNewConceptModal: false,
        newConceptTitle: '',
        disableNext: false,
        loading: true,
        questionLoading : true
    };
    questionComponent = null;

    failedInAssessment = false;
    successFailureScreenData = {};
    isRevise = false;
    isReassessment = false;

    hintShowed = false;
    solvedExamplesShowed = false;

    isCorrect = false;
    eloIds = []
    noBlur = false;

    elosCompleted = {};
    lastResumeId = {}

    constructor(props) {

        super(props);
        let { navigation } = this.props;
        this.params = this.props.params
        this.userInfo = SharedProfile.getProfile();

        if (this.params.progress && this.params.progress[this.params.eloId]) {

            this.solvedExampleProgress = this.params.progress[this.params.eloId]

            if (this.params.linkedElo)
                this.linkedEloProgress = this.params.progress[this.params.linkedElo]

            if (this.solvedExampleProgress && this.solvedExampleProgress.status == 'COMPLETED') {
                this.elosCompleted[this.params.eloId] = { status: "COMPLETED" }
            }

            if (this.linkedEloProgress && this.linkedEloProgress.status == 'COMPLETED') {
                this.elosCompleted[this.params.linkedElo] = { status: "COMPLETED" }
            }

            if (this.solvedExampleProgress && this.solvedExampleProgress.status != 'COMPLETED' && this.solvedExampleProgress.resumeId) {
                this.state.index = this.solvedExampleProgress.resumeId
            } else if (this.solvedExampleProgress &&
                this.solvedExampleProgress.status == 'COMPLETED' &&
                (this.linkedEloProgress && this.linkedEloProgress.status != 'COMPLETED')) {
                this.state.index = (this.solvedExampleProgress.resumeId + (this.linkedEloProgress.resumeId ? this.linkedEloProgress.resumeId + 1 : 1))
            }

            if (this.solvedExampleProgress && this.solvedExampleProgress.status == 'COMPLETED') {

                if (!this.params.linkedElo) {
                    this.state.status = 'COMPLETED'
                } else if (this.params.progress[this.params.linkedElo] && this.params.progress[this.params.linkedElo].status == 'COMPLETED') {
                    this.state.status = 'COMPLETED'
                }
            }
        }

        this.getPracticeContent()
    }

    componentDidMount() {
        // BackHandler.addEventListener('hardwareBackPress', this.backhandler);
    }

    getPracticeContent = () => {


        let payload = {
            eloId: this.params.eloId,
            chapterKey: this.params.chapterKey
        }

        if (this.props.isPrimary) {
            payload.isPrimary = true
        }

        if (!this.state.loading)
            this.setState({ loading: true })

        ExamPrepKitApi.getPrepKitPractice(payload).then((response) => {
            this.setState({ loading: false })

            if (!response.length) {
                this.props.close && this.props.close()
            }

            this.contents = response;

            this.elos = []
            this.eloHash = {}

            for (let i = 0; i < this.contents.length; i++) {
                let eloId = this.contents[i].eloId

                if (!this.eloHash[eloId]) {
                    this.eloHash[eloId] = 1
                    this.elos.push(eloId)
                } else {
                    this.eloHash[eloId] = i + 1
                }
            }

            console.log(response)

            this.setState({ questions: response })
        }).catch(err => {
            console.log(err);
            if (err) {
                this.setState({ loading: false })
                this.props.close && this.props.close()
                return
            }
        })
    }



    backhandler = () => {
        return true
    }

    getAssessmentContent = (isNext, callback) => {

        this.noBlur = false

        let isTryAgain = false;
        if (this.params.status === Constants.ELO_STATUS_NOT_CLEARED || this.params.status === Constants.TRY_AGIN_IN_PROGRESS) {
            isTryAgain = true;
        }

        let currentNode = this.getCurrentNode();

        if (!isTryAgain && this.params.status != Constants.ELO_STATUS_IN_PROGRESS) {
            // FirebaseEvent.logCustomEvent(FirebaseEvents.ELO_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
            // Facebook.logCustomEvent(FirebaseEvents.ELO_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
            // BranchIO.logCustomEvent(FirebaseEvents.ELO_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
        }

        let payload = {
            nodeId: this.params.nodeId,
            isTryAgain: isTryAgain,
            isNext: isNext
        };

        if (this.params.isChapterNotCompleted)
            payload.isChapterNotCompleted = true;

        this.getContent(payload, callback);
    }

    letsTryAgain = () => {

        this.noBlur = true;

        let payload = {
            nodeId: this.params.nodeId,
            isTryAgain: true,
            isNext: false
        };

        this.getContent(payload, (err, data) => {
            if (err) {
                //alert('Try again get content is error');
                return;
            }
            this.setState({ questions: data.content, index: 0 });
            this.hideSuccessFailureScreen();
        });
    }

    getContent = (payload, callback) => {

        MasterDataApi.getEloContents(payload).then((data) => {

            if (data.contentType == "LEARNING") {

                let elos = data.elos.map(elo => elo.id);

                this.getLearningContent(elos);
            }
        }).catch(err => {
            console.log(err)
            if (callback) {
                callback(err, null);
                return;
            }
        })
    }

    moveToNextWorkflowNodeForAssessment = (node) => {

        let index = -1;

        let nodes = this.params.nodes;

        this.params.nodeId = node && node.id ? node.id : this.params.nodeId;

        let currentConceptContinue = node && node.id ? true : false; /** corrent concept continue when the user press try again from the concept list screen */

        for (let i = 0; i < nodes.length; i++) {

            if (nodes[i].id == this.params.nodeId) {
                index = i;
                break;
            }
        }

        /** NEXT NODE TO START WHEN COMES TRY AGAIN (FROM CONCEPT LIST SCREEN) START */
        if (index != -1 && currentConceptContinue) {

            this.params.status = '';
            this.params.nodeId = nodes[index].id
            this.reset();
            this.getAssessmentContent(false, (err, data) => {

                if (err) {
                    //alert('get content error');
                    return;
                }
                this.setQuestion(data);
            });
            this.hideSuccessFailureScreen();
            return;
        }
        /** NEXT NODE TO START WHEN COMES TRY AGAIN (FROM CONCEPT LIST SCREEN) END */

        if (index != -1 && index < nodes.length - 1) {

            //For show concept jumping pop up
            if (this.params.nodes[index].conceptKey != this.params.nodes[index + 1].conceptKey) {
                this.setState({ showNewConceptModal: true, newConceptTitle: this.params.nodes[index + 1].conceptTitle })
            }

            this.params.status = '';
            this.params.nodeId = currentConceptContinue ? nodes[index].id : nodes[index + 1].id;
            this.reset();


            this.getAssessmentContent(false, (err, data) => {

                if (err) {
                    //alert('get content error');
                    return;
                }
                this.setQuestion(data);
            });
            this.hideSuccessFailureScreen();
        }
        else {
            this.params.nodeId = null;
            this.params.status = null;
        }
    }

    setQuestion = (data, isResume) => {

        this.totalElos = data.totalElos;
        this.eloPosition = data.eloPosition;

        if (data.isReassessment)
            this.isReassessment = true;
        else
            this.isReassessment = false;

        this.setState({ questions: data.content, index: 0 });

        if (data.resume && data.resume.contentId) {
            let resumeIndex = 0;

            for (let i = 0; i < data.content.length; i++) {
                if (data.content[i].id === data.resume.contentId) {
                    resumeIndex = i;
                    break;
                }
            }

            if (data.content[resumeIndex] && data.content[resumeIndex].details && data.content[resumeIndex].details.content && data.content[resumeIndex].details.content.isMultiStep && isResume) {

                let stepIndex = 0;

                if (data.content[resumeIndex].details.content.steps &&
                    data.content[resumeIndex].details.content.steps.length &&
                    data.content[resumeIndex].answer &&
                    data.content[resumeIndex].answer.steps.length) {
                    stepIndex = data.content[resumeIndex].details.content.steps.length <= data.content[resumeIndex].answer.steps.length ? data.content[resumeIndex].answer.steps.length - 1 : data.content[resumeIndex].answer.steps.length
                }

                this.setState({ index: resumeIndex, stepIndex });

            } else {
                this.setState({ index: resumeIndex });
            }
        }
    }

    reset = () => {
        this.setState({
            questions: [],
            answers: [],
            learningElos: [],
            index: 0
        });
    }

    next() {

        let answer = this.questionComponent && this.questionComponent.getAnswer();
        this.answer = answer
        let question = this.state.questions[this.state.index].details;
        this.question = question;// Add for correct Correct Incorrect Next Flow


        if (answer != null) {
            if (question.content.isMultiStep) {

                let ques = question.content.steps[this.state.stepIndex];
                let ans = answer.steps[this.state.stepIndex];

                this.setState({ disableNext: true })
                this.validateAnswer(ques, ans, (err, res) => {

                    if (err) {
                        this.setState({ disableNext: false })
                        return;
                    }
                    this.setState({ disableNext: false })
                    if (res.isCorrect) {

                        if (this.state.stepIndex === question.content.steps.length - 1)
                            this.ShowCorrectIncorrectModel(true);
                        else
                            this.nextContinue(question, answer, false);

                    }
                    else {
                        this.ShowCorrectIncorrectModel(false);
                    }
                });

            } else {

                this.setState({ disableNext: true })
                this.validateAnswer(question, answer, (err, res) => {

                    if (err) {
                        this.setState({ disableNext: false })
                        return;
                    }
                    this.setState({ disableNext: false })
                    if (res.isCorrect)
                        this.ShowCorrectIncorrectModel(true);
                    else
                        this.ShowCorrectIncorrectModel(false);
                });
            }
        } else {
            this.toggleInvalidAnswerModal(true);
        }
    }

    nextQuestionOnCorrectInCorrectAnswer() {

        let question = this.question;
        let answer = this.answer;

        if (question.content.isMultiStep) {
            if (this.isCorrect)
                this.nextContinue(question, answer, false);
            else
                this.nextContinue(question, answer, true);

        } else {
            this.nextContinue(this.question, this.answer);
        }

        this.setState({ showCorrectIncorrectModal: false, isReadOnly: false });
    }

    nextContinue = (question, answer, branchQuesExit) => {

        console.log(this.state.questions)

        if (this.state.questions.length > this.state.index) {
            this.state.questions[this.state.index].answer = answer;
        }

        if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex && !branchQuesExit) { /** If question is multi step  */
            this.setState({ stepIndex: this.state.stepIndex + 1, questionLoading: false }, () => {
                this.setState({ questionLoading: true })
            });
        } else { /** If it normal question */

            if (this.state.questions[this.state.index + 1] && (!this.state.questions[this.state.index + 1].status || this.state.questions[this.state.index + 1].status != 'COMPLETED')) {

                let index = this.state.index + 1;
                if (this.elos.length > 1 && this.elos[1] == this.state.questions[this.state.index + 1].eloId) {
                    index = index - this.eloHash[this.elos[0]]
                }
                this.updatePrepKitActivity(index, this.state.questions[this.state.index + 1].eloId)
            }

            if (this.state.questions.length == 1) {
                this.updateEloCompletedCompleted(this.state.questions[this.state.index].eloId, this.state.index)
            }
            else if ((this.state.questions.length - 1) <= (this.state.index + 1)) {
                this.updateEloCompletedCompleted(this.state.questions[this.state.index].eloId, this.state.index)
            } else {
                if (this.state.questions[this.state.index].eloId != this.state.questions[this.state.index + 1].eloId) {
                    this.updateEloCompletedCompleted(this.state.questions[this.state.index].eloId, this.state.index)
                }
            }

            if (this.state.stepIndex)
                this.resetStepIndex();

            if (this.state.questions.length > this.state.index + 1) {
                this.setState({ index: this.state.index + 1, questionLoading: false }, () => {
                    this.setState({ questionLoading: true })
                });
            }
            else { // Last content reached
                this.props.close && this.props.close(this.lastResumeId, this.elosCompleted)
            }
        }
    }

    resetStepIndex = () => {
        this.setState({ stepIndex: 0 });
    }

    validateAnswer = (question, answer, callback) => {

        let payload = { question, answer };

        HomeworkApi.validateAnswer(payload).then((res) => {
            console.log(res.data)
            callback(null, res.data);
        }).catch(err => {
            console.log(err)
            callback(err, null)
        })
    }

    toggleInvalidAnswerModal = (value) => {
        this.setState({ showInvalidAnswerModal: value });
    }

    ShowCorrectIncorrectModel = (isCorrect) => {
        this.isCorrect = isCorrect;
        this.setState({ showCorrectIncorrectModal: true });
    }

    navigateGroup = (index, navigate) => {
        this.setState({ index })
    }

    hideCorrectIncorrectModal() {
        this.setState({ showCorrectIncorrectModal: false });
    }

    goBack = () => {
        this.props.close && this.props.close(this.lastResumeId, this.elosCompleted)
    }

    onOk = () => {
        this.toggleInvalidAnswerModal(false);
    }


    updateEloCompletedCompleted = (eloId, index) => {

        this.elosCompleted[eloId] = { status: "COMPLETED" }

        let payload = {
            chapterKey: this.params.chapterKey,
            type: "PRACTICE_ELO_COMPLETED",
            eloId
        }
        ExamPrepKitApi.updatePrepKitProgress(payload, (err, response) => {
            if (err) {
                return
            }

            this.contents[index].status = 'COMPLETED'
        })
    }
    updatePrepKitActivity = (index, eloId) => {

        this.lastResumeId[eloId] = index

        let payload = {
            chapterKey: this.params.chapterKey,
            resumeId: index,
            type: "PRACTICE_ELO_COMPLETED",
            eloId
        }

        ExamPrepKitApi.updatePrepKitActivity(payload).then((response) => {
        }).catch(err => {
            console.log(err);
            if (err) {
                return
            }
        })
    }


    render() {

        return (
            <>
                <div>
                    {this.state.learningElos.length === 0 && this.state.questions.length > 0 && !this.state.loading ?
                        <div >
                            {
                                this.state.questionLoading ?
                                    <>
                                        <div >
                                            <AssessmentHeader
                                                title={this.params.headerTitle}
                                                subTitle={this.params.title}
                                                headerIcon={getImageSource(ImageConstants.PRACTICE)}
                                                totalPage={this.state.questions.length}
                                                activeIndex={this.state.index}
                                                showPagination={true}
                                                navigateTill={this.state.status == 'COMPLETED' ? this.state.questions.length : this.state.index}
                                                delegate={this}
                                                categoryId={this.params.categoryThemeId}
                                                hideLateralMenu
                                            ></AssessmentHeader>
                                        </div>
                                        <div>
                                            {this.state.questions && this.state.questions.length > 0 &&
                                                <div>
                                                    <QuestionContext.Provider value={{ disableNext: this.state.disableNext }}>
                                                        <Question delegate={this} ref={(ref) => { this.questionComponent = ref }}
                                                            answer={this.state.questions[this.state.index].answer}
                                                            content={this.state.questions[this.state.index].details}
                                                            stepIndex={this.state.stepIndex} showHint={this.state.showHint}
                                                            showSolvedExamplesModal={this.state.showSolvedExamplesModal}
                                                            showInvalidAnswerModal={this.state.showInvalidAnswerModal}
                                                            showCorrectIncorrectModal={this.state.showCorrectIncorrectModal}
                                                            isAnswerCorrect={this.isCorrect}
                                                            userAnswerForReadOnly={this.answer ? this.answer : {}}
                                                            hideBackground
                                                            // categoryThemeId={this.state.questions[this.state.index].categoryThemeId}
                                                            categoryThemeId={1}
                                                        ></Question>
                                                    </QuestionContext.Provider>
                                                </div>}
                                        </div>
                                    </> : <></>
                            }


                        </div> : <Loading />}
                </div>
            </>

        )
    }
}
import React from "react";
import './select-days.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";

class SelectDays extends React.Component {

    state = {
        items: [
            {
                type: 'Two days',
                days: 2,
                inactiveImage: getImageSource(ImageConstants.TWO_DAYS),
                activeImage: getImageSource(ImageConstants.TWO_DAYS_ACTIVE)

            },
            {
                type: 'Three Days',
                days: 3,
                inactiveImage: getImageSource(ImageConstants.THREE_DAYS),
                activeImage: getImageSource(ImageConstants.THREE_DAYS_ACTIVE)
            },
            {
                type: 'Five Days',
                days: 5,
                inactiveImage: getImageSource(ImageConstants.FIVE_DAYS),
                activeImage: getImageSource(ImageConstants.FIVE_DAYS_ACTIVE)
            }
        ],

        selectedDays: 3,
        selectedIndex: 1,

    }


    select = (item, index) => {
        this.setState({ selectedIndex: index, selectedDays: item.days })
    }

    continue = () => {
        this.props.onPressContinue && this.props.onPressContinue(this.state.selectedDays)
    }

    getButtons = () => {
        return (
            !this.state.selectedDays ?
                <div className="text-center continue-button continue-button-disabled">
                    Continue
                </div>
                :
                <div className="text-center continue-button cursor-pointer" onClick={() => this.continue()}>
                    Continue
                </div>
        )

    }

    goBack() {
        this.props.goBackToClassDetailsScreen && this.props.goBackToClassDetailsScreen()
    }


    render() {
        return (
            <div>
                <div className="heading">Days I want to Practice in a week</div>
                <div className="sub-heading">You can change it anytime later</div>
                <div className="select-days-wrapper mt-4">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        {this.state.items.length ? this.state.items.map((item, index) => (
                            <div className="mr-5">
                                <img onClick={() => this.select(item, index)}
                                    className="select-days-image cursor-pointer"
                                    src={this.state.selectedIndex === index ? item.activeImage : item.inactiveImage} />
                            </div>
                        ))
                            : ''}
                    </div>
                    <div className="d-flex justify-content-center flex-column align-items-center mt-4">
                        <div className="days-text">{this.state.selectedDays} days</div>
                        <div className="week-text">in a week</div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="text-center back-button cursor-pointer mr-5" onClick={() => this.goBack()}>Back</div>
                    {this.getButtons()}
                </div>
            </div>
        )
    }
}

export default SelectDays;
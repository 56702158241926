import React from "react";
import { ImageConstants } from "../../../constants/constants";
import { getImageSource } from "../../../utility/image-source";
import './assessment-header.scss';
import SharedThemeCategory from "../../../../shared-storage/category-theme";

class AssessmentHeader extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            remainingTime: 0
        };



    }

    componentDidMount() {

        if (this.props.timer)
            this.timer()

        if (this.props.totalQuestions && this.props.questionCount) {
            this.setProgress()
        }

    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.categoryThemeId != this.props.categoryThemeId) {
    //         setCategoryThemeStyles(this.props.categoryThemeId);
    //         this.forceUpdate();
    //     }
    // }

    setProgress() {
        let element = document.getElementById('myprogressBar')
        let width = 0;
        // If you want % Completed based Progress bar then add -1 with currentQuestionNumber
        //width = (18 / this.props.totalQuestions) * (this.props.currentQuestionNumber - 1)

        // If you want Question Number Based Progress Bar then Don't add with -1 currentQuestionNumber
        width = (18 / this.props.totalQuestions) * (this.props.currentQuestionNumber)
        element.style.width = width + 'rem';

        let myCircleStatus = document.getElementById("myCircleStatus")
        let left = 0;
        left = width - 0.5;
        myCircleStatus.style.left = left + 'rem';

        let questionNumber = document.getElementById("question-number")
        let questionNumberLeft = width - 1.1;
        if (this.props.totalQuestions >= 10) {
            questionNumberLeft -= 0.3;
        }
        questionNumber.style.left = questionNumberLeft + 'rem';
        questionNumber.innerHTML = `${this.props.currentQuestionNumber}/${this.props.totalQuestions}`

        let arrowForQuestionNumber = document.getElementById("arrow-position")
        let arrowLeft = width - 0.5;
        arrowForQuestionNumber.style.left = arrowLeft + 'rem';


    }

    componentWillUnmount() {
        this.clearIntervalCustom()
    }

    getTimeWithMinuteFormat(seconds) {

        let min = seconds >= 60 ? parseInt(seconds / 60) : 0
        let sec = seconds % 60;

        let time = '';

        if (min < 10)
            time += `0${min}`
        else
            time = min

        time += ':'

        if (sec < 10)
            time += `0${sec}`
        else
            time += sec

        return time
    }

    clearIntervalCustom = () => {
        if (this.timerInterval) {
            // BackgroundTimer.clearInterval(this.timerInterval);
            clearInterval(this.timerInterval);
        }
    }


    timer = () => {

        // this.timerInterval = BackgroundTimer.setInterval(() => {

        //     if (!this.state.remainingTime && this.props.time) {
        //         this.setState({ remainingTime: this.props.time })
        //     }
        //     if (this.state.remainingTime) {

        //         if (this.state.remainingTime - 1 == 0) {
        //             this.props.delegate && this.props.delegate.onTimerEnd && this.props.delegate.onTimerEnd()
        //             this.clearIntervalCustom()
        //         }
        //         this.props.delegate && this.props.delegate.setCurrentTime && this.props.delegate.setCurrentTime(this.state.remainingTime - 1)
        //         this.setState({ remainingTime: this.state.remainingTime - 1 })
        //     }

        // }, 1000);

        this.timerInterval = setInterval(() => {

            if (!this.state.remainingTime && this.props.time) {
                this.setState({ remainingTime: this.props.time })
            }
            if (this.state.remainingTime) {

                if (this.state.remainingTime - 1 == 0) {
                    this.props.delegate && this.props.delegate.onTimerEnd && this.props.delegate.onTimerEnd()
                    this.clearIntervalCustom()
                }
                this.props.delegate && this.props.delegate.setCurrentTime && this.props.delegate.setCurrentTime(this.state.remainingTime - 1)
                this.setState({ remainingTime: this.state.remainingTime - 1 })
            }

        }, 1000);
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <div className="assessment-header d-flex justify-content-between align-items-center"
                style={{ backgroundColor: this.theme ? `${this.theme['themeColor']}` : '' }}>
                <div className="chapter-title">{this.props.title ? this.props.title : 'Chapter Name'}</div>
                {
                    this.props.timer ?
                        this.props.isFromPracticeCaseStudy ?
                            <></>
                            :
                            <div className="d-flex justify-content-between align-items-center">
                                <img className="timer-icon mr-3" src={getImageSource(ImageConstants.TIMER_ICON)} />
                                <span className="time-text">{this.getTimeWithMinuteFormat(this.state.remainingTime)}</span>
                            </div>
                        :
                        this.props.questionCount ?
                            <div className="d-flex justify-content-between align-items-center mt-4">
                                <div className="progress-bar-wrapper mr-3">
                                    <div id="myprogressBar" className="progress-status">
                                        <div id="myCircleStatus" className="circle-status"></div>
                                        <div id="question-number" className={`d-flex align-items-center 
                                        justify-content-center question-number-text text-center
                                        ${this.props.totalQuestions >= 10 ? "incresed-width" : ""}`}></div>
                                        <div id="arrow-position" className="arrow-for-question-number"></div>
                                    </div>
                                </div>
                                {/* <div><div className="complete-text">{`${Math.floor((this.props.currentQuestionNumber - 1) / this.props.totalQuestions * 100)}`}% Complete</div></div> */}
                            </div> :
                            <></>
                }

            </div>
        )
    }

}

export default AssessmentHeader;
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cw-toaster-component.scss";

class ToasterComponent extends Component {
  state = {};

  componentDidMount() {
    switch (this.props.type) {
      case "success":
        this.notifySuccess();
        break;

      case "error":
        this.notifyError();
        break;

      default:
        this.notify();
        break;
    }
  }

  notify = () => {
    toast(this.props.message, {
      position: this.props.position ? this.props.position : "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      draggable: true
    });
  };

  notifySuccess = () => {
    toast.success(this.props.message, {
      position: this.props.position ? this.props.position : "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      draggable: true
    });
  };

  notifyError = () => {
    toast.error(this.props.message, {
      position: this.props.position ? this.props.position : "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      draggable: true
    });
  };

  render() {
    return (
      <>
        <ToastContainer />
      </>
    );
  }
}

export default ToasterComponent;

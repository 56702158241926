import HttpClient from './http-client';
import URL from './url';

export default class SchoolApi {

    static getSchoolInformationFromSchoolConnectBeforeLogin(payload) {
        let url = URL.BASE_URL + URL.FETCH_SCHOOL_FROM_SCHOOL_CODE_BEFORE_LOGIN;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }


    static updateSchool(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_SCHOOL, null, payload);
    }

    static updateSuperAdminSchool(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_SCHOOL_FOR_SUPER_ADMIN, null, payload);
    }

    static getSchoolWithPagination(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SCHOOL_LIST_FOR_ADMIN + "?" + params.join('&') : URL.SCHOOL_LIST_FOR_ADMIN;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getAllStaffTypes(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.ALL_STAFF_TYPES + "?" + params.join('&') : URL.STAFF_TYPE;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getStaffTypes(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.STAFF_TYPE + "?" + params.join('&') : URL.STAFF_TYPE;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getChapterDetail(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SCHOOL_CHAPTER_DETAIL_REPORT + "?" + params.join('&') : URL.SCHOOL_CHAPTER_DETAIL_REPORT;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getClassAndBoard(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SCHOOL_CLASS + "?" + params.join('&') : URL.SCHOOL_CLASS;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getClassAndBoardPublic(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SCHOOL_CLASS_PUBLIC + "?" + params.join('&') : URL.SCHOOL_CLASS_PUBLIC;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getClasses(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.CLASS_LIST + "?" + params.join('&') : URL.CLASS_LIST;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }



    static getSchool(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SCHOOL + "?" + params.join('&') : URL.SCHOOL;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getStaffs(payload = {}) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.STAFFS + "?" + params.join('&') : URL.STAFFS;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getSections(payload = {}) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SECTION + "?" + params.join('&') : URL.SECTION;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getSectionsPublic(payload = {}) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SECTION_PUBLIC + "?" + params.join('&') : URL.SECTION_PUBLIC;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getSectionsList(payload = {}) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SECTION_LIST + "?" + params.join('&') : URL.SECTION_LIST;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getStudents(payload = {}) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.STUDENTS + "?" + params.join('&') : URL.STUDENTS;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getEvents(payload = {}) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_EVENTS + "?" + params.join('&') : URL.GET_EVENTS;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getEventsList(payload = {}) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_EVENTS_LIST + "?" + params.join('&') : URL.GET_EVENTS_LIST;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static createStaff(payload) {
        return HttpClient.post(URL.BASE_URL + URL.STAFFS, null, payload);
    }

    static addBulkStaffs(payload) {
        return HttpClient.post(URL.BASE_URL + URL.STAFFS_BULK, null, payload);
    }

    static sendInvite(payload) {
        return HttpClient.post(URL.BASE_URL + URL.STAFFS_INVITE, null, payload);
    }

    static updateStaff(payload) {
        return HttpClient.put(URL.BASE_URL + URL.STAFFS, null, payload);
    }

    static updateEventsToCoordinator(payload) {
        return HttpClient.put(URL.BASE_URL + URL.EVENTS_TO_COORDINATOR, null, payload);
    }

    static updateSchoolClass(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_SCHOOL_CLASS, null, payload);
    }

    static confirmStaffProfile(payload) {
        return HttpClient.put(URL.BASE_URL + URL.CONFIRM_STAFF_PROFILE, null, payload);
    }

    static deleteStaff(payload) {
        return HttpClient.delete(URL.BASE_URL + URL.STAFFS, null, payload);
    }

    static deleteSchoolSection(payload) {
        return HttpClient.delete(URL.BASE_URL + URL.SECTION, null, payload);
    }

    static addSchoolsSection(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SECTION, null, payload);
    }

    static createSectionByTeacher(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SECTION_BULK_TEACHER, null, payload);
    }

    static updateSchoolSection(payload) {
        return HttpClient.put(URL.BASE_URL + URL.SECTION, null, payload);
    }

    static sendStudentInvite(payload) {
        return HttpClient.post(URL.BASE_URL + URL.STUDENT_INVITE, null, payload);
    }

    static updateSections(payload) {
        return HttpClient.put(URL.BASE_URL + URL.SECTION_BULK, null, payload);
    }

    static sectionBulkAssign(payload) {
        return HttpClient.put(URL.BASE_URL + URL.SCHOOL_USERS_BULK_SECTION_ASSIGN, null, payload);
    }

    static createTemporarySchool(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SCHOOL_TEMP, null, payload);
    }

    static sendSchoolSignupOTP(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SCHOOL_TEMP_SIGNUP_OTP, null, payload);
    }

    static updateInvite(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_INVITE, null, payload);
    }

    static sendTeacherInvitations(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_TEACHER_INVITATIONS, null, payload);
    }

    static sendStudentInvitations(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_STUDENT_INVITATIONS, null, payload);
    }

    static sendExistingStudentInvitations(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_EXISTING_STUDENT_INVITATIONS, null, payload);
    }

    static sendInvitation(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_INVITATION, null, payload);
    }

    static downloadAllPerformanceReports(payload) {
        return HttpClient.post(URL.BASE_URL + URL.DOWNLOAD_ALL_PERFORMANCE_REPORTS, null, payload);
    }

    static downloadAllCertificates(payload) {
        return HttpClient.post(URL.BASE_URL + URL.DOWNLOAD_ALL_CERTIFICATES, null, payload);
    }

    static downloadCertificate(payload) {
        return HttpClient.post(URL.BASE_URL + URL.DOWNLOAD_CERTIFICATE, null, payload);
    }

    static addEventToSchool(payload) {
        return HttpClient.post(URL.BASE_URL + URL.ADD_EVENT_TO_SCHOOL, null, payload);
    }

    static getTeacherCertificate(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.DOWNLOAD_TEACHER_CERTIFICATE + "?" + params.join('&') : URL.DOWNLOAD_TEACHER_CERTIFICATE;
        return HttpClient.get(URL.BASE_URL + paramsList, null, payload);
    }

    static getSchoolPdfReport(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SCHOOL_PDF_REPORT + "?" + params.join('&') : URL.SCHOOL_PDF_REPORT;
        return HttpClient.get(URL.BASE_URL + paramsList, null, payload);
    }

}
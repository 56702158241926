import React from "react";
import { ImageConstants } from "../../../constants/constants";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import './assessment-pagination.scss';
import SharedThemeCategory from "../../../../shared-storage/category-theme";
// import { element } from "prop-types";
import LayoutShared from "../layout-shared/layout-shared";

class AssessmentPagination extends React.Component {


    constructor(props) {
        super(props);
        this.scroll = React.createRef()
        this.layoutShared = LayoutShared.getLayout()
    }

    state = {
        showDisabled: false,
        changeTheWidth: false
    }

    componentDidMount() {
        let changeTheWidth = this.layoutShared.state.hideSideBar
        if (changeTheWidth) {
            this.setState({ changeTheWidth: true })
        }
    }

    pagesRef = {}

    onClickPage = (question, index) => {

        //console.log(this.props.activePage, index, question)

        if (question.answer || question.isFlagged || this.props.activePage > index)
            this.props.delegate.onClickPage && this.props.delegate.onClickPage(index + 1)
    }

    getItems() {

        // //console.log(this.props.questions)

        let elements = []
        for (let i = 0; i < this.props.questions.length; i++) {
            let question = this.props.questions[i]
            elements.push(
                // <div key={i + 'page'} style={Styles.pageWrapper} onLayout={(event) => {
                //     let { x, y, width, height } = event.nativeEvent.layout;
                //     this.pagesRef[i] = { x, y }
                // }}>
                //     <View style={this.props.activePage == i + 1 ? Styles.activePageDot : {}}><View style={question.isFlagged ? Styles.dot : (question.answer ? Styles.answeredDot : Styles.notAnsweredDot)}
                //     ></View></View>

                //     <View style={Styles.nonFlaggedContent}>{this.props.activePage == i + 1 ?
                //         <View style={Styles.activePage}><Text style={Styles.activePageText}>{i + 1}</Text></View>
                //         : <TouchableOpacity onPress={() => { this.onClickPage(question, i) }} style={Styles.pageNumberWrapper} hitSlop={Styles.hitSlop1}><Text style={Styles.paginationText}>{i + 1}</Text></TouchableOpacity>}
                //     </View>
                // </div>

                <div className={``} ref={ref => { this.pagesRef[i] = ref }}  >
                    <div id={question.id} className={`question-number d-flex align-items-center justify-content-center text-center
                    ${this.props.activePage == i + 1 ? 'active-page-circle' : ''}
                    ${question.isFlagged ? 'flagged-circle cursor-pointer' : (question.answer ? "answered-color cursor-pointer" : 'not-answered-color')}`}
                        onClick={() => {

                            if (question.isFlagged || question.answer) {
                                this.onClickPage(question, i)
                            }
                        }
                        }
                        style={{ backgroundColor: `${this.props.activePage == i + 1 ? this.theme[`themeColor`] : ''}` }}

                    >
                        {
                            this.props.activePage == i + 1 ?
                                <div>
                                    {i + 1}
                                </div> :
                                <div className="">
                                    {i + 1}
                                </div>
                        }

                    </div>
                </div>
            )
        }

        return elements;
    }

    setWidth = (width) => {
        this.setState({ scrollViewWidth: width });
    }

    isCloseToEnd = (e) => {
        console.log(e.target.scrollLeft)

        if (e.target.scrollLeft === 0) {
            this.setState({ showLeftEnabled: false })
        }

        return e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth
    }

    scrollTo = () => {
        //    //console.log(this.scroll)
        // if (this.pagesRef && this.props.activePage && this.pagesRef[this.props.activePage - 1]) {
        //     this.scroll.scrollTo({ x: this.pagesRef[this.props.activePage - 1].x, animated: true })
        // }
        let keys = Object.keys(this.pagesRef)
        //console.log(keys.length, this.props.activePage)



        if (this.pagesRef && this.props.activePage && this.pagesRef[this.props.activePage - 1]) {

            let singlePageNumberWidth = this.scroll.current.scrollWidth / keys.length

            // let scrollLeftPosition =  this.props.activePage * this.pagesRef[this.props.activePage - 1].offsetWidth 

            let scrollLeftPosition = (this.props.activePage - 1) * singlePageNumberWidth




            this.scroll.current.scrollLeft = scrollLeftPosition

            let element = this.pagesRef[this.props.activePage]

            if (element) {
                element.scrollIntoView({ behavior: "instant", block: 'center' })
            }
        }



    }

    goToEndOfPagination(e) {
        //console.log(this.scroll)
        this.scroll.current.scrollLeft = this.scroll.current.scrollWidth

    }

    goToStartOfPagination() {
        this.scroll.current.scrollLeft = 0
        this.setState({ showDisabled: false })
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <>
                {
                    this.props.isFromPracticeCaseStudy ?
                        <>
                            <div className="assessment-pagination d-flex flex-row justify-content-center align-items-center" >
                                <div className="d-flex flex-row justify-content-center pagination-wrapper align-items-center" ref={this.scroll}>
                                    {this.getItems()}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="position-absolute header-image-wrapper" style={{ top: "4rem" }}>
                                <img className={`header-bg-image-size ${this.state.changeTheWidth ? "change-image-width" : ""}`} src={getThemeImageSource(ImageConstants.HOMEWORK_COMPLETION_SCREEN_HEADER, this.props.categoryThemeId)} ></img>
                            </div>
                            <div className="assessment-pagination d-flex flex-row justify-content-center align-items-center" >

                                {!this.state.showDisabled ?
                                    <>
                                        {
                                            this.props.activePage === 1 ?
                                                <div className="position-relative mr-5">
                                                    <img className="cursor-pointer arrow-size" src={getImageSource(ImageConstants.LEFT_ARROW_DISABLED)} />
                                                </div>
                                                :
                                                <div className="position-relative mr-5">
                                                    <img className="cursor-pointer arrow-size" onClick={() => this.goToStartOfPagination()} src={getImageSource(ImageConstants.LEFT_ARROW_ENABLED)} />
                                                </div>
                                        }
                                    </>

                                    :
                                    <div className="position-relative mr-5">
                                        <img className="cursor-pointer arrow-size" onClick={() => this.goToStartOfPagination()} src={getImageSource(ImageConstants.LEFT_ARROW_ENABLED)} />
                                    </div>
                                }
                                <div className="d-flex position-relative flex-row pagination-wrapper align-items-center" ref={this.scroll}
                                    onScroll={(e) => {
                                        if (this.isCloseToEnd(e)) {
                                            this.setState({ showDisabled: true })
                                        }
                                        else {
                                            this.setState({ showDisabled: false })
                                        }
                                    }}
                                >
                                    {this.getItems()}
                                </div>

                                {!this.state.showDisabled ? <div className="position-relative ml-3">
                                    <img className="cursor-pointer arrow-size" onClick={(e) => this.goToEndOfPagination(e)} src={getImageSource(ImageConstants.RIGHT_ARROW_ENABLED)} />
                                </div>
                                    :
                                    <div className="position-relative ml-3">
                                        <img className="cursor-pointer arrow-size" src={getImageSource(ImageConstants.RIGHT_ARROW_DISABLED)} />
                                    </div>
                                }
                            </div>
                        </>
                }


            </>


        )
    }


}

export default AssessmentPagination;
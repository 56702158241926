import React from 'react';
import './refresher-course.scss';
import { getImageSource } from '../../utility/image-source.js';
import { ImageConstants, Constants, FirebaseEvents } from '../../constants/constants.js';
import SharedStaticText from '../../../shared-storage/static-text-store.js';
import RefresherCourseHeader from './refresher-course-header/refresher-course-header'
import FirebaseEvent from '../../firebase-events/firebase-events';
import SharedProfile from '../../../shared-storage/profile.js';
import Loading from '../common/loading/loading.js';
import RefresherCourseApi from '../../apis/refresher-course.js';
import Path from '../routes/routes-path.js';
import RefresherCourseExperience from './refresher-course-experience.js';
import WorkoutApi from '../../apis/workout.js';
import CommonApi from '../../apis/common.js';
import EventApi from '../../apis/event.js';
import CommonUtils from '../../utility/common-utilities.js';
import ScollarShipEligiblePopUp from '../schollarship-eligible-popup/schollarship-eligible-popup.js';
import AsyncStore from '../../../shared-storage/async-store.js';

export default class RefresherCourse extends React.Component {
    constructor(props) {
        super(props);
        this.staticText = SharedStaticText.getStaticText('RefresherCourse');
        this.meta = this.props.meta;
        if (this.meta && this.meta.refresherCourseId) {
            this.getRefresherCourseV2(this.meta.refresherCourseId)
        } else
            this.getPkChapterList()

        if (this.meta && this.meta.eventPayload && this.meta.eventPayload.staticText && this.meta.eventPayload.staticText.genericWorkflowScreen) {
            this.setState({ genericWorkflowScreen: this.meta.eventPayload.staticText.genericWorkflowScreen })
        }
        this.userInfo = SharedProfile.getProfile();
        this.getScollarEligibilityThreshold()
    }

    state = {
        chapters: [],
        loading: true,
        showRefresherCourseExperience: false,
        refresherCourseParams: {},
        showScollarEligiblePopUp: false
    }

    getPkChapterList() {

        if (!this.state.loading)
            this.toggleLoading(true)

        RefresherCourseApi.getPkChapterList({}).then((data) => {

            this.setState({ chapters: data.data, progress: data.progress, workflowId: data.workflowId, loading: false }, () => {
                this.logRefresherCourseCompletion()
            })


        }).catch(err => {
            if (err) {

                this.toggleLoading(false)
                return
            }
        })
    }

    getRefresherCourseV2(id) { // If refresher course id is already known

        if (!id)
            return

        if (!this.state.loading)
            this.toggleLoading(true)


        RefresherCourseApi.getRefresherCourseV2({ refresherCourseId: id }).then((data) => {
            this.setState({ chapters: data.data, progress: data.progress, workflowId: data.workflowId, loading: false }, () => {
                this.logRefresherCourseCompletion()
            })
        }).catch(err => {
            if (err) {
                this.toggleLoading(false)
                return
            }
        })
    }

    getScollarEligibilityThreshold = () => {

        CommonApi.getMiscellaneousList({ type: 'SCOLLARSHIP_ELIGIBILITY' }).then((data) => {

            if (data && data.response && data.response.length)
                this.threshold = data.response[0].threshold

        }).catch(err => {
            if (err) {
                return
            }
        })
    }

    toggleLoading = (value) => {
        this.setState({ loading: value })
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    navigate = (status, eloIds, node, chapter) => {

        let statusArray = []

        for (let i = 0; i < this.state.chapters.length; i++) {
            for (let j = 0; j < this.state.chapters[i].nodes.length; j++) {
                if (statusArray.indexOf(this.state.chapters[i].nodes[j].status) === -1) {
                    statusArray.push(this.state.chapters[i].nodes[j].status);
                }
            }
        }

        if (this.meta.from == Constants.EVENT_CAMPAIGN && JSON.stringify(statusArray) == JSON.stringify(Constants.REFRESHER_COURSE_START_STATUS)) {
            FirebaseEvent('IMD_CAMPAIGN_COURSE_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
            // Facebook.logCustomEvent('IMD_CAMPAIGN_COURSE_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
            // BranchIO.logCustomEvent('IMD_CAMPAIGN_COURSE_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        } else if (this.meta.from == Constants.REWARD_EVENT_BASELINE && JSON.stringify(statusArray) == JSON.stringify(Constants.REFRESHER_COURSE_START_STATUS)) {
            FirebaseEvent('IMD_COURSE_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
            // Facebook.logCustomEvent('IMD_COURSE_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
            // BranchIO.logCustomEvent('IMD_COURSE_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        } else if (JSON.stringify(statusArray) == JSON.stringify(Constants.REFRESHER_COURSE_START_STATUS)) {
            FirebaseEvent(FirebaseEvents.REFRESHER_COURSE_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
            // Facebook.logCustomEvent(FirebaseEvents.REFRESHER_COURSE_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
            // BranchIO.logCustomEvent(FirebaseEvents.REFRESHER_COURSE_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        }


        this.setState({
            showRefresherCourseExperience: true,
            refresherCourseParams: {
                status,
                nodeId: node.id,
                eloIds: eloIds,
                nodes: chapter.nodes,
                title: chapter.chapterTitle,
                chapterKey: chapter.chapterKey,
                categoryThemeId: chapter.categoryThemeId,
                isChapterNotCompleted: true,
                conceptListMeta: {},
                masterDataId: this.state.masterDataId,
                workoutId: this.state.workflowId,
                isRefresherCourse: true,
                eventThreshold: this.meta && this.meta.eventThreshold,
                from: this.meta && this.meta.from,
                eventId: this.meta && this.meta.eventId,
                scholarshipEligibleStaticText: this.meta && this.meta.scholarshipEligibleStaticText ? this.meta.scholarshipEligibleStaticText : {},
                isDeadLineOver: this.meta && this.meta.isDeadLineOver,
                eventPayload: this.meta.eventPayload
            }
        })

        // this.props.navigation.navigate('RefresherCourseExperience', {
        //     params: {
        //         status, nodeId: node.id, eloIds: eloIds, nodes: chapter.nodes, title: chapter.chapterTitle, chapterKey: chapter.chapterKey, categoryThemeId: chapter.categoryThemeId,
        //         isChapterNotCompleted: true, conceptListMeta: {}, masterDataId: this.state.masterDataId, workoutId: this.state.workflowId, isRefresherCourse: true,
        //         eventThreshold: this.meta && this.meta.eventThreshold,
        //         from: this.meta && this.meta.from,
        //         eventId: this.meta && this.meta.eventId,
        //         scholarshipEligibleStaticText: this.meta && this.meta.scholarshipEligibleStaticText ? this.meta.scholarshipEligibleStaticText : {},
        //         isDeadLineOver: this.meta && this.meta.isDeadLineOver,
        //         eventPayload: this.meta.eventPayload
        //     }
        // });

    }

    logRefresherCourseCompletion = async () => {

        let status = []

        for (let i = 0; i < this.state.chapters.length; i++) {
            for (let j = 0; j < this.state.chapters[i].nodes.length; j++) {
                if (status.indexOf(this.state.chapters[i].nodes[j].status) === -1) {
                    status.push(this.state.chapters[i].nodes[j].status);
                }
            }
        }

        if (status.length && (!status.includes("START") && !status.includes("LOCKED"))) {

            let doc = await AsyncStore.getRefresherCourseCompletedInfo()

            if (doc) {
                doc = JSON.parse(doc)

                if (!doc[`${this.userInfo.name}_${this.userInfo.mobileNumber}`]) {

                    doc[`${this.userInfo.name}_${this.userInfo.mobileNumber}`] = true

                    AsyncStore.setRefresherCourseCompletedInfo(doc)

                    if (this.meta.from && this.meta.from == Constants.EVENT_CAMPAIGN) {
                        // FirebaseEvent.logCustomEvent('IMD_CAMPAIGN_COURSE_COMPLETED', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                        // Facebook.logCustomEvent('IMD_CAMPAIGN_COURSE_COMPLETED', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                        // BranchIO.logCustomEvent('IMD_CAMPAIGN_COURSE_COMPLETED', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });

                    } else if (this.meta.from == Constants.REWARD_EVENT_BASELINE) {
                        // FirebaseEvent.logCustomEvent('IMD_COURSE_COMPLETED', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                        // Facebook.logCustomEvent('IMD_COURSE_COMPLETED', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                        // BranchIO.logCustomEvent('IMD_COURSE_COMPLETED', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                    } else {
                        FirebaseEvent(FirebaseEvents.REFRESHER_COURSE_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
                        // Facebook.logCustomEvent(FirebaseEvents.REFRESHER_COURSE_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                        // BranchIO.logCustomEvent(FirebaseEvents.REFRESHER_COURSE_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });

                    }

                }
            } else {
                AsyncStore.setRefresherCourseCompletedInfo({ [`${this.userInfo.name}_${this.userInfo.mobileNumber}`]: true })

                if (this.meta.from && this.meta.from == Constants.EVENT_CAMPAIGN) {
                    // FirebaseEvent.logCustomEvent("IMD_CAMPAIGN_COURSE_COMPLETED", { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                    // Facebook.logCustomEvent("IMD_CAMPAIGN_COURSE_COMPLETED", { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                    // BranchIO.logCustomEvent("IMD_CAMPAIGN_COURSE_COMPLETED", { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });

                } else if (this.meta.from == Constants.REWARD_EVENT_BASELINE) {
                    // FirebaseEvent.logCustomEvent('IMD_COURSE_COMPLETED', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                    // Facebook.logCustomEvent('IMD_COURSE_COMPLETED', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                    // BranchIO.logCustomEvent('IMD_COURSE_COMPLETED', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                } else {
                    FirebaseEvent(FirebaseEvents.REFRESHER_COURSE_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
                    // Facebook.logCustomEvent(FirebaseEvents.REFRESHER_COURSE_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                    // BranchIO.logCustomEvent(FirebaseEvents.REFRESHER_COURSE_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });

                }
            }
        }

    }

    getButtons = (status, elos, node, chapter) => {

        let eloIds = elos ? elos.map(item => item.id) : [];

        switch (status) {

            case Constants.ELO_STATUS_CLEARED:
                return <div className='cursor-pointer concept-button text-center' onClick={() => this.navigate(status, eloIds, node, chapter)}><div className='button-text'><span>Revise</span></div></div>

            case Constants.ELO_STATUS_IN_PROGRESS:
            case Constants.TRY_AGIN_IN_PROGRESS:
                return <div className='cursor-pointer concept-button text-center' onClick={() => this.navigate(status, eloIds, node, chapter)}><div className='button-text'><span>Continue</span></div></div>

            case Constants.ELO_STATUS_NOT_CLEARED:
                return <div className='cursor-pointer concept-button text-center' onClick={() => this.navigate(status, eloIds, node, chapter)}><div className='button-text'><span>Try Again</span></div></div>

            case Constants.ELO_STATUS_START:
                return <div className='cursor-pointer concept-button text-center' onClick={() => this.navigate(status, eloIds, node, chapter)}><div className='button-text'><span>Start</span></div></div>

            default:
                return <div className='lock-icon'><img className='lock-icon-size' src={getImageSource(ImageConstants.LOCK)}></img></div> /** Locked */
        }
    }

    close() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    showLateralMenu = () => {
        // this.props.navigation.navigate("LateralMenu")
    }

    renderElos(item) {

        if (!item)
            return <></>

        let elements = []

        for (let i = 0; i < item.nodes.length; i++) {

            let node = item.nodes[i]

            elements.push(<div>

                {
                    node.elos && node.elos.length && node.elos.map((elo, eloIndex) => (
                        <div className={`row ml-0 mr-0 align-items-center ${i < item.nodes.length - 1 ? 'elo-title-wrapper' : 'last-elo'}`} key={elo.id}>
                            <div className='col-1 number-width mr-0 pr-0' >{i + 1}.</div>
                            <div className='col ml-0 pl-0'><span className='elo-title'>{elo.title}</span></div>
                            <div className='col-2 concept-button-container d-flex justify-content-center'>
                                {this.getButtons(node.status, node.elos, node, item)}
                            </div>
                        </div>

                    ))
                }
            </div>)
        }

        return elements
    }

    hideRefresherCourseExperience(isRevise) {
        this.setState({ showRefresherCourseExperience: false }, () => {
            this.getPkChapterList()
            if (!isRevise) {
                this.showSuccessFailureScreen()
            }
        })
    }

    showSuccessFailureScreen = (data) => {

        // console.log('success', data)

        // this.setState({ disableNext: false })

        let payload = {
            workflowId: this.state.workflowId,
            workoutType: 'TRAIL',
            isRefresherCourse: true
        };

        WorkoutApi.getWorkoutProgress(payload).then((res) => {
            console.log(res)
            this.progress = res;
            // this.successFailureScreenData = data;
            // this.successFailureScreenData.upComing = [];
            // if (data.isContinue) {
            //     let { node, isEnd } = this.getNextNode();
            //     if (!isEnd) { // CONDITION TO IDENTIFY END OF CHAPTER
            //         this.successFailureScreenData.upComing = node;
            //         this.successFailureScreenData.isContinue = true;
            //     } else {
            //         this.successFailureScreenData.upComing = [];
            //         this.successFailureScreenData.isEnd = true;
            //     }
            // }

            // this.successFailureScreenData.chapterKey = this.params.chapterKey;
            // this.successFailureScreenData.chapterTitle = this.params.title;
            // this.successFailureScreenData.isChapterNotCompleted = this.params.isChapterNotCompleted;

            // this.successFailureScreenData.nodeId = this.params.nodeId;
            // this.successFailureScreenData.isTrailWorkout = this.params.isSubscribedFlow ? false : true

            // this.successFailureScreenData.isTimeOut = Constants.WORKOUT_TIME > res.timeConsumed ? false : true
            // this.successFailureScreenData.totalDuration = this.params.totalDuration
            // this.successFailureScreenData.timeConsumed = res.timeConsumed ? res.timeConsumed : 0

            this.setState({ showSuccessFailureScreen: false }, () => {

                console.log(this.userInfo)

                if (this.threshold && this.threshold.value <= this.progress.refresherCompletedProgress && !this.userInfo.isScollarShipEligible) {
                    this.updateStudentScollarShipEligible()
                }

                if (this.params && this.params.from != Constants.REWARD_EVENT_BASELINE && this.params.eventThreshold && this.params.eventThreshold <= this.progress.refresherCompletedProgress && !this.userInfo.isEventScholarshipEligible && this.params.eventId) {

                    let payload = {
                        type: Constants.SCHOLARSHIP_EVENT,
                        eventId: this.params.eventId
                    }

                    this.updateStudentScollarShipEligible(payload)
                }

                if (this.params && this.params.from == Constants.REWARD_EVENT_BASELINE && this.params.eventThreshold <= this.progress.refresherCompletedProgress && !this.userInfo.isRewardEventEligible && this.params.eventId && !this.params.isDeadLineOver) {
                    let payload = {
                        type: Constants.REWARD_EVENT,
                        eventId: this.params.eventId
                    }

                    this.updateStudentScollarShipEligible(payload)
                }

                if (this.params && this.params.from == Constants.TWO_STEP_EVENT_BASELINE && this.params.eventThreshold <= this.progress.refresherCompletedProgress && (!this.userInfo.twoStepEvent || (this.userInfo.twoStepEvent && !this.userInfo.twoStepEvent[this.params.eventId])) && this.params.eventId && !this.params.isDeadLineOver) {
                    let payload = {
                        type: Constants.TWO_STEP_EVENT,
                        eventId: this.params.eventId
                    }

                    this.updateStudentScollarShipEligible(payload)
                }


                if (this.params && this.params.isMplWorkout && this.params.mplEventThreshold && this.params.mplEventThreshold <= this.progress.refresherCompletedProgress) {
                    this.showMplQualificationPopup()
                }

            })

        }).catch(err => {
            console.log(err)
            if (err)
                return;
        })

    }

    updateStudentScollarShipEligible = (payload = {}) => {
        EventApi.updateStudentScollarShipEligible(payload).then((data) => {


            this.userInfo = { ...this.userInfo, isScollarShipEligible: true }

            if (payload.type == Constants.SCHOLARSHIP_EVENT) {
                this.userInfo['isEventScholarshipEligible'] = true
            }

            if (payload.type == Constants.REWARD_EVENT) {
                this.userInfo['isRewardEventEligible'] = true
            }

            if (payload.type == Constants.TWO_STEP_EVENT) {

                if (this.userInfo["twoStepEvent"]) {
                    this.userInfo['twoStepEvent'][payload.eventId] = true
                } else {
                    this.userInfo['twoStepEvent'] = {
                        [payload.eventId]: true
                    }
                }
                // this.userInfo['isTwoStepEventEligible'] = true
            }

            SharedProfile.setProfile(this.userInfo);
            this.showScollarEligiblePopUp()
        }).catch(err => {
            console.log(err)
            if (err)
                return
        })
    }

    onPressContinue = () => {

        if (this.meta.isMplWorkout) {
            this.setState({ showMplQualificationPopup: false })
        } else {
            this.setState({ showScollarEligiblePopUp: false })

        }
    }

    showScollarEligiblePopUp = () => {
        // this.props.navigation.navigate('EventQualifiedScreen',{meta:{eventPayload : this.params.eventPayload}})
        this.setState({ showScollarEligiblePopUp: true })
        // moengage.logCustomEvent('Event', { 'User Id': this.userInfo.userId, 'Event Name': this.params && this.params.eventPayload && this.params.eventPayload.title, 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Qualified', 'Learning Status': '100% Completed', 'Viewed Baseline Report': CommonUtils.getBooleanValue(!this.params.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.params.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.params.eventPayload.viewedCertificate) })
        FirebaseEvent('Event', { 'User Id': this.userInfo.userId, 'Event Name': this.meta && this.meta.eventPayload && this.meta.eventPayload.title, 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Qualified', 'Learning Status': '100% Completed', 'Viewed Baseline Report': CommonUtils.getBooleanValue(!this.meta.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': CommonUtils.getBooleanValue(!this.meta.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': CommonUtils.getBooleanValue(!this.meta.eventPayload.viewedCertificate) })
        // Facebook.logCustomEvent('Event', { 'User Id': this.userInfo.userId, 'Event Name': this.params && this.params.eventPayload && this.params.eventPayload.title, 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Qualified', 'Learning Status': '100% Completed', 'Viewed Baseline Report': getBooleanValue(!this.params.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.params.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.params.eventPayload.viewedCertificate) })
        // BranchIO.logCustomEvent('Event', { 'User Id': this.userInfo.userId, 'Event Name': this.params && this.params.eventPayload && this.params.eventPayload.title, 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Qualified', 'Learning Status': '100% Completed', 'Viewed Baseline Report': getBooleanValue(!this.params.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.params.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.params.eventPayload.viewedCertificate) })

        return
    }




    render() {


        let linearGradientBg = `linear-gradient(90deg, #13BFD5 0%, #86CB9F 100%)`

        if (this.state.showRefresherCourseExperience) {
            return (
                <RefresherCourseExperience delegate={this} params={this.state.refresherCourseParams} />
            )
        }


        return (
            !this.state.loading ?
                <div className='refresher-course'>
                    {
                        this.state.chapters && this.state.chapters.length ?
                            <>
                                <div>
                                    <RefresherCourseHeader delegate={this} title={this.state.genericWorkflowScreen ? this.state.genericWorkflowScreen.title : this.staticText.refresherCourseTitle} isScrolled={this.state.showShadow} close={() => this.close()} />
                                </div>
                                <div>
                                    {
                                        this.state.chapters.map((item, index) => (
                                            <>
                                                {
                                                    index == 0 ?
                                                        <div className='progress-container'>
                                                            <span className='progress-text'>{this.state.genericWorkflowScreen ? this.state.genericWorkflowScreen.progressTitle : this.staticText.courseCompletedText}</span>
                                                            <div className='status-bar mt-3'>
                                                                <div style={{
                                                                    width: `${this.state.progress ? this.state.progress.value : 0}%`,
                                                                    height: `${this.state.progress && this.state.progress.value ? 100 : 0}%`,
                                                                    backgroundImage: linearGradientBg, borderRadius: '10px'
                                                                }} />
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                <div className='mt-3 mb-5'>
                                                    <div className='d-flex align-items-center mb-2'>
                                                        {item.chapterIconUrl ?
                                                            <div>
                                                                <img className='chapter-icon mr-2' src={item.chapterIconUrl} />
                                                            </div>
                                                            :
                                                            <></>}
                                                        <div className='title-container'>
                                                            <span className='chapter-title' >{item.chapterTitle}</span>
                                                        </div>
                                                    </div>
                                                    <div className='elo-list-container'>
                                                        {this.renderElos(item)}
                                                    </div>
                                                </div>

                                            </>
                                        ))
                                    }
                                </div>


                            </>
                            :
                            <div>
                                <RefresherCourseHeader delegate={this} title={this.state.genericWorkflowScreen ? this.state.genericWorkflowScreen.title : this.staticText.refresherCourseTitle} isScrolled={this.state.showShadow} close={() => this.close()} />
                                <div>
                                    <img src={getImageSource(ImageConstants.NO_CLASSWORK_IMAGE)} />
                                </div>

                                <div>
                                    <span className='no-course-text'>{this.state.genericWorkflowScreen ? this.state.genericWorkflowScreen.noWorkflowAvailable : 'No Refresher Course Available'} </span>
                                </div>

                            </div>
                    }

                    {
                        this.state.showScollarEligiblePopUp ?
                            <ScollarShipEligiblePopUp
                                modal={true} delegate={this}
                                scholarshipEligibleStaticText={this.meta.scholarshipEligibleStaticText}
                                eventPayload={this.meta.eventPayload} />
                            :
                            <></>
                    }


                </div> : <Loading />
        )
    }
}


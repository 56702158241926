import React from "react";
import './grouping.scss';
import SharedThemeCategory from "../../../../../../shared-storage/category-theme";
import Parser from "../../../../common/content-parser/content-parser";
import QuestionTitle from "../../../../common/question/question-title/question-title";
import QuestionImage from "../../../../common/cw-question-image/cw-question-image";
import AnswerTab from "../../answer-tab/answer-tab";
import CWImageV2 from "../../../../common/cw-image-2/cw-image-2";
import { Constants } from "../../../../../constants/constants";
import { getThemeImageSource, getImageSource } from "../../../../../utility/image-source";
import { ImageConstants } from "../../../../../constants/constants";

class Grouping extends React.Component {

    state = {}

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.theme = SharedThemeCategory.getThemeCategory(props.categoryThemeId);
        this.options = this.props.content.content.options;
        let content = JSON.stringify(this.props.content)
        this.state.content = JSON.parse(content);
        this.mapDragItems();
        if (this.props.showMyAnswer) {
            this.showMyAnswer()
        }
    }

    componentDidMount() {

    }

    mapDragItems() {

        let dragItem = [];

        let options = this.props.content.content.options;

        for (let i = 0; i < options.length; i++) {
            dragItem = dragItem.concat(options[i].items);
        }
        //this.pan = dragItem.map(() => new Animated.ValueXY());
        this.dragItems = dragItem;
    };

    getContentUsingContentType(option, style) {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let content = option.content.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");

        content = content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");

        if (option.contentType == Constants.CONTENT_TYPE_TEXT) {
            return this.parser.parseWithStyles(`${content}`, { p: styles.p, 'cw-math': styles.p })
        } else if (option.contentType == Constants.CONTENT_TYPE_IMAGE) {
            return <CWImageV2 uri={option.content}></CWImageV2>
        }

    }

    updateAnswer = (payload) => {

        // let content = this.state.content
        // let options = content.content.options;

        let contentRaw = JSON.stringify(this.props.content);
        contentRaw = JSON.parse(contentRaw);
        let optionsRaw = contentRaw.content.options;


        let dragItems = {};
        this.dragItems.map((item) => { { dragItems[item.id] = item } });

        for (let i = 0; i < payload.answer.length; i++) {

            let items = payload.answer[i].selected.map((item) => {
                if (dragItems[item.id]) {
                    return dragItems[item.id];
                }
            });

            optionsRaw[i].items = items;
        }

        this.setState({ content: contentRaw });

    }


    showMyAnswer = () => {
        if (this.props.answer) {
            this.updateAnswer(this.props.answer);
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.stringify(this.props.content)
        this.setState({ content: JSON.parse(content) })
    }


    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer })
    }



    getReadonlyView(content) {
        let textColor = this.theme['themeColor']
        let borderColor = this.theme[`themeColor`]

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }


        let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        let linearGradientBg = `linear-gradient(${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][0]}, ${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][1]})`

        return (


            <div className="grouping-question-type-read-only">

                <div>

                    <QuestionTitle title={content.content.title} hideBackground={true} />
                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />

                </div>

                {
                    this.props.status != "NOT_ATTEMPTED" ?
                        <div className="view-answer-button ml-1 mt-3 cursor-pointer" style={{color : textColor, borderColor : borderColor}} onClick={this.toggleViewAnswer}>
                            {
                                this.state.showCorrectAnswer ? 'Hide Answer' : 'View Answer'
                            }
                            {/* <span>View Answer</span> */}
                            <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} />
                        </div>
                        :
                        <></>
                }



                {
                    this.state.showCorrectAnswer ?
                        <>
                            {
                                this.props.status != "NOT_ATTEMPTED" ?
                                    <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.props.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.answer}></AnswerTab>
                                    : <></>
                            }

                            {
                                this.props.status != "NOT_ATTEMPTED" ?
                                    <div className="matching-question-container d-flex flex-row mt-4">
                                        {content.content.options.map((option, index) => (
                                            <div key={"dropZone" + index} className="drop-zone" style={{backgroundImage : linearGradientBg, borderColor : dragItemBorder}}>
                                                <div className="text-center p-1 drop-zone-title" style={{background : dragItemBorder}}>
                                                    {this.parser.parseWithStyles(`<p>${option.title}</p>`, { p: styles.p, 'cw-math': styles.p })}
                                                </div>
                                                <div className="d-flex flex-row answer-wrapper justify-content-center">
                                                    {
                                                        this.renderReadOnlyItem(option)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <></>
                            }
                        </>

                        :

                        <></>
                }



            </div>



        )
    }

    renderReadOnlyItem = (option) => {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]
        

        let options = option.items;
        return (
            options.map((option, i) => (
                <div className="answer-card d-flex align-items-center justify-content-center position-relative"
                style={{borderColor : borderColor}}>

                    {option.contentType == Constants.CONTENT_TYPE_TEXT ?
                        <div className="">
                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>
                        :
                        <div className="">
                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>

                    }
                </div>
            ))
        )
    }




    render() {

        // setCategoryThemeStyles(this.theme);

        let content = this.state.content;
        return (

            this.getReadonlyView(content)

        );
    }


}


export default Grouping;

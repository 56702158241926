import React from 'react';
import "./filter.scss";
import Dropdown from '../../common/dropdown-v2/dropdown';
import DropdownV4 from '../../common/dropdown-v4/dropdown';

import SchoolApi from '../../../apis/school'
import MasterDataApi from '../../../apis/master-data'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.css'
class FilterComponent extends React.Component {

    state = {
        showShadow: false,
        showFilters: true,
        disableGo: true,

        boardsDropdownItems: [],
        classDropdownItems: [],
        chapterDropDownItems: [],
        teachersDropDownItems: [],
        selectedBoard: this.props.delegate?.props?.location?.state?.sectionDetail?.board ? this.props.delegate?.props?.location?.state?.sectionDetail?.board : "",
        selectedClass: this.props.delegate?.props?.location?.state?.sectionDetail?.class ? this.props.delegate?.props?.location?.state?.sectionDetail?.class : "",
        selectedSection: this.props.delegate?.props?.location?.state?.sectionDetail?._id ? this.props.delegate?.props?.location?.state?.sectionDetail?._id : "",
        selectedChapterKey: [],
        selectedTeachersId: [],
        eloDropdownItems: [],
        disableClass: true,
        disableChapters: true,
        reloadDropdown: false

    }

    classSectionHash = {}
    conceptHash = {}
    eloHash = {}

    componentDidMount() {
        this.getClasses()
    };

    getClasses(payload = {}) {
        SchoolApi.getClassAndBoard(payload)
            .then(school => {
                let classes = [], boards = [], classNames = [];

                for (let i = 0; i < school.classes.length; i++) {
                    classes.push({
                        title: school.classes[i].name,
                        value: school.classes[i].name,
                    })
                }

                for (let i = 0; i < school.boards.length; i++) {
                    boards.push({
                        title: school.boards[i].name,
                        value: school.boards[i].name,
                    })
                }


                let board = ''
                if (boards.length) {
                    board = boards[0].value
                }


                classNames = classes.map(c => c.value)

                if (!this.props.delegate?.props?.location?.state) {
                    this.getSections({ board, class: classNames })
                }

                if (boards.length > 1) {
                    this.setState({ boardsDropdownItems: boards })
                } else {
                    this.setState({ selectedBoard: school?.boards[0]?.name })
                }
                console.log(this.state.selectedClass)
                this.setState({ classDropdownItems: classes })
            }).catch(err => {
            })
    }

    getSections(payload = {}) {
        console.log(this.state.selectedClass)

        if (!payload.class) payload.class = this.state.selectedClass
        if (!payload.board) payload.board = this.state.selectedBoard

        if (!payload.class || !payload.board) {
            this.setState({ sectionDropdownItems: [], selectedSection: "" })
            this.reloadDropdown()
            return
        }

        SchoolApi.getSections(payload)
            .then(response => {
                console.log(response)
                let sections = []
                for (let i = 0; i < response.response.length; i++) {

                    if (!response.response[i].noSectionInClass) {
                        sections.push({
                            title: response.response[i].section,
                            value: response.response[i]._id,
                        })
                    }
                }
                this.classSectionHash[payload.class] = sections
                this.setState({ sectionDropdownItems: sections, selectedSection: "", unAssignedUsersCount: response.unAssignedUsers, noSectionInClassUsersCount: response.noSectionInClassUsersCount })
                this.reloadDropdown()
            }).catch(err => {
                this.setState({ sectionDropdownItems: [], selectedSection: "" })
                this.reloadDropdown()
            })
    }

    getChapters(payload = {}) {



        if (!payload.class) payload.class = this.state.selectedClass
        if (!payload.board) payload.board = this.state.selectedBoard

        console.log(payload)

        this.props.getChapterList && this.props.getChapterList(payload)
    }

    sortPayload(nodes) {
        let orderedList = [];
        let workFlowNodeHash = {};
        let startingNode;

        for (let i = 0; i < nodes.length; i++) {

            let nodeId = nodes[i]._id.toString();

            if (nodes[i].previousNode == null)
                startingNode = nodeId;

            workFlowNodeHash[nodeId] = nodes[i];
        }

        let tempNode = startingNode;

        for (let i = 0; i < nodes.length; i++) {
            if (tempNode) {
                orderedList.push(workFlowNodeHash[tempNode]);
                tempNode = workFlowNodeHash[tempNode].nextNode;
            }
        }
        return orderedList
    }


    getWorkflowNodes(payload = {}) {
        payload = {
            class: this.state.selectedClass,
            board: this.state.selectedBoard,
            chapterKey: this.state.selectedChapter,
            masterDataId: this.state.masterDataId
        }
        MasterDataApi.getWorkflowNodes(payload)
            .then(response => {
                response = response.data
                for (let i = 0; i < response.length; i++) {
                    let chapter = response[i], elos = []

                    for (let j = 0; j < chapter.elos?.length; j++) {
                        let elo = chapter.elos[j]
                        elos.push({
                            title: elo.title,
                            value: elo.id,
                            conceptTitle: chapter.conceptTitle,
                            conceptValue: chapter.conceptKey
                        })
                    }
                    if (this.eloHash[chapter.conceptKey]) {
                        this.eloHash[chapter.conceptKey].push(...elos)
                    } else {
                        this.eloHash[chapter.conceptKey] = elos
                    }
                }

                let eloList = this.sortPayload(response)
                this.setState({ orderedEloList: eloList })
            }).catch(err => {
            })
    }

    buildElosDropdown(concept) {
        let elos = []
        for (let i = 0; i < concept?.subConcepts?.length; i++) {
            let subConcept = concept.subConcepts[i]
            for (let j = 0; j < subConcept.elo?.length; j++) {
                let elo = subConcept.elo[j]
                elos.push({
                    title: elo.title,
                    value: elo.eloId,
                    conceptTitle: concept.title,
                    conceptValue: concept.key
                })
            }

        }
        this.eloHash[concept.key] = elos
        return
    }

    reloadDropdown() {
        this.setState({ reloadDropdown: true }, () => {
            this.setState({ reloadDropdown: false })
        })
    }

    resetDropDowns(callback) {
        this.setState({
            selectedBoardId: "",
            selectedClassId: "",
            chapterDropdownItems: [],
        }, () => { if (callback) callback(); });
    }


    onGo() {
        let payload = {
            board: this.state.selectedBoard,
            class: this.state.selectedClass,
            chapterKey: this.state.selectedChapterKey,
            assignedTo: this.state.selectedTeachersId
        };
        if (this.props.onGo) {
            this.props.onGo(payload)
        }
    }

    refreshPage() {
        this.setState(
            { reload: true },
            () => this.setState({ reload: false })
        )
    }

    toggleFilters = () => {
        this.setState({ showFilters: !this.state.showFilters })
    }

    onSelectDropDown = (option, key, cb) => {
        console.log(option)
        this.setState({ [key]: option?.value, [`${key}Object`]: option, reloadDropdown: true }, () => {

            this.setState({ reloadDropdown: false })

            if (cb) cb()
            if (key == "selectedClass" || key == "selectedBoard") {
                console.log(this.state.selectedBoard)
                // this.getSections()
                this.getChapters()


            }

            if (key == "selectedChapter") {
                if (option?.value) {
                    this.getWorkflowNodes()

                    let concepts = this.conceptHash[option?.value]
                    // concepts.push({title:'ALL',value:'ALL'})
                    this.setState({ conceptDropownItems: concepts, reloadConceptDropdown: true }, () => {
                        this.setState({ reloadConceptDropdown: false })
                    })
                } else {

                    this.setState({ conceptDropownItems: [], reloadConceptDropdown: true }, () => {
                        this.setState({ reloadConceptDropdown: false })
                    })
                }
                this.reloadDropdown()
            }

        })
    }

    onMultiSelectDropdown = (options, key) => {
        let value = [], eloDropdownItems = []
        options = options.sort((a, b) => a.order - b.order)
        for (let i = 0; i < options.length; i++) {
            let option = options[i]
            value.push(option?.value)

            if (key == "selectedConcept") {
                if (this.eloHash[option?.value]) {

                    let elo = this.eloHash[option?.value]
                    let elos = elo.map((e) => {
                        return {
                            ...e,
                            conceptName: `Concept ${i + 1}`
                        }
                    })
                    eloDropdownItems.push(...elos)
                }
            }
        }
        this.setState({ reloadDropdown: true, eloDropdownItems, [key]: value, [`${key}Object`]: options }, () => {
            this.setState({ reloadDropdown: false })
        })
    }


    onSelectElo = (options) => {
        let values = options.map(option => option?.value)
        let result = []
        for (let i = 0; i < this.state.orderedEloList?.length; i++) {
            let eloId = this.state.orderedEloList[i]?.elos[0]?.id
            if (values.includes(eloId)) {
                result.push(eloId)
            }
        }

        this.setState({ selectedElo: result, selectedEloObject: options }, () => {
        })
    }

    handleCreatedDateEvent = (event, picker) => {
        // this.createdStartDate = new Date(picker.startDate);
        // this.createdEndDate = new Date(picker.endDate);

        this.createdDateRangeStart = new Date(picker.startDate);
        this.createdDateRangeEnd = new Date(picker.endDate).toLocaleDateString() == new Date().toLocaleDateString() ? new Date() : new Date(picker.endDate);

        this.setState({ createdDate: this.createdDateRangeStart.toLocaleDateString(), selectedCreatedDateRange: this.createdDateRangeStart.toLocaleDateString() + "  -  " + this.createdDateRangeEnd.toLocaleDateString() });
    }

    handleDueDateEvent = (event, picker) => {
        this.dueDateRangeStart = new Date(picker.startDate);
        this.dueDateRangeEnd = new Date(picker.endDate).toLocaleDateString() == new Date().toLocaleDateString() ? (new Date(new Date().setHours(23, 59, 59, 999)).toISOString()).slice(0, -1) : (new Date(new Date(picker.endDate).setHours(23, 59, 59, 999)).toISOString()).slice(0, -1);

        this.setState({ dueDate: new Date(this.dueDateRangeStart).toLocaleDateString(), selectedDueDateRange: new Date(this.dueDateRangeStart).toLocaleDateString() + "  -  " + new Date(this.dueDateRangeEnd).toLocaleDateString() });
    }


    onClickGo = (callback) => {

        let payload = {}
        if (this.state.selectedBoard) {
            payload.board = this.state.selectedBoard
        }
        if (this.state.selectedClass) {
            payload.class = this.state.selectedClass
        }
        if (this.state.selectedSection) {
            payload.sectionId = this.state.selectedSection
        }
        if (this.state.search) {
            payload.search = this.state.search
        }
        if (this.state.selectedStatus) {
            payload.status = this.state.selectedStatus
        }
        if (this.state.selectedSchoolApprovalStatus) {
            payload.schoolApprovalStatus = this.state.selectedSchoolApprovalStatus
        }
        if (this.props.getChapterList) {
            this.props.getChapterList(payload)
        }
    }

    clearFilter = () => {
        this.getClasses()
        this.setState({
            selectedBoard: "",
            selectedClass: "",
            selectedSection: "",
            search: "",
            selectedStatus: ""
        }, () => {
            this.refreshPage()
            if (this.props.getChapterList) {
                this.props.getChapterList({})
            }
        })

    }

    refreshPage() {
        this.setState(
            { reload: true },
            () => this.setState({ reload: false })
        )
    }

    onChangeHandler(event, key) {
        let value = event.target.value
        console.log(value)
        this.setState({ [key]: value }, () => {
            this.props.changeChapterList && this.props.changeChapterList(this.state.search)
        })

    }


    render() {

        return (

            <div className="learning-resources-filter">
                {/* <div className="filter-header" >
                    <div className="d-inline" onClick={this.toggleFilters}>
                        <div className="filter-title d-inline">{"Filters"}</div>
                        <div className="d-inline"><i class={this.state.showFilters ? "filter-arrow up" : "filter-arrow down"}></i></div>
                    </div>
                </div> */}
                {
                    !this.state.reload ?
                        <div className={this.state.showFilters ? "filter-content" : "filter-content d-none"} >
                            <div className="row">
                                <div className="col-8">
                                    {/* <div className="filter-input-label">
                                        Search
                                    </div> */}

                                    <input className={`cw-input-for-search`}
                                        // disabled={this.state.selectedClass ? false : true}
                                        value={this.state.search}
                                        onChange={(event) => this.onChangeHandler(event, "search")}
                                        placeholder='Search by Chapter Name' />

                                </div>

                                <div className="col-1 filter-buttons">
                                    {/* <div className="filter-button  d-inline-block">
                                        <button className={`cw-button`} onClick={this.onClickGo.bind(this)}>{"GO"}</button>
                                    </div> */}
                                    <div className="filter-button ml-3 d-inline-block">
                                        <button className={`cw-button clear-btn`} onClick={this.clearFilter.bind(this)}>{"CLEAR"}</button>
                                    </div>
                                </div>
                            </div>
                        </div> : ""

                }
            </div>


        );
    }

}

export default FilterComponent;
import React from "react";
import './case-study-end-screen.scss';
import SharedProfile from "../../../../../shared-storage/profile";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import { getImageSource, getThemeImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import CwConfirmationModal from "../../../common/cw-confirmation-modal/cw-confirmation-modal";
import Path from "../../../routes/routes-path";
import ViewAnswerTimer from "../../../my-classwork/view-answer-timer-popup-for-classwork/view-answer-timer";
import ChapterListPopup from "../../../my-classwork/chapter-list-popup/chapter-list-popup";
import ChapterAssessmentPerformanceReport from "../../../chapter-assessment-performance-report/chapter-assessment-performance-report";




export default class CaseStudyEndScreen extends React.Component {
    constructor(props) {
        super(props);

        //console.log(this.props)



        // this.state.data = {
        //     "correct": 1,
        //     "inCorrect": 1,
        //     "notAttempted": 0,
        //     "totalMark": 2,
        //     "markScored": 1,
        //     "timeTaken": 9,
        //     "marksArray": [
        //         {
        //             "marks": "1",
        //             "count": 1
        //         }
        //     ],
        //     "percentage": 100,
        //     "predictedScore": 45,
        //     "skillWisePercentage": {
        //         "": 50
        //     }
        // }

        this.meta = this.props.metaInfo

        this.state.data = this.meta.data;
        this.chapterTitle = this.meta.chapterTitle;
        this.categoryThemeId = this.meta.categoryThemeId;
        this.assessmentActivityId = this.meta.assessmentActivityId;
        this.chapterKey = this.meta.chapterKey;
        this.multipleChapters = []

        // this.getChapterList();

        this.userInfo = SharedProfile.getProfile()

        // this.staticText = SharedStaticText.getStaticText('AssessmentEndScreen');

        // if (!this.userInfo.prepkitGuaranteeAssessmentActivity || (this.userInfo.prepkitGuaranteeAssessmentActivity && this.userInfo.prepkitGuaranteeAssessmentActivity && this.userInfo.prepkitGuaranteeAssessmentActivity && this.userInfo.prepkitGuaranteeAssessmentActivity.class != this.userInfo.class)) {
        //    this.state.showPredictor = true
        // }
        this.theme = SharedThemeCategory.getThemeCategory(this.categoryThemeId)
    }

    state = {
        data: {
            markScored: '',
            notAttempted: '',
            timeTaken: '',
            correct: '',
            inCorrect: '',
            totalMark: '',

        },
        showPredictor: false,
        rangeText: ''
    }

    componentDidMount() {
        // this.props.navigation.addListener("didFocus", () => {

        //     BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });

        // this.props.navigation.addListener("didBlur", () => {

        //     BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });
        // moengage.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Completed', Chapters: this.meta.selectedChapters && this.meta.selectedChapters.length ? this.multipleChapters.toString() : this.chapterTitle, "Marks Scored": this.state.data.markScored, "Total Marks": this.state.data.totalMark, "Starting Point": this.getAssessmentStartingPoint() })
        // FireBaseEvent.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Completed', Chapters: this.meta.selectedChapters && this.meta.selectedChapters.length ? this.multipleChapters.toString() : this.chapterTitle, "Marks Scored": this.state.data.markScored, "Total Marks": this.state.data.totalMark, "Starting Point": this.getAssessmentStartingPoint() })
        // Facebook.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Completed', Chapters: this.meta.selectedChapters && this.meta.selectedChapters.length ? this.multipleChapters.toString() : this.chapterTitle, "Marks Scored": this.state.data.markScored, "Total Marks": this.state.data.totalMark, "Starting Point": this.getAssessmentStartingPoint() })
        // BranchIO.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Completed', Chapters: this.meta.selectedChapters && this.meta.selectedChapters.length ? this.multipleChapters.toString() : this.chapterTitle, "Marks Scored": `${this.state.data.markScored}`, "Total Marks": `${this.state.data.totalMark}`, "Starting Point": this.getAssessmentStartingPoint() })
    }

    // getUserExamPrepkitData = () => {
    //     let payload = {}

    //     Api.getUserExamPrepkitData(payload, (err, res) => {

    //         if (err) {
    //             return;
    //         }

    //         if (res && res.length) {
    //             let userExamPrepkitData = res[0]

    //             this.setState({userExamPrepkitData})

    //         }




    //     })

    // }


    handleBackButtonClick = () => {

        if (this.meta && this.meta.isFromMyHomework) {
            this.props.navigation.navigate('MyHomeworks')
            return
        }

        if (this.meta && this.meta.isExamPrepKit) {
            this.props.navigation.navigate('ExamPrepKitTableOfContent', {
                meta: {
                    chapterTitle: this.chapterTitle,
                    chapterKey: this.chapterKey,
                    categoryThemeId: this.categoryThemeId,
                    from: 'CHAPTERS_LIST'
                }
            });
            return;
        }
        this.props.navigation.navigate('MyAssessments')
        return true
    }



    closeViewAnswerPopup = () => {
        this.setState({ showViewAnswerPopUp: false })
    }

    getAssessmentStartingPoint = () => {
        // if (this.meta && this.meta.isFromMyHomework) {
        //     return 'Homework'
        // }
        // if (this.meta && (this.meta.from == constants.REWARD_EVENT_BASELINE || this.meta.from == constants.REFRESHER_COURSE || (this.meta.from == constants.EVENT_BASELINE))) {
        //     return 'Event Baseline Assessment'
        // }

        // if (this.meta && (this.meta.from == constants.SCHOLARSHIP_MAY_9_2021 || this.meta.from == constants.REWARD_EVENT_FINAL || this.meta.from == constants.REFRESHER_COURSE || (this.meta.from == constants.SCHOLARSHIP_EVENT))) {
        //     return 'Event Finishline Assessment'
        // }

        // if (this.meta && this.meta.from == constants.MPL_EVENT ) {
        //     return 'MPL Event'
        // }
        return "Chapter Assessment"
    }


    toggleLateralMenu = () => {

        this.props.navigation.navigate('LateralMenu')
    }

    onPressCheckYourRank = () => {
        this.props.navigation.navigate('LeaderBoard', { meta: { eventId: this.meta.eventId } })
    }

    hideChaptersPopup = () => {
        this.setState({ showChaptersPopup: false })
    }

    showChaptersPopup = () => {
        this.setState({ showChaptersPopup: true })
    }

    // getChapterList = () => {

    //     if (this.meta.selectedChapters && this.meta.selectedChapters.length) {
    //         return
    //     } else if (this.meta.chapterKey) {


    //         let payload = {}


    //         Api.getChapterList(payload, (err, res) => {

    //             if (err) {
    //                 this.setState({ loading: false })
    //                 return;
    //             }

    //             let allChapters = res.items

    //             let chapters = res.items.filter(chapter => chapter.key == this.meta.chapterKey)

    //             this.setState({
    //                 allChapters,
    //                 chapter: chapters[0],
    //                 masterDataId: res.masterDataId,
    //                 overallProgress: res.overallProgress,
    //                 categories: res.categories,
    //                 loading: false
    //             })

    //             this.separateChapters(this.state.chapters);
    //         })
    //     }
    // }

    getBorderColor = () => {


        if (this.state.data.percentage >= 0 && this.state.data.percentage <= 30) {
            return '#E96A6A'
        }

        if (this.state.data.percentage >= 31 && this.state.data.percentage <= 70) {
            return '#EEC865'
        }

        if (this.state.data.percentage >= 71 && this.state.data.percentage <= 100) {
            // return '#87CA9E'

            return '#0AC6D6'
        }

    }

    onPressContinue = () => {
        if (this.state.showPredictor) {
            let isTrial = this.userInfo.isPrepkitFreeUser

            let percentage = this.state.data.percentage
            let predictedScore = this.state.predictedScore


            // if (this.state.userExamPrepkitData && this.state.userExamPrepkitData.chapters && this.state.userExamPrepkitData.chapters.length && this.state.userExamPrepkitData.examDate) {
            //     this.props.navigation.navigate('PrepKitChapterList', { meta: { title: "Circles" } });

            // } else if (this.state.userExamPrepkitData && (!this.state.userExamPrepkitData.chapters || !this.state.userExamPrepkitData.chapters.length) && this.state.userExamPrepkitData.examDate) {
            //     this.props.navigation.navigate('ExamPrepKitChapterSelectionScreen')

            // }
            // // else if (this.state.userExamPrepkitData && this.state.userExamPrepkitData.chapters && this.state.userExamPrepkitData.chapters.length && !this.state.userExamPrepkitData.examDate) {
            // //     this.props.navigation.navigate('ExamDateCaptureScreen')
            // // }
            // else {

            this.props.navigation.navigate('ExamPrepKitIntroScreen', { meta: { from: "LANDING_SCREEN", showGuarantee: isTrial ? true : false, guaranteedScore: 10, percentage: percentage ? percentage : 5 } });
            // }



        } else {

            let revisionChapterKeys = []

            if (this.meta.selectedChapters && this.meta.selectedChapters.length) {
                for (let i = 0; i < this.meta.selectedChapters.length; i++) {
                    revisionChapterKeys.push(this.meta.selectedChapters[i].key)
                }

                this.props.navigation.navigate('RevisionChaptersList', { meta: { revisionChapterKeys: revisionChapterKeys } });
            } else if (this.meta.chapterKey) {

                let chapter = this.state.chapter

                //console.log(chapter)

                // if (chapter.status == constants.IS_CHAPTER_NOT_STARTED) {  
                //     this.toggleChapterCompletedModal(true, chapter.title, chapter.key, chapter.categoryId, chapter.lockedForTrial, chapter)   
                // } else {
                //     this.navigate(chapter.key,chapter.title,chapter.status == constants.IS_CHAPTER_NOT_COMPLETED ? true : false,chapter.categoryId,chapter.lockedForTrial,chapter)
                // }


            }

        }
    }


    goToHomeScreen() {

        return this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToClassworkListScreen() {
        return this.props.delegate && this.props.delegate.hideAssessmentEndScreen && this.props.delegate.hideAssessmentEndScreen()
    }



    showInsightsModal = () => {
        this.setState({ showInsights: true })
    }

    hideInsightsModal() {
        this.setState({ showInsights: false })
    }

    showPerformanceReport = () => {
        this.setState({ showPerformanceReport: true })
    }

    hideChapterAssessmentReport() {
        this.setState({ showPerformanceReport: false })
    }


    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToPracticeQuestionsChapterList() {
        this.props.delegate && this.props.delegate.goToPracticeQuestionsChapterList && this.props.delegate.goToPracticeQuestionsChapterList()
    }

    goToPracticeList() {
        this.props.onSelectChapter && this.props.onSelectChapter({ key: this.meta.chapterKey, title: this.chapterTitle })
    }

    navigateToViewAnswer = () => {
        this.props.delegate && this.props.delegate.showViewAnswerScreen && this.props.delegate.showViewAnswerScreen({
            meta: {
                categoryThemeId: this.categoryThemeId,
                chapterTitle: this.chapterTitle,
                chapterKey: this.chapterKey,
                assessmentActivityId: this.assessmentActivityId,
                fromAssess: true,
                type: this.meta.type,
                from: this.meta && this.meta.from
            }
        })
    }

    goToSelectedChapter() {
        this.props.delegate && this.props.delegate.goToSelectedChapter && this.props.delegate.goToSelectedChapter()
    }


    renderBreadcrumbForPracticeQuestions() {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 cursor-pointer pl-2' onClick={() => this.goToSelectedChapter()} >{`${this.chapterTitle ? this.chapterTitle : 'Chapter Title'}`}{` >`}</span>
                <span className='title-1 cursor-pointer pl-2' onClick={() => this.goToSelectedChapter()}>{`Practice >`}</span>
                <span className='title-1 pl-2'>Case Study Completed</span>
                {/* <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    openPdf(url) {

        window.open('https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/9b9957ae-ba01-4368-bca6-f725a34570c6_Tractor%20Case%20Study.pdf')
        // window.open(eventCertificate.url, '_blank').focus();

        //this.setState({ showCertificate: true, eventCertificateDetail: eventCertificate, selectedIndex: index })
        // var a = document.createElement('A');
        // var filePath = eventCertificate.url;
        // a.href = filePath;
        // a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

    }


    render() {


        if (this.state.showPerformanceReport) {
            return <ChapterAssessmentPerformanceReport delegate={this}
                assessmentActivityId={this.assessmentActivityId}
                questionType={'Case Study Based Questions'} isCompletionScreen={true}
                from={'CASE_STUDY'} chapterTitle={this.chapterTitle} />
        }





        return (
            <div className="case-study-end-screen">
                <img className="header-image-size" src={getThemeImageSource(ImageConstants.HOMEWORK_COMPLETION_SCREEN_HEADER, this.state.categoryId)} />
                {this.renderBreadcrumbForPracticeQuestions()}

                <div className="text-center great-message mt-5 mb-1">Case Study Questions Completed!</div>
                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.categoryThemeId)}></img>
                </div>
                <div className="row ml-0 mr-0 justify-content-center">
                    <div className="col mt-4 ml-5">
                        <div className="chapter-title text-center mt-3">{this.chapterTitle}</div>
                        <div className="lets-text text-center mt-2">Click on Insights to Look in to your scores</div>
                        <div className="revise-text text-center mt-2">Revise concepts for better scoring</div>
                        <div className="text-center">
                            <img className="workout-image-size mt-3" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_MALE_IMAGE, this.state.categoryId)} />
                        </div>
                        <div className="mt-5 d-flex justify-content-center align-items-center">
                            <div className="button text-center ">
                                <div className="cursor-pointer" onClick={() => this.showPerformanceReport()}>
                                    {"Insights"}
                                </div>
                            </div>
                            <div className="learning-gap-button text-center ml-4">
                                <div className="cursor-pointer" onClick={() => this.navigateToViewAnswer()} >
                                    {"View Answers"}
                                </div>
                            </div>
                            {/* <div className="learning-gap-button text-center ml-4">
                                <div className="cursor-pointer" >
                                    {"Bridge Learning Gaps"}
                                </div>
                            </div> */}
                            {/* <div className="continue-button text-center ml-4">
                                <div className="cursor-pointer" >
                                    {"Continue to Next Set"}
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* <img className="vertical-line" src={getThemeImageSource(ImageConstants.ASSESSMENT_END_SCREEN_VERTICAL_LINE, this.categoryThemeId)}></img> */}
                    {/* <div className="col-5 text-center">
                        <div className="revise-concept">Revise concepts for better scoring</div>
                        <img className="workout-image-size mt-3" src={getImageSource(ImageConstants.SUCCESS_ICON)} />
                        <div className="d-flex justify-content-center button-wrapper ml-3 mt-3">
                            <div className="text-center insights-button cursor-pointer mt-5" style={{ backgroundColor: this.theme['themeColor'] }}
                                onClick={() => this.openPdf()} >View Sample Case Study</div>
                        </div>
                    </div> */}
                </div>
                {<img className="footer-image-size " src={getThemeImageSource(ImageConstants.HOMEWORK_COMPLETION_SCREEN_FOOTER, this.state.categoryId)}></img>}
                {this.state.showChaptersPopup && <ChapterListPopup modal={true} delegate={this} close={this.hideChaptersPopup} chapters={this.meta.selectedChapters ? this.meta.selectedChapters : []} categoryThemeId={this.meta.categoryThemeId} />}
                {
                    this.state.showInsights && <CwConfirmationModal showInsights={true} hideInsightsModal={() => this.hideInsightsModal()} />
                }
            </div>
        )
    }
}

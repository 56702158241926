import React from "react";
import './otp.scss';
import SharedStaticText from "../../../../shared-storage/static-text-store";

class OTP extends React.Component {


    constructor(props) {
        super(props)
        this.otpStaticText = SharedStaticText.getStaticText('otpComponent');
        this.timer(this.otpStaticText.resendOtpTime ? this.otpStaticText.resendOtpTime : 90)
        this.state.seconds = this.otpStaticText.resendOtpTime ? this.otpStaticText.resendOtpTime : 90
    }


    state = {
        isLoginFailed : false,
        invalidOtp : false,
        seconds : 90,
        showTimer : true,
        otp : '',
        otpBox1 : '',
        otpBox2 : '',
        otpBox3 : '',
        otpBox4 : ''
    }


    onKeyUpEvent(index, event, boxType) {
        const eventOtp = event.which || event.keyCode;
        if (this.getOtpBoxElement(index, boxType).value.length === 1) {
            if (index !== 4) {
                this.getOtpBoxElement(index + 1, boxType).focus()
                this.getOtpBoxElement(index + 1, boxType).select();
            } else {
                this.getOtpBoxElement(index, boxType).blur();
            }
        }
        if (eventOtp === 8 && index !== 1) {
            this.setState({ invalidOtp: false, confirmPasscode: false, invalidPsscode: false })
            this.getOtpBoxElement(index - 1, boxType).focus()
            this.getOtpBoxElement(index - 1, boxType).select();
        }
    }


    getOtpBoxElement(index, boxType) {
        return document.getElementById(boxType + index);
    }

    onFocusEvent(index, boxType) {
        if (this.state.isLoginFailed) {
            this.setState({ isLoginFailed: false })
        }
        for (let item = 1; item < index; item++) {
            const currentElement = this.getOtpBoxElement(item, boxType);
            if (!currentElement.value) {
                currentElement.focus();
                currentElement.select()
                break;
            }
        }
    }


    validateOTP() {

        if (this.state.otpBox1 && this.state.otpBox2 && this.state.otpBox3 && this.state.otpBox4) {
            return true
        }
        return false
    }


    onChangeHandler = (event, key) => {
        if (key === "mobileNumber") {

            if (!this.allowOnlyNumbers(event.target.value)) return

            this.setState({
                [key]: event.target.value,
                isInvalidMobile: false,
                isMobileNumberEdit: false,
                isMobileNumberNotRegistered: false,
                showNewPasscodeSetText: false,
                userListToSetPasscode: [],
                isValidMobileNumber: this.validateMobile(event.target.value)
            });
        } else {
            this.setState({ [key]: event.target.value }, () => {
                
                if (key.startsWith('passcodeBox') === true) {
                    this.setState({
                        passCode: `${this.state.passcodeBox1 + this.state.passcodeBox2 + this.state.passcodeBox3 + this.state.passcodeBox4}`,
                        // isLoginEnable: this.validateOTP()
                    })
                } else if (key.startsWith('newPasscodeBox') === true) {
                    this.setState({
                        newPassCode: `${this.state.newPasscodeBox1 + this.state.newPasscodeBox2 + this.state.newPasscodeBox3 + this.state.newPasscodeBox4}`,
                        // isLoginEnable: this.validateOTP()
                    })
                } else if (key.startsWith('reEnteredPasscodeBox') === true) {
                    this.setState({
                        reEnteredPassCode: `${this.state.reEnteredPasscodeBox1 + this.state.reEnteredPasscodeBox2 + this.state.reEnteredPasscodeBox3 + this.state.reEnteredPasscodeBox4}`
                    })
                } else {
                    this.props.delegate && this.props.delegate.onOtpChange(`${this.state.otpBox1 + this.state.otpBox2 + this.state.otpBox3 + this.state.otpBox4}`)
                }

               
            });
        }
    }



    resetOtpBoxs = () => {
        this.setState({
            otpBox1: '',
            otpBox2: '',
            otpBox3: '',
            otpBox4: ''
        });
    }


   


    renderOtpInput() {
        return (
            <div className="passcode-boxes">
                <input id="otpBox1" className={`input-one-margin-left  ${this.state.isLoginFailed || this.props.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, "otpBox")} onFocus={() => this.onFocusEvent(1, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox1')} value={this.state.otpBox1} />
                <input id="otpBox2" className={`${this.state.isLoginFailed || this.props.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, "otpBox")} onFocus={() => this.onFocusEvent(2, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox2')} value={this.state.otpBox2} />
                <input id="otpBox3" className={`${this.state.isLoginFailed || this.props.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, "otpBox")} onFocus={() => this.onFocusEvent(3, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox3')} value={this.state.otpBox3} />
                <input id="otpBox4" className={`${this.state.isLoginFailed || this.props.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, "otpBox")} onFocus={() => this.onFocusEvent(4, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox4')} value={this.state.otpBox4} />
            </div>)
    }

    timer(time) {

        if (time === 0) {
            this.setState({ showTimer: false });
            return;
        }


        this.setState({ seconds: time });
        let timeoutId = setTimeout(() => { this.timer(--time) }, 1000)

    }


    resendOtp = () => {
        this.setState({ showTimer: true}, () => this.timer(this.otpStaticText.resendOtpTime ? this.otpStaticText.resendOtpTime : 90));
        this.resetOtpBoxs();
        this.props.delegate && this.props.delegate.resendOtp && this.props.delegate.resendOtp()
    }

    render() {
        return (
            <div className="otp-input-box">
                <label className="cw-input-label-1">Enter OTP</label>
                {this.renderOtpInput()}
                {
                    !this.state.showTimer ?
                        <div className="resend-otp">
                            <span className="cursor-pointer" onClick={() => this.resendOtp()}>{this.otpStaticText.resendOtp}</span></div>
                        :
                        <div className="resend-otp otp-seconds">{this.otpStaticText.resendOtpIn} <strong>{this.state.seconds}</strong>s</div>
                }
            </div>
        )
    }

}


export default OTP;
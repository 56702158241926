import React from 'react'
import { Redirect, Router, Switch } from 'react-router-dom'
import Route from './route-wrapper';
import Path from './routes-path';
import history from './history';
import Layout from '../../../layout/layout'
import LoginScreen from '../../components/login-screen/login-screen';
import StudentSignup from '../student/signup/signup';
import WatchVideo from '../watch-video/watch-video';

export default function Routes() {

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={Path.LOGIN} component={LoginScreen} />
        <Route exact path={Path.SIGNUP} component={StudentSignup} />
        <Route exact path={Path.HOME} component={Layout} />
        <Route exact path={Path.WATCH_VIDEO} component={WatchVideo} />
        <Route exact path="*" component={Layout} />
      </Switch>
    </Router>
  )
}

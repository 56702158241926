import HttpClient from './http-client';
import URL from './url';

export default class VideoSchoolAssignmentApi {

    static getUserVideoSchoolAssignmentActivities(payload) {

        let url = URL.BASE_URL + URL.USER_VIDEO_SCHOOL_ASSIGNMENT_ACTIVITY;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static updateVideoWatchedDetails(payload) {
        return HttpClient.put(URL.BASE_URL + URL.USER_VIDEO_SCHOOL_ASSIGNMENT_ACTIVITY, null, payload);
    }

    static getUserVideoSchoolAssignmentActivitiesUsingMobileNumber(payload) {

        let url = URL.BASE_URL + URL.USER_VIDEO_SCHOOL_ASSIGNMENT_ACTIVITY_USING_MOBILE_NUMBER;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static updateVideoWatchedDetailsUsingMobileNumber(payload) {
        return HttpClient.put(URL.BASE_URL + URL.USER_VIDEO_SCHOOL_ASSIGNMENT_ACTIVITY_USING_MOBILE_NUMBER, null, payload);
    }

}
import React from "react";
import './incorrect-details-popup.scss';
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import Parser from "../../../common/content-parser/content-parser";
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";


export default class IncorrectDetails extends React.Component {
    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.staticText = SharedStaticText.getStaticText('IncorrectSchoolDetails');

    }
    componentWillMount() {
        // this.keyboardDidShowSub = Keyboard.addListener('keyboardDidShow', this.handleKeyboardDidShow);
        // this.keyboardDidHideSub = Keyboard.addListener('keyboardDidHide', this.handleKeyboardDidHide);
    }

    state = {
        countryCodes: [],
        searchText: '',
        noData: false
    }



    closeModal = () => {
        this.props.delegate && this.props.delegate.hideIncorrectDetailsPopup();
    }


    select = (value) => {
        if (value == "SCHOOL") {
            this.setState({ schoolSelected: !this.state.schoolSelected })
        }

        else if (value == "CLASS") {
            this.setState({ classSelected: !this.state.classSelected })
        }

        else if (value == "SECTION") {
            this.setState({ sectionSelected: !this.state.sectionSelected })
        }
    }

    submit = () => {
        let payload = {
            items: []
        }

        if (this.state.schoolSelected) {
            payload.items.push("SCHOOL")
        }
        if (this.state.classSelected) {
            payload.items.push("CLASS")
        }
        if (this.state.sectionSelected) {
            payload.items.push("SECTION")
        }

        if (!payload.items.length)
            return;

        this.props.onSubmit && this.props.onSubmit(payload.items)
    }


    render() {

        return (
            <Modal className="custom-incorrect-details-popup" isOpen={true} >
                <ModalBody>
                    <div>
                        <div>
                            <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                            <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.closeModal()} />
                        </div>
                        <div className="title">{this.staticText && this.staticText.headerText ? this.staticText.headerText : 'Let us know which details are incorrect'}</div>
                        <div className="mt-5 pl-5 body-wrapper">
                            {this.props.schoolName ?
                                <div className="d-flex align-items-center mt-3">
                                    <div className="mr-3">
                                        {!this.state.schoolSelected ?
                                            <div className="radio-button cursor-pointer" onClick={() => { this.select('SCHOOL') }}>
                                            </div>
                                            :
                                            <div className="" onClick={() => { this.select('SCHOOL') }}>
                                                <img className="incorrect-image cursor-pointer" src={getImageSource(ImageConstants.WRONG)} />
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        <div className="school-label">{this.staticText && this.staticText.schoolText ? this.staticText.schoolText : 'SCHOOL'}</div>
                                        <div className="label-text">{this.props.schoolName}, {this.props.locality}</div>
                                    </div>

                                </div>
                                :
                                <></>
                            }
                            {this.props.class ?
                                <div className="d-flex align-items-center mt-3">
                                    <div className="mr-3">
                                        {!this.state.classSelected ?
                                            <div className="radio-button cursor-pointer" onClick={() => { this.select('CLASS') }}>
                                            </div>
                                            :
                                            <div className="" onClick={() => { this.select('CLASS') }}>
                                                <img className="incorrect-image cursor-pointer" src={getImageSource(ImageConstants.WRONG)} />
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        <div className="school-label">{this.staticText && this.staticText.classText ? this.staticText.classText : 'CLASS'}</div>
                                        <div className="label-text">{this.props.class}</div>
                                    </div>

                                </div>
                                :
                                <></>
                            }

                            {this.props.section ?
                                <div className="d-flex align-items-center mt-3">
                                    <div className="mr-3">
                                        {!this.state.sectionSelected ?
                                            <div className="radio-button cursor-pointer" onClick={() => { this.select('SECTION') }}>
                                            </div>
                                            :
                                            <div className="" onClick={() => { this.select('SECTION') }}>
                                                <img className="incorrect-image cursor-pointer" src={getImageSource(ImageConstants.WRONG)} />
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        <div className="school-label">{this.staticText && this.staticText.sectionText ? this.staticText.sectionText : 'SECTION'}</div>
                                        <div className="label-text">{this.props.section}</div>
                                    </div>

                                </div>
                                :
                                <></>
                            }

                        </div>


                        <div className="button-wrapper d-flex justify-content-center mt-3">

                            <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.submit}>
                                {this.staticText && this.staticText.submitText ? this.staticText.submitText : 'SUBMIT'}
                            </div>
                        </div>

                        <div className="">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        )
    }

}
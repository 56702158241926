import React from "react";
import './invalid-answer-modal.scss';
import SharedStaticText from "../../../../shared-storage/static-text-store";
import { Modal, ModalBody } from "reactstrap";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedThemeCategory from "../../../../shared-storage/category-theme";

export default class InvalidAnswerModal extends React.Component {

    staticText = {};
    constructor(props) {
        super(props);
        // setCategoryThemeStyles(props.categoryThemeId)

        this.staticText = SharedStaticText.getStaticText('pleaseAnswerTheQuestionPopUpScreen');
    }

    onOk = () => {
        this.props.delegate && this.props.delegate.onOk && this.props.delegate.onOk();
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <Modal className="cw-custom-modal-for-invalid-answer" isOpen={true} size={'md'} >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_HEADER, this.props.categoryThemeId)}></img>
                    {/* <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.closeModal()} /> */}
                </div>
                <ModalBody>

                    <div className="pratice-container">
                        <div className="position">
                            <div className="d-flex flex-row align-items-center justify-content-center">
                                <div>
                                    <img className="image-size" src={getImageSource(ImageConstants.RED_INFO_ICON)} />
                                </div>
                                <div className="ml-1">
                                    <div className="name-header">{this.staticText.title}</div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-2">
                                <div className="take-challenge  text-center cursor-pointer" onClick={this.onOk} style={{ backgroundColor: this.theme['themeColor'] }}>
                                    {this.staticText.buttonTitle ? this.staticText.buttonTitle : 'Okay'}
                                </div>
                            </div>

                            <div className="text-center image-wrapper">
                                <img className="invalid-answer" src={getImageSource(ImageConstants.INVALID_ANSWER)}></img>
                            </div>

                            <img className="challenge-footer" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_FOOTER, this.props.categoryThemeId)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}
import React from 'react';
import './learning-content-wrapper.scss';
import SharedProfile from '../../../../shared-storage/profile';
import GlossaryParser from '../../common/content-parser/glossary-parser';
import { Constants } from '../../../constants/constants';
import { ImageConstants } from '../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import LearningContent from '../learning-content/learning-content';
import LearningContentHeader from '../learning-content-header/learning-content-header';
import PracticeQuestionsApi from '../../../apis/practice-questions';
import LearningContentFooter from '../learning-footer/learning-footer';
import LearningContentPopup from '../learning-content-popup/learning-content-popup';
import Path from '../../routes/routes-path';
import FireBaseEvent from '../../../firebase-events/firebase-events';


export default class LearningContentWrapper extends React.Component {
    constructor(props) {
        super(props);
        const { navigation } = this.props;
        // this.ycontentOffsetPosition = screenHeight / 4;
        this.scroll = React.createRef()

        this.state.elo = this.props.elo;

        this.glossaryParser = new GlossaryParser();

        // this.handleBackButton = this.handleBackButton.bind(this);
        this.userInfo = SharedProfile.getProfile();


        if (this.props.navigationInfo.nodes && this.props.navigationInfo.nodes.length) {
            let currentConceptKey = ''
            for (let i = 0; i < this.props.navigationInfo.nodes.length; i++) {
                if (this.props.navigationInfo.nodeId === this.props.navigationInfo.nodes[i].id) {
                    currentConceptKey = this.props.navigationInfo.nodes[i].conceptKey
                    break;
                }
            }

            let currentConceptNodesList = this.props.navigationInfo.nodes.filter(node => node.conceptKey === currentConceptKey)
            let currentElo = currentConceptNodesList.filter(node => node.id === this.props.navigationInfo.nodeId)
            
            this.state.currentEloSkill = currentElo[0].elos[0].skill

            // console.log(currentConceptNodesList)

            this.state.currentConceptNodesList = currentConceptNodesList
        }


        if (this.props.navigationInfo && this.props.navigationInfo.isLateralMenuNavigation) {
            let index = 0;
            if (this.props.elo) {
                this.props.elo.map((elo, i) => {
                    if (elo.eloId == this.props.navigationInfo.activeEloId) {
                        index = i;
                    }
                });
                this.state.eloIndex = index;
            }
        }

        if (this.props.isChapterIntro) {
            this.logNavigation({ ...this.props.navigationInfo });
            if (!this.props.isRevise) {
                this.logTrialActivity({ ...this.props.navigationInfo });
            }
        } else {
            this.logEloNavigation();
        }
    }

    pagesRef = {}

    nextLearningContentMap = {};
    allElosLearningHash = {};

    videoPlayerRefs = {};
    state = {
        eloIndex: 0,
        fadeIn: false,
        scrollViewHeight: null,
        showShadow: false,
        glossaryContent: null,
        blurNavigationFeedBack: false,
        elo: [],
        isLast: false,
        currentConceptNodesList: []
    };

    // animatedScroll = new Animated.Value(0);

    pageLength = this.props.elo ? this.props.elo.length : 0;
    currentPage = this.props.elo && this.props.elo.length ? 1 : 0;


    componentDidMount() {
        this.getGlossaryContent();
        // KeepAwake.activate();
        // BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
        this.prepareLearningContentVisibility();
        this.getBookmarkHelpShowed();
        this.scrollTo()





        // let numOfElos = this.props.elo.length;

        // for (let i = 0; i < numOfElos; i++) {

        //     let numOfObjectsInElo = this.props.elo[i].contents.length;

        //     let blurFlag = false;
        //     let showEye = false;
        //     for (let j = 0; j < numOfObjectsInElo; j++) {

        //         this.props.elo[i].contents[j].map((content, index) => {

        //             if (content.type == 3 && blurFlag == false) {
        //                 blurFlag = true;
        //                 showEye = true;
        //             }
        //             else {
        //                 content.showBlur = blurFlag;
        //                 content.showEye = showEye;
        //                 showEye = false;
        //             }
        //         });
        //     }
        // }
    }




    logTrialActivity(navigationInfo) {
        // if (navigationInfo && navigationInfo.chapterKey) {
        //     let payload = {
        //         type: constants.LOG_CHAPTER_INTRO,
        //         info: { chapterKey: navigationInfo.chapterKey }
        //     }
        //     TrailUserActivityLog.activity(payload);
        // }
    }


    logNavigation(navigationInfo) {
        if (navigationInfo)
            navigationInfo.navigateChapterIntroduction = true; // Flag added to call to identify call from navigation

        let payload = {
            type: "CHAPTER",
            info: navigationInfo
        }

        // Api.logUserNavigation(payload, (err, res) => {
        //     if (err) {
        //         return;
        //     }
        //     console.log("navigation logged");
        // });

    }


    logEloNavigation(isLast) {
        //console.log("logEloNavigation ***********", this.props.elo[this.state.eloIndex]);
        if (isLast) {
            let payload = {
                type: "ELO",
                eloStatus: "IN_ACTIVE",
                nodeId: this.props.navigationInfo ? this.props.navigationInfo.nodeId : ""
            }
            // Api.logUserNavigation(payload, (err, res) => {
            //     if (err) {
            //         return;
            //     }
            //     console.log("navigation logged");
            // });
        } else {

            let elo = this.props.elo[this.state.eloIndex];
            let navigationInfo = {};

            if (this.props.elo[this.state.eloIndex]) {
                navigationInfo.chapterTitle = this.props.title;
                navigationInfo.eloId = elo.eloId;
                navigationInfo.title = elo.eloTitle;
                navigationInfo.otherInfo = this.props.navigationInfo;
                navigationInfo.nodeId = this.props.navigationInfo ? this.props.navigationInfo.nodeId : "";
            }

            let payload = {
                type: "ELO",
                info: navigationInfo,
                eloStatus: "ACTIVE"
            }

            // Api.logUserNavigation(payload, (err, res) => {
            //     if (err) {
            //         return;
            //     }
            //     console.log("navigation logged");
            // });
        }

    }


    getGlossaryContent = () => {

        // console.log("eloCategory", this.props.elo)

        if (this.props && this.props.elo && this.props.elo.length > 0) {

            let elos = this.props.elo

            let contents = [];

            for (let i = 0; i < elos.length; i++) {

                let contentsList = elos[i]['contents'];

                for (let j = 0; j < contentsList.length; j++) {

                    contents = contents.concat(contentsList[j].contents)
                }
            }

            if (contents.length > 0) {

                this.glossaryParser.getParsedContentIdsFromContent(contents, (err, glossaryContent) => {
                    this.setState({
                        glossaryContent
                    })
                })

            } else {
                this.setState({
                    glossaryContent: {}
                })
            }

        } else {
            this.setState({
                glossaryContent: {}
            })
        }
    };

    nextElo = () => {

        //Reset Scroll Content height on next

        this.contentScrollHeight = 0;
        this.headerHeight = 0;
        this.setState({ showShadow: false, isScrollStart: false })


        if (this.state.eloIndex < this.props.elo.length - 1) {
            this.setState({
                eloIndex: this.state.eloIndex + 1,
                fadeIn: true
            }, () => {
                this.logEloNavigation();
                this.prepareLearningContentVisibility();
            })
        }
        else {
            if (!this.props.isChapterIntro)
                this.logEloNavigation(true);
            this.goBack();
        }

        // this.scroll.scrollTo({ x: 0, y: 0, animated: false });
        this.hidePagination();
    }

    previousElo = () => {

        if (this.state.eloIndex >= 1) {
            this.setState({
                eloIndex: this.state.eloIndex - 1,
                fadeIn: true
            }, () => {
                this.logEloNavigation();
                this.prepareLearningContentVisibility();
            })
            // this.scroll.scrollTo({ x: 0, y: 0, animated: false });
        }
        // this.scroll.scrollTo({x: 0, y: 0, animated: false});
        this.hidePagination();
    }

    hidePagination = () => {
        setTimeout(() => { this.setState({ fadeIn: false }) }, 1000)
    }

    showPagination = () => {
        this.setState({
            fadeIn: true
        })
    }

    // renderPagination = (position) => {

    //     if (this.props.elo.length > 1) {
    //         return (
    //             this.props.elo.map((_, i) => {


    //                 return (
    //                     <View style={{ flexDirection: 'row', alignItems: 'center' }}>

    //                         <View style={this.state.eloIndex == i ? Styles.dot : Styles.inActiveDot}>
    //                             <Text style={this.state.eloIndex == i ? Styles.activeIndex : Styles.inActiveIndex}>{i + 1}</Text>
    //                         </View>
    //                         {i < this.props.elo.length - 1 ?

    //                             <View style={Styles.dotConnector}></View>
    //                             :
    //                             <></>

    //                         }

    //                     </View>

    //                 );

    //             })

    //         )
    //     }

    // }

    getActiveLine = (i) => {

        let bgColor = `linear-gradient(90deg,#05C5D9 0%,#5FD590 100%)`
        return (
            <div className='gradient-border-height' style={{ backgroundImage: bgColor }} >

            </div>
        )
    }

    getInactiveLine = () => {

        return (
            <div className='gradient-border-height'></div>
        )

    }


    getPages = () => {

        console.log(this.state.currentConceptNodesList)
        let render = [];


        let bgColor = `linear-gradient(90deg,#05C5D9 0%,#5FD590 100%)`

        let navigateTill = 0

        let currentConceptNodesList = this.state.currentConceptNodesList

        if (currentConceptNodesList && currentConceptNodesList.length) {
            for (let i = 0; i < currentConceptNodesList.length; i++) {

                if (currentConceptNodesList[i].id == this.props.navigationInfo.nodeId) {
                    navigateTill = i;
                    break;
                }
            }


            for (let i = 0; i < currentConceptNodesList.length; i++) {

                if (navigateTill == i) {
                    render.push(
                        <div ref={ref => { this.pagesRef[i] = ref }} key={i} className='dot-line-container d-flex align-items-center'>
                            {this.getActiveLine(i)}
                            <div>
                                <div className='index-container' style={{ backgroundImage: bgColor }} >
                                    <span className='active-index'>{i + 1}</span>
                                </div>
                            </div>

                        </div>
                    )

                } else if (i <= navigateTill) {
                    render.push(
                        <div ref={ref => { this.pagesRef[i] = ref }} key={i} className='dot-line-container d-flex align-items-center' >
                            {this.getActiveLine(i)}
                            <div className='' onClick={() => this.navigate(i, navigateTill)}>
                                <div className='small-index-container' style={{ backgroundImage: bgColor }} >
                                    <span className='inactive-index'>{i + 1}</span>
                                </div>
                            </div>
                            {/* {i < this.props.navigateTill ? this.getActiveHitslopAdjustmentLine() : this.getInactiveHitslopAdjustmentLine()} */}
                        </div>
                    )
                } else if (i > navigateTill) {
                    render.push(

                        <div ref={ref => { this.pagesRef[i] = ref }} key={i} className='dot-line-container d-flex align-items-center'>
                            {this.getInactiveLine()}
                            <div className='small-index-container' >
                                <span className='inactive-index' >{i + 1}</span>
                            </div>
                        </div>
                    );
                }

            }
        }



        return render;
    }

    navigate = (index, activeIndex) => {

        // if(this.props.activeIndex > this.activeIndex ){
        //     this.activeIndex = this.props.activeIndex
        // }

        // this.props.delegate && this.props.delegate.navigate(index, activeIndex);

    }

    goBack = () => {

        let isRevise = this.props.isRevise ? this.props.isRevise : false;

        this.props.delegate && this.props.delegate.learningElosCompleted && this.props.delegate.learningElosCompleted(isRevise);

    }

    goBackToEloList = () => {

        this.props.delegate && this.props.delegate.goBackToEloListScreen && this.props.delegate.goBackToEloListScreen();
    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
    }

    shadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    toggleShadow = () => {
        if (!this.state.isScrollStart)
            this.setState({ showShadow: !this.state.showShadow, isScrollStart: true })
        else
            this.setState({ showShadow: !this.state.showShadow })
    }

    hideChapterIntroduction = () => {
        this.props.delegate && this.props.delegate.hideChapterIntroduction && this.props.delegate.hideChapterIntroduction();
    }

    goToChapterList = () => {

        this.props.delegate && this.props.delegate.goToChapterList && this.props.delegate.goToChapterList();
    }

    scrollUp = () => {
        // if (this.pageYPosition > screenHeight - wp(50))
        //     this.scroll.scrollTo({ x: 0, y: this.ycontentOffsetPosition + wp(50) });
    }

    // getGlossaryContentIds = (data) => {
    //     this.glossaryParser.getParsedContentIdsFromContent(data, (err, glossaryContent) =>
    //     { return glossaryContent }
    //     )
    // }

    async getBookmarkHelpShowed() {
        // let doc = await AsyncStore.getBookmarkHelp();

        // if (doc) {
        //     doc = JSON.parse(doc)

        //     if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`]) {

        //         doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`] = true

        //         AsyncStore.setBookmarkHelp(doc)

        //         this.setState({ showBookMarkHelp: true })
        //     }
        // } else {
        //     AsyncStore.setBookmarkHelp({ [`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`]: true })
        //     this.setState({ showBookMarkHelp: true })
        // }
    }

    hideOverlayHelp = () => {
        this.setState({ showBookMarkHelp: false })
    }


    componentWillUnmount() {
        // KeepAwake.deactivate();
        // BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
    }

    handleBackButton() {
        if (this.props.isChapterIntro) {
            this.props.delegate && this.props.delegate.hideChapterIntroduction && this.props.delegate.hideChapterIntroduction()
            return true;
        }
    }

    prepareLearningContentVisibility() {

        let numOfElos = this.props.elo.length;

        for (let i = 0; i < numOfElos; i++) {

            let numOfObjectsInElo = this.props.elo[i].contents.length;

            let blurFlag = false;
            let showEye = false;
            for (let j = 0; j < numOfObjectsInElo; j++) {

                this.props.elo[i].contents[j].contents.map((content, index) => {

                    if (content.type == 3 && blurFlag == false && !this.nextLearningContentMap[content._id] && !this.props.reviseChapterIntroduction && !this.props.noBlur) {

                        content.showBlur = blurFlag;
                        content.showEye = showEye;
                        blurFlag = true;
                        showEye = true;
                    }
                    else {
                        content.showBlur = blurFlag;
                        content.showEye = showEye;
                        showEye = false;
                    }
                });
            }

        }

        let contents = this.props.elo[this.state.eloIndex].contents;
        let eloId = this.props.elo[this.state.eloIndex].eloId;
        let blurNavigationFeedBack = false;
        let checkPointQuestions = [];
        let noOfQuestionsAnswered = this.allElosLearningHash[eloId] ? Object.keys(this.allElosLearningHash[eloId]).length : 0;
        contents.map(group => {

            group.contents.map(data => {

                if (data.type == 3) {
                    checkPointQuestions.push(data)
                }
            })

        });
        if (checkPointQuestions.length > 0 && checkPointQuestions.length != noOfQuestionsAnswered && !this.props.reviseChapterIntroduction && !this.props.noBlur) {

            blurNavigationFeedBack = true;

        }

        console.log('blur contents', blurNavigationFeedBack);

        this.setState({
            eloIndex: this.state.eloIndex,
            fadeIn: true,
            blurNavigationFeedBack: blurNavigationFeedBack,
            elo: this.props.elo
        });

    }

    refresh() {
        // this.scroll.scrollTo({ x: 0, y: this.ycontentOffsetPosition - 60, animated: true })
    }

    openNextLearningContent(content) {

        let eloId = this.props.elo[this.state.eloIndex].eloId;

        this.nextLearningContentMap[content._id] = content;

        if (this.allElosLearningHash[eloId]) {
            this.allElosLearningHash[eloId][content._id] = content;
        }
        else {
            this.allElosLearningHash[eloId] = { [content._id]: content }
        }

        this.prepareLearningContentVisibility();
    }

    onClickBookMarkActive = (id, index) => {

        let payload = { _id: id }

        PracticeQuestionsApi.deleteBookMark(payload).then((res) => {
            let elo = this.state.elo;

            delete elo[this.state.eloIndex]['bookMarkedItem'][index];

            this.setState({ elo })

        }).catch(err => {
            if (err) {
                console.log('delete Bookmark')
                return;
            }
        })

    }

    renderLearningContent = () => {

        let element = [];
        if (this.state.glossaryContent && this.state.elo[this.state.eloIndex].contents) {

            let contentLength = this.state.elo[this.state.eloIndex].contents.length;

            // console.log(contentLength)

            for (let i = 0; i < contentLength; i++) {

                if (i != 0) {
                    element.push(
                        <img className='d-flex justify-content-center' style={{ maxWidth: "100%", objectFit: "contain", marginBottom: "30px" }} src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryId)} resizeMode="cover" />
                    );
                }
                let contentsPerElo = this.state.elo[this.state.eloIndex].contents[i].contents;

                let groupTitle = this.state.elo[this.state.eloIndex].contents[i].title;

                let contentObjArray = contentsPerElo.map(item => ({ id: item._id, objectType: item.type, contentType: item.content.type }))

                let contentObj = { title: groupTitle, contentIds: contentObjArray }

                let bookMarkId = this.state.elo[this.state.eloIndex]['bookMarkedItem'] && this.props.elo[this.state.eloIndex]['bookMarkedItem'] && this.props.elo[this.state.eloIndex]['bookMarkedItem'][i] && this.props.elo[this.state.eloIndex]['bookMarkedItem'][i].bookMarkId ? this.props.elo[this.state.eloIndex]['bookMarkedItem'][i].bookMarkId : null
                // console.log(contentsPerElo)
                contentsPerElo.map((data, index) =>

                    element.push(
                        <div key={data._id}>
                            <LearningContent
                                content={data}
                                delegate={this}
                                glossary={this.state.glossaryContent}
                                groupTitle={groupTitle}
                                showTitle={index == 0 ? true : false}
                                categoryId={this.props.elo[this.state.eloIndex].categoryThemeId}
                                contentObj={contentObj} showInactiveBookmark={bookMarkId ? false : true}
                                showBookMark={bookMarkId ? true : false} bookMarkId={bookMarkId} selectedIndex={i} />
                            {index < contentsPerElo.length - 1 &&
                                <img className='d-flex justify-content-center' style={{ maxWidth: "100%", objectFit: "contain", marginBottom: "30px" }} src={getImageSource(ImageConstants.CONTENT_DIVIDER_GRAY)} />
                            }
                        </div>
                    )
                )
            }

            return element;
        }
    }

    togglePopup = (flag) => {
        ///console.log("*******************togglePopup", flag)

        if (!flag) {
            let element = document.getElementById(this.props.elo[this.state.eloIndex].eloId)
            // console.log('element', element)
            element.scrollIntoView({ behavior: "instant", block: 'start' })
        }

        if (this.props.elo[this.state.eloIndex].previousKnowledge && this.props.elo[this.state.eloIndex].previousKnowledge.length)
            this.setState({ showLearningPopup: flag });
    }

    onSwipeRight() {

        this.previousElo();
    }

    onSwipeLeft() {
        if (!this.state.blurNavigationFeedBack && this.state.eloIndex != this.props.elo.length - 1)
            this.nextElo();
    }

    // setScrollRef = (ref) => {
    //     this.scroll = ref;
    // }

    onClickBookMark = (contentObj, index) => {

        let payload = {
            chapterKey: this.props.navigationInfo.chapterKey,
            contents: contentObj
        }

        if (this.props.isChapterIntro) {
            payload['isChapterIntro'] = true
        } else {
            payload['eloId'] = this.props.elo[this.state.eloIndex]['eloId'];
        }

        PracticeQuestionsApi.createBookMark(payload).then((res) => {
            console.log(res)
            let elo = this.state.elo;

            if (elo[this.state.eloIndex]['bookMarkedItem'])
                elo[this.state.eloIndex]['bookMarkedItem'][index] = { bookMarkId: res.data.bookMarkId }
            else
                elo[this.state.eloIndex]['bookMarkedItem'] = { [index]: { bookMarkId: res.data.bookMarkId } }


            if (this.props.isChapterIntro) {
                // moengage.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Type: 'Chapter Introduction' })
                FireBaseEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Type: 'Chapter Introduction' })
                // Facebook.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Type: 'Chapter Introduction' })
                // BranchIO.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Type: 'Chapter Introduction' })

            } else {
                // moengage.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })
                FireBaseEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })
                // Facebook.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })
                // BranchIO.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })


            }


            this.setState({ elo })
        }).catch(err => {
            if (err) {
                console.log('learning content create bookmark')
                return;
            }
        })

    }

    onPressAskDoubt = () => {

        this.setState({ loading: true })

        let payload = {};

        // Api.getDoubtsLimti(payload, (err, res) => {
        //     if (err) {
        //         this.setState({ loading: false })
        //         return;
        //     }

        //     if (!res || res.doubtsLimitType == 'LIMITED' && res.maxDoubt == 0) {

        //         console.log("limit", res)
        //         this.setState({
        //             loading: false,
        //             showDoubtLimitPopUp: true
        //         })
        //         return
        //     }

        //     this.doubtLimitPayload = res
        //     this.setState({ loading: false, showAskDoubt: true })
        // })
    }

    closeAskDoubt = () => {
        this.setState({ showAskDoubt: false })
    }

    onSuccessDoubt = () => {
        this.setState({ showAskDoubt: false }, () => {
            this.showAskDoubtSuccessPopUp()
        })
    }

    showAskDoubtSuccessPopUp = () => {
        this.setState({ showSuccessPopup: true })
    }

    hideSuccessPopUp = () => {
        this.setState({ showSuccessPopup: false })
    }

    hideDoubtLimitPopUp = () => {
        this.setState({ showDoubtLimitPopUp: false })
    }

    scrollTo = () => {
        //    //console.log(this.scroll)
        // if (this.pagesRef && this.props.activePage && this.pagesRef[this.props.activePage - 1]) {
        //     this.scroll.scrollTo({ x: this.pagesRef[this.props.activePage - 1].x, animated: true })
        // }

        let currentConceptNodesList = this.state.currentConceptNodesList

        if (currentConceptNodesList && currentConceptNodesList.length) {
            let activePage = 0

            for (let i = 0; i < currentConceptNodesList.length; i++) {

                if (currentConceptNodesList[i].id == this.props.navigationInfo.nodeId) {
                    activePage = i;
                    break;
                }
            }


            let keys = Object.keys(this.pagesRef)
            console.log(keys.length, activePage)



            if (this.pagesRef && activePage && this.pagesRef[activePage - 1]) {

                let singlePageNumberWidth = this.scroll.current.scrollWidth / keys.length

                // let scrollLeftPosition =  activePage * this.pagesRef[activePage - 1].offsetWidth 

                let scrollLeftPosition = (activePage - 1) * singlePageNumberWidth




                this.scroll.current.scrollLeft = scrollLeftPosition

            }


        }


    }

    goToHomeScreen() {
        this.props.previousDelegate && this.props.previousDelegate.props.history.push(Path.HOME)
    }

    goToBrowseChapterList() {
        this.props.previousDelegate && this.props.previousDelegate.hideEloList && this.props.previousDelegate.hideEloList()
    }

    goToConceptList() {
        this.props.delegate && this.props.delegate.hideWorkflowExperience && this.props.delegate.hideWorkflowExperience({ meta: this.conceptListMeta })
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb ml-2 mt-2 mb-2'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToBrowseChapterList()} >{`Browse Chapter >`}</span>
                <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToConceptList()}>{`${this.params.title} >`}</span>
                <span className='pl-2 title-1'>{`${this.params.eloTitle}`}</span>
            </div>
        )
    }

    render() {

        // setCategoryThemeStyles(this.props.elo[this.state.eloIndex].categoryThemeId);
        // console.log(this.props.elo)

        return (
            <div id={this.props.elo[this.state.eloIndex].eloId} className=''>
                {
                    !this.state.showLearningPopup &&
                    <div>
                        <div>
                            <LearningContentHeader
                                isScrolled={this.state.showShadow}
                                title={!this.props.isChapterIntro && this.props.elo[this.state.eloIndex].eloTitle}
                                chapterTitle={this.props.title}
                                delegate={this}
                                conceptTitle={this.state.currentConceptNodesList &&
                                    this.state.currentConceptNodesList.length && this.state.currentConceptNodesList[0].conceptTitle}
                                isChapterIntro={this.props.isChapterIntro}
                                isChapterIntroRevise={this.props.isChapterIntroRevise}
                                categoryId={this.props.elo[this.state.eloIndex].categoryThemeId}
                                isPKExist={this.props.elo[this.state.eloIndex].previousKnowledge && this.props.elo[this.state.eloIndex].previousKnowledge.length}
                            />
                        </div>

                        <div>
                            {this.renderLearningContent()}
                        </div>
                        {
                            !this.props.isRevise ?
                                <div className='pagination-container'>
                                    <div className='dot-container d-flex align-items-center justify-content-center' ref={this.scroll}>{this.getPages()}</div>
                                </div>
                                :
                                <></>
                        }

                        <LearningContentFooter
                            blur={this.state.blurNavigationFeedBack}
                            delegate={this}
                            isPreviousEnabled={this.state.eloIndex == 0 ? false : true}
                            isChapterIntro={this.props.isChapterIntro}
                            categoryId={this.props.elo[this.state.eloIndex].categoryThemeId}
                            title={!this.props.isChapterIntro && this.props.elo[this.state.eloIndex].eloTitle}
                            chapterTitle={this.props.title}
                            conceptTitle={this.state.currentConceptNodesList &&
                            this.state.currentConceptNodesList.length && this.state.currentConceptNodesList[0].conceptTitle}
                            isRevise = {this.props.isRevise}
                            currentEloSkill = {this.state.currentEloSkill ? this.state.currentEloSkill : ''}
                             />
                    </div>
                }

                {/* To Show the PK Content */}

                {this.state.showLearningPopup && <LearningContentPopup elos={this.props.elo[this.state.eloIndex].previousKnowledge} title={this.props.title} delegate={this} chapterKey={this.props.navigationInfo.chapterKey} />}

                {/* {this.state.showDoubtLimitPopUp ? <DoubtLimitPopUp close={this.hideDoubtLimitPopUp} categoryThemeId={this.props.elo[this.state.eloIndex].categoryThemeId ? this.props.elo[this.state.eloIndex].categoryThemeId : "1"}></DoubtLimitPopUp> : <></>}

                {this.state.showLearningPopup && <LearningContentPopup elos={this.props.elo[this.state.eloIndex].previousKnowledge} title={this.props.title} delegate={this} chapterKey={this.props.navigationInfo.chapterKey} />}

                {this.state.showAskDoubt && <WorkoutAskDoubt delegate={this} doubtLimitPayload={this.doubtLimitPayload} eloDetails={this.props.elo[this.state.eloIndex]} categoryThemeId={this.props.elo[this.state.eloIndex].categoryThemeId} chapterKey={this.props.navigationInfo.chapterKey}  chapterTitle={this.props.title} />}

                {this.state.showSuccessPopup && <DoubtSuccessPopUp close={this.hideSuccessPopUp} categoryThemeId={this.props.elo[this.state.eloIndex].categoryThemeId} />}

                {this.state.showBookMarkHelp && <BookmarkHelp showModal={this.state.showBookMarkHelp} headerHeight={this.headerHeight} delegate={this} type={'BOOKMARK'} />} */}
            </div>
        );
    }
}




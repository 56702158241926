import React, { Component } from 'react';
import { ImageConstants } from '../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import './assessment-footer.scss';
// import { QuestionContext } from '../../../workflow/workflow-experience'
import { MyAssessmentContext } from '../my-assessment-context/my-assessment-content';
import { StandardClassworkConText } from '../../class-work/standard-classwork-experience';
import SharedThemeCategory from '../../../../shared-storage/category-theme';
// import { WorkoutContext } from '../../../screens/workout/workout-experience'
// import { RefresherCourseContaxt } from '../../../screens/refresher-course/refresher-course-experience'
// import { StandardHomeworkConText } from '../../../screens/homework/standard-homework-experience'
// import { StandardHomeworkLeaningExperienceConText } from '../../../screens/homework/standard-homework-learning-experience'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


export default class AssessmentFooter extends React.Component {
    // static contextType = MyAssessmentContext;

    constructor(props) {
        super(props);
        this.state = {
            enableNextButton: false,
            countDownTime: this.props.userAnswerStatus ? 15 : 30
        };
        //console.log(this.props.isReadOnly, this.props.isFromViewCorrectAnswer, this.props.userAnswerStatus)

        if (this.props.isReadOnly && this.props.isFromViewCorrectAnswer) {
            // setTimeout(() => {
            //     this.enableNextButton()
            // }, this.props.userAnswerStatus ? 1500 : 3000)


        }

    }

    componentDidMount() {
        // if (this.twoStepEventTimer) {
        //     clearInterval(this.twoStepEventTimer)
        // }
        // this.getTimeReducerForNextButton()
    }

    getTimeReducerForNextButton = () => {
        this.nextButtonTimer = setInterval(() => {



            if (this.state.nextButtonTimeRemaining == 0) {
                //console.log(this.nextButtonTimer)
                if (this.nextButtonTimer) {
                    clearInterval(this.nextButtonTimer)

                    //console.log("Completed Time")

                    // this.setState({ twoStepEventUserCurrentStepStatus: 'START', eventRes: event })
                    return
                }
            }
            this.setState({ nextButtonTimeRemaining: this.state.nextButtonTimeRemaining - 1 })

        }, 1000)
    }

    enableNextButton() {
        this.setState({ enableNextButton: true })
    }

    previous(event) {

        if (this.props.delegate && this.props.delegate.previous) {
            this.props.delegate.previous(event);
        }
    }

    next(event, disableNext) {
        //console.log(disableNext)
        if (this.props.delegate && this.props.delegate.next && !disableNext) {
            //console.log('inside next questions')
            this.props.delegate.next(event);
        }
    }

    flag(event) {
        if (this.props.delegate && this.props.delegate.flag) {
            this.props.delegate.flag(event);
        }
    }

    bookmark(event) {
        if (this.props.delegate && this.props.delegate.bookmark) {
            this.props.delegate.bookmark(event);
        }
    }

    save(event) {
        if (this.props.delegate && this.props.delegate.save) {
            this.props.delegate.save(event);
        }
    }

    onPressFinish = () => {
        this.props.delegate && this.props.delegate.onFinish && this.props.delegate.onFinish()
    }

    renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            // return <div className="timer">Too lale...</div>;

            let element = document.getElementById("countdown-id");
            element.style.display = 'none';

        }

        return (
            <div className="timer">
                {/* <div className="text">You can do in</div> */}
                <div className="value">{remainingTime}</div>
                {/* <div className="text">seconds</div> */}
            </div>
        );
    };

    // enableNextButton() {
    //     //console.log("enable next button")
    // }



    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        let buttonBorder = this.theme['themeColor']

        return (
            <div className=''>
                {/* {
                    
                    <div className='timer-wrapper' id='countdown-id'>
                        <CountdownCircleTimer
                            size={45}
                            trailStrokeWidth={20}
                            trailColor='#000000'
                            strokeLinecap='square'
                            strokeWidth={15}
                            isPlaying
                            duration={this.state.countDownTime}
                            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                            colorsTime={[10, 6, 3, 0]}
                            onComplete={() => ({ shouldRepeat: true, delay: 1 }, this.enableNextButton())}
                        >
                            {(remainingTime) => this.renderTime(remainingTime)}
                        </CountdownCircleTimer>
                    </div>
                } */}


                <StandardClassworkConText.Consumer>
                    {({ disableNextStandardHomework }) => (<>

                        <MyAssessmentContext.Consumer>

                            {({ showFinishFooter, disableNext, startIndex }) => (<>

                                {
                                    showFinishFooter ?
                                        <div className='assessment-footer'>
                                            <div className="d-flex justify-content-end button-wrapper-for-finish">
                                                <div className="text-center finish-button cursor-pointer mr-5" style={{ background: this.theme['themeColor'], border: `2px solid ${buttonBorder}` }}
                                                    onClick={this.onPressFinish}>Finish</div>

                                                <div className="text-center next-button-2 cursor-pointer mr-5" style={{ background: this.theme['themeColor'], border: `2px solid ${buttonBorder}` }}
                                                    onClick={e => this.next(e, disableNext)}>Next</div>
                                            </div>
                                            <div> <img className='footer-image-size' src={getThemeImageSource(ImageConstants.ASSESSMENT_FOOTER, this.props.categoryThemeId)} /></div>
                                        </div>
                                        :
                                        <div className='assessment-footer'>


                                            {
                                                this.props.isFromViewCorrectAnswer && this.props.isReadOnly && this.state.enableNextButton ?
                                                    <div className="d-flex justify-content-end align-items-end button-wrapper">
                                                        {/* {startIndex != 0 ? <div className="text-center back-button cursor-pointer mr-5">Back</div> : <></>} */}

                                                        <div className="text-center next-button cursor-pointer" style={{ background: this.theme['themeColor'], border: `2px solid ${buttonBorder}` }}
                                                            onClick={e => this.next(e, disableNext)}>Next</div>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            this.props.isReadOnly ?
                                                                <div className="d-flex justify-content-end align-items-end button-wrapper-for-read-only">
                                                                    {/* {startIndex != 0 ? <div className="text-center back-button cursor-pointer mr-5">Back</div> : <></>} */}

                                                                    <div className="text-center next-button "
                                                                        style={{ border: "none", background: "#d1d3d5", color: this.theme['themeColor'] }}
                                                                    >Next</div>
                                                                    <div className='timer-wrapper' id='countdown-id'>
                                                                        <div className='timer-placement'>
                                                                            <CountdownCircleTimer
                                                                                size={40}
                                                                                trailColor='#1CEA24'
                                                                                isPlaying
                                                                                duration={this.state.countDownTime}
                                                                                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                                                                colorsTime={[10, 6, 3, 0]}
                                                                                onComplete={() => ({ shouldRepeat: false, delay: 1 }, this.enableNextButton())}
                                                                            >
                                                                                {/* {(remainingTime) => this.renderTime(remainingTime)} */}
                                                                            </CountdownCircleTimer>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="d-flex justify-content-end align-items-end button-wrapper">
                                                                    {/* {startIndex != 0 ? <div className="text-center back-button cursor-pointer mr-5">Back</div> : <></>} */}

                                                                    <div className="text-center next-button cursor-pointer" style={{ background: this.theme['themeColor'], border: `2px solid ${buttonBorder}` }}
                                                                        onClick={e => this.next(e, disableNext)}>Next</div>
                                                                </div>
                                                        }
                                                    </>

                                            }

                                            <img className='footer-image-size' src={getImageSource(ImageConstants.ASSESSMENT_FOOTER_IMAGE)} />
                                        </div>
                                }

                            </>
                            )}

                        </MyAssessmentContext.Consumer>
                    </>)}
                </StandardClassworkConText.Consumer>
            </div >
        );
    }
}


import React from "react";
import { Modal, ModalBody } from "reactstrap";
import './basic-plan-features.scss';
import CwConfirmationModal from "../../home/components/common/cw-confirmation-modal/cw-confirmation-modal";


class BasicPlanFeaturesPopup extends React.Component {




    render() {
        return(
            <CwConfirmationModal showTrialFeatures = {this.props.showTrialFeatures} closeBasicPlanFeatures = {() => this.props.closeBasicPlanFeatures()} />
        )
    }


}

export default BasicPlanFeaturesPopup;
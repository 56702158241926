import React from "react";
import './school-connect.scss';
import SharedProfile from "../../../../shared-storage/profile";
import SharedStaticText from "../../../../shared-storage/static-text-store";
import CommonApi from "../../../apis/common";
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SchoolConfirmationScreen from "./school-confirmation-popup/school-confirmation-popup";
import NewSchoolConnectFlow from "../new-school-connect-flow/new-school-connect-flow";

export default class SchoolConnect extends React.Component {
    constructor(props) {
        super(props);

        this.userInfo = SharedProfile.getProfile();
        this.staticText = SharedStaticText.getStaticText('schoolConnect');
        // const redirectTo = this.props.navigation.getParam('redirectTo');
        // this.state.redirectTo = redirectTo;
    }

    state = {
        isInvalidSchoolCode: false,
        showSchoolConnectPopUp: false
    }

    close = () => {
        // const { redirectTo } = this.state;
        // AsyncStore.removeSchoolCode()
        // if (redirectTo) {
        //     this.props.navigation.navigate(redirectTo, { deeplink: false });
        //     return;
        // }
        this.props.delegate.hideSchoolConnectPopup && this.props.delegate.hideSchoolConnectPopup()
    }

    onPressContinue = () => {
        this.setState({ submit: true })
        const { schoolCode } = this.state;
        // && schoolCode.length === 8
        // if(schoolCode.substr(0, 3) === "SCX") {
        // this.getSchoolInformationForSchoolUniqueCode();
        // } else {
        this.getSchoolInformationForSchoolCode()
        // }
    }

    closeSchoolPopUp = () => {
        this.setState({ showSchoolConnectPopUp: false })
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    getSchoolInformationForSchoolUniqueCode = () => {
        const { schoolCode } = this.state
        //console.log(schoolCode)
        let payload = {
            schoolCode: schoolCode
        }
        //console.log(payload)
        this.setState({ loading: true })

        CommonApi.getSchoolInformationFromSchoolCode(payload, (err, res) => {
            if (err) {
                this.setState({ isInvalidSchoolCode: true, loading: false })
                return
            }

            if (res) {
                this.setState({
                    schoolName: res.name,
                    locality: res.locality,
                    loading: false
                }, _ => {
                    this.setState({ showSchoolConnectPopUp: true })
                })
            } else {
                this.setState({ isInvalidSchoolCode: true, loading: false })
            }
        })
    }

    getSchoolInformationForSchoolCode = () => {
        const { schoolCode } = this.state;
        let payload = {
            schoolCode: schoolCode
        }

        this.setState({ loading: true })

        CommonApi.getSchoolInformationFromSchoolConnect(payload).then((res) => {
            if (res) {
                this.setState({
                    schoolName: res.name, locality: res.locality, schoolOriginalCode: res.code, loading: false,
                    classTitle: res.class,
                    board: res.board,
                    name: res.studentName,
                    section: res.section
                }, () => {
                    this.onPressConfirm()
                    // this.setState({ showSchoolConnectPopUp: true })
                })
            } else {
                this.setState({ isInvalidSchoolCode: true, loading: false })
            }
        }).catch(err => {
            if (err) {
                this.setState({ isInvalidSchoolCode: true, loading: false })
                return;
            }
        })
    }

    schoolCodeConfirm = () => {
        const { schoolCode, redirectTo, locality, schoolName } = this.state;
        let payload = {
            schoolCode: schoolCode
        }

        CommonApi.schoolConnectDirectConfirm(payload, (err, res) => {

            if (err) {
                return;
            }

            this.setState({ showSchoolConnectPopUp: false })

            this.userInfo['schoolName'] = schoolName;
            this.userInfo['schoolLocality'] = locality;
            this.userInfo['schoolVerificationCode'] = schoolCode

            if (res && res.section)
                this.userInfo['section'] = res.section

            SharedProfile.setProfile(this.userInfo)
            if (redirectTo) {
                this.props.navigation.navigate(redirectTo);
                return;
            }
            this.props.navigation.goBack()
        })
    }

    schoolConnectCodeConfirm = () => {
        const { schoolCode, redirectTo, locality, schoolName, schoolOriginalCode } = this.state;
        let payload = {
            schoolCode: schoolCode
        }

        CommonApi.schoolConnectConfirm(payload).then((res) => {
            this.setState({ showSchoolConnectPopUp: false })

            this.userInfo['schoolName'] = schoolName;
            this.userInfo['schoolLocality'] = locality;
            this.userInfo['schoolVerificationCode'] = schoolCode
            this.userInfo['schoolCode'] = schoolOriginalCode ? schoolOriginalCode : schoolCode

            if (res && res.section)
                this.userInfo['section'] = res.section

            // moengage.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })
            // FireBaseEvent.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })
            // Facebook.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })
            // BranchIO.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })

            SharedProfile.setProfile(this.userInfo)
            if (redirectTo) {
                this.props.navigation.navigate(redirectTo);
                return;
            }
            this.props.navigation.goBack()
        }).catch(err => {
            if (err) {
                return;
            }
        })
    }

    reset = () => {
        this.setState({ schoolCode: "", schoolName: "", isInvalidSchoolCode: false, locality: '' })
    }

    onPressDecline = () => {
        this.setState({ showSchoolConnectPopUp: false }, () => {
            this.reset()
        })
    }

    onPressConfirm = () => {
        // this.schoolConnectCodeConfirm();

        if (this.state.schoolCode.substr(0, 3) === "SCX") {
            this.setState({showSchoolConfirmationScreen : true, schoolMeta : { schoolCode: this.state.schoolCode }})
            // this.props.navigation.navigate('SchoolConfirmationScreen', { meta: { schoolCode: this.state.schoolCode } });
            return
        }

        let payload = {
            schoolCode: this.state.schoolCode,
            redirectTo: this.state.redirectTo,
            locality: this.state.locality,
            schoolName: this.state.schoolName,
            schoolOriginalCode: this.state.schoolOriginalCode,
            name: this.state.name,
            classTitle: this.state.classTitle,
            board: this.state.board,
            section: this.state.section
        }

        this.setState({showNewSchoolConnectFlow : true, newSchoolMeta : { payload } })

        // this.props.navigation.replace("NewSchoolConnectFlow", { meta: { payload } });

    }


    

    render() {

        if(this.state.showSchoolConfirmationScreen) {
            return(
                <SchoolConfirmationScreen delegate = {this} meta = {this.state.schoolMeta} />
            )
        }

        if(this.state.showNewSchoolConnectFlow) {
            return(
                <NewSchoolConnectFlow delegate = {this} meta = {this.state.newSchoolMeta} />
            )
        }

        return (
            <Modal className="custom-school-connect" isOpen={true}>
                <ModalBody>
                    <div>
                        <div>
                            <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                            <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.close()} />
                        </div>
                        <div className="title">{this.staticText.headerText}</div>
                        <div>
                            <div className="mt-4 pl-5" >
                                <div>
                                    <div className="school-code-text mb-2">{this.staticText.schoolCode}</div>
                                    <div className="row">
                                        <div className="col">
                                            <input className="cw-input-1"
                                                placeholder={this.staticText.schoolCodePlaceholder}
                                                onChange={(event) => this.setState({ schoolCode: event.target.value })}
                                                value={this.state.schoolCode}
                                                onFocus={() => this.setState({ isInvalidSchoolCode: false })}
                                                maxLength={150}
                                                ref={(ref) => { this.input = ref }} />
                                        </div>
                                    </div>
                                    {this.state.isInvalidSchoolCode ? <div className="error-message mt-2">{this.staticText.invalidSchoolCode}</div> : <></>}
                                </div>
                                <div className="warning-card d-flex mt-4">
                                    <div >
                                        <img className="info-image mr-2" src={getImageSource(ImageConstants.BULB_IMAGE)} />
                                    </div>

                                    <div>
                                        <div className="warning-text">
                                            {this.staticText.note}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="button-wrapper d-flex justify-content-center mt-4">
                                {this.state.schoolCode && !this.state.isInvalidSchoolCode ?
                                    <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.onPressContinue}>
                                        {this.staticText.connectButton}
                                    </div>
                                    :
                                    <div className="active-button save-button-disabled d-flex justify-content-center">
                                        {this.staticText.connectButton}
                                    </div>

                                }
                                <div className="connect-later-button d-flex justify-content-center cursor-pointer" onClick={this.close}>
                                    {this.staticText.connectLaterButton}
                                </div>

                            </div>
                        </div>

                        <div className="">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}
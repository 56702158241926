import React from "react";
import './section-popup.scss';
import CommonApi from "../../../../apis/common";
import SharedProfile from "../../../../../shared-storage/profile";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import FireBaseEvent from '../../../../firebase-events/firebase-events';



export default class SchoolSectionPopup extends React.Component {
    constructor(props) {
        super(props);

        this.userInfo = SharedProfile.getProfile();
        this.meta = this.props.meta;
        this.staticText = SharedStaticText.getStaticText('section');
        if (this.meta && this.meta.sections) {
            this.state.sections = this.meta.sections
            this.state.loading = false
        } else {
            this.getSchoolSections()
        }

    }

    state = {
        isInvalidSchoolCode: false,
        sections: [],
        showSchoolConnectPopUp: false,
        loading: true
    }

    getSchoolSections = () => {


        if (!this.userInfo.schoolCode) {
            this.setState({ loading: false })
            return
        }

        let payload = {}

        CommonApi.getSchoolSections(payload).then((data) => {
            this.setState({ loading: false })
            if (data.length) {
                this.setState({ sections: data })
            } else {
                this.setState({ sections: [] })
            }
        }).catch(err => {
            if (err) {
                this.setState({ sections: [], loading: false })
                return
            }
        })

    }


    close = () => {
        // if (this.meta && this.meta.from && this.meta.from == 'SCHOOL_CONFIRMATION') {
        //     this.props.navigation.navigate('LandingScreen', { meta: { showSubscriptionEndPopup: this.meta && this.meta.isExipred ? this.meta.isExipred : false, subscriptionEndDate: this.meta && this.meta.subscriptionEndDate ? this.meta.subscriptionEndDate : "", showFirstPage: true } });
        //     return
        // }
        this.props.delegate && this.props.delegate.hideSectionPopup && this.props.delegate.hideSectionPopup()
    }

    onPressContinue = () => {
        this.updateProfile()
    }

    onPressSection = (index) => {

        let sections = this.state.sections

        for (let i = 0; i < sections.length; i++) {
            sections[i].active = false
        }

        sections[index].active = true
        this.setState({ sections, showSaveActive: true })
    }

    closeSchoolPopUp = () => {
        this.setState({ showSchoolConnectPopUp: false })
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    updateProfile = () => {

        let payload = {}

        let activeSec = this.state.sections.filter(item => item.active)

        if (!activeSec.length) {
            return
        }

        activeSec = activeSec[0]

        payload['section'] = activeSec.section

        if (activeSec._id) {
            payload['sectionId'] = activeSec._id
        }

        this.setState({ loading: true })

        CommonApi.updateProfile(payload).then((response) => {

            this.onSuccessUpdate(payload.section, payload.sectionId)

            this.setState({ loading: false }, () => {
                this.close()
            })
        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
                return;
            }

        })
    }

    onSuccessUpdate = (section, id) => {

        this.userInfo.section = section
        if (id) {
            this.userInfo.sectionId = id
        }
        FireBaseEvent('My Profile',{'User Id':this.userInfo.userId,'Section Updated':section})
        // moengage.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Section Updated': section })
        //  FireBaseEvent.logCustomEvent('My Profile',{'User Id':this.userInfo.userId,'Section Updated':section})
        // Facebook.logCustomEvent('My Profile',{'User Id':this.userInfo.userId,'Section Updated':section})
        // BranchIO.logCustomEvent('My Profile',{'User Id':this.userInfo.userId,'Section Updated':section})
        SharedProfile.setProfile(this.userInfo);
    }


    renderSections = () => {

        let elements = []

        for (let i = 0; i < this.state.sections.length; i++) {

            let section = this.state.sections[i]

            elements.push(
                <div className={`section d-flex align-items-center 
                justify-content-center cursor-pointer ${section.active ? 'section-active' : ''}`}
                    onClick={() => this.onPressSection(i)}>
                    <div className={`${section.active ? 'section-active-text' : ''}`}>{section.section}</div>
                </div>
            )

        }

        return <div className="section-wrapper d-flex">{elements}</div>
    }

    onPressOk = () => {
        this.close()
    }

    renderNoRecords = () => {
        return <div>
            <div className="text-center">
                <img className="no-record-image" src={getImageSource(ImageConstants.NO_CLASSWORK_IMAGE)}></img>
            </div>

            <div >
                <div className="no-records-text">{this.staticText.noRecordTitle}</div>
            </div>

            <div >
                <div className="no-record-description">{this.staticText.noRecordDescription}</div>
            </div>
        </div>
    }

    render() {

        return (
            <Modal className="custom-section-popup" isOpen={true}>
                <ModalBody>
                    <div>
                        <div>
                            <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                            <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.close()} />
                        </div>
                        <div className="title">{this.staticText.title}</div>
                        <div className="mt-5 pl-5">
                            {this.state.sections && this.state.sections.length ? this.renderSections() : this.renderNoRecords()}
                        </div>

                        {this.state.sections && this.state.sections.length ?
                            <div className="button-wrapper d-flex justify-content-center">
                                {this.state.showSaveActive ?
                                    <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.onPressContinue}>
                                        {this.staticText.save}
                                    </div>
                                    :
                                    <div className="active-button save-button-disabled d-flex justify-content-center">
                                        {this.staticText.save}
                                    </div>

                                }
                                <div className="connect-later-button d-flex justify-content-center cursor-pointer" onClick={this.close}>
                                    {this.staticText.sectionNotListed}
                                </div>
                            </div>

                            :
                            <div className="d-flex justify-content-center">
                                <div className="ok-button d-flex justify-content-center cursor-pointer" onClick={this.close}>
                                    {this.staticText.ok}
                                </div>
                            </div>
                        }

                        <div className="">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}
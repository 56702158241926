import React from "react";
import './single-select.scss';
import CommonUtils from "../../../../utility/common-utilities";
import QuestionTitle from "../question-title/question-title";
import QuestionImage from "../../../common/cw-question-image-v1/cw-question-image";
import { getThemeImageSource, getImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import RadioGroup from "../../radio/radio-group";
import AssessmentFooter from "../../assessment-footer/assessment-footer";
// import SummarySoultions from "../../summary-solution/summary-solution";
// import DotPagination from "../../dot-pagination/dot-pagination";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import AnswerTab from "../../../my-assessment-result/question/answer-tab/answer-tab";
import ReactTooltip from "react-tooltip";
import StudentAnsweredStatusBoard from "../../student-answered-status-board/student-answered-status-board";
import AssessmentQuote from "../quotes-title/quotes-title";
import LayoutShared from "../../../common/layout-shared/layout-shared";
import ToasterComponent from "../../../common/cw-toaster-component/cw-toaster-component";
import ImagePreview from "../../../common/image-preview-v1/image-preview";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import AssessmentApi from "../../../../apis/assessment";




class SingleSelect extends React.Component {

    state = {
        content: null, attachments: [], showSolution: true, showOptions: false, isListening: false, studentList: [],
        stopReceivingStudentSignal: false,
        showStudentResponse: true,
        isPlaying: true,
        pauseCount: 0,
        pauseDetails: [], // Array to store each pause's details
        lastPausedAt: null, // To track when the timer was paused
        imageWidth: 0,
        imageHeight: 0,
        startTime: Date.now(),
        timeListHash: {},
        isReload: true,
        showQuestionLeaderBoard: false,
        leaderboardData: []
    };

    isValid = false;
    originalContent = {};
    answerTickCount = 0;
    optionsHash = {};
    userIdAnswerHash = {}


    constructor(props) {
        super(props)
        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');
        this.originalContent = JSON.parse(JSON.stringify((this.props.content)));


        if (this.props.isReadOnly) {
            console.log(this.props.questions, this.props.activePage, this.props.isReadOnly)
            let content = JSON.stringify(this.props.content)
            this.state.content = JSON.parse(content);
            this.state.studentList = this.props.studentList
            this.state.showStudentResponse = false

        }
        this.layoutShared = LayoutShared.getLayout()

        if (!this.layoutShared.state.showLayoutBar && !this.props.isReadOnly) {
            this.state.showStudentResponse = false
        }

    }



    componentDidMount() {

        if (this.props.isReadOnly) {
            this.handleStartListening()
            if (this.props.isFFF) {
                this.generateTimeList()
                this.getQuestionLeaderBoard()
            }

        }



        if (!this.props.isReadOnly) {
            if (this.props.content.content.images && this.props.content.content.images.length === 1) {
                // this.getMeta(this.props.content.content.images[0].imageUrl, (err, width, height) => {
                //     console.log(width, height);
                // });

                this.loadImage(this.props.content.content.images[0].imageUrl)

            }
            this.setState({ content: this.getSuffledItem(this.props.content), studentList: this.props.studentList });
            this.startTimerforAnswer()
            this.handleStartListening()
            let content = this.props.content
            // console.log(content.content.options)
            content.content.options.forEach((item, index) => this.optionsHash[index + 1] = item)
        }

    }


    generateTimeList() {

        let timeListHash = { correct: [], incorrect: [], notattempted: [] }

        let userIdAnswerHash = this.props.questions[this.props.activePage - 1].answer.answer

        for (let i = 0; i < this.props.studentList.length; i++) {

            let student = this.props.studentList[i]

            if (!student.isTeacher) {
                let answer = userIdAnswerHash[student.userId]

                if (answer) {
                    console.log(answer)
                    if (answer.answer.isCorrect) {
                        let timeTakenInMins = (answer.answer.timeTakenToAnswer / 1000).toFixed(3)

                        timeListHash['correct'].push({
                            userName: student.userName,
                            userId: student.userId,
                            studentNumber: student.studentNumber,
                            timeTakenInMins,
                            timeTakenToAnswer: answer.answer.timeTakenToAnswer,
                            status: 'CORRECT'
                        })
                    } else {
                        let timeTakenInMins = (answer.answer.timeTakenToAnswer / 1000).toFixed(3)

                        timeListHash['incorrect'].push({
                            userName: student.userName,
                            studentNumber: student.studentNumber,
                            userId: student.userId,
                            timeTakenInMins,
                            timeTakenToAnswer: answer.answer.timeTakenToAnswer,
                            status: 'IN_CORRECT'
                        })
                    }
                } else {
                    timeListHash['notattempted'].push({
                        userName: student.userName,
                        studentNumber: student.studentNumber,
                        userId: student.userId,
                        status: 'NOT_ATTEMPTED'
                    })
                }
            }
        }

        console.log(timeListHash)

        this.setState({ timeListHash, isReload: false }, () => {
            this.setState({ isReload: true })
        })

    }

    getQuestionLeaderBoard() {
        let payload = {
            assessmentActivityId: this.props.assessmentActivityId
        }

        AssessmentApi.getQuestionLeaderBoard(payload).then(res => {
            this.setState({ leaderboardData: res.leaderboardBuild })
        }).catch(err => {
            console.log(err)
        })

    }



    loadImage = (imageUrl) => {
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
            console.log(img.width)
            this.setState({
                imageWidth: img.width,
                imageHeight: img.height
            });
        };
    };

    startTimerforAnswer() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }
        this.timerIntervalId = setInterval(() => {

            this.answerTickCount += 1
        }, 1000)
    }

    getSuffledItem(content) {
        // CommonUtils.shuffleArray(content.content.options);
        return content;
    }

    reset() {
        this.setState({ content: this.getSuffledItem(JSON.parse(JSON.stringify(this.originalContent))) });
    }

    onOptionPressed = (option) => {
        this.isValid = true;
        if (this.props.delegate && this.props.delegate.enableNavigation)
            this.props.delegate.enableNavigation();
    }

    updateAnswer = (payload) => {

        let options = this.props.content.content.options;

        let option = options.filter(opt => opt.id == payload.answer[0].id);
        if (!option.length)
            return;

        option[0].isChecked = true;
        this.isValid = true;
        this.forceUpdate();
    }

    getAnswer = () => {

        // console.log(this.answerTickCount, this.timerIntervalId)

        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }

        if (this.isValid) {
            // let payload = this.state.studentList;
            let options = this.state.content.content.options;
            // for (let i = 0; i < options.length; i++) {
            //     // if (options[i].isChecked)
            //         payload.push({ id: options[0].id })
            //     break;
            // }
            return { payload: this.userIdAnswerHash, timeTakenToAnswer: this.answerTickCount };
        } else
            return null;
    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
    }

    onSelectImage = (type) => {
        // this.props.navigation.navigate('Media')
        // this.showMedia(type)
        this.props.delegate && this.props.delegate.showMedia(type)

    }
    showMedia = (type) => {
        this.setState({ showMedia: true, selectedMediaType: type })
    }

    hideMedia = () => {
        this.setState({ showMedia: false })
    }

    addMedia = (url, type) => {
        let attachments = this.state.attachments;
        attachments.push({
            type,
            url
        })

        //console.log('atatch', attachments)
        this.setState({ attachments }, () => {
            this.hideMedia()
            //console.log(this.state.attachments)
        })
    }

    getContentType = (type) => {
        if (type == 'IMAGE') {
            return 'image/jpeg'
        }

        if (type == 'VIDEO') {
            return 'video/mp4'
        }

        if (type == 'AUDIO') {
            return 'audio/mp3'
        }
    }

    renderAttachments = (item, index) => {

        // if (item.type == 'IMAGE') {

        //     return (
        //         <View>
        //             <TouchableOpacity style={styles.attachments} onPress={() => this.openPreview(item.url, 'IMAGE')}>
        //                 <Image source={{ uri: item.url }} style={styles.imageAttachment} resizeMode="cover" />

        //             </TouchableOpacity>
        //             <TouchableOpacity onPress={() => this.removeAttachment(item)} style={styles.deleteIcon} hitSlop={styles.imageHitSlop}><Image source={getImageSource(ImageConstants.DELETE_ATTACHMENT)} /></TouchableOpacity>
        //         </View>

        //     )
        // }

        // if (item.type == 'VIDEO') {

        //     return (
        //         <View>
        //             <TouchableOpacity onPress={() => this.previewAttachment(item)} style={Styles.attachments} onPress={() => this.openPreview(item.url, 'VIDEO')}>
        //                 <Image source={{ uri: item.url }} style={Styles.imageAttachment} resizeMode="contain" />
        //             </TouchableOpacity>
        //             <TouchableOpacity onPress={() => this.removeAttachment(item)} style={Styles.deleteIcon} hitSlop={Styles.imageHitSlop}><Image source={getImageSource(ImageConstants.DELETE_ATTACHMENT)} /></TouchableOpacity>

        //         </View>

        //     )
        // }

        // if (item.type == 'AUDIO') {

        //     return (
        //         <View>
        //             <TouchableOpacity onPress={() => this.previewAttachment(item)} style={Styles.audioAttachment} onPress={() => this.openPreview(item.url, 'AUDIO')}>
        //                 <Image source={getImageSource(ImageConstants.MICROPHONE)} />
        //                 <Image source={getImageSource(ImageConstants.AUDIO_CARD_FOOTER)} style={Styles.audioCardFooter} resizeMode="cover" />
        //                 <TouchableOpacity onPress={() => this.removeAttachment(item)} style={Styles.audioDeleteIcon} hitSlop={Styles.imageHitSlop}><Image source={getImageSource(ImageConstants.NAVIGATION_CLOSE)} /></TouchableOpacity>

        //             </TouchableOpacity>
        //         </View>
        //     )
        // }
    }

    openPreview = (url, type) => {
        this.setState({ previewUrl: url, imagePreview: true, previewType: type })
    }

    removeAttachment = (item) => {

        this.props.delegate && this.props.delegate.removeAttachment(item)

    }


    updateAnswerForUserAnswer = (payload) => {

        let content = this.state.content;
        let options = content.content.options;
        console.log(payload)
        options = options.map(opt => {
            if (opt.id == payload.answer[0].id) {
                opt.isCorrect = true;
            } else {
                opt.isCorrect = false;
            }
            return opt
        });
        content.content.options = options;
        //console.log(content)
        this.setState({ content: content }, () => {

        })
    }

    showMyAnswer = () => {
        if (this.props.userAnswer) {
            this.updateAnswerForUserAnswer(this.props.userAnswer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.parse(JSON.stringify(this.props.content))
        //console.log(content)
        this.setState({ content: content }, () => {

        })
    }

    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer, showSolution: !this.state.showSolution })
    }

    toggleViewSolution = () => {

        if (this.state.status === "CORRECT") {
            return;
        }

        this.setState({ showSolution: !this.state.showSolution, showCorrectAnswer: !this.state.showCorrectAnswer })
    }

    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions })
    }

    next(event, disableNext) {
        console.log(disableNext)
        if (this.props.isReadOnly) {
            if (this.props.isFFF) {
                if (this.state.showQuestionLeaderBoard) {
                    this.setState({ showQuestionLeaderBoard: false })
                    if (this.props.delegate && this.props.delegate.next) {
                        //console.log('inside next questions')
                        this.props.delegate.next(event);
                    }
                } else {
                    console.log('...........setting leaderboard')
                    this.setState({ showQuestionLeaderBoard: true })
                }
            } else {
                if (this.props.delegate && this.props.delegate.next) {
                    //console.log('inside next questions')
                    this.props.delegate.next(event);
                }
            }
        } else {
            if (this.props.delegate && this.props.delegate.next) {
                //console.log('inside next questions')
                this.props.delegate.next(event);
            }

        }
    }


    countdown(seconds) {
        const interval = setInterval(() => {
            if (seconds > 0) {
                console.log(`${seconds} seconds remaining`);
                seconds--;
            } else {
                console.log("Time's up!");
                clearInterval(interval);
                this.setState({ stopReceivingStudentSignal: true })
            }
        }, 1000);
    }



    startExtraCountDown() {
        this.countdown(10)
    }


    toggleLayoutBar() {
        console.log('toggle called')

        // this.setState({hideLayoutSideBar : !this.state.hideLayoutSideBar}, () => {
        this.layoutShared.showHideLayoutBar(!this.layoutShared.state.showLayoutBar)
        if (!this.layoutShared.state.showLayoutBar) {
            this.setState({ showStudentResponse: false })
        }

        // this.layoutShared.toggleSidebar(this.state.hideLayoutSideBar)
        // })

    }

    toggleTimer() {
        console.log('toggle timer called')
        const { isPlaying, lastPausedAt, pauseDetails, pauseCount } = this.state;

        if (isPlaying) {
            // Timer is about to be paused
            this.setState({
                lastPausedAt: Date.now(),
                pauseCount: pauseCount + 1, // Increment pause count only when pausing
            });
        } else {
            // Timer is being resumed
            const pausedDuration = Date.now() - lastPausedAt;
            // const pausedDurationInMinutes = (pausedDuration / 1000 / 60).toFixed(2); // Convert to Mins
            const pausedDurationInSeconds = Math.ceil((pausedDuration / 1000).toFixed(2)); // Convert to seconds

            // Store the pause details in an object
            const newPauseDetails = {
                pauseCount: pauseCount, // Don't increment here
                pausedTime: pausedDurationInSeconds,
            };

            this.setState({
                pauseDetails: [...pauseDetails, newPauseDetails], // Add the new pause details to the array
                lastPausedAt: null, // Reset pause timestamp
            }, () => {
                console.log(this.state.pauseDetails)

                let index = this.state.studentList.findIndex(item => item.isTeacher === true)

                if (index != -1) {
                    this.userIdAnswerHash[this.state.studentList[index].teacherUserId] = { pauseDetails: this.state.pauseDetails }
                }
            });
        }



        this.setState({ isPlaying: !this.state.isPlaying }, () => {
            if (!this.state.isPlaying) {
                // alert('Question Timer Paused')
                this.showToaster('info', "Question Timer Paused")
            }
        });

    };

    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toasterMessage: message,
            toasterType: type
        }, () => {
            setTimeout(() => { this.hideToaster() }, 3000);
        });
    }

    hideToaster() {
        this.setState({
            showToaster: false
        });
    }



    handleStartListening = () => {
        const { isListening } = this.state;

        if (window.electron && window.electron.remoteControl) {
            if (isListening) {
                this.setState({ isListening: false });
                window.electron.remoteControl.unsubscribeEvents();
                window.electron.remoteControl.close();
            } else {
                this.setState({ isListening: true });
                console.log('Opening remote control', window.electron.remoteControl.open);
                window.electron.remoteControl.open();

                window.electron.remoteControl.subscribeEvents((data) => {
                    console.log('Received data:', data, this.props.isReadOnly);
                    // console.log(this.state.stopReceivingStudentSignal)

                    let studentList = this.state.studentList

                    let index = studentList.findIndex(item => item.userDeviceMacId === data.payload.id)

                    if (index != -1 && !this.props.isReadOnly) {
                        if (studentList[index].isTeacher && data.payload.value === 8) {
                            this.toggleLayoutBar()
                            window.electron.ipcRenderer.send('toggle-fullscreen', ['ping']);
                        }

                        if (studentList[index].isTeacher && data.payload.value === 1) {
                            this.next()
                        }

                        if (studentList[index].isTeacher && data.payload.value === 2) {
                            this.toggleTimer()
                        }

                        if (studentList[index].isTeacher && data.payload.value === 6) {
                            this.setState({ showStudentResponse: !this.state.showStudentResponse })
                        }

                        if (!this.state.stopReceivingStudentSignal && !studentList[index].isTeacher && this.state.isPlaying) {
                            this.isValid = true

                            let timeTakenToAnswer = Date.now() - this.state.startTime

                            console.log(timeTakenToAnswer)

                            let option = this.optionsHash[data.payload.value]
                            if (option && option.id) {
                                studentList[index].receivedAnswer = true
                                studentList[index].answerId = option.id
                                this.userIdAnswerHash[studentList[index].userId] = {
                                    answer: {
                                        answer: [{ id: option.id }],
                                        id: this.state.content._id,
                                        userDeviceMacId: data.payload.id,
                                        timeTakenToAnswer,
                                        isCorrect: option.isCorrect
                                    }
                                }
                            }
                        }
                    } else if (this.props.isReadOnly) {
                        console.log('called.........')
                        if (studentList[index].isTeacher && data.payload.value === 8) {
                            this.toggleLayoutBar()
                            window.electron.ipcRenderer.send('toggle-fullscreen', ['ping']);
                        }

                        if (studentList[index].isTeacher && data.payload.value === 1) {
                            this.next()
                        }

                        if (studentList[index].isTeacher && data.payload.value === 6) {
                            this.setState({ showStudentResponse: !this.state.showStudentResponse })
                        }
                    }

                    // console.log(this.state.studentList)

                });
            }
            window.electron.ipcRenderer.once('ipc-example', (arg) => {
                // eslint-disable-next-line no-console
                console.log(arg);
            });
            window.electron.ipcRenderer.send('ipc-example', ['ping']);
        }

    };

    toggleStudentResponse() {
        this.setState({ showStudentResponse: !this.state.showStudentResponse })
    }

    componentWillUnmount() {
        // Cleanup on component unmount
        if (window.electron && window.electron.remoteControl) {
            window.electron.remoteControl.unsubscribeEvents();
            window.electron.remoteControl.close();
        }
    }

    openPreview = (previewUrl) => {
        this.setState({ imagePreview: true, previewUrl: previewUrl })
    }

    closePreview = () => {
        this.setState({ imagePreview: false });
    }

    children = ({ remainingTime }) => {
        const minutes = Math.floor(remainingTime / 60)
        const seconds = remainingTime % 60

        return `${minutes}:${seconds}`
    }

    addExtraSeconds() {
        this.startExtraCountDown()
    }



    render() {

        // setCategoryThemeStyles(this.props.categoryThemeId);

        let content = this.state.content;
        // console.log(this.props.isFFF, this.state.timeListHash)
        // console.log(this.state.studentList)
        ////console.log(this.props.userAnswer)
        // if (this.state.showMedia)
        // return <View style={{ flex: 1 }}><Media delegate={this} type={this.state.selectedMediaType} /></View>

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (

            content ?
                <div className="single-select-for-assessment-flow position-relative" onMouseEnter={ReactTooltip.rebuild}>
                    {/* <div className={`${!this.props.isReadOnly ? "min-height" : ""}`}> */}

                    {
                        !this.state.showStudentResponse && !this.layoutShared.state.showLayoutBar ?
                            <div className='student-response-toggle-button d-flex align-items-center justify-content-center cursor-pointer'
                                onClick={() => this.toggleStudentResponse()}>
                                <img className='arrow-image-size' src={getImageSource(!this.state.showStudentResponse ? ImageConstants.RIGHT_ARROW_ORGANGE : ImageConstants.LEFT_ARROW_ORGANGE)} />
                            </div>
                            : <></>
                    }

                    <div className="student-answered-board mb-5 pb-5"
                        style={{
                            top: `${!this.layoutShared.state.showLayoutBar ? '0rem' : '5rem'}`,
                            display: `${this.state.showStudentResponse ? 'block' : 'none'}`
                        }}>
                        <StudentAnsweredStatusBoard studentList={this.state.studentList}
                            isPlaying={this.state.isPlaying}
                            questionTime={this.props.isReadOnly ? 0 : 60} delegate={this}
                            isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                            isFFF={this.props.isFFF} />
                    </div>

                    {
                        !this.state.isPlaying ?
                            <div className="d-flex justify-content-center"><img className="blur-image-size" src={getImageSource(ImageConstants.LC_BLUR)} /></div>
                            :
                            <></>

                    }

                    <div className={`${"min-height"}`} style={{ display: !this.state.isPlaying ? 'none' : 'block' }}>
                        {
                            // this.props.isReadOnly && !this.props.isMultiStep ?
                            //     <>
                            //         <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                            //             {
                            //                 this.props.userAnswerStatus ?
                            //                     <div className="correct-answer-bg d-flex">
                            //                         <div className="">
                            //                             <img className="image-size" src={getImageSource(ImageConstants.WHITE_TICK_ICON_NEW)} />
                            //                         </div>
                            //                         <div className="ml-2">
                            //                             <div className="name-header-correct">{"Correct"}</div>

                            //                         </div>
                            //                     </div>

                            //                     :
                            //                     <div className="incorrect-answer-bg d-flex">
                            //                         <div className="">
                            //                             <img className="image-size" src={getImageSource(ImageConstants.WHITE_CROSS_ICON)} />
                            //                         </div>
                            //                         <div className="ml-1">
                            //                             <div className="name-header text-center">{"Incorrect"}</div>

                            //                         </div>
                            //                     </div>

                            //             }

                            //         </div>

                            //     </>
                            //     :
                            //     <></>
                        }



                        {/* {this.props.totalSteps && this.props.quesNo && <DotPagination noOfDots={this.props.totalSteps} activeDot={this.props.quesNo} categoryThemeId={this.props.categoryThemeId} />} */}


                        {
                            !this.props.isCheckPoint && this.props.isMultiStep && !this.props.isReadOnly ?
                                <>
                                    <QuestionTitle title={content.content.title}
                                        queNo={this.props.quesNo ? this.props.quesNo : null}
                                        noOfDots={this.props.totalSteps}
                                        activeDot={this.props.quesNo}
                                        hideBackground={this.props.isMultiStep ? true : false}
                                        categoryThemeId={this.props.categoryThemeId}
                                        isAssessment={this.props.isAssessment}
                                        delegate={this.props.delegate}
                                        isReadOnly={this.props.isReadOnly}
                                        isSchoolAssignment={true}
                                    />
                                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                                </>
                                :
                                !this.props.isMultiStep && !this.props.isCheckPoint && !this.props.isReadOnly && content.content.images.length === 1 && this.state.imageWidth < 1010 && !content.content.displayQuestionImageAtBottom ?
                                    <div className="d-flex align-items-start">
                                        <QuestionTitle title={content.content.title}
                                            queNo={this.props.quesNo ? this.props.quesNo : null}
                                            noOfDots={this.props.totalSteps}
                                            activeDot={this.props.quesNo}
                                            hideBackground={this.props.isMultiStep ? true : false}
                                            categoryThemeId={this.props.categoryThemeId}
                                            isAssessment={this.props.isAssessment}
                                            delegate={this.props.delegate}
                                            isReadOnly={this.props.isReadOnly}
                                            showOptions={this.state.showOptions}
                                            toggleOptions={() => this.toggleOptions()}
                                            questions={this.props.questions}
                                            activePage={this.props.activePage}
                                            isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                                            showStudentResponse={this.state.showStudentResponse}
                                            singleSelectDelegate={this}
                                            isPlaying={this.state.isPlaying}
                                            questionTime={this.props.isReadOnly ? 0 : 60}
                                            questionImageExist={content.content.images && content.content.images.length ? true : false}
                                            questionImages={content.content.images && content.content.images.length ? content.content.images : ''}
                                        />
                                        {
                                            content.content.images.length ?
                                                <div className="d-flex mt-2">
                                                    {
                                                        <div className="question-image-wrapper d-flex">
                                                            {
                                                                content.content.images.map(image => (
                                                                    <div className=" "
                                                                        style={{
                                                                            marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? '0rem' :
                                                                                !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "0.5rem" :
                                                                                    this.state.showStudentResponse ? '6rem' : '0rem'}`

                                                                        }}>
                                                                        <img className="question-image-size" style={{ maxWidth: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? "none" : ''}` }}
                                                                            src={image.imageUrl} categoryThemeId={this.props.categoryThemeId} />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                : <></>
                                        }


                                        {
                                            this.props.isReadOnly && this.state.showOptions ?
                                                <div className={`mt-3 option-margin-bottom-new`}>
                                                    <RadioGroup options={content.content.options}
                                                        // onOptionPressed={this.onOptionPressed}
                                                        isCheckPoint={this.props.isCheckPoint}
                                                        // isReadOnly={this.props.isReadOnly}
                                                        categoryThemeId={this.props.categoryThemeId}
                                                    />
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>

                                    :

                                    !this.props.isMultiStep && !this.props.isCheckPoint && !this.props.isReadOnly ?
                                        <>
                                            <QuestionTitle title={content.content.title}
                                                queNo={this.props.quesNo ? this.props.quesNo : null}
                                                noOfDots={this.props.totalSteps}
                                                activeDot={this.props.quesNo}
                                                hideBackground={this.props.isMultiStep ? true : false}
                                                categoryThemeId={this.props.categoryThemeId}
                                                isAssessment={this.props.isAssessment}
                                                delegate={this.props.delegate}
                                                isReadOnly={this.props.isReadOnly}
                                                showOptions={this.state.showOptions}
                                                toggleOptions={() => this.toggleOptions()}
                                                questions={this.props.questions}
                                                activePage={this.props.activePage}
                                                isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                                                showStudentResponse={this.state.showStudentResponse}
                                                singleSelectDelegate={this}
                                                isPlaying={this.state.isPlaying}
                                                questionTime={this.props.isReadOnly ? 0 : 10}
                                                questionImageExist={content.content.images && content.content.images.length ? true : false}
                                                questionImages={content.content.images && content.content.images.length ? content.content.images : ''}
                                                isSingleImageWidthHigh={this.state.imageWidth < 1010 && !content.content.displayQuestionImageAtBottom ? false : true}
                                                isFFF={this.props.isFFF}
                                            />
                                            {
                                                content.content.images.length ?
                                                    <div className="d-flex mt-2">
                                                        {
                                                            <div className="question-image-wrapper d-flex">
                                                                {
                                                                    content.content.images.map(image => (
                                                                        <div className=" "
                                                                            style={{
                                                                                marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? '14rem' :
                                                                                    !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "0.5rem" :
                                                                                        this.state.showStudentResponse ? '6rem' : '0rem'}`
                                                                            }}>
                                                                            <img className="question-image-size-for-bottom-image" src={image.imageUrl} categoryThemeId={this.props.categoryThemeId} />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>

                                                        }

                                                        {/* <div><img className='expand-icon ml-1 mr-3 cursor-pointer' onClick={() => this.openPreview(content.content.images[0].imageUrl)} style={{}} src={getImageSource(ImageConstants.EXPAND)}></img></div> */}
                                                    </div>
                                                    : <></>
                                            }
                                            {
                                                this.props.isReadOnly && this.state.showOptions ?
                                                    <div className={`mt-3 option-margin-bottom-new`}>
                                                        <RadioGroup options={content.content.options}
                                                            // onOptionPressed={this.onOptionPressed}
                                                            isCheckPoint={this.props.isCheckPoint}
                                                            // isReadOnly={this.props.isReadOnly}
                                                            categoryThemeId={this.props.categoryThemeId}
                                                        />
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>
                        }



                        {/* {!this.props.isCheckPoint && !this.props.isReadOnly &&
                            <div className="question-separator d-flex flex-row justify-content-center align-items-center">
                                <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                    <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                </div>
                                {
                                    (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                        <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                            style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${content.content.mark} Marks`}>
                                            <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                            <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                        </div> : <></>
                                }
                            </div>
                        } */}
                        {
                            !this.props.isReadOnly ?
                                <div className={`mt-5 option-margin-bottom-new`}
                                    style={{
                                        marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? this.props.isFFF ? "29rem" : '15rem' :
                                            !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "3rem" :
                                                this.state.showStudentResponse ? '7rem' : '1rem'}`
                                    }}>
                                    <RadioGroup options={content.content.options}
                                        onOptionPressed={this.onOptionPressed}
                                        isCheckPoint={this.props.isCheckPoint}
                                        isReadOnly={this.props.isReadOnly}
                                        categoryThemeId={this.props.categoryThemeId}
                                        isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                                        questionImageExist={content.content.images && content.content.images.length ? true : false}
                                    />
                                </div>
                                :
                                <></>
                        }
                        {
                            this.props.isReadOnly && this.state.isReload ?
                                <>
                                    <AssessmentQuote questions={this.props.questions}
                                        quote={content.content.quote}
                                        activePage={this.props.activePage}
                                        isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                                        showStudentResponse={this.state.showStudentResponse}
                                        isFFF={this.props.isFFF}
                                        timeListHash={this.state.timeListHash}
                                        showQuestionLeaderBoard={this.state.showQuestionLeaderBoard}
                                        leaderboardData={this.state.leaderboardData}

                                    />
                                </>
                                :
                                <></>
                        }


                        {
                            this.props.isReadOnly && !this.props.isMultiStep ?
                                <>
                                    <div className="d-flex align-items-center mt-3 ml-3">
                                        {/* <div className="d-flex">
                                        <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                            style={{
                                                backgroundColor: `${this.state.showSolution ? "#E1D9FF" : "#E6E8E8"}`,
                                                color: `${this.state.showSolution ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                            }} onClick={this.toggleViewSolution}>
                                            {
                                                this.state.showSolution ? 'Solution' : 'Solution'
                                            }
                                           
                                        </div>
                                    </div> */}
                                        {/* {
                                        this.props.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                            <div className="d-flex ml-4">
                                                <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                                    style={{
                                                        backgroundColor: `${this.state.showCorrectAnswer ? "#E1D9FF" : "#E6E8E8"}`,
                                                        color: `${this.state.showCorrectAnswer ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                                    }} onClick={this.toggleViewAnswer}>
                                                    {
                                                        this.state.showCorrectAnswer ? 'Answer' : 'Answer'
                                                    }
                                                    
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    } */}
                                    </div>
                                    {/* <div className="solution-answer-border mb-4"></div> */}

                                    {/* {
                                    this.state.showSolution ?
                                        <SummarySoultions forQuestion={true} solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions>
                                        : <></>
                                } */}

                                    {/* {
                                    this.state.showCorrectAnswer ?
                                        <> */}
                                    {/* <div className="option-margin"
                                    style={{
                                        marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? '14rem' :
                                            !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "1rem" :
                                                this.state.showStudentResponse ? '6rem' : '0rem'}`
                                    }}>
                                    {
                                        this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                            <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.state.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.userAnswer}></AnswerTab>
                                            :
                                            <></>
                                    }
                                    {
                                        this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                            <div className="options option-margin-bottom">
                                                <RadioGroup options={content.content.options} isCheckPoint={this.props.isCheckPoint} isReadOnly={true} categoryThemeId={this.props.categoryThemeId} />
                                            </div>
                                            :
                                            <></>
                                    }
                                </div> */}

                                    {/* </>

                                        : <></>
                                } */}

                                </>
                                :
                                <></>
                        }
                        {/* {this.props.isReadOnly && !this.props.isMultiStep ? <div className="question-separator mt-3 mb-3 d-flex flex-row justify-content-center align-items-center">
                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                        </div> </div> : <></>} */}
                    </div>



                    {
                        this.state.stopReceivingStudentSignal ?
                            <div className="">
                                {!this.props.isCheckPoint && !this.props.isMultiStep && <AssessmentFooter userAnswerStatus={this.props.userAnswerStatus} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly} delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}
                            </div>
                            :
                            <></>
                    }

                    {
                        this.props.isReadOnly ?
                            this.props.isFFF && (this.props.activePage === this.props.questions.length) && this.state.showQuestionLeaderBoard ?
                                <></>
                                :
                                <div className="">
                                    {!this.props.isCheckPoint && !this.props.isMultiStep && <AssessmentFooter
                                        singleSelectDelegate={this}
                                        isFFF={this.props.isFFF} userAnswerStatus={this.props.userAnswerStatus} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly} delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}
                                </div>
                            :
                            <></>
                    }


                    <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                        multiline={true}
                        place='right'
                        type='light' effect='solid'
                        event="mouseover mouseenter"
                        eventOff="mouseleave mouseout scroll mousewheel blur" />

                    {
                        this.state.showToaster ? <div className='custom-toaster-modal'> <ToasterComponent image={this.state.toasterImage} message={this.state.toasterMessage} type={this.state.toasterType} position="top-center" /></div> : <></>
                    }
                    {
                        this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>
                    }
                    {
                        !this.props.isReadOnly ?
                            <div className='timer-wrapper-in-question-screen'
                                style={{ display: (this.state.showStudentResponse || this.state.stopReceivingStudentSignal) ? "none" : "flex" }}>
                                <div className=''>
                                    <CountdownCircleTimer
                                        size={100}
                                        trailColor='#1CEA24'
                                        isPlaying={this.state.isPlaying}
                                        duration={this.props.isReadOnly ? 0 : 60}
                                        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                        colorsTime={[10, 6, 3, 0]}
                                        onComplete={() => ({ shouldRepeat: false, delay: 1 }, this.addExtraSeconds())}
                                    >
                                        {(remainingTime) => (
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="timer-text">
                                                    {this.children(remainingTime)}
                                                </div>
                                                {
                                                    !this.state.isPlaying ?
                                                        <div className="">
                                                            <img className="pause-button-image-size" src={getImageSource(ImageConstants.PAUSE_BUTTON)} />
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        )}
                                    </CountdownCircleTimer>
                                </div>
                            </div>
                            :
                            <></>

                    }
                </div>
                :
                <></>
        );
    }
}


export default SingleSelect;
import React from 'react';
import './chapter-list.scss';
import { ImageConstants, Constants } from '../../../constants/constants.js';
import { getImageSource } from '../../../utility/image-source.js';
// import ChapterCard from '../../components/common/chapter-card/chapter-card';
import SharedStaticText from '../../../../shared-storage/static-text-store.js';
import SharedProfile from '../../../../shared-storage/profile.js';
import CommonApi from '../../../apis/common.js';
import Loading from '../../common/loading/loading.js';
// import FilterComponent from './filter/filter.js';
import Path from '../../routes/routes-path.js';
import ChapterLockModal from './chapter-lock-modal/chapter-lock-modal.js';
import ConceptList from '../../concept-list/concept-list.js';
import CommonUtils from '../../../utility/common-utilities.js';
import FireBaseEvent from '../../../firebase-events/firebase-events';
import ChapterCard from './chapter-card/chapter-card.js';

const defaultBUO = {
    title: 'countingwell'
}

export default class ChaptersList extends React.Component {

    flag = false;
    state = {
        showShadow: false,
        showIsChapterCompletedModal: false,
        showStartChapterModal: false,
        chapterStarted: false,
        showReviseChapterModal: false,
        selectedChapter: {},
        showChapterSummary: false,
        masterDataId: '',
        eloListMeta: {},
        selectedChapterIndex: -1,
    }

    bgColor = [
        "#D6D5FD",
        "#FCEEC1",
        "#54E5C0",
        "#FCD1C1",
    ];

    arrowIcon = [
        // require('../../assets/images/violet-arrow/violet-arrow.png'),
        // require('../../assets/images/yellow-arrow/yellow-arrow.png'),
        // require('../../assets/images/blue-arrow/blue-arrow.png'),
        // require('../../assets/images/orange-arrow/orange-arrow.png'),
    ]

    // lockIcon = require('../../assets/images/lock/lock.png');

    state = {
        oddNumberedChapters: [],
        evenNumberedChapters: [],
        showModal: false,
        selectedChapterTitle: '',
        showMenuModal: false,
        chapters: [],
        chapterTitle: "",
        loading: true,
        categories: [],
        selectedChapterKey: ''
    }
    masterData = null;

    chaptersOpenForTrial = [];


    constructor(props) {
        super(props);
        // const { navigation } = this.props;
        // this.navigation = navigation;
        this.setState({ loading: true })
        this.getChapterList();
        this.staticText = SharedStaticText.getStaticText('chapterListScreen');
        this.userInfo = SharedProfile.getProfile()
    }

    staticText = {};

    componentDidMount() {

    }

    showMenuModal = () => {

        this.setState({
            showMenuModal: true
        })
    }

    getChapterList = (payload = {}) => {
        this.setState({ loading: true })
        CommonApi.getChapterList(payload).then((res) => {
            console.log(res)
            this.chaptersOpenForTrial = res.items.filter(chapter => !chapter.lockedForTrial)
            this.setState({
                chapters: res.items,
                orginalChapterList: res.items,
                masterDataId: res.masterDataId,
                overallProgress: res.overallProgress,
                categories: res.categories,
                loading: false
            })
            this.separateChapters(this.state.chapters);
        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
                console.log('Chapter list api Error')
                //alert(constants.OOPS_SOMETHING_WENT_WRONG);
                return;
            }
        })
    }

    getChapterTheme = (chapter) => {

        let categoryIdHash = {};

        if (this.state.categories.length > 0) {
            for (let i = 0; i < this.state.categories.length; i++) {

                categoryIdHash[this.state.categories[i].categoryThemeId] = this.state.categories[i];
            }
            if (categoryIdHash[chapter.categoryId]) {
                return categoryIdHash[chapter.categoryId].secondaryColors.chapterCardColor
            }
            else {
                return ""
            }


        }
        else {
            return ""
        }

    }

    separateChapters(data) {

        let oddColor = 0;
        let evenColor = 2;

        let oddArrow = 0;
        let evenArrow = 2;

        let oddNumberedChapters = [];
        let evenNumberedChapters = [];

        this.biggestCharecter = 0;

        for (let i = 0; i < data.length; i++) {

            if (i % 2 == 0) {
                oddNumberedChapters.push({
                    chapter: data[i], color: this.getChapterTheme(data[i]), arrow: data[i].isLocked ? this.lockIcon : this.arrowIcon[oddArrow], progress: data[i].progress, key: data[i].key, isLocked: data[i].isLocked, status: data[i].status, categoryThemeId: data[i].categoryId,
                    isFinished: data[i].isFinished, image: data[i].chapterImage, lockedForTrial: data[i].lockedForTrial
                });
                oddColor = oddColor == 0 ? 1 : 0;
                oddArrow = oddArrow == 0 ? 1 : 0;
            }
            else {
                evenNumberedChapters.push({
                    chapter: data[i], color: this.getChapterTheme(data[i]), arrow: data[i].isLocked ? this.lockIcon : this.arrowIcon[evenArrow], progress: data[i].progress, key: data[i].key, isLocked: data[i].isLocked, status: data[i].status, categoryThemeId: data[i].categoryId,
                    isFinished: data[i].isFinished, image: data[i].chapterImage, lockedForTrial: data[i].lockedForTrial
                });
                evenColor = evenColor == 2 ? 3 : 2;
                evenArrow = evenArrow == 2 ? 3 : 2;
            }

            let chapter = data[i]

            if (chapter.title) {
                let chapterSplit = chapter.title.split(' ')

                for (let j = 0; j < chapterSplit.length; j++) {
                    let item = chapterSplit[j]

                    if (item.length > this.biggestCharecter) {
                        this.biggestCharecter = item.length
                    }
                }
            }
        }

        this.setState({
            oddNumberedChapters: oddNumberedChapters,
            evenNumberedChapters: evenNumberedChapters
        });
    }

    onActionSheetClose() {

        this.setState({
            showMenuModal: false
        })
    }

    navigate = (key, title, isChapterNotCompleted, categoryThemeId, lockedForTrial, item, logEvent) => {

        // if (item.isFinished) {
        //     moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Completed' })
        //     FireBaseEvent.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Completed' })
        //     Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Completed' })
        //     BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Completed' })
        // }
        // else if (!logEvent) {
        //     moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
        //     FireBaseEvent.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
        //     Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
        //     BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
        // } else {
        //     moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
        //     FireBaseEvent.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
        //     Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
        //     BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
        // }
        // this.props.navigation.navigate('EloList', { meta: { chapterKey: key, title: title, isChapterNotCompleted, categoryThemeId: categoryThemeId, masterDataId: this.state.masterDataId, lockedForTrial: lockedForTrial, trialChapters: this.chaptersOpenForTrial } })
    }

    showConstructionScreen = () => {
        // this.props.navigation.navigate('UnderConstructionScreen')
    }

    showChapterSummary = (key, title, categoryThemeId) => {
        // this.props.navigation.navigate('ChapterSummary', { meta: { chapterKey: key, title: title, masterDataId: this.state.masterDataId, categoryThemeId: categoryThemeId } })
    }

    showPracticeQuestions = (key, title, categoryThemeId) => {
        // this.props.navigation.navigate('PracticeMoreQuestions', { meta: { chapterKey: key, title: title, masterDataId: this.state.masterDataId, categoryThemeId: categoryThemeId } })
    }

    showModal = (title, imageUrl) => {

        this.setState({
            showModal: true,
            chapterTitle: title,
            imageUrl: imageUrl
        })

    }

    closeModal = () => {
        this.setState({
            showModal: false
        })
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    toggleChapterCompletedModal = (value, title, key, categoryThemeId, lockedForTrial, item) => {

        if (value) {
            this.setState({
                showIsChapterCompletedModal: value,
                chapterTitle: title ? title : '',
                selectedChapterKey: key ? key : '',
                categoryThemeId: categoryThemeId ? categoryThemeId : '',
                lockedForTrial,
                selectedChapterItem: item
            })
        } else {
            this.setState({ showIsChapterCompletedModal: value })
        }

    }

    onSelectChapterCompletedModal = (isChapterNotCompleted) => {
        this.setState({ showIsChapterCompletedModal: false }, () => {
            setTimeout(() => {
                // moengage.logCustomEvent('Chapter',{"User Id":this.userInfo.userId,'Chapter Name':this.state.chapterTitle,"Chapter Status":'Start',"Chapter Category":getCategoryTitle(this.state.categoryThemeId),"Completed in Class":getBooleanValue(isChapterNotCompleted)})
                this.navigate(this.state.selectedChapterKey, this.state.chapterTitle, isChapterNotCompleted, this.state.categoryThemeId, this.state.lockedForTrial, this.state.selectedChapterItem, true)
            }, 1)
        })
    }

    showStartChapterModal = (chapter) => {
        if (chapter.status == Constants.IS_CHAPTER_NOT_STARTED) {
            this.setState({
                showStartChapterModal: true,
                chapterTitle: chapter.chapter.title,
                categoryThemeId: chapter.categoryThemeId,
                chapterStarted: false,
                selectedChapter: chapter
            })
        }
        else {
            this.setState({
                showStartChapterModal: true,
                chapterTitle: chapter.chapter.title,
                categoryThemeId: chapter.categoryThemeId,
                chapterStarted: true,
                selectedChapter: chapter
            })
        }


    }

    showReviseChapterModal = (chapter) => {

        if (chapter.isFinished) {

            this.setState({
                showReviseChapterModal: true,
                chapterTitle: chapter.chapter.title,
                categoryThemeId: chapter.categoryThemeId,
                chapterStarted: false,
                selectedChapter: chapter
            })
        } else {
            this.setState({
                showReviseChapterModal: true,
                chapterTitle: chapter.chapter.title,
                categoryThemeId: chapter.categoryThemeId,
                chapterStarted: true,
                selectedChapter: chapter
            })
        }

    }

    closeReviseChapterModal = () => {
        this.setState({ showReviseChapterModal: false })
    }

    closeStartChapterModal = () => {
        this.setState({ showStartChapterModal: false })
    }

    onClickChapter = (chapter, index, logEvent) => {
        console.log(chapter)

        let categoryThemeId = chapter.categoryId

        if (chapter.isFinished) {
            // moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true), "Chapter Status": 'Completed' })
            FireBaseEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": chapter.title, "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true), "Chapter Status": 'Completed' })
            // Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Completed' })
            // BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Completed' })
        }
        else if (!logEvent) {
            // moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
            FireBaseEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': chapter.title, "Chapter Status": 'In Progress', "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true) })
            // Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
            // BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
        } else {
            // moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
            FireBaseEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": chapter.title, "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true), "Chapter Status": 'Start' })
            // Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
            // BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
        }

        let eloListMeta = {
            chapterKey: chapter.key,
            title: chapter.title,
            isChapterNotCompleted: true,
            categoryThemeId: chapter.categoryId,
            masterDataId: this.state.masterDataId,
            lockedForTrial: chapter.lockedForTrial,
            trialChapters: this.chaptersOpenForTrial,
            chapterImageUrl: chapter.chapterImage,
            calledFrom: 'HOME_COMPONENT',
            selectedChapterIndex: index,
        }


        this.props.delegate && this.props.delegate.props.history.push(Path.BROWSE_CHAPTER, { data: eloListMeta })

        // this.setState({
        //     showEloList: true,
        //     selectedChapterIndex: index,
        //     eloListMeta: {
        //         chapterKey: chapter.key,
        //         title: chapter.title,
        //         isChapterNotCompleted: true,
        //         categoryThemeId: chapter.categoryId,
        //         masterDataId: this.state.masterDataId,
        //         lockedForTrial: chapter.lockedForTrial,
        //         trialChapters: this.chaptersOpenForTrial,
        //         chapterImageUrl: chapter.chapterImage
        //     }
        // })
        // this.props.navigation.navigate('EloList', { meta: { chapterKey: chapter.key, title: chapter.title, isChapterNotCompleted : true, categoryThemeId: chapter.categoryId, masterDataId: this.state.masterDataId, lockedForTrial: chapter.lockedForTrial, trialChapters: this.chaptersOpenForTrial } })
        // this.props.navigation.navigate('ChapterNavigation', { meta: { chapter: chapter } })
    }

    hideEloList() {
        this.setState({ showEloList: false, eloListMeta: {} })
    }

    changeChapterList(searchText) {
        console.log(searchText)
        let orginalChapterList = this.state.orginalChapterList
        let value = searchText
        if (value && value.length) {
            let chapterList = orginalChapterList.filter(option => option[`title`].toLowerCase().includes(value.toLowerCase()));
            this.setState({ chapters: chapterList });
        } else {
            this.setState({ chapters: orginalChapterList });
        }

    }

    goToHomeScreen() {
        this.props.history.push(Path.HOME)
    }


    viewAllChapterList() {
        this.props.delegate && this.props.delegate.viewChapterList && this.props.delegate.viewChapterList()
    }


    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb ml-2 mt-2'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1'>{`Browse Chapters`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    render() {


        if (this.state.showEloList) {
            return <ConceptList delegate={this} params={this.state.eloListMeta} back={() => this.hideEloList()} />
        }


        return (
            <>
                <div className='home-chapter-list'>
                    {this.state.loading &&
                        <Loading loading={this.state.loading} />
                    }
                    {this.state.oddNumberedChapters.length > 0 &&
                        <>
                            <div className='mt-1 d-flex chapter-list-wrapper align-items-center' >
                                {
                                    this.state.oddNumberedChapters.map((item, index) => (
                                        index === 0 ?
                                            <div className='mr-4 cursor-pointer' onClick={() => item.isLocked ? this.showModal(item.chapter.title, item.image) : this.onClickChapter(item.chapter, index, false)}>
                                                <ChapterCard color={item.color}
                                                    chapterTitle={item.chapter.title}
                                                    arrow={item.arrow}
                                                    progress={item.progress}
                                                    isLocked={item.isLocked}
                                                    categoryThemeId={item.categoryThemeId}
                                                    chapter={item}
                                                    delegate={this}
                                                    biggestCharecter={this.biggestCharecter}
                                                    image={item.image} />
                                            </div>
                                            :
                                            <></>


                                    ))
                                }
                                {
                                    this.state.evenNumberedChapters.map((item, index) => (
                                        index === 0 ? <div className='mr-4 cursor-pointer' onClick={() => item.isLocked ? this.showModal(item.chapter.title, item.image) : this.onClickChapter(item.chapter, index, false)}>
                                            <ChapterCard color={item.color}
                                                chapterTitle={item.chapter.title}
                                                arrow={item.arrow}
                                                progress={item.progress}
                                                isLocked={item.isLocked}
                                                categoryThemeId={item.categoryThemeId}
                                                chapter={item}
                                                delegate={this}
                                                biggestCharecter={this.biggestCharecter}
                                                image={item.image} />
                                        </div>
                                            :
                                            <></>
                                    ))
                                }

                                <div className="mr-1">
                                    <div className="text-center view-all-button cursor-pointer" onClick={() => this.viewAllChapterList()}>
                                        View More
                                    </div>
                                </div>

                            </div>
                        </>


                    }

                    {/* {this.state.showMenuModal && <MenuModal delegate={this} modal={this.state.showMenuModal} />} */}
                    {this.state.showModal && <ChapterLockModal delegate={this} modal={this.state.showModal} chapterTitle={this.state.chapterTitle} imageUrl={this.state.imageUrl} />}



                </div>
                {/* </View> */}
            </>
        );
    }
}

import React from "react";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import './radio-group.scss';
import RadioButton from "./radio-button/radio-button";
import { ImageConstants } from "../../../constants/constants";
import { getImageSource } from "../../../utility/image-source";
import { Constants } from "../../../constants/constants";
import ImagePreview from "../image-preview/image-preview";






export default class RadioGroup extends React.Component {
    constructor(props) {
        super(props);
    }

    state = { imagePreview: false, previewUrl: '' };

    onOptionPressed(option) {

        //Added for View Correct Answer
        if (this.props.isReadOnly) {
            return;
        }
        this.props.options.map(opt => opt.isChecked = false);
        option.isChecked = true;
        this.props.onOptionPressed && this.props.onOptionPressed(option);
        this.forceUpdate();
    }

    openPreview = (previewUrl) => {
        this.setState({ imagePreview: true, previewUrl: previewUrl })
    }

    closePreview = () => {
        this.setState({ imagePreview: false });
    }

    //Added for View Correct Answer
    getCheckedStatus = (option) => {
        // console.log(option)
        if (this.props.isReadOnly) {
            return option.isCorrect ? true : false
        }
        else {
            return option.isChecked ? true : false
        }
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)



        return (
            <div className="d-flex flex-column option-wrapper">
                {this.props.options && this.props.options.length && this.props.options.map((option, index) =>
                    <div className={`option-border ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "d-flex justify-content-between" : this.props.isReadOnly ? "d-flex align-items-center" : ""}`}
                        style={{ borderColor: this.theme ? `${!this.props.isReadOnly ? this.theme['secondaryColors']['assessmentOptionsBorder'] : "#FFFFFF"}` : '#353535' }}
                        key={option.id}>
                        <RadioButton
                            label={option.content}
                            isChecked={this.getCheckedStatus(option)}
                            onOptionPressed={() => this.onOptionPressed(option)}
                            contentType={option.contentType ? option.contentType : 1}
                            isCheckPoint={this.props.isCheckPoint}
                            categoryThemeId={this.props.categoryThemeId}
                        />

                        {option.contentType === Constants.CONTENT_TYPE_IMAGE &&
                            <div className="ml-5">
                                <img className="expand-icon cursor-pointer" onClick={() => this.openPreview(option.content)}
                                 src={getImageSource(ImageConstants.EXPAND)} />
                            </div>
                        }
                    </div>
                )}
                {this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>}
            </div>
        );
    }
}
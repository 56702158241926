import React from "react";
import './classwork-filter.scss';
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";


export default class BookMarkFilterModal extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.filterCopy = JSON.parse(JSON.stringify(this.props.filter));

        console.log(this.filterCopy)

        let chapterHash = {};
        let contentHash = {};

        if (this.props.selectedFilters && this.props.selectedFilters.selectedItems && this.props.selectedFilters.selectedItems.length) {

            if (this.props.selectedFilters.filterType === 'CHAPTERS') {
                for (let i = 0; i < this.props.selectedFilters.selectedItems.length; i++) {
                    chapterHash[this.props.selectedFilters.selectedItems[i].key] = this.props.selectedFilters.selectedItems[i]

                }

                for (let i = 0; i < this.filterCopy.chapters.length; i++) {

                    if (chapterHash[this.filterCopy.chapters[i].key]) {
                        this.filterCopy.chapters[i].active = true

                    }
                }
            }

            if (this.props.selectedFilters.filterType === 'CONTENT') {
                for (let i = 0; i < this.props.selectedFilters.selectedItems.length; i++) {
                    contentHash[this.props.selectedFilters.selectedItems[i].value] = this.props.selectedFilters.selectedItems[i]

                }

                for (let i = 0; i < this.filterCopy.contents.length; i++) {

                    if (contentHash[this.filterCopy.contents[i].value]) {
                        this.filterCopy.contents[i].active = true
                    }
                }
            }
        }
        this.setState({ chapterFilter: this.filterCopy ? this.filterCopy.chapters : [], contentFilter: this.filterCopy ? this.filterCopy.contents : [] })
    }




    state = {
        activeHeader: "CHAPTERS",
        chapterFilter: [],
        contentFilter: []
    };

    close() {
        this.props.close();
    }

    onSelect = (option) => {
        this.props.onSelect && this.props.onSelect(option)
    }

    onPressFilter = (value) => {
        this.resetSelectionFilter(value)
        this.setState({ activeHeader: value })
    }

    resetSelectionFilter = (value) => {
        if (value == 'CHAPTERS') {
            // let contentFilter = this.state.contentFilter.map(content => ({ ...content, active: false }))
            let contentFilter = this.state.contentFilter;
            for (let i = 0; i < contentFilter.length; i++) {
                contentFilter[i]['active'] = false
            }
            this.setState({ contentFilter })
        }
        if (value == 'CONTENT') {
            //let chapterFilter = this.state.chapterFilter.map(chapter => ({ ...chapter, active: false }))
            let chapterFilter = this.state.chapterFilter;
            for (let i = 0; i < chapterFilter.length; i++) {
                chapterFilter[i]['active'] = false
            }
            this.setState({ chapterFilter })
        }
    }

    onPressCheckBox = (index, value) => {
        if (this.state.activeHeader == 'CHAPTERS') {
            let chapterFilter = this.state.chapterFilter;
            chapterFilter[index]['active'] = value;
            this.setState({ chapterFilter })
        }
        if (this.state.activeHeader == 'CONTENT') {
            let contentFilter = this.state.contentFilter;
            contentFilter[index]['active'] = value;
            this.setState({ contentFilter })
        }
    }
    clearAll = () => {
        if (this.state.activeHeader == 'CONTENT') {
            // let contentFilter = this.state.contentFilter.map(content => ({ ...content, active: false }))

            let contentFilter = this.state.contentFilter;
            for (let i = 0; i < contentFilter.length; i++) {
                contentFilter[i]['active'] = false
            }
            this.setState({ contentFilter })
        }
        if (this.state.activeHeader == 'CHAPTERS') {
            //  let chapterFilter = this.state.chapterFilter.map(chapter => ({ ...chapter, active: false }))
            let chapterFilter = this.state.chapterFilter;
            for (let i = 0; i < chapterFilter.length; i++) {
                chapterFilter[i]['active'] = false
            }
            this.setState({ chapterFilter })
        }

    }

    apply = () => {
        let payload = {
            filterType: this.state.activeHeader,
            selectedItems: this.getSelectedItems()
        }
        this.props.delegate && this.props.delegate.onApplyFilter && this.props.delegate.onApplyFilter(payload)
    }

    getSelectedItems = () => {
        if (this.state.activeHeader == 'CONTENT') {
            let contentFilter = this.state.contentFilter.filter(content => content.active)
            return contentFilter
        }
        if (this.state.activeHeader == 'CHAPTERS') {
            let chapterFilter = this.state.chapterFilter.filter(chapter => chapter.active)
            return chapterFilter
        }
    }

    render() {

        return (

            <Modal className="cw-classwork-modal-right" isOpen={true} size={'lg'} >
                <ModalBody>
                    <div className="classwork-filter">
                        <img className="filter-header-image" src={getImageSource(ImageConstants.FILLTER_HEADER)} />
                        <div className="d-flex justify-content-between filter-header">
                            {this.state.contentFilter.length && this.state.contentFilter.filter(content => content.active).length || this.state.chapterFilter.length && this.state.chapterFilter.filter(chapter => chapter.active).length ?
                                <div className="clear-all-text d-flex justify-content-center p-2">
                                    <div className="cursor-pointer" onClick={this.clearAll}>
                                        Clear All
                                    </div>
                                </div> : <div></div>}
                            <div className="filter-text">Filters</div>
                            <div>
                                <img onClick={this.close.bind(this)} className="close-icon-size cursor-pointer" src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} />
                            </div>
                        </div>
                        <div className="header-tab row justify-content-center ml-0 mr-0 mt-3">
                            <div onClick={() => this.onPressFilter("CHAPTERS")}
                                className={`col tab-bg-1 text-center ${this.state.activeHeader == 'CHAPTERS' ? 'active-tab' : 'cursor-pointer'}`}>
                                Chapters
                            </div>
                            <div onClick={() => this.onPressFilter("CONTENT")}
                                className={`col tab-bg-2 text-center ${this.state.activeHeader == 'CONTENT' ? 'active-tab' : 'cursor-pointer'}`}>
                                Content
                            </div>
                        </div>

                        <div className="mt-3">
                            {this.state.activeHeader == 'CHAPTERS' && this.state.chapterFilter.map((chapter, index) => (
                                <div className="d-flex justify-content-between mt-3 cursor-pointer" onClick={() => this.onPressCheckBox(index, chapter.active ? false : true)} key={'chapter' + index}>
                                    <div className="chapter-title">{chapter.title}</div>
                                    {
                                        chapter.active ?
                                            <div className="active-check-box">
                                                <img className="green-tick-icon" src={getImageSource(ImageConstants.GREEN_TICK_ICON)} />
                                            </div>
                                            :

                                            <div className="check-box"></div>
                                    }
                                </div>
                            ))}

                            {this.state.activeHeader == 'CONTENT' && this.state.contentFilter.map((content, index) => (
                                <div className="d-flex justify-content-between mt-3 cursor-pointer" onClick={() => this.onPressCheckBox(index, content.active ? false : true)} key={'content' + index}>
                                    <div className="chapter-title">{content.title}</div>
                                    {
                                        content.active ?
                                            <div className="active-check-box">
                                                <img className="green-tick-icon" src={getImageSource(ImageConstants.GREEN_TICK_ICON)} />
                                            </div>
                                            :

                                            <div className="check-box"></div>
                                    }
                                </div>
                            ))}




                        </div>

                        <div className="d-flex justify-content-center mt-3 mb-2">
                                    <div className="apply-button cursor-pointer" onClick={this.apply}>
                                        Apply
                                    </div>
                        </div>


                        <img className="filter-footer-image" src={getImageSource(ImageConstants.FILLTER_FOOTER)} />
                    </div>
                </ModalBody>

            </Modal>
        );
    }

}
import axios from "axios";
import URL from "../apis/url";

class Apiprovider {
  static createContent(payload) {
    return axios.post(URL.BASE_URL + URL.CONTENT, payload);
  }

  static getSignedUrl(payload) {
    let fileName = payload.fileName;

    let url = URL.BASE_URL + URL.SIGNURL;

    url += `?fileName=${fileName}`;

    return axios.get(url, payload);
  }


  static getMultpleSignedUrls(payload, callback) {

    let params = [];
    if (payload) {
      Object.keys(payload).forEach(key => {
        params.push(`${key}=${payload[key]}`);
      });
    }
    let url = URL.MULTIPLE_SIGNED_URLS;
    if (params && params.length) {
      url = url + "?" + params.join('&');
    }
    return axios.get(url, null)
  }

  static uploadFileAwsS3(signedUrl, file, progressCallback) {
    let options = {
      headers: {
        "Content-Type": file.type
      },
      onUploadProgress: progressCallback
    };
    return axios.put(signedUrl, file, options);
  }

  static getContent(payload) {
    return axios.get(URL.BASE_URL + URL.CONTENT);
  }
}

export default Apiprovider;

import React from "react";
import './fill-in-the-blank.scss';
import Parser from "../../content-parser/content-parser";
import CommonUtils from "../../../../utility/common-utilities";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import QuestionTitle from "../question-title/question-title";
import QuestionImage from "../../cw-question-image/cw-question-image";
import { Constants } from "../../../../constants/constants";
import { ImageConstants } from "../../../../constants/constants";
import { getImageSource, getThemeImageSource } from "../../../../utility/image-source";
import AssessmentFooter from "../../assessment-footer/assessment-footer";
import ActionSheet from "../../action-sheet/action-sheet";
import SummarySoultions from "../../summary-solution/summary-solution";
import DotPagination from "../../dot-pagination/dot-pagination";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import AnswerTab from "../../../my-assessment-result/question/answer-tab/answer-tab";
import ReactTooltip from "react-tooltip";


class FillInTheBlank extends React.Component {



    state = {
        actionSheet: false,
        dropDownData: [],
        selectedDropDownIndex: '',
        scrollViewHeight: 0,
        inputFocus: false,
        showKeyBoard: false,
        content: null,
        showSolution: true,
        showOptions: false
    }

    answerTickCount = 0

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');
        this.originalContent = JSON.parse(JSON.stringify((this.props.content)));

        if (this.props.isReadOnly) {
            let content = JSON.stringify(this.props.content)
            this.state.content = JSON.parse(content);

        }
    }

    // dropdownRef = React.createRef();


    originalContent = {};

    componentDidMount() {
        if (this.props.isReadOnly) {
            this.setState({ status: `${this.props.userAnswerStatus ? "CORRECT" : "IN_CORRECT"}` })
        }

        if (!this.props.isReadOnly) {
            this.setState({ content: this.getSuffledItem(this.props.content) });
            this.startTimerforAnswer()
        }
        // document.addEventListener('mousedown', this.closeActionSheet)
    }


    startTimerforAnswer() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }
        this.timerIntervalId = setInterval(() => {

            this.answerTickCount += 1
        }, 1000)
    }

    getSuffledItem(content) {

        for (let i = 0; i < content.content.options.length; i++) {
            CommonUtils.shuffleArray(content.content.options[i].items);
        }
        return content;
    }

    // closeActionSheet = (e) => {
    //     // //console.log(e)
    //     //console.log(this.dropdownRef)
    //     if (this.state.actionSheet) {
    //         // //console.log(this.dropdownRef.current.contains(e.target))
    //         if (this.dropdownRef && this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
    //             this.toggleActionSheet(false)
    //         }
    //     }

    // }

    reset() {
        this.setState({ content: this.getSuffledItem(JSON.parse(JSON.stringify(this.originalContent))) });
    }

    isValidOptions = false;

    textInput = [];

    onChangeMathFeild = (option, event) => {
        if (option.contentDataType === 1) {
            option.content = event.target.value.replace(/[^0-9-,.]/g, '');
        }
        else {
            option.content = event.target.value;
        }
        this.forceUpdate();
        this.validateAndNavigate();
    }

    toggleActionSheet = (value, dropDownData, index) => {
        if (this.props.isReadOnly) {
            return;
        }
        this.setState({ actionSheet: value, dropDownData: dropDownData ? dropDownData : [], selectedDropDownIndex: index });
    }

    onSelect = (option, index) => {

        this.state.content.content.options[this.state.selectedDropDownIndex].selectedText = option.content;

        this.state.content.content.options[this.state.selectedDropDownIndex].items.map(opt => opt.isSelected = false);
        this.state.content.content.options[this.state.selectedDropDownIndex].items[index].isSelected = true;
        this.toggleActionSheet(false);
        this.validateAndNavigate();
    }

    validateAndNavigate = () => {
        let isValid = this.validateOptions();
        if (isValid) {
            this.isValidOptions = true;
            this.props.delegate && this.props.delegate.enableNavigation && this.props.delegate.enableNavigation();
        } else {
            this.isValidOptions = false;
            this.props.delegate && this.props.delegate.disableNavigation && this.props.delegate.disableNavigation();
        }
    }

    validateOptions() {

        let noOfOptions = this.state.content.content.options.length;

        let noOfDropDownsSelected = 0;
        let noOfValidBlanks = 0;

        for (let i = 0; i < noOfOptions; i++) {

            if (this.state.content.content.options[i].contentType == Constants.CONTENT_TYPE_BLANK && this.state.content.content.options[i].content)
                noOfValidBlanks = noOfValidBlanks + 1;

            else if (this.state.content.content.options[i].contentType == Constants.CONTENT_TYPE_DROP_DOWN) {
                let option = this.state.content.content.options[i].items.filter(opt => opt.isSelected);
                if (option.length)
                    noOfDropDownsSelected = noOfDropDownsSelected + 1;
            }
        }
        if (noOfOptions == (noOfDropDownsSelected + noOfValidBlanks))
            return true;
        else
            return false;
    }

    getAnswer = () => {

        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }

        if (this.isValidOptions) {
            let payload = [];

            let options = this.state.content.content.options;

            for (let i = 0; i < options.length; i++) {

                if (options[i].contentType == Constants.CONTENT_TYPE_BLANK) {
                    if (options[i].content)
                        payload.push({ id: options[i].id, content: options[i].content ? options[i].content.trim() : '' });
                }
                else {
                    let option = options[i].items.filter(opt => opt.isSelected);
                    if (option.length)
                        payload.push({ id: options[i].id, selected: [{ id: option[0].id }] });
                }
            }
            return { payload, timeTakenToAnswer: this.answerTickCount };
        } else
            return null;
    }

    updateAnswer = (payload) => {

        if (!payload) {
            return
        }

        let options = this.props.content.content.options;

        if (payload.answer.length == options.length) {
            for (let i = 0; i < options.length; i++) {
                if (options[i].contentType == Constants.CONTENT_TYPE_BLANK)
                    options[i].content = payload.answer[i].content ? payload.answer[i].content : '';
                else if (options[i].contentType == Constants.CONTENT_TYPE_DROP_DOWN) {
                    let option = options[i].items.filter(opt => opt.id == payload.answer[i].selected[0].id);
                    if (option.length) {
                        option[0].isSelected = true;
                        options[i].selectedText = option[0].content;
                    }
                }
            }
            this.isValidOptions = true;
            this.forceUpdate();
        } else {
            alert('Not answered all options');
        }
    }

    getBlankOptionValueDropDown(content) {

        let styles = {
            p: { fontSize: '23px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (content && content.items && content.items.length) {
            let correctItem = content.items.filter((item) => item.isCorrect);
            let correctAnswer = correctItem[0].content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");
            if (correctItem[0].content) {
                return this.parser.parseWithStyles(`${correctAnswer}`, { p: styles.p, "cw-math": styles.p })
            } else {
                return <></>
            }
        }
        return <></>
    }

    getJsxForBlankTypeDropDown(option, index) {

        //console.log(option, index, this.state.selectedDropDownIndex, this.props.isReadOnly)

        //console.log(this.state.actionSheet)

        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let assessmentBorder = this.theme[`secondaryColors`][`assessmentOptionsBorder`]
        let backgroundColor = this.theme[`themeColor`]
        //console.log(this.theme[`secondaryColors`][`assessmentOptionsBorder`])

        return (
            <div key={option.id} ref={this.dropdownRef} className="position-relative" style={{}}>

                {this.state.selectedDropDownIndex === index ?
                    <>
                        <div className="row mb-5 mt-2 mr-0 ml-0" style={{}}>

                            <div className="blank-wrapper padding-for-dropdown" style={{ border: `1px solid ${assessmentBorder}` }}>
                                <div className="dropdown-input">
                                    <div className="d-inline-block alphabet-option ml-2 mr-2">
                                        <div className="circle p-1" style={{ backgroundColor: backgroundColor }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                    </div>
                                    <img className="option-separator-2" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />
                                    <div className="d-inline-block ml-2" style={{}}>
                                        {
                                            option.selectedText ?
                                                this.parser.parseWithStyles(`${option.selectedText.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
                                                : <span className="cw-input-for-dropdown" style={{}}>
                                                    {"Select One"}</span>
                                        }
                                    </div>


                                    <div className="float-right mt-2" style={{}}>
                                        <img className="dropdown-arrow-size" src={getImageSource(ImageConstants.DROPDOWN_ARROW_BLACK)} />
                                    </div>
                                </div>

                            </div>


                            {/* </LinearGradient> */}
                        </div>
                        {this.state.actionSheet && <ActionSheet modal={this.state.actionSheet} toggleActionSheet={this.toggleActionSheet} options={this.state.dropDownData} onSelect={this.onSelect} categoryThemeId={this.props.categoryThemeId}></ActionSheet>}

                    </>


                    :
                    // <LinearGradient colors={Colors.appTheme.gradient1} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1.2 }} style={styles.linearGradiendCard}>
                    <div className="row  mb-5 mt-2 mr-0 ml-0" style={{}} >

                        <div className="blank-wrapper padding-for-dropdown" style={{ border: `1px solid ${ assessmentBorder}` }} onClick={() => { this.toggleActionSheet(true, option.items, index) }}>
                            <div className="dropdown-input">
                                <div className="d-inline-block alphabet-option ml-2 mr-2">
                                    <div className="circle p-1" style={{ backgroundColor: backgroundColor }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                </div>
                                <img className="option-separator-2" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />
                                {
                                    !this.props.isReadOnly ?
                                        <div className="d-inline-block ml-2" style={{}}>
                                            {
                                                option.selectedText ?
                                                    this.parser.parseWithStyles(`${option.selectedText.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
                                                    : <span className="cw-input-for-dropdown" style={{}}>
                                                        {"Select One"}</span>
                                            }
                                        </div>
                                        :
                                        <div className="d-inline-block ml-2" style={{}}>
                                            {this.getBlankOptionValueDropDown(option)}
                                        </div>
                                }

                                {
                                    !this.props.isReadOnly ?
                                        <div className="float-right mt-2" style={{}}>
                                            <img className="dropdown-arrow-size" src={getImageSource(ImageConstants.DROPDOWN_ARROW_BLACK)} />
                                        </div> 
                                        : <></>
                                }


                            </div>

                        </div>


                        {/* </LinearGradient> */}
                    </div>
                }

            </div>

        )
    }

    getJsxForBlankTypeDropDownReadOnly(option, index) {

        //console.log(option, index, this.state.selectedDropDownIndex, this.props.isReadOnly)

        //console.log(this.state.actionSheet)

        let styles = {
            p: { fontSize: '23px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let assessmentBorder = this.theme[`secondaryColors`][`assessmentOptionsBorder`]
        let backgroundColor = this.theme[`themeColor`]
        //console.log(this.theme[`secondaryColors`][`assessmentOptionsBorder`])

        return (
            <div key={option.id} ref={this.dropdownRef} className="position-relative" style={{}}>

                {option.selectedText ?
                    <>
                        <div className="row mb-5 mt-2 mr-0 ml-0" style={{}}>

                            <div className="blank-wrapper padding-for-dropdown" style={{ border: `1px solid ${!this.props.isReadOnly ? assessmentBorder : "#FFFFFF"}` }}>
                                <div className="dropdown-input">
                                    <div className="d-inline-block alphabet-option ml-2 mr-2">
                                        <div className="circle p-1" style={{ backgroundColor: backgroundColor }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                    </div>
                                    <img className="option-separator-2" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />
                                    <div className="d-inline-block ml-2" style={{}}>
                                        {
                                            option.selectedText ?
                                                this.parser.parseWithStyles(`${option.selectedText.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
                                                : <span className="cw-input-for-dropdown" style={{}}>
                                                    {"Select One"}</span>
                                        }
                                    </div>


                                    {/* <div className="float-right mt-2" style={{}}>
                                        <img className="dropdown-arrow-size" src={getImageSource(ImageConstants.DROPDOWN_ARROW_BLACK)} />
                                    </div> */}
                                </div>

                            </div>


                            {/* </LinearGradient> */}
                        </div>
                        {this.state.actionSheet && <ActionSheet modal={this.state.actionSheet} toggleActionSheet={this.toggleActionSheet} options={this.state.dropDownData} onSelect={this.onSelect} categoryThemeId={this.props.categoryThemeId}></ActionSheet>}

                    </>


                    :
                    // <LinearGradient colors={Colors.appTheme.gradient1} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1.2 }} style={styles.linearGradiendCard}>
                    <div className="row  mb-5 mt-2 mr-0 ml-0" style={{}} >

                        <div className="blank-wrapper padding-for-dropdown" style={{ border: `1px solid ${!this.props.isReadOnly ? assessmentBorder : "#FFFFFF"}` }} onClick={() => { this.toggleActionSheet(true, option.items, index) }}>
                            <div className="dropdown-input">
                                <div className="d-inline-block alphabet-option ml-2 mr-2">
                                    <div className="circle p-1" style={{ backgroundColor: backgroundColor }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                </div>
                                <img className="option-separator-2" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />
                                {
                                    !this.props.isReadOnly ?
                                        <div className="d-inline-block ml-2" style={{}}>
                                            {
                                                option.selectedText ?
                                                    this.parser.parseWithStyles(`${option.selectedText.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
                                                    : <span className="cw-input-for-dropdown" style={{}}>
                                                        {"Select One"}</span>
                                            }
                                        </div>
                                        :
                                        <div className="d-inline-block ml-2" style={{}}>
                                            {this.getBlankOptionValueDropDown(option)}
                                        </div>
                                }

                                {
                                    !this.props.isReadOnly ?
                                        <div className="float-right mt-2" style={{}}>
                                            <img className="dropdown-arrow-size" src={getImageSource(ImageConstants.DROPDOWN_ARROW_BLACK)} />
                                        </div> : <></>
                                }


                            </div>

                        </div>


                        {/* </LinearGradient> */}
                    </div>
                }

            </div>

        )
    }

    getBlankOptionValue = (content) => {

        let styles = {
            p: { fontSize: '23px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (content && content.items && content.items.length) {
            let parsedJsx = this.parser.parseWithStyles(`${content.items[0].content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
            return (
                <div className="d-inline-block ml-2">
                    {parsedJsx}
                </div>
            )
        }
        return <></>
    }

    getJsxForBlank(option, index) {

        let assessmentBorder = this.theme[`secondaryColors`][`assessmentOptionsBorder`]
        let backgroundColor = this.theme[`themeColor`]
        return (
            <div className="">

                <div >
                    {/* <LinearGradient colors={Colors.appTheme.gradient1} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1.2 }} style={styles.blank}> */}

                    <div className="row  mb-5 mt-2 mr-0 ml-0">
                        <div className="blank-wrapper d-flex align-items-center " style={{ border: `1px solid ${!this.props.isReadOnly ? assessmentBorder : this.props.isReadOnly && this.state.showOptions ? assessmentBorder : "#FFFFFF"}` }}>
                            <div className="blank-option">
                                <div className="d-inline-block alphabet-option ml-2 mr-2">
                                    <div className="circle p-1" style={{ backgroundColor: backgroundColor }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                </div>

                                <img className="option-separator" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />

                                {
                                    !this.props.isReadOnly ?
                                        <input className="cursor-pointer"
                                            ref={(input) => { this.textInput[index] = input }}
                                            style={{}}
                                            onChange={(value) => this.onChangeMathFeild(option, value)}
                                            value={option.content}
                                            // multiline={true}
                                            maxLength={150}
                                            placeholder='Type Answer Here'
                                        // editable={this.props.isReadOnly ? false : true}
                                        />
                                        : this.getBlankOptionValue(option)

                                }

                            </div>

                            {/* <TouchableOpacity onPress={() => this.textInput[index].focus()} style={styles.keyboard} >
                                <Image source={getThemeImageSource(ImageConstants.ASSESSMENT_KEYBOARD, this.props.categoryThemeId)} />
                            </TouchableOpacity> */}
                        </div>
                        {/* </LinearGradient> */}
                    </div>
                </div>
            </div >


        )
    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
    }

    onSelectImage = (type) => {
        this.props.delegate && this.props.delegate.showMedia(type)
    }

    renderAttachments = (item, index) => {

        if (item.type == 'IMAGE') {

            return (
                // <View>
                //     <TouchableOpacity style={styles.attachments} onPress={() => this.openPreview(item.url, 'IMAGE')}>
                //         <Image source={{ uri: item.url }} style={styles.imageAttachment} resizeMode="cover" />

                //     </TouchableOpacity>
                //     <TouchableOpacity onPress={() => this.removeAttachment(item)} style={styles.deleteIcon} hitSlop={styles.imageHitSlop}><Image source={getImageSource(ImageConstants.DELETE_ATTACHMENT)} /></TouchableOpacity>
                // </View>
                <></>
            )
        }
    }

    openPreview = (url, type) => {
        this.setState({ previewUrl: url, imagePreview: true, previewType: type })
    }

    removeAttachment = (item) => {

        this.props.delegate && this.props.delegate.removeAttachment(item)

    }


    updateAnswerForUserAnswer = (payload) => {
        let content = this.state.content;
        let options = this.state.content.content.options;
        if (payload.answer.length == options.length) {

            for (let i = 0; i < options.length; i++) {
                // //console.log(options[i])
                if (options[i].contentType == Constants.CONTENT_TYPE_BLANK) {
                    //alert(JSON.stringify(options[i]))
                    options[i].items[0].content = payload.answer[i].content ? "<p>" + payload.answer[i].content + "</p>" : '';
                }
                else if (options[i].contentType == Constants.CONTENT_TYPE_DROP_DOWN) {
                    let option = options[i].items.filter(opt => opt.id == payload.answer[i].selected[0].id);
                    //console.log(option)
                    if (option.length) {
                        option[0].isSelected = true;
                        options[i].selectedText = option[0].content;
                    }
                }
            }
        }

        this.setState({ content: content })
    }

    showMyAnswer = () => {
        if (this.props.userAnswer) {
            this.updateAnswerForUserAnswer(this.props.userAnswer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.parse(JSON.stringify(this.props.content))
        //console.log(content)
        this.setState({ content: content }, () => {

        })
    }


    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer, showSolution: !this.state.showSolution, showOptions : false })
    }

    toggleViewSolution = () => {
        if(this.state.status === "CORRECT") {
            return;
        }
        this.setState({ showSolution: !this.state.showSolution, showCorrectAnswer: !this.state.showCorrectAnswer })
    }


    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions })
    }



    render() {


        //console.log(this.props.userAnswer)

        let content = this.state.content;

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (

            content ? <div className="single-select">
                <div className={`${"min-height"}`} onMouseEnter={ReactTooltip.rebuild}>


                    {
                        this.props.isReadOnly && !this.props.isMultiStep ?
                            <>
                                <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                                    {
                                        this.props.userAnswerStatus ?
                                            <div className="correct-answer-bg d-flex">
                                                <div className="">
                                                    <img className="image-size" src={getImageSource(ImageConstants.WHITE_TICK_ICON_NEW)} />
                                                </div>
                                                <div className="ml-2">
                                                    <div className="name-header-correct">{"Correct"}</div>
                                                    {/* <div className="sub-title">{this.correctAnswerStaticText.subTitle}</div> */}
                                                </div>
                                            </div>

                                            :
                                            <div className="incorrect-answer-bg d-flex">
                                                <div className="">
                                                    <img className="image-size" src={getImageSource(ImageConstants.WHITE_CROSS_ICON)} />
                                                </div>
                                                <div className="ml-1">
                                                    <div className="name-header text-center">{"Incorrect"}</div>
                                                    {/* <div className="sub-title">{this.inCorrectAnswerStaticText.subTitle}</div> */}
                                                </div>
                                            </div>

                                    }

                                </div>
                                {/* <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                    <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                    </div>
                                </div> */}
                            </>
                            :
                            <></>
                    }
                    {this.props.totalSteps && this.props.quesNo && <DotPagination noOfDots={this.props.totalSteps} activeDot={this.props.quesNo} categoryThemeId={this.props.categoryThemeId} />}

                    {
                        !this.props.isCheckPoint && this.props.isMultiStep && !this.props.isReadOnly ?
                            <>
                                <QuestionTitle title={content.content.title}
                                    queNo={this.props.quesNo ? this.props.quesNo : null}
                                    noOfDots={this.props.totalSteps}
                                    activeDot={this.props.quesNo}
                                    hideBackground={this.props.isMultiStep ? true : false}
                                    categoryThemeId={this.props.categoryThemeId}
                                    isAssessment={this.props.isAssessment}
                                    delegate={this.props.delegate}
                                    isReadOnly={this.props.isReadOnly}
                                    isSchoolAssignment={true} />
                                <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                            </>

                            :
                            !this.props.isMultiStep && !this.props.isCheckPoint ?
                                <>
                                    <QuestionTitle title={content.content.title}
                                        queNo={this.props.quesNo ? this.props.quesNo : null}
                                        noOfDots={this.props.totalSteps}
                                        activeDot={this.props.quesNo}
                                        hideBackground={this.props.isMultiStep ? true : false}
                                        categoryThemeId={this.props.categoryThemeId}
                                        isAssessment={this.props.isAssessment}
                                        delegate={this.props.delegate}
                                        isReadOnly={this.props.isReadOnly}
                                        showOptions={this.state.showOptions}
                                        toggleOptions={() => this.toggleOptions()}
                                    />
                                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                                    {
                                        this.props.isReadOnly && this.state.showOptions ?
                                            <div className={`fill-in-the-blank-min-height ${content.content.images.length ? "fill-in-the-blank-min-height-2" : ""} option-margin-bottom`}>

                                                {content.content.options && content.content.options.length && content.content.options.map((option, index) => (
                                                    <React.Fragment key={'fillInTheBlank' + index}>
                                                        {option.contentType == 3 ?
                                                            this.getJsxForBlank(option, index)
                                                            : this.getJsxForBlankTypeDropDown(option, index)
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </div>

                                            :

                                            <></>
                                    }
                                </>

                                :
                                <></>
                    }



                    {!this.props.isCheckPoint && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                        </div>
                        {
                            (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                    style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${content.content.mark} Marks`} >
                                    <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                    <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                </div> : <></>
                        }
                    </div>
                    }
                    {
                        !this.props.isReadOnly ?

                            <div className={`fill-in-the-blank-min-height ${content.content.images.length ? "fill-in-the-blank-min-height-2" : ""} option-margin-bottom`}>

                                {content.content.options && content.content.options.length && content.content.options.map((option, index) => (
                                    <React.Fragment key={'fillInTheBlank' + index}>
                                        {option.contentType == 3 ?
                                            this.getJsxForBlank(option, index)
                                            : this.getJsxForBlankTypeDropDown(option, index)
                                        }
                                    </React.Fragment>
                                ))}
                            </div>

                            :

                            <></>
                    }
                    {
                        this.props.isReadOnly && !this.props.isMultiStep ?
                            <>
                                <div className="d-flex align-items-center mt-3 ml-3">
                                    <div className="d-flex">
                                        <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                            style={{
                                                backgroundColor: `${this.state.showSolution ? "#E1D9FF" : "#E6E8E8"}`,
                                                color: `${this.state.showSolution ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                            }} onClick={this.toggleViewSolution}>
                                            {
                                                this.state.showSolution ? 'Solution' : 'Solution'
                                            }
                                            {/* <span>View Answer</span> */}
                                            {/* <img className="arrow-size" src={getThemeImageSource(this.state.showSolution ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                        </div>
                                    </div>
                                    {
                                        this.props.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                            <div className="d-flex ml-4">
                                                <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                                    style={{
                                                        backgroundColor: `${this.state.showCorrectAnswer ? "#E1D9FF" : "#E6E8E8"}`,
                                                        color: `${this.state.showCorrectAnswer ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                                    }} onClick={this.toggleViewAnswer}>
                                                    {
                                                        this.state.showCorrectAnswer ? 'Answer' : 'Answer'
                                                    }
                                                    {/* <span>View Answer</span> */}
                                                    {/* <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                                <div className="solution-answer-border mb-4"></div>

                                {
                                    this.state.showSolution ?
                                        <SummarySoultions forQuestion={true} solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions>
                                        : <></>
                                }
                                {
                                    this.state.showCorrectAnswer ?
                                        <>
                                            {
                                                this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                    <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.state.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.userAnswer}></AnswerTab>
                                                    : <></>
                                            }
                                            {
                                                this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                    <div className={`fill-in-the-blank-min-height ${content.content.images.length ? "fill-in-the-blank-min-height-2" : ""} option-margin-bottom`}>

                                                        {content.content.options && content.content.options.length && content.content.options.map((option, index) => (
                                                            <React.Fragment key={'fillInTheBlank' + index}>
                                                                {option.contentType == 3 ?
                                                                    this.getJsxForBlank(option, index)
                                                                    : this.getJsxForBlankTypeDropDownReadOnly(option, index)
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </>

                                        : <></>
                                }



                            </>
                            :
                            <></>
                    }

                    {/* {this.props.isReadOnly && !this.props.isMultiStep ? <div className="question-separator mt-3 mb-3 d-flex flex-row justify-content-center align-items-center">
                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                        </div> </div> : <></>} */}




                </div>


                {!this.props.isCheckPoint && !this.props.isMultiStep && <AssessmentFooter userAnswerStatus={this.props.userAnswerStatus} delegate={this.props.delegate} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}
                <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                    multiline={true}
                    place='right'
                    type='light' effect='solid'
                    event="mouseover mouseenter"
                    eventOff="mouseleave mouseout scroll mousewheel blur" />

            </div> : <></>

        );
    }
}


export default FillInTheBlank;

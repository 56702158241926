import React from "react";
import './single-select.scss';
import CommonUtils from "../../../../utility/common-utilities";
import QuestionTitle from "../question-title/question-title";
import QuestionImage from "../../../common/cw-question-image/cw-question-image";
import { getThemeImageSource, getImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import RadioGroup from "../../radio/radio-group";
import AssessmentFooter from "../../assessment-footer/assessment-footer";
import SummarySoultions from "../../../common/summary-solution/summary-solution";
// import DotPagination from "../../dot-pagination/dot-pagination";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import AnswerTab from "../../../my-assessment-result/question/answer-tab/answer-tab";
import ReactTooltip from "react-tooltip";
import StudentAnsweredStatusBoard from "../../student-answered-status-board/student-answered-status-board";
import AssessmentQuote from "../quotes-title/quotes-title";
import LayoutShared from "../../../common/layout-shared/layout-shared";


class SingleSelect extends React.Component {

    state = {
        content: null, attachments: [], showSolution: true, showOptions: false, isListening: false, studentList: [],
        stopReceivingStudentSignal: false,
        showStudentResponse: true,
        isPlaying: true,
        tabIndex: 1,
        showBarGraph: true
    };

    isValid = false;
    originalContent = {};
    answerTickCount = 0;
    optionsHash = {};
    userIdAnswerHash = {}


    constructor(props) {
        super(props)
        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');
        this.originalContent = JSON.parse(JSON.stringify((this.props.content)));


        if (this.props.isReadOnly) {
            let optionsHash = this.props.optionsHashForReadOnly
            let options = []
            let studentResponseCount = 0
            for (let key in optionsHash) {
                let option = optionsHash[key]
                studentResponseCount += option.usersAnsweredCount
                options.push(option)
            }

            console.log(options)

            this.state.options = options
            this.state.studentResponseCount = studentResponseCount

            let content = JSON.stringify(this.props.content)
            this.state.content = JSON.parse(content);
            this.state.studentList = this.props.studentList
            this.handleStartListening()
        }

        this.layoutShared = LayoutShared.getLayout()

        if (!this.layoutShared.state.showLayoutBar && !this.props.isReadOnly) {
            this.state.showStudentResponse = false
        }
    }

    componentDidMount() {

        if (this.props.isReadOnly) {
            // console.log('inside read only', this.props.studentList)
            this.setState({ status: `${this.props.userAnswerStatus ? "CORRECT" : "IN_CORRECT"}` })

        }

        if (!this.props.isReadOnly) {
            if (this.props.content.content.images && this.props.content.content.images.length === 1) {
                // this.getMeta(this.props.content.content.images[0].imageUrl, (err, width, height) => {
                //     console.log(width, height);
                // });

                this.loadImage(this.props.content.content.images[0].imageUrl)

            }
            this.setState({ content: this.getSuffledItem(this.props.content), studentList: this.props.studentList });
            this.startTimerforAnswer()
            this.handleStartListening()
            let content = this.props.content
            // console.log(content.content.options)
            content.content.options.forEach((item, index) => this.optionsHash[index + 1] = item)
        }
    }


    loadImage = (imageUrl) => {
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
            console.log(img.width)
            this.setState({
                imageWidth: img.width,
                imageHeight: img.height
            });
        };
    };


    startTimerforAnswer() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }
        this.timerIntervalId = setInterval(() => {

            this.answerTickCount += 1
        }, 1000)
    }

    getSuffledItem(content) {
        // CommonUtils.shuffleArray(content.content.options);
        return content;
    }

    reset() {
        this.setState({ content: this.getSuffledItem(JSON.parse(JSON.stringify(this.originalContent))) });
    }

    onOptionPressed = (option) => {
        this.isValid = true;
        if (this.props.delegate && this.props.delegate.enableNavigation)
            this.props.delegate.enableNavigation();
    }

    updateAnswer = (payload) => {

        let options = this.props.content.content.options;

        let option = options.filter(opt => opt.id == payload.answer[0].id);
        if (!option.length)
            return;

        option[0].isChecked = true;
        this.isValid = true;
        this.forceUpdate();
    }

    getAnswer = () => {

        // console.log(this.answerTickCount, this.timerIntervalId)

        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }

        if (this.isValid) {
            // let payload = this.state.studentList;
            let options = this.state.content.content.options;
            // for (let i = 0; i < options.length; i++) {
            //     // if (options[i].isChecked)
            //         payload.push({ id: options[0].id })
            //     break;
            // }
            return { payload: this.userIdAnswerHash, timeTakenToAnswer: this.answerTickCount };
        } else
            return null;
    }

    getOptionsInfo = () => {

        let options = this.state.content.content.options;

        let optionsHash = {}

        options.forEach(opt => {
            opt.usersAnsweredCount = 0
            optionsHash[opt.id] = opt
        })

        for (let key in this.userIdAnswerHash) {
            let userAnswer = this.userIdAnswerHash[key].answer.answer

            let option = optionsHash[userAnswer[0].id]

            if (!option.usersAnsweredCount) {
                option.usersAnsweredCount = 1
            } else {
                option.usersAnsweredCount += 1
            }

            optionsHash[userAnswer[0].id] = option
        }



        return optionsHash
    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
    }

    onSelectImage = (type) => {
        // this.props.navigation.navigate('Media')
        // this.showMedia(type)
        this.props.delegate && this.props.delegate.showMedia(type)

    }
    showMedia = (type) => {
        this.setState({ showMedia: true, selectedMediaType: type })
    }

    hideMedia = () => {
        this.setState({ showMedia: false })
    }

    addMedia = (url, type) => {
        let attachments = this.state.attachments;
        attachments.push({
            type,
            url
        })

        //console.log('atatch', attachments)
        this.setState({ attachments }, () => {
            this.hideMedia()
            //console.log(this.state.attachments)
        })
    }

    getContentType = (type) => {
        if (type == 'IMAGE') {
            return 'image/jpeg'
        }

        if (type == 'VIDEO') {
            return 'video/mp4'
        }

        if (type == 'AUDIO') {
            return 'audio/mp3'
        }
    }

    renderAttachments = (item, index) => {

        // if (item.type == 'IMAGE') {

        //     return (
        //         <View>
        //             <TouchableOpacity style={styles.attachments} onPress={() => this.openPreview(item.url, 'IMAGE')}>
        //                 <Image source={{ uri: item.url }} style={styles.imageAttachment} resizeMode="cover" />

        //             </TouchableOpacity>
        //             <TouchableOpacity onPress={() => this.removeAttachment(item)} style={styles.deleteIcon} hitSlop={styles.imageHitSlop}><Image source={getImageSource(ImageConstants.DELETE_ATTACHMENT)} /></TouchableOpacity>
        //         </View>

        //     )
        // }

        // if (item.type == 'VIDEO') {

        //     return (
        //         <View>
        //             <TouchableOpacity onPress={() => this.previewAttachment(item)} style={Styles.attachments} onPress={() => this.openPreview(item.url, 'VIDEO')}>
        //                 <Image source={{ uri: item.url }} style={Styles.imageAttachment} resizeMode="contain" />
        //             </TouchableOpacity>
        //             <TouchableOpacity onPress={() => this.removeAttachment(item)} style={Styles.deleteIcon} hitSlop={Styles.imageHitSlop}><Image source={getImageSource(ImageConstants.DELETE_ATTACHMENT)} /></TouchableOpacity>

        //         </View>

        //     )
        // }

        // if (item.type == 'AUDIO') {

        //     return (
        //         <View>
        //             <TouchableOpacity onPress={() => this.previewAttachment(item)} style={Styles.audioAttachment} onPress={() => this.openPreview(item.url, 'AUDIO')}>
        //                 <Image source={getImageSource(ImageConstants.MICROPHONE)} />
        //                 <Image source={getImageSource(ImageConstants.AUDIO_CARD_FOOTER)} style={Styles.audioCardFooter} resizeMode="cover" />
        //                 <TouchableOpacity onPress={() => this.removeAttachment(item)} style={Styles.audioDeleteIcon} hitSlop={Styles.imageHitSlop}><Image source={getImageSource(ImageConstants.NAVIGATION_CLOSE)} /></TouchableOpacity>

        //             </TouchableOpacity>
        //         </View>
        //     )
        // }
    }

    openPreview = (url, type) => {
        this.setState({ previewUrl: url, imagePreview: true, previewType: type })
    }

    removeAttachment = (item) => {

        this.props.delegate && this.props.delegate.removeAttachment(item)

    }


    updateAnswerForUserAnswer = (payload) => {

        let content = this.state.content;
        let options = content.content.options;
        console.log(payload)
        options = options.map(opt => {
            if (opt.id == payload.answer[0].id) {
                opt.isCorrect = true;
            } else {
                opt.isCorrect = false;
            }
            return opt
        });
        content.content.options = options;
        //console.log(content)
        this.setState({ content: content }, () => {

        })
    }

    showMyAnswer = () => {
        if (this.props.userAnswer) {
            this.updateAnswerForUserAnswer(this.props.userAnswer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.parse(JSON.stringify(this.props.content))
        //console.log(content)
        this.setState({ content: content }, () => {

        })
    }

    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer, showSolution: !this.state.showSolution })
    }

    toggleViewSolution = () => {

        if (this.state.status === "CORRECT") {
            return;
        }

        this.setState({ showSolution: !this.state.showSolution, showCorrectAnswer: !this.state.showCorrectAnswer })
    }

    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions })
    }

    next(event, disableNext) {
        //console.log(disableNext)
        if (this.props.delegate && this.props.delegate.next) {
            //console.log('inside next questions')
            this.props.delegate.next(event);
        }
    }


    countdown(seconds) {
        const interval = setInterval(() => {
            if (seconds > 0) {
                console.log(`${seconds} seconds remaining`);
                seconds--;
            } else {
                console.log("Time's up!");
                clearInterval(interval);
                this.setState({ stopReceivingStudentSignal: true })
            }
        }, 1000);
    }



    startExtraCountDown() {
        this.countdown(10)
    }

    toggleLayoutBar() {
        console.log('toggle called')

        // this.setState({hideLayoutSideBar : !this.state.hideLayoutSideBar}, () => {
        this.layoutShared.showHideLayoutBar(!this.layoutShared.state.showLayoutBar)
        if (!this.layoutShared.state.showLayoutBar) {
            this.setState({ showStudentResponse: false })
        }

        // this.layoutShared.toggleSidebar(this.state.hideLayoutSideBar)
        // })

    }

    handleStartListening = () => {
        const { isListening } = this.state;

        if (window.electron && window.electron.remoteControl) {
            if (isListening) {
                this.setState({ isListening: false });
                window.electron.remoteControl.unsubscribeEvents();
                window.electron.remoteControl.close();
            } else {
                this.setState({ isListening: true });
                console.log('Opening remote control', window.electron.remoteControl.open);
                window.electron.remoteControl.open();

                window.electron.remoteControl.subscribeEvents((data) => {
                    console.log('Received data:', data);
                    console.log(this.state.stopReceivingStudentSignal)

                    let studentList = this.state.studentList

                    let index = studentList.findIndex(item => item.userDeviceMacId === data.payload.id)

                    if (index != -1 && !this.props.isReadOnly) {
                        if (studentList[index].isTeacher && data.payload.value === 8) {
                            this.toggleLayoutBar()
                            window.electron.ipcRenderer.send('toggle-fullscreen', ['ping']);
                        }

                        if (studentList[index].isTeacher && data.payload.value === 1) {
                            this.next()
                        }

                        // if (studentList[index].isTeacher && data.payload.value === 2) {
                        //     this.toggleTimer()
                        // }

                        if (studentList[index].isTeacher && data.payload.value === 6) {
                            this.setState({ showStudentResponse: !this.state.showStudentResponse })
                        }

                        if (!this.state.stopReceivingStudentSignal && !studentList[index].isTeacher) {
                            this.isValid = true
                            let option = this.optionsHash[data.payload.value]
                            if (option && option.id) {
                                studentList[index].receivedAnswer = true
                                studentList[index].answerId = option.id
                                this.userIdAnswerHash[studentList[index].userId] = { answer: { answer: [{ id: option.id }], id: this.state.content._id } }
                            }
                        }
                    } else if (this.props.isReadOnly) {
                        if (studentList[index].isTeacher && data.payload.value === 8) {
                            this.toggleLayoutBar()
                            window.electron.ipcRenderer.send('toggle-fullscreen', ['ping']);
                        }

                        if (studentList[index].isTeacher && data.payload.value === 1) {
                            this.next()
                        }

                        if (studentList[index].isTeacher && data.payload.value === 2) {
                            this.switchTab()
                        }

                        if (studentList[index].isTeacher && data.payload.value === 6) {
                            this.setState({ showStudentResponse: !this.state.showStudentResponse })
                        }
                    }

                    console.log(this.state.studentList)

                });
            }
            window.electron.ipcRenderer.once('ipc-example', (arg) => {
                // eslint-disable-next-line no-console
                console.log(arg);
            });
            window.electron.ipcRenderer.send('ipc-example', ['ping']);
        }

    };

    componentWillUnmount() {
        // Cleanup on component unmount
        if (window.electron && window.electron.remoteControl) {
            window.electron.remoteControl.unsubscribeEvents();
            window.electron.remoteControl.close();
        }

    }

    toggleStudentResponse() {
        this.setState({ showStudentResponse: !this.state.showStudentResponse })
    }

    switchTab(index) {

        if (index) {
            this.setState({ tabIndex: index })
            return
        }

        if (this.state.tabIndex === 1) {
            this.setState({ tabIndex: 2 })
        } else if (this.state.tabIndex === 2) {
            this.setState({ tabIndex: 3 })
        } else if (this.state.tabIndex === 3) {
            this.setState({ tabIndex: 1 })
        }


    }


    showTabs() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <div className="answer-tab-for-practice-flow">
                <div className={`row row-max-width-for-answer justify-content-center align-items-center mr-0 ml-0 mt-3 mb-3`}>
                    {
                        <div className="col d-flex pl-0">
                            <div className={`text-center answer-status-text p-2 tab border-right ${this.state.tabIndex == 1 ? 'active-tab' : 'in-active-tab'}`}
                                style={{ backgroundColor: this.state.tabIndex == 1 ? this.theme['themeColor'] : '' }} onClick={() => { this.switchTab(1) }}>
                                <span className="tab-text">{"Class Response"}</span>
                            </div>
                            <div className={`text-center answer-status-text p-2 tab no-border-radius ${this.state.tabIndex == 2 ? 'active-my-answer-tab' : 'in-active-tab'}`}
                                style={{ backgroundColor: this.state.tabIndex == 2 ? this.theme['themeColor'] : '' }}
                                onClick={() => { this.switchTab(2) }}>
                                <span className="tab-text">{"Q&A"}</span>
                            </div>
                            <div className={`text-center answer-status-text p-2 tab border-left ${this.state.tabIndex == 3 ? 'active-solution-tab' : 'in-active-tab'}`}
                                style={{ backgroundColor: this.state.tabIndex == 3 ? this.theme['themeColor'] : '' }}
                                onClick={() => { this.switchTab(3) }}>
                                <span className="tab-text">{"View Solution"}</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }




    render() {

        // setCategoryThemeStyles(this.props.categoryThemeId);

        let content = this.state.content;
        // console.log(content)
        // console.log(this.state.studentList)
        ////console.log(this.props.userAnswer)
        // if (this.state.showMedia)
        // return <View style={{ flex: 1 }}><Media delegate={this} type={this.state.selectedMediaType} /></View>

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            content ? <div className="single-select-for-practice-flow position-relative" onMouseEnter={ReactTooltip.rebuild}>
                {/* <div className={`${!this.props.isReadOnly ? "min-height" : ""}`}> */}
                {
                    !this.state.showStudentResponse && !this.layoutShared.state.showLayoutBar ?
                        <div className='student-response-toggle-button d-flex align-items-center justify-content-center cursor-pointer'
                            onClick={() => this.toggleStudentResponse()}>
                            <img className='arrow-image-size' src={getImageSource(!this.state.showStudentResponse ? ImageConstants.RIGHT_ARROW_ORGANGE : ImageConstants.LEFT_ARROW_ORGANGE)} />
                        </div>
                        : <></>
                }
                <div className="student-answered-board mb-5 pb-5"
                    style={{
                        top: `${!this.layoutShared.state.showLayoutBar ? '0rem' : '5rem'}`,
                        display: `${this.state.showStudentResponse ? 'block' : 'none'}`
                    }}>
                    <StudentAnsweredStatusBoard studentList={this.state.studentList}
                        isPlaying={this.state.isPlaying}
                        questionTime={this.props.isReadOnly ? 0 : 120} delegate={this}
                        isFullScreenMode={!this.layoutShared.state.showLayoutBar} />
                </div>
                <div className={`${"min-height"}`}>
                    {
                        !this.props.isCheckPoint && this.props.isMultiStep && !this.props.isReadOnly ?
                            <>
                                <QuestionTitle title={content.content.title}
                                    queNo={this.props.quesNo ? this.props.quesNo : null}
                                    noOfDots={this.props.totalSteps}
                                    activeDot={this.props.quesNo}
                                    hideBackground={this.props.isMultiStep ? true : false}
                                    categoryThemeId={this.props.categoryThemeId}
                                    isAssessment={this.props.isAssessment}
                                    delegate={this.props.delegate}
                                    isReadOnly={this.props.isReadOnly}
                                    isSchoolAssignment={true} />
                                <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                            </>
                            :
                            !this.props.isMultiStep && !this.props.isCheckPoint && !this.props.isReadOnly && content.content.images.length === 1 && this.state.imageWidth < 1010 && !content.content.displayQuestionImageAtBottom ?
                                <div className="d-flex align-items-start">
                                    <QuestionTitle title={content.content.title}
                                        queNo={this.props.quesNo ? this.props.quesNo : null}
                                        noOfDots={this.props.totalSteps}
                                        activeDot={this.props.quesNo}
                                        hideBackground={this.props.isMultiStep ? true : false}
                                        categoryThemeId={this.props.categoryThemeId}
                                        isAssessment={this.props.isAssessment}
                                        delegate={this.props.delegate}
                                        isReadOnly={this.props.isReadOnly}
                                        showOptions={this.state.showOptions}
                                        toggleOptions={() => this.toggleOptions()}
                                        questions={this.props.questions}
                                        activePage={this.props.activePage}
                                        isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                                        showStudentResponse={this.state.showStudentResponse}
                                        singleSelectDelegate={this}
                                        isPlaying={this.state.isPlaying}
                                        questionTime={this.props.isReadOnly ? 0 : 60}
                                        questionImageExist={content.content.images && content.content.images.length ? true : false}
                                        questionImages={content.content.images && content.content.images.length ? content.content.images : ''}
                                        isFFF={true}
                                    />
                                    {
                                        content.content.images.length ?
                                            <div className="d-flex mt-2">
                                                {
                                                    <div className="question-image-wrapper d-flex">
                                                        {
                                                            content.content.images.map(image => (
                                                                <div className=" "
                                                                    style={{
                                                                        marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? '0rem' :
                                                                            !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "0.5rem" :
                                                                                this.state.showStudentResponse ? '6rem' : '0rem'}`

                                                                    }}>
                                                                    <img className="question-image-size" style={{ maxWidth: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? "none" : ''}` }}
                                                                        src={image.imageUrl} categoryThemeId={this.props.categoryThemeId} />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            : <></>
                                    }


                                    {
                                        this.props.isReadOnly && this.state.showOptions ?
                                            <div className={`mt-3 option-margin-bottom-new`}>
                                                <RadioGroup options={content.content.options}
                                                    // onOptionPressed={this.onOptionPressed}
                                                    isCheckPoint={this.props.isCheckPoint}
                                                    // isReadOnly={this.props.isReadOnly}
                                                    categoryThemeId={this.props.categoryThemeId}
                                                />
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>

                                :

                                !this.props.isMultiStep && !this.props.isCheckPoint && !this.props.isReadOnly ?
                                    <>
                                        <QuestionTitle title={content.content.title}
                                            isFFF={true}
                                            queNo={this.props.quesNo ? this.props.quesNo : null}
                                            noOfDots={this.props.totalSteps}
                                            activeDot={this.props.quesNo}
                                            hideBackground={this.props.isMultiStep ? true : false}
                                            categoryThemeId={this.props.categoryThemeId}
                                            isAssessment={this.props.isAssessment}
                                            delegate={this.props.delegate}
                                            isReadOnly={this.props.isReadOnly}
                                            showOptions={this.state.showOptions}
                                            toggleOptions={() => this.toggleOptions()}
                                            questions={this.props.questions}
                                            activePage={this.props.activePage}
                                            isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                                            showStudentResponse={this.state.showStudentResponse}
                                            singleSelectDelegate={this}
                                            isPlaying={this.state.isPlaying}
                                            questionTime={this.props.isReadOnly ? 0 : 10}
                                            questionImageExist={content.content.images && content.content.images.length ? true : false}
                                            questionImages={content.content.images && content.content.images.length ? content.content.images : ''}
                                            isSingleImageWidthHigh={this.state.imageWidth < 1010 && !content.content.displayQuestionImageAtBottom ? false : true}
                                        />
                                        {
                                            content.content.images.length ?
                                                <div className="d-flex mt-2">
                                                    {
                                                        <div className="question-image-wrapper d-flex">
                                                            {
                                                                content.content.images.map(image => (
                                                                    <div className=" "
                                                                    style={{
                                                                        marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? true ? "29rem" : '15rem' :
                                                                            !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "3rem" :
                                                                                this.state.showStudentResponse ? '7rem' : '1rem'}`
                                                                    }}>
                                                                        <img className="question-image-size-for-bottom-image" src={image.imageUrl} categoryThemeId={this.props.categoryThemeId} />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>

                                                    }

                                                    {/* <div><img className='expand-icon ml-1 mr-3 cursor-pointer' onClick={() => this.openPreview(content.content.images[0].imageUrl)} style={{}} src={getImageSource(ImageConstants.EXPAND)}></img></div> */}
                                                </div>
                                                : <></>
                                        }
                                        {
                                            this.props.isReadOnly && this.state.showOptions ?
                                                <div className={`mt-3 option-margin-bottom-new`}>
                                                    <RadioGroup options={content.content.options}
                                                        // onOptionPressed={this.onOptionPressed}
                                                        isCheckPoint={this.props.isCheckPoint}
                                                        // isReadOnly={this.props.isReadOnly}
                                                        categoryThemeId={this.props.categoryThemeId}
                                                    />
                                                </div>
                                                :
                                                <></>
                                        }
                                    </>
                                    :
                                    <></>
                    }






                    {/* {!this.props.isCheckPoint && !this.props.isReadOnly &&
                        <div className="question-separator d-flex flex-row justify-content-center align-items-center">
                            <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                            </div>
                            {
                                (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                    <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                        style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${content.content.mark} Marks`}>
                                        <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                        <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                    </div> : <></>
                            }
                        </div>
                    } */}

                    {/* {// In Place of true add the this.props.isFFF} */}
                    {
                        !this.props.isReadOnly ?
                            <div className={`mt-5 option-margin-bottom-new`}
                                style={{
                                    marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? true ? "29rem" : '15rem' :
                                        !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "3rem" :
                                            this.state.showStudentResponse ? '7rem' : '1rem'}`
                                }}>
                                <RadioGroup options={content.content.options}
                                    onOptionPressed={this.onOptionPressed}
                                    isCheckPoint={this.props.isCheckPoint}
                                    isReadOnly={this.props.isReadOnly}
                                    categoryThemeId={this.props.categoryThemeId}
                                    isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                                    questionImageExist={content.content.images && content.content.images.length ? true : false}
                                />
                            </div>
                            :
                            <></>
                    }

                    {
                        this.props.isReadOnly ?
                            <>
                                {/* <div style={{
                                    marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? '14rem' :
                                        !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "1rem" :
                                            this.state.showStudentResponse ? '6rem' : '0rem'}`
                                }}> */}
                                <div className={``}
                                    style={{
                                        marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? true ? "28rem" : '14rem' :
                                            !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "1rem" :
                                                this.state.showStudentResponse ? '6rem' : '0rem'}`
                                    }}>
                                    {this.showTabs()}
                                </div>

                                {
                                    this.state.tabIndex === 1 ?
                                        <div>
                                            {/* {this.props.isReadOnly && !this.props.isMultiStep ?
                                                <div className="question-separator mb-3 d-flex flex-row justify-content-center align-items-center"
                                                    style={{
                                                        marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? '15rem' :
                                                            !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "1rem" :
                                                                this.state.showStudentResponse ? '6rem' : '0rem'}`
                                                    }}>
                                                    <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            } */}

                                            <AssessmentQuote questions={this.props.questions}
                                                quote={content.content.quote}
                                                activePage={this.props.activePage}
                                                isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                                                showStudentResponse={this.state.showStudentResponse}
                                                options={this.state.options}
                                                studentResponseCount={this.state.studentResponseCount}
                                                studentList={this.state.studentList}
                                            />
                                            {/* <div className="d-flex"
                                                style={{
                                                    marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? '15rem' :
                                                        !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "1rem" :
                                                            this.state.showStudentResponse ? '6rem' : '0rem'}`
                                                }}> */}
                                            <div className="d-flex"
                                                style={{
                                                    marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? true ? "28rem" : '14rem' :
                                                        !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "1rem" :
                                                            this.state.showStudentResponse ? '6rem' : '0rem'}`
                                                }}>
                                                <div className="mr-2 learning-outcome-text">Learning Outcome : </div>
                                                <div className="elo-title" >{content.eloTitle ? content.eloTitle : ''}</div>
                                            </div>

                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    this.state.tabIndex === 2 ?
                                        <>
                                            <QuestionTitle title={content.content.title}
                                                isFFF = {true}
                                                queNo={this.props.quesNo ? this.props.quesNo : null}
                                                noOfDots={this.props.totalSteps}
                                                activeDot={this.props.quesNo}
                                                hideBackground={this.props.isMultiStep ? true : false}
                                                categoryThemeId={this.props.categoryThemeId}
                                                isAssessment={this.props.isAssessment}
                                                delegate={this.props.delegate}
                                                isReadOnly={this.props.isReadOnly}
                                                showOptions={this.state.showOptions}
                                                toggleOptions={() => this.toggleOptions()}
                                                questions={this.props.questions}
                                                activePage={this.props.activePage}
                                                isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                                                showStudentResponse={this.state.showStudentResponse}
                                                singleSelectDelegate={this}
                                                isPlaying={this.state.isPlaying}
                                                questionTime={this.props.isReadOnly ? 0 : 10}
                                                questionImageExist={content.content.images && content.content.images.length ? true : false}
                                                questionImages={content.content.images && content.content.images.length ? content.content.images : ''}
                                                isSingleImageWidthHigh={this.state.imageWidth < 1010 && !content.content.displayQuestionImageAtBottom ? false : true}
                                            />
                                            <div className="option-margin"
                                                 style={{
                                                    marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? true ? "30rem" : '14rem' :
                                                        !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "1rem" :
                                                            this.state.showStudentResponse ? '6rem' : '0rem'}`
                                                }}>
                                                <div className="options option-margin-bottom-new">
                                                    <RadioGroup options={content.content.options}

                                                        isCheckPoint={this.props.isCheckPoint}
                                                        isReadOnly={true}
                                                        categoryThemeId={this.props.categoryThemeId}
                                                        isFullScreenMode={!this.layoutShared.state.showLayoutBar}
                                                        questionImageExist={content.content.images && content.content.images.length ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    this.state.tabIndex === 3 ?
                                        <div>
                                            <div className="summary-solution-wrapper" 
                                            style={{
                                                marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? true ? "28rem" : '14rem' :
                                                    !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "1rem" :
                                                        this.state.showStudentResponse ? '6rem' : '0rem'}`
                                            }}>
                                                <SummarySoultions forQuestion={true} solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }









                    {/* {
                        this.props.isReadOnly && !this.props.isMultiStep ?
                            <>
                                <div className="option-margin"
                                    style={{
                                        marginLeft: `${!this.layoutShared.state.showLayoutBar && this.state.showStudentResponse ? '14rem' :
                                            !this.layoutShared.state.showLayoutBar && !this.state.showStudentResponse ? "1rem" :
                                                this.state.showStudentResponse ? '6rem' : '0rem'}`
                                    }}>
                                    <div className="d-flex">
                                        <div className="active-tab active-my-answer-tab">Correct Answer</div>
                                    </div>
                                    <div className="options option-margin-bottom-new">
                                        <RadioGroup options={content.content.options} isCheckPoint={this.props.isCheckPoint} isReadOnly={true} categoryThemeId={this.props.categoryThemeId} />
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                    } */}

                    {/* {this.props.isReadOnly && !this.props.isMultiStep ? <div className="question-separator mt-3 mb-3 d-flex flex-row justify-content-center align-items-center">
                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                        </div> </div> : <></>} */}
                </div>





                <div className="">
                    {!this.props.isCheckPoint && !this.props.isMultiStep && <AssessmentFooter userAnswerStatus={this.props.userAnswerStatus} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly} delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}
                </div>
                <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                    multiline={true}
                    place='right'
                    type='light' effect='solid'
                    event="mouseover mouseenter"
                    eventOff="mouseleave mouseout scroll mousewheel blur" />
            </div>
                :
                <></>

        );
    }
}


export default SingleSelect;
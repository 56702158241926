import React from "react";
import './clicker-quiz.scss';
import { getThemeImageSource } from "../../utility/image-source";
import { ImageConstants } from "../../constants/constants";
import Path from "../routes/routes-path";
import { getImageSource } from "../../utility/image-source";

class ClickerQuiz extends React.Component {

    state = {
        clickerCardList: [
            [
                { title: "Clicker Testing", path: Path.CLICKER_TESTING},
                { title: "Testing - Fastest Finger First", path: Path.CLICKER_FLOW_TESTING_FFF  }
            ],
            [
                { title: "Clicker Number for Schools", path: Path.CLICKER_SCHOOLS  }
            ],
            [
                { title: "Fastest Finger First", path: Path.CLICKER_FLOW_FFF},
                { title: "Fastest Finger First - 1", path: Path.CLICKER_FLOW_FFF_ONE},
                { title: "Fastest Finger First - 2", path: Path.CLICKER_FLOW_FFF_TWO},
            ]

        ],
        bgColor: [
            "#FCD1C1",
            "#80DADF",
            "#BEEED3",
            "#D6D5FD"
        ],
    }


    goToHomeScreen() {
        this.props.history.replace({ pathname: Path.HOME })
    }


    renderBreadcrumb() {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 cursor-pointer title-1' onClick={() => this.goToHomeScreen()}>{`Quiz`}</span>
                {/* <span className='pl-2 title-1'>{`Start Clicker Flow`}</span> */}
            </div>
        )
    }

    showItem(item) {


    }





    showCardList = (clickerCardList) => {
        return (
            <div className="d-flex mt-3">
                {
                    clickerCardList && clickerCardList.map((item, index) => (
                        <div key={index} className='home-chapter-card mr-4 cursor-pointer'
                            style={{ backgroundColor: this.state.bgColor[index] }}
                            onClick={() => this.props.history.push(item.path)}
                        >
                            <div className='chapter-title-container d-flex align-items-center'>
                                <div className='d-flex flex-wrap'>
                                    {/* {!isTitleWordIsBigger ?
                                <span className='chapter-title'>{this.props.chapterTitle}</span> : */}
                                    <div className='chapter-title'>{item.title}</div>
                                    {/* } */}
                                </div>
                            </div>
                            <img className="blue-arrow-icon-size" src={getImageSource(ImageConstants.BLUE_ARROW_ICON)} />

                        </div>
                    ))
                }
            </div>

        )
    }




    render() {







        return (

            <div className="clicker-quiz">
                <div className="text-right">
                    <img className="image-size" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, 1)}></img>
                </div>
                <div className="header-for-practice-question">
                    {this.renderBreadcrumb()}
                    {/* <div>
                        <img className="chapter-icon mr-1" src={this.state.selectedChapter.chapterImage ? this.state.selectedChapter.chapterImage : getImageSource(ImageConstants.CHAPTER_ICN)} />
                        <span className="chapter-title">{this.state.selectedChapter.title}</span>
                    </div> */}
                    <div className="sub-header-text">{"Quiz"}</div>
                </div>
                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, 1)}></img>
                </div>


                <div>
                    {
                        this.state.clickerCardList.map(item => (
                            <div className="mt-4">
                                {
                                    this.showCardList(item)
                                }
                            </div>
                        ))
                    }
                </div>

            </div>


        )
    }
}

export default ClickerQuiz;
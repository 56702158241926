import React from "react";
import './classwork-concept-list.scss';
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import SharedProfile from "../../../../shared-storage/profile";
import SharedStaticText from "../../../../shared-storage/static-text-store";
import HomeworkApi from "../../../apis/homework";
import Loading from "../../common/loading/loading";
import { getThemeImageSource, getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import { Constants } from "../../../constants/constants";
import Path from "../../routes/routes-path";
import CwConfirmationModal from "../../common/cw-confirmation-modal/cw-confirmation-modal";
import StandardClassworkViewAnswerScreen from '../standard-classwork-view-answer/standard-classwork-view-answer';

class ConceptList extends React.Component {

    constructor(props) {
        super(props);

        this.homeworkId = this.props.meta.homeworkId

        this.theme = SharedThemeCategory.getThemeCategory(this.props.meta.categoryThemeId);

        // this.props.navigation.addListener('didFocus', (data) => {
        this.getHomeworkStatus()

        // });

        this.getUserHomeworkActivities()

        this.staticText = SharedStaticText.getStaticText('homeworkConceptList');
        this.homeworkCardStaticText = SharedStaticText.getStaticText('homeworkCard');
        this.userInfo = SharedProfile.getProfile();
    }

    state = {

        cleared: [],
        notCleared: [],
        notAttepmted: [],
        categoryId: this.props.meta.categoryThemeId ? this.props.meta.categoryThemeId : 1,
        completedHomeworkDetail: {
            marks: 0,
            correct: 0,
            inCorrect: 0
        }

    };

    staticText = {};

    componentDidMount() {
        // BackHandler.addEventListener('hardwareBackPress', this.backhandler);
    }

    // backhandler = () => {
    //     BackHandler.removeEventListener('hardwareBackPress', this.backhandler);
    //     this.props.navigation.navigate('MyHomeworks');
    //     return true
    // }


    getUserHomeworkActivities = () => {

        let payload = {
            homeworkId: this.homeworkId
        }


        HomeworkApi.getUserHomeworkActivities(payload).then((res) => {

            //console.log(res)


            this.setState({ completedHomeworkDetail: res.contents[0], showSelectChapterModal: true })



        }).catch(err => {
            if (err) {
                return;
            }
        })

    }

    getHomeworkStatus = () => {

        let payload = {
            homeworkId: this.homeworkId
        }

        HomeworkApi.getHomeworkStatus(payload).then((res) => {
            //console.log(res)
            if (res) {
                let cleared = this.groupByConcept(res.cleared, res.concepts)
                let notCleared = this.groupByConcept(res.notCleared, res.concepts)
                let notAttepmted = this.groupByConcept(res.notAttepmted, res.concepts)
                let contentsToRender = []

                let statusSelected = null
                if (notCleared.length) {
                    statusSelected = 'NOT_CLEARED'
                    contentsToRender = notCleared
                } else if (cleared.length) {
                    statusSelected = 'CLEARED'
                    contentsToRender = cleared
                } else if (notAttepmted.length) {
                    statusSelected = 'NOT_ATTEMPTED'
                    contentsToRender = notAttepmted
                }

                this.setState({
                    cleared,
                    notCleared,
                    notAttepmted,
                    statusSelected,
                    contentsToRender
                })
            }
        }).catch(err => {
            if (err) {
                return
            }
        })
    }

    groupByConcept = (nodes, concepts) => {

        let conceptGroup = {}
        let conceptOrdered = []

        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i]

            if (conceptGroup[node.conceptKey]) {
                conceptGroup[node.conceptKey].push(node)
            } else {
                conceptGroup[node.conceptKey] = [node]
            }
        }

        for (let i = 0; i < concepts.length; i++) {
            let key = concepts[i].key

            if (conceptGroup[key]) {
                conceptOrdered.push({
                    ...concepts[i],
                    nodes: conceptGroup[key]
                })
            }
        }

        return conceptOrdered
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    navigate = (status, node, concept) => {

        let eloIds = node.elos ? node.elos.map(item => item.id) : [];

        this.props.navigation.navigate("StandardHomeworkLearningExperience", {
            params: {
                nodeId: node.id,
                status: status,
                homeworkId: this.meta.homeworkId,
                chapterKey: node.chapterKey,
                title: node.chapterTitle,
                nodes: concept.nodes,
                categoryThemeId: this.meta.categoryThemeId,
                eloIds
            }
        })


    }
    // getColorBasedOnStatus(color) {

    //     switch (color) {

    //         case 'darkGreen':
    //             return Styles.darkGreen;

    //         case 'red':
    //             return Styles.red;

    //         case 'lightGreen':
    //             return Styles.lightGreen;


    //         default:
    //             return Styles.defaultColor;
    //     }
    // }

    toggleLateralMenu = (flag) => {
        this.props.navigation.push('LateralMenu', { params: { backTo: "EloList" } });
    }

    close = () => {
        // this.props.navigation.navigate('MyHomeworks');
        this.props.navigation.goBack()
    }

    selectStatus = (status) => {
        if (status === 'CLEARED') {
            this.setState({ statusSelected: status, contentsToRender: this.state.cleared }, () => { })
        }

        if (status === 'NOT_CLEARED') {
            this.setState({ statusSelected: status, contentsToRender: this.state.notCleared }, () => { })
        }

        if (status === 'NOT_ATTEMPTED') {
            this.setState({ statusSelected: status, contentsToRender: this.state.notAttepmted }, () => { })
        }

    }

    getEloStatusColor = () => {
        if (this.state.statusSelected === 'CLEARED') {
            return (
                "green-circle"
            )
        }
        if (this.state.statusSelected === 'NOT_CLEARED') {
            return (
                "red-circle"
            )
        }
        if (this.state.statusSelected === 'NOT_ATTEMPTED') {
            return (
                "gray-circle"
            )
        }

    }

    getEloAction = (status, node, concept) => {

        if (status === 'CLEARED') {
            return (
                <div className="custom-button cursor-pointer" style={{ backgroundColor: this.theme[`themeColor`] }}><span onPress={() => this.navigate(status, node, concept)}>{this.staticText.reviseButtonTitle}</span></div>
            )
        }
        if (status === 'NOT_CLEARED') {
            return (
                <div >
                    <div className="custom-button cursor-pointer" style={{ backgroundColor: this.theme[`themeColor`] }}><span onPress={() => this.navigate(status, node, concept)} style={{}}>{node.isStart ? this.staticText.startButtonTitle : this.staticText.tryAgainButtonTitle}</span></div>
                </div>
            )
        }
        if (status === 'START') {
            return (

                <div className="custom-button cursor-pointer" style={{ backgroundColor: this.theme[`themeColor`] }} ><span onPress={() => this.navigate(status, node, concept)}>{this.staticText.startButtonTitle}</span></div>

            )
        }

        if (status === 'IN_PROGRESS' || status === Constants.TRY_AGIN_IN_PROGRESS) {
            return (

                <div className="custom-button cursor-pointer" style={{ backgroundColor: this.theme[`themeColor`] }}><span onPress={() => this.navigate(status, node, concept)}>{this.staticText.continueButtonTitle}</span></div>

            )
        }

        if (status === 'LOCKED') {
            return (
                <div className="text-center"><img className="lock-image-size" src={getImageSource(ImageConstants.LOCK_ICON)}></img></div>
            )
        }

    }

    goToHomeScreen() {

        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goToMyClassworkList = () => {

        this.props.hideClassworkConceptListScreen && this.props.hideClassworkConceptListScreen()

        this.props.delegate && this.props.delegate.hideClassworkConceptListScreen && this.props.delegate.hideClassworkConceptListScreen()
    }



    renderBreadcrumb() {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 cursor-pointer pl-2' onClick={() => this.goToMyClassworkList()}>{`My Homework >`}</span>
                <span className='title-2 pl-2'>{`${this.props.meta.title || this.props.meta.chapterTitle}`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    showCommingSoon() {
        this.setState({ showCommingSoon: true })
    }

    hideCommingSoon() {
        this.setState({ showCommingSoon: false })
    }

    viewAnswers = () => {
        this.setState({
            showViewAnswerScreen: true,
            metaForViewAnswer: {
                categoryThemeId: this.state.completedHomeworkDetail.categoryId,
                title: this.state.completedHomeworkDetail.chapterTitle,
                homeworkId: this.state.completedHomeworkDetail.homeworkId,
                from : "HOMEWORK"
            }
        })
    }

    hideViewAnswer() {
        this.setState({ showViewAnswerScreen: false })
    }




    render() {

        if (this.state.showCommingSoon) {
            return <CwConfirmationModal showInsights={true} hideInsightsModal={() => this.hideCommingSoon()} />
        }

        if (this.state.showViewAnswerScreen) {
            return (
                <StandardClassworkViewAnswerScreen
                    metaForViewAnswer={this.state.metaForViewAnswer}
                    hideViewAnswerScreen={() => this.hideViewAnswer()}
                    delegate={this} />

            )
        }

        let themeBgColor = this.theme['themeColor']

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId)
        let backgroundColor = this.theme[`secondaryColors`][`chapterCardColor`]

        let lgButton = `linear-gradient(#BDFFF5,#BDFFF5)`

        return (
            <>
                <div className="classwork-concept-list container-fluid p-0">
                    <div className="text-right">
                        <img className="image-size" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, this.state.categoryId)}></img>
                    </div>
                    <div className="header-for-practice-question">
                        {this.renderBreadcrumb()}
                        {/* <div>
                        <img className="chapter-icon mr-1" src={this.state.selectedChapter.chapterImage ? this.state.selectedChapter.chapterImage : getImageSource(ImageConstants.CHAPTER_ICN)} />
                        <span className="chapter-title">{this.state.selectedChapter.title}</span>
                    </div> */}
                    </div>
                    <div className="header-text mt-3">{this.props.meta.title || this.props.meta.chapterTitle}</div>
                    <div className="sub-header-text mt-2">{this.staticText.messageText}</div>
                    <div>
                        <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.state.categoryId)}></img>
                    </div>
                    <div className="assessmentCardDetails mt-3 d-flex justify-content-center align-items-center ">
                        <div className="completedMarkContainer d-flex justify-content-center align-items-center flex-column mr-5">
                            <div className="assessmentScoreDetails">
                                <img className="exam-pad-size pb-1 mr-1" src={getImageSource(ImageConstants.EXAM_PAD_ICON_GRAY)} />

                                <span className="assessmentCardText">{this.homeworkCardStaticText.marksScoredText}</span>
                            </div>
                            <div className="boldText">{this.state.completedHomeworkDetail.marks ? `${this.state.completedHomeworkDetail.marks} / 100` : "0/100"}</div>
                        </div>
                        {/* {this.props.item.timeTaken && this.props.item.timeTaken > 0 ? <View style={{ marginLeft: 15 }}>
                                        <Text style={Styles.boldText}>{this.props.item.timeTaken} {this.staticText.timeText}</Text>
                                        <View style={Styles.assessmentDetails}>
                                            <Image source={getImageSource(ImageConstants.CLOCK)} style={Styles.gif} resizeMode="contain" />
                                            <Text style={Styles.assessmentCardText}>{this.staticText.timeTakenText}</Text>
                                        </View>
                                    </View> :
                                        <></>} */}

                        <div className="correctInCorrectContainer d-flex flex-row ">
                            <div style={{ backgroundImage: lgButton }} className="mr-5 correct-button" >
                                <span className="buttonText">Correct: <span className="answerCountText">{this.state.completedHomeworkDetail.correct}</span></span>
                            </div>
                            <div className="incorrect-button">
                                <span className="buttonText">Incorrect: <span className="answerCountText">{this.state.completedHomeworkDetail.inCorrect}</span></span>
                            </div>
                        </div>

                    </div>

                    <div className="d-flex justify-content-center">

                        {this.state.completedHomeworkDetail.status == 'COMPLETED' ?
                            <div className="d-flex justify-content-center completed-button-padding">
                                <div onClick={this.viewAnswers} className="view-answer-wrapper cursor-pointer" >
                                    <div className="text-center view-answer-button">
                                        {'View Answers'}
                                    </div>
                                </div>
                            </div>

                            :
                            <></>
                        }
                    </div>

                    <div>
                        <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.state.categoryId)}></img>
                    </div>
                    <div className="row mr-0 ml-0">
                        <div className="col pl-0 pr-0">
                            {/* <div className={`row row-max-width justify-content-start align-content-center mt-2 mr-0 ml-0
                            ${(this.state.cleared && this.state.cleared.length === 0) ? 'single-status-width' : (this.state.notCleared && this.state.notCleared.length === 0) ? 'single-status-width' : ''}`}> */}
                            {/* {this.state.cleared && this.state.cleared.length ?
                                <div className={`col text-center border-top-left-radius p-2
                                ${this.state.notCleared && this.state.notCleared.length ? "" : "border-top-right-radius"} 
                                ${this.state.statusSelected === "CLEARED" ? "selected-text" : "not-seleted-text cursor-pointer"}`} 
                                onClick={() => this.selectStatus("CLEARED")}
                                style={{backgroundColor : `${this.state.statusSelected === "CLEARED" ? this.theme[`themeColor`] : ''}`}}>
                                    <div>{this.staticText.clearedText}</div> */}

                            {/* {this.state.statusSelected === "CLEARED" ?
                                        <div colors={this.theme.linearGradient.checkPointNotes ? this.theme.linearGradient.checkPointNotes : Colors.appTheme.gradient10} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }} style={Styles.complexityHighlight} />
                                        :
                                        <></>

                                    } */}
                            {/* </div>
                                :
                                <></>} */}
                            {/* {this.state.notCleared && this.state.notCleared.length ?
                                <div className={`col text-center p-2 border-top-right-radius
                            ${this.state.cleared && this.state.cleared.length ? '' : "border-top-left-radius"}
                             ${this.state.statusSelected === "NOT_CLEARED" ? "selected-text" : "not-seleted-text cursor-pointer"}`} 
                             onClick={() => this.selectStatus("NOT_CLEARED")}
                             style={{backgroundColor : `${this.state.statusSelected === "NOT_CLEARED" ? this.theme[`themeColor`] : ''}`}}>
                                    <div>{this.staticText.notClearedText}</div> */}

                            {/* {this.state.statusSelected === "NOT_CLEARED" ?
                                    <LinearGradient colors={this.theme.linearGradient.checkPointNotes ? this.theme.linearGradient.checkPointNotes : Colors.appTheme.gradient10} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }} style={Styles.complexityHighlight} />
                                    :
                                    <></>

                                } */}
                            {/* </div>
                                :
                                <></>} */}
                            {/* {this.state.notAttepmted && this.state.notAttepmted.length ?
                                <div className={`col text-center p-2 ${this.state.statusSelected === "NOT_ATTEMPTED" ? "seleted-text" : "not-seleted-text"}`} onPress={() => this.selectStatus("NOT_ATTEMPTED")}>
                                    <div >{this.staticText.notAttemptedText}</div> */}

                            {/* {this.state.statusSelected === "NOT_ATTEMPTED" ?
                                    <LinearGradient colors={this.theme.linearGradient.checkPointNotes ? this.theme.linearGradient.checkPointNotes : Colors.appTheme.gradient10} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }} style={Styles.complexityHighlight} />
                                    :
                                    <></>

                                } */}
                            {/* </div>
                                :
                                <></>} */}

                            {/* </div> */}

                            {
                                !this.state.notCleared.length ?
                                    <div className=" mt-3 mb-3 classwork-completed-text">
                                        <img className="success-image-size mr-2" src={getImageSource(ImageConstants.SUCCESS_ICON)}></img> {"Your Classwork is completed"}
                                    </div>

                                    : <></>
                            }


                            {
                                this.state.cleared && this.state.cleared.length ?
                                    <div className="cleared-text">
                                        {this.staticText.clearedText}
                                    </div>

                                    : <></>
                            }

                            <div>
                                {this.state.cleared && this.state.cleared.length ? this.state.cleared.map((item, index) =>
                                    <div>
                                        <div className="concept-title mt-2 mb-3">{item.title}</div>
                                        {item.nodes.length && item.nodes.map((node, index) =>
                                            <>
                                                {node.elos.length && node.elos.map((elo, eloIndex) =>
                                                    <div className="elo-row row mr-0 ml-0 mb-2">
                                                        <div className="col-12 row flex-row align-items-center  elo">
                                                            <div className="col-1 pr-0">
                                                                <div className={`${this.getEloStatusColor("CLEARED")}`} />
                                                            </div>
                                                            <div className="elo-title col pl-0">{elo.title}</div>
                                                        </div>
                                                        {/* <div className="col-2 column-width text-center status-button" onClick={() => this.showCommingSoon()}>{this.getEloAction(node.status, node, item)}</div> */}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )
                                    :
                                    <></>}
                            </div>

                            {
                                this.state.notCleared && this.state.notCleared.length ?
                                    <div className="not-cleared-text mt-4">
                                        {this.staticText.notClearedText}
                                    </div>

                                    : <></>
                            }

                            <div>
                                {this.state.notCleared && this.state.notCleared.length ? this.state.notCleared.map((item, index) =>
                                    <div>
                                        <div className="concept-title mt-2 mb-3">{item.title}</div>
                                        {item.nodes.length && item.nodes.map((node, index) =>
                                            <>
                                                {node.elos.length && node.elos.map((elo, eloIndex) =>
                                                    <div className="elo-row row mr-0 ml-0 mb-2">
                                                        <div className="col-12 row flex-row align-items-center  elo">
                                                            <div className="col-1 pr-0">
                                                                <div className={`${this.getEloStatusColor("NOT_CLEARED")}`} />
                                                            </div>
                                                            <div className="elo-title col pl-0">{elo.title}</div>
                                                        </div>
                                                        {/* <div className="col-2 column-width text-center status-button" onClick={() => this.showCommingSoon()}>{this.getEloAction(node.status, node, item)}</div> */}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )
                                    :
                                    <></>}
                            </div>

                        </div>
                        {/* <div className="col-6 ">
                            <div className="text-center">
                                <img className="mobile-app-icon" src={getImageSource(ImageConstants.MOBILE_APP_ICON)} />
                            </div>
                            <div className="text-center learning-text">{"Continue Learning on the countingwell App"}</div>
                            <div className="d-flex justify-content-center mt-3">
                                <div className="button-wrapper d-flex flex-column align-items-center p-2">
                                    <div className="send-me-text">Send me link to download countingwell app</div>
                                    <div className="d-flex justify-content-between align-items-center store-wrapper">
                                        <div className="app-store-button cursor-pointer" onClick={() => window.open('https://t2m3p.app.link/mmfmJXCBulb')}><img className="store-icon" src={getImageSource(ImageConstants.APP_STORE_ICON)}/>App Store</div>
                                        <div className="play-store-button cursor-pointer" onClick={() => window.open('https://t2m3p.app.link/mmfmJXCBulb')}><img className="store-icon" src={getImageSource(ImageConstants.PLAY_STORE_ICON)}/>Play Store</div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="mt-3 text-center">
                        <img className="mobile-app-icon-with-text" src={getThemeImageSource(ImageConstants.APP_IMAGE_WITH_TEXT, this.state.categoryId)} />
                    </div>

                </div>
                <>
                    {this.state.loading &&
                        <Loading />
                    }
                </>
            </>

        )
    }
}

export default ConceptList
import React from "react";
import './school-confirmation-popup.scss';
import SharedProfile from "../../../../../shared-storage/profile";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import CommonApi from "../../../../apis/common";
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import FireBaseEvent from '../../../../firebase-events/firebase-events';



export default class SchoolConfirmationScreen extends React.Component {
    constructor(props) {

        super(props);
        this.staticText = SharedStaticText.getStaticText('schoolConfirmationScreen');
        this.userInfo = SharedProfile.getProfile()
        // this.branchParams = SharedDeepLink.getDeepLink()
        this.meta = this.props.meta;

        if (this.meta.schoolCode || (this.branchParams && this.branchParams["show"] == "SCHOOL_CONNECT_DEEPLINK" && this.branchParams['schoolCode'])) {
            this.state.schoolCode = this.meta.schoolCode ? this.meta.schoolCode : this.branchParams['schoolCode']
            this.getSchoolInformationForSchoolCode()
        } else {
            // this.props.delegate && this.props.delegate.hideSchoolConfirmation && this.props.delegate.hideSchoolConfirmation(false,null)
        }
    }

    state = {
        schoolDetails: {

        }
    }

    getSchoolInformationForSchoolCode = () => {
        const { schoolCode } = this.state;
        let payload = {
            schoolCode: schoolCode
        }

        this.setState({ loading: true })


        CommonApi.getSchoolInformationFromSchoolConnect(payload).then((res) => {
            if (res) {
                this.setState({ schoolName: res.name, locality: res.locality, schoolDetails: res, schoolOriginalCode: res.code, loading: false }, () => {
                    this.setState({ showSchoolConnectPopUp: true })
                })
            } else {
                this.setState({ isInvalidSchoolCode: true, loading: false })
            }
        }).catch(err => {
            if (err) {
                this.setState({ isInvalidSchoolCode: true, loading: false })
                return;
            }
        })
    }

    confirmSchool = (value) => {

        if (!this.state.schoolCode) {
            return
        }

        let payload = {
            schoolCode: this.state.schoolCode,
            validateSection: true
        }

        CommonApi.schoolConnectConfirm(payload).then((res) => {
            // this.setState({ showSchoolConnectPopUp: false })

            this.userInfo['schoolName'] = this.state.schoolDetails.name;
            this.userInfo['schoolLocality'] = this.state.schoolDetails.locality;
            this.userInfo['schoolVerificationCode'] = this.state.schoolDetails.code
            this.userInfo['schoolCode'] = this.state.schoolDetails.code


            this.userInfo['section'] = ""
            this.userInfo['sectionId'] = ""

            // moengage.logCustomEvent('My Profile',{'User Id':this.userInfo.userId,"School Updated":this.state.schoolName,"School Locality":this.state.locality})
            FireBaseEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolDetails.name, "School Locality": this.state.schoolDetails.locality })

            SharedProfile.setProfile(this.userInfo)
            this.close()
            // SharedDeepLink.setDeepLink(null)
            // this.props.navigation.navigate("Section", { meta: { from: 'SCHOOL_CONFIRMATION' } })

        }).catch(err => {
            if (err) {
                return;
            }
        })


    }


    incorrectSchool = () => {

        // moengage.logCustomEvent('Login', { 'User Id': res.profile.userId, "First Time": "No" }, res.profile, true)
        this.props.navigation.navigate('LandingScreen', { meta: { showSubscriptionEndPopup: this.meta && this.meta.isExipred ? this.meta.isExipred : false, subscriptionEndDate: this.meta && this.meta.subscriptionEndDate ? this.meta.subscriptionEndDate : "", showFirstPage: true } });


    }


    close = () => {
        this.props.delegate.close && this.props.delegate.close()
    }

    render() {

        return (

            <Modal className="custom-school-confirmation-popup" isOpen={true}>
                <ModalBody>
                    <div>
                        <div>
                            <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                            <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.close()} />
                        </div>
                        <div className="title">{`Connect your School`}</div>
                        <div className="mt-5 pl-5">
                            <div className="text-container">
                                <div className="header-text">{this.staticText && this.staticText.questionText ? this.staticText.questionText : "Are You from "}</div>

                                <div className="school-name">{this.state.schoolDetails.name}, </div>
                                <div className="city-name">{this.state.schoolDetails.locality}, {this.state.schoolDetails.city} ?</div>
                            </div>
                        </div>
                        <div className="button-wrapper d-flex justify-content-center mt-4">

                            <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.confirmSchool}>
                                {this.staticText && this.staticText.correctText ? this.staticText.correctText : "YES, THAT'S CORRECT"}
                            </div>

                            <div className="connect-later-button d-flex justify-content-center cursor-pointer" onClick={this.close}>
                                {this.staticText && this.staticText.inCorrectText ? this.staticText.inCorrectText : "INCORRECT DETAIL"}
                            </div>

                        </div>
                        <div className="">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }


}
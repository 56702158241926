import React from "react";
import './paid-event-pay-screen.scss';
import SharedProfile from "../../../../shared-storage/profile";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import Path from "../../routes/routes-path";

export default class PaidEventPayScreen extends React.Component {
    constructor(props) {
        super(props);

        this.staticText = {
            title: "This is a paid Event",
            description: "Please pay the participation fees in order to access the quiz",
            amountSubText: "Price mentioned is inclusive of GST",
            buttonText: "CONTINUE TO PAY",
            amountText: "₹ 59",
            url: "https://www.countingwell.com"
        }

        this.userInfo = SharedProfile.getProfile();
        // //console.log(this.props.event)

        this.event = this.props.event;

        if (this.event) {
            if (this.event.staticText && this.event.staticText.paidEventPayScreen) {
                this.staticText = this.event.staticText.paidEventPayScreen
            }

        }
    }

    state = {
    }


    componentDidMount() {
    }

    handleBackButtonClick = () => {
        this.props.navigation.navigate('LandingScreen')
        return true
    }

    handleNavigation = () => {
    }

    close = () => {
        this.props.navigation.navigate("LandingScreen")
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    onScrollEnd = (e) => {

        let contentOffset = e.nativeEvent.contentOffset;

        let viewSize = e.nativeEvent.layoutMeasurement;

        let pageNum = Math.floor(contentOffset.x / viewSize.width);

        this.setState({ activeIndex: pageNum })
    }

    onPressPay = () => {
        // if (this.staticText.url) {
        //     window.open(`//${this.staticText.url}`)
        // }

        window.open(`https://countingwell.com/login?plan=650adb104936dc2d62c00c39&m=${this.userInfo.mobileNumber}&cc=${this.userInfo.countryCode}`)
    }

    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goToEventList() {
        this.props.delegate && this.props.delegate.hidePaidEventPayScreen && this.props.delegate.hidePaidEventPayScreen()
    }


    renderBreadcrumbForKnowMore = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToEventList()} >{`Events >`}</span>
                <span className='title-1 pl-2'>{`Know More`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    render() {

        return (

            <div className="paid-event-pay-screen">
                <img className="header-image-size" src={getImageSource(ImageConstants.KNOW_MORE_HEADER)}></img>
                {this.renderBreadcrumbForKnowMore()}
                <div className="paid-event-content">
                    <img className="credit-card-image-size" src={getImageSource(ImageConstants.CREDIT_CARD_IMAGE)} />
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="mr-3"><img className="bage-image-size" src={getImageSource(ImageConstants.BADGE_IMAGE)} /> </div>
                        <div className="title">{this.staticText.title}</div>
                    </div>
                    <div className="description-text row ml-0 pr-0">
                        <div className="col-11">
                            {this.staticText.description}
                        </div>
                    </div>

                    <div className="text-center amount-text">
                        {this.staticText.amountText}
                    </div>

                    <div className="amount-subtext text-center mt-2">
                        {this.staticText.amountSubText}
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                        <div className="continue-button cursor-pointer" onClick={this.onPressPay}>{this.staticText.buttonText}</div>
                </div>
                {/* <img className="footer-image-size" src={getImageSource(ImageConstants.KNOW_MORE_FOOTER)}></img> */}


            </div>

        )
    }
}
import React from "react";
import './grouping.scss';
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import CommonUtils from "../../../../utility/common-utilities";
import CWImageV2 from "../../cw-image-2/cw-image-2";
import QuestionTitle from "../question-title/question-title";
import QuestionImage from "../../cw-question-image/cw-question-image";
import { getImageSource, getThemeImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import AssessmentFooter from "../../assessment-footer/assessment-footer";
import { Constants } from "../../../../constants/constants";
import Parser from "../../content-parser/content-parser";
import SummarySoultions from "../../summary-solution/summary-solution";
import DotPagination from "../../dot-pagination/dot-pagination";
import AnswerTab from "../../../my-assessment-result/question/answer-tab/answer-tab";
import ReactTooltip from "react-tooltip";


class Grouping extends React.Component {

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.dragItems = [];
        this.options = this.props.content.content.options;
        this.mapDragItems();

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId);
        this.staticText = SharedStaticText.getStaticText('multiStepQuestion');
        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');

        if (this.props.isReadOnly) {
            let content = JSON.stringify(this.props.content)
            this.state.content = JSON.parse(content);

        }

    }

    state = {

        droppedItemsIndex: [],
        droppedZoneItems: [],
        draggedIndex: -1,
        dropIndex: -1,
        dragabbleItemsScroll: true,
        selectedItem: null,
        pan: { x: 0, y: 0 },
        scrollViewHeight: 0,
        activateResponder: false,
        stickyHeaderIndex: -1,
        dropZonePosition: [],
        dragOverIndex: -1,
        sourceX: null,
        sourceY: null,
        sourcePreviewVisible: false,
        showSolution: true,
        showOptions: false
    }

    scrollViewRef;
    scrollViewMeasure = {};

    //pan = { x: 0, y: 0 }; // to hold array pan gesture items to be draged
    dropZone = []; // To hold droppable Zones on load
    dropZoneMeasure = []; //Presist droppable Zones on reaload
    panPosition = [] // store scroll view items position

    dropZoneNew = [];
    dropZoneMeasureNew = [];
    dropZoneHeights = [];

    dragItemScrollTouchXY = null
    dx = 0;
    dy = 0;

    dragOverInfo = { index: -1 }

    isValid = false;

    answerTickCount = 0;

    componentDidMount() {
        if (this.props.isReadOnly) {
            this.setState({ status: `${this.props.userAnswerStatus ? "CORRECT" : "IN_CORRECT"}` })
        }

        if (!this.props.isReadOnly) {
            this.setState({ content: this.props.content });
            this.startTimerforAnswer()
        }
    }

    startTimerforAnswer() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }
        this.timerIntervalId = setInterval(() => {

            this.answerTickCount += 1
        }, 1000)
    }

    mapDragItems() {

        let dragItem = [];

        let options = this.props.content.content.options;

        for (let i = 0; i < options.length; i++) {
            dragItem = dragItem.concat(options[i].items);
        }
        //this.pan = dragItem.map(() => new Animated.ValueXY());
        this.dragItems = CommonUtils.shuffleArray(dragItem);
    };




    setSelectedAnswer(draggedIndex, dropIndex) {

        let droppedZoneItems = [...this.state.droppedZoneItems];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        if (droppedZoneItems[dropIndex] && droppedZoneItems[dropIndex].items)
            droppedZoneItems[dropIndex].items.push(this.dragItems[draggedIndex]);
        else
            droppedZoneItems[dropIndex] = { items: [this.dragItems[draggedIndex]] };


        if (this.dragItems[draggedIndex]) {
            droppedItemsIndex.push(this.dragItems[draggedIndex].id);
        }

        this.setState({
            droppedZoneItems: droppedZoneItems,
            droppedItemsIndex: droppedItemsIndex,
            dragabbleItemsScroll: true,
        })
        this.validateAndDelegate(droppedZoneItems);
        this.dragInfo = {}
        this.dragOverInfo = { index: -1 }
    }

    validateAndDelegate(droppedZoneItems) {

        let total = 0;

        for (let i = 0; i < droppedZoneItems.length; i++) {
            if (droppedZoneItems[i])
                total = total + droppedZoneItems[i].items.length;
        }

        if (this.dragItems.length == total) {
            this.isValid = true;
            this.props.delegate && this.props.delegate.enableNavigation && this.props.delegate.enableNavigation();
        } else {
            this.isValid = false;
            this.props.delegate && this.props.delegate.disableNavigation && this.props.delegate.disableNavigation();
        }

    }

    getContentUsingContentType(option, style) {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let content = option.content.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");

        content = content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");

        if (option.contentType == Constants.CONTENT_TYPE_TEXT) {
            return this.parser.parseWithStyles(`${content}`, { p: styles.p, 'cw-math': styles.p })
        } else if (option.contentType == Constants.CONTENT_TYPE_IMAGE) {
            return <CWImageV2 uri={option.content}></CWImageV2>
        }

    }

    onDragStart = (event, dragInfo, type) => {

        event.stopPropagation();


        if (event.type === 'touchstart') {
            event.preventDefault()
            this.setState({ sourcePreviewVisible: true });
        }
        this.dragType = type;
        this.dragInfo = dragInfo;
    };

    onDragOver = (event, dragOverInfo, type) => {

        event.stopPropagation();
        event.preventDefault()
        if (event.type === 'touchmove') {

            // Find the target element based on the touch event
            const targetElement = document.elementFromPoint(
                event.changedTouches[0].clientX,
                event.changedTouches[0].clientY
            );

            //console.log('drag over', targetElement)

            let dragOverIndex = { index: -1 }
            let index = +targetElement?.attributes[0]?.textContent
            if (!isNaN(index)) {
                dragOverIndex = { index: index }
            }

            this.dragOverInfo = dragOverIndex

            // Access touch coordinates
            const touchX = event.touches[0].clientX;
            const touchY = event.touches[0].clientY;

            // Update the source preview's position
            this.setState({
                sourceX: touchX,
                sourceY: touchY,
            });

        } else if (this.dragType == type && this.dragType == "ANSWER") {

            this.dragOverInfo = dragOverInfo;

            this.setState({ dragOverQuestionContentId: this.dragOverInfo.item.id });
            // //console.log("CONCEPT onDragOver", dragOverInfo);

        }

    };

    onDragEnd = (event) => {

        // //console.log("end reached", this.dragType, this.dragInfo, this.dragOverInfo)
        // if (this.dragType == "ANSWER") {

        //     if (this.dragInfo) {

        //         // if (this.dragOverInfo.index != -1) {
        //             // this.setSelectedAnswer(index, dropIndex);
        //             this.setSelectedAnswer(this.hashedOriginalOptions[this.dragInfo.item.id], this.dragOverInfo.index, this.dragInfo.index)
        //         // }

        //         //console.log(this.dragInfo, this.dragOverInfo)

        //     }
        // }

    };

    dropItem = (event) => {

        event.preventDefault()
        this.setState({ sourcePreviewVisible: false });

        if (this.dragType == "ANSWER") {

            if (this.dragInfo) {

                if (this.dragOverInfo.index != -1) {
                    // this.setSelectedAnswer(index, dropIndex);
                    this.setSelectedAnswer(this.dragInfo.index, this.dragOverInfo.index)
                }

                //console.log(this.dragInfo, this.dragOverInfo)

            }
        }
    }



    getOptionItem = (index, option) => {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]


        return (
            !(this.state.droppedItemsIndex.includes(option.id)) &&
            <div key={option.id} className="answer-card cursor-pointer" style={{ border: `1.5px solid ${borderColor}`, backgroundColor: bgColor }}
                onDragStart={e => this.onDragStart(e, { index: index, item: option }, "ANSWER")}
                onTouchStart={e => this.onDragStart(e, { index: index, item: option }, "ANSWER")}
                onTouchMove={(e) => this.onDragOver(e, { index: index, item: option }, "")}
                onTouchEnd={this.dropItem}
                onDragEnd={this.onDragEnd}
                draggable={true}>
                <div className="align-self-start mb-2">{this.props.isReadOnly && this.state.showOptions ? <></> : <img className="drag-image" src={getThemeImageSource(ImageConstants.DRAG_ICON, this.props.categoryThemeId)} />} </div>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                </div>
            </div>

        )
    }




    renderDropItem = (index) => {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]

        if (this.state.droppedZoneItems[index] && this.state.droppedZoneItems[index].items) {
            let options = this.state.droppedZoneItems[index].items;
            //console.log(options)
            return (
                options.map((option, i) => (
                    <div id={index} className="answer-card d-flex align-items-center justify-content-center position-relative"
                        style={{ border: `1.5px solid ${borderColor}`, backgroundColor: bgColor }}>
                        <div className="close-icon-wrapper">
                            <img className="close-icon"
                                onClick={() => this.onDroppedItemRemove(index, option.id, i)}
                                src={getThemeImageSource(ImageConstants.THEME_CLOSE_ICON, this.props.categoryThemeId)} />
                        </div>
                        <div className="">
                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>
                    </div>
                ))
            )
        }
    }

    onDroppedItemRemove(dropZoneIndex, itemId, itemIndex) {
        let droppedZoneItems = [...this.state.droppedZoneItems];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        droppedItemsIndex = droppedItemsIndex.filter((item) => item != itemId);

        if (droppedZoneItems[dropZoneIndex] && droppedZoneItems[dropZoneIndex].items) {
            droppedZoneItems[dropZoneIndex].items.splice(itemIndex, 1);
        }

        this.setState({
            droppedZoneItems: droppedZoneItems,
            droppedItemsIndex: droppedItemsIndex
        })
        this.validateAndDelegate(droppedZoneItems);
    }

    getAnswer = () => {


        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }

        if (this.isValid) {

            let options = this.props.content.content.options;

            let payload = [];

            for (let i = 0; i < this.state.droppedZoneItems.length; i++) {

                let selected = [];
                if (this.state.droppedZoneItems[i]) {
                    selected = this.state.droppedZoneItems[i].items.map(opt => ({ id: opt.id }));
                }
                payload.push({ id: options[i].id, selected: selected });
            }
            return { payload, timeTakenToAnswer: this.answerTickCount };

        } else {
            return null;
        }
    }

    updateAnswer = (payload) => {

        let droppedZoneItems = [...this.state.droppedZoneItems];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        let dragItems = {};
        this.dragItems.map((item) => { { dragItems[item.id] = item } });

        for (let i = 0; i < payload.answer.length; i++) {

            let items = payload.answer[i].selected.map((item) => {
                if (dragItems[item.id]) {
                    droppedItemsIndex.push(item.id);
                    return dragItems[item.id];
                }
            });

            droppedZoneItems[i] = { items: items };
        }
        this.isValid = true;
        this.setState({ droppedZoneItems: droppedZoneItems, droppedItemsIndex: droppedItemsIndex });

    }


    reset = () => {
        this.setState({
            droppedItemsIndex: [],
            droppedZoneItems: []
        });
    }

    updateAnswerForUserAnswer = (payload) => {

        //console.log(payload)
        // let content = this.state.content
        // let options = content.content.options;

        let contentRaw = JSON.stringify(this.props.content);
        contentRaw = JSON.parse(contentRaw);
        let optionsRaw = contentRaw.content.options;

        //console.log(optionsRaw)

        let dragItems = {};

        this.dragItems.map((item) => { { dragItems[item.id] = item } });

        for (let i = 0; i < payload.answer.length; i++) {

            let items = payload.answer[i].selected.map((item) => {
                if (dragItems[item.id]) {
                    return dragItems[item.id];
                }
            });

            optionsRaw[i].items = items;
        }

        this.setState({ content: contentRaw });

    }


    showMyAnswer = () => {
        if (this.props.userAnswer) {
            this.updateAnswerForUserAnswer(this.props.userAnswer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.parse(JSON.stringify(this.props.content))
        //console.log(content)
        this.setState({ content: content }, () => {

        })
    }


    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer, showSolution: !this.state.showSolution })
    }

    toggleViewSolution = () => {
        if (this.state.status === "CORRECT") {
            return;
        }
        this.setState({ showSolution: !this.state.showSolution, showCorrectAnswer: !this.state.showCorrectAnswer })
    }


    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions })
    }


    renderReadOnlyItem = (option) => {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]
        let options = option.items;
        return (
            options.map((option, i) => (
                <div className="answer-card d-flex align-items-center justify-content-center position-relative"
                    style={{ border: `1.5px solid ${borderColor}` }}>

                    {option.contentType == Constants.CONTENT_TYPE_TEXT ?
                        <div className="">
                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>
                        :
                        <div className="">
                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>

                    }
                </div>
            ))
        )
    }

    getReadonlyView(content) {
        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]
        let linearGradientBg = `linear-gradient(${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][0]}, ${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][1]})`


        return (
            <div className="grouping-question-type-read-only">

                <div className={`${!this.props.isReadOnly ? "min-height" : ""}`}>

                    <div>
                        {
                            this.props.isReadOnly ?
                                <>
                                    <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                                        {
                                            this.props.userAnswerStatus ?
                                                <div className="correct-answer-bg d-flex">
                                                    <div className="">
                                                        <img className="image-size" src={getImageSource(ImageConstants.WHITE_TICK_ICON_NEW)} />
                                                    </div>
                                                    <div className="ml-2">
                                                        <div className="name-header-correct">{"Correct"}</div>
                                                        {/* <div className="sub-title">{this.correctAnswerStaticText.subTitle}</div> */}
                                                    </div>
                                                </div>

                                                :
                                                <div className="incorrect-answer-bg d-flex">
                                                    <div className="">
                                                        <img className="image-size" src={getImageSource(ImageConstants.WHITE_CROSS_ICON)} />
                                                    </div>
                                                    <div className="ml-1">
                                                        <div className="name-header text-center">{"Incorrect"}</div>
                                                        {/* <div className="sub-title">{this.inCorrectAnswerStaticText.subTitle}</div> */}
                                                    </div>
                                                </div>

                                        }

                                    </div>
                                    {/* <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                    <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                    </div>
                                </div> */}
                                </>
                                :
                                <></>
                        }

                        {
                            this.props.isMultiStep && this.props.mulipStepQuestion ?
                                <>
                                    <QuestionTitle title={this.props.mulipStepQuestion.content.title} queNo={this.props.quesNo ? this.props.quesNo : null} delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId} />
                                    <QuestionImage images={this.props.mulipStepQuestion.content.images} categoryThemeId={this.props.categoryThemeId} />
                                    <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                        </div>
                                    </div>
                                </>
                                : <></>
                        }

                        {this.props.totalSteps && this.props.quesNo && <DotPagination noOfDots={this.props.totalSteps} activeDot={this.props.quesNo} categoryThemeId={this.props.categoryThemeId} />}
                        {!this.props.isCheckPoint && !this.props.isMultiStep ?
                            <>
                                <QuestionTitle
                                    title={content.content.title}
                                    queNo={this.props.quesNo ? this.props.quesNo : null}
                                    hideBackground={this.props.isMultiStep ? true : false}
                                    categoryThemeId={this.props.categoryThemeId}
                                    isAssessment={this.props.isAssessment}
                                    delegate={this.props.delegate}
                                    isReadOnly={this.props.isReadOnly}
                                    showOptions={this.state.showOptions}
                                    toggleOptions={() => this.toggleOptions()} />
                                <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                                {
                                    this.props.isReadOnly && this.state.showOptions ?
                                        <>
                                            <div className="d-flex flex-row  answer-card-container mt-4">
                                                {
                                                    this.dragItems.map((option, index) => this.getOptionItem(index, option))
                                                }
                                            </div>
                                            {!this.props.isCheckPoint && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                                <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                                    <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                                </div>
                                                {
                                                    (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                                        <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                                            style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }}>
                                                            <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                                            <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                                        </div> : <></>
                                                }
                                            </div>
                                            }
                                            <div className={`matching-question-container d-flex flex-row mt-4 ${this.state.droppedItemsIndex.length ? "matching-question-container-min-height-2" : ""} option-margin-bottom`}>
                                                {this.options.map((option, index) => (
                                                    <div id={index} key={"dropZone" + index} className="drop-zone" style={{ backgroundImage: linearGradientBg, border: `1.5px solid ${borderColor}` }}
                                                        onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}
                                                        onDrop={this.dropItem}
                                                    >
                                                        {/* <Text style={styles.dropContainerHeaderText}>{option.title}</Text> */}
                                                        <div className="text-center p-1 drop-zone-title" style={{ background: borderColor }}>
                                                            {this.parser.parseWithStyles(`<p>${option.title}</p>`, { p: styles.p, 'cw-math': styles.p })}
                                                        </div>

                                                        <div id={index} className="d-flex flex-row answer-wrapper justify-content-center">
                                                            {
                                                                this.renderDropItem(index)
                                                            }
                                                        </div>

                                                    </div>
                                                ))
                                                }
                                            </div>
                                        </>
                                        :
                                        <></>
                                }

                            </>

                            : <></>
                        }


                        {
                            !this.props.isMultiStep && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                    <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                </div>
                            </div>

                        }

                        {
                            this.props.isReadOnly ?
                                <>
                                    <div className="d-flex align-items-center mt-3 ml-3">
                                        <div className="d-flex ">
                                            <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                                style={{
                                                    backgroundColor: `${this.state.showSolution ? "#E1D9FF" : "#E6E8E8"}`,
                                                    color: `${this.state.showSolution ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                                }} onClick={this.toggleViewSolution}>
                                                {
                                                    this.state.showSolution ? 'Solution' : 'Solution'
                                                }
                                                {/* <span>View Answer</span> */}
                                                {/* <img className="arrow-size" src={getThemeImageSource(this.state.showSolution ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                            </div>
                                        </div>
                                        {
                                            this.props.isReadOnly && !this.props.isMultiStep ?
                                                <>
                                                    {
                                                        this.props.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                            <div className="d-flex  ml-4">
                                                                <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                                                    style={{
                                                                        backgroundColor: `${this.state.showCorrectAnswer ? "#E1D9FF" : "#E6E8E8"}`,
                                                                        color: `${this.state.showCorrectAnswer ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                                                    }} onClick={this.toggleViewAnswer}>
                                                                    {
                                                                        this.state.showCorrectAnswer ? 'Answer' : 'Answer'
                                                                    }
                                                                    {/* <span>View Answer</span> */}
                                                                    {/* <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </>

                                                : <></>
                                        }

                                    </div>

                                    <div className="solution-answer-border mb-4"></div>


                                    {
                                        this.state.showSolution ?
                                            <SummarySoultions solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions>
                                            : <></>
                                    }

                                    {
                                        this.props.isReadOnly && !this.props.isMultiStep ?
                                            this.state.showCorrectAnswer ?
                                                <>
                                                    {
                                                        this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                            <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.state.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.userAnswer}></AnswerTab>
                                                            : <></>
                                                    }
                                                    {
                                                        this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                            <div className="matching-question-container d-flex flex-row mt-4 option-margin-bottom">
                                                                {content.content.options.map((option, index) => (
                                                                    <div key={"dropZone" + index} className="drop-zone" style={{ backgroundImage: linearGradientBg, border: `1.5px solid ${borderColor}` }}>
                                                                        <div className="text-center p-1 drop-zone-title" style={{ background: borderColor }}>
                                                                            {this.parser.parseWithStyles(`<p>${option.title}</p>`, { p: styles.p, 'cw-math': styles.p })}
                                                                        </div>
                                                                        <div className="d-flex flex-row answer-wrapper justify-content-center">
                                                                            {
                                                                                this.renderReadOnlyItem(option)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                                : <></>
                                            : <></>
                                    }
                                </>
                                :
                                <></>
                        }


                        {/* {
                            this.props.isReadOnly &&
                            <div className="d-flex mb-4">
                                <div className="d-flex row-width-for-solution justify-content-center align-items-center"
                                    style={{ backgroundColor: "#F0ECFF" }}>
                                    <div className="col view-button-for-solution text-center"
                                        style={{ color: `${this.theme['themeColor']}` }} >Solution</div>
                                </div>
                            </div>

                        } */}

                        {/* <SummarySoultions forQuestion={true} solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions> */}
                        {/* {this.props.isReadOnly ? <SummarySoultions forQuestion={true} solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions> : <></>} */}
                    </div>





                    {/* {this.props && this.props.attachments && this.props.attachments.length > 0 &&
                        <ScrollView style={styles.attachmentsContainer} horizontal={true} showsHorizontalScrollIndicator={false}>

                            {this.props.attachments.map((item, index) =>
                                this.renderAttachments(item, index)
                            )}

                            {this.props.attachments.length < 3 ? <TouchableOpacity onPress={() => this.onSelectImage('IMAGE')} style={styles.addAttachments}>

                                <Image source={getImageSource(ImageConstants.ADD_IMAGE)} />

                            </TouchableOpacity>
                                :
                                <></>
                            }

                        </ScrollView>
                    } */}



                </div>

                {<AssessmentFooter userAnswerStatus={this.props.userAnswerStatus} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly} delegate={this.props.delegate} fixedFooter={true} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}

            </div>

        )
    }





    render() {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let content = this.state.content;

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]
        let linearGradientBg = `linear-gradient(${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][0]}, ${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][1]})`
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]

        const { sourceX, sourceY, sourcePreviewVisible } = this.state;

        return (

            !this.props.isReadOnly ?
                content ? <div className="grouping-question-type" onMouseEnter={ReactTooltip.rebuild}>
                    {sourcePreviewVisible && (

                        <div className="source-preview answer-card cursor-pointer" style={{
                            border: `2px solid ${borderColor}`, backgroundColor: bgColor,
                            left: sourceX + 30 + 'px',
                            top: sourceY - 50 + 'px'
                        }}
                        >
                            {this.getContentUsingContentType(this.dragInfo?.item, 'answer')}
                        </div>
                    )}
                    <div className="min-height">

                        {
                            this.props.isMultiStep && this.props.mulipStepQuestion ?
                                <>
                                    <QuestionTitle title={this.props.mulipStepQuestion.content.title} queNo={this.props.quesNo ? this.props.quesNo : null} categoryThemeId={this.props.categoryThemeId} delegate={this.props.delegate} />
                                    <QuestionImage images={this.props.mulipStepQuestion.content.images} categoryThemeId={this.props.categoryThemeId} />

                                    <div className="d-flex align-items-center mt-4">
                                        <img className="bulb-image-size" src={getImageSource(ImageConstants.BULB_IMAGE)} />
                                        <div className="instruction-text ml-3">
                                            {/* {constants.MULTI_SELECT_INSTRUCTION_TEXT} */}
                                            {this.props.isFromMyAssessment ? this.staticText.assessmentMultistepInstruction : this.staticText.instruction}
                                        </div>
                                    </div>

                                    <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                        <div className={`${(this.props.isFromMyAssessment) ? 'divider-wrapper' : ''}`}>
                                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                        </div>
                                        {
                                            this.props.isFromMyAssessment ?
                                                <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                                    style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${this.props.mulipStepQuestion.content.mark} Marks`}>
                                                    <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                                    <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{this.props.mulipStepQuestion.content.mark}</span>
                                                </div> : <></>
                                        }
                                    </div>
                                </>
                                : <></>
                        }

                        {this.props.totalSteps && this.props.quesNo && <DotPagination noOfDots={this.props.totalSteps} activeDot={this.props.quesNo} categoryThemeId={this.props.categoryThemeId} />}

                        {
                            !this.props.isCheckPoint &&
                            <QuestionTitle title={content.content.title}
                                queNo={this.props.quesNo ? this.props.quesNo : null}
                                noOfDots={this.props.totalSteps}
                                activeDot={this.props.quesNo}
                                hideBackground={this.props.isMultiStep ? true : false}
                                categoryThemeId={this.props.categoryThemeId}
                                isAssessment={this.props.isAssessment}
                                delegate={this.props.delegate}
                                isSchoolAssignment={true}
                            />
                        }
                        {!this.props.isCheckPoint && <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />}



                        <div className="d-flex flex-row  answer-card-container mt-4">
                            {
                                this.dragItems.map((option, index) => this.getOptionItem(index, option))
                            }
                        </div>

                        {!this.props.isCheckPoint && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                            <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                            </div>
                            {
                                (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                    <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                        style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${content.content.mark} Marks`}>
                                        <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                        <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                    </div> : <></>
                            }
                        </div>
                        }
                        <div className={`matching-question-container d-flex flex-row mt-4 ${this.state.droppedItemsIndex.length ? "matching-question-container-min-height-2" : ""} option-margin-bottom`}>
                            {this.options.map((option, index) => (
                                <div id={index} key={"dropZone" + index} className="drop-zone" style={{ backgroundImage: linearGradientBg, border: `1.5px solid ${borderColor}` }}
                                    onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}
                                    onDrop={this.dropItem}
                                >
                                    {/* <Text style={styles.dropContainerHeaderText}>{option.title}</Text> */}
                                    <div className="text-center p-1 drop-zone-title" style={{ background: borderColor }}>
                                        {this.parser.parseWithStyles(`<p>${option.title}</p>`, { p: styles.p, 'cw-math': styles.p })}
                                    </div>

                                    <div id={index} className="d-flex flex-row answer-wrapper justify-content-center">
                                        {
                                            this.renderDropItem(index)
                                        }
                                    </div>

                                </div>
                            ))
                            }
                        </div>
                    </div>


                    {!this.props.isCheckPoint && <AssessmentFooter isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly} delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}

                    <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                        multiline={true}
                        place='right'
                        type='light' effect='solid'
                        event="mouseover mouseenter"
                        eventOff="mouseleave mouseout scroll mousewheel blur" />
                </div> : <></>

                :

                this.getReadonlyView(content)


        );
    }



}


export default Grouping;
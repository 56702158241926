import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import SessionCache from './session-cache';
import AuthenticateRoute from './authenticate-route';
import Path from './routes-path';
import LoginScreen from '../login-screen/login-screen';
import SharedProfile from '../../../shared-storage/profile';
// import HomeComponent from '../home-component/home-component';

let public_routes = [
    Path.LOGIN,
    Path.SIGNUP,
    Path.WATCH_VIDEO,
    
    `${Path.HOMEWORK_COMPLETION_REPORT}:type/:homeworkId/:chapter/:class/:createdDate`,
    `${Path.HOMEWORK_EVALUATION_REPORT}:type/:homeworkId/:chapter/:class/:createdDate`,
    `${Path.MOST_WRONG_REPORT}:type/:homeworkId/:chapter/:class/:createdDate`,
    `${Path.ELO_LEVEL_REPORT}:type/:homeworkId/:chapter/:class/:createdDate`,

    `${Path.ASSESSMENT_RESULTS_REPORT}:homeworkId/:chapter/:class/:createdDate/:schoolName/:teacherName/:city/:branch`,
    `${Path.TEST_COMPLETION_REPORT}:homeworkId/:chapter/:class/:createdDate`,
    `${Path.TEST_WRONG_ANSWER_REPORT}:type/:homeworkId/:chapter/:class/:createdDate`,
    `${Path.TEST_ALL_QUESTIONS_REPORT}:homeworkId/:chapter/:class/:createdDate`,
];

export default function RouteWrapper({
    component: Component,
    isPrivate,
    ...rest
}) {

    if (public_routes.indexOf(rest.path) != -1) {
        return <Route {...rest} component={Component} />;
    }

    let status = SharedProfile.isAuthenticated();
    // let status = true
    if (!status) {
        return <Route {...rest} render={(props) => {
            return <AuthenticateRoute {...rest} component={Component}></AuthenticateRoute>
        }} />
    }
    if (SharedProfile.isVerified() == 'false') {
        return <Route location={Path.LOGIN} component={LoginScreen} />
    }
    else if (status) {
        //console.log("inside route wrapper")
        return <Route {...rest} component={Component} />;
    }

    return <Route path={Path.LOGIN} component={LoginScreen} />
}

RouteWrapper.propTypes = {

    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {

    isPrivate: false
}

import React, { Component } from 'react';
import './cw-confirmation-modal.scss'
import { Modal, ModalBody } from "reactstrap";
import { ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source'
import SharedProfile from '../../../../shared-storage/profile';
import SharedStaticText from '../../../../shared-storage/static-text-store';
import CommonUtils from '../../../utility/common-utilities';

class CwConfirmationModal extends Component {

    constructor(props) {
        super(props);
        if (this.props.showTrialFeatures) {
            this.staticText = SharedStaticText.getStaticText('BasicPlanFeatures');
        }

        if (this.props.showReducedTimePopup) {
            this.staticText = SharedStaticText.getStaticText('ReducedTimeLimitPopup');
        }

        if (this.props.showTrialFeatures || this.props.showReducedTimePopup) {
            this.userInfo = SharedProfile.getProfile();
        }


    }

    onClickOkay = () => {
        this.props.onOk && this.props.onOk()
    }

    onClickClose = () => {

        if (this.props.showTrialFeatures) {
            this.props.closeBasicPlanFeatures && this.props.closeBasicPlanFeatures()
        }

        if (this.props.showReducedTimePopup) {
            this.props.closeReducedTimeLimitPopup && this.props.closeReducedTimeLimitPopup()
        }

        if (this.props.showPasscodeSettingPopup) {
            this.props.closePasscodeSettingPopup && this.props.closePasscodeSettingPopup()
        }

        if (this.props.closeModal) {
            this.props.closeModal && this.props.closeModal()
        }

        if(this.props.showInsights) {
            this.props.hideInsightsModal && this.props.hideInsightsModal()
        }

    }

    showSetNewPasscodeScreen() {
        this.props.delegate && this.props.delegate.goToNewPasscodeScreen && this.props.delegate.goToNewPasscodeScreen()
    }


    sendOtp() {
        this.props.sendOtp && this.props.sendOtp()
    }

    showPasscodeScreen() {
        this.props.showPasscodeScreen && this.props.showPasscodeScreen({ skipOtp: true })
    }


    render() {
        return (
            <Modal className="cw-common-custom-modal-center" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                    {this.props.showTrialFeatures ? <img className='badge-image' src={getImageSource(ImageConstants.BADGE_IMAGE)} /> : ''}
                    {this.props.showReducedTimePopup ? <img className='time-limit-image' src={getImageSource(ImageConstants.DAILY_TIME_LIMIT_IMAGE)} /> : ''}
                    {this.props.showPasscodeSettingPopup ? <img className='badge-image mt-3' src={getImageSource(ImageConstants.DOUBTS)} /> : ''}
                    <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} />
                </div>
                <ModalBody>
                    {
                        this.props.showTrialFeatures ?
                            <div className="pratice-container">
                                <div className='header-text'>{this.staticText.headerText}</div>
                                <div className="text-center name-header mt-2">{CommonUtils.simpleTemplateEngine(this.staticText.messageText, { userName: this.userInfo.name })}</div>
                                <div className='header-text mt-2 mb-4'>{this.staticText.happyLearning}</div>
                                <div className="d-flex align-items-center justify-content-center mt-2">
                                    <div className="take-challenge cursor-pointer text-center" onClick={() => this.onClickClose()}>
                                        {this.staticText.continueButtonTitle}
                                    </div>
                                </div>
                                <div className="position">
                                    <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                                </div>
                            </div>
                            : ''
                    }

                    {
                        this.props.showReducedTimePopup ?
                            <div className="pratice-container">
                                <div className='header-text mt-5'>{this.staticText.reducedTimeLimitMesasage}</div>
                                <div className='name-header mt-2 mb-4'>{this.staticText.messageText}</div>
                                <div className="d-flex align-items-center justify-content-around mt-2">
                                    <div className="continue-button cursor-pointer text-center" onClick={() => this.onClickClose()}>
                                        {this.staticText.continueButtonTitle}
                                    </div>
                                    <div className="take-challenge cursor-pointer  text-center">
                                        {this.staticText.upgradeButtonTitle}
                                    </div>
                                </div>
                                <div className="position">
                                    <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                                </div>
                            </div>
                            : ''
                    }
                    {
                        this.props.showPasscodeSettingPopup ?
                            <div className="pratice-container">
                                <div className='header-text mt-5'>Looks like you haven’t set a passcode</div>
                                <div className='name-header mt-2 mb-4'>Set a 4 digit passcode for your profile to login without OTP</div>
                                <div className="d-flex align-items-center justify-content-around mt-2">
                                    <div className="continue-button cursor-pointer text-center" onClick={() => this.onClickClose()}>
                                        {`NOT NOW`}
                                    </div>
                                    <div className="take-challenge cursor-pointer  text-center" onClick={() => this.showSetNewPasscodeScreen()}>
                                        {`SET PASSCODE`}
                                    </div>
                                </div>
                                <div className="position">
                                    <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                                </div>
                            </div>
                            : ''
                    }

                    {
                        this.props.showSendOtpConfirmationPopup ?
                            <div className="pratice-container">
                                <div className='header-text mt-4'>An OTP will be sent to your mobile number.</div>
                                <div className='name-header mt-2 mb-4'>You can skip this in case you do not have a phone right now</div>
                                <div className="d-flex align-items-center justify-content-around mt-2">
                                    <div className="continue-button cursor-pointer text-center" onClick={() => this.showPasscodeScreen()}>
                                        {`Skip for now`}
                                    </div>
                                    <div className="take-challenge cursor-pointer  text-center" onClick={() => this.sendOtp()}>
                                        {`OK, Send OTP`}
                                    </div>
                                </div>
                                <div className="position">
                                    <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                                </div>
                            </div>
                            : ''
                    }

                    {
                        this.props.showInsights ?
                            <div className="pratice-container">
                                <div className='header-text mt-5 mb-4'>COMMING SOON</div>
                                {/* <div className='name-header mt-2 mb-4'>You can skip this in case you do not have a phone right now</div> */}
                                <div className="d-flex align-items-center justify-content-around mt-2">
                                    {/* <div className="continue-button cursor-pointer text-center" onClick={() => this.onClickClose()}>
                                        {`OK`}
                                    </div> */}
                                    <div className="take-challenge cursor-pointer  text-center" onClick={() => this.onClickClose()}>
                                        {`OK`}
                                    </div>
                                </div>
                                <div className="position">
                                    <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                                </div>
                            </div>
                            :
                            ''
                    }

                </ModalBody>
            </Modal>
        );
    }
}

export default CwConfirmationModal;

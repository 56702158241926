
import React, { Component } from 'react';
import './cw-confirmation-modal.scss'
import { Modal, ModalBody } from "reactstrap";
import { ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source'
import SharedProfile from '../../../../shared-storage/profile';
import SharedStaticText from '../../../../shared-storage/static-text-store';
import CommonUtils from '../../../utility/common-utilities';

class CwSubscriptionModal extends Component {

    constructor(props) {
        super(props);
       
        if (this.props.showSubscriptionPopup) {
            this.userInfo = SharedProfile.getProfile();
        }


    }

    onClickOkay = () => {
        this.props.onOk && this.props.onOk()
    }

    onClickClose = () => {
        this.props.delegate && this.props.delegate.closeSubscriptionPopup && this.props.delegate.closeSubscriptionPopup()
    }

    showSetNewPasscodeScreen() {
        window.open('https://countingwell.com/premium_subscription.html')
    }


    sendOtp() {
        this.props.sendOtp && this.props.sendOtp()
    }

    showPasscodeScreen() {
        this.props.showPasscodeScreen && this.props.showPasscodeScreen({ skipOtp: true })
    }


    render() {
        return (
            <Modal className="cw-common-custom-modal-center-for-subscription" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                    {this.props.showSubscriptionPopup ? <img className='badge-image' src={getImageSource(ImageConstants.BADGE_IMAGE)} /> : ''}
                    {/* {this.props.showReducedTimePopup ? <img className='time-limit-image' src={getImageSource(ImageConstants.DAILY_TIME_LIMIT_IMAGE)} /> : ''}
                    {this.props.showPasscodeSettingPopup ? <img className='badge-image mt-3' src={getImageSource(ImageConstants.DOUBTS)} /> : ''} */}
                    <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} />
                </div>
                <ModalBody>
                    {
                        this.props.showSubscriptionPopup ?
                            <div className="pratice-container">
                                <div className='header-text mt-5'>Subscribe to Unlock the Chapter</div>
                                {/* <div className='name-header mt-2 mb-4'>Set a 4 digit passcode for your profile to login without OTP</div> */}
                                <div className="d-flex align-items-center justify-content-around mt-2">
                                    <div className="continue-button cursor-pointer text-center" onClick={() => this.onClickClose()}>
                                        {`NOT NOW`}
                                    </div>
                                    <div className="take-challenge cursor-pointer  text-center" onClick={() => this.showSetNewPasscodeScreen()}>
                                        {`Subscribe`}
                                    </div>
                                </div>
                                <div className="position">
                                    <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                                </div>
                            </div>
                            : ''
                    }
                </ModalBody>
            </Modal>
        );
    }
}

export default CwSubscriptionModal;

import React from 'react';
import './check-point-questions.scss'
import { getImageSource } from '../../../utility/image-source';
import { ImageConstants, Constants } from '../../../constants/constants';
import LearningHeader from '../learning-header/learning-header';
import Parser from '../../common/content-parser/content-parser';
import QuestionImage from '../../common/cw-question-image/cw-question-image';
import QuestionTitle from '../../common/question/question-title/question-title';
import Question from '../../common/question/question';
// import sharedThemecategory from '../../../shared-storage/category-theme';
// import sharedAppSounds from "../../../shared-storage/app-sounds";
// import CWAppSounds from '../../../components/common/cw-sounds/cw-sounds';
import SharedProfile from '../../../../shared-storage/profile';
import SharedThemeCategory from '../../../../shared-storage/category-theme';


export default class PracticeQuestions extends React.Component {
    constructor(props) {
        super(props);
        this.parser = new Parser();
        // this.appSounds = sharedAppSounds.getAppSounds();
        // this.playAppSound = CWAppSounds;
        this.userInfo = SharedProfile.getProfile();

    }
    state = {
        showSuccessNotes: false,
        showErrorNotes: false,
        showIcons: false,
        scrollNotes: true,
        notesImage: true
    };

    showSuccessNotes = () => {
        this.setState({
            showSuccessNotes: true,
            showErrorNotes: false,
        })

        this.props.enableContinue && this.props.enableContinue()
        // this.playAppSound.playSound(this.appSounds.correctAnswer);
    }

    showErrorNotes = () => {
        this.setState({
            showErrorNotes: true,
            showSuccessNotes: false
        })

        this.props.enableContinue && this.props.enableContinue()

        // this.playAppSound.playSound(this.appSounds.inCorrectAnswer);
    }

    enableNavigation = () => {
        let selectedAnswer = this.questionComponent.getAnswer();
        if (selectedAnswer != null) {

            if (this.props.content.content.type === Constants.BINARY) {
                this.props.delegate && this.props.delegate.openNextLearningContent && this.props.delegate.openNextLearningContent(this.props.content);

                this.setState({ scrollNotes: false })
                //if(this.state.scrollNotes){
                this.props.delegate && this.props.delegate.scrollUp && this.props.delegate.scrollUp();
                //}
                let result = this.props.content.content.options.filter(item => item.id === selectedAnswer.answer[0].id)

                if (result[0].isCorrect === true) {
                    this.showSuccessNotes();
                }
                else {
                    this.showErrorNotes();
                }
            }
        }
    }

    showNotes = () => {
        let result = []
        let selectedAnswer = this.questionComponent.getAnswer()

        if (selectedAnswer != null) {

            this.props.delegate && this.props.delegate.openNextLearningContent && this.props.delegate.openNextLearningContent(this.props.content);
            this.setState({ scrollNotes: false })
            //if(this.state.scrollNotes){
            this.props.delegate && this.props.delegate.scrollUp && this.props.delegate.scrollUp();
            //}

            if (this.props.content.content.type == Constants.GEOMETRY_QUESTION && this.props.content.content.canvas && this.props.content.content.canvas.type == 'CANVAS_CONSTRUCTIVE') {

                if (!selectedAnswer || !selectedAnswer.answer || !selectedAnswer.answer.answers || !selectedAnswer.answer.answers.length) {
                    this.showErrorNotes();
                    return;
                }

                let correctAnswers = selectedAnswer.answer.answers.filter(item => item)

                if (correctAnswers.length == selectedAnswer.answer.answers.length) {
                    this.showSuccessNotes();
                    return
                }

                this.showErrorNotes();
                return;
            }

            if (this.props.content.content.type === Constants.SIGNLE_SELECT ||
                (this.props.content.content.type == Constants.GEOMETRY_QUESTION && +this.props.content.content.optionType == Constants.SIGNLE_SELECT)) {
                result = this.props.content.content.options.filter(item => item.id === selectedAnswer.answer[0].id)

                if (result[0].isCorrect === true) {
                    this.showSuccessNotes();
                }
                else {
                    this.showErrorNotes();
                }
            }

            else if (this.props.content.content.type === Constants.MULTI_SELECT ||
                (this.props.content.content.type == Constants.GEOMETRY_QUESTION && +this.props.content.content.optionType == Constants.MULTI_SELECT)) {

                let totalCorrectAnswers = this.props.content.content.options.filter(item => item.isCorrect === true);

                if (totalCorrectAnswers.length !== selectedAnswer.answer.length) {
                    this.showErrorNotes();
                }
                else {
                    let isSuccess = true
                    for (let i = 0; i < selectedAnswer.answer.length; i++) {

                        let correctAnswersIdArray = totalCorrectAnswers.map(item => item.id);

                        if (!correctAnswersIdArray.includes(selectedAnswer.answer[i].id)) {
                            isSuccess = false;
                            break;
                        }
                    }
                    if (isSuccess)
                        this.showSuccessNotes();
                    else
                        this.showErrorNotes();
                }

            }
            else if (this.props.content.content.type === Constants.FILL_IN_THE_BLANK ||
                (this.props.content.content.type == Constants.GEOMETRY_QUESTION && +this.props.content.content.optionType == Constants.FILL_IN_THE_BLANK)) {

                let isWrong = false;

                let options = this.props.content.content.options;

                let mappedObj = {};

                for (let i = 0; i < selectedAnswer.answer.length; i++) {

                    mappedObj[selectedAnswer.answer[i].id] = selectedAnswer.answer[i].selected[0].id

                }
                for (let i = 0; i < options.length; i++) {
                    if (mappedObj.hasOwnProperty(options[i].id)) {
                        let correctAnswers = options[i].items.filter(item => item.isCorrect);

                        if (correctAnswers[0].id !== mappedObj[options[i].id]) {
                            isWrong = true;
                            break;
                        }
                    }

                }
                if (isWrong === true) {
                    this.showErrorNotes();
                }
                else {
                    this.showSuccessNotes();
                }

            }
        }

        else {
            this.toggleInvalidAnswerModal(true);
        }
    }

    showIcons = () => {
        this.setState({
            showIcons: !this.state.showIcons
        })
    }

    toggleInvalidAnswerModal = (value) => {
        this.setState({ showInvalidAnswerModal: value });
    }
    onOk = () => {
        this.toggleInvalidAnswerModal(false);
    }

    onClickBookMarkActive = () => {
        this.props.delegate && this.props.delegate.onClickBookMarkActive && this.props.delegate.onClickBookMarkActive(this.props.bookMarkId, this.props.selectedIndex)
    }

    onClickBookMark = () => {
        this.props.delegate && this.props.delegate.onClickBookMark && this.props.delegate.onClickBookMark(this.props.contentObj, this.props.selectedIndex)
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId);
        // setCategoryThemeStyles(this.props.categoryId);

        return (

            <div className='check-point-questions'>

                {this.props.showTitle &&
                    <div>
                        <LearningHeader data={{ headerIcon: ImageConstants.PRACTICE, title: this.props.groupTitle ? this.props.groupTitle : (this.props.content.displayTitle ? this.props.content.displayTitle : this.props.content.title) }} delegate={this} showBookMark={this.props.showBookMark} showInactiveBookmark={this.props.showInactiveBookmark} meta={this.props.meta}></LearningHeader>
                    </div>
                }


                <div ><QuestionTitle title={this.props.content.content.title} isCheckPoint={true} categoryThemeId={this.props.categoryId}></QuestionTitle></div>

                {this.props.content.content.images.length > 0 &&
                    <div><QuestionImage images={this.props.content.content.images} isCheckPoint={true} categoryThemeId={this.props.categoryId} /></div>
                }


                <div>

                    <div >

                        <div>
                            <Question
                                content={this.props.content}
                                ref={ref => this.questionComponent = ref}
                                isCheckPoint={true}
                                delegate={this}
                                showInvalidAnswerModal={this.state.showInvalidAnswerModal}
                                categoryThemeId={this.props.categoryId} />
                        </div>

                        <div className='d-flex justify-content-center'>
                            {this.props.content.content.type !== Constants.BINARY &&
                                <div className='submit-button' onClick={this.showNotes} >

                                    <span className='button-text'>{Constants.LETS_CHECK}</span>

                                </div>
                            }
                        </div>

                    </div>
                    {this.state.showSuccessNotes &&
                        <div>
                            <div className='notes-wrapper'>
                                <div className='position-relative'>

                                    <div className='notes-header-text mb-3'>Hey {this.userInfo.name}, {Constants.THATS_CORRECT}</div>
                                    <img className='notes-image-size' src={getImageSource(ImageConstants.SUCCESS_NOTES_NEW)} />
                                </div>

                                <div className='answer-explanation'>{this.parser.parseLearningContent(this.props.content.notes.success, this.props.delegate, this.props.glossary, true, false, this.props.categoryId)}</div>
                            </div>
                        </div>
                    }
                    {this.state.showErrorNotes &&
                        <div>
                            <div className='notes-wrapper'> 
                                <div className='position-relative' >
                                    <div className='notes-header-text mb-3'>Hey {this.userInfo.name}, {Constants.THATS_INCORRECT}</div>
                                    <img className='notes-image-size' src={getImageSource(ImageConstants.ERROR_NOTES_NEW)} />
                                </div>

                                <div className='answer-explanation'>{this.parser.parseLearningContent(this.props.content.notes.error, this.props.delegate, this.props.glossary, true, false, this.props.categoryId)}</div>

                            </div>
                        </div>
                    }

                </div>

            </div >

        );
    }
}


import React, { Component } from 'react';
import './prep-kit-solved-example.scss';
import LearningContentWrapper from '../exam-prep-kit-learning-content-wrapper/exam-prep-kit-learning-content-wrapper';
import { ImageConstants, Constants } from '../../../../constants/constants';
import SharedProfile from '../../../../../shared-storage/profile';
import { getImageSource } from '../../../../utility/image-source';
import Loading from '../../../common/loading/loading';
import ExamPrepKitApi from '../../../../apis/exam-prep-kit';
import AssessmentApi from '../../../../apis/assessment';

export default class SolvedExampleModal extends Component {

    workflowEngine = null;
    worflowNodes = [];
    questions = [];
    state = {
        learningElos: [
        ]
    };

    CONTENT_FETCH_LIMIT = 5
    contentHash = {}

    constructor(props) {

        super(props);
        // let { navigation } = this.props;
        // this.params = navigation.getParam('meta');
        this.params = this.props.metaInfo;
        console.log(this.props.metaInfo)
        this.headerTitle = this.params && this.params.headerTitle ? this.params.headerTitle : ""
        this.progress = null;
        this.userInfo = SharedProfile.getProfile();


        this.contents = []

        if (this.params.resumeIndex) {
            this.state.resumeIndex = this.params.resumeIndex;
            this.lastMaxIndex = this.params.resumeIndex;
            this.getSolvedExampleContent(this.params.resumeIndex)
        } else {
            this.getSolvedExampleContent()
        }
    }

    learnMoreParam = {}

    componentDidMount() {
        // this.props.navigation.addListener("didFocus", () => {

        //     BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });

        // this.props.navigation.addListener("didBlur", () => {
        //     BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });
    }

    handleBackButtonClick = () => {
        return true
    }

    buildLearningContent = (contents, index, callback) => {

        this.getContents(contents, index, (err, response) => {

            if (err) {
                return
            }

            for (let i = 0; i < response.length; i++) {
                let content = response[i]
                this.contentHash[content._id] = content
            }

            let learningElos = this.state.learningElos;

            for (let i = 0; i < contents.length; i++) {
                let content = contents[i]

                if (this.contentHash[content.contentId]) {
                    learningElos.push({
                        eloId: content.eloId,
                        eloTitle: content.eloTitle,
                        categoryThemeId: content.categoryThemeId,
                        contentId: content.contentId,
                        contents: [{ title: "", contents: [this.contentHash[content.contentId]] }],
                        bookMarkedItem: content.bookMarkedItem ? content.bookMarkedItem : null
                    })
                } else {
                    learningElos.push({
                        eloId: content.eloId,
                        eloTitle: content.eloTitle,
                        categoryThemeId: content.categoryThemeId,
                        contentId: content.contentId,
                        contents: [],
                        bookMarkedItem: content.bookMarkedItem ? content.bookMarkedItem : null
                    })
                }
            }

            this.setState({ learningElos }, () => {
                if (callback)
                    callback()
            })
        })
    }

    buildLearningContentPagePress = (contents, index, callback) => {

        let learningElos = this.state.learningElos;

        if (this.contentHash[learningElos[index].contentId]) {
            learningElos[index].contents = [{ title: "", contents: [this.contentHash[learningElos[index].contentId]] }]

            this.setState({ learningElos }, () => {
                callback && callback()
            })
            return
        }

        this.getContents(contents, index, (err, response) => {

            if (err) {
                return
            }

            for (let i = 0; i < response.length; i++) {
                let content = response[i]
                this.contentHash[content._id] = content
            }

            for (let i = 0; i < learningElos.length; i++) {

                if (this.contentHash[learningElos[i].contentId]) {
                    learningElos[i].contents = [{ title: "", contents: [this.contentHash[learningElos[i].contentId]] }]
                }
            }

            this.setState({ learningElos }, () => {
                if (callback)
                    callback()
            })
        })
    }

    getSolvedExampleContent = (index, callback) => {


        let payload = {
            chapterKey: this.params.chapterKey
        }

        ExamPrepKitApi.prepkitSolvedExample(payload).then((response) => {
            if (!response.length) {
                this.props.close && this.props.close()
            }

            this.contents = response

            if (this.contents)
                this.buildLearningContent(this.contents, index ? index : 0, callback)

        }).catch(err => {
            console.log(err)
            if (err) {
                this.props.close && this.props.close()
                return
            }
        })
    }

    getContents = (questions, index, callback) => {


        let ids = [];

        for (let i = 0; i < questions.length; i++) {

            if (index <= i) {
                if (ids.length < this.CONTENT_FETCH_LIMIT) {
                    if (!this.contentHash[questions[i].contentId])
                        ids.push(questions[i].contentId)
                }
            }
        }

        let payload = { _id: ids }

        AssessmentApi.getContentList(payload).then((res) => {
            callback && callback(null, res.response)
        }).catch(err => {
            console.log(err);
            if (err) {
                callback && callback(err, null)
                return;
            }
        })
    }

    onPressNext = (activeIndex, callback) => {

        if (this.contents[activeIndex + 1] && this.contentHash[this.contents[activeIndex + 1].contentId]) {
            callback && callback()
        } else {

            this.getContents(this.contents, activeIndex + 1, (err, response) => {

                if (err) {
                    return
                }

                for (let i = 0; i < response.length; i++) {
                    let content = response[i]
                    this.contentHash[content._id] = content
                }

                let learningElos = this.state.learningElos;

                for (let i = activeIndex + 1; i < learningElos.length; i++) {
                    let lerningContent = learningElos[i]

                    if (lerningContent.contentId && !lerningContent.contents.length && this.contentHash[lerningContent.contentId]) {
                        lerningContent.contents = [{ title: "", contents: [this.contentHash[lerningContent.contentId]] }]
                    }
                }

                this.setState({ learningElos }, () => {
                    if (callback)
                        callback(true)
                })
            })
        }
    }

    learningElosCompleted = () => {
        this.updatePrepkitSolvedExampleCompleted(() => {
            // this.props.navigation.goBack()
            this.goBackToTableOfContents()
        })

    }

    close = () => {
        this.props.navigation.goBack()
    }

    goToLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    closeLearnMore = () => {
        this.setState({ showLearnMore: false })
    }

    onPressLearnMore = (doc) => {

        this.learnMoreParam = {
            eloId: doc.eloId,
            title: this.params.title,
            headerTitle: doc.eloTitle,
            chapterKey: this.params.chapterKey
        }

        this.setState({ showLearnMore: true })
    }

    updatePrepkitSolvedExampleCompleted = (callback) => {

        let payload = {
            chapterKey: this.params.chapterKey,
            type: "SOLVED_EXAMPLES"
        }

        this.setState({ loading: true })
        ExamPrepKitApi.updatePrepKitProgress(payload).then((response) => {
            callback && callback()
            this.setState({ loading: false })
        }).catch(err => {
            console.log(err);
            this.setState({ loading: false })
            if (err) {
                return
            }
        })
    }

    next = (index) => {

        if (this.params.isSolvedExampleCompleted || this.lastMaxIndex > index)
            return

        this.lastMaxIndex = index

        let payload = {
            chapterKey: this.params.chapterKey,
            resumeId: index,
            type: "SOLVED_EXAMPLES"
        }

        ExamPrepKitApi.updatePrepKitActivity(payload).then((response) => {
        }).catch(err => {
            console.log(err);
            if (err) {
                return
            }
        })

    }

    onPressPage = (index, callback) => {
        this.buildLearningContentPagePress(this.contents, index, callback)
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToExamPrepKit() {
        this.props.delegate && this.props.delegate.goToExamPrepKit && this.props.delegate.goToExamPrepKit()
    }




    goBackToTableOfContents() {

        this.props.delegate && this.props.delegate.hidePrepKitSolvedExample && this.props.delegate.hidePrepKitSolvedExample()

    }





    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToExamPrepKit()} >{`Prepare`}{` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToTableOfContents()}>{`${this.params.title}`} {` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToTableOfContents()}>{`${`Table of Contents`}`} {` > `}</span>
                <span className='title-1 pl-2'>{`Solved Examples`}</span>
                {/* <span className='title-1 pl-2' >{this.params.headerTitle}</span> */}
            </div>
        )
    }


    render() {

        return (
            <div className='prep-kit-solved-example'>
                {
                    this.renderBreadcrumbForChapterList()
                }
                {/* <StatusBar hidden={true} /> */}
                {this.state.learningElos.length > 0 && <LearningContentWrapper
                    elo={this.state.learningElos}
                    delegate={this}
                    navigation={this.props.navigation}
                    isRevise={this.isRevise}
                    title={this.params.title ? this.params.title : ''}
                    navigationInfo={this.params}
                    eloIds={this.eloIds}
                    noBlur={true}
                    headerIcon={getImageSource(ImageConstants.SOLVED_EXAMPLES)}
                    headerTitle={this.params.headerTitle}
                    removeHeaderIcon
                    onPressNext={this.onPressNext}
                    onPressPage={this.onPressPage}
                    activeIndex={this.state.resumeIndex}
                    allPagesActive={this.params.isSolvedExampleCompleted}
                >
                </LearningContentWrapper>}
            </div>
        )
    }
}
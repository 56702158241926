import React from "react";
import './chapter-list.scss';
import CommonApi from "../../../apis/common";
import { Constants, ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source'
import Loading from "../loading/loading";
import SharedProfile from "../../../../shared-storage/profile";

class ChapterList extends React.Component {

    state = {
        chapterList: [],
        loading: false,
        selectedChapters: []
    }

    componentDidMount() {

        let userInfo = SharedProfile.getProfile()
        this.setState({ userInfo })

        if (this.props.type === "MY_ASSESSMENTS" || this.props.type === "PRACTICE" || this.props.type === 'HOME_COMPONENT') {
            this.getOnlyChapters()
        } else {
            this.getChapterList()
        }
    }


    getOnlyChapters() {
        this.setState({ loading: true })
        CommonApi.getOnlyChapters().then(data => {
            console.log(data)
            let chapterList = data.items

            let filteredChapterChapterList = chapterList.filter(item => (item.key === '17200705216289554' || item.key === '17211962915345747'))

            // console.log(filteredChapterChapterList)
// To Hide Science Chapters in the Maths Chapters Class 10
            chapterList.forEach((item, index) => {
                if((item.key != '17200705216289554' || item.key != '17211962915345747') && (index <= 13 && this.props.type === 'HOME_COMPONENT')) {
                    filteredChapterChapterList.push(item)
                }
            })

            if (this.props.searchedChapterName) {
                let selectedChapterList = filteredChapterChapterList.filter(option => option[`title`].toLowerCase().includes(this.props.searchedChapterName.toLowerCase()));
                this.setState({ chapterList: selectedChapterList, loading: false });
            } else {
                this.setState({ chapterList: filteredChapterChapterList, loading: false });
            }

        }).catch(err => {
            //console.log(err)
        })
    }


    getChapterList() {
        this.setState({ loading: true })

        CommonApi.getChapterList().then(data => {
            console.log(data)
            let chapterList = data.items

            if (this.props.searchedChapterName) {
                let selectedChapterList = chapterList.filter(option => option[`title`].toLowerCase().includes(this.props.searchedChapterName.toLowerCase()));
                this.setState({ chapterList: selectedChapterList, loading: false });
            } else {
                this.setState({ chapterList: chapterList, loading: false });
            }

        }).catch(err => {
            //console.log(err)
        })

    }

    showChapterDetails(chapter, index) {
        this.props.onSelectChapter && this.props.onSelectChapter(chapter)
    }


    onPressCheckBox = (index, value) => {

        let selectedChapters = this.state.chapterList.filter(chapter => chapter.active)

        if (selectedChapters.length < this.props.assessmentMeta.maxChaptersAllowed || !value) {

            let chapterList = this.state.chapterList;
            chapterList[index].active = value

            selectedChapters = this.state.chapterList.filter(chapter => chapter.active)
            //console.log(selectedChapters)

            this.setState({ index, chapterList, selectedChapters })
        }


    }

    start = (selectedChapter, index) => {

        console.log(selectedChapter)
        // let selectedChapters = this.state.chapterList.filter(chapter => chapter.active)

        // if (selectedChapters.length == 0)
        //     return;

        // if (selectedChapters.length > this.props.assessmentMeta.maxChaptersAllowed)
        //     return;

        // else if (selectedChapters.length == 1) {
        // this.props.delegate && this.props.delegate.hideSelectChapterModal && this.props.delegate.hideSelectChapterModal();
        this.props.delegate && this.props.delegate.onStart && this.props.delegate.onStart({
            chapterKey: selectedChapter.key,
            chapterTitle: selectedChapter.title,
            categoryId: selectedChapter.categoryId,
            isLocked : selectedChapter.isLocked,
            selectedChapterIndex : index,
            imageUrl : selectedChapter.chapterImage
        });
        //     return;
        // }

        this.props.delegate && this.props.delegate.hideSelectChapterModal && this.props.delegate.hideSelectChapterModal(() => {

            // if (Platform.OS == 'ios') {
            //     setTimeout(() => {
            //         this.props.delegate && this.props.delegate.onStart && this.props.delegate.showSelectDuration(selectedChapters);
            //     }, 500)
            // } else {
            //     this.props.delegate && this.props.delegate.onStart && this.props.delegate.showSelectDuration(selectedChapters);
            // }

        });

    }




    renderMyAssessmentsChapterList() {
        // console.log(this.state.userInfo)
        return (
            <div className="chapters-list">
                {this.state.loading && <Loading />}
                {this.state.chapterList.length && this.props.type != 'HOME_COMPONENT' ? <div className="select-chapter mb-2">Select a Chapter</div> : ''}

                {!this.state.loading &&
                    <div className="mt-3">
                        {
                            this.state.chapterList.map((chapter, index) => (
                                <div className={`chapter-card mb-1 cursor-pointer ${this.state.selectedChapterIndex === index ? 'enable' : ''}`}
                                    onClick={() => this.start(chapter, index)}
                                    style={{ backgroundColor: chapter.isLocked || (index > 1 && this.state.userInfo && !this.state.userInfo.isSubscribed) ? 'rgb(246, 245, 245)' : '' }}
                                >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-inline">
                                            <img className="chapter-icon cursor-pointer" src={chapter.chapterImage ? chapter.chapterImage : getImageSource(ImageConstants.CHAPTER_ICN)} />
                                        </div>

                                        <div className="d-inline-flex flex-column"  >
                                            <span className="chapter-title cursor-pointer ml-3" >{chapter.title}</span>
                                            {/* <span className="pl-3 chapter-sub-title" >{chapter.chapterSubTitle}</span> */}
                                        </div>

                                    </div>
                                    {
                                        chapter.isLocked || (index > 1 && this.state.userInfo && !this.state.userInfo.isSubscribed) ?
                                            <div className=''>
                                                <img className='lock-icon-size mr-3' src={getImageSource(ImageConstants.LOCK)} />
                                            </div>
                                            :
                                            <div>
                                                <img className='arrow-image-size' src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                                            </div>
                                    }
                                    {/* <div >
                                        <div>
                                            <img className='arrow-gray cursor-pointer' src={getImageSource(ImageConstants.RIGHT_ARROW_BLUE)} onClick={() => this.showChapterDetails(chapter, index)} />
                                        </div>

                                    </div> */}
                                </div>
                            ))
                        }

                        {/* <div className="d-flex justify-content-center">
                            {
                                this.state.selectedChapters.length ?
                                    <div className="text-center continue-button cursor-pointer" onClick={() => this.start()}>
                                        Continue
                                    </div>
                                    :
                                    <div className="text-center continue-button continue-button-disabled">
                                        Continue
                                    </div>

                            }

                        </div> */}

                        {/* <img className="footer-image-size" src={getImageSource(ImageConstants.NEW_FOOTER)} /> */}

                    </div>
                }


            </div>
        )
    }

    render() {


        if (this.props.type === "MY_ASSESSMENTS" || this.props.type === 'HOME_COMPONENT') {
            return (
                <>{this.renderMyAssessmentsChapterList()}</>
            )
        }

        return (
            <div className="chapters-list">
                {this.state.loading && <Loading />}
                {this.state.chapterList.length ? <div className="select-chapter mb-3">Select a chapter</div> : ''}
                <div className="">
                    {
                        this.state.chapterList.map((chapter, index) => (

                            <div className={`chapter-card mb-1 cursor-pointer ${this.state.selectedChapterIndex === index ? 'enable' : ''}`} onClick={() => this.showChapterDetails(chapter, index)}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-inline">
                                        <img className="chapter-icon cursor-pointer" src={chapter.chapterImage ? chapter.chapterImage : getImageSource(ImageConstants.CHAPTER_ICN)} />
                                    </div>

                                    <div className="d-inline-flex flex-column">
                                        <span className="chapter-title cursor-pointer ml-3" onClick={() => this.showChapterDetails(chapter, index)} >{chapter.title}</span>
                                        {/* <span className="pl-3 chapter-sub-title">{chapter.chapterSubTitle}</span> */}
                                    </div>
                                </div>
                                <div>
                                    <img className='arrow-gray cursor-pointer' src={getImageSource(ImageConstants.RIGHT_ARROW_BLUE)} onClick={() => this.showChapterDetails(chapter, index)} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }


}

export default ChapterList
import React, { Component } from 'react';
import './two-step-event-result-screen.scss';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import { ImageConstants, Constants, FirebaseEvents } from '../../../constants/constants';
import Loading from '../../common/loading/loading';
import SharedStaticText from '../../../../shared-storage/static-text-store';
import SharedProfile from '../../../../shared-storage/profile';
import CommonUtils from '../../../utility/common-utilities';
import Parser from '../../common/content-parser/custom-parser';
import AsyncStore from '../../../../shared-storage/async-store';
import FireBaseEvent from '../../../firebase-events/firebase-events';
import EventApi from '../../../apis/event';
import CommonApi from '../../../apis/common';
import Moment from 'moment-timezone';
import AssessmentViewDetailedReport from '../assessment-report/assessment-report';
import TwoStepEventLeaderboard from '../two-step-event-leaderboard/two-step-event-leaderboard';
import Path from '../../routes/routes-path';


export default class TwoStepEventResultScreen extends Component {
    constructor(props) {
        super(props);
        // const { navigation } = this.props;
        // this.meta = navigation.getParam('meta');
        console.log(this.props.meta)
        this.meta = this.props.meta


        this.staticText = SharedStaticText.getStaticText('scollarshipStartScreen');
        this.crossLinkStaticText = SharedStaticText.getStaticText('crossLinking');

        if (this.meta && this.meta.event && this.meta.event.staticText && this.meta.event.staticText.eventResultScreen) {
            this.staticText = this.meta.event.staticText.eventResultScreen
        }

        if (this.meta && this.meta.event && this.meta.event.staticText && this.meta.event.staticText.winnersStaticText) {
            this.sampleData = this.meta.event.staticText.winnersStaticText;
        }

        if (this.meta && this.meta.event) {
            this.state.event = this.meta.event
            this.state.winnersMeta = this.meta.event.winnersMeta
        }

        if (this.meta && this.meta.event && this.meta.event.isSecondAttemptUser) {
            this.state.twoStepEventWildCardUser = true
        }

        this.getCrossLinkingContents()
        this.getWinnersMeta()

        this.userInfo = SharedProfile.getProfile();
        this.state.loading = true
        this.parser = new Parser();
    }

    state = {
        isStarted: false,
        remaningTime: 0,
        loading: true,
        collapseButtons: true,
        tip: '',
        event: {
            event: {
                status: 'COMPLETED'
            }
        },
        winnersGenerated: true,
        winnersMeta: {
            award: {
                cashPrice: 500,
                discount: 80,
                rank: 5
            }
        }
    }

    // sampleData = {
    //     topRankHeader: `<p><strong>Congratulations <%name%>, You got <%rank%> rank in India Maths Day Final Test.</strong></p>`,
    //     otherRankers: `<p><strong>Congratulations <%name%>, You are among Top <%topRank%> in Ramanujam Scholarship.</strong></p>`,
    //     cashPriceText: [
    //         {
    //             "msg": "You won a cash prize of Rs <%amount%> & a medal ",
    //             "style": "text"
    //         },
    //         {
    //             "msg": "Claim Cash Prize ",
    //             "style": "link",
    //             "url": "https://www.countingwell.com"
    //         }
    //     ],
    //     discountPriceText: [
    //         {
    //             "msg": "You got an additional prize of  <%percentage%>% Off on Premium Subscription. Click to Grab the discount ",
    //             "style": "text"
    //         },
    //         {
    //             "msg": "<%link%> ",
    //             "style": "link",
    //             "url": "<%link%>"
    //         }
    //     ],
    //     otherRankersText: [
    //         {
    //             "msg": "You got an additional prize of <%percentage%>% Off on Premium Subscription. Click to Grab the discount ",
    //             "style": "text"
    //         },
    //         {
    //             "msg": "<%link%> ",
    //             "style": "link",
    //             "url": "<%link%>"
    //         }
    //     ]

    // }

    getWinnersMeta = () => {

        let payload = {
            eventId: this.meta.event.eventId
        }

        EventApi.getTwoStepEventWinners(payload).then((data) => {
            console.log('winnersData', data)
            this.setState({ winnersMeta: data, loading: false })
        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
                console.log('err', err)
                return
            }
        })
    }

    componentDidMount() {
        this.setState({ showConfetti: true }, () => {
            setTimeout(() => { this.setState({ showConfetti: false }) }, 3000);
        });
    }

    parseWinnersData = (msg, obj, style) => {

        let jsx = [];
        for (let i = 0; i < msg.length; i++) {

            if (msg[i].msg)
                msg[i].msg = CommonUtils.simpleTemplateEngine(msg[i].msg, obj)

            if (msg[i].url)
                msg[i].url = CommonUtils.simpleTemplateEngine(msg[i].url, obj)

            switch (msg[i].style) {
                case 'text': jsx.push(<div style={style.text}>{msg[i].msg}</div>); break;
                case 'bold': jsx.push(<div style={style.strong}>{msg[i].msg}</div>); break;
                // case 'link': jsx.push(<Text onPress={() => Linking.openURL(msg[i].url)} style={style.link}>{msg[i].msg}</Text>); break;
            }
        }

        return <div>{jsx}</div>
    }


    closeModal = () => {

        if (this.meta && this.meta.origin && this.meta.origin == 'RewardScreen') {
            this.props.navigation.navigate('RewardEventLandingScreen', { meta: { ...this.meta } })
        } else {
            this.props.navigation.goBack();
        }

    }

    onClickLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    onClickHelp = () => {

        let help = this.state.event && this.state.event.help ? this.state.event.help : ""

        if (!help) {
            return
        }

        this.props.navigation.navigate('ScholarshipEventHelp', { meta: { help: help } })

    }

    onClickClaimCashPrize = async (url) => {

        if (!url) {
            return
        }

        if (this.staticText && !this.staticText.cashPrizeVideoUrl) {
            window.open(url)
            return
        }

        let doc = await AsyncStore.getEventScholarshipClaimVideo()

        if (doc) {
            doc = JSON.parse(doc)

            console.log(doc, 'cashDoc')

            if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${this.state.event.title}_CASH_PRIZE`]) {

                doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${this.state.event.title}_CASH_PRIZE`] = true

                AsyncStore.setEventScholarshipClaimVideo(doc)

                // moengage.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'No' })
                FireBaseEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'No' })
                // Facebook.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'No' })
                // BranchIO.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'No' })

                this.props.navigation.navigate('EventVideo', { meta: { videoUrl: this.staticText.cashPrizeVideoUrl, claimUrl: url, type: 'CASH_PRIZE', event: this.state.event } })
                // Linking.openURL(url)

            } else {
                // moengage.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'Yes' })
                FireBaseEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'Yes' })
                // Facebook.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'Yes' })
                // BranchIO.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'Yes' })
                window.open(url)
            }
        } else {
            AsyncStore.setEventScholarshipClaimVideo({ [`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${this.state.event.title}_CASH_PRIZE`]: true })
            // moengage.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'No' })
            FireBaseEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'No' })
            // Facebook.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'No' })
            // BranchIO.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Cash Prize', 'Viewed Video': 'No' })
            this.props.navigation.navigate('EventVideo', { meta: { videoUrl: this.staticText.cashPrizeVideoUrl, claimUrl: url, type: 'CASH_PRIZE', event: this.state.event } })

        }
    }

    onClickClaimScholarship = async (url) => {



        if (!url) {
            return
        }

        if (this.staticText && !this.staticText.scholarshipVideoUrl) {
            window.open(url)
            return
        }

        let doc = await AsyncStore.getEventScholarshipClaimVideo()

        if (doc) {
            doc = JSON.parse(doc)

            if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${this.state.event.title}_SCHOLARSHIP`]) {

                doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${this.state.event.title}_SCHOLARSHIP`] = true

                AsyncStore.setEventScholarshipClaimVideo(doc)
                // moengage.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'No' })
                FireBaseEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'No' })
                // Facebook.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'No' })
                // BranchIO.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'No' })
                this.props.navigation.navigate('EventVideo', { meta: { videoUrl: this.staticText.scholarshipVideoUrl, claimUrl: url, type: 'SCHOLARSHIP', event: this.state.event } })

            } else {
                // moengage.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'Yes' })
                FireBaseEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'Yes' })
                // Facebook.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'Yes' })
                // BranchIO.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'Yes' })
                window.open(url)
            }
        } else {
            AsyncStore.setEventScholarshipClaimVideo({ [`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${this.state.event.title}_SCHOLARSHIP`]: true })
            // moengage.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'No' })
            FireBaseEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'No' })
            // Facebook.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'No' })
            // BranchIO.logCustomEvent('Claim Scholarship', { 'User Id': this.userInfo.userId, type: 'Discount', 'Viewed Video': 'No' })
            this.props.navigation.navigate('EventVideo', { meta: { videoUrl: this.staticText.scholarshipVideoUrl, claimUrl: url, type: 'SCHOLARSHIP', event: this.state.event } })

        }
    }

    renderWinnnersCard = () => {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let bgColor = `linear-gradient(180deg,#F1EDFF 0%,#FFFFFF 100%)`

        let eventCardStaticText = this.state.event && this.state.event.staticText && this.state.event.staticText.eventCard ? this.state.event.staticText.eventCard : {}

        if (this.state.event &&
            !this.state.twoStepEventWildCardUser &&
            this.state.winnersGenerated &&
            this.state.event['event'].status == 'COMPLETED' &&
            this.state.winnersMeta && this.state.winnersMeta.award && this.state.winnersMeta.award.cashPrice && this.state.winnersMeta.award.discount) {
            return (
                <>
                    <div className='offer-card-header-container d-flex mt-3' style={{ backgroundImage: bgColor }}>
                        {/* <img className='offer-card-bg' src={getImageSource(ImageConstants.EVENT_OFFER_CARD_BG)} /> */}
                        <div>
                            <img className='gift-image-size' src={getImageSource(ImageConstants.GIFT_BOX)} />
                        </div>
                        <div className='ml-3'>
                            <div>
                                {this.state.winnersMeta.isWinner ? this.parser.parseWithStyles(CommonUtils.simpleTemplateEngine(this.sampleData.topRankHeader, {
                                    name: this.userInfo.name,
                                    rank: this.state.winnersMeta.rank + (
                                        this.state.winnersMeta.rank == 1 ? 'st' :
                                            this.state.winnersMeta.rank == 2 ? 'nd' : this.state.winnersMeta.rank == 3 ? 'rd' : 'th'),
                                    winnersType: this.state.winnersMeta && this.state.winnersMeta.award && this.state.winnersMeta.award.typeText ? this.state.winnersMeta.award.typeText : ""
                                }), { p: styles.p, strong: { fontSize: '22px', fontWeight: '800' } })
                                    :
                                    <></>}

                                {this.state.winnersMeta.isRunnerWithCashPrize ?
                                    this.parser.parseWithStyles(CommonUtils.simpleTemplateEngine(this.sampleData.runnersWithCashPrizeText, {
                                        name: this.userInfo.name,
                                        position: this.state.winnersMeta?.award?.displayValue ? `${this.state.winnersMeta?.award?.displayValue}` : 0
                                    }), { p: styles.p, strong: styles.p })
                                    :
                                    <></>
                                }

                                {this.state.winnersMeta.isRunner ?
                                    this.parser.parseWithStyles(CommonUtils.simpleTemplateEngine(this.sampleData.runnersText, {
                                        name: this.userInfo.name,
                                        topRank: this.state.winnersMeta?.award?.displayValue ? `${this.state.winnersMeta?.award?.displayValue}` : 0
                                    }), { p: styles.p, strong: styles.p })
                                    :
                                    <></>
                                }

                                {/* {this.state.winnersMeta && this.state.winnersMeta.award && this.state.winnersMeta.award.cashPrice ? */}
                                {this.parseWinnersData(this.sampleData.cashPriceText, {
                                    amount: this.state.winnersMeta && this.state.winnersMeta.award && this.state.winnersMeta.award.cashPrice ? this.state.winnersMeta.award.cashPrice.amount : "",
                                    percentage: this.state.winnersMeta && this.state.winnersMeta.award && this.state.winnersMeta.award.discount ? this.state.winnersMeta.award.discount.value : ""
                                }, { text: styles.p, strong: styles.p, link: styles.p })}
                                {/* : 
                                this.parseWinnersData(this.sampleData.discountPriceText, {
                                    percentage:this.state.winnersMeta && this.state.winnersMeta.award && this.state.winnersMeta.award.discount ? this.state.winnersMeta.award.discount.value : ""
                                }, { text: Styles.offersHeaderNormal, strong: Styles.offersHeader, link: Styles.link })
                                } */}

                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-around mt-3'>
                        <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#6B52C8" }} onClick={() => this.onClickClaimScholarship(this.state.winnersMeta?.discountLink ? this.state.winnersMeta?.discountLink : eventCardStaticText.claimScholarshipUrl)}>
                            <div>{this.staticText.claimScholarshipButtonText ? this.staticText.claimScholarshipButtonText : 'CLAIM YOUR SCHOLARSHIP'}</div>
                        </div>
                        {this.state.winnersMeta.award.cashPrice ?
                            <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#06C5D9" }} onClick={() => this.onClickClaimCashPrize(eventCardStaticText.claimCashPriceUrl)} >
                                <div>
                                    <div>{this.staticText.claimCashPrizeButtonText ? this.staticText.claimCashPrizeButtonText : 'CLAIM YOUR CASH PRIZE'}</div>
                                </div>
                            </div>
                            :
                            <></>
                        }
                        <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#FFC928" }} onClick={this.viewCertificate}>
                            <div>{this.staticText.certificateButtonTitle ? this.staticText.certificateButtonTitle : 'MY CERTIFICATE'}</div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-around mt-4'>
                        {!this.state.event.isSecondAttemptUser ?
                            <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#FF9501" }} onClick={this.navigateToScoreBoard}>
                                <div>{this.staticText.leaderboardButtonTitle ? this.staticText.leaderboardButtonTitle : 'TOP 3 WINNERS'}</div>
                            </div>
                            :
                            <></>
                        }

                        {this.meta && this.meta.event && this.meta.event.hideReportButtonAfterResult ?
                            <></>
                            :
                            <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#6B52C8" }} onClick={this.viewReport}>
                                <div>{this.staticText.reportButtonTitle ? this.staticText.reportButtonTitle : 'MY PERFORMANCE'}</div>
                            </div>
                        }

                        {this.state.event && this.state.event.showSocialMediaSharing ?
                            <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#4E79F6" }} onClick={this.onPressSocialMedia}>

                                {/* <View style={{ flexDirection: 'row', alignitems: 'center', justifyContent: 'center', marginRight: 8 }}>
                                <Image source={getImageSource(ImageConstants.WHATSAPP)} style={Styles.socialMediaIcons}></Image>
                                <Image source={getImageSource(ImageConstants.INSTAGRAM)} style={Styles.socialMediaIcons}></Image>
                                <Image source={getImageSource(ImageConstants.FACEBOOK)} style={Styles.socialMediaIcons}></Image>
                            </View> */}

                                <div>{this.staticText && this.staticText.shareButtonText ? this.staticText.shareButtonText : 'SHARE ACHIEVEMENT'}</div>

                            </div>
                            :
                            <></>
                        }
                    </div>
                </>
            )
        } else if (this.state.event
            && this.state.winnersGenerated &&
            this.state.event['event'].status == 'COMPLETED' &&
            this.state.winnersMeta && this.state.winnersMeta.award && this.state.winnersMeta.award.discount && !this.state.winnersMeta.discountConsumed) {
            return (
                <>
                    <div>

                        {/* <Image source={getImageSource(ImageConstants.OTHER_RANKERS_BG)} style={[Styles.otherRanks]} resizeMode={'contain'} /> */}

                        <div className='offer-card-header-container d-flex mt-3'>
                            <div>
                                <img className='gift-image-size' src={getImageSource(ImageConstants.GIFT_BOX)} />
                            </div>
                            <div>
                                <div>
                                    {!this.state.twoStepEventWildCardUser && this.state.winnersMeta.isRunner ?
                                        this.parser.parseWithStyles(CommonUtils.simpleTemplateEngine(this.sampleData.runnersText, {
                                            name: this.userInfo.name,
                                            position: this.state.winnersMeta?.award?.displayValue ? `${this.state.winnersMeta?.award?.displayValue}` : 0
                                        }), { p: styles.p, strong: { fontSize: '22px', fontWeight: '800' } })
                                        :
                                        <></>
                                    }

                                    {this.state.twoStepEventWildCardUser && this.state.winnersMeta.isRunner ?
                                        this.parser.parseWithStyles(CommonUtils.simpleTemplateEngine(this.sampleData.wildCardText, {
                                            name: this.userInfo.name,
                                            position: this.state.winnersMeta?.award?.displayValue ? `${this.state.winnersMeta?.award?.displayValue}` : 0
                                        }), { p: styles.p, strong: styles.p })
                                        :
                                        <></>
                                    }

                                </div>

                                {
                                    this.parseWinnersData(this.sampleData.discountPriceText, {
                                        percentage: this.state.winnersMeta && this.state.winnersMeta.award && this.state.winnersMeta.award.discount ? this.state.winnersMeta.award.discount.value : ""
                                    }, { text: styles.p, strong: styles.p, link: styles.p })
                                }


                            </div>
                        </div>


                        <div className='d-flex justify-content-around mt-3'>
                            <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#6B52C8" }} onClick={() => this.onClickClaimScholarship(this.state.winnersMeta?.discountLink ? this.state.winnersMeta?.discountLink : eventCardStaticText.claimScholarshipUrl)}>
                                <div>{this.staticText.claimScholarshipButtonText ? this.staticText.claimScholarshipButtonText : 'CLAIM YOUR SCHOLARSHIP'}</div>
                            </div>
                            <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#FFC928" }} onClick={this.viewCertificate}>
                                <div>{this.staticText.certificateButtonTitle ? this.staticText.certificateButtonTitle : 'MY CERTIFICATE'}</div>
                            </div>
                            {!this.state.event.isSecondAttemptUser ?
                                <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#FF9501" }} onClick={this.navigateToScoreBoard}>
                                    <div>{this.staticText.leaderboardButtonTitle ? this.staticText.leaderboardButtonTitle : 'TOP 3 WINNERS'}</div>
                                </div>
                                :
                                <></>
                            }
                        </div>

                        <div className='d-flex justify-content-around mt-4'>


                            {this.meta && this.meta.event && this.meta.event.hideReportButtonAfterResult ?
                                <></>
                                :
                                <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#6B52C8" }} onClick={this.viewReport}>
                                    <div>{this.staticText.reportButtonTitle ? this.staticText.reportButtonTitle : 'MY PERFORMANCE'}</div>
                                </div>
                            }

                            {this.state.event && this.state.event.showSocialMediaSharing ?
                                <div className='button-wrapper text-center cursor-pointer' style={{ "background": "#4E79F6" }} onClick={this.onPressSocialMedia}>

                                    {/* <View style={{ flexDirection: 'row', alignitems: 'center', justifyContent: 'center', marginRight: 8 }}>
                                <Image source={getImageSource(ImageConstants.WHATSAPP)} style={Styles.socialMediaIcons}></Image>
                                <Image source={getImageSource(ImageConstants.INSTAGRAM)} style={Styles.socialMediaIcons}></Image>
                                <Image source={getImageSource(ImageConstants.FACEBOOK)} style={Styles.socialMediaIcons}></Image>
                            </View> */}

                                    <div>{this.staticText && this.staticText.shareButtonText ? this.staticText.shareButtonText : 'SHARE ACHIEVEMENT'}</div>

                                </div>
                                :
                                <></>
                            }
                            <div style={{ width: "20rem" }}></div>
                        </div>

                        {/* <TouchableOpacity style={Styles.claimScholarshipButton} onPress={() => this.onClickClaimScholarship(this.state.winnersMeta?.discountLink ? this.state.winnersMeta?.discountLink : eventCardStaticText.claimScholarshipUrl)}>
                            <Text style={Styles.answerButtonText}>{this.staticText.claimScholarshipButtonText ? this.staticText.claimScholarshipButtonText : 'CLAIM YOUR SCHOLARSHIP'}</Text>
                        </TouchableOpacity> */}

                    </div>

                </>
            )
        }
    }

    toggleButtons = () => {
        this.setState({ collapseButtons: !this.state.collapseButtons })
    }

    getCrossLinkingContents = () => {


        CommonApi.getMiscellaneousList({ type: 'CROSS_LINKING_CONTENTS' }).then((data) => {
            if (data) {
                let res = data.response ? data.response : [];

                let contentList = res.length ? res[0] : {};

                this.setState({ list: contentList.content }, () => {
                    this.getContent();
                })
            }
        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
                return
            };
        })
    }

    async getContent() {
        let doc = await AsyncStore.getLastCrossLinkStored();

        let index = 1

        if (doc) {
            doc = JSON.parse(doc)

            if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`]) {

                doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`] = 2

                AsyncStore.setLastCrossLinkStored(doc)

                index = 1

            } else {

                let lastShowedIndex = doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`]

                if (lastShowedIndex == this.state.list.length) {
                    index = 0
                    doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`] = 1
                } else {
                    index = lastShowedIndex
                    doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`] = lastShowedIndex + 1
                }

                AsyncStore.setLastCrossLinkStored(doc)
            }
        } else {
            AsyncStore.setLastCrossLinkStored({ [`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`]: 2 })
            index = 1
            this.setState({ showBookMarkHelp: true })
        }

        let content = this.state.list[index]
        this.setState({ content })
    }

    navigate = (navigation, type) => {

        console.log(navigation, type)

        switch (type) {

            case "WORKOUT":
                this.getWorkoutStatus(navigation);
                break;

            case 'BROWSE CHAPTER':
                this.navigateToChapterList();
                break;

            case 'PRACTICE':
                this.onClickPractice();
                break;

        }

    }

    navigateToChapterList = () => {

        FireBaseEvent(FirebaseEvents.CROSS_BROWSE_CHAPTER, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // Facebook.logCustomEvent(FirebaseEvents.CROSS_BROWSE_CHAPTER, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // BranchIO.logCustomEvent(FirebaseEvents.CROSS_BROWSE_CHAPTER, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // moengage.logCustomEvent('CROSS_BROWSE_CHAPTER', { 'User Id': this.userInfo.userId, 'Source': 'Result Screen' })

        // this.props.navigation.navigate('Chapters', { meta: { fromCrossLink: true } });
        this.props.delegate.props.history.push(Path.BROWSE_CHAPTER)

    }

    onClickPractice = () => {

        FireBaseEvent(FirebaseEvents.CROSS_PRACTICE, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // Facebook.logCustomEvent(FirebaseEvents.CROSS_PRACTICE, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // BranchIO.logCustomEvent(FirebaseEvents.CROSS_PRACTICE, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // moengage.logCustomEvent('CROSS_PRACTICE', { 'User Id': this.userInfo.userId, 'Source': 'Result Screen' })

        // this.setState({ showSelectPracticeChapter: true })
        this.props.delegate.props.history.push(Path.PRACTICE_QUESTIONS)
    }

    hideSelectPracticeChapter = () => {
        this.setState({ showSelectPracticeChapter: false })
    }

    getWorkoutStatus = (navigation, callback) => {

        FireBaseEvent(FirebaseEvents.CROSS_WORKOUT_STARTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // Facebook.logCustomEvent(FirebaseEvents.CROSS_WORKOUT_STARTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // BranchIO.logCustomEvent(FirebaseEvents.CROSS_WORKOUT_STARTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // moengage.logCustomEvent('CROSS_WORKOUT', { 'User Id': this.userInfo.userId, 'Source': 'Result Screen' })

        // CommonApi.getSubscribedWorkoutStatus({}, (err, response) => {

        //     if (err) {
        //         callback && callback()
        //         return
        //     }

        //     callback && callback()

        //     let status = response.status

        //     if (status == 'COMPLETED') {
        //         this.props.navigation.navigate('LandingScreen')
        //         return
        //     }

        //     if (status == 'SETUP') {
        //         navigation.navigate('WorkoutSetupScreen');
        //     }

        //     if (status == 'START' || status == 'COMPLETED') {
        //         navigation.navigate('WorkoutStartScreenSubscribed');
        //     }

        //     if (status == 'RESUME') {
        //         let workout = response

        //         let nodes = workout.nodes.map(node => ({ ...node, id: node._id }))

        //         let resumeNodeStatus = ''

        //         for (let i = 0; i < workout.nodes.length; i++) {
        //             if (workout.nodes[i]._id == workout.resumeNodeId) {
        //                 resumeNodeStatus = workout.nodes[i].status
        //                 break;
        //             }
        //         }

        //         navigation.navigate('WorkoutExperience', {
        //             params: {
        //                 nodeId: workout.resumeNodeId, eloIds: [], nodes: nodes, title: workout.chapterTitle, chapterKey: workout.chapterKey, categoryThemeId: workout.workoutThemeId,
        //                 isChapterNotCompleted: workout.isChapterNotCompletedFlow, conceptListMeta: {}, masterDataId: null, workoutId: workout.id, totalDuration: workout.totalDuration ? workout.totalDuration : 0,
        //                 isSubscribedFlow: true, status: resumeNodeStatus ? resumeNodeStatus : ''
        //             }
        //         });
        //     }
        // });
    }



    toggleLoading(value) {
        this.setState({ loading: value })
    }

    getServerTime = (item, payload = {}) => {


        CommonApi.getServerTime(payload).then((data) => {
            if (data && data.time && item.viewLeaderboardTime) {

                if (new Date(data.time) >= new Date(item.viewLeaderboardTime)) {

                    this.props.navigation.navigate('MPLScoreBoard', { meta: { qualifierEventPosition: item.qualifierEventPosition, eventId: item.eventId, assessmentId: item.assessmentId, eventType: item.type === Constants.FINAL_EVENT ? Constants.FINAL_EVENT : Constants.QUALIFIER_EVENT, type: Constants.MPL_EVENT, from: Constants.MPL_EVENT, title: item.title, mplStaticText: this.meta.mplStaticText } })

                } else {

                    this.viewAnswerTime = '';

                    if (this.userInfo.timeZone) {

                        let convetedTime = Moment.tz(item.viewLeaderboardTime, this.userInfo.timeZone).format('DD/MM/YYYY, HH:mm:ss')
                        this.viewAnswerTime = convetedTime.split(',')[1]
                    }

                    this.showViewAnswerTimer()
                }
            } else {
                this.props.navigation.navigate('MPLScoreBoard', { meta: { qualifierEventPosition: item.qualifierEventPosition, eventId: item.eventId, assessmentId: item.assessmentId, eventType: item.type === Constants.FINAL_EVENT ? Constants.FINAL_EVENT : Constants.QUALIFIER_EVENT, type: Constants.MPL_EVENT, from: Constants.MPL_EVENT, title: item.title, mplStaticText: this.meta.mplStaticText } })

            }

        }).catch(err => {
            if (err)
                return
        })
    }

    showViewAnswerTimer = () => {
        this.setState({ showViewAnswerTimer: true, selectedType: 'ScoreBoard' })
    }

    hideViewAnswerTimer = () => {
        this.setState({ showViewAnswerTimer: false })
    }


    navigateToScoreBoard = (item) => {


        // this.props.navigation.navigate('TwoStepEventLeaderboard', 
        //     { meta: { eventId: this.state.event.eventId, assessmentId: this.state.event['event'].activity._id, eventType: this.state.event.eventId, type: Constants.TWO_STEP_EVENT_FINAL, from: Constants.TWO_STEP_EVENT_FINAL, title: this.meta.title ? this.meta.title : '', staticText: this.state.event.staticText, eventPayload: this.state.event } })

        this.setState({
            showLeaderboard: true,
            leaderBoardMeta: {
                eventId: this.state.event.eventId,
                assessmentId: this.state.event['event'].activity._id,
                eventType: this.state.event.eventId,
                type: Constants.TWO_STEP_EVENT_FINAL,
                from: Constants.TWO_STEP_EVENT_FINAL,
                title: this.meta.title ? this.meta.title : '',
                staticText: this.state.event.staticText,
                eventPayload: this.state.event
            }
        })

    }

    hideLeaderboard() {
        this.setState({showLeaderboard : false})
    }

    getCrossLinkImage = (type) => {

        switch (type) {

            case 'WORKOUT':
                return getImageSource(ImageConstants.WORKOUT_CROSS_LINK)

            case 'BROWSE CHAPTER':
                return getImageSource(ImageConstants.BROWSE_CHAPTER_CROSS_LINK)

            case 'PRACTICE':
                return getImageSource(ImageConstants.PRACTICE_CROSS_LINK)

            default:
                return getImageSource(ImageConstants.WORKOUT_CROSS_LINK)
        }
    }

    viewCertificate = () => {


        let winnersMeta = this.state.winnersMeta

        if (!winnersMeta || !winnersMeta.award || !winnersMeta.award.type)
            return

        // moengage.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.event.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Completed', "Learning Status": 'Completed', 'Viewed Baseline Report': getBooleanValue(!this.state.event.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.event.viewedFinishlineReport), 'Viewed Event Certificate': 'Yes' })
        FireBaseEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.event.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Completed', "Learning Status": 'Completed', 'Viewed Baseline Report': CommonUtils.getBooleanValue(!this.state.event.viewedBaselineReport), 'Viewed Finishline Report': CommonUtils.getBooleanValue(!this.state.event.viewedFinishlineReport), 'Viewed Event Certificate': 'Yes' })
        // Facebook.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.event.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Completed', "Learning Status": 'Completed', 'Viewed Baseline Report': getBooleanValue(!this.state.event.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.event.viewedFinishlineReport), 'Viewed Event Certificate': 'Yes' })
        // BranchIO.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.event.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Completed', "Learning Status": 'Completed', 'Viewed Baseline Report': getBooleanValue(!this.state.event.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.event.viewedFinishlineReport), 'Viewed Event Certificate': 'Yes' })

        // this.props.navigation.navigate('ViewCertificate', {
        //     meta: {
        //         type: Constants.TWO_STEP_EVENT_FINAL,
        //         eventId: this.state.event.eventId,
        //         certificateType: this.state.winnersMeta.isWinner ? 'WINNER' : winnersMeta.award.type,
        //         rank: winnersMeta.rank ? winnersMeta.rank : "",
        //         rankUnder: this.state.winnersMeta?.award?.displayValue ? `${this.state.winnersMeta?.award?.displayValue}` : '',
        //         eventPayload: this.state.event


        //     }
        // })

        let payload = {
        }


        // if ((this.meta.type == Constants.REWARD_EVENT_FINAL || this.meta.type == Constants.TWO_STEP_EVENT_FINAL) && this.meta.eventId) {
        payload.type = Constants.SCHOLARSHIP_EVENT
        payload.eventId = this.state.event.eventId
        payload.certificateType = this.state.winnersMeta.isWinner ? 'WINNER' : winnersMeta.award.type
        payload.rank = winnersMeta.rank ? winnersMeta.rank : ""
        payload.rankUnder = this.state.winnersMeta?.award?.displayValue ? `${this.state.winnersMeta?.award?.displayValue}` : ''
        // }
        EventApi.getScolarshipCertificate(payload).then((response) => {
            // this.setState({ url: response.certificateUrl })
            window.open(response.certificateUrl)
        }).catch(err => {
            if (err) {
                return
            }
        })


    }

    viewReport = () => {
        // this.props.navigation.navigate('AssessmentReport', {
        //     meta: {
        //         activityId: this.state.event['event'].activity._id,
        //         eventId: this.state.event.eventId,
        //         type: Constants.TWO_STEP_EVENT_FINAL,
        //         title: this.chapterTitle,
        //         refresherCourseId: this.meta ? this.meta.refresherCourseId : '',
        //         eventThreshold: this.meta ? this.meta.eventThreshold : '',
        //         scholarshipEligibleStaticText: this.meta.scholarshipEligibleStaticText ? this.meta.scholarshipEligibleStaticText : {},
        //         eventPayload: this.state.event
        //     }
        // })


        this.setState(
            {
                showPerformanceReport: true,
                viewDetailReportInfo: {
                    type: Constants.TWO_STEP_EVENT_FINAL,
                    activityId: this.state.event['event'].activity._id,
                    title: this.chapterTitle,
                    eventId: this.state.event.eventId,
                    from: this.meta && this.meta.from ? this.meta.from : '',
                    eventThreshold: this.meta ? this.meta.eventThreshold : '',
                    scholarshipEligibleStaticText: this.meta.scholarshipEligibleStaticText ? this.meta.scholarshipEligibleStaticText : {},
                    eventPayload: this.state.event
                }
            }
        )
    }

    onPressSocialMedia = () => {
        this.props.navigation.navigate('SocialMediaShare', { meta: { mediaUrl: this.state.event && this.state.event.socialMediaAchievementImageUrl ? this.state.event.socialMediaAchievementImageUrl : '', event: this.state.event, fromResultScreen: true } })
    }

    hideResultScreen() {
        this.props.delegate && this.props.delegate.hideTwoStepEventResultScreen && this.props.delegate.hideTwoStepEventResultScreen()
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    hidePerformanceReport() {
        this.setState({ showPerformanceReport: false })
    }


    renderBreadcrumbForKnowMore = () => {
        return (
            <div className='breadcrumb mt-1'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.hideResultScreen()} >{`Events >`}</span>
                <span className='active title-1 pl-2'>{`${this.meta && this.meta?.event?.title}`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }


    render() {



        if (this.state.showPerformanceReport) {
            return <AssessmentViewDetailedReport meta={this.state.viewDetailReportInfo}
                delegate={this} from="RESULT_SCREEN" />
        }

        if (this.state.showLeaderboard) {
            return <TwoStepEventLeaderboard 
            meta = {this.state.leaderBoardMeta} 
            delegate = {this}
            from="RESULT_SCREEN" />
        }



        return (
            <div className='two-step-event-result-screen'>

                {!this.state.loading ?
                    <div>

                        {/* {this.state.showConfetti ? <Image source={getImageSource(ImageConstants.ELO_COMPLETED_GIF)} style={[Styles.levelCompleted, Platform.OS == 'android' ? Styles.elevationForGif : {}]}></Image> : <></>} */}

                        {/* <Image source={getThemeImageSource(ImageConstants.LEARNING_CONTENT_HEADER, this.state.categoryId)} resizeMode="stretch" style={Styles.headerContainerImage} /> */}

                        {this.renderBreadcrumbForKnowMore()}

                        <div >

                            <div className='header-text'>
                                {this.staticText && this.staticText.headerText ? CommonUtils.simpleTemplateEngine(this.staticText.headerText, { name: this.userInfo.name }) : `Congratulation ${this.userInfo.name}!`}
                            </div>

                            <div className='text-center mt-1'>
                                <img className='question-divider-image-width' style={{ width: '98%', marginLeft: '-20px' }} src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, 1)} />
                            </div>

                            {/* {this.state.collapseButtons ? <View style={Styles.percentageCard}>
                                <View style={Styles.percentageContainer}>
                                    {this.state.twoStepEventWildCardUser ?
                                        <View style={Styles.wildCard}></View> :

                                        this.state.winnersMeta.isWinner ?
                                            <View style={{ flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'center' }}>
                                                <Text style={Styles.rank1}>1</Text>
                                                <Text style={Styles.rankSuffix}>st</Text>
                                            </View>
                                            :
                                            <View>
                                                <Text style={this.state.isTopFive ? Styles.percentageText2 : Styles.percentageText1}>TOP</Text>
                                                {this.state.winnersMeta.isWinner ? <Text style={Styles.percentageText2}>{this.state.winnersMeta?.award?.displayValue ? this.state.winnersMeta?.award?.displayValue : 0}</Text> : <Text style={Styles.percentageText1}>{this.state.winnersMeta?.award?.displayValue ? `${this.state.winnersMeta?.award?.displayValue}` : 0}</Text>}
                                            </View>}
                                   
                                </View>
                                <Image style={Styles.bg} source={getImageSource(ImageConstants.WINNERS_BG)} />

                            </View>
                                :
                                <></>
                            } */}

                            {this.renderWinnnersCard()}

                            {/* <TouchableOpacity onPress={this.toggleButtons} style={[Styles.toggleContainer, !this.state.collapseButtons ? {} : { marginBottom: 20 }]}>
                                {this.state.collapseButtons ?
                                    <Text style={Styles.toggleText}>{this.staticText.viewMoreButtonText ? this.staticText.viewMoreButtonText : 'VIEW MORE'}</Text>
                                    :
                                    <Text style={Styles.toggleText}>{this.staticText.viewLessButtonText ? this.staticText.viewLessButtonText : 'VIEW LESS'}</Text>
                                }
                                {this.state.collapseButtons ?
                                    <View style={{ transform: [{ rotate: "180deg" }], marginLeft: 10 }}><Image source={getImageSource(ImageConstants.TOGGLE_CARET_BLUE)}></Image></View>
                                    :
                                    <Image source={getImageSource(ImageConstants.TOGGLE_CARET_BLUE)} style={{ marginLeft: 10 }}></Image>
                                }
                            </TouchableOpacity> */}

                            {/* {!this.state.collapseButtons ?
                                <>
                                    <View style={Styles.buttonContainer}>
                                        <TouchableOpacity style={Styles.performanceButton} onPress={this.viewCertificate}>
                                            <Text style={Styles.answerButtonText}>{this.staticText.certificateButtonTitle ? this.staticText.certificateButtonTitle : 'MY CERTIFICATE'}</Text>
                                        </TouchableOpacity>

                                        {!this.state.event.isSecondAttemptUser ? <TouchableOpacity style={Styles.leaderBoardButton} onPress={this.navigateToScoreBoard}>
                                            <Text style={Styles.answerButtonText}>{this.staticText.leaderboardButtonTitle ? this.staticText.leaderboardButtonTitle : 'TOP 3 WINNERS'}</Text>
                                        </TouchableOpacity>
                                            :
                                            <></>
                                        }

                                        {this.meta && this.meta.event && this.meta.event.hideReportButtonAfterResult ? <></> : <TouchableOpacity style={Styles.claimScholarshipButton} onPress={this.viewReport}>
                                            <Text style={Styles.answerButtonText}>{this.staticText.reportButtonTitle ? this.staticText.reportButtonTitle : 'MY PERFORMANCE'}</Text>
                                        </TouchableOpacity>}

                                        {this.state.event && this.state.event.showSocialMediaSharing ? <TouchableOpacity style={Styles.socialMediaButton} onPress={this.onPressSocialMedia}>

                                            <View style={{ flexDirection: 'row', alignitems: 'center', justifyContent: 'center', marginRight: 8 }}>
                                                <Image source={getImageSource(ImageConstants.WHATSAPP)} style={Styles.socialMediaIcons}></Image>
                                                <Image source={getImageSource(ImageConstants.INSTAGRAM)} style={Styles.socialMediaIcons}></Image>
                                                <Image source={getImageSource(ImageConstants.FACEBOOK)} style={Styles.socialMediaIcons}></Image>
                                            </View>

                                            <Text style={Styles.answerButtonText}>{this.staticText && this.staticText.shareButtonText ? this.staticText.shareButtonText : 'SHARE ACHIEVEMENT'}</Text>

                                        </TouchableOpacity>
                                            :
                                            <></>
                                        }

                                    </View>
                                </>
                                :
                                <></>
                            } */}

                        </div>

                        

                        <div className='text-center mt-3'>
                            <img className='question-divider-image-width' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, 1)} />
                        </div>

                        {this.state.content ?
                            <div className="">
                                <div className="mt-3 d-flex align-items-center">
                                    <img className="bulb-image mr-3" src={getImageSource(ImageConstants.YELLOW_BULB_IMAGE)} />
                                    <span className="did-you-know-text d-flex align-items-center">{this.crossLinkStaticText.didYouKnowText ? this.crossLinkStaticText.didYouKnowText : "Did you know ?"}</span>
                                </div>

                                <div className="row m-0 p-0 mt-4 cross-detail-wrapper align-items-center cursor-pointer" onClick={() => this.navigate(this.props.navigation, this.state.content.type)} style={{ backgroundColor: this.state.content.backgroundColor ? this.state.content.backgroundColor : '#F7F5FF' }}>
                                    <div className="col-2">
                                        <img className="cross-linking-image" src={this.getCrossLinkImage(this.state.content.type)}></img>
                                    </div>
                                    <div className="col">
                                        <div className="description-text">{this.state.content.description}</div>
                                        <div className="mt-3 practice-button d-flex justify-content-center" style={{ background: this.state.content.buttonColor ? this.state.content.buttonColor : '#6B52C8' }}>{this.state.content.buttonTitle}</div>
                                    </div>

                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>

                    : <Loading loading={this.state.loading} />
                }
                {/* {this.state.showSelectPracticeChapter && <SelectPracticeChapter delegate={this} modal={this.state.showSelectPracticeChapter} navigation={this.props.navigation} />} */}


            </div>

        )
    }
}
import React from "react";
import './signup.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants, Constants, FirebaseEvents } from "../../../constants/constants";
import SharedStaticText from "../../../../shared-storage/static-text-store";
import VideoPlayer from 'react-video-js-player';
import CommonUtils from "../../../utility/common-utilities";
import CommonApi from "../../../apis/common";
import CityModal from "../../common/city-modal/city-modal";
import CryptoJS from "crypto-js";
import AsyncStore from "../../../../shared-storage/async-store";
import AuthenticateAPI from "../../../apis/authenticate";
import CwConfirmationModal from "../../common/cw-confirmation-modal/cw-confirmation-modal";
import OTP from "../otp/otp";
import CustomToaster from "../../common/custom-toaster/custom-toaster";
import Passcode from "../passcode/passcode";
import SharedProfile from "../../../../shared-storage/profile";
import PillButtonSelect from '../../common/pill-button-select/pill-button-select';
import ClassDetails from "../class-details/class-details";
import SelectDays from "../select-days/select-days";
import SelectTime from "../select-time/select-time";
import DailyGoal from "../select-daily-goal/select-daily-goal";
import SelectLevel from "../select-level/select-level";
import AppIntroVideo from "../intro-video-screen/intro-video-screen";
import Path from "../../routes/routes-path";
import ReactPlayer from 'react-player';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SchoolApi from "../../../apis/school";
import FirebaseEvent from '../../../firebase-events/firebase-events';

class StudentSignup extends React.Component {


    constructor(props) {
        super(props)
        this.welComeStaticText = SharedStaticText.getStaticText('appWelcomeScreen')
        this.staticText = SharedStaticText.getStaticText('studentSignup')

        this.getSignUpKey()
        this.getBoard();
        this.getClass()

        let urlParams = new URLSearchParams(window.location.search);
        this.schoolCode = urlParams.get('schoolCode')

    }

    schoolCode = ''

    coutryCodeHash = {}

    dashes = [
        {
            step: "signupScreen",
            value: 1
        },
        {
            step: "otpScreen",
            value: 2
        },
        {
            step: "classDetailsScreen",
            value: 3
        },
        {
            step: "noOfDaysPractice",
            value: 4
        },
        {
            step: "selectTime",
            value: 5
        },
        {
            step: "dailyGoal",
            value: 6
        },
        {
            step: "selectLevel",
            value: 7
        }
    ]

    gender = [
        {
            name: "Miss",
            type: "F",
            activeSource: getImageSource(ImageConstants.MISS_ACTIVE),
            inActiveSource: getImageSource(ImageConstants.MISS_INACTIVE)
        },
        {
            name: "Master",
            type: "M",
            activeSource: getImageSource(ImageConstants.MASTER_ACTIVE),
            inActiveSource: getImageSource(ImageConstants.MASTER_INACTIVE)
        }
    ]

    state = {
        showStudentSignupScreen: false,
        showOtpScreen: false,
        selectedCountry: { name: 'India', dialCode: "+91", length: 10, _id: '5f733391ac527316bbd9c539' },
        otp: '',
        isOtpSent: false,
        invalidOtp: false,
        showPasscodeScreen: false,
        showPasscodeNotMatch: false,
        showClassDetailsScreen: false,
        showNoOfDaysPracticeScreen: false,
        showSelectTimeScreen: false,
        showDailyGoalScreen: false,
        showSelectLevelScreen: false,
        showAppIntroVideoScreen: false,
        showTermsAndConditionsModal: false,
        fromSchoolConnectDeepLink: false
    }

    deviceInfo = {}

    componentDidMount() {
        this.deviceInfo = CommonUtils.getDeviceInformation()
        this.getCountryCode()
        this.getTermsAndConditions()
        if (this.schoolCode) {
            this.getSchoolInformationForUniqueSchoolCode(this.schoolCode, true)
        }
    }

    getSchoolInformationForUniqueSchoolCode = (schoolCode, flag) => {

        let payload = {
            schoolCode: schoolCode
        }

        // this.setState({ loading: true })


        SchoolApi.getSchoolInformationFromSchoolConnectBeforeLogin(payload).then((res) => {

            // this.setState({ loading: false })
            //console.log(res)
            if (res) {

                // if (flag) {
                //     this.props.navigation.navigate('WelcomeScreen', { meta: { fromSchoolConnectDeepLink: true, schoolName: res.name, locality: res.locality, schoolDetails: res, schoolOriginalCode: res.code } });
                //     this.branchParams = null
                //     return
                // } else {
                //     this.props.navigation.navigate('SchoolConfirmationScreen', { meta: { fromSchoolConnectDeepLink: true, schoolCode: this.branchParams['schoolCode'] }, showSubscriptionEndPopup: this.meta && this.meta.isExipred ? this.meta.isExipred : false, subscriptionEndDate: this.meta && this.meta.subscriptionEndDate ? this.meta.subscriptionEndDate : "", showFirstPage: true });
                //     this.branchParams = null
                // }

                if (flag) {
                    this.setState({
                        fromSchoolConnectDeepLink: true,
                        schoolDetails: res,
                    })
                } else {
                    this.setState({
                        schoolDetails: res
                    })
                }




            } else {
                // this.props.navigation.navigate('LandingScreen', { meta: { showSubscriptionEndPopup: this.meta && this.meta.isExipred ? this.meta.isExipred : false, subscriptionEndDate: this.meta && this.meta.subscriptionEndDate ? this.meta.subscriptionEndDate : "", showFirstPage: true } });
                // this.branchParams = null
                //console.log(res)
            }

            return
        }).catch(err => {
            if (err) {

                // if (flag) {
                //     this.props.navigation.navigate('WelcomeScreen');
                //     this.branchParams = null
                //     return
                // } else {
                //     //console.log(err, 'loginerr')
                //     this.props.navigation.navigate('LandingScreen', { meta: { showSubscriptionEndPopup: this.meta && this.meta.isExipred ? this.meta.isExipred : false, subscriptionEndDate: this.meta && this.meta.subscriptionEndDate ? this.meta.subscriptionEndDate : "", showFirstPage: true } });
                //     this.branchParams = null
                // }

                return;
            }
        })
    }


    getTermsAndConditions = () => {

        CommonApi.getTermsandConditions({ type: 'TERMS_AND_CONDITIONS' }).then((data) => {

            if (data) {
                let content = data.content
                this.setState({ content: content })
            }
        }).catch(err => {
            if (err) {
                return
            };
        })
    }


    getBoard() {

        let payload = {
            limit: 100,
            sortType: 'asc',
            sortKey: 'order',
            display: "SHOW"
        }

        CommonApi.getBoard(payload).then((data) => {
            //console.log(data)

            if (data) {
                let res = data.response;
                let activeBoardList = [];
                let inActiveBoardList = [];
                for (let i = 0; i < res.length; i++) {


                    if (res[i].active === true || !res[i].hasOwnProperty("active")) {
                        activeBoardList.push(res[i])
                    }

                    else if (res[i].active === false) {
                        inActiveBoardList.push(res[i])

                    }

                }
                this.setState({ boardList: res, activeBoardList: activeBoardList, inActiveBoardList: inActiveBoardList }, () => {
                    // this.toggleLoading(false)
                })
            }
        }).catch(err => {
            //console.log(err)
            if (err) {
                // this.toggleLoading(false)
                return
            };
        })
    }

    getClass(flag) {
        //this.toggleLoading(true)

        let payload = {
            limit: 100,
            sortType: 'asc',
            sortKey: 'order',
            display: "SHOW"
        }

        let schoolClasses = []

        CommonApi.getClass(payload).then((data) => {
            //console.log(data)

            if (data) {
                let res = data.response;
                let activeClassList = [];
                let inActiveClassList = [];
                for (let i = 0; i < res.length; i++) {

                    if (res[i].active === true || !res[i].hasOwnProperty("active")) {
                        activeClassList.push(res[i])
                    }
                    else if (res[i].active === false) {
                        inActiveClassList.push(res[i].title)
                    }

                }
                if (flag) {
                    schoolClasses = res.filter(o1 => this.state.schoolClasses.some(o2 => o1.title === o2.title))

                } else {
                    schoolClasses = res
                }

                this.setState({ classList: schoolClasses, activeClassList: activeClassList, inActiveClassList: inActiveClassList })
            }
        }).catch(err => {
            if (err) { return };
        })
    }



    getSignUpKey = () => {
        AsyncStore.getStudentSignupHashKey().then((hashKey) => {
            if (hashKey) {
                this.hashKey = hashKey
            } else {
                this.setSignUpKey()
            }
        }).catch((err) => {
            this.setSignUpKey()
        });
    }

    setSignUpKey = () => {
        this.hashKey = CryptoJS.AES.encrypt('COUNTINGWELL_STUDENT_SIGN_UP_RAVI_NIRMAL', 'COUNTINGWELL@123_RAVI_NIRMAL').toString();
        AsyncStore.setStduentSignupHashKey(this.hashKey);
        //console.log(this.hashKey)
    }



    onSelectGender = (type) => {
        // if (this.state.activeStep == 'SIGNUP')
        this.setState({ activeGender: type })
    }


    goToSignupScreen(flag, schoolDetails) {

        if (flag) {
            this.setState({ showSchoolConfirmationScreen: true })
        } else {
            this.setState({ showStudentSignupScreen: true, signupScreen: "signupScreen" }, () => {
                if (!schoolDetails) {
                    this.getSchoolInformationForUniqueSchoolCode("SCX-121", false)
                }
            })
        }

    }

    goToOtpScreen() {
        this.setState({ showOtpScreen: true, isOtpSent: true, showStudentSignupScreen: false, otpScreenDash: 'otpScreen' })
    }

    goBackToWelcome() {
        this.setState({ showStudentSignupScreen: false })
    }

    goBackToSignupScreen() {
        this.setState({
            showStudentSignupScreen: true,
            showPasscodeScreen: false,
            showOtpScreen: false,
            isOtpSent: false, otp: '',
            otpScreenDash: '',
            isVerifyEnable: false
        })
    }

    getCountryCode(payload = {}) {
        CommonApi.getCountryCode(payload).then((response) => {
            let data = response.response
            //console.log(data)
            if (data.length) {
                this.setState({ countryList: data })
                this.buildCountryCodeDropDown(data)
            } else {
                this.setState({ countryList: [], countryCodeDropDown: [] })
            }
        }).catch((err) => {
            //console.log(err)
        })
    }

    buildCountryCodeDropDown(list = []) {
        let countryCodeDropDown = []
        list.map(item => {
            this.coutryCodeHash[item._id] = item
            countryCodeDropDown.push({ text: item.dialCode, addOn: item.name, value: item._id })
        })
        this.setState({ countryCodeDropDown })
    }

    onSelectCode(item) {
        this.setState({ selectedCountry: this.coutryCodeHash[item.value], showDropdown: false }, () => {
            // this.setState({ isLoginEnable: this.validateMobile(this.state.mobileNumber) })
        })
    }

    hideCountryDropdown() {
        this.setState({ showDropdown: false })
    }

    onSearch(event) {
        let search = event.target.value
        this.getCountryCode({ name: `/${search}`, })
    }


    openCountryCode() {
        if (this.state.isOtpSent) return
        this.getCountryCode()
        this.setState({ showDropdown: !this.state.showDropdown })
    }

    openCityModal() {
        this.setState({ showCityModal: true })
    }


    onChangeCity = (city) => {

        this.setState({
            selectedCity: city,
            city: city.name + ', ' + city.state + ', ' + city.country
        })
    }

    closeCityModal = () => {
        this.setState({ showCityModal: false })

    }

    onDeleteCity(event) {
        if (event.keyCode === 8) {
            this.setState({ city: '' })
        }
    }

    signupHandler() {
        if (this.state.skipOtp) {
            this.studentSignupUsingPasscode()
        } else {
            this.studentSignup()
        }
    }




    signUp = (value = {}) => {

        this.setState({ showSendOtpConfirmationModal: false })

        if (this.state.showServerErrorMessage || this.state.showCheckInternetMessage) {
            this.setState({ showServerErrorMessage: false, showCheckInternetMessage: false })
        }


        if (this.state.userAlreadyExist) {
            this.setState({ userAlreadyExist: false, userExistMessage: '' })
        }

        if (this.state.mobileNumber && !CommonUtils.validateMobileNumber(this.state.mobileNumber, this.coutryCodeHash[this.state?.selectedCountry?._id])) {
            this.setState({ invalidMobileNumber: true })
            return
        }

        if (this.state.email && !CommonUtils.validateEmail(this.state.email)) {
            this.setState({ invalidEmail: true })
            return
        }

        let payload = {
            countryCode: this.state.selectedCountry.dialCode,
            mobileNumber: this.state.mobileNumber,
            name: this.state.name.trim(),
            nickName: this.state.nickName.trim(),
            city: this.state.selectedCity.name,
            state: this.state.selectedCity.state,
            country: this.state.selectedCity.country,
            timeZone: this.state.selectedCity.timeZone,
            hashKey: this.hashKey,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL
        }

        if (this.state.alternateOtpOption) {
            payload['sendOtpViaWhatsapp'] = true
        }

        if (value) {
            payload['skipOtp'] = value.skipOtp
        }

        AuthenticateAPI.sendOtpStudentSignUp(payload).then((res) => {


            if (value && value.skipOtp) {
                this.setState({
                    showPasscodeScreen: true, showSendOtpConfirmationModal: false,
                    showStudentSignupScreen: false, otpScreenDash: 'otpScreen'
                })
            } else {
                this.goToOtpScreen()
            }

        }).catch(err => {
            if (err) {
                if (err.response && err.response.data && err.response.data.code == 11000) {
                    this.setState({ userAlreadyExist: true })
                } else if (err.response && err.response.status && err.response.status === 500) {
                    this.setState({ showServerErrorMessage: true, loading: false })
                } else if (!err.response) {
                    this.setState({ showCheckInternetMessage: true, loading: false })
                }
                return;
            }
        })
    }



    verifyOtp = () => {

        if (this.state.showServerErrorMessage || this.state.showCheckInternetMessage) {
            this.setState({ showServerErrorMessage: false, showCheckInternetMessage: false })
        }

        let payload = {
            countryCode: this.state.selectedCountry.dialCode,
            mobileNumber: this.state.mobileNumber,
            // gender: this.state.activeGender,
            // deviceId: "STUDENT_PORTAL_DEVICE_ID",
            name: this.state.name.trim(),
            // nickName: this.state.nickName.trim(),
            otp: this.state.otp,
            // city: this.state.selectedCity.name,
            // state: this.state.selectedCity.state,
            // country: this.state.selectedCity.country,
            // timeZone: this.state.selectedCity.timeZone ? this.state.selectedCity.timeZone : '',
            // email: this.state.email,
            // deviceInfo: this.deviceInfo,
            // currentVersion: DeviceInfo.getVersion(),
            // platform: this.deviceInfo.os,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL
        }

        // if (this.state.cityNotFound) {
        //     payload = {
        //         ...payload,
        //         city: 'Other',
        //         state: 'Other',
        //         country: this.state.selectedCountryName ? this.state.selectedCountryName : 'Other',
        //         timeZone: this.countryDetails && this.countryDetails.timeZone ? this.countryDetails.timeZone : ''
        //     }
        // }

        // this.toggleLoading(true)
        AuthenticateAPI.studentSignupVerifyOtp(payload).then((res) => {

            if (res.status === 200) {
                this.setState({ isVerifyEnable: true, otpVerified: res.data.otpVerified })
            }

            // this.uniqueKey = res.data.uniqueKey;
            // AsyncStore.setUniqueKey(this.uniqueKey);
            // this.verifyDevice();

            /***Log Branch IO on Register */
            // BranchIO.logCustomEvent(BranchIOEvents.REGISTER, { countryCode: this.state.countryCode, mobileNumber: this.state.mobileNumber, name: this.state.name.trim() });
            // Facebook.logCustomEvent(BranchIOEvents.REGISTER, { countryCode: this.state.countryCode, mobileNumber: this.state.mobileNumber, name: this.state.name.trim() });

        }).catch(err => {
            if (err) {
                // this.toggleLoading(false)
                if (err.response && err.response.data && (err.response.data.code == 1600 || err.response.data.code == 1601)) {
                    this.setState({ invalidOtp: true }, () => {
                        setTimeout(() => {
                            this.setState({ invalidOtp: false })
                        }, 3000)
                    })
                } else if (err.response && err.response.status && err.response.status === 500) {
                    this.setState({ showServerErrorMessage: true, loading: false })
                } else if (!err.response) {
                    this.setState({ showCheckInternetMessage: true, loading: false })
                } return;
            }
        })
    }

    studentSignup() {

        if (this.state.showServerErrorMessage || this.state.showCheckInternetMessage) {
            this.setState({ showServerErrorMessage: false, showCheckInternetMessage: false })
        }

        let payload = {
            countryCode: this.state.selectedCountry.dialCode,
            mobileNumber: this.state.mobileNumber,
            gender: this.state.activeGender,
            deviceId: "STUDENT_PORTAL_DEVICE_ID",
            name: this.state.name.trim(),
            nickName: this.state.nickName.trim(),
            otp: this.state.otp,
            city: this.state.selectedCity.name,
            state: this.state.selectedCity.state,
            country: this.state.selectedCity.country,
            timeZone: this.state.selectedCity.timeZone ? this.state.selectedCity.timeZone : '',
            email: this.state.email,
            deviceInfo: this.deviceInfo,
            // currentVersion: DeviceInfo.getVersion(),
            platform: this.deviceInfo.os,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL,
            otpVerified: this.state.otpVerified,
            passcode: this.state.passcode
        }

        if (this.state.cityNotFound) {
            payload = {
                ...payload,
                city: 'Other',
                state: 'Other',
                country: this.state.selectedCountryName ? this.state.selectedCountryName : 'Other',
                timeZone: this.countryDetails && this.countryDetails.timeZone ? this.countryDetails.timeZone : ''
            }
        }

        // this.toggleLoading(true)
        AuthenticateAPI.signupStudent(payload).then((res) => {

            //console.log(res)

            this.uniqueKey = res.data.uniqueKey;
            AsyncStore.setUniqueKey(this.uniqueKey);
            this.verifyDevice();

            /***Log Branch IO on Register */
            // BranchIO.logCustomEvent(BranchIOEvents.REGISTER, { countryCode: this.state.countryCode, mobileNumber: this.state.mobileNumber, name: this.state.name.trim() });
            // Facebook.logCustomEvent(BranchIOEvents.REGISTER, { countryCode: this.state.countryCode, mobileNumber: this.state.mobileNumber, name: this.state.name.trim() });

        }).catch(err => {
            if (err) {
                // this.toggleLoading(false)
                if (err.response && err.response.data && (err.response.data.code == 1600 || err.response.data.code == 1601)) {
                    this.setState({ invalidOtp: true }, () => {
                        setTimeout(() => {
                            this.setState({ invalidOtp: false })
                        }, 3000)
                    })
                } else if (err.response && err.response.status && err.response.status === 500) {
                    this.setState({ showServerErrorMessage: true, loading: false })
                } else if (!err.response) {
                    this.setState({ showCheckInternetMessage: true, loading: false })
                } return;
            }
        })

    }

    studentSignupUsingPasscode() {
        if (this.state.showServerErrorMessage || this.state.showCheckInternetMessage) {
            this.setState({ showServerErrorMessage: false, showCheckInternetMessage: false })
        }

        let payload = {
            countryCode: this.state.selectedCountry.dialCode,
            mobileNumber: this.state.mobileNumber,
            gender: this.state.activeGender,
            deviceId: "STUDENT_PORTAL_DEVICE_ID",
            name: this.state.name.trim(),
            nickName: this.state.nickName.trim(),
            city: this.state.selectedCity.name,
            state: this.state.selectedCity.state,
            country: this.state.selectedCity.country,
            timeZone: this.state.selectedCity.timeZone ? this.state.selectedCity.timeZone : '',
            email: this.state.email,
            deviceInfo: this.deviceInfo,
            // currentVersion: DeviceInfo.getVersion(),
            platform: this.deviceInfo.os,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL,
            otpVerified: this.state.otpVerified,
            passcode: this.state.passcode
        }

        if (this.state.cityNotFound) {
            payload = {
                ...payload,
                city: 'Other',
                state: 'Other',
                country: this.state.selectedCountryName ? this.state.selectedCountryName : 'Other',
                timeZone: this.countryDetails && this.countryDetails.timeZone ? this.countryDetails.timeZone : ''
            }
        }

        // this.toggleLoading(true)
        AuthenticateAPI.studentSignupUsingPasscode(payload).then((res) => {

            this.uniqueKey = res.data.uniqueKey;
            AsyncStore.setUniqueKey(this.uniqueKey);
            this.verifyDevice();

            /***Log Branch IO on Register */
            // BranchIO.logCustomEvent(BranchIOEvents.REGISTER, { countryCode: this.state.countryCode, mobileNumber: this.state.mobileNumber, name: this.state.name.trim() });
            // Facebook.logCustomEvent(BranchIOEvents.REGISTER, { countryCode: this.state.countryCode, mobileNumber: this.state.mobileNumber, name: this.state.name.trim() });

        }).catch(err => {
            if (err) {
                // this.toggleLoading(false)
                if (err.response && err.response.data && (err.response.data.code == 1600 || err.response.data.code == 1601)) {
                    this.setState({ invalidOtp: true }, () => {
                        setTimeout(() => {
                            this.setState({ invalidOtp: false })
                        }, 3000)
                    })
                } else if (err.response && err.response.status && err.response.status === 500) {
                    this.setState({ showServerErrorMessage: true, loading: false })
                } else if (!err.response) {
                    this.setState({ showCheckInternetMessage: true, loading: false })
                } return;
            }
        })
    }


    verifyDevice() {
        let payload = {
            uniqueKey: this.uniqueKey,
            deviceId: "STUDENT_PORTAL_DEVICE_ID",
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL
        };
        AuthenticateAPI.verifyDevice(payload).then(async (res) => {
            //console.log(res)
            SharedProfile.setProfile(res.data.profile);
            // RemoteNotification.setNotificationToken();
            // this.props.navigation.navigate('ClassDetails');
            if (res.status === 200) {
                this.setState({ showPasscodeScreen: false, showClassDetailsScreen: true, showClassDetailsScreenDash: 'classDetailsScreen' })
            }

            //console.log('success')

            FirebaseEvent(FirebaseEvents.REGISTER, { name: res.data.profile.nickName, phone: res.data.profile.mobileNumber, board: res.data.profile.alternateBoard ? res.data.profile.alternateBoard : res.data.profile.board, class: res.data.profile.class, gender: res.data.profile.gender })

        }).catch(err => {
            //console.log(err)
            if (err) {
                // this.toggleLoading(false)
                return;
            }
        })
    }


    goToPasscodeScreen() {
        this.setState({ showPasscodeScreen: true, showOtpScreen: false })
    }


    onChangeMobileNumber(event) {
        if (this.state.userAlreadyExist) {
            this.setState({ userAlreadyExist: false, mobileNumber: event.target.value })
        }
        else {
            this.setState({ mobileNumber: event.target.value })
        }
    }

    showSendOtpConfirmationModal() {
        this.setState({ showSendOtpConfirmationModal: true })
    }

    hideSendOtpConfirmaitonModal() {
        this.setState({ showSendOtpConfirmationModal: false })
    }

    showPasscodeScreen(skipOtp) {
        this.setState({ skipOtp: true })
        this.signUp(skipOtp)
    }

    showPasscodeNotMatch() {
        this.setState({ showPasscodeNotMatch: true }, () => {
            setTimeout(() => {
                this.setState({ showPasscodeNotMatch: false })
            }, 2000)
        })
    }

    confirmPasscode(passcode, value) {
        //console.log(passcode)
        this.setState({ confirmPasscode: value, passcode: passcode })
    }

    onPressStep4Continue = (days) => {

        let payload = {
            daysWantsToPractice: days
        }

        this.updateProfileMoreInfo(payload, () => {

            //console.log('inside callback')

            if (this.userInfo && this.userInfo["moreInfo"]) {
                this.userInfo["moreInfo"]["daysWantsToPractice"] = days
            } else {
                this.userInfo["moreInfo"] = { daysWantsToPractice: days }
            }

            // moengage.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Days want to practice per week': `${days}` })
            // FireBaseEvent.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Days want to practice per week': `${days}` })
            // Facebook.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Days want to practice per week': `${days}` })
            // BranchIO.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Days want to practice per week': `${days}` })

            SharedProfile.setProfile(this.userInfo);

            this.setState({ showSelectTimeScreen: true, showNoOfDaysPracticeScreen: false, showSelectTimeScreenDash: 'selectTime' })
        })
    }


    updateProfileMoreInfo = (payload, callback) => {

        // this.setState({ loading: true })
        AuthenticateAPI.updateProfileMoreInfo(payload).then((data) => {

            // this.setState({ loading: false })
            callback && callback()
        }).catch(err => {
            //console.log(err)
            if (err) {
                return
            }
        })
    }

    onPressStep5Continue = (time) => {

        let payload = {
            timeForPractice: time
        }
        this.updateProfileMoreInfo(payload, () => {

            if (this.userInfo && this.userInfo["moreInfo"]) {
                this.userInfo["moreInfo"]["timeForPractice"] = time
            } else {
                this.userInfo["moreInfo"] = { timeForPractice: time }
            }

            // moengage.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Preferred Time to practice': time })
            // FireBaseEvent.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Preferred Time to practice': time })
            // Facebook.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Preferred Time to practice': time })
            // BranchIO.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Preferred Time to practice': time })

            SharedProfile.setProfile(this.userInfo);

            this.setState({ showDailyGoalScreen: true, showSelectTimeScreen: false, showDailyGoalScreenDash: 'dailyGoal' })
        })
    }

    onPressStep6Continue = (days) => {

        let payload = {
            dailyGoal: days
        }
        this.updateProfileMoreInfo(payload, () => {

            if (this.userInfo && this.userInfo["moreInfo"]) {
                this.userInfo["moreInfo"]["dailyGoal"] = days
            } else {
                this.userInfo["moreInfo"] = { dailyGoal: days }
            }

            // moengage.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Daily Goal': `${days} mins` })
            // FireBaseEvent.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Daily Goal': `${days} mins` })
            // Facebook.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Daily Goal': `${days} mins` })
            // BranchIO.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Daily Goal': `${days} mins` })

            SharedProfile.setProfile(this.userInfo);

            this.setState({ showSelectLevelScreen: true, showSelectLevelScreenDash: 'selectLevel', showDailyGoalScreen: false })
        })
    }

    onPressStep7Continue = (level) => {

        let payload = {
            currentLevelOfMaths: level

        }
        this.updateProfileMoreInfo(payload, async () => {

            if (this.userInfo && this.userInfo["moreInfo"]) {
                this.userInfo["moreInfo"]["currentLevelOfMaths"] = level
            } else {
                this.userInfo["moreInfo"] = { currentLevelOfMaths: level }
            }

            // moengage.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Level of Maths': level })
            // FireBaseEvent.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Level of Maths': level })
            // Facebook.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Level of Maths': level })
            // BranchIO.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Level of Maths': level })


            SharedProfile.setProfile(this.userInfo);

            let doc = await AsyncStore.getWelcomePopupInfo();

            if (doc) {
                doc = JSON.parse(doc)

                if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}`]) {

                    doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}`] = true

                    AsyncStore.setWelcomePopupInfo(doc)
                }
            } else {
                AsyncStore.setWelcomePopupInfo({ [`${this.userInfo.mobileNumber}_${this.userInfo.userName}`]: true })
            }

            this.setState({ showAppIntroVideoScreen: true, showTrialFeatures: true, showSelectLevelScreen: false, showDashes: false })

            // this.props.navigation.navigate('LandingScreen', { meta: { showTrialFeatures: true, showFirstPage: true } });
        })
        // this.props.navigation.navigate('LandingScreen', { meta: { showTrialFeatures: false, showFirstPage: true } });
    }


    showNoOfDaysPracticeScreen() {
        this.userInfo = SharedProfile.getProfile();
        this.setState({
            showNoOfDaysPracticeScreen: true,
            showClassDetailsScreen: false,
            showNoOfDaysPracticeScreenDash: 'noOfDaysPractice'
        })
    }


    goBackToClassDetailsScreen() {
        this.setState({
            showNoOfDaysPracticeScreen: false,
            showClassDetailsScreen: true,
            showNoOfDaysPracticeScreenDash: 'a'
        })
    }

    goBackToNoOfDaysPractice() {
        this.setState({
            showNoOfDaysPracticeScreen: true,
            showSelectTimeScreen: false,
            showSelectTimeScreenDash: 'b'
        })
    }

    goBackToSelectTimeScreen() {
        this.setState({
            showDailyGoalScreen: false,
            showDailyGoalScreenDash: 'c',
            showSelectTimeScreen: true
        })
    }

    goBackToDailyGoalScreen() {
        this.setState({
            showSelectLevelScreen: false,
            showSelectLevelScreenDash: 'd',
            showDailyGoalScreen: true
        })
    }

    resendOtp = () => {
        if (!this.state.mobileNumber)
            return;

        this.setState({ invalidOtp: false, otp: '' }, () => {
            this.signUp()
        })

    }

    onOtpChange = (otp) => {
        this.setState({ otp: otp }, () => {
            if (this.state.otp.length === 4) {
                this.verifyOtp()
            }
        })
    }

    onPressSkip = () => {

        this.props.history.push({ pathname: Path.HOME, state: { showTrialFeatures: this.state.showTrialFeatures } })

        // if (this.onboardingFlowConfig && this.onboardingFlowConfig.skipTrialWorkflow)
        // this.props.navigation.navigate('LandingScreen', { meta: { showTrialFeatures: false, showFirstPage: true } });
        // else
        //     this.setState({ activeStep: 10 })
    }


    showTermsAndConditionsModal() {
        this.setState({ showTermsAndConditionsModal: true })
    }

    hideTermsAndConditions() {
        this.setState({ showTermsAndConditionsModal: false })
    }



    countryCodeDropDown(dropDownList = []) {
        return (
            <div className="country-code-drop-down" id="st-3" ref={ref => this.countryCodeRef = ref} onClick={() => this.handleClickInside}>
                <div className="">
                    <div className="country-close-icon cursor-pointer">
                        <img className="close-icon" onClick={() => this.hideCountryDropdown()} src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} />
                    </div>
                    <div className="country-header-div">
                        <img className="country-header-img" src={getImageSource(ImageConstants.COUNTRY_DROPDOWN_HEADER)} />
                    </div>
                    <div className="select-country">Select a country</div>
                    <div className="country-search-div">
                        <img className="country-search" src={getImageSource(ImageConstants.COUNTRY_CODE_SEARCH)}></img>
                    </div>

                    <div className="search">

                        <input className="form-control country-search" placeholder={`Search`} value={this.state.search} onChange={(event) => { this.onSearch(event) }} />
                    </div>
                    <div className="p-2 dropdown-scroll">
                        {dropDownList.length ?
                            dropDownList.map(dropdown => (
                                <div className="dropdown-list cursor-pointer px-3 py-1" onClick={() => { this.onSelectCode(dropdown) }}>
                                    <div className="row country-name cursor-pointer">
                                        <div className="col-8"> {dropdown.addOn}</div>
                                        <div className="col text-right">  {dropdown.text}</div>
                                    </div>
                                </div>

                            ))
                            : <div className="text-center py-4 no-data">
                                <label> No Records found</label>
                            </div>
                        }
                    </div>
                </div>
                <div className="country-footer-div">
                    <img className="country-header-img" src={getImageSource(ImageConstants.COUNTRY_DROPDOWN_FOOTER)} />
                </div>
            </div>
        )
    }





    welcomeScreen() {

        return (
            <div className="welcome-screen">

                <div className="text-center welcome-text">{`Welcome to Countingwell`}</div>
                {/* <div className="position-absolute already-wrapper">Already Registered ? <span className="login-button cursor-pointer" onClick={() => this.props.history.replace(Path.LOGIN)}>Login</span></div> */}
                <div className="text-center favourite-text">{`India’s favourite Maths Learning App`}</div>

                <div className="d-flex welcom-content-wrapper  mt-3 ">
                    <div className="col col-md-6 video-container">
                        <div className="d-flex justify-content-center">
                            <div className="video-style">
                                <ReactPlayer
                                    controls
                                    light={<img className="poster-image-size" src={getImageSource(ImageConstants.VIDEO_POSTER)} />}
                                    playing={true}
                                    playIcon={<img />}
                                    url='https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/47347552-4f0b-43de-995a-1a3e0399bb6f_CW_Video_for_WhatsApp.mp4'
                                />
                            </div>
                        </div>


                        <div className="d-flex justify-content-end ml-4  flex-row student-learning-count">
                            <div className="active-students d-flex justify-content-center justify-items-center flex-column text-margin">
                                <div className="text-center pt-1">
                                    <img className="image-size" src={getImageSource(ImageConstants.ACTIVE_STUDENTS)} />
                                </div>
                                <div className="text-center student-count mt-2">
                                    100000 +
                                </div>
                                <div className="text-center student-text mt-2">
                                    Active
                                    <div className="text-center">
                                        Students
                                    </div>
                                </div>

                            </div>
                            <div className="learning-videos d-flex justify-content-center justify-items-center flex-column text-margin">
                                <div className="text-center pt-1">
                                    <img className="image-size" src={getImageSource(ImageConstants.VIDEO_PLAY_ICON)} />
                                </div>
                                <div className="text-center student-count mt-2">
                                    800 +
                                </div>
                                <div className="text-center student-text mt-2">
                                    Learning
                                    <div className="text-center">
                                        Videos
                                    </div>
                                </div>
                            </div>
                            <div className="practice-questions d-flex justify-content-center justify-items-center flex-column mr-5">
                                <div className="text-center pt-1">
                                    <img className="image-size" src={getImageSource(ImageConstants.EXAM_PAD_ICON)} />
                                </div>
                                <div className="text-center student-count mt-2">
                                    1000 +
                                </div>
                                <div className="text-center student-text mt-2">
                                    Practice
                                    <div className="text-center">
                                        Questions
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col text-left ml-5 feedback-container">
                        <div>
                            <div className="quote-margin">
                                <img className="quote-image-size" src={getImageSource(ImageConstants.QUOTE_UP)} />
                            </div>
                            <div className="feedback-text">
                                I am enjoying the app. Beside I am also learning. Now I am thinking if countingwell app was not there I would be bored.
                            </div>
                            <div className="student-name">
                                Bidhyanshu Das, Class 7 Student from Kolkata
                                <img className="quote-image-size ml-4 mb-3" src={getImageSource(ImageConstants.QUOTE_DOWN)} />
                            </div>
                        </div>
                        <div className="quote-margin-top">
                            <div className="quote-margin">
                                <img className="quote-image-size" src={getImageSource(ImageConstants.QUOTE_UP)} />
                            </div>
                            <div className="feedback-text">
                                I am enjoying the app. Beside I am also learning. Now I am thinking if countingwell app was not there I would be bored.
                            </div>
                            <div className="student-name">
                                Bidhyanshu Das, Class 7 Student from Kolkata
                                <img className="quote-image-size ml-4 mb-3" src={getImageSource(ImageConstants.QUOTE_DOWN)} />
                            </div>
                        </div>

                    </div>
                </div>

                {/* <div className="welcom-content-wrapper d-flex justify-content-between align-items-center"> 
                    
                    
                    <div className="video-style">
                        <ReactPlayer
                            controls
                            light={<img className="poster-image-size" src={getImageSource(ImageConstants.VIDEO_POSTER)} />}
                            playing={true}
                            playIcon={<img />}
                            url='https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/workout_app_explore.mp4'
                        />

                    </div>
                    

                </div> */}

                <div className="d-flex justify-content-center">
                    <div className="text-center continue-button cursor-pointer" onClick={() => this.goToSignupScreen(this.state.fromSchoolConnectDeepLink, null)}>
                        Continue
                    </div>
                    <div className="align-self-center mt-4 ml-3">
                        <div className="already-wrapper">Already Registered ? <span className="login-button cursor-pointer" onClick={() => this.props.history.replace(Path.LOGIN)}>Login</span></div>
                    </div>
                </div>
            </div>
        )

    }

    getButtons = () => {

        if (this.state.showStudentSignupScreen) {
            return (
                !this.state.name ||
                    (this.state.name && !this.state.name.trim()) ||
                    !this.state.nickName ||
                    (this.state.nickName && !this.state.nickName.trim()) ||
                    !this.state.mobileNumber ||
                    !this.state.activeGender ||
                    this.state.invalidName ||
                    (!this.state.city) ||
                    (this.state.email && this.state.invalidEmail) ||
                    this.state.invalidMobileNumber ?
                    <div className="text-center continue-button continue-button-disabled">
                        Continue
                    </div>
                    :
                    <div className="text-center continue-button cursor-pointer" onClick={() => this.showPasscodeScreen({ skipOtp: true })}>
                        Continue
                    </div>
            )
        }

        if (this.state.showOtpScreen) {
            return (
                !this.state.isVerifyEnable ?
                    <div className="text-center continue-button continue-button-disabled">
                        Verify
                    </div>
                    :
                    <div className="text-center continue-button cursor-pointer" onClick={() => this.goToPasscodeScreen()}>
                        Verify
                    </div>
            )
        }

        if (this.state.showPasscodeScreen) {
            return (
                !this.state.confirmPasscode ?
                    <div className="text-center continue-button continue-button-disabled">
                        Signup
                    </div>
                    :
                    <div className="text-center continue-button cursor-pointer" onClick={() => this.signupHandler()}>
                        Signup
                    </div>
            )
        }

        if (this.state.showClassDetailsScreen) {
            return (
                !this.state.selectedBoard && !this.state.selectedClass ?
                    <div className="text-center continue-button continue-button-disabled">
                        Continue
                    </div>
                    :
                    <div className="text-center continue-button cursor-pointer" onClick={() => this.onPressClassDetailsContinue()}>
                        Continue
                    </div>
            )
        }
    }



    studentSignupScreen() {
        return (
            <div className="studentSignupScreen">
                <div className="heading">Heading</div>
                <div className="d-flex student-signup-wrapper">
                    <div className="col item-1">
                        <div className="">
                            <div className="row">
                                <div className="col cw-input-label-1 mb-3">I am a</div>
                            </div>
                            <div className="row">
                                {
                                    this.gender.map((item, index) => (
                                        <div className="col-3">
                                            <img onClick={() => this.onSelectGender(item.type)} className="student-gender-image cursor-pointer" src={this.state.activeGender === item.type ? item.activeSource : item.inActiveSource} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col">
                                    <label className="cw-input-label-1 mb-2">My Full Name</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <input className="cw-input-1"
                                        placeholder="Enter your Full Name"
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                        value={this.state.name}
                                        onFocus={() => this.setState({ invalidName: false })}
                                        maxLength={150} />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col">
                                    <label className="cw-input-label-1 mb-3">My Friends Call Me</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <input className="cw-input-1"
                                        placeholder="Enter your Nick Name"
                                        onChange={(event) => this.setState({ nickName: event.target.value })}
                                        value={this.state.nickName}
                                        onFocus={() => this.setState({ invalidName: false })}
                                        maxLength={150} />
                                </div>
                            </div>
                        </div>
                        <div className="input-subtext">We need this to create a custom experience for you</div>
                    </div>
                    <div className="col item-2">
                        <div className="">
                            <div className="row">
                                <div className="col">
                                    <label className="cw-input-label-1 mb-3">Select Your Country</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cw-input-wrapper d-inline-block" >


                                    {/* <img className="country-code-separator" src={getImageSource(ImageConstants.COUNTRY_SEPARATOR)} /> */}
                                    <input className="cw-input-1-for-mobileNumber cursor-pointer"
                                        value={this.state.selectedCountry.name}
                                        maxLength={150}
                                        onClick={() => this.openCountryCode()}
                                    />

                                    <div className={`d-inline-block country-code country-code-color cursor-pointer  
                                    ${this.state.isOtpSent ? "cursor: pointer" : "cursor: pointer"}`} onClick={() => this.openCountryCode()}>
                                        {this.state.selectedCountry.dialCode}
                                    </div>
                                    <img onClick={() => this.openCountryCode()} className={`country-code-down-arrow cursor-pointer ${this.state.selectedCountry.dialCode.length == 3 ? 'country-code-down-arrow-margin' : ''}`} src={getImageSource(ImageConstants.DOWN_ARROW)} />
                                    <div className="abs-div"  >
                                        {this.state.showDropdown ? this.countryCodeDropDown(this.state.countryCodeDropDown) : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col">
                                    <label className="cw-input-label-1 mb-3">My Mobile No</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cw-input-wrapper">
                                    <div className={`d-inline-block country-code ${this.state.selectedCountry.dialCode.length > 3 ? "country-code-min-width" : ""}
                                    ${this.state.isOtpSent ? "cursor: default" : "cursor: default"}`}>
                                        {this.state.selectedCountry.dialCode}
                                    </div>
                                    {/* <img onClick={() => this.openCountryCode()} className={`country-code-down-arrow cursor-pointer ${this.state.selectedCountry.dialCode.length == 3 ? 'country-code-down-arrow-margin' : ''}`} src={getImageSource(ImageConstants.DOWN_ARROW)} /> */}
                                    <img className="country-code-separator" src={getImageSource(ImageConstants.COUNTRY_SEPARATOR)} />
                                    <input className="cw-input-1-for-mobileNumber"
                                        type="number"
                                        placeholder={this.staticText.mobilePlaceHolder}
                                        onChange={(event) => CommonUtils.isValidNumber(event.target.value) && this.onChangeMobileNumber(event)}
                                        value={this.state.mobileNumber}
                                        onFocus={() => this.setState({ invalidName: false, userAlreadyExist: false, invalidMobileNumber: false })}
                                        onBlur={() => {
                                            if (this.state.mobileNumber && !CommonUtils.validateMobileNumber(this.state.mobileNumber, this.coutryCodeHash[this.state?.selectedCountry?._id])) {
                                                this.setState({ invalidMobileNumber: true })
                                            } else {
                                                this.setState({ invalidMobileNumber: false })
                                            }
                                        }}
                                        maxLength={150}
                                    />

                                    {this.state.mobileNumber && this.state.invalidMobileNumber ? <div className="invalid-message mt-2">
                                        {/* Enter valid Mobile number */}
                                        {this.staticText.invalidMobileMessage}
                                    </div> : <></>}
                                    {
                                        this.state.userAlreadyExist ? <div className="invalid-message mt-2">{this.staticText.userExistMessage}</div> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col">
                                    <label className="cw-input-label-1 mb-3">City</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <input className="cw-input-1"
                                        placeholder="Enter your City" onClick={() => this.openCityModal()}
                                        value={this.state.city}

                                        onKeyUp={event => this.onDeleteCity(event)} />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col">
                                    <label className="cw-input-label-1 mb-3">My Email ID</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <input className="cw-input-1"
                                        placeholder={this.staticText.emailPlaceHolder}
                                        onChange={(event) => this.setState({ email: event.target.value })}
                                        value={this.state.email}
                                        onFocus={() => this.setState({ invalidEmail: false })}
                                        onBlur={() => {
                                            if (this.state.email && !CommonUtils.validateEmail(this.state.email)) {
                                                this.setState({ invalidEmail: true })
                                            }
                                        }}
                                        maxLength={150} />
                                    <span className="optional-text">OPTIONAL</span>
                                </div>

                            </div>
                            {this.state.email && this.state.invalidEmail ? <div className="invalid-message">
                                {this.staticText.invalidEmailMessage}
                            </div> : <></>}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center ">
                    <div className="text-center back-button cursor-pointer mr-5" onClick={() => this.goBackToWelcome()}>Back</div>
                    {this.getButtons()}
                </div>
            </div>
        )
    }

    renderOtpScreen() {
        return (
            <>
                {
                    this.state.isOtpSent ?

                        <div>
                            <div className="heading mb-3">OTP</div>
                            <div className="otp-wrapper">
                                <div className="mb-3">OTP Sent to {`${this.state.selectedCountry.dialCode} ${this.state.mobileNumber}`}</div>
                                <div>
                                    <OTP delegate={this} isInvalidOtp={this.state.invalidOtp} />
                                </div>
                                {
                                    this.state.invalidOtp ?
                                        <div className="custom-toast-for-invalid-otp">
                                            <CustomToaster toasterMessage="Incorrect OTP, try again" type="error" />
                                        </div> : ''
                                }
                            </div>

                            <div className="d-flex justify-content-center">
                                <div className="text-center back-button cursor-pointer mr-5" onClick={() => this.goBackToSignupScreen()}>Back</div>
                                {this.getButtons()}
                            </div>

                        </div>
                        : ''
                }
            </>
        )
    }

    renderPasscodeScreen() {
        return (
            <div>
                <div className="heading">Passcode</div>
                <div className="d-flex justify-content-start align-items-center">
                    <div><img className="info-size" src={getImageSource(ImageConstants.INFO_ICON)} /></div>
                    <div className="info-text ml-3 mb-3 mt-3">Your passcode will be the key to access your Countingwell account. So think of a unique 4 digit number and use it as a passcode to login.</div>
                </div>
                <div className="pass-code-wrapper">
                    <Passcode showPasscodeNotMatch={() => this.showPasscodeNotMatch()}
                        confirmPasscode={((passcode, value) => this.confirmPasscode(passcode, value))} />
                    {
                        this.state.showPasscodeNotMatch ?
                            <div className="custom-toast-for-invalid-passcode">
                                <CustomToaster toasterMessage="Passcode does mot match" type="error" />
                            </div>
                            :
                            ""
                    }
                </div>
                <div className="text-center agree-text">By signing up I agree to the <span className="conditions-text cursor-pointer" onClick={() => this.showTermsAndConditionsModal()}>Terms and conditions</span></div>
                <div className="d-flex justify-content-center">
                    <div className="text-center back-button cursor-pointer mr-5" onClick={() => this.goBackToSignupScreen()}>Back</div>
                    {this.getButtons()}
                </div>
            </div>
        )
    }

    renderClassDetailsScreen() {
        return (
            <>
                <ClassDetails
                    boardList={this.state.boardList}
                    classList={this.state.activeClassList}
                    schoolDetails={this.state.schoolDetails}
                    showSchoolDetails={this.state.schoolDetails ? true : false}
                    onPressContinue={() => this.showNoOfDaysPracticeScreen()}
                />
            </>
        )
    }

    renderNoOfDaysPracticeScreen() {
        return (
            <SelectDays
                onPressContinue={(selectedDays) => this.onPressStep4Continue(selectedDays)}
                goBackToClassDetailsScreen={() => this.goBackToClassDetailsScreen()} />
        )
    }

    renderSelectTimeScreen() {
        return (
            <SelectTime
                onPressContinue={(selectedTime) => this.onPressStep5Continue(selectedTime)}
                goBackToClassDetailsScreen={() => this.goBackToNoOfDaysPractice()} />
        )
    }

    renderDailyGoalScreen() {
        return (
            <DailyGoal onPressContinue={(days) => this.onPressStep6Continue(days)}
                goBackToSelectTimeScreen={() => this.goBackToSelectTimeScreen()} />
        )
    }



    renderSelectLevelScreen() {
        return (
            <SelectLevel onPressContinue={(level) => this.onPressStep7Continue(level)}
                goBackToDailyGoalScreen={() => this.goBackToDailyGoalScreen()} />
        )
    }

    renderAppIntroVideoScreen() {
        return (
            <AppIntroVideo onPressSkip={() => this.onPressSkip()} />
        )
    }


    renderTermsAndConditionsModal() {
        return (
            <Modal className="cw-modal-center" isOpen={true} size={'lg'} >
                <ModalBody>
                    <div className="d-flex  justify-content-between align-items-center" >
                        <div className="terms">Terms and Conditions</div>
                        <div><img className="close-icon-size cursor-pointer" src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.hideTermsAndConditions()} /></div>
                    </div>
                    <img className="header-divider" src={getImageSource(ImageConstants.TITLE_DIVIDER)} />
                    <div className="content-wrapper">
                        {this.state.content ? this.state.content.split("\n\n").map((item, index) =>

                            item.split("\n").map((content, index) =>

                                content.trim().length ? <div className={`mb-2 content-size ${index == 0 ? 'overview' : ''}`}>{content}</div> : <></>
                            )

                        )
                            :
                            <></>}
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="text-center continue-button cursor-pointer mr-5" onClick={() => this.hideTermsAndConditions()}>Continue</div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    showSchoolConfirmationScreen() {
        return (
            <div className="school-confirmation-wrapper">
                <div className="d-flex justify-content-center flex-column">
                    <div className="align-self-center from-text">Are you from</div>
                    <div className="align-self-center school-name">{this.state.schoolDetails.name}</div>
                    <div className="align-self-center school-branch">{`${this.state.schoolDetails.locality}, ${this.state.schoolDetails.city} ?`}</div>
                </div>

                <div className="d-flex justify-content-center button-wrapper">
                    <div className="text-center yes-button cursor-pointer mr-5" onClick={() => this.goToSignupScreen(false, this.state.schoolDetails)}>
                        YES THAT'S CORRECT
                    </div>
                    <div className="text-center no-button cursor-pointer" onClick={() => this.goToSignupScreen(false, null)}>THAT'S NOT MY SCHOOL</div>

                </div>

            </div>
        )
    }




    render() {
        return (
            <div className="student-signup container-fluid">
                <div className="container-height">
                    <div className="header-abs-div">
                        <img className="login-header" src={getImageSource(ImageConstants.LOGIN_HEADER)} />
                    </div>
                    {
                        this.state.showStudentSignupScreen ||
                            this.state.showOtpScreen ||
                            this.state.showPasscodeScreen ||
                            this.state.showClassDetailsScreen ||
                            this.state.showNoOfDaysPracticeScreen ||
                            this.state.showSelectTimeScreen ||
                            this.state.showDailyGoalScreen ||
                            this.state.showSelectLevelScreen && !this.state.showAppIntroVideoScreen ?
                            <div className="d-flex flex-coloum justify-content-center align-items-center dash-wrapper">
                                {
                                    this.dashes.map(item => (
                                        <div className={`dash-colour 
                                        ${this.state.signupScreen === item.step ? 'enable-dash' : ''}
                                        ${this.state.otpScreenDash === item.step ? 'enable-dash' : ''}
                                        ${this.state.showClassDetailsScreenDash === item.step ? 'enable-dash' : ''}
                                        ${this.state.showNoOfDaysPracticeScreenDash === item.step ? 'enable-dash' : ''}
                                        ${this.state.showSelectTimeScreenDash === item.step ? 'enable-dash' : ''}
                                        ${this.state.showDailyGoalScreenDash === item.step ? 'enable-dash' : ''}
                                        ${this.state.showSelectLevelScreenDash === item.step ? 'enable-dash' : ''}`}>
                                            {''}
                                        </div>
                                    ))
                                }
                            </div>
                            : ''
                    }
                    <div className="signup-wrapper">
                        {
                            !this.state.showStudentSignupScreen &&
                                !this.state.showOtpScreen &&
                                !this.state.showPasscodeScreen &&
                                !this.state.showClassDetailsScreen &&
                                !this.state.showNoOfDaysPracticeScreen &&
                                !this.state.showSelectTimeScreen &&
                                !this.state.showDailyGoalScreen &&
                                !this.state.showSelectLevelScreen &&
                                !this.state.showAppIntroVideoScreen
                                ? this.state.showSchoolConfirmationScreen ? this.showSchoolConfirmationScreen() : this.welcomeScreen() : ''
                        }
                        {
                            this.state.showStudentSignupScreen ? this.studentSignupScreen() : ''
                        }
                        {
                            this.state.showOtpScreen ? this.renderOtpScreen() : ''
                        }
                        {
                            this.state.showPasscodeScreen ? this.renderPasscodeScreen() : ''
                        }
                        {
                            this.state.showClassDetailsScreen ? this.renderClassDetailsScreen() : ''
                        }
                        {
                            this.state.showNoOfDaysPracticeScreen ? this.renderNoOfDaysPracticeScreen() : ''
                        }
                        {
                            this.state.showSelectTimeScreen ? this.renderSelectTimeScreen() : ''
                        }

                        {
                            this.state.showDailyGoalScreen ? this.renderDailyGoalScreen() : ''
                        }
                        {
                            this.state.showSelectLevelScreen ? this.renderSelectLevelScreen() : ''
                        }
                        {
                            this.state.showAppIntroVideoScreen ? this.renderAppIntroVideoScreen() : ''
                        }

                    </div>

                    <div className="footer-abs-div">
                        <img className="login-footer" src={getImageSource(ImageConstants.LOGIN_FOOTER)} />
                    </div>
                </div>

                {
                    this.state.showCityModal ? <CityModal delegate={this} /> : ''
                }
                {/* {
                    this.state.showSendOtpConfirmationModal ?
                        <CwConfirmationModal
                            showSendOtpConfirmationPopup={this.state.showSendOtpConfirmationModal}
                            showPasscodeScreen={(skipOtp) => this.showPasscodeScreen(skipOtp)}
                            sendOtp={() => this.signUp()}
                            closeModal={() => this.hideSendOtpConfirmaitonModal()} />
                        :
                        ''
                } */}

                {
                    this.state.showTermsAndConditionsModal ? this.renderTermsAndConditionsModal() : ''
                }

            </div>
        )
    }

}

export default StudentSignup;
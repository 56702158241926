import React from "react";
import './clicker-assessment-engine.scss';
import Loading from "../../common/loading/loading";
// import AssessmentHeader from "../assessment-header/assessment-header";
import AssessmentEngineShared from "../../../../shared-storage/assessment-engine";
import SharedProfile from "../../../../shared-storage/profile";
import AssessmentApi from "../../../apis/assessment";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
// import AssessmentPagination from "../assessment-pagination/assessment-pagination";
import Question from "../question/question";
// import FlagPopup from "../flag-popup/flag-popup";
// import AssessmentEndPopup from "../assessment-end-popup/assessment-end-popup";
import { Constants, FirebaseEvents } from "../../../constants/constants";
import { MyAssessmentContext } from "../clicker-assessment-context/my-assessment-context/clicker-assessment-context";
import HomeworkApi from "../../../apis/homework";
import FireBaseEvent from '../../../firebase-events/firebase-events';
import CommonUtils from "../../../utility/common-utilities";
import StudentAnsweredStatusBoard from "../student-answered-status-board/student-answered-status-board";
// export const MyAssessmentContext = React.createContext({
//     showFinishFooter: false,
//     isAssessment: false,
//     disableNext: false
// })


let onlyonce = false;


class ClickerAssessmentEngine extends React.Component {

    constructor(props) {
        super(props);

        AssessmentEngineShared.setAssessmentEngine(this)

        this.userInfo = SharedProfile.getProfile();

        this.meta = this.props.meta

        this.chapterKey = this.meta.chapterKey;
        this.questionSetId = this.meta.questionSetId;
        // this.questionSetId = '66d6b60d6c7da05dcf39ec6c';

        this.chapterTitle = this.meta.chapterTitle
        this.categoryThemeId = this.meta.categoryThemeId;

        //console.log(this.categoryThemeId)

        if (this.meta.selectedChapters) {

            //console.log('::::::selected chapters', this.meta.selectedChapters)

            for (let i = 0; i < this.meta.selectedChapters.length; i++) {
                this.selectedChaptersHash[this.meta.selectedChapters[i].key] = this.meta.selectedChapters[i]
            }
        }

        console.log(this.meta)

        if (this.meta.isResume && this.meta.assessmentActivityId) {

            if (this.meta && (this.meta.from == Constants.EVENT_BASELINE || this.meta.from == Constants.REWARD_EVENT_BASELINE || this.meta.from == Constants.TWO_STEP_EVENT_BASELINE)) {

                // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()

                // if (!this.userInfo.isSubscribed)
                //     this.landingScreenInstance.pauseUserAppTime()

            }

            if (this.meta && this.meta.from == Constants.SCHOLARSHIP_EVENT || this.meta.from == Constants.REWARD_EVENT_FINAL || this.meta.from == Constants.TWO_STEP_EVENT_FINAL) {
                // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()

                // if (!this.userInfo.isSubscribed)
                //     this.landingScreenInstance.pauseUserAppTime()
            }

            this.assessmentActivityId = this.meta.assessmentActivityId

            let payload = {
                assessmentActivityId: this.assessmentActivityId,
                isResume: true
            }

            this.getAssessement(payload)
        } else {

            // if(onlyonce) return
            // onlyonce = true

            let payload = {
                chapterKey: this.chapterKey,
                questionSetId: this.questionSetId
            }

            if (this.meta.selectedDuration) {
                payload['duration'] = this.meta.selectedDuration
            }

            if (this.meta.type == 'EVENT') {
                payload.type = 'EVENT'
                payload.assessmentId = this.meta.assessmentId;
            }

            if (this.meta && this.meta.from == Constants.REFRESHER_COURSE) {
                payload.type = Constants.REFRESHER_COURSE
            }

            if (this.meta && this.meta.from == Constants.SCHOLARSHIP_MAY_9_2021) {
                payload.type = Constants.SCHOLARSHIP_MAY_9_2021
            }

            if (this.meta && (this.meta.from == Constants.EVENT_BASELINE || this.meta.from == Constants.REWARD_EVENT_BASELINE || this.meta.from == Constants.TWO_STEP_EVENT_BASELINE)) {
                payload.type = Constants.EVENT_BASELINE
                payload.eventId = this.meta.eventId

                // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()

                // if (!this.userInfo.isSubscribed)
                //     this.landingScreenInstance.pauseUserAppTime()
            }

            if (this.meta && this.meta.from == Constants.SCHOLARSHIP_EVENT || this.meta.from == Constants.REWARD_EVENT_FINAL || this.meta.from == Constants.TWO_STEP_EVENT_FINAL) {
                payload.type = Constants.SCHOLARSHIP_EVENT
                payload.eventId = this.meta.eventId

                // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()

                // if (!this.userInfo.isSubscribed)
                //     this.landingScreenInstance.pauseUserAppTime()
            }

            if (this.meta && this.meta.from == Constants.MPL_EVENT) {
                payload.type = Constants.MPL_EVENT
                payload.assessmentId = this.meta.assessmentId;
                payload.eventId = this.meta.eventId
            }

            if (this.meta && this.meta.isFromMyHomework) {
                payload.isFromMyHomework = true
                payload.homeworkActivityId = this.meta.homeworkActivityId
            }

            if (this.meta && this.meta.from && this.meta.from == "MOCK_TEST") {
                payload.type = "MOCK_TEST"
                payload.assessmentId = this.meta.assessmentId;
                payload.eventId = this.meta.eventId
            }

            if (this.meta && this.meta.isFromPracticeCaseStudy) {
                payload.isFromPracticeCaseStudy = true
                payload.practiceActivityId = this.meta.practiceActivityId
            }

            if (this.meta && this.meta.from == 'CLASS_MOCK_TEST') {
                payload.type = 'CLASS_MOCK_TEST'
            }

            if (this.meta && this.meta.from == 'SELECTED_CHAPTER_MOCK_TEST') {
                payload.type = 'SELECTED_CHAPTER_MOCK_TEST'
            }

            if (this.meta && this.meta.from == 'SCIENCE_CLASS_MOCK_TEST') {
                payload.type = 'SCIENCE_CLASS_MOCK_TEST'
            }

            //console.log("Called")
            payload.type = 'CLICKER_ASSESSMENT'

            let userIds = []

            this.props.studentList.map(item => {
                if (!item.isTeacher) {
                    userIds.push(item.userId)
                }
            })

            if (userIds.length) {
                payload.userIds = userIds.join(',')
            }

            this.getAssessement(payload, true)
        }

    }


    state = {
        questions: [],
        studentList: this.props.studentList,

        // questions: [
        //     // {
        //     //     "id": "618222ff2f081330c715fc12"
        //     // },
        //     // {
        //     //     "id": "618223902f081330c715fc16"
        //     // },
        //     // {
        //     //     "id": "618225092f081330c715fc1a"
        //     // },
        //     // {
        //     //     "id": "618225612f081330c715fc1d"
        //     // },
        //     // {
        //     //     "id": "618225c12f081330c715fc20"
        //     // },
        //     // {
        //     //     "id": "618226332f081330c715fc23"
        //     // },
        //     // {
        //     //     "id": "6182269f2f081330c715fc26"
        //     // },
        //     // {
        //     //     "id": "618227302f081330c715fc29"
        //     // },
        //     // {
        //     //     "id": "618227a52f081330c715fc2c"
        //     // },
        //     // {
        //     //     "id": "618228272f081330c715fc2f"
        //     // },
        //     // {
        //     //     "id": "6182326a2f081330c715fc3c"
        //     // },
        //     // {
        //     //     "id": "6182337a2f081330c715fc3f"
        //     // },
        //     // {
        //     //     "id": "618235032f081330c715fc42"
        //     // }
        // ],
        index: 0,
        showFlagPopUp: false,
        showAssessEndPopUp: false,
        remainingTime: 0,
        showTimeUpPopUp: false,
        isFlaggedContentRendering: false,
        currentFlaggedIndex: 0,
        showFinishFooter: false,
        isAssessment: true,
        stepIndex: 0,
        questionDetailsHash: {},
        disableNext: false,
        loading: false,
        currentTime: 0,
        attachments: {},
        questionLoading: true
    };

    flaggedContents = []

    CONTENT_FETCH_LIMIT = 1;
    selectedChaptersHash = {}

    currentTime = 0

    componentDidMount() {

        setTimeout(() => {
            this.setState({ loading: true }, () => {
                this.setState({ loading: false })
            })
        }, 100)
    }


    getAssessement = (payload, assessmentStart) => {
        //console.log(assessmentStart)
        payload = payload ? payload : {}

        AssessmentApi.getMyClickerAssessmentContent(payload).then((res) => {
            //console.log(res)
            if (assessmentStart && this.meta && this.meta.from == Constants.SCHOLARSHIP_MAY_9_2021 && res && res.eventEnd) {
                // this.props.navigation.navigate('LandingScreen')
                //console.log('inside SCHOLARSHIP_MAY_9_2021 ')
                return
            }

            if (res && res.startTime) {
                this.startTime = res.startTime
                this.duration = res.duration
            }

            this.assessmentActivityId = res.assessmentActivityId;
            // this.setState({remainingTime : res.remainingTime})
            this.state.remainingTime = res.remainingTime;

            this.ignoreTimeLimit = res && res.ignoreTimeLimit ? true : false

            if (res.questions && res.questions.length) {

                if (res.resumeId) {

                    for (let i = 0; i < res.questions.length; i++) {
                        if (res.questions[i].id == res.resumeId) {
                            this.setState({ index: i, loading: false }, () => {
                                setTimeout(() => {
                                    if (this.assessmentPaginationComponent) {
                                        this.assessmentPaginationComponent.scrollTo()
                                    }
                                }, 300)

                            })
                            break;
                        }
                    }
                }

                this.getContents(res.questions, this.state.index, (err, response) => {

                    this.setState({
                        questions: res.questions,
                        showTimeUpPopUp: res.remainingTime == 0 ? true : false
                    }, () => {
                        // this.timer()
                        // this.setState({questionLoading : true})
                    })

                    this.currentTime = res.remainingTime
                    this.setState({ currentTime: res.remainingTime })
                    if (err)
                        return;
                })
            }

        }).catch(err => {
            //console.log(err)
            if (err) {
                if (err.response && err.response.data && err.response.data.code == 1701) {
                    this.setState({ showTimeUpPopUp: true })

                    if (err.response.data.response && err.response.data.response.questions) {

                        this.getContents(err.response.data.response.questions, this.state.index, (error, response) => {

                            if (error)
                                return;

                            this.setState({
                                questions: [], showTimeUpPopUp: true
                            }, () => {

                            })

                        })

                    }
                }
                return;
            }


        })
    }

    getContents = (questions, index, callback) => {


        if (this.meta && this.meta.isFromMyHomework) {
            this.getHomeworkAssessmentContents(questions, index, callback)
            return
        }

        let ids = [];

        for (let i = 0; i < questions.length; i++) {

            if (index <= i) {
                if (ids.length < this.CONTENT_FETCH_LIMIT) {
                    ids.push(questions[i].id)
                }
            }
        }

        let payload = { _id: ids }

        AssessmentApi.getContentList(payload).then((res) => {
            let questionDetailsHash = this.state.questionDetailsHash;
            //console.log(res)

            for (let i = 0; i < res.response.length; i++) {

                let content = res.response[i]
                //console.log(content)

                questionDetailsHash[content._id] = content;

            }

            this.setState({ questionDetailsHash })

            callback && callback(null, res)
        }).catch(err => {
            if (err) {
                callback && callback(err, null)
                return;
            }
        })
    }

    unFlagTheQuestion = () => {

        this.updateActivity({ isFlagged: false }, (err, res) => {

            if (err)
                return;

            let questions = this.state.questions;
            questions[this.state.index]['isFlagged'] = false;
            this.setState({ questions })

        })
    }

    flagTheQuestion = () => {

        this.updateActivity({ isFlagged: true }, (err, res) => {

            if (err)
                return;

            let questions = this.state.questions;
            questions[this.state.index]['isFlagged'] = true;
            this.setState({ questions })

        })
    }


    getHomeworkAssessmentContents = (questions, index, callback) => {

        let ids = [];

        for (let i = 0; i < questions.length; i++) {

            if (index <= i) {
                if (ids.length < this.CONTENT_FETCH_LIMIT) {
                    ids.push(questions[i].id)
                }
            }
        }

        let payload = { ids, questions }

        HomeworkApi.getHomeworkAssessmentContents(payload).then((res) => {
            //console.log(res)

            let questionDetailsHash = this.state.questionDetailsHash;

            for (let i = 0; i < res.data.response.length; i++) {

                let content = res.data.response[i]

                questionDetailsHash[content._id] = content;

            }

            //console.log(questionDetailsHash)

            this.setState({ questionDetailsHash })

            callback && callback(null, res)
        }).catch(err => {
            if (err) {
                callback && callback(err, null)
                return;
            }

        })
    }

    next = () => {

        console.log('next called')

        let answer = this.questionComponent && this.questionComponent.getAnswer();

        console.log(answer.answer)

        //let question = this.state.questions[this.state.index].details;
        let question = this.state.questionDetailsHash[this.state.questions[this.state.index].id];

        if (answer) {
            this.state.questions[this.state.index]['answer'] = answer
            // this.state.questions[this.state.index]['attachments'] = this.state.attachments[this.state.index] ? this.state.attachments[this.state.index] : []
            let payload = {
                answer
            }

            if (this.state.questions[this.state.index].attachments && this.state.questions[this.state.index].attachments.length) {
                this.state.attachments[this.state.index] = this.state.questions[this.state.index].attachments
            }

            // if (this.meta && this.meta.isFromMyHomework && question && question.mandatoryAttachments && question.mandatoryAttachments.image && question.mandatoryAttachments.image == true && (!this.state.attachments[this.state.index] || (this.state.attachments[this.state.index] && this.state.attachments[this.state.index].length == 0)) && question.content.isMultiStep && question.content.steps.length - 1 == this.state.stepIndex) {
            //     this.showMedia()
            //     return
            // } else if (this.meta && this.meta.isFromMyHomework && question && question.mandatoryAttachments && question.mandatoryAttachments.image && question.mandatoryAttachments.image == true && (!this.state.attachments[this.state.index] || (this.state.attachments[this.state.index] && this.state.attachments[this.state.index].length == 0)) && !question.content.isMultiStep) {

            //     this.showMedia()
            //     return
            // }

            if (this.state.attachments && this.state.attachments[this.state.index] && this.state.attachments[this.state.index].length) {
                payload['attachments'] = this.state.attachments[this.state.index] ? this.state.attachments[this.state.index] : []
                this.state.questions[this.state.index]['attachments'] = this.state.attachments[this.state.index] ? this.state.attachments[this.state.index] : []
            }


            if (payload.attachments) {
                this.getSignedUrlForattachments(payload, question)
                return
            }
            this.toggleDisableNext(true)
            this.updateActivity(payload, (err, res) => {

                //console.log('udpated the activity')

                if (err) {
                    this.toggleDisableNext(false)
                    return;
                }

                this.toggleDisableNext(false)

                /** If flagged content rendering while press next */
                if (this.state.isFlaggedContentRendering) {

                    if (this.state.currentFlaggedIndex !== this.flaggedContents.length - 1) {

                        if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex) { /** If question is multi step  */
                            this.setState({ stepIndex: this.state.stepIndex + 1 });
                        } else {
                            let nextFlaggedIndex = this.state.currentFlaggedIndex + 1

                            this.setState({
                                currentFlaggedIndex: nextFlaggedIndex,
                                index: this.flaggedContents[nextFlaggedIndex], stepIndex: 0, questionLoading: false
                            }, () => {
                                this.setState({ questionLoading: true })
                            })
                        }

                        return;

                    }
                }

                this.ShowCorrectIncorrectScreen()

                // if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex) { /** If question is multi step  */

                //     this.setState({ stepIndex: this.state.stepIndex + 1, questionLoading: false }, () => {
                //         this.setState({ questionLoading: true })
                //     });
                // } else {

                //     if (this.state.index !== this.state.questions.length - 1) {
                //         if (this.state.questionDetailsHash[this.state.questions[this.state.index + 1].id]) {

                //             //console.log('updated the existing content')

                //             this.setState({ index: this.state.index + 1, stepIndex: 0, questionLoading: false }, () => {
                //                 this.setState({ questionLoading: true })
                //             })

                //         } else {

                //             this.toggleDisableNext(true)

                //             //console.log('updated the content')

                //             this.getContents(this.state.questions, this.state.index + 1, (err, response) => {

                //                 if (err) {
                //                     this.toggleDisableNext(false)
                //                     return;
                //                 }

                //                 let studentList = this.props.studentList

                //                 studentList.forEach(item => {
                //                     item.receivedAnswer = false
                //                     item.answerId = ''
                //                 })

                //                 this.setState({ index: this.state.index + 1, stepIndex: 0, questionLoading: false, studentList }, () => {
                //                     this.setState({ questionLoading: true })
                //                 })
                //                 this.toggleDisableNext(false)
                //             })
                //         }


                //     } else {
                //         this.flaggedContents = this.getFlaggedContentIndex()
                //         if (this.meta && this.meta.from === 'CASE STUDY') {
                //             this.onFinish()
                //         } else {
                //             this.showAssessEndPopUp()
                //         }
                //     }
                // }
            })
        } else {
            // console.log('inside correct answer')
            // this.ShowCorrectIncorrectScreen()
            // if (this.state.questions[this.state.index].isFlagged) {

            //     /** If flagged content rendering while press next */
            //     if (this.state.isFlaggedContentRendering) {

            //         if (this.state.currentFlaggedIndex !== this.flaggedContents.length - 1) {

            //             let nextFlaggedIndex = this.state.currentFlaggedIndex + 1

            //             this.setState({
            //                 currentFlaggedIndex: nextFlaggedIndex,
            //                 index: this.flaggedContents[nextFlaggedIndex], stepIndex: 0, questionLoading: false
            //             }, () => {
            //                 this.setState({ questionLoading: true })
            //             })

            //             return;

            //         }
            //     }

            //     if (this.state.index !== this.state.questions.length - 1) {

            //         if (this.state.questionDetailsHash[this.state.questions[this.state.index + 1].id]) {

            //             this.setState({ index: this.state.index + 1, stepIndex: 0, questionLoading: false }, () => {
            //                 this.setState({ questionLoading: true })
            //             })

            //         } else {
            //             this.toggleDisableNext(true)
            //             this.getContents(this.state.questions, this.state.index + 1, (err, response) => {

            //                 if (err) {
            //                     this.toggleDisableNext(false)
            //                     return;
            //                 }

            //                 this.setState({ index: this.state.index + 1, stepIndex: 0, questionLoading: false }, () => {
            //                     this.setState({ questionLoading: true })
            //                 })
            //                 this.toggleDisableNext(false)
            //             })

            //         }

            //     } else {
            //         this.flaggedContents = this.getFlaggedContentIndex()
            //         if (this.meta && this.meta.from === 'CASE STUDY') {
            //             this.onFinish()
            //         } else {
            //             this.showAssessEndPopUp()
            //         }
            //     }

            // } else {

            //     if (this.meta && this.meta.from === 'CASE STUDY') {
            //         this.toggleInvalidAnswerModal(true)
            //     } else {
            //         this.showFlagPopUp()
            //     }
            // }
        }
    }


    updateActivity = (payload, callback) => {

        let questionId = this.state.questions[this.state.index].id;

        payload = {
            assessmentActivityId: this.assessmentActivityId,
            questionId,
            ...payload
        }

        AssessmentApi.updateMyClickerAsssemetActicity(payload).then((res) => {
            callback(null, res)
        }).catch(err => {
            callback(err, null)
        })
    }

    toggleDisableNext = (value) => {
        this.setState({ disableNext: value })
    }

    ShowCorrectIncorrectScreen = (isCorrect) => {
        this.isCorrect = isCorrect;
        this.setState({ showCorrectIncorrectScreen: true, questionLoading: false }, () => {
            this.setState({ questionLoading: true })
        })
    }

    hideCorrectIncorrectScreen() {
        this.setState({ showCorrectIncorrectScreen: false });
    }

    nextQuestionOnCorrectInCorrectAnswer() {

        // if (this.question.content.isMultiStep) {
        //     //console.log('continue 200', this.question)
        //     if (this.isCorrect)
        //         this.nextContinue(this.question, this.answer, false);
        //     else
        //         this.nextContinue(this.question, this.answer, true);

        // } else {
        //console.log('normal next continue called...')
        this.nextContinue();
        // }

        this.setState({ showCorrectIncorrectScreen: false, isReadOnly: false, questionLoading : false });
    }


    nextContinue = () => {
        let question = this.state.questionDetailsHash[this.state.questions[this.state.index].id];

        if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex) { /** If question is multi step  */

            this.setState({ stepIndex: this.state.stepIndex + 1, questionLoading: false }, () => {
                this.setState({ questionLoading: true })
            });
        } else {

            if (this.state.index !== this.state.questions.length - 1) {
                if (this.state.questionDetailsHash[this.state.questions[this.state.index + 1].id]) {

                    //console.log('updated the existing content')

                    this.setState({ index: this.state.index + 1, stepIndex: 0, questionLoading: false }, () => {
                        this.setState({ questionLoading: true })
                    })

                } else {

                    this.toggleDisableNext(true)

                    //console.log('updated the content')

                    this.getContents(this.state.questions, this.state.index + 1, (err, response) => {

                        if (err) {
                            this.toggleDisableNext(false)
                            return;
                        }

                        let studentList = this.props.studentList

                        studentList.forEach(item => {
                            item.receivedAnswer = false
                            item.answerId = ''
                        })

                        this.setState({ index: this.state.index + 1, stepIndex: 0, questionLoading: false, studentList }, () => {
                            this.setState({ questionLoading: true })
                        })
                        this.toggleDisableNext(false)
                    })
                }


            } else {
                // this.flaggedContents = this.getFlaggedContentIndex()
                // if (this.meta && this.meta.from === 'CASE STUDY') {
                //     this.onFinish()
                // } else {
                //     this.showAssessEndPopUp()
                // }
                this.onFinish()
            }
        }

    }




    onClickPage = (page) => {

        if (this.state.stepIndex) {
            this.setState({ stepIndex: 0 })
        }

        let answer = this.questionComponent && this.questionComponent.getAnswer();

        if (answer) {
            this.state.questions[this.state.index]['answer'] = answer
            let payload = {
                answer
            }
            this.updateActivity(payload, (err, res) => {
                if (err)
                    return;
                this.onClickPageContinue(page)
            })
        } else {

            this.onClickPageContinue(page)
        }
    }

    onClickPageContinue = (page) => {
        if (this.state.questionDetailsHash[this.state.questions[page - 1].id]) {

            this.setState({ index: page - 1, isFlaggedContentRendering: false, questionLoading: false }, () => {
                this.setState({ questionLoading: true })
            })
        } else {
            this.getContents(this.state.questions, page - 1, (err, response) => {

                if (err)
                    return;

                this.setState({ index: page - 1, isFlaggedContentRendering: false, questionLoading: false }, () => {
                    this.setState({ questionLoading: true })
                })
            })
        }
    }

    getFlaggedContentIndex = () => {
        let flaggedIndex = [];

        for (let i = 0; i < this.state.questions.length; i++) {
            let question = this.state.questions[i];

            if (question.isFlagged)
                flaggedIndex.push(i)
        }

        return flaggedIndex
    }

    showFlagPopUp = () => {
        //console.log('flag')
        this.setState({ showFlagPopUp: true })
    }

    hideFlagPopUp = (callback) => {
        this.setState({ showFlagPopUp: false }, () => callback && callback())
    }

    showAssessEndPopUp = () => {
        this.setState({ showAssessEndPopUp: true })
    }

    hideAssessEndPopUp = () => {
        this.setState({ showAssessEndPopUp: false })
    }

    onFinish = () => {

        let answer = this.questionComponent && this.questionComponent.getAnswer();

        if (answer) {
            this.state.questions[this.state.index]['answer'] = answer
            let payload = {
                answer
            }
            this.updateActivity(payload, (err, res) => {

                if (err)
                    return;

                this.onPressFinish()
            })

        } else {
            this.onPressFinish()
        }

    }


    onPressFlagYes = () => {
        let questions = this.state.questions;
        questions[this.state.index]['isFlagged'] = true;
        this.setState({ questions })

        this.updateActivity({ isFlagged: true }, (err, res) => {

            if (err)
                return;

            this.hideFlagPopUp(() => {

                if (this.state.index !== this.state.questions.length - 1) {

                    if (this.state.questionDetailsHash[this.state.questions[this.state.index + 1].id]) {
                        this.setState({ index: this.state.index + 1, stepIndex: 0, questionLoading: false }, () => {
                            this.setState({ questionLoading: true })
                        })
                    } else {

                        this.getContents(this.state.questions, this.state.index + 1, (err, response) => {

                            if (err)
                                return;

                            this.setState({ index: this.state.index + 1, stepIndex: 0, questionLoading: false }, () => {
                                this.setState({ questionLoading: true })
                            })
                        })

                    }
                } else {
                    //console.log('inside review of flagged assessment')

                    if (this.meta && this.meta.from === 'CASE STUDY') {
                        this.onFinish()
                    } else {
                        setTimeout(() => {
                            this.flaggedContents = this.getFlaggedContentIndex()
                            this.showAssessEndPopUp()
                        }, 500)
                    }

                }
            })
        })
    }


    onPressContinue = () => {
        if (this.state.questionDetailsHash[this.state.questions[this.flaggedContents[0]].id]) {
            this.setState({
                index: this.flaggedContents[0],
                showAssessEndPopUp: false,
                isFlaggedContentRendering: true,
                currentFlaggedIndex: 0,
                showFinishFooter: true, stepIndex: 0, questionLoading: false
            }, () => {
                this.setState({ questionLoading: true })
                if (this.assessmentPaginationComponent) {
                    this.assessmentPaginationComponent.scrollTo()
                }
            })
        } else {
            this.getContents(this.state.questions, this.flaggedContents[0], (err, response) => {

                if (err)
                    return;

                this.setState({
                    index: this.flaggedContents[0],
                    showAssessEndPopUp: false,
                    isFlaggedContentRendering: true,
                    currentFlaggedIndex: 0,
                    showFinishFooter: true, stepIndex: 0, questionLoading: false
                }, () => {

                    this.setState({ questionLoading: true })

                    if (this.assessmentPaginationComponent) {
                        this.assessmentPaginationComponent.scrollTo()
                    }
                })
            })
        }
    }

    assessMyAssessment = (callback) => {

        let payload = {
            assessmentActivityId: this.assessmentActivityId
        }


        if (this.meta.isFromMyHomework) {
            payload.homeworkActivityId = this.meta.homeworkActivityId
            payload.isFromMyHomework = true
        }

        if (this.meta.isFromPracticeCaseStudy) {
            payload.practiceActivityId = this.meta.practiceActivityId
            payload.isFromPracticeCaseStudy = true
        }



        if (!this.userInfo.prepkitGuaranteeAssessmentActivity || (this.userInfo.prepkitGuaranteeAssessmentActivity && this.userInfo.prepkitGuaranteeAssessmentActivity.class && this.userInfo.prepkitGuaranteeAssessmentActivity.class != this.userInfo.class)) {
            payload.guaranteeAssessment = true
        }

        let userIds = []

            this.props.studentList.map(item => {
                if (!item.isTeacher && item.userDeviceMacId.length === 17) {
                    userIds.push(item.userId)
                }
            })

            let totalActiveClickers = userIds.length

            payload.totalActiveClickers = totalActiveClickers


        AssessmentApi.assessMyClickerAssessment(payload).then((res) => {
            callback(null, res)
        }).catch(err => {
            callback(err, null)
        });

    }

    onPressFinish = () => {
        console.log('finish Pressed')
        this.hideAssessEndPopUp()
        this.assessMyAssessment((err, res) => {
            console.log(err)
            if (err)
                return
            console.log('completed assessment', this.meta, this.props.delegate.showAssessmentEndScreen)
            // this.headerComponent.clearIntervalCustom()

            this.props.delegate && this.props.delegate.showAssessmentEndScreen && this.props.delegate.showAssessmentEndScreen({
                data: res.data,
                percentage: (res.data.markScored / res.data.totalMark) * 100,
                chapterTitle: this.chapterTitle,
                categoryThemeId: this.categoryThemeId,
                assessmentActivityId: this.assessmentActivityId,
                chapterKey: this.chapterKey,
                type: this.meta.type,
                eventId: this.meta.eventId,
                from: this.meta.from,
                selectedChapters: this.meta.selectedChapters,
                scholarshipEligibleStaticText: this.meta.scholarshipEligibleStaticText ? this.meta.scholarshipEligibleStaticText : {},
                eventPayload: this.meta && this.meta.eventPayload ? this.meta.eventPayload : {},
                isExamPrepKit: this.meta && this.meta.isExamPrepKit ? this.meta.isExamPrepKit : "",
                isFromMyHomework: this.meta && this.meta.isFromMyHomework,
                isDueDatePassed: this.meta && this.meta.isDueDatePassed,
                viewAnswerTime: this.meta && this.meta.viewAnswerTime,
                // from: this.meta.from
            })
        })
    }

    onPressReviewAnswers = () => {

        if (this.state.questionDetailsHash[this.state.questions[0].id]) {
            this.setState({ showFinishFooter: true, index: 0, stepIndex: 0, questionLoading: false }, () => {
                this.setState({ questionLoading: true })
                if (this.assessmentPaginationComponent) {
                    this.assessmentPaginationComponent.scrollTo()
                }
            })
            this.hideAssessEndPopUp()
        } else {
            this.getContents(this.state.questions, 0, (err, response) => {

                if (err)
                    return;

                this.setState({ showFinishFooter: true, index: 0, stepIndex: 0, questionLoading: false }, () => {
                    this.setState({ questionLoading: true })
                    if (this.assessmentPaginationComponent) {
                        this.assessmentPaginationComponent.scrollTo()
                    }
                })
                this.hideAssessEndPopUp()
            })
        }
    }

    onPressTimeUpContinue = () => {
        this.setState({ showTimeUpPopUp: false })
        this.onPressFinish()
    }

    setCurrentTime = (time) => {
        this.currentTime = time
        this.state.remainingTime = time
        this.setState({ currentTime: time })
    }


    onTimerEnd = () => {

        if (this.ignoreTimeLimit)
            return;

        if (this.state.showAssessEndPopUp) {
            this.setState({ showAssessEndPopUp: false }, () => {
                setTimeout(() => {
                    this.setState({ showTimeUpPopUp: true })
                }, 500)
            })
        } else
            this.setState({ showTimeUpPopUp: true })

    }

    toggleInvalidAnswerModal = (value) => {
        this.setState({ showInvalidAnswerModal: value, questionLoading: false }, () => {
            this.setState({ questionLoading: true })
        });
    }

    onOk = () => {
        this.toggleInvalidAnswerModal(false);
    }




    render() {

        // console.log(this.state.questionDetailsHash)
        // console.log(this.state.questions)

        let content = this.state.questions && this.state.questions.length ? this.state.questionDetailsHash[this.state.questions[this.state.index].id] : null

        // single select
        // let content = {
        //     "_id": "618222ff2f081330c715fc12",
        //     "title": "",
        //     "displayTitle": "",
        //     "type": 5,
        //     "typeName": "ASSESSMENT QUESTION",
        //     "content": {
        //         "type": 1,
        //         "typeName": "SINGLE SELECT",
        //         "title": "<p>In a class of <cw-math data=\"60\">60</cw-math> students, <cw-math data=\"\\frac{1}{5}\">\\frac{1}{5}</cw-math>of the total number of students play football, <cw-math data=\"\\frac{2}{5}\">\\frac{2}{5}</cw-math>&nbsp;of the total number play cricket and the remaining students play kho-kho.</p><p>&nbsp;</p><p>Find the number of students who plays each type of sports. is<cw-blank>_____</cw-blank><cw-blank>_____</cw-blank></p>",
        //         "images": [
        //             {
        //                 "imageUrl": "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/a12094d4-a791-4589-8de1-c0ba64418282-1.png"
        //             },
        //             // {
        //             //     "imageUrl" : "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/a12094d4-a791-4589-8de1-c0ba64418282-1.png"
        //             // }, 
        //             // {
        //             //     "imageUrl" : "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/a12094d4-a791-4589-8de1-c0ba64418282-1.png"
        //             // },
        //             // {
        //             //     "imageUrl" : "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/a12094d4-a791-4589-8de1-c0ba64418282-1.png"
        //             // }
        //         ],
        //         "complexityId": "5e8ebdf8d979fe12d6310080",
        //         "duration": 1,
        //         "mark": 1,
        //         "solutionSteps": {
        //             "type": "",
        //             "typeName": "",
        //             "question": [],
        //             "steps": [],
        //             "conclusion": "",
        //             "summarySolution": "<p>Smallest 5 digit number = 10,000</p><p> </p><p>10,000 – 1 = 9,999 which is the greatest 4 digit number.</p><p> </p><p>Hence, 1 subtracted from the smallest 5 digit number is the greatest 4 digit number.</p>"
        //         },
        //         "options": [
        //             {
        //                 "id": "163591851670916359185167099091",
        //                 "contentType": 1,
        //                 "contentTypeName": "TEXT",
        //                 "content": "<p>Greatest 4 digit number</p>",
        //                 "isCorrect": true
        //             },
        //             {
        //                 "id": "163591851904916359185190491407",
        //                 "contentType": 1,
        //                 "contentTypeName": "TEXT",
        //                 "content": "<p>Greatest 5 digit number</p>",
        //                 "isCorrect": false
        //             },
        //             {
        //                 "id": "163591852022616359185202264036",
        //                 "contentType": 1,
        //                 "contentTypeName": "TEXT",
        //                 "content": "<p>Smallest 4 digit number</p>",
        //                 "isCorrect": false
        //             },
        //             {
        //                 "id": "163591852220416359185222048748",
        //                 "contentType": 1,
        //                 "contentTypeName": "TEXT",
        //                 "content": "<p>Smallest 5 digit number</p>",
        //                 "isCorrect": false
        //             }
        //         ]
        //         // "options" : [
        //         //     {
        //         //         "id": "160488576789816048857678983828",
        //         //         "contentType": 1,
        //         //         "contentTypeName": "TEXT",
        //         //         "content": "<p><cw-math data=\"78\\frac{85}{69}\">78\\frac{85}{69}</cw-math>&nbsp;Test 10</p>",
        //         //         "isCorrect": true
        //         //     },
        //         //     {
        //         //         "id": "160488578944616048857894467479",
        //         //         "contentType": 1,
        //         //         "contentTypeName": "TEXT",
        //         //         "content": "<p>Test 20</p>",
        //         //         "isCorrect": false
        //         //     },
        //         //     {
        //         //         "id": "160578762402116057876240218094",
        //         //         "contentType": 2,
        //         //         "contentTypeName": "IMAGE",
        //         //         "content": "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/a12094d4-a791-4589-8de1-c0ba64418282-1.png",
        //         //         "isCorrect": false
        //         //     },
        //         //     {
        //         //         "id": "160933561372616093356137267309",
        //         //         "contentType": 2,
        //         //         "contentTypeName": "IMAGE",
        //         //         "content": "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/a12094d4-a791-4589-8de1-c0ba64418282-1.png",
        //         //         "isCorrect": false
        //         //     }
        //         // ]
        //     },
        //     "notes": {
        //         "error": "",
        //         "success": ""
        //     },
        //     "createdDate": 1635918591669,
        //     "createdBy": "",
        //     "updatedDate": 1635918591669,
        //     "updatedBy": "",
        //     "state": "",
        //     "author": "",
        //     "parallel": [],
        //     "hint": "",
        //     "comprehension": [],
        //     "mandatoryAttachments": {
        //         "audio": false,
        //         "video": false,
        //         "image": false
        //     },
        //     "countingwellCreatedTimestamp": "2021-11-03T05:49:51.677Z",
        //     "countingwellLastUpdateTimestamp": "2021-11-03T05:49:51.677Z"
        // }




        let categoryThemeId = this.categoryThemeId

        //console.log(content)

        return (


            !this.state.loading ?
                <div className="clicker-assessment-engine">
                    {/* <AssessmentHeader
                        title={this.chapterTitle}
                        categoryThemeId={categoryThemeId}
                        timer={true}
                        time={this.state.remainingTime}
                        delegate={this}
                        ref={(ref) => { this.headerComponent = ref }}
                        isFromPracticeCaseStudy={this.meta.isFromPracticeCaseStudy}
                    >
                    </AssessmentHeader> */}
                    {/* {
                        this.state.questions && this.state.questions.length ?
                            <AssessmentPagination
                                questions={this.state.questions}
                                activePage={this.state.index + 1}
                                delegate={this}
                                categoryThemeId={categoryThemeId}
                                ref={(ref) => { this.assessmentPaginationComponent = ref }}
                                isFromPracticeCaseStudy={this.meta.isFromPracticeCaseStudy}
                            />
                            :
                            <></>
                    } */}

                    {this.state.questionLoading ?
                        <div className="question-wrapper ml-5 mt-4">
                            {
                                this.state.questions && this.state.questions.length > 0 &&
                                content && <div>
                                    <MyAssessmentContext.Provider value={{ showFinishFooter: this.state.showFinishFooter, startIndex: this.state.index, isAssessment: this.state.isAssessment, disableNext: this.state.disableNext, showAlphabet: true }}>
                                        <Question delegate={this} ref={(ref) => { this.questionComponent = ref }}
                                            questions={this.state.questions}
                                            activePage={this.state.index + 1}
                                            studentList={this.state.studentList}
                                            // answer={this.state.questions[this.state.index].answer}
                                            content={content}
                                            stepIndex={this.state.stepIndex}
                                            showHint={this.state.showHint}
                                            showSolvedExamplesModal={this.state.showSolvedExamplesModal}
                                            showInvalidAnswerModal={this.state.showInvalidAnswerModal}
                                            showCorrectIncorrectModal={this.state.showCorrectIncorrectModal}
                                            showCorrectAnswer={this.state.showCorrectIncorrectScreen}
                                            isAnswerCorrect={this.isCorrect}
                                            categoryThemeId={categoryThemeId}
                                            isFromMyAssessment={true}
                                            isFFF = {this.props.isFFF}
                                            assessmentActivityId = {this.assessmentActivityId}
                                            isFromMyHomework={this.meta.isFromMyHomework}
                                            isFromPracticeCaseStudy={this.meta.isFromPracticeCaseStudy}
                                            practiceId={this.meta.practice && this.meta.practice.practiceId ? this.meta.practice.practiceId : ''}
                                        // attachments={this.state.questions[this.state.index].attachments ? this.state.questions[this.state.index].attachments : (this.state.attachments[this.state.index] ? this.state.attachments[this.state.index] : [])}
                                        ></Question>
                                    </MyAssessmentContext.Provider>
                                </div>
                            }
                        </div>
                        :
                        <Loading />
                    }

                    {/* {this.state.showFlagPopUp && <FlagPopup close={this.hideFlagPopUp} onPressYes={this.onPressFlagYes} categoryThemeId={categoryThemeId} />} */}
                    {/* {this.state.showAssessEndPopUp && <AssessmentEndPopup flaggedContent={this.flaggedContents} close={this.hideAssessEndPopUp} delegate={this} time={this.currentTime} categoryThemeId={categoryThemeId} liveTimer={this.state.currentTime}
                        isFromPracticeCaseStudy={this.meta.isFromPracticeCaseStudy} />} */}
                    {/* {this.state.showTimeUpPopUp && <AssessmentEndPopup type="TIMEUP_POPUP" onPressTimeUpContinue={this.onPressTimeUpContinue} categoryThemeId={categoryThemeId} isFromPracticeCaseStudy={this.meta.isFromPracticeCaseStudy} />} */}

                </div>
                :
                <Loading />
        )
    }

}

export default ClickerAssessmentEngine;
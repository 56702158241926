import React from "react";
import './summary-solution.scss';
import Parser from "../content-parser/content-parser";


class SummarySoultions extends React.Component {

    constructor(props) {
        super(props);
        // this.handleHardwareBackButton = this.handleHardwareBackButton.bind(this);
        this.parser = new Parser();
    }

    componentDidMount() {
        // BackHandler.addEventListener('hardwareBackPress', this.handleHardwareBackButton);
    }


    componentWillUnmount() {
        // BackHandler.removeEventListener('hardwareBackPress', this.handleHardwareBackButton);
    }


    handleHardwareBackButton() {
        return true;
    }


    render() {

        return (

            !this.props.isPopup ?
                <div className="mt-2 solution-margin-bottom" style={{}}>
                    {
                       this.props.solutionSteps && this.props.solutionSteps.summarySolution ?
                            this.parser.parseLearningContent(this.props.solutionSteps.summarySolution, null,null,null,null,this.props.categoryId ? this.props.categoryId :'' )                            
                            :
                            <></>
                    }
                </div>
                :
                <div className="mt-2 solution-margin-bottom" style={{}}>
                    {
                        this.props.solutionSteps && this.props.solutionSteps.summarySolution ?
                            this.parser.parseLearningContent(this.props.solutionSteps.summarySolution, null,null,null,null,this.props.categoryId ? this.props.categoryId :'' )
                            :
                            <></>
                    }
                </div>
        );
    }
}

export default SummarySoultions;
import React from 'react';
import './content-list-popup.scss';
import { getImageSource, getThemeImageSource } from '../../../../utility/image-source';
import { ImageConstants } from '../../../../constants/constants';
import SharedProfile from '../../../../../shared-storage/profile';
import SharedStaticText from '../../../../../shared-storage/static-text-store';
import SharedThemeCategory from '../../../../../shared-storage/category-theme';
import { Modal, ModalBody } from 'reactstrap';

export default class ContentListPopup extends React.Component {
    constructor(props) {
        super(props);
        this.staticText = SharedStaticText.getStaticText('contentListPopup');
        this.userInfo = SharedProfile.getProfile();

        this.state.categoryId = this.props.categoryId

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId);
    }

    state = {
        categoryId: "1"
    }

    onPressContinue = () => {
        this.props.delegate.closePopup();
    }


    render() {

        // setCategoryThemeStyles(this.props.categoryId)

        return (
            <Modal className="cw-common-custom-modal-center-for-rfc-content-list" isOpen={true} size={'lg'}>
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                    {/* {this.props.showTrialFeatures ? <img className='badge-image' src={getImageSource(ImageConstants.BADGE_IMAGE)} /> : ''} */}
                    {/* <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} /> */}
                </div>
                <ModalBody>
                    <div className="pratice-container">
                        <div className='message-text'>
                            {this.userInfo.name}, {this.staticText.text}
                        </div>
                        <div className='elo-title-container'>
                            <span className='elo-title'>{this.props.data.eloTitle ? this.props.data.eloTitle : ""}</span>
                        </div>
                        <img className='separator' src={getImageSource(ImageConstants.LEARNING_CONTENT_SEPARATOR)} />
                        <div className='chapter-detail-container text-center mb-5' style={{backgroundColor : this.theme.linearGradient.workoutEndCard[0]}}>
                            <div className='chapter-title'>{this.props.data.chapterTitle ? this.props.data.chapterTitle : ""}</div>
                            <div className='concept-title'>{this.props.data.conceptTitle ? this.props.data.conceptTitle : ""}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-around mt-2">
                            {/* <div className="continue-button cursor-pointer text-center" onClick={() => this.onClickClose()}>
                                    {`OK`}
                                </div> */}
                            <div className="take-challenge cursor-pointer  text-center" onClick={() => this.onPressContinue()}>
                                {this.staticText.continueButtonTitle}
                            </div>
                        </div>
                        <div className="position">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}


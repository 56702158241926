import React from "react";
import './matching.scss';
import CommonUtils from "../../../../utility/common-utilities";
import QuestionTitle from "../question-title/question-title";
import QuestionImage from "../../cw-question-image/cw-question-image";
import { getImageSource, getThemeImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import AssessmentFooter from "../../assessment-footer/assessment-footer";
import { Constants } from "../../../../constants/constants";
import Parser from "../../content-parser/content-parser";
import CWImageV2 from "../../cw-image-2/cw-image-2";
import SummarySoultions from "../../summary-solution/summary-solution";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import DotPagination from "../../dot-pagination/dot-pagination";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AnswerTab from "../../../my-assessment-result/question/answer-tab/answer-tab";
import ReactTooltip from "react-tooltip";



class Matching extends React.Component {

    state = {
        dropIndex: -1,
        draggedIndex: -1,
        options: [],
        occupiedDropZone: [],
        droppedItemsIndex: [],
        dragabbleItemsScroll: true,
        selectedItem: null,
        pan: { x: 0, y: 0 },
        scrollViewHeight: 0,
        activateResponder: false,
        touchMessage: null,
        sourceX: null,
        sourceY: null,
        sourcePreviewVisible: false,
        showSolution: true,
        reload: true,
        showOptions: false
    }

    dropZone = []; // To hold droppable Zones on load
    dropZoneMeasure = []; //Presist droppable Zones on reaload

    dragOverInfo = { index: -1 }

    scrollViewRef;
    scrollViewMeasure = {};

    options = [];
    suffledOptions = [];
    hashedOriginalOptions = {};
    selectedItem = {};
    hashedSuffleOptions = {};
    dragItemScrollTouchXY = null
    answerTickCount = 0

    isValid = false;

    dx = 0;
    dy = 0;

    constructor(props) {
        super(props);
        this.options = this.props.content.content.options;
        this.suffledOptions = CommonUtils.shuffleArray(JSON.parse(JSON.stringify(this.props.content.content.options)));
        this.props.content.content.options.map((item, index) => { this.hashedOriginalOptions[item.id] = index });
        this.suffledOptions.map((item, index) => { this.hashedSuffleOptions[item.id] = index });
        this.parser = new Parser();
        this.staticText = SharedStaticText.getStaticText('multiStepQuestion');

        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');
        // this.originalContent = JSON.parse(JSON.stringify((this.props.content)));

        if (this.props.isReadOnly) {
            let content = JSON.stringify(this.props.content)
            this.state.content = JSON.parse(content);

        }
    }

    componentDidMount() {
        if (this.props.isReadOnly) {
            this.setState({ status: `${this.props.userAnswerStatus ? "CORRECT" : "IN_CORRECT"}` })
        }

        if (!this.props.isReadOnly) {
            this.setState({ content: this.props.content });
            this.startTimerforAnswer()
        }
    }

    startTimerforAnswer() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }
        this.timerIntervalId = setInterval(() => {

            this.answerTickCount += 1
        }, 1000)
    }

    getContentUsingContentType(option, type) {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (option[type].contentType == Constants.CONTENT_TYPE_TEXT) {
            return this.parser.parseWithStyles(`${option[type].content}`, { p: styles.p, 'cw-math': styles.p })
        } else if (option[type].contentType == Constants.CONTENT_TYPE_IMAGE) {
            //console.log(option[type].content)
            return <CWImageV2 uri={option[type].content}></CWImageV2>
        }

    }





    setSelectedAnswer(draggedIndex, dropIndex, dragSuffleIndex) {
        this.setState({
            draggedIndex: draggedIndex, dropIndex: dropIndex
        });

        let occupiedDropZone = [...this.state.occupiedDropZone];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        occupiedDropZone[dropIndex] = { index: draggedIndex, item: this.options[draggedIndex] };
        droppedItemsIndex.push(dragSuffleIndex);

        this.setState({
            occupiedDropZone: occupiedDropZone,
            droppedItemsIndex: droppedItemsIndex,
            dragabbleItemsScroll: true
        })

        // //console.log("draggedIndex, dropIndex", draggedIndex, dropIndex);
        this.validateAndDelegate(occupiedDropZone);
        this.dragInfo = {}
        this.dragOverInfo = { index: -1 }
    }

    onDroppedItemRemove = (itemIndex, dropIndex, optionId) => {
        let occupiedDropZone = [...this.state.occupiedDropZone];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        //console.log(droppedItemsIndex)

        occupiedDropZone[dropIndex] = null;
        // droppedItemsIndex = droppedItemsIndex.filter((item) => item != itemIndex);
        droppedItemsIndex = droppedItemsIndex.filter((item) => item != this.hashedSuffleOptions[optionId]);
        //console.log(droppedItemsIndex)

        this.setState({
            occupiedDropZone: occupiedDropZone,
            droppedItemsIndex: droppedItemsIndex
        })

        this.validateAndDelegate(occupiedDropZone);
    }

    validateAndDelegate(occupiedDropZone) {

        let total = 0;

        for (let i = 0; i < occupiedDropZone.length; i++) {
            if (occupiedDropZone[i])
                total = total + 1;
        }

        if (this.props.content.content.options.length == total) {
            this.isValid = true;
            this.props.delegate && this.props.delegate.enableNavigation && this.props.delegate.enableNavigation();
        } else {
            this.isValid = false;
            this.props.delegate && this.props.delegate.disableNavigation && this.props.delegate.disableNavigation();
        }
    }

    getAnswer = () => {

        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }

        let occupiedDropZone = this.state.occupiedDropZone;

        if (this.isValid) {
            let payload = [];
            for (let i = 0; i < occupiedDropZone.length; i++) {
                payload.push({ id: this.options[i].id, selected: [{ id: occupiedDropZone[i].item.id }] });
            }
            return { payload, timeTakenToAnswer: this.answerTickCount };

        } else
            return null;
    }



    updateAnswer = (payload) => {

        let occupiedDropZone = [...this.state.occupiedDropZone];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        let options = this.props.content.content.options;

        let dragItems = {};
        payload.answer.map((item, index) => { { dragItems[item.id] = index } });

        let mappedOptions = {};

        options.map((item, index) => { { mappedOptions[item.id] = { item, index } } });

        for (let i = 0; i < options.length; i++) {

            for (let j = 0; j < payload.answer.length; j++) {

                if (payload.answer[j].id == options[i].id) {
                    occupiedDropZone.push({ index: mappedOptions[payload.answer[j].selected[0].id].index, item: mappedOptions[payload.answer[j].selected[0].id].item });
                }
            }
            droppedItemsIndex[i] = i;
        }

        this.isValid = true;
        this.setState({ occupiedDropZone: occupiedDropZone, droppedItemsIndex: droppedItemsIndex });
    }



    reset = () => {
        this.setState({
            occupiedDropZone: [],
            droppedItemsIndex: []
        });
    }

    createDOMElement(elementData) {
        const element = document.createElement(elementData.type);

        if (elementData.props) {
            // Set attributes and styles if available
            const { style, ...otherProps } = elementData.props;
            for (const prop in otherProps) {
                element[prop] = otherProps[prop];
            }

            if (style) {
                for (const styleProp in style) {
                    element.style[styleProp] = style[styleProp];
                }
            }

            if (elementData.props.children) {
                // Recursively create and append child elements
                elementData.props.children.forEach((childData) => {
                    if (Array.isArray(childData)) {
                        childData.forEach((child) => {
                            if (typeof child === 'object' && child.type) {
                                const childElement = this.createDOMElement(child);
                                element.appendChild(childElement);
                            }
                        });
                    }
                });
            }
        }

        return element;
    }



    onDragStart = (event, dragInfo, type) => {


        if (event.type === 'touchstart') {
            event.preventDefault()
            this.setState({ sourcePreviewVisible: true });
        }

        this.dragType = type;
        this.dragInfo = dragInfo;


    };



    onDragOver = (event, dragOverInfo, type) => {


        event.preventDefault()


        if (event.type === 'touchmove') {

            // Find the target element based on the touch event
            const targetElement = document.elementFromPoint(
                event.changedTouches[0].clientX,
                event.changedTouches[0].clientY
            );

            // //console.log('target Element', targetElement)

            let dragOverIndex = { index: -1 }
            let index = +targetElement?.attributes[0]?.textContent
            if (!isNaN(index)) {
                dragOverIndex = { index: index }
            }

            this.dragOverInfo = dragOverIndex

            // Access touch coordinates
            const touchX = event.touches[0].clientX;
            const touchY = event.touches[0].clientY;

            // Update the source preview's position
            this.setState({
                sourceX: touchX,
                sourceY: touchY,
            });



        } else if (this.dragType == type && this.dragType == "ANSWER") {

            this.dragOverInfo = dragOverInfo;

            this.setState({ dragOverQuestionContentId: this.dragOverInfo.item.id });
            // //console.log("CONCEPT onDragOver", dragOverInfo);

        }

    };


    onDragEnd = (event) => {

        // //console.log("end reached", this.dragType, this.dragInfo, this.dragOverInfo)
        // if (this.dragType == "ANSWER") {

        //     if (this.dragInfo) {

        //         // if (this.dragOverInfo.index != -1) {
        //             // this.setSelectedAnswer(index, dropIndex);
        //             this.setSelectedAnswer(this.hashedOriginalOptions[this.dragInfo.item.id], this.dragOverInfo.index, this.dragInfo.index)
        //         // }

        //         //console.log(this.dragInfo, this.dragOverInfo)

        //     }
        // }

    };

    dropItem = (event) => {

        // if(event.type === 'touchend') {
        event.preventDefault()
        this.setState({ sourcePreviewVisible: false });
        // }


        if (this.dragType == "ANSWER") {

            if (this.dragInfo) {

                if (this.dragOverInfo.index != -1) {
                    // this.setSelectedAnswer(index, dropIndex);
                    this.setSelectedAnswer(this.hashedOriginalOptions[this.dragInfo.item.id], this.dragOverInfo.index, this.dragInfo.index)
                }

                //console.log(this.dragInfo, this.dragOverInfo)

            }
        }
    }

    updateAnswerForUserAnswer = (payload) => {

        console.log(payload)

        let content = this.state.content
        let options = content.content.options;

        let contentRaw = JSON.stringify(this.props.content);
        contentRaw = JSON.parse(contentRaw);
        let optionsRaw = contentRaw.content.options;

        let optionHash = {}
        optionsRaw.map((option) => optionHash[option.id] = option);

        let answerHash = {}
        payload.answer.map((option) => answerHash[option.id] = option.selected[0].id);

        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            let answerOption = answerHash[option.id]
            option.answer = optionHash[answerOption].answer;
        }

        this.setState({ content: content, reload: false }, () => {
            this.setState({ reload: true })
        });
    }

    showMyAnswer = () => {
        if (this.props.userAnswer) {
            this.updateAnswerForUserAnswer(this.props.userAnswer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.parse(JSON.stringify(this.props.content))
        //console.log(content)
        this.setState({ content: content, reload: false }, () => {
            this.setState({ reload: true })
        })
    }


    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer, showSolution: !this.state.showSolution })
    }

    toggleViewSolution = () => {
        if (this.state.status === "CORRECT") {
            return;
        }
        this.setState({ showSolution: !this.state.showSolution, showCorrectAnswer: !this.state.showCorrectAnswer })
    }


    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions })
    }





    getOptionItem(index, option) {

        let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]

        return (
            !(this.state.droppedItemsIndex.includes(index)) &&
            <div className="answer-card cursor-pointer" style={{ border: `2px solid ${dragItemBorder}`, backgroundColor: bgColor }}
                onDragStart={e => this.onDragStart(e, { index: index, item: option }, "ANSWER")}
                onDragEnd={this.onDragEnd}
                onTouchStart={e => this.onDragStart(e, { index: index, item: option }, "ANSWER")}
                onTouchMove={(e) => this.onDragOver(e, { index: index, item: option }, "")}
                onTouchEnd={this.dropItem}
                draggable={true}
            >
                <div style={{}} className="align-self-start mb-2"><img className="drag-image" src={getThemeImageSource(ImageConstants.DRAG_ICON, this.props.categoryThemeId)} /></div>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                </div>
            </div>
        )
    }

    renderDropedItem(index) {
        if (this.state.occupiedDropZone[index]) {
            let option = this.options[this.state.occupiedDropZone[index].index];
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <div className="close-icon-wrapper">
                        <img className="close-icon"
                            onClick={() => this.onDroppedItemRemove(this.state.occupiedDropZone[index].index, index, option.id)}
                            src={getThemeImageSource(ImageConstants.THEME_CLOSE_ICON, this.props.categoryThemeId)} />
                    </div>
                    <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                </div>
            )
        }
    }

    renderReadOnlyItem(option) {
        return (
            <>
                {
                    option["answer"].contentType == Constants.CONTENT_TYPE_TEXT ?
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>
                        :
                        <div className="d-flex align-items-center justify-content-center">

                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>

                }
            </>

        )
    }

    getReadonlyView = (content) => {

        //console.log('inside read only view')

        let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        return (

            <div className="matching-question-type-read-only" style={{}}>
                <div className={`${!this.props.isReadOnly ? "min-height" : ""}`}>

                    <div>

                        {
                            this.props.isReadOnly ?
                                <>
                                    <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                                        {
                                            this.props.userAnswerStatus ?
                                                <div className="correct-answer-bg d-flex">
                                                    <div className="">
                                                        <img className="image-size" src={getImageSource(ImageConstants.WHITE_TICK_ICON_NEW)} />
                                                    </div>
                                                    <div className="ml-2">
                                                        <div className="name-header-correct">{"Correct"}</div>
                                                        {/* <div className="sub-title">{this.correctAnswerStaticText.subTitle}</div> */}
                                                    </div>
                                                </div>

                                                :
                                                <div className="incorrect-answer-bg d-flex">
                                                    <div className="">
                                                        <img className="image-size" src={getImageSource(ImageConstants.WHITE_CROSS_ICON)} />
                                                    </div>
                                                    <div className="ml-1">
                                                        <div className="name-header text-center">{"Incorrect"}</div>
                                                        {/* <div className="sub-title">{this.inCorrectAnswerStaticText.subTitle}</div> */}
                                                    </div>
                                                </div>

                                        }

                                    </div>
                                    {/* <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                    <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                    </div>
                                </div> */}
                                </>
                                :
                                <></>
                        }


                        {
                            this.props.isMultiStep && this.props.mulipStepQuestion ?
                                <>
                                    <QuestionTitle title={this.props.mulipStepQuestion.content.title} queNo={this.props.quesNo ? this.props.quesNo : null} delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId} />
                                    <QuestionImage images={this.props.mulipStepQuestion.content.images} categoryThemeId={this.props.categoryThemeId} />
                                    <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                        </div>
                                    </div>

                                </>
                                : <></>
                        }

                        {
                            !this.props.isMultiStep ?
                                <>
                                    <QuestionTitle title={content.content.title}
                                        queNo={this.props.quesNo ? this.props.quesNo : null}
                                        hideBackground={this.props.isMultiStep ? true : false}
                                        delegate={this.props.delegate}
                                        categoryThemeId={this.props.categoryThemeId}
                                        isReadOnly={this.props.isReadOnly}
                                        showOptions={this.state.showOptions}
                                        toggleOptions={() => this.toggleOptions()} />
                                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                                    {
                                        this.props.isReadOnly && this.state.showOptions ?
                                            <>
                                                <div className="d-flex flex-row  answer-card-container mt-4">
                                                    {
                                                        this.suffledOptions.map((option, index) => this.getOptionItem(index, option))
                                                    }
                                                </div>

                                                {!this.props.isCheckPoint && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                                    <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                                    </div>
                                                    {
                                                        (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                                            <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                                                style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }}>
                                                                <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                                                <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                                            </div> : <></>
                                                    }
                                                </div>
                                                }
                                                <div className={`matching-question-container mt-4 option-margin-bottom`}>
                                                    <div className="row question-option-wrapper mr-0">
                                                        <div className="col">
                                                            {this.options.map((option, index) => (
                                                                <div className="matching-question d-flex flex-row align-items-center mt-3" key={option.id + 'answer'}>

                                                                    {
                                                                        this.options.length <= 4 ?
                                                                            index <= 1 ?
                                                                                <>
                                                                                    <div className={`question-card d-flex align-items-center justify-content-center
                                                                ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                                                        <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                                                    </div>
                                                                                    <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                                                    <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                        ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                                                        onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}
                                                                                        onDrop={this.dropItem}
                                                                                        style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                                                    >
                                                                                        {
                                                                                            this.renderDropedItem(index)
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                </>
                                                                            :
                                                                            index <= 2 ?
                                                                                <>
                                                                                    <div className={`question-card d-flex align-items-center justify-content-center
                                                                ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                                                        <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                                                    </div>
                                                                                    <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                                                    <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                        ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                                                        onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}

                                                                                        onDrop={this.dropItem}
                                                                                        style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                                                    >
                                                                                        {
                                                                                            this.renderDropedItem(index)
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                    }


                                                                </div>
                                                            ))
                                                            }
                                                        </div>
                                                        {/* <img className="option-divider" src={getImageSource(ImageConstants.MATCHING_QUESTION_DIVIDER)} /> */}
                                                        <div className="col ml-1">
                                                            {this.options.map((option, index) => (
                                                                <div className={`matching-question d-flex flex-row align-items-center 
                                            ${index < 2 && option[`question`].contentType == Constants.CONTENT_TYPE_IMAGE ? 'mt-0' : 'mt-1'}
                                            ${this.options.length <= 4 && index > 2 ? 'mt-3' : ''} ${this.options.length <= 4 && index == 2 ? 'mt-0' : ''}
                                            ${!this.options.length <= 4 && index > 3 ? 'mt-3' : ''} ${!this.options.length <= 4 && index == 3 ? 'mt-0' : ''}`}
                                                                    key={option.id + 'answer'}>

                                                                    {
                                                                        this.options.length <= 4 ?
                                                                            index > 1 ?
                                                                                <>
                                                                                    <div className={`question-card d-flex align-items-center justify-content-center
                                                                ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                                                        <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                                                    </div>
                                                                                    <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                                                    <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                        ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                                                        onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}

                                                                                        onDrop={this.dropItem}
                                                                                        style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                                                    >
                                                                                        {
                                                                                            this.renderDropedItem(index)
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                </>
                                                                            :
                                                                            index > 2 ?
                                                                                <>
                                                                                    <div className={`question-card d-flex align-items-center justify-content-center
                                                                ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                                                        <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                                                    </div>
                                                                                    <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                                                    <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                                                        onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}

                                                                                        onDrop={this.dropItem}
                                                                                        style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                                                    >
                                                                                        {
                                                                                            this.renderDropedItem(index)
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                    }


                                                                </div>
                                                            ))
                                                            }
                                                        </div>
                                                    </div>

                                                </div>

                                            </>

                                            :
                                            <></>
                                    }
                                </>
                                :
                                <></>
                        }
                        {this.props.totalSteps && this.props.quesNo && <DotPagination noOfDots={this.props.totalSteps} activeDot={this.props.quesNo} categoryThemeId={this.props.categoryThemeId} />}

                        {
                            !this.props.isMultiStep && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                    <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                </div>
                            </div>

                        }


                        {
                            this.props.isReadOnly ?
                                <>
                                    <div className="d-flex align-items-center mt-3 ml-3">
                                        <div className="d-flex ">
                                            <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                                style={{
                                                    backgroundColor: `${this.state.showSolution ? "#E1D9FF" : "#E6E8E8"}`,
                                                    color: `${this.state.showSolution ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                                }} onClick={this.toggleViewSolution}>
                                                {
                                                    this.state.showSolution ? 'Solution' : 'Solution'
                                                }
                                                {/* <span>View Answer</span> */}
                                                {/* <img className="arrow-size" src={getThemeImageSource(this.state.showSolution ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                            </div>
                                        </div>
                                        {
                                            this.props.isReadOnly && !this.props.isMultiStep ?
                                                <>
                                                    {
                                                        this.props.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                            <div className="d-flex  ml-4">
                                                                <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                                                    style={{
                                                                        backgroundColor: `${this.state.showCorrectAnswer ? "#E1D9FF" : "#E6E8E8"}`,
                                                                        color: `${this.state.showCorrectAnswer ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                                                    }} onClick={this.toggleViewAnswer}>
                                                                    {
                                                                        this.state.showCorrectAnswer ? 'Answer' : 'Answer'
                                                                    }
                                                                    {/* <span>View Answer</span> */}
                                                                    {/* <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </>

                                                : <></>
                                        }

                                    </div>

                                    <div className="solution-answer-border mb-4"></div>

                                    {
                                        this.state.showSolution ?
                                            <SummarySoultions solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions>
                                            : <></>
                                    }

                                    {
                                        this.props.isReadOnly && !this.props.isMultiStep ?
                                            this.state.showCorrectAnswer ?
                                                <>
                                                    {
                                                        this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                            <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.state.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.userAnswer}></AnswerTab>
                                                            : <></>
                                                    }
                                                    {
                                                        this.state.reload ?
                                                            <>
                                                                {
                                                                    this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                                        <div className="matching-question-container mt-4 option-margin-bottom">
                                                                            {content.content.options.map((option, index) =>
                                                                                <div className="matching-question d-flex flex-row align-items-center mt-3" key={option.id + 'answer'}>

                                                                                    <div className="question-card d-flex align-items-center justify-content-center" style={{ borderColor: dragItemBorder }}>
                                                                                        {
                                                                                            option["question"].contentType == Constants.CONTENT_TYPE_TEXT ?
                                                                                                <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                                                                :
                                                                                                <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                                                        }

                                                                                    </div>
                                                                                    <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                                                    <div className={`question-card d-flex align-items-center justify-content-center position-relative`} style={{ borderColor: dragItemBorder }}>
                                                                                        {
                                                                                            this.renderReadOnlyItem(option)
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                            </>

                                                            :

                                                            <></>
                                                    }

                                                </> : <></>

                                            : <></>
                                    }
                                </>
                                :
                                <></>
                        }


                        {
                            this.props.isReadOnly && !this.props.isMultiStep ?
                                <>


                                </>
                                :
                                <></>
                        }


                        {/* {
                            this.props.isReadOnly &&
                            <div className="d-flex mb-4">
                                <div className="d-flex row-width-for-solution justify-content-center align-items-center"
                                    style={{ backgroundColor: "#F0ECFF" }}>
                                    <div className="col view-button-for-solution text-center"
                                        style={{ color: `${this.theme['themeColor']}` }} >Solution</div>
                                </div>
                            </div>

                        }
                        {this.props.isReadOnly ? <SummarySoultions forQuestion={true} solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions> : <></>} */}

                    </div>



                    {/* <div className="matching-question-container mt-4">
                        {this.options.map((option, index) =>
                            <div className="matching-question d-flex flex-row align-items-center mt-3" key={option.id + 'answer'}>

                                <div className="question-card d-flex align-items-center justify-content-center" style={{ border: `2px solid ${dragItemBorder}` }}>
                                    {
                                        option["question"].contentType == Constants.CONTENT_TYPE_TEXT ?
                                            <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                            :
                                            <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                    }

                                </div>
                                <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                <div className={`question-card d-flex align-items-center justify-content-center position-relative`} style={{ border: `2px solid ${dragItemBorder}` }}>
                                    {
                                        this.renderReadOnlyItem(option)
                                    }
                                </div>
                            </div>
                        )}
                    </div> */}

                    {/* {(this.state.scrollViewHeight + constants.FOOTER_HEIGHT + (this.props.isFromMyAssessment ? constants.MY_ASSESSMENT_PAGE_HEADER_HEIGHT : 0)) > sHeight && <AssessmentFooter delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>} */}

                </div >
                {<AssessmentFooter userAnswerStatus={this.props.userAnswerStatus}
                    isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly}
                    delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}
            </div>
        )
    }





    render() {

        //console.log(this.props.isReadOnly)

        let content = this.state.content;
        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)
        let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]

        const { sourceX, sourceY, sourcePreviewVisible } = this.state;

        return (
            <>

                {!this.props.isReadOnly ?
                    content ?
                        <div className="matching-question-type" onMouseEnter={ReactTooltip.rebuild}>
                            {sourcePreviewVisible && (

                                <div className="source-preview answer-card cursor-pointer" style={{
                                    border: `2px solid ${dragItemBorder}`, backgroundColor: bgColor,
                                    left: sourceX + 30 + 'px',
                                    top: sourceY - 50 + 'px'
                                }}
                                >
                                    {this.getContentUsingContentType(this.dragInfo?.item, 'answer')}
                                </div>
                            )}
                            <div className="min-height">

                                {
                                    this.props.isMultiStep && this.props.mulipStepQuestion ?
                                        <>
                                            <QuestionTitle title={this.props.mulipStepQuestion.content.title} queNo={this.props.quesNo ? this.props.quesNo : null} categoryThemeId={this.props.categoryThemeId} delegate={this.props.delegate} />
                                            <QuestionImage images={this.props.mulipStepQuestion.content.images} categoryThemeId={this.props.categoryThemeId} />

                                            <div className="d-flex align-items-center mt-4">
                                                <img className="bulb-image-size" src={getImageSource(ImageConstants.BULB_IMAGE)} />
                                                <div className="instruction-text ml-3">
                                                    {/* {constants.MULTI_SELECT_INSTRUCTION_TEXT} */}
                                                    {this.props.isFromMyAssessment ? this.staticText.assessmentMultistepInstruction : this.staticText.instruction}
                                                </div>
                                            </div>

                                            <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                                <div className={`${(this.props.isFromMyAssessment) ? 'divider-wrapper' : ''}`}>
                                                    <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                                </div>
                                                {
                                                    this.props.isFromMyAssessment ?
                                                        <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                                            style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${this.props.mulipStepQuestion.content.mark} Marks`}>
                                                            <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                                            <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{this.props.mulipStepQuestion.content.mark}</span>
                                                        </div> : <></>
                                                }
                                            </div>
                                        </>
                                        : <></>
                                }

                                {this.props.totalSteps && this.props.quesNo && <DotPagination noOfDots={this.props.totalSteps} activeDot={this.props.quesNo} categoryThemeId={this.props.categoryThemeId} />}

                                {
                                    !this.props.isCheckPoint &&
                                    <QuestionTitle title={content.content.title}
                                        queNo={this.props.quesNo ? this.props.quesNo : null}
                                        noOfDots={this.props.totalSteps}
                                        activeDot={this.props.quesNo}
                                        hideBackground={this.props.isMultiStep ? true : false}
                                        categoryThemeId={this.props.categoryThemeId}
                                        isAssessment={this.props.isAssessment}
                                        delegate={this.props.delegate}
                                        isSchoolAssignment={true} />
                                }
                                {!this.props.isCheckPoint && <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />}



                                <div className="d-flex flex-row  answer-card-container mt-4">
                                    {
                                        this.suffledOptions.map((option, index) => this.getOptionItem(index, option))
                                    }
                                </div>

                                {!this.props.isCheckPoint && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                    <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                    </div>
                                    {
                                        (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                            <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                                style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${content.content.mark} Marks`}>
                                                <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                                <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                            </div> : <></>
                                    }
                                </div>
                                }
                                <div className={`matching-question-container mt-4 option-margin-bottom`}>
                                    <div className="row question-option-wrapper mr-0">
                                        <div className="col">
                                            {this.options.map((option, index) => (
                                                <div className="matching-question d-flex flex-row align-items-center mt-3" key={option.id + 'answer'}>

                                                    {
                                                        this.options.length <= 4 ?
                                                            index <= 1 ?
                                                                <>
                                                                    <div className={`question-card d-flex align-items-center justify-content-center
                                                                ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                                        <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                                    </div>
                                                                    <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                                    <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                        ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                                        onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}
                                                                        onDrop={this.dropItem}
                                                                        style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                                    >
                                                                        {
                                                                            this.renderDropedItem(index)
                                                                        }
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                            :
                                                            index <= 2 ?
                                                                <>
                                                                    <div className={`question-card d-flex align-items-center justify-content-center
                                                                ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                                        <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                                    </div>
                                                                    <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                                    <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                        ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                                        onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}

                                                                        onDrop={this.dropItem}
                                                                        style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                                    >
                                                                        {
                                                                            this.renderDropedItem(index)
                                                                        }
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                    }


                                                </div>
                                            ))
                                            }
                                        </div>
                                        {/* <img className="option-divider" src={getImageSource(ImageConstants.MATCHING_QUESTION_DIVIDER)} /> */}
                                        <div className="col ml-1">
                                            {this.options.map((option, index) => (
                                                <div className={`matching-question d-flex flex-row align-items-center 
                                            ${index < 2 && option[`question`].contentType == Constants.CONTENT_TYPE_IMAGE ? 'mt-0' : 'mt-1'}
                                            ${this.options.length <= 4 && index > 2 ? 'mt-3' : ''} ${this.options.length <= 4 && index == 2 ? 'mt-0' : ''}
                                            ${!this.options.length <= 4 && index > 3 ? 'mt-3' : ''} ${!this.options.length <= 4 && index == 3 ? 'mt-0' : ''}`}
                                                    key={option.id + 'answer'}>

                                                    {
                                                        this.options.length <= 4 ?
                                                            index > 1 ?
                                                                <>
                                                                    <div className={`question-card d-flex align-items-center justify-content-center
                                                                ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                                        <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                                    </div>
                                                                    <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                                    <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                        ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                                        onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}

                                                                        onDrop={this.dropItem}
                                                                        style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                                    >
                                                                        {
                                                                            this.renderDropedItem(index)
                                                                        }
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                            :
                                                            index > 2 ?
                                                                <>
                                                                    <div className={`question-card d-flex align-items-center justify-content-center
                                                                ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                                        <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                                    </div>
                                                                    <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                                    <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                                        onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}

                                                                        onDrop={this.dropItem}
                                                                        style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                                    >
                                                                        {
                                                                            this.renderDropedItem(index)
                                                                        }
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                    }


                                                </div>
                                            ))
                                            }
                                        </div>
                                    </div>

                                </div>

                            </div>

                            {!this.props.isCheckPoint && <AssessmentFooter userAnswerStatus={this.props.userAnswerStatus} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly} delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}

                            <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                                multiline={true}
                                place='right'
                                type='light' effect='solid'
                                event="mouseover mouseenter"
                                eventOff="mouseleave mouseout scroll mousewheel blur" />
                        </div>
                        :
                        <></>
                    : this.getReadonlyView(content)
                }
            </>
        );
    }
}

export default Matching;

import React from 'react';
import './binary.scss';
import QuestionTitle from '../question-title/question-title.js';
import QuestionImage from '../../cw-question-image/cw-question-image.js';
import { ImageConstants, Constants } from '../../../../constants/constants.js';
import { getImageSource, getThemeImageSource } from '../../../../utility/image-source.js';
import DotPagination from '../../dot-pagination/dot-pagination.js';
import AssessmentFooter from '../../assessment-footer/assessment-footer.js';
import SummarySoultions from '../../summary-solution/summary-solution.js';
import SharedThemeCategory from '../../../../../shared-storage/category-theme.js';

class Binary extends React.Component {

    constructor(props) {
        super(props)

        this.theme = SharedThemeCategory.getThemeCategory(props.categoryThemeId);

    }

    theme = {}

    answerTickCount = 0


    isValid = false;

    state = { scrollViewHeight: 0 };


    componentDidMount() {



        if (!this.props.isReadOnly) {
            this.startTimerforAnswer()
        }

    }

    startTimerforAnswer() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }
        this.timerIntervalId = setInterval(() => {

            this.answerTickCount += 1
        }, 1000)
    }

    onPress(option) {

        if (this.props.isReadOnly) {
            return;
        }

        this.props.content.content.options.map((opt) => opt.isChecked = false);
        option.isChecked = true;
        this.forceUpdate();

        this.isValid = true;
        if (this.props.delegate && this.props.delegate.enableNavigation)
            this.props.delegate.enableNavigation();
    }

    getAnswer = () => {

        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }

        if (this.isValid) {
            let payload = [];
            let options = this.props.content.content.options;
            for (let i = 0; i < options.length; i++) {
                if (options[i].isChecked)
                    payload.push({ id: options[i].id })
            }
            return { payload, timeTakenToAnswer: this.answerTickCount };;
        } else
            return null;
    }

    updateAnswer = (payload) => {
        let options = this.props.content.content.options;

        let option = options.filter(opt => opt.id == payload.answer[0].id);
        option[0].isChecked = true;
        this.isValid = true;
        this.forceUpdate();
    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
    }


    render() {
        let content = this.props.content;
        return (
            <div className='binary-question'>

                {/* {!this.props.isCheckPoint && <QuestionTitle title={content.content.title} queNo={this.props.quesNo ? this.props.quesNo : null} hideBackground={this.props.isMultiStep ? true : false} categoryThemeId={this.props.categoryThemeId} delegate={this.props.delegate} />}

                {!this.props.isCheckPoint && <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />}

                {this.props.totalSteps && this.props.quesNo && <DotPagination noOfDots={this.props.totalSteps} activeDot={this.props.quesNo} categoryThemeId={this.props.categoryThemeId} />} */}

                {/* {!this.props.isCheckPoint && <View style={[styles.seperator]}>
                    <View style={(this.props.isFromMyAssessment && !this.props.isMultiStep) ? { flex: 8.5 } : {}}><Image source={getThemeImageSource(ImageConstants.QUESTION_SEPERATOR, this.props.categoryThemeId)} resizeMode="contain" /></View>
                    {(this.props.isFromMyAssessment && !this.props.isMultiStep) ? <View style={{ flex: 1.5 }}>
                        <View style={styles.markContainer}>
                            <Image source={getThemeImageSource(ImageConstants.MARKS, this.props.categoryThemeId)} style={styles.markIcon} />
                            <Text style={styles.mark}>{content.content.mark}</Text>
                        </View>
                    </View>
                        :
                        <></>}
                </View>} */}

                {this.props.isReadOnly ?
                    <div><SummarySoultions solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions></div> : <></>}

                <div className='option-wrapper-for-binary d-flex justify-content-center'>

                    {content.content.options && content.content.options.length && content.content.options.map((option, index) =>
                        <div className='  cursor-pointer' onClick={this.onPress.bind(this, option)} key={index}>

                            {

                                !this.props.isReadOnly ?

                                    <div className={`option-button mr-5 ${option.isChecked ? "selected-option-bg" : ''}`}>
                                        {option.isChecked ?
                                            <div className=''><span>{option.content}</span></div>
                                            :
                                            <div>
                                                <div><span className=' option-title '>{option.content}</span></div>
                                            </div>
                                        }
                                    </div>

                                    :
                                    <></>
                                // <View style={styles.binary, content.content.options.length - 1 > index ? styles.paddingOptions : {}}>
                                //     {option.isCorrect ?
                                //         <View style={styles.activeOption}><Text style={this.props.isCheckPoint ? styles.checkPointTextWhite : styles.textWhite}>{option.content}</Text></View>
                                //         :
                                //         <LinearGradient colors={Colors.appTheme.gradient} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1.2 }} style={styles.linearGradient}>
                                //             <View><Text style={this.props.isCheckPoint ? styles.checkPointTitle : styles.title}>{option.content}</Text></View>
                                //         </LinearGradient>
                                //     }
                                // </View>
                            }

                        </div>
                    )}
                </div>

                {!this.props.isCheckPoint && !this.props.isMultiStep && <AssessmentFooter delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}
            </div>
        );
    }
}



export default Binary;

import React from "react";
import CommonApi from "../../apis/common";
import { Constants, ImageConstants } from "../../constants/constants";
import { getImageSource } from "../../utility/image-source";
import './login-screen.scss';
import SharedStaticText from "../../../shared-storage/static-text-store";
import CommonUtils from '../../utility/common-utilities'
import AuthenticateAPI from "../../apis/authenticate";
import CountryCodeDropdown from "../common/country-code-dropdown/country-code-dropdown";
import Path from "../routes/routes-path";
import SharedProfile from "../../../shared-storage/profile";
import SelectUser from "./select-user/select-user";
import CustomToaster from "../common/custom-toaster/custom-toaster";
import AsyncStore from "../../../shared-storage/async-store";
import PasscodeUpdateScreen from "./passcode-update-screen/passcode-update-screen";
import FirebaseEvent from '../../firebase-events/firebase-events';


class LoginScreen extends React.Component {

    // state = {
    //     renderMobileView : false
    // }

    constructor(props) {
        super(props);

        // if (this.props?.location?.state) {
        //     //console.log(this.props.location.state)
        //     this.state.mobileNumber = this.props.location.state.mobileNumber
        //     this.state.countryCode = this.props.location.state.countryCode
        //     this.state.userName = this.props.location.state.userName
        //     this.state.showSetNewPassCodeCard = true
        //     this.state.fromStudentAfterLogin = true
        // }

        let width = window.screen.width;
        //console.log(width)

        if (width < 768) {
            this.state.renderMobileView = true
        } else {
            this.state.renderMobileView = false
        }


    }

    state = {
        passcodeBox1: '',
        passcodeBox2: '',
        passcodeBox3: '',
        passcodeBox4: '',
        newPasscodeBox1: '',
        newPasscodeBox2: '',
        newPasscodeBox3: '',
        newPasscodeBox4: '',
        reEnteredPasscodeBox1: '',
        reEnteredPasscodeBox2: '',
        reEnteredPasscodeBox3: '',
        reEnteredPasscodeBox4: "",
        confirmPasscode: false,
        newPassCode: '',
        reEnteredPassCode: '',
        loginWithPasscode: true,
        loginWithOTP: false,
        selectedCountry: { dialCode: "+91", length: 10, _id: '5f733391ac527316bbd9c539' },
        mobileNumber: "",
        otp: '',
        dot: "",
        passCode: '',
        isInvalidMobile: false,
        isSubmit: false,
        invalidOtp: false,
        isOtpSent: false,
        countryCodeDropDown: [],
        seconds: 90,
        showSetNewPassCodeCard: false,
        isMobileNumberNotRegistered: false,
        showSelectUserScreen: false,
        invalidPsscode: false,
        showToastMessage: false,
        showSendOtp: true,
        showForgotPasscodeCard: false,
        nickName: '',
        showWrongPasscodeErrorMessage: false
    }

    coutryCodeHash = {}

    deviceInfo = {}

    homeworkActivityId = ''

    fromSchoolAssignment = false

    componentDidMount() {
        this.deviceInfo = CommonUtils.getDeviceInformation()
        this.getCountryCode()
        let urlParams = new URLSearchParams(window.location.search);
        this.homeworkActivityId = urlParams.get('id')
        console.log('homework activity id', this.homeworkActivityId)
        if (this.homeworkActivityId) {
            this.autoLoginUser()
        }
    }

    autoLoginUser() {
        let payload = {
            deviceId: "STUDENT_PORTAL_DEVICE_ID",
            deviceInfo: this.deviceInfo,
            // currentVersion: 10,
            platform: this.deviceInfo.os,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL,
            homeworkActivityId: this.homeworkActivityId
        };

        AuthenticateAPI.loginWithId(payload).then(res => {
            console.log(res)
            this.uniqueKey = res.data.uniqueKey;
            this.deviceId = "STUDENT_PORTAL_DEVICE_ID"
            //console.log(//console.log(this.uniqueKey))
            AsyncStore.setUniqueKey(this.uniqueKey);
            this.fromSchoolAssignment = true
            this.verifyDevice()
        }).catch(err => {
            if (err) {
                console.log(err);
                return;
            }
        })
    }







    onKeyUpEvent(index, event, boxType) {
        const eventOtp = event.which || event.keyCode;
        if (this.getOtpBoxElement(index, boxType).value.length === 1) {
            if (index !== 4) {
                this.getOtpBoxElement(index + 1, boxType).focus()
                this.getOtpBoxElement(index + 1, boxType).select();
            } else {
                this.getOtpBoxElement(index, boxType).blur();
            }
        }
        if (eventOtp === 8 && index !== 1) {
            this.setState({ invalidOtp: false, confirmPasscode: false, invalidPsscode: false })
            this.getOtpBoxElement(index - 1, boxType).focus()
            this.getOtpBoxElement(index - 1, boxType).select();
        }
    }


    getOtpBoxElement(index, boxType) {
        return document.getElementById(boxType + index);
    }

    onFocusEvent(index, boxType) {
        if (this.state.isLoginFailed) {
            this.setState({ isLoginFailed: false })
        }
        for (let item = 1; item < index; item++) {
            const currentElement = this.getOtpBoxElement(item, boxType);
            if (!currentElement.value) {
                currentElement.focus();
                currentElement.select()
                break;
            }
        }
    }


    validateOTP() {

        if (this.state.passcodeBox1 && this.state.passcodeBox2 && this.state.passcodeBox3 && this.state.passcodeBox4) {
            return true
        }
        return false
    }


    onChangeHandler = (event, key) => {
        if (key === "mobileNumber") {

            if (!this.allowOnlyNumbers(event.target.value)) return

            this.setState({
                [key]: event.target.value,
                isInvalidMobile: false,
                isMobileNumberEdit: false,
                isMobileNumberNotRegistered: false,
                showNewPasscodeSetText: false,
                userListToSetPasscode: [],
                // isValidMobileNumber: this.validateMobile(event.target.value)
            }, () => {
                let validate = CommonUtils.validateMobileNumber(this.state.mobileNumber, this.coutryCodeHash[this.state?.selectedCountry?._id])
                if (validate) {
                    this.validateMobile(this.state.mobileNumber)
                }
            });
        } else {
            this.setState({ [key]: event.target.value }, () => {
                if (key.startsWith('passcodeBox') === true) {
                    //console.log('inside passcode box')
                    this.setState({
                        passCode: `${this.state.passcodeBox1 + this.state.passcodeBox2 + this.state.passcodeBox3 + this.state.passcodeBox4}`,
                        // isLoginEnable: this.validateOTP()
                    })
                } else if (key.startsWith('newPasscodeBox') === true) {
                    this.setState({
                        newPassCode: `${this.state.newPasscodeBox1 + this.state.newPasscodeBox2 + this.state.newPasscodeBox3 + this.state.newPasscodeBox4}`,
                        // isLoginEnable: this.validateOTP()
                    })
                } else if (key.startsWith('reEnteredPasscodeBox') === true) {
                    this.setState({
                        reEnteredPassCode: `${this.state.reEnteredPasscodeBox1 + this.state.reEnteredPasscodeBox2 + this.state.reEnteredPasscodeBox3 + this.state.reEnteredPasscodeBox4}`
                    })
                } else {
                    //console.log('inside otp box')
                    this.setState({
                        otp: `${this.state.otpBox1 + this.state.otpBox2 + this.state.otpBox3 + this.state.otpBox4}`,
                        // isLoginEnable: this.validateOTP()
                    })
                }


                if (this.state.reEnteredPasscodeBox4) {
                    this.matchPasscode()
                }


            });
        }
    }


    matchPasscode() {
        let newPassCode = `${this.state.newPasscodeBox1 + this.state.newPasscodeBox2 + this.state.newPasscodeBox3 + this.state.newPasscodeBox4}`
        let reEnteredPassCode = `${this.state.reEnteredPasscodeBox1 + this.state.reEnteredPasscodeBox2 + this.state.reEnteredPasscodeBox3 + this.state.reEnteredPasscodeBox4}`

        // //console.log(newPassCode, reEnteredPassCode)

        if (newPassCode === reEnteredPassCode) {
            this.setState({ confirmPasscode: true })
        } else {
            this.setState({ showPasscodeNotMatch: true }, () => {
                setTimeout(() => {
                    this.setState({ showPasscodeNotMatch: false })
                }, 2000)
            })
            return false
        }

    }


    resetNewPasscodeInputBoxs() {
        this.setState({
            newPasscodeBox1: '',
            newPasscodeBox2: '',
            newPasscodeBox3: '',
            newPasscodeBox4: '',
            newPassCode: '',
        })
    }


    resetReEnteredPasscodeInputBoxs() {
        this.setState({
            reEnteredPasscodeBox1: '',
            reEnteredPasscodeBox2: '',
            reEnteredPasscodeBox3: '',
            reEnteredPasscodeBox4: '',
            reEnteredPassCode: '',
        })
    }






    changeLoginStep() {
        //this.otpStaticText = SharedStaticText.getStaticText('otpComponent');
        if (this.state.mobileNumber) {
            this.onSubmit("sendOtp")
        }
        this.setState({ loginWithOTP: true })
    }


    goBackToLoginWithPassCode() {
        this.resetNewPasscodeInputBoxs()
        this.resetReEnteredPasscodeInputBoxs()
        this.setState({
            loginWithOTP: false,
            showForgotPasscodeCard: false,
            showSetNewPassCodeCard: false,
            showNewPasscodeSetText: false,
            showPasscodeInputBoxs: false,
            isOtpSent: false,
            mobileNumber: '',
            showSendOtp: true,
        })
    }


    openCountryCode() {
        if (this.state.isOtpSent) return
        this.getCountryCode()
        this.setState({ showDropdown: !this.state.showDropdown })
    }

    resetPasscodeInputBoxs() {
        this.setState({
            passcodeBox1: '',
            passcodeBox2: '',
            passcodeBox3: '',
            passcodeBox4: '',
            passCode: '',
        })
    }



    onMobileNumberEdit() {
        this.resetPasscodeInputBoxs()
        this.resetOtpBoxs()

        this.setState({
            isOtpSent: false,
            time: 0,
            isLoginEnable: false,
            invalidOtp: false,
            isMobileNumberEdit: true,
            showSendOtp: true
        })
        this.timer(0)
    }


    getCountryCode(payload = {}) {
        CommonApi.getCountryCode(payload).then((response) => {
            let data = response.response
            //console.log(data)
            if (data.length) {
                this.setState({ countryList: data })
                this.buildCountryCodeDropDown(data)
            } else {
                this.setState({ countryList: [], countryCodeDropDown: [] })
            }
        }).catch((err) => {
            //console.log(err)
        })
    }

    buildCountryCodeDropDown(list = []) {
        let countryCodeDropDown = []
        list.map(item => {
            this.coutryCodeHash[item._id] = item
            countryCodeDropDown.push({ text: item.dialCode, addOn: item.name, value: item._id })
        })
        this.setState({ countryCodeDropDown })
    }

    onSelectCode(item) {
        this.setState({ selectedCountry: this.coutryCodeHash[item.value], showDropdown: false }, () => {
            // this.setState({ isLoginEnable: this.validateMobile(this.state.mobileNumber) })
        })

    }

    hideCountryDropdown() {
        this.setState({ showDropdown: false })
    }

    onSearch(event) {
        let search = event.target.value
        this.getCountryCode({ name: `/${search}`, })
    }




    allowOnlyNumbers(value) {
        const re = /^[0-9\b]+$/;

        if (value === '' || re.test(value)) {
            return true
        }
        return false
    }

    validateMobile(value) {


        if (value) {
            let validate = CommonUtils.validateMobileNumber(value, this.coutryCodeHash[this.state?.selectedCountry?._id])
            //console.log(validate)
            if (validate) {

                const mobileNumber = value
                const countryCode = this.state.selectedCountry.dialCode
                //console.log(countryCode)

                let payload = {
                    mobileNumber,
                    countryCode
                }

                AuthenticateAPI.checkUserPasscodeSet(payload).then(res => {


                    if (!this.state.showSetNewPassCodeToast) {
                        if (res.users.length && res.users.length > 1) {
                            this.setState({ showNewPasscodeSetText: true, userListToSetPasscode: res.users })
                        } else {
                            this.setState({ showNewPasscodeSetText: true, userListToSetPasscode: res.users, userName: res.users[0].username })
                        }
                    }


                }).catch(err => {
                    //console.log(err)

                    this.timer(0)
                    if (err) {
                        //console.log("err", err)

                        if (err.response && err.response.data && err.response.data.code == 1602) {
                            this.setState({
                                isMobileNumberNotRegistered: true, time: 0, isSubmit: false, showSendOtp: true,
                                isMobileNumberEdit: true, showTimer: false
                            }, () => {
                                // this.toggleLoading(false)
                            })
                        } else if (err.response && err.response.status && err.response.status === 500) {
                            this.setState({ showServerErrorMessage: true }, () => {
                                // this.toggleLoading(false)
                            })
                        } else if (!err.response) {
                            this.setState({ showCheckInternetMessage: true }, () => {
                                // this.toggleLoading(false)
                            })
                        }
                        return;
                    }
                    this.setState({ isSubmit: false, dot: "", isInvalidMobile: true })


                })
            }

            return true






        }
        return false
    }



    onSubmit(option = "sendOtp") {
        if (option === "sendOtp") {
            this.sendOtp()
        } else if (option === "verifyOtp" && this.state.isOtpSent && this.state.otp.length === 4) {
            this.verifyOtp()
        }
    }


    onLoginWithPassCodeSubmit() {

        const mobileNumber = this.state.mobileNumber.trim()
        const countryCode = this.state.selectedCountry.dialCode
        const passcode = this.state.passCode

        if (!this.validateMobile(this.state.mobileNumber)) {
            //console.log(this.validateMobile(this.state.mobileNumber))
            this.setState({ isInvalidMobile: true });
            return;
        } else {
            this.setState({ isInvalidMobile: false });
        }

        let payload = {
            countryCode,
            mobileNumber,
            passcode
        }

        this.setState({ isSubmit: true });

        AuthenticateAPI.loginWithPassCode(payload).then(res => {

            //console.log(res)
            if (res && res.data.users.length > 1) {

                this.setState(
                    {
                        showSelectUserScreen: true,
                        basePath: res.data.basePath,
                        usersList: res.data.users
                    }
                )

            } else {
                this.updatePasscodeLoginUser(res && res.data.users.length && res.data.users[0].username);
            }

        }).catch(err => {
            if (err) {
                if (err.response && err.response.data && err.response.data.code == 1602) {
                    this.resetPasscodeInputBoxs()
                    this.setState({ isMobileNumberNotRegistered: true, isSubmit: false }, () => {
                        // this.toggleLoading(false)
                    })
                } else if (err.response && err.response.data && (err.response.data.code == 1600 || err.response.data.code == 1601)) {
                    //console.log("invalidPasscode")
                    this.setState({ invalidPsscode: true }, () => {
                        // this.toggleLoading(false)
                    });
                }

                return
            }

        })


    }




    sendOtp() {

        const mobileNumber = this.state.mobileNumber
        const countryCode = this.state.selectedCountry.dialCode
        if (!this.validateMobile(this.state.mobileNumber)) {
            //console.log(this.validateMobile(this.state.mobileNumber))
            this.setState({ isInvalidMobile: true });
            return;
        } else {
            this.setState({ isInvalidMobile: false, showTimer: true, showSendOtp: false });
        }

        this.otpStaticText = SharedStaticText.getStaticText('otpComponent');
        //console.log(this.otpStaticText)

        this.timer(this.otpStaticText.resendOtpTime ? this.otpStaticText.resendOtpTime : 90)


        this.setState({ isSubmit: true });

        FirebaseEvent('Passcode Reset OTP', { type: 'Passcode Reset', 'Mobile Number': this.state.mobileNumber, "Country Code": countryCode })


        if (mobileNumber && countryCode) {

            let payload = { mobileNumber, countryCode, requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL };
            AuthenticateAPI.sendOtpStudentLogin(payload).then((userDetails) => {

                this.setState({ isOtpSent: true, isLoginEnable: false, isSubmit: false, dot: "", otpBox1: "", otpBox2: "", otpBox3: "", otpBox4: "" });


            }).catch((err) => {
                this.timer(0)
                if (err) {
                    //console.log("err", err)

                    if (err.response && err.response.data && err.response.data.code == 1602) {
                        this.setState({
                            isMobileNumberNotRegistered: true, time: 0, isSubmit: false, showSendOtp: true,
                            isMobileNumberEdit: true, showTimer: false
                        }, () => {
                            // this.toggleLoading(false)
                        })
                    } else if (err.response && err.response.status && err.response.status === 500) {
                        this.setState({ showServerErrorMessage: true }, () => {
                            // this.toggleLoading(false)
                        })
                    } else if (!err.response) {
                        this.setState({ showCheckInternetMessage: true }, () => {
                            // this.toggleLoading(false)
                        })
                    }
                    return;
                }
                this.setState({ isSubmit: false, dot: "", isInvalidMobile: true })
            })
        }
    }

    verifyOtp() {

        if (this.state.showServerErrorMessage || this.state.showCheckInternetMessage) {
            this.setState({ showServerErrorMessage: false, showCheckInternetMessage: false })
        }

        const mobileNumber = this.state.mobileNumber
        const countryCode = this.state.selectedCountry.dialCode
        // const otp = `${this.state.otpBox1 || ''}${this.state.otpBox2 || ''}${this.state.otpBox3 || ''}${this.state.otpBox4 || ''}`
        if (!this.state.mobileNumber || !this.state.otp || this.state.otp.length !== 4) {
            this.setState({ invalidOtp: true })
            return;
        }

        //console.log(this.state.otp)

        let payload = {
            countryCode: countryCode,
            mobileNumber: mobileNumber,
            otp: this.state.otp,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL
        }

        this.setState({ isSubmit: true });

        if (mobileNumber && this.state.otp) {


            AuthenticateAPI.studentLogin(payload).then((res) => {

                // SessionCache.user = userDetails.data

                //console.log(res)

                if (res && res.data.users.length > 1) {

                    this.setState(
                        {
                            showSelectUserScreen: true,
                            basePath: res.data.basePath,
                            usersList: res.data.users,
                        }
                    )

                } else {

                    if (this.state.showForgotPasscodeCard) {
                        this.setState({ userName: res && res.data.users.length && res.data.users[0].username, showPasscodeInputBoxs: true })
                    } else {
                        this.updateLoginUser(res && res.data.users.length && res.data.users[0].username);
                    }
                }

                this.setState({ isLoginedIn: true })
            }).catch((err) => {
                // this.setState({ isLoginFailed: true, dot: "" })
                // alert("Invalid credentials");
                // //console.log(err.response.data.code)
                if (err) {
                    if (err.response && err.response.data && (err.response.data.code == 1600 || err.response.data.code == 1601)) {
                        this.setState({ invalidOtp: true, showToastMessage: true }, () => {
                            // this.toggleLoading(false)
                            setTimeout(() => {
                                this.setState({
                                    showToastMessage: false
                                })
                            }, 2000)
                        });
                    } else if (err.response && err.response.status && err.response.status === 500) {
                        this.setState({ showServerErrorMessage: true }, () => {
                            // this.toggleLoading(false)
                        });
                    } else if (!err.response) {
                        this.setState({ showCheckInternetMessage: true }, () => {
                            // this.toggleLoading(false)
                        });
                    }
                    return;
                }

            })
        }

    }

    updateLoginUser = (username) => {
        let payload = {
            deviceId: "STUDENT_PORTAL_DEVICE_ID",
            mobileNumber: this.state.mobileNumber,
            otp: this.state.otp,
            username: username,
            countryCode: this.state.selectedCountry.dialCode,
            deviceInfo: this.deviceInfo,
            // currentVersion: 10,
            platform: this.deviceInfo.os,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL
        };

        //console.log(payload)

        AuthenticateAPI.updateLoginUser(payload).then((res) => {



            this.uniqueKey = res.data.uniqueKey;
            this.deviceId = "STUDENT_PORTAL_DEVICE_ID"
            //console.log(//console.log(this.uniqueKey))
            AsyncStore.setUniqueKey(this.uniqueKey);
            this.verifyDevice()
        }).catch(err => {
            if (err) {
                if (err.response && err.response.data && (err.response.data.code == 1600 || err.response.data.code == 1601)) {
                    this.setState({ showSelectUserScreen: false }, () => {
                        this.setState({ showSelectUserScreen: true, showWrongPasscodeErrorMessage: true })
                    })
                }
                return;
            }
        })
    }

    verifyDevice = async () => {

        let doc = await AsyncStore.getWelcomePopupInfo();

        let payload = {
            uniqueKey: this.uniqueKey,
            deviceId: this.deviceId,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL
        };

        let showTrialFeatures = false
        AuthenticateAPI.verifyDevice(payload).then((res) => {

            console.log(res)

            if (!res.data.profile.appTime) {
                if (doc) {
                    doc = JSON.parse(doc)

                    if (!doc[`${res.data.profile.mobileNumber}_${res.data.profile.userName}`]) {

                        doc[`${res.data.profile.mobileNumber}_${res.data.profile.userName}`] = true

                        AsyncStore.setWelcomePopupInfo(doc)
                        //console.log("storedWelocme", doc)

                        showTrialFeatures = true
                    }
                } else {
                    AsyncStore.setWelcomePopupInfo({ [`${res.data.profile.mobileNumber}_${res.data.profile.userName}`]: true })
                    showTrialFeatures = true
                    //console.log("storedWelocme")
                }
            }


            // SessionStore.sharedInstance().setSession(res.session);
            SharedProfile.setProfile(res.data.profile);

            FirebaseEvent('Login', { 'User Id': res.data.profile.userId, "First Time": 'Yes' })


            // As Per Ravi Instruction for pilot no need to update the passcode so commented the code

            // if (res.data && res.data.profile && res.data.profile.passcodeInfo && res.data.profile.passcodeInfo.isFirstTime) {
            //     this.setState({ showPasscodeUpdateScreen: true, showSelectUserScreen: false, userName: res.data.profile.userName })
            //     return
            // } else {
            if (this.fromSchoolAssignment) {
                this.props.history.push({ pathname: Path.MY_CLASSWORK, state: { showTrialFeatures: showTrialFeatures } })
            } else {
                this.props.history.push({ pathname: Path.HOME, state: { showTrialFeatures: showTrialFeatures } })
            }

            return
            // }



            // RemoteNotification.setNotificationToken();
            // Moengage.logCustomEvent('Login', { 'User Id': res.profile.userId, "First Time": 'No' }, res.profile, true)

            // Facebook.logCustomEvent('Login', { 'User Id': res.profile.userId, "First Time": 'No' })
            // BranchIO.logCustomEvent('Login', { 'User Id': res.profile.userId, "First Time": 'No' })

            // this.handleCasesNavigation(res , showTrialFeatures)

            // if (res.profile.board && res.profile.class) {

            //     if (!res.profile.moreInfo || (res.profile.moreInfo && !res.profile.moreInfo.daysWantsToPractice)) {
            //         this.props.navigation.navigate('NewOnboaringScreen', { meta: { daysWantsToPractice: true, showTrialFeatures: false } });
            //         this.toggleLoading(false)
            //         return
            //     }

            //     if (res.profile.moreInfo && !res.profile.moreInfo.timeForPractice) {
            //         this.props.navigation.navigate('NewOnboaringScreen', { meta: { timeForPractice: true, showTrialFeatures: false } });
            //         this.toggleLoading(false)
            //         return
            //     }

            //     if (res.profile.moreInfo && !res.profile.moreInfo.dailyGoal) {
            //         this.props.navigation.navigate('NewOnboaringScreen', { meta: { dailyGoal: true, showTrialFeatures: false } });
            //         this.toggleLoading(false)
            //         return
            //     }

            //     if (res.profile.moreInfo && !res.profile.moreInfo.currentLevelOfMaths) {
            //         this.props.navigation.navigate('NewOnboaringScreen', { meta: { currentLevelOfMaths: true, showTrialFeatures: false } });
            //         this.toggleLoading(false)
            //         return
            //     }


            //     if (res.profile.schoolCode && !res.profile.sectionId) {
            //         this.getSchoolSections(res, showTrialFeatures)
            //         return
            //     }


            // // if (!res.profile.schoolName || !res.profile.schoolLocality) {
            // //     this.props.navigation.navigate('AddYourSchoolV2', { meta: { from: 'LOGIN', data: { showTrialFeatures: showTrialFeatures } } })
            // // } else
            // // const schoolCode = await AsyncStore.getSchoolCode();
            // // if(schoolCode){
            // //     const code = JSON.parse(schoolCode);
            // //     if(code.value && res.profile.schoolCode !== code.value) {
            // //         this.props.navigation.navigate('SchoolVerify', { meta: { showTrialFeatures: showTrialFeatures } });
            // //         return;
            // //     }
            // // }
            //     this.props.navigation.navigate('LandingScreen', { meta: { showTrialFeatures: showTrialFeatures } })
            // } else {
            //     this.props.navigation.navigate('NewOnboaringScreen', { meta: { isClassDeatailsCapture: true } });
            //     this.toggleLoading(false);
            // }
        }).catch(err => {
            if (err) {
                return;
            }

        })
    }


    updatePasscodeLoginUser(username) {
        let payload = {
            deviceId: "STUDENT_PORTAL_DEVICE_ID",
            mobileNumber: this.state.mobileNumber,
            passcode: this.state.passCode,
            username: username,
            countryCode: this.state.selectedCountry.dialCode,
            deviceInfo: this.deviceInfo,
            // currentVersion: 10,
            platform: this.deviceInfo.os,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL
        };

        //console.log(payload)

        AuthenticateAPI.updatePasscodeLoginUser(payload).then((res) => {

            this.uniqueKey = res.data.uniqueKey;
            this.deviceId = "STUDENT_PORTAL_DEVICE_ID"
            //console.log(//console.log(this.uniqueKey))
            AsyncStore.setUniqueKey(this.uniqueKey);
            this.verifyDevice()


        }).catch(err => {
            if (err) {
                // //console.log("err", err)
                this.setState({ showSelectUserScreen: false, invalidPasscodeEntered: true }, () => {
                    setTimeout(() => {
                        this.setState({ invalidPasscodeEntered: false })
                    }, 3000)
                })
                this.onMobileNumberEdit()
                return;
            }
        })
    }

    showPasscodeResetCard(selectedUserName) {
        this.setState({ showSelectUserScreen: false, userName: selectedUserName, showPasscodeInputBoxs: true })
    }




    resetOtpBoxs = () => {
        this.setState({
            otpBox1: '',
            otpBox2: '',
            otpBox3: '',
            otpBox4: ''
        });
    }


    resendOtp = () => {
        this.setState({ showTimer: true, reSendOtp: true, otp: '', askAlternate: true },
            () => this.timer(this.otpStaticText.resendOtpTime ? this.otpStaticText.resendOtpTime : 90));
        this.resetOtpBoxs();
        this.onSubmit("sendOtp")
    }


    timer(time) {

        if (time === 0) {
            this.setState({ showTimer: false });
            return;
        }


        this.setState({ seconds: time });
        let timeoutId = setTimeout(() => { this.timer(--time) }, 1000)

    }

    showSetNewPassCodeCard() {

        if (this.state.userListToSetPasscode.length > 1) {

            this.setState({ showSelectUserScreen: true, setNewPasscodeFlow: true, usersList: this.state.userListToSetPasscode })

        } else {
            this.setState({ showSetNewPassCodeCard: true })
        }
    }

    showSetNewPasscode(selectedUser) {
        this.setState({
            showSelectUserScreen: false,
            setNewPasscodeFlow: false,
            userName: selectedUser.username,
            nickName: selectedUser.nickName,
            showSetNewPassCodeCard: true
        })
    }

    showCustomToaster() {

        this.setState({ showToastMessage: true })

        setTimeout(() => {
            this.setState({
                showToastMessage: false
            })
        }, 2000)

    }



    countryCodeDropDown(dropDownList = []) {
        return (
            <div className="country-code-drop-down" id="st-3" ref={ref => this.countryCodeRef = ref} onClick={() => this.handleClickInside}>
                <div className="">
                    <div className="country-close-icon cursor-pointer">
                        <img className="close-icon" onClick={() => this.hideCountryDropdown()} src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} />
                    </div>
                    <div className="country-header-div">
                        <img className="country-header-img" src={getImageSource(ImageConstants.COUNTRY_DROPDOWN_HEADER)} />
                    </div>
                    <div className="select-country">Select a country</div>
                    <div className="country-search-div">
                        <img className="country-search" src={getImageSource(ImageConstants.COUNTRY_CODE_SEARCH)}></img>
                    </div>

                    <div className="search">

                        <input className="form-control country-search" placeholder={`Search`} value={this.state.search} onChange={(event) => { this.onSearch(event) }} />
                    </div>
                    <div className="p-2 dropdown-scroll">
                        {dropDownList.length ?
                            dropDownList.map(dropdown => (
                                <div className="dropdown-list cursor-pointer px-3 py-1" onClick={() => { this.onSelectCode(dropdown) }}>
                                    <div className="row country-name cursor-pointer">
                                        <div className="col-8"> {dropdown.addOn}</div>
                                        <div className="col text-right">  {dropdown.text}</div>
                                    </div>
                                </div>

                            ))
                            : <div className="text-center py-4 no-data">
                                <label> No Records found</label>
                            </div>
                        }
                    </div>
                </div>
                <div className="country-footer-div">
                    <img className="country-header-img" src={getImageSource(ImageConstants.COUNTRY_DROPDOWN_FOOTER)} />
                </div>
            </div>
        )
    }

    setNewPasscode() {
        if (!this.state.mobileNumber && !this.state.newPassCode.length && !this.state.reEnteredPassCode.length) {
            return
        }

        let userName = this.state.userName
        let mobileNumber = this.state.mobileNumber
        let passCode = this.state.reEnteredPassCode
        const countryCode = this.state.selectedCountry.dialCode


        if (!userName) return
        if (!this.validateMobile(this.state.mobileNumber)) {
            //console.log('invlaid')
            this.setState({ isInvalidMobile: true });
            return;
        } else {
            this.setState({ isInvalidMobile: false });
        }

        let payload = {
            userName,
            countryCode,
            mobileNumber,
            passCode,
        }



        AuthenticateAPI.createNewPassCode(payload).then(res => {
            //console.log(res)

            if (res.status === 200) {

                this.resetOtpBoxs()

                if (this.state.showSetNewPassCodeCard) {
                    this.setState({
                        showSetNewPassCodeCard: false, showNewPasscodeSetText: false,
                        otp: '', isOtpSent: false, showSetNewPassCodeToast: true
                    }, () => {
                        setTimeout(() => {
                            this.setState({ showSetNewPassCodeToast: false })
                        }, 3000)

                        this.resetNewPasscodeInputBoxs()
                        this.resetReEnteredPasscodeInputBoxs()
                        this.resetPasscodeInputBoxs()

                    })

                } else if (this.state.showForgotPasscodeCard) {
                    this.setState({
                        showForgotPasscodeCard: false, showNewPasscodeSetText: false,
                        otp: '', isOtpSent: false, showPasscodeResetToast: true
                    }, () => {
                        setTimeout(() => {
                            this.setState({ showPasscodeResetToast: false })
                        }, 3000)
                        this.resetNewPasscodeInputBoxs()
                        this.resetReEnteredPasscodeInputBoxs()
                        this.resetPasscodeInputBoxs()

                    })
                } else if (this.state.showPasscodeUpdateScreen) {
                    this.setState({
                        showPasscodeUpdateScreen: false, showNewPasscodeSetText: false,
                        otp: '', isOtpSent: false, showSetNewPassCodeToast: true,
                    }, () => {
                        setTimeout(() => {
                            this.setState({ showSetNewPassCodeToast: false })
                        }, 3000)

                        this.resetNewPasscodeInputBoxs()
                        this.resetReEnteredPasscodeInputBoxs()
                        this.resetPasscodeInputBoxs()

                    })
                }

            }

        }).catch(err => {
            //console.log(err)
        })
    }


    showForgotPasscodeCard() {
        this.setState({ showForgotPasscodeCard: true })
    }

    goToSignup() {
        this.props.history.replace(Path.SIGNUP)
    }

    updatePasscode(newPassCode, reEnteredPassCode) {
        this.setState({ newPassCode: newPassCode, reEnteredPassCode: reEnteredPassCode }, () => {
            this.setNewPasscode()
        })
    }


    renderPassCodeInputBoxes() {
        let validate = CommonUtils.validateMobileNumber(this.state.mobileNumber, this.coutryCodeHash[this.state?.selectedCountry?._id])
        return (
            <div className="passcode-boxes">
                <input autoFocus={validate ? true : false} id="passcodeBox1" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, 'passcodeBox')} onFocus={() => this.onFocusEvent(1, 'passcodeBox')} onChange={(e) => this.onChangeHandler(e, 'passcodeBox1')} value={this.state.passcodeBox1} />
                <input id="passcodeBox2" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, 'passcodeBox')} onFocus={() => this.onFocusEvent(2, 'passcodeBox')} onChange={(e) => this.onChangeHandler(e, 'passcodeBox2')} value={this.state.passcodeBox2} />
                <input id="passcodeBox3" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, 'passcodeBox')} onFocus={() => this.onFocusEvent(3, 'passcodeBox')} onChange={(e) => this.onChangeHandler(e, 'passcodeBox3')} value={this.state.passcodeBox3} />
                <input id="passcodeBox4" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, 'passcodeBox')} onFocus={() => this.onFocusEvent(4, 'passcodeBox')} onChange={(e) => this.onChangeHandler(e, 'passcodeBox4')} value={this.state.passcodeBox4} />
            </div>)
    }


    renderNewPassCodeInputBoxes() {
        return (
            <div className="passcode-boxes">
                <input id="newPasscodeBox1" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(1, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox1')} value={this.state.newPasscodeBox1} />
                <input id="newPasscodeBox2" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(2, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox2')} value={this.state.newPasscodeBox2} />
                <input id="newPasscodeBox3" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(3, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox3')} value={this.state.newPasscodeBox3} />
                <input id="newPasscodeBox4" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(4, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox4')} value={this.state.newPasscodeBox4} />
            </div>)
    }

    renderReEnterPassCodeInputBoxes() {
        return (
            <div className="passcode-boxes">
                <input id="reEnteredPasscodeBox1" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(1, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox1')} value={this.state.reEnteredPasscodeBox1} />
                <input id="reEnteredPasscodeBox2" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(2, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox2')} value={this.state.reEnteredPasscodeBox2} />
                <input id="reEnteredPasscodeBox3" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(3, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox3')} value={this.state.reEnteredPasscodeBox3} />
                <input id="reEnteredPasscodeBox4" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(4, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox4')} value={this.state.reEnteredPasscodeBox4} />
            </div>)
    }

    renderOtpInput() {
        return (
            <div className="passcode-boxes">
                <input id="otpBox1" className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`} type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, "otpBox")} onFocus={() => this.onFocusEvent(1, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox1')} value={this.state.otpBox1} />
                <input id="otpBox2" className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`} type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, "otpBox")} onFocus={() => this.onFocusEvent(2, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox2')} value={this.state.otpBox2} />
                <input id="otpBox3" className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`} type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, "otpBox")} onFocus={() => this.onFocusEvent(3, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox3')} value={this.state.otpBox3} />
                <input id="otpBox4" className={`${this.state.isLoginFailed || this.state.invalidOtp ? 'invalid' : ''}`} type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, "otpBox")} onFocus={() => this.onFocusEvent(4, "otpBox")} onChange={(e) => this.onChangeHandler(e, 'otpBox4')} value={this.state.otpBox4} />
            </div>)
    }


    getButtons() {
        return (
            this.state.mobileNumber && this.state.passCode.length == 4 ?

                <div className="d-flex align-items-center justify-content-center" onClick={() => this.onLoginWithPassCodeSubmit()}>
                    <div className="text-center login-button cursor-pointer">Login</div>
                </div>
                :
                <div className="d-flex align-items-center justify-content-center" >
                    <div className="text-center login-button login-button-disabled" onClick={() => this.showCustomToaster()}>Login</div>
                </div>
        )
    }


    renderNewPasscodeCard() {
        return (
            <div>
                <div className="row mb-1">
                    <div className="col-1 mt-1 cursor-pointer" onClick={() => this.goBackToLoginWithPassCode()}><img className="back-arrow" src={getImageSource(ImageConstants.BACK_ARROW)} /></div>
                    <div className="col  login-title text-center">Set New Passcode</div>
                </div>

                <div className="user-nickname text-center">{this.state.nickName ? this.state.nickName : ''}</div>

                <div className="mobile-number mb-3">
                    <label className="cw-input-label-1 mb-3">Mobile Number</label>
                    <div className="cw-input ">
                        <span className={`d-inline-block country-code ${this.state.isOtpSent ? "cursor: default" : "cursor-pointer"}`} onClick={() => this.openCountryCode()}>
                            {this.state.selectedCountry.dialCode}
                        </span>
                        <img className={`country-code-down-arrow cursor-pointer 
                                        ${this.state.selectedCountry.dialCode.length == 3 ? 'country-code-down-arrow-margin' : ''}`}
                            src={getImageSource(ImageConstants.DOWN_ARROW)} />
                        <img className="country-code-separator" src={getImageSource(ImageConstants.COUNTRY_SEPARATOR)} />
                        <input className="d-inline-block cw-input-1"
                            placeholder={this.staticText.mobilePlaceHolder}
                            onChange={event => this.onChangeHandler(event, 'mobileNumber')}
                            onBlur={(event) => this.validateMobile(event.target.value)}
                            value={this.state.mobileNumber}
                            disabled={this.state.isOtpSent}
                        />
                        {this.state.isOtpSent ?
                            <span className="float-right mr-1 mt-2">
                                <img className="cursor-pointer edit-icon-for-login" onClick={() => this.onMobileNumberEdit()} src={getImageSource(ImageConstants.EDIT_ICON_FOR_LOGIN)} />
                            </span> : ""
                        }
                        <div className="abs-div"  >
                            {this.state.showDropdown ? this.countryCodeDropDown(this.state.countryCodeDropDown) : ""}
                        </div>

                    </div>
                    {(this.state.isSubmit && !this.state.mobileNumber) || this.state.isInvalidMobile ? <div className="invalid-mobilenumber">
                        Please enter Valid Mobile Number</div> : ''}


                </div>
                <div className="pass-code">
                    <label className="cw-input-label-1">Create New Passcode</label>
                    {this.renderNewPassCodeInputBoxes()}
                    <div className="text-left enter-passcode">Enter new 4 digit passcode</div>
                    <label className="cw-input-label-1 mt-2">Re-enter Passcode</label>
                    {this.renderReEnterPassCodeInputBoxes()}
                    {
                        this.state.showPasscodeNotMatch ?
                            <div className="custom-toast-for-invalid-passcode">
                                <CustomToaster toasterMessage="Passcode does mot match" type="error" />
                            </div>
                            :
                            ""
                    }
                </div>

                <div className="d-flex align-items-center justify-content-center" >
                    <div className={`text-center login-button  confirm-button 
                    ${this.state.mobileNumber && this.state.confirmPasscode ? 'confirm-button-enabled cursor-pointer' : ''}`}
                        onClick={() => this.setNewPasscode()}>
                        Confirm
                    </div>
                </div>
                <div className="text-center register-coutingwell mt-2">
                    <span className="new-to-countingwell">New to Countingwell ?  </span>
                    <span className="register cursor-pointer" onClick={() => this.goToSignup()}>REGISTER</span>
                </div>

            </div>
        )
    }

    renderForgotPasscodeCard() {
        return (
            <div className="p-3 forgot-passcode-container">
                <div className="row mb-3">
                    <div className="col-1 mt-1 cursor-pointer" onClick={() => this.goBackToLoginWithPassCode()}><img className="back-arrow" src={getImageSource(ImageConstants.BACK_ARROW)} /></div>
                    <div className="col login-title text-center">Forgot Passcode</div>
                </div>
                <div className="mobile-number-container mb-3">
                    {this.state.showSendOtp ? <div className="mb-3">We will send an OTP to your registered number for authentication.</div> : ""}
                    {!this.state.isInvalidMobile && this.state.isOtpSent && !this.state.showPasscodeInputBoxs && <div className="mb-3">OTP Sent to <strong>{this.state.selectedCountry.dialCode}{this.state.mobileNumber}</strong></div>}
                    <label className="cw-input-label-1 mb-2">Enter Registered Mobile Number</label>
                    <div className="cw-input ">
                        <span className={`d-inline-block country-code ${this.state.isOtpSent ? "cursor: default" : "cursor-pointer"}`} onClick={() => this.openCountryCode()}>
                            {this.state.selectedCountry.dialCode}
                        </span>
                        <img className={`country-code-down-arrow cursor-pointer 
                                        ${this.state.selectedCountry.dialCode.length == 3 ? 'country-code-down-arrow-margin' : ''}`}
                            src={getImageSource(ImageConstants.DOWN_ARROW)} />
                        <img className="country-code-separator" src={getImageSource(ImageConstants.COUNTRY_SEPARATOR)} />
                        <input className="d-inline-block cw-input-1"
                            placeholder={this.staticText.mobilePlaceHolder}
                            onChange={event => this.onChangeHandler(event, 'mobileNumber')}
                            onBlur={(event) => this.validateMobile(event.target.value)}
                            value={this.state.mobileNumber}
                            disabled={this.state.isOtpSent}
                        />
                        {this.state.isOtpSent && !this.state.showPasscodeInputBoxs ?
                            <span className="forgot-passcode-edit-icon">
                                <img className="cursor-pointer edit-icon-for-login" onClick={() => this.onMobileNumberEdit()} src={getImageSource(ImageConstants.EDIT_ICON_FOR_LOGIN)} />
                            </span> : ""
                        }
                        <div className="abs-div"  >
                            {this.state.showDropdown ? this.countryCodeDropDown(this.state.countryCodeDropDown) : ""}
                        </div>

                    </div>
                    {this.state.mobileNumber && this.state.isInvalidMobile ?
                        <div className="invalid-mobilenumber">
                            {this.staticText.invalidMobileMessage}
                        </div>
                        : ''
                    }
                    {this.state.isMobileNumberNotRegistered ?
                        <div className="invalid-mobilenumber">
                            {this.staticText.unRegisteredMobileMessage}
                        </div>
                        : ''
                    }


                </div>

                {
                    this.state.isOtpSent && !this.state.showPasscodeInputBoxs ?
                        <div className="pass-code">
                            <label className="cw-input-label-1">Enter OTP</label>
                            {this.renderOtpInput()}

                            {
                                this.state.isLoginFailed || this.state.invalidOtp ?
                                    <>
                                        {
                                            this.state.showToastMessage ?
                                                <div className="custom-toast-for-invalid-otp">
                                                    <CustomToaster toasterMessage="Incorrect OTP, try again" type="error" />
                                                </div> : ''
                                        }
                                    </>

                                    : ''
                            }
                            {
                                !this.state.showTimer ?
                                    <div className="text-right resend-otp mr-3 ">
                                        <span className="cursor-pointer" onClick={() => this.resendOtp()}>{this.otpStaticText.resendOtp}</span></div>
                                    :
                                    <div className="text-right resend-otp">{this.otpStaticText.resendOtpIn} <strong>{this.state.seconds}</strong>s</div>
                            }
                        </div>
                        : ''
                }

                {
                    !this.state.isOtpSent && this.state.showToastMessage ?
                        <div className="custom-toast-for-forgot-passcode">
                            <CustomToaster toasterMessage="Please enter your phone number" />
                        </div> : ''
                }



                {this.state.isOtpSent && !this.state.showPasscodeInputBoxs ?
                    <>
                        {
                            this.state.mobileNumber &&
                                !this.state.isInvalidMobile &&
                                this.state.otp.length == 4 ?
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="text-center login-button verify-button-enabled cursor-pointer" onClick={() => this.onSubmit("verifyOtp")}>Verify</div>
                                </div>
                                :
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="text-center login-button verify-button ">Verify</div>
                                </div>
                        }
                    </>
                    :
                    ""
                }


                {this.state.showSendOtp ?
                    <>
                        {
                            this.state.mobileNumber && !this.state.isMobileNumberEdit && !this.state.isInvalidMobile ?
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="text-center login-button send-otp-button-enabled cursor-pointer" onClick={() => this.onSubmit("sendOtp")}>Send OTP</div>
                                </div>
                                :
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="text-center login-button send-otp-disabled" onClick={() => this.showCustomToaster()}>Send OTP</div>
                                </div>
                        }
                    </> : ''}


                {
                    this.state.showPasscodeInputBoxs ?
                        <>
                            <div className="pass-code">
                                <label className="cw-input-label-1">Create New Passcode</label>
                                {this.renderNewPassCodeInputBoxes()}
                                <div className="text-left enter-passcode">Enter new 4 digit passcode</div>
                                <label className="cw-input-label-1 mt-2">Re-enter Passcode</label>
                                {this.renderReEnterPassCodeInputBoxes()}
                            </div>
                            {
                                this.state.showPasscodeNotMatch ?
                                    <div className="custom-toast-for-passcode-not-match">
                                        <CustomToaster toasterMessage="Passcode does mot match" />
                                    </div>
                                    :
                                    ""
                            }

                            <div className="d-flex align-items-center justify-content-center" >
                                <div className={`text-center login-button  confirm-button 
                    ${this.state.mobileNumber && this.state.confirmPasscode ? 'confirm-button-enabled cursor-pointer' : ''}`}
                                    onClick={() => this.setNewPasscode()}>
                                    Confirm
                                </div>
                            </div>
                        </>
                        :
                        ""
                }


            </div>
        )
    }


    getMobileViewScreen() {
        return (
            <div className="mobile-login-screen">
                <div className="header-abs-div">
                    <img className="login-header" src={getImageSource(ImageConstants.LOGIN_HEADER)} />
                </div>
                <div className="info-text">Looks like you are using a mobile phone to visit this page. In that case, use the
                    Countingwell App to access your content.</div>
                <div className="d-flex justify-content-center">
                    <div className="app-button">
                        <a className="navigation-text" href="https://t2m3p.app.link/mmfmJXCBulb" target="_blank">Click Here to Download the app</a>
                    </div>
                </div>

                <div className="footer-abs-div">
                    <img className="login-footer" src={getImageSource(ImageConstants.LOGIN_FOOTER)} />
                </div>
            </div>
        )
    }




    render() {

        this.staticText = SharedStaticText.getStaticText('studentLogin');

        //console.log(this.state.renderMobileView)
        if (this.state.renderMobileView) {
            return (
                <>
                    {this.getMobileViewScreen()}
                </>
            )
        }

        if (this.state.showSelectUserScreen) {
            return (
                <SelectUser delegate={this} usersList={this.state.usersList}
                    basePath={this.state.basePath}
                    passcode={this.state.passCode}
                    otp={this.state.otp.length === 4 ? this.state.otp : ''}
                    forgotPasscodeFlow={this.state.showForgotPasscodeCard}
                    showPasscodeResetCard={(selectedUserName) => this.showPasscodeResetCard(selectedUserName)}
                    setNewPasscodeFlow={this.state.setNewPasscodeFlow}
                    showSetNewPasscode={(selectedUser) => this.showSetNewPasscode(selectedUser)}
                    showWrongPasscodeErrorMessage={this.state.showWrongPasscodeErrorMessage} />
            )
        }

        if (this.state.showPasscodeUpdateScreen) {
            return (
                <PasscodeUpdateScreen delegate={this} />
            )
        }



        return (
            <div className="login-screen container-fluid">
                <div className="d-flex align-items-center justify-content-center container-height">
                    <div className="header-abs-div">
                        <img className="login-header" src={getImageSource(ImageConstants.LOGIN_HEADER)} />
                    </div>

                    <div className="d-flex flex-column logo-container text-center">
                        <div>
                            <img className="coutingwell-logo" src={getImageSource(ImageConstants.COUNTINGWELL_LOGO)} />
                        </div>
                        <div>
                            <img className="female-workout-image" src={getImageSource(ImageConstants.FEMALE_WORKOUT_IMAGE)}></img>
                        </div>
                        <div className="mt-3 master-maths-text">Master Maths in just</div>
                        <div className="master-maths-text master-maths-sub-text">20 mins a day</div>
                    </div>


                    <div className="d-flex align-item-center justify-content-center login-container cw-card">
                        <div className="cw-card-body">
                            {
                                !this.state.showForgotPasscodeCard && !this.state.showSetNewPassCodeCard ?
                                    <div>
                                        <div className="row mb-3">
                                            {this.state.loginWithOTP && <div className="col-1 mt-1 cursor-pointer" onClick={() => this.goBackToLoginWithPassCode()}><img className="back-arrow" src={getImageSource(ImageConstants.BACK_ARROW)} /></div>}
                                            <div className="col  login-title text-center">Student Login</div>
                                        </div>

                                        <div className="login-with-password">
                                            {this.state.loginWithOTP && !this.state.isInvalidMobile && this.state.isOtpSent && <div className="mb-3">OTP Sent to <strong>{this.state.selectedCountry.dialCode}{this.state.mobileNumber}</strong></div>}
                                            <div className="mobile-number mb-3">
                                                <label className="cw-input-label-1 mb-3">Mobile Number</label>
                                                <div className="cw-input">
                                                    <span className={`d-inline-block country-code ${this.state.isOtpSent ? "cursor: default" : "cursor-pointer"}`} onClick={() => this.openCountryCode()}>
                                                        {this.state.selectedCountry.dialCode}
                                                    </span>
                                                    <img onClick={() => this.openCountryCode()} className={`country-code-down-arrow cursor-pointer ${this.state.selectedCountry.dialCode.length == 3 ? 'country-code-down-arrow-margin' : ''}`} src={getImageSource(ImageConstants.DOWN_ARROW)} />
                                                    <img className="country-code-separator" src={getImageSource(ImageConstants.COUNTRY_SEPARATOR)} />
                                                    <input className="d-inline-block cw-input-1"
                                                        placeholder={this.staticText.mobilePlaceHolder}
                                                        onChange={event => this.onChangeHandler(event, 'mobileNumber')}
                                                        onBlur={(event) => this.validateMobile(event.target.value)}
                                                        value={this.state.mobileNumber}
                                                        disabled={this.state.isOtpSent}
                                                    />
                                                    {this.state.isOtpSent ?
                                                        <span className="float-right mr-2 mt-2">
                                                            <img className="cursor-pointer edit-icon-for-login" onClick={() => this.onMobileNumberEdit()} src={getImageSource(ImageConstants.EDIT_ICON_FOR_LOGIN)} />
                                                        </span> : ""
                                                    }
                                                    <div className="abs-div" >
                                                        {this.state.showDropdown ? this.countryCodeDropDown(this.state.countryCodeDropDown) : ""}
                                                    </div>

                                                </div>
                                                {this.state.mobileNumber && this.state.isInvalidMobile ?
                                                    <div className="invalid-mobilenumber">
                                                        {this.staticText.invalidMobileMessage}
                                                    </div>
                                                    : ''
                                                }
                                                {this.state.isMobileNumberNotRegistered ?
                                                    <div className="invalid-mobilenumber">
                                                        {this.staticText.unRegisteredMobileMessage}
                                                    </div>
                                                    : ''
                                                }

                                            </div>
                                            {
                                                !this.state.loginWithOTP ?
                                                    <div>
                                                        <div className="pass-code">
                                                            <label className="cw-input-label-1">Enter Passcode</label>
                                                            {this.renderPassCodeInputBoxes()}

                                                            {
                                                                this.state.showToastMessage ?
                                                                    <div className="custom-toast">
                                                                        <CustomToaster toasterMessage="Please enter your phone number" />
                                                                    </div> : ''
                                                            }

                                                            {
                                                                this.state.invalidPasscodeEntered ? <div className="custom-toast-for-invalid-otp">
                                                                    <CustomToaster toasterMessage="Incorrect Passcode Entered" type="error" />
                                                                </div> : ''
                                                            }

                                                            {
                                                                this.state.showPasscodeResetToast ?
                                                                    <div className="custom-toast">
                                                                        <CustomToaster toasterMessage="Passcode Change Success" showImage={true} />
                                                                    </div> : ''
                                                            }
                                                            {
                                                                this.state.showSetNewPassCodeToast ?
                                                                    <div className="custom-toast-for-new-passcode">
                                                                        <CustomToaster toasterMessage="New Passcode Set" showImage={true} />
                                                                    </div> : ''
                                                            }

                                                            {this.state.invalidPsscode ?
                                                                <div className="invalid-mobilenumber">
                                                                    Enter Correct Passcode
                                                                </div>
                                                                : ''
                                                            }
                                                            <div className="forgot-passcode "><span className="cursor-pointer" onClick={() => this.showForgotPasscodeCard()}>Forgot Passcode</span></div>
                                                        </div>
                                                        {
                                                            this.state.showNewPasscodeSetText ?
                                                                <div className="text-center register-coutingwell mt-3">
                                                                    <div className="new-to-countingwell">Looks like you haven’t set your passcode.</div>
                                                                    <span><span className="register cursor-pointer" onClick={() => this.showSetNewPassCodeCard()}>CLICK HERE</span> to set passcode</span>
                                                                </div>
                                                                :
                                                                ''
                                                        }

                                                        {this.getButtons()}
                                                        {/* {
                                                            this.state.mobileNumber ?
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <div className="text-center login-with-otp cursor-pointer" onClick={() => this.changeLoginStep()}>Login with OTP</div>
                                                                </div>
                                                                :
                                                                <div className="d-flex align-items-center justify-content-center" >
                                                                    <div className="text-center login-with-otp login-with-otp-disabled" onClick={() => this.showCustomToaster()}>Login with OTP</div>
                                                                </div>
                                                        } */}


                                                    </div>
                                                    :
                                                    <div>
                                                        {
                                                            this.state.isOtpSent ?
                                                                <div className="pass-code">
                                                                    <label className="cw-input-label-1">Enter OTP</label>
                                                                    {this.renderOtpInput()}
                                                                    {
                                                                        this.state.isLoginFailed || this.state.invalidOtp ?
                                                                            <>
                                                                                {
                                                                                    this.state.showToastMessage ?
                                                                                        <div className="custom-toast-for-invalid-otp">
                                                                                            <CustomToaster toasterMessage="Incorrect OTP, try again" type="error" />
                                                                                        </div> : ''
                                                                                }
                                                                            </>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        !this.state.showTimer ?
                                                                            <div className="text-right resend-otp mr-3 ">
                                                                                <span className="cursor-pointer" onClick={() => this.resendOtp()}>{this.otpStaticText.resendOtp}</span></div>
                                                                            :
                                                                            <div className="text-right resend-otp">{this.otpStaticText.resendOtpIn} <strong>{this.state.seconds}</strong>s</div>
                                                                    }
                                                                </div>
                                                                : ''
                                                        }


                                                        {this.state.isOtpSent ?
                                                            <>
                                                                {
                                                                    this.state.mobileNumber &&
                                                                        !this.state.isInvalidMobile &&
                                                                        this.state.otp.length === 4 ?
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="text-center login-button verify-button-enabled cursor-pointer" onClick={() => this.onSubmit("verifyOtp")}>Verify</div>
                                                                        </div>
                                                                        :
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="text-center login-button verify-button ">Verify</div>
                                                                        </div>
                                                                }
                                                            </>
                                                            :
                                                            ""
                                                        }


                                                        {this.state.showSendOtp ?
                                                            <>
                                                                {
                                                                    this.state.mobileNumber && !this.state.isMobileNumberEdit && !this.state.isInvalidMobile ?
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="text-center login-button send-otp-button-enabled cursor-pointer" onClick={() => this.onSubmit("sendOtp")}>Send OTP</div>
                                                                        </div>
                                                                        :
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="text-center login-button send-otp-disabled">Send OTP</div>
                                                                        </div>
                                                                }
                                                            </> : ''}

                                                    </div>
                                            }

                                        </div>
                                        <div className="text-center register-coutingwell">
                                            <span className="new-to-countingwell">New to Countingwell ?  </span>
                                            <span className="register cursor-pointer" onClick={() => this.goToSignup()}>REGISTER</span>
                                        </div>

                                    </div>
                                    : ''
                            }

                            {
                                this.state.showForgotPasscodeCard ?
                                    this.renderForgotPasscodeCard()
                                    :
                                    ''
                            }

                            {
                                this.state.showSetNewPassCodeCard ? this.renderNewPasscodeCard() : ""
                            }

                        </div>
                    </div>

                    <div className="footer-abs-div">
                        <img className="login-footer" src={getImageSource(ImageConstants.LOGIN_FOOTER)} />
                    </div>


                </div>

            </div>
        )
    }

}


export default LoginScreen;
export default class SharedThemeCategory {

    static categories = {};

    static setThemeCategory(categories) {
        this.categories = categories;
    }

    static getThemeCategory(id) {

        if (id) {
            return this.categories[id];
        }

        else {
            return this.categories['1']
        }

    }

    static getAllCategories() {
        return this.categories
    }

}
import React from "react";
import './my-assessments.scss';
import { getImageSource } from "../../utility/image-source";
import { ImageConstants } from "../../constants/constants";
import ChapterList from "../common/chapter-list/chapter-list";
import SharedProfile from "../../../shared-storage/profile";
import CommonApi from "../../apis/common";
import StartAssessment from "../common/start-assessment/start-assessment";
import AssessmentEngine from "../common/assessment-engine/assessment-engine";
import SharedThemeCategory from "../../../shared-storage/category-theme";
import AssessmentEndScreen from "./assessment-end-screen/assessment-end-screen";
import MyAssessmentResult from "../my-assessment-result/my-assessment-result";
import AssessmentApi from "../../apis/assessment";

class MyAssessments extends React.Component {


    state = {
        searchText: '',
        showStartAssessment: false,
        showAssessmentEngine: false,
        showAssessmentEndScreen: false,
        showViewAnswersScreen: false
    }


    constructor(props) {
        super(props)


    }



    componentDidMount() {
        this.getAssessmentMeta()
        if (this.props.location && this.props.location.state && this.props.location.state.from &&
            this.props.location.state.from === 'CLASS_MOCK_TEST') {
            console.log(this.props.location.state.from)
            this.getUserAssessmentActivity()
        }
    }

    getAssessmentMeta = () => {

        CommonApi.getMiscellaneousList({ type: 'ASSESSMENT_META' }).then((data) => {

            //console.log(data)
            // if (this.state.loading)
            //     this.toggleLoading(false)

            // if (this.state.fullScreenLoading)
            //     this.toggleFullScreenLoading(false)

            if (data) {
                this.setState({ assessmentMeta: data.response[0] })
            }
        }).catch(err => {
            if (err) {
                // if (this.state.loading)
                //     this.toggleLoading(false)

                // if (this.state.fullScreenLoading)
                //     this.toggleFullScreenLoading(false)

                return
            }
            //console.log(err)
        })
    }

    getUserAssessmentActivity() {
        let payload = {
            chapterKeys: '17200704731054394',
            status: 'COMPLETED'
        }
        AssessmentApi.getUserAssessmentActivity(payload).then(res => {
            console.log(res)

            if(res.contents.length) {
                this.showAssessmentEndScreen({data : res.contents[0], chapterTitle: 'Diagnostic Assessment',
                    categoryThemeId: 1,
                    assessmentActivityId: res.contents[0]._id,
                    chapterKey: '17200704731054394',})
            }else {
                this.onStart({ "chapterKey": "17200704731054394", chapterTitle: 'Diagnostic Assessment', categoryId: 1 })
            }


        }).catch(err => {
            console.log(err)
        })
    }


    setSearchText(event, key) {
        this.setState({ [key]: event.target.value }, () => {
            if (!this.state.searchText) {
                this.getSearchedChapterList()
            }
        })
    }


    getSearchedChapterList() {
        this.setState({ reload: true }, () => {
            this.setState({ reload: false })
        })
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb'>
                <span>{`Home >`}</span>
                <span className='pl-2'>{`Assessment`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    onStart = (data) => {
        //console.log(data)
        this.setState({ showStartAssessment: true, chapterKey: data.chapterKey, chapterTitle: data.chapterTitle, categoryThemeId: data.categoryId })
        // this.props.navigation.navigate('StartAssessment', { meta: { chapterKey: data.chapterKey, chapterTitle: data.chapterTitle, categoryThemeId: data.categoryId } })
    }

    hideStartAssessmentScreen() {
        this.setState({ showStartAssessment: false })
    }

    showAssessmentEngine(data) {
        //console.log(data)
        this.setState({ showAssessmentEngine: true, showStartAssessment: false, ...data })
    }

    showAssessmentEndScreen(assessmentCompletedInfo) {
        console.log(assessmentCompletedInfo)
        this.setState({ showAssessmentEngine: false, showAssessmentEndScreen: true, ...assessmentCompletedInfo })
    }

    showViewAnswersScreen(assessmentEndScreenInfo) {
        this.setState({ showViewAnswersScreen: true, showAssessmentEndScreen: false, assessmentEndScreenInfo: assessmentEndScreenInfo.meta })
    }

    hideViewAnswersScreen() {
        this.setState({ showViewAnswersScreen: false, showAssessmentEndScreen: true })
    }


    renderChapterList() {
        return (
            <div className="chapter-list">
                <div className="text-right">
                    <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                </div>
                <div className="header-title-margin-top">
                    {this.renderBreadcrumbForChapterList()}
                    <div className="d-flex justify-content-between">
                        <div className="title">Assessment</div>
                        <div className="text-right mr-5 ">
                            <img className="search-icon-width"
                                src={getImageSource(ImageConstants.CHAPTER_SEARCH_ICON)}
                                onClick={() => this.getSearchedChapterList()}></img>
                            <input
                                className="input-box"
                                type="text"
                                value={this.state.searchText}
                                onChange={e => this.setSearchText(e, 'searchText')}
                                placeholder='Search Chapter'>
                            </input>

                        </div>
                    </div>
                    <div>
                        <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                    </div>
                </div>
                {
                    !this.state.reload ?
                        <ChapterList
                            type="MY_ASSESSMENTS"
                            assessmentMeta={this.state.assessmentMeta}
                            searchedChapterName={this.state.searchText}
                            onSelectChapter={(chapter) => this.selectedChapter(chapter)}
                            delegate={this} />
                        : ''
                }

            </div>
        )
    }



    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.state.categoryThemeId)
        //console.log(this.theme)


        if (this.state.showStartAssessment) {
            return (
                <StartAssessment delegate={this}
                    meta={{ chapterKey: this.state.chapterKey, chapterTitle: this.state.chapterTitle, categoryThemeId: this.state.categoryThemeId }}
                />
            )
        }

        if (this.state.showAssessmentEngine) {
            return (
                <AssessmentEngine meta={{
                    chapterKey: this.state.chapterKey, chapterTitle: this.state.chapterTitle, categoryThemeId: this.state.categoryThemeId,
                    questionSetId: this.state.questionSetId
                }}
                    delegate={this} />
            )
        }

        if (this.state.showAssessmentEndScreen) {
            return (
                <AssessmentEndScreen
                    metaInfo={this.state}
                    showViewAnswerScreen={(fromAssess) => this.showViewAnswersScreen(fromAssess)}
                />
            )
        }

        if (this.state.showViewAnswersScreen) {
            return (
                <MyAssessmentResult meta={this.state.assessmentEndScreenInfo}
                    hideViewAnswersScreen={() => this.hideViewAnswersScreen()} />
            )
        }


        return (
            <div className="my-assessments">
                {this.renderChapterList()}
            </div>

        )
    }

}

export default MyAssessments;
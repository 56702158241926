import React, { Component } from 'react';
import './approach-to-problem-solving-experience.scss';
import LearningContentWrapper from '../exam-prep-kit-learning-content-wrapper/exam-prep-kit-learning-content-wrapper';
import SharedProfile from '../../../../../shared-storage/profile';
import LearnMoreExperience from '../learn-more-experience/learn-more-experience'
import SolvedExample from '../solved-example-modal/solved-example-modal'
import PracticeModal from '../practice-modal/practice-modal'
import Loading from '../../../common/loading/loading';
import ExamPrepKitApi from '../../../../apis/exam-prep-kit';

export default class ApproachToProblemSolvingExperience extends Component {

    workflowEngine = null;
    worflowNodes = [];
    questions = [];
    state = {
        learningElos: [],
        intialLoading: true,
        loading: true
    };

    CONTENT_FETCH_LIMIT = 1
    contentHash = {}

    constructor(props) {

        super(props);
        // let { navigation } = this.props;
        // this.params = navigation.getParam('meta');
        this.params = this.props.metaInfo;
        this.headerTitle = this.params && this.params.headerTitle ? this.params.headerTitle : ""
        this.progress = null;
        this.userInfo = SharedProfile.getProfile();

        if (this.params.resumeIndex) {
            this.state.resumeIndex = this.params.resumeIndex;
            this.lastMaxIndex = this.params.resumeIndex
        } else {
            this.prepkitActivityUpdateForResume(0)
        }


        this.getApproachToProblemSolving()
    }

    learnMoreParam = {}

    componentDidMount() {
        // this.props.navigation.addListener("didFocus", () => {

        //     BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });

        // this.props.navigation.addListener("didBlur", () => {
        //     BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });
    }

    getApproachToProblemSolving = () => {

        let payload = {
            chapterKey: this.params.chapterKey,
            skill: this.params.skill
        }

        this.setState({ loading: true, intialLoading: true })
        ExamPrepKitApi.getApproachToProblemSolving(payload).then((response) => {
            // this.setState({ loading: false })

            let learningElos = this.state.learningElos;

            for (let i = 0; i < response.length; i++) {
                let content = response[i]
                learningElos.push({
                    eloId: content.eloId,
                    eloTitle: "",
                    categoryThemeId: "",
                    contentId: "",
                    contents: [],
                    meta: { ...content }
                })
            }

            this.contents = learningElos

            this.setState({ learningElos })

            let index = this.state.resumeIndex ? this.state.resumeIndex : 0
            this.getContents(learningElos, index, (err, eloDetail) => {

                this.setState({ intialLoading: false, loading: false })
                if (err)
                    return
            })
        }).catch(err => {
            console.log(err);
            if (err) {
                this.setState({ loading: false, intialLoading: false })
                this.learningElosCompleted()
                return
            }

        })

    }

    handleBackButtonClick = () => {
        return true
    }

    getContents = (elos, index, callback) => {

        let eloId = elos[index].eloId;

        let payload = { eloId }
        ExamPrepKitApi.getApproachToProblemSolvingEloDetails(payload).then((response) => {
            let learningElos = this.state.learningElos;
            let eloDetail = response

            if (eloDetail) {

                let contentList = []

                for (let i = 0; i < eloDetail.contents.length; i++) {
                    contentList.push({ title: "", contents: [eloDetail.contents[i]] })

                }

                learningElos[index] = {
                    ...learningElos[index],
                    eloTitle: eloDetail['eloTitle'],
                    categoryThemeId: eloDetail['categoryThemeId'],
                    contents: contentList
                }

                this.setState({ learningElos })
            }

            if (callback) {
                callback(null, response)
            }
        }).catch(err => {
            console.log(err);
            if (err) {

                if (this.state.loading)
                    this.setState({ loading: false })

                if (this.state.intialLoading)
                    this.setState({ intialLoading: false })
                return
            }
        })
    }

    prepkitActivityUpdateForResume = (index) => {
        let payload = {
            chapterKey: this.params.chapterKey,
            resumeId: index,
            type: this.params.from
        }

        ExamPrepKitApi.updatePrepKitActivity(payload).then((response) => {

        }).catch(err => {
            console.log(err);
            if (err) {
                return
            }
        })
    }

    onPressNext = (activeIndex, callback) => {

        /** Resume update */
        this.prepkitActivityUpdateForResume(activeIndex + 1)

        /** content activity capturing */
        let updateDoc = {
            contentId: this.contents[activeIndex].contentId,
            eloId: this.contents[activeIndex].eloId,
            linkedElo: this.contents[activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc)

        this.setState({ loading: true })
        this.getContents(this.state.learningElos, activeIndex + 1, (err, response) => {

            this.setState({ loading: false })
            if (err) {
                return
            }

            callback && callback(true)
        })

    }

    prepkitContentActivity = (doc, callback) => {

        let payload = {
            chapterKey: this.params.chapterKey
        }

        if (doc.eloId) {
            payload.eloId = doc.eloId;
        }

        payload.contentType = 'APPROACH_TO_PROBLEM_SOLVING'

        ExamPrepKitApi.updatePrepKitContentActivityApproachToProblemSolving(payload).then((response) => {
            callback && callback(null, response.data)
        }).catch(err => {
            console.log(err);
            if (err) {
                callback && callback(true, null)
                return;
            }
        })

    }

    toggleLateralMenu = (activeIndex) => {

        let updateDoc = {
            contentId: this.contents[activeIndex].contentId,
            eloId: this.contents[activeIndex].eloId,
            linkedElo: this.contents[activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc)
    }

    close = (activeIndex) => {
        let updateDoc = {
            contentId: this.contents[activeIndex].contentId,
            eloId: this.contents[activeIndex].eloId,
            linkedElo: this.contents[activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc)
        this.props.navigation.goBack()
    }

    learningElosCompleted = (activeIndex) => {

        let updateDoc = {
            contentId: this.contents[activeIndex].contentId,
            eloId: this.contents[activeIndex].eloId,
            linkedElo: this.contents[activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc)
        // this.props.navigation.goBack()
        this.goBackToSelectedContent()
    }

    goToLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    onPressPracticeParam = (doc, activeIndex) => {

        this.activeIndex = activeIndex;

        this.practiceParam = {
            eloId: doc.eloId,
            title: doc.eloTitle,
            headerTitle: "Practice",
            chapterKey: this.params.chapterKey,
            progress: doc.meta && doc.meta.progress && doc.meta.progress.practiceQuestions,
            linkedElo: doc.meta && doc.meta.linkedElo,
            categoryThemeId: doc && doc.categoryThemeId
        }

        this.setState({ showPracticeModal: true })
    }

    closePracticeModal = (lastResumeId, elosCompleted) => {

        let updateDoc = {
            contentId: this.contents[this.activeIndex].contentId,
            eloId: this.contents[this.activeIndex].eloId,
            linkedElo: this.contents[this.activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc, (err, response) => {
            if (response) {

                let learningElos = this.state.learningElos

                let selectedElo = this.state.learningElos[this.activeIndex]
                if (selectedElo && selectedElo.meta) {

                    if (selectedElo.meta.progress) {

                        selectedElo.meta.progress.percentage = response.percentage
                        selectedElo.meta.percentage = response.percentage

                        if (selectedElo.meta.progress.solvedExamples) {
                            selectedElo.meta.progress.solvedExamples.percentage = response.solvedExamples.percentage
                        } else {
                            selectedElo.meta.progress.solvedExamples = { percentage: response.solvedExamples.percentage }
                        }

                        if (selectedElo.meta.progress.practiceQuestions) {
                            selectedElo.meta.progress.practiceQuestions.percentage = response.practiceQuestions.percentage
                        } else {
                            selectedElo.meta.progress.practiceQuestions = { percentage: response.practiceQuestions.percentage }
                        }
                    } else {
                        selectedElo.meta.progress = {
                            percentage: response.percentage,
                            solvedExamples: { percentage: response.solvedExamples.percentage },
                            practiceQuestions: { percentage: response.practiceQuestions.percentage }
                        }
                    }
                } else {
                    selectedElo.meta = {
                        progress: {
                            percentage: response.percentage,
                            solvedExamples: { percentage: response.solvedExamples.percentage },
                            practiceQuestions: { percentage: response.practiceQuestions.percentage }
                        }
                    }
                }

                if (selectedElo && selectedElo.meta && selectedElo.meta.progress.practiceQuestions && selectedElo.meta.progress.practiceQuestions) {

                    if (lastResumeId && (lastResumeId[updateDoc.eloId] || lastResumeId[updateDoc.eloId] == 0)) {

                        if (selectedElo.meta.progress.practiceQuestions[updateDoc.eloId])
                            selectedElo.meta.progress.practiceQuestions[updateDoc.eloId].resumeId = lastResumeId[updateDoc.eloId]
                        else
                            selectedElo.meta.progress.practiceQuestions[updateDoc.eloId] = { resumeId: lastResumeId[updateDoc.eloId] }

                    }
                }

                if (selectedElo && selectedElo.meta && selectedElo.meta.progress.practiceQuestions && selectedElo.meta.progress.practiceQuestions) {

                    if (elosCompleted && elosCompleted[updateDoc.eloId]) {

                        if (selectedElo.meta.progress.practiceQuestions[updateDoc.eloId])
                            selectedElo.meta.progress.practiceQuestions[updateDoc.eloId].status = "COMPLETED"
                        else
                            selectedElo.meta.progress.practiceQuestions[updateDoc.eloId] = { status: "COMPLETED" }

                    }
                }

                console.log(selectedElo)
                this.setState({ learningElos })
            }
            this.setState({ showPracticeModal: false })
        })
    }

    onPressPage = (index, callback) => {

        this.setState({ loading: true })

        this.getContents(this.state.learningElos, index, (err, response) => {

            if (err) {
                this.setState({ loading: false })
                return
            }

            this.setState({ loading: false })

            this.getEachStepProgress(index)
            callback && callback(true)
        })
    }

    onPressSolvedExample = (doc, activeIndex) => {

        this.activeIndex = activeIndex
        console.log('param', doc)

        this.solvedExampleParam = {
            eloId: doc.eloId,
            title: doc.eloTitle,
            headerTitle: "Solved Examples",
            chapterKey: this.params.chapterKey,
            progress: doc.meta && doc.meta.progress && doc.meta.progress.solvedExamples,
            linkedElo: doc.meta && doc.meta.linkedElo
        }

        this.setState({ showSolvedExample: true })
    }

    closeLearnMore = () => {
        this.setState({ showLearnMore: false })
    }

    onPressLearnMore = (doc) => {

        this.learnMoreParam = {
            eloId: doc.eloId,
            title: this.params.title,
            headerTitle: doc.eloTitle,
            chapterKey: this.params.chapterKey
        }

        this.setState({ showLearnMore: true })
    }

    closeSolvedExample = (lastResumeId, elosCompleted) => {

        let updateDoc = {
            contentId: this.contents[this.activeIndex].contentId,
            eloId: this.contents[this.activeIndex].eloId,
            linkedElo: this.contents[this.activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc, (err, response) => {
            if (response) {

                let learningElos = this.state.learningElos

                let selectedElo = this.state.learningElos[this.activeIndex]

                console.log('::::', selectedElo.meta)

                if (selectedElo && selectedElo.meta) {

                    if (selectedElo.meta.progress) {

                        selectedElo.meta.progress.percentage = response.percentage
                        selectedElo.meta.percentage = response.percentage

                        if (selectedElo.meta.progress.solvedExamples) {
                            selectedElo.meta.progress.solvedExamples.percentage = response.solvedExamples.percentage
                        } else {
                            selectedElo.meta.progress.solvedExamples = { percentage: response.solvedExamples.percentage }
                        }

                        if (selectedElo.meta.progress.practiceQuestions) {
                            selectedElo.meta.progress.practiceQuestions.percentage = response.practiceQuestions.percentage
                        } else {
                            selectedElo.meta.progress.practiceQuestions = { percentage: response.practiceQuestions.percentage }
                        }
                    } else {
                        selectedElo.meta.progress = {
                            percentage: response.percentage,
                            solvedExamples: { percentage: response.solvedExamples.percentage },
                            practiceQuestions: { percentage: response.practiceQuestions.percentage }
                        }
                    }
                } else {
                    selectedElo.meta = {
                        progress: {
                            percentage: response.percentage,
                            solvedExamples: { percentage: response.solvedExamples.percentage },
                            practiceQuestions: { percentage: response.practiceQuestions.percentage }
                        }
                    }
                }

                if (selectedElo && selectedElo.meta && selectedElo.meta.progress.solvedExamples && selectedElo.meta.progress.solvedExamples) {

                    if (lastResumeId && (lastResumeId[updateDoc.eloId] || lastResumeId[updateDoc.eloId] == 0)) {

                        if (selectedElo.meta.progress.solvedExamples[updateDoc.eloId])
                            selectedElo.meta.progress.solvedExamples[updateDoc.eloId].resumeId = lastResumeId[updateDoc.eloId]
                        else
                            selectedElo.meta.progress.solvedExamples[updateDoc.eloId] = { resumeId: lastResumeId[updateDoc.eloId] }

                    }
                }

                if (selectedElo && selectedElo.meta && selectedElo.meta.progress.solvedExamples && selectedElo.meta.progress.solvedExamples) {

                    if (elosCompleted && elosCompleted[updateDoc.eloId]) {

                        if (selectedElo.meta.progress.solvedExamples[updateDoc.eloId])
                            selectedElo.meta.progress.solvedExamples[updateDoc.eloId].status = "COMPLETED"
                        else
                            selectedElo.meta.progress.solvedExamples[updateDoc.eloId] = { status: "COMPLETED" }

                    }
                }


                console.log(selectedElo)
                this.setState({ learningElos })
            }
            this.setState({ showSolvedExample: false })
        })
    }

    getEachStepProgress = (activeIndex) => {

        let payload = {
            chapterKey: this.params.chapterKey,
            eloId: this.contents[activeIndex].eloId,
            type: "APPROACH_TO_PROBLEM_SOLVING"
        }

        this.setState({ loading: true })
        ExamPrepKitApi.getEachStepProgress(payload).then((response) => {

            if (!response) {
                this.setState({ loading: false })
                return
            }

            let learningElos = this.state.learningElos

            let selectedElo = this.state.learningElos[activeIndex]

            if (selectedElo && selectedElo.meta) {

                if (selectedElo.meta.progress) {

                    selectedElo.meta.progress.percentage = response.percentage
                    selectedElo.meta.percentage = response.percentage

                    if (selectedElo.meta.progress.solvedExamples) {
                        selectedElo.meta.progress.solvedExamples.percentage = response.solvedExamples.percentage
                    }

                    if (selectedElo.meta.progress.practiceQuestions) {
                        selectedElo.meta.progress.practiceQuestions.percentage = response.practiceQuestions.percentage
                    }
                }
            }

            this.setState({ learningElos, loading: false })
        }).catch(err => {
            console.log(err);
            if (err) {
                this.setState({ loading: false })
                return
            }

        })
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToExamPrepKit() {
        this.props.delegate && this.props.delegate.goToExamPrepKit && this.props.delegate.goToExamPrepKit()
    }

    // goToExamPrepKitChapterList() {
    //     this.props.showPrepKitChapterList && this.props.showPrepKitChapterList()
    // }

    goBackToTableOfContents() {
        this.props.delegate && this.props.delegate.goBackToTableOfContents && this.props.delegate.goBackToTableOfContents()
    }

    goBackToSelectedContent() {
        if (this.params.origin && this.params.origin == 'APPROACH_TO_PROBLEM_SOLVING') {
            this.props.delegate && this.props.delegate.hideApproachToProblemSolvingExperience && this.props.delegate.hideApproachToProblemSolvingExperience()
            return;
        }
    }

    getTitle() {
        if (this.params.origin && this.params.origin == 'APPROACH_TO_PROBLEM_SOLVING') {
            return 'Approach to Problem Solving';
        }
    }



    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToExamPrepKit()} >{`Prepare`}{` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToTableOfContents()}>{`${this.params.title}`} {` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToTableOfContents()}>{`${`Table of Contents`}`} {` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToSelectedContent()}>{this.getTitle()}{` > `}</span>
                <span className='title-1 pl-2' >{this.params.headerTitle}</span>
            </div>
        )
    }

    render() {

        return (
            <div className='approach-to-problem-solving-experience'>
                {
                    this.renderBreadcrumbForChapterList()
                }
                {this.state.learningElos.length > 0 && !this.state.intialLoading  && !this.state.showLearnMore && !this.state.showSolvedExample &&
                    !this.state.showPracticeModal ? <LearningContentWrapper
                    elo={this.state.learningElos}
                    delegate={this}
                    navigation={this.props.navigation}
                    isRevise={this.isRevise}
                    title={this.params.title ? this.params.title : ''}
                    navigationInfo={this.params}
                    eloIds={this.eloIds}
                    noBlur={true}
                    headerIcon={this.params.headerIcon}
                    headerTitle={this.params.headerTitle}
                    removeHeaderIcon
                    showLearnMoreCard
                    onPressNext={this.onPressNext}
                    showPaginationProgress={true}
                    activeIndex={this.state.resumeIndex}
                    onPressPage={this.onPressPage}
                >
                </LearningContentWrapper> : <></>}

                {this.state.loading ? <Loading /> : <></>}

                {this.state.showLearnMore ? <LearnMoreExperience params={this.learnMoreParam} navigation={this.props.navigation} close={this.closeLearnMore}></LearnMoreExperience> : <></>}
                {this.state.showSolvedExample ? <SolvedExample params={this.solvedExampleParam} navigation={this.props.navigation} close={this.closeSolvedExample} isPrimary></SolvedExample> : <></>}


                {this.state.showPracticeModal ? 
                <div>
                    <PracticeModal params={this.practiceParam} close={this.closePracticeModal} isPrimary></PracticeModal>
                </div>
                    : <></>}

            </div>
        )
    }
}
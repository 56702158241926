import React, { Component } from 'react';
import './exam-prep-kit-learning-experience.scss';
import SharedProfile from '../../../../../shared-storage/profile';
import LearningContentWrapper from '../exam-prep-kit-learning-content-wrapper/exam-prep-kit-learning-content-wrapper';
import LearnMoreExperience from '../learn-more-experience/learn-more-experience'
import SolvedExample from '../solved-example-modal/solved-example-modal'
import PracticeModal from '../practice-modal/practice-modal'
import ExamPrepKitApi from '../../../../apis/exam-prep-kit';
import AssessmentApi from '../../../../apis/assessment';
import Loading from '../../../common/loading/loading';

export default class ExamPrepKitLearningExperience extends Component {

    workflowEngine = null;
    worflowNodes = [];
    questions = [];
    state = {
        learningElos: [
        ],
        resumeIndex: 0,
        index: 0
    };

    CONTENT_FETCH_LIMIT = 1
    contentHash = {}

    constructor(props) {

        super(props);
        // let { navigation } = this.props;
        // this.params = navigation.getParam('params');
        this.params = this.props.metaInfo;
        this.headerTitle = this.params && this.params.headerTitle ? this.params.headerTitle : ""
        this.progress = null;
        this.userInfo = SharedProfile.getProfile();

        this.contents = this.params.contents

        if (this.params.resumeIndex) {
            this.state.resumeIndex = this.params.resumeIndex;
            this.lastMaxIndex = this.params.resumeIndex

            if (this.contents)
                this.buildLearningContent(this.contents, this.params.resumeIndex)
        } else {
            if (this.contents)
                this.buildLearningContent(this.contents, 0)

            this.prepkitActivityUpdateForResume(0)
        }

    }

    learnMoreParam = {}

    componentDidMount() {
        // this.props.navigation.addListener("didFocus", () => {

        //     BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });

        // this.props.navigation.addListener("didBlur", () => {
        //     BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });
    }


    prepkitContentActivity = (doc, callback) => {

        let payload = {
            chapterKey: this.params.chapterKey
        }

        if (doc.contentId) {
            payload.contentId = doc.contentId
        }

        if (doc.eloId) {
            payload.eloId = doc.eloId;
        }

        if (doc.linkedElo) {
            payload.linkedElo = doc.linkedElo
        }

        if (this.params.origin && this.params.origin == 'THINGS_TO_REMEMBER') {
            payload.contentType = 'THINGS_TO_REMEMBER'
        }

        ExamPrepKitApi.updatePrepKitContentActivity(payload).then((response) => {
            callback && callback(null, response.data)
        }).catch(err => {
            if (err) {
                callback && callback(true, null)
                return;
            }
        })

    }

    getLearningContent = (elos, callback) => {

        let payload = {
            nodeId: this.params.nodeId
        }

        if (!this.params.isSubscribedFlow)
            payload['workoutId'] = this.params.workoutId

        if (this.params.isChapterNotCompleted)
            payload.isNotCompletedFlow = true;

        ExamPrepKitApi.getWorkoutLeanring(payload).then((response) => {


            // let data = response.data;

            // if (data) {
            //     let decodedHtmlText = decodeHTMLEntities(JSON.stringify(data));

            //     data = JSON.parse(decodedHtmlText);
            // }


            this.contentListData = response;

            this.setState({
                learningElos: response.learningGroups,
                showContentList: this.state.isNodeActivityExist || !this.params.isChapterNotCompleted ? true : false,
                isNodeActivityExist: true,
                disableNext: false
            });
            this.hideSuccessFailureScreen();
        }).catch(err => {
            console.log(err)
            if (callback) {
                callback(err, null)
                return;
            }


        })

    }

    handleBackButtonClick = () => {
        return true
    }

    buildLearningContent = (contents, index, callback) => {

        this.getContents(contents, index, (err, response) => {

            if (err) {
                return
            }

            for (let i = 0; i < response.length; i++) {
                let content = response[i]
                this.contentHash[content._id] = content
            }

            let learningElos = this.state.learningElos;

            console.log(contents)

            for (let i = 0; i < contents.length; i++) {
                let content = contents[i]

                if (this.contentHash[content.contentId]) {
                    learningElos.push({
                        eloId: content.eloId,
                        eloTitle: content.eloTitle,
                        categoryThemeId: content.categoryThemeId,
                        contentId: content.contentId,
                        contents: [{ title: "", contents: [this.contentHash[content.contentId]] }],
                        bookMarkedItem: content.bookMarkedItem ? content.bookMarkedItem : null,
                        meta: { ...content }
                    })
                } else {
                    learningElos.push({
                        eloId: content.eloId,
                        eloTitle: content.eloTitle,
                        categoryThemeId: content.categoryThemeId,
                        contentId: content.contentId,
                        contents: [],
                        bookMarkedItem: content.bookMarkedItem ? content.bookMarkedItem : null,
                        meta: { ...content }
                    })
                }
            }

            this.setState({ learningElos }, () => {
                if (callback)
                    callback()
            })
        })
    }

    getContents = (questions, index, callback) => {


        let ids = [];

        for (let i = 0; i < questions.length; i++) {

            if (index <= i) {
                if (ids.length < this.CONTENT_FETCH_LIMIT) {
                    if (!this.contentHash[questions[i].contentId])
                        ids.push(questions[i].contentId)
                }
            }
        }

        let payload = { _id: ids }

        AssessmentApi.getContentList(payload).then((res) => {
            callback && callback(null, res.response)
        }).catch(err => {
            console.log(err)
            if (err) {
                callback && callback(err, null)
                return;
            }
        })
    }

    buildLearningContentPagePress = (contents, index, callback) => {

        let learningElos = this.state.learningElos;

        if (this.contentHash[learningElos[index].contentId]) {
            learningElos[index].contents = [{ title: "", contents: [this.contentHash[learningElos[index].contentId]] }]

            this.setState({ learningElos }, () => {
                callback && callback()
            })
            return
        }

        this.getContents(contents, index, (err, response) => {

            if (err) {
                return
            }

            for (let i = 0; i < response.length; i++) {
                let content = response[i]
                this.contentHash[content._id] = content
            }

            for (let i = 0; i < learningElos.length; i++) {

                if (this.contentHash[learningElos[i].contentId]) {
                    learningElos[i].contents = [{ title: "", contents: [this.contentHash[learningElos[i].contentId]] }]
                }
            }

            this.setState({ learningElos }, () => {
                if (callback)
                    callback(response.length ? true : false)
            })
        })
    }

    prepkitActivityUpdateForResume = (index) => {
        let payload = {
            chapterKey: this.params.chapterKey,
            resumeId: index,
            type: this.params.from
        }

        ExamPrepKitApi.updatePrepKitActivity(payload).then((response) => {
        }).catch(err => {
            console.log(err)
            if (err) {
                return
            }
        })
    }

    onPressNext = (activeIndex, callback) => {

        console.log(':::', this.contents)

        /** Resume update */
        let payload = {
            chapterKey: this.params.chapterKey,
            resumeId: activeIndex + 1,
            type: this.params.from
        }

        ExamPrepKitApi.updatePrepKitActivity(payload, (err, response) => {

            if (err) {
                return
            }
        })

        /** content activity capturing */
        let updateDoc = {
            contentId: this.contents[activeIndex].contentId,
            eloId: this.contents[activeIndex].eloId,
            linkedElo: this.contents[activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc)

        if (this.contents[activeIndex + 1] && this.contentHash[this.contents[activeIndex + 1].contentId]) {
            callback && callback()
        } else {

            this.getContents(this.contents, activeIndex + 1, (err, response) => {

                if (err) {
                    return
                }

                for (let i = 0; i < response.length; i++) {
                    let content = response[i]
                    this.contentHash[content._id] = content
                }

                let learningElos = this.state.learningElos;

                for (let i = activeIndex + 1; i < learningElos.length; i++) {
                    let lerningContent = learningElos[i]

                    if (lerningContent.contentId && !lerningContent.contents.length && this.contentHash[lerningContent.contentId]) {
                        lerningContent.contents = [{ title: "", contents: [this.contentHash[lerningContent.contentId]] }]
                    }
                }

                this.setState({ learningElos, index: activeIndex + 1 }, () => {
                    if (callback)
                        callback(true)
                })
            })
        }
    }

    close = (activeIndex) => {
        let updateDoc = {
            contentId: this.contents[activeIndex].contentId,
            eloId: this.contents[activeIndex].eloId,
            linkedElo: this.contents[activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc)
        this.props.navigation.goBack()
    }

    learningElosCompleted = (activeIndex) => {

        let updateDoc = {
            contentId: this.contents[activeIndex].contentId,
            eloId: this.contents[activeIndex].eloId,
            linkedElo: this.contents[activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc)
        // this.props.navigation.goBack()
        this.goBackToSelectedContent()
    }

    goToLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    toggleLateralMenu = (activeIndex) => {
        let updateDoc = {
            contentId: this.contents[activeIndex].contentId,
            eloId: this.contents[activeIndex].eloId,
            linkedElo: this.contents[activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc)
    }

    onPressPracticeParam = (doc, activeIndex) => {

        this.activeIndex = activeIndex;

        this.practiceParam = {
            eloId: doc.eloId,
            title: doc.eloTitle,
            headerTitle: "Practice",
            chapterKey: this.params.chapterKey,
            progress: doc.meta && doc.meta.progress && doc.meta.progress.practiceQuestions,
            linkedElo: doc.meta && doc.meta.linkedElo,
            categoryThemeId: doc && doc.categoryThemeId ? doc.categoryThemeId : ""
        }

        this.setState({ showPracticeModal: true })
    }

    closePracticeModal = (lastResumeId, elosCompleted) => {

        let updateDoc = {
            contentId: this.contents[this.activeIndex].contentId,
            eloId: this.contents[this.activeIndex].eloId,
            linkedElo: this.contents[this.activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc, (err, response) => {

            if (response) {

                let learningElos = this.state.learningElos

                let selectedElo = this.state.learningElos[this.activeIndex]

                console.log('::::', selectedElo.meta)

                if (selectedElo && selectedElo.meta) {

                    if (selectedElo.meta.progress) {

                        selectedElo.meta.progress.percentage = response.percentage
                        selectedElo.meta.percentage = response.percentage

                        if (selectedElo.meta.progress.solvedExamples) {
                            selectedElo.meta.progress.solvedExamples.percentage = response.solvedExamples.percentage
                        } else {
                            selectedElo.meta.progress.solvedExamples = { percentage: response.solvedExamples.percentage }
                        }

                        if (selectedElo.meta.progress.practiceQuestions) {
                            selectedElo.meta.progress.practiceQuestions.percentage = response.practiceQuestions.percentage
                        } else {
                            selectedElo.meta.progress.practiceQuestions = { percentage: response.practiceQuestions.percentage }
                        }
                    } else {
                        selectedElo.meta.progress = {
                            percentage: response.percentage,
                            solvedExamples: { percentage: response.solvedExamples.percentage },
                            practiceQuestions: { percentage: response.practiceQuestions.percentage }
                        }
                    }
                } else {
                    selectedElo.meta = {
                        progress: {
                            percentage: response.percentage,
                            solvedExamples: { percentage: response.solvedExamples.percentage },
                            practiceQuestions: { percentage: response.practiceQuestions.percentage }
                        }
                    }
                }

                if (selectedElo && selectedElo.meta && selectedElo.meta.progress.practiceQuestions && selectedElo.meta.progress.practiceQuestions) {

                    if (lastResumeId && (lastResumeId[updateDoc.eloId] || lastResumeId[updateDoc.eloId] == 0)) {

                        if (selectedElo.meta.progress.practiceQuestions[updateDoc.eloId])
                            selectedElo.meta.progress.practiceQuestions[updateDoc.eloId].resumeId = lastResumeId[updateDoc.eloId]
                        else
                            selectedElo.meta.progress.practiceQuestions[updateDoc.eloId] = { resumeId: lastResumeId[updateDoc.eloId] }

                    }

                    if (lastResumeId && (lastResumeId[updateDoc.linkedElo] || lastResumeId[updateDoc.linkedElo] == 0)) {

                        if (selectedElo.meta.progress.practiceQuestions[updateDoc.linkedElo])
                            selectedElo.meta.progress.practiceQuestions[updateDoc.linkedElo].resumeId = lastResumeId[updateDoc.linkedElo]
                        else
                            selectedElo.meta.progress.practiceQuestions[updateDoc.linkedElo] = { resumeId: lastResumeId[updateDoc.linkedElo] }

                    }
                }

                if (selectedElo && selectedElo.meta && selectedElo.meta.progress.practiceQuestions && selectedElo.meta.progress.practiceQuestions) {

                    if (elosCompleted && elosCompleted[updateDoc.eloId]) {

                        if (selectedElo.meta.progress.practiceQuestions[updateDoc.eloId])
                            selectedElo.meta.progress.practiceQuestions[updateDoc.eloId].status = "COMPLETED"
                        else
                            selectedElo.meta.progress.practiceQuestions[updateDoc.eloId] = { status: "COMPLETED" }

                    }

                    if (elosCompleted && elosCompleted[updateDoc.linkedElo]) {

                        if (selectedElo.meta.progress.practiceQuestions[updateDoc.linkedElo])
                            selectedElo.meta.progress.practiceQuestions[updateDoc.linkedElo].status = "COMPLETED"
                        else
                            selectedElo.meta.progress.practiceQuestions[updateDoc.linkedElo] = { status: "COMPLETED" }

                    }
                }


                console.log(selectedElo)
                this.setState({ learningElos })
            }
            this.setState({ showPracticeModal: false })
        })
    }

    onPressSolvedExample = (doc, activeIndex) => {

        this.activeIndex = activeIndex
        // console.log(':::doc', doc)
        // console.log(':::::', doc.meta.progress.solvedExamples)
        this.solvedExampleParam = {
            eloId: doc.eloId,
            title: doc.eloTitle,
            headerTitle: "Solved Examples",
            chapterKey: this.params.chapterKey,
            progress: doc.meta && doc.meta.progress && doc.meta.progress.solvedExamples,
            linkedElo: doc.meta && doc.meta.linkedElo
        }

        this.setState({ showSolvedExample: true })
    }

    closeLearnMore = () => {
        this.setState({ showLearnMore: false })
    }

    onPressLearnMore = (doc) => {
        console.log('learn more', doc)
        this.learnMoreParam = {
            eloId: doc.eloId,
            title: this.params.title,
            headerTitle: doc.eloTitle,
            chapterKey: this.params.chapterKey
        }

        this.setState({ showLearnMore: true })
    }

    onPressPage = (index, callback) => {
        this.buildLearningContentPagePress(this.contents, index, callback)
        this.getEachStepProgress(index)
    }

    closeSolvedExample = (lastResumeId, elosCompleted) => {
        let updateDoc = {
            contentId: this.contents[this.activeIndex].contentId,
            eloId: this.contents[this.activeIndex].eloId,
            linkedElo: this.contents[this.activeIndex].linkedElo
        }

        this.prepkitContentActivity(updateDoc, (err, response) => {

            if (response) {

                let learningElos = this.state.learningElos

                let selectedElo = this.state.learningElos[this.activeIndex]

                console.log('::::', selectedElo.meta)

                if (selectedElo && selectedElo.meta) {

                    if (selectedElo.meta.progress) {

                        selectedElo.meta.progress.percentage = response.percentage
                        selectedElo.meta.percentage = response.percentage

                        if (selectedElo.meta.progress.solvedExamples) {
                            selectedElo.meta.progress.solvedExamples.percentage = response.solvedExamples.percentage
                        } else {
                            selectedElo.meta.progress.solvedExamples = { percentage: response.solvedExamples.percentage }
                        }

                        if (selectedElo.meta.progress.practiceQuestions) {
                            selectedElo.meta.progress.practiceQuestions.percentage = response.practiceQuestions.percentage
                        } else {
                            selectedElo.meta.progress.practiceQuestions = { percentage: response.practiceQuestions.percentage }
                        }
                    } else {
                        selectedElo.meta.progress = {
                            percentage: response.percentage,
                            solvedExamples: { percentage: response.solvedExamples.percentage },
                            practiceQuestions: { percentage: response.practiceQuestions.percentage }
                        }
                    }
                } else {
                    selectedElo.meta = {
                        progress: {
                            percentage: response.percentage,
                            solvedExamples: { percentage: response.solvedExamples.percentage },
                            practiceQuestions: { percentage: response.practiceQuestions.percentage }
                        }
                    }
                }

                console.log(selectedElo)

                if (selectedElo && selectedElo.meta && selectedElo.meta.progress.solvedExamples && selectedElo.meta.progress.solvedExamples) {

                    if (lastResumeId && (lastResumeId[updateDoc.eloId] || lastResumeId[updateDoc.eloId] == 0)) {

                        if (selectedElo.meta.progress.solvedExamples[updateDoc.eloId])
                            selectedElo.meta.progress.solvedExamples[updateDoc.eloId].resumeId = lastResumeId[updateDoc.eloId]
                        else
                            selectedElo.meta.progress.solvedExamples[updateDoc.eloId] = { resumeId: lastResumeId[updateDoc.eloId] }

                    }

                    if (lastResumeId && (lastResumeId[updateDoc.linkedElo] || lastResumeId[updateDoc.linkedElo] == 0)) {

                        if (selectedElo.meta.progress.solvedExamples[updateDoc.linkedElo])
                            selectedElo.meta.progress.solvedExamples[updateDoc.linkedElo].resumeId = lastResumeId[updateDoc.linkedElo]
                        else
                            selectedElo.meta.progress.solvedExamples[updateDoc.linkedElo] = { resumeId: lastResumeId[updateDoc.linkedElo] }

                    }
                }

                if (selectedElo && selectedElo.meta && selectedElo.meta.progress.solvedExamples && selectedElo.meta.progress.solvedExamples) {

                    if (elosCompleted && elosCompleted[updateDoc.eloId]) {

                        if (selectedElo.meta.progress.solvedExamples[updateDoc.eloId])
                            selectedElo.meta.progress.solvedExamples[updateDoc.eloId].status = "COMPLETED"
                        else
                            selectedElo.meta.progress.solvedExamples[updateDoc.eloId] = { status: "COMPLETED" }

                    }

                    if (elosCompleted && elosCompleted[updateDoc.linkedElo]) {

                        if (selectedElo.meta.progress.solvedExamples[updateDoc.linkedElo])
                            selectedElo.meta.progress.solvedExamples[updateDoc.linkedElo].status = "COMPLETED"
                        else
                            selectedElo.meta.progress.solvedExamples[updateDoc.linkedElo] = { status: "COMPLETED" }

                    }
                }

                this.setState({ learningElos })
            }
            this.setState({ showSolvedExample: false })
        })
    }


    getEachStepProgress = (activeIndex) => {

        let payload = {
            chapterKey: this.params.chapterKey,
            contentId: this.contents[activeIndex].contentId,
            eloId: this.contents[activeIndex].eloId,
            type: "THINGS_TO_REMEMBER"
        }

        this.setState({ loading: true })
        ExamPrepKitApi.getEachStepProgress(payload).then((response) => {

            if (!response) {
                this.setState({ loading: false })
                return
            }

            let learningElos = this.state.learningElos

            let selectedElo = this.state.learningElos[activeIndex]

            if (selectedElo && selectedElo.meta) {

                if (selectedElo.meta.progress) {

                    selectedElo.meta.progress.percentage = response.percentage
                    selectedElo.meta.percentage = response.percentage

                    if (selectedElo.meta.progress.solvedExamples) {
                        selectedElo.meta.progress.solvedExamples.percentage = response.solvedExamples.percentage
                    }

                    if (selectedElo.meta.progress.practiceQuestions) {
                        selectedElo.meta.progress.practiceQuestions.percentage = response.practiceQuestions.percentage
                    }
                }
            }

            this.setState({ learningElos, loading: false })
        }).catch(err => {
            console.log(err)
            if (err) {
                this.setState({ loading: false })
                return
            }
        })
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToExamPrepKit() {
        this.props.delegate && this.props.delegate.goToExamPrepKit && this.props.delegate.goToExamPrepKit()
    }

    // goToExamPrepKitChapterList() {
    //     this.props.showPrepKitChapterList && this.props.showPrepKitChapterList()
    // }

    goBackToTableOfContents() {
        this.props.delegate && this.props.delegate.goBackToTableOfContents && this.props.delegate.goBackToTableOfContents()
    }

    goBackToSelectedContent() {
        if (this.params.origin && this.params.origin == 'THINGS_TO_REMEMBER') {
            this.props.delegate && this.props.delegate.hideExamPrepKitLearningExperience && this.props.delegate.hideExamPrepKitLearningExperience()
            return;
        }
    }

    getTitle() {
        if (this.params.origin && this.params.origin == 'THINGS_TO_REMEMBER') {
            return 'Things to Remember';
        }
    }

    goToPrepare() {
        this.props.delegate && this.props.delegate.goToPrepare && this.props.delegate.goToPrepare()
    }


    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                {/* <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToExamPrepKit()} >{`Prepare`}{` > `}</span> */}
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToPrepare()}>{`${this.params.title}`} {` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToTableOfContents()}>{`${`Table of Contents`}`} {` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToSelectedContent()}>{this.getTitle()}{` > `}</span>
                <span className='title-1 pl-2' >{this.params.headerTitle}</span>
            </div>
        )
    }



    render() {

        // if (this.state.showLearnMore) {
        //     return <LearnMoreExperience
        //         params={this.learnMoreParam}
        //         navigation={this.props.navigation}
        //         close={this.closeLearnMore}>
        //     </LearnMoreExperience>
        // }

        // if (this.state.showSolvedExample) {
        //     return <SolvedExample
        //         params={this.solvedExampleParam}
        //         navigation={this.props.navigation}
        //         close={this.closeSolvedExample}>
        //     </SolvedExample>
        // }

        return (
            <div className='exam-prep-kit-learning-experience'>
                {
                    this.renderBreadcrumbForChapterList()
                }
                {
                    this.state.learningElos.length > 0 && !this.state.showLearnMore && !this.state.showSolvedExample &&
                    !this.state.showPracticeModal && <LearningContentWrapper
                        elo={this.state.learningElos}
                        delegate={this}
                        navigation={this.props.navigation}
                        isRevise={this.isRevise}
                        title={this.params.title ? this.params.title : ''}
                        navigationInfo={this.params}
                        eloIds={this.eloIds}
                        noBlur={true}
                        headerIcon={this.params.headerIcon}
                        headerTitle={this.params.headerTitle}
                        removeHeaderIcon
                        showLearnMoreCard
                        onPressNext={this.onPressNext}
                        showPaginationProgress={true}
                        activeIndex={this.state.resumeIndex}
                        onPressPage={this.onPressPage}
                    >
                    </LearningContentWrapper>
                }

                {this.state.loading ? <Loading /> : <></>}

                {this.state.showLearnMore ? <LearnMoreExperience params={this.learnMoreParam} navigation={this.props.navigation} close={this.closeLearnMore}></LearnMoreExperience> : <></>}
                {this.state.showSolvedExample ? <SolvedExample params={this.solvedExampleParam} navigation={this.props.navigation} close={this.closeSolvedExample}></SolvedExample> : <></>}
                {this.state.showPracticeModal ?
                    <div style={{}}>
                        <PracticeModal params={this.practiceParam} close={this.closePracticeModal}></PracticeModal>
                    </div> : <></>}
            </div>
        )
    }
}


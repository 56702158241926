import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import { PREFIX } from '../../config/constants';
import CommonApi from '../apis/common';


// const firebaseConfig = {
//     apiKey: "AIzaSyAtqQr3jVtPUep71xPCAb7wnb54StUWfaM",
//     authDomain: "test-project-464f8.firebaseapp.com",
//     projectId: "test-project-464f8",
//     storageBucket: "test-project-464f8.appspot.com",
//     messagingSenderId: "789410283204",
//     appId: "1:789410283204:web:bc4d4b6441acd899cc1391",
//     measurementId: "G-VFG0531QSN"
// };

// Production Configuration
const firebaseConfig = {
    apiKey: "AIzaSyBOQaznope_EJxUcCiXhdYUBpo9XbWEtgM",
    authDomain: "countingwell-22064.firebaseapp.com",
    projectId: "countingwell-22064",
    storageBucket: "countingwell-22064.appspot.com",
    messagingSenderId: "434404415565",
    appId: "1:434404415565:web:b521ffe4ee74a59479e404",
    measurementId: "G-9V6QN94W27"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// import firebase from "firebase/app";
// import "firebase/analytics";
// import "firebase/auth";
// import "firebase/firestore";

// import analytics from '@react-native-firebase/analytics';
// import API from '../api/api'

// import { getTrackingStatus } from 'react-native-tracking-transparency';
// import { Platform } from 'react-native';

async function logCustomEvent(eventName, payload) {
    // const trackingStatus = await getTrackingStatus();

    eventName = eventName.split(' ').join('_')

    let event = PREFIX && PREFIX.EVENT_PREFIX_TEXT ? `${PREFIX.EVENT_PREFIX_TEXT}${eventName}` : eventName

    // if (Platform.OS == 'ios' && (trackingStatus === 'denied' || trackingStatus === "restricted")) {
    //     //console.log("Customer dont want us to track their data", event)
    //     return
    // }

    try {
        // await analytics().logEvent(event, {
        //     ...payload
        // });

        if(payload) {
            payload.source = "STUDENT PORTAL"
        }

        logEvent(analytics, event, payload);

        // console.log('firsebase event logged', event, payload)

        let insertPayload = {
            ...payload,
            eventName,
            type: "FIREBASE"
        }

        let constant = eventName == 'PARENT_SIGNUP' || eventName == 'PARENT_LOGIN' ? "PARENT_EVENT_LOGS" : "EVENT_LOGS"

        // CommonApi.insertEventLogs(insertPayload).then((data) => {

        //     console.log(data)

        // }).catch(err => {
        //     if (err) {
        //         console.log(err)
        //         return
        //     }
        // })

    } catch (error) {
        //console.log('log event failed', error)
    }

}

export default logCustomEvent;

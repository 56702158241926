import React from 'react';
import './learning-content.scss';
import { ImageConstants } from '../../../constants/constants';
import LearningCard from '../learning-card/learning-card';
import Summary from '../../common/summary/summary';
import SolvedExamples from '../solved-examples/solved-examples';
import LearningContentCommonError from '../learning-card-common-errors/learning-card-common-errors';
import PracticeQuestions from '../check-point-questions/check-point-questions';
import { getThemeImageSource } from '../../../utility/image-source';

export default class LearningContent extends React.Component {


    getImageIcon(contentType) {

        switch (contentType) {
            case 1:
                return ImageConstants.INTRODUCTION;

            case 2:
                return ImageConstants.DEFINITION;

            case 3:
                return ImageConstants.PROPERTIES;

            case 4:
                return ImageConstants.STATEMENT;

            case 5:
                return ImageConstants.FORMULA;

            case 6:
                return ImageConstants.THINGS_TO_REMEMBER;

            case 8:
                return ImageConstants.VIDEO_ICON;

            default:
                return null;
        }
    }


    renderContent(learningContentType, ContentType, content) {
        if (ContentType === 3) {
            return (

                <PracticeQuestions
                    content={content}
                    groupTitle={this.props.groupTitle}
                    delegate={this.props.delegate}
                    glossary={this.props.glossary}
                    showTitle={this.props.showTitle}
                    categoryId={this.props.categoryId}
                    showBookMark={this.props.showBookMark}
                    bookMarkId={this.props.bookMarkId}
                    contentObj={this.props.contentObj}
                    showInactiveBookmark={this.props.showInactiveBookmark}
                    selectedIndex={this.props.selectedIndex}
                    meta={this.props.meta} />
            )
        }

        else if (ContentType === 4) {
            return (
                <></>
                // <View>
                //     <PracticeMoreQuestions content={content} delegate={this.props.delegate} glossary={this.props.glossary} categoryId={this.props.categoryId} showBookMark={this.props.showBookMark} selectedIndex={this.props.selectedIndex} meta={this.props.meta} isBookMark={true} bookMarkId={this.props.bookMarkId} />
                // </View>

            )
        }
        else {
            switch (learningContentType) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 8:
                    return <LearningCard delegate={this.props.delegate}
                        glossary={this.props.glossary}
                        categoryId={this.props.categoryId}
                        data={{
                            headerIcon: this.getImageIcon(content.content.type),
                            title: this.props.groupTitle ? this.props.groupTitle : (content.displayTitle ? content.displayTitle : content.title),
                            content: content.content.description,
                            showTitle: this.props.showTitle,
                            videoCheckpointInfo: content.content.videoCheckpointInfo ? content.content.videoCheckpointInfo : []
                        }}
                        showBookMark={this.props.showBookMark}
                        bookMarkId={this.props.bookMarkId}
                        contentObj={this.props.contentObj}
                        showInactiveBookmark={this.props.showInactiveBookmark}
                        selectedIndex={this.props.selectedIndex}
                        meta={this.props.meta}
                        removeHeaderIcon={this.props.removeHeaderIcon}>
                    </LearningCard>

                case 9:
                case 10:
                    return (<LearningContentCommonError
                        delegate={this.props.delegate}
                        glossary={this.props.glossary}
                        categoryId={this.props.categoryId}
                        data={{
                            headerIcon: this.getImageIcon(content.content.type),
                            title: content.content.title ? content.content.title : content?.title,
                            content: content.content.description, showTitle: this.props.showTitle
                        }}
                        showBookMark={this.props.showBookMark} bookMarkId={this.props.bookMarkId}
                        contentObj={this.props.contentObj} showInactiveBookmark={this.props.showInactiveBookmark}
                        selectedIndex={this.props.selectedIndex} meta={this.props.meta}
                        removeHeaderIcon={this.props.removeHeaderIcon}></LearningContentCommonError>)
                // return <LearningCard delegate={this.props.delegate} glossary={this.props.glossary} categoryId={this.props.categoryId} data={{ headerIcon: this.getImageIcon(content.content.type), title: content.displayTitle ? content.displayTitle : content.title, content: content.content.description, showTitle: this.props.showTitle }} ></LearningCard>

                case 7:
                    return <SolvedExamples
                        data={{ headerIcon: ImageConstants.SOLVED_EXAMPLES, title: this.props.groupTitle ? this.props.groupTitle : (content.displayTitle ? content.displayTitle : content.title), image: content.content.image }}
                        solutionSteps={content.content.solutionSteps}
                        title={content.content.title}
                        image={content.content.images}
                        delegate={this.props.delegate}
                        glossary={this.props.glossary}
                        showTitle={this.props.showTitle}
                        categoryId={this.props.categoryId}
                        showBookMark={this.props.showBookMark}
                        bookMarkId={this.props.bookMarkId}
                        contentObj={this.props.contentObj}
                        showInactiveBookmark={this.props.showInactiveBookmark}
                        selectedIndex={this.props.selectedIndex} meta={this.props.meta}
                        removeHeaderIcon={this.props.removeHeaderIcon} />

                case 6:
                    return <Summary data={content} delegate={this.props.delegate} glossary={this.props.glossary} showTitle={this.props.showTitle} categoryId={this.props.categoryId} showBookMark={this.props.showBookMark} bookMarkId={this.props.bookMarkId} contentObj={this.props.contentObj} showInactiveBookmark={this.props.showInactiveBookmark} selectedIndex={this.props.selectedIndex} meta={this.props.meta}></Summary>

            }
        }
    }

    render() {
        let content = this.props.content

        // console.log(content)

        return (
            <div style={{ backgroundColor: "transparent" }}>
                {/* {this.props.content.showEye &&
                    <div style={{ alignItems: "center", marginVertical: 20 }}>
                        <img className='eye-image' src={getThemeImageSource(ImageConstants.EYE_CONTENT_BLOCK, this.props.categoryId)} resizeMode="cover" />
                        <div className='eye-text'>Please answer the above question</div>
                        <div className='eye-text'>to continue</div>
                    </div>
                } */}
                {
                    this.renderContent(content.content.type, content.type, content)
                }
            </div>

        );
    }
}



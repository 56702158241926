import React from "react";
import './assessment-end-screen.scss';
import Path from "../../routes/routes-path";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import LayoutShared from "../../common/layout-shared/layout-shared";

class ClickerAssessmentEndScreen extends React.Component {


    state = {
        assessmentQuestionWiseStudentInfo: [],
        studentList: this.props.studentList,
        // assessmentQuestionWiseStudentInfo:
        //     [
        //         {
        //             correct: 1,
        //             correctStudentsCount: 1,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 50,
        //             questionId: "618222ff2f081330c715fc12",
        //             questionNumber: 'Q1'
        //         },
        //         {
        //             correct: 2,
        //             correctStudentsCount: 2,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 100,
        //             questionId: "66d6b6486c7da05dcf39ec6e",
        //             questionNumber: 'Q50'
        //         },
        //         {
        //             correct: 1,
        //             correctStudentsCount: 1,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 50,
        //             questionId: "618222ff2f081330c715fc12",
        //             questionNumber: 'Q1'
        //         },
        //         {
        //             correct: 2,
        //             correctStudentsCount: 2,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 100,
        //             questionId: "66d6b6486c7da05dcf39ec6e",
        //             questionNumber: 'Q1'
        //         },
        //         {
        //             correct: 1,
        //             correctStudentsCount: 1,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 50,
        //             questionId: "618222ff2f081330c715fc12",
        //             questionNumber: 'Q1'
        //         },
        //         {
        //             correct: 2,
        //             correctStudentsCount: 2,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 100,
        //             questionId: "66d6b6486c7da05dcf39ec6e",
        //             questionNumber: 'Q1'
        //         },
        //         {
        //             correct: 1,
        //             correctStudentsCount: 1,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 50,
        //             questionId: "618222ff2f081330c715fc12",
        //             questionNumber: 'Q1'
        //         },
        //         {
        //             correct: 2,
        //             correctStudentsCount: 2,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 100,
        //             questionId: "66d6b6486c7da05dcf39ec6e",
        //             questionNumber: 'Q1'
        //         },
        //         {
        //             correct: 1,
        //             correctStudentsCount: 1,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 50,
        //             questionId: "618222ff2f081330c715fc12",
        //             questionNumber: 'Q1'
        //         },
        //         {
        //             correct: 2,
        //             correctStudentsCount: 2,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 100,
        //             questionId: "66d6b6486c7da05dcf39ec6e",
        //             questionNumber: 'Q1'
        //         },
        //         {
        //             correct: 1,
        //             correctStudentsCount: 1,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 50,
        //             questionId: "618222ff2f081330c715fc12",
        //             questionNumber: 'Q1'
        //         },
        //         {
        //             correct: 2,
        //             correctStudentsCount: 50,
        //             totalUserAttenedQuestion: 2,
        //             clearedPercentage: 100,
        //             questionId: "66d6b6486c7da05dcf39ec6e",
        //             questionNumber: 'Q1'
        //         }
        //     ]
    }

    constructor(props) {
        super(props)

        this.layoutShared = LayoutShared.getLayout()
    }

    componentDidMount() {
        console.log(this.props.metaInfo.data)
        this.setState({ assessmentQuestionWiseStudentInfo: this.props.metaInfo.data.assessmentQuestionWiseStudentInfo ? this.props.metaInfo.data.assessmentQuestionWiseStudentInfo : []})
        this.handleStartListening()
    }



    toggleLayoutBar() {
        console.log('toggle called')
        // this.setState({hideLayoutSideBar : !this.state.hideLayoutSideBar}, () => {
        this.layoutShared.showHideLayoutBar(!this.layoutShared.state.showLayoutBar)
        // this.layoutShared.toggleSidebar(this.state.hideLayoutSideBar)
        // })

    }


    handleStartListening = () => {
        const { isListening } = this.state;

        if (window.electron && window.electron.remoteControl) {
            if (isListening) {
                this.setState({ isListening: false });
                window.electron.remoteControl.unsubscribeEvents();
                window.electron.remoteControl.close();
            } else {
                this.setState({ isListening: true });
                console.log('Opening remote control', window.electron.remoteControl.open);
                window.electron.remoteControl.open();

                window.electron.remoteControl.subscribeEvents((data) => {
                    console.log('Received data:', data);
                    console.log(this.state.stopReceivingStudentSignal)

                    let studentList = this.state.studentList

                    let index = studentList.findIndex(item => item.userDeviceMacId === data.payload.id)

                    if (index != -1 && !this.props.isReadOnly) {
                        if (studentList[index].isTeacher && data.payload.value === 8) {
                            this.toggleLayoutBar()
                            window.electron.ipcRenderer.send('toggle-fullscreen', ['ping']);
                        }
                    }

                    console.log(this.state.studentList)

                });
            }
            window.electron.ipcRenderer.once('ipc-example', (arg) => {
                // eslint-disable-next-line no-console
                console.log(arg);
            });
            window.electron.ipcRenderer.send('ipc-example', ['ping']);
        }

    };

    componentWillUnmount() {
        // Cleanup on component unmount
        console.log('component unmounted')
        if (window.electron && window.electron.remoteControl) {
            window.electron.remoteControl.unsubscribeEvents();
            window.electron.remoteControl.close();
        }

    }


    goToHomeScreen() {
        if (this.layoutShared.state.showLayoutBar) {
            // this.layoutShared.showHideLayoutBar(this.layoutShared.state.showLayoutBar)
        } else {
            this.layoutShared.showHideLayoutBar(!this.layoutShared.state.showLayoutBar)
            window.electron.ipcRenderer.send('toggle-fullscreen', ['ping']);
        }
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    renderBreadcrumb() {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                {/* <span className='pl-2 cursor-pointer title-1' onClick={() => this.goBack()}>{`Clicker Flow`} {`>`}</span> */}
                <span className='pl-2 title-1'>{`Completed Clicker Assessment Flow`}</span>
            </div>

        )
    }


    chunkArray(array, chunkSize) {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    }



    renderColumn(assessmentQuestionWiseStudentInfo, idx) {

        return (
            <div className={`${idx > 0 ? "second-column-wrapper" :""}`}>
                {/* <div className="row flex-column align-items-center mr-4 mb-1 image-wrapper">
                    <div className="col-1 align-self-center ml-4">
                        <div className="sub-column-width"><img className="student-count-image" src={getImageSource(ImageConstants.BLACK_STUDENTS_COUNT_IMAGE)} /></div>
                    </div>
                    <div className="d-flex col-1 second-column-wrapper">
                        <div className="sub-column-width"><img className="tick-mark-image-size" src={getImageSource(ImageConstants.GREEN_BG_TICK_MARK)} /></div>
                        <div className=" percentage-text-symblol">%</div>
                    </div>
                </div> */}
                {assessmentQuestionWiseStudentInfo.map((item, index) => (
                    <div className="row align-items-center info-wrapper">
                        <div className="question-number-text col-1 column-max-width question-number-circle">
                            <div className="question-number">{item.questionNumber}</div>
                        </div>
                        {/* <div className="col-1 sub-column-width"><img className="tick-mark-image-size" src={getImageSource(ImageConstants.GREEN_BG_TICK_MARK)} /></div> */}
                        {/* <div className="col-1 sub-column-width"><img className="student-count-image" src={getImageSource(ImageConstants.BLACK_STUDENTS_COUNT_IMAGE)} /></div> */}
                        <div className=" d-flex align-items-center">
                            <div className="sub-column-width correct-count-text">{item.correctStudentsCount}</div>
                            <div className={`divider ${item.correctStudentsCount >= 10 ? 'ml-4' : 'ml-3'} mr-2`}>/</div>
                            <div className="sub-column-width  correct-count-text percentage-text">{item.clearedPercentage}%</div>
                        </div>

                    </div>
                ))}
            </div>
        )
    }



    renderQuestionWisePercentage() {


        const chunkedArray = this.chunkArray(this.state.assessmentQuestionWiseStudentInfo, 10);


        let questionWiseInfo = []

        // let questionLength = this.state.assessmentQuestionWiseStudentInfo.length / 2
        // let assessmentQuestionWiseStudentInfo = this.state.assessmentQuestionWiseStudentInfo

        let element = <div className="d-flex justify-content-center">
            {
                chunkedArray.map((item, index) => (
                    this.renderColumn(item, index)
                ))
            }
        </div>

        questionWiseInfo.push(element)

        return questionWiseInfo
    }


    render() {
        return (
            <div className="clicker-assessment-end-screen">
                {
                    this.renderBreadcrumb()
                }
                <div className="header-text">Jigayasa Baseline Assessment</div>
                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.state.categoryId)}></img>
                </div>
                <div className="text-center sub-header-text mt-1">Student Response Accuracy – Correct Answers (Count <span className="divider">/</span> Percentage)</div>
                <div className="d-flex justify-content-center mt-3 mb-3"><img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} /></div>
                {
                    this.state.assessmentQuestionWiseStudentInfo && this.state.assessmentQuestionWiseStudentInfo.length ?
                        <div className="question-wrapper mt-1">
                            {this.renderQuestionWisePercentage()}
                        </div>
                        :
                        <div className="text">No Questionwise Student Correct Answer Information</div>
                }

            </div>
        )
    }

}

export default ClickerAssessmentEndScreen;

import React, { Component } from 'react';
import './exam-prep-kit-learning-content-header.scss';
import { getImageSource, getThemeImageSource } from '../../../../../utility/image-source';
import { ImageConstants } from '../../../../../constants/constants';
import WorkoutHeaderPagination from './workout-header-pagination/workout-header-pagination';
import SharedThemeCategory from '../../../../../../shared-storage/category-theme';

export default class LearningContentHeader extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        showLateralMenu: false
    }

    goBack = () => {
        this.props.delegate && this.props.delegate.goBack(true)
    }

    onPressAskDoubt = () => {
        this.props.delegate && this.props.delegate.onPressAskDoubt()
    }

    navigate = (index, navigate) => {
        this.props.delegate && this.props.delegate.navigateGroup(index, navigate);
    }

    goToEventStart = () => {
        this.props.navigation.navigate('ScholarshipScreenV2', { meta: {} })
    }

    scrollTo = () => {
        this.props.showPagination && this.paginationComponent.scrollTo();
    }

    toggleLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu', { meta: { from: 'PREPKIT' } })
        this.props.delegate && this.props.delegate.toggleLateralMenu && this.props.delegate.toggleLateralMenu()
    }

    closeLearnMore() {
        this.props.delegate && this.props.delegate.closeLearnMore && this.props.delegate.closeLearnMore()
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId);

        return (
            <>

                <div className='exam-prep-kit-learning-content-header'>
                    <div className=''>
                        <img className='learning-content-header-image-size' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, 1)} />
                    </div>

                    <div>

                        {
                            this.props.isScrolled ?
                                <div>

                                </div>
                                : <></>
                        }
                    </div>
                    {
                        !this.props.isScrolled ?
                            <>
                                {
                                    this.props.headerIcon ?
                                        <div className='d-flex align-items-center'>
                                            <div className='mr-2'>
                                                <img className='header-icon' src={this.props.headerIcon ? this.props.headerIcon : getImageSource(ImageConstants.THINGS_TO_REMEMBER_ICON)}></img>
                                            </div>
                                            <div>
                                                <div>
                                                    <div className='elo-title-text'>{this.props.title ? this.props.title : (this.props.subTitle ? this.props.subTitle : "Let’s quickly recall last workout summary")}</div>
                                                </div>
                                                {
                                                    !this.props.isChapterIntro || this.props.isSummaryContent ?
                                                        <div>
                                                            <div className='sub-title-text'>
                                                                {this.props.subTitle}
                                                            </div>
                                                        </div>
                                                        : <></>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {/* <div><img className='back-arrow mt-4 mb-3 cursor-pointer' onClick={() => this.closeLearnMore()}
                                                src={getImageSource(ImageConstants.BACK_ICON)} /></div> */}
                                            <div>
                                                <div className='elo-title-text'>{this.props.title ? this.props.title : (this.props.subTitle ? this.props.subTitle : "Let’s quickly recall last workout summary")}</div>
                                            </div>
                                            {
                                                !this.props.isChapterIntro || this.props.isSummaryContent ?
                                                    <div >
                                                        <div className='sub-title-text'>
                                                            {this.props.subTitle}
                                                        </div>
                                                    </div>
                                                    : <></>
                                            }

                                        </div>
                                }

                            </>
                            : <></>
                    }
                    <div>
                        <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, 1)}></img>
                    </div>
                </div>

                {
                    this.props.showPagination &&
                    <div className='d-flex justify-content-center mb-3'>
                        <WorkoutHeaderPagination delegate={this} totalPage={this.props.totalPage} activeIndex={this.props.activeIndex} ref={(ref) => { this.paginationComponent = ref }} navigateTill={this.props.navigateTill} showPaginationProgress={this.props.showPaginationProgress} percentageCompleted={this.props.percentageCompleted} allPagePercentage={this.props.allPagePercentage} />
                    </div>
                }
            </>
        );
    }
}

import HttpClient from './http-client';
import URL from './url';

export default class AuthenticateAPI { 

    static sendOtpStudentSignUp(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_OTP_STUDENT_SIGNUP, null, payload);
    }

    static studentSignupVerifyOtp(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SIGNUP_STUDENT_VERIFY_OTP, null, payload);
    }

    static signupStudent(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SIGNUP_STUDENT, null, payload);
    }

    static studentSignupUsingPasscode(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SIGNUP_STUDENT_USING_PASSCODE, null, payload);
    }


    static signupStudentUpdate(payload) {
        return HttpClient.put(URL.BASE_URL + URL.STUDENT_SIGN_UP_UPDATE, null, payload);
    }

    static updateProfileMoreInfo(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_PROFILE_MORE_INFO, null, payload);
    }

    static updateDefaultWorkflowStage(payload) {
        return HttpClient.put(URL.BASE_URL + URL.DEFAULT_FIRST_WORKOUT, null, payload);
    }


    static sendOtpStudentLogin(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_OTP, null, payload);
    }

    static studentLogin(payload) {
        return HttpClient.post(URL.BASE_URL + URL.LOGIN, null, payload);
    }

    static updateLoginUser(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_LOGIN_USER, null, payload);
    }


    static verifyDevice(payload) {
        return HttpClient.post(URL.BASE_URL + URL.VERIFY_DEVICE, null, payload);
    }


    static studentLogout(payload) {
        return HttpClient.put(URL.BASE_URL + URL.STUDENT_LOGOUT, null, payload);
    }

    static createNewPassCode(payload) {
        return HttpClient.post(URL.BASE_URL + URL.STUDENT_CREATE_NEW_PASSCODE, null, payload);
    }

    static updatePasscode(payload) {
        return HttpClient.post(URL.BASE_URL + URL.STUDENT_UPDATE_PASSCODE, null, payload);
    }

    
    static loginWithPassCode(payload) {
        return HttpClient.post(URL.BASE_URL + URL.LOGIN_WTIH_PASSCODE, null, payload);
    }

    static updatePasscodeLoginUser(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_PASSCODE_LOGIN_USER, null, payload);
    }

      
    static loginWithId(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_ID_LOGIN_USER, null, payload);
    }



    static checkUserPasscodeSet(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        //console.log(params)
        let paramsList = params.length ? URL.CHECK_USER_PASSCODE_SET + "?" + params.join('&') : URL.CHECK_USER_PASSCODE_SET;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }



    
    

    static changePassword(payload) {
        return HttpClient.put(URL.BASE_URL + URL.CHANGE_PASSWORD, null, payload);
    }

    static createQuickSurvey(payload) {
        return HttpClient.post(URL.BASE_URL + URL.STAFF_QUICK_SURVEY, null, payload);
    }
}

import React from 'react';
import './solved-examples.scss';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import { ImageConstants } from '../../../constants/constants';
import Parser from '../../common/content-parser/content-parser';
import { Constants } from '../../../constants/constants';
import QuestionTitle from '../../common/question/question-title/question-title';
import QuestionImage from '../../common/cw-question-image/cw-question-image';
import AssessmentApi from '../../../apis/assessment';
import LearningHeader from '../learning-header/learning-header';

export default class SolvedExamples extends React.Component {

    constructor(props) {
        super(props)
        this.parser = new Parser();

    }
    state = {
        lookUpData: [],
        showSolutions: false,
        showSteps: false,
        currentIndex: 0,
        showIcons: false,
        showBorderLine: false,
    }

    componentDidMount() {
        this.getLookUpData()
    }

    getLookUpData() {

        AssessmentApi.getLookupList({ limit: 100 }).then((res) => {
            if (res) {
                this.setState({ lookUpData: res.response })
            }
            else {
                //alert(err)
            }

        }).catch(err => {
            console.log(err)
        })

    }


    getLookUpText(id) {

        for (let i = 0; i < this.state.lookUpData.length; i++) {
            if (id == this.state.lookUpData[i].id)
                return this.state.lookUpData[i].text;
        }

    }

    toggleSteps = (data, index) => {

        data.showSteps = data.hasOwnProperty('showSteps') ? !data.showSteps : true;
        this.forceUpdate();
        if (index === this.props.solutionSteps.steps.length - 1) {
            this.setState({ showBorderLine: !this.state.showBorderLine })
        }

    }
    toggleSolutions = () => {
        this.setState({ showSolutions: !this.state.showSolutions })
    }
    showIcons = () => {
        this.setState({ showIcons: !this.state.showIcons })
    }

    onClickBookMarkActive = () => {
        this.props.delegate && this.props.delegate.onClickBookMarkActive && this.props.delegate.onClickBookMarkActive(this.props.bookMarkId, this.props.selectedIndex)
    }

    onClickBookMark = () => {
        this.props.delegate && this.props.delegate.onClickBookMark && this.props.delegate.onClickBookMark(this.props.contentObj, this.props.selectedIndex)
    }


    render() {

        // setCategoryThemeStyles(this.props.categoryId);

        let Styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        return (

            <div className='solved-examples-container'>
                {
                    !this.props.hideHeader && this.props.showTitle && <div>
                        <LearningHeader data={{ headerIcon: this.props.data.headerIcon, title: this.props.data.title }} delegate={this} showBookMark={this.props.showBookMark} showInactiveBookmark={this.props.showInactiveBookmark} meta={this.props.meta} removeHeaderIcon={this.props.removeHeaderIcon}></LearningHeader>
                    </div>
                }

                {/* {this.state.showIcons &&
                    <CardActions />
                } */}

                <div>

                    {
                        !this.props.hideQues && <div style={{}}>
                            <QuestionTitle title={this.props.title} isCheckPoint={true}></QuestionTitle>
                        </div>
                    }

                    {this.props.image.length !== 0 && !this.props.hideHeader &&
                        <div style={{}}>
                            <QuestionImage images={this.props.image} isCheckPoint={true} categoryThemeId={this.props.categoryId} />
                        </div>
                    }
                    {this.props.solutionSteps.summarySolution.length > 0 ?
                        <div style={{}}>
                            {this.parser.parseLearningContent(this.props.solutionSteps.summarySolution, this.props.delegate, this.props.glossary, null, null, this.props.categoryId)}
                        </div>
                        :
                        <></>
                    }

                    {this.props.solutionSteps && ((this.props.solutionSteps.question && Array.isArray(this.props.solutionSteps.question) && this.props.solutionSteps.question.length) || (this.props.solutionSteps.steps && Array.isArray(this.props.solutionSteps.steps) && this.props.solutionSteps.steps.length)) ?
                        <div className={`detail-button ${this.state.showSolutions ? "mb-3" : 'mb-5'}`} onClick={this.toggleSolutions} style={{}} >
                            <span className='detail-text' style={{}}>{this.state.showSolutions ? Constants.HIDE_DETAILED_SOLUTION : Constants.SHOW_DETAILED_SOLUTION}</span>
                            <img className='arrow-size ml-1' src={this.state.showSolutions ? getThemeImageSource(ImageConstants.THEME_UP_ARROW, this.props.categoryId) : getThemeImageSource(ImageConstants.THEME_DOWN_ARROW, this.props.categoryId)} />
                        </div> : <></>
                    }


                    {this.state.showSolutions &&
                        <div className='ml-1'>

                            <div >

                                {this.props.solutionSteps.question.map((data) =>
                                    <div >
                                        <div className='question-text mb-2' >{this.getLookUpText(data.id)}</div>
                                        {this.parser.parseLearningContent(data.description)}
                                    </div>
                                )}

                            </div>

                            <div className=''>
                                {this.props.solutionSteps.steps.map((data, index) =>


                                    // <div style={(index < this.props.solutionSteps.steps.length - 1 || this.state.showBorderLine ? Styles.borderLine : Styles.noBorderLine)}>
                                    <div className={`position-relative ml-4 ${index < this.props.solutionSteps.steps.length - 1 || this.state.showBorderLine ? '' : ''}`}>
                                        
                                        {data.showSteps ?
                                            <img className='ellipse-size' src={getImageSource(ImageConstants.SELECTED_RADIO_IMAGE)} />
                                            :
                                            <img className='ellipse-size' src={getImageSource(ImageConstants.ELLIPSE)} />
                                        }
                                        {/* <div style={(index < this.props.solutionSteps.steps.length - 1 ? Styles.stepDescription : Styles.lastStepDescription)}> */}
                                        <div className='d-flex step-button-wrapper justify-content-between align-items-center ml-2 cursor-pointer mb-4' onClick={() => this.toggleSteps(data, index)}>
                                            <div className='step-wrapper'>
                                                <span className='step-title'>{`Step ${index + 1}`} </span>
                                                {this.parser.parseWithStyles(data.name, { p: Styles.p })}
                                            </div>
                                            <div className='ml-3'>
                                                <div >
                                                    <img className='step-arrow-size' src={getImageSource((data.showSteps ? ImageConstants.CIRCLE_UP_ARROW : ImageConstants.CIRCLE_DOWN_ARROW))} />
                                                </div>
                                            </div>
                                        </div>

                                        {data.showSteps &&
                                            // <div style={(index < this.props.solutionSteps.steps.length - 1 ? Styles.stepsContainer : Styles.stepPadding)}>
                                            <div className='ml-2 mb-3'>
                                                <div >{this.parser.parseLearningContent(data.description)}</div>

                                                <div className='step-title mb-2' >{Constants.ACTUAL_STEP} :</div>

                                                <div>{this.parser.parseLearningContent(data.actualStep, this.props.delegate, this.props.glossary, true, null, this.props.categoryId)}</div>

                                                {index === this.props.solutionSteps.steps.length - 1 &&
                                                    <>
                                                        {this.props.solutionSteps.conclusion.length > 0 &&
                                                            <div>
                                                                <div className='step-title mb-2' >{Constants.CONCLUSION} :</div>
                                                                {this.parser.parseLearningContent(this.props.solutionSteps.conclusion, this.props.delegate, this.props.glossary, true, null, this.props.categoryId)}
                                                            </div>
                                                        }
                                                        <img src={getImageSource(ImageConstants.LAST_STEP)} />
                                                    </>
                                                }
                                            </div>

                                        }

                                    </div>

                                )}
                            </div>
                        </div>
                    }

                </div>

            </div>


        );
    }
}

import React from "react";
import './edit-class.scss';
import SharedStaticText from "../../../../shared-storage/static-text-store";
import SharedProfile from "../../../../shared-storage/profile";
import CommonApi from "../../../apis/common";
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import Otp from "../edit-popup/otp/otp";


export default class EditClass extends React.Component {

    state = {
        class: [],
        activeIndex: 0,
        showOtpVerification: false,
        isvalid: false,
        screenStatus: "CONTINUE",
        otp: '',
        containerLoading: true,
        loading: true
    };

    constructor(props) {
        super(props);

        this.staticText = SharedStaticText.getStaticText('editClass');

        this.userInfo = SharedProfile.getProfile()
        this.getClass()
    }

    getClass = () => {

        let payload = {
            sortKey: 'order',
            sortType: "asc"
        }

        // this.toggleLoading(true)

        CommonApi.getClass(payload).then((response) => {


            let order = parseInt(this.userInfo.class.split(' ')[1])

            if (response && response.response) {

                let tempClass = response.response

                tempClass = tempClass.filter(obj => obj.order == order + 1);

                // for (let i = 0; i < response.response.length; i++) {
                //     let item = response.response[i]

                //     if ((item.title != this.userInfo.class || tempClass.length > 0) && tempClass.length < 1) {
                //         tempClass.push(item)
                //     }
                // }
                //console.log(tempClass)
                this.setState({ class: tempClass, loading: false, isvalid: true })
            } else
                this.toggleLoading(false)
        }).catch(err => {
            if (err) {
                this.toggleLoading(false)
                return;
            }
        })
    }


    sendOtp = () => {

        let payload = {}

        this.toggleLoading(true)

        CommonApi.userTransferSendOtp(payload).then((response) => {
            this.setState({ screenStatus: 'VERIFY', loading: false })
        }).catch(err => {
            if (err) {
                this.toggleLoading(false)
                return;
            }
        })
    }

    upgradeUser = () => {

        let activeClass = this.state.class[this.state.activeIndex]

        let payload = {
            class: activeClass.title,
            otp: this.state.otp
        }

        this.toggleLoading(true)

        CommonApi.userTransfer(payload).then((response) => {

            this.toggleLoading(false)

            this.userInfo['class'] = activeClass.title

            if (this.userInfo.isScollarShipEligible)
                this.userInfo['isScollarShipEligible'] = false


            if (this.userInfo.isEventScholarshipEligible)
                this.userInfo['isEventScholarshipEligible'] = false


            // moengage.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Class Updated': activeClass.title })
            // FireBaseEvent.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Class Updated': activeClass.title })
            // Facebook.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Class Updated': activeClass.title })
            // BranchIO.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, 'Class Updated': activeClass.title })

            SharedProfile.setProfile(this.userInfo)
            this.props.delegate.hideEditClass && this.props.delegate.hideEditClass();
        }).catch(err => {
            if (err) {
                this.setState({ invalidOtp: true, loading: false })
                return;
            }
        })

    }

    toggleLoading = (value) => {
        this.setState({ loading: value })
    }

    showLateralMenu = () => {
        this.props.navigation.push('LateralMenu');
    }

    close = () => {
        this.props.delegate.hideEditClass && this.props.delegate.hideEditClass();
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    onOtpChange = (otp) => {
        this.setState({ otp })
    }

    onPressClass = (item, index) => {

        if (this.state.screenStatus == "VERIFY")
            return

        if (item.title == this.userInfo.class) {
            this.setState({ activeIndex: index, isvalid: false })
        } else
            this.setState({ activeIndex: index, isvalid: true })
    }

    onPressContinue = () => {
        this.sendOtp()
    }

    onPressVerify = () => {
        this.upgradeUser()
    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height, containerLoading: false });
    }

    resendOtp = () => {
        this.setState({ invalidOtp: false, otp: '' })
        this.sendOtp()
    }

    resetInvalidOtp = () => {
        this.setState({ invalidOtp: false })
    }

    renderClasses = () => {
        //console.log('inside render class')
        let elements = []

        for (let i = 0; i < this.state.class.length; i++) {
            let item = this.state.class[i]

            elements.push(
                <div className="d-flex align-items-center" onClick={() => this.onPressClass(item, i)}>
                    <div>
                        {this.state.activeIndex == i ?
                            <div className="active-radio">
                                <img className="active-radio-image mr-2" src={getImageSource(ImageConstants.ACTIVE_RADIO_BUTTON)} />
                            </div> :

                            <div>
                                <div className="check-box"></div>
                            </div>}

                    </div>
                    <div className="class-name">{item.title}</div>
                </div>
            )
        }

        return elements
    }

    renderButtons = () => {

        if (this.state.screenStatus == 'CONTINUE') {

            return (
                this.state.isvalid ?
                    <div className="d-flex justify-content-center mt-2">
                        <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.onPressContinue}>
                            {this.staticText.continueButton}
                        </div>
                    </div> :
                    <div className="d-flex justify-content-center mt-2">
                        <div className="active-button save-button-disabled d-flex justify-content-center">
                            {this.staticText.continueButton}
                        </div>
                    </div>
            )
        }

        if (this.state.screenStatus == 'VERIFY') {

            return (
                this.state.otp ?
                    <div className="d-flex justify-content-center ">
                        <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.onPressVerify}>
                            {this.staticText.verifyButton}
                        </div>
                    </div> :
                    <div className="d-flex justify-content-center">
                        <div className="active-button save-button-disabled d-flex justify-content-center">
                            {this.staticText.verifyButton}
                        </div>
                    </div>
            )
        }
    }

    render() {


        return (
            <Modal className="custom-edit-class-popup" isOpen={true}>
                <ModalBody>
                    <div>
                        <div>
                            <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                            <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.close()} />
                        </div>
                        <div className="title">{'Edit Class'}</div>
                        <div className="edit-class-wrapper pl-5">

                            {
                                this.state.screenStatus == 'CONTINUE' ?
                                    <>
                                        {this.renderClasses()}

                                        {!this.state.class.length ?
                                            <>
                                                <div>
                                                    <img src={getImageSource(ImageConstants.NO_CLASSWORK_IMAGE)} />
                                                </div>
                                                <div>
                                                    <div className="text-center">{'No Class Available to upgrade'} </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {this.state.class.length ?
                                            <div className="bottom-wrapper">

                                                <div className="warning-card d-flex mt-2">
                                                    <div >
                                                        <img className="info-image mr-2" src={getImageSource(ImageConstants.INFO_RED_1)} />
                                                    </div>

                                                    <div>
                                                        <div className="warning-text">
                                                            {this.staticText.warningCard}
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            :
                                            <></>
                                        }

                                    </>
                                    : <></>
                            }

                            {
                                this.state.screenStatus == 'VERIFY' ?
                                    <div>
                                        <div className="otp-verification-text">OTP Verification</div>
                                        <div className="otp-sent-text" >{`OTP sent to ${this.userInfo.countryCode}-${this.userInfo.mobileNumber}`}</div>

                                        <div>
                                            <Otp onChange={this.onOtpChange} ref={(ref) => this.otpComponent = ref} isInvalidOtp={this.state.invalidOtp} delegate={this} hideLable={true}></Otp>
                                        </div>
                                    </div> : <></>
                            }


                            {this.renderButtons()}

                        </div>

                        <div className="">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )

    }

}
import React from 'react';
import Service from '../../apis/service-manager';
import Urls from '../../apis/url';
import { Route, Redirect } from 'react-router-dom';
import SessionCache from './session-cache';
import Path from './routes-path';
import SharedProfile from '../../../shared-storage/profile';

 import Loading from '../common/loading/loading';
import AsyncStore from '../../../shared-storage/async-store';
let commonPath = [Path.HOME, Path.UPDATE_PASSWORD, Path.ONBOARD]

let allowedPath = {
    ADMIN: Path.ADMIN,
    USERS: Path.USERS,
    CLASSES: Path.CLASSES,
    HOMEWORK: Path.HOMEWORK,
    TEST: Path.TEST,
    PROFILE: Path.PROFILE,
    STUDENTS: Path.STUDENTS,
    SCHOOLS: Path.SCHOOLS,
    ASSESSMENT: Path.ASSESSMENT,
    QUESTION_BANK: Path.QUESTION_BANK,
    LEARNING_RESOURCES : Path.LEARNING_RESOURCES
}
export default class AuthenticateRoute extends React.Component {

    state = {
        isLoading: true
    }

    componentDidMount() {
        this.isAuthenticated();
        ////console.log('inside authenticate route')
    }

     isAuthenticated = async () => {
        if (SharedProfile.isAuthenticated() == null) {

            ////console.log('inside with null authentication')
            Service.getData(Urls.IS_LOGGEDIN, {}, (err, res) => {
                // //console.log(res.data.profile)
                if (res) {
                    SharedProfile.setProfile(res.data.profile);

                    this.setState({
                        ...res.data.profile,
                        isLoading: false
                    });


                } else {
                    this.setState({ isLoading: false })
                }
            });
        }
        else {
            SharedProfile.setProfile(null);
            this.setState({
                isLoading: false
            });
        }
    }

    validateWithUserActions() {
        let pathName = window.location.pathname

        if (commonPath.indexOf(pathName) >= 0) {
            //console.log('inside validate route')
            return (<Route {...this.props} component={this.props.component} />)
        }

        // let userActions = SessionCache.getUserActions()

        // for (let i = 0; i < userActions.length; i++) {

        //     let action = userActions[i]

            // if (allowedPath[action.moduleName] === pathName) {
            //     return (<Route {...this.props} component={this.props.component} />)
            // }

        // }

        return (<Redirect to={Path.HOME} />)
    }

    render() {
        let isAuthenticated = SharedProfile.isAuthenticated()
        let isVerified = SharedProfile.isVerified()
        return (
            <div>
                {this.state.isLoading && <Loading></Loading>}
                {/* {!this.state.isLoading && isAuthenticated && this.props.path == Path.LOGIN && <Redirect to={Path.DASHBOARD} />} */}
                {!this.state.isLoading && isAuthenticated && isVerified && this.validateWithUserActions()}
                {!this.state.isLoading && !isAuthenticated && <Redirect to={Path.LOGIN} />}

            </div>
        );
    }

}
import CommonApi from '../../../apis/common';
import CommonUtils from '../../../utility/common-utilities';


export default class ParseContentIds {

    constructor() {

        this.contentRegex = /<cw-glossary [^>]*/g;
        this.contentIdRegex = /glossary-id="[^"]*/;
    }

    getContentIdsFromHtmlTags(data) {


        let contentData = data.match(this.contentRegex);
        contentData = contentData ? contentData : [];

        let contentIds = [];

        for (let i = 0; i < contentData.length; i++) {

            let idData = contentData[i].match(this.contentIdRegex);

            if (idData && idData.length) {

                let id = idData[0].split('"')[1];
                contentIds.push(id)
            }
        }
        return contentIds;

    }

    getParsedContentIdsFromContent(learningContentData, callback) {



        if (learningContentData instanceof Array && learningContentData.length > 0) {

            let glossaryIds = [];

            for (let i = 0; i < learningContentData.length; i++) {

                if (learningContentData[i]["type"] === 1 && learningContentData[i]['content'] && learningContentData[i]['content']['hint']) {

                    let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['content']['hint']);
                    glossaryIds = glossaryIds.concat(ids);
                }

                if (learningContentData[i]["type"] === 1 && learningContentData[i]['hint']) {

                    let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['hint']);
                    glossaryIds = glossaryIds.concat(ids);
                }

                if (learningContentData[i]["type"] === 4 && learningContentData[i]['question'] && learningContentData[i]['question']['solution']) {

                    let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['question']['solution']);
                    glossaryIds = glossaryIds.concat(ids);
                }

                if (learningContentData[i]["type"] === 4 && learningContentData[i]['question'] && learningContentData[i]['question']['answer']) {

                    let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['question']['answer']);
                    glossaryIds = glossaryIds.concat(ids);
                }

                if ((learningContentData[i]["type"] === 1 || learningContentData[i]["type"] === 5)
                    && (learningContentData[i]['content']
                        && learningContentData[i]['content']['solutionSteps']
                        && learningContentData[i]['content']['solutionSteps']["steps"])) {

                    if (learningContentData[i]['content']['solutionSteps']["steps"].length > 0) {
                        for (let j = 0; j < learningContentData[i]['content']['solutionSteps']["steps"].length; j++) {

                            let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['content']['solutionSteps']['steps'][j]['actualStep']);
                            glossaryIds = glossaryIds.concat(ids);
                        }
                    }

                    if (learningContentData[i]['content']['solutionSteps']["conclusion"]) {

                        let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['content']['solutionSteps']['conclusion']);
                        glossaryIds = glossaryIds.concat(ids);

                    }

                    let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['content']['solutionSteps']['summarySolution']);
                    glossaryIds = glossaryIds.concat(ids);
                }


                if (learningContentData[i]['content'] && learningContentData[i]['content']['type'] === 7
                    && learningContentData[i]['content']['solutionSteps']
                    && learningContentData[i]['content']['solutionSteps']["steps"]) {

                    if (learningContentData[i]['content']['solutionSteps']["steps"].length > 0) {
                        for (let j = 0; j < learningContentData[i]['content']['solutionSteps']["steps"].length; j++) {

                            let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['content']['solutionSteps']['steps'][j]['actualStep']);
                            glossaryIds = glossaryIds.concat(ids);
                        }
                    }

                    if (learningContentData[i]['content']['solutionSteps']['conclusion']) {

                        let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['content']['solutionSteps']['conclusion']);
                        glossaryIds = glossaryIds.concat(ids);

                    }

                    let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['content']['solutionSteps']['summarySolution']);
                    glossaryIds = glossaryIds.concat(ids);
                }

                if (learningContentData[i]['content'] && learningContentData[i]['content']['description']) {

                    let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['content']['description']);
                    glossaryIds = glossaryIds.concat(ids);
                }

                if (learningContentData[i]['content'] && learningContentData[i]['content']['summary']) {

                    for (let k = 0; k < learningContentData[i]['content']['summary'].length; k++) {
                        let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['content']['summary'][k]);
                        glossaryIds = glossaryIds.concat(ids);
                    }

                }

                if (learningContentData[i]["type"] === 3) {

                    if (learningContentData[i]["notes"] && learningContentData[i]["notes"]["error"]) {
                        let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['notes']['error']);
                        glossaryIds = glossaryIds.concat(ids);
                    }

                    if (learningContentData[i]["notes"] && learningContentData[i]["notes"]["success"]) {
                        let ids = this.getContentIdsFromHtmlTags(learningContentData[i]['notes']['success']);
                        glossaryIds = glossaryIds.concat(ids);
                    }
                }

            }
            if (glossaryIds.length > 0) {

                let payload = {
                    ids: glossaryIds.join()
                };

                CommonApi.getGlossaryDetails(payload).then((glossaryContent) => {


                    let decodedHtmlText = CommonUtils.decodeHTMLEntities(JSON.stringify(glossaryContent));

                    glossaryContent = JSON.parse(decodedHtmlText);

                    let glossaryResponse = {};

                    for (let i = 0; i < glossaryContent.length; i++) {
                        let id = String(glossaryContent[i]['_id']);

                        glossaryResponse[id] = glossaryContent[i];

                    }

                    callback(null, glossaryResponse)

                }).catch(err => {
                    if (err) {

                        callback(err, {});
                        return;
                    }

                })

            } else {
                callback(null, {});
            }

        } else {
            callback(null, {});
        }
    }

}

import React from "react";
import './question-title.scss';
import { getImageSource, getThemeImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import CwContentViewer from "../../../common/cw-content-viewer/cw-content-viewer";
import { MyAssessmentContext } from "../../clicker-assessment-context/my-assessment-context/clicker-assessment-context";
import Parser from "../../../common/content-parser/content-parser";
import ReactTooltip from "react-tooltip";
import PracticeApi from "../../../../apis/practice";
import { Modal, ModalBody } from "reactstrap";
import QuestionImage from "../../../common/cw-question-image/cw-question-image";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// import { contextType } from "react-latex";

class QuestionTitle extends React.Component {

    // static contextType = MyAssessmentContext;
    static contextType = MyAssessmentContext;

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.state = {
            question: {
                caseStudyDescription: ''
            }
        };

    }

    alphabet = ['a', 'b', 'c', 'd', 'e',
        'f', 'g', 'h', 'i', 'j',
        'k', 'l', 'm', 'n', 'o',
        'p', 'q', 'r', 's', 't',
        'u', 'v', 'w', 'x', 'y',
        'z'];

    componentDidMount() {

        this.getPracticeQuestion()

        setTimeout(() => {
            this.contextType = MyAssessmentContext ? MyAssessmentContext : "";
        }, 1000)
    }


    getPracticeQuestion() {

        let payload = {
            practiceId: this.props.practiceId
        }
        PracticeApi.getExamCaseStudyPracticeQuestion(payload).then(res => {
            console.log(res.practiceQuestion.question)
            if (res.practiceQuestion.question) {
                this.setState({ question: res.practiceQuestion.question })
            }

        }).catch(err => {
            console.log(err)
        })
    }

    toggleOptions() {
        this.props.toggleOptions && this.props.toggleOptions()
    }

    showCaseStudyDescription() {
        this.setState({ showCaseStudyDescription: true })
    }

    onClickClose() {
        this.setState({ showCaseStudyDescription: false })
    }

    children = ({ remainingTime }) => {
        const minutes = Math.floor(remainingTime / 60)
        const seconds = remainingTime % 60

        return `${minutes}:${seconds}`
    }

    addExtraSeconds() {
        this.props.singleSelectDelegate && this.props.singleSelectDelegate.startExtraCountDown && this.props.singleSelectDelegate.startExtraCountDown()
    }


    renderAssessment(value) {

        // //console.log("inside assessment")

        let styles = {
            p: { fontSize: '38px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (this.props.isFromPracticeCaseStudy) {
            return (
                <div className="question-title d-flex flex-row justify-content-between align-items-start" onMouseEnter={ReactTooltip.rebuild}>
                    <div className="question d-inline-flex">
                        {
                            !this.props.isReadOnly && this.props.isSchoolAssignment && this.props.activeDot ?
                                <div className="mr-2" style={{ fontSize: '24px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }}>
                                    {this.alphabet[this.props.activeDot - 1]}{'). '}
                                </div>
                                :
                                <></>
                        }
                        {this.parser.parseWithStyles(`${this.props.title}`, { p: styles.p, 'cw-math': styles.p, 'cw-blank': styles.p })}
                    </div>
                    <div className="" style={{}}>
                        <div className="view-case-study-button-wrapper d-flex align-items-center justify-content-center">
                            <div className="view-case-study-button cursor-pointer" onClick={() => this.showCaseStudyDescription()}>
                                <img className="case-study-icon" src={getImageSource(ImageConstants.PRACTICE_CASE_STUDY_ICON)} data-tip={"View Case Study"} /></div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="question-title-for-clicker" style={{
                marginLeft: `${this.props.isFullScreenMode && this.props.showStudentResponse ? this.props.isFFF ? '29rem' : '14rem' :
                    this.props.isFullScreenMode && !this.props.showStudentResponse ? "0.5rem" : this.props.showStudentResponse ? "6rem" : '0rem'}`,
                maxWidth: `${!this.props.questionImages.length ? '100%' : this.props.questionImages.length === 1 && !this.props.isSingleImageWidthHigh ? '50%' : '100%'}`
            }}>
                {
                    this.props.isFullScreenMode ?
                        <></>
                        :
                        this.props.isFFF ?
                            <div className="d-flex align-items-center mb-3">
                                <div className="quiz-button mr-2">Quiz</div>
                                <div className="subject-text">India Maths Day 2024</div>
                                <div className="question-number ml-2">Q {this.props.activePage}/{this.props.questions.length}</div>
                            </div>
                            :
                            <div className="d-flex align-items-center mb-3">
                                <div className="quiz-button mr-2">Assessment</div>
                                <div className="subject-text">Science</div>
                                <div className="question-number ml-2">Q {this.props.activePage}/{this.props.questions.length}</div>
                            </div>
                }

                <div className=" d-flex flex-row justify-content-between align-items-center" onMouseEnter={ReactTooltip.rebuild}>
                    <div className="question d-inline-flex align-items-start">
                        {
                            !this.props.isReadOnly && this.props.isSchoolAssignment && this.props.activeDot ?
                                <div className="mr-2" style={{ fontSize: '24px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }}>
                                    {this.alphabet[this.props.activeDot - 1]}{'). '}
                                </div>
                                :
                                <></>
                        }
                        <div className="question-number-circle">
                            <div className="question-number">{this.props.activePage}</div>
                        </div>
                        <div className={`${this.props.questionImageExist ? "" : 'mb-5'}`}>
                            {this.parser.parseWithStyles(`${this.props.title}`, { p: styles.p, 'cw-math': styles.p, 'cw-blank': styles.p })}
                        </div>

                    </div>
                    {
                        // <div className='timer-wrapper' style={{ display: this.props.showStudentResponse ? "none" : "flex" }}>
                        //     <div className=''>
                        //         <CountdownCircleTimer
                        //             size={100}
                        //             trailColor='#1CEA24'
                        //             isPlaying={this.props.isPlaying}
                        //             duration={this.props.questionTime}
                        //             colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                        //             colorsTime={[10, 6, 3, 0]}
                        //             onComplete={() => ({ shouldRepeat: false, delay: 1 }, this.addExtraSeconds())}
                        //         >
                        //             {(remainingTime) => (
                        //                 <div className="d-flex flex-column justify-content-center align-items-center">
                        //                     <div className="timer-text">
                        //                         {this.children(remainingTime)}
                        //                     </div>
                        //                     {
                        //                         !this.props.isPlaying ?
                        //                             <div className="">
                        //                                 <img className="pause-button-image-size" src={getImageSource(ImageConstants.PAUSE_BUTTON)} />
                        //                             </div>
                        //                             :
                        //                             <></>
                        //                     }

                        //                 </div>
                        //             )}
                        //         </CountdownCircleTimer>
                        //     </div>
                        // </div>

                    }
                </div>
            </div>


        )
    }


    getActiveDot() {
        let render = ''
        for (let i = 0; i < this.props.noOfDots; i++) {
            if (this.props.activeDot == i + 1)
                render = this.alphabet[this.props.activeDot - 1]
            else
                render = this.alphabet[i]
        }

        return render
    }


    renderOthers() {

        let styles = {
            p: { fontSize: '24px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "600" }
        }

        //console.log(this.props.activeDot, this.props.noOfDots)


        return (
            <>
                {!this.props.hideBackground && !this.props.isCheckPoint ?

                    <div className="question-title d-flex flex-row justify-content-start align-items-center">
                        {/* <div>
                            {this.alphabet[this.props.activeDot - 1]}
                        </div> */}
                        <div className="question d-flex">
                            {this.parser.parseWithStyles(`${this.props.title}`, { p: styles.p, 'cw-math': styles.p, 'cw-blank': styles.p })}
                            {
                                this.props.isReadOnly ?
                                    <div className="" onClick={() => this.toggleOptions()}>
                                        <img className="arrow-size cursor-pointer"
                                            src={getThemeImageSource(this.props.showOptions ?
                                                ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, 1)} />
                                    </div>
                                    :
                                    <></>
                            }
                        </div>

                    </div>

                    :
                    <div className="question-title d-flex flex-row justify-content-start align-items-start">
                        {
                            !this.props.isReadOnly && this.props.isSchoolAssignment ?
                                <div className="" style={{ fontSize: '24px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "600" }}>
                                    {this.alphabet[this.props.activeDot - 1]}{'). '}
                                </div>
                                :
                                <></>
                        }

                        <div className="question ml-1">
                            {this.parser.parseWithStyles(`${this.props.title}`, { p: styles.p, 'cw-math': styles.p, 'cw-blank': styles.p })}
                        </div>
                    </div>

                }
            </>
        )

    }



    renderPracticeQuestionDescriptionPopup() {
        return (
            <div>
                <Modal className="cw-case-study-custom-modal-center" isOpen={true} size={'lg'}  >
                    {/* <div className="pt-4 my-auto text-right">
                        <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                        <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} />
                    </div> */}
                    <ModalBody>
                        {
                            <>
                                <div className="mb-2 case-study-title d-flex justify-content-between">
                                    <div>
                                        <CwContentViewer content={this.state.question.caseStudyTitle} />
                                    </div>

                                    <div className="">
                                        <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} />
                                    </div>
                                </div>
                                <div className="mb-2 case-study-description">
                                    <CwContentViewer content={this.state.question.caseStudyDescription} />
                                </div>
                                <div className="mb-2">
                                    <QuestionImage images={this.state.question.images} />
                                </div>
                            </>

                        }
                    </ModalBody>
                </Modal>
            </div>
        )
    }

    render() {

        // // static contextType = MyAssessmentContext ;
        // //console.log(this.context)

        // if (this.context && this.context.isAssessment) {
        //     // return (
        //     //     <View style={[styles.titleContainer, styles.paddingTopForAssessmentTitle]}>
        //     //         <View style={styles.questionTitleForAssesssment}>
        //     //             {
        //     //                 this.parser.parseWithStyles(`${this.props.title}`, { p: styles.title, 'cw-math': styles.mathStyle, 'cw-blank': styles.blankStyle })
        //     //             }
        //     //         </View>
        //     //         {!this.props.hideBackground && <View style={styles.flagAssessment}>
        //     //             {this.context.showFlagActive ? <TouchableOpacity onPress={() => this.props.delegate && this.props.delegate.unFlagTheQuestion && this.props.delegate.unFlagTheQuestion()} hitSlop={styles.hitSlop}><Image source={getImageSource(ImageConstants.FLAG_ACTIVE_MY_ASSESSMENT)} /></TouchableOpacity>
        //     //                 : <TouchableOpacity onPress={() => this.props.delegate && this.props.delegate.flagTheQuestion && this.props.delegate.flagTheQuestion()} hitSlop={styles.hitSlop}><Image source={getImageSource(ImageConstants.FLAG_ASSESSMENT)} /></TouchableOpacity>}
        //     //         </View>
        //     //         }
        //     //     </View>
        //     // )
        //     return (
        //         <>

        //         </>


        //     )
        // }

        return (
            // <>
            //     {/* <CwContentViewer content={this.props.title} ></CwContentViewer> */}
            // </>
            <>
                <MyAssessmentContext.Consumer>
                    {value => value.isAssessment ? this.renderAssessment(value) : this.renderOthers()}

                </MyAssessmentContext.Consumer>
                <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                    multiline={true}
                    place='right'
                    type='light' effect='solid'
                    event="mouseover mouseenter"
                    eventOff="mouseleave mouseout scroll mousewheel blur" />
                {
                    this.state.showCaseStudyDescription && this.renderPracticeQuestionDescriptionPopup()
                }
            </>

        )


    }
}

export default QuestionTitle;

import React from 'react';
import './learning-content-popup-header.scss';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import { ImageConstants } from '../../../constants/constants';
import SharedThemeCategory from '../../../../shared-storage/category-theme';

export default class LearningContentPopupHeader extends React.Component {
    constructor(props) {
        super(props);
    }


    showPK = () => {
        this.props.delegate && this.props.delegate.showPK && this.props.delegate.showPK(true);
    }

    closePopup = () => {
        this.props.delegate && this.props.delegate.closePopup && this.props.delegate.closePopup();
    }

    onPressAskDoubt = () => {
        this.props.delegate && this.props.delegate.onPressAskDoubt()
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId);

        // setCategoryThemeStyles(this.props.categoryId);

        return (
            <div className='learning-content-popup-header' >

                <div className=''>
                    <img className='learning-content-header-image-size' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, this.props.categoryId)} />
                </div>

                <div>

                    {/* {
                        this.props.isScrolled ?
                            <View style={Styles.titleContainer}>

                            </View>
                            : <></>
                    } */}
                    <div>



                        {/* <TouchableOpacity onPress={() => { this.toggleLateralMenu(true) }} style={Styles.actionContainerItems} hitSlop={Styles.hitSlop}>
                            <View>
                                <Image source={getImageSource(ImageConstants.NAV_LATERAL_MENU)} />
                            </View>
                        </TouchableOpacity> */}

                        <div className='d-flex justify-content-between'>


                            {
                                !this.props.isScrolled ?
                                    <div>
                                        <div className=''>
                                            <span className='elo-title-text'>{this.props.title ? this.props.title : this.props.chapterTitle}</span>
                                        </div>
                                        {
                                            !this.props.isChapterIntro ?
                                                <div >
                                                    <div className='sub-title-text'>
                                                        {this.props.chapterTitle}
                                                    </div>
                                                </div>
                                                : <></>
                                        }

                                    </div>
                                    : <></>
                            }
                            <div className='d-flex align-items-center'>
                                {/* {this.props.isPKExist ?
                                    <div onPress={this.togglePopup} >
                                        <div>
                                            <img className='prev-knowledge-image' src={getImageSource(ImageConstants.NAV_PREV_KNOWLEDGE)} />
                                        </div>
                                    </div>
                                    :
                                    <>

                                    </>
                                } */}
                                <div className='pk-label mr-3'>Previous Knowledge</div>
                                {/* <div className='ask-doubt-button' onPress={this.onPressAskDoubt}>
                                    <img className='ask-doubt-image-size' src={getImageSource(ImageConstants.PLUS_ICON)}></img>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>

                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.props.categoryId)}></img>
                </div>

            </div>
        );
    }
}

import React from "react";
import './student-answered-status-board.scss';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'




class StudentAnsweredStatusBoard extends React.Component {


    state = {
        // studentsList: [
        //     { studentNumber: 1, receivedAnswer: true },
        //     { studentNumber: 2, receivedAnswer: true },
        //     { studentNumber: 3, receivedAnswer: true },
        //     { studentNumber: 4, receivedAnswer: true },
        //     { studentNumber: 5, receivedAnswer: true },
        //     { studentNumber: 6, receivedAnswer: true },
        //     { studentNumber: 7, receivedAnswer: true },
        //     { studentNumber: 8, receivedAnswer: true },
        //     { studentNumber: 9, receivedAnswer: false },
        //     { studentNumber: 10, receivedAnswer: false }
        // ]
        studentsList : this.props.studentList
    }



    children = ({ remainingTime }) => {
        const minutes = Math.floor(remainingTime / 60)
        const seconds = remainingTime % 60

        return `${minutes}:${seconds}`
    }


    addExtraSeconds() {
        this.props.delegate && this.props.delegate.startExtraCountDown && this.props.delegate.startExtraCountDown()
    }

    renderLargeStatusBar() {
        return (
            <div className="large-student-answered-status-board">
                {/* <div className='timer-wrapper'>
                    <div className=''>
                        <CountdownCircleTimer
                            size={120}
                            trailColor='#1CEA24'
                            isPlaying={this.props.isPlaying}
                            duration={this.props.questionTime}
                            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                            colorsTime={[10, 6, 3, 0]}
                            onComplete={() => ({ shouldRepeat: false, delay: 1 }, this.addExtraSeconds())}
                        >
                            {(remainingTime) => (
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="timer-text">
                                        {this.children(remainingTime)}
                                    </div>
                                    {
                                        !this.props.isPlaying ?
                                            <div className="">
                                                <img className="pause-button-image-size" src={getImageSource(ImageConstants.PAUSE_BUTTON)} />
                                            </div>
                                            :
                                            <></>
                                    }

                                </div>
                            )}

                        </CountdownCircleTimer>
                    </div>
                </div> */}
                <div className="d-flex justify-content-center align-items-center button-wrapper mt-4">
                    {
                        this.state.studentsList && this.state.studentsList.length ?
                            this.state.studentsList.map(student => (
                                student.isTeacher ? <></> :
                                    <div className={`student-roll-number-button ml-1 mb-2 ${student.receivedAnswer ? 'enable-button' : ''}`}>{student.studentNumber}</div>
                            ))
                            :
                            <>No Students Found</>
                    }
                </div>
            </div>
        )
    }


    render() {

        // console.log(this.state.studentsList)

        return (
            // <div className="student-answered-status-board">
            //     <div className="d-flex justify-content-center align-items-center button-wrapper mt-4">
            //         {
            //             this.state.studentsList && this.state.studentsList.length ?
            //                 this.state.studentsList.map(student => (
            //                     student.isTeacher ? <></> :
            //                     <div className={`student-roll-number-button ml-1 mb-1 ${student.receivedAnswer ? 'enable-button' : ''}`}>{student.studentNumber}</div>
            //                 ))
            //                 :
            //                 <>No Students Found</>
            //         }
            //     </div>
            // </div>
            this.renderLargeStatusBar()
        )
    }

}

export default StudentAnsweredStatusBoard;
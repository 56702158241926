class LayoutShared {

    static sideBar = null
    static setLayout(value) {
        this.sideBar = value
    }
    
    static getLayout() {
        return this.sideBar
    }

}

export default LayoutShared;

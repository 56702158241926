import React from "react";
import './practice-experience.scss';
import SharedProfile from "../../../shared-storage/profile";
import CommonUtils from "../../utility/common-utilities";
import HomeworkApi from "../../apis/homework";
import AssessmentHeader from "../common/assessment-header/assessment-header";
import Question from "../common/question/question";
import SuccessFailureScreen from '../class-specific-flow/practice-completion-screen/practice-completion-screen'
import ConceptList from "../my-classwork-V1/classwork-concept-list-screen/classwork-concept-list";
import HomeworkConceptList from '../my-homework/classwork-concept-list-screen/classwork-concept-list';
import { Constants } from "../../constants/constants";
import FireBaseEvent from '../../firebase-events/firebase-events';
import LayoutShared from "../common/layout-shared/layout-shared";
import PracticeApi from "../../apis/practice";


export const StandardClassworkConText = React.createContext({ disableNext: false })


export default class WorkflowExperience extends React.Component {

    workflowEngine = null;
    worflowNodes = [];
    questions = [];
    state = {
        questions: [],
        answers: [],
        learningElos: [],
        showSuccessFailureScreen: false,
        stepIndex: 0,
        showHint: false,
        showSolvedExamplesModal: false,
        showInvalidAnswerModal: false,
        showCorrectIncorrectModal: false,
        showCorrectAnswer: false,
        isReadOnly: false,
        disableNext: false,
        toastText: "",
        questionLoading: true,
        currentQuestionNumber: 0,
    };
    questionComponent = null;
    successFailureScreenData = {
        // "concepts": [
        //     {
        //         "title": "Numbers upto 1 lakh",
        //         "key": "15846112995131712"
        //     }
        // ],
        // "chapterTitle": "Knowing Our Numbers",
        // "homeworkId": "649c05f72d13afcd37cc5e1d",
        // "cleared": [],
        // "notCleared": [
        //     {
        //         "id": "5e7340315c3254149568b90f",
        //         "title": "Read and Write Numbers with Place Value "
        //     }
        // ],
        // "progress": {
        //     "value": 0,
        //     "type": "%"
        // }
    };

    params = {

    }

    isRevise = false;

    isCorrect = false;
    eloIds = []

    constructor(props) {
        super(props);
        // let { navigation } = this.props;
        this.params = this.props.params
        this.userInfo = SharedProfile.getProfile();
        this.layoutShared = LayoutShared.getLayout()

        //console.log(this.params)

        // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()

        // if (!this.userInfo.isSubscribed)
        //     this.landingScreenInstance.pauseUserAppTime()

        // this.staticText = SharedStaticText.getStaticText('timerPauseToaster');
    }

    componentDidMount() {

        this.getAssessmentContent((err, data) => {
            if (err) {
                return;
            }
            this.setQuestion(data);
        });

        // if (!this.userInfo.isSubscribed) {
        //     this.showTimerPauseToast()

        //     this.props.navigation.addListener("didFocus", () => {
        //         AppState.addEventListener('change', this._handleAppStateChange);
        //     });

        //     this.props.navigation.addListener("didBlur", () => {
        //         AppState.removeEventListener('change', this._handleAppStateChange);
        //     });

        // }

        // BackHandler.addEventListener('hardwareBackPress', this.backhandler);
    }

    backhandler = () => {
        // BackHandler.removeEventListener('hardwareBackPress', this.backhandler);
        return true
    }

    // _handleAppStateChange = (state) => {
    //     if ((state == constants.APP_STATE_BACKGROUND && Platform.OS == 'android') || (state == constants.APP_STATE_IN_ACTIVE && Platform.OS == 'ios')) {
    //         HomeworkShared.setHomeworkPageStatus(true)
    //     }
    // }

    getAssessmentContent = (callback) => {

        let payload = {
            nodeId: this.params.nodeId,
            practiceId: this.params.practiceId
        };

        PracticeApi.getPracticeAssessment(payload).then((data) => {
            callback && callback(null, data);
        }).catch(err => {
            callback && callback(err, null);
        })
    }

    setQuestion = (data) => {

        // //console.log(CommonUtils.shuffleArray(JSON.parse(JSON.stringify(data.content))))

        //console.log(JSON.parse(JSON.stringify(data.content)))


        this.totalElos = data.totalElos;
        this.eloPosition = data.eloPosition;

        // this.setState({ questions: CommonUtils.shuffleArray(JSON.parse(JSON.stringify(data.content))), index: 0, currentQuestionNumber: data.completedQuestionNumber + 1, totalQuestions: this.params.homework.approximateQuestions });

        this.setState({ questions: JSON.parse(JSON.stringify(data.content)), index: 0, currentQuestionNumber: data.completedQuestionNumber + 1 <= this.params.practice.approximateQuestions ? data.completedQuestionNumber + 1 : data.completedQuestionNumber, totalQuestions: this.params.practice.approximateQuestions });

        if (data.resume && data.resume.contentId) {

            let resumeIndex = 0;

            for (let i = 0; i < data.content.length; i++) {
                if (data.content[i].id === data.resume.contentId) {
                    resumeIndex = i;
                    break;
                }
            }

            //console.log(resumeIndex)

            if (data.content[resumeIndex].details.content.isMultiStep) {

                //console.log(data.content[resumeIndex].answer)

                let stepIndex = 0

                if (data.content[resumeIndex].details.content.steps &&
                    data.content[resumeIndex].details.content.steps.length &&
                    data.content[resumeIndex].answer &&
                    data.content[resumeIndex].answer.steps.length) {
                    stepIndex = data.content[resumeIndex].details.content.steps.length <= data.content[resumeIndex].answer.steps.length ? data.content[resumeIndex].answer.steps.length - 1 : data.content[resumeIndex].answer.steps.length
                }

                this.setState({
                    index: resumeIndex,
                    stepIndex: stepIndex,
                    // stepIndex: data.content[resumeIndex].answer &&
                    //     data.content[resumeIndex].answer.steps.length ? data.content[resumeIndex].answer.steps.length : 0,
                    questionLoading: false
                }, () => {
                    this.setState({ questionLoading: true })
                });

            } else {

                this.setState({ index: resumeIndex, questionLoading: false }, () => {
                    this.setState({ questionLoading: true })
                });
            }
        }
    }

    reset = () => {
        this.setState({
            questions: [],
            answers: [],
            learningElos: [],
            index: 0
        });
    }

    next() {

        //console.log(this.questionComponent)

        this.answer = this.questionComponent && this.questionComponent.getAnswer();

        this.question = this.state.questions[this.state.index].details;

        //console.log(this.question, this.answer)

        if (this.answer != null) {

            if (this.question.content.isMultiStep) {

                //console.log('inside multi step')

                let ques = this.question.content.steps[this.state.stepIndex];
                let ans = this.answer.steps[this.state.stepIndex];

                this.setState({ disableNext: true })
                this.validateAnswer(ques, ans, (err, res) => {

                    if (err) {
                        this.setState({ disableNext: false })
                        return;
                    }
                    this.setState({ disableNext: false })



                    if (res.data.isCorrect) {

                        if (this.question.content.isMultiStep && this.state.stepIndex === this.question.content.steps.length - 1) {
                            //console.log('correct answer')
                            this.ShowCorrectIncorrectModel(true);
                        }
                        else {
                            //console.log('next continue')
                            this.nextContinue(this.question, this.answer, false);
                        }


                    } else
                        this.ShowCorrectIncorrectModel(false);

                });

            } else {

                this.setState({ disableNext: true })
                this.validateAnswer(this.question, this.answer, (err, res) => {

                    if (err) {
                        this.setState({ disableNext: false })
                        return;
                    }
                    this.setState({ disableNext: false })

                    //console.log(res)

                    if (res.data.isCorrect)
                        this.ShowCorrectIncorrectModel(true);
                    else
                        this.ShowCorrectIncorrectModel(false);
                });
            }

        } else {
            this.toggleInvalidAnswerModal(true);
        }
    }

    nextQuestionOnCorrectInCorrectAnswer() {

        if (this.question.content.isMultiStep) {
            //console.log('continue 200', this.question)
            if (this.isCorrect)
                this.nextContinue(this.question, this.answer, false);
            else
                this.nextContinue(this.question, this.answer, true);

        } else {
            //console.log('normal next continue called...')
            this.nextContinue(this.question, this.answer);
        }

        this.setState({ showCorrectIncorrectModal: false, isReadOnly: false });
    }

    nextContinue = (question, answer, branchQuesExit) => {

        if (this.state.questions.length > this.state.index) {
            this.state.questions[this.state.index].answer = answer;
        }

        if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex && !branchQuesExit) { /** If question is multi step  */


            this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index], answer, null, (err, res) => {
                if (err) {
                    return
                }

                this.setState({ stepIndex: this.state.stepIndex + 1, questionLoading: false }, () => {
                    this.setState({ questionLoading: true })
                });
            });

        } else { /** If it normal question */

            //console.log("inside normal next question")

            if (this.state.stepIndex)
                this.resetStepIndex();

            if (this.state.questions.length > this.state.index + 1) {


                if (this.state.questions[this.state.index] && answer) {
                    if (this.state.questions[this.state.index].id != answer.id) {
                        return
                    }
                }

                this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index + 1], answer, null, (err, res) => {
                    if (err) {
                        return
                    }

                    this.setState({ index: this.state.index + 1, currentQuestionNumber: this.state.currentQuestionNumber + 1, questionLoading: false }, () => {
                        this.setState({ questionLoading: true })
                    });
                });

            }
            else { /** If end of the elo reached */

                //console.log("ELO End Reached")


                if (this.state.questions[this.state.index] && answer) {
                    //console.log('inside---111')
                    if (this.state.questions[this.state.index].id != answer.id) {
                        //console.log('inside---112')
                        return
                    }
                }

                this.updateUserActivity(this.state.questions[this.state.index], null, answer, null, (err, res) => {

                    if (err) {
                        return;
                    }

                    this.setState({ disableNext: true })
                    this.assessNode((err, res) => {

                        if (err) {
                            this.setState({ disableNext: false })
                            return;
                        }

                        let { currentNode, nextNode, isLastNode } = this.getNodeMetaInfo()

                        //console.log("isLastNode", isLastNode)
                        //console.log("Next Node", nextNode)

                        if (!isLastNode && nextNode) {

                            this.params.nodeId = nextNode.id

                            this.getAssessmentContent((err, data) => {

                                if (err) {
                                    this.setState({ disableNext: false })
                                    return;
                                }

                                this.setState({
                                    disableNext: false,
                                    questions: data.content,
                                    index: 0,
                                    stepIndex: 0,
                                    questionLoading: false,
                                    currentQuestionNumber: data.completedQuestionNumber + 1
                                }, () => {
                                    this.setState({ questionLoading: true })
                                });

                            });
                        } else {
                            console.log("show success failure screen")
                            this.showSuccessFailureScreen(res);
                        }
                    });

                });
            }
        }
    }

    assessNode(callback) {

        let payload = {
            nodeId: this.params.nodeId,
            practiceId: this.params.practiceId
        }

        PracticeApi.assessPracticeNode(payload).then((res) => {

            if (callback)
                callback(null, res);

        }).catch(err => {
            if (callback)
                callback(err, null);
        })

    }

    updateUserActivity(presentContent, resumeContent, answer, isReEnforcementCompleted, callback) {

        let payload = {
            nodeId: this.params.nodeId,
            practiceId: this.params.practiceId
        };

        if (presentContent)
            payload.eloActivity = { contentId: presentContent.id, answer: answer, eloId: presentContent.eloId }

        if (resumeContent)
            payload.resume = { contentId: resumeContent.id, eloId: resumeContent.eloId };


        if (isReEnforcementCompleted)
            payload.isReEnforcementCompleted = true;


        PracticeApi.updateUserPracticeActivity(payload).then((res) => {

            if (callback)
                callback(null, res);
        }).catch(err => {

            if (callback)
                callback(err, null);

        })

    }

    showSuccessFailureScreen = (data) => {

        console.log(data)

        this.layoutShared.toggleSidebar(true)

        this.setState({ disableNext: false })

        this.successFailureScreenData = {
            concepts: this.params.concepts,
            chapterTitle: this.params.title,
            practiceId: this.params.practiceId,
            cleared: data.data.cleared,
            notCleared: data.data.notCleared,
            progress: data.data.progress,
            chapterImageUrl: this.params.chapterImageUrl
        }

        //console.log(this.successFailureScreenData)
        // BackHandler.addEventListener('hardwareBackPress', this.backhandler);
        // moengage.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy,'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter': this.state.chapterTitle,'Chapter Key':this.props.homework.chapterKey, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'NO','Completed Date':new Date().toLocaleDateString()})
        // FireBaseEvent(Constants.SCHOOL_ASSIGNMENT, { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Teacher: this.params.homework.createdBy, 'Class': this.userInfo.class, 'Assignment Type': this.params.homework.type.replace('_', " "), 'Chapter': this.params.homework.chapterTitle, 'Chapter Key': this.params.homework.chapterKey, 'Concepts': this.params.conceptsCovered.toString(), 'Status': 'Completed', 'Learning Started': 'NO', 'Completed Date': new Date().toLocaleDateString() })
        // Facebook.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy,'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter': this.state.chapterTitle,'Chapter Key':this.props.homework.chapterKey, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'NO','Completed Date':new Date().toLocaleDateString()})
        // BranchIO.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy,'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter': this.state.chapterTitle,'Chapter Key':this.props.homework.chapterKey, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'NO','Completed Date':new Date().toLocaleDateString()})

        // moengage.logCustomEvent('Homework Completion' , { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy,'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter': this.state.chapterTitle,'Chapter Key':this.props.homework.chapterKey, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'NO','Completed Date':new Date().toLocaleDateString(),'Due Date': new Date(this.props.homework.endTime).toLocaleDateString()})
        // FireBaseEvent(`${Constants.SCHOOL_ASSIGNMENT} Completion`, { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Teacher: this.params.homework.createdBy, 'Class': this.userInfo.class, 'Assignment Type': this.params.homework.type.replace('_', " "), 'Chapter': this.params.homework.chapterTitle, 'Chapter Key': this.params.homework.chapterKey, 'Concepts': this.params.conceptsCovered.toString(), 'Status': 'Completed', 'Learning Started': 'NO', 'Completed Date': new Date().toLocaleDateString() })
        // Facebook.logCustomEvent('Homework Completion', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy,'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter': this.state.chapterTitle,'Chapter Key':this.props.homework.chapterKey, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'NO','Completed Date':new Date().toLocaleDateString()})
        // BranchIO.logCustomEvent('Homework Completion', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy,'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter': this.state.chapterTitle,'Chapter Key':this.props.homework.chapterKey, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'NO','Completed Date':new Date().toLocaleDateString()})


        this.setState({ showSuccessFailureScreen: true }, () => {

            if (!this.userInfo.isSubscribed) {

                // AppState.removeEventListener('change', this._handleAppStateChange);

                // if (this.landingScreenInstance)
                //     this.landingScreenInstance.resumeUserAppTime()

                // this.showTimerResumeText()
            }
        })
    }

    getCurrentNode = () => {
        let nodes = this.params.nodes;
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id == this.params.nodeId) {
                return nodes[i];
            }
        }
    }

    resetStepIndex = () => {
        this.setState({ stepIndex: 0 });
    }

    validateAnswer = (question, answer, callback) => {

        let payload = { question, answer };

        PracticeApi.validateAnswer(payload).then((res) => {
            callback(null, res);
        }).catch(err => {
            callback(err, null);
        })
    }

    goToChapterList = () => {
        this.props.navigation.navigate('Chapters')
    }

    goToLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    toggleInvalidAnswerModal = (value) => {
        this.setState({ showInvalidAnswerModal: value, questionLoading: false }, () => {
            this.setState({ questionLoading: true })
        });
    }

    ShowCorrectIncorrectModel = (isCorrect) => {
        this.isCorrect = isCorrect;
        this.setState({ showCorrectIncorrectModal: true, questionLoading: false }, () => {
            this.setState({ questionLoading: true })
        })
    }

    hideCorrectIncorrectModal() {
        this.setState({ showCorrectIncorrectModal: false });
    }

    onOk = () => {
        this.toggleInvalidAnswerModal(false);
    }

    getNodeMetaInfo = () => {

        let currentNodeIndex = null

        let nodes = this.params.nodes;

        //console.log(nodes)

        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id == this.params.nodeId) {
                currentNodeIndex = i
                break;
            }
        }
        return { currentNode: nodes[currentNodeIndex], nextNode: nodes[currentNodeIndex + 1], isLastNode: nodes.length - 1 <= currentNodeIndex }
    }

    onPressResumeTimePopupContinue = () => {
        this.setState({ showResumeTimePopup: false })
    }

    showTimerPauseToast = () => {
        let minutes = this.getRemainingTime()
        let message = CommonUtils.simpleTemplateEngine(this.staticText.timerPauseText, { time: minutes })
        this.setState({ toastText: message }, () => {
            this.showResumeTimerPopUp()
        })
    }

    getRemainingTime = () => {
        if (this.landingScreenInstance) {
            this.remainingTime = this.landingScreenInstance.getRemainingAppTime()
            return this.remainingTime ? Math.ceil(this.remainingTime / 60) : 0
        }

        return 0
    }

    showTimerResumeText = () => {
        let minutes = this.getRemainingTime()
        let message = CommonUtils.simpleTemplateEngine(this.staticText.timerResumeText, { time: minutes })
        this.setState({ toastText: message }, () => {
            this.showResumeTimerPopUp()
        })
    }

    showResumeTimerPopUp = () => {
        this.setState({ showResumeTimePopup: true }, () => {
            // setTimeout(() => this.setState({ showResumeTimePopup: false }), 3000)
        })
    }





    render() {

        return (
            <div className="practice-experience">
                {!this.state.showSuccessFailureScreen ?
                    <>
                        {
                            this.state.learningElos.length === 0 && this.state.questions.length > 0 &&
                            <div>

                                {
                                    this.state.questionLoading ?
                                        <>
                                            <AssessmentHeader questionCount={true}
                                                currentQuestionNumber={this.state.currentQuestionNumber}
                                                totalQuestions={this.state.totalQuestions}
                                                title={this.params.title ? this.params.title : ''}
                                                // categoryThemeId={this.state.questions[this.state.index].categoryThemeId}>
                                                categoryThemeId={1}>
                                            </AssessmentHeader>
                                            <div className="ml-5 mt-3">
                                                {
                                                    this.state.questions && this.state.questions.length > 0 &&
                                                    <div>
                                                        <StandardClassworkConText.Provider value={{ disableNextStandardHomework: this.state.disableNext, showAlphabetForHomeworkAssessment: true }}>
                                                            <Question delegate={this} ref={(ref) => { this.questionComponent = ref }}
                                                                answer={this.state.questions[this.state.index].answer}
                                                                content={this.state.questions[this.state.index].details}
                                                                stepIndex={this.state.stepIndex} showHint={this.state.showHint}
                                                                showSolvedExamplesModal={this.state.showSolvedExamplesModal}
                                                                showInvalidAnswerModal={this.state.showInvalidAnswerModal}
                                                                showCorrectIncorrectModal={this.state.showCorrectIncorrectModal}
                                                                isAnswerCorrect={this.isCorrect}
                                                                userAnswerForReadOnly={this.answer ? this.answer : {}}
                                                                // categoryThemeId={this.state.questions[this.state.index].categoryThemeId}
                                                                categoryThemeId={1}
                                                            ></Question>
                                                        </StandardClassworkConText.Provider>
                                                    </div>
                                                }
                                            </div>

                                        </>

                                        :

                                        <></>
                                }

                            </div>
                        }
                    </>
                    :

                    <SuccessFailureScreen
                        data={this.successFailureScreenData}
                        navigation={this.props.navigation}
                        categoryThemeId={this.params.categoryThemeId}
                        conceptsCovered={this.params.conceptsCovered}
                        practice={this.params.practice}
                        delegate={this.props.delegate}
                        chapterTitle={this.params.title ? this.params.title : ''} />
                    // this.params.typeOfSchoolwork === 'CLASSWORK' ? <div className="margin-for-concept-list">
                    //     <ConceptList
                    //         meta={this.successFailureScreenData}
                    //         conceptsCovered={this.params.conceptsCovered}
                    //         homework={this.params.homework}
                    //         delegate={this.props.delegate} />
                    // </div> :
                    //     <div className="margin-for-concept-list">
                    //         <HomeworkConceptList meta={this.successFailureScreenData} delegate={this.props.delegate} />
                    //     </div>


                }
            </div>
        )
    }
}
import React, { Component } from 'react';
import './dropdown.scss';
import { ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source';


class Dropdown extends Component {

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this)

        this.optionsRef = [];
        this.optionWrapperRef = React.createRef();
        this.inputRef = React.createRef();

        this.state = {
            showExpandView: false,
            selectedText: '',
            searchText: '',
            selectedOptions: [],
            options: this.props.options ? JSON.parse(JSON.stringify(this.props.options)) : [],
            cursor: 0,
            listSize: this.props.options ? this.props.options.length : 0,
            showInput: true
        }
        let optionHash = {}
        if (this.props.multiSelect && this.props.value) {
            for (let i = 0; i < this.props.value.length; i++) {
                optionHash[this.props.value[i]] = {}
            }
        } else if (this.props.value) {
            let option = this.props.options?.filter(option => option.value == this.props.value);
            if (option?.length)
                this.state.selectedText = option[0].title;
        }

        this.state.selectedOptions = this.props.options?.filter(option => optionHash[option?.value]) || []
        this.state.options = this.props.options?.filter(option => !optionHash[option?.value]) || []

    }

    handleKeyDown(e) {
        const { cursor, listSize } = this.state

        if (e.keyCode === 38 && cursor > 0) {
            this.setState(prevState => ({
                cursor: prevState.cursor - 1
            }));

        } else if (e.keyCode === 40 && cursor < listSize - 1) {
            this.setState(prevState => ({
                cursor: prevState.cursor + 1
            }));
        }

        //console.log("cursor", cursor);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickEvent, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    toggleExpandView(value) {
        this.setState({ showExpandView: value, cursor: 0 });
        if (!value && this.props.multiSelect && this.state.selectedOptions && this.state.selectedOptions.length) {
            this.setState({ showInput: false })
        }
    }

    handleClickEvent = (event) => {
        if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
            if (this.state.showExpandView)
                this.toggleExpandView(false);
        }
    }

    onClickOption(option) {

        if (!this.props.multiSelect) {
            this.setState({ selectedText: option[this.props.text], searchText: "" }, () => {
                if (this.props.onSelect) {
                    // let value = !this.props.value ? option : option[this.props.value]; edit mode issue
                    this.props.onSelect(option);
                }
            });
            this.toggleExpandView(false);
        } else {
            let selectedOptions = this.state.selectedOptions;
            selectedOptions.push(option);
            let options = this.state.options.filter((item) => item[this.props.text] != option[this.props.text]);

            if (this.props.onSelect) {
                this.props.onSelect(selectedOptions);
            }
            this.setState({ selectedOptions: selectedOptions, options: options });
        }
    }

    onRemoveOption(index) {
        if (this.props.disabled) return
        let selectedOptions = this.state.selectedOptions;
        selectedOptions.splice(index, 1);
        let selectedItem = selectedOptions.map((option) => option[this.props.text]);
        let options = this.props.options.filter((item) => !selectedItem.includes(item[this.props.text]));

        if (this.props.onSelect) {
            this.props.onSelect(selectedOptions);
        }
        this.setState({ selectedOptions: selectedOptions, options: options });
    }

    onSearch = (event) => {

        let value = event.target.value;

        this.setState({ searchText: value });

        let origanlOptions = JSON.parse(JSON.stringify(this.props.options));

        //console.log(origanlOptions)

        if (!this.props.multiSelect) {
            let options = origanlOptions.filter(option => option[this.props.text].toLowerCase().includes(value.toLowerCase()));
            this.setState({ options: options });
        } else {
            if (this.state.selectedOptions && this.state.selectedOptions.length) {
                let selectedTexts = this.state.selectedOptions.map((option) => option[this.props.text]);
                origanlOptions = origanlOptions.filter((option) => !selectedTexts.includes(option[this.props.text]));

                let options = origanlOptions.filter(option => option[this.props.text].toLowerCase().includes(value.toLowerCase()));
                this.setState({ options: options });
            } else {
                let options = origanlOptions.filter(option => option[this.props.text].toLowerCase().includes(value.toLowerCase()));
                this.setState({ options: options });
            }
        }

    }



    checkValue = (event) => {

        if (!this.state.options.length && !this.props.multiSelect) {
            this.setState({ selectedText: "", searchText: "" });
            this.resetDropdown();
            this.props.onSelect(null);
            this.toggleExpandView(false);
        } else {
            if (this.props.multiSelect) {
                if (!this.state.options.length) {
                    this.resetMulitSelectDropdown();
                }
            }
        }
    }

    resetDropdown() {
        let options = this.props.options && this.props.options.length ? JSON.parse(JSON.stringify(this.props.options)) : []
        this.setState({ options: options, searchText: '' });
    }

    resetMulitSelectDropdown() {
        let options = this.props.options && this.props.options.length ? JSON.parse(JSON.stringify(this.props.options)) : []
        if (this.state.selectedOptions && this.state.selectedOptions.length) {
            let selectedTexts = this.state.selectedOptions.map((option) => option[this.props.text]);
            options = options.filter((option) => !selectedTexts.includes(option[this.props.text]));
            this.setState({ options: options, searchText: "" });
        } else {
            this.setState({ options: options, searchText: "" });
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.options && this.props.options && (previousProps.options.length !== this.props.options.length || !this.compareOptions(previousProps.options, this.props.options))) {
            this.setState({ options: this.props.options ? JSON.parse(JSON.stringify(this.props.options)) : [], selectedOptions: [], selectedText: "", })
        }
    }

    compareOptions(previousOption, currentOption) {
        let leftOption = previousOption.map((option) => option[this.props.text]);
        let filterOption = currentOption.filter((option) => leftOption.includes(option[this.props.text]));

        if (leftOption.length == filterOption.length) {
            return true;
        } else {
            return false
        }

    }

    clearAll() {
        if (this.props.multiSelect) {
            let options = this.props.options;

            if (this.props.onSelect) {
                this.props.onSelect([]);
            }
            this.setState({ selectedOptions: [], options: options });
        } else {
            this.setState({ selectedText: "", searchText: "" }, () => {
                if (this.props.onSelect) {
                    this.props.onSelect(null);
                }
            });
        }
    }

    getEditModeText() {
        if (Array.isArray(this.props.value)) {

            let values = this.props.options.map(item => {
                if (this.props.value.includes(item.value)) {
                    return item.title
                }
            })
            return values.join(", ")
        } else {
            let value = this.props.options.find(item => this.props.value == item.value)
            return value?.title || ""
        }
    }

    render() {
        return (
            <div className='dropdown-v2' ref={ref => this.dropdownRef = ref}>

                {
                    (!this.props.disabled && this.state.selectedOptions && this.state.selectedOptions.length) ||
                        (!this.props.disabled && this.state.selectedText)
                        ?
                        !this.props.hideAllDelete ? <img src={getImageSource(ImageConstants.CLEAR_ICON)} className='caret' onClick={() => this.clearAll()}></img> : ""
                        :
                        <img src={getImageSource(ImageConstants.DROPDOWN_ARROW)} className='caret' onClick={() => this.toggleExpandView(!this.state.showExpandView)}></img>
                }
                {!this.props.disabled ?

                    <div className={`dropdown-input-mulit-select ${this.props.invalid ? 'invalid' : ''}
                                  ${this.state.showExpandView ? 'dropdown-input-border-radius' :
                            (this.state.selectedOptions && this.state.selectedOptions.length) ? 'dropdown-input-border-radius-mulit-select' : ''}`}
                        style={this.props.containerStyle ? this.props.containerStyle : this.props.style ? this.props.style : {}}
                        onClick={() => {
                            this.setState({ showInput: true }, () => { if (this.inputRef) this.inputRef.focus() })
                        }}>
                        {this.state.selectedOptions.map((option, index) =>
                        (<div className="selected-item" key={option.value}>
                            <span className="selected-text">{option[this.props.text]}</span>
                            <span className="selected-delete" onClick={() => this.onRemoveOption(index)}>{"x"}</span>
                        </div>))
                        }
                        {this.props.multiSelect && (this.state.selectedOptions && this.state.selectedOptions.length) && !this.state.showInput ?
                            <></>
                            :
                            <input className={`input
                            ${this.state.selectedText ? 'placeholder-selected' : ''}
                            `}
                                style={this.props.style ? this.props.style : {}}
                                placeholder={this.state.selectedText ? this.state.selectedText :
                                    !(this.props.multiSelect && this.state.selectedOptions && this.state.selectedOptions.length) && this.props.placeholder ?
                                        this.props.placeholder : ''}
                                onFocus={() => this.toggleExpandView(true)}
                                onChange={this.onSearch}
                                value={this.state.searchText}
                                onKeyDown={this.handleKeyDown}
                                onBlur={this.checkValue}
                                ref={(ref) => this.inputRef = ref} />
                        }
                    </div>
                    :
                    <div className={`dropdown-input-mulit-select dropdown-read-only`}>
                        <input className={`input dropdown-read-only`} placeholder={this.getEditModeText()} readOnly></input>
                    </div>
                }
                {/* <input className={`dropdown-input
                 ${this.state.selectedText ? 'placeholder-selected' : ''}
                 ${this.props.invalid ? 'invalid' : ''}
                 ${this.state.showExpandView ? 'dropdown-input-border-radius' : ''}  `}
                    placeholder={this.state.selectedText ? this.state.selectedText : this.props.placeholder ? this.props.placeholder : ''}
                    onFocus={() => this.toggleExpandView(true)}
                    onChange={this.onSearch}
                    value={this.state.searchText}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.checkValue} /> */}

                {
                    !this.props.disabled && this.state.showExpandView ?
                        <div className='dropdown-options dropdown-options-show-border-radius'>

                            <div className='dropdown-options-wrapper'>
                                {this.state.options.map((option, index) => (
                                    <div className={`dropdown-option ${this.state.cursor == index ? 'dropdown-option-active' : ''}`}
                                        onClick={() => this.onClickOption(option)}
                                        key={option.value}
                                        //ref={ref => this.optionsRef[index] = ref}
                                        tabIndex={index}>
                                        {option[this.props.text]}
                                    </div>
                                ))}
                                {
                                    !this.state.options || !this.state.options.length ?
                                        <div className='dropdown-option no-result'>{'No results found.'}</div>
                                        : <></>
                                }
                            </div>
                        </div>
                        : <></>
                }
            </div>);
    }
}

export default Dropdown;
import React from "react";
import './one-step-event-start-screen.scss';
import SharedStaticText from "../../../../shared-storage/static-text-store";
import SharedProfile from "../../../../shared-storage/profile";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { Constants, ImageConstants } from "../../../constants/constants";
import AssessmentApi from "../../../apis/assessment";
import CommonUtils from "../../../utility/common-utilities";
import Loading from "../../common/loading/loading";
import Path from "../../routes/routes-path";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import Parser from "../../common/content-parser/content-parser";
import EventAssessmentTip from "../event-assessment-tip-popup/event-assessment-tip-popup";
import FireBaseEvent from '../../../firebase-events/firebase-events';


export default class ScollarShipStartScreen extends React.Component {
    constructor(props) {
        super(props);

        this.parser = new Parser();

        this.meta = this.props.assessmentStartInfo

        //console.log(this.meta)

        this.staticText = SharedStaticText.getStaticText('scollarshipStartScreen');

        if (this.meta.schollarshipStartScreen) {
            this.staticText = this.meta.schollarshipStartScreen
        }

        this.userInfo = SharedProfile.getProfile();
        this.state.loading = true

        if (this.meta && this.meta.isStarted) {
            this.state.isStarted = true
            this.getMyAssessmentInfo()
        } else {
            this.state.loading = false
            this.state.remaningTime = this.meta && this.meta.remainingTime ? this.meta.remainingTime : 0
            this.getTimeReducer()
        }

        // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()

        // if (!this.userInfo.isSubscribed)
        //     this.landingScreenInstance.pauseUserAppTime()
    }

    state = {
        isStarted: false,
        remaningTime: 0,
        loading: true,
        tip: '',
        list: []
    }

    componentWillUnmount() {

        if (this.timeout)
            clearInterval(this.timeout)
    }

    componentDidMount() {
        let list = []
        list.push({
            tip: `${this.staticText.instructionText}`
        })


        list.push(
            {
                tip: this.parser.parseWithStyles(`${this.staticText.eventInstructionText}`, { p: { fontSize: '17px' }, strong: { fontWeight: "500" } })
            }
        )

        this.setState({ list: list })
    }

    closeModal = () => {

        if (this.landingScreenInstance)
            this.landingScreenInstance.resumeUserAppTime()

        if (this.meta && this.meta.origin && this.meta.origin == 'RewardScreen') {
            this.props.navigation.navigate('RewardEventLandingScreen', { meta: { ...this.meta } })
        } else {
            this.props.navigation.goBack();
        }

    }

    onPressStart = () => {

        if (this.meta && this.meta.type && (this.meta.type == Constants.EVENT_BASELINE || this.meta.type == Constants.REWARD_EVENT_BASELINE || this.meta.type == Constants.TWO_STEP_EVENT_BASELINE)) {
            // moengage.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.meta.eventPayload.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Started', 'Assessment Finishline Status': 'Not Qualified', "Learning Status": `Not Started`, 'Viewed Baseline Report': getBooleanValue(!this.meta.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.meta.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.meta.eventPayload.viewedCertificate) })
            // FireBaseEvent.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.meta.eventPayload.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Started', 'Assessment Finishline Status': 'Not Qualified', "Learning Status": `Not Started`, 'Viewed Baseline Report': getBooleanValue(!this.meta.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.meta.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.meta.eventPayload.viewedCertificate) })
            // Facebook.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.meta.eventPayload.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Started', 'Assessment Finishline Status': 'Not Qualified', "Learning Status": `Not Started`, 'Viewed Baseline Report': getBooleanValue(!this.meta.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.meta.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.meta.eventPayload.viewedCertificate) })
            // BranchIO.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.meta.eventPayload.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Started', 'Assessment Finishline Status': 'Not Qualified', "Learning Status": `Not Started`, 'Viewed Baseline Report': getBooleanValue(!this.meta.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.meta.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.meta.eventPayload.viewedCertificate) })

        } else if (this.meta && this.meta.type && (this.meta.type == Constants.SCHOLARSHIP_EVENT || this.meta.type == Constants.REWARD_EVENT_FINAL || this.meta.type == Constants.TWO_STEP_EVENT_FINAL)) {
            // moengage.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.meta.eventPayload.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Started', "Learning Status": `100% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.meta.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.meta.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.meta.eventPayload.viewedCertificate) })
             FireBaseEvent('Event', { 'User Id': this.userInfo.userId, 
             plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN,
             "Event Name": this.meta.eventPayload.title, 'Explored': 'Yes', 'Assessment Finishline Status': 'Started', 'Viewed Finishline Report': CommonUtils.getBooleanValue(!this.meta.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': CommonUtils.getBooleanValue(!this.meta.eventPayload.viewedCertificate) })
            // Facebook.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.meta.eventPayload.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Started', "Learning Status": `100% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.meta.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.meta.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.meta.eventPayload.viewedCertificate) })
            // BranchIO.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.meta.eventPayload.title, 'Explored': 'Yes', 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Started', "Learning Status": `100% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.meta.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.meta.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.meta.eventPayload.viewedCertificate) })

        }

        if (this.meta && this.meta.type && this.meta.type == Constants.REWARD_EVENT_BASELINE || this.meta.type == Constants.EVENT_BASELINE || this.meta.type == Constants.TWO_STEP_EVENT_BASELINE) {
            this.props.navigation.navigate('AssessmentEngine', {
                meta: {
                    chapterKey: "",
                    chapterTitle: this.meta.title,
                    questionSetId: this.state.questionSetId,
                    categoryThemeId: "1",
                    type: this.meta.type,
                    assessmentId: this.meta.assessmentId,
                    eventId: this.meta.eventId,
                    from: this.meta.type,
                    selectedDuration: this.state.selectedDuration,
                    selectedChapters: [],
                    eventThreshold: this.meta.eventThreshold,
                    scholarshipEndScreenStaticText: this.meta.scholarshipEndScreenStaticText,
                    refresherCourseId: this.meta.refresherCourseId,
                    scholarshipEligibleStaticText: this.meta.scholarshipEligibleStaticText ? this.meta.scholarshipEligibleStaticText : {},
                    viewAnswerTime: this.meta.viewAnswerTime ? this.meta.viewAnswerTime : null,
                    viewAnswerTimeScreenStaticText: this.meta.viewAnswerTimeScreenStaticText ? this.meta.viewAnswerTimeScreenStaticText : null,
                    viewLeaderboardTime: this.meta.viewLeaderboardTime ? this.meta.viewLeaderboardTime : null,
                    eventPayload: this.meta && this.meta.eventPayload ? this.meta.eventPayload : {}
                }
            })
            return
        } else if (this.meta && this.meta.type && (this.meta.type == Constants.REWARD_EVENT_FINAL || this.meta.type == Constants.SCHOLARSHIP_EVENT || this.meta.type == Constants.TWO_STEP_EVENT_FINAL)) {
            this.showMessagePopup()
        }


    }

    showMessagePopup = () => {
        this.setState({ showEventMessagePopup: true })
    }

    hideMessagePopup = (value) => {

        this.setState({ showEventMessagePopup: false }, () => {
            this.props.delegate && this.props.delegate.showAssessmentEngine && this.props.delegate.showAssessmentEngine({
                meta: {
                    chapterKey: "",
                    chapterTitle: this.meta.title,
                    questionSetId: this.state.questionSetId,
                    categoryThemeId: "1",
                    type: this.meta.type,
                    assessmentId: this.meta.assessmentId,
                    eventId: this.meta.eventId,
                    from: this.meta.type,
                    selectedDuration: this.state.selectedDuration,
                    selectedChapters: [],
                    eventThreshold: this.meta.eventThreshold,
                    scholarshipEndScreenStaticText: this.meta.scholarshipEndScreenStaticText,
                    refresherCourseId: this.meta.refresherCourseId,
                    scholarshipEligibleStaticText: this.meta.scholarshipEligibleStaticText ? this.meta.scholarshipEligibleStaticText : {},
                    viewAnswerTime: this.meta.viewAnswerTime ? this.meta.viewAnswerTime : null,
                    viewLeaderboardTime: this.meta.viewLeaderboardTime ? this.meta.viewLeaderboardTime : null,
                    viewAnswerTimeScreenStaticText: this.meta.viewAnswerTimeScreenStaticText ? this.meta.viewAnswerTimeScreenStaticText : null,
                    eventPayload: this.meta && this.meta.eventPayload ? this.meta.eventPayload : {}
                }
            })
        })

    }

    showTipPopup = () => {
        this.setState({ showTipPopup: true })
    }

    closeTipPopup = (value) => {
        if (value) {
            this.setState({ showTipPopup: false }, () => {
                this.onPressStart();
            })
        }
        else {
            this.setState({ showTipPopup: false })
        }

    }

    showTermsAndConditions = () => {

        if (!this.staticText.redirectUrl) {
            return
        }
        window.open(`${this.staticText.redirectUrl}`)
    }

    hideTermsAndConditions = () => {
        this.setState({ showTermsAndConditions: false })
    }

    getTimeReducer = () => {
        this.timeout = setInterval(() => {

            if (this.state.remaningTime == 0) {
                if (this.timeout) {
                    this.setState({ loading: true })
                    this.getMyAssessmentInfo()
                    this.setState({ isStarted: true })
                    clearInterval(this.timeout)
                    return
                }
            }

            this.setState({ remaningTime: this.state.remaningTime - 1 })
        }, 1000)
    }

    getDisplayTime = () => {

        let d = this.state.remaningTime

        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hDisplay = h > 9 ? h : "0" + h
        let mDisplay = m > 9 ? m : "0" + m
        let sDisplay = s > 9 ? s : "0" + s
        return hDisplay + ':' + mDisplay + ':' + sDisplay;
    }

    getMyAssessmentInfo() {

        let payload = {}
        payload.type = this.meta.type == Constants.TWO_STEP_EVENT_BASELINE ? Constants.EVENT_BASELINE : (this.meta.type == Constants.REWARD_EVENT_FINAL || this.meta.type == Constants.TWO_STEP_EVENT_FINAL ? Constants.SCHOLARSHIP_EVENT : this.meta.type)
        payload.eventId = this.meta.eventId

        AssessmentApi.getMyAssessmentInfo(payload).then((res) => {
            //console.log(res)
            if (res && res.eventEnd) {
                this.setState({ loading: false })
                this.props.navigation.goBack()
                return
            }

            if (this.meta.type == Constants.EVENT_BASELINE || this.meta.type == Constants.REWARD_EVENT_BASELINE || this.meta.type == Constants.TWO_STEP_EVENT_BASELINE) {
                // this.state.tip = `<p>You have got only <strong>${Math.round(res.time / 60)}mins</strong> to finish this Assessment. Do not get stuck on one question for long time</p>`
                this.state.tip = this.staticText && this.staticText.baselineAssessmentTip ? CommonUtils.simpleTemplateEngine(this.staticText.baselineAssessmentTip, { time: res.time / 60 }) : ''

            } else {
                // this.state.tip = `<p>Attempt as many Questions as you can in minimum time to rank higher. Faster you finish the test better are chances to rank higher</p>`
                this.state.tip = this.staticText && this.staticText.eventAssessmentTip ? this.staticText.eventAssessmentTip : ''

            }

            this.setState({
                questionCount: res.noOfQuestion,
                questionSetId: res.questionSetId,
                duration: Math.round(res.time / 60),
                totalMarks: res.totalMarks,
                loading: false
            })
        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
                return;
            }
        })
    }

    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goToEventList() {
        this.props.delegate && this.props.delegate.hideAssessmentStartScreen && this.props.delegate.hideAssessmentStartScreen()
    }

    showMore() {
        let viewMoreText = document.getElementById('show-more')
        let viewTableRows = [...document.getElementsByClassName('hide-list')]

        //console.log(viewMoreText.innerHTML)
        if (viewMoreText.innerHTML == "See More") {
            //console.log('1')
            viewMoreText.innerHTML = "See Less"
            viewTableRows.forEach(element => element.style.display = 'list-item')

        } else {
            //console.log('2')

            viewMoreText.innerHTML = "See More"
            viewTableRows.forEach(element => element.style.display = 'none')
        }
    }


    renderBreadcrumbForKnowMore = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToEventList()} >{`Events >`}</span>
                <span className='title-1 pl-2'>{`${this.meta && this.meta?.title}`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }


    getAssessmentDetailCard() {
        return (
            <div className="d-flex justify-content-center align-items-center mt-2">
                <div className="row justify-content-between align-items-center assessment-detail-card pl-2 pr-2">
                    <div className="col d-flex justify-content-center">
                        <div>
                            <div className="text-center">Duration</div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div><img className="clock-image-size mr-2" src={getImageSource(ImageConstants.CLOCK_IMAGE)} /></div>
                                <div className="min-text">{this.state.duration} min</div>
                            </div>
                        </div>
                    </div>
                    <div className="line-wrapper"><img className="vertical-line" src={getImageSource(ImageConstants.VERTICAL_LINE)} /></div>
                    <div className="col-3 d-flex justify-content-center">
                        <div>
                            <div className="text-center">Marks</div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div><img className="clock-image-size mr-2" src={getImageSource(ImageConstants.EXAM_PAD_ICON_BLOCK)} /></div>
                                <div className="min-text">{this.state.totalMarks}</div>
                            </div>
                        </div>

                    </div>
                    <div className="line-wrapper"><img className="vertical-line" src={getImageSource(ImageConstants.VERTICAL_LINE)} /></div>
                    <div className="col d-flex justify-content-center">
                        <div className="">
                            <div className="text-center">Questions</div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div><img className="clock-image-size mr-2" src={getImageSource(ImageConstants.QUESTION_MARK)} /></div>
                                <div className="min-text">{this.state.questionCount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.state.categoryId)

        return (
            <div className="one-step-event-start-screen container-fluid p-0">
                {!this.state.loading ? <div >

                    <div className="text-right">
                        <img className="image-size" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, this.state.categoryId)}></img>
                    </div>
                    <div className="header-for-practice-question">
                        {this.renderBreadcrumbForKnowMore()}
                        {/* <div>
                        <img className="chapter-icon mr-1" src={this.state.selectedChapter.chapterImage ? this.state.selectedChapter.chapterImage : getImageSource(ImageConstants.CHAPTER_ICN)} />
                        <span className="chapter-title">{this.state.selectedChapter.title}</span>
                    </div> */}
                        <div className="sub-header-text">{this.meta && this.meta?.title}</div>
                    </div>
                    <div className="mt-1">
                        <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.state.categoryId)}></img>
                    </div>


                    {this.state.isStarted ?
                        <div>
                            {
                                this.state.questionCount > 0 ? <>
                                    <div className="row mr-0">
                                        <div className="col text-center">
                                            {this.meta.eventPayload && this.meta.eventPayload.headerImage ?
                                                <img className="desktop-image-size" src={this.meta.eventPayload.headerImage} />
                                                :
                                                <img className="desktop-image-size" src={getImageSource(ImageConstants.MALE_WITH_DESKTOP_ICON)} />
                                            }
                                        </div>
                                    </div>
                                    <div className="row mr-0 mt-2">
                                        <div className="col text-center chapter-title">{this.meta && this.meta?.title}</div>
                                    </div>
                                    {this.getAssessmentDetailCard()}
                                    <div className="d-flex flex-column justify-content-center pro-tips mt-3 p-3">
                                        <div><img className="bulb-image-size" src={getImageSource(ImageConstants.BULB_IMAGE)} /> <span className="pro-title">Pro tips:</span></div>

                                        <ul>
                                            {
                                                this.state.list.map((tip, index) => (
                                                    <li className={`tips-text ${index >= 1 ? 'hide-list' : ''}`}>{tip.tip}</li>
                                                ))
                                            }
                                        </ul>

                                        {
                                            this.state.list.length > 1 ?
                                                <>
                                                    {
                                                        this.state.list.map((tip, index) => (
                                                            <div className="cursor-pointer" onClick={() => this.showMore()}>
                                                                {index === 1 ? <div id='show-more'>{`See More`}</div> : ''}
                                                            </div>
                                                        ))
                                                    }
                                                </>

                                                :

                                                ''
                                        }

                                    </div>
                                    {
                                        this.meta &&
                                            (this.meta.type == Constants.SCHOLARSHIP_EVENT ||
                                                this.meta.type == Constants.REWARD_EVENT_FINAL ||
                                                this.meta.type == Constants.TWO_STEP_EVENT_FINAL) ?
                                            <div className="terms-and-condition text-center mt-2">
                                                <span className="agree-text">{this.staticText.agreeText}</span>
                                                <span className="terms-text cursor-pointer" onClick={this.showTermsAndConditions}>{this.staticText.termsAndConditionsText}</span>

                                            </div> : <></>
                                    }
                                    <div className="d-flex justify-content-center">
                                        <div className="text-center back-button cursor-pointer mr-5"
                                            style={{ color: this.theme ? `${this.theme[`themeColor`]}` : '', border: `2px solid ${this.theme[`themeColor`]}` }}
                                            onClick={() => this.goToEventList()}>Back</div>

                                        <div className="text-center continue-button cursor-pointer"
                                            style={{ backgroundColor: this.theme ? `${this.theme[`themeColor`]}` : '' }}
                                            onClick={this.onPressStart}>
                                            Start
                                        </div>
                                    </div>


                                </>
                                    :

                                    <div className="mt-5">
                                        <div className="text-center">
                                            <img className="no-event-found-image" src={getImageSource(ImageConstants.NO_CLASSWORK_IMAGE)}></img>
                                        </div>
                                        <div className="no-event-text text-center">{this.staticText.noAssessmentsText}</div>
                                    </div>
                            }

                        </div> : <></>}

                </div>

                    : <Loading />
                }

                {/* {this.state.showTipPopup && <AssessmentTip modal={true} close={this.closeTipPopup} delegate={this} categoryThemeId={this.state.categoryId} tip={this.state.tip} />}
                {this.state.showTermsAndConditions && <TermsAndConditions delegate={this} showModal={this.state.showTermsAndConditions} />} */}
                {this.state.showEventMessagePopup && <EventAssessmentTip modal={true} close={this.hideMessagePopup} delegate={this} categoryThemeId={this.state.categoryId} staticText={this.staticText} />}

            </div>

        )
    }
}
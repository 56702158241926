import React from "react";
import './questions-pagination.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";

class QuestionPagination extends React.Component {


    constructor(props) {
        super(props);
        this.scroll = React.createRef()
    }


    pagesRef = {}

    clickPage = (i) => {
        this.props.onClickPage && this.props.onClickPage(i)
    }

    state = {
        showDisabled: false
    }

    getItems() {

        // //console.log(this.props.activePage)

        let elements = []
        for (let i = 0; i < this.props.questions.length; i++) {
            let question = this.props.questions[i]
            elements.push(
                // <div key={i + 'page'} style={Styles.pageWrapper} onLayout={(event) => {
                //     let { x, y, width, height } = event.nativeEvent.layout;
                //     this.pagesRef[i] = { x, y }
                // }}>
                //     <View style={this.props.activePage == i + 1 ? Styles.activePageDot : {}}><View style={question.isFlagged ? Styles.dot : (question.answer ? Styles.answeredDot : Styles.notAnsweredDot)}
                //     ></View></View>

                //     <View style={Styles.nonFlaggedContent}>{this.props.activePage == i + 1 ?
                //         <View style={Styles.activePage}><Text style={Styles.activePageText}>{i + 1}</Text></View>
                //         : <TouchableOpacity onPress={() => { this.onClickPage(question, i) }} style={Styles.pageNumberWrapper} hitSlop={Styles.hitSlop1}><Text style={Styles.paginationText}>{i + 1}</Text></TouchableOpacity>}
                //     </View>
                // </div>

                <div className={``} ref={ref => { this.pagesRef[i] = ref }}  >
                    <div onClick={() => { this.clickPage(i) }} className={`question-number cursor-pointer d-flex align-items-center justify-content-center text-center
                    ${question.status == 'CORRECT' ? 'correct-circle' : ''}
                    ${question.status == 'IN_CORRECT' ? 'incorrect-circle' : ''}`}>
                        <div className="" >
                            {question.index + 1}
                        </div>
                    </div>
                </div>
            )
        }

        return elements;
    }

    setWidth = (width) => {
        this.setState({ scrollViewWidth: width });
    }

    isCloseToEnd = (e) => {
        //console.log(Math.ceil(e.target.scrollLeft))
        //console.log(e.target.clientWidth)
        //console.log(e.target.scrollWidth)


        return e.target.scrollWidth - Math.ceil(e.target.scrollLeft) === e.target.clientWidth
    }

    goToEndOfPagination(e) {
        //console.log(this.scroll)
        this.scroll.current.scrollLeft = this.scroll.current.scrollWidth

    }

    goToStartOfPagination() {
        this.scroll.current.scrollLeft = 0
        this.setState({ showDisabled: false })
    }

    scrollTo = () => {
        if (this.pagesRef && this.props.activePage && this.pagesRef[this.props.activePage - 1]) {
            this.scroll.scrollTo({ x: this.pagesRef[this.props.activePage - 1].x, animated: true })
        }
    }



    render() {
        return (
            <div className="question-pagination d-flex flex-row justify-content-center align-items-center" >
                {/* <div className="position-absolute header-image-wrapper"><img className="header-bg-image-size" src={getImageSource(ImageConstants.HEADER_BACKGROUND)} ></img></div> */}
                {!this.state.showDisabled ?
                    // <div className="position-relative mr-5">
                    //     <img className="cursor-pointer arrow-size" src={getImageSource(ImageConstants.LEFT_ARROW_DISABLED)} />
                    // </div>
                    <div className="position-relative mr-5">
                        <img className="cursor-pointer arrow-size" onClick={() => this.goToStartOfPagination()} src={getImageSource(ImageConstants.LEFT_ARROW_ENABLED)} />
                    </div>
                    :
                    <div className="position-relative mr-5">
                        <img className="cursor-pointer arrow-size" onClick={() => this.goToStartOfPagination()} src={getImageSource(ImageConstants.LEFT_ARROW_ENABLED)} />
                    </div>
                }
                <div className="d-flex position-relative flex-row pagination-wrapper align-items-center" ref={this.scroll}
                    onScroll={(e) => {
                        if (this.isCloseToEnd(e)) {
                            this.setState({ showDisabled: true })
                        }
                        else {
                            this.setState({ showDisabled: false })
                        }
                    }}
                >
                    {this.getItems()}
                </div>

                {!this.state.showDisabled ? <div className="position-relative ml-3">
                    <img className="cursor-pointer arrow-size" onClick={(e) => this.goToEndOfPagination(e)} src={getImageSource(ImageConstants.RIGHT_ARROW_ENABLED)} />
                </div>
                    :
                    <div className="position-relative ml-3">
                        <img className="cursor-pointer arrow-size" src={getImageSource(ImageConstants.RIGHT_ARROW_DISABLED)} />
                    </div>
                }
            </div>
        )
    }


}

export default QuestionPagination;
import React from 'react';
import './practice-questions.scss';
import CwContentViewer from '../../common/cw-content-viewer/cw-content-viewer';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import { ImageConstants } from '../../../constants/constants';
import QuestionImage from '../../common/cw-question-image/cw-question-image';
import PracticeQuestionModal from './practice-question-popup/practice-question-popup';
import SharedThemeCategory from '../../../../shared-storage/category-theme';
import CommonUtils from '../../../utility/common-utilities';
import RadioGroup from "../../common/radio/radio-group";
import CWImageV2 from "../../common/cw-image-2/cw-image-2";
import { Constants } from "../../../constants/constants";
import Parser from "../../common/content-parser/content-parser";
import Checkbox from "../../common/check-box/check-box";
import ActionSheet from "../../common/action-sheet/action-sheet";
import Grouping from './grouping/grouping';
import HomeworkApi from '../../../apis/homework';
import CwConfirmationModal from './cw-confirmation-modal/cw-confirmation-modal';
import { Modal, ModalBody } from 'reactstrap';
import ImagePreview from '../../common/image-preview/image-preview';
// import TestContent from './practice-question-popup/test-popup-content';



class PracticeQuestions extends React.Component {

    state = {
        showQuestionPopup: false,


        data: [],
        imagePreview : false
    }


    //For Sequencing

    isValidOptions = false;

    textInput = [];
    dragType = "";
    dragInfo = {};

    //For Matching
    dropZone = []; // To hold droppable Zones on load
    dropZoneMeasure = []; //Presist droppable Zones on reaload

    dragOverInfo = { index: -1 }

    scrollViewRef;
    scrollViewMeasure = {};

    options = [];
    suffledOptions = [];
    hashedOriginalOptions = {};
    selectedItem = {};
    hashedSuffleOptions = {};
    dragItemScrollTouchXY = null

    isValid = false;

    dx = 0;
    dy = 0;

    constructor(props) {
        super(props)
        this.parser = new Parser();
        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        //For Sequencing
        this.shuffleOptions = [...this.props.content.content.options];
        this.state = {
            data: this.props.isReadOnly ? this.props.content.content.options : CommonUtils.shuffleArray(this.shuffleOptions),
            content: this.props.content,

            //For Matching
            dropIndex: -1,
            draggedIndex: -1,
            options: [],
            occupiedDropZone: [],
            droppedItemsIndex: [],
            dragabbleItemsScroll: true,
            selectedItem: null,
            pan: { x: 0, y: 0 },
            scrollViewHeight: 0,
            activateResponder: false,
            touchMessage: null,
            sourceX: null,
            sourceY: null,
            sourcePreviewVisible: false,
            showAnswerStatus: false,

            //For Grouping
            answerStatus: "NOT_ANSWERED"

        }

        //For Matching
        this.options = this.props.content.content.options;
        this.suffledOptionsForMatching = CommonUtils.shuffleArray(JSON.parse(JSON.stringify(this.props.content.content.options)));
        this.props.content.content.options.map((item, index) => { this.hashedOriginalOptions[item.id] = index });
        this.suffledOptionsForMatching.map((item, index) => { this.hashedSuffleOptions[item.id] = index });


    }

    componentDidMount() {
        //console.log(this.props)
        // this.setSta : true})
    }

    viewAnswer = (item, index) => {

        this.showQuestionPopup(this.props.content, this.props.index, true, false)
        this.props.getSelectedIndex && this.props.getSelectedIndex(this.props.index)
    }

    viewSolution = (item, index) => {




        this.setState({ showAnswerStatus: false }, () => {
            this.showQuestionPopup(this.props.content, this.props.index, false, true)
            this.props.getSelectedIndex && this.props.getSelectedIndex(this.props.index)
        })
    }

    hideAnswerStatusPopup() {
        this.setState({ showAnswerStatus: false })
    }

    showAnswerStatusPopup() {

        const element = document.getElementById(this.props.content._id);
        // //console.log(id, element)
        element.scrollIntoView({ behavior: "instant", block: 'center' })

        let answerPayload = {}

        if (this.state.content.content.type === 1) {
            answerPayload = {
                id: this.state.content._id,
                answer: this.getAnswerForSingleSelect()
            }
        } else if (this.state.content.content.type === 2) {
            answerPayload = {
                id: this.state.content._id,
                answer: this.getAnswerForSingleSelect()
            }
        } else if (this.state.content.content.type === 3) {
            answerPayload = {
                id: this.state.content._id,
                answer: this.getAnswerForFillInTheBlank()
            }
        } else if (this.state.content.content.type === 4) {
            answerPayload = {
                id: this.state.content._id,
                answer: this.getAnswerForMatching()
            }
        } else if (this.state.content.content.type === 5) {
            answerPayload = {
                id: this.state.content._id,
                answer: this.getAnswerForSequencing()
            }
        } else if (this.state.content.content.type === 6) {
            answerPayload = {
                id: this.state.content._id,
                answer: this.groupingComponent.getAnswer()
            }
        }

        this.next(answerPayload)

        if (this.state.content.content.isMultiStep) {
            this.viewSolution()
        } else {
            this.setState({ showAnswerStatus: true })
        }
    }

    getAnswerForSequencing = () => {

        let data = this.state.data.map((option, index) => ({ id: option.id }));

        if (data)
            return data;
        else
            return null;
    }


    showQuestionPopup = (item, index, isAnswer, isSolution) => {

        // //console.log(item, index)

        if (item.knowledgeEloId) {
            this.getLearningContent(item.knowledgeEloId, (learningElos) => {
                this.setState({
                    showQuestionPopup: true,
                    currentQuestion: item, index: index, viewAnswer: isAnswer,
                    viewSolution: isSolution, learningElos
                })
            })
        } else {
            this.setState({
                showQuestionPopup: true,
                currentQuestion: item, index: index, viewAnswer: isAnswer,
                viewSolution: isSolution
            })
        }
    }

    hideQuestionPopup = () => {
        this.isValid = false;

        this.setState({ showQuestionPopup: false, index: '', answerStatus: "NOT_ANSWERED" }, () => {
            this.props.getSelectedIndex && this.props.getSelectedIndex(this.state.index)
        })
    }

    getLearningContent(elos, callback) {

        let payload = {
            ids: elos,
            chapterKey: this.props.chapterKey
        }

        HomeworkApi.getReenforcementLearningELODetails(payload).then((eloDetails) => {

            //console.log(eloDetails)

            let data = eloDetails;

            if (data) {
                let decodedHtmlText = CommonUtils.decodeHTMLEntities(JSON.stringify(data));


                data = JSON.parse(decodedHtmlText);
            }
            callback(data)

            // this.hideSuccessFailureScreen();
        }).catch(err => {
            if (err) {
                //alert("error");
                //console.log(err)
                this.setState({ disableNext: false })
                return;
            }
        })

    }


    onClickBookMarkActive = () => {
        this.props.delegate && this.props.delegate.onClickBookMarkActive && this.props.delegate.onClickBookMarkActive(!this.props.isBookMark ? this.props.content.bookMarkId : this.props.bookMarkId, this.props.index);
    }

    onClickBookMark = () => {

        let content = this.props.content;

        let contentObj = { id: content._id, objectType: 4, contentType: 1 }
        this.props.delegate && this.props.delegate.onClickBookMark && this.props.delegate.onClickBookMark(contentObj, this.props.index);
    }


    getContentTypeOptionsForMultiStep(content, questionNo) {
        //console.log(content)

        let isMultiStep = content.content.isMultiStep ? content.content.isMultiStep : false

        //console.log(content.content)

        if (isMultiStep) {
            return content.content.steps.map((content, index) => (
                <>
                    <div className='row mt-4 mb-4 align-items-center ml-2'>
                        <div className='col-1 part-title' style={{ backgroundColor: this.theme[`themeColor`] }}>
                            {questionNo + 1} {CommonUtils.getAlphabet(index)}
                        </div>
                        <div className='col part-question'>
                            <CwContentViewer content={content.content.title} />
                        </div>
                    </div>
                    {content.content.images && content.content.images.length ? content.content.images.map((image, imageIndex) =>
                        <img className='image-container mb-4 mr-3' src={image.imageUrl} alt="" />
                    )
                        :
                        ""}
                    {this.renderContentType(content.content.type, content)}
                    <img className="question-divider-image-width mt-4 mb-4" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)}></img>
                </>
            ))
        }
    }


    onOptionPressedForRadioButton = () => {
        this.isValid = true;
    }

    getAnswerForSingleSelect = () => {

        if (this.isValid) {
            let payload = [];
            let options = this.state.content.content.options;
            for (let i = 0; i < options.length; i++) {
                if (options[i].isChecked)
                    payload.push({ id: options[i].id })
            }
            return payload;
        } else
            return null;
    }

    next(answerPayload) {
        this.answer = answerPayload


        this.question = this.state.content
        this.validateAnswer(this.question, this.answer, (err, res) => {

            if (err) {
                //console.log(err)
                this.setState({ disableNext: false })
                return;
            }
            this.setState({ disableNext: false })

            //console.log(res)

            if (res.data.isCorrect) {
                // this.ShowCorrectIncorrectModel(true);
                //console.log('correct Anwer')
                this.setState({ answerStatus: "CORRECT_ANSWER" })
            }
            else {
                this.setState({ answerStatus: "INCORRECT_ANSWER" })
                //console.log('Wrong Anwer')
                // this.ShowCorrectIncorrectModel(false);
            }
        });
    }

    validateAnswer = (question, answer, callback) => {

        let payload = { question, answer };

        HomeworkApi.validateAnswer(payload).then((res) => {
            callback(null, res);
        }).catch(err => {
            callback(err, null);
        })
    }


    openPreview = (previewUrl) => {
        this.setState({ imagePreview: true, previewUrl: previewUrl })
    }

    closePreview = () => {
        this.setState({ imagePreview: false });
    }


    renderContentType(contentType, content) {
        //console.log(this.props.content)
        let textColor = this.theme['themeColor']
        let borderColor = this.theme[`themeColor`]
        let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }


        // let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        let linearGradientBg = `linear-gradient(${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][0]}, ${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][1]})`

        // let content = this.props.content
        switch (contentType) {
            case 1:
                return (
                    <div className="options margin-for-options">
                        <RadioGroup options={content.content.options} onOptionPressed={this.onOptionPressedForRadioButton} categoryThemeId={this.props.categoryThemeId} />
                    </div>
                )

            case 2:
                return (
                    <div className="option-wrapper d-flex flex-column mt-3 justify-content-between margin-for-options">
                        {content.content.options && content.content.options.map((option, index) => (
                            <div className={`option-border ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "d-flex justify-content-between" : this.props.isReadOnly ? "d-flex align-items-center" : ""}`}
                                style={{ borderColor: this.theme ? `${this.theme['secondaryColors']['assessmentOptionsBorder']}` : '#353535' }}
                                key={option.id}>
                                <Checkbox label={option.content}
                                    isChecked={this.getCheckedStatus(option)}
                                    onOptionPressed={() => this.onOptionPressed(option)} contentType={option.contentType ? option.contentType : 1} categoryThemeId={this.props.categoryThemeId} />
                                {option.contentType === Constants.CONTENT_TYPE_IMAGE &&
                                    <div className="ml-5">
                                        <img className="expand-icon cursor-pointer" onClick={() => this.openPreview(option.content)} src={getImageSource(ImageConstants.EXPAND)} />
                                    </div>
                                }
                            </div>
                        ))}
                        {this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>}
                    </div>
                )

            case 3:
                return (
                    <div className="">

                        {content.content.options && content.content.options.length && content.content.options.map((option, index) => (
                            <React.Fragment key={'fillInTheBlank' + index}>
                                {option.contentType == 3 ?
                                    this.getJsxForBlank(option, index)
                                    : this.getJsxForBlankTypeDropDown(option, index)
                                }
                            </React.Fragment>
                        ))}
                    </div>
                )

            // case 4:
            //     return (
            //         <div className="matching-question-container mt-4">
            //             {content.content.options.map((option, index) =>
            //                 <div className="matching-question d-flex flex-row align-items-center mt-3" key={option.id + 'answer'}>

            //                     <div className="question-card d-flex align-items-center justify-content-center" style={{ borderColor: dragItemBorder }}>
            //                         {
            //                             option["question"].contentType == Constants.CONTENT_TYPE_TEXT ?
            //                                 <div className="">{this.getContentUsingContentType(option, 'question')}</div>
            //                                 :
            //                                 <div className="">{this.getContentUsingContentType(option, 'question')}</div>
            //                         }

            //                     </div>
            //                     <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
            //                     <div className={`question-card d-flex align-items-center justify-content-center position-relative`} style={{ borderColor: dragItemBorder }}>
            //                         {
            //                             this.renderReadOnlyItem(option)
            //                         }
            //                     </div>
            //                 </div>
            //             )}
            //         </div>
            //     )

            case 5:
                return (
                    <div className="options margin-for-options">
                        {this.state.data.map((item, index) => (
                            <>
                                {this.renderItem(item, index)}
                            </>
                        ))}
                    </div>
                )

            // case 6:
            //     return (
            //         <div className="grouping-question-type-read-only">
            //             <div className="matching-question-container d-flex flex-column mt-4">
            //                 {content.content.options.map((option, index) => (
            //                     <div key={"dropZone" + index} className="drop-zone mt-3" style={{ backgroundImage: linearGradientBg, borderColor: dragItemBorder }}>
            //                         <div className="text-center p-1 drop-zone-title" style={{ background: dragItemBorder }}>
            //                             {this.parser.parseWithStyles(`<p>${option.title}</p>`, { p: styles.p, 'cw-math': styles.p })}
            //                         </div>
            //                         <div className="d-flex flex-row answer-wrapper justify-content-center">
            //                             {
            //                                 this.renderReadOnlyItemForGrouping(option)
            //                             }
            //                         </div>
            //                     </div>
            //                 ))}
            //             </div>
            //         </div>

            //     )

        }
    }



    getCheckedStatus = (option) => {

        return option.isChecked ? true : false

    }

    onOptionPressed(option) {
        //console.log(option)

        //Added for View Correct Answer
        if (this.props.isReadOnly) {
            return;
        }

        option.isChecked = !option.isChecked;
        this.forceUpdate();

        let options = this.state.content.content.options.filter(opt => opt.isChecked);
        // // this.props.delegate && this.props.delegate.onAnswer(this.props.content._id, options, this.props.content.content.type);
        if (options.length) {
            this.isValid = true;
            // this.props.delegate && this.props.delegate.enableNavigation && this.props.delegate.enableNavigation();
        } else {
            this.isValid = false;
            //console.log(this.isValid)
            // this.props.delegate && this.props.delegate.disableNavigation && this.props.delegate.disableNavigation();
        }

    }

    getBlankOptionValueDropDown(content) {

        let styles = {
            p: { fontSize: '23px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (content && content.items && content.items.length) {
            let correctItem = content.items.filter((item) => item.isCorrect);
            let correctAnswer = correctItem[0].content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");
            if (correctItem[0].content) {
                return this.parser.parseWithStyles(`${correctAnswer}`, { p: styles.p, "cw-math": styles.p })
            } else {
                return <></>
            }
        }
        return <></>
    }

    getJsxForBlankTypeDropDown(option, index) {

        //console.log(option, index, this.state.selectedDropDownIndex, this.props.isReadOnly)

        //console.log(this.state.actionSheet)

        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let assessmentBorder = this.theme[`secondaryColors`][`assessmentOptionsBorder`]
        let backgroundColor = this.theme[`themeColor`]
        //console.log(this.theme[`secondaryColors`][`assessmentOptionsBorder`])

        return (
            <div key={option.id} ref={this.dropdownRef} className="position-relative margin-for-options" style={{}}>

                {this.state.selectedDropDownIndex === index ?
                    <>
                        <div className="row mb-5 mt-2 mr-0 ml-0" style={{}}>

                            <div className="blank-wrapper padding-for-dropdown" style={{ border: `1px solid ${assessmentBorder}` }}>
                                <div className="dropdown-input">
                                    <div className="d-inline-block alphabet-option ml-2 mr-2">
                                        <div className="circle p-1" style={{ backgroundColor: backgroundColor }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                    </div>
                                    <img className="option-separator-2" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />
                                    <div className="d-inline-block ml-2" style={{}}>
                                        {
                                            option.selectedText ?
                                                this.parser.parseWithStyles(`${option.selectedText.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
                                                : <span className="cw-input-for-dropdown" style={{}}>
                                                    {"Select One"}</span>
                                        }
                                    </div>

                                    <div className="float-right mt-2" style={{}}>
                                        <img className="dropdown-arrow-size" src={getImageSource(ImageConstants.DROPDOWN_ARROW_BLACK)} />
                                    </div>
                                </div>

                            </div>


                            {/* </LinearGradient> */}
                        </div>
                        {this.state.actionSheet && <ActionSheet modal={this.state.actionSheet} toggleActionSheet={this.toggleActionSheet} options={this.state.dropDownData} onSelect={this.onSelect} categoryThemeId={this.props.categoryThemeId}></ActionSheet>}

                    </>


                    :
                    // <LinearGradient colors={Colors.appTheme.gradient1} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1.2 }} style={styles.linearGradiendCard}>
                    <div className="row  mb-5 mt-2 mr-0 ml-0" style={{}} >

                        <div className="blank-wrapper padding-for-dropdown" style={{ border: `1px solid ${assessmentBorder}` }} onClick={() => { this.toggleActionSheet(true, option.items, index) }}>
                            <div className="dropdown-input">
                                <div className="d-inline-block alphabet-option ml-2 mr-2">
                                    <div className="circle p-1" style={{ backgroundColor: backgroundColor }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                </div>
                                <img className="option-separator-2" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />
                                {
                                    !this.props.isReadOnly ?
                                        <div className="d-inline-block ml-2" style={{}}>
                                            {
                                                option.selectedText ?
                                                    this.parser.parseWithStyles(`${option.selectedText.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
                                                    : <span className="cw-input-for-dropdown" style={{}}>
                                                        {"Select One"}</span>
                                            }
                                        </div>
                                        :
                                        <div className="d-inline-block ml-2" style={{}}>
                                            {this.getBlankOptionValueDropDown(option)}
                                        </div>
                                }

                                <div className="float-right mt-2" style={{}}>
                                    <img className="dropdown-arrow-size" src={getImageSource(ImageConstants.DROPDOWN_ARROW_BLACK)} />
                                </div>
                            </div>

                        </div>


                        {/* </LinearGradient> */}
                    </div>
                }

            </div>

        )
    }

    getBlankOptionValue = (content) => {

        let styles = {
            p: { fontSize: '23px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (content && content.items && content.items.length) {
            let parsedJsx = this.parser.parseWithStyles(`${content.items[0].content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
            return (
                <div className="d-inline-block ml-2">
                    {parsedJsx}
                </div>
            )
        }
        return <></>
    }
    onChangeMathFeild = (option, event) => {
        if (option.contentDataType === 1) {
            option.content = event.target.value.replace(/[^0-9-,.]/g, '');
        }
        else {
            option.content = event.target.value;
        }
        this.forceUpdate();
        this.validateAndNavigate();
    }

    validateAndNavigate = () => {
        let isValid = this.validateOptions();
        if (isValid) {
            this.isValidOptions = true;
            // this.props.delegate && this.props.delegate.enableNavigation && this.props.delegate.enableNavigation();
        } else {
            this.isValidOptions = false;
            // this.props.delegate && this.props.delegate.disableNavigation && this.props.delegate.disableNavigation();
        }
    }

    validateOptions() {

        let noOfOptions = this.state.content.content.options.length;

        let noOfDropDownsSelected = 0;
        let noOfValidBlanks = 0;

        for (let i = 0; i < noOfOptions; i++) {

            if (this.state.content.content.options[i].contentType == Constants.CONTENT_TYPE_BLANK && this.state.content.content.options[i].content)
                noOfValidBlanks = noOfValidBlanks + 1;

            else if (this.state.content.content.options[i].contentType == Constants.CONTENT_TYPE_DROP_DOWN) {
                let option = this.state.content.content.options[i].items.filter(opt => opt.isSelected);
                if (option.length)
                    noOfDropDownsSelected = noOfDropDownsSelected + 1;
            }
        }
        if (noOfOptions == (noOfDropDownsSelected + noOfValidBlanks))
            return true;
        else
            return false;
    }

    getAnswerForFillInTheBlank = () => {

        if (this.isValidOptions) {
            let payload = [];

            let options = this.state.content.content.options;

            for (let i = 0; i < options.length; i++) {

                if (options[i].contentType == Constants.CONTENT_TYPE_BLANK) {
                    if (options[i].content)
                        payload.push({ id: options[i].id, content: options[i].content ? options[i].content.trim() : '' });
                }
                else {
                    let option = options[i].items.filter(opt => opt.isSelected);
                    if (option.length)
                        payload.push({ id: options[i].id, selected: [{ id: option[0].id }] });
                }
            }
            return payload;
        } else
            return null;
    }

    getJsxForBlank(option, index) {

        let assessmentBorder = this.theme[`secondaryColors`][`assessmentOptionsBorder`]
        let backgroundColor = this.theme[`themeColor`]
        return (
            <div className="margin-for-options">

                <div >
                    {/* <LinearGradient colors={Colors.appTheme.gradient1} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1.2 }} style={styles.blank}> */}

                    <div className="row  mb-5 mt-2 mr-0 ml-0">
                        <div className="blank-wrapper d-flex align-items-center " style={{ border: `1px solid ${assessmentBorder}` }}>
                            <div className="blank-option">
                                <div className="d-inline-block alphabet-option ml-2 mr-2">
                                    <div className="circle p-1" style={{ backgroundColor: backgroundColor }}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                </div>

                                <img className="option-separator" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />

                                {
                                    !this.props.isReadOnly ?
                                        <input className="cursor-pointer"
                                            ref={(input) => { this.textInput[index] = input }}
                                            style={{}}
                                            onChange={(value) => this.onChangeMathFeild(option, value)}
                                            value={option.content}
                                            // multiline={true}
                                            maxLength={150}
                                            placeholder='Type Answer Here'
                                        // editable={this.props.isReadOnly ? false : true}
                                        />
                                        : this.getBlankOptionValue(option)

                                }

                            </div>

                            {/* <TouchableOpacity onPress={() => this.textInput[index].focus()} style={styles.keyboard} >
                                <Image source={getThemeImageSource(ImageConstants.ASSESSMENT_KEYBOARD, this.props.categoryThemeId)} />
                            </TouchableOpacity> */}
                        </div>
                        {/* </LinearGradient> */}
                    </div>
                </div>
            </div >


        )
    }

    toggleActionSheet = (value, dropDownData, index) => {
        if (this.props.isReadOnly) {
            return;
        }
        this.setState({ actionSheet: value, dropDownData: dropDownData ? dropDownData : [], selectedDropDownIndex: index });
    }

    onSelect = (option, index) => {

        this.state.content.content.options[this.state.selectedDropDownIndex].selectedText = option.content;

        this.state.content.content.options[this.state.selectedDropDownIndex].items.map(opt => opt.isSelected = false);
        this.state.content.content.options[this.state.selectedDropDownIndex].items[index].isSelected = true;
        this.toggleActionSheet(false);
        this.validateAndNavigate();
    }

    renderItem(item, index) {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]



        return (
            <div className={`mt-2 mr-0 ml-0 ${this.state.data.length - 1 == index ? "mb-0" : "mb-3"}`} >
                <div className="sequence-wrapper padding-for-dropdown" style={{ border: `1.5px solid ${borderColor}` }}
                    onDragOver={(e) => this.onDragOver(e, { index: index, option: item }, "OPTION")}
                    onDragStart={e => this.onDragStart(e, { index: index, option: item }, "OPTION")}
                    onDragEnd={this.onDragEnd}
                    onTouchStart={e => this.onDragStart(e, { index: index, option: item }, "OPTION")}
                    onTouchMove={(e) => this.onDragOver(e, { index: index, option: item }, "")}
                    onTouchEnd={this.onDragEnd}
                    draggable={true}
                    key={item.id}
                    id={item.id}>
                    <div id={index} className="dropdown-input cursor-pointer">
                        <div className="d-inline-block alphabet-option mr-2">
                            <div className="circle p-1"><img id={index} className="drag-image" src={getThemeImageSource(ImageConstants.DRAG_ICON, this.props.categoryThemeId)} /></div>
                        </div>
                        <img id={index} className="option-separator" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />

                        <div className="d-inline-block ml-2" style={{}}>
                            {
                                this.getContent(item)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onDragStart = (event, dragInfo, type) => {
        // event.stopPropagation();
        if (event.type === 'touchstart') {
            event.preventDefault()
            this.setState({ sourcePreviewVisible: true });
        }
        this.dragType = type;
        this.dragInfo = dragInfo;

    };

    onDragOver = (event, dragOverInfo, type) => {

        // event.stopPropagation();
        event.preventDefault()

        if (event.type === 'touchmove') {
            // Find the target element based on the touch event
            const targetElement = document.elementFromPoint(
                event.changedTouches[0].clientX,
                event.changedTouches[0].clientY
            );

            //console.log(targetElement)

            // //console.log('drag over', targetElement.attributes[0].textContent)

            let dragOverIndex = { index: -1 }
            let index = targetElement.attributes[0] ? +targetElement.attributes[0].textContent : 1
            //console.log(index)

            let option = this.state.data.filter((data, i) => {
                if (i === index) {
                    return data
                }
            })

            //console.log(option)

            if (!isNaN(index)) {
                dragOverIndex = { index: index, option: option[0] }
            }

            this.dragOverInfo = dragOverIndex

            // Access touch coordinates
            const touchX = event.touches[0].clientX;
            const touchY = event.touches[0].clientY;

            // Update the source preview's position
            this.setState({
                sourceX: touchX,
                sourceY: touchY,
            });

        } else if (this.dragType == type && this.dragType == "OPTION") {

            this.dragOverInfo = dragOverInfo;

            this.setState({ dragOverQuestionContentId: this.dragOverInfo.option.id });
            // //console.log("CONCEPT onDragOver", dragOverInfo);

        }

    };

    onDragEnd = (event) => {

        // //console.log("end reached", this.dragType, this.dragInfo, this.dragOverInfo)

        event.preventDefault()
        this.setState({ sourcePreviewVisible: false });

        if (this.dragType == "OPTION") {

            if (this.dragInfo && this.dragOverInfo) {

                if (this.dragInfo.option.id === this.dragOverInfo.option.id) {
                    this.resetOrderingDetails();
                    return;
                }

                this.reOrder()
            }
        }

    };

    reOrder = () => {

        let dragType = this.dragType;
        let dragInfo = this.dragInfo;
        let dragOverInfo = this.dragOverInfo;

        let allOptions = this.state.data

        if (dragInfo.index != dragOverInfo.index) {

            let allOptionsForReOrder = this.insertDragedQuestions(allOptions, allOptions[dragInfo.index], allOptions[dragOverInfo.index], dragInfo.index, dragOverInfo.index)
            this.resetOrderingDetails()

            this.setState({ data: allOptionsForReOrder })
        }


    }


    insertDragedQuestions(allQuestions, drapItem, dropItem, dragIndex, dragOverIndex) {

        //console.log(dragIndex, dragOverIndex)

        var allQuestionsCopy = allQuestions.slice();

        allQuestionsCopy.splice(dragIndex, 1)

        if (dragOverIndex === 0) {
            allQuestionsCopy.splice(0, 0, drapItem)
        } else if (dragIndex > dragOverIndex) {
            allQuestionsCopy.splice(dragOverIndex, 0, drapItem)
        } else if (dragIndex < dragOverIndex) {
            if (dragOverIndex - 1 === dragIndex) {
                allQuestionsCopy.splice(dragOverIndex, 0, drapItem)
            } else {
                allQuestionsCopy.splice(dragOverIndex, 0, drapItem)
            }
        }



        // allQuestionsCopy[allQuestions.indexOf(drapItem)] = dropItem;
        // allQuestionsCopy[allQuestions.indexOf(dropItem)] = drapItem;

        return allQuestionsCopy;

    }

    resetOrderingDetails = () => {

        this.dragInfo = null;
        this.dragType = "";
        this.draggedItem = null;
        this.draggedOverItem = null;
        this.setState({ dragOverQuestionContentId: null });
    }





    getContent(item) {


        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let content = item.content.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");

        content = content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");


        if (item.contentType == Constants.CONTENT_TYPE_TEXT) {
            return (
                this.parser.parseWithStyles(content.includes("<p>") ? `${content}` : `<p>${content}</p>`, { p: styles.p, 'cw-math': styles.p })
            )
        } else if (item.contentType == Constants.CONTENT_TYPE_IMAGE) {
            return (
                <CWImageV2 source={{ uri: item.content }} ></CWImageV2>
            )
        }




    }


    getOptionItem(index, option) {

        let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]

        //console.log(this.state.droppedItemsIndex, index)

        return (
            !(this.state.droppedItemsIndex.includes(index)) &&
            <div className="answer-card cursor-pointer" style={{ border: `2px solid ${dragItemBorder}`, backgroundColor: bgColor }}
                onDragStart={e => this.onDragStartForMatching(e, { index: index, item: option }, "ANSWER")}
                onDragEnd={this.onDragEndForMatching}
                onTouchStart={e => this.onDragStartForMatching(e, { index: index, item: option }, "ANSWER")}
                onTouchMove={(e) => this.onDragOverForMatching(e, { index: index, item: option }, "")}
                onTouchEnd={this.dropItemForMatching}
                draggable={true}
            >
                <div style={{}} className="align-self-start mb-2"><img className="drag-image" src={getThemeImageSource(ImageConstants.DRAG_ICON, this.props.categoryThemeId)} /></div>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                </div>
            </div>
        )
    }

    renderDropedItem(index) {
        if (this.state.occupiedDropZone[index]) {
            let option = this.options[this.state.occupiedDropZone[index].index];
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <div className="close-icon-wrapper">
                        <img className="close-icon"
                            onClick={() => this.onDroppedItemRemove(this.state.occupiedDropZone[index].index, index, option.id)}
                            src={getThemeImageSource(ImageConstants.THEME_CLOSE_ICON, this.props.categoryThemeId)} />
                    </div>
                    <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                </div>
            )
        }
    }

    getContentUsingContentType(option, type) {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (option[type].contentType == Constants.CONTENT_TYPE_TEXT) {
            return this.parser.parseWithStyles(`${option[type].content}`, { p: styles.p, 'cw-math': styles.p })
        } else if (option[type].contentType == Constants.CONTENT_TYPE_IMAGE) {
            //console.log(option[type].content)
            return <CWImageV2 uri={option[type].content}></CWImageV2>
        }

    }


    onDragStartForMatching = (event, dragInfo, type) => {


        if (event.type === 'touchstart') {
            event.preventDefault()
            this.setState({ sourcePreviewVisible: true });
        }

        this.dragType = type;
        this.dragInfo = dragInfo;


    };



    onDragOverForMatching = (event, dragOverInfo, type) => {


        event.preventDefault()


        if (event.type === 'touchmove') {

            // Find the target element based on the touch event
            const targetElement = document.elementFromPoint(
                event.changedTouches[0].clientX,
                event.changedTouches[0].clientY
            );

            // //console.log('target Element', targetElement)

            let dragOverIndex = { index: -1 }
            let index = +targetElement?.attributes[0]?.textContent
            if (!isNaN(index)) {
                dragOverIndex = { index: index }
            }

            this.dragOverInfo = dragOverIndex

            // Access touch coordinates
            const touchX = event.touches[0].clientX;
            const touchY = event.touches[0].clientY;

            // Update the source preview's position
            this.setState({
                sourceX: touchX,
                sourceY: touchY,
            });



        } else if (this.dragType == type && this.dragType == "ANSWER") {

            this.dragOverInfo = dragOverInfo;

            this.setState({ dragOverQuestionContentId: this.dragOverInfo.item.id });
            // //console.log("CONCEPT onDragOver", dragOverInfo);

        }

    };


    onDragEndForMatching = (event) => {

        // //console.log("end reached", this.dragType, this.dragInfo, this.dragOverInfo)
        // if (this.dragType == "ANSWER") {

        //     if (this.dragInfo) {

        //         // if (this.dragOverInfo.index != -1) {
        //             // this.setSelectedAnswer(index, dropIndex);
        //             this.setSelectedAnswer(this.hashedOriginalOptions[this.dragInfo.item.id], this.dragOverInfo.index, this.dragInfo.index)
        //         // }

        //         //console.log(this.dragInfo, this.dragOverInfo)

        //     }
        // }

    };

    dropItemForMatching = (event) => {

        // if(event.type === 'touchend') {
        event.preventDefault()
        this.setState({ sourcePreviewVisible: false });
        // }


        if (this.dragType == "ANSWER") {

            if (this.dragInfo) {

                if (this.dragOverInfo.index != -1) {
                    // this.setSelectedAnswer(index, dropIndex);
                    this.setSelectedAnswer(this.hashedOriginalOptions[this.dragInfo.item.id], this.dragOverInfo.index, this.dragInfo.index)
                }

                //console.log(this.dragInfo, this.dragOverInfo)

            }
        }
    }

    setSelectedAnswer(draggedIndex, dropIndex, dragSuffleIndex) {
        this.setState({
            draggedIndex: draggedIndex, dropIndex: dropIndex
        });

        let occupiedDropZone = [...this.state.occupiedDropZone];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        occupiedDropZone[dropIndex] = { index: draggedIndex, item: this.options[draggedIndex] };
        droppedItemsIndex.push(dragSuffleIndex);

        this.setState({
            occupiedDropZone: occupiedDropZone,
            droppedItemsIndex: droppedItemsIndex,
            dragabbleItemsScroll: true
        })

        // //console.log("draggedIndex, dropIndex", draggedIndex, dropIndex);
        this.validateAndDelegate(occupiedDropZone);
        this.dragInfo = {}
        this.dragOverInfo = { index: -1 }
    }

    onDroppedItemRemove = (itemIndex, dropIndex, optionId) => {
        let occupiedDropZone = [...this.state.occupiedDropZone];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        //console.log(droppedItemsIndex)

        occupiedDropZone[dropIndex] = null;
        // droppedItemsIndex = droppedItemsIndex.filter((item) => item != itemIndex);
        droppedItemsIndex = droppedItemsIndex.filter((item) => item != this.hashedSuffleOptions[optionId]);
        //console.log(droppedItemsIndex)

        this.setState({
            occupiedDropZone: occupiedDropZone,
            droppedItemsIndex: droppedItemsIndex
        })

        // this.validateAndDelegate(occupiedDropZone);
    }


    renderMatchingOptions() {

        let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]
        return (
            <>
                {
                    <div className="d-flex flex-row  answer-card-container mt-4 margin-for-options">
                        {
                            this.suffledOptionsForMatching.map((option, index) => this.getOptionItem(index, option))
                        }
                    </div>
                }
                <img className="question-divider-image-width mt-4" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img>
                {
                    <div className={`matching-question-container mt-4 margin-for-options`}>
                        <div className="row question-option-wrapper mr-0">
                            <div className="col">
                                {this.options.map((option, index) => (
                                    <div className="matching-question d-flex flex-row align-items-center mt-3" key={option.id + 'answer'}>

                                        {
                                            this.options.length <= 4 ?
                                                index <= 1 ?
                                                    <>
                                                        <div className={`question-card d-flex align-items-center justify-content-center
                                                            ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                            <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                        </div>
                                                        <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                        <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                    ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                            onDragOver={(e) => this.onDragOverForMatching(e, { index: index, item: option }, "ANSWER")}
                                                            onDrop={this.dropItemForMatching}
                                                            style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                        >
                                                            {
                                                                this.renderDropedItem(index)
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                :
                                                index <= 2 ?
                                                    <>
                                                        <div className={`question-card d-flex align-items-center justify-content-center
                                                            ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                            <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                        </div>
                                                        <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                        <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                    ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                            onDragOver={(e) => this.onDragOverForMatching(e, { index: index, item: option }, "ANSWER")}

                                                            onDrop={this.dropItemForMatching}
                                                            style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                        >
                                                            {
                                                                this.renderDropedItem(index)
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                        }


                                    </div>
                                ))
                                }
                            </div>
                            {/* <img className="option-divider" src={getImageSource(ImageConstants.MATCHING_QUESTION_DIVIDER)} /> */}
                            <div className="col ml-1">
                                {this.options.map((option, index) => (
                                    <div className={`matching-question d-flex flex-row align-items-center 
                                        ${index < 2 && option[`question`].contentType == Constants.CONTENT_TYPE_IMAGE ? 'mt-0' : 'mt-1'}
                                        ${this.options.length <= 4 && index > 2 ? 'mt-3' : ''} ${this.options.length <= 4 && index == 2 ? 'mt-0' : ''}
                                        ${!this.options.length <= 4 && index > 3 ? 'mt-3' : ''} ${!this.options.length <= 4 && index == 3 ? 'mt-0' : ''}`}
                                        key={option.id + 'answer'}>

                                        {
                                            this.options.length <= 4 ?
                                                index > 1 ?
                                                    <>
                                                        <div className={`question-card d-flex align-items-center justify-content-center
                                                            ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                            <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                        </div>
                                                        <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                        <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                                    ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                            onDragOver={(e) => this.onDragOverForMatching(e, { index: index, item: option }, "ANSWER")}

                                                            onDrop={this.dropItemForMatching}
                                                            style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                        >
                                                            {
                                                                this.renderDropedItem(index)
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                :
                                                index > 2 ?
                                                    <>
                                                        <div className={`question-card d-flex align-items-center justify-content-center
                                                            ${option[`question`].contentType != Constants.CONTENT_TYPE_IMAGE ? 'width-for-text-box' : ''}`} style={{ border: `2px solid ${dragItemBorder}` }}>

                                                            <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                        </div>
                                                        <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                        <div id={index} className={`question-card d-flex align-items-center justify-content-center position-relative
                                                            ${!this.state.occupiedDropZone[index] ? "question-card-dashed" : ''}`}
                                                            onDragOver={(e) => this.onDragOverForMatching(e, { index: index, item: option }, "ANSWER")}

                                                            onDrop={this.dropItemForMatching}
                                                            style={{ border: this.state.occupiedDropZone[index] ? `2px solid ${dragItemBorder}` : '' }}
                                                        >
                                                            {
                                                                this.renderDropedItem(index)
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                        }


                                    </div>
                                ))
                                }
                            </div>
                        </div>

                    </div>
                }
            </>
        )
    }

    validateAndDelegate(occupiedDropZone) {

        let total = 0;

        for (let i = 0; i < occupiedDropZone.length; i++) {
            if (occupiedDropZone[i])
                total = total + 1;
        }

        if (this.props.content.content.options.length == total) {
            this.isValid = true;
            // this.props.delegate && this.props.delegate.enableNavigation && this.props.delegate.enableNavigation();
        } else {
            this.isValid = false;
            // this.props.delegate && this.props.delegate.disableNavigation && this.props.delegate.disableNavigation();
        }
    }

    getAnswerForMatching = () => {

        let occupiedDropZone = this.state.occupiedDropZone;

        if (this.isValid) {
            let payload = [];
            for (let i = 0; i < occupiedDropZone.length; i++) {
                payload.push({ id: this.options[i].id, selected: [{ id: occupiedDropZone[i].item.id }] });
            }
            return payload;

        } else
            return null;
    }

    // showViewSolutionModal() {
    //     return (
    //         <Modal className="cw-custom-modal-right-for-solution" isOpen={true} toggle={this.hideQuestionList} size={'lg'}>
    //             <ModalBody >
    //                 <PracticeQuestionModal
    //                     content={this.state.currentQuestion}
    //                     index={this.state.index}
    //                     isAnswer={this.state.viewAnswer}
    //                     isSolution={this.state.viewSolution}
    //                     hideQuestionPopup={() => this.hideQuestionPopup()}
    //                     categoryThemeId={this.props.categoryThemeId}
    //                     elo={this.state.learningElos}

    //                 />

    //             </ModalBody>
    //         </Modal>
    //     )
    // }




    render() {

        this.theme = SharedThemeCategory.getThemeCategory(1)

        //console.log(this.props.content)

        let lgButton = `linear-gradient(${this.theme[`linearGradient`][`binaryCard`][0]}, ${this.theme[`linearGradient`][`binaryCard`][1]})`
        let buttonBorder = CommonUtils.getThemeBasedButtonBorder(this.theme[`secondaryColors`][`assessmentOptionsBorder`])

        return (
            <div className='chapter-m2-practice-questions '>
                {/* <div style={{ backgroundColor: `${this.state.index === this.props.index ? this.theme['learningContentBackground'] : ''}` }} className={`d-flex justify-content-between ${this.state.index === this.props.index ? "title-width-on-selection" : ''}`}> */}
                <div id={this.props.content._id} style={{}} className={`d-flex justify-content-between
                 ${this.state.index === this.props.index ? "title-width-on-selection" : ''}`}>
                    <div className={`d-inline-flex content-width ${this.state.index === this.props.index ? "title-bold-selection" : ''}`}>
                        <span style={{ backgroundColor: `${this.theme['themeColor']}` }}
                            className={`mr-2 sr-no 
                        ${this.props.index >= 9 ? "sr-no-padding" : ''}
                        ${this.props.index === 0 ? "sr-no-padding-for-1" : ''}`}>
                            {`${this.props.index + 1}`}
                        </span>
                        <CwContentViewer content={this.props.content.content.title}></CwContentViewer>
                    </div>
                    {/* {this.props.showBookMark && <div className='mr-5 cursor-pointer' onClick={()=>this.onClickBookMarkActive()}><img className='bookmark' src={getImageSource(ImageConstants.BOOKMARK_ACTIVE)}></img></div>}
                    {this.props.showInactiveBookmark && <div className='mr-5 cursor-pointer' onClick={()=>this.onClickBookMark()}><img className='bookmark' src={getImageSource(ImageConstants.BOOKMARK_ICON)}></img></div>}
                     */}
                </div>

                {this.props.content.content.images && this.props.content.content.images.length ?
                    <div className='mb-3 question-title-image mt-2'>
                        <QuestionImage images={this.props.content.content.images} index={this.props.index} />
                    </div>
                    :
                    ''
                }
                <img className="question-divider-image-width mt-4 mb-4" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img>

                {
                    this.props.content.content.type === 4 ?
                        <>
                            {
                                this.renderMatchingOptions()
                            }

                        </>
                        :
                        <></>
                }

                {
                    this.props.content.content.type === 6 ?
                        <>
                            {
                                <div className='margin-for-options'>
                                    <Grouping content={this.props.content} ref={Ref => this.groupingComponent = Ref} />
                                </div>

                            }

                        </>
                        :
                        <></>
                }




                {
                    this.props.content.content.isMultiStep ?
                        // this.getContentTypeOptionsForMultiStep(this.props.content, this.props.index)
                        <></>
                        : this.renderContentType(this.props.content.content.type, this.props.content)
                }

                <div className={`justify-content-start align-items-center button-wrapper p-3 ${this.state.index === this.props.index ? "d-none" : "d-flex"}`}>
                    {/* <div className='view-button view-answer text-center ' style={{ backgroundImage: lgButton, border: buttonBorder }} onClick={() => this.viewAnswer(this.props.content.content, this.props.index)}>View Answer</div> */}
                    {/* <div className='view-button text-center' style={{ backgroundImage: lgButton, border: buttonBorder }} */}
                    <div className='view-button text-center' style={{ backgroundColor: this.theme['themeColor'] }}
                        onClick={() => this.showAnswerStatusPopup()}>View Solution</div>
                </div>
                {
                    this.state.showAnswerStatus ?
                        <CwConfirmationModal hideAnswerStatusPopup={() => this.hideAnswerStatusPopup()}
                            onOk={() => this.viewSolution()}
                            answerStatus={this.state.answerStatus} />
                        :
                        <></>
                }

                {
                    this.state.showQuestionPopup ?
                        <PracticeQuestionModal
                            content={this.state.currentQuestion}
                            index={this.state.index}
                            isAnswer={this.state.viewAnswer}
                            isSolution={this.state.viewSolution}
                            hideQuestionPopup={() => this.hideQuestionPopup()}
                            categoryThemeId={this.props.categoryThemeId}
                            elo={this.state.learningElos}

                        />
                        // this.showViewSolutionModal()
                        :
                        ''
                }




            </div>
        )
    }


}

export default PracticeQuestions;
import { getImageSource } from '../home/utility/image-source'
import { ImageConstants } from '../home/constants/constants'
import PracticeMoreQuestions from '../home/components/practice-more-questions/practice-more-questions';
import MyAssessments from '../home/components/my-assessments/my-assessments';
import ClassSpecificMyAssessments from '../home/components/class-specific-flow/my-assessments/my-assessments';

// import Students from '../home/components/students/students'

import Path from '../home/components/routes/routes-path';
import MyClasswork from '../home/components/my-classwork-V1/my-classwork';
import MyHomework from '../home/components/my-homework/my-classwork';
import EventList from '../home/components/events/event-list';
import MyProfile from '../home/components/my-profile/my-profile';
import MyCertificate from '../home/components/my-certificate/my-certificate-list';
import WorkflowExperience from '../home/components/workflow/workflow-experience';
import DiagnosticAssessment from '../home/components/diagnostic-assessment/diagnostic-assessment';
import VideoList from '../home/components/video-library/video-list';
import EloList from '../home/components/all-elo/elo-list';
import ChaptersList from '../home/components/chapter-list-v1/chapter-list';
import VideoCheckpointLearning from '../home/components/video-checkpoint-learning/video-checkpoint-learning';
import ChangePasscode from '../home/components/change-passcode/passcode-update-screen';
import ClassMockTestPerformanceReport from '../home/components/class-mock-test-performance-report/class-mock-test-performance-report';
import { Constants } from '../home/constants/constants';
import ClassSpecificPractice from '../home/components/class-specific-flow/pratice/practice-more-questions/practice-more-questions'
import ClassDiagnosticAssessment from '../home/components/class-diagnostic-assessment/my-assessments';
import ExamPrepKit from '../home/components/exam-prep-kit/exam-prep-kit/exam-prep-kit';
import ClassDiagnosticAssessmentList from '../home/components/class-diagnostic-assessment/class-diagnostic-assessment-list/class-diagnostic-assessment-list';
import ClickerFlow from '../home/components/clicker-flow/assessment';
import ClickerFlowPractice from '../home/components/clicker-flow-practice/clicker-flow-practice';
import ClickerTesting from '../home/components/clicker-testing/clicker-testing';
import ClickerSchools from '../home/components/clicker-schools/clicker-schools';
import ClickerQuiz from '../home/components/clicker-quiz/clicker-quiz';
// import WorksheetsList from '../home/components/worksheets-list/chapter-list/chapter-list';
// import SessionCache from '../home/components/routes/session-cache';
// import Performace from '../home/components/performance/performance'
// import Users from '../home/components/users/users'
// import Events from '../home/components/eventsV1/events'
// import QuestionBank from '../home/components/question-bank/question-bank'
// import LearningResources from '../home/components/learning-resources/learning-resources'


let routesObj = {

    // HOME_COMPONENT: {
    //     moduleName: "MY_CLASSWORK",
    //     title: 'My Classworks',
    //     subTitle: '',
    //     path: Path.HOME,
    //     activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
    //     inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
    //     component: MyClasswork,
    //     order: 1,
    //     disabled: false
    // },


    MY_CLASSWORK: {
        moduleName: "MY_CLASSWORK",
        title: 'My Assignments',
        subTitle: '',
        path: Path.MY_CLASSWORK,
        activeIcon: getImageSource(ImageConstants.ASSESSMENTS_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.ASSESSMENTS_INACTIVE),
        component: MyClasswork,
        order: 1,
        disabled: false,
        notShowInSideBar: false
    },

    MY_HOMEWORK: {
        moduleName: "MY_HOMEWORK",
        title: 'My Homework',
        subTitle: '',
        path: Path.MY_HOMEWORK,
        activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
        inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
        component: MyHomework,
        order: 1,
        disabled: false,
        notShowInSideBar: true
    },

    // MY_ASSESSMENTS: {
    //     moduleName: "MY_ASSESSMENTS",
    //     title: 'My Assessments',
    //     subTitle: '',
    //     path: Path.MY_ASSESSMENTS,
    //     activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
    //     inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
    //     component: MyAssessments,
    //     order: 1,
    //     disabled: false
    // },
    PRACTICE_QUESTIONS: {
        moduleName: "PRACTICE_QUESTIONS",
        title: 'Practice Questions',
        subTitle: '',
        path: Path.PRACTICE_QUESTIONS,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: PracticeMoreQuestions,
        order: 2,
        disabled: false
    },

    MY_PROFILE: {
        moduleName: "MY_PROFILE",
        title: 'My Profile',
        subTitle: '',
        path: Path.MY_PROFILE,
        activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
        inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
        component: MyProfile,
        order: 1,
        disabled: false,
        notShowInSideBar: true
    },

    LEARNING_CONTENT_WRAPPER: {
        moduleName: "LEARNING_CONTENT",
        title: 'Learning Content',
        subTitle: '',
        path: Path.LEARNING_CONTENT,
        activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
        inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
        component: WorkflowExperience,
        order: 1,
        disabled: false,
        notShowInSideBar: true
    },

    REFRESHER_COURSE: {
        moduleName: "REFRESHER_COURSE",
        title: 'Refresher Course',
        subTitle: '',
        path: Path.REFRESHER_COURSE,
        activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
        inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
        component: DiagnosticAssessment,
        order: 1,
        disabled: false,
        notShowInSideBar: true
    },

    EVENT_LIST: {
        moduleName: "EVENT_LIST",
        title: 'Event / Competition',
        subTitle: '',
        path: Path.EVENT_LIST,
        activeIcon: getImageSource(ImageConstants.EVENTS_MENU_ACTIVE_ICON),
        inActiveIcon: getImageSource(ImageConstants.EVENTS_MENU_IN_ACTIVE_ICON),
        component: EventList,
        order: 3,
        disabled: false,
        notShowInSideBar: false
    },


    // MY_CERTIFICATE: {
    //     moduleName: "MY_CERTIFICATE",
    //     title: 'My Certificates',
    //     subTitle: '',
    //     path: Path.MY_CERTIFICATE,
    //     activeIcon: getImageSource(ImageConstants.CERTIFICATE_ACTIVE_ICON),
    //     inActiveIcon: getImageSource(ImageConstants.CERTIFICATE_IN_ACTIVE_ICON),
    //     component: MyCertificate,
    //     order: 4,
    //     disabled: false
    // },

    // EXPECTED_LEARNING_OUTCOME: {
    //     moduleName: "EXPECTED_LEARNING_OUTCOME",
    //     title: 'ELO',
    //     subTitle: '',
    //     path: Path.ALL_ELOS,
    //     activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE_ICON),
    //     inActiveIcon: getImageSource(ImageConstants.PRACTICE_IN_ACTIVE_ICON),
    //     component: EloList,
    //     order: 4,
    //     disabled: false
    // },

    VIDEO_LIBRARY: {
        moduleName: "VIDEO_LIBRARY",
        title: 'Video Library',
        subTitle: '',
        path: Path.VIDEO_LIBRARY,
        activeIcon: getImageSource(ImageConstants.VIDEO_LIBRARY_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.VIDEO_LIBRARY_INACTIVE),
        component: VideoList,
        order: 4,
        disabled: false
    },

    // VIDEO_CHECKPOINT_LEARNING: {
    //     moduleName: "VIDEO_CHECKPOINT_LEARNING",
    //     title: 'Video Learning',
    //     subTitle: '',
    //     path: Path.VIDEO_CHECKPOINT_LEARNING,
    //     activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE_ICON),
    //     inActiveIcon: getImageSource(ImageConstants.PRACTICE_IN_ACTIVE_ICON),
    //     component: VideoCheckpointLearning,
    //     order: 4,
    //     disabled: false
    // },

    BROWSE_CHAPTER: {
        moduleName: "BROWSE_CHAPTER",
        title: 'Browse Chapter',
        subTitle: '',
        path: Path.BROWSE_CHAPTER,
        activeIcon: getImageSource(ImageConstants.BROWSE_CHAPTER_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.BROWSE_CHAPTER_INACTIVE),
        component: ChaptersList,
        order: 5,
        disabled: false
    },

    // WORKSHEET_LIST: {
    //     moduleName: "WORKSHEET_LIST",
    //     title: 'Worksheets',
    //     subTitle: '',
    //     path: Path.WORKSHEET_LIST,
    //     activeIcon: getImageSource(ImageConstants.WORKSHEET_ACTIVE),
    //     inActiveIcon: getImageSource(ImageConstants.WORKSHEET_INACTIVE),
    //     component: WorksheetsList,
    //     order: 5,
    //     disabled: false
    // },

    // QUIZ: {
    //     moduleName: "QUIZ",
    //     title: 'Quiz',
    //     subTitle: '',
    //     path: Path.CLICKER_FLOW_QUIZ,
    //     activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
    //     inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
    //     component: ClickerQuiz,
    //     order: 10,
    //     disabled: false,
    //     // subMenus: []
    // },

    CLICKER_TESTING: {
        moduleName: "CLICKER_TESTING",
        title: 'Clicker Testing',
        subTitle: '',
        path: Path.CLICKER_TESTING,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: ClickerTesting,
        order: 11,
        disabled: false,
        notShowInSideBar: true,
        // parent: "QUIZ"
    },

    CLICKER_FLOW_TESTING_FFF: {
        moduleName: "CLICKER_FLOW_TESTING_FFF",
        title: 'Testing - FFF',
        subTitle: '',
        path: Path.CLICKER_FLOW_TESTING_FFF,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: ClickerFlow,
        order: 11,
        disabled: false,
        notShowInSideBar: true,
        // parent: "QUIZ"
    },

    CLICKER_FLOW_FFF: {
        moduleName: "CLICKER_FLOW_FFF",
        title: 'FFF',
        subTitle: '',
        path: Path.CLICKER_FLOW_FFF,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: ClickerFlow,
        order: 11,
        disabled: false,
        notShowInSideBar: true,
        // parent: "QUIZ"
    },
    CLICKER_FLOW_FFF_ONE: {
        moduleName: "CLICKER_FLOW_FFF_ONE",
        title: 'FFF_ONE',
        subTitle: '',
        path: Path.CLICKER_FLOW_FFF_ONE,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: ClickerFlow,
        order: 11,
        disabled: false,
        notShowInSideBar: true,
        // parent: "QUIZ"
    },
    CLICKER_FLOW_FFF_TWO: {
        moduleName: "CLICKER_FLOW_FFF_TWO",
        title: 'FFF_TWO',
        subTitle: '',
        path: Path.CLICKER_FLOW_FFF_TWO,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: ClickerFlow,
        order: 11,
        disabled: false,
        notShowInSideBar: true,
        // parent: "QUIZ"
    },

    CLICKER_SCHOOLS: {
        moduleName: "CLICKER_SCHOOLS",
        title: 'Clicker Schools',
        subTitle: '',
        path: Path.CLICKER_SCHOOLS,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: ClickerSchools,
        order: 11,
        disabled: false,
        notShowInSideBar: true,
        // parent: "QUIZ"
    },

    CLICKER_FLOW: {
        moduleName: "CLICKER_FLOW",
        title: 'Jigyasa Assessment',
        subTitle: '',
        path: Path.CLICKER_FLOW,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: ClickerFlow,
        order: 11,
        disabled: false,
        // notShowInSideBar: false,
        // parent: "QUIZ"
    },

    CLICKER_FLOW_PRACTICE: {
        moduleName: "CLICKER_FLOW_PRACTICE",
        title: 'Clicker Flow - Practice',
        subTitle: '',
        path: Path.CLICKER_FLOW_PRACTICE,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: ClickerFlowPractice,
        order: 11,
        disabled: false,
        notShowInSideBar: false
    },

    CHANGE_PASSCODE: {
        moduleName: "CHANGE_PASSCODE",
        title: 'Change Passcode',
        subTitle: '',
        path: Path.CHANGE_PASSCODE,
        activeIcon: getImageSource(ImageConstants.ACTIVE_LOCK_ICON),
        inActiveIcon: getImageSource(ImageConstants.INACTIVE_LOCK_ICON),
        component: ChangePasscode,
        order: 6,
        disabled: false
    },



    // CLASS_MOCK_TEST: {
    //     moduleName: "CLASS_MOCK_TEST",
    //     title: 'Class Mock Test',
    //     subTitle: '',
    //     path: Path.CLASS_MOCK_TEST,
    //     activeIcon: getImageSource(ImageConstants.ACTIVE_LOCK_ICON),
    //     inActiveIcon: getImageSource(ImageConstants.INACTIVE_LOCK_ICON),
    //     component: ClassMockTestPerformanceReport,
    //     order: 6,
    //     disabled: false
    // },


    // CHAPTERS: {
    //     moduleName: "CHAPTERS",
    //     title: 'Chapters',
    //     subTitle: '',
    //     path: '/chapters',
    //     activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
    //     inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
    //     component: PracticeMoreQuestions,
    //     order: 3,
    //     disabled: false
    // },
    // EVENTS: {
    //     moduleName: "EVENTS",
    //     title: 'Events',
    //     subTitle: '',
    //     path: Path.EVENTS,
    //     activeIcon: null,
    //     inActiveIcon: null,
    //     component: Events,
    //     order: 2,
    //     disabled: false
    // },
    // SETUP: {
    //     moduleName: "SETUP",
    //     title: 'Homework',
    //     subTitle: '',
    //     path: Path.CLASSES,
    //     component: PracticeMoreQuestions,
    //     activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
    //     inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
    //     order: 3,
    //     disabled: false,
    //     subMenus: []
    // },
    // USERS: {
    //     moduleName: "USERS",
    //     title: 'Homework List',
    //     subTitle: '',
    //     path: Path.USERS,
    //     // activeIcon: getImageSource(ImageConstants.ADMIN_USER_ACTIVE),
    //     // inActiveIcon: getImageSource(ImageConstants.ADMIN_USER),
    //     component: PracticeMoreQuestions,
    //     order: 3,
    //     disabled: false,
    //     parent: "SETUP"
    // },
    // CLASSES: {
    //     moduleName: "CLASSES",
    //     title: 'Classes',
    //     subTitle: '',
    //     path: Path.CLASSES,
    //     //activeIcon: getImageSource(ImageConstants.CLASSES_ACTIVE),
    //     // inActiveIcon: getImageSource(ImageConstants.CLASSES),
    //     component: Classes,
    //     order: 1,
    //     disabled: false,
    //     parent: "SETUP"
    // },
    // STUDENTS: {
    //     moduleName: "STUDENTS",
    //     title: 'Students',
    //     subTitle: '',
    //     path: Path.STUDENTS,
    //     // activeIcon: getImageSource(ImageConstants.ADMIN_USER_ACTIVE),
    //     // inActiveIcon: getImageSource(ImageConstants.ADMIN_USER),
    //     component: Students,
    //     order: 3,
    //     disabled: true,
    //     parent: "SETUP"
    // },
    // ASSIGN: {
    //     moduleName: "ASSIGN",
    //     title: 'Assign',
    //     subTitle: '',
    //     path: Path.STANDARD_HOMEWORK,
    //     component: Homework,
    //     order: 7,
    //     disabled: false,
    //     subMenus: []
    // },
    // // QUICK_VIEW: {
    // //     moduleName: "QUICK_VIEW",
    // //     title: 'Quick View',
    // //     subTitle: '',
    // //     path: Path.QUICK_VIEW,
    // //     component: Homework,
    // //     order: 1,
    // //     disabled: false,
    // //     parent: "ASSIGN"
    // // },
    // TEST: {
    //     moduleName: "TEST",
    //     title: 'Assessment',
    //     subTitle: '',
    //     path: Path.TEST,
    //     // activeIcon: getImageSource(ImageConstants.TEST_ACTIVE),
    //     // inActiveIcon: getImageSource(ImageConstants.TEST_INACTIVE),
    //     component: Tests,
    //     order: 2,
    //     disabled: false,
    //     parent: "ASSIGN"
    // },
    // STANDARD_HOMEWORK: {
    //     moduleName: "STANDARD_HOMEWORK",
    //     title: 'Homework',
    //     subTitle: '',
    //     path: Path.STANDARD_HOMEWORK,
    //     component: Homework,
    //     order: 3,
    //     disabled: false,
    //     parent: "ASSIGN"
    // },
    // PERSONALISED_HOMEWORK: {
    //     moduleName: "PERSONALISED_HOMEWORK",
    //     title: 'Personalised Homework',
    //     subTitle: '',
    //     path: Path.PERSONALISED_HOMEWORK,
    //     component: Homework,
    //     order: 4,
    //     disabled: false,
    //     parent: "ASSIGN"
    // },
    // PROFILE: {
    //     moduleName: "PROFILE",
    //     title: 'Profile',
    //     subTitle: '',
    //     path: Path.PROFILE,
    //     // activeIcon: getImageSource(ImageConstants.PROFILE_ACTIVE),
    //     // inActiveIcon: getImageSource(ImageConstants.SETTINGS),
    //     component: Profile,
    //     order: 100, //always to be last
    //     disabled: false
    // },

    // ASSESSMENT: {
    //     title: 'Mock Test',
    //     subTitle: '',
    //     path: Path.ASSESSMENT,
    //     activeIcon: getImageSource(ImageConstants.TEST_ACTIVE),
    //     inActiveIcon: getImageSource(ImageConstants.TEST_INACTIVE),
    //     component: MyAssessments,
    //     order: 8,
    //     disabled: true
    // },


    // QUESTION_BANK: {
    //     moduleName: "QUESTION_BANK",
    //     title: 'My Question Bank',
    //     subTitle: '',
    //     path: Path.QUESTION_BANK,
    //     // activeIcon: getImageSource(ImageConstants.ADMIN_USER_ACTIVE),
    //     // inActiveIcon: getImageSource(ImageConstants.ADMIN_USER),
    //     component: QuestionBank,
    //     order: 12,
    //     disabled: false
    // },

    // LEARNING_RESOURCES: {
    //     moduleName: "LEARNING_RESOURCES",
    //     title: 'Learning Resources',
    //     subTitle: '',
    //     path: Path.LEARNING_RESOURCES,
    //     // activeIcon: getImageSource(ImageConstants.ADMIN_USER_ACTIVE),
    //     // inActiveIcon: getImageSource(ImageConstants.ADMIN_USER),
    //     component: LearningResources,
    //     order: 11,
    //     disabled: false
    // },
}

let classSpecificRoutesObj = {
    // CLASS_SPECIFIC_PRACTICE: {
    //     moduleName: "CLASS_SPECIFIC_PRACTICE",
    //     title: 'Practice',
    //     subTitle: '',
    //     path: Path.CLASS_SPECIFIC_PRACTICE,
    //     activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
    //     inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
    //     component: ClassSpecificPractice,
    //     order: 2,
    //     disabled: false
    // },
    CLASS_SPECIFIC_ASSESSMENT: {
        moduleName: "CLASS_SPECIFIC_ASSESSMENT",
        title: 'Exam Mock Test',
        subTitle: '',
        path: Path.CLASS_SPECIFIC_ASSESSMENT,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: ClassSpecificMyAssessments,
        order: 8,
        disabled: false
    },
    CLASS_DIAGNOSTIC_ASSESSMENT: {
        moduleName: "CLASS_DIAGNOSTIC_ASSESSMENT",
        title: 'Diagnostic Assessment',
        subTitle: '',
        path: Path.CLASS_DIAGNOSTIC_ASSESSMENT,
        activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
        inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
        component: ClassDiagnosticAssessment,
        order: 1,
        disabled: false,
        notShowInSideBar: true
    },

    DIAGNOSTIC_ASSESSMENT_LIST: {
        moduleName: "DIAGNOSTIC_ASSESSMENT_LIST",
        title: 'Free Diagnostic Test',
        subTitle: '',
        path: Path.DIAGNOSTIC_ASSESSMENT_LIST,
        activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
        component: ClassDiagnosticAssessmentList,
        order: 11,
        disabled: false,
        notShowInSideBar: false
    },



    EXAM_PREP_KIT: {
        moduleName: "EXAM_PREP_KIT",
        title: 'Prepare',
        subTitle: '',
        path: Path.EXAM_PREP_KIT,
        activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
        inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
        component: ExamPrepKit,
        order: 1,
        disabled: false,
        notShowInSideBar: true
    },
    BROWSE_CHAPTER: {
        moduleName: "BROWSE_CHAPTER",
        title: 'Browse Chapter',
        subTitle: '',
        path: Path.BROWSE_CHAPTER,
        activeIcon: getImageSource(ImageConstants.BROWSE_CHAPTER_ACTIVE),
        inActiveIcon: getImageSource(ImageConstants.BROWSE_CHAPTER_INACTIVE),
        component: ChaptersList,
        order: 5,
        disabled: false,
        notShowInSideBar: true
    },
    MY_PROFILE: {
        moduleName: "MY_PROFILE",
        title: 'My Profile',
        subTitle: '',
        path: Path.MY_PROFILE,
        activeIcon: getImageSource(ImageConstants.ACTIVE_ROUTE_IMAGE),
        inActiveIcon: getImageSource(ImageConstants.SIDE_MENU_LIST_IMAGE),
        component: MyProfile,
        order: 1,
        disabled: false,
        notShowInSideBar: true
    },
    // CLICKER_FLOW: {
    //     moduleName: "CLICKER_FLOW",
    //     title: 'Clicker Flow',
    //     subTitle: '',
    //     path: Path.CLICKER_FLOW,
    //     activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
    //     inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
    //     component: ClickerFlow,
    //     order: 11,
    //     disabled: false,
    //     notShowInSideBar: false
    // },
    // CLICKER_FLOW_PRACTICE: {
    //     moduleName: "CLICKER_FLOW_PRACTICE",
    //     title: 'Clicker Flow - Practice',
    //     subTitle: '',
    //     path: Path.CLICKER_FLOW_PRACTICE,
    //     activeIcon: getImageSource(ImageConstants.PRACTICE_ACTIVE),
    //     inActiveIcon: getImageSource(ImageConstants.PRACTICE_INACTIVE),
    //     component: ClickerFlowPractice,
    //     order: 11,
    //     disabled: false,
    //     notShowInSideBar: false
    // }

}








function sortArray(a, b) {
    if (a.order < b.order) {
        return -1;
    }
    if (a.order > b.order) {
        return 1;
    }
    return 0;
}


function getRoutes(user) {
    let routes = [], pushedModules = {}

    routesObj = Constants.CLASS_SPECIFIC_CONFIGURATION.includes(user.class) ? classSpecificRoutesObj : routesObj
    let routesObjKeys = Object.keys(routesObj)


    routesObjKeys.forEach(moduleName => {
        if (routesObj[moduleName] && !pushedModules[moduleName]) {
            if (!routesObj[moduleName].parent) {
                routes.push(routesObj[moduleName])
                pushedModules[moduleName] = true
            }
        }
    });

    routesObjKeys.forEach(moduleName => {
        if (routesObj[moduleName] && !pushedModules[moduleName]) {
            if (routesObj[moduleName].parent) {
                const idx = routes.findIndex(r => r.moduleName === routesObj[moduleName].parent)
                if (idx >= 0) {
                    const sIdx = routes[idx].subMenus.findIndex(s => s.moduleName === moduleName)
                    if (sIdx < 0) {
                        routes[idx].subMenus.push(routesObj[moduleName])
                        pushedModules[moduleName] = true
                    }
                }
            }
        }
    });



    // let actions = SessionCache.getUserActions()

    // actions.forEach(action => {
    //     if (routesObj[action.moduleName] && !pushedModules[action.moduleName]) {
    //         if(!routesObj[action.moduleName].parent) {
    //             routes.push(routesObj[action.moduleName])
    //             pushedModules[action.moduleName] = true
    //         }
    //     }
    // });
    // actions.forEach(action => {
    //     if (routesObj[action.moduleName] && !pushedModules[action.moduleName]) {
    //         if(routesObj[action.moduleName].parent) {
    //             const idx = routes.findIndex(r => r.moduleName === routesObj[action.moduleName].parent)
    //             if(idx >= 0) {
    //                 const sIdx = routes[idx].subMenus.findIndex(s => s.moduleName === action.moduleName)
    //                 if(sIdx < 0) {
    //                     routes[idx].subMenus.push(routesObj[action.moduleName])
    //                     pushedModules[action.moduleName] = true
    //                 }
    //             }
    //         }
    //     }
    // });
    routes = routes.sort(sortArray)
    routes.map(route => {
        if (route.subMenus) {
            route.subMenus.sort(sortArray)
        }
        return route
    })

    console.log(routes)

    return routes
}

export default getRoutes
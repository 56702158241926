import Axios from 'axios';
import HttpClient from './http-client';
import URL from './url';
import CommonUtils from '../utility/common-utilities'

export default class PracticeQuestionsApi {


    static getPracticeQuestions(payload) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.PRACTICE_QUESTIONS + "?" + params.join('&') : URL.PRACTICE_QUESTIONS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    //    return HttpClient.get(url, null)
    //         .then((res) => {
    //             let data = res.data;

    //             if (data) {
    //                 let decodedHtmlText = CommonUtils.decodeHTMLEntities(JSON.stringify(data));

    //                 data = JSON.parse(decodedHtmlText);
    //             }
    //             callback(null, data);
    //         })
    //         .catch((err) => {
    //             callback(err, null);
    //         });
    }

    static prepkitPracticeActivity(payload) {
        return HttpClient.put(URL.BASE_URL + URL.PREP_KIT_PRACTICE_ACTIVITY, null, payload);
    }

    static createBookMark(payload) {
        return HttpClient.post(URL.BASE_URL + URL.CREATE_BOOKMARK, null, payload);
    }


    static deleteBookMark(payload) {
        return HttpClient.delete(URL.BASE_URL + URL.BOOKMARK, null, payload);
    }
    


}

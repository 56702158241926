import React, { Component } from 'react';
import { Input } from 'reactstrap';
import './dropdown.scss';
import { ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source';
import { element } from 'prop-types';


class Dropdown extends Component {
    groups = {}
    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this)

        this.optionsRef = [];
        this.optionWrapperRef = React.createRef();
        this.inputRef = React.createRef();

        this.state = {
            groups: [],
            selectedGroup: 0,
            selectedValuesHash: {},
            showExpandView: false,
            selectedText: '',
            selectedOptions: [],
            optionsBackup: this.props.options ? JSON.parse(JSON.stringify(this.props.options)) : [],
            cursor: 0,
            listSize: this.props.option ? this.props.options.length : 0,
            showInput: true,
            isLocalSearch: this.props.isLocalSearch,
            selectedGroupCount: {},
            isFilter: this.props.isFilter,
            selectedFilter: this.props.selectedFilter || []
        }


        if (this.props.isGroup) {
            this.groups = {}
            for (let i = 0; i < this.props.options.length; i++) {
                let obj = this.props.options[i]

                let key = obj[this.props.groupKey]
                if (this.groups[key]) {
                    this.groups[key].push(obj)
                } else {
                    this.state.groups.push({ header: obj[this.props.groupName], title: obj[this.props.groupTitle], value: key })
                    this.groups[key] = [obj]
                }
            }
            this.state.options = this.groups[this.state.groups[0]?.value]

        } else if (this.props.isFilter) {

            this.filterHash = {}
            for (let i = 0; i < this.props.options.length; i++) {
                let obj = this.props.options[i]

                let key = obj[this.props.filterKey]
                if (this.filterHash[key]) {
                    this.filterHash[key].push(obj)
                } else {
                    this.filterHash[key] = [obj]
                }
            }
            this.state.options = this.props.options ? JSON.parse(JSON.stringify(this.props.options)) : []

            if (this.state.selectedFilter?.length) {
                let options = []
                this.state.selectedFilter.forEach(element => {
                    this.filterHash[element] && options.push(...this.filterHash[element])
                })
                this.state.options = options
            }


        } else {

            this.state.options = this.props.options ? JSON.parse(JSON.stringify(this.props.options)) : []
        }

        if (this.props.value) {
            let option = this.state.optionsBackup.filter(option => option.value == this.props.value);
            let titles = option.map(o => o.title)
            titles.join(", ")
            this.state.selectedText = titles;
        }

        if (this.props.multiSelect && this.props.value) {
            let optionHash = {}

            for (let i = 0; i < this.props.value.length; i++) {
                optionHash[this.props.value[i]] = {}
            }

            this.state.selectedOptions = this.state.optionsBackup?.filter(option => optionHash[option?.value]) || []
            this.state.selectedText = this.state.selectedOptions.map(item => item?.title).join(', ')
            let selectedValuesHash = {}
            this.state.selectedOptions.forEach(element => {
                selectedValuesHash[element.value] = true
            })
            this.state.selectedValuesHash = selectedValuesHash
        }
    }


    handleKeyDown(e) {
        const { cursor, listSize } = this.state

        if (e.keyCode === 38 && cursor > 0) {
            this.setState(prevState => ({
                cursor: prevState.cursor - 1
            }));

        } else if (e.keyCode === 40 && cursor < listSize - 1) {
            this.setState(prevState => ({
                cursor: prevState.cursor + 1
            }));
        }

        //console.log("cursor", cursor);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickEvent, false);
        this.setState({ searchText: this.props.searchText || "", })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    toggleExpandView(value) {
        this.setState({ showExpandView: value, cursor: 0 });
        if (!value && this.props.multiSelect && this.state.selectedOptions && this.state.selectedOptions.length) {
            this.setState({ showInput: false })
        }
    }

    handleClickEvent = (event) => {
        if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
            if (this.state.showExpandView)
                this.toggleExpandView(false);
        }
    }

    onClickOption(option, index) {

        if (!this.props.multiSelect) {
            this.setState({ selectedText: option[this.props.text], selectedHomeworkId: option.value, searchText: "" }, () => {
                if (this.props.onSelect) {
                    let value = !this.props.value ? option : option[this.props.value];
                    this.props.onSelect(value);
                }
            });
            this.toggleExpandView(false);
        } else {


            let selectedValuesHash = this.state.selectedValuesHash
            if (selectedValuesHash[option.value]) {
                this.onRemoveOption(option)
                return
            } else {
                if (this.state.selectedOptions.length == this.props.max) return
                selectedValuesHash[option.value] = true
            }

            let selectedOptions = this.state.selectedOptions;
            selectedOptions.push(option);
            let titles = []
            selectedOptions.map(op => {
                titles.push(op.title)
            });

            if (this.props.onSelect) {
                this.props.onSelect(selectedOptions);
            }

            if (this.props.isGroup) {
                let selectedGroupCount = this.state.selectedGroupCount
                if (selectedGroupCount[this.state.selectedGroup]) {
                    selectedGroupCount[this.state.selectedGroup] += 1
                } else {
                    selectedGroupCount[this.state.selectedGroup] = 1
                }
            }

            this.setState({ selectedOptions: selectedOptions, selectedValuesHash, selectedText: titles.join(', '), });
        }
    }

    onClickGroupOption(option, index) {
        let options = this.groups[option?.value] || []
        this.setState({ selectedGroup: index, options })
    }

    onRemoveOption(option) {
        let selectedOptions = this.state.selectedOptions;

        let selectedValuesHash = this.state.selectedValuesHash
        selectedValuesHash[option.value] = false
        let titles = []
        selectedOptions = selectedOptions.filter(op => {
            if (op.value != option.value) {
                titles.push(op.title)
                return true
            }
        });

        if (this.props.onSelect) {
            this.props.onSelect(selectedOptions);
        }

        if (this.props.isGroup) {
            let selectedGroupCount = this.state.selectedGroupCount
            if (selectedGroupCount[this.state.selectedGroup]) {
                selectedGroupCount[this.state.selectedGroup] -= 1
            }
        }
        this.setState({ selectedOptions, selectedValuesHash, selectedText: titles.join(', '), });
    }

    onSearch = (event) => {
        let value = event.target.value;
        this.setState({ searchText: value })
        if (!this.state.isLocalSearch) {

            if (this.props.onSearch) {
                this.props.onSearch(this.state.searchText)
            }
            return
        }
        let origanlOptions = JSON.parse(JSON.stringify(this.state.optionsBackup));

        if (!this.props.multiSelect) {
            let options = origanlOptions.filter(option => option[this.props.text].toLowerCase().includes(value.toLowerCase()));
            this.setState({ options: options });
        } else {
            if (this.state.selectedOptions && this.state.selectedOptions.length) {
                let selectedTexts = this.state.selectedOptions.map((option) => option[this.props.text]);
                origanlOptions = origanlOptions.filter((option) => !selectedTexts.includes(option[this.props.text]));

                let options = origanlOptions.filter(option => option[this.props.text].toLowerCase().includes(value.toLowerCase()));
                this.setState({ options: options });
            } else {
                let options = origanlOptions.filter(option => option[this.props.text].toLowerCase().includes(value.toLowerCase()));
                this.setState({ options: options });
            }
        }

    }



    checkValue = (event) => {

        if (!this.state.options.length && !this.props.multiSelect) {
            this.setState({ selectedText: "", searchText: "" });
            this.resetDropdown();
            this.props.onSelect(null);
            this.toggleExpandView(false);
        } else {
            if (this.props.multiSelect) {
                if (!this.state.options.length) {
                    this.resetMulitSelectDropdown();
                }
            }
        }
    }

    resetDropdown() {
        let options = this.state.optionsBackup && this.state.optionsBackup.length ? JSON.parse(JSON.stringify(this.state.optionsBackup)) : []
        this.setState({ options: options, searchText: '', selectedText: "", selectedValuesHash: {} });
    }

    resetMulitSelectDropdown() {
        let options = this.state.optionsBackup && this.state.optionsBackup.length ? JSON.parse(JSON.stringify(this.state.optionsBackup)) : []
        if (this.state.selectedOptions && this.state.selectedOptions.length) {
            let selectedTexts = this.state.selectedOptions.map((option) => option[this.props.text]);
            options = options.filter((option) => !selectedTexts.includes(option[this.props.text]));
            this.setState({ options: options, });
        } else {
            this.setState({ options: options, });
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.options && this.state.optionsBackup && (previousProps.options.length !== this.state.optionsBackup.length || !this.compareOptions(previousProps.options, this.state.optionsBackup))) {
            let options = this.state.optionsBackup ? JSON.parse(JSON.stringify(this.state.optionsBackup)) : []
            if (this.props.isGroup) {
                options = this.groups[this.state.groups[0]?.value]
            }

            this.setState({ options, selectedOptions: [], selectedText: "", })
        }
    }

    compareOptions(previousOption, currentOption) {
        let leftOption = previousOption.map((option) => option[this.props.text]);
        let filterOption = currentOption.filter((option) => leftOption.includes(option[this.props.text]));

        if (leftOption.length == filterOption.length) {
            return true;
        } else {
            return false
        }

    }

    clearAll() {
        if (this.props.multiSelect) {
            let options = this.state.optionsBackup;

            if (this.props.onSelect) {
                this.props.onSelect([]);
            }
            this.setState({ selectedOptions: [], options: options, selectedValuesHash: {}, selectedText: "", selectedGroupCount: {} });
        } else {
            this.setState({ selectedText: "", searchText: "", selectedValuesHash: {}, selectedGroupCount: {} }, () => {
                if (this.props.onSelect) {
                    this.props.onSelect(null);
                }
            });
        }
    }

    onClickFliter(item) {
        let value = item.value
        let selectedFilter = this.state.selectedFilter || []
        if (item.value == selectedFilter) value = ""
        let options = []


        if (value) {
            options = this.filterHash[value] || []
            selectedFilter = [value]
        } else {
            let allOptions = Object.values(this.filterHash)
            allOptions.forEach(element => {
                options.push(...element)
            })
            selectedFilter = []
        }

        this.setState({ options, selectedFilter })
    }

    showHomeworkDetailModal(option, index) {
        if(this.props.showInfoModal) {
            this.props.showInfoModal(option, index)
        }
    }



    render() {
        return (
            <div className='dropdown-v4' ref={ref => this.dropdownRef = ref}>
                {
                    this.state.selectedOptions?.length ? <div className="custome-badge right-top">
                        {this.state.selectedOptions.length}
                    </div> : ""
                }
                {
                    (this.state.selectedOptions && this.state.selectedOptions.length) ||
                        this.state.selectedText
                        ?
                        <img src={getImageSource(ImageConstants.CLEAR_ICON)} className='caret' onClick={() => this.clearAll()}></img>
                        :
                        <img src={getImageSource(ImageConstants.DROPDOWN_ARROW)} className='caret' onClick={() => this.toggleExpandView(!this.state.showExpandView)}></img>
                }
                {!this.props.disabled ?

                    <div className={`dropdown-input-mulit-select  ${this.props.invalid ? 'invalid' : ''}
                                  ${this.state.showExpandView ? 'dropdown-input-border-radius' :
                            (this.state.selectedOptions && this.state.selectedOptions.length) ? 'dropdown-input-border-radius-mulit-select' : ''}`}
                        style={this.props.style ? this.props.style : {}}
                        onClick={() => {
                            this.setState({ showInput: true }, () => { if (this.inputRef) this.inputRef.focus() })
                        }}>

                        {
                            <input className={`input ${this.state.selectedText ? 'placeholder-selected' : ''}`}
                                style={this.props.style ? this.props.style : {}}
                                placeholder={this.state.selectedText ? this.state.selectedText : this.props.placeholder}
                                onFocus={() => this.toggleExpandView(true)}
                                onChange={this.onSearch}
                                value={this.state.searchText}
                                onKeyDown={this.handleKeyDown}
                                // onBlur={this.checkValue}
                                ref={(ref) => this.inputRef = ref} />
                        }
                    </div>
                    :
                    <div className={`dropdown-input-mulit-select dropdown-read-only`}>
                        <input className={`input dropdown-read-only`} placeholder={this.props.placeholder} readOnly></input>
                    </div>
                }

                {
                    !this.props.disabled && this.state.showExpandView ?
                        <div className='dropdown-options dropdown-options-show-border-radius'>

                            <div className='dropdown-options-wrapper ml-2'>

                                <div className="row p-0 m-0">
                                    {
                                        this.state.groups?.length ?
                                            <div className="col-4 p-0">
                                                {this.state.groups.map((option, index) => (
                                                    <div key={option.value} className={`group ${this.state.selectedGroup == index ? "" : "inactive-group"} `} onClick={() => this.onClickGroupOption(option, index)}>
                                                        {this.state.selectedGroupCount[index] > 0 && <div className="custome-badge">{this.state.selectedGroupCount[index]}</div>}
                                                        <div className="group-header pb-2">{option?.header}</div>
                                                        <div className="group-title">{option?.title} </div>
                                                    </div>
                                                ))}

                                            </div>
                                            : ""
                                    }
                                    <div className="col p-0">
                                        {
                                            this.state.options?.length && this.state.isFilter ?
                                                <>
                                                    <div className="filter-wrapper pt-4 px-4 pb-2">
                                                        <div className="title">{this.props.filterTitle || "FILTER"} </div>
                                                        <div className="d-flex">{
                                                            this.props.filterItem?.length ? this.props.filterItem.map(item => (
                                                                <span className={`mt-2 mr-2 filter-item ${this.state.selectedFilter?.includes(item.title) ? "filter-item-active" : ""}`} onClick={() => this.onClickFliter(item)}>
                                                                    <span>{item.title}</span>
                                                                </span>))
                                                                : ""
                                                        }
                                                        </div>
                                                    </div>
                                                    <img className="d-inline-block img-divider" src={getImageSource(ImageConstants.DIVIDER)} />
                                                </>
                                                : ""
                                        }
                                        <div className="pt-2 pb-4">
                                            {
                                                this.state.options?.length ?
                                                    this.state.options.map((option, index) => (
                                                        <div className='row p-o m-0'>
                                                        <div className="row p-0 m-0" onClick={() => this.onClickOption(option, index)}>
                                                            {
                                                                this.props.multiSelect ?
                                                                    <div className="col-1 my-auto">
                                                                        {
                                                                            this.state.selectedValuesHash[option?.value] ?
                                                                                <div className='d-inline-block'>
                                                                                    <img className="d-inline-block img" src={getImageSource(ImageConstants.RECT_TICK)} />
                                                                                </div>
                                                                                :
                                                                                <div className='d-inline-block'>
                                                                                    <img className="d-inline-block img" src={getImageSource(ImageConstants.RECT_BLACK)} />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    : this.props.showRadio ?
                                                                        <div className="col-1 my-auto cursor-pointer">
                                                                            {
                                                                                this.state.selectedText == option?.title && this.state.selectedHomeworkId == option?.value ?
                                                                                    <div className='d-inline-block'>
                                                                                        <img className="d-inline-block img" src={getImageSource(ImageConstants.RADIO_ACTIVE)} />
                                                                                    </div>
                                                                                    :
                                                                                    <div className='d-inline-block'>
                                                                                        <img className="d-inline-block img" src={getImageSource(ImageConstants.RADIO_INACTIVE)} />
                                                                                    </div>
                                                                            }
                                                                        </div> : ""
                                                            }
                                                            <div className="col p-0">
                                                                <div className={`dropdown-option ${this.state.cursor == index ? 'dropdown-option-active' : ''} ${!this.state.selectedValuesHash[option?.value] && this.state.selectedOptions.length == this.props.max ? "disable" : ""}`}
                                                                    key={option.value}
                                                                    tabIndex={index}>
                                                                    {option[this.props.text]}
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        {this.props.showInfo ?
                                                            <div className='col-1 my-auto cursor-pointer'>
                                                                <div className='d-inline-block info-margin-left' onClick={() => this.showHomeworkDetailModal(option, index)}>
                                                                    <img className="d-inline-block img-info" src={getImageSource(ImageConstants.INFO_GREY)} />
                                                                </div>
                                                            </div> : ''
                                                        }
                                                        </div>
                                                    ))
                                                    : <div className='dropdown-option no-result text-center'>{'No results found.'}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>
                }
            </div>);
    }
}

export default Dropdown;
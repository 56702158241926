import React from "react";
import './video-detail.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import CwContentViewer from "../../common/cw-content-viewer/cw-content-viewer";
import ReactTooltip from 'react-tooltip';
import CouponPI from "../../../apis/coupons";
import Loading from "../../common/loading/loading";
import Path from "../../routes/routes-path";


class VideoDetail extends React.Component {



    currentPage = 1
    limit = 10
    filter = {}

    alphabetHash = {}

    state = {

    }

    constructor(props) {
        super(props)
        
    }


    componentDidMount() {
        const element = document.getElementById("video-detail-unique-id");
            // console.log(id, element)
            element.scrollIntoView({ behavior: "instant", block: 'start' })

    }


    videoEnded(chapterKey, chapterTitle, content, subType, type) {

        console.log(content)

        if (this.watchedVideoRewardAddedList[`${chapterKey}_${content._id}`]) {
            // console.log('returned')
            return;
        }

        let payload = {
            type: type,
            subType: subType,
            chapterKey,
            chapterTitle: this.state.chapterTitle,
            contentTitle: chapterTitle,
            contentId: content._id,
            board: this.state.selectedBoard,
            class: this.state.selectedClass
        }

        CouponPI.addVideoWatchedRewardPoint(payload).then((res) => {
            if (res.data.rewardPointAdded && res.data.chapterKey) {
                this.watchedVideoRewardAddedList[`${res.data.chapterKey}`] = true
            }

            if (type === "CASE_STUDY_ASSIGN" || type === "CHAPTER_ACTIVITY_ASSIGN") {
                this.setState({ rewardAssigned: this.watchedVideoRewardAddedList })
            }

            this.setState({})
        }).catch(err => {
            console.log(err)
        })


    }

    toggleBack() {
        this.props.back && this.props.back()
    }




    renderBreadcrumb = () => {
        return (
            <div className='breadcrumb '>
                <span className="bread-crumb-title" onClick={this.goToChapterListScreen}>{`${this.props.selectedClass || ""} >`}</span>
                <span className='pl-2 bread-crumb-title' onClick={() => this.toggleBack()}>{`${this.props.chapterTitle || ""}`}</span>
                {/* <span className='pl-2'>{this.props.selectedElo?.conceptTitle} {`>`} </span>
                <span className='active pl-2'>{this.props.selectedElo?.title}</span> */}
            </div>
        )
    }

    renderSolvedExamples() {



        return (
            <div className="row">
                <>

                    {
                        <div className='col mb-4 video-learning-content-wrapper-header position-relative'>
                            {/* <img className='blue-dimond-icon' src={getImageSource(ImageConstants.BLUE_DIMOND_ICON)} /> */}

                            <div className='col-4 video-learning-content flex-column position-relative'>

                                {/* <div className="">
                                    <div className='d-flex align-items-start  video-learning-content-title '>
                                        <div className='col-2 mr-2 d-flex justify-content-center align-items-center sr-no-bg '>
                                                <div className=' sr-no-bg-color'>{`${serialNumber += 1}`}</div>
                                            </div>
                                        <div className='col align-self-center sr-no'>{this.props.videoInfo.displayTitle} </div>
                                    </div>

                                </div> */}
                                <div className="text-right d-flex flex-row"><CwContentViewer from={'video_only'} content={this.props.videoInfo.content.description}
                                    videoEnded={() => this.videoEnded(this.props.videoInfo.chapterKey, this.props.videoInfo.displayTitle, this.props.videoInfo, "ELO_VIDEO_WATCHED", "WATCHED_VIDEO_ASSIGN")} /></div>
                                <div className="d-flex mt-2">
                                    <div><img className="chapter-image-icon" src={this.props.videoInfo.chapterImageUrl} /></div>
                                    <div className="ml-2 d-flex flex-column">
                                        <div><span className="chapter-title">{this.props.videoInfo.chapterTitle}</span> | {this.props.videoInfo.class}</div>
                                        <div>{this.props.videoInfo.conceptTitle}</div>
                                    </div>
                                </div>
                            </div>

                            {    
                                    <>
                                        <div className="p-3 position-relative">
                                            {/* <img className="video-detail-header-image" src={getImageSource(ImageConstants.VIDEO_DETAIL_HEADER_IMAGE)} /> */}
                                            <div className="video-detail-wrapper">
                                                <div className="elo-title p-4">
                                                    {`${this.props.videoInfo.conceptTitle}`}
                                                </div>
                                                <div className="video-detail-divider"></div>
                                                <div className="content-white-bg">
                                                    <div className="p-4 content-title">{this.props.videoInfo.displayTitle}</div>
                                                    <div className="d-flex video-learning-content-wrapper mt-4  pb-2">
                                                        <div className='video-learning-content row '>
                                                            <div className="col content-alignment mt-2">
                                                                {/* <div className='d-flex video-learning-content-title'>
                                                                    <div className='mr-1 sr-no '><span className='mt-3 sr-no-bg-color'>{`${this.state.serialNumber}.`}</span></div>
                                                                    <div className='align-self-center sr-no'>{this.props.videoInfo.displayTitle} </div>
                                                                </div> */}
                                                                <div className="video-content-wrapper">
                                                                    {<CwContentViewer from={'video_content'} content={this.props.videoInfo.content.description} />}
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </>
                                    
                            }

                           
                        </div >


                    }
                </>
            </div>
        )

    }

    goToHomeScreen() {
        this.props.delegate.props.history.push(Path.HOME)
    }

    goToVideoLibrary() {
        this.props.back && this.props.back()
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb ml-2 mt-2'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToVideoLibrary()}>{`Video Library >`}</span>
                <span className='pl-2 title-1'>{`${this.props.videoInfo.chapterTitle}`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }



    render() {
        return (
            <div id="video-detail-unique-id" className="video-detail">
                {/* <div className='' >
                    <img className='back-icon cursor-pointer' src={getImageSource(ImageConstants.BACK_ICON)} onClick={() => this.toggleBack()} />
                </div> */}
                {this.renderBreadcrumbForChapterList()}
                <div className="mt-4">
                    <div className='row align-items-center heading-wrapper mt-2 p-2'>
                        {/* <img className="case-study-bg" src={getImageSource(ImageConstants.ACTIVITY_BG)} /> */}
                        <div className='col d-flex align-items-center'>
                            <div>
                                <img className='orange-play-icon mr-2' src={getImageSource(ImageConstants.PLAY_ICON_ORANGE)} />
                            </div>
                            <div>
                                <div className="header-text">{this.props.videoInfo.displayTitle}</div>
                                {/* <div className=''>{this.renderBreadcrumb()}</div> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="solved-examples-card mt-4">

                    {this.props.videoInfo ? this.renderSolvedExamples() 
                    : 
                    // <div className='d-flex justify-content-center pb-4'>No Video Content Found for this Chapter</div>
                    <Loading />
                    }

                </div>
                <ReactTooltip ref={el => this.tooltip = el} multiline={true} place='bottom' type='dark' effect='solid' event="mouseover mouseenter" eventOff="mouseleave mouseout scroll mousewheel blur" />
            </div>
        )
    }

}

export default VideoDetail;
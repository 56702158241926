
import React, { Component } from 'react';
import './cw-confirmation-modal.scss'
import { Modal, ModalBody } from "reactstrap";
import { ImageConstants } from '../../../../constants/constants';
import { getImageSource } from '../../../../utility/image-source'


class CwConfirmationModal extends Component {

    constructor(props) {
        super(props);



    }

    onClickOkay = () => {
        this.props.onOk && this.props.onOk()
    }

    onClickClose = () => {

        this.props.hideAnswerStatusPopup && this.props.hideAnswerStatusPopup()



    }

    showSetNewPasscodeScreen() {
        this.props.delegate && this.props.delegate.goToNewPasscodeScreen && this.props.delegate.goToNewPasscodeScreen()
    }


    sendOtp() {
        this.props.sendOtp && this.props.sendOtp()
    }

    showPasscodeScreen() {
        this.props.showPasscodeScreen && this.props.showPasscodeScreen({ skipOtp: true })
    }


    render() {
        return (
            <Modal className="cw-common-custom-modal-center-for-practice" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>

                    <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} />
                </div>
                <ModalBody>
                    {

                        <div className="pratice-container">
                            <div className="d-flex justify-content-center align-items-center mt-5">
                                <div>
                                    {
                                        this.props.answerStatus === "CORRECT_ANSWER" ?
                                            <img className="image-size" src={getImageSource(ImageConstants.CORRECT_ANSWER_TICK_MARK)} />
                                            : this.props.answerStatus === "INCORRECT_ANSWER" || this.props.answerStatus === "NOT_ANSWERED" ?
                                                <img className="image-size" src={getImageSource(ImageConstants.WRONG_ANSWER_CROSS_MARK)} />
                                                : <></>
                                    }
                                </div>
                                <div className="answer-status-text">
                                    {
                                        this.props.answerStatus === "CORRECT_ANSWER" ? "This is Correct Answer"
                                            : this.props.answerStatus === "INCORRECT_ANSWER" ? "This is Incorrect Answer"
                                                : this.props.answerStatus === "NOT_ANSWERED" ? "Not Answered" : ""
                                    }
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-2">
                                <div className="take-challenge cursor-pointer text-center" onClick={() => this.onClickOkay()}>
                                    {"Okay"}
                                </div>
                            </div>
                            <div className="position">
                                <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                            </div>
                        </div>

                    }


                </ModalBody>
            </Modal>
        );
    }
}

export default CwConfirmationModal;

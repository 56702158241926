import React, { Component } from 'react';
// import Mathparser from "../../cw-common-components/cw-math-parser-component/math-parser-component.js";
// import JsxParser from "react-jsx-parser";
import JsxParsar from '../jsx-parsar/jsx-parsar';

// function getdata(content) {
//     if (/<[a-z][\s\S]*>/i.test(content)) {
//         return <JsxParser jsx={content}></JsxParser>;
//     }
//     return content;
// }

const CwContentViewer = (props) => {
    return (

        <div>
            {/* {
                props.content &&
                    props.content.includes(Constants.CUSTOM_MATH_TAG) ?
                    <Mathparser content={props.content} /> : getdata(props.content)
            } */}
            {props.content && <JsxParsar from = {props.from} showOnlyOneVideo = {props.showOnlyOneVideo} data={props.content} 
            videoEnded = {props.videoEnded} videoPlayed = {props.videoPlayed}  />}
        </div>
    );
}

export default CwContentViewer;
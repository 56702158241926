import React from "react";
import './select-level.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";

class SelectLevel extends React.Component {

    state = {
        items: [
            {
                title: 'NOVICE',
                description: this.staticText && this.staticText.noviceDescription ? this.staticText.noviceDescription : 'I usually score less than 50% in Maths exams',
                image: getImageSource(ImageConstants.NOVICE),
                backgroundColor: '#EDEDFF'
            },
            {
                title: 'INTERMEDIATE',
                description: this.staticText && this.staticText.intermediateDescription ? this.staticText.intermediateDescription : 'I usually score between 50-80% in Maths exams',
                image: getImageSource(ImageConstants.INTERMEDIATE),
                backgroundColor: '#FFF5E2'
            },
            {
                title: 'ADVANCE',
                description: this.staticText && this.staticText.advanceDescription ? this.staticText.advanceDescription : 'I usually score more than 80% in Maths exams',
                image: getImageSource(ImageConstants.ADVANCED),
                backgroundColor: '#CDFFF8'
            }
        ],

        selectedLevel : 'NOVICE',
        selectedIndex : 0

    }

    select = (item, index) => {
        this.setState({ selectedIndex: index, selectedLevel: item.title })
    }

    continue = () => {
        this.props.onPressContinue && this.props.onPressContinue(this.state.selectedLevel)
    }
    getButtons = () => {
        return (
            !this.state.selectedLevel ?
                <div className="text-center continue-button continue-button-disabled">
                    Continue
                </div>
                :
                <div className="text-center continue-button cursor-pointer" onClick={() => this.continue()}>
                    Continue
                </div>
        )

    }

    goBack() {
        this.props.goBackToDailyGoalScreen && this.props.goBackToDailyGoalScreen()
    }


    render() {
        return (
            <div>
                <div className="heading">My current Level of Maths is</div>
                {/* <div className="sub-heading">You can change it anytime later</div> */}
                <div className="select-level-wrapper mt-4">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        {this.state.items.length ? this.state.items.map((item, index) => (
                            <div className={`row student-level cursor-pointer 
                            ${index === 0 ? '' : 'mt-4'}
                            ${this.state.selectedIndex === index ? "selected-level-bg" : ''}`}
                            onClick={() => this.select(item, index)} >
                                <div className="col-2">
                                    <img 
                                        className="select-level-image cursor-pointer"
                                        src={item.image} />
                                </div>
                                <div className="col d-flex flex-column justify-content-center ">
                                    <div className="title-text">{item.title}</div>
                                    <div className="sub-title-text">{item.description}</div>
                                </div>
                                {
                                    this.state.selectedIndex === index ?
                                        <div className="text-right text-center mt-3">
                                            <img className="green-tick-mark" src={getImageSource(ImageConstants.GREEN_TICK_MARK)} />
                                        </div>
                                        : ''
                                }

                            </div>
                        ))
                            : ''}
                    </div>

                </div>
                <div className="d-flex justify-content-center">
                    <div className="text-center back-button cursor-pointer mr-5" onClick={() => this.goBack()}>Back</div>
                    {this.getButtons()}
                </div>
            </div>
        )
    }
}

export default SelectLevel;
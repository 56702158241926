import React from "react";
import './clicker-assessment-leaderboard.scss';

class ClickerAssessmentLeaderboard extends React.Component {


    state = {
        // leaderboardData: [
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99964",
        //         "assessmentActivityId": "66f53ed23315456b8a678529",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 2,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 1,
        //         "name": "Countingwell School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.15",
        //         "timeTakenInMilliSecond": 8740,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a8",
        //         "rank": 1
        //     },
        //     {
        //         "_id": "66f53ed8b97d6172dba99963",
        //         "assessmentActivityId": "66f53ed23315456b8a678528",
        //         "board": "CBSE",
        //         "class": "Class 6",
        //         "correctQuestionCount": 1,
        //         "countingwellLastUpdateTimestamp": "2024-09-26T11:00:54.530Z",
        //         "incorrectQuestionCount": 2,
        //         "name": "Countingwell School School",
        //         "notAttemptedQuestionCount": 0,
        //         "timeTaken": "0.03",
        //         "timeTakenInMilliSecond": 1662,
        //         "transferKey": "USER_BULK_IMPORT_8_1_2024",
        //         "userId": "659b90fa21cb4f53081b46a7",
        //         "rank": 2
        //     }
        // ]
        leaderboardData: this.props.leaderboardData
    }

    render() {
        return (
            <div className="clicker-assessment-leaderboard">
                <div className="score-board mt-1">
                    {this.state.leaderboardData.length ?
                        this.state.leaderboardData.map((item, index) => (
                            <div className="row" style={{ width: '98%' }}>
                                {/* <div className="col-2 rank-wrapper d-flex justify-content-center align-items-center">
                                    {
                                        this.props.isFinalQuestion && index <= 1 ? <div>{"Q"}</div> : <div>{item.rank}</div>
                                    }
                                </div> */}
                                <div className="col-2 rank-wrapper d-flex justify-content-center align-items-center">
                                    {
                                        <div>{item.rank}</div>
                                    }
                                </div>
                                <div className="score-item col"
                                    key={index}
                                    style={{ backgroundColor: "#003478" }}
                                >
                                    {/* <span className="rank">{index + 1}</span> */}
                                    <span className="name">{item.name}{` (${item.studentNumber})`}</span>
                                    {/* <span className="correct-question-count">{item.correctQuestionCount}</span> */}
                                    {
                                        item.timeTaken ?
                                            <span className="score">Score :  {item.markScored} | Time : {item.timeTaken} S</span> : <></>
                                    }
                                </div>
                            </div>
                        ))
                        :
                        <div className="text-center no-leaderboard-text">No leaderboard</div>
                    }
                </div>
            </div>
        )
    }

}

export default ClickerAssessmentLeaderboard;
import React, { Component } from 'react';
import './common-error-experience.scss';
import LearningContentWrapper from '../exam-prep-kit-learning-content-wrapper/exam-prep-kit-learning-content-wrapper';
import SharedProfile from '../../../../../shared-storage/profile';
import Loading from '../../../common/loading/loading';
import ExamPrepKitApi from '../../../../apis/exam-prep-kit';

export default class CommonErrorExpernce extends Component {

    workflowEngine = null;
    worflowNodes = [];
    questions = [];
    state = {
        learningElos: [],
        resumeIndex: 0
    };

    CONTENT_FETCH_LIMIT = 5
    contentHash = {}

    constructor(props) {

        super(props);
        // let { navigation } = this.props;
        // this.params = navigation.getParam('meta');
        this.params = this.props.metaInfo
        this.headerTitle = this.params && this.params.headerTitle ? this.params.headerTitle : ""
        this.progress = null;
        this.userInfo = SharedProfile.getProfile();
        this.getCommonErrors()

        if (this.params.resumeIndex) {
            this.state.resumeIndex = this.params.resumeIndex;
            this.lastMaxIndex = this.params.resumeIndex
        }
    }

    learnMoreParam = {}

    componentDidMount() {
        // this.props.navigation.addListener("didFocus", () => {

        //     BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });

        // this.props.navigation.addListener("didBlur", () => {
        //     BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });
    }

    handleBackButtonClick = () => {
        return true
    }

    buildLearningContent = (contents, callback) => {

        let learningElos = this.state.learningElos;

        for (let i = 0; i < contents.length; i++) {
            let content = contents[i]
            learningElos.push({
                eloId: content.eloId,
                eloTitle: content.eloTitle,
                categoryThemeId: this.params && this.params.categoryThemeId ? this.params.categoryThemeId : "1",
                contentId: content.contentId,
                contents: [{ title: "", contents: [content] }]
            })
        }

        this.setState({ learningElos }, () => {
            if (callback)
                callback()
        })
    }

    onPressNext = (activeIndex, callback) => {
        callback && callback()
    }

    learningElosCompleted = () => {

        if (this.params.isCommonErrorCompleted) {
            // this.props.navigation.goBack()
            this.goBackToTableOfContents()
            return
        }

        this.updateCommonErrorCompleted(() => {
            // this.props.navigation.goBack()
            this.goBackToTableOfContents()
        })
    }

    goToLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    close = () => {
        this.props.navigation.goBack()
    }

    next = (index) => {

        if (this.params.isCommonErrorCompleted || this.lastMaxIndex > index)
            return

        this.lastMaxIndex = index

        let payload = {
            chapterKey: this.params.chapterKey,
            resumeId: index,
            type: "COMMON_ERRORS"
        }

        ExamPrepKitApi.updatePrepKitActivity(payload).then((response) => {
            this.buildLearningContent(response)
        }).catch(err => {
            console.log(err);
            if (err) {
                this.learningElosCompleted()
                return
            }
        })

    }

    getCommonErrors = () => {

        let payload = {
            chapterKey: this.params.chapterKey
        }

        ExamPrepKitApi.getCommonErrors(payload).then((response) => {
            this.buildLearningContent(response)
        }).catch(err => {
            console.log(err);
            if (err) {
                this.learningElosCompleted()
                return
            }
        })

    }

    updateCommonErrorCompleted = (callback) => {

        let payload = {
            chapterKey: this.params.chapterKey,
            type: "COMMON_ERRORS"
        }

        this.setState({ loading: true })
        ExamPrepKitApi.updatePrepKitProgress(payload).then((response) => {
            callback && callback()
            this.setState({ loading: false })
            
        }).catch(err => {
            console.log(err);
            this.setState({ loading: false })
            if (err) {
                return
            }
        })
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToExamPrepKit() {
        this.props.delegate && this.props.delegate.goToExamPrepKit && this.props.delegate.goToExamPrepKit()
    }

    goToExamPrepKitChapterList() {
        this.props.showPrepKitChapterList && this.props.showPrepKitChapterList()
    }

    goBackToTableOfContents() {
        this.props.delegate && this.props.delegate.hideCommonErrorExperience && this.props.delegate.hideCommonErrorExperience()
    }

    goToPrepare() {
        this.props.delegate && this.props.delegate.goToPrepare && this.props.delegate.goToPrepare()
    }


    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                {/* <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToExamPrepKit()} >{`Prepare`}{` > `}</span> */}
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToPrepare()}>{`${this.params.title}`} {` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToTableOfContents()}>{`${`Table of Contents`}`} {` > `}</span>
                <span className='title-1 pl-2' >{`Common Errors`}</span>
            </div>
        )
    }


    render() {

        return (
            <div className='prep-kit-common-error-experience'>
                {
                    this.renderBreadcrumbForChapterList()
                }
                {this.state.learningElos.length > 0 && !this.state.loading ? <LearningContentWrapper
                    elo={this.state.learningElos}
                    delegate={this}
                    navigation={this.props.navigation}
                    isRevise={this.isRevise}
                    title={this.params.title ? this.params.title : ''}
                    navigationInfo={this.params}
                    eloIds={this.eloIds}
                    noBlur={true}
                    headerIcon={this.params.headerIcon}
                    headerTitle={"Common Errors"}
                    removeHeaderIcon
                    onPressNext={this.onPressNext}
                    activeIndex={this.state.resumeIndex}
                    allPagesActive={this.params.isCommonErrorCompleted}
                >
                </LearningContentWrapper> : <Loading />}
            </div>
        )
    }
}
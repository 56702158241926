import React from "react";
import './chapter-activity-list.scss';
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import PracticeMoreQuestions from "../../class-specific-flow/pratice/practice-more-questions/practice-more-questions";
import ExamPrepKit from "../../exam-prep-kit/exam-prep-kit/exam-prep-kit";
import MyAssessments from "../../class-specific-flow/my-assessments/my-assessments";


class ChapterActivityList extends React.Component {

    state = {
        showPractice : false
    }

    constructor(props) {
        super(props)
        this.meta = this.props.meta
    }


    goToPractice() {
        this.setState({showPractice : true})
    }

    hidePractice() {
        this.setState({showPractice : false})
    }

    goToExamPrepKit() {
        this.setState({showExamPrepKit : true})
    }

    hideExamPrepKit() {
        this.setState({showExamPrepKit : false})
    }

    goToMockTest() {
        this.setState({showMyAssessment : true, showPractice : false})
    }

    hideMockTest() {
        this.setState({showMyAssessment : false})
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.hideChapterActivityList && this.props.delegate.hideChapterActivityList()
    }


    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2 active' >{this.meta.chapterTitle}</span>
            </div>
        )
    }


    render() {

        if(this.state.showPractice) {
            return<PracticeMoreQuestions from = 'CHAPTER_ACTIVITY' delegate = {this} meta = {{...this.meta}} />
        }

        if(this.state.showExamPrepKit) {
            return <ExamPrepKit delegate = {this} meta = {{...this.meta, from : 'CHAPTER_ACTIVITY'}} />
        }

        if(this.state.showMyAssessment) {
            return <MyAssessments delegate = {this} meta = {{...this.meta, from : 'CHAPTER_ACTIVITY'}} />
        }


        return (
            <div className="chapter-activity-list">
                <div className="text-right">
                    <img className="image-size" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, this.meta.categoryThemeId)}></img>
                </div>
                <div className="header-for-practice-question">
                    {this.renderBreadcrumbForChapterList()}
                    <div className="d-flex align-items-center">
                        <img className="chapter-icon mr-1" src={this.meta.imageUrl ? this.meta.imageUrl : getImageSource(ImageConstants.CHAPTER_ICN)} />
                        <span className="chapter-title">{this.meta.chapterTitle}</span>
                    </div>
                </div>
                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.meta.categoryThemeId)}></img>
                </div>
                <div>
                    <div className="exam-prep-card mt-4" style={{ background: '#FFF0E3' }}>
                        <div className="d-flex justify-content-between align-items-center cursor-pointer p-3" onClick={() => this.goToExamPrepKit()}>
                            <div className="d-flex align-items-center">
                                <div className="mr-2">
                                    <img className="book-with-apple" src={getImageSource(ImageConstants.EXAM_PREP_ICON)} />
                                </div>
                                <div className="heading">Prepare</div>
                            </div>
                            <div>
                                <img className="arrow-size-for-case-study" src={getImageSource(ImageConstants.BLUE_ARROW_ICON)} />
                            </div>
                        </div>
                    </div>
                    <div className="exam-prep-card mt-4" style={{ background: '#FFF8DD' }}>  
                        <div className="d-flex justify-content-between align-items-center cursor-pointer p-3" onClick={() => this.goToPractice()}>
                            <div className="d-flex align-items-center">
                                <div className="mr-2">
                                    <img className="book-with-apple" src={getImageSource(ImageConstants.PRACTICE_ICON)} />
                                </div>
                                <div className="heading">Practice</div>
                            </div>
                            <div>
                                <img className="arrow-size-for-case-study" src={getImageSource(ImageConstants.BLUE_ARROW_ICON)} />
                            </div>
                        </div>
                    </div>
                    <div className="exam-prep-card mt-4" style={{ background: '#EADDFF' }}>
                        <div className="d-flex justify-content-between align-items-center cursor-pointer p-3" onClick={() => this.goToMockTest()}>
                            <div className="d-flex align-items-center">
                                <div className="mr-2">
                                    <img className="book-with-apple" src={getImageSource(ImageConstants.MOCK_TEST)} />
                                </div>
                                <div className="heading">Mock Test</div>
                            </div>
                            <div>
                                <img className="arrow-size-for-case-study" src={getImageSource(ImageConstants.BLUE_ARROW_ICON)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ChapterActivityList;
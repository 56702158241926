import React from "react";
import Sound from 'react-sound';


class CwAudio extends React.Component {


    componentDidMount() {
        //console.log(this.props.url)
    }

    render(){
        return(
            <Sound 
            url={this.props.url}
            playStatus={Sound.status.PLAYING} />
        )
    }


}

export default CwAudio;
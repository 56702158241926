import React from 'react';
import './cw-question-image.scss';
import SharedThemeCategory from '../../../../shared-storage/category-theme';
import ImagePreview from '../image-preview/image-preview';
import { getImageSource } from '../../../utility/image-source';
import { ImageConstants } from '../../../constants/constants';
import CustomImage from '../content-parser/custom-image-renderer/custom-image-renderer';

class QuestionImage extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            imagePreview: false
        };
    }

    componentDidMount() {
        // //console.log(this.props.images.length)
    }


    openPreview = (previewUrl) => {
        this.setState({ imagePreview: true, previewUrl: previewUrl })
    }

    closePreview = () => {
        this.setState({ imagePreview: false });
    }



    render() {
        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)
        return (
            <>
                {
                    this.props.images && this.props.images.length ?
                        <div className={`question-image-v1 d-flex flex-row mt-3 
                        ${this.props.images.length === 1 ? 'container-img' : ''}
                        ${this.props.images.length === 2 ? 'container-img-2' : ''}
                        ${this.props.images.length >= 3 ? 'container-img-3' : ''}`}
                            style={this.props.categoryThemeId ? { border: `2px solid ${this.theme[`learningContentBackground`]}` } : {}}
                        >
                            {this.props.images.map((image, index) => (
                                <div className='d-flex'>

                                    <CustomImage
                                        styles={{

                                            // width: this.getWidthForImages(obj, isGlossaryImage, isNotesImage, isBulletImage, this.isSummary),
                                            // height: this.getHeigthForImages(obj, isGlossaryImage, isNotesImage, isBulletImage, this.isSummary),
                                            // // height:obj.size.heightRatio,
                                            // marginTop: wp(7.5),
                                            // marginBottom: wp(7.5),
                                            maxHeight: `10rem`,
                                            maxWidth: "100%",
                                            objectFit: 'contain',
                                        }}
                                        isQuestionImage={true}
                                        uri={image.imageUrl}
                                        categoryId={this.props.categoryThemeId}
                                        previewImage={(uri) => this.openPreview(uri)}
                                    />


                                    {/* <img className='image-width mr-1 cursor-pointer'
                                        onClick={() => this.openPreview(image.imageUrl)}
                                        src={image.imageUrl} /> */}
                                    <div><img className='expand-icon ml-1 mr-3 cursor-pointer' onClick={() => this.openPreview(image.imageUrl)} style={{}} src={getImageSource(ImageConstants.EXPAND)}></img></div>

                                </div>

                            ))}
                        </div>
                        :
                        <></>
                }
                {
                    this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>
                }

            </>
        )
    }

}

export default QuestionImage
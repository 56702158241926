import React from "react";
import './practice-completion-screen.scss';
import SharedStaticText from "../../../../shared-storage/static-text-store";
import SharedProfile from "../../../../shared-storage/profile";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import HomeworkApi from "../../../apis/homework";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import Path from "../../routes/routes-path";
import CwConfirmationModal from "../../common/cw-confirmation-modal/cw-confirmation-modal";
import ChapterAssessmentPerformanceReport from "../../chapter-assessment-performance-report/chapter-assessment-performance-report";

export default class PracticeCompletionScreen extends React.Component {

    constructor(props) {
        super(props);


        console.log(this.props.data, this.props.practice)

        this.staticText = SharedStaticText.getStaticText('homeworkCompletionScreen');
        this.state.progress = 60
        this.state.categoryId = this.props.categoryThemeId
        this.userInfo = SharedProfile.getProfile();
        this.theme = SharedThemeCategory.getThemeCategory(this.state.categoryId);
        // this.appSounds = sharedAppSounds.getAppSounds();
        // this.playAppSound = CWAppSounds;

        // this.state.concepts = this.props.data.concepts
        //console.log('data', this.props.data)
        this.state.conceptList = this.props.data.concepts
        this.state.concepts = this.props.data.concepts.slice(0, 3);

        // this.conceptList = [
        //     {
        //         title: 'Read and Write Numbers With Place Value',
        //     },
        //     {
        //         title: 'Read and Write Numbers With Place Value',
        //     }

        // ]

        // this.state.conceptList = this.conceptList
        // this.state.concepts = this.conceptList.slice(0, 3);
        this.state.chapterTitle = this.props.data.chapterTitle
        this.state.clearedElos = this.props.data.cleared
        this.state.notClearedElos = this.props.data.notCleared
        this.state.totalElos = this.props.data.cleared.concat(this.props.data.notCleared)
    }

    state = {
        clearedElos: [],
        totalElos: [],
        notClearedElos: [],
        upComing: [{}, {}],
        eloTitle: "",
        time: 0,
        isContinue: true,
        progress: "0",
        fill: 0,
        chapterTitle: '',
        concepts: [],
        conceptList: [],
        showCompletedPopUp: false,
        nodesToPush: []
    }



    componentDidMount() {
        // this.setState({ chapterCompletionAnimation: true }, () => {
        //     this.playAppSound.playSound(this.appSounds.conceptCleared)
        //     setTimeout(() => { this.setState({ chapterCompletionAnimation: false }); }, 3000);
        // })
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    toggleLateralMenu = (flag) => {

        this.props.navigation.push('LateralMenu', { params: { backTo: "LandingScreen" } });
    }

    onContinue = () => {

        let payload = {
            homeworkId: this.props.data.homeworkId
        }

        HomeworkApi.getHomeworkStatus(payload).then((res) => {

            if (res) {
                let cleared = this.groupByConcept(res.cleared, res.concepts)
                let notCleared = this.groupByConcept(res.notCleared, res.concepts)
                let notAttepmted = this.groupByConcept(res.notAttepmted, res.concepts)
                let contentsToRender = ''

                let statusSelected = null
                if (notCleared.length) {
                    statusSelected = 'NOT_CLEARED'
                    contentsToRender = notCleared
                } else if (cleared.length) {
                    statusSelected = 'CLEARED'
                    contentsToRender = cleared
                } else if (notAttepmted.length) {
                    statusSelected = 'NOT_ATTEMPTED'
                    contentsToRender = notAttepmted
                }

                this.setState({
                    cleared,
                    notCleared,
                    notAttepmted,
                    statusSelected,
                    contentsToRender
                }, () => {

                    let concept = notCleared[0]
                    let node = concept.nodes[0]
                    let status = node.status

                    this.navigate(status, node, concept)

                })
            }
        }).catch(err => {
            if (err) {
                return
            }
        })
    }

    groupByConcept = (nodes, concepts) => {

        let conceptGroup = {}
        let conceptOrdered = []

        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i]

            if (conceptGroup[node.conceptKey]) {
                conceptGroup[node.conceptKey].push(node)
            } else {
                conceptGroup[node.conceptKey] = [node]
            }
        }

        for (let i = 0; i < concepts.length; i++) {
            let key = concepts[i].key

            if (conceptGroup[key]) {
                conceptOrdered.push({
                    ...concepts[i],
                    nodes: conceptGroup[key]
                })
            }
        }

        return conceptOrdered
    }


    navigate = (status, node, concept) => {

        let eloIds = node.elos ? node.elos.map(item => item.id) : [];

        this.props.navigation.navigate("StandardHomeworkLearningExperience", {
            params: {
                nodeId: node.id,
                status: status,
                homeworkId: this.props.data.homeworkId,
                chapterKey: node.chapterKey,
                title: node.chapterTitle,
                nodes: concept.nodes,
                categoryThemeId: this.state.categoryId,
                eloIds
            }
        })


    }

    toggleConcepts = () => {
        if (!this.state.showContent) {
            this.setState({ concepts: this.state.conceptList, showContent: !this.state.showContent })
        } else {
            this.setState({ concepts: this.state.conceptList.slice(0, 3), showContent: !this.state.showContent })
        }

    }

    // learnChapter=()=>{
    //     let chapterKey = this.props.homework.chapterKey

    //     if (!chapterKey) {
    //         this.setState({ lmsLoading: false })
    //         this.state.lmsLoading = false
    //         return
    //     }

    //     let payload = {
    //         chapterKey
    //     }

    //     this.setState({ showChapterSelectionStatusLoading: true })

    //     Api.getSingleChapterStatus(payload, (err, res) => {

    //         this.setState({ showChapterSelectionStatusLoading: false })

    //         if (err) {
    //             return;
    //         }

    //         if (res && res.items && res.items.length) {

    //             let data = res.items[0]

    //             let obj = {
    //                 chapter: data,
    //                 color: "",
    //                 arrow: "",
    //                 progress: data.progress,
    //                 key: data.key,
    //                 isLocked: data.isLocked,
    //                 status: data.status,
    //                 categoryThemeId: data.categoryId,
    //                 isFinished: data.isFinished,
    //                 image: data.chapterImage,
    //                 lockedForTrial: data.lockedForTrial
    //             }
    //             this.props.navigation.navigate('ChapterNavigation', { meta: { chapter: obj } })
    //         }
    //     })
    // }

    renderConceptTitle = () => {

        if (this.state.concepts.length === 1) {
            return (
                <div className="concept-name">{this.state.concepts[0].title}</div>
            )
        } else {
            return (
                <>
                    {this.state.concepts.map((item, index) =>
                        <div className="features-item d-flex flex-row justify-content-center align-items-center">
                            <div className="bulletContainer mr-2">
                                <div className="featuresItemBullet"></div>
                            </div>
                            <div className="textContainer">
                                <span className="featuresItemText concept-name">
                                    {item.title}
                                </span>
                            </div>
                        </div>
                    )}

                    {this.state.conceptList && this.state.conceptList.length > 3 ?
                        <div className="" >
                            <span className="moreText mr-1 cursor-pointer" onClick={this.toggleConcepts}>
                                {!this.state.showContent ? this.staticText.moreText : this.staticText.lessText}
                            </span>
                            {this.state.showContent ?
                                <span onClick={this.toggleConcepts} style={{ transform: [{ scaleY: -1 }] }}><img className="cursor-pointer arrow-size" src={getImageSource(ImageConstants.DOWN_ARROW)} /></span>
                                :
                                <img onClick={this.toggleConcepts} className="arrow-size cursor-pointer" src={getImageSource(ImageConstants.DOWN_ARROW)} />
                            }
                        </div>
                        :
                        <></>
                    }

                </>
            )
        }
    }

    onGoHome = () => {
        this.props.navigation.navigate('LandingScreen', { params: { backTo: "LandingScreen" } });
    }

    onPressLearn = () => {
        // moengage.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy, 'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter Key':this.props.homework.chapterKey,'Chapter': this.state.chapterTitle, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'Yes'})
        // FireBaseEvent.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy, 'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter Key':this.props.homework.chapterKey,'Chapter': this.state.chapterTitle, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'Yes'})
        // Facebook.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy, 'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter Key':this.props.homework.chapterKey,'Chapter': this.state.chapterTitle, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'Yes'})
        // BranchIO.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName,Teacher:this.props.homework.createdBy, 'Class': this.userInfo.class, 'Homework Type': 'Standard', 'Chapter Key':this.props.homework.chapterKey,'Chapter': this.state.chapterTitle, 'Concepts': this.props.conceptsCovered.toString() ,'Status':'Completed','Learning Started':'Yes'})

        //console.log(this.state.categoryId, this.state.chapterTitle, this.props.data.homeworkId)

        this.props.delegate && this.props.delegate.hideStandardClassworkExperience && this.props.delegate.hideStandardClassworkExperience({
            categoryThemeId: this.state.categoryId,
            title: this.state.chapterTitle,
            homeworkId: this.props.data.homeworkId
        })

        // this.props.navigation.navigate('HomeworkConceptListScreen', {
        //     meta: {
        //         categoryThemeId: this.state.categoryId,
        //         title: this.state.chapterTitle,
        //         homeworkId: this.props.data.homeworkId
        //     }
        // })
    }

    onPressViewAnswers = () => {
        this.props.navigation.navigate('StandardHomeworkAnswers', {
            meta: {
                categoryThemeId: this.state.categoryId,
                title: this.state.chapterTitle,
                homeworkId: this.props.data.homeworkId
            }
        })
    }

    close = () => {
        this.props.navigation.navigate('LandingScreen', { params: { backTo: "LandingScreen" } });
    }

    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    showCommingSoon() {
        this.setState({ showCommingSoon: true })
    }

    hideCommingSoon() {
        this.setState({ showCommingSoon: false })
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToPracticeQuestionsChapterList() {
        this.props.delegate && this.props.delegate.goToPracticeQuestionsChapterList && this.props.delegate.goToPracticeQuestionsChapterList()
    }

    goToPracticeList() {
        this.props.delegate && this.props.delegate.selectedChapter && this.props.delegate.selectedChapter({ key: this.props.practice.chapterKey, title: this.props.chapterTitle })
    }

    renderBreadcrumbForPracticeQuestions() {
        return (
            <div className='breadcrumb mt-2 ml-4'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 cursor-pointer pl-2' onClick={() => this.goToPracticeQuestionsChapterList()}>{`Practice >`}</span>
                <span className='title-1 cursor-pointer pl-2' onClick={() => this.goToPracticeList()} >{`${this.props.chapterTitle ? this.props.chapterTitle : 'Chapter Title'}`}{` >`}</span>
                <span className='title-1 pl-2'>Practice Completed</span>
                {/* <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    goToBrowseChapter() {
        this.props.delegate && this.props.delegate.goToBrowseChapterSignleChapter && this.props.delegate.goToBrowseChapterSignleChapter({
            key: this.props.practice.chapterKey,
            title: this.props.practice.chapterTitle, categoryId: this.props.practice.categoryThemeId, chapterImage: this.props.data.chapterImageUrl
        }, 0, null)
    }

    getChapterAssessmentReport(item) {

        this.setState({ showChapterAssessmentReport: true, practiceId: item.practiceId })
    }

    hideChapterAssessmentReport() {
        this.setState({ showChapterAssessmentReport: false, practiceId: '' })
    }



    render() {

        if (this.state.showCommingSoon) {
            return <CwConfirmationModal showInsights={true} hideInsightsModal={() => this.hideCommingSoon()} />
        }

        if (this.state.showChapterAssessmentReport) {
            return <ChapterAssessmentPerformanceReport delegate={this}
                practiceId={this.state.practiceId}
                chapterTitle={this.props.chapterTitle}
                from={'COMPLETION_SCREEN'}
                questionType={this.props.practice.type === 'STR' ? 'Stuctured Response Questions' : 'Multiple Choice Questions'} />
        }


        let lgButton = `linear-gradient(${this.theme[`linearGradient`][`workoutEndCard`][0]}, ${this.theme[`linearGradient`][`workoutEndCard`][1]})`
        let textColor = this.theme[`themeColor`]
        let buttonBg = this.theme[`secondaryColors`][`dragItemBorder`]

        return (
            <>
                <div className="practice-completion-screen">
                    <img className="header-image-size" src={getThemeImageSource(ImageConstants.HOMEWORK_COMPLETION_SCREEN_HEADER, this.state.categoryId)} />
                    {
                        this.renderBreadcrumbForPracticeQuestions()
                    }
                    <div className="d-flex justify-content-center mt-5 mb-1">
                        <div className="great-message">{this.props.practice.type === 'STR' ? 'Stuctured Response Questions Practice Completed!' : `${this.props.practice.type} Practice Completed!`}</div>
                    </div>
                    {
                        this.props.practice.type === 'STR' ? <div className="non-descriptive-text mb-2 text-center">Non Descriptive Questions</div> : <></>
                    }
                    <div className="divider ml-4"></div>

                    <div className="chapter-title text-center mt-3">{this.props.chapterTitle}</div>
                    {
                        this.props.practice.type === 'STR' ?
                            <></>
                            :
                            <div className="lets-text text-center mt-2">Click on Insights to Look in to your scores</div>
                    }

                    <div className="revise-text text-center mt-2">Revise concepts for better scoring</div>

                    <div className="text-center">
                        <img className="workout-image-size mt-3" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_MALE_IMAGE, this.state.categoryId)} />
                    </div>

                    <div className="mt-5 d-flex justify-content-center align-items-center">
                        {
                            this.props.practice.type === 'STR' ?
                                <></> :
                                <div className="button text-center ">
                                    <div className="cursor-pointer" onClick={() => this.getChapterAssessmentReport(this.props.practice)} >
                                        {"Insights"}
                                    </div>
                                </div>
                        }

                        <div className="learning-gap-button text-center ml-4">
                            <div className="cursor-pointer" onClick={() => this.goToBrowseChapter()}>
                                {"Bridge Learning Gaps"}
                            </div>
                        </div>
                        {/* <div className="continue-button text-center ml-4">
                            <div className="cursor-pointer" >
                                {"Continue to Next Set"}
                            </div>
                        </div> */}
                    </div>

                    <img className="footer-image-size " src={getThemeImageSource(ImageConstants.HOMEWORK_COMPLETION_SCREEN_FOOTER, this.state.categoryId)}></img>
                </div>
            </>
        )
    }




}
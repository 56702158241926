import React from "react";
import './clicker-schools.scss';
import { getThemeImageSource } from "../../utility/image-source";
import { ImageConstants } from "../../constants/constants";
import AssessmentApi from "../../apis/assessment";
import Loading from "../common/loading/loading";
import Path from "../routes/routes-path";
import PaginationComponent from "../common/cw-pagination/cw-pagination-v2";

class ClickerSchools extends React.Component {

    state = {
        studentList: []
    }

    currentPage = 1
    limit = 10
    filter = {}

    componentDidMount() {
        this.getClickerList({ page: 1 }, {})
    }

    getClickerList(payload, filter) {

        // let payload = { ignoreLimit: true }

        if (!payload) payload = filter || {}

        payload.page = filter.page || 1
        payload.limit = this.limit
        payload.ignoreConfigLimit = true

        this.currentPage = payload.page

        this.setState({ isLoading: true })

        AssessmentApi.getClickerList(payload).then(res => {
            // console.log(res)
            this.setState({ studentList: res.clickerUserList, meta: res.meta || {}, }, () => {
                this.setState({ isLoading: false })
                // this.handleStartListening()
            })
        }).catch(err => {
            this.setState({ isLoading: false })
            console.log(err)
        })
    }

    onPageSelected = (selectedPage, startIndex) => {
        this.currentPage = selectedPage

        this.filter.page = selectedPage

        this.getClickerList({}, this.filter)
    }

    goToHomeScreen() {
        this.props.history.replace({ pathname: Path.CLICKER_FLOW_QUIZ })
    }


    renderBreadcrumb() {
        return (

            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 cursor-pointer title-1' onClick={() => this.goToHomeScreen()}>{`Clicker Schools List`}</span>

                {/* <span className='pl-2 title-1'>{`Start Clicker Flow`}</span> */}
            </div>

        )
    }


    render() {
        return (
            <div className="clicker-schools-list">
                <div className="text-right">
                    <img className="image-size" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, 1)}></img>
                </div>
                <div className="header-for-practice-question">
                    {this.renderBreadcrumb()}
                    {/* <div>
                        <img className="chapter-icon mr-1" src={this.state.selectedChapter.chapterImage ? this.state.selectedChapter.chapterImage : getImageSource(ImageConstants.CHAPTER_ICN)} />
                        <span className="chapter-title">{this.state.selectedChapter.title}</span>
                    </div> */}
                    <div className="sub-header-text">{"Clicker Schools List"}</div>
                </div>
                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, 1)}></img>
                </div>
                <div className="table-bg">
                    <div id="table-scoll" className="table-responsive mt-1">
                        <table className="table cw-table" style={{ width: '98%' }}>
                            <thead style={{ height: "5rem" }}>
                                <tr>
                                    {/* <th className='th-left-radius'>
                                    </th> */}
                                    <th className="header-text th-left-radius" style={{ width: "30%" }}>Clicker Number</th>
                                    <th className="header-text" style={{ width: "80%" }}>School Name</th>
                                    {/* <th className="th-right-radius header-text" style={{ width: "20%" }}>ID</th> */}
                                    {/* <th className="text-center">Previous Year Marks</th> */}
                                    {/* <th className='th-right-radius list-actions-header'>
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {
                                    this.state.studentList && this.state.studentList.length ? this.state.studentList.map(item => (
                                        <>
                                            {
                                                !item.isTeacher ?
                                                    <tr className="mt-4 " style={{ height: '5rem' }}>
                                                        <td className="student-table-row">

                                                            <div className="question-number-circle">
                                                                <div className="question-number">{item.studentNumber}</div>
                                                            </div>
                                                        </td>
                                                        <td className="student-table-row">{item.userName}</td>
                                                        {/* <td className="student-table-row">{item.userDeviceMacId}</td> */}
                                                    </tr>
                                                    :
                                                    <></>
                                            }
                                        </>

                                    ))
                                        :
                                        <Loading />
                                    // <tr className="bg-white pt-4"><td colSpan="11" align='center'>No Records found</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='card-footer'>
                    {
                        this.state.meta?.totalPages ?
                            <PaginationComponent startIndex={this.startIndex} selectedPage={this.currentPage} totalPages={this.state.meta?.totalPages} onPageSelected={this.onPageSelected} /> : ''
                    }
                </div>
            </div>
        )
    }


}

export default ClickerSchools;
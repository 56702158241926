import React from "react";
import './my-profile.scss';
import { ImageConstants } from "../../constants/constants";
import { getImageSource } from "../../utility/image-source";
import Path from "../routes/routes-path";
import SharedProfile from "../../../shared-storage/profile";
import AddProfileImagePopup from "./add-profile-image-popup/add-profile-image-popup";
import EditProfileScreen from "./edit-profile-screen/edit-profile-screen";
import CommonApi from "../../apis/common";

class MyProfile extends React.Component {

    constructor(props) {
        super(props)
        let userInfo = SharedProfile.getProfile()
        this.state.userInfo = userInfo
        this.inputRef = React.createRef();
    }


    state = {
        text: '',
        translatedText: '',
    }

    componentDidMount() {
        window.google.load('elements', '1', {
            packages: 'transliterate',
            callback: this.onGoogleScriptLoad,
        });
    }

    onGoogleScriptLoad = () => {
        const { targetLanguage } = 'hi'; // Target language code (e.g., 'hi' for Hindi)

        const options = {
            sourceLanguage:
                window.google.elements.transliteration.LanguageCode.ENGLISH,
            destinationLanguage: [targetLanguage],
            transliterationEnabled: true,
        };

        // Initialize TransliterationControl
        const control = new window.google.elements.transliteration.TransliterationControl(
            options
        );

        console.log(control)


        // Make the input field transliteratable
        control.makeTransliteratable([this.inputRef.current]);

        // Set up event listener for transliteration updates
        control.addEventListener(
            window.google.elements.transliteration.TransliterationControl.EventType
                .TRANSLITERATION_STATE_CHANGED,
            this.handleTransliterationChange
        );

        this.setState({ transliterationControl: control });


        // // Add event listener to update state with transliterated text
        // control.addEventListener(
        //     window.google.elements.transliteration.TransliterationControl.EventType.
        //         SERVER_UNREACHABLE,
        //     (event) => {
        //         this.setState({ transliteratedText: event.targetLanguageWord });
        //     });

        // // Add event listener to update state with transliterated text
        // control.addEventListener(
        //     window.google.elements.transliteration.TransliterationControl.EventType.
        //         SERVER_UNREACHABLE,
        //     (event) => {
        //         this.setState({ transliteratedText: event.targetLanguageWord });
        //     });
    }

    goToHomeScreen() {
        this.props.history.push(Path.HOME)
    }

    showEditProfileImagePopup() {
        this.setState({ showEditProfileImagePopup: true })
    }

    hideEditProfileImagePopup() {
        this.setState({ showEditProfileImagePopup: false })
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1'>{`Profile`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    showProfileEditScreen() {
        this.setState({ showProfileEditScreen: true })
    }

    hideProfileEditScreen() {
        this.setState({ showProfileEditScreen: false })
    }

    handleInputChange = (event) => {
        const inputValue = event.target.value;
        console.log(inputValue)
        // Update the input value in component state
        this.setState({ inputValue });

        // Manually trigger transliteration update
        this.updateTransliteration(inputValue);
    };

    handleTransliterationChange = (event) => {
        const { state } = event;

        // Update the transliterated text in component state
        this.setState({ transliteratedText: state.transliteratedWords });
    };

    updateTransliteration = (inputValue) => {
        const { transliterationControl } = this.state;
        console.log(transliterationControl)
        if (transliterationControl) {
            // Update transliteration with the current input value
            transliterationControl.setLanguagePair(
                window.google.elements.transliteration.LanguageCode.ENGLISH,
                'hi'
            );
            transliterationControl.transliterate([inputValue]);
        }
    };

    handleTextChange = (e) => {
        this.setState({ text: e.target.value });
    };

    translateText = () => {
        const { text } = this.state;
        const targetLanguage = 'hi'; // Example: French
        let payload = {
            text,
            targetLanguage
        }

        CommonApi.getTranslatedText(payload).then(res => {
            console.log(res.translatedText.data.translations[0].translatedText)
        }).catch(err => {
            console.log(err)
        })
    };

    render() {

        const { text, translatedText } = this.state;

        if (this.state.showProfileEditScreen) {
            return (
                <EditProfileScreen delegate={this} editProfileInfo={true} />
            )
        }
        const { inputValue, transliteratedText } = this.state;

        return (
            <div className="my-profile mb-4">
                <div className="text-right">
                    <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                </div>
                {/* <div className="mb-5">
                    <textarea value={text} onChange={this.handleTextChange} placeholder="Enter text to translate" />
                    <button onClick={this.translateText}>Translate</button>
                    <div>
                        <h3>Translated Text:</h3>
                        <p>{translatedText}</p>
                    </div>
                </div> */}
                <div className="header-title-margin-top">
                    {this.renderBreadcrumbForChapterList()}
                    <div className="d-flex justify-content-between">
                        <div className="title">Profile</div>

                    </div>

                    <div>
                        <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="profile-circle d-flex justify-content-center align-items-center">
                        {
                            this.state.userInfo?.basePath ? <img className="std-image" src={`${this.state?.userInfo?.basePath + this.state?.userInfo?.image}`}></img> : <img className="std-image" src={getImageSource(ImageConstants.STUDENT_IMAGE)}></img>
                        }

                        <div className="edit-icon d-flex justify-content-center align-items-center">
                            <div>
                                <img className="pen-edit-icon cursor-pointer" onClick={() => this.showEditProfileImagePopup()}
                                    src={getImageSource(ImageConstants.PEN_EDIT_ICON)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-info mt-4">
                    <div className="profile-info-header d-flex justify-content-between align-items-center">
                        <div className="info-text">Profile Info</div>
                        <div className="info-text cursor-pointer" onClick={() => this.showProfileEditScreen()}>Edit <img className="info-edit-icon ml-1 " src={getImageSource(ImageConstants.PEN_EDIT_ICON)} /></div>
                    </div>
                    <div className="field-wrapper">
                        <div className="field-name">FULL NAME</div>
                        <div className="field-value">{this.state.userInfo.name}</div>
                    </div>
                    <div className="field-wrapper">
                        <div className="field-name">NICK NAME</div>
                        <div className="field-value">{this.state.userInfo.nickName}</div>
                    </div>
                    <div className="field-wrapper">
                        <div className="field-name">MOBILE NO</div>
                        <div className="field-value">{this.state.userInfo.countryCode}-{this.state.userInfo.mobileNumber}</div>
                    </div>
                    <div className="field-wrapper">
                        <div className="field-name">EMAIL ID</div>
                        {
                            this.state.userInfo.email ? <div className="field-value">{this.state.userInfo.email}</div> : <div className="place-holder-text">{"Enter Your Parent Email Id"}</div>
                        }
                    </div>
                    <div className="field-wrapper">
                        <div className="field-name">BOARD</div>
                        {this.state.userInfo.board ?
                            <div className="field-value">{this.state.userInfo.alternateBoard ? this.state.userInfo.alternateBoard : this.state.userInfo.board}</div>
                            :
                            <div className="place-holder-text">Enter Your Board</div>}

                    </div>
                    <div className="field-wrapper">
                        <div className="field-name">SCHOOL</div>
                        {this.state.userInfo.schoolName ?
                            <div className="field-value">{this.state.userInfo.schoolName}</div>
                            :
                            <div className="place-holder-text">Connect Your School</div>
                        }
                        {this.state.userInfo && this.state.userInfo.schoolVerificationCode ?
                            <div className="school-code">SCHOOL CODE: {this.state.userInfo.schoolVerificationCode}</div>
                            : <></>
                        }
                    </div>

                    <div className="field-wrapper">
                        <div className="field-name">CLASS</div>
                        <div className="field-value">{this.state.userInfo.class}</div>
                    </div>

                    {
                        this.state.userInfo && this.state.userInfo.schoolCode ?
                            <div className="field-wrapper">
                                <div className="field-name">SECTION</div>
                                {this.state.userInfo.section ?
                                    <div className="field-value">{this.state.userInfo.section}</div>
                                    :
                                    <div className="place-holder-text">Enter Your Class Section</div>
                                }

                            </div>
                            : <></>
                    }

                    <div className="field-wrapper no-border">
                        <div className="field-name">CITY</div>
                        {this.state.userInfo.city ?
                            <div className="field-value">{this.state.userInfo.city}{this.state.userInfo.state ? ', ' + this.state.userInfo.state : ''}{this.state.userInfo.country ? ', ' + this.state.userInfo.country : ''}</div>
                            :
                            <div className="place-holder-text">Enter Your City</div>}

                    </div>

                    {/* <div className="delete-wrapper">
                        <img className="delete-image mr-2" src={getImageSource(ImageConstants.DELETE_RED)} />
                        <span className="field-value">Delete Account</span>
                    </div> */}

                </div>
                {
                    this.state.showEditProfileImagePopup ? <AddProfileImagePopup delegate={this} userInfo={this.state.userInfo} /> : <></>
                }
            </div>
        )
    }
}

export default MyProfile;
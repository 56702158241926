import AsyncStorage from '@createnextapp/async-local-storage';
import { Constants } from '../home/constants/constants';

const getWelcomePopupInfo = async () => {
  let getStarted = 'NO';
  try {
    getStarted = await AsyncStorage.getItem('welcomePopupInfo')
  } catch (error) {
    //console.log(error.message);
  }
  return getStarted;
}

const setWelcomePopupInfo = async (doc) => {

  try {
    await AsyncStorage.setItem('welcomePopupInfo', JSON.stringify(doc));
  } catch (error) {
    //console.log(error.message);
  }
}

const getReducedTimePopup = async () => {
  let getStarted = 'NO';
  try {
    getStarted = await AsyncStorage.getItem('reducedTimePopup')
  } catch (error) {
    //console.log(error.message);
  }
  return getStarted;
}

const setReducedTimePopup = async (doc) => {
  try {
    //console.log('set reduced time')
    await AsyncStorage.setItem('reducedTimePopup', JSON.stringify(doc));
  } catch (error) {
    //console.log(error.message);
  }
}

const setStduentSignupHashKey = async (key) => {

  try {
    await AsyncStorage.setItem('studentSignupHashKey', key);
  } catch (error) {
    //console.log(error.message);
  }
}

const getStudentSignupHashKey = async () => {
  let getStarted = '';
  try {
    getStarted = await AsyncStorage.getItem('studentSignupHashKey')
  } catch (error) {
    //console.log(error.message);
  }
  return getStarted;
}

const getUniqueKey = async () => {
  let key = '';
  try {
    key = await AsyncStorage.getItem(Constants.ASYNC_UNIQUE_KEY);
  } catch (error) {
    //console.log(error.message);
  }
  return key;
}

const setUniqueKey = async (key) => {
  try {
    await AsyncStorage.setItem(Constants.ASYNC_UNIQUE_KEY, key);
  } catch (error) {
    //console.log(error.message);
  }
}

const setKnowMoreRead = async (doc) => {

  try {
    await AsyncStorage.setItem('knowmore', JSON.stringify(doc));
  } catch (error) {
    //console.log(error.message);
  }
}


const getKnowMoreRead = async () => {
  let knowmoreData = null
  try {
    knowmoreData = await AsyncStorage.getItem('knowmore')
  } catch (error) {
    //console.log(error.message);
  }
  return knowmoreData;
}

const getLastCrossLinkStored = async () => {
  let getStarted = 'NO';
  try {
    getStarted = await AsyncStorage.getItem('bookmarkHelp')
  } catch (error) {
    //console.log(error.message);
  }
  return getStarted;
}

const setLastCrossLinkStored = async (doc) => {
  try {
    await AsyncStorage.setItem('bookmarkHelp', JSON.stringify(doc));
  } catch (error) {
    //console.log(error.message);
  }
}

const getAppTime = async () => {
  let getStarted = 'NO';
  try {
    getStarted = await AsyncStorage.getItem('appTime')
  } catch (error) {
    //console.log(error.message);
  }
  return getStarted;
}

const setAppTime = async (doc) => {
  try {
    await AsyncStorage.setItem('appTime', JSON.stringify(doc));
  } catch (error) {
    //console.log(error.message);
  }
}

const removeAppTime = async () => {
  try {
    await AsyncStorage.removeItem('appTime');
  } catch (error) {
    //console.log(error.message);
  }
}

const setTimeUpWarning = async (doc) => {

  try {
    await AsyncStorage.setItem('timeupwarning', JSON.stringify(doc));
  } catch (error) {
    //console.log(error.message);
  }
}


const getTimeUpWarning = async () => {
  let knowmoreData = null
  try {
    knowmoreData = await AsyncStorage.getItem('timeupwarning')
  } catch (error) {
    //console.log(error.message);
  }
  return knowmoreData;
}

const getDailyLimitPopupInfo = async () => {
  let getStarted = 'NO';
  try {
    getStarted = await AsyncStorage.getItem('dailyLimitPopupInfo')
  } catch (error) {
    //console.log(error.message);
  }
  return getStarted;
}

const setDailyLimitPopupInfo = async (doc) => {

  try {
    await AsyncStorage.setItem('dailyLimitPopupInfo', JSON.stringify(doc));
  } catch (error) {
    //console.log(error.message);
  }
}

const getRefresherCourseCompletedInfo = async () => {
  let getStarted = 'NO';
  try {
    getStarted = await AsyncStorage.getItem('refresherCourseCompletedInfo')
  } catch (error) {
    console.log(error.message);
  }
  return getStarted;
}

const setRefresherCourseCompletedInfo = async (doc) => {

  try {
    await AsyncStorage.setItem('refresherCourseCompletedInfo', JSON.stringify(doc));
  } catch (error) {
    console.log(error.message);
  }
}

const getEventScholarshipClaimVideo = async () => {
  let getStarted = 'NO';
  try {
    getStarted = await AsyncStorage.getItem('EventScholarshipClaimVideo')
  } catch (error) {
    console.log(error.message);
  }
  return getStarted;
}

const setEventScholarshipClaimVideo = async (doc) => {

  try {
    await AsyncStorage.setItem('EventScholarshipClaimVideo', JSON.stringify(doc));
  } catch (error) {
    console.log(error.message);
  }
}


let AsyncStore = {}

export default AsyncStore = {
  getWelcomePopupInfo,
  setWelcomePopupInfo,
  getReducedTimePopup,
  setReducedTimePopup,
  setStduentSignupHashKey,
  getStudentSignupHashKey,
  setUniqueKey,
  getUniqueKey,
  setKnowMoreRead,
  getKnowMoreRead,
  getLastCrossLinkStored,
  setLastCrossLinkStored,
  setAppTime,
  getAppTime,
  removeAppTime,
  setTimeUpWarning,
  getTimeUpWarning,
  getDailyLimitPopupInfo,
  setDailyLimitPopupInfo,
  setRefresherCourseCompletedInfo,
  getRefresherCourseCompletedInfo,
  getEventScholarshipClaimVideo,
  setEventScholarshipClaimVideo
}
import React from "react";
import './my-classwork.scss';
import SharedProfile from "../../../shared-storage/profile";
import SharedStaticText from "../../../shared-storage/static-text-store";
import HomeworkApi from "../../apis/homework";
import Loading from "../common/loading/loading";
import ClassworkHeader from "./my-classwork-header/my-classwork-header";
import MyClassworkStat from "./my-classwork-stat/my-classwork-stat";
import ConceptsPopup from "./concepts-popup/concepts-popup";
import StandardClassWorkExperience from '../class-work/standard-classwork-experience';
import ClassworkConceptListScreen from './classwork-concept-list-screen/classwork-concept-list';
import StandardClassworkViewAnswerScreen from './standard-classwork-view-answer/standard-classwork-view-answer';
import { getImageSource } from "../../utility/image-source";
import { ImageConstants } from "../../constants/constants";
import HomeworkFilter from './classwork-filter/classwork-filter';
import ChapterListPopup from "./chapter-list-popup/chapter-list-popup";
import StartAssessment from "../common/start-assessment/start-assessment";
import AssessmentEngine from "../common/assessment-engine/assessment-engine";
import AssessmentEndScreen from "../my-assessments/assessment-end-screen/assessment-end-screen";
import ViewAnswerTimer from "./view-answer-timer-popup-for-classwork/view-answer-timer";
import MyAssessmentResult from "../my-assessment-result/my-assessment-result";

export default class MyHomeworks extends React.Component {

    constructor(props) {
        super(props);
        this.userInfo = SharedProfile.getProfile();
        this.staticText = SharedStaticText.getStaticText('homeworkList');
        // this.screenName = this.props.navigation.state.routeName
        // this.props.navigation.addListener('didFocus', (data) => {
        this.setState({ fullScreenLoading: true })
        this.getUserHomeworkActivities({}, null, null, true);
        // });

    }

    componentDidMount() {
        // this.getUserHomeworkActivities({});
        // this.getChapterList();
    }

    filters = null;
    payload = {}
    BASE_PATH = '';
    page = 1;
    totalPages = 0;
    onEndReachedCalledDuringMomentum = true
    videoPlayerRefs = {};
    PAGE_SIZE = 1

    state = {
        showShadow: false,
        content: [],
        chapters: [],
        loading: false,
        // fullScreenLoading: true,
        showAssessmentFilterModal: false,
        selectChapterModal: false,
        assessmentMeta: null,
        conceptsList: [],
        viewAnswerTime: "",
        showStandardClassworkExperience: false,
        homeworkMetaInfo: {
            // categoryThemeId : 1,
            // title : "Knowing Our Numbers",
            // homeworkId : '649c05f72d13afcd37cc5e1d'
        },
        showHomeworkConceptListScreen: false,
        tabIndex: 2,
        showActiveFilter: false
    }

    conceptsList = [
        {
            conceptTitle: 'Concept 1',
            elos: [
                {
                    eloId: "",
                    title: 'elo 1'
                },
                {
                    eloId: "",
                    title: 'elo 2'
                },
            ]
        },
        {
            conceptTitle: 'Concept 2',
            elos: [
                {
                    eloId: "",
                    title: 'elo 1 elo 1 elo 1 elo 1 elo 1 elo 1elo 1elo 1elo 1elo 1 elo 1 elo 1 elo 1 elo 1 elo 1 elo 1 elo 1 elo 1'
                },
                {
                    eloId: "",
                    title: 'elo 2'
                },
                {
                    eloId: "",
                    title: 'elo 3'
                },
                {
                    eloId: "",
                    title: 'elo 4'
                },
            ]
        },

        {
            conceptTitle: 'Concept 3',
            elos: [
                {
                    eloId: "",
                    title: 'elo 1'
                },
                {
                    eloId: "",
                    title: 'elo 2'
                },
            ]
        },
        {
            conceptTitle: 'Concept 4',
            elos: [
                {
                    eloId: "",
                    title: 'elo 1'
                },
                {
                    eloId: "",
                    title: 'elo 2'
                },
                {
                    eloId: "",
                    title: 'elo 3'
                },
                {
                    eloId: "",
                    title: 'elo 4'
                },
                {
                    eloId: "",
                    title: 'elo 1'
                },
                {
                    eloId: "",
                    title: 'elo 2'
                },
                {
                    eloId: "",
                    title: 'elo 3'
                },
                {
                    eloId: "",
                    title: 'elo 4'
                },
            ]
        },
        {
            conceptTitle: 'Concept 4',
            elos: [
                {
                    eloId: "",
                    title: 'elo 1'
                },
                {
                    eloId: "",
                    title: 'elo 2'
                },
                {
                    eloId: "",
                    title: 'elo 3'
                },
                {
                    eloId: "",
                    title: 'elo 4'
                },
                {
                    eloId: "",
                    title: 'elo 1'
                },
                {
                    eloId: "",
                    title: 'elo 2'
                },
                {
                    eloId: "",
                    title: 'elo 3'
                },
                {
                    eloId: "",
                    title: 'elo 4'
                },
            ]
        },
    ]


    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    getUserHomeworkActivities = (payload, isFilter, append, getFilter) => {

        payload = payload ? payload : {}

        payload.type = ["ADAPTIVE", "STANDARD", "NORMAL"]

        HomeworkApi.getUserHomeworkActivities(payload).then((res) => {

            //console.log(res)

            if (this.state.loading)
                this.toggleLoading(false)

            if (this.state.fullScreenLoading)
                this.toggleFullScreenLoading(false)



            this.totalPages = res.totalPages;
            if (getFilter) {
                this.getFilters((err, filterResponse) => {

                    if (err)
                        return;

                    this.filters = filterResponse;
                })
            }

            if (append) {

                let content = this.state.content;
                content = content.concat(res.contents)
                this.setState({ content })
            } else {

                if (res.contents.length > 0) {


                    let completedHomework = [], notCompletedHomework = []


                    res.contents.forEach(item => {

                        if (item.status === 'COMPLETED') {
                            completedHomework.push(item)
                        } else {
                            notCompletedHomework.push(item)
                        }
                    })


                    //console.log(notCompletedHomework)


                    this.setState({ content: res.contents, completedHomework, notCompletedHomework })
                } else {
                    this.setState({ content: res.contents, showSelectChapterModal: true })
                }

            }

            if (isFilter) {
                this.filters = res;
            }
        }).catch(err => {
            if (err) {
                return;
            }
        })

    }

    getFilters(callback) {

        let payload = {}

        HomeworkApi.getHomeworkFilters(payload).then((res) => {
            callback(null, res)
        }).catch(err => {
            callback(err, null)
        })

    }


    toggleLoading = (value, callback) => {
        this.setState({ loading: value }, () => {
            callback && callback()
        });
    }

    toggleFullScreenLoading = (value, callback) => {
        this.setState({ fullScreenLoading: value }, () => {
            callback && callback()
        })
    }

    scollEndReached = () => {

        //console.log("scrolled to bottom")

        if (this.totalPages <= this.page)
            return;

        this.page = this.page + 1;

        let payload = {
            ...this.payload,
            page: this.page
        }

        this.toggleLoading(true)
        this.getUserHomeworkActivities(payload, null, true)

    }

    onApplyFilter = (filterPayload) => {

        this.page = 1;

        this.payload = {}

        this.selectedFilters = filterPayload;

        if (filterPayload.filterType == 'CHAPTERS') {

            let chapterKeys = filterPayload.selectedItems.map(item => item.key)

            this.payload['chapterKeys'] = chapterKeys.join(',');
        }

        if (filterPayload.filterType == 'CONTENT') {

            let status = filterPayload.selectedItems.map(item => item.value)

            this.payload['status'] = status.join(',');
        }

        if (filterPayload.selectedItems.length > 0) {

            this.setState({ showActiveFilter: true })

        } else {

            this.setState({ showActiveFilter: false })
        }

        this.hideAssessmentFilterModal(() => {
            setTimeout(() => {
                this.toggleFullScreenLoading(true, () => {
                    this.getUserHomeworkActivities(this.payload, false)
                })
            }, 100);

        })
    }

    goBack = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    showAssessmentFilterModal = () => {
        this.setState({ showAssessmentFilterModal: true })
    }

    hideAssessmentFilterModal = (callback) => {
        this.setState({ showAssessmentFilterModal: false }, () => {
            callback && callback()
        })
    }

    close = () => {
        this.props.navigation.goBack()
    }

    viewAnswers = (item) => {

        //console.log(item)

        if (item.type == 'ASSESSMENT') {

            // this.props.navigation.navigate('MyAssessmentResult', {
            //     meta: {
            //         categoryThemeId: item.categoryId,
            //         chapterTitle: "Multiple Chapters Assessment",
            //         chapterKey: "",
            //         assessmentActivityId: item.assessmentActivityId,
            //         fromAssess: true,
            //         type: "HOMEWORK",
            //         from: "HOMEWORK"
            //     }
            // })
            // return

            //console.log('inside assessment')

            if (item.isDueDatePassed) {

                this.showViewAnswerScreen({meta: {
                    categoryThemeId: item.categoryId,
                    chapterTitle: "Multiple Chapters Assessment",
                    chapterKey: "",
                    assessmentActivityId: item.assessmentActivityId,
                    fromAssess: true,
                    type: "HOMEWORK",
                    from: "HOMEWORK",
                    fromHomeworkList : true
                }})

                // this.props.navigation.navigate('MyAssessmentResult', {
                //     meta: {
                //         categoryThemeId: item.categoryId,
                //         chapterTitle: "Multiple Chapters Assessment",
                //         chapterKey: "",
                //         assessmentActivityId: item.assessmentActivityId,
                //         fromAssess: true,
                //         type: "HOMEWORK",
                //         from: "HOMEWORK"
                //     }
                // })
                return
            } else {
                this.setState({ showViewAnswerPopUp: true, viewAnswerTime: item.endTime, fromHomeworkList : true })
                return
            }
        }

        // this.props.navigation.navigate('StandardHomeworkAnswers', {
        //     meta: {
        //         categoryThemeId: item.categoryId,
        //         title: item.chapterTitle,
        //         homeworkId: item.homeworkId
        //     }
        // })

        this.setState({
            showViewAnswerScreen: true,
            metaForViewAnswer: {
                categoryThemeId: item.categoryId,
                title: item.chapterTitle,
                homeworkId: item.homeworkId,
                from : "HOMEWORK"
            }
        })

    }

    handleAssessmenets = (item) => {

        let chapterKey = item.chapters.map(data => data.key)

        let chapters = []

        for (let i = 0; i < item.chapters.length; i++) {
            chapters.push(item.chapters[i].title)
        }

        //console.log(item.categoryId)

        if (item.status == 'ASSIGNED') {

            this.setState({
                showAssessmentStartScreen: true,
                startAssessmentMetaInfo: {
                    chapterKey: chapterKey.join(),
                    chapterTitle: item.chapters && item.chapters.length ? item.chapters[0].title : "",
                    categoryThemeId: item.categoryId,
                    selectedChapters: item.chapters && item.chapters.length > 1 ? item.chapters : null,
                    homeworkChapters: chapters.join(),
                    duration: item.duration,
                    isFromMyHomework: true,
                    homeworkActivityId: item._id,
                    isDueDatePassed: item.isDueDatePassed,
                    viewAnswerTime: item.endTime,
                    homework: item,
                    from : "HOMEWORK"
                }
            })


            // this.props.navigation.navigate('StartAssessment', {
            //     meta: {
            //         chapterKey: chapterKey.join(),
            //         chapterTitle: item.chapters && item.chapters.length ? item.chapters[0].title : "",
            //         categoryThemeId: item.categoryId,
            //         selectedChapters: item.chapters && item.chapters.length > 1 ? item.chapters : null,
            //         homeworkChapters: chapters.join(),
            //         duration: item.duration,
            //         isFromMyHomework: true,
            //         homeworkActivityId: item._id,
            //         isDueDatePassed: item.isDueDatePassed,
            //         viewAnswerTime: item.endTime,
            //         homework: item
            //     }
            // })
        } else if (item.status == 'IN_PROGRESS') {

            // moengage.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, "Chapter Key": item.chapterKey ? item.chapterKey : '', status: 'Resume', Chapters: item.chapters && item.chapters.length > 0 ? chapters.toString() : item.chapters[0].title, 'Starting Point': 'Homework' })
            // FireBaseEvent.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, status: 'Resume', Chapters: item.chapters && item.chapters.length > 0 ? chapters.toString() : item.chapters[0].title, 'Starting Point': 'Homework' })
            // Facebook.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, status: 'Resume', Chapters: item.chapters && item.chapters.length > 0 ? chapters.toString() : item.chapters[0].title, 'Starting Point': 'Homework' })
            // BranchIO.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, status: 'Resume', Chapters: item.chapters && item.chapters.length > 0 ? chapters.toString() : item.chapters[0].title, 'Starting Point': 'Homework' })

            this.showAssessmentEngine(
                {
                    chapterTitle: item.chapters && item.chapters.length ? item.chapters[0].title : "",
                    isResume: true,
                    assessmentActivityId: item.assessmentActivityId,
                    categoryThemeId: item.categoryId,
                    selectedChapters: item.chapters,
                    isFromMyHomework: true,
                    homeworkChapters: chapters.join(),
                    homeworkActivityId: item._id,
                    isDueDatePassed: item.isDueDatePassed,
                    viewAnswerTime: item.endTime,
                    homework: item,
                    from : "HOMEWORK"
                }
            )

            // this.props.navigation.navigate('AssessmentEngine', {
            //     meta: {
            //         chapterTitle: item.chapters && item.chapters.length ? item.chapters[0].title : "",
            //         isResume: true,
            //         assessmentActivityId: item.assessmentActivityId,
            //         categoryThemeId: item.categoryId,
            //         selectedChapters: item.chapters,
            //         isFromMyHomework: true,
            //         homeworkChapters: chapters.join(),
            //         homeworkActivityId: item._id,
            //         isDueDatePassed: item.isDueDatePassed,
            //         viewAnswerTime: item.endTime,
            //         homework: item
            //     }
            // })
        }
    }

    getHomeworkStatus = (item) => {

        if (item.type == 'ASSESSMENT') {
            this.handleAssessmenets(item)
            return
        }

        //console.log('>>>>::', item)

        let payload = {
            homeworkId: item.homeworkId
        }

        this.toggleFullScreenLoading(true)

        HomeworkApi.getHomeworkStatus(payload).then((res) => {
            let concepts = []

            if (item.conceptsCovered.length) {
                for (let i = 0; i < item.conceptsCovered.length; i++) {
                    concepts.push(item.conceptsCovered[i].title)
                }
            }

            //console.log(res)

            // moengage.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Techer: item.createdBy, 'Class': this.userInfo.class, 'Homework Type': item.type, 'Chapter Key': item.chapterKey ? item.chapterKey : '', 'Chapter': item.chapterTitle, 'Concepts': concepts.toString(), 'Status': this.getHomeworkStatusText(item.status), 'Learning Started': item.status == 'COMPLETED' ? 'Yes' : "No" })
            // FireBaseEvent.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Techer: item.createdBy, 'Class': this.userInfo.class, 'Homework Type': item.type, 'Chapter Key': item.chapterKey ? item.chapterKey : '', 'Chapter': item.chapterTitle, 'Concepts': concepts.toString(), 'Status': this.getHomeworkStatusText(item.status), 'Learning Started': item.status == 'COMPLETED' ? 'Yes' : "No" })
            // Facebook.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Techer: item.createdBy, 'Class': this.userInfo.class, 'Homework Type': item.type, 'Chapter Key': item.chapterKey ? item.chapterKey : '', 'Chapter': item.chapterTitle, 'Concepts': concepts.toString(), 'Status': this.getHomeworkStatusText(item.status), 'Learning Started': item.status == 'COMPLETED' ? 'Yes' : "No" })
            // BranchIO.logCustomEvent('Homework', { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Techer: item.createdBy, 'Class': this.userInfo.class, 'Homework Type': item.type, 'Chapter Key': item.chapterKey ? item.chapterKey : '', 'Chapter': item.chapterTitle, 'Concepts': concepts.toString(), 'Status': this.getHomeworkStatusText(item.status), 'Learning Started': item.status == 'COMPLETED' ? 'Yes' : "No" })

            if (res && res.notAttepmted && res.notAttepmted.length) {

                //console.log(res)

                this.setState({
                    showStandardClassworkExperience: true,
                    params: {
                        homeworkId: item.homeworkId,
                        nodeId: res.notAttepmted[0].id,
                        nodes: res.notAttepmted,
                        title: item.chapterTitle,
                        concepts: res.concepts,
                        categoryThemeId: item.categoryId,
                        conceptsCovered: concepts,
                        homework: item,
                        typeOfSchoolwork : "HOMEWORK"
                    }
                })

                // this.props.navigation.navigate("StandardHomeworkExperience", {
                //     params: {
                //         homeworkId: item.homeworkId,
                //         nodeId: res.notAttepmted[0].id,
                //         nodes: res.notAttepmted,
                //         title: item.chapterTitle,
                //         concepts: res.concepts,
                //         categoryThemeId: item.categoryId,
                //         conceptsCovered: concepts,
                //         homework: item
                //     }
                // })

            } else {
                this.setState({ showHomeworkConceptListScreen: true, homeworkMetaInfo: { details: res, homeworkId: item.homeworkId, title: item.chapterTitle, categoryThemeId: item.categoryId } })
            }


        }).catch(err => {
            if (err) {
                return
            }
        })
    }

    getHomeworkStatusText = (status) => {

        switch (status) {
            case 'ASSIGNED':
                return 'Start'

            case 'IN_PROGRESS':
                return 'In Progress'

            case 'COMPLETED':
                return 'Completed'
        }

    }

    onClickCard = (item) => {
        this.getHomeworkStatus(item)
        // this.props.navigation.navigate("HomeWorkCompletionScreen", { meta: {} })
    }

    showChaptersPopup = (item) => {
        this.chapterTitles = item.chapters
        this.setState({ showChaptersPopup: true })
    }

    showConceptsPopup = (item) => {
        //console.log('::::::item', item)
        this.setState({ showConceptsPopup: true, selectedChapterCategoryId: item.categoryId, conceptsList: item.conceptsCovered ? item.conceptsCovered : [] })
    }

    hideConceptsPopup = () => {
        this.setState({ showConceptsPopup: false })
    }

    hideChapters = () => {
        this.setState({ showChaptersPopup: false })
    }



    hideStandardClassworkExperience(data) {
        this.setState({ showStandardClassworkExperience: false, showHomeworkConceptListScreen: true, homeworkMetaInfo: data })
    }

    hideViewAnswer() {
        this.setState({ showViewAnswerScreen: false })
    }

    toggleTab(value) {
        this.setState({ tabIndex: value })
    }

    hideClassworkConceptListScreen() {
        this.setState({ showHomeworkConceptListScreen: false, showStandardClassworkExperience : false,  fullScreenLoading: true }, () => {
            this.getUserHomeworkActivities({}, null, null, true);
            // this.setState({fullScreenLoading : false})
        })
    }

    hideStartAssessmentScreen() {
        this.setState({ showAssessmentStartScreen: false })
    }

    showAssessmentEngine(data) {
        //console.log(data)
        this.setState({ showAssessmentEngine: true, showAssessmentStartScreen: false, assessmentEngineMetaInfo: data })
    }

    showAssessmentEndScreen(assessmentCompletedInfo) {
        //console.log(assessmentCompletedInfo)
        this.setState({ showAssessmentEngine: false, showAssessmentEndScreen: true, assessmentCompletedMetaInfo: assessmentCompletedInfo })
    }

    hideAssessmentEndScreen() {
        this.setState({ showAssessmentEndScreen: false }, () => {
            this.getUserHomeworkActivities({}, null, null, true);
        })
    }

    showViewAnswerTimerScreen(data) {
        this.setState({
            showViewAnswerPopUp: true, showAssessmentEndScreen: false,
            viewAnswerTime: data.viewAnswerTime,
            categoryThemeId: data.categoryThemeId 
        })
    }

    closeViewAnswerPopup = (data) => {
        //console.log(data)
        this.setState({ showViewAnswerPopUp: false })

        if (data.goToListScreen) {
            this.getUserHomeworkActivities({}, null, null, true);
        } else {
            this.showAssessmentEndScreen(data)
        }

    }


    showViewAnswerScreen(assessmentEndScreenInfo) {
        this.setState({
            showAssessmentEndScreen: false, showAssessmentViewAnswersScreen: true,
            assessmentEndScreenInfo: assessmentEndScreenInfo.meta
        })
    }

    hideViewAnswersScreen(value) {

        this.setState({ showAssessmentViewAnswersScreen: false })

        if (!value) {
            this.getUserHomeworkActivities({}, null, null, true);
        } else {
            this.setState({ showAssessmentEndScreen: true })
        }
    }







    render() {

        // return <HomeworkSuccessFailureScreen />

        //console.log(this.state.content)


        if (this.state.showStandardClassworkExperience) {
            return (
                <StandardClassWorkExperience params={this.state.params} delegate={this} />
            )
        }

        if (this.state.showHomeworkConceptListScreen) {
            return (
                <ClassworkConceptListScreen
                    meta={this.state.homeworkMetaInfo}
                    hideClassworkConceptListScreen={() => this.hideClassworkConceptListScreen()}
                    delegate={this} />
            )
        }

        if (this.state.showViewAnswerScreen) {
            return (
                <StandardClassworkViewAnswerScreen
                    metaForViewAnswer={this.state.metaForViewAnswer}
                    hideViewAnswerScreen={() => this.hideViewAnswer()}
                    delegate={this} />

            )
        }

        if (this.state.showAssessmentStartScreen) {
            return (
                <StartAssessment delegate={this} meta={this.state.startAssessmentMetaInfo} />
            )
        }

        if (this.state.showAssessmentEngine) {
            return (
                <AssessmentEngine meta={this.state.assessmentEngineMetaInfo} delegate={this} />
            )
        }

        if (this.state.showAssessmentEndScreen) {
            return (
                <AssessmentEndScreen metaInfo={this.state.assessmentCompletedMetaInfo} delegate={this}
                    showViewAnswerScreen={(fromAssess) => this.showViewAnswerScreen(fromAssess)} />
            )
        }

        if (this.state.showViewAnswerPopUp) {
            return (
                <ViewAnswerTimer delegate={this}
                    viewAnswerTime={this.state.viewAnswerTime}
                    categoryThemeId={this.state.categoryThemeId}
                    fromHomeworkList = {this.state.fromHomeworkList}
                    from = 'HOMEWORK'
                    assessmentCompletedMetaInfo={this.state.assessmentCompletedMetaInfo} />
            )
        }

        if (this.state.showAssessmentViewAnswersScreen) {
            return (
                <MyAssessmentResult delegate={this} meta={this.state.assessmentEndScreenInfo} />
            )
        }






        return (
            !this.state.fullScreenLoading ?
                <div className="my-classwork">
                    {
                        this.state.content && this.state.content.length ?
                            <div className="">
                                <ClassworkHeader delegate={this} showActiveFilter={this.state.showActiveFilter} />
                                <div className={`row row-max-width justify-content-center align-items-center mr-0 ml-0 mt-3 mb-3`}>
                                    <div className={`col text-center answer-status-text p-2 tab ${this.state.tabIndex == 2 ? 'not-completed-tab' : ''}`} onClick={() => { this.toggleTab(2) }}>
                                        <span className="tab-text">{"Not Completed"}</span>
                                    </div>
                                    <div className={`col text-center answer-status-text p-2 tab ${this.state.tabIndex == 1 ? 'completed-tab' : ''}`} onClick={() => { this.toggleTab(1) }}>
                                        <span className="tab-text">{"Completed"}</span>
                                    </div>

                                </div>
                                {
                                    this.state.content.map((item, index) => (
                                        <div key={item._id} className="card-wrapper">
                                            {
                                                this.state.tabIndex === 1 && item.status === 'COMPLETED' ?
                                                    <MyClassworkStat item={item} index={index} delegate={this} categoryId={item.categoryId} content={this.state.content} status={item.status} />
                                                    :
                                                    <></>
                                            }

                                            {
                                                this.state.tabIndex === 2 && item.status != 'COMPLETED' ?
                                                    <MyClassworkStat item={item} index={index} delegate={this} categoryId={item.categoryId} content={this.state.content} status={item.status} />
                                                    :

                                                    <></>


                                            }



                                        </div>
                                    ))
                                }
                                {
                                    this.state.tabIndex === 1 && !this.state.completedHomework.length &&
                                    <div>
                                        <div className="text-center mt-5">
                                            <img className="no-homework-image-size" src={getImageSource(ImageConstants.NO_CLASSWORK_IMAGE)} />
                                        </div>

                                        {this.state.showActiveFilter ?
                                            <div className="text-center no-homework-text">
                                                {this.staticText.noResultFoundText}
                                            </div>

                                            :
                                            <div className="mt-3 text-center">
                                                <div className="no-homework-text" >{"No Classwork Found"}</div>
                                                <div className="mt-2" >{"All Your Classwork Will appear here"}</div>
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.state.tabIndex === 2 && !this.state.notCompletedHomework.length &&
                                    <div>
                                        <div className="text-center mt-5">
                                            <img className="no-homework-image-size" src={getImageSource(ImageConstants.NO_CLASSWORK_IMAGE)} />
                                        </div>

                                        {this.state.showActiveFilter ?
                                            <div className="text-center no-homework-text">
                                                {this.staticText.noResultFoundText}
                                            </div>

                                            :
                                            <div className="mt-3 text-center">
                                                <div className="no-homework-text" >{"No Classwork Found"}</div>
                                                <div className="mt-2" >{"All Your Classwork Will appear here"}</div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <ClassworkHeader delegate={this} showActiveFilter={this.state.showActiveFilter} />
                                <div className="text-center mt-5">
                                    <img className="no-homework-image-size" src={getImageSource(ImageConstants.NO_CLASSWORK_IMAGE)} />
                                </div>

                                {this.state.showActiveFilter ?
                                    <div className="text-center no-homework-text">
                                        {this.staticText.noResultFoundText}
                                    </div>

                                    :
                                    <div className="mt-3 text-center">
                                        <div className="no-homework-text" >{"No Classwork Found"}</div>
                                        <div className="mt-2" >{"All Your Classwork Will appear here"}</div>
                                    </div>
                                }
                                {/* No Homework Found */}
                            </div>
                    }
                    {this.state.showAssessmentFilterModal && <HomeworkFilter close={this.hideAssessmentFilterModal} onSelect={this.onSelect} chapters={this.state.chapters} filter={this.filters} selectedFilters={this.selectedFilters} delegate={this} />}
                    {/* {/* {this.state.showSelectChapterModal && this.state.chapters.length > 0 && this.state.assessmentMeta && <SelectChapterModal chapters={this.state.chapters} assessmentMeta={this.state.assessmentMeta} delegate={this} />}
                    {this.state.showSelectDuration && <SelectDurationModal selectedChapters={this.state.selectedChapters} assessmentMeta={this.state.assessmentMeta} navigation={this.props.navigation} delegate={this} />} */}
                    {this.state.showConceptsPopup && <ConceptsPopup delegate={this} close={this.hideConceptsPopup} chapters={this.state.conceptsList} title={"Concepts"} categoryThemeId={this.state.selectedChapterCategoryId} />}
                    {this.state.showChaptersPopup && <ChapterListPopup modal={true} close={this.hideChapters} delegate={this} chapters={this.chapterTitles} categoryThemeId={this.chapterTitles && this.chapterTitles.length && this.chapterTitles[0].categoryThemeId ? this.chapterTitles[0].categoryThemeId : "1"} />}
                    {/* {this.state.showViewAnswerPopUp && <ViewAnswerTimePopUp viewAnswerTime={this.state.viewAnswerTime} close={this.closeViewAnswerPopup}></ViewAnswerTimePopUp>} */}
                </div>
                :
                <Loading />

        )
    }

}
import React from "react";
import './matching.scss';
import SharedThemeCategory from "../../../../../../shared-storage/category-theme";
import Parser from "../../../../common/content-parser/content-parser";
import CWImageV2 from "../../../../common/cw-image-2/cw-image-2";
import QuestionTitle from "../../../../common/question/question-title/question-title";
import QuestionImage from "../../../../common/cw-question-image/cw-question-image";
import { getThemeImageSource, getImageSource } from "../../../../../utility/image-source";
import { ImageConstants } from "../../../../../constants/constants";
import AnswerTab from "../../answer-tab/answer-tab";
import { Constants } from "../../../../../constants/constants";

class Matching extends React.Component {


    theme = {};
    state = {};

    constructor(props) {
        super(props);
        let content = JSON.stringify(this.props.content)
        this.state.content = JSON.parse(content);
        if (this.props.showMyAnswer) {
            this.showMyAnswer()
        }
        //this.options = this.props.content.content.options;
        this.parser = new Parser();
        this.theme = SharedThemeCategory.getThemeCategory(props.categoryThemeId);
    }

    componentDidMount() {

    }


    updateAnswer = (payload) => {

        let content = this.state.content
        let options = content.content.options;

        let contentRaw = JSON.stringify(this.props.content);
        contentRaw = JSON.parse(contentRaw);
        let optionsRaw = contentRaw.content.options;

        let optionHash = {}
        optionsRaw.map((option) => optionHash[option.id] = option);

        let answerHash = {}
        payload.answer.map((option) => answerHash[option.id] = option.selected[0].id);

        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            let answerOption = answerHash[option.id]
            option.answer = optionHash[answerOption].answer;
        }
        this.setState({ content: content });
    }

    showMyAnswer = () => {
        if (this.props.answer) {
            this.updateAnswer(this.props.answer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.stringify(this.props.content)
        this.setState({ content: JSON.parse(content) })
    }

    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer })
    }

    getContentUsingContentType(option, type) {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (option[type].contentType == Constants.CONTENT_TYPE_TEXT) {
            return this.parser.parseWithStyles(`${option[type].content}`, { p: styles.p, 'cw-math': styles.p })
        } else if (option[type].contentType == Constants.CONTENT_TYPE_IMAGE) {
            //console.log(option[type].content)
            return <CWImageV2 uri={option[type].content}></CWImageV2>
        }

    }





    renderReadOnlyItem(option) {
        return (
            <>
                {
                    option["answer"].contentType == Constants.CONTENT_TYPE_TEXT ?
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>
                        :
                        <div className="d-flex align-items-center justify-content-center">

                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>

                }
            </>

        )
    }

    

    render() {
        // setCategoryThemeStyles(this.theme)
        
        let content = this.state.content;
        return (
            this.getReadonlyView(content)
        );
    }

    getReadonlyView = (content) => {
        let textColor = this.theme['themeColor']
        let borderColor = this.theme[`themeColor`]
        let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        return (


            <div className="matching">

                <div>
                    <QuestionTitle title={content.content.title} hideBackground={true} />
                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                </div>

                {
                    this.props.status != "NOT_ATTEMPTED" ?
                        <div className="view-answer-button ml-1 mt-3 cursor-pointer" style={{color : textColor, borderColor : borderColor}} onClick={this.toggleViewAnswer}>
                            {
                                this.state.showCorrectAnswer ? 'Hide Answer' : 'View Answer'
                            }
                            {/* <span>View Answer</span> */}
                            <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} />
                        </div>
                        :
                        <></>
                }

                {
                    this.state.showCorrectAnswer ?
                        <>
                            {
                                this.props.status != "NOT_ATTEMPTED" ?
                                    <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.props.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.answer}></AnswerTab>
                                    : <></>
                            }

                            {
                                this.props.status != "NOT_ATTEMPTED" ?
                                    <div className="matching-question-container mt-4">
                                        {content.content.options.map((option, index) =>
                                            <div className="matching-question d-flex flex-row align-items-center mt-3" key={option.id + 'answer'}>

                                                <div className="question-card d-flex align-items-center justify-content-center" style={{borderColor : dragItemBorder}}>
                                                    {
                                                        option["question"].contentType == Constants.CONTENT_TYPE_TEXT ?
                                                            <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                            :
                                                            <div className="">{this.getContentUsingContentType(option, 'question')}</div>
                                                    }

                                                </div>
                                                <div><img className="arrow-image" src={getImageSource(ImageConstants.ARROW)} /></div>
                                                <div className={`question-card d-flex align-items-center justify-content-center position-relative`} style={{borderColor : dragItemBorder}}>
                                                    {
                                                        this.renderReadOnlyItem(option)
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    :
                                    <></>
                            }
                        </>

                        :

                        <></>
                }



            </div>


        )
    }

}

export default Matching;

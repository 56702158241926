import React, { Component } from "react";
import './cw-image-2.scss';


// const screenWidth = Dimensions.get('window').width;

// const FIXED_HEIGHT = 105;

export default class CWImageV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { source: { uri: this.props.uri } };
    }

    componentDidMount() {
        // Image.getSize(this.props.uri, (width, height) => {

        //     let scaledHeight = FIXED_HEIGHT;

        //     let scaledPercentage = (scaledHeight / height);

        //     let scaledWidth = width * scaledPercentage;

        //     this.setState({ height: scaledHeight, width: scaledWidth });

        //     this.setState({ height: widthPercentageToDP(FIXED_HEIGHT / 4), width: scaledWidth });
        // });
    }

    render() {
        return (
            <img className="option-image-width" src={this.state.source.uri}/>
        );
    }
}

import React from "react";
import './case-study-question-set-list.scss';
import { ImageConstants } from "../../../constants/constants";
import { getImageSource } from "../../../utility/image-source";
import PracticeApi from "../../../apis/practice";
import Loading from "../../common/loading/loading";
import CaseStudyStartScreen from "./case-study-start-screen/case-study-start-screen";
import AssessmentEngine from "../../common/assessment-engine/assessment-engine";
import CaseStudyEndScreen from "./case-study-end-screen/case-study-end-screen";
import MyAssessmentResult from "../../my-assessment-result/my-assessment-result";
import CwContentViewer from "../../common/cw-content-viewer/cw-content-viewer";
import ChapterAssessmentPerformanceReport from "../../chapter-assessment-performance-report/chapter-assessment-performance-report";

class CaseStudyQuestionSetList extends React.Component {

    state = {
        // setList: [
        //     {
        //         setName: 'Set 1',
        //         questionsCount: 15,
        //         approximateTime: 15
        //     },
        //     {
        //         setName: 'Set 2',
        //         questionsCount: 25,
        //         approximateTime: 20
        //     },
        //     {
        //         setName: 'Set 3',
        //         questionsCount: 25,
        //         approximateTime: 35
        //     }
        // ]
        isLoading: true,
        userPracticeActivity: [],
        showCaseStudyStartScreen: false,
        showAssessmentEndScreen: false,
        assessmentEndScreenInfo: {},
        showAssessmentViewAnswersScreen: false,
        isAllCaseStudyCompleted: false
    }

    componentDidMount() {
        console.log(this.props.selectedChapter)
        this.getUserPracticeActivity()
    }

    getUserPracticeActivity() {

        let payload = {
            type: 'CASE STUDY',
            chapterKey: this.props.selectedChapter.key
        }

        PracticeApi.getUserPracticeActivities(payload).then(res => {
            console.log(res)
            if (res.practiceActivity.length) {

                let allCaseStudyCompletedCount = 0, allCaseStudyCompleted = false

                res.practiceActivity.forEach(item => {
                    if (item.status === 'COMPLETED') {
                        allCaseStudyCompletedCount += 1
                    }
                })

                if (res.practiceActivity.length === allCaseStudyCompletedCount) {
                    allCaseStudyCompleted = true
                }

                this.setState({
                    userPracticeActivity: res.practiceActivity, isLoading: false,
                    isAllCaseStudyCompleted: allCaseStudyCompleted, caseStudyUrl: res.caseStudyUrl
                })
            } else {
                this.setState({ userPracticeActivity: [], isLoading: false })
            }
        }).catch(err => {
            console.log('get user Practice Activity Error', err)
        })


    }

    goBack() {
        this.hideCaseStudyStartScreen()
        this.props.back && this.props.back()
    }

    goToHomeScreen() {
        this.hideCaseStudyStartScreen()
        this.hidePracticeCaseStudyEndScreen()
        this.props.goToHomeScreen && this.props.goToHomeScreen()
    }

    goToPracticeQuestionsChapterList() {
        this.hideCaseStudyStartScreen()
        this.hidePracticeCaseStudyEndScreen()
        this.props.goToPracticeQuestionsChapterList && this.props.goToPracticeQuestionsChapterList()
    }

    navigate = (item) => {
        this.props.delegate && this.props.delegate.getPracticeStatus && this.props.delegate.getPracticeStatus(item)
    }


    onClickCard(item) {

        let chapterKey = item.chapters.map(data => data.key)

        let chapters = []

        for (let i = 0; i < item.chapters.length; i++) {
            chapters.push(item.chapters[i].title)
        }

        if (item.status == 'START') {
            // this.layoutShared.toggleSidebar(true, this.props.history.location.pathname)
            this.setState({
                showCaseStudyStartScreen: true,
                startAssessmentMetaInfo: {
                    chapterKey: chapterKey.join(),
                    chapterTitle: item.chapters && item.chapters.length ? item.chapters[0].title : "",
                    categoryThemeId: item.categoryId,
                    selectedChapters: item.chapters && item.chapters.length > 1 ? item.chapters : null,
                    practiceChapters: chapters.join(),
                    duration: item.duration,
                    isFromPracticeCaseStudy: true,
                    practiceActivityId: item._id,
                    viewAnswerTime: item.endTime,
                    practice: item,
                    from: "CASE STUDY"
                }
            })
        }

        if (item.status == 'IN_PROGRESS') {
            this.showAssessmentEngine(
                {
                    chapterTitle: item.chapters && item.chapters.length ? item.chapters[0].title : "",
                    isResume: true,
                    assessmentActivityId: item.assessmentActivityId,
                    categoryThemeId: item.categoryId,
                    selectedChapters: item.chapters,
                    isFromPracticeCaseStudy: true,
                    practiceChapters: chapters.join(),
                    practiceActivityId: item._id,
                    viewAnswerTime: item.endTime,
                    practice: item,
                    chapterKey: chapterKey.join(),
                    from: "CASE STUDY"
                }
            )
        }


    }

    hideCaseStudyStartScreen() {
        this.setState({ showCaseStudyStartScreen: false, selectedCaseStudy: '' })
    }

    goToSelectedChapter() {
        this.props.delegate && this.props.delegate.goToSelectedChapter && this.props.delegate.goToSelectedChapter()
    }


    renderBreadcrumbForPracticeQuestions() {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToSelectedChapter()} >{`${this.props.chapterTitle} >`}</span>
                <span className='title-1 cursor-pointer pl-2' onClick={() => this.goBack()} >{`Practice >`}</span>
                <span className='title-1 pl-2 active'>{`Case Studies`}</span>
                {/* <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }


    showCaseStudyReport(item) {
        this.setState({ showCaseStudyReport: true, assessmentActivityId: item.assessmentActivityId })
    }

    hideChapterAssessmentReport() {
        this.setState({ showCaseStudyReport: false, assessmentActivityId: "" })
    }


    getButton(item) {
        if (item.status === 'START') {
            return (
                <div className="start-button text-center cursor-pointer" onClick={() => this.onClickCard(item)}>
                    Start
                </div>
            )
        } else if (item.status === 'IN_PROGRESS') {
            return (
                <div className="start-button text-center cursor-pointer" onClick={() => this.onClickCard(item)}>
                    Resume
                </div>
            )
        } else if (item.status === 'COMPLETED') {
            return (
                <div className="d-flex align-items-center" >
                    <div className="insights-button text-center cursor-pointer" onClick={() => this.showCaseStudyReport(item)}>
                        Insights
                    </div>
                    <div className="learning-gap-button text-center ml-4 cursor-pointer"
                        onClick={() => this.showViewAnswerScreen({
                            meta: {
                                chapterKey: this.props.selectedChapter.key,
                                chapterTitle: this.props.selectedChapter.title,
                                categoryThemeId: this.props.selectedChapter.categoryId,
                                assessmentActivityId: item.assessmentActivityId,
                                from: "CASE STUDY"
                            }
                        })}>
                        View Answers
                    </div>
                </div>
            )
        }
    }

    goToBrowseChapter() {
        this.props.delegate && this.props.delegate.goToBrowseChapterSignleChapter && this.props.delegate.goToBrowseChapterSignleChapter({ ...this.props.selectedChapter }, 0, null)
    }

    showAssessmentEngine(data) {
        //console.log(data)
        this.setState({ showAssessmentEngine: true, showCaseStudyStartScreen: false, assessmentEngineMetaInfo: data })
    }

    showPracticeCaseStudyEndScreen(assessmentCompletedInfo) {
        console.log(assessmentCompletedInfo)
        this.setState({ showAssessmentEngine: false, showAssessmentEndScreen: true, assessmentCompletedMetaInfo: assessmentCompletedInfo })
    }

    hidePracticeCaseStudyEndScreen() {
        this.setState({ showAssessmentEndScreen: false }, () => {

        })
    }

    showViewAnswerScreen(assessmentEndScreenInfo) {
        this.setState({
            showAssessmentEndScreen: false, showAssessmentViewAnswersScreen: true,
            assessmentEndScreenInfo: assessmentEndScreenInfo.meta
        })
    }

    hideViewAnswerScreen() {
        this.setState({
            showAssessmentViewAnswersScreen: false,
            assessmentEndScreenInfo: ''
        }, () => {
            this.getUserPracticeActivity()
        })
    }

    hideViewAnswer() {
        this.setState({
            showAssessmentViewAnswersScreen: false,
            assessmentEndScreenInfo: ''
        })
    }

    hideViewAnswersScreen() {
        this.setState({
            showAssessmentViewAnswersScreen: false,
            assessmentEndScreenInfo: ''
        }, () => {
            this.getUserPracticeActivity()
        })
    }



    render() {


        if (this.state.showCaseStudyStartScreen) {
            return <CaseStudyStartScreen
                back={() => this.goBack()}
                delegate={this}
                meta={this.state.startAssessmentMetaInfo} />
        }

        if (this.state.showAssessmentEngine) {
            return (
                <AssessmentEngine meta={this.state.assessmentEngineMetaInfo} delegate={this} />
            )
        }

        if (this.state.showAssessmentEndScreen) {
            return (
                <CaseStudyEndScreen
                    metaInfo={this.state.assessmentCompletedMetaInfo}
                    delegate={this}
                    onSelectChapter={this.props.onSelectChapter} />
            )
        }

        if (this.state.showAssessmentViewAnswersScreen) {
            return (
                <MyAssessmentResult
                 delegate={this} meta={this.state.assessmentEndScreenInfo} onSelectChapter={this.props.onSelectChapter}
                 hideViewAnswersScreen={() => this.hideViewAnswer(true)} />
            )
        }

        if (this.state.showCaseStudyReport) {
            return <ChapterAssessmentPerformanceReport delegate={this}
                assessmentActivityId={this.state.assessmentActivityId}
                questionType={'Case Study Based Questions'}
                from={'CASE_STUDY'} chapterTitle={this.props.chapterTitle} />
        }


        return (
            <div className="case-study-question-set-list">
                <div className="text-right">
                    <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                </div>
                <div className="header-title-margin-top">
                    {this.renderBreadcrumbForPracticeQuestions()}
                    {
                        this.state.caseStudyUrl ?
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="title">Case Studies</div>
                                <div>
                                    <div className="sample-case-study-button text-center cursor-pointer mr-4" onClick={() => { window.open(this.state.caseStudyUrl) }}>
                                        View Sample Case Study
                                    </div>
                                </div>
                            </div>
                            :

                            <div className="title">Case Studies</div>
                    }

                    <div className="mb-4">
                        <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                    </div>
                    {
                        this.state.isLoading ? <Loading />
                            :
                            <>
                                {
                                    this.state.userPracticeActivity && this.state.userPracticeActivity.length ?
                                        this.state.userPracticeActivity.map((activity, index) => (
                                            <div className="question-set row mr-0 ml-0 mb-4 pb-4 align-items-center">
                                                <div className="col pl-0">
                                                    <div className="d-flex align-items-center">
                                                        {/* <span className="set-text">Case Study {index + 1}</span> */}
                                                        <div className="mb-2 case-study-title"><CwContentViewer content={activity.practiceQuestion.caseStudyTitle} /></div>
                                                        {/* <img className="clock-icon-size ml-3" src={getImageSource(ImageConstants.GRAY_QUESTION_MARK_ICON)} />
                                                        <span className="approximate-text ml-1">{activity.approximateQuestions} Questions</span>
                                                        <img className="clock-icon-size ml-3" src={getImageSource(ImageConstants.GRAY_CLOCK_ICON)} />
                                                        <span className="approximate-text ml-1">Approx {activity.approximateTime} mins</span> */}
                                                    </div>
                                                </div>
                                                <div className="col-3 pr-0 d-flex justify-content-end">
                                                    {this.getButton(activity)}
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className="text-center">No Qestion Set Found for the Chapter : <strong>{this.props.selectedChapter.title}</strong></div>
                                }
                            </>
                    }

                </div>
            </div>
        )
    }





}

export default CaseStudyQuestionSetList;
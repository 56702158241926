
import React, { Component } from 'react';
import './assessment-end-popup.scss'
import { Modal, ModalBody } from "reactstrap";
import { ImageConstants } from '../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source'
import SharedProfile from '../../../../shared-storage/profile';
import SharedStaticText from '../../../../shared-storage/static-text-store';
import CommonUtils from '../../../utility/common-utilities';
import SharedThemeCategory from '../../../../shared-storage/category-theme';

class AssessmentEndPopup extends Component {

    staticText = {};

    constructor(props) {
        super(props);

        if (this.props.type != 'TIMEUP_POPUP') {
            this.state.remainingTime = this.props.time
            this.staticText = SharedStaticText.getStaticText('assessmentEndPopUp');
        }


    }

    componentDidMount() {
        // this.timer()
    }

    componentWillUnmount() {
        // this.clearIntervalCustom()
    }

    state = {

        flaggedContent: [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
        ]
    }



    onPressContinue = () => {
        this.props.delegate && this.props.delegate.onPressContinue && this.props.delegate.onPressContinue()
    }

    onPressFinish = () => {
        this.props.delegate && this.props.delegate.onPressFinish && this.props.delegate.onPressFinish()
    }

    onPressReviewAnswers = () => {
        this.props.delegate && this.props.delegate.onPressReviewAnswers && this.props.delegate.onPressReviewAnswers()
    }

    getTimeWithMinuteFormat(seconds) {
        // //console.log(seconds)

        let min = seconds >= 60 ? parseInt(seconds / 60) : 0
        let sec = seconds % 60;

        let time = '';

        if (min < 10)
            time += `0${min}`
        else
            time = min

        time += ':'

        if (sec < 10)
            time += `0${sec}`
        else
            time += sec

        return time

    }


    onClickClose = () => {

        this.props.close && this.props.close()

    }



    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <Modal className="cw-common-custom-modal-center-for-assessmentendpopup" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_HEADER, this.props.categoryThemeId)}></img>
                    {this.props.type != "TIMEUP_POPUP" ? <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} /> : <></>}
                </div>
                <ModalBody>

                    {this.props.type != "TIMEUP_POPUP" ?
                        <div className="pratice-container">
                            <div className='d-flex flex-row align-items-center justify-content-center flagged-header'>
                                <div className='header-text mt-3'>
                                    {this.props.flaggedContent && this.props.flaggedContent.length ? this.staticText.flaggedText : this.staticText.finishedText}
                                </div>
                            </div>
                            {
                                this.props.isFromPracticeCaseStudy ?
                                    <div className='mt-5'></div>
                                    :
                                    <div className='text-center mt-3'>
                                        <img className='clock_image_size' src={getThemeImageSource(ImageConstants.THEME_CLOCK_IMAGE, this.props.categoryThemeId)} />
                                        <span className='ml-3 timer-text'>{this.getTimeWithMinuteFormat(this.props.liveTimer)} <span className='min-text'>mins left</span></span>
                                    </div>
                            }

                            <div className={`flagged-questions-list d-flex justify-content-center mt-3 ml-4
                    ${this.props.flaggedContent && this.props.flaggedContent.length ? "fagged-card-hight" : ''}`}>
                                <div className='text-center pt-2'>

                                    {
                                        this.props.flaggedContent && this.props.flaggedContent.length ?
                                            <>
                                                <img className='flag-image-size' src={getImageSource(ImageConstants.FLAG_ENABLED)}></img>
                                                <span className='sub-text'>{this.props.flaggedContent.length} {this.props.flaggedContent.length > 1 ? this.staticText.questionsAreFlagged : this.staticText.questionIsFlagged}</span>
                                                <div className='flagged-questions d-flex flex-row align-items-center justify-content-center mt-3'>
                                                    {this.props.flaggedContent.map(content => (
                                                        <div className='flag-circle d-flex align-items-center justify-content-center'><span >{content + 1}</span></div>
                                                    ))}
                                                </div>
                                            </>
                                            :

                                            <div className='flagged-questions d-flex flex-row align-items-center justify-content-center'>
                                                <img className='review-answers-image' src={getImageSource(ImageConstants.REVIEW_ANSWERS_IMAGE)} ></img>
                                            </div>
                                    }

                                    <img className='flagged-card-footer' src={getThemeImageSource(ImageConstants.FLAGGED_CARD_FOOTER, this.props.categoryThemeId)}></img>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-around button-wrapper mt-3">

                                {
                                    this.props.flaggedContent && this.props.flaggedContent.length ?
                                        <div className="take-challenge cursor-pointer text-center" style={{ backgroundColor: this.theme[`themeColor`] }}
                                            onClick={this.onPressContinue}>
                                            {`CONTINUE ASSESSMENT`}
                                        </div>
                                        :

                                        <div className="take-challenge review-answers cursor-pointer text-center" style={{ backgroundColor: this.theme[`themeColor`] }}
                                            onClick={this.onPressReviewAnswers}>
                                            {`REVIEW ANSWERS`}
                                        </div>
                                }

                                <div className="continue-button cursor-pointer  text-center" style={{ color: this.theme[`themeColor`], borderColor: this.theme[`themeColor`] }}
                                    onClick={() => this.onPressFinish()}>
                                    {`FINISH`}
                                </div>
                            </div>

                        </div>
                        : <></>}

                    {
                        this.props.type === "TIMEUP_POPUP" ?
                            <div className="pratice-container">
                                <div className='time-up-header-text mt-5 text-center'>{`Time’s UP!`}</div>
                                <div className='header-sub-text mt-2 mb-4 text-center'>{`Please be fast next time`}</div>
                                <div className='text-center'>
                                    <img className='timeup-image' src={getImageSource(ImageConstants.TIMEUP_IMAGE)} />
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-2">
                                    <div className="take-challenge cursor-pointer text-center" style={{ backgroundColor: this.theme[`themeColor`] }}
                                        onClick={() => this.props.onPressTimeUpContinue && this.props.onPressTimeUpContinue()}>
                                        {'CONTINUE'}
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }


                </ModalBody>
                <div className="position">
                    <img className="challenge-footer" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_FOOTER, this.props.categoryThemeId)}></img>
                </div>
            </Modal>
        );
    }
}

export default AssessmentEndPopup;

import React from "react";
import './chapter-list-filter.scss';
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";


export default class ChapterListFilter extends React.Component {


    state = {

    };

    constructor(props) {
        super(props);

        let classDetail = this.tabs.filter(tab => {
            if (tab.value === this.props.selectedMasterDataId) {
                return tab.title
            }
        })

        let selectedTabIndex = this.tabs.filter((tab, index) => {
            if (tab.value === this.props.selectedMasterDataId) {
                return index
            }
        })


        this.state = {
            masterDataHash: this.props.masterDataHash,
            selectedMasterDataId: this.props.selectedMasterDataId,
            activeHeader: classDetail[0].title,
            selectedTabIndex: selectedTabIndex[0],
            chapterFilter: [],
            contentFilter: []
            
        }
    }



    // filterCopy = []

    componentDidMount() {

        let chapterList = this.state.masterDataHash[this.state.selectedMasterDataId]

        console.log(chapterList)

        this.filterCopy = chapterList

        console.log(this.filterCopy)

        let chapterHash = {};
        let contentHash = {};

        if (this.props.selectedFilters && this.props.selectedFilters.selectedItems && this.props.selectedFilters.selectedItems.length) {

            if (this.props.selectedFilters.filterType === 'CHAPTERS') {
                for (let i = 0; i < this.props.selectedFilters.selectedItems.length; i++) {
                    chapterHash[this.props.selectedFilters.selectedItems[i].value] = this.props.selectedFilters.selectedItems[i]

                }

                for (let i = 0; i < this.filterCopy.length; i++) {

                    if (chapterHash[this.filterCopy[i].value]) {
                        this.filterCopy[i].active = true

                    }
                }
            }


        }
        this.setState({ chapterFilter: this.filterCopy ? this.filterCopy : [] })
    }


    tabs = [
        {
            title: 'Class 6', value: "5e71c0afe6a6150727ddc842"
        },
        {
            title: 'Class 7', value: "5e71c567e6a6150727ddc843"
        },
        {
            title: 'Class 8', value: "5e84606776d92d04237961f1"
        },
        {
            title: 'Class 9', value: "60484cc19b269f034cb847d0"
        }
    ]

    close() {
        this.props.close();
    }

    onSelect = (option) => {
        this.props.onSelect && this.props.onSelect(option)
    }

    onPressFilter = (tab, index) => {


        let chapterFilter = this.state.chapterFilter;
        for (let i = 0; i < chapterFilter.length; i++) {
            chapterFilter[i]['active'] = false
        }

        let masterDataHash = this.state.masterDataHash

        masterDataHash[this.state.selectedMasterDataId] = chapterFilter

        chapterFilter = this.state.masterDataHash[tab.value]

        // console.log(chapterFilter)

        this.setState({ chapterFilter, masterDataHash, activeHeader: tab.title, selectedTabIndex: index, selectedMasterDataId : tab.value }, () => {
            this.setState({  })
        })

        // this.setState({ activeHeader: tab.title, selectedTabIndex: index }, () => {
        //     this.resetSelectionFilter(tab)
        // })
    }

    resetSelectionFilter = (tab) => {
        // if (value == 'CHAPTERS') {
        // let contentFilter = this.state.contentFilter.map(content => ({ ...content, active: false }))
        // let contentFilter = this.state.contentFilter;
        // for (let i = 0; i < contentFilter.length; i++) {
        //     contentFilter[i]['active'] = false
        // }


        // }
        // if (tab == 'CONTENT') {
        //     //let chapterFilter = this.state.chapterFilter.map(chapter => ({ ...chapter, active: false }))
        //     let chapterFilter = this.state.chapterFilter;
        //     for (let i = 0; i < chapterFilter.length; i++) {
        //         chapterFilter[i]['active'] = false
        //     }
        //     this.setState({ chapterFilter })
        // }
    }

    onPressCheckBox = (index, value) => {
        // if (this.state.activeHeader == 'CHAPTERS') {
            let chapterFilter = this.state.chapterFilter;
            chapterFilter[index]['active'] = value;
            this.setState({ chapterFilter })
        // }
        // if (this.state.activeHeader == 'CONTENT') {
        //     let contentFilter = this.state.contentFilter;
        //     contentFilter[index]['active'] = value;
        //     this.setState({ contentFilter })
        // }
    }
    clearAll = () => {
        // if (this.state.activeHeader == 'CONTENT') {
        //     // let contentFilter = this.state.contentFilter.map(content => ({ ...content, active: false }))

        //     let contentFilter = this.state.contentFilter;
        //     for (let i = 0; i < contentFilter.length; i++) {
        //         contentFilter[i]['active'] = false
        //     }
        //     this.setState({ contentFilter })
        // }
        // if (this.state.activeHeader == 'CHAPTERS') {
            //  let chapterFilter = this.state.chapterFilter.map(chapter => ({ ...chapter, active: false }))
            let chapterFilter = this.state.chapterFilter;
            for (let i = 0; i < chapterFilter.length; i++) {
                chapterFilter[i]['active'] = false
            }
            this.setState({ chapterFilter })
        // }

    }

    apply = () => {
        let payload = {
            filterType: this.state.activeHeader,
            selectedItems: this.getSelectedItems(),
            selectedMasterDataId : this.state.selectedMasterDataId
        }
        this.props.delegate && this.props.delegate.onApplyFilter && this.props.delegate.onApplyFilter(payload)
    }

    getSelectedItems = () => {
        // if (this.state.activeHeader == 'CONTENT') {
        //     let contentFilter = this.state.contentFilter.filter(content => content.active)
        //     return contentFilter
        // }
        // if (this.state.activeHeader == 'CHAPTERS') {
            let chapterFilter = this.state.chapterFilter.filter(chapter => chapter.active)
            return chapterFilter
        // }
    }

    render() {

        return (

            <Modal className="cw-classwork-modal-right" isOpen={true} size={'lg'} >
                <ModalBody>
                    <div className="classwork-filter">
                        <img className="filter-header-image" src={getImageSource(ImageConstants.FILTER_HEADER)} />
                        <div className="d-flex justify-content-between filter-header">
                            {this.state.chapterFilter.length && this.state.chapterFilter.filter(chapter => chapter.active).length ?
                                <div className="clear-all-text d-flex justify-content-center p-2">
                                    <div className="cursor-pointer" onClick={this.clearAll}>
                                        Clear All
                                    </div>
                                </div> : <div></div>}
                            <div className="filter-text">Filters</div>
                            <div>
                                <img onClick={this.close.bind(this)} className="close-icon-size cursor-pointer" src={getImageSource(ImageConstants.CROSS)} />
                            </div>
                        </div>
                        <div className="header-tab row  justify-content-center ml-0 mr-0 mt-3">
                            {
                                this.tabs.map((tab, index) => (
                                    <div onClick={() => this.onPressFilter(tab, index)}
                                        className={`col tab-bg-1 text-center ${this.state.activeHeader === tab.title ? 'active-tab-1' : 'cursor-pointer'}
                                        ${this.state.selectedTabIndex === 0 ? 'active-border-left-radius' : ''}
                                        ${this.state.selectedTabIndex === 3 ? 'active-border-right-radius' : ''}
                                        ${this.state.activeHeader !== tab.title ? 'in-active-border' : 'cursor-pointer'}
                                        ${index === 3 ? 'in-active-border-right-radius' : 'cursor-pointer'}
                                        ${index === 0 ? 'in-active-border-left-radius' : 'cursor-pointer'}`}>
                                        {tab.title}
                                    </div>
                                ))
                            }
                            {/* <div onClick={() => this.onPressFilter("CHAPTERS")}
                                className={`col tab-bg-1 text-center ${this.state.activeHeader == 'CHAPTERS' ? 'active-tab' : 'cursor-pointer'}`}>
                                Chapters
                            </div>
                            <div onClick={() => this.onPressFilter("CONTENT")}
                                className={`col tab-bg-2 text-center ${this.state.activeHeader == 'CONTENT' ? 'active-tab' : 'cursor-pointer'}`}>
                                Content
                            </div> */}
                        </div>

                        
                                <div className="mt-3">
                                    {this.state.chapterFilter && this.state.chapterFilter.length && this.state.chapterFilter.map((chapter, index) => (
                                        <div className="d-flex justify-content-between mt-3 cursor-pointer" onClick={() => this.onPressCheckBox(index, chapter.active ? false : true)} key={'chapter' + index}>
                                            <div className="chapter-title">{chapter.title}</div>
                                            {
                                                chapter.active ?
                                                    <div className="active-check-box">
                                                        <img className="green-tick-icon" src={getImageSource(ImageConstants.GREEN_TICK_ICON)} />
                                                    </div>
                                                    :

                                                    <div className="check-box"></div>
                                            }
                                        </div>
                                    ))}

                                </div>

                                


                        <div className="d-flex justify-content-center mt-3 mb-2">
                            <div className="apply-button cursor-pointer" onClick={this.apply}>
                                Apply
                            </div>
                        </div>


                        <img className="filter-footer-image" src={getImageSource(ImageConstants.FILTER_FOOTER)} />
                    </div>
                </ModalBody>

            </Modal>
        );
    }

}
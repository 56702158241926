import React from 'react';
import './content-list-header.scss';
import { getImageSource, getThemeImageSource } from '../../../../utility/image-source';
import { ImageConstants } from '../../../../constants/constants';
import SharedThemeCategory from '../../../../../shared-storage/category-theme';

export default class ContentListHeader extends React.Component {
    constructor(props) {
        super(props);
    }


    showPK = () => {
        this.props.delegate && this.props.delegate.showPK && this.props.delegate.showPK(true);
    }

    closePopup = () => {
        this.props.delegate && this.props.delegate.closePopup && this.props.delegate.closePopup();
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToRefresherCourseEloListScreen() {
        this.props.delegate && this.props.delegate.goToRefresherCourseEloList && this.props.delegate.goToRefresherCourseEloList()
    }

    

    renderBreadcrumb() {
        return (
            <div className='breadcrumb-for-classwork mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 cursor-pointer title-1' onClick={() => this.goToRefresherCourseEloListScreen()}>{`Bridge Course >`}</span>
                <span className='pl-2 cursor-pointer title-1' onClick={() => this.goToRefresherCourseEloListScreen()}>{`${this.props.chapterTitle} >`}</span>
                <span className='pl-2 title-1'>{this.props.title}</span>
                {/* <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                {/* <span className='pl-2 title-1'>{`Start Assessment`}</span> */}
            </div>
        )
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId);


        return (
            <div className='header-container-wrapper'>

                {
                    this.renderBreadcrumb()
                }

                <div className='header-container mt-2'>
                    {
                        this.props.isScrolled ?
                            <div>
                            </div>
                            : <></>
                    }
                    <div>
                        {
                            this.props.isPKExist ? <div>
                                <div onClick={this.showPK} >
                                    <div >
                                        <img src={getImageSource(ImageConstants.NAV_PREV_KNOWLEDGE)} />
                                    </div>
                                </div>
                            </div> : <></>
                        }
                    </div>
                </div>
                {
                    !this.props.isScrolled ?
                        <div className='title-container'>
                            <div>
                                <div className='title-text'>{this.props.title ? this.props.title : this.props.chapterTitle}</div>
                            </div>
                            {
                                !this.props.isChapterIntro ?
                                    <div>
                                        <div className='sub-title-text'>
                                            {this.props.chapterTitle}
                                        </div>
                                    </div>
                                    : <></>
                            }

                        </div>
                        : <></>
                }
                <img className='header-divider-image' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.props.categoryId)} />

            </div>
        );
    }
}

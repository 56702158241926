import React, { Component } from 'react';
import './image-preview.scss';
import { ImageConstants } from '../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import { Modal, ModalBody } from 'reactstrap';
// import { styles } from './image-preview-css';
// import AudioPlayer from '../../audio-player/audio-player';
// import Video from 'react-native-af-video-player'
// import Video from '../../cw-video/cw-video-v2';


class ImagePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstLoadedGif : this.props.source,
            loaded : this.props.source
        };
    }
    videoPlayerRefs = {};


    renderElements = () => {

        console.log(this.props.source)

        if (this.props.type) {

            if (this.props.type == 'IMAGE') {
                return (
                    <>
                        <img src={this.props.source}></img>
                    </>

                )
            }

            if (this.props.type == 'VIDEO') {
                return (
                    // <View style={styles.audioPreview}>
                    //     <Video
                    //         url={this.props.source}
                    //         delegate={this}
                    //         style={Styles.videPlayer}
                    //     />
                    // </View>
                    <>Video</>
                )
            }

            if (this.props.type == 'AUDIO') {
                return (
                    // <View style={styles.audioPreview}>
                    //     <AudioPlayer audioUrl={this.props.source} />
                    // </View>
                    <>Audio</>
                )
            }

        } else {
            return (

                <div className='d-flex flex-column justify-content-center'>
                    <img className='image-size-for-preview' src={this.state.loaded} key={Math.random()}  ></img >
                    {this.props.source.toString().endsWith("gif") ?
                        <div className='d-flex justify-content-center'>
                            <div className='d-flex align-items-center justify-content-center cursor-pointer play-again-button-wrapper'
                                onClick={this.reloadGif}>
                                <img className='reload-image-size' src={getImageSource(ImageConstants.RELOAD)} />
                                <span className='ml-2 play-again-text'>Play again</span>
                            </div>
                        </div>
                        :
                        <></>
                    }
                </div>


            )
        }
    }

    reloadGif = () => {
        this.setState({ loaded: '' }, () => {
            setTimeout(() => {
                this.setState({ loaded: this.state.loaded })
            }, 0)
        })
    }


    // <View>
    //             <Modal
    //                 animationType="slide"
    //                 transparent={true}
    //                 visible={this.props.modal ? this.props.modal : false}
    //                 onRequestClose={() => {
    //                     this.props.closePreview();
    //                 }}>
    //                 <TouchableWithoutFeedback onPress={() => this.props.closePreview()}>
    //                     <View style={styles.modal}>
    //                         <TouchableWithoutFeedback>
    //                             <View style={styles.previewConatiner}>
    //                                 {Platform.OS === 'ios' ? <TouchableOpacity style={styles.zIndex} onPress={() => this.props.closePreview()} hitSlop={Styles.hitSlop}>
    //                                     <View style={styles.removeIcon}>
    //                                         <Image source={getThemeImageSource(ImageConstants.POPUP_CLOSE, this.props.categoryThemeId)}  />
    //                                     </View>
    //                                 </TouchableOpacity>
    //                                     :
    //                                     <TouchableNativeFeedback style={styles.zIndex} onPress={() => this.props.closePreview()} hitSlop={Styles.hitSlop}>
    //                                         <View style={styles.removeIcon}>
    //                                             <Image source={getThemeImageSource(ImageConstants.POPUP_CLOSE, this.props.categoryThemeId)}  />
    //                                         </View>
    //                                     </TouchableNativeFeedback>}
    //                                 {this.renderElements()}
    //                             </View>
    //                         </TouchableWithoutFeedback>
    //                     </View>
    //                 </TouchableWithoutFeedback>
    //             </Modal>
    //         </View>

    render() {
        return (
            <Modal className='cw-custom-modal-for-image-preview-v1' isOpen={true} size={"lg"}>
                <ModalBody>
                    <div>
                        {/* <div className='image-icon-wrapper'>
                            <img className='theme-close-icon cursor-pointer'
                                onClick={() => this.props.closePreview()}
                                src={getThemeImageSource(ImageConstants.THEME_CLOSE_ICON)} />
                        </div> */}
                        {/* {this.renderElements()} */}
                        <div><img src={this.state.loaded} /></div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default ImagePreview;

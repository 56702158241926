export default class OnBoardingSharedStorage {

    static data = {}

    static set(key, item) {
        this.data[key] = item;
    }

    static get(key) {
        if (this.data) {
            return this.data[key];
        }
    }

}

import React, { Component } from 'react';
import { ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source';
import './pill-button-select.scss';

class PillButtonSelect extends Component {

    state = {
        selectedValues: Array.isArray(this.props.value) ? this.props.value : this.props.value ? [this.props.value] : []
    };

    componentDidMount() {
    }

    onSelect(item) {
        //console.log(item)
        if (this.props.disabled) return
        if (this.props.isMultiple) {
            if (this.state.selectedValues.includes(item.value)) {
                let values = this.state.selectedValues.filter(i => i != item.value)
                this.setState({ selectedValues: values }, () => {
                    this.props.onSelect(this.state.selectedValues)
                })
            } else {
                this.setState({ selectedValues: [...this.state.selectedValues, item.value] }, () => {
                    this.props.onSelect(this.state.selectedValues)
                })
            }

        } else {
            this.setState({ selectedValues: [item.title] }, () => {
                this.props.onSelect(this.state.selectedValues[0], item)
            })
        }
    }

    render() {
        return (
            <div className={`pill-button-select`}>
                <div className={`${this.props.isHorizontalScroll ? "scroll-view" : ""}`} id="pill-scroll-1">

                    {
                        this.props.type === 'boardPillButton' ?
                            <>
                                {
                                    this.props.options && this.props.options.map(item => (
                                        <div className={`text-center cursor-pointer d-inline-block mr-4`} onClick={() => this.onSelect(item)}>
                                            <div className={`pill-button px-3 ${item.imageLink ? 'class-pill-button-size ' : ''} ${this.props.invalid ? 'invalid' : ''}  
                                ${this.state.selectedValues.includes(item.title) ? "pill-button-selected" : "pill-button-not-selected"}`}
                                                style={this.props.width ? { width: this.props.width } : this.props.style ? { ...this.props.style } : {}} >
                                                {item.imageLink ? <img className='class-image-size mr-4' src={item.imageLink} /> : ''}
                                                {item.title}

                                            </div>

                                        </div>
                                    ))
                                }
                            </>

                            : ''
                    }

                    {
                        this.props.type === 'classPillButton' ?
                            <>
                                {
                                    this.props.options && this.props.options.map(item => (
                                        <div className={`text-center cursor-pointer d-inline-block mr-4`} onClick={() => this.onSelect(item)}>
                                            <div className={`pill-button px-3 ${item.imageLink ? 'class-pill-button-size ' : ''} ${this.props.invalid ? 'invalid' : ''}  
                                ${this.state.selectedValues.includes(item.title) ? "pill-button-selected-for-class" : "pill-button-not-selected-for-class"}`}
                                                style={this.props.width ? { width: this.props.width } : this.props.style ? { ...this.props.style } : {}} >
                                                {this.state.selectedValues.includes(item.title) ? <img className='green-tick-mark mr-3' src={getImageSource(ImageConstants.GREEN_TICK_MARK)} /> : ''}
                                                {item.imageLink ? <img className='class-image-size mr-4' src={item.imageLink} /> : ''}
                                                {item.title}

                                            </div>

                                        </div>
                                    ))
                                }
                            </>

                            : ''
                    }



                </div >
            </div >
        )
    }
}

export default PillButtonSelect;
import React from 'react';
import './summary.scss';
import { getImageSource } from '../../../utility/image-source';
import { ImageConstants } from '../../../constants/constants';
import Parser from "../content-parser/content-parser";
import ReactTooltip from "react-tooltip";

export default class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.parser = new Parser();
    }

    renderTickMark = (index) => {

        index = index % 4;

        switch (index) {
            case 0:
                return <img className='summary-tick-image-size' src={getImageSource(ImageConstants.SUMMARY_YELLOW_TICK)} resizeMode="contain" />

            case 1:
                return <img className='summary-tick-image-size' src={getImageSource(ImageConstants.SUMMARY_RED_TICK)} resizeMode="contain" />

            case 2:
                return <img className='summary-tick-image-size' src={getImageSource(ImageConstants.SUMMARY_GREEN_TICK)} resizeMode="contain" />

            case 3:
                return <img className='summary-tick-image-size' src={getImageSource(ImageConstants.SUMMARY_BLUE_TICK)} resizeMode="contain" />

            default:
                return <img className='summary-tick-image-size' src={getImageSource(ImageConstants.SUMMARY_YELLOW_TICK)} resizeMode="contain" />
        }
    };
    onClickBookMarkActive = () => {
        this.props.delegate && this.props.delegate.onClickBookMarkActive && this.props.delegate.onClickBookMarkActive(this.props.bookMarkId, this.props.selectedIndex)
    }

    onClickBookMark = () => {
        this.props.delegate && this.props.delegate.onClickBookMark && this.props.delegate.onClickBookMark(this.props.contentObj, this.props.selectedIndex)
    }


    render() {

        return (
            <>
                {
                    this.props.data &&
                    <div className='learning-summary mb-5' style={{}} onMouseEnter={ReactTooltip.rebuild}>
                        {this.props.showTitle &&
                            <>
                                <div className='d-flex justify-content-between align-items-center' style={{}}>
                                    <div className='d-flex align-items-center'>
                                        <div style={{}}>
                                            <img className='innovation-image-size' src={getImageSource(ImageConstants.SUMMARY_INNOVATION)} />
                                        </div>
                                        <div className='ml-3' style={{}}>

                                            {
                                                this.props.data.displayTitle ?

                                                    <span className='title' style={{}}>{this.props.data.displayTitle}</span> :
                                                    <span className='title' style={{}}>{this.props.data.title}</span>
                                            }

                                        </div>
                                    </div>


                                    {/* {this.props.showBookMark && <View style={Styles.bookMarkIconStyles} >
                                        <TouchableOpacity onPress={this.onClickBookMarkActive} hitSlop={Styles.hitSlop}>
                                            <Image source={getImageSource(ImageConstants.BOOKMARK_ACTIVE)} />
                                        </TouchableOpacity>
                                    </View>} */}

                                    {/* {this.props.showInactiveBookmark && < View style={Styles.bookMarkIconStyles} >
                                        <TouchableOpacity onPress={this.onClickBookMark} hitSlop={Styles.hitSlop}>
                                            <Image source={getImageSource(ImageConstants.BOOKMARK_INACTIVE)} />
                                        </TouchableOpacity>
                                    </View>} */}

                                    {this.props.showBookMark && <div >
                                        <div className='cursor-pointer' onClick={this.onClickBookMarkActive} data-tip={'BREAK UP'} >
                                            <img className='bookmark-image-size mr-4' src={getImageSource(ImageConstants.BOOKMARK_ACTIVE)} />
                                        </div>
                                    </div>}

                                    {this.props.showInactiveBookmark && <div >
                                        <div className='cursor-pointer' onClick={this.onClickBookMark} data-tip={'BOOKMARK'} >
                                            <img className='bookmark-image-size mr-4' src={getImageSource(ImageConstants.BOOKMARK_ICON)} />
                                        </div>
                                    </div>}
                                </div>

                                {this.props.meta ?
                                    <div style={{}}>
                                        <div style={{}}></div>
                                        <div style={{}}>
                                            {this.props.meta.chapterName ? <span style={{}}>{this.props.meta.chapterName}</span> : <></>}
                                            {this.props.meta.eloName ? <span style={{}}>{this.props.meta.eloName}</span> : <></>}
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </>
                        }
                        <div className='ml-1' style={this.props.showTitle && {}}>
                            {
                                this.props.data.content && this.props.data.content.summary &&
                                this.props.data.content.summary.map((tag, index) =>

                                    // <div style={[this.props.data.content.summary.length - 1 !== index ? {} : {}]}>
                                    <div className='mt-3'>

                                        <div className='d-flex align-items-center' style={{}}>
                                            <div style={{}}>{this.renderTickMark(index)}</div>

                                            <div className='ml-3 no-margin-bottom' style={{}}>
                                                {this.parser.parseLearningContent(tag, this.props.delegate, this.props.glossary, null, null, this.props.categoryId, null, true)}
                                            </div>
                                        </div>
                                    </div>

                                )
                            }
                        </div>
                        <ReactTooltip ref={el => this.tooltip = el} multiline={true} place='bottom' type='dark' effect='solid' event="mouseover mouseenter" eventOff="mouseleave mouseout scroll mousewheel blur" />
                    </div>
                }
            </>
        )
    }
}

import React from "react";
import './one-step-event.scss';
import SharedProfile from "../../../../shared-storage/profile";
import Parser from "../../common/content-parser/custom-parser";
import CommonUtils from "../../../utility/common-utilities";
import AsyncStore from "../../../../shared-storage/async-store";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import { Constants } from "../../../constants/constants";
import FireBaseEvent from '../../../firebase-events/firebase-events';

export default class OneStepEvent extends React.Component {

    staticText = {};

    constructor(props) {
        super(props);

        this.staticText = {
        }
        this.parser = new Parser();

        this.userInfo = SharedProfile.getProfile()
        // this.branchParams = SharedDeepLink.getDeepLink()

        this.state.eventRes = this.props.event
    }

    componentDidMount() {
        if (this.props.event)
            this.getTwoStepEventStatus(this.props.event)
    }
    state = {
    }

    getTwoStepEventStatus = (res) => {

        //this.resetTwoStepEventStatus()

        if (this.userInfo.timeZone && res.staticText) {
            let stringyFyText = JSON.stringify(res.staticText)
            res.staticText = JSON.parse(CommonUtils.timeParsar(stringyFyText, this.userInfo.timeZone))
        }

        if (this.userInfo.timeZone && res.knowMore) {
            let stringyFyText = JSON.stringify(res.knowMore)
            res.knowMore = JSON.parse(CommonUtils.timeParsar(stringyFyText, this.userInfo.timeZone))
        }

        if (this.userInfo.timeZone && res.help) {
            let stringyFyText = JSON.stringify(res.help)
            res.help = JSON.parse(CommonUtils.timeParsar(stringyFyText, this.userInfo.timeZone))
        }

        this.twoStepEventKnowMore = res.knowMore
        this.twoStepEventHelp = res.help
        this.scholarshipEligibleStaticText = res.staticText && res.staticText.scollarShipEligiblePopUp ? res.staticText.scollarShipEligiblePopUp : {}

        let updatePayload = {
            showTwoStepEventCard: true,
            twoStepEventCardLoading: false,
            twoStepEventResultAnnounced: res.resultAnnounced,
            twoStepWildCardUser: res.isSecondAttemptUser ? true : false,
            twoStepWildcardResultAnnounced: res.wildCardResultAnnounced ? true : false,
            twoStepEventRemaningTimeForCurrentStep: res.event.remainingTime,
            isTwoStepEventCompleted: res && res.event && res.event.status == "COMPLETED",
            isTwoStepEventDeadLineOver: res && res.event && res.event.status == "DEADLINE_OVER" ? true : false
        }

        if (res.position)
            updatePayload.twoStepEventPosition = res.position;

        if (res) {
            this.getknowMoreRead(res)
        }

        this.setState({ eventRes: res, ...updatePayload }, () => {

            if (res && res.event && res.event.status == 'LOCKED' && this.state.twoStepEventRemaningTimeForCurrentStep) {

                if (this.twoStepEventTimer) {
                    clearInterval(this.twoStepEventTimer)
                }
                this.getTimeReducerForTwoStepEvent()
            }

        })
    }

    getknowMoreRead = (res) => {

        AsyncStore.getKnowMoreRead().then((data) => {

            data = JSON.parse(data)

            if (data[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${res && res.eventId ? res.eventId : ""}`]) {
                this.setState({ isKnowmoreRead: true })
            }
        }).catch((err) => {
        });
    }

    onPressTwoStepEventHelp = () => {

        if (this.twoStepEventHelp)
            this.props.showHelp({ help: this.twoStepEventHelp })
    }

    getTimeReducerForTwoStepEvent = () => {
        this.twoStepEventTimer = setInterval(() => {

            if (this.state.twoStepEventRemaningTimeForCurrentStep == 0) {
                if (this.twoStepEventTimer) {
                    clearInterval(this.twoStepEventTimer)
                    let event = this.state.eventRes
                    event.showTimer = false
                    event.event.status = "START"
                    this.setState({ twoStepEventUserCurrentStepStatus: 'START', eventRes: event })
                    return
                }
            }
            this.setState({ twoStepEventRemaningTimeForCurrentStep: this.state.twoStepEventRemaningTimeForCurrentStep - 1 })

        }, 1000)
    }

    async resetTwoStepEventStatus() {
        await this.setState({
            isTwoStepEventDeadLineOver: false,
            isTwoStepEventCompleted: false,
            twoStepEventUserCurrentStep: "STEP1",
            twoStepEventUserCurrentStepStatus: "LOCKED",
            twoStepEventRemaningTimeForCurrentStep: 0,
            showTwoStepEventCard: false
        })
    }

    getCardColor = () => {
        let index = this.props.index % 2

        // //console.log('indes',this.props.index)

        // if (index == 0) {
        //     return '#424D6C'
        // } else {
        //     return "#3F5D60"
        // }
        return '#FFF7F2'
    }

    getCardBorderColor = () => {

        return '#FEE690'

    }

    handleMockTest = () => {

        let event = this.state.eventRes;

        // if (event && event.openLink && event.openLink.url) {
        //     Linking.openURL(event.openLink.url)
        //     return
        // }P

        let assessmentId = this.state.eventRes && this.state.eventRes.mockTestAssessmentId;
        let mockTest = this.state.eventRes && this.state.eventRes.mockTestStatus;
        if (!assessmentId || !mockTest)
            return;


        if (mockTest.status == "IN_PROGRESS") {

            this.props.navigation.navigate('AssessmentEngine', {
                meta: {
                    chapterTitle: this.state.eventRes && this.state.eventRes.mockTestTitle || "Mock Test",
                    isResume: true,
                    assessmentActivityId: mockTest.activityId,
                    categoryThemeId: "1",
                    assessmentId: assessmentId,
                    from: Constants.MOCK_TEST,
                    type: Constants.MOCK_TEST,
                    eventId: this.state.eventRes.eventId,
                    viewAnswerTime: this.state.eventRes && this.state.eventRes.mockTestViewAnswerTime || null,
                    viewAnswerTimeScreenStaticText: this.state.eventRes.staticText && this.state.eventRes.staticText.viewAnswerTimeScreen ? this.state.eventRes.staticText.viewAnswerTimeScreen : null,
                    eventPayload: this.state.eventRes
                }
            })
            return
        }

        if (mockTest.status == "COMPLETED") {

            this.props.navigation.navigate('MockTestEndScreen', {
                meta: {
                    data: mockTest.activity,
                    chapterTitle: this.state.eventRes && this.state.eventRes.mockTestTitle || "Mock Test",
                    categoryThemeId: "1",
                    assessmentActivityId: mockTest.activityId,
                    chapterKey: "",
                    type: Constants.MOCK_TEST,
                    from: Constants.MOCK_TEST,
                    selectedChapters: [],
                    isResultAnnounced: false,
                    eventId: this.state.eventRes.eventId,
                    viewAnswerTime: this.state.eventRes && this.state.eventRes.mockTestViewAnswerTime || null,
                    eventPayload: this.state.eventRes
                }
            })

            return
        }
        this.props.navigation.navigate('MockTestStartScreen', {
            meta: {
                event: this.state.eventRes,
                assessmentId,
                eventId: this.state.eventRes.eventId,
                title: this.state.eventRes && this.state.eventRes.mockTestTitle || "Mock Test",
                isStarted: true
            }
        })
    }

    handlePreapre = () => {

        let event = this.state.eventRes;

        if (event && event.openLink && event.openLink.url) {
            // Linking.openURL(event.openLink.url)
            return
        }

        let workflowId = this.state.eventRes && this.state.eventRes.preprationId

        if (!workflowId) {
            return;
        }

        this.props.navigation.navigate('NewEventWorkflow', {
            meta: {
                eventId: this.state.eventRes.eventId,
                from: Constants.MOCK_TEST,
                refresherCourseId: workflowId,
                eventPayload: this.state.eventRes,
            }
        })

    }

    getMockTestTitle = (statickText, status) => {

        if (status == "START") {
            return statickText.mockTestTitle || "TAKE A MOCK TEST"
        } else if (status == "IN_PROGRESS") {
            return statickText.mockTestResume || "RESUME MOCK TEST"
        } else if (status == "COMPLETED") {
            return statickText.mockTestResult || "MOCK TEST RESULT"
        }
    }


    getTwoStepEventCardV2 = () => {
        let eventRes = this.state.eventRes;
        let eventCardStaticText = eventRes && eventRes.staticText && eventRes.staticText.eventCard ? eventRes.staticText.eventCard : {}
        // //console.log(eventCardStaticText.eventFinishedText)
        return (
            <div className="event-card">
                <div className="" >

                    <div className="event-card-container position-relative p-3" style={{ backgroundColor: this.getCardColor(), border: `2px solid ${this.getCardBorderColor()}` }}>
                        <img className="event-card-bg-image" src={getImageSource(ImageConstants.EVENT_CARD_BG_IMAGE)} />
                        <div className={`row align-items-center 
                        ${(!this.state.isKnowmoreRead) &&
                                eventRes.event.status == 'LOCKED' ? "" : this.state.isTwoStepEventDeadLineOver ? "" : this.state.isTwoStepEventCompleted ? "" : "margin-bottom"}
                               ${!this.state.isTwoStepEventDeadLineOver && eventRes && eventRes.event.status == 'LOCKED' && eventRes.showTimer ? "mb-2" : ''} `}>
                            <div className="col-1 pr-0">
                                {
                                    eventRes && eventRes.headerImage ?
                                        <img className="logo-image-size" src={eventRes.headerImage} />
                                        :
                                        <img className="logo-image-size" src={getImageSource(ImageConstants.RAMANUJAN_CIRCLE_LOGO)} />
                                }
                            </div>
                            <div className="col ">
                                <div className={`event-name ${eventRes.event.status == 'LOCKED' && eventRes.isPaid &&
                                    !this.state.isTwoStepEventCompleted && !(this.state.twoStepEventResultAnnounced &&
                                        this.state.isTwoStepEventCompleted) ? "" : ''}
                                    ${(!this.state.isKnowmoreRead) &&
                                        eventRes.event.status == 'LOCKED' ? "" : this.state.isTwoStepEventDeadLineOver ? "" : this.state.isTwoStepEventCompleted ? "" : ""}`}>{eventCardStaticText.headerText}</div>
                                {
                                    eventRes &&
                                        eventRes.note &&
                                        !this.state.isKnowmoreRead &&
                                        eventRes.event &&
                                        eventRes.event.status == "LOCKED" &&
                                        eventRes.showKnowMore ?
                                        <div className="event-note mt-2">{eventRes.note}</div>
                                        : <></>
                                }
                            </div>
                            {
                                !this.state.isTwoStepEventDeadLineOver &&
                                    (eventRes && eventRes.event && eventRes.event.status == "START") ||
                                    (this.state.twoStepEventUserCurrentStepStatus == 'START'
                                        || this.state.twoStepEventUserCurrentStepStatus == 'IN_PROGRESS') ?
                                    <div className="col-2 row justify-content-end">
                                        <div className="col-7 text-center live-wrapper">
                                            <span className="d-inline-block red-dot"> </span>
                                            Live
                                        </div>
                                    </div>
                                    : <></>
                            }
                            <div className="col-1 text-right" onClick={this.onPressTwoStepEventHelp}>
                                <img className="help-image-size cursor-pointer"
                                    src={getImageSource(ImageConstants.QUESTION_MARK)} />
                            </div>
                        </div>
                        {
                            this.state.isTwoStepEventCompleted ||
                                (this.state.twoStepEventResultAnnounced &&
                                    this.state.isTwoStepEventCompleted) ?
                                <div className="" onClick={() => this.onPressTwoStepEventCard()}>
                                    <img className="success-bg" src={getImageSource(ImageConstants.EVENT_CARD_SUCCESS_BG)}></img>
                                    <div className="paid-event-flag-wrapper text-center">
                                        <img className="paid-event-flag" src={getImageSource(ImageConstants.PAID_EVENT_FLAG)}></img>
                                    </div>

                                    {/* {eventRes.secondaryLogo ? <Image source={{ uri: eventRes.secondaryLogo }} style={eventRes.secondaryImageSize ? { ...Styles.secondaryImage1, height: wp(eventRes.secondaryImageSize.height / 4), width: wp(eventRes.secondaryImageSize.width / 4) } : Styles.secondaryImage1} resizeMode={'contain'}></Image> : <></>} */}
                                </div> :
                                <></>
                        }
                        {
                            !this.state.isKnowmoreRead && eventRes && eventRes.event && eventRes.event.status == "LOCKED" && eventRes.showKnowMore ?
                                <div className="paid-event-container mt-2">
                                    <div className="paid-event-wrapper d-flex">
                                        <div className="paid-text">
                                            <div className="white-border">Paid Event {eventRes && eventRes.amountText || "- ₹59 only"}</div>
                                        </div>
                                        {/* <span className="amount-text">{eventRes && eventRes.amountText || "- ₹59 only"}</span> */}
                                    </div>
                                </div> : <></>
                        }

                        {
                            this.state.isTwoStepEventCompleted || (this.state.twoStepEventResultAnnounced && this.state.isTwoStepEventCompleted) ?
                                <div onClick={() => this.onPressTwoStepEventCard()} className="scholarship-card-footer-2 mt-1 d-flex justify-content-center">
                                    {this.state.twoStepWildCardUser && !this.state.twoStepWildcardResultAnnounced ?
                                        <div className="wild-card-finished-text">
                                            {this.parser.parseWithStyles(eventCardStaticText.eventWildCardFinishedText, { p: { fontSize: "15px", fontWeight: "600", color: "#353535" }, strong: { fontSize: "16px", fontWeight: "700", color: "#353535" } })}
                                        </div>
                                        :
                                        (!this.state.istwoStepWildCardUser && this.state.twoStepEventResultAnnounced) || (this.state.twoStepWildCardUser && this.state.twoStepWildcardResultAnnounced) ?
                                            <div>
                                                {this.renderCardAfterResultAnnounced(eventRes, eventCardStaticText)}
                                                {/* <div className="position-relative">Result Announced. <a style={{ textDecoration: "none" }} href="https://countingwell.com/msf-2023-final-winners-list" target="_blank">Click Here to View the Winner List</a></div> */}
                                            </div> :
                                            <div >
                                                {this.parser.parseWithStyles(eventCardStaticText.eventFinishedText, { p: { fontSize: "15px", fontWeight: "600", color: "#353535" }, strong: { fontSize: "16px", fontWeight: "700", color: "#353535" } })}
                                            </div>
                                    }
                                </div>


                                :
                                <>

                                    {/* {
                                        eventRes.showPrepare || eventRes.showMockTest ?
                                            <View style={[Styles.rewardEventButtonWrapper1, eventRes.prepareButtonStyle ? eventRes.prepareButtonStyle : {}]}>
                                                {eventRes.showPrepare ?
                                                    <TouchableOpacity onPress={() => this.handlePreapre()} style={eventRes.showMockTest ? { marginRight: wp(0.5) } : {}}>
                                                        <LinearGradient colors={Colors.appTheme.gradient4} start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} style={Styles.prepareButton}>
                                                            <Text style={Styles.mocTestTextColor}>{eventCardStaticText.prepareText || "PREPARE"}</Text>
                                                        </LinearGradient>
                                                    </TouchableOpacity> : <></>}
                                                {eventRes.showMockTest ? <TouchableOpacity style={[Styles.mocTestButton, eventRes.showPrepare ? { marginLeft: wp(0.5) } : {}]} onPress={() => this.handleMockTest()}>
                                                    <Text style={Styles.mocTestTextColor}>{this.getMockTestTitle(eventCardStaticText, eventRes.mockTestStatus && eventRes.mockTestStatus.status || "START")}</Text>
                                                </TouchableOpacity> : <></>}
                                            </View> : <></>
                                    } */}

                                    {
                                        !this.state.isTwoStepEventDeadLineOver ?
                                            <div className="button-wrapper d-flex justify-content-center flex-column">
                                                {
                                                    (this.state.isKnowmoreRead || !eventRes.showKnowMore) &&
                                                        eventRes.event.status == 'LOCKED' &&
                                                        eventRes.isPaid ?
                                                        <div className="d-flex justify-content-center flex-column">
                                                            <div className="d-flex justify-content-center" >
                                                                <div className="know-more-button-disabled cursor-pointer" onClick={() => this.onPressTwoStepEventCardFooterButton()}>
                                                                    {this.twoStepEventFooterButtonTitle()}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        :
                                                        <div className="d-flex justify-content-center" >
                                                            <div className="know-more-button cursor-pointer" onClick={() => this.onPressTwoStepEventCardFooterButton()}>
                                                                {this.twoStepEventFooterButtonTitle()}
                                                            </div>
                                                        </div>
                                                    // :
                                                    // <div className="d-flex justify-content-center" >
                                                    //     <div className="know-more-button cursor-pointer" onClick={() => this.onPressTwoStepEventCardFooterButton()}>
                                                    //         {this.twoStepEventFooterButtonTitle()}
                                                    //     </div>
                                                    // </div>
                                                }

                                                {eventRes && eventRes.event.status == 'LOCKED' && eventRes.showTimer ?
                                                    // <div className="time-running-text text-center mt-2">
                                                    //     {eventCardStaticText.startsInText ? eventCardStaticText.startsInText : ""} {this.getTwoStepEventDisplayTimeForEvent()}
                                                    // </div>
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <div className="time-running-text text-center">
                                                            {this.getTwoStepEventDisplayTimeForEvent()}
                                                        </div>
                                                    </div>

                                                    :
                                                    (eventRes && eventRes.event && eventRes.event.status == "START") || (this.state.twoStepEventUserCurrentStepStatus == 'START' || this.state.twoStepEventUserCurrentStepStatus == 'IN_PROGRESS') ?
                                                        // <div className="timer-text mt-2 text-center">
                                                        //     {eventCardStaticText.itsLiveText ? eventCardStaticText.itsLiveText : "Its Live"} </div>
                                                        <></>
                                                        :
                                                        // <div className="timer-text mt-2">{eventCardStaticText.addText ? eventCardStaticText.addText : ""}</div>
                                                        eventRes.event.status == 'LOCKED' &&
                                                            eventRes.isPaid ? <div className="mt-2 text-center start-time-text">{eventCardStaticText.finalEventLiveText}</div> : <></>
                                                }

                                                {/* {!this.state.isTwoStepEventCompleted && (this.state.twoStepEventUserCurrentStepStatus == 'START' || this.state.twoStepEventUserCurrentStepStatus == 'IN_PROGRESS') ? <div>{eventCardStaticText.itsLiveText ? eventCardStaticText.itsLiveText : "Its Live"} </div> : <></>} */}

                                                {/* {eventRes.secondaryLogo ? <img className="secodary-logo-size" src={eventRes.secondaryLogo} ></img> : <></>} */}
                                            </div>
                                            :

                                            this.twoStepEventDeadLineOverText(eventCardStaticText)
                                    }
                                </>
                        }



                    </div>
                </div>
            </div >
        )
    }

    onPressTwoStepEventCard = (isDeepLink) => {

        let event = this.state.eventRes

        if (event && event.openLink && event.openLink.url) {
            // Linking.openURL(event.openLink.url)
            return
        }

        // let eventStarted = event.baseline.status == 'LOCKED' || event.baseline.status == 'START' ? false : true

        // if (this.knowMore && !eventStarted && this.state.twoStepEventUserCurrentStepStatus == 'LOCKED' && this.state.twoStepEventUserCurrentStep == 'STEP1') {
        //     moengage.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'No', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        //     FireBaseEvent.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'No', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        //     Facebook.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'No', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        //     BranchIO.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'No', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })

        //     this.props.navigation.navigate('ScholarshipEventKnowMore', {
        //         meta: {
        //             knowMore: this.twoStepEventKnowMore, eventId: this.state.eventRes && this.state.eventRes.eventId ? this.state.eventRes.eventId : '',
        //             from: constants.TWO_STEP_EVENT
        //         }
        //     })
        //     return
        // }

        // moengage.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        // FireBaseEvent.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        // Facebook.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        // BranchIO.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })

        this.rewardTwoStepEventHandleClick('event')
        return;
    }

    navigateToTwoStepEventResultScreen = () => {
        // this.props.navigation.navigate('TwoStepEventResultScreen', { meta: { event: this.state.eventRes } })
        this.props.delegate && this.props.delegate.showTwoStepEventResultScreen({ meta: { event: this.state.eventRes } })
    }

    renderCardAfterResultAnnounced = (eventRes, eventCardStaticText) => {


        if (this.state.twoStepWildCardUser) {
            return (
                <>
                    {/* <Text style={Styles.resultAnouncedTextNew}>{CommonUtils.simpleTemplateEngine(eventCardStaticText.wildCardResultAnnouncedText, { percentage: eventRes?.winnersMeta?.award?.displayValue ? eventRes?.winnersMeta?.award?.displayValue : 0 })}</Text> */}
                    {/* <Text style={Styles.newResultAnouncedTextNew}>{eventCardStaticText.wildcardCongratulationsText ? eventCardStaticText.wildcardCongratulationsText : "Congratulations! You Won"}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: wp(2.5), justifyContent: 'center' }}>
                        <TouchableOpacity onPress={this.navigateToTwoStepEventResultScreen} >
                            <LinearGradient colors={["#0AC6D6", "#5CD492"]} start={{ x: 0, y: 1 }} end={{ x: 1, y: 0 }} style={Styles.knowMoreButton}>
                                <Text style={Styles.twoStepEventCardButton}>{eventCardStaticText.knowMoreButtonTitle ? eventCardStaticText.knowMoreButtonTitle : 'KNOW MORE'}</Text>
                            </LinearGradient>
                        </TouchableOpacity>
                    </View> */}
                    <div>wild card user</div>
                </>
            )
        } else {
            return (

                <>
                    {/* {eventRes.winnersMeta.isRunnerWithCashPrize && eventRes.winnersMeta.award.cashPrice ? <Text style={Styles.resultAnouncedTextNew}>{CommonUtils.simpleTemplateEngine(eventCardStaticText.resultAnnouncedRunnerWithCashPrizeText, { percentage: eventRes?.winnersMeta?.award?.displayValue ? eventRes?.winnersMeta?.award?.displayValue : 0 })}</Text> : <></>}
                    {eventRes.winnersMeta.isRunner && !eventRes.winnersMeta.award.cashPrice ? <Text style={Styles.resultAnouncedTextNew}>{simpleTemplateEngine(eventCardStaticText.resultAnnouncedRunnerText, { percentage: eventRes?.winnersMeta?.award?.displayValue ? eventRes?.winnersMeta?.award?.displayValue : 0 })}</Text> : <></>}
                        {eventRes.winnersMeta.isWinner ? <Text style={Styles.resultAnouncedTextNew}>{simpleTemplateEngine(eventCardStaticText.resultAnnouncedWinnerText, { range: eventRes?.winnersMeta?.award?.displayValue ? eventRes?.winnersMeta?.award?.displayValue : 0 })}</Text> : <></>} */}
                    <div className="result-announeced-text">{eventCardStaticText.congratulationsText ? eventCardStaticText.congratulationsText : "Congratulations! You Won"}</div>
                    <div className="d-flex justify-content-center position-relative" >
                        <div className="know-more-button cursor-pointer" onClick={() => this.navigateToTwoStepEventResultScreen()}>
                            <div>{eventCardStaticText.resultAnnouncedButtonTitle ? eventCardStaticText.resultAnnouncedButtonTitle : 'VIEW RESULT'}</div>
                        </div>
                    </div>
                    {/* <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: wp(2), justifyContent: 'center' }}>
                        <TouchableOpacity onPress={this.navigateToTwoStepEventResultScreen} style={Styles.shadow}>
                            <LinearGradient colors={["#0AC6D6", "#5CD492"]} start={{ x: 0, y: 1 }} end={{ x: 1, y: 0 }} style={Styles.knowMoreButton}>
                                <Text style={Styles.twoStepEventCardButton}>{eventCardStaticText.resultAnnouncedButtonTitle ? eventCardStaticText.resultAnnouncedButtonTitle : 'VIEW RESULT'}</Text>
                            </LinearGradient>
                        </TouchableOpacity>
                    </View> */}
                </>
            )
        }







        //     // if (eventRes.winnersMeta && eventRes.winnersMeta.isRunner) {
        //     //     return (
        //     //         <>
        //     //             <Text style={Styles.resultAnouncedTextNew}>{simpleTemplateEngine(eventCardStaticText.resultAnnouncedRunnerText, { percentage: eventRes?.winnersMeta?.award?.displayValue ? eventRes?.winnersMeta?.award?.displayValue + '%' : 0 })}</Text>

        //     //             {/* <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: wp(5),justifyContent :'center' }}>
        //     //                 <TouchableOpacity onPress={() => this.onClickOpenUrl(eventRes?.winnersMeta?.discountLink ? eventRes?.winnersMeta?.discountLink : eventCardStaticText.claimScholarshipUrl)} style={Styles.shadow}>
        //     //                     <LinearGradient colors={["#0AC6D6", "#5CD492"]} start={{ x: 0, y: 1 }} end={{ x: 1, y: 0 }} style={Styles.claimButton}>
        //     //                         <Text style={Styles.twoStepEventCardButton}>{eventCardStaticText.claimScholarshipButtonTitle ? eventCardStaticText.claimScholarshipButtonTitle : 'CLAIM YOUR SCHOLARSHIP'}</Text>
        //     //                     </LinearGradient>
        //     //                 </TouchableOpacity>
        //     //             </View> */}



        //     //             <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: wp(5),justifyContent :'center' }}>
        //     //                 <TouchableOpacity onPress={()=>this.props.navigation.navigate('TwoStepEventResultScreen', {meta: {event: this.state.eventRes} })} style={Styles.shadow}>
        //     //                     <LinearGradient colors={["#0AC6D6", "#5CD492"]} start={{ x: 0, y: 1 }} end={{ x: 1, y: 0 }} style={Styles.claimButton}>
        //     //                         <Text style={Styles.twoStepEventCardButton}>{eventCardStaticText.knowMoreButtonTitle ? eventCardStaticText.knowMoreButtonTitle : 'KNOW MORE'}</Text>
        //     //                     </LinearGradient>
        //     //                 </TouchableOpacity>
        //     //             </View>

        //     //         </>
        //     //     )
        //     // } else if (eventRes.winnersMeta && eventRes.winnersMeta.isWinner) {
        //     //     return (
        //     //         <>
        //     //             <Text style={Styles.resultAnouncedTextNew}>{simpleTemplateEngine(eventCardStaticText.resultAnnouncedWinnerText, { range: eventRes?.winnersMeta?.award?.displayValue ? eventRes?.winnersMeta?.award?.displayValue : 0 })}</Text>

        //     //             <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: wp(5) }}>
        //     //                 <TouchableOpacity style={[Styles.claimScholarshipButton, { marginRight: wp(1) }]} onPress={() => this.onClickOpenUrl(eventCardStaticText.claimCashPriceUrl)}>
        //     //                     <Text style={Styles.twoStepEventCardButton}>{eventCardStaticText.claimCashPriceButtonTitle ? eventCardStaticText.claimCashPriceButtonTitle : 'CLAIM YOUR CASH PRIZE'}</Text>
        //     //                 </TouchableOpacity>

        //     //                 <TouchableOpacity onPress={() => this.onClickOpenUrl(eventRes?.winnersMeta?.discountLink ? eventRes?.winnersMeta?.discountLink : eventCardStaticText.claimScholarshipUrl)} style={Styles.shadow}>
        //     //                     <LinearGradient colors={["#0AC6D6", "#5CD492"]} start={{ x: 0, y: 1 }} end={{ x: 1, y: 0 }} style={[Styles.claimButton, { marginLeft: wp(1) }]}>
        //     //                         <Text style={Styles.twoStepEventCardButton}>{eventCardStaticText.claimScholarshipButtonTitle ? eventCardStaticText.claimScholarshipButtonTitle : 'CLAIM YOUR SCHOLARSHIP'}</Text>
        //     //                     </LinearGradient>
        //     //                 </TouchableOpacity>
        //     //             </View>
        //     //         </>
        //     //     )
        //     // }
        //     // else {
        //     //     return (
        //     //         <>
        //     //             <Text style={Styles.resultAnouncedTextNew}>{eventCardStaticText.resultAnnouncedText}</Text>
        //     //         </>
        //     //     )
        //     // }

    }

    renderTwoStepEventCardText = (eventCardStaticText) => {

        let text = ''

        if (this.state.twoStepEventUserCurrentStep == 'STEP1') {

            if (this.state.twoStepEventUserCurrentStepStatus == 'LOCKED')
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.readyToParticipateText, { count: this.state.eventParticipantsCount })
            else if (this.state.twoStepEventUserCurrentStepStatus == 'START') {
                text = eventCardStaticText.baselineAssessmentLive
            } else if (this.state.twoStepEventUserCurrentStepStatus == 'IN_PROGRESS') {
                text = eventCardStaticText.baselineAssessmentResumeText
            }

        } else if (this.state.twoStepEventUserCurrentStep == 'STEP2') {

            if (this.state.twoStepEventUserCurrentStepStatus == 'LOCKED' || this.state.twoStepEventUserCurrentStepStatus == 'START')
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.startCourseText, { count: this.state.numberOfStudentsStartedRefresher })
            else if (this.state.twoStepEventUserCurrentStepStatus == 'IN_PROGRESS')
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.courseInstructionText, { count: this.state.totalStudentsEligible })
            else if (this.state.twoStepEventUserCurrentStepStatus == 'COMPLETED')
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.courseCompletedText, { count: this.state.totalStudentsEligible })

        } else if (this.state.twoStepEventUserCurrentStep == 'STEP3') {

            if (this.state.twoStepEventUserCurrentStepStatus == 'LOCKED')
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.courseCompletedText, { count: this.state.totalStudentsEligible })
            else if (this.state.twoStepEventUserCurrentStepStatus == 'START')
                text = eventCardStaticText.finalEventLiveText
            else if (this.state.twoStepEventUserCurrentStepStatus == 'IN_PROGRESS')
                text = eventCardStaticText.finalAssessmentResumeText
        }

        return text
    }

    renderTwoStepEventCardSubText = (eventCardStaticText) => {

        let text = ''

        if (this.state.twoStepEventUserCurrentStep == 'STEP1') {

            if (this.state.twoStepEventUserCurrentStepStatus == 'LOCKED') {
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.eventParticipantsText, { count: this.state.eventParticipantsCount })
            } else if (this.state.twoStepEventUserCurrentStepStatus == 'START') {
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.baselineAssessmentStartedText, { count: this.state.baselineStartedStudents })
            } else if (this.state.twoStepEventUserCurrentStepStatus == 'IN_PROGRESS') {
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.baselineAssessmentStartedText, { count: this.state.baselineStartedStudents })
            }

        } else if (this.state.twoStepEventUserCurrentStep == 'STEP2') {

            if (this.state.twoStepEventUserCurrentStepStatus == 'LOCKED' || this.state.twoStepEventUserCurrentStepStatus == 'START') {
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.courseStartedStudentsText, { count: this.state.numberOfStudentsStartedRefresher })
            }
            else if (this.state.twoStepEventUserCurrentStepStatus == 'IN_PROGRESS') {
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.courseCompletedStudentsText, { count: this.state.totalStudentsEligible })
            }
        } else if (this.state.twoStepEventUserCurrentStep == 'STEP3') {

            if (this.state.twoStepEventUserCurrentStepStatus == 'LOCKED') {
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.finalAssessmentParticipantsText, { count: this.state.totalStudentsEligible })
            } else if (this.state.twoStepEventUserCurrentStepStatus == 'START') {
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.finalAssessmentParticipantsText, { count: this.state.totalStudentsEligible })
            } else if (this.state.twoStepEventUserCurrentStepStatus == 'IN_PROGRESS') {
                text = CommonUtils.simpleTemplateEngine(eventCardStaticText.finalAssessmentStartedText, { count: this.state.totalStudentsEligible })
            }

            // text = this.eventCardStaticText.scholarshipEventLiveText
        }

        return text
    }

    onPressTwoStepEventCardFooterButton = () => {

        let event = this.state.eventRes

        if (event && event.openLink && event.openLink.url) {
            window.open(`${event.openLink.url}`)
            return
        }

        // if (this.knowMore && this.state.twoStepEventUserCurrentStepStatus == 'LOCKED' && this.state.twoStepEventUserCurrentStep == 'STEP1') {
        //     moengage.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'No', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        //     FireBaseEvent.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'No', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        //     Facebook.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'No', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        //     BranchIO.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'No', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })

        //     this.props.navigation.navigate('ScholarshipEventKnowMore', {
        //         meta: {
        //             knowMore: this.twoStepEventKnowMore, eventId: this.state.eventRes && this.state.eventRes.eventId ? this.state.eventRes.eventId : '',
        //             from: constants.TWO_STEP_EVENT
        //         }
        //     })
        //     return
        // }

        // moengage.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        // FireBaseEvent.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        // Facebook.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })
        // BranchIO.logCustomEvent('Event', { 'User Id': this.userInfo.userId, "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Baseline Status': this.getBaselineAssessmentStatus(event.baseline.status), 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.event.status), "Learning Status": event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED' ? 'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`, 'Viewed Baseline Report': getBooleanValue(!this.state.eventRes.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.state.eventRes.viewedCertificate) })

        // if ((this.state.isKnowmoreRead || !event.showKnowMore) && event.event.status == 'LOCKED' && event.isPaid) {
        //     return
        // }

        this.rewardTwoStepEventHandleClick('event')
    }

    twoStepEventFooterButtonTitle = () => {

        let event = this.state.eventRes

        let eventStarted = event.event.status == 'LOCKED' ? false : true


        if (!this.state.isKnowmoreRead && !eventStarted && event.showKnowMore) {
            return 'KNOW MORE'
        }

        if (event.event.status == "LOCKED" && !event.isPaid) {
            return event.staticText && event.staticText.eventCard && event.staticText.eventCard.amountButtonText || "Pay and continue"
        }

        if (event.event.status == "LOCKED")
            return 'START'

        if (event.event.status == "START")
            return 'START'

        if (event.event.status == "COMPLETED")
            return 'REVISE'

        if (event.event.status == "IN_PROGRESS")
            return 'CONTINUE'


        if (event.event.status == "COMPLETED")
            return 'RESULT'
    }

    getTwoStepEventDisplayTimeForEvent = (seconds) => {

        let d = this.state.twoStepEventRemaningTimeForCurrentStep ? this.state.twoStepEventRemaningTimeForCurrentStep : ""

        if (seconds) {
            d = seconds
        }

        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hDisplay = h > 9 ? h : "0" + h
        let mDisplay = m > 9 ? m : "0" + m
        let sDisplay = s > 9 ? s : "0" + s
        return hDisplay + ':' + mDisplay + ':' + sDisplay;
    }

    twoStepEventDeadLineOverText = (eventCardStaticText) => {

        let text = eventCardStaticText.eventDeadlineOverText

        return <div className="d-flex justify-content-center flex-column">
            <div className="text-center">
                <img className="missed-event-flag" src={getImageSource(ImageConstants.MISSED_EVENT_FLAG)}></img>
            </div>
            <div className="missed-event-text text-center mt-2">{text}</div>
        </div >
    }

    rewardTwoStepEventHandleClick = (step) => {

        let data = this.state.eventRes && this.state.eventRes[step]

        let event = this.state.eventRes["event"]

        //console.log(this.state.eventRes.title)

        let eventStarted = this.state.eventRes["event"].status == 'LOCKED' ? false : true

        if (!data || data.status == 'DEADLINE_OVER')
            return

        if (!this.state.isKnowmoreRead && !eventStarted && this.state.eventRes.showKnowMore) {
            FireBaseEvent('Event', {
                'User Id': this.userInfo.userId,
                plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN,
                "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.status), 'Viewed Finishline Report': CommonUtils.getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': CommonUtils.getBooleanValue(!this.state.eventRes.viewedCertificate)
            })
            this.props.delegate.showKnowMoreScreens && this.props.delegate.showKnowMoreScreens({
                meta: {
                    knowMore: this.twoStepEventKnowMore,
                    eventId: this.state.eventRes && this.state.eventRes.eventId ? this.state.eventRes.eventId : '',
                    from: Constants.TWO_STEP_EVENT,
                    isPaidEvent: true,
                    event: this.state.eventRes
                }
            })
            return
        }

        if (event.status == 'LOCKED') {
            FireBaseEvent('Event', {
                'User Id': this.userInfo.userId,
                plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN,
                "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.status), 'Viewed Finishline Report': CommonUtils.getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': CommonUtils.getBooleanValue(!this.state.eventRes.viewedCertificate)
            })
            this.props.delegate && this.props.delegate.showPaidEventLandingScreen({
                event: this.state.eventRes
            })
            // moengage.logCustomEvent('Event',{'User Id':this.userInfo.userId,"Event Name":this.state.eventRes.title,'Explored':'No','Assessment Baseline Status':this.getBaselineAssessmentStatus(event.baseline.status),'Assessment Finishline Status':this.getFinalAssessmentStatus(event.event.status),"Learning Status":event.refresherCourse.status == 'LOCKED' || event.refresherCourse.status == 'START' ? 'Not Started' : event.refresherCourse.status == 'COMPLETED'?'Completed' : `${this.state.scholarshipStep2Percentage ? this.state.scholarshipStep2Percentage : 0}% Completed`})
            // this.props.navigation.navigate('ScholarshipEventKnowMore', {
            //     meta: {
            //         knowMore: this.twoStepEventKnowMore,
            //         eventId: this.state.eventRes && this.state.eventRes.eventId ? this.state.eventRes.eventId : '',
            //         from: constants.TWO_STEP_EVENT,
            //         isPaidEvent: true,
            //         event : this.state.eventRes
            //     }
            // })
            return
        }

        let meta = {
            eventId: this.state.eventRes.eventId,
            eventThreshold: 0
        }

        FireBaseEvent('Event', {
            'User Id': this.userInfo.userId,
            plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN,
            "Event Name": this.state.eventRes.title, 'Explored': 'Yes', 'Assessment Finishline Status': this.getFinalAssessmentStatus(event.status), 'Viewed Finishline Report': CommonUtils.getBooleanValue(!this.state.eventRes.viewedFinishlineReport), 'Viewed Event Certificate': CommonUtils.getBooleanValue(!this.state.eventRes.viewedCertificate)
        })

        if (step == 'event') {
            meta.type = Constants.TWO_STEP_EVENT_FINAL;
            meta.title = this.state.eventRes && this.state.eventRes.staticText && this.state.eventRes.staticText.eventCard && this.state.eventRes.staticText.eventCard.scholarshipAssessmentTitle ? this.state.eventRes.staticText.eventCard.scholarshipAssessmentTitle : ''
            meta.viewAnswerTime = this.state.eventRes && this.state.eventRes.event && this.state.eventRes.event.viewAnswerTime ? this.state.eventRes.event.viewAnswerTime : null
            meta.viewLeaderboardTime = this.state.eventRes && this.state.eventRes.event && this.state.eventRes.event.viewLeaderboardTime ? this.state.eventRes.event.viewLeaderboardTime : null


            if (data.status == 'IN_PROGRESS') {

                this.props.delegate && this.props.delegate.showAssessmentEngine && this.props.delegate.showAssessmentEngine({
                    meta: {
                        chapterTitle: meta.title,
                        isResume: true,
                        assessmentActivityId: data.assessmentActivityId,
                        categoryThemeId: "1",
                        assessmentId: data.assessmentId,
                        from: Constants.TWO_STEP_EVENT_FINAL,
                        type: Constants.TWO_STEP_EVENT_FINAL,
                        eventId: this.state.eventRes.eventId,
                        scholarshipEndScreenStaticText: this.state.eventRes && this.state.eventRes.staticText && this.state.eventRes.staticText.scholarshipEndScreen ? this.state.eventRes.staticText.scholarshipEndScreen : {},
                        viewAnswerTime: this.state.eventRes && this.state.eventRes.event && this.state.eventRes.event.viewAnswerTime ? this.state.eventRes.event.viewAnswerTime : null,
                        viewLeaderboardTime: this.state.eventRes && this.state.eventRes.event && this.state.eventRes.event.viewLeaderboardTime ? this.state.eventRes.event.viewLeaderboardTime : null,
                        viewAnswerTimeScreenStaticText: this.state.eventRes.staticText && this.state.eventRes.staticText.viewAnswerTimeScreen ? this.state.eventRes.staticText.viewAnswerTimeScreen : null,
                        eventPayload: this.state.eventRes

                    }
                })
                // this.props.navigation.navigate('AssessmentEngine', {
                //     meta: {
                //         chapterTitle: meta.title,
                //         isResume: true,
                //         assessmentActivityId: data.assessmentActivityId,
                //         categoryThemeId: "1",
                //         assessmentId: data.assessmentId,
                //         from: Constants.TWO_STEP_EVENT_FINAL,
                //         type: Constants.TWO_STEP_EVENT_FINAL,
                //         eventId: this.state.eventRes.eventId,
                //         scholarshipEndScreenStaticText: this.state.eventRes && this.state.eventRes.staticText && this.state.eventRes.staticText.scholarshipEndScreen ? this.state.eventRes.staticText.scholarshipEndScreen : {},
                //         viewAnswerTime: this.state.eventRes && this.state.eventRes.event && this.state.eventRes.event.viewAnswerTime ? this.state.eventRes.event.viewAnswerTime : null,
                //         viewLeaderboardTime: this.state.eventRes && this.state.eventRes.event && this.state.eventRes.event.viewLeaderboardTime ? this.state.eventRes.event.viewLeaderboardTime : null,
                //         viewAnswerTimeScreenStaticText: this.state.eventRes.staticText && this.state.eventRes.staticText.viewAnswerTimeScreen ? this.state.eventRes.staticText.viewAnswerTimeScreen : null,
                //         eventPayload: this.state.eventRes

                //     }
                // })
                return
            }


            if (data.status == 'COMPLETED') {

                let payload = {
                    data: data.activity,
                    chapterTitle: meta.title,
                    categoryThemeId: "1",
                    assessmentActivityId: data.activity._id,
                    chapterKey: "",
                    type: Constants.TWO_STEP_EVENT_FINAL,
                    from: Constants.TWO_STEP_EVENT_FINAL,
                    selectedChapters: [],
                    isResultAnnounced: false,
                    eventId: this.state.eventRes.eventId,
                    scholarshipEndScreenStaticText: this.state.eventRes && this.state.eventRes.staticText && this.state.eventRes.staticText.scholarshipEndScreen ? this.state.eventRes.staticText.scholarshipEndScreen : {},
                    viewAnswerTime: this.state.eventRes && this.state.eventRes.event && this.state.eventRes.event.viewAnswerTime ? this.state.eventRes.event.viewAnswerTime : null,
                    viewAnswerTimeScreenStaticText: this.state.eventRes.staticText && this.state.eventRes.staticText.viewAnswerTimeScreen ? this.state.eventRes.staticText.viewAnswerTimeScreen : null,
                    viewLeaderboardTime: this.state.eventRes && this.state.eventRes.event && this.state.eventRes.event.viewLeaderboardTime ? this.state.eventRes.event.viewLeaderboardTime : null,
                    eventPayload: this.state.eventRes
                }

                if (!data.gotFeedBack) {
                    this.props.delegate && this.props.delegate.showScholarshipFeedback && this.props.delegate.showScholarshipFeedback({
                        meta: payload
                    })
                    return
                }


                if (this.state.twoStepWildCardUser && this.state.twoStepWildcardResultAnnounced && this.state.isTwoStepEventCompleted) {
                    // this.props.navigation.navigate('TwoStepEventResultScreen', {
                    //     meta: {
                    //         event: this.state.eventRes
                    //     }
                    // })
                    return
                }

                if (!this.state.twoStepWildCardUser && this.state.twoStepEventResultAnnounced && this.state.isTwoStepEventCompleted) {
                    // this.props.navigation.navigate('TwoStepEventResultScreen', {
                    //     meta: {
                    //         event: this.state.eventRes
                    //     }
                    // })
                    return
                }


                this.props.delegate && this.props.delegate.showOneStepEventAssessmentEndScreen && this.props.delegate.showOneStepEventAssessmentEndScreen({
                    meta: payload
                })
                return
            }
        }

        meta = {
            ...meta,
            isStarted: data.status == 'LOCKED' ? false : true,
            remainingTime: this.state.remaningTimeForCurrentStep,
            scholarshipEndScreenStaticText: this.state.eventRes && this.state.eventRes.staticText && this.state.eventRes.staticText.scholarshipEndScreen ? this.state.eventRes.staticText.scholarshipEndScreen : {},
            schollarshipStartScreen: this.state.eventRes && this.state.eventRes.staticText && this.state.eventRes.staticText.schollarshipStartScreen ? this.state.eventRes.staticText.schollarshipStartScreen : {},
            scholarshipEligibleStaticText: this.state.eventRes && this.state.eventRes.staticText && this.state.eventRes.staticText.scollarShipEligiblePopUp ? this.state.eventRes.staticText.scollarShipEligiblePopUp : {},
            viewAnswerTimeScreenStaticText: this.state.eventRes.staticText && this.state.eventRes.staticText.viewAnswerTimeScreen ? this.state.eventRes.staticText.viewAnswerTimeScreen : null,
            eventPayload: this.state.eventRes
        }

        // if (!this.userInfo.schoolName || !this.userInfo.schoolLocality) {
        //     this.props.navigation.navigate('AddYourSchoolV2', { meta: { from: "EVENT", customMeta: meta } })
        //     return
        // }

        // if (this.userInfo.event && this.userInfo.event[this.state.eventRes.eventId] && this.userInfo.event[this.state.eventRes.eventId].reward) {
        this.props.delegate && this.props.delegate.showAssessmentStartScreen && this.props.delegate.showAssessmentStartScreen({ meta })

        // this.props.navigation.navigate('ScholarshipStartScreenV2', { meta })
    }

    twoStepEventMenRunningPosition = () => {
        // if (this.state.activeTwoStepEventStep == 'STEP2') {
        //     return `${20 + ((this.state.twoStepEventStep2Percentage / 100) * 60) - 6.75}%`
        // } else if (this.state.activeTwoStepEventStep == 'STEP3') {
        //     return `${80 + ((this.state.twoStepEventStep3Percentage / 100) * 20) - 6.75}%`
        // } else {
        //     return `${((this.state.twoStepEventStep1Percentage / 100) * 20) ? ((this.state.twoStepEventStep1Percentage / 100) * 20) - 8 : ((this.state.scholarshipStep1Percentage / 100) * 20)}%`
        // }

        let qualifyPercentage = this.state.twoStepEventStep1Percentage == 100 ? this.state.eventRes.baselineComletionPercentage : 0

        if (this.state.twoStepEventStep2Percentage) {
            qualifyPercentage += this.state.twoStepEventStep2Percentage * ((100 - this.state.eventRes.baselineComletionPercentage) / 100)
        }


        if (this.state.activeTwoStepEventStep == 'STEP3') {
            return `${80 + ((this.state.twoStepEventStep3Percentage / 100) * 20) - 6.75}%`
        } else {
            return `${((qualifyPercentage / 100) * 80) ? ((qualifyPercentage / 100) * 80) - 8 : ((qualifyPercentage / 100) * 80)}%`
        }
    }

    getBaselineAssessmentStatus = (status) => {

        switch (status) {

            case 'LOCKED':
                return 'Not Started'

            case 'COMPLETED':
                return 'Completed'

            case 'START':
                return 'Not Started'

            case "IN_PROGRESS":
                return 'In Progress'
        }

    }

    getFinalAssessmentStatus = (status) => {

        switch (status) {

            case 'LOCKED':
                return 'Not Started'

            case 'COMPLETED':
                return 'Completed'

            case 'START':
                return 'Qualified'

            case "IN_PROGRESS":
                return 'In Progress'
        }

    }


    render() {
        return (
            this.state.showTwoStepEventCard ? this.getTwoStepEventCardV2() : <></>
        );
    }
}


import React, { Component } from 'react';
import './workout-header-pagination.scss';
import { CircularProgressbar } from 'react-circular-progressbar';


// const deviceWidth = Dimensions.get('window').width;
// const deviceHeight = Dimensions.get('window').height;
// const screenWidth = Dimensions.get('screen').width;
// const screenHeight = Dimensions.get('screen').height;

export default class LearningContentHeaderPagination extends Component {
    constructor(props) {
        super(props);

        let contents = []
        this.allPagePercentage = this.props.allPagePercentage

        for (let i = 0; i < this.props.totalPage; i++) {
            let item = {}

            if (this.allPagePercentage && this.props.totalPage == this.allPagePercentage.length) {
                item.percentage = this.allPagePercentage[i]
            }
            contents.push(item)
        }
        this.state.contents = contents;
    }

    activeIndex = 0
    pagesRef = {}

    state = {
        contents: [],
        index: 1
    }

    getActiveLine = (i) => {

        let bgColor = `linear-gradient(90deg,#05C5D9 0%,#5FD590 100%)`
        return (
            <div className='gradient-border-height' style={{ backgroundImage: bgColor }} >

            </div>
        )
    }

    getInactiveLine = () => {

        return (
            <div className='gradient-border-height'></div>
        )

    }

    // getActiveHitslopAdjustmentLine = () => {
    //     return (
    //         <LinearGradient colors={["#5FD590", "#5FD590"]} start={{ x: 0, y: 0 }} locations={[1, 0.6]} end={{ x: 1, y: 0 }} style={[Styles.gradientBorderHeight, Styles.hitSlopAdjustmentLine]} >

    //         </LinearGradient>
    //     )
    // }

    // getInactiveHitslopAdjustmentLine = () => {
    //     return (
    //         <View style={[Styles.gradientBorderHeight, Styles.hitSlopAdjustmentLine]} ></View>
    //     )
    // }


    getPages = () => {

        let allPagePercentage = this.props.allPagePercentage;

        let render = [];

        let bgColor = `linear-gradient(90deg,#05C5D9 0%,#5FD590 100%)`


        for (let i = 0; i < this.state.contents.length; i++) {

            let item = this.state.contents[i]

            if (this.props.activeIndex == i) {
                render.push(

                    <div ref={ref => { this.pagesRef[i] = ref }} key={i} className='dot-line-container d-flex align-items-center'>
                        {this.getActiveLine(i)}
                        <div>
                            <div className={` position-relative ${this.props.showPaginationProgress ? 'index-container': 'index-container-1'}`} style={{ backgroundImage: bgColor }} >
                                <span className='active-index'>{i + 1}</span>
                                {
                                    this.props.showPaginationProgress ? <div className='progress-container'>
                                        <div className='mr-2' style={{ width: '40px', height: '40px' }}>
                                            <CircularProgressbar value={this.props.percentageCompleted && this.props.percentageCompleted > 0 ? this.props.percentageCompleted : 0}
                                                strokeWidth={15}
                                            />
                                        </div>
                                    </div> : <></>
                                }

                            </div>
                        </div>
                    </div>
                )
            } else if (i <= this.props.navigateTill) {
                render.push(
                    <div ref={ref => { this.pagesRef[i] = ref }} key={i} className='dot-line-container d-flex align-items-center cursor-pointer' >
                        {this.getActiveLine(i)}
                        <div className='' onClick={() => this.navigate(i, this.props.navigateTill)}>
                            <div className={` position-relative ${this.props.showPaginationProgress ? 'small-index-container': 'small-index-container-1'}`} style={{ backgroundImage: bgColor }} >
                                <span className='inactive-index'>{i + 1}</span>
                                {
                                    this.props.showPaginationProgress ?
                                        <div className='progress-container'>
                                            <div className='mr-2' style={{ width: '40px', height: '40px' }}>
                                                <CircularProgressbar value={allPagePercentage && allPagePercentage[i] && allPagePercentage[i] > 0 ? allPagePercentage[i] : 0}
                                                    strokeWidth={15}
                                                />
                                            </div>
                                        </div>
                                        : <></>
                                }

                            </div>
                        </div>
                        {/* {i < this.props.navigateTill ? this.getActiveHitslopAdjustmentLine() : this.getInactiveHitslopAdjustmentLine()} */}
                    </div>
                )
            } else if (i > this.props.navigateTill) {
                render.push(
                    <div ref={ref => { this.pagesRef[i] = ref }} key={i} className='dot-line-container d-flex align-items-center'>
                        {this.getInactiveLine()}
                        <div className='small-index-container' >
                            <span className='inactive-index' >{i + 1}</span>
                        </div>
                    </div>
                );
            }

        }

        return render;
    }

    navigate = (index) => {

        if (this.props.activeIndex > this.activeIndex) {
            this.activeIndex = this.props.activeIndex
        }

        this.props.delegate && this.props.delegate.navigate(index, this.activeIndex);

    }

    // scrollTo = () => {

    //     // this.scroll.scrollTo({ x: this.pagesRef[this.props.activeIndex].x, animated: true })



    // }

    scrollTo = () => {

        // this.scroll.scrollTo({ x: this.pagesRef[this.props.activeIndex].x, animated: true })
        //console.log('scrolled', this.pagesRef[this.props.activeIndex])
        // console.log("width....",this.scroll.current.scrollWidth)

        let keys = Object.keys(this.pagesRef)

        // console.log(keys, this.props.activeIndex)

        if (this.pagesRef && this.props.activeIndex && this.pagesRef[this.props.activeIndex - 1]) {

            let singlePageNumberWidth = this.scroll.current.scrollWidth / keys.length



            // let scrollLeftPosition =  this.props.activePage * this.pagesRef[this.props.activePage - 1].offsetWidth 

            let scrollLeftPosition = (this.props.activeIndex - 1) * singlePageNumberWidth

            console.log(this.scroll.current.scrollLeft, scrollLeftPosition)


            this.scroll.current.scrollLeft = scrollLeftPosition

            let element = this.pagesRef[this.props.activeIndex]

            if (element) {
                element.scrollIntoView({ behavior: "instant", block: 'center' })
            }


        }




    }

    render() {

        return (
            <div className={`exam-prep-kit-header-pagination d-flex justify-content-center`}
                style={{ width: `${this.state.contents.length > 18 ? '85%' : ''}` }} ref={(ref) => { this.scroll = ref }}>
                <div ref={this.scroll} className='pagination-container d-flex align-items-center'>
                    {this.getPages()}
                </div>
            </div>
        )
    }


}



import React, { Component } from 'react';
import './exam-prep-kit-learning-content-wrapper.scss';
import LearningContent from '../../../learning/learning-content/learning-content';
import { ImageConstants } from '../../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../../utility/image-source';
import LearningContentFooter from '../../../learning/learning-footer/learning-footer';
import SharedProfile from '../../../../../shared-storage/profile';
import WorkoutLearningContentHeader from './exam-prep-kit-learning-content-header/exam-prep-kit-learning-content-header';
// import LearningContentPopup from '../learning-content-popup/learning-content-popup';
import GlossaryParser from "../../../common/content-parser/glossary-parser";
import SharedStaticText from '../../../../../shared-storage/static-text-store';
import CommonApi from '../../../../apis/common';
import PracticeQuestionsApi from '../../../../apis/practice-questions';
import { CircularProgressbar } from 'react-circular-progressbar';
import Loading from '../../../common/loading/loading';

// const deviceWidth = Dimensions.get('window').width;
// const deviceHeight = Dimensions.get('window').height;
// const screenWidth = Dimensions.get('screen').width;
// const screenHeight = Dimensions.get('screen').height;

// const navigationBarHeight = (screenHeight - deviceHeight);

export default class ExamPrepKitLeaningContentWrapper extends Component {
    constructor(props) {
        super(props);
        // const { navigation } = this.props;
        // this.ycontentOffsetPosition = screenHeight / 4;

        this.state.elo = this.props.elo;

        this.glossaryParser = new GlossaryParser();
        this.userInfo = SharedProfile.getProfile();

        if (this.props.navigationInfo && this.props.navigationInfo.isLateralMenuNavigation) {
            let index = 0;
            if (this.props.elo) {
                this.props.elo.map((elo, i) => {
                    if (elo.eloId == this.props.navigationInfo.activeEloId) {
                        index = i;
                    }
                });
                this.state.eloIndex = index;
            }
        }

        if (this.props.isChapterIntro) {
            this.logNavigation({ ...this.props.navigationInfo });
        } else {
            this.logEloNavigation();
        }

        if (this.props.activeIndex)
            this.state.eloIndex = this.props.activeIndex

        if (this.props.allPagesActive)
            this.navigateTill = this.state.elo.length

        this.staticText = SharedStaticText.getStaticText('examPrepKitLeaningExpeirience');
    }

    componentDidMount() {

        this.getGlossaryContent();
        // KeepAwake.activate();
        this.prepareLearningContentVisibility();
        // this.getBookmarkHelpShowed()
    }

    componentWillUnmount() {
        // KeepAwake.deactivate();
    }

    nextLearningContentMap = {};
    allElosLearningHash = {};

    videoPlayerRefs = {};
    state = {
        eloIndex: 0,
        fadeIn: false,
        showShadow: false,
        glossaryContent: null,
        blurNavigationFeedBack: false,
        elo: [],
        isLast: false,
        scrollViewHeight: null,
        showAskDoubt: false,
        showSuccessPopup: false
    };

    // animatedScroll = new Animated.Value(0);

    pageLength = this.props.elo ? this.props.elo.length : 0;
    currentPage = this.props.elo && this.props.elo.length ? 1 : 0;


    logNavigation(navigationInfo) {
    }

    hideChapterIntroduction = () => {
        this.props.delegate && this.props.delegate.hideChapterIntroduction()
    }


    logEloNavigation = (isLast) => {
    }


    getGlossaryContent = () => {

        if (this.props && this.props.elo && this.props.elo.length > 0) {

            let elos = this.props.elo

            let contents = [];

            for (let i = 0; i < elos.length; i++) {

                let contentsList = elos[i]['contents'];

                for (let j = 0; j < contentsList.length; j++) {

                    contents = contents.concat(contentsList[j].contents)
                }
            }

            if (contents.length > 0) {

                this.glossaryParser.getParsedContentIdsFromContent(contents, (err, glossaryContent) => {
                    this.setState({
                        glossaryContent
                    })
                })

            } else {
                this.setState({
                    glossaryContent: {}
                })
            }

        } else {
            this.setState({
                glossaryContent: {}
            })
        }
    };


    resetScrollViewValues() {
        this.contentScrollHeight = 0;
        this.lastHeaderHeight = this.headerHeight != 0 ? this.headerHeight : this.lastHeaderHeight;
        this.headerHeight = 0;
        this.isScrollStart = false;
        this.setState({ scrollViewHeight: 0, showShadow: false })

    }

    nextElo = () => {

        this.resetScrollViewValues();

        if (this.state.eloIndex < this.props.elo.length - 1) {

            this.props.delegate && this.props.delegate.next && this.props.delegate.next(this.state.eloIndex + 1);

            if (this.props.onPressNext) {

                this.props.onPressNext(this.state.eloIndex, (isUpdated) => {

                    if (isUpdated) {
                        this.getGlossaryContent()
                    }

                    this.setState({
                        eloIndex: this.state.eloIndex + 1,
                        fadeIn: true,
                        //scrollViewHeight: 0
                    }, () => {

                        if (this.state.eloIndex > this.navigateTill) {
                            this.navigateTill = this.props.allPagesActive ? this.state.elo.length : this.state.eloIndex
                        }
                        this.prepareLearningContentVisibility();
                    })
                })

            } else {
                this.setState({
                    eloIndex: this.state.eloIndex + 1,
                    fadeIn: true,
                    //scrollViewHeight: 0
                }, () => {

                    if (this.state.eloIndex > this.navigateTill) {
                        this.navigateTill = this.props.allPagesActive ? this.state.elo.length : this.state.eloIndex
                    }
                    this.prepareLearningContentVisibility();

                })
            }

        }
        else {
            this.goBack();
        }
        this.headerComponent.scrollTo();
        // this.scroll.scrollTo({ x: 0, y: 0, animated: false });


        this.hidePagination();
    }

    goBack = (fromBack) => {

        if (fromBack)
            this.close()
        else
            this.props.delegate && this.props.delegate.learningElosCompleted && this.props.delegate.learningElosCompleted(this.state.eloIndex);
    }

    close = () => {
        this.props.delegate && this.props.delegate.close && this.props.delegate.close(this.state.eloIndex)
    }

    previousElo = () => {

        this.resetScrollViewValues();

        if (this.state.eloIndex >= 1) {
            this.setState({
                eloIndex: this.state.eloIndex - 1,
                fadeIn: true
            }, () => {
                this.logEloNavigation();
                this.prepareLearningContentVisibility();
            })
            this.scroll.scrollTo({ x: 0, y: 0, animated: false });
        }
        this.hidePagination();
    }

    hidePagination = () => {
        setTimeout(() => { this.setState({ fadeIn: false }) }, 1000)
    }

    showPagination = () => {
        this.setState({
            fadeIn: true
        })
    }

    renderPagination = (position) => {

        let previousKnowledge = this.props.elo.filter(item => item.isPreviousKnowledge)

        if (previousKnowledge.length > 1 && this.state.eloIndex < previousKnowledge.length - 1) {
            return (
                previousKnowledge.map((_, i) => {


                    return (
                        <div>

                            <div style={this.state.eloIndex == i ? "Styles.dot" : "Styles.inActiveDot"}>
                                <div style={this.state.eloIndex == i ? "Styles.activeIndex" : "Styles.inActiveIndex"}>{i + 1}</div>
                            </div>
                            {i < previousKnowledge.length - 1 ?

                                <div style={"Styles.dotConnector"}></div>
                                :
                                <></>

                            }

                        </div>

                    );

                })

            )
        }

    }


    toggleShadow = () => {
        if (!this.isScrollStart)
            this.setState({ showShadow: !this.state.showShadow });
        else
            this.setState({ showShadow: !this.state.showShadow })
    }

    scrollUp = () => {
        // if (this.pageYPosition > screenHeight - wp(50))
        //     this.scroll.scrollTo({ x: 0, y: this.ycontentOffsetPosition + wp(50) });
    }



    // async getBookmarkHelpShowed() {
    //     let doc = await AsyncStore.getBookmarkHelp();

    //     if (doc) {
    //         doc = JSON.parse(doc)

    //         if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`]) {

    //             doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`] = true

    //             AsyncStore.setBookmarkHelp(doc)

    //             this.setState({ showBookMarkHelp: true })
    //         }
    //     } else {
    //         AsyncStore.setBookmarkHelp({ [`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`]: true })
    //         this.setState({ showBookMarkHelp: true })
    //     }
    // }

    hideOverlayHelp = () => {
        this.setState({ showBookMarkHelp: false })
    }

    navigateGroup = (index, navigate) => {

        if (this.props.onPressPage) {
            this.props.onPressPage(index, (callGlossary) => {

                if (callGlossary) {
                    this.getGlossaryContent()
                }

                this.resetScrollViewValues();

                this.setState({
                    eloIndex: index,
                    fadeIn: true
                }, () => {
                    this.logEloNavigation();
                    this.prepareLearningContentVisibility();
                })

                this.navigateTill = this.props.allPagesActive ? this.state.elo.length : navigate

                //this.headerComponent.scrollTo();

                this.scroll.scrollTo({ x: 0, y: 0, animated: false });

                this.hidePagination();
            })
        } else {
            this.resetScrollViewValues();

            this.setState({
                eloIndex: index,
                fadeIn: true
            }, () => {
                this.logEloNavigation();
                this.prepareLearningContentVisibility();
            })

            this.navigateTill = this.props.allPagesActive ? this.state.elo.length : navigate

            //this.headerComponent.scrollTo();

            this.scroll.scrollTo({ x: 0, y: 0, animated: false });

            this.hidePagination();
        }
    }

    prepareLearningContentVisibility() {

        let numOfElos = this.props.elo.length;

        for (let i = 0; i < numOfElos; i++) {

            let numOfObjectsInElo = this.props.elo[i].contents.length;

            let blurFlag = false;
            let showEye = false;
            for (let j = 0; j < numOfObjectsInElo; j++) {

                this.props.elo[i].contents[j].contents.map((content, index) => {

                    if (content.type == 3 && blurFlag == false && !this.nextLearningContentMap[content._id] && !this.props.reviseChapterIntroduction && !this.props.noBlur) {

                        content.showBlur = blurFlag;
                        content.showEye = showEye;
                        blurFlag = true;
                        showEye = true;
                    }
                    else {
                        content.showBlur = blurFlag;
                        content.showEye = showEye;
                        showEye = false;
                    }
                });
            }

        }

        let contents = this.props.elo[this.state.eloIndex].contents;
        let eloId = this.props.elo[this.state.eloIndex].eloId + this.state.eloIndex;
        let blurNavigationFeedBack = false;
        let checkPointQuestions = [];
        let noOfQuestionsAnswered = this.allElosLearningHash[eloId] ? Object.keys(this.allElosLearningHash[eloId]).length : 0;
        contents.map(group => {

            group.contents.map(data => {

                if (data.type == 3) {
                    checkPointQuestions.push(data)
                }
            })

        });
        if (checkPointQuestions.length > 0 && checkPointQuestions.length != noOfQuestionsAnswered && !this.props.reviseChapterIntroduction && !this.props.noBlur) {

            blurNavigationFeedBack = true;

        }

        // console.log('blur contents', this.props.elo);

        this.setState({
            eloIndex: this.state.eloIndex,
            fadeIn: true,
            blurNavigationFeedBack: blurNavigationFeedBack
        });

    }

    refresh() {
        this.scroll.scrollTo({ x: 0, y: this.ycontentOffsetPosition - 60, animated: true })
    }

    openNextLearningContent(content) {

        let eloId = this.props.elo[this.state.eloIndex].eloId + this.state.eloIndex;

        this.nextLearningContentMap[content._id] = content;

        if (this.allElosLearningHash[eloId]) {
            this.allElosLearningHash[eloId][content._id] = content;
        }
        else {
            this.allElosLearningHash[eloId] = { [content._id]: content }
        }

        this.prepareLearningContentVisibility();
    }

    onClickBookMarkActive = (id, index) => {

        let payload = { _id: id }

        PracticeQuestionsApi.deleteBookMark(payload).then((res) => {
            let elo = this.state.elo;

            delete elo[this.state.eloIndex]['bookMarkedItem'][index];

            this.setState({ elo })

        }).catch(err => {
            if (err) {
                return;
            }
        })

    }


    renderLearningContent = () => {

        let element = [];
        if (this.state.glossaryContent && this.props.elo[this.state.eloIndex].contents) {

            let contentLength = this.props.elo[this.state.eloIndex].contents.length;

            for (let i = 0; i < contentLength; i++) {

                if (i != 0) {
                    element.push(
                        <img className='d-flex justify-content-center' style={{ maxWidth: "100%", objectFit: "contain", marginBottom: "30px" }} src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryId)} resizeMode="cover" />
                    );
                }
                let contentsPerElo = this.props.elo[this.state.eloIndex].contents[i].contents;

                // let contentObj = contentsPerElo.map(item => ({ id: item._id, objectType: item.type, contentType: item.content.type }))
                let groupTitle = this.state.elo[this.state.eloIndex].contents[i].title;

                let contentObjArray = contentsPerElo.map(item => ({ id: item._id, objectType: item.type, contentType: item.content.type }))

                let contentObj = { title: groupTitle, contentIds: contentObjArray }


                let bookMarkId = this.state.elo[this.state.eloIndex]['bookMarkedItem'] && this.props.elo[this.state.eloIndex]['bookMarkedItem'] && this.props.elo[this.state.eloIndex]['bookMarkedItem'][i] && this.props.elo[this.state.eloIndex]['bookMarkedItem'][i].bookMarkId ? this.props.elo[this.state.eloIndex]['bookMarkedItem'][i].bookMarkId : null


                contentsPerElo.map((data, index) =>
                    element.push(
                        <div key={data._id}>
                            <LearningContent
                                content={data}
                                delegate={this}
                                glossary={this.state.glossaryContent}
                                groupTitle={groupTitle}
                                showTitle={index == 0 ? true : false}
                                categoryId={this.props.elo[this.state.eloIndex].categoryThemeId}
                                contentObj={contentObj} showInactiveBookmark={bookMarkId ? false : true}
                                showBookMark={bookMarkId ? true : false} bookMarkId={bookMarkId} selectedIndex={i} />
                            {index < contentsPerElo.length - 1 &&
                                <img className='d-flex justify-content-center' style={{ maxWidth: "100%", objectFit: "contain", marginBottom: "30px" }} src={getImageSource(ImageConstants.CONTENT_DIVIDER_GRAY)} />
                            }
                        </div>
                    )
                )
            }

            return element;
        }
    }

    togglePopup = (flag) => {
        if (this.props.elo[this.state.eloIndex].previousKnowledge && this.props.elo[this.state.eloIndex].previousKnowledge.length)
            this.setState({ showLearningPopup: flag });
    }

    onSwipeRight() {

        this.previousElo();
    }

    onSwipeLeft() {
        if (!this.state.blurNavigationFeedBack && this.state.eloIndex != this.props.elo.length - 1)
            this.nextElo();
    }

    setScrollRef = (ref) => {
        this.scroll = ref;
    }

    onClickBookMark = (contentObj, index) => {

        //console.log('>>>>???', contentObj, index)

        let payload = {
            chapterKey: this.props.navigationInfo.chapterKey,
            contents: contentObj
        }

        console.log('::::', contentObj)

        if (this.props.elo[this.state.eloIndex].isChapterIntroduction || this.props.isChapterIntro) {
            payload['isChapterIntro'] = true
        } else {
            payload['eloId'] = this.props.elo[this.state.eloIndex]['eloId'];
        }

        PracticeQuestionsApi.createBookMark(payload).then((res) => {


            let elo = this.state.elo;

            if (elo[this.state.eloIndex]['bookMarkedItem'])
                elo[this.state.eloIndex]['bookMarkedItem'][index] = { bookMarkId: res.bookMarkId }
            else
                elo[this.state.eloIndex]['bookMarkedItem'] = { [index]: { bookMarkId: res.bookMarkId } }


            this.setState({ elo })
        }).catch(err => {
            console.log(err)
            if (err) {
                return;
            }
        })

    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
    }

    onPressAskDoubt = () => {

        this.setState({ loading: true })

        let payload = {};

        // Api.getDoubtsLimti(payload, (err, res) => {
        //     if (err) {
        //         this.setState({ loading: false })
        //         return;
        //     }

        //     if (!res || res.doubtsLimitType == 'LIMITED' && res.maxDoubt == 0) {
        //         this.setState({
        //             loading: false,
        //             showDoubtLimitPopUp: true
        //         })
        //         return
        //     }

        //     this.doubtLimitPayload = res
        //     this.setState({ loading: false, showAskDoubt: true })
        // })
    }

    closeAskDoubt = () => {
        this.setState({ showAskDoubt: false })
    }

    onSuccessDoubt = () => {
        this.setState({ showAskDoubt: false }, () => {
            this.showAskDoubtSuccessPopUp()
        })
    }

    showAskDoubtSuccessPopUp = () => {
        this.setState({ showSuccessPopup: true })
    }

    onPressLearnMore = () => {
        this.props.delegate && this.props.delegate.onPressLearnMore && this.props.delegate.onPressLearnMore({
            ...this.state.elo[this.state.eloIndex]
        })
    }

    onPressPracticeParam = () => {
        this.props.delegate && this.props.delegate.onPressLearnMore && this.props.delegate.onPressPracticeParam({
            ...this.state.elo[this.state.eloIndex]
        }, this.state.eloIndex)
    }

    onPressSolvedExamples = () => {
        this.props.delegate && this.props.delegate.onPressSolvedExample && this.props.delegate.onPressSolvedExample({
            ...this.state.elo[this.state.eloIndex]
        }, this.state.eloIndex)
    }

    onPressPractice = () => {
    }

    hideSuccessPopUp = () => {
        this.setState({ showSuccessPopup: false })
    }

    toggleLateralMenu = () => {
        this.props.delegate && this.props.delegate.toggleLateralMenu && this.props.delegate.toggleLateralMenu(this.state.eloIndex)
    }

    renderLearnMoreContainer = () => {

        return (
            <div className='learn-more-container'>
                <div className='cursor-pointer' onClick={this.onPressLearnMore}>
                    <div >
                        <img src={getImageSource(ImageConstants.BULB_IMAGE)}></img>
                    </div>
                    <div >
                        <div className='learn-more-text'>Learn More ?</div>
                    </div>
                    <div >
                        <img src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                    </div>
                </div>

                <div className='cursor-pointer' onClick={this.onPressSolvedExamples}>
                    <div>
                        <img src={getImageSource(ImageConstants.SOLVED_EXAMPLES_ICON)}></img>
                    </div>
                    <div >
                        <div className='learn-more-text'>Solved Examples</div>
                    </div>
                    <div >
                        <img src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                    </div>
                </div>

                <div className='cursor-pointer' onClick={this.onPressPractice}>
                    <div>
                        <img src={getImageSource(ImageConstants.SOLVED_EXAMPLES_ICON)}></img>
                    </div>
                    <div >
                        <div className='learn-more-text'>Practice ?</div>
                    </div>
                    <div >
                        <img src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                    </div>
                </div>
            </div>
        )
    }

    renderLearnMoreContainerV2 = () => {

        let currentElo = this.state.elo[this.state.eloIndex]

        let solvedExamplePercentage = currentElo.meta && currentElo.meta.progress && currentElo.meta.progress.solvedExamples && currentElo.meta.progress.solvedExamples.percentage ? currentElo.meta.progress.solvedExamples.percentage : 0
        let practicePracentage = currentElo.meta && currentElo.meta.progress && currentElo.meta.progress.solvedExamples && currentElo.meta.progress.practiceQuestions.percentage ? currentElo.meta.progress.practiceQuestions.percentage : 0
        return (
            <div className='bottom-card-container mt-3'>

                <div className='cursor-pointer d-flex align-items-center card-border-bottom' onClick={this.onPressLearnMore}>
                    <div>
                        <img className='image-size' src={getImageSource(ImageConstants.BULB_IMAGE)}></img>
                    </div>
                    <div className='ml-2'>
                        <div className='learn-more-text'>Learn More ?</div>
                    </div>
                    <div className='ml-2'>
                        <img className='arrow-size' src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                    </div>
                </div>

                {currentElo && currentElo.meta && currentElo.meta.solvedExamplesCount ?
                    <div className='cursor-pointer d-flex align-items-center card-border-bottom' onClick={this.onPressSolvedExamples}>
                        <div>
                            <div style={{ transform: [{ scaleX: -1 }] }}>
                                <div className='' style={{ width: '30px', height: '30px' }}>
                                    <CircularProgressbar value={solvedExamplePercentage && solvedExamplePercentage > 0 ? solvedExamplePercentage : 0}
                                        strokeWidth={15}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='ml-2 d-flex align-items-center'>
                            <div className=''>
                                <img className='image-size' src={getImageSource(ImageConstants.SOLVED_EXAMPLES_ICON)}></img>
                            </div>
                            <div className='ml-2'>
                                <div className='learn-more-text'>Solved Examples</div>
                            </div>
                        </div>
                        <div className='ml-2'>
                            <img className='arrow-size' src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                        </div>
                    </div>
                    :
                    <></>
                }

                <div className='cursor-pointer cursor-pointer d-flex align-items-center card-border-bottom' onClick={this.onPressPracticeParam}>
                    <div>
                        <div style={{ transform: [{ scaleX: -1 }] }}>
                            <div className='' style={{ width: '30px', height: '30px' }}>
                                <CircularProgressbar value={practicePracentage && practicePracentage > 0 ? practicePracentage : 0}
                                    strokeWidth={15}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='ml-2 d-flex align-items-center'>
                        <div>
                            <img className='image-size' src={getImageSource(ImageConstants.SOLVED_EXAMPLES_ICON)}></img>
                        </div>
                        <div className='ml-2'>
                            <div className='learn-more-text'>Practice ?</div>
                        </div>
                    </div>
                    <div className='ml-2'>
                        <img className='arrow-size' src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                    </div>
                </div>

                <div className='notes d-flex align-items-center mt-5 mb-5'>
                    <div>
                        <img className='info-icon' src={getImageSource(ImageConstants.INFO)}></img>
                    </div>
                    <div className='ml-2'>
                        <div className='notes-text'>
                            {this.staticText.note}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    hideDoubtLimitPopUp = () => {
        this.setState({ showDoubtLimitPopUp: false })
    }

    closeLearnMore() {
        this.props.delegate && this.props.delegate.close && this.props.delegate.close()
    }

    render() {

        // SetCategoryThemeStyles(this.props.elo[this.state.eloIndex].categoryThemeId);

        let allPagePercentage = this.props.elo.map(item => item && item.meta && item.meta.percentage ? item.meta.percentage : 0)
        console.log(">>>:::ALL", allPagePercentage)

        //console.log(this.state.scrollViewHeight + " - " + navigationBarHeight + " - " + screenHeight + "-" + (this.state.scrollViewHeight + 45 + navigationBarHeight))
        return (
            <>
                {
                    !this.state.loading ?
                        <>
                            {
                                !this.state.showLearningPopup &&
                                <div className='exam-prep-kit-learning-content-wrapper'>
                                    <div
                                        showsVerticalScrollIndicator={false}
                                        style={{}}
                                        stickyHeaderIndices={[0]}
                                        scrollsToTop={true}
                                        scrollEventThrottle={16}
                                        onScroll={({ nativeEvent }) => {
                                            this.ycontentOffsetPosition = nativeEvent.contentOffset.y;
                                            const offset = nativeEvent.contentOffset.y;
                                            //console.log("---------", this.contentScrollHeight, this.headerHeight, screenHeight)
                                            let headerHeight = !this.headerHeight ? this.lastHeaderHeight ? this.lastHeaderHeight : 0 : this.headerHeight;

                                            //Setting ScrollStarted
                                            if (!this.isScrollStart) {
                                                //console.log("isScrollStart->>>>>>>>>>>")
                                                this.isScrollStart = true;
                                            }

                                            // if (!(this.contentScrollHeight - headerHeight <= screenHeight)) {
                                            //     if (offset > 2 && !this.state.showShadow) {
                                            //         this.toggleShadow();
                                            //     } else if (offset < 2 && this.state.showShadow) {
                                            //         this.toggleShadow();
                                            //     }
                                            // }
                                        }}
                                        onContentSizeChange={(w, h) => {
                                            if (!this.isScrollStart) {
                                                this.contentScrollHeight = h;
                                            }
                                            if (h > this.state.scrollViewHeight) {
                                                this.setHeight(h);
                                            }
                                        }}
                                        onTouchStart={(event) => {
                                            this.pageYPosition = event.nativeEvent.pageY;
                                        }}
                                        ref={(ref) => { this.scroll = ref }}
                                        bounces={false}>

                                        <div onLayout={e => {
                                            if (!this.isScrollStart) {
                                                this.headerHeight = e.nativeEvent.layout.height;
                                                //console.log("headerHeight----------------->" + e.nativeEvent.layout.height);
                                            }
                                        }}>
                                            <WorkoutLearningContentHeader
                                                isScrolled={this.state.showShadow}
                                                title={!this.props.isChapterIntro ? this.props.headerTitle ? this.props.headerTitle : this.props.elo[this.state.eloIndex].eloTitle : ""}
                                                subTitle={this.props.title}
                                                delegate={this}
                                                isChapterIntro={this.props.isChapterIntro}
                                                isChapterIntroRevise={this.props.isChapterIntroRevise}
                                                categoryId={this.props.elo[this.state.eloIndex].categoryThemeId}
                                                isPKExist={this.props.elo[this.state.eloIndex].previousKnowledge && this.props.elo[this.state.eloIndex].previousKnowledge.length}
                                                isPreviousKnowledge={this.props.elo[this.state.eloIndex].isPreviousKnowledge}
                                                isChapterIntroduction={this.props.elo[this.state.eloIndex].isChapterIntroduction}
                                                estimatedLearningTime={this.props.estimatedLearningTime && this.props.estimatedLearningTime}
                                                headerHeight={this.headerHeight}
                                                totalPage={this.pageLength ? this.pageLength : 0}
                                                activeIndex={this.state.eloIndex ? this.state.eloIndex : 0}
                                                navigateTill={this.navigateTill ? this.navigateTill : this.state.eloIndex}
                                                ref={(ref) => { this.headerComponent = ref }}
                                                showPagination={this.props.elo.length === 1 || this.props.hidePagination ? false : true}
                                                from={this.props.from}
                                                navigation={this.props.navigation}
                                                isMplWorkout={this.props.isMplWorkout}
                                                headerIcon={this.props.headerIcon}
                                                showPaginationProgress={this.props.showPaginationProgress}
                                                allPagePercentage={allPagePercentage}
                                                percentageCompleted={this.props.elo[this.state.eloIndex] && this.props.elo[this.state.eloIndex].meta &&
                                                    this.props.elo[this.state.eloIndex].meta.percentage}
                                                hideLateralMenu={this.props.hideLateralMenu}
                                            />
                                        </div>

                                        <div style={{}}>
                                            {this.renderLearningContent()}
                                        </div>

                                        {/* {this.props.showLearnMoreCard ? this.renderLearnMoreContainer() : <></>} */}
                                        {this.props.showLearnMoreCard ? this.renderLearnMoreContainerV2() : <></>}

                                        <div  >
                                            <div className='dot-container'>{this.renderPagination()}</div>
                                        </div>

                                        {/* {!this.props.hideNext && <LearningContentFooter blur={this.state.blurNavigationFeedBack} style={{}} delegate={this} isChapterIntro={this.props.isChapterIntro} categoryId={this.props.elo[this.state.eloIndex].categoryThemeId} />} */}

                                    </div>

                                    {!this.props.hideNext && <LearningContentFooter blur={this.state.blurNavigationFeedBack} delegate={this} isChapterIntro={this.props.isChapterIntro} categoryId={this.props.elo[this.state.eloIndex].categoryThemeId} />}

                                </div>
                            }
                        </>
                        :
                        <Loading />
                }

                {/* {this.state.showLearningPopup && <LearningContentPopup elos={this.props.elo[this.state.eloIndex].previousKnowledge} title={this.props.title} delegate={this} chapterKey={this.props.navigationInfo.chapterKey} />} */}

                {/* {this.state.showBookMarkHelp && <BookmarkHelp showModal={this.state.showBookMarkHelp} headerHeight={this.headerHeight} delegate={this} fromWorkout={true} type={'BOOKMARK'} />} */}
            </>
        );
    }
}




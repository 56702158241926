import React from "react";
import './str-questionset-list.scss';
import { ImageConstants } from "../../../constants/constants";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import PracticeApi from "../../../apis/practice";
import Loading from "../../common/loading/loading";
import ChapterAssessmentPerformanceReport from "../../chapter-assessment-performance-report/chapter-assessment-performance-report";

class STRQuestionSetList extends React.Component {

    state = {
        // setList: [
        //     {
        //         setName: 'Set 1',
        //         questionsCount: 15,
        //         approximateTime: 15
        //     },
        //     {
        //         setName: 'Set 2',
        //         questionsCount: 25,
        //         approximateTime: 20
        //     },
        //     {
        //         setName: 'Set 3',
        //         questionsCount: 25,
        //         approximateTime: 35
        //     }
        // ]
        isLoading: true,
        userPracticeActivity: [],
        totalQuestions : 0
    }

    componentDidMount() {
        console.log(this.props.selectedChapter)
        this.getUserPracticeActivity()
    }

    getUserPracticeActivity() {

        let payload = {
            type: 'STR',
            chapterKey: this.props.selectedChapter.key
        }

        PracticeApi.getUserPracticeActivities(payload).then(res => {
            console.log(res)

            if (res.practiceActivity.length) {
                let totalQuestions = 0

                res.practiceActivity.forEach(item => {
                    totalQuestions += item.approximateQuestions
                })
                this.setState({ userPracticeActivity: res.practiceActivity,totalQuestions, isLoading: false })
            } else {
                this.setState({ userPracticeActivity: [], isLoading: false, totalQuestions : 0 })
            }




        }).catch(err => {
            console.log('get user Practice Activity Error', err)
        })


    }

    goBack() {
        this.props.back && this.props.back()
    }

    goToHomeScreen() {
        this.props.goToHomeScreen && this.props.goToHomeScreen()
    }

    goToPracticeQuestionsChapterList() {
        this.props.goToPracticeQuestionsChapterList && this.props.goToPracticeQuestionsChapterList()
    }

    navigate = (item) => {
        this.props.delegate && this.props.delegate.getPracticeStatus && this.props.delegate.getPracticeStatus(item)
    }

    showDescriptionQuestions() {
        this.props.delegate && this.props.delegate.showDescriptionQuestions && this.props.delegate.showDescriptionQuestions()
    }

    goToSelectedChapter() {
        this.props.delegate && this.props.delegate.goToSelectedChapter && this.props.delegate.goToSelectedChapter()
    }

    renderBreadcrumbForPracticeQuestions() {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToSelectedChapter()} >{`${this.props.chapterTitle} >`}</span>
                <span className='title-1 cursor-pointer pl-2' onClick={() => this.goBack()} >{`Practice >`}</span>
                <span className='title-1 pl-2 active'>{`Structured Response Questions`}</span>
                {/* <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    getChapterAssessmentReport(item) {

        this.setState({ showChapterAssessmentReport: true, practiceId: item.practiceId })
    }

    hideChapterAssessmentReport() {
        this.setState({ showChapterAssessmentReport: false, practiceId: '' })
    }



    getButton(item) {
        if (item.status === 'START') {
            return (
                <div className="start-button text-center cursor-pointer" onClick={() => this.navigate(item)}>
                    Start
                </div>
            )
        } else if (item.status === 'IN_PROGRESS') {
            return (
                <div className="start-button text-center cursor-pointer" onClick={() => this.navigate(item)}>
                    Resume
                </div>
            )
        } else if (item.status === 'COMPLETED') {
            return (
                <div className="d-flex align-items-center" >
                    <div className="insights-button text-center cursor-pointer" onClick={() => this.getChapterAssessmentReport(item)}>
                        Insights
                    </div>
                    <div className="learning-gap-button text-center ml-4 cursor-pointer" onClick={() => this.goToBrowseChapter()}>
                        Bridge learning Gaps
                    </div>
                </div>
            )
        }
    }

    goToBrowseChapter() {
        this.props.delegate && this.props.delegate.goToBrowseChapterSignleChapter && this.props.delegate.goToBrowseChapterSignleChapter({...this.props.selectedChapter},0, null)
    }

    


    render() {

        if(this.state.showChapterAssessmentReport) {
            return <ChapterAssessmentPerformanceReport delegate = {this} 
            practiceId = {this.state.practiceId} chapterTitle = {this.props.chapterTitle} questionType = {'Stuctured Response Questions'} />
        }


        return (
            <div className="str-questionset-list">
                <div className="text-right">
                    <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                </div>
                <div className="header-title-margin-top">
                    {this.renderBreadcrumbForPracticeQuestions()}
                    <div className="title">Structured Response Questions </div>
                    <div className="mb-3">
                        <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                    </div>
                    <div className="descriptive-text mb-3 text-center">Non Descriptive Questions <span className="total-questions-text">{this.state.totalQuestions ? `(${this.state.totalQuestions} Questions)` : ''}</span></div>

                    {
                        this.state.isLoading ? <Loading />
                            :
                            <>
                                {
                                    this.state.userPracticeActivity && this.state.userPracticeActivity.length ?
                                        this.state.userPracticeActivity.map((activity, index) => (
                                            <div className={`question-set row mr-0 ml-0 mb-4 pb-4 align-items-center ${index != this.state.userPracticeActivity.length - 1 ? 'question-border-bottom' : ''}`}>
                                                <div className="col pl-0">
                                                    <div className="d-flex align-items-center">
                                                        <span className="set-text">Set {index + 1}{index === this.state.userPracticeActivity.length - 1 ? <span className="competency-questions-text">{` (CBQ's only)`}</span> :'' }</span>
                                                        <img className="clock-icon-size ml-3" src={getImageSource(ImageConstants.GRAY_QUESTION_MARK_ICON)} />
                                                        <span className="approximate-text ml-1">{activity.approximateQuestions} Questions</span>
                                                        <img className="clock-icon-size ml-3" src={getImageSource(ImageConstants.GRAY_CLOCK_ICON)} />
                                                        <span className="approximate-text ml-1">Approx {activity.approximateTime} mins</span>
                                                    </div>
                                                </div>
                                                <div className="col-3 pr-0 d-flex justify-content-end">
                                                    {this.getButton(activity)}
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className="text-center">No Qestion Set Found for the Chapter : <strong>{this.props.selectedChapter.title}</strong></div>
                                }
                            </>
                    }

                    <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, 1)} />

                    <div className={`question-set row mr-0 ml-0 mb-4 pb-4 mt-5 align-items-center}`}>
                        <div className="col pl-0">
                            <div className="d-flex align-items-center">
                                <span className="descriptive-text">Descriptive Questions</span>
                            </div>
                        </div>
                        <div className="col-3 pr-0 d-flex justify-content-end">
                            <div className="start-button text-center cursor-pointer" onClick={() => this.showDescriptionQuestions()}>
                                Start
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }





}

export default STRQuestionSetList;
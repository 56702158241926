import React from 'react';
import './learning-content-popup-wrapper.scss';
import { ImageConstants, Constants } from '../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import LearningContent from '../learning-content/learning-content';
import LearningContentPopupHeader from '../learning-content-popup-header/learning-content-popup-header';
import LearningContentFooter from '../learning-footer/learning-footer';
import GlossaryParser from '../../common/content-parser/glossary-parser';
import SharedProfile from '../../../../shared-storage/profile';
import PracticeQuestionsApi from '../../../apis/practice-questions';
import FireBaseEvent from '../../../firebase-events/firebase-events';

export default class LearningContentPopupWrapper extends React.Component {
    constructor(props) {
        super(props);

        // const { navigation } = this.props;

        this.state.elo = this.props.elo;

        // this.ycontentOffsetPosition = screenHeight / 4;
        this.glossaryParser = new GlossaryParser();
        // this.handleBackButton = this.handleBackButton.bind(this);
        console.log("chapter into called");
        this.logEloNavigation();

        this.userInfo = SharedProfile.getProfile()
    }

    nextLearningContentMap = {};
    allElosLearningHash = {};
    videoPlayerRefs = {};
    state = {
        eloIndex: 0,
        fadeIn: false,
        scrollViewHeight: 0,
        showShadow: false,
        glossaryContent: null,
        blurNavigationFeedBack: false

    };
    headerHeight = 0

    pageLength = this.props.elo ? this.props.elo.length : 0;
    currentPage = this.props.elo && this.props.elo.length ? 1 : 0;


    logEloNavigation() {

        //console.log("logEloNavigation ***********", this.props.elo[this.state.eloIndex]);

        let elo = this.props.elo[this.state.eloIndex];
        let navigationInfo = {};

        if (this.props.elo[this.state.eloIndex]) {
            navigationInfo.chapterTitle = this.props.title;
            navigationInfo.eloId = elo.eloId;
            navigationInfo.title = elo.eloTitle;
        }

        let payload = {
            type: "ELO",
            info: navigationInfo,
        }

        // Api.logUserNavigation(payload, (err, res) => {
        //     if (err) {
        //         return;
        //     }
        //     console.log("navigation logged");
        // });
    }

    componentDidMount() {
        this.getGlossaryContent();
        // KeepAwake.activate();
        // BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
        this.prepareLearningContentVisibility();
        // this.getBookmarkHelpShowed()
    }

    componentWillUnmount() {
        // KeepAwake.deactivate();
        // BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
    }

    // async getBookmarkHelpShowed() {
    //     let doc = await AsyncStore.getBookmarkHelp();

    //     if (doc) {
    //         doc = JSON.parse(doc)

    //         if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`]) {

    //             doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`] = true

    //             AsyncStore.setBookmarkHelp(doc)

    //             this.setState({ showBookMarkHelp: true })
    //         }
    //     } else {
    //         AsyncStore.setBookmarkHelp({ [`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`]: true })
    //         this.setState({ showBookMarkHelp: true })
    //     }
    // }

    hideOverlayHelp = () => {
        this.setState({ showBookMarkHelp: false })
    }

    getGlossaryContent = () => {

        if (this.props && this.props.elo && this.props.elo.length > 0) {

            let elos = this.props.elo

            let contents = [];

            for (let i = 0; i < elos.length; i++) {

                let contentsList = elos[i]['contents'];

                for (let j = 0; j < contentsList.length; j++) {

                    contents = contents.concat(contentsList[j].contents)
                }
            }

            if (contents.length > 0) {

                this.glossaryParser.getParsedContentIdsFromContent(contents, (err, glossaryContent) => {
                    this.setState({
                        glossaryContent
                    })
                })

            } else {
                this.setState({
                    glossaryContent: {}
                })
            }

        } else {
            this.setState({
                glossaryContent: {}
            })
        }
    };

    nextElo = () => {

        if (this.state.eloIndex < this.props.elo.length - 1) {
            this.setState({
                eloIndex: this.state.eloIndex + 1,
                fadeIn: true,
                eloLoading: false
            }, () => {
                this.logEloNavigation();
            })
        } else {
            this.setState({ eloLoading: false })
        }

        // this.scroll.scrollTo({ x: 0, y: 0, animated: false });
        this.hidePagination();
    }

    previousElo = () => {
        if (this.state.eloIndex >= 1) {
            this.setState({
                eloIndex: this.state.eloIndex - 1,
                fadeIn: true,
                eloLoading: false
            }, () => {
                this.logEloNavigation();
            })
            this.scroll.scrollTo({ x: 0, y: 0, animated: false });
        } else {
            this.setState({ eloLoading: false }, () => {
                this.props.onBack && this.props.onBack()
            });
        }
        // this.scroll.scrollTo({x: 0, y: 0, animated: false});
        this.hidePagination();
    }

    hidePagination = () => {
        setTimeout(() => { this.setState({ fadeIn: false }) }, 1000)
    }

    showPagination = () => {
        this.setState({
            fadeIn: true
        })
    }

    renderPagination = (position) => {

        console.log(this.props.elo.length)

        if (this.props.elo.length > 1) {
            return (
                this.props.elo.map((_, i) => {

                    return (
                        <div className='d-flex align-items-center justify-content-center'>

                            <div className={this.state.eloIndex == i ? "dot" : "inactive-dot"}>
                                <span className={this.state.eloIndex == i ? "active-index" : "inactive-index"}>{i + 1}</span>
                            </div>
                            {i < this.props.elo.length - 1 ?

                                <div className='dot-connector'></div>
                                :
                                <></>
                            }

                        </div>

                    );

                })

            )
        }

    }

    handleBackButton() {
        this.closePopup()
    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
    }

    shadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    hideChapterIntroduction = () => {
        this.props.delegate && this.props.delegate.hideChapterIntroduction && this.props.delegate.hideChapterIntroduction();
    }

    scrollUp = () => {
        // if (this.pageYPosition > screenHeight - wp(50))
        //     this.scroll.scrollTo({ x: 0, y: this.ycontentOffsetPosition + wp(50) });

    }

    prepareLearningContentVisibility() {

        let numOfElos = this.props.elo.length;

        for (let i = 0; i < numOfElos; i++) {

            let numOfObjectsInElo = this.props.elo[i].contents.length;

            let blurFlag = false;
            let showEye = false;
            for (let j = 0; j < numOfObjectsInElo; j++) {

                this.props.elo[i].contents[j].contents.map((content, index) => {

                    if (content.type == 3 && blurFlag == false && !this.nextLearningContentMap[content._id]) {

                        content.showBlur = blurFlag;
                        content.showEye = showEye;
                        blurFlag = true;
                        showEye = true;
                    }
                    else {
                        content.showBlur = blurFlag;
                        content.showEye = showEye;
                        showEye = false;
                    }
                });
            }
        }

        let contents = this.props.elo[this.state.eloIndex].contents;
        let eloId = this.props.elo[this.state.eloIndex].eloId;
        let blurNavigationFeedBack = false;
        let checkPointQuestions = [];
        let noOfQuestionsAnswered = this.allElosLearningHash[eloId] ? Object.keys(this.allElosLearningHash[eloId]).length : 0;
        contents.map(group => {

            group.contents.map(data => {

                if (data.type == 3) {
                    checkPointQuestions.push(data)
                }
            })

        });
        if (checkPointQuestions.length > 0 && checkPointQuestions.length != noOfQuestionsAnswered) {

            blurNavigationFeedBack = true;

        }

        console.log('blur contents', blurNavigationFeedBack);

        this.setState({
            eloIndex: this.state.eloIndex,
            fadeIn: true,
            blurNavigationFeedBack: blurNavigationFeedBack
        });

    }

    openNextLearningContent(content) {

        let eloId = this.props.elo[this.state.eloIndex].eloId;

        this.nextLearningContentMap[content._id] = content;

        if (this.allElosLearningHash[eloId]) {
            this.allElosLearningHash[eloId][content._id] = content;
        }
        else {
            this.allElosLearningHash[eloId] = { [content._id]: content }
        }

        this.prepareLearningContentVisibility();
    }

    renderLearningContent = () => {

        let element = [];
        if (this.state.glossaryContent && this.props.elo[this.state.eloIndex].contents) {

            let contentLength = this.props.elo[this.state.eloIndex].contents.length;

            for (let i = 0; i < contentLength; i++) {

                if (i != 0) {
                    element.push(
                        <img className='d-flex justify-content-center' style={{ maxWidth: "100%", objectFit: "contain", marginBottom: "30px" }} src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryId)} resizeMode="cover" />
                    );
                }
                let contentsPerElo = this.props.elo[this.state.eloIndex].contents[i].contents;

                // let contentObj = contentsPerElo.map(item => ({ id: item._id, objectType: item.type, contentType: item.content.type }))
                let groupTitle = this.state.elo[this.state.eloIndex].contents[i].title;

                let contentObjArray = contentsPerElo.map(item => ({ id: item._id, objectType: item.type, contentType: item.content.type }))

                let contentObj = { title: groupTitle, contentIds: contentObjArray }


                let bookMarkId = this.state.elo[this.state.eloIndex]['bookMarkedItem'] && this.props.elo[this.state.eloIndex]['bookMarkedItem'] && this.props.elo[this.state.eloIndex]['bookMarkedItem'][i] && this.props.elo[this.state.eloIndex]['bookMarkedItem'][i].bookMarkId ? this.props.elo[this.state.eloIndex]['bookMarkedItem'][i].bookMarkId : null


                contentsPerElo.map((data, index) =>
                    element.push(
                        <div key={data._id}>
                            <LearningContent
                                content={data}
                                delegate={this}
                                glossary={this.state.glossaryContent}
                                groupTitle={groupTitle}
                                showTitle={index == 0 ? true : false}
                                categoryId={this.props.elo[this.state.eloIndex].categoryThemeId}
                                contentObj={contentObj} showInactiveBookmark={bookMarkId ? false : true}
                                showBookMark={bookMarkId ? true : false} bookMarkId={bookMarkId} selectedIndex={i} />
                            {index < contentsPerElo.length - 1 &&
                                <img className='d-flex justify-content-center' style={{ maxWidth: "100%", objectFit: "contain", marginBottom: "30px" }} src={getImageSource(ImageConstants.CONTENT_DIVIDER_GRAY)} />
                            }
                        </div>
                    )
                )
            }

            return element;
        }
    }



    showPK = () => {
        this.props.delegate.showPK && this.props.delegate.showPK(this.props.elo[this.state.eloIndex].previousKnowledge);
    }


    closePopup = () => {
        this.props.delegate.closePopup && this.props.delegate.closePopup(false)
    }

    onSwipeRight = () => {
        this.setState({ eloLoading: true }, () => {
            this.previousElo();
        })

    }

    onSwipeLeft = () => {
        if (!this.state.blurNavigationFeedBack) {
            this.setState({ eloLoading: true }, () => {
                this.nextElo();
            })
        }
    }

    setScrollRef = (ref) => {
        this.scroll = ref;
    }

    onClickBookMarkActive = (id, index) => {

        let payload = { _id: id }

        PracticeQuestionsApi.deleteBookMark(payload).then((res) => {
            let elo = this.state.elo;

            delete elo[this.state.eloIndex]['bookMarkedItem'][index];

            this.setState({ elo })

        }).catch(err => {
            if (err) {
                return;
            }
        })

    }

    onClickBookMark = (contentObj, index) => {

        let payload = {
            chapterKey: this.props.navigationInfo.chapterKey,
            contents: contentObj
        }

        if (this.props.isChapterIntro) {
            payload['isChapterIntro'] = true
        } else {
            payload['eloId'] = this.props.elo[this.state.eloIndex]['eloId'];
        }

        PracticeQuestionsApi.createBookMark(payload).then((res) => {

            let elo = this.state.elo;

            if (elo[this.state.eloIndex]['bookMarkedItem'])
                elo[this.state.eloIndex]['bookMarkedItem'][index] = { bookMarkId: res.bookMarkId }
            else
                elo[this.state.eloIndex]['bookMarkedItem'] = { [index]: { bookMarkId: res.bookMarkId } }

            if (this.props.isChapterIntro) {
                // moengage.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Type: 'Chapter Introduction' })
                FireBaseEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Type: 'Chapter Introduction' })
                // Facebook.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Type: 'Chapter Introduction' })
                // BranchIO.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Type: 'Chapter Introduction' })


            } else {
                // moengage.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })
                FireBaseEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })
                // Facebook.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })
                // BranchIO.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })


            }

            this.setState({ elo })
        }).catch(err => {
            if (err) {
                return;
            }
        })

    }

    // onPressAskDoubt = () => {

    //     this.setState({ loading: true })

    //     let payload = {};

    //     Api.getDoubtsLimti(payload, (err, res) => {
    //         if (err) {
    //             this.setState({ loading: false })
    //             return;
    //         }

    //         if (!res || res.doubtsLimitType == 'LIMITED' && res.maxDoubt == 0) {
    //             this.setState({
    //                 loading: false,
    //                 showDoubtLimitPopUp: true
    //             })
    //             return
    //         }

    //         this.doubtLimitPayload = res
    //         this.setState({ loading: false, showAskDoubt: true })
    //     })
    // }

    closeAskDoubt = () => {
        this.setState({ showAskDoubt: false })
    }

    onSuccessDoubt = () => {
        this.setState({ showAskDoubt: false }, () => {
            this.showAskDoubtSuccessPopUp()
        })
    }

    showAskDoubtSuccessPopUp = () => {
        this.setState({ showSuccessPopup: true })
    }

    hideSuccessPopUp = () => {
        this.setState({ showSuccessPopup: false })
    }

    hideDoubtLimitPopUp = () => {
        this.setState({ showDoubtLimitPopUp: false })
    }



    render() {

        // setCategoryThemeStyles(this.props.elo[this.state.eloIndex].categoryThemeId);

        return (

            <div >
                <div>
                    <div>
                        <LearningContentPopupHeader
                            isScrolled={this.state.showShadow}
                            title={this.props.elo[this.state.eloIndex].eloTitle}
                            chapterTitle={this.props.title} delegate={this}
                            isPKExist={this.props.elo[this.state.eloIndex].previousKnowledge && this.props.elo[this.state.eloIndex].previousKnowledge.length}
                            categoryId={this.props.elo[this.state.eloIndex].categoryThemeId}
                            isLateralMenuPopup={this.props.isLateralMenuPopup}
                        />
                    </div>

                    <div >
                        {this.renderLearningContent()}
                    </div>
                    <div className='pagination-alignment' >
                        <div className='dot-pagination-container d-flex justify-content-center'>{this.renderPagination()}</div>
                    </div>

                    <LearningContentFooter
                        delegate={this}
                        // style={Styles.footer}
                        categoryId={this.props.elo[this.state.eloIndex].categoryThemeId}
                        isPopup={true}
                        isLastElo={this.props.elo && this.props.elo.length - 1 == this.state.eloIndex} />
                </div>


                {/* {this.state.showDoubtLimitPopUp ? <DoubtLimitPopUp close={this.hideDoubtLimitPopUp} categoryThemeId={this.props.elo[this.state.eloIndex].categoryThemeId ? this.props.elo[this.state.eloIndex].categoryThemeId : "1"}></DoubtLimitPopUp> : <></>}

                {this.state.showAskDoubt && <WorkoutAskDoubt delegate={this} doubtLimitPayload={this.doubtLimitPayload} eloDetails={this.props.elo[this.state.eloIndex]} chapterTitle={this.props.title} categoryThemeId={this.props.elo[this.state.eloIndex].categoryThemeId} chapterKey={this.props.navigationInfo.chapterKey} />}

                {this.state.showSuccessPopup && <DoubtSuccessPopUp close={this.hideSuccessPopUp} categoryThemeId={this.props.elo[this.state.eloIndex].categoryThemeId} />}

                {this.state.showBookMarkHelp && <BookmarkHelp showModal={this.state.showBookMarkHelp} headerHeight={this.headerHeight} delegate={this} type={'BOOKMARK'} />} */}

            </div >
        );
    }
}




import React from "react";
import './fill-in-the-blank.scss';
import QuestionTitle from "../../../../common/question/question-title/question-title";
import QuestionImage from "../../../../common/cw-question-image/cw-question-image";
import AnswerTab from "../../answer-tab/answer-tab";
import { getThemeImageSource, getImageSource } from "../../../../../utility/image-source";
import { ImageConstants } from "../../../../../constants/constants";
import Parser from "../../../../common/content-parser/content-parser";
import { Constants } from "../../../../../constants/constants";
import CommonUtils from "../../../../../utility/common-utilities";
import ActionSheet from "../../../../common/action-sheet/action-sheet";
import SharedThemeCategory from "../../../../../../shared-storage/category-theme";

class FillInTheBlank extends React.Component {

    constructor(props) {
        super(props);
        this.parser = new Parser();
        let content = JSON.stringify(this.props.content)
        this.state.content = JSON.parse(content);
        if (this.props.showMyAnswer) {
            this.showMyAnswer()
        }
    }


    state = {
        content: null
    }

    updateAnswer = (payload) => {

        let content = this.state.content;

        let options = content.content.options;

        if (payload.answer.length == options.length) {

            for (let i = 0; i < options.length; i++) {
                if (options[i].contentType == Constants.CONTENT_TYPE_BLANK) {
                    //alert(JSON.stringify(options[i]))
                    options[i].items[0].content = payload.answer[i].content ? "<p>" + payload.answer[i].content + "</p>" : '';
                }
                else if (options[i].contentType == Constants.CONTENT_TYPE_DROP_DOWN) {
                    let option = options[i].items.filter(opt => opt.id == payload.answer[i].selected[0].id);
                    if (option.length) {
                        option[0].isSelected = true;
                        options[i].selectedText = option[0].content;
                    }
                }
            }
        }

        this.setState({ content: content })
    }

    showMyAnswer = () => {
        if (this.props.answer) {
            this.updateAnswer(this.props.answer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.stringify(this.props.content)
        this.setState({ content: JSON.parse(content) })
    }


    getBlankOptionValueDropDown(content) {

        let styles = {
            p: { fontSize: '23px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (content && content.items && content.items.length) {
            let correctItem = content.items.filter((item) => item.isCorrect);
            let correctAnswer = correctItem[0].content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");
            if (correctItem[0].content) {
                return this.parser.parseWithStyles(`${correctAnswer}`, { p: styles.p, "cw-math": styles.p })
            } else {
                return <></>
            }
        }
        return <></>
    }

    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer })
    }

    getJsxForBlankTypeDropDown(option, index) {

        //console.log(option, index, this.state.selectedDropDownIndex, this.props.isReadOnly)

        //console.log(this.state.actionSheet)

        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let assessmentBorder = this.theme[`secondaryColors`][`assessmentOptionsBorder`]

        return (
            <div className="position-relative" style={{}}>

                {option.selectedText ?
                    <>
                        <div className="row mb-5 mt-2 mr-0 ml-0" style={{}}>

                            <div className="blank-wrapper padding-for-dropdown" style={{ border: `1px solid ${assessmentBorder}` }}> 
                                <div className="dropdown-input">
                                    <div className="d-inline-block alphabet-option ml-2 mr-2">
                                        <div className="circle p-1" style={{backgroundColor : this.theme['themeColor']}}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                    </div>
                                    <img className="option-separator-2" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />
                                    <div className="d-inline-block ml-2" style={{}}>
                                        {
                                            option.selectedText ?
                                                this.parser.parseWithStyles(`${option.selectedText.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
                                                : <span className="cw-input-for-dropdown" style={{}}>
                                                    {"Select One"}</span>
                                        }
                                    </div>

                                    <div className="float-right mt-2" style={{}}>
                                        <img className="dropdown-arrow-size" src={getImageSource(ImageConstants.DROPDOWN_ARROW_BLACK)} />
                                    </div>
                                </div>

                            </div>


                            {/* </LinearGradient> */}
                        </div>
                        {this.state.actionSheet && <ActionSheet modal={this.state.actionSheet} toggleActionSheet={this.toggleActionSheet} options={this.state.dropDownData} onSelect={this.onSelect} categoryThemeId={this.props.categoryThemeId}></ActionSheet>}

                    </>


                    :
                    // <LinearGradient colors={Colors.appTheme.gradient1} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1.2 }} style={styles.linearGradiendCard}>
                    <div className="row  mb-5 mt-2 mr-0 ml-0 cursor-pointer" style={{}}>

                        <div className="blank-wrapper padding-for-dropdown" style={{ border: `1px solid ${assessmentBorder}` }}>
                            <div className="dropdown-input">
                                <div className="d-inline-block alphabet-option ml-2 mr-2">
                                    <div className="circle p-1" style={{backgroundColor : this.theme['themeColor']}}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                </div>
                                <img className="option-separator-2" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />
                                {
                                    // !this.props.isReadOnly ?
                                    //     <div className="d-inline-block ml-2" style={{}}>
                                    //         {
                                    //             option.selectedText ?
                                    //                 this.parser.parseWithStyles(`${option.selectedText.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
                                    //                 : <span className="cw-input-for-dropdown" style={{}}>
                                    //                     {"Select One"}</span>
                                    //         }
                                    //     </div>
                                    //     :
                                        <div className="d-inline-block ml-2" style={{}}>
                                            {this.getBlankOptionValueDropDown(option)}
                                        </div>
                                }

                                <div className="float-right mt-2" style={{}}>
                                    <img className="dropdown-arrow-size" src={getImageSource(ImageConstants.DROPDOWN_ARROW_BLACK)} />
                                </div>
                            </div>

                        </div>


                        {/* </LinearGradient> */}
                    </div>
                }

            </div>

        )
    }

    getBlankOptionValue = (content) => {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (content && content.items && content.items.length) {
            let parsedJsx = this.parser.parseWithStyles(`${content.items[0].content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })
            return (
                <div className="d-inline-block ml-2">
                    {parsedJsx}
                </div>
            )
        }
        return <></>
    }

    getJsxForBlank(option, index) {

        let assessmentBorder = this.theme[`secondaryColors`][`assessmentOptionsBorder`]


        return (
            <div className="">

                <div >
                    {/* <LinearGradient colors={Colors.appTheme.gradient1} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1.2 }} style={styles.blank}> */}

                    <div className="row  mb-5 mt-2 mr-0 ml-0">
                        <div className="blank-wrapper d-flex align-items-center" style={{ border: `1px solid ${assessmentBorder}` }}>
                            <div className="blank-option">
                                <div className="d-inline-block alphabet-option ml-2 mr-2">
                                    <div className="circle p-1" style={{backgroundColor : this.theme['themeColor']}}>{CommonUtils.getAlphabet(index).toUpperCase()}</div>
                                </div>

                                <img className="option-separator" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />

                                {
                                     this.getBlankOptionValue(option)

                                }

                            </div>

                            {/* <TouchableOpacity onPress={() => this.textInput[index].focus()} style={styles.keyboard} >
                                <Image source={getThemeImageSource(ImageConstants.ASSESSMENT_KEYBOARD, this.props.categoryThemeId)} />
                            </TouchableOpacity> */}
                        </div>
                        {/* </LinearGradient> */}
                    </div>
                </div>
            </div >


        )
    }


    render() {
        // setCategoryThemeStyles(this.props.categoryThemeId)

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)
        let textColor = this.theme['themeColor']
        let borderColor = this.theme[`themeColor`]

        let content = this.state.content;
        //console.log(content)
        return (

            <div className="fill-in-the-blank">


                <QuestionTitle title={content.content.title} hideBackground={true} categoryThemeId={this.props.categoryThemeId} />

                <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />


                {
                    this.props.status != "NOT_ATTEMPTED" ?
                        <div className="view-answer-button ml-1 mt-3 cursor-pointer" style={{color : textColor, borderColor : borderColor}} onClick={this.toggleViewAnswer}>
                            {
                                this.state.showCorrectAnswer ? 'Hide Answer' : 'View Answer'
                            }
                            {/* <span>View Answer</span> */}
                            <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} />
                        </div>
                        :
                        <></>
                }

                {
                    this.state.showCorrectAnswer ?
                        <>
                            {
                                this.props.status != "NOT_ATTEMPTED" ?
                                    <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.props.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.answer}></AnswerTab>
                                    : <></>
                            }

                            {
                                this.props.status != "NOT_ATTEMPTED" ?
                                    <div className="">

                                        {content.content.options && content.content.options.length && content.content.options.map((option, index) => (
                                            <React.Fragment key={'fillInTheBlank' + index}>
                                                {option.contentType == 3 ?
                                                    this.getJsxForBlank(option, index)
                                                    : this.getJsxForBlankTypeDropDown(option, index)
                                                }
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    :
                                    <></>
                            }
                        </>

                        :

                        <></>
                }
            </div>
        );
    }
}

export default FillInTheBlank;
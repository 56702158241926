import React from "react";
import './video-checkpoint-learning.scss';
import LearningCard from "./learning-card/learning-card";
import { ImageConstants } from "../../constants/constants";
import Path from "../routes/routes-path";

class VideoCheckpointLearning extends React.Component {

    state = {
        content : {
            "_id" : "5ecf98e18e13c928534e06fd",
            "title" : "Applications of LCM",
            "type" : 2,
            "typeName" : "LEARNING CONTENT",
            "content" : {
                "type" : 1,
                "typeName" : "LEARNING CONTENT",
                "videoCheckpointInfo" : [ 
                    {
                        "videoUrl" : "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/9110f953-a7ba-4ced-80f4-6e46e3965b7b-18F38acf-F536-4697-8Cb4-9883244402Df-Applications%20Of%20Lcm%20V4-2.m4v",
                        "stopVideoAt" : [ 
                            {
                                "time" : 0.27,
                                "content" : {
                                    "_id" : "5e7308fb5c3254149568b8cd",
                                    "title" : "Checkpoint - Identify the smaller/bigger integer - Conceptual Knowledge - M1",
                                    "type" : 3,
                                    "typeName" : "CHECKPOINT QUESTION",
                                    "content" : {
                                        "type" : 8,
                                        "typeName" : "BINARY",
                                        "title" : "<p>Tanya should add cubes to each stack until both their heights are same.</p>",
                                        "images" : [],
                                        "solutionSteps" : {
                                            "type" : "",
                                            "typeName" : "",
                                            "question" : [],
                                            "steps" : [],
                                            "conclusion" : "",
                                            "summarySolution" : ""
                                        },
                                        "options" : [ 
                                            {
                                                "content" : "YES",
                                                "isCorrect" : true,
                                                "id" : "158459641233215845964123320023"
                                            }, 
                                            {
                                                "content" : "NO",
                                                "isCorrect" : false,
                                                "id" : "158459641233215845964123321798"
                                            }
                                        ]
                                    },
                                    "notes" : {
                                        "error" : "<p>To make the heights same, Tanya should keeping adding cubes to each stack until the heights are same.</p>",
                                        "success" : "<p>To make the heights same, Tanya should keeping adding cubes to each stack until the heights are same.</p>"
                                    },
                                    "createdDate" : 1584597243833.0,
                                    "createdBy" : "",
                                    "updatedDate" : 1584597243833.0,
                                    "updatedBy" : "",
                                    "state" : "",
                                    "author" : "",
                                    "parallel" : [],
                                    "relation" : [ 
                                        {
                                            "masterDataId" : "5e71c567e6a6150727ddc843",
                                            "state" : "SINGLE_PRIMARY_ELO",
                                            "present" : {
                                                "Type" : "elo",
                                                "id" : "5e71cc3ce6a6150727ddc855"
                                            }
                                        }
                                    ],
                                    "hint" : "",
                                    "comprehension" : []
                                    
                                }
                            }, 
                            {
                                "time" : 1.52,
                                "content" : {
                                    "_id" : "5e759fa1a207d67445a8501c",
                                    "title" : "Let us try",
                                    "type" : 3,
                                    "typeName" : "CHECKPOINT QUESTION",
                                    "content" : {
                                        "type" : 1,
                                        "typeName" : "SINGLE SELECT",
                                        "title" : "<p>3, 6, 9, 12, 15 are multiples of 3.Similarly, 4, 8, 12, 16 are multiples of _______.</p>",
                                        "images" : [],
                                        "solutionSteps" : {
                                            "type" : "",
                                            "typeName" : "",
                                            "question" : [],
                                            "steps" : [],
                                            "conclusion" : "",
                                            "summarySolution" : ""
                                        },
                                        "options" : [ 
                                            {
                                                "id" : "158476678719415847667871945110",
                                                "contentType" : 1,
                                                "contentTypeName" : "TEXT",
                                                "content" : "<p>4</p>",
                                                "isCorrect" : true
                                            }, 
                                            {
                                                "id" : "158476680157615847668015768534",
                                                "contentType" : 1,
                                                "contentTypeName" : "TEXT",
                                                "content" : "<p>3</p>",
                                                "isCorrect" : false
                                            }, 
                                            {
                                                "id" : "158476680491715847668049178988",
                                                "contentType" : 1,
                                                "contentTypeName" : "TEXT",
                                                "content" : "<p>6</p>",
                                                "isCorrect" : false
                                            }, 
                                            {
                                                "id" : "158476681279115847668127912306",
                                                "contentType" : 1,
                                                "contentTypeName" : "TEXT",
                                                "content" : "<p>8</p>",
                                                "isCorrect" : false
                                            }
                                        ]
                                    },
                                    "notes" : {
                                        "error" : "<p>4 × 1 = 4, 4 × 2 = 8, 4 × 3 = 12, 4 × 4 = 16. You can see 4, 8, 12, 16 are multiples of 4</p>",
                                        "success" : "<p>4 × 1 = 4, 4 × 2 = 8, 4 × 3 = 12, 4 × 4 = 16. You can see 4, 8, 12, 16 are multiples of 4</p>"
                                    },
                                    "createdDate" : 1584766880826.0,
                                    "createdBy" : "",
                                    "updatedDate" : 1586390366462.0,
                                    "updatedBy" : "",
                                    "state" : "",
                                    "author" : "",
                                    "parallel" : [],
                                    "relation" : [ 
                                        {
                                            "masterDataId" : "5e71c0afe6a6150727ddc842",
                                            "state" : "SINGLE_PRIMARY_ELO",
                                            "present" : {
                                                "Type" : "elo",
                                                "id" : "5e749968a207d67445a84fe3"
                                            }
                                        }
                                    ],
                                    "hint" : "",
                                    "comprehension" : [],
                                    
                                }
                            }, 
                            {
                                "time" : 2.1,
                                "content" : {
                                    "_id" : "5e75a3d9a207d67445a85024",
                                    "title" : "Let us try",
                                    "type" : 3,
                                    "typeName" : "CHECKPOINT QUESTION",
                                    "content" : {
                                        "type" : 1,
                                        "typeName" : "SINGLE SELECT",
                                        "title" : "<p> What is the LCM of 10 and 15?</p>",
                                        "images" : [],
                                        "solutionSteps" : {
                                            "type" : "",
                                            "typeName" : "",
                                            "question" : [],
                                            "steps" : [],
                                            "conclusion" : "",
                                            "summarySolution" : ""
                                        },
                                        "options" : [ 
                                            {
                                                "id" : "158476785507115847678550715946",
                                                "contentType" : 1,
                                                "contentTypeName" : "TEXT",
                                                "content" : "<p>10</p>",
                                                "isCorrect" : false
                                            }, 
                                            {
                                                "id" : "158476787132215847678713226308",
                                                "contentType" : 1,
                                                "contentTypeName" : "TEXT",
                                                "content" : "<p>20</p>",
                                                "isCorrect" : false
                                            }, 
                                            {
                                                "id" : "158476787512815847678751288680",
                                                "contentType" : 1,
                                                "contentTypeName" : "TEXT",
                                                "content" : "<p>30</p>",
                                                "isCorrect" : true
                                            }, 
                                            {
                                                "id" : "158476789003015847678900309653",
                                                "contentType" : 1,
                                                "contentTypeName" : "TEXT",
                                                "content" : "<p>60</p>",
                                                "isCorrect" : false
                                            }
                                        ]
                                    },
                                    "notes" : {
                                        "error" : "<p>10 = 2 × 5, 15 = 3 × 5, LCM = 2 × 3 × 5 = 30</p>",
                                        "success" : "<p>10 = 2 × 5, 15 = 3 × 5, LCM = 2 × 3 × 5 = 30</p>"
                                    },
                                    "createdDate" : 1584767960732.0,
                                    "createdBy" : "",
                                    "updatedDate" : 1586855994206.0,
                                    "updatedBy" : "",
                                    "state" : "",
                                    "author" : "",
                                    "parallel" : [],
                                    "relation" : [ 
                                        {
                                            "masterDataId" : "5e71c0afe6a6150727ddc842",
                                            "state" : "SINGLE_PRIMARY_ELO",
                                            "present" : {
                                                "Type" : "elo",
                                                "id" : "5e749ad9a207d67445a84feb"
                                            }
                                        }
                                    ],
                                    "hint" : "",
                                    "comprehension" : []
                                    
                                }
                            }
                        ]
                    }
                ],
                "description" : "<div class=\"ckeditor-html5-video\" style=\"text-align: center;\"><video controlslist=\"nodownload\" src=\"https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/9110f953-a7ba-4ced-80f4-6e46e3965b7b-18F38acf-F536-4697-8Cb4-9883244402Df-Applications%20Of%20Lcm%20V4-2.m4v\">&nbsp;</video></div>"
            },
            "createdDate" : 1590663394801.0,
            "createdBy" : "",
            "updatedDate" : 1614852445304.0,
            "updatedBy" : "",
            "state" : "",
            "displayTitle" : "Applications of LCM",
            
        }
    }

    goToHomeScreen() {
        this.props.history.push(Path.HOME)
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2 mb-4'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1' >{`Video Learning`}</span>
                {/* <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToConceptList()}>{`${this.params.title} >`}</span>
                <span className='pl-2 title-1'>{`${this.state.learningElos && this.state.learningElos.length ? this.state.learningElos[0].eloTitle : ""}`}</span> */}
            </div>
        )
    }


    render() {
        return(
            <div className="ml-2 text-center video-checkpoint-questions">
                {this.renderBreadcrumbForChapterList()}
                <LearningCard
                    data = {{
                        headerIcon : ImageConstants.INTRODUCTION,
                        showTitle : true,
                        content : this.state.content.content.description,
                        title : this.state.content.title,
                        videoCheckpointInfo: this.state.content.content.videoCheckpointInfo ? this.state.content.content.videoCheckpointInfo : []
                    }}
                />
            </div>
        )
    }


}

export default VideoCheckpointLearning;
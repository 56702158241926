import React, { Component } from 'react';
import './action-sheet.scss';
import { ImageConstants } from '../../../constants/constants';
import Parser from '../content-parser/content-parser';
import { getThemeImageSource, getImageSource } from '../../../utility/image-source';
import SharedThemeCategory from '../../../../shared-storage/category-theme';
import { Modal, ModalBody } from 'reactstrap';

class ActionSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.parser = new Parser();
    }

    dropdownRef = React.createRef()

    componentDidMount() {
        document.addEventListener('mousedown', this.closeActionSheet)
    }

    closeActionSheet = (e) => {
        // //console.log(e)
        //console.log(this.dropdownRef)
        if (this.props.modal) {
            // //console.log(this.dropdownRef.current.contains(e.target))
            if (this.dropdownRef && this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
                this.props.toggleActionSheet(false)
            }
        }

    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let assessmentBorder = this.theme[`secondaryColors`][`assessmentOptionsBorder`]

        return (
            <div ref={this.dropdownRef} className='dropdown-for-fill-in-the-blank' style={{ border: `1.2px solid ${assessmentBorder}` }}>
                <div className=''>
                    <div>
                        <div className='dropdown-container position-relative'>
                            {/* <div className='close-icon-wrapper'>
                                <img className='close-icon'
                                    src={getThemeImageSource(ImageConstants.THEME_CLOSE_ICON, this.props.categoryThemeId)}
                                    onClick = {() => this.props.toggleActionSheet()} />
                            </div> */}
                            <div>
                                {this.props.options.map((item, index) => (
                                    <>
                                        {this.props.isGeneral ? <li>{item.content}</li> :

                                            <div className='list-item-style'
                                                style={{ borderBottom: `${this.props.options.length - 1 == index ? "none" : `1px solid ${assessmentBorder}`}` }}
                                                key={item.id + index}
                                                onClick={() => { this.props.onSelect && this.props.onSelect(item, index) }}
                                            >
                                                {this.parser.parseWithStyles(`${item.content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "")}`, { p: styles.p, "cw-math": styles.p })}
                                            </div>

                                        }
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ActionSheet;

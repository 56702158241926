import React from "react";
import './single-select.scss';
import CommonUtils from "../../../../utility/common-utilities";
import QuestionTitle from "../question-title/question-title";
import QuestionImage from "../../cw-question-image/cw-question-image";
import { getThemeImageSource, getImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import RadioGroup from "../../radio/radio-group";
import AssessmentFooter from "../../assessment-footer/assessment-footer";
import SummarySoultions from "../../summary-solution/summary-solution";
import DotPagination from "../../dot-pagination/dot-pagination";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import AnswerTab from "../../../my-assessment-result/question/answer-tab/answer-tab";
import ReactTooltip from "react-tooltip";


class SingleSelect extends React.Component {

    state = { content: null, attachments: [], showSolution: true, showOptions: false };

    isValid = false;
    originalContent = {};
    answerTickCount = 0


    constructor(props) {
        super(props)
        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');
        this.originalContent = JSON.parse(JSON.stringify((this.props.content)));

        if (this.props.isReadOnly) {
            let content = JSON.stringify(this.props.content)
            this.state.content = JSON.parse(content);

        }
    }

    componentDidMount() {

        if (this.props.isReadOnly) {
            this.setState({ status: `${this.props.userAnswerStatus ? "CORRECT" : "IN_CORRECT"}` })
        }

        if (!this.props.isReadOnly) {
            this.setState({ content: this.getSuffledItem(this.props.content) });
            this.startTimerforAnswer()
        }

    }

    startTimerforAnswer() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }
        this.timerIntervalId = setInterval(() => {

            this.answerTickCount += 1
        }, 1000)
    }

    getSuffledItem(content) {
        CommonUtils.shuffleArray(content.content.options);
        return content;
    }

    reset() {
        this.setState({ content: this.getSuffledItem(JSON.parse(JSON.stringify(this.originalContent))) });
    }

    onOptionPressed = (option) => {
        this.isValid = true;
        if (this.props.delegate && this.props.delegate.enableNavigation)
            this.props.delegate.enableNavigation();
    }

    updateAnswer = (payload) => {

        let options = this.props.content.content.options;

        let option = options.filter(opt => opt.id == payload.answer[0].id);
        if (!option.length)
            return;

        option[0].isChecked = true;
        this.isValid = true;
        this.forceUpdate();
    }

    getAnswer = () => {

        // console.log(this.answerTickCount, this.timerIntervalId)

        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }

        if (this.isValid) {
            let payload = [];
            let options = this.state.content.content.options;
            for (let i = 0; i < options.length; i++) {
                if (options[i].isChecked)
                    payload.push({ id: options[i].id })
            }
            return { payload, timeTakenToAnswer: this.answerTickCount };
        } else
            return null;
    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
    }

    onSelectImage = (type) => {
        // this.props.navigation.navigate('Media')
        // this.showMedia(type)
        this.props.delegate && this.props.delegate.showMedia(type)

    }
    showMedia = (type) => {
        this.setState({ showMedia: true, selectedMediaType: type })
    }

    hideMedia = () => {
        this.setState({ showMedia: false })
    }

    addMedia = (url, type) => {
        let attachments = this.state.attachments;
        attachments.push({
            type,
            url
        })

        //console.log('atatch', attachments)
        this.setState({ attachments }, () => {
            this.hideMedia()
            //console.log(this.state.attachments)
        })
    }

    getContentType = (type) => {
        if (type == 'IMAGE') {
            return 'image/jpeg'
        }

        if (type == 'VIDEO') {
            return 'video/mp4'
        }

        if (type == 'AUDIO') {
            return 'audio/mp3'
        }
    }

    renderAttachments = (item, index) => {

        // if (item.type == 'IMAGE') {

        //     return (
        //         <View>
        //             <TouchableOpacity style={styles.attachments} onPress={() => this.openPreview(item.url, 'IMAGE')}>
        //                 <Image source={{ uri: item.url }} style={styles.imageAttachment} resizeMode="cover" />

        //             </TouchableOpacity>
        //             <TouchableOpacity onPress={() => this.removeAttachment(item)} style={styles.deleteIcon} hitSlop={styles.imageHitSlop}><Image source={getImageSource(ImageConstants.DELETE_ATTACHMENT)} /></TouchableOpacity>
        //         </View>

        //     )
        // }

        // if (item.type == 'VIDEO') {

        //     return (
        //         <View>
        //             <TouchableOpacity onPress={() => this.previewAttachment(item)} style={Styles.attachments} onPress={() => this.openPreview(item.url, 'VIDEO')}>
        //                 <Image source={{ uri: item.url }} style={Styles.imageAttachment} resizeMode="contain" />
        //             </TouchableOpacity>
        //             <TouchableOpacity onPress={() => this.removeAttachment(item)} style={Styles.deleteIcon} hitSlop={Styles.imageHitSlop}><Image source={getImageSource(ImageConstants.DELETE_ATTACHMENT)} /></TouchableOpacity>

        //         </View>

        //     )
        // }

        // if (item.type == 'AUDIO') {

        //     return (
        //         <View>
        //             <TouchableOpacity onPress={() => this.previewAttachment(item)} style={Styles.audioAttachment} onPress={() => this.openPreview(item.url, 'AUDIO')}>
        //                 <Image source={getImageSource(ImageConstants.MICROPHONE)} />
        //                 <Image source={getImageSource(ImageConstants.AUDIO_CARD_FOOTER)} style={Styles.audioCardFooter} resizeMode="cover" />
        //                 <TouchableOpacity onPress={() => this.removeAttachment(item)} style={Styles.audioDeleteIcon} hitSlop={Styles.imageHitSlop}><Image source={getImageSource(ImageConstants.NAVIGATION_CLOSE)} /></TouchableOpacity>

        //             </TouchableOpacity>
        //         </View>
        //     )
        // }
    }

    openPreview = (url, type) => {
        this.setState({ previewUrl: url, imagePreview: true, previewType: type })
    }

    removeAttachment = (item) => {

        this.props.delegate && this.props.delegate.removeAttachment(item)

    }


    updateAnswerForUserAnswer = (payload) => {

        let content = this.state.content;
        let options = content.content.options;
        console.log(payload)
        options = options.map(opt => {
            if (opt.id == payload.answer[0].id) {
                opt.isCorrect = true;
            } else {
                opt.isCorrect = false;
            }
            return opt
        });
        content.content.options = options;
        //console.log(content)
        this.setState({ content: content }, () => {

        })
    }

    showMyAnswer = () => {
        if (this.props.userAnswer) {
            this.updateAnswerForUserAnswer(this.props.userAnswer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.parse(JSON.stringify(this.props.content))
        //console.log(content)
        this.setState({ content: content }, () => {

        })
    }

    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer, showSolution: !this.state.showSolution })
    }

    toggleViewSolution = () => {

        if (this.state.status === "CORRECT") {
            return;
        }

        this.setState({ showSolution: !this.state.showSolution, showCorrectAnswer: !this.state.showCorrectAnswer })
    }

    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions })
    }



    render() {

        // setCategoryThemeStyles(this.props.categoryThemeId);

        let content = this.state.content;
        ////console.log(content)
        console.log(this.props.isFromMyAssessment)
        ////console.log(this.props.userAnswer)
        // if (this.state.showMedia)
        // return <View style={{ flex: 1 }}><Media delegate={this} type={this.state.selectedMediaType} /></View>

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (

            content ? <div className="single-select " onMouseEnter={ReactTooltip.rebuild}>
                {/* <div className={`${!this.props.isReadOnly ? "min-height" : ""}`}> */}
                <div className={`${"min-height"}`}>
                    {
                        this.props.isReadOnly && !this.props.isMultiStep ?
                            <>
                                <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                                    {
                                        this.props.userAnswerStatus ?
                                            <div className="correct-answer-bg d-flex">
                                                <div className="">
                                                    <img className="image-size" src={getImageSource(ImageConstants.WHITE_TICK_ICON_NEW)} />
                                                </div>
                                                <div className="ml-2">
                                                    <div className="name-header-correct">{"Correct"}</div>
                                                    {/* <div className="sub-title">{this.correctAnswerStaticText.subTitle}</div> */}
                                                </div>
                                            </div>

                                            :
                                            <div className="incorrect-answer-bg d-flex">
                                                <div className="">
                                                    <img className="image-size" src={getImageSource(ImageConstants.WHITE_CROSS_ICON)} />
                                                </div>
                                                <div className="ml-1">
                                                    <div className="name-header text-center">{"Incorrect"}</div>
                                                    {/* <div className="sub-title">{this.inCorrectAnswerStaticText.subTitle}</div> */}
                                                </div>
                                            </div>

                                    }

                                </div>
                                {/* <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                    <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                    </div>
                                </div> */}
                            </>
                            :
                            <></>
                    }



                    {this.props.totalSteps && this.props.quesNo && <DotPagination noOfDots={this.props.totalSteps} activeDot={this.props.quesNo} categoryThemeId={this.props.categoryThemeId} />}


                    {
                        !this.props.isCheckPoint && this.props.isMultiStep && !this.props.isReadOnly ?
                            <>
                                <QuestionTitle title={content.content.title}
                                    queNo={this.props.quesNo ? this.props.quesNo : null}
                                    noOfDots={this.props.totalSteps}
                                    activeDot={this.props.quesNo}
                                    hideBackground={this.props.isMultiStep ? true : false}
                                    categoryThemeId={this.props.categoryThemeId}
                                    isAssessment={this.props.isAssessment}
                                    delegate={this.props.delegate}
                                    isReadOnly={this.props.isReadOnly}
                                    isSchoolAssignment={true} />
                                <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                            </>

                            :
                            !this.props.isMultiStep && !this.props.isCheckPoint ?
                                <>
                                    <QuestionTitle title={content.content.title}
                                        queNo={this.props.quesNo ? this.props.quesNo : null}
                                        noOfDots={this.props.totalSteps}
                                        activeDot={this.props.quesNo}
                                        hideBackground={this.props.isMultiStep ? true : false}
                                        categoryThemeId={this.props.categoryThemeId}
                                        isAssessment={this.props.isAssessment}
                                        delegate={this.props.delegate}
                                        isReadOnly={this.props.isReadOnly}
                                        showOptions={this.state.showOptions}
                                        toggleOptions={() => this.toggleOptions()}
                                    />
                                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                                    {
                                        this.props.isReadOnly && this.state.showOptions ?
                                            <div className={`mt-3 option-margin-bottom`}>
                                                <RadioGroup options={content.content.options}
                                                    // onOptionPressed={this.onOptionPressed}
                                                    isCheckPoint={this.props.isCheckPoint}
                                                    // isReadOnly={this.props.isReadOnly}
                                                    categoryThemeId={this.props.categoryThemeId}
                                                />
                                            </div>
                                            :
                                            <></>
                                    }
                                </>

                                :
                                <></>
                    }



                    {!this.props.isCheckPoint && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                        </div>
                        {
                            (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                    style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${content.content.mark} Marks`}>
                                    <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                    <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                </div> : <></>
                        }
                    </div>
                    }
                    {
                        !this.props.isReadOnly ?
                            <div className={`mt-3 option-margin-bottom`}>
                                <RadioGroup options={content.content.options}
                                    onOptionPressed={this.onOptionPressed}
                                    isCheckPoint={this.props.isCheckPoint}
                                    isReadOnly={this.props.isReadOnly}
                                    categoryThemeId={this.props.categoryThemeId}
                                />
                            </div>
                            :
                            <></>
                    }


                    {
                        this.props.isReadOnly && !this.props.isMultiStep ?
                            <>
                                <div className="d-flex align-items-center mt-3 ml-3">
                                    <div className="d-flex">
                                        <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                            style={{
                                                backgroundColor: `${this.state.showSolution ? "#E1D9FF" : "#E6E8E8"}`,
                                                color: `${this.state.showSolution ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                            }} onClick={this.toggleViewSolution}>
                                            {
                                                this.state.showSolution ? 'Solution' : 'Solution'
                                            }
                                            {/* <span>View Answer</span> */}
                                            {/* <img className="arrow-size" src={getThemeImageSource(this.state.showSolution ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                        </div>
                                    </div>
                                    {
                                        this.props.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                            <div className="d-flex ml-4">
                                                <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                                    style={{
                                                        backgroundColor: `${this.state.showCorrectAnswer ? "#E1D9FF" : "#E6E8E8"}`,
                                                        color: `${this.state.showCorrectAnswer ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                                    }} onClick={this.toggleViewAnswer}>
                                                    {
                                                        this.state.showCorrectAnswer ? 'Answer' : 'Answer'
                                                    }
                                                    {/* <span>View Answer</span> */}
                                                    {/* <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                                <div className="solution-answer-border mb-4"></div>

                                {
                                    this.state.showSolution ?
                                        <SummarySoultions forQuestion={true} solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions>
                                        : <></>
                                }

                                {
                                    this.state.showCorrectAnswer ?
                                        <>
                                            {
                                                this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                    <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.state.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.userAnswer}></AnswerTab>
                                                    : <></>
                                            }
                                            {
                                                this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                    <div className="options option-margin-bottom">
                                                        <RadioGroup options={content.content.options} isCheckPoint={this.props.isCheckPoint} isReadOnly={true} categoryThemeId={this.props.categoryThemeId} />
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </>

                                        : <></>
                                }

                            </>
                            :
                            <></>
                    }
                    {/* {this.props.isReadOnly && !this.props.isMultiStep ? <div className="question-separator mt-3 mb-3 d-flex flex-row justify-content-center align-items-center">
                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                        </div> </div> : <></>} */}
                </div>
                <div className="">
                    {!this.props.isCheckPoint && !this.props.isMultiStep && <AssessmentFooter userAnswerStatus={this.props.userAnswerStatus} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly} delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}
                </div>
                <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                    multiline={true}
                    place='right'
                    type='light' effect='solid'
                    event="mouseover mouseenter"
                    eventOff="mouseleave mouseout scroll mousewheel blur" />
            </div>
                :
                <></>

        );
    }
}


export default SingleSelect;

export default class Store {

    static store = {};

    static setStore(key, value) {

        this.store[key] = value
    }

    static getStore(key) {
        return this.store[key]
    }

};
import React from "react";
import './multi-select.scss';
import SharedThemeCategory from "../../../../../../shared-storage/category-theme";
import Checkbox from "../../../../common/check-box/check-box";
import AnswerTab from "../../answer-tab/answer-tab";
import QuestionTitle from "../../../../common/question/question-title/question-title";
import QuestionImage from "../../../../common/cw-question-image/cw-question-image";
import { getThemeImageSource, getImageSource } from "../../../../../utility/image-source";
import { ImageConstants } from "../../../../../constants/constants";
import { Constants } from "../../../../../constants/constants";
import ImagePreview from "../../../../common/image-preview/image-preview";



class MultiSelect extends React.Component {


    state = {
        content: null
    }

    constructor(props) {
        super(props);
        let content = JSON.stringify(this.props.content)
        this.state.content = JSON.parse(content);
        if (this.props.showMyAnswer) {
            this.showMyAnswer()
        }

    }

    componentDidMount() {
    }



    updateAnswer = (payload) => {

        let answeredIds = {};
        for (let i = 0; i < payload.answer.length; i++) {
            answeredIds[payload.answer[i].id] = payload.answer[i];
        }

        let content = this.state.content;

        let options = this.state.content.content.options;

        for (let i = 0; i < options.length; i++) {
            if (answeredIds[options[i].id]) {
                options[i].isCorrect = true;
            } else {
                options[i].isCorrect = false;
            }
        }

        this.setState({ content: content })

    }


    showMyAnswer = () => {
        // let answer = {
        //     "id": "5f6cd5463086ed328ee2ab86",
        //     "answer": [
        //         {
        //             "id": "160096799443916009679944397871"
        //         }
        //     ]
        // }
        if (this.props.answer) {
            this.updateAnswer(this.props.answer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.stringify(this.props.content)
        this.setState({ content: JSON.parse(content) })
    }


    openPreview = (previewUrl) => {
        this.setState({ imagePreview: true, previewUrl: previewUrl })
    }

    closePreview = () => {
        this.setState({ imagePreview: false });
    }

    getCheckedStatus = (option) => {
        return option.isCorrect ? true : false
    }

    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer })
    }



    render() {
        // setCategoryThemeStyles(this.props.categoryThemeId);
        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)
        let textColor = this.theme['themeColor']
        let borderColor = this.theme[`themeColor`]
        let content = this.state.content;
        return (
            content ?

                <div className="multi-select">

                    <QuestionTitle title={content.content.title} hideBackground={true} categoryThemeId={this.props.categoryThemeId} />

                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />

                    {
                        this.props.status != "NOT_ATTEMPTED" ?
                            <div className="view-answer-button ml-1 mt-3 cursor-pointer" style={{ color: textColor, borderColor: borderColor }} onClick={this.toggleViewAnswer}>
                                {
                                    this.state.showCorrectAnswer ? 'Hide Answer' : 'View Answer'
                                }
                                {/* <span>View Answer</span> */}
                                <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} />
                            </div>
                            :
                            <></>
                    }


                    {
                        this.state.showCorrectAnswer ?
                            <>
                                {
                                    this.props.status != "NOT_ATTEMPTED" ?
                                        <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.props.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.answer}></AnswerTab>
                                        : <></>
                                }

                                {
                                    this.props.status != "NOT_ATTEMPTED" ?
                                        <div className="option-wrapper d-flex flex-column mt-3 justify-content-between">
                                            {content.content.options && content.content.options.map((option, index) => (
                                                <div className={`option-border ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "d-flex justify-content-between" : this.props.isReadOnly ? "d-flex align-items-center" : ""}`}
                                                    style={{ borderColor: this.theme ? `${this.theme['secondaryColors']['assessmentOptionsBorder']}` : '#353535' }}
                                                    key={option.id}>
                                                    <Checkbox label={option.content} isChecked={this.getCheckedStatus(option)} contentType={option.contentType ? option.contentType : 1} categoryThemeId={this.props.categoryThemeId} />
                                                    {option.contentType === Constants.CONTENT_TYPE_IMAGE &&
                                                        <div className="ml-5">
                                                            <img className="expand-icon cursor-pointer" onClick={() => this.openPreview(option.content)} src={getImageSource(ImageConstants.EXPAND)} />
                                                        </div>
                                                    }
                                                </div>
                                            ))}

                                            {this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>}

                                        </div>
                                        :
                                        <></>
                                }
                            </>

                            :

                            <></>
                    }



                </div> : <></>
        );
    }
}

export default MultiSelect;
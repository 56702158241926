import React from "react";
import './my-classwork-header.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import Path from "../../routes/routes-path";

export default class ClassworkHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        showLateralMenu: false
    }


    toggleLateralMenu = () => {
        this.props.delegate && this.props.delegate.goBack();
    }


    onPressAdd = () => {
        this.props.delegate && this.props.delegate.showSelectChapterModal();
    }



    onPressFilter = () => {
        this.props.delegate && this.props.delegate.showAssessmentFilterModal()
    }

    close = () => {
        this.props.delegate && this.props.delegate.close()
    }

    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }


    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2'>{`My Homeworks`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }



    render() {

        return (
            <div className="classwork-header">
                <div className="text-right">
                    <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                </div>
                <div className="header-title-margin-top">
                    {this.renderBreadcrumbForChapterList()}
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="title">My Homeworks</div>
                        <div className="filter-wrapper cursor-pointer" onClick={this.onPressFilter}>
                            {this.props.showActiveFilter ?
                                <img className="filter-size" src={getImageSource(ImageConstants.FILLTER_ACTIVE)} />
                                :
                                <img className="filter-size" src={getImageSource(ImageConstants.FILLTER_ICON)} />
                            }
                        </div>
                        {/* <div className="text-right mr-5 ">
                            <img className="search-icon-width"
                                src={getImageSource(ImageConstants.CHAPTER_SEARCH_ICON)}
                                onClick={() => this.getSearchedChapterList()}></img>
                            <input
                                className="input-box"
                                type="text"
                                value={this.state.searchText}
                                onChange={e => this.setSearchText(e, 'searchText')}
                                placeholder='Search Chapter'>
                            </input>

                        </div> */}
                    </div>
                    <div>
                        <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                    </div>
                </div>
            </div>
        );
    }
}



export const API_CONSTANTS = {

    MASTER_DATA: 'MASTER_DATA',
    CONTENT: 'CONTENT',
    META_DATA: 'META_DATA',
    ELO: 'ELO',
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP',
    WORKFLOW: 'WORKFLOW',
    WORKFLOW_NODES: 'WORKFLOW_NODES',
    LOOKUP: 'LOOKUP',
    SCHOOL: 'SCHOOL',
    CLASS: 'CLASS',
    SECTION: 'SECTION',
    STUDENT_SIGNUP: 'STUDENT_SIGNUP',
    STUDENT_SIGNUP_UPDATE: 'STUDENT_SIGNUP_UPDATE',
    BOARD: 'BOARD',

    CATEGORY_THEME: 'CATEGORY_THEME',

    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',

    GUARDIAN_FORGOT_PASSWORD: 'GUARDIAN_FORGOT_PASSWORD',
    GUARDIAN_RESET_PASSWORD: 'GUARDIAN_RESET_PASSWORD',

    APP_VERSION: 'APP_VERSION',

    CONFIGURATIONS: "CONFIGURATIONS",
    BOOKMARK: 'BOOKMARK',
    DOUBTS_GENERIC: 'DOUBTS_GENERIC',
    CITIES: 'CITIES',
    COUNTRY: 'COUNTRY',
    MISCELLANEOUS: 'MISCELLANEOUS',
    EVENTS: "EVENTS",
    PARENT_USERS: "PARENT_USERS",
    PUBLIC_CONTENT: "PUBLIC_CONTENT",
    NOTIFICATIONS_LOG: 'NOTIFICATIONS_LOG',
    COURSES: 'COURSES'

}

export const PREFIX = {
    EVENT_PREFIX_TEXT: ""
}

import React, { Component } from 'react';
import './flag-popup.scss'
import { Modal, ModalBody } from "reactstrap";
import { ImageConstants } from '../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source'
import SharedProfile from '../../../../shared-storage/profile';
import SharedStaticText from '../../../../shared-storage/static-text-store';
import CommonUtils from '../../../utility/common-utilities';
import SharedThemeCategory from '../../../../shared-storage/category-theme';

class FlagPopup extends Component {

    constructor(props) {
        super(props);

    }

    onClickOkay = () => {
       this.props.onPressYes && this.props.onPressYes()
    }

    onClickClose = () => {

        this.props.close && this.props.close()

    }

    

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <Modal className="cw-common-custom-modal-center-for-assessment" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_HEADER, this.props.categoryThemeId)}></img>
                    <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} />
                </div>
                <ModalBody>

                    <div className="pratice-container">
                        <div className='d-flex flex-row align-items-center justify-content-center'>
                            <div> <img className='flag-size' src={getImageSource(ImageConstants.FLAG_ENABLED)} /></div>
                            <div className='ml-3'>
                                <div className='header-text '>Flag the question to continue</div>
                                <div className='name-header'>Flag it in case you want to answer it later.</div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-around button-wrapper">
                            <div className="continue-button cursor-pointer text-center" style={{color : this.theme[`themeColor`], borderColor : this.theme[`themeColor`]}}
                            onClick={() => this.onClickClose()}>
                                {`Cancel`}
                            </div>
                            <div className="take-challenge cursor-pointer  text-center" style={{backgroundColor : this.theme[`themeColor`]}}
                             onClick={() => this.onClickOkay()}>
                                {`Yes`}
                            </div>
                        </div>
                        <div className="position">
                            <img className="challenge-footer" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_FOOTER, this.props.categoryThemeId)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default FlagPopup;

import React from 'react';
import './practice-questions.scss';
import CwContentViewer from '../../common/cw-content-viewer/cw-content-viewer';
import { getImageSource } from '../../../utility/image-source';
import { ImageConstants } from '../../../constants/constants';
import QuestionImage from '../../common/cw-question-image/cw-question-image';
import PracticeQuestionModal from '../practice-question-popup/practice-question-popup';
import SharedThemeCategory from '../../../../shared-storage/category-theme';
import CommonUtils from '../../../utility/common-utilities';

class PracticeQuestions extends React.Component {

    state = {
        showQuestionPopup: false
    }

    componentDidMount() {
        //console.log(this.props)
    }

    viewAnswer = (item, index) => {

        this.showQuestionPopup(this.props.content, this.props.index, true, false)
        this.props.getSelectedIndex && this.props.getSelectedIndex(this.props.index)
    }

    viewSolution = (item, index) => {

        this.showQuestionPopup(this.props.content, this.props.index, false, true)
        this.props.getSelectedIndex && this.props.getSelectedIndex(this.props.index)
    }

   
    showQuestionPopup = (item, index, isAnswer, isSolution) => {

        this.setState({ showQuestionPopup: true, currentQuestion: item, index: index, viewAnswer: isAnswer, viewSolution: isSolution })
    }

    hideQuestionPopup = () => {
        this.setState({ showQuestionPopup: false, index: '' }, () => {
            this.props.getSelectedIndex && this.props.getSelectedIndex(this.state.index)
        })


    }


    onClickBookMarkActive = () => {
        this.props.delegate && this.props.delegate.onClickBookMarkActive && this.props.delegate.onClickBookMarkActive(!this.props.isBookMark ? this.props.content.bookMarkId : this.props.bookMarkId,this.props.index);
    }

    onClickBookMark = () => {

        let content = this.props.content;

        let contentObj = { id: content._id, objectType: 4, contentType: 1 }
        this.props.delegate && this.props.delegate.onClickBookMark && this.props.delegate.onClickBookMark(contentObj,this.props.index);
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        let lgButton = `linear-gradient(${this.theme[`linearGradient`][`binaryCard`][0]}, ${this.theme[`linearGradient`][`binaryCard`][1]})`
        let buttonBorder = CommonUtils.getThemeBasedButtonBorder(this.theme[`secondaryColors`][`assessmentOptionsBorder`])

        return (
            <div className='practice-questions'>
                <div style={{backgroundColor : `${this.state.index === this.props.index ? this.theme['learningContentBackground'] : ''}`}} className={`d-flex justify-content-between ${this.state.index === this.props.index ? "title-width-on-selection" : ''}`}>
                    <div className={`d-inline-flex content-width ${this.state.index === this.props.index ? "title-bold-selection" : ''}`}>
                        <span style={{backgroundColor : `${this.theme['themeColor']}`}} className={`mr-2 sr-no ${this.props.index >= 9 ? "sr-no-padding" : ''}`}>{`${this.props.index + 1}`}</span>
                        <CwContentViewer content={this.props.content.question.title}></CwContentViewer>
                    </div>
                    {this.props.showBookMark && <div className='mr-5 cursor-pointer' onClick={()=>this.onClickBookMarkActive()}><img className='bookmark' src={getImageSource(ImageConstants.BOOKMARK_ACTIVE)}></img></div>}
                    {this.props.showInactiveBookmark && <div className='mr-5 cursor-pointer' onClick={()=>this.onClickBookMark()}><img className='bookmark' src={getImageSource(ImageConstants.BOOKMARK_ICON)}></img></div>}
                    
                </div>

                {this.props.content.question.images && this.props.content.question.images.length ?
                    <div className='mb-3 question-title-image mt-2'>
                        <QuestionImage images={this.props.content.question.images} index={this.props.index} />
                    </div>
                    :
                    ''
                }

                <div className={`justify-content-start align-items-center button-wrapper p-3 ${this.state.index === this.props.index ? "d-none" : "d-flex"}`}>
                    <div className='view-button view-answer text-center ' style={{ backgroundImage: lgButton, border: buttonBorder }} onClick={() => this.viewAnswer(this.props.content.question, this.props.index)}>View Answer</div>
                    <div className='view-button text-center  ml-5' style={{ backgroundImage: lgButton, border: buttonBorder }} onClick={() => this.viewSolution()}>View Solution</div>
                </div>

                {this.state.showQuestionPopup ?
                    <PracticeQuestionModal
                        content={this.state.currentQuestion}
                        index={this.state.index}
                        isAnswer={this.state.viewAnswer}
                        isSolution={this.state.viewSolution}
                        hideQuestionPopup={() => this.hideQuestionPopup()}
                        categoryThemeId = {this.props.categoryThemeId}
                    /> :
                    ''}
            </div>
        )
    }


}

export default PracticeQuestions;
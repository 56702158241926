import { ImageConstants } from '../constants/constants';
import imageCache from "./image-cache";

export function getThemeImageSource(image, categoryId) {


    let theme = '';

    switch (categoryId) {

        case "1": theme = `purple`;
            break;

        case "2": theme = `indigo`;
            break;

        case "3": theme = `orange`;
            break;

        case "4": theme = `skyblue`;
            break;

        case "5": theme = `blue`;
            break;

        case "6": theme = `yellow`;
            break;

        default:
            theme = `purple`;
            break;
    }


    return imageCache[theme][image];

}

export function getImageSource(image) {

    let imageSrc;

    switch (image) {
        case ImageConstants.BROWSE_CHAPTER_CROSS_LINK:
            imageSrc = require("../assets/images/browse-chapter-cross-link.png")
            break;
        case ImageConstants.WORKOUT_CROSS_LINK:
            imageSrc = require("../assets/images/workout-cross-link.png")
            break;
        case ImageConstants.GIFT_BOX:
            imageSrc = require("../assets/images/events/gift-box.png")
            break;
        case ImageConstants.SUBJECT_CHANGE_ICON:
            imageSrc = require("../assets/images/subject_change_icon.png")
            break;
        case ImageConstants.CHEMISTRY_ICON:
            imageSrc = require("../assets/images/chemistry_icon.png")
            break;
        case ImageConstants.MATHS_ICON:
            imageSrc = require("../assets/images/maths_icon.png")
            break;
        case ImageConstants.WHITE_UP_ANGLE_ARROW:
            imageSrc = require("../assets/images/white-up-angle-arrow.png")
            break;
        case ImageConstants.WHITE_DOWN_ANGLE_ARROW:
            imageSrc = require("../assets/images/white-down-angle-arrow.png")
            break;

        case ImageConstants.PAUSE_BUTTON:
            imageSrc = require("../assets/images/pause-button.png")
            break;

        case ImageConstants.BLACK_STUDENTS_COUNT_IMAGE:
            imageSrc = require("../assets/images/black-students-count-image.png")
            break;
        case ImageConstants.CHEAT_SHEET_ICON:
            imageSrc = require("../assets/images/exam-prep-kit/cheat-sheet-icon.png")
            break;
        case ImageConstants.APPROACH_TO_PROBLEM_SOLVING:
            imageSrc = require("../assets/images/exam-prep-kit/approach-to-problem-solving.png")
            break;
        case ImageConstants.COMMON_ERRORS:
            imageSrc = require("../assets/images/exam-prep-kit/common-errors.png")
            break;
        case ImageConstants.MOCK_TEST_ICON:
            imageSrc = require("../assets/images/exam-prep-kit/mock-test.png")
            break;
        case ImageConstants.PRACTICE_QUESTION_ICON:
            imageSrc = require("../assets/images/exam-prep-kit/practice-questions.png")
            break;
        case ImageConstants.SOLVED_EXAMPLES_ICON:
            imageSrc = require("../assets/images/exam-prep-kit/solved-examples.png")
            break;
        case ImageConstants.THINGS_TO_REMEMBER_ICON:
            imageSrc = require("../assets/images/exam-prep-kit/things-to-remember.png")
            break;
        case ImageConstants.TIPS_AND_TRICKS_ICON:
            imageSrc = require("../assets/images/exam-prep-kit/tips-and-tricks.png")
            break;




        case ImageConstants.PREP_KIT_BG:
            imageSrc = require("../assets/images/prep-kit-bg.png")
            break;

        case ImageConstants.PRACTICE_CASE_STUDY_ICON:
            imageSrc = require("../assets/images/my-assessments/practice_case_study_icon.png")
            break;
        case ImageConstants.GRAY_QUESTION_MARK_ICON:
            imageSrc = require("../assets/images/gray_question_mark_icon.png")
            break;
        case ImageConstants.GRAY_CLOCK_ICON:
            imageSrc = require("../assets/images/gray_clock_icon.png")
            break;
        case ImageConstants.MCQ_ICON:
            imageSrc = require("../assets/images/mcq_icon.png")
            break;
        case ImageConstants.CASE_STUDY_ICON:
            imageSrc = require("../assets/images/case_study_icon.png")
            break;
        case ImageConstants.SRQ_ICON:
            imageSrc = require("../assets/images/srq_icon.png")
            break;
        case ImageConstants.MOCK_TEST:
            imageSrc = require("../assets/images/mock_test_icon.png")
            break;
        case ImageConstants.PRACTICE_ICON:
            imageSrc = require("../assets/images/practice_icon.png")
            break;
        case ImageConstants.EXAM_PREP_ICON:
            imageSrc = require("../assets/images/exam_prep_icon.png")
            break;
        case ImageConstants.GREEN_BG_TICK_MARK:
            imageSrc = require("../assets/images/green-bg-tick-mark.png")
            break;
        case ImageConstants.RED_BG_CROSS_MARK:
            imageSrc = require("../assets/images/red-bg-cross-mark.png")
            break;
        case ImageConstants.WORKSHEET_ACTIVE:
            imageSrc = require("../assets/images/worksheet_active.png")
            break;
        case ImageConstants.WORKSHEET_INACTIVE:
            imageSrc = require("../assets/images/worksheet_inactive.png")
            break;
        case ImageConstants.ASSESSMENTS_ACTIVE:
            imageSrc = require("../assets/images/assessments_active.png")
            break;
        case ImageConstants.ASSESSMENTS_INACTIVE:
            imageSrc = require("../assets/images/assessments_inactive.png")
            break;
        case ImageConstants.PRACTICE_ACTIVE:
            imageSrc = require("../assets/images/practice-active.png")
            break;
        case ImageConstants.PRACTICE_INACTIVE:
            imageSrc = require("../assets/images/practice_inactive.png")
            break;
        case ImageConstants.VIDEO_LIBRARY_ACTIVE:
            imageSrc = require("../assets/images/video_library_active.png")
            break;
        case ImageConstants.VIDEO_LIBRARY_INACTIVE:
            imageSrc = require("../assets/images/video_library_inactive.png")
            break;
        case ImageConstants.BROWSE_CHAPTER_ACTIVE:
            imageSrc = require("../assets/images/browse_chapter_active.png")
            break;
        case ImageConstants.BROWSE_CHAPTER_INACTIVE:
            imageSrc = require("../assets/images/browse_chapter_inactive.png")
            break;
        case ImageConstants.NO_WORKSHEET_IMAGE:
            imageSrc = require("../assets/images/no_worksheet_image.png")
            break;
        case ImageConstants.DOWNLOAD_ICON_NEW:
            imageSrc = require("../assets/images/download_icon_new.png")
            break;
        case ImageConstants.INACTIVE_LOCK_ICON:
            imageSrc = require("../assets/images/inactive_lock_icon.png")
            break;
        case ImageConstants.ACTIVE_LOCK_ICON:
            imageSrc = require("../assets/images/active_lock_icon.png")
            break;
        case ImageConstants.LC_BLUR:
            imageSrc = require("../assets/images/learning-content-blur.png")
            break;
        case ImageConstants.TROPHY:
            imageSrc = require("../assets/images/trophy.png")
            break;
        case ImageConstants.GRAY_UP_ARROW:
            imageSrc = require("../assets/images/gray-up-arrow.png")
            break;
        case ImageConstants.GRAY_DOWN_ARROW:
            imageSrc = require("../assets/images/gray-down-arrow.png")
            break;
        case ImageConstants.LOCK:
            imageSrc = require("../assets/images/lock.png")
            break;
        case ImageConstants.CHAPTER_LOCK:
            imageSrc = require("../assets/images/chapter-lock.png")
            break;
        case ImageConstants.YEELOW_BULLET:
            imageSrc = require("../assets/images/yellow-bullet.png")
            break;
        case ImageConstants.GREEN_BULLET:
            imageSrc = require("../assets/images/green-bullet.png")
            break;
        case ImageConstants.LIGHT_GREEN_BULLET:
            imageSrc = require("../assets/images/light-green-bullet.png")
            break;
        case ImageConstants.ORANGE_BULLET:
            imageSrc = require("../assets/images/orange-bullet.png")
            break;
        case ImageConstants.BLUE_BULLET:
            imageSrc = require("../assets/images/blue-bullet.png")
            break;
        case ImageConstants.RED_BULLET:
            imageSrc = require("../assets/images/red-bullet.png")
            break;
        case ImageConstants.INDIGO_BULLET:
            imageSrc = require("../assets/images/indigo-bullet.png")
            break;

        case ImageConstants.SELECTED_RADIO_IMAGE:
            imageSrc = require("../assets/images/selected-radio-image.png")
            break;
        case ImageConstants.CIRCLE_UP_ARROW:
            imageSrc = require("../assets/images/circle-up-arrow.png")
            break;
        case ImageConstants.CIRCLE_DOWN_ARROW:
            imageSrc = require("../assets/images/circle-down-arrow.png")
            break;
        case ImageConstants.VIDEO_POSTER:
            imageSrc = require("../assets/images/video-poster.png")
            break;
        case ImageConstants.PLAY_ICON_ORANGE:
            imageSrc = require("../assets/images/play-icon-orange.png")
            break;
        case ImageConstants.FILTER_ICON_NEW:
            imageSrc = require("../assets/images/filter-icon.png")
            break;
        case ImageConstants.FILTER_ICON_ACTIVE:
            imageSrc = require("../assets/images/filter-active.png")
            break;
        case ImageConstants.ERROR_NOTES_NEW:
            imageSrc = require("../assets/images/error-notes-new.png")
            break;
        case ImageConstants.CONTENT_DIVIDER_GRAY:
            imageSrc = require("../assets/images/content-divider-gray.png")
            break;
        case ImageConstants.SUCCESS_NOTES_NEW:
            imageSrc = require("../assets/images/success-note-new.png")
            break;
        case ImageConstants.NAV_PREV_KNOWLEDGE:
            imageSrc = require("../assets/images/nav-prev-kg.png")
            break;
        case ImageConstants.PLUS_ICON:
            imageSrc = require("../assets/images/plus-icon.png")
            break;
        case ImageConstants.RELOAD:
            imageSrc = require("../assets/images/reload.png")
            break;
        case ImageConstants.LOADING_IMG_GIF:
            imageSrc = require("../assets/images/loading-img-gif.png")
            break;
        case ImageConstants.LOADING_IMG_LOGO:
            imageSrc = require("../assets/images/loading-img-logo.png")
            break;
        case ImageConstants.USER_NAME_ICON:
            imageSrc = require("../assets/images/user-name-icon.png")
            break;
        case ImageConstants.SUCCESS_NOTES:
            imageSrc = require("../assets/images/success-notes.png")
            break;
        case ImageConstants.ERROR_NOTES:
            imageSrc = require("../assets/images/error-notes.png")
            break;
        case ImageConstants.PRACTICE:
            imageSrc = require("../assets/images/practice-new.png")
            break;
        case ImageConstants.SOLVED_EXAMPLES:
            imageSrc = require("../assets/images/solved-examples.png")
            break;
        case ImageConstants.SUMMARY_INNOVATION:
            imageSrc = require("../assets/images/innovation-new.png")
            break;
        case ImageConstants.SUMMARY_YELLOW_TICK:
            imageSrc = require("../assets/images/yellow-tick.png")
            break;
        case ImageConstants.SUMMARY_BLUE_TICK:
            imageSrc = require("../assets/images/blue-tick.png")
            break;
        case ImageConstants.SUMMARY_RED_TICK:
            imageSrc = require("../assets/images/red-tick.png")
            break;
        case ImageConstants.SUMMARY_GREEN_TICK:
            imageSrc = require("../assets/images/summary-green-tick.png")
            break;
        case ImageConstants.INTRODUCTION:
            imageSrc = require("../assets/images/introduction-image.png")
            break;
        case ImageConstants.THINGS_TO_REMEMBER:
            imageSrc = require("../assets/images/things-to-remember.png")
            break;
        case ImageConstants.WHITE_TICK_ICON_NEW:
            imageSrc = require("../assets/images/white-tick-icon-new.png")
            break;
        case ImageConstants.WHITE_CROSS_ICON:
            imageSrc = require("../assets/images/white-cross-icon.png")
            break;

        case ImageConstants.SCHOOL_ASSIGNMENT_ACTIVE_ICON:
            imageSrc = require("../assets/images/school-assignment-active-icon.png")
            break;
        case ImageConstants.SCHOOL_ASSIGNMENT_IN_ACTIVE_ICON:
            imageSrc = require("../assets/images/school-assignment-in-active-icon.png")
            break;
        case ImageConstants.PRACTICE_ACTIVE_ICON:
            imageSrc = require("../assets/images/practice-active-icon.png")
            break;
        case ImageConstants.PRACTICE_IN_ACTIVE_ICON:
            imageSrc = require("../assets/images/practice-in-active-icon.png")
            break;
        case ImageConstants.EVENTS_MENU_ACTIVE_ICON:
            imageSrc = require("../assets/images/events-menu-active-icon.png")
            break;
        case ImageConstants.EVENTS_MENU_IN_ACTIVE_ICON:
            imageSrc = require("../assets/images/events-menu-in-active-icon.png")
            break;
        case ImageConstants.CERTIFICATE_ACTIVE_ICON:
            imageSrc = require("../assets/images/certificate-active-icon.png")
            break;
        case ImageConstants.CERTIFICATE_IN_ACTIVE_ICON:
            imageSrc = require("../assets/images/certificate-in-active-icon.png")
            break;

        case ImageConstants.RIGHT_ARROW_ORGANGE:
            imageSrc = require("../assets/images/right-arrow-orange.png")
            break;
        case ImageConstants.LEFT_ARROW_ORGANGE:
            imageSrc = require("../assets/images/left-arrow-orange.png")
            break;

        case ImageConstants.DARK_GREEN_ICON:
            imageSrc = require("../assets/images/dark-green-icon.png")
            break;

        case ImageConstants.GREEN_TICK_ICON_NEW:
            imageSrc = require("../assets/images/green-tick-icon-new.png")
            break;
        case ImageConstants.WHITE_TICK_ICON:
            imageSrc = require("../assets/images/white-tick-icon.png")
            break;
        case ImageConstants.CLOSE_ICON_NEW:
            imageSrc = require("../assets/images/close-icon-new.png")
            break;
        case ImageConstants.SUCCESS_BG_STARS:
            imageSrc = require("../assets/images/success-bg-stars.png")
            break;
        case ImageConstants.GREEN_HEADER:
            imageSrc = require("../assets/images/green-header.png")
            break;
        case ImageConstants.GREEN_FOOTER:
            imageSrc = require("../assets/images/green-footer.png")
            break;
        case ImageConstants.RED_WRONG_ICON:
            imageSrc = require("../assets/images/red-wrong-icon.png")
            break;
        case ImageConstants.RED_CLOSE_ICON_NEW:
            imageSrc = require("../assets/images/red-close-icon-new.png")
            break;
        case ImageConstants.RED_HEADER:
            imageSrc = require("../assets/images/red-header.png")
            break;
        case ImageConstants.RED_FOOTER:
            imageSrc = require("../assets/images/red-footer.png")
            break;

        case ImageConstants.RED_CLOSE_ICON_WITH_HAND:
            imageSrc = require("../assets/images/red-close-icon-with-hand.png")
            break;
        case ImageConstants.GREEN_TICK_ICON_WITH_HAND:
            imageSrc = require("../assets/images/green-tick-icon-with-hand.png")
            break;
        case ImageConstants.HAND_ICON:
            imageSrc = require("../assets/images/hand-icon.png")
            break;

        case ImageConstants.ASSESSMENT_FOOTER_IMAGE:
            imageSrc = require("../assets/images/assessment-footer-image.png")
            break;



        case ImageConstants.BLUE_INFO_ICON:
            imageSrc = require("../assets/images/blue-info-icon.png")
            break;
        case ImageConstants.BLUE_ARROW_ICON:
            imageSrc = require("../assets/images/blue-arrow-icon.png")
            break;
        case ImageConstants.WHITE_STAR_ICON:
            imageSrc = require("../assets/images/white-star-icon.png")
            break;
        case ImageConstants.BLUE_EXAM_PAD_ICON:
            imageSrc = require("../assets/images/blue-exam-pad-icon.png")
            break;
        case ImageConstants.CUP_ICON:
            imageSrc = require("../assets/images/cup-icon.png")
            break;
        case ImageConstants.EXPAND:
            imageSrc = require("../assets/images/expand.png")
            break;
        case ImageConstants.LOGOUT_ICON_RED:
            imageSrc = require("../assets/images/logout-icon-red.png")
            break;
        case ImageConstants.FEEDBACK_LEFT:
            imageSrc = require("../assets/images/feedback-left.png")
            break;
        case ImageConstants.FEEDBACK_RIGHT:
            imageSrc = require("../assets/images/feedback-right.png")
            break;
        case ImageConstants.FEEDBACK_STARS:
            imageSrc = require("../assets/images/feedback-stars.png")
            break;
        case ImageConstants.YES_ICON:
            imageSrc = require("../assets/images/yes-icon.png")
            break;
        case ImageConstants.NO_ICON:
            imageSrc = require("../assets/images/no-icon.png")
            break;
        case ImageConstants.DEFINITION:
            imageSrc = require("../assets/images/theme-images/purple/definition-new.png")
            break;
        case ImageConstants.STATEMENT:
            imageSrc = require("../assets/images/theme-images/purple/statement-new.png")
            break;
        case ImageConstants.PROPERTIES:
            imageSrc = require("../assets/images/theme-images/purple/property-new.png")
            break;
        case ImageConstants.FORMULA:
            imageSrc = require("../assets/images/theme-images/purple/formula-new.png")
            break;

        case ImageConstants.BASIC_USER_BANNER_TWO:
            imageSrc = require("../assets/images/basic_user_two.png")
            break;
        case ImageConstants.INFO_ICON_RED:
            imageSrc = require("../assets/images/profile/info-icon-red.png")
            break;
        case ImageConstants.BASIC_USER_BANNER:
            imageSrc = require("../assets/images/basic_user_banner.png")
            break;
        case ImageConstants.PREMIUM_USER_BANNER:
            imageSrc = require("../assets/images/premium_user_banner.png")
            break;
        case ImageConstants.PREMIUM_USER_BANNER_START_ASSESSMENT:
            imageSrc = require("../assets/images/start_assessment.png")
            break;
        case ImageConstants.MOBILE_APP_ICON:
            imageSrc = require("../assets/images/mobile-app-icon.png")
            break;
        case ImageConstants.APP_STORE_ICON:
            imageSrc = require("../assets/images/app-store-icon.png")
            break;
        case ImageConstants.PLAY_STORE_ICON:
            imageSrc = require("../assets/images/play-store-icon.png")
            break;
        case ImageConstants.EVENT_DISABLED:
            imageSrc = require("../assets/images/events/event-disabled.png")
            break;
        case ImageConstants.INFO_RED_1:
            imageSrc = require("../assets/images/info-red-1.png")
            break;
        case ImageConstants.ACTIVE_RADIO_BUTTON:
            imageSrc = require("../assets/images/active-radio-button.png")
            break;

        case ImageConstants.PEN_EDIT_ICON:
            imageSrc = require("../assets/images/pen-edit-icon.png")
            break;

        case ImageConstants.STUDENT_IMAGE:
            imageSrc = require("../assets/images/student-image.png")
            break;

        case ImageConstants.EVENT_CARD_BG_IMAGE:
            imageSrc = require("../assets/images/events/event-card-bg-image.png")
            break;
        case ImageConstants.GREEN_TICK_ICON:
            imageSrc = require("../assets/images/my-classwork/green-tick-icon.png")
            break;
        case ImageConstants.FILLTER_HEADER:
            imageSrc = require("../assets/images/my-classwork/filter-header.png")
            break;
        case ImageConstants.FILLTER_FOOTER:
            imageSrc = require("../assets/images/my-classwork/filter-footer.png")
            break;

        case ImageConstants.FILLTER_ACTIVE:
            imageSrc = require("../assets/images/filter-active.png")
            break;

        case ImageConstants.PRACTICE_CROSS_LINK:
            imageSrc = require("../assets/images/events/practice-cross-link.png")
            break;

        case ImageConstants.EVENT_CARD_SUCCESS_BG:
            imageSrc = require("../assets/images/events/event-card-success-bg.png")
            break;

        case ImageConstants.MISSED_EVENT_FLAG:
            imageSrc = require("../assets/images/events/missed-event-flag.png")
            break;

        case ImageConstants.PAID_LANDING_SCREEN_FOOTER:
            imageSrc = require("../assets/images/events/paid-landing-screen-footer.png")
            break;

        case ImageConstants.TICK_BUTTON:
            imageSrc = require("../assets/images/events/tick-button.png")
            break;

        case ImageConstants.FAILED_SKILL_HEADER:
            imageSrc = require("../assets/images/events/failed-skill-header.png")
            break;
        case ImageConstants.PERFORMANCE_CARD_HEADER:
            imageSrc = require("../assets/images/events/performance-card-header.png")
            break;
        case ImageConstants.WHITE_DOWNLOAD_ICON:
            imageSrc = require("../assets/images/events/white-download-icon.png")
            break;

        case ImageConstants.ASSESSMENT_REPORT_MARKS_CARD_BG:
            imageSrc = require("../assets/images/events/assessment-report-marks-card-bg.png")
            break;

        case ImageConstants.PERFORMANCE_BG:
            imageSrc = require("../assets/images/events/performance-bg.png")
            break;

        case ImageConstants.YELLOW_BULB_IMAGE:
            imageSrc = require("../assets/images/events/yellow-bulb-image.png")
            break;

        case ImageConstants.BROWSE_CHAPTER_IMAGE:
            imageSrc = require("../assets/images/events/browse-chapter-image.png")
            break;

        case ImageConstants.NOTES_IMAGE:
            imageSrc = require("../assets/images/events/notes-image.png")
            break;


        case ImageConstants.EVENT_ASSESSMENT_END_SCREEN_BG:
            imageSrc = require("../assets/images/events/event-assessment-end-screen-bg.png")
            break;

        case ImageConstants.SHARE_ICON:
            imageSrc = require("../assets/images/events/share-icon.png")
            break;

        case ImageConstants.PAID_EVENT_FLAG:
            imageSrc = require("../assets/images/events/paid-event-flag.png")
            break;
        case ImageConstants.CREDIT_CARD_IMAGE:
            imageSrc = require("../assets/images/events/credit-card-image.png")
            break;
        case ImageConstants.KNOW_MORE_HEADER:
            imageSrc = require("../assets/images/events/know-more-header.png")
            break;

        case ImageConstants.KNOW_MORE_FOOTER:
            imageSrc = require("../assets/images/events/know-more-footer.png")
            break;


        case ImageConstants.WHITE_QUESTION_MARK:
            imageSrc = require("../assets/images/events/white-question-mark.png")
            break;

        case ImageConstants.RAMANUJAN_CIRCLE_LOGO:
            imageSrc = require("../assets/images/events/ramanujan-logo.png")
            break;

        case ImageConstants.ACTIVE_EVENTS_ICON:
            imageSrc = require("../assets/images/events-active-icon.png")
            break;
        case ImageConstants.EVENT_CUP_ICON:
            imageSrc = require("../assets/images/events/event-cup-icon.png")
            break;
        case ImageConstants.PAPER_ICON:
            imageSrc = require("../assets/images/courses/paper-icon.png")
            break;

        case ImageConstants.ELLIPSE_ICON:
            imageSrc = require("../assets/images/my-homework/ellipse-icon.png")
            break;
        case ImageConstants.EVENTS_POLYCON_ICON:
            imageSrc = require("../assets/images/events/events-polygon-icon.png")
            break;
        case ImageConstants.COURSES_ELLIPSE_ICON:
            imageSrc = require("../assets/images/courses/courses-ellipse-icon.png")
            break;

        case ImageConstants.POLYCON_ICON:
            imageSrc = require("../assets/images/my-classwork/polycon-icon.png")
            break;

        case ImageConstants.HEADER_BANNER_IMAGE:
            imageSrc = require("../assets/images/header-banner-image.png")
            break;

        case ImageConstants.HOME_SCREEN_FOOTER:
            imageSrc = require("../assets/images/home-screen-footer.png")
            break;

        case ImageConstants.CHAPTERS_LIST_IMAGE:
            imageSrc = require("../assets/images/chapters-list-image.png")
            break;

        case ImageConstants.FUN_ZONE_IMAGE:
            imageSrc = require("../assets/images/fun-zone-image.png")
            break;

        case ImageConstants.ACTIVE_ROUTE_IMAGE:
            imageSrc = require("../assets/images/active-route-image.png")
            break;

        case ImageConstants.HOME_ICON:
            imageSrc = require("../assets/images/home-icon.png")
            break;
        case ImageConstants.HOME_ECLIPSE:
            imageSrc = require("../assets/images/home-eclipse.png")
            break;
        case ImageConstants.HOMEWORK_BOOK_ICON:
            imageSrc = require("../assets/images/homework-book-icon.png")
            break;

        case ImageConstants.ACTIVE_HOMEWORK_ICON:
            imageSrc = require("../assets/images/active-homework-icon.png")
            break;

        case ImageConstants.EVENTS_CARD_IMAGE:
            imageSrc = require("../assets/images/events-card-image.png")
            break;
        case ImageConstants.COURSES_CARD_IMAGE:
            imageSrc = require("../assets/images/courses-card-image.png")
            break;




        case ImageConstants.NO_CLASSWORK_IMAGE:
            imageSrc = require("../assets/images/my-classwork/no-classwork-image.png")
            break;

        case ImageConstants.LEARNING_CONTENT_SEPARATOR:
            imageSrc = require("../assets/images/my-assessments/learning-content-separator.png")
            break;

        case ImageConstants.FLAG_ICON:
            imageSrc = require("../assets/images/my-assessments/flag-icon.png")
            break;

        case ImageConstants.CORRECT_ANSWER_ICON:
            imageSrc = require("../assets/images/my-assessments/correct-answer-icon.png")
            break;
        case ImageConstants.INCORRECT_ANSWER_ICON:
            imageSrc = require("../assets/images/my-assessments/incorrect-icon.png")
            break;

        case ImageConstants.DIVIDER_GRAY:
            imageSrc = require("../assets/images/my-classwork/divider-gray.png")
            break;

        case ImageConstants.SUCCESS_ICON:
            imageSrc = require("../assets/images/my-classwork/success-icon.png")
            break;

        case ImageConstants.CLASSWORK_COMPLETION_SCREEN_BG:
            imageSrc = require("../assets/images/my-classwork/classwork-completion-screen-bg.png")
            break;

        case ImageConstants.LOCK_ICON:
            imageSrc = require("../assets/images/my-classwork/lock-icon.png")
            break;

        case ImageConstants.YELLOW_TICK:
            imageSrc = require("../assets/images/summary-solution/yellow-tick-mark.png")
            break;
        case ImageConstants.RED_TICK:
            imageSrc = require("../assets/images/summary-solution/red-tick-mark.png")
            break;
        case ImageConstants.GREEN_TICK_V3:
            imageSrc = require("../assets/images/summary-solution/green-tick-mark.png")
            break;
        case ImageConstants.BLUE_TICK:
            imageSrc = require("../assets/images/summary-solution/blue-tick-mark.png")
            break;

        case ImageConstants.INVALID_ANSWER:
            imageSrc = require("../assets/images/my-classwork/invalid-answer.png")
            break;
        case ImageConstants.RED_INFO_ICON:
            imageSrc = require("../assets/images/my-classwork/red-info-icon.png")
            break;


        case ImageConstants.CORRECT_ANSWER_TICK_MARK:
            imageSrc = require("../assets/images/my-classwork/correct-answer-tick-mark.png")
            break;
        case ImageConstants.WRONG_ANSWER_CROSS_MARK:
            imageSrc = require("../assets/images/my-classwork/wrong-answer-cross-mark.png")
            break;

        case ImageConstants.QUESTION_MARK_ICON:
            imageSrc = require("../assets/images/my-classwork/question-mark-icon.png")
            break;
        case ImageConstants.DATE_ICON:
            imageSrc = require("../assets/images/my-classwork/date-icon.png")
            break;

        case ImageConstants.FILLTER_ICON:
            imageSrc = require("../assets/images/my-classwork/filter-icon.png")
            break;

        case ImageConstants.RED_RIPPLE_ICON:
            imageSrc = require("../assets/images/my-classwork/red-ripple-icon.png")
            break;


        case ImageConstants.EXAM_PAD_ICON_GRAY:
            imageSrc = require("../assets/images/my-assessments/exam-pad-icon-gray.png")
            break;
        case ImageConstants.CLOCK_ICON_GRAY:
            imageSrc = require("../assets/images/my-assessments/clock-icon-gray.png")
            break;
        case ImageConstants.ASSESSMENT_END_SCREEN_BG:
            imageSrc = require("../assets/images/my-assessments/assessment-end-screen-bg.png")
            break;
        case ImageConstants.TIMEUP_IMAGE:
            imageSrc = require("../assets/images/my-assessments/timeup-image.png")
            break;
        case ImageConstants.REVIEW_ANSWERS_IMAGE:
            imageSrc = require("../assets/images/my-assessments/review-answers-image.png")
            break;
        case ImageConstants.ARROW:
            imageSrc = require("../assets/images/my-assessments/arrow.png")
            break;
        case ImageConstants.DROPDOWN_ARROW_BLACK:
            imageSrc = require("../assets/images/my-assessments/dropdown-arrow.png")
            break;
        case ImageConstants.FLAG_ENABLED:
            imageSrc = require("../assets/images/my-assessments/flag-enabled.png")
            break;
        case ImageConstants.FLAG_DISABLED:
            imageSrc = require("../assets/images/my-assessments/flag-disabled.png")
            break;
        case ImageConstants.MATCHING_QUESTION_DIVIDER:
            imageSrc = require("../assets/images/my-assessments/matching-question-divider.png")
            break;


        case ImageConstants.INFO_ICON:
            imageSrc = require("../assets/images/signup-screen/info-icon.png")
            break;
        case ImageConstants.RIGHT_ARROW_ENABLED:
            imageSrc = require("../assets/images/my-assessments/right-arrow-enabled.png")
            break;
        case ImageConstants.RIGHT_ARROW_DISABLED:
            imageSrc = require("../assets/images/my-assessments/right-arrow-disabled.png")
            break;
        case ImageConstants.LEFT_ARROW_ENABLED:
            imageSrc = require("../assets/images/my-assessments/left-arrow-enabled.png")
            break;
        case ImageConstants.LEFT_ARROW_DISABLED:
            imageSrc = require("../assets/images/my-assessments/left-arrow-disabled.png")
            break;

        case ImageConstants.HEADER_BACKGROUND:
            imageSrc = require("../assets/images/my-assessments/header-background.png")
            break;

        case ImageConstants.TIMER_ICON:
            imageSrc = require("../assets/images/my-assessments/timer-icon.png")
            break;

        case ImageConstants.NEW_FOOTER:
            imageSrc = require("../assets/images/my-assessments/new-footer.png")
            break;

        case ImageConstants.BULB_IMAGE:
            imageSrc = require("../assets/images/my-assessments/bulb.png")
            break;

        case ImageConstants.VERTICAL_LINE:
            imageSrc = require("../assets/images/my-assessments/vertical-line.png")
            break;
        case ImageConstants.CLOCK_IMAGE:
            imageSrc = require("../assets/images/my-assessments/clock-image.png")
            break;
        case ImageConstants.EXAM_PAD_ICON_BLOCK:
            imageSrc = require("../assets/images/my-assessments/exam-pad-image.png")
            break;
        case ImageConstants.QUESTION_MARK:
            imageSrc = require("../assets/images/my-assessments/question-mark-image.png")
            break;

        case ImageConstants.MALE_WITH_DESKTOP_ICON:
            imageSrc = require("../assets/images/my-assessments/male-with-desktop-icon.png")
            break;

        case ImageConstants.CHECKBOX_ACTIVE:
            imageSrc = require("../assets/images/my-assessments/checkbox-active.png")
            break;

        case ImageConstants.CHECKBOX_INACTIVE:
            imageSrc = require("../assets/images/my-assessments/checkbox-inactive.png")
            break;

        case ImageConstants.ROCKET:
            imageSrc = require("../assets/images/daily-goal-bg/rocket.png")
            break;

        case ImageConstants.DAILY_GOAL_BG:
            imageSrc = require("../assets/images/daily-goal-bg/daily-goal-bg.png")
            break;

        case ImageConstants.NOVICE:
            imageSrc = require("../assets/images/select-level/novice.png")
            break;

        case ImageConstants.INTERMEDIATE:
            imageSrc = require("../assets/images/select-level/intermediate.png")
            break;
        case ImageConstants.ADVANCED:
            imageSrc = require("../assets/images/select-level/advanced.png")
            break;

        case ImageConstants.MORNING:
            imageSrc = require("../assets/images/select-time/morning.png")
            break;
        case ImageConstants.MORNING_ACTIVE:
            imageSrc = require("../assets/images/select-time/morning-active.png")
            break;
        case ImageConstants.EVENING:
            imageSrc = require("../assets/images/select-time/evening.png")
            break;
        case ImageConstants.EVENING_ACTIVE:
            imageSrc = require("../assets/images/select-time/evening-active.png")
            break;
        case ImageConstants.NIGHT:
            imageSrc = require("../assets/images/select-time/night.png")
            break;
        case ImageConstants.NIGHT_ACTIVE:
            imageSrc = require("../assets/images/select-time/night-active.png")
            break;

        case ImageConstants.FIVE_DAYS_ACTIVE:
            imageSrc = require("../assets/images/select-no-days/five-days-active.png")
            break;
        case ImageConstants.FIVE_DAYS:
            imageSrc = require("../assets/images/select-no-days/five-days.png")
            break;
        case ImageConstants.THREE_DAYS_ACTIVE:
            imageSrc = require("../assets/images/select-no-days/three-days-active.png")
            break;
        case ImageConstants.THREE_DAYS:
            imageSrc = require("../assets/images/select-no-days/three-days.png")
            break;
        case ImageConstants.TWO_DAYS_ACTIVE:
            imageSrc = require("../assets/images/select-no-days/two-days-active.png")
            break;
        case ImageConstants.TWO_DAYS:
            imageSrc = require("../assets/images/select-no-days/two-days.png")
            break;

        case ImageConstants.MISS_ACTIVE:
            imageSrc = require("../assets/images/signup-screen/miss_active.png")
            break;
        case ImageConstants.MISS_INACTIVE:
            imageSrc = require("../assets/images/signup-screen/miss_inactive.png")
            break;
        case ImageConstants.MASTER_ACTIVE:
            imageSrc = require("../assets/images/signup-screen/master_active.png")
            break;


        case ImageConstants.MASTER_INACTIVE:
            imageSrc = require("../assets/images/signup-screen/master_inactive.png")
            break;


        case ImageConstants.QUOTE_UP:
            imageSrc = require("../assets/images/welcome-screen/quote-up.png")
            break;

        case ImageConstants.QUOTE_DOWN:
            imageSrc = require("../assets/images/welcome-screen/quote-down.png")
            break;


        case ImageConstants.ACTIVE_STUDENTS:
            imageSrc = require("../assets/images/welcome-screen/active-students.png")
            break;
        case ImageConstants.VIDEO_PLAY_ICON:
            imageSrc = require("../assets/images/welcome-screen/video-play-icon.png")
            break;
        case ImageConstants.EXAM_PAD_ICON:
            imageSrc = require("../assets/images/welcome-screen/exam-pad-icon.png")
            break;



        case ImageConstants.VIDEO_POSTER:
            imageSrc = require("../assets/images/video-poster/video-poster-image.png")
            break;

        case ImageConstants.BADGE_IMAGE:
            imageSrc = require("../assets/images/confirmation-modal/badge-image.png")
            break;

        case ImageConstants.DAILY_TIME_LIMIT_IMAGE:
            imageSrc = require("../assets/images/confirmation-modal/daily-time-limit-image.png")
            break;
        case ImageConstants.DOUBTS:
            imageSrc = require("../assets/images/confirmation-modal/doubts.png")
            break;

        case ImageConstants.CONFIRMATION_MODAL_HEADER:
            imageSrc = require("../assets/images/confirmation-modal/confirmation-modal-header.png")
            break;

        case ImageConstants.CONFIRMATION_MODAL_FOOTER:
            imageSrc = require("../assets/images/confirmation-modal/confirmation-modal-footer.png")
            break;
        case ImageConstants.BACK_ARROW:
            imageSrc = require("../assets/images/login-screen/back-arrow.png")
            break;
        case ImageConstants.GREEN_TICK_MARK:
            imageSrc = require("../assets/images/profile/green-tick-mark.png")
            break;
        case ImageConstants.PROFILE_PICTURE:
            imageSrc = require("../assets/images/profile/profile-picture.png")
            break;

        case ImageConstants.COUNTRY_CLOSE_ICON:
            imageSrc = require("../assets/images/country-code-dropdown/close-icon.png")
            break;

        case ImageConstants.COUNTRY_CODE_SEARCH:
            imageSrc = require("../assets/images/country-code-dropdown/country-code-search-icon.png")
            break;

        case ImageConstants.COUNTRY_DROPDOWN_HEADER:
            imageSrc = require("../assets/images/country-code-dropdown/country-dropdown-header.png")
            break;
        case ImageConstants.COUNTRY_DROPDOWN_FOOTER:
            imageSrc = require("../assets/images/country-code-dropdown/country-dropdown-footer.png")
            break;

        case ImageConstants.LOGIN_HEADER:
            imageSrc = require("../assets/images/login-screen/login-header.png")
            break;
        case ImageConstants.LOGIN_FOOTER:
            imageSrc = require("../assets/images/login-screen/login-footer.png")
            break;
        case ImageConstants.COUNTINGWELL_LOGO:
            imageSrc = require("../assets/images/login-screen/countingwell-logo.png")
            break;
        case ImageConstants.FEMALE_WORKOUT_IMAGE:
            imageSrc = require("../assets/images/login-screen/female-workout-image.png")
            break;

        case ImageConstants.DOWN_ARROW:
            imageSrc = require("../assets/images/login-screen/down-arrow.png")
            break;

        case ImageConstants.COUNTRY_SEPARATOR:
            imageSrc = require("../assets/images/login-screen/separator.png")
            break;

        case ImageConstants.EDIT_ICON_FOR_LOGIN:
            imageSrc = require("../assets/images/edit-icon/edit-icon.png")
            break;




        case ImageConstants.BOOKMARK_ACTIVE:
            imageSrc = require("../assets/images/book-mark-active/bookmark-active@3x.png")
            break;
        case ImageConstants.WHITE_TICK:
            imageSrc = require("../assets/images/white-tick/white-tick.png")
            break;
        case ImageConstants.RIGHT_ARROW_BLUE:
            imageSrc = require("../assets/images/right-arrow-blue.png")
            break;
        case ImageConstants.CHALLENGE_POPUP_FOOTER:
            imageSrc = require("../assets/images/challenge-popup-footer.png")
            break;
        case ImageConstants.CHALLENGE_POPUP_HEADER:
            imageSrc = require("../assets/images/challenge-popup-header.png")
            break;
        case ImageConstants.RIGHT_DARK_ARROW:
            imageSrc = require("../assets/images/right-dark-arrow.png")
            break;
        case ImageConstants.TAKE_CHALLENGE_IMAGE:
            imageSrc = require("../assets/images/take-challenge-image.png")
            break;
        case ImageConstants.POPUP_HEADER_IMAGE:
            imageSrc = require("../assets/images/popup-header-image.png")
            break;
        case ImageConstants.QUESTION_DIVIDER:
            imageSrc = require("../assets/images/question-divider.png")
            break;

        case ImageConstants.HEADER_BG_IMAGE:
            imageSrc = require("../assets/images/header-bg-image.png")
            break;

        case ImageConstants.BOOKMARK_ICON:
            imageSrc = require("../assets/images/bookmark-icon.png")
            break;

        case ImageConstants.TITLE_DIVIDER:
            imageSrc = require("../assets/images/title-divider.png")
            break;

        case ImageConstants.CHAPTER_SEARCH_ICON:
            imageSrc = require("../assets/images/chapter-search-icon.png")
            break;

        case ImageConstants.SIDE_MENU_LIST_IMAGE:
            imageSrc = require("../assets/images/side-menu-list-icon.png")
            break;

        case ImageConstants.TICK_WHITE:
            imageSrc = require("../assets/images/tick-white.png")
            break;
        case ImageConstants.NEW_ASSESSMENT_BANNER:
            imageSrc = require("../assets/images/new-assessment-banner.png")
            break;
        case ImageConstants.TREE_ICN:
            imageSrc = require("../assets/images/tree-icn.png")
            break;
        case ImageConstants.PDF_ICON:
            imageSrc = require("../assets/images/pdf-icon.png")
            break;
        case ImageConstants.GRID_ICN:
            imageSrc = require("../assets/images/grid-icn.png")
            break;
        case ImageConstants.STEM_QUESTION_EXAMPLE:
            imageSrc = require("../assets/images/stem-question-sample.png")
            break;
        case ImageConstants.TREE_LINE_ICN_BLACK:
            imageSrc = require("../assets/images/tree-line-icn-black.png")
            break;
        case ImageConstants.TREE_LINE_SINGLE_ICN_BLACK:
            imageSrc = require("../assets/images/tree-line-single-icn-black.png")
            break;
        case ImageConstants.TREE_LINE_ICN:
            imageSrc = require("../assets/images/tree-line-icn.png")
            break;
        case ImageConstants.CAMERA:
            imageSrc = require("../assets/images/camera.png")
            break;
        case ImageConstants.SKILL_LOW_ICN:
            imageSrc = require("../assets/images/skill-level-low.png")
            break;
        case ImageConstants.SKILL_MEDIUM_ICN:
            imageSrc = require("../assets/images/skill-level-medium.png")
            break;
        case ImageConstants.SKILL_HIGH_ICN:
            imageSrc = require("../assets/images/skill-level-high.png")
            break;
        case ImageConstants.MARK_ICN:
            imageSrc = require("../assets/images/mark-icn.png")
            break;
        case ImageConstants.CHAPTER_ICN:
            imageSrc = require("../assets/images/chapter-icn.png")
            break;
        case ImageConstants.GIFTBOX:
            imageSrc = require('../assets/images/giftbox.png');
            break;
        case ImageConstants.CLAIM_SCHOLARSHIP_BANNER:
            imageSrc = require('../assets/images/claim-banner.png');
            break;
        case ImageConstants.HOMEWORK_SUCCESS_LEFT:
            imageSrc = require('../assets/images/homework-success-banner.png');
            break;
        case ImageConstants.HOMEWORK_SUCCESS_RIGHT:
            imageSrc = require('../assets/images/homework-success-banner-right.png');
            break;
        case ImageConstants.SUCCESS_GREEN:
            imageSrc = require('../assets/images/success-green.png');
            break;
        case ImageConstants.WEAKNESS_ICN:
            imageSrc = require('../assets/images/weakness-icn.png');
            break;
        case ImageConstants.STRENGTH_ICN:
            imageSrc = require('../assets/images/strength-icn.png');
            break;
        case ImageConstants.SCHOLAR_BLACK:
            imageSrc = require('../assets/images/scholar-black.png');
            break;
        case ImageConstants.SCHOLAR_WHITE:
            imageSrc = require('../assets/images/scholar-white.png');
            break;
        case ImageConstants.NOTIFICATION_BANNER:
            imageSrc = require('../assets/images/notification-banner.png');
            break;
        case ImageConstants.NOTIFICATION_HEADER_BG:
            imageSrc = require('../assets/images/notification-header-bg.png');
            break;
        case ImageConstants.FLUENT_CERTIFICATE:
            imageSrc = require('../assets/images/fluent-certificate.png');
            break;
        case ImageConstants.STUDENT_WINNERS_BG:
            imageSrc = require('../assets/images/imd-student-winners-bg.png');
            break;
        case ImageConstants.PEOPLE:
            imageSrc = require('../assets/images/people.png');
            break;
        case ImageConstants.PLAY_RED:
            imageSrc = require('../assets/images/play-red.png');
            break;
        case ImageConstants.NOTES:
            imageSrc = require('../assets/images/notes.png');
            break;
        case ImageConstants.VIDEO_BANNER:
            imageSrc = require('../assets/images/video-banner.png');
            break;
        case ImageConstants.XLS_BLUE:
            imageSrc = require('../assets/images/xls-blue.png');
            break;
        case ImageConstants.AD_BANNER_3:
            imageSrc = require('../assets/images/countingwell-banner-3.png');
            break;
        case ImageConstants.AD_BANNER_1:
            imageSrc = require('../assets/images/countingwell-banner-1.png');
            break;
        case ImageConstants.COUNTINGWELL_LOGO:
            imageSrc = require('../assets/images/countingwell-logo.png');
            break;
        case ImageConstants.AD_BANNER:
            imageSrc = require('../assets/images/ad-banner.png');
            break;
        case ImageConstants.INDIA_MAP_PIN:
            imageSrc = require('../assets/images/india-map-pin.png');
            break;
        case ImageConstants.INDIA_MAP_ICON:
            imageSrc = require('../assets/images/india-map-icn.png');
            break;
        case ImageConstants.CLONE_WHITE:
            imageSrc = require('../assets/images/clone-white.png');
            break;
        case ImageConstants.CLONE_ICON:
            imageSrc = require('../assets/images/clone-icn.png');
            break;
        case ImageConstants.PENCIL_ICON:
            imageSrc = require('../assets/images/pencil-icn.png');
            break;
        case ImageConstants.SIGNUP_SUCCESS_BANNER:
            imageSrc = require('../assets/images/signup-success-banner.png');
            break;
        case ImageConstants.SIGNUP_LOGO:
            imageSrc = require('../assets/images/signup-logo.png');
            break;
        case ImageConstants.MATHS_DAY_POS:
            imageSrc = require('../assets/images/math-day-pos.png');
            break;
        case ImageConstants.MATHS_DAY_POS_LOGO:
            imageSrc = require('../assets/images/math-day-pos-logo-new.png');
            break;
        case ImageConstants.DOWNLOAD_APP_ICON:
            imageSrc = require('../assets/images/download-app-icn.png');
            break;
        case ImageConstants.IMD_CLOCK_ICON:
            imageSrc = require('../assets/images/imd-clock-icn.png');
            break;
        case ImageConstants.IMD_CALENDAR_ICON:
            imageSrc = require('../assets/images/imd-calendar-icn.png');
            break;
        case ImageConstants.PRE_ASSESMENT_APP_ICON:
            imageSrc = require('../assets/images/pre-assesment-app-icn.png');
            break;
        case ImageConstants.ANDROID_DOWNLOAD:
            imageSrc = require('../assets/images/android-download.png');
            break;
        case ImageConstants.IOS_DOWNLOAD:
            imageSrc = require('../assets/images/ios-download.png');
            break;
        case ImageConstants.DOWN_CARET_DROPDOWN:
            imageSrc = require('../assets/images/down-caret-light.png');
            break;

        case ImageConstants.CW_LOGO:
            imageSrc = require('../assets/images/cw-logo.png');
            break;

        case ImageConstants.LOGO_WHITE:
            imageSrc = require('../assets/images/logo-white.png');
            break;

        case ImageConstants.LOGO_ICON:
            imageSrc = require('../assets/images/logo-icon.png');
            break;

        case ImageConstants.CW_IMAGE:
            imageSrc = require('../assets/images/cw-image.png');
            break;

        case ImageConstants.WELCOME:
            imageSrc = require('../assets/images/welcome.png');
            break;

        case ImageConstants.HEADER_LOGO:
            imageSrc = require('../assets/images/header-logo.png');
            break;

        case ImageConstants.USER_PLACEHOLDER:
            imageSrc = require('../assets/images/user.png');
            break;

        case ImageConstants.LOGOUT:
            imageSrc = require('../assets/images/logout.png');
            break;

        case ImageConstants.REMOVE_CIRCLE:
            imageSrc = require('../assets/images/remove-circle.png');
            break;

        case ImageConstants.RED_REMOVE:
            imageSrc = require('../assets/images/red-remove.png');
            break;

        case ImageConstants.FORGOT_PASSWORD:
            imageSrc = require('../assets/images/forgot-password.png');
            break;

        case ImageConstants.DROPDOWN_ARROW:
            imageSrc = require('../assets/images/dropdown-arrow.png');
            break;

        case ImageConstants.REPORT_BG_1:
            imageSrc = require('../assets/images/report-background1.png');
            break;

        case ImageConstants.REPORT_BG_2:
            imageSrc = require('../assets/images/report-background2.png');
            break;

        case ImageConstants.LOADER_HEADER:
            imageSrc = require('../assets/images/loader-header.png');
            break;

        case ImageConstants.LOADER_FOOTER:
            imageSrc = require('../assets/images/loader-footer.png');
            break;

        case ImageConstants.LOADING_GIF:
            imageSrc = require('../assets/images/loading-gif/loading-gif.gif');
            break;

        case ImageConstants.PRE_LOADER_GIF:
            imageSrc = require('../assets/images/loading-gif/pre-loader-gif.gif');
            break;

        case ImageConstants.PROFILE_VECTOR:
            imageSrc = require('../assets/images/profile-vector.png');
            break;

        case ImageConstants.REPORT_WELCOME_ICON:
            imageSrc = require('../assets/images/teaching-quote.png');
            break;

        case ImageConstants.EXCEL_ICON:
            imageSrc = require('../assets/images/excel.png');
            break;

        case ImageConstants.CLEAR_ICON:
            imageSrc = require('../assets/images/clear.png');
            break;

        case ImageConstants.HR_LINE:
            imageSrc = require('../assets/images/line.png');
            break;

        case ImageConstants.NOTIFICATION_BELL_ACTIVE:
            imageSrc = require('../assets/images/notification-bell-active.png');
            break;

        case ImageConstants.DASHBOARD_IN_ACTIVE:
            imageSrc = require('../assets/images/dashboard-in-active.png');
            break;

        case ImageConstants.PERFORMANCE_IN_ACTIVE:
            imageSrc = require('../assets/images/performance-in-active.png');
            break;

        case ImageConstants.ADMIN_USER:
            imageSrc = require('../assets/images/admin-user.png');
            break;

        case ImageConstants.CONTACTS:
            imageSrc = require('../assets/images/contact-in-active.png');
            break;

        case ImageConstants.TEACHERS:
            imageSrc = require('../assets/images/teachers-in-active.png');
            break;

        case ImageConstants.CLASSES:
            imageSrc = require('../assets/images/classes-in-active.png');
            break;

        case ImageConstants.SETTINGS:
            imageSrc = require('../assets/images/settings-in-active.png');
            break;

        case ImageConstants.DASHBOARD_ACTIVE:
            imageSrc = require('../assets/images/dashboard-active.png');
            break;

        case ImageConstants.PERFORMANCE_ACTIVE:
            imageSrc = require('../assets/images/performance-in-active.png');
            break;

        case ImageConstants.ADMIN_USER_ACTIVE:
            imageSrc = require('../assets/images/admin-user-active.png');
            break;

        case ImageConstants.CONTACTS_ACTIVE:
            imageSrc = require('../assets/images/contact-active.png');
            break;

        case ImageConstants.CLASSES_ACTIVE:
            imageSrc = require('../assets/images/classes-active.png');
            break;

        case ImageConstants.SETTINGS_ACTIVE:
            imageSrc = require('../assets/images/settings-in-active.png');
            break;

        case ImageConstants.PROFILE_ACTIVE:
            imageSrc = require('../assets/images/profile-active.png');
            break;

        case ImageConstants.ONBOARD_TOP_RIGHT:
            imageSrc = require('../assets/images/graphic-1.png');
            break;

        case ImageConstants.ONBOARD_BOTTOM_RIGHT:
            imageSrc = require('../assets/images/graphic-2.png');
            break;

        case ImageConstants.CROSS:
            imageSrc = require('../assets/images/cross.png');
            break;

        case ImageConstants.CROSS_BLACK:
            imageSrc = require('../assets/images/cross-black.png');
            break;
        case ImageConstants.ADD:
            imageSrc = require('../assets/images/add.png');
            break;
        case ImageConstants.ADD_BLUE:
            imageSrc = require('../assets/images/add_blue.png');
            break;
        case ImageConstants.ADD_GRAY:
            imageSrc = require('../assets/images/add_gray.png');
            break;

        case ImageConstants.EDIT:
            imageSrc = require('../assets/images/edit.png');
            break;

        case ImageConstants.DELETE:
            imageSrc = require('../assets/images/del.png');
            break;

        case ImageConstants.BAR_CHART:
            imageSrc = require('../assets/images/bar-chart.png');
            break;

        case ImageConstants.CORRECT:
            imageSrc = require('../assets/images/correct.png');
            break;

        case ImageConstants.NO_RECORDS:
            imageSrc = require('../assets/images/no-records.png');
            break;

        case ImageConstants.ENVELOPE:
            imageSrc = require('../assets/images/envelope.png');
            break;

        case ImageConstants.MESSAGE:
            imageSrc = require('../assets/images/message.png');
            break;

        case ImageConstants.WHATSAPP:
            imageSrc = require('../assets/images/whatsapp.png');
            break;

        case ImageConstants.ENVELOPE_WHITE:
            imageSrc = require('../assets/images/envelope-white.png');
            break;

        case ImageConstants.MESSAGE_WHITE:
            imageSrc = require('../assets/images/message-white.png');
            break;

        case ImageConstants.WHATSAPP_WHITE:
            imageSrc = require('../assets/images/whatsapp-white.png');
            break;

        case ImageConstants.BACK_ICON:
            imageSrc = require('../assets/images/back-icon.png');
            break;

        case ImageConstants.ACTIVE_TICK:
            imageSrc = require('../assets/images/active-tick.png');
            break;

        case ImageConstants.INACTIVE_TICK:
            imageSrc = require('../assets/images/inactive-tick.png');
            break;

        case ImageConstants.RECT:
            imageSrc = require('../assets/images/rect.png');
            break;

        case ImageConstants.RECT_TICK:
            imageSrc = require('../assets/images/rect-tick.png');
            break;

        case ImageConstants.HOMEWORK_ACTIVE:
            imageSrc = require('../assets/images/homework-active.png');
            break;

        case ImageConstants.HOMEWORK_IN_ACTIVE:
            imageSrc = require('../assets/images/homework-in-active.png');
            break;

        case ImageConstants.STANDARD_HOMEWORK:
            imageSrc = require('../assets/images/standard-homework.png');
            break;

        case ImageConstants.ADAPTIVE_HOMEWORK:
            imageSrc = require('../assets/images/adaptive-homework.png');
            break;

        case ImageConstants.RIGHT_ARROW:
            imageSrc = require('../assets/images/right-arrow.png');
            break;

        case ImageConstants.COMPLETION_REPORT:
            imageSrc = require('../assets/images/completion-report.png');
            break;

        case ImageConstants.HOMEWORK_EVALUATION_REPORT:
            imageSrc = require('../assets/images/homework-evaluation-report.png');
            break;

        case ImageConstants.MOST_WRONGLY_ANSWERED:
            imageSrc = require('../assets/images/most-wrongly-answered.png');
            break;

        case ImageConstants.LEVEL_PROGRESSION_REPORT:
            imageSrc = require('../assets/images/level-progression-report.png');
            break;

        case ImageConstants.LEVEL_IMPROVEMENT_REPORT:
            imageSrc = require('../assets/images/level-improvement-report.png');
            break;

        case ImageConstants.DOWNLOAD_ICON:
            imageSrc = require('../assets/images/download-icon.png');
            break;

        case ImageConstants.DOWNLOAD_ICON_BLUE:
            imageSrc = require('../assets/images/download-icon-blue.png');
            break;

        case ImageConstants.EDIT_ICON:
            imageSrc = require('../assets/images/edit-icon.png');
            break;

        case ImageConstants.ARCHIVE:
            imageSrc = require('../assets/images/archive.png');
            break;


        case ImageConstants.REMAINDER:
            imageSrc = require('../assets/images/remainder.png');
            break;


        case ImageConstants.FILTER_SEPERATOR:
            imageSrc = require('../assets/images/filter-seperator.png');
            break;

        case ImageConstants.DELETE_WHITE:
            imageSrc = require('../assets/images/delete-white.png');
            break;

        case ImageConstants.HAMBARGUR:
            imageSrc = require('../assets/images/hambargur.png');
            break;

        case ImageConstants.WHITE_VIDEO_ICON:
            imageSrc = require('../assets/images/white-video-icon.png');
            break;

        case ImageConstants.HAMBARGUR1:
            imageSrc = require('../assets/images/hambergur1.png');
            break;

        case ImageConstants.HAMBARGUR2:
            imageSrc = require('../assets/images/hambargur2.png');
            break;


        case ImageConstants.INFO:
            imageSrc = require('../assets/images/info.png');
            break;

        case ImageConstants.INFO_GREY:
            imageSrc = require('../assets/images/info-grey.png');
            break;

        case ImageConstants.INFO1:
            imageSrc = require('../assets/images/info1.png');
            break;

        case ImageConstants.SEARCH:
            imageSrc = require('../assets/images/search.png');
            break;

        case ImageConstants.HEADER1:
            imageSrc = require('../assets/images/header-1.png');
            break;

        case ImageConstants.PDF:
            imageSrc = require('../assets/images/pdf.png');
            break;
        case ImageConstants.PDF_WHITE:
            imageSrc = require('../assets/images/pdf-white.png');
            break;
        case ImageConstants.CALENDAR_WHITE:
            imageSrc = require('../assets/images/calendar-white.png');
            break;
        case ImageConstants.PRINT:
            imageSrc = require('../assets/images/print.png');
            break;

        case ImageConstants.RECT_BLACK:
            imageSrc = require('../assets/images/rect-black.png');
            break;

        case ImageConstants.RECT_BLACK1:
            imageSrc = require('../assets/images/rect-black1.png');
            break;

        case ImageConstants.DRAG:
            imageSrc = require('../assets/images/drag.png');
            break;

        case ImageConstants.DRAG_LOADING:
            imageSrc = require('../assets/images/drag-loading.png');
            break;

        case ImageConstants.REPORT_CARD_INFO:
            imageSrc = require('../assets/images/report-card-info.png');
            break;

        case ImageConstants.EQUAL_ICON:
            imageSrc = require('../assets/images/equal-icon.png');
            break;

        case ImageConstants.UP_ARROW:
            imageSrc = require('../assets/images/up-arrow.png');
            break;

        case ImageConstants.DOWN_ARROW:
            imageSrc = require('../assets/images/down-arrow.png');
            break;

        case ImageConstants.MATCHING_ARROW:
            imageSrc = require('../assets/images/matching-arrow.png');
            break;

        case ImageConstants.DRAG_ICON:
            imageSrc = require('../assets/images/drag-icon.png');
            break;

        case ImageConstants.TEST_ACTIVE:
            imageSrc = require('../assets/images/test-active.png');
            break;

        case ImageConstants.TEST_INACTIVE:
            imageSrc = require('../assets/images/test-inactive.png');
            break;

        case ImageConstants.TEST_RESULTS_ACTIVE:
            imageSrc = require('../assets/images/test-results-active.png');
            break;

        case ImageConstants.TEACHERS_ACTIVE:
            imageSrc = require('../assets/images/teachers-active.png');
            break;

        case ImageConstants.MENU_ICON:
            imageSrc = require('../assets/images/menu-icon.png');
            break;

        case ImageConstants.ANSWER_ICON:
            imageSrc = require('../assets/images/answer-icon.png');
            break;

        case ImageConstants.SEPARATOR:
            imageSrc = require('../assets/images/separator.png');
            break;

        case ImageConstants.CORRECT_ANSWER:
            imageSrc = require('../assets/images/correct-answer.png');
            break;

        case ImageConstants.WRONG_ANSWER:
            imageSrc = require('../assets/images/wrong-answer.png');
            break;

        case ImageConstants.COMPLETION_REPORT_ACTIVE:
            imageSrc = require('../assets/images/completion-report-active.png');
            break;

        case ImageConstants.TEST_RESULTS_INACTIVE:
            imageSrc = require('../assets/images/test-results-inactive.png');
            break;

        case ImageConstants.MOST_WRONGLY_ANSWERED_ACTIVE:
            imageSrc = require('../assets/images/most-wrongly-answered-active.png');
            break;

        case ImageConstants.CARD_SEPARATOR:
            imageSrc = require('../assets/images/card-separator.png');
            break;

        case ImageConstants.GREEN_TICK:
            imageSrc = require('../assets/images/green-tick.png');
            break;

        case ImageConstants.GREEN_TICK_V1:
            imageSrc = require('../assets/images/green-tick-v1.png');
            break;

        case ImageConstants.GREEN_TICK_V2:
            imageSrc = require('../assets/images/green-tick-v2.png');
            break;

        case ImageConstants.CALENDAR:
            imageSrc = require('../assets/images/calendar.png');
            break;

        case ImageConstants.DOWNLOAD_ACTIVE:
            imageSrc = require('../assets/images/download-active.png');
            break;

        case ImageConstants.DATA_BAR:
            imageSrc = require('../assets/images/data-bar.png');
            break;

        case ImageConstants.EXAM_PAD:
            imageSrc = require('../assets/images/exam-pad.png');
            break;

        case ImageConstants.STUDENTS:
            imageSrc = require('../assets/images/students.png');
            break;

        case ImageConstants.VIDEOS:
            imageSrc = require('../assets/images/videos.png');
            break;

        case ImageConstants.QUOTE_LEFT:
            imageSrc = require('../assets/images/quote-left.png');
            break;

        case ImageConstants.WHITE_CLOSE_ICON:
            imageSrc = require('../assets/images/white-close-icon.png');
            break;

        case ImageConstants.QUOTE_RIGHT:
            imageSrc = require('../assets/images/quote-right.png');
            break;

        case ImageConstants.RIGHT_ARROW_WHITE:
            imageSrc = require('../assets/images/right-arrow-white.png');
            break;

        case ImageConstants.TICK:
            imageSrc = require('../assets/images/tick.png');
            break;

        case ImageConstants.RIGHT_ARROW_GRAY:
            imageSrc = require('../assets/images/right-arrow-gray.png');
            break;

        case ImageConstants.TICK_GREEN:
            imageSrc = require('../assets/images/tick-green.png');
            break;

        case ImageConstants.WRONG:
            imageSrc = require('../assets/images/wrong.png');
            break;

        case ImageConstants.RIGHT_ARROW_GRAY1:
            imageSrc = require('../assets/images/right-arrow-gray1.png');
            break;

        case ImageConstants.NO_DATA:
            imageSrc = require('../assets/images/no-data.png');
            break;

        case ImageConstants.FILTER:
            imageSrc = require('../assets/images/filter.png');
            break;

        case ImageConstants.BULB:
            imageSrc = require('../assets/images/bulb.png');
            break;

        case ImageConstants.TICK_GRAY:
            imageSrc = require('../assets/images/tick-gray.png');
            break;

        case ImageConstants.TICK_BLACK:
            imageSrc = require('../assets/images/tick-black.png');
            break;

        case ImageConstants.CLASS_SECTION_ICON:
            imageSrc = require('../assets/images/class-section-icn.png');
            break;

        case ImageConstants.CLASS_SECTION_ICON_WHITE:
            imageSrc = require('../assets/images/class-section-icn.png');
            break;
        case ImageConstants.GRAPHIC1:
            imageSrc = require('../assets/images/graphic-1.png');
            break;
        case ImageConstants.GRAPHIC2:
            imageSrc = require('../assets/images/graphic-2.png');
            break;

        case ImageConstants.KNOWLEDGE:
            imageSrc = require('../assets/images/knowledge.png');
            break;

        case ImageConstants.CALCULATION:
            imageSrc = require('../assets/images/calculation.png');
            break;

        case ImageConstants.MODELING:
            imageSrc = require('../assets/images/modeling.png');
            break;

        case ImageConstants.COMPREHENSION:
            imageSrc = require('../assets/images/comprehension.png');
            break;

        case ImageConstants.SIGNUP_WEB:
            imageSrc = require('../assets/images/signup-web.png');
            break;

        case ImageConstants.SIGNUP_MOBILE:
            imageSrc = require('../assets/images/signup-mobile.png');
            break;

        case ImageConstants.GULF_CALENDAR_ICON:
            imageSrc = require('../assets/images/gulf-calender.png');
            break;

        case ImageConstants.ARROW_DOWN_ANGLE:
            imageSrc = require('../assets/images/arrow_down_angle.png');
            break;

        case ImageConstants.ARROW_UP_ANGLE:
            imageSrc = require('../assets/images/arrow_up_angle.png');
            break;

        case ImageConstants.WHITE_TICK_MARK:
            imageSrc = require('../assets/images/white-tick-mark.png');
            break;

        case ImageConstants.EYE_ICON:
            imageSrc = require('../assets/images/eye-icon.png');
            break;

        case ImageConstants.CIRCLE_INFO_ICON:
            imageSrc = require('../assets/images/circle-info-icon.png');
            break;

        case ImageConstants.VIDEO_ICON:
            imageSrc = require('../assets/images/video-icon.png');
            break;

        case ImageConstants.BOOK_ICON:
            imageSrc = require('../assets/images/book-icon.png');
            break;



        case ImageConstants.RADIO_ACTIVE:
            imageSrc = require('../assets/images/radio-active.png');
            break;

        case ImageConstants.RADIO_INACTIVE:
            imageSrc = require('../assets/images/radio-inactive.png');
            break;

        case ImageConstants.EMAIL:
            imageSrc = require('../assets/images/email.png');
            break;

        case ImageConstants.SMS:
            imageSrc = require('../assets/images/sms.png');
            break;

        case ImageConstants.CLONE:
            imageSrc = require('../assets/images/clone.png');
            break;

        case ImageConstants.GULF_CALENDAR_ICON_1:
            imageSrc = require('../assets/images/gulf-calender-1.png');
            break;

        case ImageConstants.PRE_ASSESMENT_APP_ICON_1:
            imageSrc = require('../assets/images/pre-assesment-app-icn-1.png');
            break;

        case ImageConstants.ARROW_HORIZONTAL:
            imageSrc = require('../assets/images/arrow-horizontal.png');
            break;

        case ImageConstants.INFO_RED:
            imageSrc = require('../assets/images/info-red.png');
            break;

        case ImageConstants.INFO_RED_FILL:
            imageSrc = require('../assets/images/info-fill.png');
            break;

        case ImageConstants.DELETE_RED:
            imageSrc = require('../assets/images/del-red.png');
            break;

        case ImageConstants.EXTERNAL_LINK:
            imageSrc = require('../assets/images/external-link.png');
            break;

        case ImageConstants.INFO_CIRCLE:
            imageSrc = require('../assets/images/info-circle.png');
            break;
        case ImageConstants.SIGNUP_LOGO_RAMANUJAN:
            imageSrc = require('../assets/images/ramanujan.png');
            break;

        case ImageConstants.RECT_INACTIVE:
            imageSrc = require('../assets/images/rect-inactive.png');
            break;

        case ImageConstants.DIVIDER:
            imageSrc = require('../assets/images/divider.png');
            break;

        case ImageConstants.HELP_BLUE:
            imageSrc = require('../assets/images/help-blue.png');
            break;

        case ImageConstants.ELLIPSE:
            imageSrc = require('../assets/images/ellipse.png');
            break;

        case ImageConstants.ELLIPSE_ACTIVE:
            imageSrc = require('../assets/images/ellipse-active.png');
            break;

        case ImageConstants.ELLIPSE_INACTIVE:
            imageSrc = require('../assets/images/ellipse-inactive.png');
            break;


        case ImageConstants.CURRENCY:
            imageSrc = require('../assets/images/currency.png');
            break;

        case ImageConstants.REWARD1:
            imageSrc = require('../assets/images/reward-1.png');
            break;

        case ImageConstants.REWARD2:
            imageSrc = require('../assets/images/reward-2.png');
            break;

        case ImageConstants.REWARD3:
            imageSrc = require('../assets/images/reward-3.png');
            break;

        case ImageConstants.FLIPKART:
            imageSrc = require('../assets/images/third-party/flipkart.png');
            break;

        case ImageConstants.AMAZON:
            imageSrc = require('../assets/images/third-party/amazon.png');
            break;

        case ImageConstants.CROMA:
            imageSrc = require('../assets/images/third-party/croma.png');
            break;

        case ImageConstants.MPL:
            imageSrc = require('../assets/images/mpl.png');
            break;

        case ImageConstants.FLC:
            imageSrc = require('../assets/images/flc.png');
            break;
        case ImageConstants.MSF:
            imageSrc = require('../assets/images/msf-logo.png');
            break;
        case ImageConstants.SIGNUP_LOGO_MATHS_OLYMPIAD:
            imageSrc = require('../assets/images/math-olympaid-logo_thumbnail.png');
            break;
        case ImageConstants.USER1:
            imageSrc = require('../assets/images/user-1.png');
            break;

        case ImageConstants.BULLET_LIST:
            imageSrc = require('../assets/images/bullet-list-line.png');
            break;

        case ImageConstants.MAIL:
            imageSrc = require('../assets/images/mail.png');
            break;

        case ImageConstants.ONBOARDED:
            imageSrc = require('../assets/images/onboarded.png');
            break;
        case ImageConstants.INVITE_SENT:
            imageSrc = require('../assets/images/invite-sent.png');
            break;

        case ImageConstants.ONBOARDED_NEW:
            imageSrc = require('../assets/images/onboarded_new.png');
            break;
        case ImageConstants.INVITE_SENT_NEW:
            imageSrc = require('../assets/images/invite-sent-new.png');
            break;

    }

    return imageSrc;
}
import HttpClient from './http-client';
import URL from './url';

export default class MasterDataApi {

    static getChapterList(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.CHAPTER_LIST + "?" + params.join('&') : URL.CHAPTER_LIST;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getEloStatusListV2(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_ELO_STATUS_LIST_V2 + "?" + params.join('&') : URL.GET_ELO_STATUS_LIST_V2;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getChapterProgress(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_CHAPTER_PROGRESS + "?" + params.join('&') : URL.GET_CHAPTER_PROGRESS;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getEloContents(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_ASSESSMENT_CONTENT + "?" + params.join('&') : URL.GET_ASSESSMENT_CONTENT;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static updateUserActivity(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_USER_ACTIVITY, null, payload);
    }

    static getElos(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.ELO_LIST + "?" + params.join('&') : URL.ELO_LIST;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getWorkflowNodes(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.WORKFLOW_NODES + "?" + params.join('&') : URL.WORKFLOW_NODES;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getCountingwellClass(payload) {
        let url = URL.BASE_URL + URL.COUNTINGWELL_CLASS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getCountignwellBoard(payload) {

        let url = URL.BASE_URL + URL.COUNTINGWELL_BOARD;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getAllClassVideoContent(payload) {
        console.log(payload)
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        console.log(params)
        let paramsList = params.length ? URL.ALL_VIDEOS_LIST + "?" + params.join('&') : URL.ALL_VIDEOS_LIST;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getAllClassChapterList(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.ALL_CLASS_CHAPTER_LIST + "?" + params.join('&') : URL.ALL_CLASS_CHAPTER_LIST;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    static getAllClassElos(payload) {
        console.log(payload)
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        console.log(params)
        let paramsList = params.length ? URL.ALL_ELOS_LIST + "?" + params.join('&') : URL.ALL_ELOS_LIST;
        return HttpClient.get(URL?.BASE_URL + paramsList, payload);
    }

    
    

}

import React from 'react';
import './learning-card-common-errors.scss';
import Parser from "../../common/content-parser/content-parser";
import LearningHeader from '../learning-header/learning-header';



export default class LearningCard extends React.Component {

    constructor(props) {

        super(props);
        this.parser = new Parser();

    }

    state = {
        showIcons: false
    }

    showIcons = () => {
        this.setState({ showIcons: !this.state.showIcons })
    }

    onClickBookMarkActive = () => {
        this.props.delegate && this.props.delegate.onClickBookMarkActive && this.props.delegate.onClickBookMarkActive(this.props.bookMarkId, this.props.selectedIndex)
    }

    onClickBookMark = () => {
        this.props.delegate && this.props.delegate.onClickBookMark && this.props.delegate.onClickBookMark(this.props.contentObj, this.props.selectedIndex)
    }

    render() {

        // setCategoryThemeStyles(this.props.categoryId);

        return (

            <div>
                {this.props.data.showTitle &&
                    <LearningHeader data={{ headerIcon: this.props.data.headerIcon, title: this.props.data.title }} delegate={this} showBookMark={this.props.showBookMark} showInactiveBookmark={this.props.showInactiveBookmark} meta={this.props.meta} removeHeaderIcon={this.props.removeHeaderIcon} headerWithStyle isCommonError></LearningHeader>
                }

                {/* {this.state.showIcons &&
                    <CardActions />
                } */}

                <div>{this.parser.parseLearningContent(this.props.data.content, this.props.delegate, this.props.glossary, null, null, this.props.categoryId)}</div>
            </div>


        );
    }
}




import React from "react";
import './class-mock-test-performance-report.scss';
import { getImageSource } from "../../utility/image-source";
import { ImageConstants } from "../../constants/constants";
import CommonApi from "../../apis/common";
import Loading from "../common/loading/loading";


class ClassMockTestPerformanceReport extends React.Component {


    state = {
        // "name": "Ramya Haridas",
        // "class": "Class 10",
        // "schoolName": "Countingwell Academy",
        // "schoolId": "SCX-121",
        // "section": null,
        // "sectionId": "",
        // "markScored": 18,
        // "totalMark": 30,
        // "questionTypeList": [
        //     {
        //         "markScored": 4,
        //         "totalMark": 6,
        //         "percentage": 67,
        //         "questionType": "MCQ",
        //         "questionTypeName": "Multiple Choice Questions",
        //         "average": 4
        //     },
        //     {
        //         "markScored": 10,
        //         "totalMark": 16,
        //         "percentage": 63,
        //         "questionType": "STR",
        //         "questionTypeName": "Structured Response Questions",
        //         "average": 10
        //     },
        //     {
        //         "totalMark": 8,
        //         "markScored": 4,
        //         "percentage": 50,
        //         "questionType": "CASE_STUDY",
        //         "questionTypeName": "Case Study Based Questions",
        //         "average": 4
        //     }
        // ],
        // "caseStudySkillReport": [
        //     {
        //         "skillTotal": 2,
        //         "totalCorrect": 1,
        //         "skillCleared": false,
        //         "skill": "Comprehension",
        //         "caseStudySkillTitle": "Ability to understand the question"
        //     },
        //     {
        //         "skillTotal": 4,
        //         "totalCorrect": 3,
        //         "skillCleared": true,
        //         "skill": "Modelling",
        //         "caseStudySkillTitle": "Ability to apply concepts"
        //     },
        //     {
        //         "skillTotal": 4,
        //         "totalCorrect": 2,
        //         "skillCleared": false,
        //         "skill": "Calculation",
        //         "caseStudySkillTitle": "Ability to calculate"
        //     }
        // ],
        // "chapterList": [
        //     {
        //         "chapterKey": "17200704731054394",
        //         "chapterTitle": "Real Numbers",
        //         "concepts": [
        //             {
        //                 "chapterKey": "17200704731054394",
        //                 "chapterTitle": "Real Numbers",
        //                 "conceptKey": "17200705377333305",
        //                 "conceptTitle": "The Fundamental Theorem of Arithmetic",
        //                 "conceptAverage": 1,
        //                 "markScored": 6,
        //                 "totalMark": 6,
        //                 "percentage": 100
        //             }
        //         ]
        //     },
        //     {
        //         "chapterKey": "17200705216289554",
        //         "chapterTitle": "Polynomials",
        //         "concepts": [
        //             {
        //                 "chapterKey": "17200705216289554",
        //                 "chapterTitle": "Polynomials",
        //                 "conceptKey": "17200705641661252",
        //                 "conceptTitle": "Geometrical Meaning of the Zeroes of a Polynomial",
        //                 "conceptAverage": 1,
        //                 "markScored": 1,
        //                 "totalMark": 6,
        //                 "percentage": 17
        //             },
        //             {
        //                 "chapterKey": "17200705216289554",
        //                 "chapterTitle": "Polynomials",
        //                 "conceptKey": "17200705781528154",
        //                 "conceptAverage": 3,
        //                 "conceptTitle": "Relationship between Zeroes and Coefficients of a Polynomial",
        //                 "markScored": 3,
        //                 "totalMark": 6,
        //                 "percentage": 50
        //             }
        //         ]
        //     },
        //     {
        //         "chapterKey": "17200705288321844",
        //         "chapterTitle": "Pair of Linear Equations in Two Variables",
        //         "concepts": [
        //             {
        //                 "chapterKey": "17200705288321844",
        //                 "chapterTitle": "Pair of Linear Equations in Two Variables",
        //                 "conceptKey": "17200706079628568",
        //                 "conceptTitle": " Algebraic Methods of Solving a Pair of Linear Equations ",
        //                 "conceptAverage": 1,
        //                 "markScored": 8,
        //                 "totalMark": 12,
        //                 "percentage": 67
        //             }
        //         ]
        //     }
        // ],
        // "competencyChapterList": [
        //     {
        //         "chapterKey": "17200704731054394",
        //         "chapterTitle": "Real Numbers",
        //         "concepts": [
        //             {
        //                 "chapterKey": "17200704731054394",
        //                 "chapterTitle": "Real Numbers",
        //                 "conceptKey": "17200705377333305",
        //                 "conceptTitle": "The Fundamental Theorem of Arithmetic",
        //                 "competency": true,
        //                 "standard": true
        //             }
        //         ]
        //     },
        //     {
        //         "chapterKey": "17200705216289554",
        //         "chapterTitle": "Polynomials",
        //         "concepts": [
        //             {
        //                 "chapterKey": "17200705216289554",
        //                 "chapterTitle": "Polynomials",
        //                 "conceptKey": "17200705641661252",
        //                 "conceptTitle": "Geometrical Meaning of the Zeroes of a Polynomial",
        //                 "competency": false,
        //                 "standard": true
        //             },
        //             {
        //                 "chapterKey": "17200705216289554",
        //                 "chapterTitle": "Polynomials",
        //                 "conceptKey": "17200705781528154",
        //                 "conceptTitle": "Relationship between Zeroes and Coefficients of a Polynomial",
        //                 "competency": false,
        //                 "standard": true
        //             }
        //         ]
        //     },
        //     {
        //         "chapterKey": "17200705288321844",
        //         "chapterTitle": "Pair of Linear Equations in Two Variables",
        //         "concepts": [
        //             {
        //                 "chapterKey": "17200705288321844",
        //                 "chapterTitle": "Pair of Linear Equations in Two Variables",
        //                 "conceptKey": "17200706079628568",
        //                 "conceptTitle": " Algebraic Methods of Solving a Pair of Linear Equations ",
        //                 "competency": true,
        //                 "standard": true
        //             }
        //         ]
        //     }
        // ],
        // "competency": {
        //     "markScored": 7,
        //     "totalMark": 19,
        //     "percentage": 37
        // },
        // "competencyAverage": 7,
        // questionTypeRecommendation: ['Case Study Based Questions','Case Study Based Questions 2'],
        // skillRecommendation: ['Comprehension : You need to spend more time in understanding the Question.','Comprehension : You need to spend more time in understanding the Question.2'],
        // conceptRecommendation: ['The Fundamental Theorem of Arithmetic', 'Relationship between Zeroes and Coefficients of a Polynomial'],
        // competencyRecommendation: 'You need to practice more Competency Based Questions',
        isLoading: false
    }

    constructor(props) {
        super(props)
        this.getStudentPerformanceReport()
    }

    alphabet = ['a', 'b', 'c', 'd', 'e',
        'f', 'g', 'h', 'i', 'j',
        'k', 'l', 'm', 'n', 'o',
        'p', 'q', 'r', 's', 't',
        'u', 'v', 'w', 'x', 'y',
        'z'];


    componentDidMount() {

    }


    getStudentPerformanceReport() {
        let payload = {
            assessmentActivityId: this.props.meta.assessmentActivityId
        }
        this.setState({ isLoading: true })
        CommonApi.getStudentPerformanceReport(payload).then(res => {
            console.log(res)
            this.setState({ isLoading: false, ...res })
        }).catch(err => {
            console.log(err)
        })
    }

    goToHomeScreen() {
        return this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    hideReport() {
        this.props.delegate && this.props.delegate.hidePerformanceReport && this.props.delegate.hidePerformanceReport()
    }

    goToMockTestChapterList() {
        this.props.delegate && this.props.delegate.goToMockTestChapterList && this.props.delegate.goToMockTestChapterList()
    }


    getQuestionTypeProgressBar(questionTypeList) {

        return (
            <div>
                {
                    questionTypeList && questionTypeList.length && questionTypeList.map((item, index) => (
                        <>
                            <div className="mb-3 d-flex align-items-center">
                                <div className="question-type-name pr-2 mark-border">{item.questionTypeName} {`(${item.totalQuestions} Q)`}</div>
                                <div className="d-flex align-items-center ml-2 pr-2">
                                    <img className="exam-pad-icon mr-1" src={getImageSource(ImageConstants.EXAM_PAD_ICON_GRAY)} />
                                    <span className="mark-scored">{item.markScored}/{item.totalMark}</span>
                                </div>
                            </div>
                            <div className={`row align-items-center ml-0 mr-0 p-1 ${index === questionTypeList.length - 1 ? "" : "mb-4"}`}>
                                <div className="col question-type-wrapper pl-0 pr-0 position-relative">
                                    <div className={`pl-3 d-flex align-items-center justify-content-between inner-progress-bar
                                ${item.percentage !== 0 && item.percentage < 100 ? 'border-right-radius' : ''}`}
                                        style={{ maxWidth: `${item.percentage}%`, background: `${item.percentage ? '#60CFFF' : 'none'}` }}>
                                        {/* {
                                            item.average > 0 ? <div className="average-indicator" style={{ left: `${item.average}%` }}>
                                                <div className="average-number-absolute" style={{ left: `${item.average}%` }}>{item.average}</div>
                                            </div> : <></>
                                        } */}

                                        {/* <div className="question-type-name question-name-absolute">{item.questionTypeName}</div>
                                    <div className="question-type-name pr-2 mark-text-absolute">{item.markScored}/{item.totalMark}</div> */}

                                    </div>
                                </div>
                                <div className="col-1 percentage-text">{item.percentage}%</div>
                            </div>
                        </>

                    ))
                }
            </div>
        )


    }

    getCaseStudySkillQuestionsProgressBar(caseStudySkillList) {
        return (
            <div className="skill-table mt-3 table-responsive card">
                <table className="table table-bordered" style={{}}>
                    {/* <thead className="class-header">
                        <tr className="first-header-bg">
                            <th className="" width='50%'>Concepts</th>
                            <th>Competency</th>
                            <th>Standard</th>
                        </tr>
                    </thead> */}
                    <tbody id="rows" className="class-body">
                        {
                            caseStudySkillList &&
                            caseStudySkillList.length &&
                            caseStudySkillList.map((item, index) => (
                                <>
                                    {

                                        <tr>
                                            <td className={`question-type-name ${index ? 'border-bottom-left-radius' : ''}  `}>
                                                {item.caseStudySkillTitle} {item.totalQuestions ?`(${item.totalQuestions} Q)` : ''}
                                            </td>
                                            <td>
                                                <img className="image-size" src={getImageSource(item.skillCleared ? ImageConstants.GREEN_BG_TICK_MARK : ImageConstants.RED_BG_CROSS_MARK)} />
                                            </td>
                                            {/* <td><img className="image-size" src={getImageSource(item.standard ? ImageConstants.GREEN_BG_TICK_MARK : ImageConstants.RED_BG_CROSS_MARK)} /></td> */}
                                            {/* <td className="assignment-count">
                                                            {this.classWiseCompletionDetails[item.title] ? this.classWiseCompletionDetails[item.title].totalSchoolwork : 0}
                                                        </td>
                                                        <td className="assignment-count">
                                                            {`${this.classWiseCompletionDetails[item.title] ? this.classWiseCompletionDetails[item.title].classCompletionRate : 0}%`}
                                                        </td> */}
                                        </tr>

                                    }
                                </>

                            ))
                        }
                    </tbody>
                </table>
            </div>
        )

        // return (
        //     <div>
        //         {
        //             caseStudySkillList && caseStudySkillList.length && caseStudySkillList.map((item, index) => (
        //                 <div className={`row align-items-center p-1 ml-0 mr-0 ${index === caseStudySkillList.length - 1 ? "" : "mb-4"}`}>
        //                     <div className="col question-type-wrapper pl-0 pr-0 position-relative">
        //                         <div className={`pl-3 d-flex align-items-center justify-content-between inner-progress-bar
        //                         ${item.percentage !== 0 && item.percentage < 100 ? 'border-right-radius' : ''}`}
        //                             style={{}}>

        //                             <div className="question-type-name question-name-absolute">{item.caseStudySkillTitle}</div>
        //                             {/* <div className="question-type-name pr-2 mark-text-absolute">{item.markScored}/{item.totalMark}</div> */}
        //                         </div>
        //                     </div>
        //                     <div className="col-1"><img className="skill-status-image" src={getImageSource(item.skillCleared ? ImageConstants.GREEN_BG_TICK_MARK : ImageConstants.RED_BG_CROSS_MARK)} /></div>
        //                     {/* <div className="col-1 percentage-text">{item.percentage}%</div> */}
        //                 </div>
        //             ))
        //         }
        //     </div>
        // )
    }


    getConceptsProgressBar(chapterList) {

        return (
            <div>
                {
                    chapterList && chapterList.length && chapterList.map((chapter, cidx) => (
                        <div>
                            <div className={`chapter-title mb-2 ${cidx != 0 ? 'mt-3' : ''}`}>{chapter.chapterTitle}</div>
                            {
                                chapter.concepts && chapter.concepts.length && chapter.concepts.map((item, index) => (
                                    <>
                                        <div className="mb-3 d-flex align-items-center">
                                            <div className="question-type-name pr-2 mark-border">{item.conceptTitle} {`(${item.totalQuestions} Q)`}</div>
                                            <div className="d-flex align-items-center ml-2 pr-2">
                                                <img className="exam-pad-icon mr-1" src={getImageSource(ImageConstants.EXAM_PAD_ICON_GRAY)} />
                                                <span className="mark-scored">{item.markScored}/{item.totalMark}</span>
                                            </div>
                                        </div>
                                        <div className={`row align-items-center ml-0 mr-0 p-2 ${index === chapter.concepts.length - 1 ? "" : "mb-4"}`}>
                                            <div className="col question-type-wrapper pl-0 pr-0 position-relative">
                                                <div className={`pl-3 d-flex align-items-center justify-content-between inner-progress-bar
                                ${item.percentage !== 0 && item.percentage < 100 ? 'border-right-radius' : ''}`}
                                                    style={{ maxWidth: `${item.percentage}%`, background: `${item.percentage ? '#FFA629' : 'none'}` }}>
                                                    {/* {
                                                        item.conceptAverage > 0 ? <div className="average-indicator" style={{ left: `${item.conceptAverage}%` }}>
                                                            <div className="average-number-absolute" style={{ left: `${item.conceptAverage}%` }}>{item.conceptAverage}</div>
                                                        </div> : <></>
                                                    } */}
                                                    {/* <div className="question-type-name question-name-absolute">{item.conceptTitle}</div>
                                                <div className="question-type-name pr-2 mark-text-absolute">{item.markScored}/{item.totalMark}</div> */}
                                                </div>
                                            </div>
                                            <div className="col-1 percentage-text">{item.percentage}%</div>
                                        </div>
                                    </>

                                ))
                            }
                        </div>
                    ))
                }
            </div>
        )
    }

    getCompetencyProgressBar(item) {
        console.log(item)
        return (
            <div>
                {
                    item && item.percentage ?
                        <>
                            <div className="question-type-name pr-2 mb-3">Competency</div>
                            <div className={`row align-items-center ml-0 mr-0`}>
                                <div className="col question-type-wrapper pl-0 pr-0 position-relative">
                                    <div className={`pl-3 d-flex align-items-center justify-content-between inner-progress-bar
                                ${item.percentage !== 0 && item.percentage < 100 ? 'border-right-radius' : ''}`}
                                        style={{ maxWidth: `${item.percentage}%`, background: `${item.percentage ? '#20D2F6' : 'none'}` }}>
                                        {/* {
                                            this.state.competencyAverage > 0 ? <div className="average-indicator" style={{ left: `${this.state.competencyAverage}%` }}>
                                                <div className="average-number-absolute" style={{ left: `${this.state.competencyAverage}%` }}>{this.state.competencyAverage}</div>
                                            </div> : <></>
                                        } */}
                                        {/* <div className="question-type-name question-name-absolute">Competency</div> */}
                                        {/* <div className="question-type-name pr-2 mark-text-absolute">{item.markScored}/{item.totalMark}</div> */}
                                    </div>
                                </div>
                                <div className="col-1 percentage-text">{item.percentage}%</div>
                            </div>
                        </>

                        : <></>
                }

            </div>
        )
    }


    goToCompletedMockTestList() {
        this.props.delegate && this.props.delegate.showCompletedAssessments && this.props.delegate.showCompletedAssessments()
    }

    generatePdf() {

        let url = this.state.pdfLink

        console.log(url)
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank'
        link.setAttribute('download', 'document.pdf'); // Specify the file name

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }


    hideMockTest() {
        this.props.delegate && this.props.delegate.hideMockTest && this.props.delegate.hideMockTest()
    }

    goToHome() {
        this.props.delegate && this.props.delegate.goToHome && this.props.delegate.goToHome()
    }

    renderBreadcrumb = () => {

        console.log(this.props.meta.from)

        if (this.props.meta && this.props.meta.from === "CLASS_MOCK_TEST") {
            return (
                <div className='breadcrumb mt-2'>
                    <span className="home cursor-pointer title-1" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.hideReport()}>{`Maths Diagnostic Assessment`} {`>`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.hideReport()}>{`Assessment Completed`} {`>`}</span>
                    <span className='pl-2 title-1'>{`Student Performance Report`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                </div>
            )
        }

        if (this.props.meta && this.props.meta.from === "SCIENCE_CLASS_MOCK_TEST") {
            return (
                <div className='breadcrumb mt-2'>
                    <span className="home cursor-pointer title-1" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.hideReport()}>{`Science Diagnostic Assessment`} {`>`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.hideReport()}>{`Assessment Completed`} {`>`}</span>
                    <span className='pl-2 title-1'>{`Student Performance Report`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                </div>
            )
        }

        if (this.props.meta && this.props.meta.from === "SELECTED_CHAPTER_MOCK_TEST") {
            return (
                <div className='breadcrumb mt-2'>
                    <span className="home cursor-pointer title-1" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.hideReport()}>{`Mock Test`} {`>`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.hideReport()}>{`Mock Test Completed`} {`>`}</span>
                    <span className='pl-2 title-1'>{`Student Performance Report`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                </div>
            )
        }

        if (this.props.meta && this.props.meta.from === "SINGLE_CHAPTER") {
            return (
                <div className='breadcrumb mt-2'>
                    <span className="home cursor-pointer title-1" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.hideReport()}>{`Mock Test`} {`>`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToCompletedMockTestList()}>{`Mock Test Completed`} {`>`}</span>
                    <span className='pl-2 title-1'>{`Student Performance Report`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                </div>
            )
        }

        if (this.props.meta && this.props.meta.from === "CHAPTER_ACTIVITY") {
            return (
                <div className='breadcrumb mt-2'>
                    <span className="home cursor-pointer title-1" onClick={() => this.goToHome()}>{`Home >`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.hideMockTest()}>{this.props.meta.chapterTitle ? this.props.meta.chapterTitle : 'Mock Test'} {`>`}</span>
                    {/* <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToCompletedMockTestList()}>{`Mock Test Completed`} {`>`}</span> */}
                    <span className='pl-2 title-1'>{`Student Performance Report`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                </div>
            )
        }
    }

    renderQuestionTypeRecommendation() {
        return (
            <div className="d-flex align-items-center mt-2">
                <div className="circle mr-2"></div>
                <div className="mr-1 recommendation-text"> You need to work harder on </div>
                {
                    this.state.questionTypeRecommendation.map((item, index) => (
                        <div className="mr-1 recommendation-text">{item}{index != this.state.questionTypeRecommendation.length - 1 ? ',' : '.'}</div>
                    ))
                }
            </div>
        )
    }

    renderConceptRecommendation() {
        return (
            <div className="mt-2">
                <div className="d-flex align-items-center">
                    <div className="circle mr-2"></div>
                    <div className="mr-1 recommendation-text"> You need to revise the following Concepts : </div>
                </div>

                <div className="d-flex flex-column">
                    {
                        this.state.conceptRecommendation.map((item, index) => (
                            <div className="d-flex align-items-center mt-2 ml-3">
                                <div className="recommendation-text mr-1"><strong>{this.alphabet[index].toLocaleUpperCase()}.</strong></div>
                                <div className="mr-1 recommendation-text">{item}{index != this.state.conceptRecommendation.length - 1 ? ',' : '.'}</div>
                            </div>

                        ))
                    }
                </div>
            </div>

        )
    }

    renderSkillRecommendation() {
        return (
            <div className="">
                <div className="d-flex flex-column mt-1">
                    {
                        this.state.skillRecommendation.map((item, index) => (
                            <div className="d-flex align-items-center mt-2">
                                <div className="circle mr-2"></div>
                                <div className="mr-1 recommendation-text">{item}</div>
                            </div>
                        ))
                    }
                </div>
            </div>

        )
    }




    render() {
        console.log(this.state.isLoading)
        return (
            <div className="class-mock-test-performance-report">
                {
                    this.state.isLoading ? <Loading />
                        :
                        <>
                            {this.renderBreadcrumb()}
                            <div className="school-name  mt-3">{this.props.meta.from === 'SELECTED_CHAPTER_MOCK_TEST' ? 'Multiple Chapters Assessment - Feedback' : this.props.meta.from === 'SINGLE_CHAPTER' ? `${this.props.meta.selectedMockTestTitle} Assessment - Feedback` : this.props.meta.from === 'CLASS_MOCK_TEST' ? "Maths Diagnostic Assessment - Feedback" : this.props.meta.from === 'SCIENCE_CLASS_MOCK_TEST' ? "Science Diagnostic Assessment - Feedback" : "Assessment"}</div>

                            <div className="d-flex justify-content-between mr-5 mb-3 mt-1 align-items-center">
                                <div className="school-name-new">{this.state.schoolName}</div>
                                <div className="export-button cursor-pointer" onClick={() => this.generatePdf()}>Export to PDF</div>
                            </div>
                            {/* <div className="school-name text-center mt-3">{this.state.schoolName}</div> */}
                            <div className="student-basic-details mt-3">
                                <div className="student-detail-header">Student Basic Details</div>
                                <div className="student-details-wrapper d-flex mt-3 align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="student-name pr-4 student-name-border">Name : {this.state.name} </div>
                                        <div className="student-name ml-4 pr-4 student-name-border">Class : {this.state.class} </div>
                                        {
                                            this.state.section ? <div className="student-name ml-4">Section : {this.state.section} </div> : <></>
                                        }
                                    </div>
                                    <div className="mr-4 d-flex align-items-center">
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <div className="total-score">Total Score : </div>
                                                <div className="score-circle ml-2 d-flex flex-column justify-content-center align-items-center">
                                                    <div className="mark-text">{this.state.markScored}</div>
                                                    <div className="divider"></div>
                                                    <div className="mark-text">{this.state.totalMark}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="ml-4">
                                            <div className="class-average-indication d-flex align-items-center">
                                                <div className="class-average-indicator mr-2"></div>
                                                <div>Class Average</div>
                                            </div>
                                        </div> */}
                                    </div>


                                </div>
                                <div className="performance-overview mt-3">
                                    <div className="student-detail-header">Performance Overview</div>
                                    {/* <div className="score-wrapper mt-3 d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div className="total-score">Total Score : </div>
                                            <div className="score-circle ml-2 d-flex flex-column justify-content-center align-items-center">
                                                <div className="mark-text">{this.state.markScored}</div>
                                                <div className="divider"></div>
                                                <div className="mark-text">{this.state.totalMark}</div>
                                            </div>
                                        </div>
                                        <div className="class-average-indication d-flex align-items-center">
                                            <div className="class-average-indicator mr-2"></div>
                                            <div>Class Average</div>
                                        </div>
                                    </div> */}

                                    <div className="row mt-4  mr-0 pl-0 pr-0 row-max-width">
                                        <div className="col-6 column-max-width">
                                            <div className="question-type-border-container">
                                                <div className="bordered-text">Questions Type{` (Marks Scored / Total Mark)`}</div>
                                                {
                                                    this.getQuestionTypeProgressBar(this.state.questionTypeList)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-6 column-max-width">
                                            <div className="case-study-skill-border-container">
                                                <div className="bordered-text">Case Study Based Questions</div>
                                                {
                                                    this.getCaseStudySkillQuestionsProgressBar(this.state.caseStudySkillReport)
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="chapter-list-border-container">
                                            <div className="bordered-text">Concepts</div>
                                            {
                                                this.getConceptsProgressBar(this.state.chapterList)
                                            }
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="competency-list-border-container">
                                            <div className="bordered-text">Competency Questions</div>
                                            {
                                                this.getCompetencyProgressBar(this.state.competency)
                                            }

                                            <div className="competency-table mt-3 table-responsive card">
                                                <table className="table table-bordered table-striped" style={{}}>
                                                    <thead className="class-header">
                                                        <tr className="first-header-bg">
                                                            <th className="" width='50%'>Concepts</th>
                                                            <th>Standard</th>
                                                            <th>Competency</th>
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody id="rows" className="class-body">
                                                        {
                                                            this.state.competencyChapterList &&
                                                            this.state.competencyChapterList.length &&
                                                            this.state.competencyChapterList.map((chapter, cidx) => (
                                                                <>
                                                                    {
                                                                        chapter.concepts && chapter.concepts.length && chapter.concepts.map((item, index) => (
                                                                            <tr>
                                                                                <td className={`class-text ${chapter.concepts.length - 1 === index ? 'border-bottom-left-radius' : ''}  `}>
                                                                                    {item.conceptTitle}
                                                                                </td>
                                                                                <td><img className="image-size" src={getImageSource(item.standard ? ImageConstants.GREEN_BG_TICK_MARK : ImageConstants.RED_BG_CROSS_MARK)} /></td>
                                                                                <td>
                                                                                    <img className="image-size" src={getImageSource(item.competency ? ImageConstants.GREEN_BG_TICK_MARK : ImageConstants.RED_BG_CROSS_MARK)} />
                                                                                </td>
                                                                               
                                                                                {/* <td className="assignment-count">
                                                            {this.classWiseCompletionDetails[item.title] ? this.classWiseCompletionDetails[item.title].totalSchoolwork : 0}
                                                        </td>
                                                        <td className="assignment-count">
                                                            {`${this.classWiseCompletionDetails[item.title] ? this.classWiseCompletionDetails[item.title].classCompletionRate : 0}%`}
                                                        </td> */}
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </>

                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="recommendation mt-4 mb-5">
                                    <div className="student-detail-header">Recommendation</div>
                                    <div className="recommendation-header mt-2">We recommend the following for {this.state.name} :</div>
                                    {
                                        this.state.questionTypeRecommendation && this.state.questionTypeRecommendation.length ? this.renderQuestionTypeRecommendation() : <></>
                                    }
                                    {
                                        this.state.competencyRecommendation ?
                                            <div className="mt-2 d-flex align-items-center">
                                                <div className="circle mr-2"></div>
                                                <div className="recommendation-text">{this.state.competencyRecommendation}</div>
                                            </div> : <></>

                                    }
                                    {
                                        this.state.skillRecommendation && this.state.skillRecommendation.length ? this.renderSkillRecommendation() : <></>
                                    }
                                    {
                                        this.state.conceptRecommendation && this.state.conceptRecommendation.length ? this.renderConceptRecommendation() : <></>
                                    }
                                </div>

                            </div>
                        </>
                }

            </div>
        )
    }
}

export default ClassMockTestPerformanceReport;
export default class AssessmentEngineShared {

    static AssessmentEngine = null
    static isBackgroundFromAssessment = false

    static getAssessmentEngine() {
        return this.AssessmentEngine
    }

    static setAssessmentEngine(Component) {
        this.AssessmentEngine = Component
    }

    static getIsBackgroundFromAssessment() {
        return this.isBackgroundFromAssessment
    }

    static setIsBackgroundFromAssessment(value) {
        this.isBackgroundFromAssessment = value
    }

}
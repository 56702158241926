import Axios from 'axios';
import HttpClient from './http-client';
import URL from './url';

export default class RefresherCourseApi {


    static getPkChapterList(payload) {

        let url = URL.BASE_URL + URL.PK_CHAPTER_LIST;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    

    static getRefresherCourseV2(payload) {

        let url = URL.BASE_URL + URL.REFRESHER_COURSE;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getRefresherLeanring(payload) {

        let url = URL.BASE_URL + URL.REFRESHER_LEARNING;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getRefresherCourseAssessmentContent(payload) {

        let url = URL.BASE_URL + URL.GET_ASSESSMENT_CONTENT_REFRESHER_COURSE;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static updateUserActivityRefresherCourse(payload) {
        return HttpClient.put(URL.BASE_URL + URL.USER_UPDATE_ACTIVITY_REFRESHER_COURSE, null, payload);
    }
    

    
}



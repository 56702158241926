
import React from 'react';
import './workout-learning-content-header.scss';
import { getImageSource, getThemeImageSource } from '../../../../utility/image-source';
import { ImageConstants, Constants } from '../../../../constants/constants';
import SharedThemeCategory from '../../../../../shared-storage/category-theme';
import ReactTooltip from 'react-tooltip';
import WorkoutHeaderPagination from './workout-header-pagination/workout-header-pagination'
// import LandingScreenShared from '../../../../shared-storage/landing-screen';

export default class LearningContentHeader extends React.Component {
    constructor(props) {
        super(props);

        // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()
    }

    componentDidMount() {
        // this.props.navigation.addListener("didFocus", () => {
        //     if(this.props.from && this.props.from == 'TWO_STEP_EVENT_BASELINE'){
        //         this.landingScreenInstance && this.landingScreenInstance.pauseUserAppTime()
        //     }
        // })
    }

    state = {
        showLateralMenu: false
    }

    goBack = () => {

        if (this.props.isChapterIntro) {
            this.props.delegate && this.props.delegate.goToChapterList && this.props.delegate.goToChapterList();
        }
        else {
            this.props.delegate && this.props.delegate.goBackToEloList && this.props.delegate.goBackToEloList();
        }
    }

    onPressAskDoubt = () => {
        this.props.delegate && this.props.delegate.onPressAskDoubt()
    }

    navigate = (index, navigate) => {
        this.props.delegate && this.props.delegate.navigateGroup(index, navigate);
    }

    goToEventStart = () => {
        this.props.navigation.navigate('ScholarshipScreenV2', { meta: {} })
    }

    scrollTo = () => {
        this.props.showPagination && this.paginationComponent.scrollTo();
    }

    toggleLateralMenu = () => {

        if (this.props.from && this.props.from == 'TWO_STEP_EVENT_BASELINE') {
            this.landingScreenInstance && this.landingScreenInstance.resumeUserAppTime()
        }
        this.props.navigation && this.props.navigation.navigate('LateralMenu')
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId);

        // setCategoryThemeStyles(this.props.categoryId);

        return (
            <>
                <div className='learning-content-header' onMouseEnter={ReactTooltip.rebuild} >
                    <div className=''>
                        <img className='learning-content-header-image-size' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, this.props.categoryId)} />
                    </div>
                    <div>

                        {/* {
                        this.props.isScrolled ?
                            <View style={Styles.titleContainer}>

                            </View>
                            : <></>
                    } */}
                        <div>



                            {/* <TouchableOpacity onPress={() => { this.toggleLateralMenu(true) }} style={Styles.actionContainerItems} hitSlop={Styles.hitSlop}>
                            <View>
                                <Image source={getImageSource(ImageConstants.NAV_LATERAL_MENU)} />
                            </View>
                        </TouchableOpacity> */}

                            <div className={`d-flex justify-content-between ${this.props.isChapterIntro ? 'mt-5' : ''}`}>


                                {
                                    !this.props.isScrolled ?
                                        <div>
                                            <div className=''>
                                                <span className='elo-title-text'>{this.props.title ? this.props.title : (this.props.subTitle ? this.props.subTitle : "Let’s quickly recall last workout summary")}</span>
                                            </div>
                                            {
                                                !this.props.isChapterIntro || this.props.isSummaryContent ?
                                                    <div >
                                                        <div className='sub-title-text'>
                                                            {this.props.subTitle}
                                                        </div>
                                                    </div>
                                                    : <></>
                                            }

                                        </div>
                                        : <></>
                                }
                                <div className='d-flex align-items-center'>
                                    {this.props.isPreviousKnowledge ?
                                        <div>
                                            <div data-tip={'READ PREVIOUS KNOWLEDGE'}>
                                                <img className='prev-knowledge-image' src={getImageSource(ImageConstants.NAV_PREV_KNOWLEDGE)} />
                                            </div>
                                        </div>
                                        :
                                        <>

                                        </>
                                    }
                                    {/* <div className='ask-doubt-button' onPress={this.onPressAskDoubt}>
                                    <img className='ask-doubt-image-size' src={getImageSource(ImageConstants.PLUS_ICON)}></img>
                                </div> */}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div>
                        <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.state.categoryThemeId)}></img>
                    </div>

                    {/* {this.state.showLateralMenu && <LateralMenuPopup delegate={this} ></LateralMenuPopup>} */}
                </div>

                {
                    this.props.showPagination &&
                    <div className='d-flex justify-content-center mt-4 mb-5'>
                        <WorkoutHeaderPagination delegate={this} totalPage={this.props.totalPage} activeIndex={this.props.activeIndex} ref={(ref) => { this.paginationComponent = ref }} navigateTill={this.props.navigateTill} />
                    </div>
                }
            </>
        );
    }
}


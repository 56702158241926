import React, { Component } from 'react';
import Parser from '../../content-parser/content-parser';
import { Constants } from '../../../../constants/constants';
import './radio-button.scss';
import CWImageV2 from '../../cw-image-2/cw-image-2';
import SharedThemeCategory from '../../../../../shared-storage/category-theme';



export default class RadioButton extends React.Component {

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.label = this.props.label.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");
        this.label = this.label.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");
    }

    onClick(event) {

        if (this.props.onOptionPressed) {
            this.props.onOptionPressed(true);
        }
    }

    getLabel() {

        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        if (this.props.contentType == Constants.CONTENT_TYPE_TEXT) {
            return (
                this.parser.parseWithStyles(`${this.label}`, { p: styles.p, 'cw-math': styles.p, 'cw-blank': styles.p })
            )
        } else if (this.props.contentType == Constants.CONTENT_TYPE_IMAGE) {
            return (
                // <Image source={{ uri: this.props.label }} style={styles.image} resizeMode={'contain'}></Image>
                <CWImageV2 uri={this.props.label} key={this.props.label} />
            )
        }
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId);

        return (
            <div onClick={this.onClick.bind(this)} className='cursor-pointer' >
                <div className='radio-container row flex-row justify-content-start align-items-center'>
                    <div className=' col-2 m-0 p-0 d-flex justify-content-center align-items-center radio-max-width' >
                        <div className='radio-circle' style={{ borderColor: this.theme ? `${this.theme['secondaryColors']['assessmentOptionsBorder']}` : '#353535' }}>
                            <div className={`${this.props.isChecked === true ? 'inner-selected' : ''}`} style={{ backgroundColor: this.props.isChecked === true ? this.theme['themeColor'] : 'none' }}></div>
                        </div>

                    </div>
                    <div className=' col m-0 p-0 lable-text'>
                        {this.getLabel()}
                    </div>
                </div>
            </div>
        );
    }
}


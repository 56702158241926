import React from "react";
import './case-study-start-screen.scss';
import { getImageSource, getThemeImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import PracticeApi from "../../../../apis/practice";
import CwContentViewer from "../../../common/cw-content-viewer/cw-content-viewer";
import QuestionImage from "../../../common/cw-question-image/cw-question-image";
import AssessmentApi from "../../../../apis/assessment";

class CaseStudyStartScreen extends React.Component {


    state = {
        question: {
            caseStudyDescription: ''
        }
    }

    constructor(props) {
        super(props)
        this.meta = this.props.meta
        this.chapterKey = this.meta.chapterKey
        this.state.chapterTitle = this.meta.chapterTitle
        this.state.categoryId = this.meta.categoryThemeId


        this.getMyAssessmentInfo()

    }

    componentDidMount() {
        this.getPracticeQuestion()
    }

    getMyAssessmentInfo() {
        let payload = {
            chapterKey: this.chapterKey,
            duration: this.state.selectedDuration
        }


        if (this.meta && this.meta.isFromPracticeCaseStudy) {
            payload.isFromPracticeCaseStudy = true;
            payload.practiceActivityId = this.meta.practiceActivityId
        }


        AssessmentApi.getMyAssessmentInfo(payload).then((res) => {
            console.log(res)
            this.setState({
                questionCount: res.noOfQuestion,
                questionSetId: res.questionSetId,
                duration: Math.round(res.time / 60),
                totalMarks: res.totalMarks
            })
            // this.toggleLoading(false)
        }).catch(err => {
            //console.log(err)
            if (err) {
                // this.toggleLoading(false)

                if (err.response && err.response.data && err.response.data.code == 1801) {
                    // this.props.navigation.navigate('EventEndScreen', {
                    //     meta: {
                    //         title: this.meta.chapterTitle,
                    //         categoryThemeId: '1',
                    //         assessmentId: this.meta.assessmentId,
                    //         eventId: this.meta.eventId
                    //     }
                    // })
                }
                return;
            }
        })



    }


    getPracticeQuestion() {

        let payload = {
            practiceId: this.meta.practice.practiceId
        }
        PracticeApi.getExamCaseStudyPracticeQuestion(payload).then(res => {
            console.log(res.practiceQuestion.question)
            if (res.practiceQuestion.question) {
                this.setState({ question: res.practiceQuestion.question })
            }

        }).catch(err => {
            console.log(err)
        })
    }

    goBack() {
        this.props.delegate && this.props.delegate.hideCaseStudyStartScreen && this.props.delegate.hideCaseStudyStartScreen()
    }

    goToCaseStudyListScreen() {
        this.props.back && this.props.back()  
    }

    goToPracticeQuestionsChapterList() {
        this.props.delegate.goToPracticeQuestionsChapterList && this.props.delegate.goToPracticeQuestionsChapterList()
    }

    goToHomeScreen() {
        this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    showAssessmentEngine() {
        this.props.delegate && this.props.delegate.showAssessmentEngine && this.props.delegate.showAssessmentEngine({
            chapterKey: this.chapterKey,
            chapterTitle: this.state.chapterTitle,
            questionSetId: this.state.questionSetId,
            categoryThemeId: this.state.categoryId,
            type: this.meta.type,
            assessmentId: this.meta.assessmentId,
            from: this.meta.from,
            selectedDuration: this.state.selectedDuration,
            selectedChapters: this.chapterTitles,
            practiceChapters: this.meta.practiceChapters ? this.meta.practiceChapters : '',
            practice: this.meta.practice,
            isFromPracticeCaseStudy: this.meta.isFromPracticeCaseStudy,
            practiceActivityId: this.meta.practiceActivityId,
            viewAnswerTime: this.meta && this.meta.viewAnswerTime,
            // from: this.meta.from
        })
    }

    goToSelectedChapter() {
        this.props.delegate && this.props.delegate.goToSelectedChapter && this.props.delegate.goToSelectedChapter()
    }



    renderBreadcrumbForPracticeQuestions() {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToCaseStudyListScreen()} >{`${this.state.chapterTitle} >`}</span>
                <span className='title-1 cursor-pointer pl-2' onClick={() => this.goToSelectedChapter()} >{`Practice >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBack()}>{`Case Studies > `}</span>
                <span className='active pl-2'><CwContentViewer content={this.state.question.caseStudyTitle} /></span>
            </div>
        )
    }

    render() {
        return (
            <div className="case-study-start-screen">
                <div className="text-right">
                    <img className="image-size" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, 1)}></img>
                </div>
                <div className="header-for-practice-question">
                    {this.renderBreadcrumbForPracticeQuestions()}
                    <div className="title">Case Studies</div>
                </div>
                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, 1)}></img>
                </div>
                <div className="description-wrapper">
                    <div className="mb-2 case-study-title"><CwContentViewer content={this.state.question.caseStudyTitle} /></div>
                    <div className="mb-2 case-study-description">
                        <CwContentViewer content={this.state.question.caseStudyDescription} />
                    </div>
                    <div className="mb-2">
                        <QuestionImage images={this.state.question.images} />
                    </div>
                    <div className="d-flex align-items-center mt-3">
                        <img className="bulb-image" src={getImageSource(ImageConstants.BULB)} />
                        <span className="ml-2 info-text">This Case Study has multiple questions</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-3 mb-5">
                        {
                            this.state.question.caseStudyQuestions && this.state.question.caseStudyQuestions.length &&
                            this.state.question.caseStudyQuestions.map((question, index) => (
                                <div className="d-flex align-items-center justify-content-center question-number-circle mr-4">
                                    <div className="question-number">{index + 1}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="case-study-info-footer">
                    <div className="d-flex justify-content-end align-items-end button-wrapper">
                        {/* {startIndex != 0 ? <div className="text-center back-button cursor-pointer mr-5">Back</div> : <></>} */}

                        <div className="text-center next-button cursor-pointer" style={{ background: '#6B52C8' }}
                            onClick={() => this.showAssessmentEngine()}>Continue</div>
                    </div>
                    <img className='footer-image-size' src={getImageSource(ImageConstants.ASSESSMENT_FOOTER_IMAGE)} />
                </div>
            </div>
        )
    }

}

export default CaseStudyStartScreen;
import React from "react";
import './practice-end-screen.scss';

class PracticeEndScreen extends React.Component {


    render() {
        return (
            <div className="practice-end-screen">
                <div className="text-center mt-5 header-text">Thank You !!!</div>
            </div>

        )
    }
}

export default PracticeEndScreen;
import React from "react";

/** MATH JAX VIEWER */
 import MathJax from "react-mathjax2";
 


/** LATEX VIEWER */
import Latex from "react-latex";
import '../../../assets/vendor/katex/dist/katex.min.css';

/** KATEX VIEWER */
// import { InlineMath, BlockMath } from 'react-katex';
// import 'katex/dist/katex.min.css';


const CwMath = props => {

  //console.log(props)

  return (
    /** MATH JAX VIEWER */
    //  <MathJax.Context input="tex"> 
     
    //  <MathJax.Node inline>{props.mathExp}</MathJax.Node>
     
    //  </MathJax.Context> 

    /** LATEX VIEWER */
    
       <Latex  colorIsTextColor = {true}  >{`$${props.mathExp}$`}</Latex>

    

    /** KATEX VIEWER */
    /**  <InlineMath>{props.mathExp}</InlineMath> */
  );
};

export default CwMath;

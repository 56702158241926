import React from 'react';
import './workout-learning-content-wrapper.scss';
import LearningContent from '../learning-content/learning-content';
import { ImageConstants, Constants, FirebaseEvents } from '../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import LearningContentFooter from '../learning-footer/learning-footer';
import WorkoutLearningContentHeader from './workout-learning-content-header/workout-learning-content-header';
import LearningContentPopup from '../learning-content-popup/learning-content-popup';
import GlossaryParser from "../../common/content-parser/glossary-parser";
import SharedProfile from '../../../../shared-storage/profile';

// import WorkoutAskDoubt from './workout-ask-doubt/workout-ask-doubt'
// import DoubtSuccessPopUp from '../../../components/common/doubt-success-pop-up/doubt-success-pop-up'

// import DoubtLimitPopUp from '../../doubts/doubt-limit-popup/doubt-limit-popup'
// import AsyncStore from '../../../shared-storage/async-store';
// import BookmarkHelp from '../../../components/common/bookmark-help/bookmark-help'
import FireBaseEvent from '../../../firebase-events/firebase-events'
import Loading from '../../common/loading/loading';
import CommonApi from '../../../apis/common';
import PracticeQuestionsApi from '../../../apis/practice-questions';



export default class LearningContentWrapper extends React.Component {
    constructor(props) {
        super(props);
        // const { navigation } = this.props;

        this.state.elo = this.props.elo;

        this.glossaryParser = new GlossaryParser();
        this.userInfo = SharedProfile.getProfile();
        // this.handleBackButton = this.handleBackButton.bind(this);

        if (this.props.navigationInfo && this.props.navigationInfo.isLateralMenuNavigation) {
            let index = 0;
            if (this.props.elo) {
                this.props.elo.map((elo, i) => {
                    if (elo.eloId == this.props.navigationInfo.activeEloId) {
                        index = i;
                    }
                });
                this.state.eloIndex = index;
            }
        }

        if (this.props.isChapterIntro) {
            this.logNavigation({ ...this.props.navigationInfo });
        } else {
            this.logEloNavigation();
        }



    }

    nextLearningContentMap = {};
    allElosLearningHash = {};

    videoPlayerRefs = {};
    state = {
        eloIndex: 0,
        fadeIn: false,
        showShadow: false,
        glossaryContent: null,
        blurNavigationFeedBack: false,
        elo: [],
        isLast: false,
        scrollViewHeight: null,
        showAskDoubt: false,
        showSuccessPopup: false
    };

    // animatedScroll = new Animated.Value(0);

    pageLength = this.props.elo ? this.props.elo.length : 0;
    currentPage = this.props.elo && this.props.elo.length ? 1 : 0;

    componentDidMount() {

        this.getGlossaryContent();
        // KeepAwake.activate();
        // BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
        this.prepareLearningContentVisibility();
        this.getBookmarkHelpShowed()
    }

    componentWillUnmount() {
        // KeepAwake.deactivate();
        // BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
    }


    logNavigation(navigationInfo) {
        if (false) {
            if (navigationInfo)
                navigationInfo.navigateChapterIntroduction = true; // Flag added to call to identify call from navigation

            let payload = {
                type: "CHAPTER",
                info: navigationInfo
            }

            // Api.logUserNavigation(payload, (err, res) => {
            //     if (err) {
            //         return;
            //     }
            //     console.log("navigation logged");
            // });
        }
    }

    hideChapterIntroduction = () => {
        this.props.delegate && this.props.delegate.hideChapterIntroduction()
    }


    logEloNavigation = (isLast) => {
        // let userInfo = SharedProfile.getProfile();
        // console.log("this.userInfothis.userInfo", this.userInfo)
        // if (userInfo && userInfo.isSubscribed) {
        if (false) {
            if (isLast) {
                let payload = {
                    type: "ELO",
                    eloStatus: "IN_ACTIVE",
                    nodeId: this.props.navigationInfo ? this.props.navigationInfo.nodeId : ""
                }
                // Api.logUserNavigation(payload, (err, res) => {
                //     if (err) {
                //         return;
                //     }
                //     console.log("navigation logged");
                // });
            } else {

                let elo = this.props.elo[this.state.eloIndex];
                let navigationInfo = {};

                if (this.props.elo[this.state.eloIndex]) {
                    navigationInfo.chapterTitle = this.props.title;
                    navigationInfo.eloId = elo.eloId;
                    navigationInfo.title = elo.eloTitle;
                    navigationInfo.otherInfo = this.props.navigationInfo;
                    navigationInfo.nodeId = this.props.navigationInfo ? this.props.navigationInfo.nodeId : "";
                    navigationInfo.isWorkout = true;
                }

                let payload = {
                    type: "ELO",
                    info: navigationInfo,
                    eloStatus: "ACTIVE"
                }

                // Api.logUserNavigation(payload, (err, res) => {
                //     if (err) {
                //         return;
                //     }
                //     console.log("navigation logged");
                // });
            }
        }
    }


    getGlossaryContent = () => {

        if (this.props && this.props.elo && this.props.elo.length > 0) {

            let elos = this.props.elo

            let contents = [];

            for (let i = 0; i < elos.length; i++) {

                let contentsList = elos[i]['contents'];

                for (let j = 0; j < contentsList.length; j++) {

                    contents = contents.concat(contentsList[j].contents)
                }
            }

            if (contents.length > 0) {

                this.glossaryParser.getParsedContentIdsFromContent(contents, (err, glossaryContent) => {
                    this.setState({
                        glossaryContent
                    })
                })

            } else {
                this.setState({
                    glossaryContent: {}
                })
            }

        } else {
            this.setState({
                glossaryContent: {}
            })
        }
    };


    resetScrollViewValues() {
        //Reset Scroll Content height on next
        // this.contentScrollHeight = 0;
        // this.lastHeaderHeight = this.headerHeight != 0 ? this.headerHeight : this.lastHeaderHeight;
        // this.headerHeight = 0;
        // this.isScrollStart = false;
        // this.setState({ scrollViewHeight: 0, showShadow: false })

    }

    nextElo = () => {

        console.log('next elo called')

        this.resetScrollViewValues();

        if (this.state.eloIndex < this.props.elo.length - 1) {

            console.log('next elo called 1')
            this.setState({
                eloIndex: this.state.eloIndex + 1,
                fadeIn: true,
                //scrollViewHeight: 0
            }, () => {

                if (this.state.eloIndex > this.navigateTill) {
                    this.navigateTill = this.state.eloIndex
                }
                this.logEloNavigation();
                this.prepareLearningContentVisibility();
            })

        }
        else {
            // if (!this.props.isChapterIntro)
            //     this.logEloNavigation(true);
            this.goBack();
            console.log('next elo called 2')

            let currentNode = this.getCurrentNode();

            // console.log(this.props.conceptTitle, this.props.chapterTitle, this.props.currentEloSkill, this.props.title)

            // Moengage.logCustomEvent('Elo', { "User Id":this.userInfo.userId, 'Elo Name': this.state.eloName, 'Chapter':this.props.title,'Elo Skill':this.state.eloSkill,'Elo Status':this.state.eloStatus,concept:this.props.currentConcept.title,'Starting Point':'Browse Chapters'});
            FireBaseEvent('Elo', { "User Id": this.userInfo.userId, 'Elo Name': !this.props.isChapterIntro && this.props.elo[this.state.eloIndex].eloTitle, 'Chapter': this.props.title, 'Elo Skill': !this.props.isChapterIntro && this.props.elo[this.state.eloIndex].eloSkill, 'Elo Status': "CLEARED", concept: currentNode.conceptTitle, 'Starting Point': 'Browse Chapters' });
            // Facebook.logCustomEvent('Elo', { "User Id":this.userInfo.userId, 'Elo Name': this.state.eloName, 'Chapter':this.props.title,'Elo Skill':this.state.eloSkill,'Elo Status':this.state.eloStatus,concept:this.props.currentConcept.title,'Starting Point':'Browse Chapters'});
            // BranchIO.logCustomEvent('Elo', { "User Id":this.userInfo.userId, 'Elo Name': this.state.eloName, 'Chapter':this.props.title,'Elo Skill':this.state.eloSkill,'Elo Status':this.state.eloStatus,concept:this.props.currentConcept.title,'Starting Point':'Browse Chapters'});
            FireBaseEvent(FirebaseEvents.ELO_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, eloName: !this.props.isChapterIntro && this.props.elo[this.state.eloIndex].eloTitle, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
            // Facebook.logCustomEvent(FirebaseEvents.ELO_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, eloName: this.props.data.cleared[0].title, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
            // BranchIO.logCustomEvent(FirebaseEvents.ELO_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, eloName: this.props.data.cleared[0].title, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });

        }
        this.headerComponent.scrollTo();
        // this.scroll.scrollTo({top: 100,
        //     left: 100,
        //     behavior: "smooth"});


        this.hidePagination();
    }

    goBack = () => {

        let isRevise = this.props.isRevise ? this.props.isRevise : false;

        this.props.delegate && this.props.delegate.learningElosCompleted && this.props.delegate.learningElosCompleted(isRevise);

    }

    previousElo = () => {

        this.resetScrollViewValues();

        if (this.state.eloIndex >= 1) {
            this.setState({
                eloIndex: this.state.eloIndex - 1,
                fadeIn: true
            }, () => {
                this.logEloNavigation();
                this.prepareLearningContentVisibility();
            })
            this.scroll.scrollTo({ x: 0, y: 0, animated: false });
        }
        // this.scroll.scrollTo({x: 0, y: 0, animated: false});
        this.hidePagination();
    }

    hidePagination = () => {
        setTimeout(() => { this.setState({ fadeIn: false }) }, 1000)
    }

    showPagination = () => {
        this.setState({
            fadeIn: true
        })
    }

    renderPagination = (position) => {

        // let previousKnowledge = this.props.elo.filter(item => item.isPreviousKnowledge)

        // if (previousKnowledge.length > 1 && this.state.eloIndex < previousKnowledge.length - 1) {
        //     return (
        //         previousKnowledge.map((_, i) => {


        //             return (
        //                 <View style={{ flexDirection: 'row', alignItems: 'center' }}>

        //                     <View style={this.state.eloIndex == i ? Styles.dot : Styles.inActiveDot}>
        //                         <Text style={this.state.eloIndex == i ? Styles.activeIndex : Styles.inActiveIndex}>{i + 1}</Text>
        //                     </View>
        //                     {i < previousKnowledge.length - 1 ?

        //                         <View style={Styles.dotConnector}></View>
        //                         :
        //                         <></>

        //                     }

        //                 </View>

        //             );

        //         })

        //     )
        // }

    }


    toggleShadow = () => {
        if (!this.isScrollStart)
            this.setState({ showShadow: !this.state.showShadow });
        else
            this.setState({ showShadow: !this.state.showShadow })
    }

    scrollUp = () => {
        // if (this.pageYPosition > screenHeight - wp(50))
        //     this.scroll.scrollTo({ x: 0, y: this.ycontentOffsetPosition + wp(50) });
    }

    

    async getBookmarkHelpShowed() {
        // let doc = await AsyncStore.getBookmarkHelp();

        // if (doc) {
        //     doc = JSON.parse(doc)

        //     if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`]) {

        //         doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`] = true

        //         AsyncStore.setBookmarkHelp(doc)

        //         this.setState({ showBookMarkHelp: true })
        //     }
        // } else {
        //     AsyncStore.setBookmarkHelp({ [`${this.userInfo.mobileNumber}_${this.userInfo.userName}_BOOKMARK`]: true })
        //     this.setState({ showBookMarkHelp: true })
        // }
    }

    hideOverlayHelp = () => {
        this.setState({ showBookMarkHelp: false })
    }

    navigateGroup = (index, navigate) => {

        this.resetScrollViewValues();

        this.setState({
            eloIndex: index,
            fadeIn: true
        }, () => {
            this.logEloNavigation();
            this.prepareLearningContentVisibility();
        })

        this.navigateTill = navigate

        //this.headerComponent.scrollTo();

        // this.scroll.scrollTo({ x: 0, y: 0, animated: false });

        this.hidePagination();
    }

    // handleBackButton() {
    //     if (this.props.isChapterIntro) {
    //         this.props.delegate && this.props.delegate.hideChapterIntroduction && this.props.delegate.hideChapterIntroduction()
    //         return true;
    //     }
    // }

    getCurrentNode = () => {
        let nodes = this.props.navigationInfo.nodes;
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id == this.props.navigationInfo.nodeId) {
                return nodes[i];
            }
        }
    }

    prepareLearningContentVisibility() {

        console.log(this.props.noBlur)

        let numOfElos = this.props.elo.length;

        for (let i = 0; i < numOfElos; i++) {

            let numOfObjectsInElo = this.props.elo[i].contents.length;

            let blurFlag = false;
            let showEye = false;
            for (let j = 0; j < numOfObjectsInElo; j++) {

                this.props.elo[i].contents[j].contents.map((content, index) => {

                    if (content.type == 3 && blurFlag == false && !this.nextLearningContentMap[content._id] && !this.props.reviseChapterIntroduction && !this.props.noBlur) {
                        console.log('inside blur')
                        content.showBlur = blurFlag;
                        content.showEye = showEye;
                        blurFlag = true;
                        showEye = true;
                    }
                    else {
                        console.log('inside no blur')
                        content.showBlur = blurFlag;
                        content.showEye = showEye;
                        showEye = false;
                    }
                });
            }

        }

        let contents = this.props.elo[this.state.eloIndex].contents;
        let eloId = this.props.elo[this.state.eloIndex].eloId + this.state.eloIndex;
        let blurNavigationFeedBack = false;
        let checkPointQuestions = [];
        let noOfQuestionsAnswered = this.allElosLearningHash[eloId] ? Object.keys(this.allElosLearningHash[eloId]).length : 0;
        contents.map(group => {

            group.contents.map(data => {

                if (data.type == 3) {
                    checkPointQuestions.push(data)
                }
            })

        });
        if (checkPointQuestions.length > 0 && checkPointQuestions.length != noOfQuestionsAnswered && !this.props.reviseChapterIntroduction && !this.props.noBlur) {

            blurNavigationFeedBack = true;

        }

        // console.log('blur contents', this.props.elo);

        this.setState({
            eloIndex: this.state.eloIndex,
            fadeIn: true,
            blurNavigationFeedBack: blurNavigationFeedBack
        });

    }

    refresh() {
        this.scroll.scrollTo({ x: 0, y: this.ycontentOffsetPosition - 60, animated: true })
    }

    openNextLearningContent(content) {

        let eloId = this.props.elo[this.state.eloIndex].eloId + this.state.eloIndex;

        this.nextLearningContentMap[content._id] = content;

        if (this.allElosLearningHash[eloId]) {
            this.allElosLearningHash[eloId][content._id] = content;
        }
        else {
            this.allElosLearningHash[eloId] = { [content._id]: content }
        }

        this.prepareLearningContentVisibility();
    }

    onClickBookMarkActive = (id, index) => {

        let payload = { _id: id }

        PracticeQuestionsApi.deleteBookMark(payload).then((res) => {

            let elo = this.state.elo;

            delete elo[this.state.eloIndex]['bookMarkedItem'][index];

            this.setState({ elo })

        }).catch(err => {
            if (err) {
                return;
            }
        })

    }

    renderLearningContent = () => {

        // console.log("elo", this.props.elo)

        let element = [];
        if (this.state.glossaryContent && this.state.elo[this.state.eloIndex].contents) {

            let contentLength = this.state.elo[this.state.eloIndex].contents.length;

            for (let i = 0; i < contentLength; i++) {

                if (i != 0) {
                    element.push(
                        <img className='d-flex justify-content-center' style={{ maxWidth: "100%", objectFit: "contain", marginBottom: "30px" }} src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryId)} resizeMode="cover" />

                    );
                }
                let contentsPerElo = this.state.elo[this.state.eloIndex].contents[i].contents;

                let groupTitle = this.state.elo[this.state.eloIndex].contents[i].title;

                let contentObjArray = contentsPerElo.map(item => ({ id: item._id, objectType: item.type, contentType: item.content.type }))

                let contentObj = { title: groupTitle, contentIds: contentObjArray }

                let bookMarkId = this.state.elo[this.state.eloIndex]['bookMarkedItem'] && this.props.elo[this.state.eloIndex]['bookMarkedItem'] && this.props.elo[this.state.eloIndex]['bookMarkedItem'][i] && this.props.elo[this.state.eloIndex]['bookMarkedItem'][i].bookMarkId ? this.props.elo[this.state.eloIndex]['bookMarkedItem'][i].bookMarkId : null

                contentsPerElo.map((data, index) =>

                    element.push(
                        <div key={data._id}>
                            <LearningContent
                                content={data}
                                isRefresherCourse = {this.props.navigationInfo.isRefresherCourse}
                                delegate={this}
                                glossary={this.state.glossaryContent}
                                groupTitle={groupTitle}
                                showTitle={index == 0 ? true : false}
                                categoryId={this.props.elo[this.state.eloIndex].categoryThemeId}
                                contentObj={contentObj} 
                                showInactiveBookmark={bookMarkId ? false : true}
                                showBookMark={bookMarkId ? true : false} bookMarkId={bookMarkId} selectedIndex={i} />
                            {index < contentsPerElo.length - 1 &&
                                <img className='d-flex justify-content-center' style={{ maxWidth: "100%", objectFit: "contain", marginBottom: "30px" }} src={getImageSource(ImageConstants.CONTENT_DIVIDER_GRAY)} />
                            }
                        </div>
                    )
                )
            }
            return element;
        }
    }



    togglePopup = (flag) => {
        if (this.props.elo[this.state.eloIndex].previousKnowledge && this.props.elo[this.state.eloIndex].previousKnowledge.length)
            this.setState({ showLearningPopup: flag });
    }

    onSwipeRight() {

        this.previousElo();
    }

    onSwipeLeft() {
        if (!this.state.blurNavigationFeedBack && this.state.eloIndex != this.props.elo.length - 1)
            this.nextElo();
    }

    setScrollRef = (ref) => {
        this.scroll = ref;
    }

    onClickBookMark = (contentObj, index) => {

        console.log('>>>>???', contentObj, index)

        let payload = {
            chapterKey: this.props.navigationInfo.chapterKey,
            contents: contentObj
        }

        if (this.props.elo[this.state.eloIndex].isChapterIntroduction || this.props.isChapterIntro) {
            payload['isChapterIntro'] = true
        } else {
            payload['eloId'] = this.props.elo[this.state.eloIndex]['eloId'];
        }

        PracticeQuestionsApi.createBookMark(payload).then((res) => {

            console.log(res)

            let elo = this.state.elo;

            if (elo[this.state.eloIndex]['bookMarkedItem'])
                elo[this.state.eloIndex]['bookMarkedItem'][index] = { bookMarkId: res.data.bookMarkId }
            else
                elo[this.state.eloIndex]['bookMarkedItem'] = { [index]: { bookMarkId: res.data.bookMarkId } }

            if (this.props.isRivisionSummary) {
                // moengage.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.workoutChapterTitle, Type: 'Revision Summary' })
                FireBaseEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.workoutChapterTitle, Type: 'Revision Summary' })
                // Facebook.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.workoutChapterTitle, Type: 'Revision Summary' })
                // BranchIO.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.workoutChapterTitle, Type: 'Revision Summary' })


            } else if (this.props.isWorkoutChapterIntroduction) {
                // moengage.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.workoutChapterTitle, Type: 'Chapter Introduction' })
                FireBaseEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.workoutChapterTitle, Type: 'Chapter Introduction' })
                // Facebook.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.workoutChapterTitle, Type: 'Chapter Introduction' })
                // BranchIO.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.workoutChapterTitle, Type: 'Chapter Introduction' })

            } else {
                // moengage.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })
                FireBaseEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })
                // Facebook.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })
                // BranchIO.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.props.title, Elo: !this.props.isChapterIntro ? this.props.elo[this.state.eloIndex].eloTitle : 'Chapter Introduction' })
            }


            this.setState({ elo })
        }).catch(err => {
            if (err) {
                return;
            }
        })

    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
    }

    onPressAskDoubt = () => {

        this.setState({ loading: true })

        let payload = {};

        // Api.getDoubtsLimti(payload, (err, res) => {
        //     if (err) {
        //         this.setState({ loading: false })
        //         return;
        //     }

        //     if (!res || res.doubtsLimitType == 'LIMITED' && res.maxDoubt == 0) {
        //         this.setState({
        //             loading: false,
        //             showDoubtLimitPopUp: true
        //         })
        //         return
        //     }

        //     this.doubtLimitPayload = res
        //     this.setState({ loading: false, showAskDoubt: true })
        // })
    }

    closeAskDoubt = () => {
        this.setState({ showAskDoubt: false })
    }

    onSuccessDoubt = () => {
        this.setState({ showAskDoubt: false }, () => {
            this.showAskDoubtSuccessPopUp()
        })
    }

    showAskDoubtSuccessPopUp = () => {
        this.setState({ showSuccessPopup: true })
    }

    hideSuccessPopUp = () => {
        this.setState({ showSuccessPopup: false })
    }

    hideDoubtLimitPopUp = () => {
        this.setState({ showDoubtLimitPopUp: false })
    }

    render() {

        // setCategoryThemeStyles(this.props.elo[this.state.eloIndex].categoryThemeId);

        console.log(this.props.navigationInfo.nodes)

        return (
            <>
                {!this.state.loading ? <>{
                    !this.state.showLearningPopup &&
                    <div className='workout-learning-content-wrapper'>
                        <div>

                            <div>
                                <WorkoutLearningContentHeader
                                    isScrolled={this.state.showShadow}
                                    title={!this.props.isChapterIntro && this.props.elo[this.state.eloIndex].eloTitle}
                                    subTitle={this.props.title}
                                    delegate={this}
                                    isChapterIntro={this.props.isChapterIntro}
                                    isChapterIntroRevise={this.props.isChapterIntroRevise}
                                    categoryId={this.props.elo[this.state.eloIndex].categoryThemeId}
                                    isPKExist={this.props.elo[this.state.eloIndex].previousKnowledge && this.props.elo[this.state.eloIndex].previousKnowledge.length}
                                    isPreviousKnowledge={this.props.elo[this.state.eloIndex].isPreviousKnowledge}
                                    isChapterIntroduction={this.props.elo[this.state.eloIndex].isChapterIntroduction}
                                    estimatedLearningTime={this.props.estimatedLearningTime && this.props.estimatedLearningTime}
                                    headerHeight={this.headerHeight}
                                    totalPage={this.pageLength ? this.pageLength : 0}
                                    activeIndex={this.state.eloIndex ? this.state.eloIndex : 0}
                                    navigateTill={this.navigateTill ? this.navigateTill : this.state.eloIndex}
                                    ref={(ref) => { this.headerComponent = ref }}
                                    showPagination={this.props.elo.length === 1 ? false : true}
                                    from={this.props.from}
                                    navigation={this.props.navigation}
                                    isMplWorkout={this.props.isMplWorkout}
                                />
                            </div>

                            <div >
                                {this.renderLearningContent()}
                            </div>

                            <div className='pagination-container' >
                                <div className='dot-container d-flex align-items-center justify-content-center' >{this.renderPagination()}</div>
                            </div>
                            <LearningContentFooter
                                blur={this.state.blurNavigationFeedBack}
                                // style={Styles.footer}
                                delegate={this}
                                isChapterIntro={this.props.isChapterIntro}
                                isRefresherCourse = {this.props.navigationInfo.isRefresherCourse}
                                categoryId={this.props.elo[this.state.eloIndex].categoryThemeId} />

                        </div>
                    </div>
                }</> : <Loading />}


                {/* {this.state.showDoubtLimitPopUp ? <DoubtLimitPopUp close={this.hideDoubtLimitPopUp} categoryThemeId={this.props.elo[this.state.eloIndex].categoryThemeId ? this.props.elo[this.state.eloIndex].categoryThemeId : "1"}></DoubtLimitPopUp> : <></>} */}
                {/* To Show the PK Content */}
                {this.state.showLearningPopup && <LearningContentPopup elos={this.props.elo[this.state.eloIndex].previousKnowledge} title={this.props.title} delegate={this} chapterKey={this.props.navigationInfo.chapterKey} />}

                {/* {this.state.showAskDoubt && <WorkoutAskDoubt delegate={this} doubtLimitPayload={this.doubtLimitPayload} eloDetails={this.props.elo[this.state.eloIndex]} chapterTitle={this.props.title} categoryThemeId={this.props.elo[this.state.eloIndex].categoryThemeId} chapterKey={this.props.navigationInfo.chapterKey} />}

                {this.state.showSuccessPopup && <DoubtSuccessPopUp close={this.hideSuccessPopUp} categoryThemeId={this.props.elo[this.state.eloIndex].categoryThemeId} />}

                {this.state.showBookMarkHelp && <BookmarkHelp showModal={this.state.showBookMarkHelp} headerHeight={this.headerHeight} delegate={this} fromWorkout={true} type={'BOOKMARK'} />} */}

            </>
        );
    }
}




import React from 'react';
import './custom-image-renderer.scss';
import { getImageSource } from '../../../../utility/image-source';
import { ImageConstants } from '../../../../constants/constants';

export default class CustomImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDefault: true,
            error: false,
            renderImage: true,
            firstLoadedGif: this.props.uri,
            loaded: this.props.uri

        };
        console.log(this.props.uri)
    }


    toggleLoading = () => {
        this.setState({ showDefault: false })
    };

    toggleError = () => {
        this.setState({ showDefault: false, error: true, renderImage: false })
    };

    reloadImage = () => {
        this.setState({ showDefault: true, error: false, renderImage: true })
    };

    reloadGif = () => {
        this.setState({ loaded: '', showDefault: true, error: false }, () => {
            setTimeout(() => {
                this.setState({ loaded: this.state.firstLoadedGif })
            }, 0)
        })
    }

    previewImage() {
        this.props.previewImage && this.props.previewImage(this.props.uri)
    }


    render() {
        // setCategoryThemeStyles(this.props.categoryId);
        // console.log(this.state.renderImage)



        return (
            <>
                {this.props.uri && this.state.renderImage ?

                    <div className='custom-image-renderer' >
                        {

                            <div className='d-flex flex-column justify-content-start' style={{}}>
                                <div className='ml-4'>
                                    {
                                        this.props.isQuestionImage ?
                                            <img className='cursor-pointer' onClick={() => this.previewImage()}
                                                style={{
                                                    maxWidth: this.props.styles.maxWidth,
                                                    maxHeight: this.props.uri.toString().endsWith("gif") ? '15rem' :this.props.styles.maxHeight,
                                                    objectFit: this.props.styles.objectFit,
                                                }}
                                                onLoad={this.toggleLoading}
                                                onError={this.toggleError}
                                                src={this.state.loaded}
                                                key={this.props.uri}
                                            >
                                            </img>
                                            :
                                            <img className='cursor-pointer' onClick={() => this.previewImage()}
                                                style={{
                                                    // maxWidth: this.props.styles.maxWidth,
                                                    // maxHeight: this.props.uri.toString().endsWith("gif") ? '15rem' :this.props.styles.maxHeight,
                                                    width: this.props.styles.width,
                                                    height: this.props.uri.toString().endsWith("gif") ? '15rem' : this.props.styles.height,
                                                    marginTop: this.props.styles.marginTop,
                                                    marginBottom: this.props.uri.toString().endsWith("gif") ? '0px' : this.props.styles.marginBottom,
                                                    objectFit: this.props.styles.objectFit,
                                                    border: '1px solid #6f42c1',
                                                    padding: '0.8rem',
                                                    borderRadius: '8px'
                                                }}
                                                onLoad={this.toggleLoading}
                                                onError={this.toggleError}
                                                src={this.state.loaded}
                                                key={this.props.uri}
                                            >
                                            </img>
                                    }

                                </div>
                                {this.props.uri.toString().endsWith("gif") ?
                                    <div className='d-flex ml-4'>
                                        <div className='d-flex align-items-center justify-content-center cursor-pointer play-again-button-wrapper'
                                        style={{width : this.props.styles.width ? this.props.styles.width : '18.3rem'}}
                                            onClick={this.reloadGif}>
                                            <img className='reload-image-size' src={getImageSource(ImageConstants.RELOAD)} />
                                            <span className='ml-2 play-again-text'>Play again</span>
                                        </div>
                                    </div>

                                    :
                                    <></>
                                }
                            </div>
                        }
                        {
                            this.state.showDefault &&
                            <div className='d-flex align-items-center justify-content-center play-again-button-wrapper'>
                                <img className='loding-image-size'
                                    src={this.props.uri.toString().endsWith("gif") ?
                                        getImageSource(ImageConstants.LOADING_IMG_GIF) :
                                        getImageSource(ImageConstants.LOADING_IMG_LOGO)
                                    }
                                >
                                </img>
                                {/* <ActivityIndicator size="large" style={Styles.indicator} color="#FFFFFF" /> */}
                                <span className='ml-2 play-again-text'>
                                    Loading...
                                </span>
                            </div>
                        }
                        {
                            this.state.error &&
                            <div>
                                <img className='loding-image-size'
                                    src={this.props.uri.toString().endsWith("gif") ?
                                        getImageSource(ImageConstants.LOADING_IMG_GIF) :
                                        getImageSource(ImageConstants.LOADING_IMG_LOGO)
                                    }
                                >
                                </img>
                                <div className='d-flex align-items-center justify-content-center cursor-pointer play-again-button-wrapper'
                                    onClick={this.reloadImage}>
                                    <img

                                        className='reload-image-size'
                                        src={getImageSource(ImageConstants.RELOAD)}
                                    >
                                    </img>
                                    <span className='ml-2 play-again-text'>
                                        Refresh to load
                                    </span>
                                </div>
                            </div>
                        }
                    </div>

                    : <></>

                }
            </>
        );
    }


}

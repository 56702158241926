import React, { createRef } from "react";
import './view-certificate.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
// import { Document, Page, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// import { pdfjs } from 'react-pdf';


// PSPDFKit.unload(container);


class ViewCertificate extends React.Component {


    state = {
        pdfUrl : this.props.certificateInfo.url
    }

    constructor(props) {
        super(props)
        this.pdfRef = React.createRef();
    }

    

    componentDidMount() {
        //console.log(this.props.certificateInfo)

        // this.getPdf()
    }


    getPdf = async () => {
        // const container = this.pdfRef.current;
        // const headers = new Headers();
        // headers.set("Access-Control-Allow-Origin", `no-cors`);
        // const pdfResponse = await fetch(this.state.pdfUrl, {headers : headers});
        // //console.log(pdfResponse)
        // // PSPDFKit.unload(container);
        // let pdf = await PSPDFKit.load({container : container, document : this.state.pdfUrl})
        // //console.log(pdf)
    }

    goToCertificateList() {
        this.props.hideCertificate && this.props.hideCertificate()
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToCertificateList()}>{`My Certificates >`}</span>
                <span className='pl-2'>{`View Certificate`}</span>
                {/* <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    render() {
        return (
            <div className="view-certificate">
                <div className="certificate-header">
                    <div className="text-right">
                        <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                    </div>
                    <div className="header-title-margin-top">
                        {this.renderBreadcrumbForChapterList()}
                        <div className="d-flex justify-content-between">
                            <div className="title">My Certificate - {this.props.certificateInfo.eventName}</div>

                        </div>
                        <div ref={this.pdfRef}>
                            {/* <ReactPDF filePath= {this.props.certificateInfo.url} /> */}
                            {/* <PDFViewer fileName = {this.props.certificateInfo.url} /> */}
                            {/* <object data={this.props.certificateInfo.url} type='application/pdf'></object>
                             */}
                             {/* <iframe src={this.props.certificateInfo.url}  /> */}
                        </div>
                    </div>

                    <div>
                        <img className="certificate-size" src={this.props.certificateInfo.url} />
                    </div>



                </div>

            </div>
        )
    }

}

export default ViewCertificate;
export default class SharedProfile {

    static profile = null;


    static isAuthenticated() {

        if (this.profile == null) {
            return null;
        }
        else if (this.profile['isAuthenticated']) {
            return true;
        }

        return false;
    }

    static isVerified() {

        return this.profile?.isVerified;
    }

    static isPasscodeSet() {
        this.profile['passcodeInfo'] = true
    }
    

    static setProfile(profile) {
        //console.log(profile)
        // profile.isSubscribed = true
         this.profile = profile;

        // this.profile = {
        //     "fullName": "class 10",
        //     "nickName": "class 10 sara",
        //     "userName": "class10",
        //     "gender": "M",
        //     "mobileNumber": "8105853633",
        //     "countryCode": "+91",
        //     "section": "",
        //     "board": "Others",
        //     "class": "Class 10",
        //     "city": "Delhi",
        //     "school": {
        //         "id": 1,
        //         "title": "Other"
        //     },
        //     "image": "d6df7b40-ec93-48cc-9c1c-4d0e5c4243ba1611296318433.jpg",
        //     "schoolName": "Countingwell Academy",
        //     "basePath": "https://countingwell-user-profile-pic-dev.s3.ap-south-1.amazonaws.com/",
        //     "state": "Delhi",
        //     "country": "India",
        //     "createdDate": "2023-07-31T15:06:34.982Z",
        //     "timeZone": "Asia/Kolkata",
        //     "workflowId": null,
        //     "isSubscribed": false,
        //     "email": "",
        //     "masterDataId": null,
        //     "metaInfo": {
        //         "funFacts": {
        //             "sequenceNumber": 6,
        //             "lastDisplayedTime": "2020-12-22T09:45:03.067Z",
        //             "displayCount": 6
        //         }
        //     },
        //     "planName": "",
        //     "planTitle": "",
        //     "isUserAtLastClass": false,
        //     "currentVersion": {
        //         "number": "3.35",
        //         "platform": "android",
        //         "updatedDate": "2022-12-22T02:22:06.390Z"
        //     },
        //     "event": {},
        //     "schoolVerificationCode": "SCX-121",
        //     "schoolCode": "SCX-121",
        //     "userId": "5fe0b9fa6b6bd512f971707c",
        //     "isPrepkitFreeUser": true,
        //     "moreInfo": {
        //         "isCompletedWorkoutCompletionScreen": true,
        //         "isCompletedWorkoutReportScreen": true,
        //         "isVideoViewed": true,
        //         "note": "This is done by admin on 17.10.2022",
        //         "currentLevelOfMaths": "INTERMEDIATE",
        //         "dailyGoal": 20,
        //         "daysWantsToPractice": 2,
        //         "fourStepAdded": "This is done by admin on 19.10.2022",
        //         "timeForPractice": "EVENING"
        //     },
        //     "video": {
        //         "url": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/5f26d304-a521-4298-a134-b9bbda1175c6_CW_learning%20app%20video_Reel.mp4",
        //         "tumbnail": "https://countingwell-assets-production.s3.ap-south-1.amazonaws.com/0d60ef48-97cb-4217-9ad6-4ec3cb7ba57b-Group%20624t059.png",
        //         "skipOn": 5
        //     },
        //     "defaultWorkouts": {
        //         "_id": "63058c6589b70373802c61f0",
        //         "type": "DEFAULT_WORKOUTS",
        //         "class": {
        //             "Class 6": {
        //                 "NOVICE": {
        //                     "workflowId": "62b933a5a39e2d2d1630aab3"
        //                 },
        //                 "INTERMEDIATE": {
        //                     "workflowId": "62b9357fa39e2d2d1630aabb"
        //                 },
        //                 "ADVANCE": {
        //                     "workflowId": "62b935c2a39e2d2d1630aac3"
        //                 }
        //             },
        //             "Class 7": {
        //                 "NOVICE": {
        //                     "workflowId": "62b93635a39e2d2d1630aac9"
        //                 },
        //                 "INTERMEDIATE": {
        //                     "workflowId": "62b936aca39e2d2d1630aacf"
        //                 },
        //                 "ADVANCE": {
        //                     "workflowId": "62b9370aa39e2d2d1630aad5"
        //                 }
        //             },
        //             "Class 8": {
        //                 "NOVICE": {
        //                     "workflowId": "62b9376aa39e2d2d1630aadb"
        //                 },
        //                 "INTERMEDIATE": {
        //                     "workflowId": "62b937cba39e2d2d1630aae1"
        //                 },
        //                 "ADVANCE": {
        //                     "workflowId": "62b93825a39e2d2d1630aae7"
        //                 }
        //             },
        //             "Class 9": {
        //                 "NOVICE": {
        //                     "workflowId": "62b93881a39e2d2d1630aaed"
        //                 },
        //                 "INTERMEDIATE": {
        //                     "workflowId": "62b938e6a39e2d2d1630aaf3"
        //                 },
        //                 "ADVANCE": {
        //                     "workflowId": "62b93944a39e2d2d1630aaf9"
        //                 }
        //             }
        //         }
        //     },
        //     "zone": "International",
        //     "alternateBoard": "Others",
        //     "remainingDays": 0,
        //     "isAuthenticated": true,
        //     "isVerified": true,
        //     "name": "class 10 sara"
        // }
     }

    static getProfile() {

        this.profile.name = this.profile.fullName ? this.profile.fullName : this.profile.nickName;
        this.profile.board = this.profile.alternateBoard ? this.profile.alternateBoard : this.profile.board
        return this.profile;
    }

}
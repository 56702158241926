import React from "react";
import './assessment.scss';
import StartAssessment from "./start-assessment/start-assessment";
import ClickerAssessmentEngine from './clicker-assessment-engine/clicker-assessment-engine'
import ClickerAssessmentEndScreen from "./assessment-end-screen/assessment-end-screen";
import LayoutShared from "../common/layout-shared/layout-shared";
import TimeList from "./time-list/time-list";
import ClickerAssessmentLeaderboard from "./clicker-assessment-leaderboard/clicker-assessment-leaderboard";
import AssessmentApi from "../../apis/assessment";

class AssessmentComponent extends React.Component {


    state = {
        // We need to have the Users List, Then only we will know who has answered and not answered the Question
        // usersList: [
        //     {
        //         teacherUserId: '659b90fa21cb4f53081b46a9',
        //         studentNumber: '100',
        //         userDeviceMacId: 'bf:fc:e2:0e:93:e7',
        //         receivedAnswer: false,
        //         isTeacher: true,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a7',
        //         studentNumber: '21',
        //         userDeviceMacId: '58:2f:be:86:1c:ee',
        //         receivedAnswer: false,
        //         userName : 'Countingwell School'
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a8',
        //         studentNumber: '22',
        //         userDeviceMacId: 'ef:3f:4d:11:ef:c7',
        //         receivedAnswer: false,
        //         userName : 'countingwell School - 2'
        //     },
        //     // {
        //     //     userId: '659b90fa21cb4f53081b46a7',
        //     //     studentNumber: '1',
        //     //     userDeviceMacId: '7c:0c:d3:ef:9b:d8',
        //     //     receivedAnswer: false,
        //     // },
        //     // {
        //     //     userId: '659b90fa21cb4f53081b46a8',
        //     //     studentNumber: '2',
        //     //     userDeviceMacId: '65:05:df:31:dd:fe',
        //     //     receivedAnswer: false,
        //     // },

        //     {
        //         userId: '659b90fa21cb4f53081b46aa',
        //         studentNumber: '3',
        //         userDeviceMacId: '26:05:e8:5b:ed:d1',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ab',
        //         studentNumber: '4',
        //         userDeviceMacId: '98:83:8f:a6:9c:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ac',
        //         studentNumber: '5',
        //         userDeviceMacId: 'b5:de:e9:93:c5:c2',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ad',
        //         studentNumber: '6',
        //         userDeviceMacId: 'c9:ee:61:02:61:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ae',
        //         studentNumber: '7',
        //         userDeviceMacId: '28:56:46:47:b8:f4',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46af',
        //         studentNumber: '8',
        //         userDeviceMacId: '98:95:c3:0d:4b:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b0',
        //         studentNumber: '9',
        //         userDeviceMacId: '39:76:8d:c4:b5:e3',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b1',
        //         studentNumber: '10',
        //         userDeviceMacId: 'ba:97:f2:01:91:ce',
        //         receivedAnswer: false,
        //     }
        // ],
        // Production Users List
        // usersList: [
        //     {
        //         teacherUserId : '659b90fa21cb4f53081b46a9',
        //         studentNumber: '100',
        //         userDeviceMacId: 'bd:d0:04:05:db:d8',
        //         receivedAnswer : false,
        //         isTeacher : true,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a7',
        //         studentNumber: '1',
        //         userDeviceMacId: '7c:0c:d3:ef:9b:d8',
        //         receivedAnswer : false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a8',
        //         studentNumber: '2',
        //         userDeviceMacId: '65:05:df:31:dd:fe',
        //         receivedAnswer : false,
        //     },

        //     {
        //            userId: '659b90fa21cb4f53081b46aa',
        //            studentNumber: '3',
        //            userDeviceMacId: '26:05:e8:5b:ed:d1',
        //            receivedAnswer : false,
        //        },
        //        {
        //            userId: '659b90fa21cb4f53081b46ab',
        //            studentNumber: '4',
        //            userDeviceMacId: '98:83:8f:a6:9c:fa',
        //            receivedAnswer : false,
        //        },
        //        {
        //            userId: '659b90fa21cb4f53081b46ac',
        //            studentNumber: '5',
        //            userDeviceMacId: 'b5:de:e9:93:c5:c2',
        //            receivedAnswer : false,
        //        },
        //        {
        //            userId: '659b90fa21cb4f53081b46ad',
        //            studentNumber: '6',
        //            userDeviceMacId: 'c9:ee:61:02:61:d8',
        //            receivedAnswer : false,
        //        },
        //        {
        //         userId: '659b90fa21cb4f53081b46ae',
        //         studentNumber: '7',
        //         userDeviceMacId: '28:56:46:47:b8:f4',
        //         receivedAnswer : false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46af',
        //         studentNumber: '8',
        //         userDeviceMacId: '98:95:c3:0d:4b:fa',
        //         receivedAnswer : false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b0',
        //         studentNumber: '9',
        //         userDeviceMacId: '39:76:8d:c4:b5:e3',
        //         receivedAnswer : false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b1',
        //         studentNumber: '10',
        //         userDeviceMacId: 'ba:97:f2:01:91:ce',
        //         receivedAnswer : false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b2',
        //         studentNumber: '11',
        //         userDeviceMacId: '05:d0:84:1f:a9:c9',
        //         receivedAnswer : false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b3',
        //         studentNumber: '12',
        //         userDeviceMacId: 'bd:53:3b:30:15:ed',
        //         receivedAnswer : false,
        //     },
        //     {
        //            userId: '659b90fa21cb4f53081b46b4',
        //            studentNumber: '13',
        //            userDeviceMacId: '7a:89:0f:87:42:eb',
        //            receivedAnswer : false,
        //        },
        //        {
        //            userId: '659b90fa21cb4f53081b46b5',
        //            studentNumber: '14',
        //            userDeviceMacId: '72:87:f4:33:b9:d8',
        //            receivedAnswer : false,
        //        },
        //        {
        //            userId: '659b90fa21cb4f53081b46b6',
        //            studentNumber: '15',
        //            userDeviceMacId: '09:65:11:75:bd:de',
        //            receivedAnswer : false,
        //        },
        //        {
        //            userId: '659b90fa21cb4f53081b46b7',
        //            studentNumber: '16',
        //            userDeviceMacId: '93:86:69:41:2f:fd',
        //            receivedAnswer : false,
        //        },
        //        {
        //         userId: '659b90fa21cb4f53081b46b8',
        //         studentNumber: '17',
        //         userDeviceMacId: 'ca:b1:a7:e1:6c:dc',
        //         receivedAnswer : false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b9',
        //         studentNumber: '18',
        //         userDeviceMacId: '17:0d:52:8c:8a:cb',
        //         receivedAnswer : false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ba',
        //         studentNumber: '19',
        //         userDeviceMacId: '122',
        //         receivedAnswer : false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46bb',
        //         studentNumber: '20',
        //         userDeviceMacId: '123',
        //         receivedAnswer : false,
        //     }
        // ],
        // Testing with large List

        // usersList: [
        //     {
        //         teacherUserId: '659b90fa21cb4f53081b46a9',
        //         studentNumber: '100',
        //         userDeviceMacId: 'bf:fc:e2:0e:93:e7',
        //         receivedAnswer: false,
        //         isTeacher: true,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a7',
        //         studentNumber: '21',
        //         userDeviceMacId: '58:2f:be:86:1c:ee',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a8',
        //         studentNumber: '22',
        //         userDeviceMacId: 'ef:3f:4d:11:ef:c7',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46aa',
        //         studentNumber: '3',
        //         userDeviceMacId: '26:05:e8:5b:ed:d1',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ab',
        //         studentNumber: '4',
        //         userDeviceMacId: '98:83:8f:a6:9c:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ac',
        //         studentNumber: '5',
        //         userDeviceMacId: 'b5:de:e9:93:c5:c2',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ad',
        //         studentNumber: '6',
        //         userDeviceMacId: 'c9:ee:61:02:61:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ae',
        //         studentNumber: '7',
        //         userDeviceMacId: '28:56:46:47:b8:f4',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46af',
        //         studentNumber: '8',
        //         userDeviceMacId: '98:95:c3:0d:4b:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b0',
        //         studentNumber: '9',
        //         userDeviceMacId: '39:76:8d:c4:b5:e3',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b1',
        //         studentNumber: '10',
        //         userDeviceMacId: 'ba:97:f2:01:91:ce',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b2',
        //         studentNumber: '11',
        //         userDeviceMacId: '05:d0:84:1f:a9:c9',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b3',
        //         studentNumber: '12',
        //         userDeviceMacId: 'bd:53:3b:30:15:ed',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b4',
        //         studentNumber: '13',
        //         userDeviceMacId: '7a:89:0f:87:42:eb',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b5',
        //         studentNumber: '14',
        //         userDeviceMacId: '72:87:f4:33:b9:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b6',
        //         studentNumber: '15',
        //         userDeviceMacId: '09:65:11:75:bd:de',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b7',
        //         studentNumber: '16',
        //         userDeviceMacId: '93:86:69:41:2f:fd',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b8',
        //         studentNumber: '17',
        //         userDeviceMacId: 'ca:b1:a7:e1:6c:dc',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b9',
        //         studentNumber: '18',
        //         userDeviceMacId: '17:0d:52:8c:8a:cb',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ba',
        //         studentNumber: '19',
        //         userDeviceMacId: '122',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46bb',
        //         studentNumber: '20',
        //         userDeviceMacId: '123',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a7',
        //         studentNumber: '1',
        //         userDeviceMacId: '7c:0c:d3:ef:9b:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a8',
        //         studentNumber: '2',
        //         userDeviceMacId: '65:05:df:31:dd:fe',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46aa',
        //         studentNumber: '3',
        //         userDeviceMacId: '26:05:e8:5b:ed:d1',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ab',
        //         studentNumber: '4',
        //         userDeviceMacId: '98:83:8f:a6:9c:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ac',
        //         studentNumber: '5',
        //         userDeviceMacId: 'b5:de:e9:93:c5:c2',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ad',
        //         studentNumber: '6',
        //         userDeviceMacId: 'c9:ee:61:02:61:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ae',
        //         studentNumber: '7',
        //         userDeviceMacId: '28:56:46:47:b8:f4',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46af',
        //         studentNumber: '8',
        //         userDeviceMacId: '98:95:c3:0d:4b:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b0',
        //         studentNumber: '9',
        //         userDeviceMacId: '39:76:8d:c4:b5:e3',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b1',
        //         studentNumber: '10',
        //         userDeviceMacId: 'ba:97:f2:01:91:ce',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b2',
        //         studentNumber: '11',
        //         userDeviceMacId: '05:d0:84:1f:a9:c9',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b3',
        //         studentNumber: '12',
        //         userDeviceMacId: 'bd:53:3b:30:15:ed',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b4',
        //         studentNumber: '13',
        //         userDeviceMacId: '7a:89:0f:87:42:eb',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b5',
        //         studentNumber: '14',
        //         userDeviceMacId: '72:87:f4:33:b9:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b6',
        //         studentNumber: '15',
        //         userDeviceMacId: '09:65:11:75:bd:de',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b7',
        //         studentNumber: '16',
        //         userDeviceMacId: '93:86:69:41:2f:fd',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b8',
        //         studentNumber: '17',
        //         userDeviceMacId: 'ca:b1:a7:e1:6c:dc',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b9',
        //         studentNumber: '18',
        //         userDeviceMacId: '17:0d:52:8c:8a:cb',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ba',
        //         studentNumber: '19',
        //         userDeviceMacId: '122',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46bb',
        //         studentNumber: '20',
        //         userDeviceMacId: '123',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a7',
        //         studentNumber: '1',
        //         userDeviceMacId: '7c:0c:d3:ef:9b:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a8',
        //         studentNumber: '2',
        //         userDeviceMacId: '65:05:df:31:dd:fe',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46aa',
        //         studentNumber: '3',
        //         userDeviceMacId: '26:05:e8:5b:ed:d1',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ab',
        //         studentNumber: '4',
        //         userDeviceMacId: '98:83:8f:a6:9c:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ac',
        //         studentNumber: '5',
        //         userDeviceMacId: 'b5:de:e9:93:c5:c2',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ad',
        //         studentNumber: '6',
        //         userDeviceMacId: 'c9:ee:61:02:61:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ae',
        //         studentNumber: '7',
        //         userDeviceMacId: '28:56:46:47:b8:f4',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46af',
        //         studentNumber: '8',
        //         userDeviceMacId: '98:95:c3:0d:4b:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b0',
        //         studentNumber: '9',
        //         userDeviceMacId: '39:76:8d:c4:b5:e3',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b1',
        //         studentNumber: '10',
        //         userDeviceMacId: 'ba:97:f2:01:91:ce',
        //         receivedAnswer: false,
        //     }
        // ],
        showStartAssessment: false,
        showAssessmentEngine: false,
        showAssessmentEndScreen: false,
        isFFF: false,
        type: 'CLICKER_ASSESSMENT'
        // showTimeList : false,
        // showClickerAssessmentLeaderboard : false,
    }

    constructor(props) {
        super(props);
        this.layoutShared = LayoutShared.getLayout()

    }

    componentDidMount() {

        // console.log(this.props.history.location.pathname)

        if (this.props.history.location.pathname === '/student/clicker-flow') {
            this.setState({ isFFF: false, type: 'CLICKER_ASSESSMENT' }, () => {
                this.getClickerList()
            })
        } else if (this.props.history.location.pathname === '/student/clicker-flow-fff') {
            this.setState({ isFFF: true, type: 'CLICKER_ASSESSMENT_FFF' }, () => {
                this.getClickerList()
            })
        } else if (this.props.history.location.pathname === '/student/clicker-flow-testing-fff') {
            this.setState({ isFFF: true, type: 'CLICKER_ASSESSMENT_TESTING_FFF' }, () => {
                this.getClickerList()
            })
        } else if (this.props.history.location.pathname === '/student/clicker-flow-fff-one') {
            this.setState({ isFFF: true, type: 'CLICKER_ASSESSMENT_FFF_ONE' }, () => {
                this.getClickerList()
            })
        } else if (this.props.history.location.pathname === '/student/clicker-flow-fff-two') {
            this.setState({ isFFF: true, type: 'CLICKER_ASSESSMENT_FFF_TWO' }, () => {
                this.getClickerList()
            })
        }

        this.layoutShared.toggleSidebar(true)

    }

    getClickerList() {

        let payload = {
            type: this.state.type
        }

        AssessmentApi.getClickerList(payload).then(res => {

            console.log(res)

            this.setState({ usersList: res.clickerUserList, showStartAssessment: true }, () => {

                // this.handleStartListening()
            })


        }).catch(err => {
            this.setState({ isLoading: false })
            console.log(err)
        })

    }


    showAssessmentEngine(data) {
        //console.log(data)
        this.setState({ showAssessmentEngine: true, showStartAssessment: false, ...data })
    }

    showAssessmentEndScreen(assessmentCompletedInfo) {
        console.log(assessmentCompletedInfo)
        this.setState({ showAssessmentEngine: false, showAssessmentEndScreen: true, ...assessmentCompletedInfo })
    }


    render() {

        if (this.state.showStartAssessment) {
            return (
                <StartAssessment delegate={this}
                    meta={{ chapterKey: '123', chapterTitle: 'Jigayasa Baseline Assessment', categoryThemeId: 1 }}
                    studentList={this.state.usersList}
                    isFFF={this.state.isFFF}
                    type={this.state.type}
                />
            )
        }

        if (this.state.showAssessmentEngine) {
            return (
                <ClickerAssessmentEngine meta={
                    {
                        chapterKey: this.state.chapterKey,
                        chapterTitle: this.state.chapterTitle,
                        categoryThemeId: this.state.categoryThemeId,
                        questionSetId: this.state.questionSetId
                    }
                }
                    delegate={this}
                    studentList={this.state.usersList}
                    isFFF={this.state.isFFF} />
            )
        }

        if (this.state.showAssessmentEndScreen) {
            return (
                <ClickerAssessmentEndScreen metaInfo={this.state} delegate={this} studentList={this.state.usersList} />
            )
        }

        if (this.state.showTimeList) {
            return (<TimeList />)
        }

        if (this.state.showClickerAssessmentLeaderboard) {
            return (<ClickerAssessmentLeaderboard />)
        }


        return (
            <div>
                <div>Test</div>
            </div>
        )
    }

}

export default AssessmentComponent;
import { render } from "@testing-library/react";
import React from "react";
import { ImageConstants } from "../../../constants/constants";
import { getImageSource } from "../../../utility/image-source";
import './custom-toaster.scss';

class CustomToaster extends React.Component {

    render() {
        return (
            <div className="custom-toast-message">
                <div className={`custom-toast-message-for-mobile-number ${this.props.type === 'error' ? "error-color" : ''} `}>
                    {this.props.toasterMessage}
                    {this.props.showImage ? <img className="green-tick-mark"  src={getImageSource(ImageConstants.GREEN_TICK_MARK)} /> : ''}
                    
                </div>
            </div>
        )
    }

}



export default CustomToaster
import React from "react";
import './quotes-title.scss';
import Parser from "../../../common/content-parser/content-parser";
import BarChart from "../../bar-chart/bar-chart";

class AssessmentQuote extends React.Component {

    state = {
        totalActiveClickers: 0
    }

    constructor(props) {
        super(props);
        this.parser = new Parser();
        // this.label = this.props.quote.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");
        // this.label = this.label.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");



    }

    componentDidMount() {
        // let userIds = []

        // this.props.studentList.map(item => {
        //     if (!item.isTeacher && item.userDeviceMacId.length === 17) {
        //         userIds.push(item.userId)
        //     }
        // })

        // let totalActiveClickers = userIds.length

        // this.setState({ totalActiveClickers })
    }


    render() {
        let styles = {
            p: { fontSize: '28px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "700" }
        }
        return (
            <div className="quotes-title-for-practice-flow mt-5" style={{
                marginLeft: `${this.props.isFullScreenMode && this.props.showStudentResponse ? '14rem' :
                    this.props.isFullScreenMode && !this.props.showStudentResponse ? "0.5rem" : this.props.showStudentResponse ? "6rem" : '0rem'}`
            }}>
                {/* <div className="d-flex align-items-center mb-3">
                    <div className="quiz-button mr-2">Practice</div>
                    <div className="subject-text">Maths</div>
                    <div className="question-number ml-2">Q {this.props.activePage}/{this.props.questions}</div>
                </div> */}
                <div className="d-flex justify-content-center">
                    <BarChart options={this.props.options} studentResponseCount={this.props.studentResponseCount} />
                </div>
            </div>
        )
    }

}

export default AssessmentQuote;
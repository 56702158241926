
export default class SharedAuthKeys {

    static authKeys = {}

    static setAuthKeys(keys) {
        this.authKeys = keys;
    }

    static getAuthKey(key) {
        return this.authKeys[key];
    }

}

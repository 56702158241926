import React from "react";
import './sequencing.scss';
import Parser from "../../../../common/content-parser/content-parser";
import QuestionTitle from "../../../../common/question/question-title/question-title";
import QuestionImage from "../../../../common/cw-question-image/cw-question-image";
import AnswerTab from "../../answer-tab/answer-tab";
import CWImageV2 from "../../../../common/cw-image-2/cw-image-2";
import { Constants } from "../../../../../constants/constants";
import { getThemeImageSource } from "../../../../../utility/image-source";
import { ImageConstants } from "../../../../../constants/constants";
import SharedThemeCategory from "../../../../../../shared-storage/category-theme";

class Sequencing extends React.Component {

    state = {}

    constructor(props) {
        super(props);
        let content = JSON.stringify(this.props.content)
        this.state.content = JSON.parse(content);
        if (this.props.showMyAnswer) {
            this.showMyAnswer()
        }
        this.parser = new Parser();
        this.data = this.props.content.content.options
    }

    getContent(item) {


        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let content = item.content.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");

        content = content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");


        if (item.contentType == Constants.CONTENT_TYPE_TEXT) {
            return (
                this.parser.parseWithStyles(content.includes("<p>") ? `${content}` : `<p>${content}</p>`, { p: styles.p, 'cw-math': styles.p })
            )
        } else if (item.contentType == Constants.CONTENT_TYPE_IMAGE) {
            return (
                <CWImageV2 uri={item.content} ></CWImageV2>
            )
        }
    }


    updateAnswer = (payload) => {

        let obj = {};

        this.state.content.content.options.map((option, index) => { obj[option.id] = option });

        let data = [];

        for (let i = 0; i < payload.answer.length; i++) {

            if (obj[payload.answer[i].id]) {
                data.push(obj[payload.answer[i].id]);
            }
        }

        let content = this.state.content;
        content.content.options = data;

        this.setState({ content: content });

    }


    showMyAnswer = () => {
        if (this.props.answer) {
            this.updateAnswer(this.props.answer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.stringify(this.props.content)
        this.setState({ content: JSON.parse(content) })
    }

    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer })
    }




    renderItem(item, index) {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]

        return (
            <div className="mb-3 mt-2 mr-0 ml-0" style={{}}>
                <div className={`sequence-wrapper padding-for-dropdown ${item.contentType == Constants.CONTENT_TYPE_IMAGE ? '' : 'sequence-question-height'}`} style={{borderColor : borderColor}}
                    key={item.id}
                    id={item.id}>
                    <div className="dropdown-input cursor-pointer">
                        {/* <div className="d-inline-block alphabet-option mr-2">
                            <div className="circle p-1"><img className="drag-image" src={getThemeImageSource(ImageConstants.DRAG_ICON, this.props.categoryThemeId)} /></div>
                        </div> */}
                        {/* <img className="option-separator" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} /> */}

                        <div className="d-inline-block ml-2 p-2" style={{}}>
                            {
                                this.getContent(item)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }





    render() {
        // setCategoryThemeStyles(this.props.categoryThemeId)
        let content = this.state.content;
        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)
        let textColor = this.theme['themeColor']
        let borderColor = this.theme[`themeColor`]

        return (
            <div className="sequencing">
                <div className="question-wrapper">
                    <QuestionTitle title={content.content.title} hideBackground={true} categoryThemeId={this.props.categoryThemeId} />
                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                </div>

                {
                    this.props.status != "NOT_ATTEMPTED" ?
                        <div className="view-answer-button ml-1 mt-3 cursor-pointer" style={{color : textColor, borderColor : borderColor}} onClick={this.toggleViewAnswer}>
                            {
                                this.state.showCorrectAnswer ? 'Hide Answer' : 'View Answer'
                            }
                            {/* <span>View Answer</span> */}
                            <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} />
                        </div>
                        :
                        <></>
                }

                {
                    this.state.showCorrectAnswer ?
                        <>
                            {
                                this.props.status != "NOT_ATTEMPTED" ?
                                    <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.props.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.answer}></AnswerTab>
                                    : <></>
                            }

                            {
                                this.props.status != "NOT_ATTEMPTED" ?
                                    <div className="options">
                                        {
                                            this.state.content ? this.state.content.content.options.map((item) => this.renderItem(item)) : <></>
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                        </>

                        :

                        <></>
                }

            </div>
        );
    }
}


export default Sequencing;


import React, { Component } from 'react';
import './chapter-lock-modal.scss'
import { Modal, ModalBody } from "reactstrap";
import { ImageConstants } from '../../../../constants/constants';
import { getImageSource } from '../../../../utility/image-source'

class ChapterLockModal extends Component {

    constructor(props) {
        super(props);



    }

    onClickOkay = () => {
        this.props.delegate.closeModal();
    }

    onClickClose = () => {
        this.props.delegate.closeModal();
    }

    showSetNewPasscodeScreen() {

    }


    sendOtp() {
        this.props.sendOtp && this.props.sendOtp()
    }

    showPasscodeScreen() {
        this.props.showPasscodeScreen && this.props.showPasscodeScreen({ skipOtp: true })
    }


    render() {
        return (
            <Modal className="cw-common-custom-modal-center-for-chapter-lock" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                    {this.props.showTrialFeatures ? <img className='badge-image' src={getImageSource(ImageConstants.BADGE_IMAGE)} /> : ''}
                    {/* <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} /> */}
                </div>
                <ModalBody>
                    <div className="pratice-container">
                        <div><img className='float-right chapter-image-size' src={this.props.imageUrl} /></div>
                        <div className=' mt-5 mb-4 d-flex align-items-center'>
                            <div><img className='chapter-lock-image-size' src={getImageSource(ImageConstants.CHAPTER_LOCK)} /></div>
                            <div className='ml-2'><span className='header-text'>{this.props.chapterTitle}</span><span className='sub-text'> will be available soon.</span></div>
                        </div>
                        {/* <div className='name-header mt-2 mb-4'>You can skip this in case you do not have a phone right now</div> */}
                        <div className="d-flex align-items-center justify-content-around mt-2">
                            {/* <div className="continue-button cursor-pointer text-center" onClick={() => this.onClickClose()}>
                                        {`OK`}
                                    </div> */}
                            <div className="take-challenge cursor-pointer  text-center" onClick={() => this.onClickClose()}>
                                {`OK`}
                            </div>
                        </div>
                        <div className="position">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default ChapterLockModal;

import React from "react";
import './descriptive.scss';
import CommonUtils from "../../../../utility/common-utilities";
import Parser from "../../content-parser/content-parser";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import { Constants } from "../../../../constants/constants";
import CWImageV2 from "../../cw-image-2/cw-image-2";
import QuestionTitle from "../question-title/question-title";
import QuestionImage from "../../cw-question-image/cw-question-image";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import { getThemeImageSource, getImageSource } from "../../../../utility/image-source";
import AssessmentFooter from "../../assessment-footer/assessment-footer";
import { ImageConstants } from "../../../../constants/constants";
import SummarySoultions from "../../summary-solution/summary-solution";
import DotPagination from "../../dot-pagination/dot-pagination";
import AnswerTab from "../../../my-assessment-result/question/answer-tab/answer-tab";
import ReactTooltip from "react-tooltip";


class Descriptive extends React.Component {

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.shuffleOptions = [...this.props.content.content.options];
        this.state = {
            data: this.props.isReadOnly ? this.props.content.content.options : CommonUtils.shuffleArray(this.shuffleOptions),
            sourceX: null,
            sourceY: null,
            sourcePreviewVisible: false,
            showSolution: true,
            showOptions: false
        };

        this.staticText = SharedStaticText.getStaticText('multiStepQuestion');
        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');

        if (this.props.isReadOnly) {
            let content = JSON.stringify(this.props.content)
            this.state.content = JSON.parse(content);

        }

    }

    dragType = "";
    dragInfo = {};
    answerTickCount = 0;

    componentDidMount() {
        if (this.props.isReadOnly) {
            this.setState({ status: `${this.props.userAnswerStatus ? "CORRECT" : "IN_CORRECT"}` })
        }

        if (!this.props.isReadOnly) {
            console.log(this.props.content)
            this.setState({ content: this.props.content });
            this.startTimerforAnswer()
        }
    }


    startTimerforAnswer() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }
        this.timerIntervalId = setInterval(() => {

            this.answerTickCount += 1
        }, 1000)
    }

    getContent(item) {


        let styles = {
            p: { fontSize: '19px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let content = item.content.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");

        content = content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");


        if (item.contentType == Constants.CONTENT_TYPE_TEXT) {
            return (
                this.parser.parseWithStyles(content.includes("<p>") ? `${content}` : `<p>${content}</p>`, { p: styles.p, 'cw-math': styles.p })
            )
        } else if (item.contentType == Constants.CONTENT_TYPE_IMAGE) {
            return (
                <CWImageV2 uri={item.content} ></CWImageV2>
            )
        }




    }

    getAnswer = () => {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }

        // Hard Coded the options Value in the Assessment flow

        let data = [
            {
                "id": "158459849165815845984916583222",
                "contentType": 1,
                "contentTypeName": "TEXT",
                "content": "<p>Alaska -42°C</p>",
                "order": 1
            },
            {
                "id": "158459850546415845985054646373",
                "contentType": 1,
                "contentTypeName": "TEXT",
                "content": "<p>Ottawa -18°C</p>",
                "order": 2
            }
        ]

        let payload = data.map((option, index) => ({ id: option.id }));

        if (payload)
            return { payload, timeTakenToAnswer: this.answerTickCount };
        else
            return null;
    }

    updateAnswer = (payload) => {

        let obj = {};

        this.props.content.content.options.map((option, index) => { obj[option.id] = option });

        let data = [];

        for (let i = 0; i < payload.answer.length; i++) {

            if (obj[payload.answer[i].id]) {
                data.push(obj[payload.answer[i].id]);
            }
        }

        this.setState({ data: data });
    }

    onDragStart = (event, dragInfo, type) => {
        // event.stopPropagation();
        if (event.type === 'touchstart') {
            event.preventDefault()
            this.setState({ sourcePreviewVisible: true });
        }
        this.dragType = type;
        this.dragInfo = dragInfo;

    };

    onDragOver = (event, dragOverInfo, type) => {

        // event.stopPropagation();
        event.preventDefault()

        if (event.type === 'touchmove') {
            // Find the target element based on the touch event
            const targetElement = document.elementFromPoint(
                event.changedTouches[0].clientX,
                event.changedTouches[0].clientY
            );

            //console.log(targetElement)

            // //console.log('drag over', targetElement.attributes[0].textContent)

            let dragOverIndex = { index: -1 }
            let index = targetElement.attributes[0] ? +targetElement.attributes[0].textContent : 1
            //console.log(index)

            let option = this.state.data.filter((data, i) => {
                if (i === index) {
                    return data
                }
            })

            //console.log(option)

            if (!isNaN(index)) {
                dragOverIndex = { index: index, option: option[0] }
            }

            this.dragOverInfo = dragOverIndex

            // Access touch coordinates
            const touchX = event.touches[0].clientX;
            const touchY = event.touches[0].clientY;

            // Update the source preview's position
            this.setState({
                sourceX: touchX,
                sourceY: touchY,
            });

        } else if (this.dragType == type && this.dragType == "OPTION") {

            this.dragOverInfo = dragOverInfo;

            this.setState({ dragOverQuestionContentId: this.dragOverInfo.option.id });
            // //console.log("CONCEPT onDragOver", dragOverInfo);

        }

    };

    onDragEnd = (event) => {

        // //console.log("end reached", this.dragType, this.dragInfo, this.dragOverInfo)

        event.preventDefault()
        this.setState({ sourcePreviewVisible: false });

        if (this.dragType == "OPTION") {

            if (this.dragInfo && this.dragOverInfo) {

                if (this.dragInfo.option.id === this.dragOverInfo.option.id) {
                    this.resetOrderingDetails();
                    return;
                }

                this.reOrder()
            }
        }

    };

    reOrder = () => {

        let dragType = this.dragType;
        let dragInfo = this.dragInfo;
        let dragOverInfo = this.dragOverInfo;

        let allOptions = this.state.data

        if (dragInfo.index != dragOverInfo.index) {

            let allOptionsForReOrder = this.insertDragedQuestions(allOptions, allOptions[dragInfo.index], allOptions[dragOverInfo.index], dragInfo.index, dragOverInfo.index)
            this.resetOrderingDetails()

            this.setState({ data: allOptionsForReOrder })
        }


    }


    insertDragedQuestions(allQuestions, drapItem, dropItem, dragIndex, dragOverIndex) {

        //console.log(dragIndex, dragOverIndex)

        var allQuestionsCopy = allQuestions.slice();

        allQuestionsCopy.splice(dragIndex, 1)

        if (dragOverIndex === 0) {
            allQuestionsCopy.splice(0, 0, drapItem)
        } else if (dragIndex > dragOverIndex) {
            allQuestionsCopy.splice(dragOverIndex, 0, drapItem)
        } else if (dragIndex < dragOverIndex) {
            if (dragOverIndex - 1 === dragIndex) {
                allQuestionsCopy.splice(dragOverIndex, 0, drapItem)
            } else {
                allQuestionsCopy.splice(dragOverIndex, 0, drapItem)
            }
        }



        // allQuestionsCopy[allQuestions.indexOf(drapItem)] = dropItem;
        // allQuestionsCopy[allQuestions.indexOf(dropItem)] = drapItem;

        return allQuestionsCopy;

    }

    resetOrderingDetails = () => {

        this.dragInfo = null;
        this.dragType = "";
        this.draggedItem = null;
        this.draggedOverItem = null;
        this.setState({ dragOverQuestionContentId: null });
    }




    renderItem(item, index) {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]

        return (
            <div className={`mt-2 mr-0 ml-0 ${this.state.data.length - 1 == index ? "mb-0" : "mb-3"}`} >
                <div className={`sequence-wrapper padding-for-dropdown ${item.contentType == Constants.CONTENT_TYPE_IMAGE ? '' : 'sequence-question-height'}`} style={{
                    border: ` ${!this.props.isReadOnly ? `1.5px solid ${borderColor}` : '#FFFFFF'}`,
                    boxShadow: `${this.props.isReadOnly ? 'none' : ""}`,
                    height: `${this.props.isReadOnly ? '20px' : ""}`,
                    padding: `${this.props.isReadOnly ? '0px' : ""}`
                }}
                    onDragOver={(e) => this.onDragOver(e, { index: index, option: item }, "OPTION")}
                    onDragStart={e => this.onDragStart(e, { index: index, option: item }, "OPTION")}
                    onDragEnd={this.onDragEnd}
                    onTouchStart={e => this.onDragStart(e, { index: index, option: item }, "OPTION")}
                    onTouchMove={(e) => this.onDragOver(e, { index: index, option: item }, "")}
                    onTouchEnd={this.onDragEnd}
                    draggable={true}
                    key={item.id}
                    id={item.id}>
                    <div id={index} className="dropdown-input cursor-pointer">
                        {
                            !this.props.isReadOnly ?
                                <>
                                    <div className="d-inline-block alphabet-option mr-2">
                                        <div className="circle p-1"><img id={index} className="drag-image" src={getThemeImageSource(ImageConstants.DRAG_ICON, this.props.categoryThemeId)} /></div>
                                    </div>
                                    <img id={index} className="option-separator" src={getThemeImageSource(ImageConstants.OPTION_SEPARATOR, this.props.categoryThemeId)} />

                                </> : <></>
                        }

                        <div className={`d-inline-block ml-2 ${item.contentType == Constants.CONTENT_TYPE_IMAGE ? 'mt-2' : ''}`} style={{}}>
                            {
                                this.getContent(item)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }




    getSequencingOptions() {
        return (
            <div>
                {this.state.data.map((item, index) => (
                    <>
                        {this.renderItem(item, index)}
                    </>
                ))}
            </div>
        )
    }

    updateAnswerForUserAnswer = (payload) => {

        let obj = {};

        this.state.content.content.options.map((option, index) => { obj[option.id] = option });

        let data = [];

        for (let i = 0; i < payload.answer.length; i++) {

            if (obj[payload.answer[i].id]) {
                data.push(obj[payload.answer[i].id]);
            }
        }

        let content = this.state.content;
        content.content.options = data;

        this.setState({ content: content });

    }


    showMyAnswer = () => {
        if (this.props.userAnswer) {
            this.updateAnswerForUserAnswer(this.props.userAnswer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.parse(JSON.stringify(this.props.content))
        //console.log(content)
        this.setState({ content: content }, () => {

        })
    }


    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer, showSolution: !this.state.showSolution })
    }

    toggleViewSolution = () => {
        if (this.state.status === "CORRECT") {
            return;
        }
        this.setState({ showSolution: !this.state.showSolution, showCorrectAnswer: !this.state.showCorrectAnswer })
    }


    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions })
    }






    render() {
        let content = this.state.content;

        // if (this.state.showMedia)
        // return <View style={{ flex: 1 }}><Media delegate={this} type={this.state.selectedMediaType} /></View>

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        let dragItemBorder = this.theme[`secondaryColors`][`dragItemBorder`]
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]

        const { sourceX, sourceY, sourcePreviewVisible } = this.state;

        return (

            content ?
                <div className="single-select test" onMouseEnter={ReactTooltip.rebuild}>
                    {sourcePreviewVisible && (

                        <div className="source-preview-for-sequencing answer-card cursor-pointer" style={{
                            border: `2px solid ${dragItemBorder}`, backgroundColor: bgColor,
                            left: sourceX + 30 + 'px',
                            top: sourceY - 20 + 'px'
                        }}
                        >
                            {this.getContent(this.dragInfo?.option)}
                        </div>
                    )}
                    <div className={`${!this.props.isReadOnly ? "min-height" : ""}`}>
                        {/** Squencing question will take care of multistep Quesion as well */}

                        {
                            this.props.isReadOnly ?
                                <>
                                    <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                                        {
                                            this.props.userAnswerStatus ?
                                                <div className="correct-answer-bg d-flex">
                                                    <div className="">
                                                        <img className="image-size" src={getImageSource(ImageConstants.WHITE_TICK_ICON_NEW)} />
                                                    </div>
                                                    <div className="ml-2">
                                                        <div className="name-header-correct">{"Correct"}</div>
                                                        {/* <div className="sub-title">{this.correctAnswerStaticText.subTitle}</div> */}
                                                    </div>
                                                </div>

                                                :
                                                <div className="incorrect-answer-bg d-flex">
                                                    <div className="">
                                                        <img className="image-size" src={getImageSource(ImageConstants.WHITE_CROSS_ICON)} />
                                                    </div>
                                                    <div className="ml-1">
                                                        <div className="name-header text-center">{"Incorrect"}</div>
                                                        {/* <div className="sub-title">{this.inCorrectAnswerStaticText.subTitle}</div> */}
                                                    </div>
                                                </div>

                                        }

                                    </div>
                                    {/* <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                    <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                    </div>
                                </div> */}
                                </>
                                :
                                <></>
                        }


                        {
                            this.props.isMultiStep && this.props.mulipStepQuestion ?
                                <>
                                    <QuestionTitle title={this.props.mulipStepQuestion.content.title} queNo={this.props.quesNo ? this.props.quesNo : null} categoryThemeId={this.props.categoryThemeId} delegate={this.props.delegate} />
                                    <QuestionImage images={this.props.mulipStepQuestion.content.images} categoryThemeId={this.props.categoryThemeId} />

                                    <div className="d-flex align-items-center mt-4">
                                        <img className="bulb-image-size" src={getImageSource(ImageConstants.BULB_IMAGE)} />
                                        <div className="instruction-text ml-3">
                                            {/* {constants.MULTI_SELECT_INSTRUCTION_TEXT} */}
                                            {this.props.isFromMyAssessment ? this.staticText.assessmentMultistepInstruction : this.staticText.instruction}
                                        </div>
                                    </div>

                                    <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                        <div className={`${(this.props.isFromMyAssessment) ? 'divider-wrapper' : ''}`}>
                                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                        </div>
                                        {
                                            this.props.isFromMyAssessment ?
                                                <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                                    style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${this.props.mulipStepQuestion.content.mark} Marks`}>
                                                    <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                                    <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{this.props.mulipStepQuestion.content.mark}</span>
                                                </div> : <></>
                                        }
                                    </div>
                                </>
                                : <></>
                        }

                        {this.props.totalSteps && this.props.quesNo && <DotPagination noOfDots={this.props.totalSteps} activeDot={this.props.quesNo} categoryThemeId={this.props.categoryThemeId} />}

                        {
                            !this.props.isCheckPoint && this.props.isMultiStep && !this.props.isReadOnly ?
                                <>
                                    <QuestionTitle title={content.content.title}
                                        queNo={this.props.quesNo ? this.props.quesNo : null}
                                        noOfDots={this.props.totalSteps}
                                        activeDot={this.props.quesNo}
                                        hideBackground={this.props.isMultiStep ? true : false}
                                        categoryThemeId={this.props.categoryThemeId}
                                        isAssessment={this.props.isAssessment}
                                        delegate={this.props.delegate}
                                        isReadOnly={this.props.isReadOnly}
                                        isSchoolAssignment={true} />
                                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                                    {!this.props.isCheckPoint && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                        </div>
                                        {
                                            (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                                <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                                    style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${content.content.mark} Marks`}>
                                                    <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                                    <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                                </div> : <></>
                                        }
                                    </div>
                                    }
                                </>

                                :
                                !this.props.isMultiStep && !this.props.isCheckPoint ?
                                    <>
                                        <QuestionTitle title={content.content.title}
                                            queNo={this.props.quesNo ? this.props.quesNo : null}
                                            noOfDots={this.props.totalSteps}
                                            activeDot={this.props.quesNo}
                                            hideBackground={this.props.isMultiStep ? true : false}
                                            categoryThemeId={this.props.categoryThemeId}
                                            isAssessment={this.props.isAssessment}
                                            delegate={this.props.delegate}
                                            isReadOnly={this.props.isReadOnly}
                                            isSchoolAssignment={true}
                                            showOptions={this.state.showOptions}
                                            toggleOptions={() => this.toggleOptions()} />
                                        <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                                        {!this.props.isCheckPoint && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                            <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                                <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                            </div>
                                            {
                                                (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                                    <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                                        style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${content.content.mark} Marks`}>
                                                        <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                                        <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                                    </div> : <></>
                                            }
                                        </div>
                                        }

                                        {/* {
                                            this.props.isReadOnly && this.state.showOptions ?
                                                <div className=" option-margin-bottom">
                                                    {this.getSequencingOptions()}
                                                </div>
                                                :
                                                <></>
                                        } */}


                                    </>


                                    :
                                    <></>
                        }

                        {/* {!this.props.isCheckPoint && !this.props.isMultiStep && <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />} */}





                        {/* {
                            !this.props.isReadOnly ?
                                <div className=" option-margin-bottom">
                                    {this.getSequencingOptions()}
                                </div>
                                :
                                <></>
                        } */}

                        <div className="d-flex align-items-center mt-4">
                            <img className="bulb-image-size" src={getImageSource(ImageConstants.BULB_IMAGE)} />
                            <div className="instruction-text ml-3">
                                {/* {constants.MULTI_SELECT_INSTRUCTION_TEXT} */}
                                {"Write the answer in notebook and later verify"}
                            </div>
                        </div>


                        {
                            this.props.isReadOnly ?
                                <>


                                    <div className="solution-answer-border mb-4"></div>


                                    {
                                        this.state.showSolution ?
                                            <SummarySoultions solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions>
                                            : <></>
                                    }


                                </>
                                : <></>
                        }










                    </div>

                    {<AssessmentFooter userAnswerStatus={this.props.userAnswerStatus} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly} delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}
                    <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                        multiline={true}
                        place='right'
                        type='light' effect='solid'
                        event="mouseover mouseenter"
                        eventOff="mouseleave mouseout scroll mousewheel blur" />
                </div>
                :
                <></>

        );
    }


}



export default Descriptive;
import React from "react";
import './cw-steps.scss';
import SharedThemeCategory from "../../../../shared-storage/category-theme";


export default class CWSteps extends React.Component {
    constructor(props) {
        super(props);
        // this.parser = new Parser();
        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId);
        
    }

    render(){

        // linear-gradient(165deg, #B6F8FF 0%, #A7FFCC 100%);
        let lgButton = `linear-gradient(165deg, ${this.theme[`linearGradient`][`steps`][0]} 0%, ${this.theme[`linearGradient`][`steps`][1]} 100%)`

        return(
            <div className="mb-2 mt-3 p-2" style={{backgroundImage : lgButton}}>
                <span className="step-color" style={{}}>{this.props.data}</span>
            </div>
        )
    }

}
import React from "react";
import './assessment-solution.scss';
import Parser from "../content-parser/content-parser";
import AssessmentApi from "../../../apis/assessment";
import GlossaryParser from '../content-parser/glossary-parser';
import { ImageConstants } from "../../../constants/constants";
import { getThemeImageSource } from "../../../utility/image-source";
import { Constants } from "../../../constants/constants";
import SharedThemeCategory from "../../../../shared-storage/category-theme";


export default class AssessmentSolution extends React.Component {

    staticText = {};

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.glossaryParser = new GlossaryParser();
        console.log(this.props.content.content.solutionSteps.question)
        console.log(this.props.content.content.solutionSteps.summarySolution)

    }

    state = {
        lookUpData: [],
        glossaryContent: null,
    }

    videoPlayerRefs = {};

    componentDidMount() {
        this.getGlossaryContent();
        this.getLookUpData();
    }

    getLookUpData() {

        AssessmentApi.getLookupList({ limit: 100 }).then((res) => {

            //console.log(res)

            if (res) {
                this.setState({ lookUpData: res.response })
            }


        }).catch(err => {
            if (err) {
                //alert(err)
                //console.log(err)
            }
        })

    }


    getLookUpText(id) {

        for (let i = 0; i < this.state.lookUpData.length; i++) {
            if (id == this.state.lookUpData[i].id)
                return this.state.lookUpData[i].text;
        }

    }

    getGlossaryContent = () => {

        if (this.props.content) {

            this.glossaryParser.getParsedContentIdsFromContent([this.props.content], (err, glossaryContent) => {
                this.setState({
                    glossaryContent: glossaryContent
                })
            })

        } else {
            this.setState({
                glossaryContent: {}
            })
        }

    };

    toggleSteps = (data, index) => {

        data.showSteps = data.hasOwnProperty('showSteps') ? !data.showSteps : true;
        this.forceUpdate();
        if (index === this.props.content.content.solutionSteps.steps.length - 1) {
            this.setState({ showBorderLine: !this.state.showBorderLine })
        }

    }

    toggleSolutions = () => {
        this.setState({ showSolutions: !this.state.showSolutions })
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        let textColor = this.theme['themeColor']
        let borderColor = this.theme[`themeColor`]

        return (

            <div className="assessment-solution">

                {this.props.content.content.solutionSteps && (this.props.content.content.solutionSteps.summarySolution ||
                    ((this.props.content.content.solutionSteps.question && Array.isArray(this.props.content.content.solutionSteps.question) &&
                        this.props.content.content.solutionSteps.question.length) || (this.props.content.content.solutionSteps.steps &&
                            Array.isArray(this.props.content.content.solutionSteps.steps) && this.props.content.content.solutionSteps.steps.length))) ?
                    <div className="view-solution-button cursor-pointer mt-3" style={{color : textColor, borderColor : borderColor}}
                    onClick={this.toggleSolutions}>
                        <span className="">{this.state.showSolutions ? Constants.HIDE_DETAILED_SOLUTION : Constants.VIEW_DETAILED_SOLUTION_TEXT}</span>
                        <img className="arrow-size" src={this.state.showSolutions ? getThemeImageSource(ImageConstants.THEME_UP_ARROW, this.props.categoryThemeId) : getThemeImageSource(ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} />
                    </div>
                    :
                    <></>
                }

                {
                    this.state.showSolutions ?
                        <>
                            {this.props.content.content.solutionSteps.summarySolution.length > 0 ?
                                <div className="ml-2 mt-2">
                                    {this.parser.parseLearningContent(this.props.content.content.solutionSteps.summarySolution, this, this.state.glossaryContent, null, null, this.props.categoryThemeId)}
                                </div>
                                :
                                <></>
                            }
                            <div className="solution-container">

                                <div className="approach-container">

                                    {this.props.content.content.solutionSteps.question.map((data) =>
                                        <div className="question-description">
                                            <span className="steps ml-2">{this.getLookUpText(data.id)}</span>
                                            {this.parser.parseLearningContent(data.description, this, this.state.glossaryContent)}
                                        </div>
                                    )}

                                </div>

                                {/* <div>
                                    {this.props.content.content.solutionSteps.steps.map((data, index) =>


                                        <div className={`${(index < this.props.content.content.solutionSteps.steps.length - 1 || this.state.showBorderLine ? "border-line" : 'no-border-line')}`}>

                                            {data.showSteps ?
                                                <Image source={getThemeImageSource(ImageConstants.CURRENT_STEP, this.props.categoryThemeId)} style={Styles.stepHighlight} />
                                                :
                                                <Image source={getImageSource(ImageConstants.ELLIPSE)} style={Styles.ellipse} />
                                            }
                                            <View style={(index < this.props.content.content.solutionSteps.steps.length - 1 ? Styles.stepDescription : Styles.lastStepDescription)}>

                                                <View style={Styles.stepNumberandTitle}>

                                                    <Text style={Styles.stepNumber}>{`Step ${index + 1}`} </Text>

                                                    {this.parser.parseWithStyles(data.name, { p: Styles.stepTitle })}

                                                </View>
                                                <View style={Styles.caretIcon}>
                                                    <TouchableOpacity onPress={() => this.toggleSteps(data, index)} hitSlop={{ top: 15, bottom: 15, left: 15, right: 15 }}>
                                                        <Image source={getImageSource((data.showSteps ? ImageConstants.CARET_UP_CARD : ImageConstants.CARET_DOWN_CARD))} />
                                                    </TouchableOpacity>
                                                </View>
                                            </View>

                                            {data.showSteps &&
                                                <View style={(index < this.props.content.content.solutionSteps.steps.length - 1 ? Styles.stepsContainer : Styles.stepPadding)}>

                                                    <View style={Styles.stepDescriptionBottom}>{this.parser.parseLearningContent(data.description)}</View>

                                                    <Text style={Styles.headings}>{constants.ACTUAL_STEP}</Text>

                                                    <View>{this.parser.parseLearningContent(data.actualStep, this, this.state.glossaryContent, true, null, this.props.categoryThemeId)}</View>

                                                    {index === this.props.content.content.solutionSteps.steps.length - 1 &&
                                                        <>
                                                            {this.props.content.content.solutionSteps.conclusion.length > 0 &&
                                                                <View>
                                                                    <Text style={Styles.conclusion}>{constants.CONCLUSION}</Text>
                                                                    {this.parser.parseLearningContent(this.props.content.content.solutionSteps.conclusion, this, this.state.glossaryContent, true, null, this.props.categoryThemeId)}
                                                                </View>
                                                            }
                                                            <Image source={getImageSource(ImageConstants.LAST_STEP)} style={Styles.lastStep} />
                                                        </>
                                                    }
                                                </View>

                                            }

                                        </div>

                                    )}
                                </div> */}
                            </div>
                        </>
                        :
                        <></>
                }






            </div>

        )
    }

}
import React, { Component } from 'react';
import './bar-chart.scss'; // Import the SCSS file

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Define the chart data
      labels: ['A', 'B', 'C', 'D', 'E', 'F'],
      data: [30, 20, 50, 40, 60, 70], // These are the heights of the bars
    };
  }

  renderBars() {
    const { labels, data } = this.state;
    const maxValue = Math.max(...data); // Find the maximum value to scale the chart properly

    return this.props.options.map((option, index) => (
      <div className="bar" key={index}>

        <div
          className="bar-inner"
          style={{
            height: `${Math.ceil((option.usersAnsweredCount / this.props.studentResponseCount) * 100) - 5}%`,
            backgroundColor: option.isCorrect ? "#22AB93" : "#FF6464", border: `1px solid ${option.isCorrect ? "#22AB93" : "#FF6464"}`
          }} // Dynamically set height
        // style={{ height: `80%` }} // Dynamically set height
        >
          <span className="bar-percentage">{Math.ceil((option.usersAnsweredCount / this.props.studentResponseCount) * 100)}%</span> {/* Show percentage */}
        </div>

        <span className="label">{labels[index]}</span>
      </div>
    ));
  }

  render() {
    const { labels } = this.state;
    return (
      <div className='bar-wrapper'>
        <div className='question-score-text text-center'>% of Responses by Options {`(${this.props.studentResponseCount} responses)`}</div>
        <div className="chart-container">
          {/* <h2>Sales Data</h2> */}
          
          <div className="y-axis">
            {[100, 80, 60, 40, 20, 0].map((value) => (
              <div key={value} className="y-axis-label">{value}%</div>
            ))}
          </div>
          <div className="bar-chart">
            {this.renderBars()}
          </div>
          <div className='y-axis-header'>Response %</div>
          <div className='x-axis-header'>Options</div>
          {/* <div className="x-axis">
          {labels.map((label, index) => (
            <div key={index} className="label">{label}</div>
          ))}
        </div> */}
          {/* <div className="x-axis-label">Months</div>  */}
          {/* <div>Test</div> */}
        </div>
      </div>

    );
  }
}

export default BarChart;

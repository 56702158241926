import React from 'react';
import './learning-content-popup.scss';
import MasterDataApi from '../../../apis/master-data';
import LearningContentPopupWrapper from '../learning-content-popup-wrapper/learning-content-popup-wrapper';
import HomeworkApi from '../../../apis/homework';


export default class LearningContentPopup extends React.Component {

    constructor(props) {
        super(props)
        this.pkLearningElos = []
    }

    pkIndex = 0;

    state = {
        learningElos: null,

        isScrollEnabled: true
    }

    componentDidMount() {
        //console.log(this.props.elos);
        let eloIds = this.props.elos.map((elo) => elo.eloId)
        this.getLearningContent(eloIds);
    }

    getLearningContent = (elos) => {

        if (!this.props.chapterKey)
            return

        let payload = {
            ids: elos,
            // chapterKey: "15845131996920700"
            chapterKey: this.props.chapterKey
        }

        HomeworkApi.getReenforcementLearningELODetails(payload).then((eloDetails) => {
            this.pkLearningElos.push(eloDetails);
            this.setState({
                learningElos: eloDetails
            });
        }).catch(err => {
            if (err) {
                return;
            }
        })
    }

    close() {
        console.log("close");
    }

    onSwipeRight = () => {
        console.log("onSwipeLeft", this.pkIndex);
        if (this.pkIndex != this.pkLearningElos.length - 1) {
            this.pkIndex++;
        }
        let pkLearningElo = this.pkLearningElos[this.pkIndex];
        this.setState({
            learningElos: pkLearningElo
        });
    }

    onSwipeLeft = () => {
        console.log("onSwipeRight", this.pkIndex);

        if (this.pkIndex != 0) {
            this.pkIndex--;
        } else {
            this.closePopup();
        }

        let pkLearningElo = this.pkLearningElos[this.pkIndex];
        this.setState({
            learningElos: pkLearningElo
        });
    }


    showPK(elos) {
        if (elos && elos.length) {
            let eloIds = elos.map((elo) => elo.eloId)
            this.getLearningContent(eloIds);
        }
    }

    closePopup = () => {
        this.props.delegate.togglePopup && this.props.delegate.togglePopup(false)
    }

    onBack = () => {
        if (this.pkLearningElos && this.pkLearningElos.length != 1) {
            this.pkLearningElos.pop();
            this.setState({
                learningElos: this.pkLearningElos[this.pkLearningElos.length - 1]
            });
        } else {
            this.closePopup();
        }
    }

    render() {

        return (

            <div>
                {this.state.learningElos ?
                    <LearningContentPopupWrapper elo={this.state.learningElos} delegate={this} title={this.props.title ? this.props.title : ''} isScrollEnabled={this.state.isScrollEnabled} key={this.state.learningElos.length ? this.state.learningElos[0].eloId : ""} onBack={this.onBack} isLateralMenuPopup={this.props.isLateralMenuPopup} navigationInfo={{ chapterKey: this.props.chapterKey }} />
                    : <></>
                }
            </div>

        );
    }
}




import React from "react";
import './correct-incorrect-answer-modal.scss';
import SharedStaticText from "../../../../shared-storage/static-text-store";
import { Modal, ModalBody } from "reactstrap";
import { getThemeImageSource, getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import SummarySoultions from "../summary-solution/summary-solution";


export default class CorrectIncorrectAnswerModel extends React.Component {

    correctAnswerStaticText = {};
    inCorrectAnswerStaticText = {};

    state = {
        enableNextButton: false
    }

    constructor(props) {
        super(props);

        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');

        setTimeout(() => {
            this.enableNextButton()
        }, 5000)
    }

    enableNextButton() {
        this.setState({ enableNextButton: true })
    }

    onNext = () => {
        this.props.delegate && this.props.delegate.next && this.props.delegate.next();
    }

    onViewCorrectAnswer = () => {
        this.props.delegate && this.props.delegate.showCorrectAnswer && this.props.delegate.showCorrectAnswer();
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <>
                {
                    this.props.isCorrect ?
                        <Modal isOpen={true} className="cw-custom-popup-center" size={'md'}>
                            <div>
                                <img className="success-bg-stars" src={getImageSource(ImageConstants.SUCCESS_BG_STARS)} />
                            </div>
                            <div className="pt-4 my-auto text-right">
                                <img className="challenge-header" src={getImageSource(ImageConstants.GREEN_HEADER)}></img>
                                {/* <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.closeModal()} /> */}
                            </div>
                            <ModalBody>
                                <div className="pratice-container">
                                    <div className="d-flex flex-row align-items-center justify-content-center">
                                        {
                                            this.props.isCorrect ?
                                                <div>
                                                    <img className="image-size" src={getImageSource(ImageConstants.GREEN_TICK_ICON_NEW)} />
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            this.props.isCorrect ?
                                                <div className="ml-1">
                                                    <div className="name-header">{this.correctAnswerStaticText.title}</div>
                                                    {/* <div className="sub-title">{this.correctAnswerStaticText.subTitle}</div> */}
                                                </div>
                                                :
                                               <></>
                                        }

                                    </div>

                                    <div>
                                        <img className="white-tick-icon" src={getImageSource(ImageConstants.WHITE_TICK_ICON)} />
                                    </div>

                                    {/* <div className="d-flex justify-content-center  mt-5 concepts-covered-wrapper">
                            <div>
                                <SummarySoultions solutionSteps={this.props.content ? this.props.content.content.solutionSteps : ""} isPopup="true" categoryId={this.props.categoryThemeId}></SummarySoultions>
                            </div>
                        </div> */}
                                    <div className="d-flex align-items-center justify-content-center mt-2">

                                        {
                                            this.props.isCorrect ?
                                                // <div className="take-challenge cursor-pointer  text-center"
                                                //     style={{ backgroundColor: this.theme['themeColor'] }}
                                                //     onClick={this.onNext}>
                                                //     {this.correctAnswerStaticText.buttonTitle}
                                                // </div>
                                                <div className="view-correct-answer cursor-pointer mr-3" style={{ backgroundColor: this.theme['themeColor'], color  : "#FFFFFF" }}
                                                    onClick={this.onViewCorrectAnswer}>
                                                    {
                                                        "View Solution"
                                                    }
                                                </div>
                                                :
                                                <>
                                                    {/* <div className="view-correct-answer cursor-pointer mr-3" style={{ color: this.theme['themeColor'], border: `1px solid ${this.theme['themeColor']}` }}
                                                        onClick={this.onViewCorrectAnswer}>
                                                        {
                                                            "View Solution"
                                                        }
                                                    </div> */}
                                                    {/* {
                                            this.state.enableNextButton ?
                                                <div className="take-challenge cursor-pointer text-center" style={{ background: this.theme['themeColor'] }}
                                                    onClick={this.onNext}>
                                                    {this.inCorrectAnswerStaticText.nextButtonTitle}</div>
                                                :
                                                    <div className="take-challenge text-center" style={{ background: "#d1d3d5", color :this.theme['themeColor'] }}
                                                    >
                                                        {this.inCorrectAnswerStaticText.nextButtonTitle}</div>
                                                


                                        } */}

                                                </>
                                        }


                                    </div>
                                    <div className="position">
                                        <img className={`challenge-footer ${this.props.isCorrect ? "" : "for-wrong-answer"}`} src={getImageSource(ImageConstants.GREEN_FOOTER)}></img>
                                    </div>
                                    <div className="position">
                                        <img className="hand-icon" src={getImageSource(ImageConstants.HAND_ICON)} />
                                    </div>
                                </div>
                            </ModalBody>

                        </Modal>

                        :

                        <Modal isOpen={true} className="cw-custom-popup-center" size={'md'}>
                            <div className="pt-4 my-auto text-right">
                                <img className="challenge-header" src={getImageSource(ImageConstants.RED_HEADER)}></img>
                                {/* <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.closeModal()} /> */}
                            </div>
                            <ModalBody>
                                <div className="pratice-container">
                                    <div className="d-flex flex-row align-items-center justify-content-center">
                                        {
                                            this.props.isCorrect ?
                                                <div>
                                                    {/* <img className="image-size" src={getImageSource(ImageConstants.GREEN_TICK_ICON_NEW)} /> */}
                                                </div>
                                                :
                                                <div>
                                                    <img className="image-size" src={getImageSource(ImageConstants.RED_CLOSE_ICON_NEW)} />
                                                </div>
                                        }
                                        {
                                            this.props.isCorrect ?
                                                <div className="ml-1">
                                                    {/* <div className="name-header">{this.correctAnswerStaticText.title}</div> */}
                                                    {/* <div className="sub-title">{this.correctAnswerStaticText.subTitle}</div> */}
                                                </div>
                                                :
                                                <div className="ml-1">
                                                    <div className="name-header">{this.inCorrectAnswerStaticText.title}</div>
                                                    <div className="sub-title">{this.inCorrectAnswerStaticText.subTitle}</div>
                                                </div>
                                        }

                                    </div>
                                    <div>
                                        <img className="red-cross-icon" src={getImageSource(ImageConstants.RED_WRONG_ICON)} />
                                    </div>
                                    {/* <div className="d-flex justify-content-center  mt-5 concepts-covered-wrapper">
                            <div>
                                <SummarySoultions solutionSteps={this.props.content ? this.props.content.content.solutionSteps : ""} isPopup="true" categoryId={this.props.categoryThemeId}></SummarySoultions>
                            </div>
                        </div> */}
                                    <div className="d-flex align-items-center justify-content-center mt-2">

                                        {
                                            this.props.isCorrect ?
                                                // <div className="take-challenge cursor-pointer  text-center"
                                                //     style={{ backgroundColor: this.theme['themeColor'] }}
                                                //     onClick={this.onNext}>
                                                //     {this.correctAnswerStaticText.buttonTitle}
                                                // </div>
                                                // <div className="view-correct-answer cursor-pointer mr-3" style={{ color: this.theme['themeColor'], border: `1px solid ${this.theme['themeColor']}` }}
                                                //     onClick={this.onViewCorrectAnswer}>
                                                //     {
                                                //         "View Solution"
                                                //     }
                                                // </div>
                                                <></>
                                                :
                                                <>
                                                    <div className="view-correct-answer cursor-pointer mr-3" style={{ backgroundColor: this.theme['themeColor'], color  : "#FFFFFF" }}
                                                        onClick={this.onViewCorrectAnswer}>
                                                        {
                                                            "View Solution"
                                                        }
                                                    </div>
                                                    {/* {
                                            this.state.enableNextButton ?
                                                <div className="take-challenge cursor-pointer text-center" style={{ background: this.theme['themeColor'] }}
                                                    onClick={this.onNext}>
                                                    {this.inCorrectAnswerStaticText.nextButtonTitle}</div>
                                                :
                                                    <div className="take-challenge text-center" style={{ background: "#d1d3d5", color :this.theme['themeColor'] }}
                                                    >
                                                        {this.inCorrectAnswerStaticText.nextButtonTitle}</div>
                                                


                                        } */}

                                                </>
                                        }


                                    </div>
                                    <div className="position">
                                        <img className={`challenge-footer ${this.props.isCorrect ? "" : "for-wrong-answer"}`} src={getImageSource(ImageConstants.RED_FOOTER)}></img>
                                    </div>
                                    <div className="position">
                                        <img className="hand-icon" style={{top : "-11.03rem"}} src={getImageSource(ImageConstants.HAND_ICON)} />
                                    </div>
                                </div>
                            </ModalBody>

                        </Modal>
                }

            </>

        );
    }
}
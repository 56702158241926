import React from "react";
import './edit-profile-screen.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedProfile from "../../../../shared-storage/profile";
import EditPopup from "../edit-popup/edit-popup";
import CommonApi from "../../../apis/common";
import EditClass from "../edit-class/edit-class";
import SchoolConnect from "../school-connect/school-connect";
import SchoolSectionPopup from "../school-connect/section-popup/section-popup";
import FireBaseEvent from '../../../firebase-events/firebase-events';

class EditProfileScreen extends React.Component {


    constructor(props) {
        super(props)

        let userInfo = SharedProfile.getProfile()
        this.state.userInfo = userInfo
    }

    state = {

    }




    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToProfileScreen() {
        this.props.delegate && this.props.delegate.hideProfileEditScreen && this.props.delegate.hideProfileEditScreen()
    }


    showProfileEdit(type) {

        if (type == 'SECTION') {
            this.setState({showSchoolSectionPopup : true})
            return
        }

        this.setState({ showProfileEdit: true, activeEdit: type })
    }

   

    hideSectionPopup() {
        this.setState({showSchoolSectionPopup : false})
    }

    hideProfileEdit = () => {
        this.setState({ showProfileEdit: false })
    }

    onSuccessUpdate = (value) => {

        //console.log(value)

        let updateType = this.state.activeEdit;
        let userInfo = this.state.userInfo;

        if (updateType == 'NAME') {
            userInfo.fullName = value.name
            userInfo.name = value.name
            FireBaseEvent('My Profile', { 'User Id': userInfo.userId, 'Name Updated': value.name })
        }
        if (updateType == 'NICK_NAME') {
            userInfo.nickName = value.nickName
            FireBaseEvent('My Profile', { 'User Id': userInfo.userId, 'Nick Name Updated': value.nickName })
        }

        if (updateType == 'MOBILE_NUMBER') {
            userInfo.mobileNumber = value.mobileNumber;
            userInfo.countryCode = value.countryCode
        }

        if (updateType == 'SCHOOL') {
            // userInfo.school = { title: value.school }
            userInfo.schoolName = value.schoolName
        }

        if (updateType == 'SECTION') {
            userInfo.section = value.section
            if (value.sectionId) {
                userInfo.sectionId = value.sectionId
            }
        }

        if (updateType == 'EMAIL') {
            userInfo.email = value.email

            FireBaseEvent('My Profile', { 'User Id': userInfo.userId, 'Email Updated': value.email })
        }

        if (updateType == 'CITY') {
            userInfo.city = value.city;
            userInfo.state = value.state;
            userInfo.country = value.country;
            userInfo.timeZone = value.timeZone;

            // moengage.logCustomEvent('My Profile', { 'User Id': userInfo.userId, 'City Updated': value.city, 'State Updated': value.state })
            FireBaseEvent('My Profile', { 'User Id': userInfo.userId, 'City Updated': value.city, 'State Updated': value.state })
            // Facebook.logCustomEvent('My Profile', { 'User Id': userInfo.userId, 'City Updated': value.city, 'State Updated': value.state })
            // BranchIO.logCustomEvent('My Profile', { 'User Id': userInfo.userId, 'City Updated': value.city, 'State Updated': value.state })

        }


        this.setState({ userInfo })
        SharedProfile.setProfile(userInfo);


    }

    onPressClassEdit = () => {
        // this.props.navigation.navigate('EditClass')

        this.getTranferLimit()
    }

    getTranferLimit = () => {

        let payload = {}

        this.toggleLoading(true)

        CommonApi.getTranferLimit(payload).then((response) => {

            this.toggleLoading(false)

            if (response && response.allowToUpgrade) {
                this.setState({showEditClass : true})
            } else
                this.setState({ showWarningMessage: true })
        }).catch(err => {
            if (err) {
                this.toggleLoading(false)
                return;
            }
        })
    }

    hideEditClass() {
        this.setState({showEditClass : false})
    }

    toggleLoading = (value) => {
        this.setState({ loading: value })
    }

    onPressSchoolEdit = () => {
        this.setState({showSchoolConnectPopup : true})
        // this.props.navigation.push('SchoolConnect')
        // this.props.navigation.push('AddYourSchoolV2', { meta: { from: constants.SCHOOL_EDIT } })
    }

    hideSchoolConnectPopup() {
        this.setState({showSchoolConnectPopup : false})
    }


    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToProfileScreen()}>{`Profile >`}</span>
                <span className='pl-2 title-1'>{`Edit Profile Info`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    render() {
        return (
            <div className="edit-profile-screen">
                <div className="text-right">
                    <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                </div>
                <div className="header-title-margin-top">
                    {this.renderBreadcrumbForChapterList()}
                    <div className="d-flex justify-content-between">
                        <div className="title">Profile</div>
                    </div>
                    <div>
                        <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                    </div>
                </div>
                <div className="edit-profile-info">
                    <div className="profile-info-header">
                        <div className="info-text">Edit Profile Info</div>
                    </div>
                    <div className="d-flex input-field-wrapper mb-5">
                        <div className="col">
                            <div className="mt-4">
                                <div className="row">
                                    <div className="col">
                                        <label className="cw-input-label-1 mb-2">FULL NAME</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="cw-input-1" onClick={() => { this.showProfileEdit('NAME') }}>
                                            {this.state.userInfo.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="row">
                                    <div className="col">
                                        <label className="cw-input-label-1">MOBILE NO</label>
                                    </div>
                                </div>
                                <div className="row flex-column">
                                    {/* <div className="cw-input-wrapper cursor-pointer" onClick={() => { this.showProfileEdit('MOBILE_NUMBER') }}> */}
                                    <div className="cw-input-wrapper">

                                        <div className={`d-inline-block country-code disabled`}>
                                            {this.state.userInfo.countryCode}
                                        </div>
                                        {/* <img onClick={() => this.openCountryCode()} className={`country-code-down-arrow cursor-pointer ${this.state.selectedCountry.dialCode.length == 3 ? 'country-code-down-arrow-margin' : ''}`} src={getImageSource(ImageConstants.DOWN_ARROW)} /> */}
                                        <img className="country-code-separator" src={getImageSource(ImageConstants.COUNTRY_SEPARATOR)} />
                                        <span className="cw-input-1-for-mobileNumber disabled">{this.state.userInfo.mobileNumber}</span>
                                    </div>
                                    <div><img className="info-icon-size" src={getImageSource(ImageConstants.INFO_ICON_RED)} /><span className="mobile-number-change-text ml-2">Phone number can be changed only through mobile app.</span></div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="row">
                                    <div className="col">
                                        <label className="cw-input-label-1 mb-2">SCHOOL</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" onClick={() => this.onPressSchoolEdit()}>
                                        {this.state.userInfo.schoolName ?
                                            <div className="cw-input-1">
                                                {this.state.userInfo.schoolName}
                                            </div>
                                            :
                                            <div className="place-holder-text">Connect Your School</div>
                                        }

                                    </div>
                                </div>
                                {this.state.userInfo && this.state.userInfo.schoolVerificationCode ?
                                    <div className="school-code">SCHOOL CODE: {this.state.userInfo.schoolVerificationCode}</div>
                                    : <></>
                                }
                            </div>

                            {
                                this.state.userInfo && this.state.userInfo.schoolCode ?
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col">
                                                <label className="cw-input-label-1 mb-2">SECTION</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" onClick={() => { this.showProfileEdit('SECTION') }}>
                                                {this.state.userInfo.section ?
                                                    <div className="cw-input-1">{this.state.userInfo.section}</div>
                                                    :
                                                    <div className="place-holder-text">Enter Your Class Section</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }

                        </div>
                        <div className="col">
                            <div className="mt-4">
                                <div className="row">
                                    <div className="col">
                                        <label className="cw-input-label-1 mb-2">NICK NAME</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" onClick={() => { this.showProfileEdit('NICK_NAME') }}>
                                        <div className="cw-input-1">
                                            {this.state.userInfo.nickName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="row">
                                    <div className="col">
                                        <label className="cw-input-label-1 mb-2">EMAIL ID</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" onClick={() => { this.showProfileEdit('EMAIL') }}>
                                        {
                                            this.state.userInfo.email ?
                                                <div className="cw-input-1">{this.state.userInfo.email}</div>
                                                :
                                                <div className="place-holder-text">{"Enter Your Parent Email Id"}</div>
                                        }

                                    </div>
                                </div>
                            </div>
                            {this.state.userInfo.isUserAtLastClass && this.props.editProfileInfo ?
                                <></>
                                :
                                <div className="mt-4">
                                    <div className="row">
                                        <div className="col">
                                            <label className="cw-input-label-1 mb-2">CLASS</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* As Per Ravi not needed in the student portal if teacher wants they can transfer from school portal */}
                                    {/* <div className="col" onClick={this.onPressClassEdit}> */}
                                        <div className="col">
                                            {this.state.userInfo.class ? <div className="cw-input-1">{this.state.userInfo.class}</div> :
                                                <div className="place-holder-text">Enter Your Class</div>}

                                        </div>
                                    </div>
                                </div>
                            }


                            <div className="city-margin-top">
                                <div className="row">
                                    <div className="col">
                                        <label className="cw-input-label-1 mb-2">CITY</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" onClick = {() => { this.showProfileEdit('CITY') }}>
                                        {this.state.userInfo.city ?
                                            <div className="cw-input-1">{this.state.userInfo.city}{this.state.userInfo.state ? ', ' + this.state.userInfo.state : ''}{this.state.userInfo.country ? ', ' + this.state.userInfo.country : ''}</div>
                                            :
                                            <div className="place-holder-text">Enter Your City</div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {
                    this.state.showProfileEdit ? <EditPopup close={this.hideProfileEdit} type={this.state.activeEdit} delegate={this} /> : <></>
                }
                 {
                    this.state.showEditClass ? <EditClass delegate = {this} /> : <></>
                 }
                 {
                    this.state.showSchoolConnectPopup ? <SchoolConnect delegate = {this} /> : <></>
                 }
                 {
                    this.state.showSchoolSectionPopup ? <SchoolSectionPopup delegate = {this} /> : <></>
                 }

            </div>
        )
    }
}

export default EditProfileScreen;
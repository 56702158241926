import React, { Component } from 'react';
import './two-step-event-leaderboard.scss';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import { ImageConstants, Constants } from '../../../constants/constants';
import SharedProfile from '../../../../shared-storage/profile';
import Loading from '../../common/loading/loading';
import EventApi from '../../../apis/event';

export default class TwoStepEventLeaderboard extends Component {
    constructor(props) {
        super(props);

        // const { navigation } = this.props;
        // this.meta = navigation.getParam('meta');
        this.meta = this.props.meta;


        this.staticText = {}
        if (this.meta && this.meta.eventPayload && this.meta.eventPayload.staticText && this.meta.eventPayload.staticText.leaderBoardScreen) {
            this.staticText = this.meta.eventPayload.staticText.leaderBoardScreen
        }

        // this.meta.eventPayload &&
        //     tthis.meta.eventPayload &&

        this.userInfo = SharedProfile.getProfile()
        this.payload = this.buildPayload()

        this.state.loading = true
        this.getLeaderboard(this.payload)
    }

    componentDidMount = () => {
    }

    index = 0
    page = 1
    totalPages = 0
    topPageNumber = 1;
    bottomPageNumber = 1
    lastUpdatedDate = null

    topRankerPage = 1;
    topRankerTotalPages = 0
    offset = 0

    state = {
        showStudentScoreboard: true,
        selectedFilter: 'ACROSS_SCHOOLS',
        data: {
        },

        topRankers: [],
        others: [
        ],
        // userRank: {
        //     // name: '',
        //     // rank: 1,
        //     // marks: 0,
        //     // city: ''
        // },
        resultTimer: 60,

        FILTER: 'COUNTRY',
        showMessage: false,
        hideTopRankers: false,

        qualifierEventPosition: 1,
        eventType: 'QUALIFIER_EVENT',
        eventId: '60d9724d8f18650ba19cd837',
        assessmentId: '604664d79b269f034cb8440a'
    }

    rankColors = [
        {
            largeCircle: '#FFE596',
            smallCircle: '#FAD300'
        },
        {
            largeCircle: '#E0E0E0',
            smallCircle: '#B3B3B3'
        },
        {
            largeCircle: '#FFCFA5',
            smallCircle: '#CD7F32'
        }
    ]

    onMomentScrollStart = false
    scollCounter = 0

    randomColors = [
        { color: "#D7FFA5" },
        { color: "#C7FFF8" },
        { color: "#BFFFD1" },
        { color: "#FDDBF3" },
        { color: "#80BFFF" }
    ]

    onEndReachedCalledDuringMomentum = true
    scrollPosition = {}

    getLeaderboard = (payload, append) => {

        if (payload) {
            payload = {
                ...payload,
                sortKey: 'rank',
                sortType: "asc"
            }
        }

        console.log(':::::', payload)

        if (append) {
            this.setState({ smallLoading: true })
        }

        // this.getEventRank()

        EventApi.getTwoStepEventLeaderboard(payload).then((res) => {
            console.log(res)
            this.setState({ smallLoading: false })

            if (this.state.loading) {
                this.toggleFullLoading(false)
            }
            this.totalPages = res.meta.totalPages;

            if (res.response) {
                console.log('api called')

                let list = []

                if (this.state.selectedFilter == 'GLOBAL' || this.state.selectedFilter == 'ZONAL') {
                    list = res.response.map(item => ({
                        name: item.schoolName,
                        rank: item.rank,
                        marks: item.markScored,
                        city: item.schoolLocality,
                        ...item
                    }))
                }

                if (this.state.selectedFilter == 'MY_SCHOOL' || this.state.selectedFilter == 'ACROSS_SCHOOLS') {
                    list = res.response.map(item => ({
                        name: item.name,
                        rank: item.rank,
                        marks: item.markScored,
                        ...item
                    }))
                }

                if (append) {
                    let others = this.state.others;
                    others = others.concat(list)
                    this.setState({ others, smallLoading: false })
                } else {
                    this.setState({
                        others: list,
                        smallLoading: false
                    })
                }

            }
        }).catch(err => {
            if (err) {
                return
            }
        })

    }

    getEventRank = () => {
        let payload = {
            eventId: this.meta.eventId,
            eventType: this.meta.eventId
        }

        // if (this.meta.eventType == 'QUALIFIER_EVENT') {
        //     payload.qualifierEvent = this.meta.qualifierEventPosition
        // }

        // if (this.meta.eventType == 'FINAL_EVENT') {
        //     payload.qualifierEvent = this.meta.qualifierEventPosition
        // }

        if (this.state.selectedFilter == 'MY_SCHOOL') {
            payload.type = "STUDENT_BY_SCHOOL_RANKING"
        }

        if (this.state.selectedFilter == 'ACROSS_SCHOOLS') {
            payload.type = "STUDENT_RANKING"
        }

        // if (this.state.selectedFilter == 'GLOBAL') {
        //     payload.type = "SCHOOL_RANKING_BY_GLOBAL"
        // }

        // if (this.state.selectedFilter == 'ZONAL') {
        //     payload.type = "SCHOOL_RANKING_BY_ZONE"
        //     payload.zone = this.userInfo.zone
        // }

        console.log("RANK", payload)


        EventApi.getUserEventRank(payload).then((res) => {
            if (res) {

                let objBuild = {
                    name: this.userInfo.fullName,
                    rank: res.rank,
                    marks: res.markScored,
                    timeTaken: res.timeTaken
                }

                if (this.state.selectedFilter == 'GLOBAL' || this.state.selectedFilter == 'ZONAL') {
                    objBuild.name = res.schoolName
                }

                this.setState({ userRank: objBuild })
            }

        }).catch(err => {
            if (err) {
                this.setState({ smallLoading: false })
                return
            }

        })

    }

    buildPayload = () => {

        let payload = {
            page: 1,
            limit: this.staticText && this.staticText.leaderBoardLimit ? this.staticText.leaderBoardLimit : 3,
            eventId: this.meta.eventId,
            type: "STUDENT_RANKING",
            class: this.userInfo.class
        }


        if (this.state.selectedFilter == 'MY_SCHOOL') {
            payload.type = "STUDENT_BY_SCHOOL_RANKING"
            payload.schoolCode = this.userInfo.schoolCode
            payload.class = this.userInfo.class
        }

        if (this.state.selectedFilter == 'ACROSS_SCHOOLS') {
            payload.type = "STUDENT_RANKING"
            payload.class = this.userInfo.class
        }

        return payload
    }

    scrollToUser() {

        if (!this.activeUserPosition)
            return;


        this.scroll.scrollTo({
            x: 0,
            y: this.activeUserPosition,
            animated: true,
        });

    }

    getNamesFirstLetter = (name) => {

        if (!name)
            return ''

        name = name.replace(/\s\s+/g, ' ');

        let splittedName = name.split(' ')

        let letters = splittedName.map(item => item.length > 0 ? item[0].toUpperCase() : '')

        letters = letters.splice(0, 2)

        return letters.join('')
    }

    close = () => {
        clearInterval(this.timerInterval);
        this.props.navigation.goBack()
    }

    scollEndReached = () => {

        // if (this.totalPages <= this.page)
        //     return;

        // this.page = this.page + 1;

        // let payload = {
        //     ...this.payload,
        //     page: this.page
        // }

        // this.getMplLeaderboard(payload, true)
    }

    scollToActive() {
        setTimeout(() => {
            this.scroll.scrollToIndex({ animated: true, index: this.index });
        }, 100)
    }

    getItemLayout = (data, index) => {
        return {
            length: 100, offset: 110 * index, index
        }
    }

    resetList = () => {
        this.topPageNumber = 1;
        this.bottomPageNumber = 1
        this.activeUserPosition = null
        this.totalPages = 0
        this.topRankerPage = 1;
        this.topRankerTotalPages = 0
        this.onMomentScrollStart = false
        this.offset = 0
        this.setState({ topRankers: [], others: [], hideTopRankers: false })
    }

    getRandomColors = (index) => {
        if (index === 1) {
            return '#FFDB7A'
        }
        else if (index === 2) {
            return '#E0E0E0'
        }
        else if (index === 3) {
            return '#F8CAA0'
        }
        else {
            return this.randomColors[index % 5].color
        }
    }

    renderGiftBoxIcon = (index) => {

        switch (index) {

            case 1:
                return <img src={getImageSource(ImageConstants.GOLD_GIFT_BOX)} />

            case 2:
                return <img src={getImageSource(ImageConstants.SILVER_GIFT_BOX)} />

            case 3:
                return <img src={getImageSource(ImageConstants.BRONZE_GIFT_BOX)} />

            // default:
            //     return <Image style={Styles.giftBoxIcon} source={getImageSource(ImageConstants.SILVER_GIFT_BOX)} />
        }

    }

    renderMedals = (index) => {
        switch (index) {

            case 1:
                return <img src={getImageSource(ImageConstants.GOLD_MEDAL)} />

            case 2:
                return <img src={getImageSource(ImageConstants.SILVER_MEDAL)} />

            case 3:
                return <img src={getImageSource(ImageConstants.BRONZE_MEDAL)} />

            default:
                return <img src={getImageSource(ImageConstants.BRONZE_MEDAL)} />
        }
    }

    renderBadges = (index) => {

        if (this.state.selectedFilter === 'MY_SCHOOL') {
            return
        }

        let prizeObj = this.staticText.prizeMoney ? this.staticText.prizeMoney : ""

        let finalWinnersCount = this.staticText.finalWinnersCount ? this.staticText.finalWinnersCount : 3

        let finalSchoolWinnersZoneCount = this.staticText.finalSchoolWinnersZoneCount ? this.staticText.finalSchoolWinnersZoneCount : 1

        let finalSchoolWinnersGlobalCount = this.staticText.finalSchoolWinnersGlobalCount ? this.staticText.finalSchoolWinnersGlobalCount : 1

        // if (this.meta.eventType == 'QUALIFIER_EVENT' && this.state.selectedFilter === 'ACROSS_SCHOOLS' && (prizeObj && prizeObj[`${index}`])) {

        return (<div className='prize-money-container'>
            {this.renderGiftBoxIcon(index)}


        </div>)
        // }

        // else if (this.state.selectedFilter === 'ACROSS_SCHOOLS' && this.meta.eventType == 'FINAL_EVENT' && index <= finalWinnersCount) {
        //     return (
        //         this.renderMedals(index)
        //     )
        // }

        // else if (this.state.selectedFilter === 'GLOBAL' && this.meta.eventType == 'FINAL_EVENT' && index <= finalSchoolWinnersGlobalCount) {

        //     return this.renderTrophy(index)

        // }

        // else if (this.state.selectedFilter === 'ZONAL' && this.meta.eventType == 'FINAL_EVENT' && index <= finalSchoolWinnersZoneCount) {

        //     return this.renderShield(index)

        // }
    }

    renderShield = (index) => {
        switch (index) {

            case 1:
                return <img src={getImageSource(ImageConstants.MPL_SHIELD)} />

            default:
                return <img src={getImageSource(ImageConstants.MPL_SHIELD)} />
        }
    }

    renderTrophy = (index) => {
        switch (index) {

            case 1:
                return <img src={getImageSource(ImageConstants.MPL_TROPHY)} />

            default:
                return <img src={getImageSource(ImageConstants.MPL_TROPHY)} />
        }
    }

    getCardBackground = (index) => {
        // if (this.state.selectedFilter === 'MY_SCHOOL' || index > 2) {
        //     return 'white'
        // }
        // else if ((this.state.selectedFilter === 'ZONAL' || this.state.selectedFilter === 'GLOBAL')) {
        //     if (index === 0 && this.meta.eventType == 'FINAL_EVENT') {
        //         return '#FFFBE2'
        //     } else {
        //         return 'white'
        //     }
        // }
        // else {
        if (index === 1) {
            return '#FFFBE2'
        }
        else if (index === 2) {
            return '#F7F7F7'
        }
        else if (index === 3) {
            return '#FFF5EC'
        }
        else {
            return 'white'
        }
        // }

    }

    isWinnerWonPrize = (index) => {

        let prizeObj = this.staticText.prizeMoney ? this.staticText.prizeMoney : ""

        let finalWinnersCount = this.staticText.finalWinnersCount ? this.staticText.finalWinnersCount : 3

        let finalSchoolWinnersZoneCount = this.staticText.finalSchoolWinnersZoneCount ? this.staticText.finalSchoolWinnersZoneCount : 1

        let finalSchoolWinnersGlobalCount = this.staticText.finalSchoolWinnersGlobalCount ? this.staticText.finalSchoolWinnersGlobalCount : 1



        if (index <= 3) {
            return { fontSize: '20px', fontWeight : '700' }
        } else if (index > 3 && index < 99) {
            return { fontSize: '20px',fontWeight : '700' }
        } else if (index <= 99) {
            return { fontSize: '20px',fontWeight : '700' }
        }
        else if (index > 99) {
            return { fontSize: '20px',fontWeight : '700' }

        } else if (index > 9999) {
            return { fontSize: '20px', fontWeight : '700' }
        }

    }

    renderOthers = () => {

        console.log(this.state.others)

        if (!this.state.others.length)
            return <></>

        let elements = []

        elements.push(
            <>
                <div className='dummy-scroll'></div>
                {this.state.selectedFilter === 'MY_SCHOOL' ?
                    <div className='instruction-text d-flex align-items-center'><img className='info-icon mr-2' src={getImageSource(ImageConstants.INFO_ICON)} /> {this.staticText.schoolLeaderboardDisclaimerText ? this.staticText.schoolLeaderboardDisclaimerText : 'There are no prizes for school level winners'}</div>
                    :
                    <div className='instruction-text d-flex align-items-center'><img className='info-icon mr-2' src={getImageSource(ImageConstants.INFO_ICON)} />{this.staticText.studentLeaderboardDisclaimerText ? this.staticText.studentLeaderboardDisclaimerText : 'Ranking is based on Scores & Time taken'}</div>
                }
            </>
        )

        for (let i = 0; i < this.state.others.length; i++) {
            let item = this.state.others[i];
            let index = i

            elements.push(

                <div className='row row-wrapper align-items-center' style={{ backgroundColor: this.getCardBackground(item.rank), borderRadius: '10px' }}>
                    <div className='left-wrapper col-2 mr-0 pr-0 column-max-width'>
                        <div className='circle d-flex justify-content-center align-items-center' style={{ backgroundColor: this.getRandomColors(item.rank) }}>
                            <div className='circle-text'>{this.getNamesFirstLetter(item.name)}</div>
                        </div>
                    </div>
                    <div className='middle-wrapper col ml-0 pl-0'>
                        <div className='name-text'>
                            {item.name}
                        </div>
                        <div style={{ flexDirection: 'row' }}>
                            {/* {this.state.showSchoolScoreboard && item.city ? */}
                            <div className='d-flex align-items-center'>
                                <div><img className='exam-pad-icon mr-2' src={getImageSource(ImageConstants.EXAM_PAD_ICON_GRAY)} /></div>
                                <div className='marks-text pt-1'>{item.marks}</div>
                            </div>
                            {/* :
                                <></>} */}

                            {/* <View style={[Styles.nameWrapper, Styles.marksContainer ]}>
                                <Image source={getImageSource(ImageConstants.CLOCK)} />
                                <Text style={Styles.markText}>{item.timeTaken} {this.staticText.minsText ? this.staticText.minsText : 'Mins'}</Text>
                            </View> */}
                        </div>

                    </div>
                    {/* <View style={Styles.prizeWrapper}>

                        {this.renderBadges(item.rank)}

                    </View> */}

                    <div className='right-wrapper col-2'>
                        <div className='' style={this.isWinnerWonPrize(item.rank)}>{item.rank}</div>
                    </div>
                </div>


            )
        }

        elements.push(
            <div className='dummy-scroll' style={{}}></div>
        )


        return (
            <div>
                {elements}
                {/* {this.state.smallLoading ? 
                <Image source={getImageSource(ImageConstants.LOADING_GIF)} style={Styles.gif} resizeMode="contain" /> : <></>} */}
            </div>
        )
    }

    isCloseToBottom({ layoutMeasurement, contentOffset, contentSize }) {
        return layoutMeasurement.height + contentOffset.y >= contentSize.height - 10;
    }

    isCloseToBottomForHarizontal({ layoutMeasurement, contentOffset, contentSize }) {

        console.log(layoutMeasurement.width)
        console.log(contentOffset.x)
        console.log(contentSize.width)

        return layoutMeasurement.width + contentOffset.x >= contentSize.width;
    }

    isCloseToTop({ layoutMeasurement, contentOffset, contentSize }) {
        return contentOffset.y == 0;
    }


    switchScoreboard = (type) => {

        // if (type == 'student') {
        //     this.setState({
        //         showStudentScoreboard: true, showSchoolScoreboard: false, selectedFilter: 'ACROSS_SCHOOLS', userRank: ""
        //     }, () => {
        //         this.page = 1
        //         this.payload = this.buildPayload()
        //         this.toggleFullLoading(true)
        //         this.getMplLeaderboard(this.payload)
        //     })
        // } else if (type == 'school') {
        //     this.setState({ showStudentScoreboard: false, showSchoolScoreboard: true, selectedFilter: "GLOBAL", userRank: "" }, () => {
        //         this.page = 1
        //         this.payload = this.buildPayload()
        //         this.toggleFullLoading(true)
        //         this.getMplLeaderboard(this.payload)
        //     })
        // }
    }

    toggleFullLoading = (value) => {
        this.setState({ loading: value })
    }

    getScoreBoardWithFilter = (type) => {
        // this.setState({ selectedFilter: type, userRank: "" }, () => {
        //     this.page = 1
        //     this.payload = this.buildPayload()
        //     this.toggleFullLoading(true)
        //     this.getMplLeaderboard(this.payload)
        // })
    }

    goToEventResultScreen() {
        this.props.delegate && this.props.delegate.hideLeaderboard && this.props.delegate.hideLeaderboard()
    }

    hideResultScreen() {
        this.props.delegate && this.props.delegate.hideResultScreen && this.props.delegate.hideResultScreen()
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    renderBreadcrumbForKnowMore = () => {
        return (
            <div className='breadcrumb mt-1'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.hideResultScreen()} >{`Events >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToEventResultScreen()}>{`${this.meta && this.meta?.eventPayload?.title} > `}</span>
                <span className='active title-1 pl-2'>{`Leaderboard`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }


    render() {

        return (

            !this.state.loading ?
                <div className='event-leaderboard mb-5'>
                    {this.renderBreadcrumbForKnowMore()}
                    {/* <Image source={getThemeImageSource(ImageConstants.LEARNING_CONTENT_HEADER, this.state.categoryId)} resizeMode="stretch" style={Styles.headerContainerImage} /> */}


                    {/* <TouchableOpacity onPress={this.close} style={Styles.closeIcon} hitSlop={Styles.hitSlop}>
                        <Image source={getImageSource(ImageConstants.NAVIGATION_CLOSE)} />
                    </TouchableOpacity> */}

                    {/* <Image source={getImageSource(ImageConstants.SCOREBOARD_HEADER)} style={Styles.imageBackground} resizeMode="stretch" /> */}
                    <div>
                        <div>
                            <div className='header-text'>{this.staticText.scoreboardText ? this.staticText.scoreboardText : 'Top Winners'} <div>{this.meta.title}</div></div>
                            <div className='sub-header-text'>{this.staticText.eventTitle ? this.staticText.eventTitle : 'India Maths Day, 2021'}</div>
                        </div>
                    </div>

                    <div className='text-center mt-1'>
                        <img className='question-divider-image-width' style={{ width: '98%', marginLeft: '-30px' }} src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, 1)} />
                    </div>



                    {this.renderOthers()}

                    {this.state.userRank ?
                        <div className='user-rank-container row row-wrapper align-items-center'>
                            <div className='left-wrapper col-2 mr-0 pr-0 column-max-width'>
                                <div className='circle d-flex justify-content-center align-items-center' style={{ backgroundColor: "#E5DEFF" }}>
                                    <div className='circle-text'>{this.getNamesFirstLetter(this.state.userRank.name)}</div>
                                </div>
                            </div>

                            <div className='middle-wrapper col ml-0 pl-0'>
                                <div className='name-text'>
                                    {this.state.userRank.name}
                                </div>
                                <div style={{ flexDirection: 'row' }}>
                                    {/* {this.state.showSchoolScoreboard && item.city ? */}
                                    <div className='d-flex align-items-center'>
                                        <div><img className='exam-pad-icon mr-2' src={getImageSource(ImageConstants.EXAM_PAD_ICON_GRAY)} /></div>
                                        <div className='marks-text pt-1'>{this.state.userRank.marks}</div>
                                    </div>
                                    {/* :
                                <></>} */}

                                    <div d-flex align-items-center>
                                        <img className='exam-pad-icon mr-2' src={getImageSource(ImageConstants.CLOCK_ICON_GRAY)} />
                                        <div className='marks-text pt-1'>{this.state.userRank.timeTaken} {this.staticText.minsText ? this.staticText.minsText : 'Mins'}</div>
                                    </div>
                                </div>

                            </div>

                            <div className='prize-wrapper'>
                                {this.renderBadges(this.state.userRank.rank)}
                            </div>

                            <div className='right-wrapper col-2'>
                                <div style={this.isWinnerWonPrize(this.state.userRank.rank)}>{this.state.userRank.rank}</div>
                            </div>
                        </div>
                        : <></>}

                </div >

                : <Loading loading={this.state.loading} />


        )
    }
}


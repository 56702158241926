import React from 'react';
import './workflow-experience.scss';
import LearningContentWrapper from '../../components/learning/learning-content-wrapper/learning-content-wrapper';
import SharedProfile from '../../../shared-storage/profile';
import HomeworkApi from '../../apis/homework';
import { Constants } from '../../constants/constants';
import Path from '../routes/routes-path';

export default class WorkflowExperience extends React.Component {

    workflowEngine = null;
    worflowNodes = [];
    questions = [];
    state = {
        questions: [],
        answers: [],
        learningElos: [],
        showSuccessFailureScreen: false,
        stepIndex: 0,
        showHint: false,
        showSolvedExamplesModal: false,
        showInvalidAnswerModal: false,
        showCorrectIncorrectModal: false,
        showCorrectAnswer: false,
        isReadOnly: false,
        showNewConceptModal: false,
        newConceptTitle: '',
        disableNext: false
    };
    questionComponent = null;

    failedInAssessment = false;
    successFailureScreenData = {};
    isRevise = false;
    isReassessment = false;

    hintShowed = false;
    solvedExamplesShowed = false;

    isCorrect = false;
    eloIds = []
    noBlur = false

    constructor(props) {

        super(props);
        // let { navigation } = this.props;
        this.params = this.props.params
        console.log(this.params)
        //15845131996920700
        //15846985439743399
        this.progress = null;

        

        // this.conceptListMeta = this.params.conceptListMeta;

        //Concept Completion Screen
        // this.currentConcept = this.params.currentConcept

        this.userInfo = SharedProfile.getProfile();

        

    }

    componentDidMount() {

        // if (this.params.status === Constants.ELO_STATUS_CLEARED && this.params.eloIds) {
        this.getLearningContent([this.params.eloIds]);
        // this.getLearningContent(["5f1151c796cec71c0c0e4c69"]);
        //5e7340315c3254149568b90f
        //5f1151c796cec71c0c0e4c69

        this.isRevise = true;
        this.noBlur = true;
        // }
        // else
        //     this.getAssessmentContent(false, (err, data) => {

        //         if (err) {
        //             //alert('get content is error');
        //             return;
        //         }
        //         this.setQuestion(data, true);
        //     });

        // if (this.params.status === constants.ELO_STATUS_NOT_CLEARED || this.params.status === constants.TRY_AGIN_IN_PROGRESS) {
        //     this.noBlur = true
        // }
        // BackHandler.addEventListener('hardwareBackPress', this.backhandler);
    }




    getLearningContent = (elos) => {
        this.eloIds = elos
        let payload = {
            ids: elos,
            // chapterKey: "15845131996920700"
            chapterKey : this.params.chapterKey
        }
        HomeworkApi.getReenforcementLearningELODetails(payload).then((eloDetails) => {
            console.log(eloDetails)
            this.setState({
                learningElos: eloDetails,
                disableNext: false
            });
            this.hideSuccessFailureScreen();
        }).catch(err => {
            if (err) {
                //alert("error");
                this.setState({ disableNext: false })
                return;
            }
        })

    }

    getAssessmentContent = (isNext, callback) => {

        this.noBlur = false

        let isTryAgain = false;
        if (this.params.status === Constants.ELO_STATUS_NOT_CLEARED || this.params.status === Constants.TRY_AGIN_IN_PROGRESS) {
            isTryAgain = true;
        }

        let currentNode = this.getCurrentNode();

        if (!isTryAgain && this.params.status != Constants.ELO_STATUS_IN_PROGRESS) {
            // FirebaseEvent.logCustomEvent(FirebaseEvents.ELO_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
            // Facebook.logCustomEvent(FirebaseEvents.ELO_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
            // BranchIO.logCustomEvent(FirebaseEvents.ELO_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
        }

        let payload = {
            nodeId: this.params.nodeId,
            isTryAgain: isTryAgain,
            isNext: isNext
        };

        if (this.params.isChapterNotCompleted)
            payload.isChapterNotCompleted = true;

        this.getContent(payload, callback);
    }

    letsTryAgain = () => {

        this.noBlur = true;

        let payload = {
            nodeId: this.params.nodeId,
            isTryAgain: true,
            isNext: false
        };

        this.getContent(payload, (err, data) => {
            if (err) {
                //alert('Try again get content is error');
                return;
            }
            this.setState({ questions: data.content, index: 0 });
            this.hideSuccessFailureScreen();
        });
    }

    getContent = (payload, callback) => {

        // Apis.getEloContents(payload, (err, data) => {

        //     if (data.contentType == "LEARNING") {

        //         let elos = data.elos.map(elo => elo.id);

        //         this.getLearningContent(elos);
        //     } else {

        //         if (callback) {
        //             callback(err, data);
        //             return;
        //         }
        //     }
        // });
    }

    moveToNextWorkflowNodeForAssessment = (node) => {

        let index = -1;

        let nodes = this.params.nodes;

        this.params.nodeId = node && node.id ? node.id : this.params.nodeId;

        let currentConceptContinue = node && node.id ? true : false; /** corrent concept continue when the user press try again from the concept list screen */

        for (let i = 0; i < nodes.length; i++) {

            if (nodes[i].id == this.params.nodeId) {
                index = i;
                break;
            }
        }

        /** NEXT NODE TO START WHEN COMES TRY AGAIN (FROM CONCEPT LIST SCREEN) START */
        if (index != -1 && currentConceptContinue) {

            this.params.status = '';
            this.params.nodeId = nodes[index].id
            this.reset();
            this.getAssessmentContent(false, (err, data) => {

                if (err) {
                    //alert('get content error');
                    return;
                }
                this.setQuestion(data);
            });
            this.hideSuccessFailureScreen();
            return;
        }
        /** NEXT NODE TO START WHEN COMES TRY AGAIN (FROM CONCEPT LIST SCREEN) END */

        if (index != -1 && index < nodes.length - 1) {

            //For show concept jumping pop up
            if (this.params.nodes[index].conceptKey != this.params.nodes[index + 1].conceptKey) {
                this.setState({ showNewConceptModal: true, newConceptTitle: this.params.nodes[index + 1].conceptTitle })
            }

            this.params.status = '';
            this.params.nodeId = currentConceptContinue ? nodes[index].id : nodes[index + 1].id;
            this.reset();


            this.getAssessmentContent(false, (err, data) => {

                if (err) {
                    //alert('get content error');
                    return;
                }
                this.setQuestion(data);
            });
            this.hideSuccessFailureScreen();
        }
        else {
            this.params.nodeId = null;
            this.params.status = null;
        }
    }

    setQuestion = (data, isResume) => {

        this.totalElos = data.totalElos;
        this.eloPosition = data.eloPosition;

        if (data.isReassessment)
            this.isReassessment = true;
        else
            this.isReassessment = false;

        this.setState({ questions: data.content, index: 0 });

        if (data.resume && data.resume.contentId) {
            let resumeIndex = 0;

            for (let i = 0; i < data.content.length; i++) {
                if (data.content[i].id === data.resume.contentId) {
                    resumeIndex = i;
                    break;
                }
            }

            if (data.content[resumeIndex] && data.content[resumeIndex].details && data.content[resumeIndex].details.content && data.content[resumeIndex].details.content.isMultiStep && isResume) {

                let stepIndex = 0;

                if (data.content[resumeIndex].details.content.steps &&
                    data.content[resumeIndex].details.content.steps.length &&
                    data.content[resumeIndex].answer &&
                    data.content[resumeIndex].answer.steps.length) {
                    stepIndex = data.content[resumeIndex].details.content.steps.length <= data.content[resumeIndex].answer.steps.length ? data.content[resumeIndex].answer.steps.length - 1 : data.content[resumeIndex].answer.steps.length
                }

                this.setState({ index: resumeIndex, stepIndex });

            } else {
                this.setState({ index: resumeIndex });
            }
        }
    }

    reset = () => {
        this.setState({
            questions: [],
            answers: [],
            learningElos: [],
            index: 0
        });
    }

    /**
     * Delegate from Question Component
     */
    enableNavigation(flag) {
        console.log("enableNavigation ", flag);
    }

    disableNavigation(flag) {
        console.log("disableNavigation ", flag);
    }


    nextOld() {

        let answer = {};

        if (!this.solvedExamplesShowed)
            answer = this.questionComponent && this.questionComponent.getAnswer();

        let question = this.state.questions[this.state.index].details;

        if (answer != null) {

            if (this.isReassessment && !this.hintShowed && ((!question.content.isMultiStep && question.hint) || (question.content.isMultiStep && question.content.steps[this.state.stepIndex].hint))) {

                let ques = question;
                let ans = answer;

                if (question.content.isMultiStep) {
                    ques = question.content.steps[this.state.stepIndex];
                    ans = answer.steps[this.state.stepIndex];
                }

                this.validateAnswer(ques, ans, (err, res) => {

                    if (err) {
                        //alert('Answer validation failed');
                        return;
                    }
                    if (res.isCorrect)
                        this.nextContinue(question, answer);
                    else {
                        this.hintShowed = true;
                        this.showHint();
                    }
                });
            }

            else if (this.isReassessment && this.hintShowed && !this.solvedExamplesShowed) {

                let ques = question;
                let ans = answer;

                if (question.content.isMultiStep) {
                    ques = question.content.steps[this.state.stepIndex];
                    ans = answer.steps[this.state.stepIndex];
                }

                this.validateAnswer(ques, ans, (err, res) => {

                    if (err) {
                        //alert('Answer validation failed');
                        return;
                    }
                    if (res.isCorrect) {
                        this.resetHint();
                        this.nextContinue(question, answer);
                    }
                    else {
                        this.solvedExamplesShowed = true;
                        this.showSolvedExamples();
                        this.updateAnswerInAssessmentSolutionStep(question, answer);
                    }
                });

            }
            else if (this.isReassessment && this.hintShowed && this.solvedExamplesShowed) {
                this.resetHint();
                this.nextContinueForSolutionSteps(question, true);
            }
            else {

                this.resetHint();

                if (question.content.isMultiStep) {

                    let ques = question.content.steps[this.state.stepIndex];
                    let ans = answer.steps[this.state.stepIndex];

                    this.validateAnswer(ques, ans, (err, res) => {

                        if (err) {
                            //alert('Answer validation failed');
                            return;
                        }
                        if (res.isCorrect)
                            this.nextContinue(question, answer, false);
                        else
                            this.nextContinue(question, answer, true);
                    });

                } else {
                    this.nextContinue(question, answer);
                }

            }

        } else {
            this.toggleInvalidAnswerModal(true);
        }
    }


    next() {

        //Assesment flow and Reassesment flow
        //Assesment flow 
        // Validate the incoming answer if wrong show solution summary as message: show correct answer and next button
        // if correct show solution summary and next button
        //Reassesment flow
        // Validate the question if worng show hint message and try again button 
        // If wrong again show empty error message and view solution step button
        // if right move to next question

        let answer = {};
        this.answer = {}

        if (!this.solvedExamplesShowed) {
            answer = this.questionComponent && this.questionComponent.getAnswer();
            this.answer = answer; // Add for correct Correct Incorrect Next Flow
        }

        let question = this.state.questions[this.state.index].details;
        this.question = question;// Add for correct Correct Incorrect Next Flow


        if (answer != null) {

            if (this.isReassessment && !this.hintShowed && ((!question.content.isMultiStep && question.hint) || (question.content.isMultiStep && question.content.steps[this.state.stepIndex].hint))) {

                let ques = question;
                let ans = answer;

                if (question.content.isMultiStep) {
                    ques = question.content.steps[this.state.stepIndex];
                    ans = answer.steps[this.state.stepIndex];
                }

                this.setState({ disableNext: true })

                this.validateAnswer(ques, ans, (err, res) => {

                    if (err) {
                        //alert('Answer validation failed');
                        this.setState({ disableNext: false })
                        return;
                    }
                    this.setState({ disableNext: false })

                    if (res.isCorrect) {

                        if (!question.content.isMultiStep) {
                            this.ShowCorrectIncorrectModel(true);
                        }

                        else if (question.content.isMultiStep && this.state.stepIndex === question.content.steps.length - 1) {
                            this.ShowCorrectIncorrectModel(true);

                        }

                        else {
                            this.nextContinue(question, answer, false);
                        }
                    }

                    else {
                        this.hintShowed = true;
                        this.showHint();
                    }
                });
            }

            else if (this.isReassessment && this.hintShowed && !this.solvedExamplesShowed) {

                let ques = question;
                let ans = answer;

                if (question.content.isMultiStep) {
                    ques = question.content.steps[this.state.stepIndex];
                    ans = answer.steps[this.state.stepIndex];
                }

                this.setState({ disableNext: true })

                this.validateAnswer(ques, ans, (err, res) => {

                    if (err) {
                        //alert('Answer validation failed');
                        this.setState({ disableNext: false })
                        return;
                    }
                    this.setState({ disableNext: false })
                    if (res.isCorrect) {
                        this.resetHint();

                        if (!question.content.isMultiStep) {
                            this.ShowCorrectIncorrectModel(true);
                        }

                        else if (question.content.isMultiStep && this.state.stepIndex === question.content.steps.length - 1) {
                            this.ShowCorrectIncorrectModel(true);

                        }

                        else {
                            this.nextContinue(question, answer, false);
                        }
                    }
                    else {
                        this.solvedExamplesShowed = true;
                        this.showSolvedExamples();
                        this.updateAnswerInAssessmentSolutionStep(question, answer);
                    }
                });

            }
            else if (this.isReassessment && this.hintShowed && this.solvedExamplesShowed) {
                this.resetHint();
                this.nextContinueForSolutionSteps(question, true);
            }
            else {

                this.resetHint();

                if (question.content.isMultiStep) {

                    let ques = question.content.steps[this.state.stepIndex];
                    let ans = answer.steps[this.state.stepIndex];

                    this.setState({ disableNext: true })
                    this.validateAnswer(ques, ans, (err, res) => {

                        if (err) {
                            //alert('Answer validation failed');
                            this.setState({ disableNext: false })
                            return;
                        }
                        this.setState({ disableNext: false })
                        if (res.isCorrect) {

                            if (!question.content.isMultiStep) {
                                this.ShowCorrectIncorrectModel(true);
                            }

                            else if (question.content.isMultiStep && this.state.stepIndex === question.content.steps.length - 1) {
                                this.ShowCorrectIncorrectModel(true);

                            }

                            else {
                                this.nextContinue(question, answer, false);
                            }
                            //this.nextContinue(question, answer, false);
                        }
                        else {
                            this.ShowCorrectIncorrectModel(false);
                            //this.nextContinue(question, answer, true);
                        }
                    });

                } else {

                    let ques = question;
                    let ans = answer;

                    this.setState({ disableNext: true })
                    this.validateAnswer(ques, ans, (err, res) => {

                        if (err) {
                            this.setState({ disableNext: false })
                            return;
                        }
                        this.setState({ disableNext: false })
                        if (res.isCorrect)
                            this.ShowCorrectIncorrectModel(true);
                        else
                            this.ShowCorrectIncorrectModel(false);
                    });
                }

            }

        } else {
            this.toggleInvalidAnswerModal(true);
        }
    }

    nextQuestionOnCorrectInCorrectAnswer() {

        let question = this.question;
        let answer = this.answer;

        if (question.content.isMultiStep) {
            if (this.isCorrect)
                this.nextContinue(question, answer, false);
            else
                this.nextContinue(question, answer, true);

        } else {
            this.nextContinue(this.question, this.answer);
        }

        this.setState({ showCorrectIncorrectModal: false, isReadOnly: false });
    }



    nextContinue = (question, answer, branchQuesExit) => {

        if (this.state.questions.length > this.state.index) {
            this.state.questions[this.state.index].answer = answer;
        }

        if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex && !branchQuesExit) { /** If question is multi step  */
            this.setState({ stepIndex: this.state.stepIndex + 1 });

            this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index], answer, null, (err, res) => {
                if (err) {
                    //alert('user activity update error');
                }
            });

        } else { /** If it normal question */

            if (this.state.stepIndex)
                this.resetStepIndex();

            if (this.state.questions.length > this.state.index + 1) {


                if (this.state.questions[this.state.index] && answer) {
                    if (this.state.questions[this.state.index].id != answer.id) {
                        return
                    }
                }

                this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index + 1], answer, null, (err, res) => {
                    if (err) {
                        //alert('user activity update error');
                    }
                });

                this.setState({ index: this.state.index + 1 });

            }
            else { /** If end of the elo reached */


                if (this.state.questions[this.state.index] && answer) {
                    if (this.state.questions[this.state.index].id != answer.id) {
                        return
                    }
                }

                this.updateUserActivity(this.state.questions[this.state.index], null, answer, null, (err, res) => {

                    if (err) {
                        //alert('user activity update error');
                        return;
                    }

                    if (this.totalElos !== this.eloPosition) {
                        this.setState({ disableNext: true })
                        this.getAssessmentContent(true, (err, data) => {

                            if (err) {
                                //alert('get Content error');
                                this.setState({ disableNext: false })
                                return;
                            }

                            this.totalElos = data.totalElos;
                            this.eloPosition = data.eloPosition;

                            this.setState({
                                disableNext: false,
                                questions: this.state.questions.concat(data.content),
                                index: this.state.index + 1
                            });
                        });
                    } else { /** Reached end of the node */

                        this.setState({ disableNext: true })
                        this.assessNode((err, res) => {

                            if (err) {
                                //alert('Node Assess Error');
                                this.setState({ disableNext: false })
                                return;
                            }

                            if (res.isVertical) {
                                this.params.status = Constants.TRY_AGIN_IN_PROGRESS;
                                this.getAssessmentContent(true, (err, data) => {

                                    if (err) {
                                        this.setState({ disableNext: false })
                                        return;
                                    }

                                    if (data.contentType == "LEARNING") {

                                        let elos = data.elos.map(elo => elo.id);

                                        this.getLearningContent(elos);
                                    } else {

                                        this.totalElos = data.totalElos;
                                        this.eloPosition = data.eloPosition;

                                        this.setState({
                                            disableNext: false,
                                            questions: data.content,
                                            index: 0
                                        });
                                    }
                                });
                            } else {
                                this.showSuccessFailureScreen(res);
                            }
                        });
                    }
                });
            }
        }
    }

    assessNode(callback) {

        let payload = {
            nodeId: this.params.nodeId
        }

        if (this.params.isChapterNotCompleted)
            payload.isChapterNotCompleted = true;

        // Apis.assessNode(payload, (err, res) => {

        //     if (callback)
        //         callback(err, res);

        // });

    }

    updateUserActivity(presentContent, resumeContent, answer, isReEnforcementCompleted, callback) {

        let payload = {
            nodeId: this.params.nodeId
        };

        if (presentContent)
            payload.eloActivity = { contentId: presentContent.id, answer: answer, eloId: presentContent.eloId }

        if (resumeContent)
            payload.resume = { contentId: resumeContent.id, eloId: resumeContent.eloId };


        if (isReEnforcementCompleted)
            payload.isReEnforcementCompleted = true;


        // Apis.updateUserActivity(payload, (err, res) => {

        //     if (callback)
        //         callback(err, res);
        // });

    }

    updateAnswerInAssessmentSolutionStep(question, answer) {

        if (this.state.questions.length > this.state.index) {
            this.state.questions[this.state.index].answer = answer;
        }

        if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex) { /** If question is multi step  */

            this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index], answer, null, (err, res) => {
                if (err) {
                    //alert('user activity update error');
                }
            });

        } else { /** If it normal question */

            if (this.state.questions[this.state.index] && answer) {
                if (this.state.questions[this.state.index].id != answer.id) {
                    return
                }
            }

            if (this.state.questions.length > this.state.index + 1) {

                this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index + 1], answer, null, (err, res) => {
                    if (err) {
                        //alert('user activity update error');
                    }
                });
            }
            else { /** If end of the elo reached */

                this.updateUserActivity(this.state.questions[this.state.index], null, answer, null, (err, res) => {

                    if (err) {
                        //alert('user activity update error');
                        return;
                    }
                });
            }
        }

    }

    nextContinueForSolutionSteps(question, branchQuesExit) {

        if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex && !branchQuesExit) { /** If question is multi step  */
            this.setState({ stepIndex: this.state.stepIndex + 1 });
        } else { /** If it normal question */

            if (this.state.stepIndex)
                this.resetStepIndex();

            if (this.state.questions.length > this.state.index + 1) {

                this.setState({ index: this.state.index + 1 });

            }
            else { /** If end of the elo reached */

                if (this.totalElos !== this.eloPosition) {
                    this.setState({ disableNext: true })
                    this.getAssessmentContent(true, (err, data) => {

                        if (err) {
                            //alert('get Content error');
                            this.setState({ disableNext: false })
                            return;
                        }

                        this.totalElos = data.totalElos;
                        this.eloPosition = data.eloPosition;

                        this.setState({
                            disableNext: false,
                            questions: this.state.questions.concat(data.content),
                            index: this.state.index + 1
                        });
                    });
                } else { /** Reached end of the node */

                    this.setState({ disableNext: true })
                    this.assessNode((err, res) => {

                        if (err) {
                            //alert('Node Assess Error');
                            this.setState({ disableNext: false })
                            return;
                        }

                        if (res.isVertical) {
                            this.params.status = Constants.TRY_AGIN_IN_PROGRESS;
                            this.getAssessmentContent(true, (err, data) => {

                                if (err) {
                                    this.setState({ disableNext: false })
                                    return;
                                }

                                if (data.contentType == "LEARNING") {

                                    let elos = data.elos.map(elo => elo.id);

                                    this.getLearningContent(elos);
                                } else {

                                    this.totalElos = data.totalElos;
                                    this.eloPosition = data.eloPosition;

                                    this.setState({
                                        disableNext: false,
                                        questions: this.state.questions.concat(data.content),
                                        index: this.state.index + 1
                                    });
                                }
                            });
                        } else {
                            this.showSuccessFailureScreen(res);
                        }
                    });
                }

            }
        }
    }

    showSuccessFailureScreen = (data) => {

        this.setState({ disableNext: false })

        let chapterKey = this.params.chapterKey;

        if (!chapterKey)
            return;

        let payload = {
            chapterKey
        };

        // Apis.getChapterProgress(payload, (err, res) => {

        //     this.progress = res;
        //     this.successFailureScreenData = data;
        //     this.successFailureScreenData.upComing = [];
        //     if (data.isContinue) {
        //         let { node, isEnd } = this.getNextNode();
        //         if (!isEnd) { // CONDITION TO IDENTIFY END OF CHAPTER
        //             this.successFailureScreenData.upComing = node;
        //             this.successFailureScreenData.isContinue = true;
        //         } else {
        //             this.successFailureScreenData.upComing = [];
        //             this.successFailureScreenData.isEnd = true;
        //         }
        //     }
        //     //this.successFailureScreenData.concept = this.processDataToSetNodeEloStatus(data);
        //     //this.successFailureScreenData.concept = this.currentConcept;
        //     this.successFailureScreenData.chapterKey = this.params.chapterKey;
        //     this.successFailureScreenData.nodeId = this.params.nodeId;
        //     let currentNode = this.getCurrentNode();
        //     this.successFailureScreenData.node = currentNode.elos && currentNode.elos.length ? currentNode.elos[0] : [];
        //     this.successFailureScreenData.isChapterCleared = this.params.isChapterCleared ? true : false
        //     this.setState({ showSuccessFailureScreen: true });
        // });
    }

    getCurrentNode = () => {
        let nodes = this.params.nodes;
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id == this.params.nodeId) {
                return nodes[i];
            }
        }
    }


    processDataToSetNodeEloStatus(data) {
        let currrentNodeId = this.params.nodeId;
        let currentConcept = this.currentConcept;
        let levels = currentConcept.levels;

        let nodeStatus = data.notCleared && data.notCleared.length ? Constants.ELO_STATUS_NOT_CLEARED : Constants.ELO_STATUS_CLEARED
        let clearedElos = data.cleared && data.cleared.length ? data.cleared.map((elo) => elo.id) : [];
        let notClearedElos = data.notCleared && data.notCleared.length ? data.notCleared.map((elo) => elo.id) : [];

        for (let i = 0; i < levels.length; i++) {
            let nodes = levels[i].nodes;
            for (let j = 0; j < nodes.length; j++) {
                //Find the active node and level
                let node = nodes[j]
                if (node.id == currrentNodeId) {
                    node.status = nodeStatus;
                    let elos = node.elos;
                    for (let k = 0; k < elos.length; k++) {
                        let elo = elos[k];
                        if (clearedElos.includes(elo.id)) {
                            elo.status = Constants.ELO_STATUS_CLEARED
                        } else if (notClearedElos.includes(elo.id)) {
                            elo.status = Constants.ELO_STATUS_NOT_CLEARED
                        }
                    }
                }
            }
        }
        console.log(currentConcept);
        return this.currentConcept;
    }


    hideSuccessFailureScreen = () => {
        this.setState({ showSuccessFailureScreen: false });
    }

    getNextNode = () => {

        let index = -1;

        let nodes = this.params.nodes;

        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id == this.params.nodeId) {
                index = i;
                break;
            }
        }

        //Idenfify End of chapter
        if (index == nodes.length - 1) {
            return { node: [], isEnd: true };
        }

        if (index != -1 && index < nodes.length - 1) {
            if (this.params.nodes[index + 1].elos.length &&
                this.params.nodes[index + 1].status == Constants.ELO_STATUS_LOCKED) {
                return { node: this.params.nodes[index + 1].elos, isEnd: false };
            } else {
                return { node: [], isEnd: false };
            }
        } else
            return { node: [], isEnd: false };
    }

    previous() {

        let question = this.state.questions[this.state.index].details;

        let answer = this.questionComponent && this.questionComponent.getAnswer();

        if (question.content.isMultiStep && this.state.stepIndex !== 0) {

            this.setState({ stepIndex: this.state.stepIndex - 1 });

            this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index], answer, null, (err, res) => {
                if (err) {
                    //alert('user activity update error');
                }
            });
        } else {

            if (this.state.index != 0) {
                this.setState({
                    index: this.state.index - 1,
                });

                if (!answer)
                    return;

                this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index - 1], answer, null, (err, res) => {
                    if (err) {
                        //alert('user activity update error');
                    }
                });
            }

        }


    }

    bookmark(event) {
        //alert("bookmark")
    }

    save(event) {
        //alert("save")
    }

    /** End of the learning content from revise flow */
    learningElosCompleted = (isRevise) => {

        if (isRevise)
            //this.props.navigation.goBack();
            this.props.navigation.navigate('EloList', { meta: this.conceptListMeta });
        else {
            this.updateUserActivity(null, null, null, true, (err, res) => {
                if (err) {
                    //alert('Update user activity error');
                }

                this.reset();
                this.params.status = Constants.TRY_AGIN_IN_PROGRESS; // Setting this because change in success screen flow
                this.getAssessmentContent(false, (err, data) => {

                    if (err) {
                        //alert('get content is error');
                        return;
                    }
                    this.setQuestion(data);
                });
            });
        }
    }

    resetHint = () => {
        if (this.hintShowed)
            this.hintShowed = false;

        if (this.solvedExamplesShowed)
            this.solvedExamplesShowed = false;
    }

    resetStepIndex = () => {
        this.setState({ stepIndex: 0 });
    }

    validateAnswer = (question, answer, callback) => {

        let payload = { question, answer };

        // Apis.validateAnswer(payload, (err, res) => {
        //     callback(err, res);
        // });
    }

    showHint = () => {
        this.setState({ showHint: true });
    }

    hideHint = () => {
        this.setState({ showHint: false });
    }

    hintTryAgain = () => {
        let question = this.state.questions[this.state.index];
        if (question.details.content.isMultiStep && question.answer) {
            question.answer.steps[this.state.stepIndex] = null;
        } else {
            if (question.answer)
                question.answer = null;
        }
        this.questionComponent.resetAns();
        this.hideHint();
    }

    goBackToEloListScreen = () => {
        //this.props.navigation.goBack();
        this.props.navigation.navigate('EloList', { meta: this.conceptListMeta });
    }

    goToChapterList = () => {
        this.props.navigation.navigate('Chapters')
    }

    goToLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    showSolvedExamples = () => {
        this.setState({ showSolvedExamplesModal: true });
    }

    hideSolvedExamples = () => {
        this.setState({ showSolvedExamplesModal: false });
    }

    toggleInvalidAnswerModal = (value) => {
        this.setState({ showInvalidAnswerModal: value });
    }

    ShowCorrectIncorrectModel = (isCorrect) => {
        this.isCorrect = isCorrect;
        this.setState({ showCorrectIncorrectModal: true });
    }

    hideCorrectIncorrectModal() {
        this.setState({ showCorrectIncorrectModal: false });
    }

    onOk = () => {
        this.toggleInvalidAnswerModal(false);
    }

    showNewConceptModal = () => {
        this.setState({ showNewConceptModal: true })
    }

    hideNewConceptModal = () => {
        this.setState({ showNewConceptModal: false })
    }

    goToHomeScreen() {
        this.props.delegate.props.history.push(Path.HOME)
    }

    goToEloSearch() {
        this.props.back && this.props.back()
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb ml-1 mt-2 mb-2'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToEloSearch()}>{`ELO Search >`}</span>
                <span className='pl-2 title-1'>{`${this.params.chapterTitle}`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }



    render() {

        return (
            <div className='workflow-experience'>
            {this.renderBreadcrumbForChapterList()}
                {this.state.learningElos.length > 0 &&
                    <LearningContentWrapper
                        elo={this.state.learningElos}
                        delegate={this}
                        isRevise={this.isRevise}
                        title={this.params.chapterTitle}
                        navigationInfo={this.params}
                        eloIds={this.eloIds}
                        noBlur={this.noBlur}
                    // isVerification={this.params.isVerification}
                    >
                    </LearningContentWrapper>}
            </div>

        )
    }
}
import React, { Component } from 'react';
import './learn-more-experience.scss';
import LearningContentWrapper from '../exam-prep-kit-learning-content-wrapper/exam-prep-kit-learning-content-wrapper';
import SharedProfile from '../../../../../shared-storage/profile';
import Loading from '../../../common/loading/loading';
import HomeworkApi from '../../../../apis/homework';

export default class LearnMoreExperience extends Component {

    workflowEngine = null;
    worflowNodes = [];
    questions = [];
    state = {
        learningElos: [],
        loading: true
    };

    CONTENT_FETCH_LIMIT = 5
    contentHash = {}

    constructor(props) {

        super(props);
        // let { navigation } = this.props;
        // console.log(this.props.params)
        this.params = this.props.params
        this.headerTitle = this.params && this.params.headerTitle ? this.params.headerTitle : ""
        this.progress = null;
        this.userInfo = SharedProfile.getProfile();

        this.eloId = this.params.eloId

        if (this.eloId)
            this.buildLearningContent()

    }

    componentDidMount() {
        // this.props.navigation.addListener("didFocus", () => {

        //     BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });

        // this.props.navigation.addListener("didBlur", () => {
        //     BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });
    }

    handleBackButtonClick = () => {

    }

    buildLearningContent = () => {

        let elos = [this.params.eloId]

        if (!this.state.loading)
            this.setState({ loading: true })

        let payload = {
            ids: elos,
            chapterKey: this.params.chapterKey
        }

        HomeworkApi.getReenforcementLearningELODetails(payload).then((eloDetails) => {
            this.setState({
                learningElos: eloDetails,
                loading: false
            });
        }).catch(err => {
            console.log(err)
            if (err) {
                this.setState({ loading: false })
                return;
            }
        })
    }

    goToLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }


    learningElosCompleted = () => {
        this.props.close && this.props.close()
    }

    close = () => {
        this.props.close && this.props.close()
    }

    render() {

        return (
            <>
                <div className='learn-more-experience'>
                    {this.state.learningElos.length > 0 ? <LearningContentWrapper
                        elo={this.state.learningElos}
                        delegate={this}
                        navigation={this.props.navigation}
                        isRevise={this.isRevise}
                        title={this.params.title ? this.params.title : ''}
                        navigationInfo={this.params}
                        eloIds={this.eloIds}
                        noBlur={true}
                        headerIcon={this.params.headerIcon}
                        headerTitle={this.params.headerTitle}
                        removeHeaderIcon
                        // hideNext
                        onPressNext={this.onPressNext}
                    >
                    </LearningContentWrapper> : <Loading />}
                </div>
            </>
        )
    }
}
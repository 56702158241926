import React from "react";
import './edit-popup.scss';
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedProfile from "../../../../shared-storage/profile";
import CommonApi from "../../../apis/common";
import CommonUtils from "../../../utility/common-utilities";
import Loading from "../../common/loading/loading";
import { Constants } from "../../../constants/constants";
import Otp from "./otp/otp";
import CityModal from "../../common/city-modal/city-modal";

class EditPopup extends React.Component {


    constructor(props) {
        super(props);
        this.state.userInfo = SharedProfile.getProfile()

        if (this.state.userInfo) {

            if (props.type == 'NAME') {
                this.state.name = this.state.userInfo.fullName ? this.state.userInfo.fullName : '';
            }

            if (props.type == 'NICK_NAME') {
                this.state.nickName = this.state.userInfo.nickName ? this.state.userInfo.nickName : '';
            }

            if (props.type == 'MOBILE_NUMBER') {
                this.state.mobileNumber = this.state.userInfo.mobileNumber;
                this.state.countryCode = this.state.userInfo.countryCode;

            }

            if (props.type == 'SCHOOL') {
                this.state.school = this.state.userInfo.schoolName ? this.state.userInfo.schoolName : ''
            }

            if (props.type == 'SECTION') {
                this.state.section = this.state.userInfo.section ? this.state.userInfo.section : ''
            }

            if (props.type == 'CITY') {

                if (this.state.userInfo.city && this.state.userInfo.state && this.state.userInfo.country) {
                    this.state.city = this.state.userInfo.city + ', ' + this.state.userInfo.state + ', ' + this.state.userInfo.country;
                    this.state.selectedCity = { name: this.state.userInfo.city, state: this.state.userInfo.state, country: this.state.userInfo.country }
                }
            }

            if (props.type == 'EMAIL') {
                this.state.email = this.state.userInfo.email ? this.state.userInfo.email : ''
            }
        }

        this.getCountryCode()

    }

    coutryCodeHash = {}

    state = {
        isUserExist: false,
        countryCode: '+91',
        mobileNumber: '',
        activeType: 'SEND',
        invalidOtp: false,
        otp: '',
        loading: false,
        cities: [],
        selectedCity: null,
        email: ''
    };

    countryDetails = { name: "India", length: 10 }

    cityList = []

    componentDidMount() {
        this.getCountryCode()
    }


    getCountryCode(payload = {}) {
        CommonApi.getCountryCode(payload).then((response) => {
            let data = response.response
            //console.log(data)
            if (data.length) {
                this.setState({ countryList: data })
                this.buildCountryCodeDropDown(data)
            } else {
                this.setState({ countryList: [], countryCodeDropDown: [] })
            }
        }).catch((err) => {
            //console.log(err)
        })
    }

    buildCountryCodeDropDown(list = []) {
        let countryCodeDropDown = []
        list.map(item => {
            this.coutryCodeHash[item._id] = item
            countryCodeDropDown.push({ text: item.dialCode, addOn: item.name, value: item._id })
        })
        this.setState({ countryCodeDropDown })
    }

    // onSelectCode(item) {
    //     this.setState({ selectedCountry: this.coutryCodeHash[item.value], showDropdown: false }, () => {
    //         // this.setState({ isLoginEnable: this.validateMobile(this.state.mobileNumber) })
    //     })

    // }

    onChangeCountryCode = (countryDetails) => {
        this.countryDetails = this.coutryCodeHash[countryDetails.value];
        this.setState({
            countryCode: this.countryDetails.dialCode, showDropdown: false
        })
    }

    hideCountryDropdown() {
        this.setState({ showDropdown: false })
    }

    onSearch(event) {
        let search = event.target.value
        this.getCountryCode({ name: `/${search}`, })
    }


    openCountryCode() {
        // if (this.state.isOtpSent) return
        this.getCountryCode()
        this.setState({ showDropdown: !this.state.showDropdown })
    }


    onClickClose() {
        this.props.close && this.props.close()
    }

    getTitle = () => {
        let updateType = this.props.type;

        if (updateType == 'NAME')
            return 'Name'

        if (updateType == 'NICK_NAME')
            return 'Nick Name'

        if (updateType == 'MOBILE_NUMBER')
            return this.state.activeType == 'SEND' ? 'Mobile No.' : 'OTP Verification'

        if (updateType == 'SCHOOL')
            return 'School'

        if (updateType == 'SECTION')
            return 'Section'

        if (updateType == 'CITY')
            return 'City'

            if (updateType == 'EMAIL')
            return 'Email'



        if (updateType == 'IMAGE')
            return 'Photo'

    }

    updateProfile = () => {

        let isValid = this.validatePayload()

        if (!isValid)
            return

        let payload = {}

        let updateType = this.props.type;

        if (updateType == 'NAME') {
            payload['name'] = this.state.name.trim();
        }

        if (updateType == 'NICK_NAME') {
            payload['nickName'] = this.state.nickName.trim();
        }

        if (updateType == 'MOBILE_NUMBER') {
            payload['countryCode'] = this.state.countryCode;
            payload['mobileNumber'] = this.state.mobileNumber.trim();
            payload['otp'] = this.state.otp;
        }

        if (updateType == 'SCHOOL') {
            payload['schoolName'] = this.state.school.trim();
        }

        if (updateType == 'SECTION') {
            payload['section'] = this.state.section.trim();

            if (this.state.sectionId) {
                payload['sectionId'] = this.state.sectionId.trim();
            }
        }

        if (updateType == 'CITY') {
            payload['city'] = this.state.selectedCity.name;
            payload['state'] = this.state.selectedCity.state;
            payload['country'] = this.state.selectedCity.country;
            payload['timeZone'] = this.state.selectedCity.timeZone;
        }

        if (updateType == 'EMAIL') {
            payload['email'] = this.state.email.trim();
        }

        this.toggleLoading(true)

        CommonApi.updateProfile(payload).then((response) => {
            this.props.delegate && this.props.delegate.onSuccessUpdate && this.props.delegate.onSuccessUpdate(payload)
            this.props.close && this.props.close()

        }).catch(err => {
            if (err) {
                if (updateType == 'MOBILE_NUMBER') {
                    this.setState({ invalidOtp: true })
                    this.toggleLoading(false)
                    return;
                }
                if (err.response && err.response.data) {
                    if (err.response.data.code == 11000) {
                        this.setState({ isUserExist: true })
                    }
                }
                this.toggleLoading(false)
                return;
            }
        })
    }

    validatePayload() {

        let updateType = this.props.type;

        if (updateType == 'NAME') {
            if (!this.state.name.trim()) {
                this.setState({ invalidName: true })
                return false
            } else {

                if (this.state.name == this.state.userInfo.fullName) {
                    this.props.close()
                    return false;
                }

                this.setState({ invalidName: false })
            }
        }

        if (updateType == 'NICK_NAME') {
            if (!this.state.nickName.trim()) {
                this.setState({ invalidNickName: true })
                return false
            } else {

                if (this.state.nickName == this.state.userInfo.nickName) {
                    this.props.close()
                    return false;
                }

                this.setState({ invalidNickName: false })
            }
        }

        if (updateType == 'MOBILE_NUMBER') {
            if (!this.state.otp || this.state.otp.length != 4) {
                this.setState({ invalidOtp: true })
                return false
            } else {
                if (this.state.userInfo && this.state.mobileNumber == this.state.userInfo.mobileNumber && this.state.countryCode == this.state.userInfo.countryCode) {
                    this.props.close()
                    return false;
                }
                this.setState({ invalidOtp: false })
            }
        }

        if (updateType == 'SCHOOL') {
            if (!this.state.school.trim()) {
                this.setState({ invalidSchool: true })
                return false
            } else {

                if (this.state.userInfo && this.state.school == this.state.userInfo.schoolName) {
                    this.props.close()
                    return false;
                }
                this.setState({ invalidSchool: false })
            }
        }

        if (updateType == 'SECTION') {
            if (!this.state.section.trim()) {
                this.setState({ invalidSection: true })
                return false
            } else {
                if (this.state.section == this.state.userInfo.section) {
                    this.props.close()
                    return false;
                }
                this.setState({ invalidSection: false })
            }
        }

        if (updateType == 'CITY') {
            if (!this.state.city || !this.state.selectedCity) {
                this.setState({ invalidCity: true })
                return false
            } else {
                if (this.state.city == this.state.userInfo.city + ', ' + this.state.userInfo.state + ', ' + this.state.userInfo.country) {
                    this.props.close()
                    return false;
                }
                this.setState({ invalidCity: false })
            }
        }


        if (updateType == 'EMAIL') {
            if (!this.state.email.trim() || !CommonUtils.validateEmail(this.state.email)) {
                this.setState({ invalidEmail: true })
                return false
            } else {

                if (this.state.email && this.state.email == this.state.userInfo.email) {
                    this.props.close()
                    return false;
                }
                this.setState({ invalidSchool: false })
            }
        }

        return true

    }

    sendOtp = () => {

        if (!this.state.mobileNumber.trim() || !this.state.countryCode) {
            this.setState({ invalidMobileNumber: true })
            return
        } else {
            this.setState({ invalidMobileNumber: false })
        }

        if (!CommonUtils.validateMobileNumber(this.state.mobileNumber, this.countryDetails)) {
            this.setState({ invalidMobileNumber: true })
            return
        }

        if (this.state.userInfo && this.state.mobileNumber == this.state.userInfo.mobileNumber && this.state.countryCode == this.state.userInfo.countryCode) {
            this.props.close()
            return
        }

        let payload = {
            mobileNumber: this.state.mobileNumber.trim(),
            countryCode: this.state.countryCode,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL
        }

        this.toggleLoading(true)

        CommonApi.sendOtpUpdateProfile(payload).then((response) => {

            this.toggleLoading(false)
            this.setState({ activeType: 'VERIFY' })
        }).catch(err => {
            if (err) {
                if (err.response && err.response.data) {
                    if (err.response.data.code == 11000) {
                        this.setState({ isUserExist: true })
                    }
                }
                this.toggleLoading(false)
                return;
            }
        })
    }

    onChangeCity = (city) => {

        this.setState({
            selectedCity: city,
            city: city.name + ', ' + city.state + ', ' + city.country
        })
    }

    closeCityModal = () => {
        // this.setState({ showCityModal: false })
        this.props.close && this.props.close()

    }

    onDeleteCity(event) {
        if (event.keyCode === 8) {
            this.setState({ city: '' })
        }
    }

    resetInvalidOtp = () => {
        this.setState({ invalidOtp: false });
    }

    onOtpChange = (otp) => {
        this.setState({ otp: otp })
    }

    toggleLoading = (value) => {
        this.setState({ loading: value })
    }

    resendOtp = () => {
        //console.log('inside resend otp')
        this.setState({ invalidOtp: false, otp: '' })
        this.sendOtp()
    }

    countryCodeDropDown(dropDownList = []) {
        return (
            <div className="country-code-drop-down" id="st-3" ref={ref => this.countryCodeRef = ref} onClick={() => this.handleClickInside}>
                <div className="">
                    <div className="country-close-icon cursor-pointer">
                        <img className="close-icon" onClick={() => this.hideCountryDropdown()} src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} />
                    </div>
                    <div className="country-header-div">
                        <img className="country-header-img" src={getImageSource(ImageConstants.COUNTRY_DROPDOWN_HEADER)} />
                    </div>
                    <div className="select-country">Select a country</div>
                    <div className="country-search-div">
                        <img className="country-search" src={getImageSource(ImageConstants.COUNTRY_CODE_SEARCH)}></img>
                    </div>

                    <div className="search">

                        <input className="form-control country-search" placeholder={`Search`} value={this.state.search} onChange={(event) => { this.onSearch(event) }} />
                    </div>
                    <div className="p-2 dropdown-scroll">
                        {dropDownList.length ?
                            dropDownList.map(dropdown => (
                                <div className="dropdown-list cursor-pointer px-3 py-1" onClick={() => { this.onChangeCountryCode(dropdown) }}>
                                    <div className="row country-name cursor-pointer">
                                        <div className="col-8"> {dropdown.addOn}</div>
                                        <div className="col text-right">  {dropdown.text}</div>
                                    </div>
                                </div>

                            ))
                            : <div className="text-center py-4 no-data">
                                <label> No Records found</label>
                            </div>
                        }
                    </div>
                </div>
                <div className="country-footer-div">
                    <img className="country-header-img" src={getImageSource(ImageConstants.COUNTRY_DROPDOWN_FOOTER)} />
                </div>
            </div>
        )
    }

    renderBodyContent() {

        let updateType = this.props.type;

        if (updateType == 'NAME') {
            return (
                <div className="mt-5 pl-4">
                    <div className="row">
                        <div className="col">
                            <input className="cw-input-1"
                                placeholder="Enter your first name"
                                onChange={(event) => this.setState({ name: event.target.value })}
                                value={this.state.name}
                                onFocus={() => this.setState({ isUserExist: false, invalidName: false })}
                                maxLength={150}
                                ref={(ref) => { this.input = ref }} />
                        </div>
                    </div>
                    {this.state.invalidName && !this.state.name.trim() && <div className="failed-text">Please enter your name</div>}
                    {this.state.isUserExist && <div className="failed-text">User name already exist</div>}


                    {!this.state.name.trim() ?
                        <div className="d-flex justify-content-center mt-5">
                            <div className="active-button save-button-disabled d-flex justify-content-center">
                                SAVE
                            </div>
                        </div>

                        :

                        <div className="d-flex justify-content-center mt-5">
                            <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.updateProfile}>
                                SAVE
                            </div>
                        </div>
                    }


                </div>
            )
        }

        if (updateType == 'NICK_NAME') {
            return (
                <div className="mt-5 pl-4">
                    <div className="row">
                        <div className="col">
                            <input className="cw-input-1"
                                placeholder="Enter your Nick name"
                                onChange={(event) => this.setState({ nickName: event.target.value })}
                                value={this.state.nickName}
                                onFocus={() => this.setState({ isUserExist: false, invalidNickName: false })}
                                maxLength={150}
                                ref={(ref) => { this.input = ref }} />
                        </div>
                    </div>
                    {this.state.invalidNickName && !this.state.nickName.trim() && <div className="failed-text">Please enter your Nick name</div>}



                    {!this.state.nickName.trim() ?
                        <div className="d-flex justify-content-center mt-5">
                            <div className="active-button save-button-disabled d-flex justify-content-center">
                                SAVE
                            </div>
                        </div>

                        :

                        <div className="d-flex justify-content-center mt-5">
                            <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.updateProfile}>
                                SAVE
                            </div>
                        </div>
                    }


                </div>

            )
        }

        if (updateType == 'MOBILE_NUMBER') {

            if (this.state.activeType == 'SEND') {
                return (
                    <div className="mt-4 pl-4">
                        <div className="mobile-number-text">Note that changing mobile no would change your credential</div>
                        <div>
                            <div className="cw-input-wrapper">
                                <span className={`d-inline-block country-code ${this.state.isOtpSent ? "cursor: default" : "cursor-pointer"}`} onClick={() => this.openCountryCode()}>
                                    {this.state.countryCode}
                                </span>
                                <img onClick={() => this.openCountryCode()}
                                    className={`country-code-down-arrow cursor-pointer 
                                ${this.state.countryCode.length == 3 ? 'country-code-down-arrow-margin' : ''}`}
                                    src={getImageSource(ImageConstants.DOWN_ARROW)} />
                                <img className="country-code-separator" src={getImageSource(ImageConstants.COUNTRY_SEPARATOR)} />
                                <input className=" cw-input-1-for-mobileNumber"
                                    placeholder={`Enter your mobile number`}
                                    onChange={(event) => CommonUtils.isValidNumber(event.target.value) && this.setState({ mobileNumber: event.target.value })}
                                    onBlur={() => {
                                        if (this.state.mobileNumber && !CommonUtils.validateMobileNumber(this.state.mobileNumber, this.countryDetails)) {
                                            this.setState({ invalidMobileNumber: true })
                                        } else {
                                            this.setState({ invalidMobileNumber: false })
                                        }
                                    }}
                                    onFocus={() => this.setState({ isUserExist: false, invalidMobileNumber: false })}
                                    ref={(ref) => { this.input = ref }}
                                    value={this.state.mobileNumber}
                                    maxLength={150}
                                />
                                <div className="abs-div" >
                                    {this.state.showDropdown ? this.countryCodeDropDown(this.state.countryCodeDropDown) : ""}
                                </div>

                            </div>


                        </div>

                        {this.state.invalidMobileNumber && <div className="failed-text">Please enter your mobile number</div>}
                        {this.state.isUserExist && <div className="failed-text">User already exist</div>}


                        {!this.state.mobileNumber || !this.state.countryCode ?
                            <div className="d-flex justify-content-center mt-4">
                                <div className="active-button save-button-disabled d-flex justify-content-center">
                                    SAVE
                                </div>
                            </div>

                            :

                            <div className="d-flex justify-content-center mt-4">
                                <div className="active-button d-flex justify-content-center cursor-pointer"
                                    onClick={this.sendOtp}>
                                    SAVE
                                </div>
                            </div>
                        }
                    </div>
                )
            }

            if (this.state.activeType == 'VERIFY') {
                return (
                    <div className="mt-4 pl-4">
                        <div className="mobile-number-text">OTP sent to {this.state.mobileNumber && this.state.mobileNumber.trim()}</div>

                        {/* <View style={Styles.otpWrapper}>
                            <Otp hideLable={true} onChange={this.onOtpChange} ref={(ref) => this.otpComponent = ref} isInvalidOtp={this.state.invalidOtp} delegate={this} otpRef={(ref) => { this.input = ref }} />
                        </View> */}
                        <div className="otp-wrapper">
                            <Otp hideLable={true} onChange={this.onOtpChange} ref={(ref) => this.otpComponent = ref} isInvalidOtp={this.state.invalidOtp} delegate={this} otpRef={(ref) => { this.input = ref }} />
                        </div>

                        {!this.state.otp || this.state.otp.length != 4 ?
                            <div className="d-flex justify-content-center mt-4">
                                <div className="active-button save-button-disabled d-flex justify-content-center">
                                    VERIFY
                                </div>
                            </div>

                            :

                            <div className="d-flex justify-content-center mt-2">
                                <div className="active-button d-flex justify-content-center cursor-pointer"
                                    onClick={this.updateProfile}>
                                    VERIFY
                                </div>
                            </div>
                        }

                    </div>

                )

            }
        }

        

        // if (updateType == 'CITY') {
        //     return (
        //         <View>
        //             <TouchableOpacity style={this.state.invalidCity && !this.state.city ? [Styles.inputField, Styles.failed] : Styles.inputField}>

        //                 <TouchableOpacity style={Styles.searchIcon}>
        //                     <Image source={getImageSource(ImageConstants.CITY_SEARCH_ICON)} />
        //                 </TouchableOpacity>

        //                 <TextInput style={Styles.inputBoxForCity}
        //                     placeholder={'Enter your city'}
        //                     onChangeText={(text) => this.onCityChange(text)}
        //                     value={this.state.city}
        //                     onFocus={() => this.setState({ invalidCity: false })}
        //                     autoCompleteType='off'
        //                     textContentType='none'
        //                     returnKeyType={"next"}
        //                     maxLength={150}
        //                     editable={!this.state.selectedCity}
        //                     ref={(ref) => { this.input = ref }}
        //                 />

        //                 {this.state.selectedCity && <TouchableOpacity style={Styles.closeIconSearch} onPress={() => { this.setState({ selectedCity: null, city: '', cities: [] }) }} hitSlop={Styles.hitSlop}>
        //                     <Image source={getImageSource(ImageConstants.NAVIGATION_CLOSE)} />
        //                 </TouchableOpacity>}
        //             </TouchableOpacity>

        //             <View style={Styles.citiesList}>
        //                 <FlatList
        //                     style={Styles.flatList}
        //                     data={this.state.cities}
        //                     showsVerticalScrollIndicator={false}
        //                     keyboardShouldPersistTaps='handled'
        //                     renderItem={({ item }) =>

        //                         <TouchableOpacity onPress={() => { this.onSelectCity(item) }} style={Styles.cityDetails}>
        //                             <Text style={Styles.cityName}>{item.name}, {item.state}, {item.country}</Text>
        //                         </TouchableOpacity>
        //                     }
        //                 />
        //             </View>


        //             {/* {this.state.invalidCity && !this.state.city && <Text style={Styles.failedText}>Please enter your city</Text>} */}

        //             {!this.state.selectedCity ? <View style={Styles.applyButtonForCity}>
        //                 <View style={Styles.applayButtonWarpper}>
        //                     <Text style={Styles.disabledText}>
        //                         SAVE
        //                         </Text>
        //                 </View>
        //             </View> :
        //                 <TouchableOpacity onPress={this.updateProfile} style={Styles.applyButtonForCity}>
        //                     <LinearGradient colors={Colors.appTheme.gradient4} start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} style={Styles.applayButtonWarpper}>
        //                         <Text style={Styles.applyText}>SAVE</Text>
        //                     </LinearGradient>
        //                 </TouchableOpacity>
        //             }

        //         </View>
        //     )
        // }



        if (updateType == 'EMAIL') {
            return (
                     <div className="mt-5 pl-4">
                    <div className="row">
                        <div className="col">
                            <input className="cw-input-1"
                                placeholder="Enter Your Parent Email Id"
                                onChange={(event) => this.setState({ email: event.target.value })}
                                value={this.state.email}
                                onFocus={() => this.setState({invalidEmail: false })}
                                maxLength={150}
                                ref={(ref) => { this.input = ref }}
                                onBlur={() => {
                                    if (this.state.email && !CommonUtils.validateEmail(this.state.email)) {
                                        this.setState({ invalidEmail: true })
                                    } else {
                                        this.setState({ invalidEmail: false })
                                    }
                                }} />
                        </div>
                    </div>
                    {this.state.email && this.state.invalidEmail ? <div className="failed-text mt-2">Please enter valid email</div> : <></>}


                    {!this.state.email.trim() ?
                        <div className="d-flex justify-content-center mt-5">
                            <div className="active-button save-button-disabled d-flex justify-content-center">
                                SAVE
                            </div>
                        </div>

                        :

                        <div className="d-flex justify-content-center mt-5">
                            <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.updateProfile}>
                                SAVE
                            </div>
                        </div>
                    }


                </div>
                    
                
            )
        }



    }


    render() {


        if(this.props.type == 'CITY') {
            return(
                <CityModal delegate = {this} showSaveButton = {true} />
            )
        }


        return (
            <Modal className="custom-edit-popup" isOpen={true}>

                <ModalBody>
                    <div className="">
                        <div>
                            <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                            <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} />
                        </div>
                        <div className="title">{this.getTitle()}</div>
                        {this.renderBodyContent()}
                        <div className="">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>

                {/* {this.state.loading && <Loading />} */}
            </Modal>
        )
    }

}

export default EditPopup;
import React from "react";
import './passcode-update-screen.scss';
import { getImageSource } from "../../utility/image-source";
import { ImageConstants } from "../../constants/constants";
import CustomToaster from "../common/custom-toaster/custom-toaster";
import Path from "../routes/routes-path";
import AuthenticateAPI from "../../apis/authenticate";
import SharedProfile from "../../../shared-storage/profile";

class PasscodeUpdateScreen extends React.Component {

    state = {
        newPasscodeBox1: '',
        newPasscodeBox2: '',
        newPasscodeBox3: '',
        newPasscodeBox4: '',
        reEnteredPasscodeBox1: '',
        reEnteredPasscodeBox2: '',
        reEnteredPasscodeBox3: '',
        reEnteredPasscodeBox4: "",
        newPassCode: '',
        reEnteredPassCode: '',
        passcodeBox1: '',
        passcodeBox2: '',
        passcodeBox3: '',
        passcodeBox4: '',
        userInfo: {}
    }

    userInfo = {}

    constructor(props) {
        super(props)
        // this.userInfo = SharedProfile.getProfile()

        // console.log(this.userInfo)

    }


    componentDidMount() {
        let userInfo = SharedProfile.getProfile()
        console.log(userInfo)
        this.setState({ userInfo })
    }



    onKeyUpEvent(index, event, boxType) {
        const eventOtp = event.which || event.keyCode;
        if (this.getOtpBoxElement(index, boxType).value.length === 1) {
            if (index !== 4) {
                this.getOtpBoxElement(index + 1, boxType).focus()
                this.getOtpBoxElement(index + 1, boxType).select();
            } else {
                this.getOtpBoxElement(index, boxType).blur();
            }
        }
        if (eventOtp === 8 && index !== 1) {
            this.props.confirmPasscode && this.props.confirmPasscode(this.state.reEnteredPassCode, false)
            this.setState({ invalidOtp: false, confirmPasscode: false, invalidPsscode: false })
            this.getOtpBoxElement(index - 1, boxType).focus()
            this.getOtpBoxElement(index - 1, boxType).select();
        }
    }


    getOtpBoxElement(index, boxType) {
        return document.getElementById(boxType + index);
    }

    onFocusEvent(index, boxType) {
        if (this.state.isLoginFailed) {
            this.setState({ isLoginFailed: false })
        }
        for (let item = 1; item < index; item++) {
            const currentElement = this.getOtpBoxElement(item, boxType);
            if (!currentElement.value) {
                currentElement.focus();
                currentElement.select()
                break;
            }
        }
    }


    validateOTP() {

        if (this.state.otpBox1 && this.state.otpBox2 && this.state.otpBox3 && this.state.otpBox4) {
            return true
        }
        return false
    }


    onChangeHandler = (event, key) => {
        if (key === "mobileNumber") {
            if (!this.allowOnlyNumbers(event.target.value)) return
            this.setState({
                [key]: event.target.value,
                isInvalidMobile: false,
                isMobileNumberEdit: false,
                isMobileNumberNotRegistered: false,
                showNewPasscodeSetText: false,
                userListToSetPasscode: [],
                isValidMobileNumber: this.validateMobile(event.target.value)
            });
        } else {
            this.setState({ [key]: event.target.value, showInvalidMessage: false, showSuccessMessage: false }, () => {

                if (key.startsWith('passcodeBox') === true) {

                    this.setState({
                        currentPasscode: `${this.state.passcodeBox1 + this.state.passcodeBox2 + this.state.passcodeBox3 + this.state.passcodeBox4}`,
                        // isLoginEnable: this.validateOTP()
                    })
                } else if (key.startsWith('newPasscodeBox') === true) {
                    this.setState({
                        newPassCode: `${this.state.newPasscodeBox1 + this.state.newPasscodeBox2 + this.state.newPasscodeBox3 + this.state.newPasscodeBox4}`,
                        // isLoginEnable: this.validateOTP()
                    })
                } else if (key.startsWith('reEnteredPasscodeBox') === true) {
                    this.setState({
                        reEnteredPassCode: `${this.state.reEnteredPasscodeBox1 + this.state.reEnteredPasscodeBox2 + this.state.reEnteredPasscodeBox3 + this.state.reEnteredPasscodeBox4}`
                    })
                } else {
                    this.props.delegate && this.props.delegate.onOtpChange(`${this.state.otpBox1 + this.state.otpBox2 + this.state.otpBox3 + this.state.otpBox4}`)
                }

                if (this.state.reEnteredPasscodeBox4) {
                    this.matchPasscode()
                }
            });
        }
    }

    matchPasscode() {
        let newPassCode = `${this.state.newPasscodeBox1 + this.state.newPasscodeBox2 + this.state.newPasscodeBox3 + this.state.newPasscodeBox4}`
        let reEnteredPassCode = `${this.state.reEnteredPasscodeBox1 + this.state.reEnteredPasscodeBox2 + this.state.reEnteredPasscodeBox3 + this.state.reEnteredPasscodeBox4}`
        let currentPasscode = `${this.state.passcodeBox1 + this.state.passcodeBox2 + this.state.passcodeBox3 + this.state.passcodeBox4}`
        console.log(currentPasscode)

        if (currentPasscode.length < 4) {
            this.setState({ showEnterCurrentPasscode: true }, () => {
                setTimeout(() => {
                    this.setState({ showEnterCurrentPasscode: false })
                }, 2000)
            })
            return;
        }

        if (newPassCode === reEnteredPassCode && currentPasscode.length === 4) {
            this.setState({ confirmPasscode: true, newPassCode, reEnteredPassCode })
        } else {
            this.setState({ showPasscodeNotMatch: true }, () => {
                setTimeout(() => {
                    this.setState({ showPasscodeNotMatch: false })
                }, 2000)
            })
            return false
        }

    }



    resetNewPasscodeInputBoxs() {
        this.setState({
            newPasscodeBox1: '',
            newPasscodeBox2: '',
            newPasscodeBox3: '',
            newPasscodeBox4: '',
            newPassCode: '',
        })
    }


    resetReEnteredPasscodeInputBoxs() {
        this.setState({
            reEnteredPasscodeBox1: '',
            reEnteredPasscodeBox2: '',
            reEnteredPasscodeBox3: '',
            reEnteredPasscodeBox4: '',
            reEnteredPassCode: '',
        })
    }

    resetPasscodeInputBoxs() {
        this.setState({
            passcodeBox1: '',
            passcodeBox2: '',
            passcodeBox3: '',
            passcodeBox4: '',
            passCode: '',
        })
    }


    updatePasscode() {
        if (!this.state.currentPasscode.length && !this.state.newPassCode.length && !this.state.reEnteredPassCode.length) {
            return
        }

        console.log(this.state.userInfo.username)

        let userName = this.state.userInfo.username
        let mobileNumber = this.state.userInfo.mobileNumber
        let newPasscode = this.state.reEnteredPassCode
        let currentPasscode = this.state.currentPasscode
        const countryCode = this.state.userInfo.countryCode

        let payload = {
            userName,
            countryCode,
            mobileNumber,
            newPasscode,
            currentPasscode
        }

        console.log(payload)

        AuthenticateAPI.updatePasscode(payload).then(res => {
            console.log(res)

            if (res.status === 200) {
                this.resetNewPasscodeInputBoxs()
                this.resetReEnteredPasscodeInputBoxs()
                this.resetPasscodeInputBoxs()
                this.setState({ showSuccessMessage: true })
            }

        }).catch(err => {
            console.log(err.response)
            this.setState({ showInvalidMessage: true })

        })
    }

    renderPassCodeInputBoxes() {
        return (
            <div className="passcode-boxes">
                <input autoFocus id="passcodeBox1" className='input-one-margin-left' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, 'passcodeBox')} onFocus={() => this.onFocusEvent(1, 'passcodeBox')} onChange={(e) => this.onChangeHandler(e, 'passcodeBox1')} value={this.state.passcodeBox1} />
                <input id="passcodeBox2" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, 'passcodeBox')} onFocus={() => this.onFocusEvent(2, 'passcodeBox')} onChange={(e) => this.onChangeHandler(e, 'passcodeBox2')} value={this.state.passcodeBox2} />
                <input id="passcodeBox3" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, 'passcodeBox')} onFocus={() => this.onFocusEvent(3, 'passcodeBox')} onChange={(e) => this.onChangeHandler(e, 'passcodeBox3')} value={this.state.passcodeBox3} />
                <input id="passcodeBox4" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, 'passcodeBox')} onFocus={() => this.onFocusEvent(4, 'passcodeBox')} onChange={(e) => this.onChangeHandler(e, 'passcodeBox4')} value={this.state.passcodeBox4} />
            </div>)
    }


    renderNewPassCodeInputBoxes() {
        return (
            <div className="passcode-boxes">
                <input id="newPasscodeBox1" className='input-one-margin-left' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(1, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox1')} value={this.state.newPasscodeBox1} />
                <input id="newPasscodeBox2" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(2, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox2')} value={this.state.newPasscodeBox2} />
                <input id="newPasscodeBox3" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(3, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox3')} value={this.state.newPasscodeBox3} />
                <input id="newPasscodeBox4" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, 'newPasscodeBox')} onFocus={() => this.onFocusEvent(4, 'newPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'newPasscodeBox4')} value={this.state.newPasscodeBox4} />
            </div>)
    }

    renderReEnterPassCodeInputBoxes() {
        return (
            <div className="passcode-boxes">
                <input id="reEnteredPasscodeBox1" className='input-one-margin-left' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(1, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox1')} value={this.state.reEnteredPasscodeBox1} />
                <input id="reEnteredPasscodeBox2" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(2, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox2')} value={this.state.reEnteredPasscodeBox2} />
                <input id="reEnteredPasscodeBox3" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(3, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox3')} value={this.state.reEnteredPasscodeBox3} />
                <input id="reEnteredPasscodeBox4" className='' type="number" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event, 'reEnteredPasscodeBox')} onFocus={() => this.onFocusEvent(4, 'reEnteredPasscodeBox')} onChange={(e) => this.onChangeHandler(e, 'reEnteredPasscodeBox4')} value={this.state.reEnteredPasscodeBox4} />
            </div>)
    }


    goToHomeScreen() {
        this.props.history.push(Path.HOME)
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb ml-2 mt-2'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1'>{`Change Passcode`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }



    render() {
        return (
            <div className="passcode-update-screen container-fluid">
                {this.renderBreadcrumbForChapterList()}
                <div className="ml-2">
                    <div className="header-text">
                        Change Passcode
                    </div>
                    <div className="row align-items-center mt-2">
                        <div className="col-1 pr-0 info-max-width"><img className="info-image-size" src={getImageSource(ImageConstants.INFO_ICON)} /></div>
                        <div className="col-6 pl-0 info-text">Your passcode will be the key to access your Countingwell account. So think of a unique 4 digit number and use it as a passcode to login.</div>
                    </div>
                    <div className="container-height mt-3">
                        <div className="update-passcode-wrapper">
                            <div className="pass-code">

                                <label className="cw-input-label-1">Enter Current Passcode</label>
                                {this.renderPassCodeInputBoxes()}
                                <label className="cw-input-label-1 mt-4">Create New Passcode</label>
                                {this.renderNewPassCodeInputBoxes()}
                                {/* <div className="text-left enter-passcode">Enter new 4 digit passcode. You can use this passcode to login when you do not have a phone.</div> */}
                                <label className="cw-input-label-1 mt-4">Re-enter New Passcode</label>
                                {this.renderReEnterPassCodeInputBoxes()}
                                {
                                    this.state.showPasscodeNotMatch ?
                                        <div className="custom-toast-for-invalid-passcode">
                                            <CustomToaster toasterMessage="Passcode does mot match" type="error" />
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    this.state.showEnterCurrentPasscode ?
                                        <div className="custom-toast-for-invalid-passcode">
                                            <CustomToaster toasterMessage="Enter Current Passcode" type="error" />
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                        </div>
                        {
                            this.state.showInvalidMessage ?
                                <div className="invalid-text">Entered Current Passcode is invlaid</div>
                                :
                                <></>
                        }

                        {
                            this.state.showSuccessMessage ?
                                <div className="success-text">Passcode Changed Successfully</div>
                                :
                                <></>
                        }
                        {
                            this.state.confirmPasscode ?
                                <div className="d-flex justify-content-center ">
                                    <div className="update-button cursor-pointer" onClick={() => this.updatePasscode()}>Change Passcode</div>
                                </div>
                                :
                                <div className="d-flex justify-content-center ">
                                    <div className="update-button-disabled">Change Passcode</div>
                                </div>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default PasscodeUpdateScreen;
import React, { Component } from 'react';
import './dot-pagination.scss';
import { MyAssessmentContext } from '../my-assessment-context/my-assessment-content';
import { StandardClassworkConText } from '../../class-work/standard-classwork-experience';
import SharedThemeCategory from '../../../../shared-storage/category-theme';

class DotPagination extends React.Component {
    state = {

    };

    alphabet = ['a', 'b', 'c', 'd', 'e',
        'f', 'g', 'h', 'i', 'j',
        'k', 'l', 'm', 'n', 'o',
        'p', 'q', 'r', 's', 't',
        'u', 'v', 'w', 'x', 'y',
        'z'];

    constructor(props) {
        super(props);
        // setCategoryThemeStyles(props.categoryThemeId)
    }


    getSteps = (showAlphabet, showAlphabetForHomeworkAssessment) => {
        let render = [];

        let activeBgColor = this.theme['themeColor']
        let inActiveBgColor = this.theme[`secondaryColors`][`dragItemBorder`]

        for (let i = 0; i < this.props.noOfDots; i++) {
            if (this.props.activeDot == i + 1)
                render.push(
                    <div className='active-step d-flex flex-column justify-content-center align-items-center' style={{backgroundColor : activeBgColor}} >
                        <div className='active-step-text'>{showAlphabet || showAlphabetForHomeworkAssessment ? this.alphabet[this.props.activeDot - 1] : this.props.activeDot}</div>
                    </div>
                );

            else if (i < this.props.activeDot)
                render.push(
                    <div className='dot-line-container d-flex flex-row justify-content-center align-items-center' >
                        <div className='attempted-question d-flex flex-column justify-content-center align-items-center' style={{backgroundColor : activeBgColor}} >
                            <div className='steps-attempted-text'>{showAlphabet || showAlphabetForHomeworkAssessment ? this.alphabet[i] : i + 1}</div>
                        </div>
                        <div className='line' style={{backgroundColor : activeBgColor}}></div>

                    </div>
                );

            else
                render.push(
                    <div className='dot-line-container d-flex flex-row justify-content-center align-items-center' >
                        <div className='line' style={{backgroundColor : activeBgColor}}></div>
                        <div className='steps d-flex flex-column justify-content-center align-items-center' style={{backgroundColor : inActiveBgColor}}>
                            <div className='inactive-steps-text'>{showAlphabet || showAlphabetForHomeworkAssessment ? this.alphabet[i] : i + 1}</div>
                        </div>
                    </div>
                );

        }
        return render;
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <div className='dot-pagination d-flex flex-row justify-content-center align-items-center mb-3' style={{}}>
                <MyAssessmentContext.Consumer>
                    {({ showAlphabet }) => (<>
                        <StandardClassworkConText.Consumer>
                            {
                                ({ showAlphabetForHomeworkAssessment }) => (<>
                                    {showAlphabetForHomeworkAssessment || showAlphabet ? this.getSteps(showAlphabet, showAlphabetForHomeworkAssessment) : <></>}
                                </>)
                            }
                        </StandardClassworkConText.Consumer>
                    </>)}
                </MyAssessmentContext.Consumer>

            </div>);
    }
}

export default DotPagination;
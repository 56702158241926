import React from "react";
import './answer-tab.scss';
import SharedThemeCategory from "../../../../../shared-storage/category-theme";

export default class AnswerTab extends React.Component {

    state = {
        tabIndex: 1
    }


    onCorrectAnswer = () => {
        this.setState({ tabIndex: 1 })
        this.props.onCorrectAnswer && this.props.onCorrectAnswer()
    }

    onMyAnswer = () => {
        this.setState({ tabIndex: 2 })
        this.props.onMyAnswer && this.props.onMyAnswer()
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <div className="answer-tab">
                <div className={`row row-max-width-for-answer justify-content-center align-items-center mr-0 ml-0 mt-3 mb-3
                ${this.props.status == "IN_CORRECT" && this.props.answer ? '' : 'only-correct-answer-width'}`}>
                    {
                        this.props.status == "IN_CORRECT" && this.props.answer ?
                            <div className="col d-flex">
                                <div className={`text-center answer-status-text p-2 tab ${this.state.tabIndex == 1 ? 'active-tab' : 'in-active-tab'}`}
                                style={{backgroundColor : this.state.tabIndex == 1 ? this.theme['themeColor'] : ''}} onClick={() => { this.onCorrectAnswer() }}>
                                    <span className="tab-text">{"Correct Answer"}</span>
                                </div>
                                <div className={`text-center ml-3 answer-status-text p-2 tab ${this.state.tabIndex == 2 ? 'active-my-answer-tab' : 'in-active-tab'}`}
                                style={{backgroundColor : this.state.tabIndex == 2 ? this.theme['themeColor'] : ''}} 
                                onClick={() => { this.onMyAnswer() }}>
                                    <span className="tab-text">{"My Answer"}</span>
                                </div>
                            </div> :
                            <div className="col text-center only-correct-answer answer-status-text tab active-tab" style={{backgroundColor : this.theme['themeColor']}}>
                                <span className="tab-text">{"Correct Answer"}</span>
                            </div>

                    }
                </div>
            </div>


        )
    }
}
import React from "react";
import './school-info-confirm.scss';
import SharedProfile from "../../../../../shared-storage/profile";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import { getImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
import CommonApi from "../../../../apis/common";
import { Modal, ModalBody } from "reactstrap";
import IncorrectDetails from "../incorrect-details-poup/incorrect-details-popup";
import FirebaseEvent from '../../../../firebase-events/firebase-events';

export default class SchoolInfoConfirm extends React.Component {

    constructor(props) {
        super(props);
        this.staticText = SharedStaticText.getStaticText('schoolInfoConfirm');
        this.userInfo = SharedProfile.getProfile();


        this.meta = this.props.meta;

        if (this.meta && this.meta.payload) {
            let { schoolCode,
                redirectTo,
                locality,
                schoolName,
                schoolOriginalCode,
                classTitle,
                board,
                section } = this.meta.payload

            if (schoolCode)
                this.state.schoolCode = schoolCode

            if (redirectTo)
                this.state.redirectTo = redirectTo

            if (locality)
                this.state.locality = locality

            if (schoolName)
                this.state.schoolName = schoolName

            if (schoolOriginalCode)
                this.state.schoolOriginalCode = schoolOriginalCode

            if (classTitle)
                this.state.class = classTitle

            if (board)
                this.state.board = board

            if (section)
                this.state.section = section
        }
    }

    state = {
        schoolName: "",
        class: "",
        section: ""
    }

    componentDidMount() {
    }

    onPressConfirm = () => {
        this.schoolConnectCodeConfirm()
    }

    onPressIncorrectDetails = () => {
        this.setState({ showIncorrectDetailsPopup: true })
    }

    onPressSubmit = (items) => {


        let payload = {
            schoolCode: this.state.schoolCode,
            items
        }

        CommonApi.updateInvalidInformation(payload).then((res) => {
            this.setState({ showIncorrectDetailsPopup: false })

            // if (this.meta && this.meta.payload && this.meta.payload.isFromOnBoarding) {
            //     this.props.navigation.navigate('LandingScreen', {
            //         meta: {
            //             ...this.meta.payload.landingScreenMeta
            //         }
            //     });
            //     return
            // }

            // SharedDeepLink.setDeepLink(null)
            this.props.delegate && this.props.delegate.onPressIncorrectDetails && this.props.delegate.onPressIncorrectDetails()
        }).catch(err => {
            if (err) {
                return;
            }
        })
    }

    hideIncorrectDetailsPopup = () => {
        this.setState({ showIncorrectDetailsPopup: false })
    }


    schoolConnectCodeConfirm = () => {
        const { schoolCode, redirectTo, locality, schoolName, schoolOriginalCode } = this.state;
        let payload = {
            schoolCode: schoolCode
        }

        CommonApi.schoolConnectConfirm(payload).then((res) => {

            this.setState({ showSchoolConnectPopUp: false })

            this.userInfo['schoolName'] = schoolName;
            this.userInfo['schoolLocality'] = locality;
            this.userInfo['schoolVerificationCode'] = schoolCode
            this.userInfo['schoolCode'] = schoolOriginalCode ? schoolOriginalCode : schoolCode

            if (res && res.section)
                this.userInfo['section'] = res.section

            // moengage.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })
            FirebaseEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": schoolName, "School Locality": locality })
            // Facebook.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })
            // BranchIO.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })


            SharedProfile.setProfile(this.userInfo)
            // SharedDeepLink.setDeepLink(null)


            // if (this.meta && this.meta.payload && this.meta.payload.isFromOnBoarding) {
            //     this.props.navigation.navigate('LandingScreen', {
            //         meta: {
            //             ...this.meta.payload.landingScreenMeta
            //         }
            //     });
            //     return
            // }

            // if (redirectTo) {
            //     this.props.navigation.navigate(redirectTo);
            //     return;
            // }
            this.props.delegate && this.props.delegate.onPressIncorrectDetails && this.props.delegate.onPressIncorrectDetails()
        }).catch(err => {
            if (err) {
                return;
            }
        })
    }


    close() {
        this.props.delegate && this.props.delegate.onPressIncorrectDetails && this.props.delegate.onPressIncorrectDetails()
    }

    render() {


        if(this.state.showIncorrectDetailsPopup) {
            return(
                <IncorrectDetails 
                delegate = {this} 
                schoolName={this.state.schoolName}
                locality={this.state.locality}
                class={this.state.class}
                board={this.state.board}
                onSubmit={this.onPressSubmit}
                section={this.state.section}
                 />
            )
        }


        return (
            <Modal className="custom-school-info-confirm-popup" isOpen={true}>
                <ModalBody>
                    <div>
                        <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                        <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.close()} />
                    </div>
                    <div className="title">{`Verify Your School Information`}</div>
                    <div className="mt-4 pl-5">
                        <div className="header-text">{this.staticText && this.staticText.title ? this.staticText.title : "Are you from"}</div>
                        <div className="label-container">
                            <div className="school-label mt-3">{this.staticText.schoolTitle}</div>
                            <div className="label-text">{this.state.schoolName}, {this.state.locality}</div>

                            <div className="school-label mt-3">{this.staticText.classTitle}</div>
                            <div className="label-text">{this.state.class}</div>

                            {
                                this.state.section ?
                                    <>
                                        <div className="school-label mt-3">{this.staticText.sectionTitle}</div>
                                        <div className="label-text">{this.state.section}</div>
                                    </>
                                    : <></>
                            }
                        </div>
                    </div>

                    <div className="button-wrapper d-flex justify-content-center mt-1">

                        <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.onPressConfirm}>
                            {this.staticText && this.staticText.confirmButton ? this.staticText.confirmButton : "CONFIRM"}
                        </div>

                        <div className="connect-later-button d-flex justify-content-center cursor-pointer" onClick={this.onPressIncorrectDetails}>
                            {this.staticText && this.staticText.incorrectDetailsButton ? this.staticText.incorrectDetailsButton : "INCORRECT DETAILS"}
                        </div>
                    </div>
                    <div className="">
                        <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}
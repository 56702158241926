import React from "react";
import './diagnostic-assessment.scss';
import CommonApi from "../../apis/common";
import { Constants } from "../../constants/constants";
import StartAssessment from "../common/start-assessment/start-assessment";
import Loading from "../common/loading/loading";
import AssessmentEngine from "../common/assessment-engine/assessment-engine";
import AssessmentEndScreen from "../diagnostic-assessment/assessment-end-screen/assessment-end-screen";
import MyAssessmentResult from "../my-assessment-result/my-assessment-result";
import LayoutShared from "../common/layout-shared/layout-shared";
import RefresherCourse from "../refresher-course/refresher-course";

class DiagnosticAssessment extends React.Component {


    constructor(props) {
        super(props)
        this.getDiagnosticAssessmentStatus()
        this.layoutShared = LayoutShared.getLayout()
    }

    state = {
        diagnosticAssessmentRes: {},
        isLoading: true,
        showStartAssessment: false,
        showAssessmentEngine: false,
        assessmentEngineMeta: {},
        showAssessmentEndScreen: false,
        showViewAnswersScreen: false,
        assessmentEndScreenInfo: {},
        showRefresherCourse : false,
        refresherCourseMeta : {}
    }

    componentDidMount() {
        this.layoutShared.toggleSidebar(true)
    }

    getDiagnosticAssessmentStatus = () => {

        let payload = {}
        this.setState({ isLoading: true })
        CommonApi.getDiagnosticAssessmentStatus(payload).then((res) => {
            console.log(res)
            this.setState({
                diagnosticAssessmentRes: res,
                showRefresherCourseCard: res.showRefresherCourseCard, isLoading: false
            }, () => {
                this.onPressDiagnosticAssessment()
            })
        }).catch(err => {
            if (err) {
                this.setState({ showRefresherCourseCard: false, isLoading: false })
                return;
            }
        })
    }

    onPressDiagnosticAssessment = () => {

        if (!this.state.diagnosticAssessmentRes)
            return

        // if (!this.userInfo.schoolName || !this.userInfo.schoolLocality) {
        //     this.props.navigation.navigate('AddYourSchoolV2', { meta: { data: this.state.diagnosticAssessmentRes } })
        //     return
        // }
        // this.setState({isLoading : false})
        if (this.state.diagnosticAssessmentRes.status == 'START') {
            // this.props.navigation.navigate('StartAssessment', { meta: { chapterKey: "", chapterTitle: `Diagnostic Assessment for Refresher Course`, categoryThemeId: "1", from: Constants.REFRESHER_COURSE } })
            this.layoutShared.toggleSidebar(true, this.props.history.location.pathname)
            let meta = {
                chapterKey: "",
                chapterTitle: `Diagnostic Assessment for Bridge Course`,
                categoryThemeId: "1",
                from: Constants.REFRESHER_COURSE
            }

            this.setState({ startAssessmentMeta: meta, showStartAssessment: true })
            // return <StartAssessment
            //     delegate = {this}
            //     meta={meta}
            // />
        }

        if (this.state.diagnosticAssessmentRes.status == 'IN_PROGRESS') {
            this.layoutShared.toggleSidebar(true, this.props.history.location.pathname)
            let meta = {
                chapterTitle: "Diagnostic Assessment for Bridge Course",
                isResume: true,
                assessmentActivityId: this.state.diagnosticAssessmentRes.assessmentActivityId,
                categoryThemeId: "1",
                assessmentId: this.state.diagnosticAssessmentRes.assessmentId,
                from: Constants.REFRESHER_COURSE
            }


            this.setState({ showAssessmentEngine: true, assessmentEngineMeta: meta })

            // this.props.navigation.navigate('AssessmentEngine', {
            //     meta: {
            //         chapterTitle: "Diagnostic Assessment for Refresher Course",
            //         isResume: true,
            //         assessmentActivityId: this.state.diagnosticAssessmentRes.assessmentActivityId,
            //         categoryThemeId: "1",
            //         assessmentId: this.state.diagnosticAssessmentRes.assessmentId,
            //         from: constants.REFRESHER_COURSE
            //     }
            // })


            // return
        }

        if (this.state.diagnosticAssessmentRes.status == 'COMPLETED') {
            this.setState({showRefresherCourse : true, refresherCourseMeta : { from: "REFRESHER_COURSE" }})
            // this.props.navigation.navigate('RefresherCourse', { meta: { from: "REFRESHER_COURSE" } })
            // this.showDiagnosticAssessmentEndScreen({data : this.state.diagnosticAssessmentRes.activityData, 
            //     from : Constants.REFRESHER_COURSE,
            //     chapterTitle: "Diagnostic Assessment for Refresher Course", categoryThemeId: "1",assessmentActivityId: this.state.diagnosticAssessmentRes.assessmentActivityId,})
            return
        }
    }


    showAssessmentEngine(data) {
        // console.log(data)
        this.setState({ showAssessmentEngine: true, showStartAssessment: false, assessmentEngineMeta: data })
    }



    showDiagnosticAssessmentEndScreen(assessmentCompletedInfo) {
        //console.log(assessmentCompletedInfo)
        this.setState({ showAssessmentEngine: false, showAssessmentEndScreen: true, ...assessmentCompletedInfo })
    }

    showViewAnswersScreen(assessmentEndScreenInfo) {
        this.setState({ showViewAnswersScreen: true, showAssessmentEndScreen: false, assessmentEndScreenInfo: assessmentEndScreenInfo.meta })
    }

    hideViewAnswersScreen() {
        this.setState({ showViewAnswersScreen: false, showAssessmentEndScreen: true })
    }

    showRefresherCourseFromAssessmentEndScreen() {
        this.setState({showRefresherCourse : true, showAssessmentEndScreen: false, refresherCourseMeta : { from: "REFRESHER_COURSE" }})
    }

   

    render() {

        if (this.state.showAssessmentEndScreen) {
            return (
                <AssessmentEndScreen
                    delegate = {this}
                    metaInfo={this.state}
                    showViewAnswerScreen={(fromAssess) => this.showViewAnswersScreen(fromAssess)}
                />
            )
        }

        if (this.state.showViewAnswersScreen) {
            return (
                <MyAssessmentResult meta={this.state.assessmentEndScreenInfo}
                    hideViewAnswersScreen={() => this.hideViewAnswersScreen()}
                    delegate = {this} />
            )
        }

        if(this.state.showRefresherCourse) {
            return (
                <RefresherCourse delegate = {this} meta = {this.state.refresherCourseMeta} />
            )
        }

        return (
            <>
                {
                    !this.state.isLoading ?
                        <>
                            {
                                this.state.showStartAssessment &&
                                <StartAssessment
                                    delegate={this}
                                    meta={this.state.startAssessmentMeta} />
                            }
                            {
                                this.state.showAssessmentEngine &&
                                <AssessmentEngine
                                    delegate={this}
                                    meta={this.state.assessmentEngineMeta}
                                />
                            }
                        </>
                        :
                        <Loading />
                }

            </>
        )
    }

}

export default DiagnosticAssessment;
import React from 'react';
import './refresher-course.scss';
import LearningContentWrapper from '../learning/workout-learning-content-wrapper/workout-learning-content-wrapper';
import { Constants, FirebaseEvents } from '../../constants/constants';
import ContentListScreen from './content-list-screen/content-list-screen';
// export const RefresherCourseContaxt = React.createContext({ disableNext: false })
import FirebaseEvent from '../../firebase-events/firebase-events';
import SharedProfile from '../../../shared-storage/profile';
import CommonUtils from '../../utility/common-utilities';
import CommonApi from '../../apis/common';
import RefresherCourseApi from '../../apis/refresher-course';
import WorkoutApi from '../../apis/workout';
import Loading from '../common/loading/loading';
import EventApi from '../../apis/event';


export default class RefresherCourseExperience extends React.Component {

    workflowEngine = null;
    worflowNodes = [];
    questions = [];
    state = {
        questions: [],
        answers: [],
        learningElos: [],
        showSuccessFailureScreen: false,
        stepIndex: 0,
        showHint: false,
        showSolvedExamplesModal: false,
        showInvalidAnswerModal: false,
        showCorrectIncorrectModal: false,
        showCorrectAnswer: false,
        isReadOnly: false,
        showNewConceptModal: false,
        newConceptTitle: '',
        disableNext: false,
        showContentList: false,
        loading: false
    };
    questionComponent = null;

    failedInAssessment = false;
    successFailureScreenData = {};
    isRevise = false;
    isReassessment = false;

    hintShowed = false;
    solvedExamplesShowed = false;

    isCorrect = false;
    eloIds = []

    contentListData = {}
    noBlur = false

    constructor(props) {

        super(props);

        this.params = this.props.params
        this.progress = null;
        this.userInfo = SharedProfile.getProfile();

        this.getScollarEligibilityThreshold()

        // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()

        // setTimeout(()=>{
        // if (!this.userInfo.isSubscribed && this.params && (this.params.from == constants.TWO_STEP_EVENT || this.params.from == constants.TWO_STEP_EVENT_BASELINE))
        //     this.landingScreenInstance.pauseUserAppTime()
        // },1000)

    }

    componentDidMount() {

        if (this.params.status === Constants.ELO_STATUS_CLEARED && this.params.eloIds) {
            this.getLearningContent([this.params.eloIds]);
            this.isRevise = true;
            this.noBlur = true
        }
        else {

            // this.getAssessmentContent(false, (err, data) => {

            //     if (err) {
            //         //alert('get content is error');
            //         return;
            //     }
            //     this.setQuestion(data, true);
            // });

            this.getLearningContent(null, (err, data) => {

                if (err) {
                    return;
                }

                console.log(data)

                this.contentListData = data

                if (!data.isNodeActivityExist)
                    this.setState({ showContentList: true, isNodeActivityExist: false })
                else {

                    this.getAssessmentContent(false, (err, data) => {

                        if (err) {
                            //alert('get content is error');
                            return;
                        }

                        this.setQuestion(data, true);
                    });
                }
            });

            if (this.params.isSubscribedFlow && this.params.isWorkoutNotStarted)
                this.updateWorkOut({ updateType: 'START_TIME' })


            if (this.params.status === Constants.ELO_STATUS_NOT_CLEARED || this.params.status === Constants.TRY_AGIN_IN_PROGRESS) {
                this.noBlur = true
            }

        }

        // this.props.navigation.addListener("didFocus", () => {

        //     BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });

        // this.props.navigation.addListener("didBlur", () => {
        //     BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });
    }

    getLearningContent = (elos, callback) => {

        let payload = {
            nodeId: this.params.nodeId
        }

        if (!this.params.isSubscribedFlow)
            payload['workoutId'] = this.params.workoutId

        if (this.params.isChapterNotCompleted)
            payload.isNotCompletedFlow = true;

        let currentNode = this.getCurrentNode()

        this.setState({ loading: true })

        RefresherCourseApi.getRefresherLeanring(payload).then((response) => {


            if (callback) {
                callback(null, response)
                return;
            }

            this.contentListData = response;

            this.setState({
                learningElos: response.learningGroups,
                showContentList: this.state.isNodeActivityExist || !this.params.isChapterNotCompleted ? true : false,
                isNodeActivityExist: true,
                disableNext: false,
                loading: false
            });
            if (this.params && this.params.from == Constants.EVENT_CAMPAIGN) {
                FirebaseEvent('EVENT_CAMPAIGN_COURSE_LEARNING_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN })
                // Facebook.logCustomEvent('EVENT_CAMPAIGN_COURSE_LEARNING_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                // BranchIO.logCustomEvent('IMD_CAMPAIGN_COURSE_LEARNING_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                // moengage.logCustomEvent('Elo', { 'User Id': this.userInfo.userId, Chapter: this.params.title ? this.params.title : '', 'Elo Name': currentNode.elos[0].title, 'Elo Skill': currentNode.elos[0].skill ? currentNode.elos[0].skill : '', "Elo Status": 'Start', "Starting Point": 'Event Campaign' })
            } else if (this.params && this.params.from == Constants.REWARD_EVENT_BASELINE) {
                FirebaseEvent('EVENT_COURSE_LEARNING_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN })
                // Facebook.logCustomEvent('EVENT_COURSE_LEARNING_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                // BranchIO.logCustomEvent('EVENT_COURSE_LEARNING_START', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                // moengage.logCustomEvent('Elo', { 'User Id': this.userInfo.userId, Chapter: this.params.title ? this.params.title : '', 'Elo Name': currentNode.elos[0].title, 'Elo Skill': currentNode.elos[0].skill ? currentNode.elos[0].skill : '', "Elo Status": 'Start', "Starting Point": 'Event Qualification Course' })

            } else {
                FirebaseEvent('Elo', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN })
                // Facebook.logCustomEvent('Elo', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                // BranchIO.logCustomEvent('Elo', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                // moengage.logCustomEvent('Elo', { 'User Id': this.userInfo.userId, Chapter: this.params.title ? this.params.title : '', 'Elo Name': currentNode.elos[0].title, 'Elo Skill': currentNode.elos[0].skill ? currentNode.elos[0].skill : '', "Elo Status": 'Start', "Starting Point": 'Refresher Course' })

            }
            this.hideSuccessFailureScreen();
        }).catch(err => {
            if (err) {
                this.setState({ disableNext: false })
                return;
            }
        })

    }

    getAssessmentContent = (isNext, callback, firstTime, eloTitle) => {

        // this.noBlur = false

        let isTryAgain = false;
        if (this.params.status === Constants.ELO_STATUS_NOT_CLEARED || this.params.status === Constants.TRY_AGIN_IN_PROGRESS) {
            isTryAgain = true;
        }

        let payload = {
            nodeId: this.params.nodeId,
            isTryAgain: isTryAgain,
            isNext: isNext
        };

        let currentNode = this.getCurrentNode();

        if (!isTryAgain && this.params.status != Constants.ELO_STATUS_IN_PROGRESS) {
            FirebaseEvent(FirebaseEvents.ELO_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN })
            // Facebook.logCustomEvent(FirebaseEvents.ELO_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
            // BranchIO.logCustomEvent(FirebaseEvents.ELO_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
        }

        if (!this.params.isSubscribedFlow) {
            payload['workflowId'] = this.params.workoutId
            payload['isWorkout'] = true
        }

        if (this.params.isChapterNotCompleted)
            payload.isChapterNotCompleted = true;

        this.getContent(payload, callback, firstTime);
    }

    letsTryAgain = () => {

        this.noBlur = true

        let payload = {
            nodeId: this.params.nodeId,
            isTryAgain: true,
            isNext: false,
            // workflowId: this.params.workoutId,
            // isWorkout: true
        };

        if (!this.params.isSubscribedFlow) {
            payload['workflowId'] = this.params.workoutId
            payload['isWorkout'] = true
        }

        this.getContent(payload, (err, data) => {
            if (err) {
                //alert('Try again get content is error');
                return;
            }
            this.setState({ questions: data.content, index: 0 });
            this.hideSuccessFailureScreen();
        });
    }

    getContent = (payload, callback, firstTime) => {

        RefresherCourseApi.getRefresherCourseAssessmentContent(payload).then((data) => {

            let currentNode = this.getCurrentNode();

            if (data.contentType == "LEARNING") {

                let elos = data.elos.map(elo => elo.id);

                this.getLearningContent(elos);

            } else {
                // if (this.params && this.params.from == constants.EVENT_CAMPAIGN) {
                //     FirebaseEvent.logCustomEvent("EVENT_CAMPAIGN_COURSE_ASSESSMENT_START", { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                //     Facebook.logCustomEvent("EVENT_CAMPAIGN_COURSE_ASSESSMENT_START", { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                //     BranchIO.logCustomEvent("EVENT_CAMPAIGN_COURSE_ASSESSMENT_START", { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                //     moengage.logCustomEvent('Elo', { 'User Id': this.userInfo.userId, Chapter: this.params.title ? this.params.title : '', 'Elo Name': currentNode.elos[0].title, 'Elo Skill': currentNode.elos[0].skill ? currentNode.elos[0].skill : '', "Elo Status": 'Start', "Starting Point": 'Event Campaign' })

                // } else if (this.params && this.params.from == constants.REWARD_EVENT_BASELINE) {
                //     FirebaseEvent.logCustomEvent("EVENT_COURSE_ASSESSMENT_START", { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                //     Facebook.logCustomEvent("EVENT_COURSE_ASSESSMENT_START", { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                //     BranchIO.logCustomEvent("EVENT_COURSE_ASSESSMENT_START", { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                //     moengage.logCustomEvent('Elo', { 'User Id': this.userInfo.userId, Chapter: this.params.title ? this.params.title : '', 'Elo Name': currentNode.elos[0].title, 'Elo Skill': currentNode.elos[0].skill ? currentNode.elos[0].skill : '', "Elo Status": 'Start', "Starting Point": 'Event Qualification Course' })

                // } else {
                //     FirebaseEvent.logCustomEvent('Elo', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                //     Facebook.logCustomEvent('Elo', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                //     BranchIO.logCustomEvent('Elo', { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, eloName: currentNode.elos[0].title, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
                //     moengage.logCustomEvent('Elo', { 'User Id': this.userInfo.userId, Chapter: this.params.title ? this.params.title : '', 'Elo Name': currentNode.elos[0].title, 'Elo Skill': currentNode.elos[0].skill ? currentNode.elos[0].skill : '', "Elo Status": 'Start', "Starting Point": 'Refresher Course' })

                // }
                if (callback) {
                    callback(null, data);
                    return;
                }
            }
        }).catch(err => {
            console.log('refresher course assessment content error')
            if (callback) {
                callback(err, null);
                return;
            }
        })
    }

    updateWorkOut(payload) {
        WorkoutApi.updateWorkout(payload).then((data) => {

        }).catch(err => {
            if (err)
                return;
            console.log('error in update workout api')
        })
    }

    moveToNextWorkflowNodeForAssessment = (node) => {

        this.noBlur = false;

        let index = -1;

        let nodes = this.params.nodes;

        this.params.nodeId = node && node.id ? node.id : this.params.nodeId;

        let currentConceptContinue = node && node.id ? true : false;

        for (let i = 0; i < nodes.length; i++) {

            if (nodes[i].id == this.params.nodeId) {
                index = i;
                break;
            }
        }

        if (index != -1 && index < nodes.length - 1) {

            //For show concept jumping pop up
            if (this.params.nodes[index].conceptKey != this.params.nodes[index + 1].conceptKey) {
                this.setState({ showNewConceptModal: true, newConceptTitle: this.params.nodes[index + 1].conceptTitle })
            }

            this.params.status = '';
            this.params.nodeId = currentConceptContinue ? nodes[index].id : nodes[index + 1].id;
            this.reset();
            // this.getAssessmentContent(false, (err, data) => {

            //     if (err) {
            //         //alert('get content error');
            //         return;
            //     }
            //     this.setQuestion(data);
            // });

            this.getLearningContent(null, (err, data) => {

                if (err) {
                    return;
                }

                this.contentListData = data

                if (!data.isNodeActivityExist)
                    this.setState({ showContentList: true, isNodeActivityExist: false })
                else {
                    this.getAssessmentContent(false, (err, data) => {

                        if (err) {
                            //alert('get content is error');
                            return;
                        }
                        this.setQuestion(data, true);
                    });
                }
            });
            this.hideSuccessFailureScreen();
        }
        else {
            // alert('end')
            this.goBackToEloListScreen()
            this.params.nodeId = null;
            this.params.status = null;
        }
    }

    setQuestion = (data, isResume) => {

        this.totalElos = data.totalElos;
        this.eloPosition = data.eloPosition;

        if (data.isReassessment)
            this.isReassessment = true;
        else
            this.isReassessment = false;

        this.setState({ questions: data.content, index: 0 });

        if (data.resume && data.resume.contentId) {
            let resumeIndex = 0;

            for (let i = 0; i < data.content.length; i++) {
                if (data.content[i].id === data.resume.contentId) {
                    resumeIndex = i;
                    break;
                }
            }

            if (data.content[resumeIndex] && data.content[resumeIndex].details && data.content[resumeIndex].details.content && data.content[resumeIndex].details.content.isMultiStep && isResume) {

                let stepIndex = 0;

                if (data.content[resumeIndex].details.content.steps &&
                    data.content[resumeIndex].details.content.steps.length &&
                    data.content[resumeIndex].answer &&
                    data.content[resumeIndex].answer.steps.length) {
                    stepIndex = data.content[resumeIndex].details.content.steps.length <= data.content[resumeIndex].answer.steps.length ? data.content[resumeIndex].answer.steps.length - 1 : data.content[resumeIndex].answer.steps.length
                }

                this.setState({ index: resumeIndex, stepIndex });

            } else {

                this.setState({ index: resumeIndex });
            }
        }
    }

    reset = () => {
        this.setState({
            questions: [],
            answers: [],
            learningElos: [],
            index: 0
        });
    }

    /**
     * Delegate from Question Component
     */
    enableNavigation(flag) {
        console.log("enableNavigation ", flag);
    }

    disableNavigation(flag) {
        console.log("disableNavigation ", flag);
    }


    nextOld() {

        let answer = {};

        if (!this.solvedExamplesShowed)
            answer = this.questionComponent && this.questionComponent.getAnswer();

        let question = this.state.questions[this.state.index].details;

        if (answer != null) {

            if (this.isReassessment && !this.hintShowed && ((!question.content.isMultiStep && question.hint) || (question.content.isMultiStep && question.content.steps[this.state.stepIndex].hint))) {

                let ques = question;
                let ans = answer;

                if (question.content.isMultiStep) {
                    ques = question.content.steps[this.state.stepIndex];
                    ans = answer.steps[this.state.stepIndex];
                }

                this.validateAnswer(ques, ans, (err, res) => {

                    if (err) {
                        //alert('Answer validation failed');
                        return;
                    }
                    if (res.isCorrect)
                        this.nextContinue(question, answer);
                    else {
                        this.hintShowed = true;
                        this.showHint();
                    }
                });
            }

            else if (this.isReassessment && this.hintShowed && !this.solvedExamplesShowed) {

                let ques = question;
                let ans = answer;

                if (question.content.isMultiStep) {
                    ques = question.content.steps[this.state.stepIndex];
                    ans = answer.steps[this.state.stepIndex];
                }

                this.validateAnswer(ques, ans, (err, res) => {

                    if (err) {
                        //alert('Answer validation failed');
                        return;
                    }
                    if (res.isCorrect) {
                        this.resetHint();
                        this.nextContinue(question, answer);
                    }
                    else {
                        this.solvedExamplesShowed = true;
                        this.showSolvedExamples();
                        this.updateAnswerInAssessmentSolutionStep(question, answer);
                    }
                });

            }
            else if (this.isReassessment && this.hintShowed && this.solvedExamplesShowed) {
                this.resetHint();
                this.nextContinueForSolutionSteps(question, true);
            }
            else {

                this.resetHint();

                if (question.content.isMultiStep) {

                    let ques = question.content.steps[this.state.stepIndex];
                    let ans = answer.steps[this.state.stepIndex];

                    this.validateAnswer(ques, ans, (err, res) => {

                        if (err) {
                            //alert('Answer validation failed');
                            return;
                        }
                        if (res.isCorrect)
                            this.nextContinue(question, answer, false);
                        else
                            this.nextContinue(question, answer, true);
                    });

                } else {
                    this.nextContinue(question, answer);
                }

            }

        } else {
            this.toggleInvalidAnswerModal(true);
        }
    }


    next() {

        //Assesment flow and Reassesment flow
        //Assesment flow 
        // Validate the incoming answer if wrong show solution summary as message: show correct answer and next button
        // if correct show solution summary and next button
        //Reassesment flow
        // Validate the question if worng show hint message and try again button 
        // If wrong again show empty error message and view solution step button
        // if right move to next question

        let answer = {};
        this.answer = {}

        if (!this.solvedExamplesShowed) {
            answer = this.questionComponent && this.questionComponent.getAnswer();
            this.answer = answer; // Add for correct Correct Incorrect Next Flow
        }

        let question = this.state.questions[this.state.index].details;
        this.question = question;// Add for correct Correct Incorrect Next Flow


        if (answer != null) {

            if (this.isReassessment && !this.hintShowed && ((!question.content.isMultiStep && question.hint) || (question.content.isMultiStep && question.content.steps[this.state.stepIndex].hint))) {

                let ques = question;
                let ans = answer;

                if (question.content.isMultiStep) {
                    ques = question.content.steps[this.state.stepIndex];
                    ans = answer.steps[this.state.stepIndex];
                }

                this.setState({ disableNext: true })

                this.validateAnswer(ques, ans, (err, res) => {

                    if (err) {
                        //alert('Answer validation failed');
                        this.setState({ disableNext: false })
                        return;
                    }
                    this.setState({ disableNext: false })

                    if (res.isCorrect) {

                        if (!question.content.isMultiStep) {
                            this.ShowCorrectIncorrectModel(true);
                        }

                        else if (question.content.isMultiStep && this.state.stepIndex === question.content.steps.length - 1) {
                            this.ShowCorrectIncorrectModel(true);

                        }

                        else {
                            this.nextContinue(question, answer, false);
                        }
                    }

                    else {
                        this.hintShowed = true;
                        this.showHint();
                    }
                });
            }

            else if (this.isReassessment && this.hintShowed && !this.solvedExamplesShowed) {

                let ques = question;
                let ans = answer;

                if (question.content.isMultiStep) {
                    ques = question.content.steps[this.state.stepIndex];
                    ans = answer.steps[this.state.stepIndex];
                }

                this.setState({ disableNext: true })

                this.validateAnswer(ques, ans, (err, res) => {

                    if (err) {
                        //alert('Answer validation failed');
                        this.setState({ disableNext: false })
                        return;
                    }
                    this.setState({ disableNext: false })
                    if (res.isCorrect) {
                        this.resetHint();

                        if (!question.content.isMultiStep) {
                            this.ShowCorrectIncorrectModel(true);
                        }

                        else if (question.content.isMultiStep && this.state.stepIndex === question.content.steps.length - 1) {
                            this.ShowCorrectIncorrectModel(true);

                        }

                        else {
                            this.nextContinue(question, answer, false);
                        }
                    }
                    else {
                        this.solvedExamplesShowed = true;
                        this.showSolvedExamples();
                        this.updateAnswerInAssessmentSolutionStep(question, answer);
                    }
                });

            }
            else if (this.isReassessment && this.hintShowed && this.solvedExamplesShowed) {
                this.resetHint();
                this.nextContinueForSolutionSteps(question, true);
            }
            else {

                this.resetHint();

                if (question.content.isMultiStep) {

                    let ques = question.content.steps[this.state.stepIndex];
                    let ans = answer.steps[this.state.stepIndex];

                    this.setState({ disableNext: true })
                    this.validateAnswer(ques, ans, (err, res) => {

                        if (err) {
                            //alert('Answer validation failed');
                            this.setState({ disableNext: false })
                            return;
                        }
                        this.setState({ disableNext: false })
                        if (res.isCorrect) {

                            if (!question.content.isMultiStep) {
                                this.ShowCorrectIncorrectModel(true);
                            }

                            else if (question.content.isMultiStep && this.state.stepIndex === question.content.steps.length - 1) {
                                this.ShowCorrectIncorrectModel(true);

                            }

                            else {
                                this.nextContinue(question, answer, false);
                            }
                            //this.nextContinue(question, answer, false);
                        }
                        else {
                            this.ShowCorrectIncorrectModel(false);
                            //this.nextContinue(question, answer, true);
                        }
                    });

                } else {

                    let ques = question;
                    let ans = answer;

                    this.setState({ disableNext: true })
                    this.validateAnswer(ques, ans, (err, res) => {

                        if (err) {
                            this.setState({ disableNext: false })
                            return;
                        }
                        this.setState({ disableNext: false })
                        if (res.isCorrect)
                            this.ShowCorrectIncorrectModel(true);
                        else
                            this.ShowCorrectIncorrectModel(false);
                    });
                }

            }

        } else {
            this.toggleInvalidAnswerModal(true);
        }
    }

    nextQuestionOnCorrectInCorrectAnswer() {

        let question = this.question;
        let answer = this.answer;

        if (question.content.isMultiStep) {
            if (this.isCorrect)
                this.nextContinue(question, answer, false);
            else
                this.nextContinue(question, answer, true);

        } else {
            this.nextContinue(this.question, this.answer);
        }

        this.setState({ showCorrectIncorrectModal: false, isReadOnly: false });
    }



    nextContinue = (question, answer, branchQuesExit) => {

        if (this.state.questions.length > this.state.index) {
            this.state.questions[this.state.index].answer = answer;
        }

        if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex && !branchQuesExit) { /** If question is multi step  */
            this.setState({ stepIndex: this.state.stepIndex + 1 });

            this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index], answer, null, (err, res) => {
                if (err) {
                    //alert('user activity update error');
                }
            });

        } else { /** If it normal question */

            if (this.state.stepIndex)
                this.resetStepIndex();

            if (this.state.questions.length > this.state.index + 1) {

                if (this.state.questions[this.state.index] && answer) {
                    if (this.state.questions[this.state.index].id != answer.id) {
                        return
                    }
                }

                this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index + 1], answer, null, (err, res) => {
                    if (err) {
                        //alert('user activity update error');
                    }
                });

                this.setState({ index: this.state.index + 1 });

            }
            else { /** If end of the elo reached */

                if (this.state.questions[this.state.index] && answer) {
                    if (this.state.questions[this.state.index].id != answer.id) {
                        return
                    }
                }

                this.updateUserActivity(this.state.questions[this.state.index], null, answer, null, (err, res) => {

                    if (err) {
                        //alert('user activity update error');
                        return;
                    }

                    if (this.totalElos !== this.eloPosition) {
                        this.setState({ disableNext: true })
                        this.getAssessmentContent(true, (err, data) => {

                            if (err) {
                                //alert('get Content error');
                                this.setState({ disableNext: false })
                                return;
                            }

                            this.totalElos = data.totalElos;
                            this.eloPosition = data.eloPosition;

                            this.setState({
                                disableNext: false,
                                questions: this.state.questions.concat(data.content),
                                index: this.state.index + 1,
                            });
                        });
                    } else { /** Reached end of the node */

                        this.setState({ disableNext: true })
                        this.assessNode((err, res) => {

                            if (err) {
                                // alert('Node Assess Error');
                                this.setState({ disableNext: false })
                                return;
                            }

                            if (res.isVertical) {
                                this.params.status = Constants.TRY_AGIN_IN_PROGRESS;
                                this.getAssessmentContent(true, (err, data) => {

                                    if (err) {
                                        this.setState({ disableNext: false })
                                        return;
                                    }

                                    if (data.contentType == "LEARNING") {

                                        let elos = data.elos.map(elo => elo.id);

                                        this.getLearningContent(elos);
                                    } else {

                                        this.totalElos = data.totalElos;
                                        this.eloPosition = data.eloPosition;

                                        this.setState({
                                            disableNext: false,
                                            questions: data.content,
                                            index: 0
                                        });
                                    }
                                });
                            } else {
                                this.showSuccessFailureScreen(res);
                            }
                        });
                    }
                });
            }
        }
    }

    assessNode(callback) {

        let payload = {
            nodeId: this.params.nodeId,
            // workflowId: this.params.workoutId,
            // isWorkout: true
        }

        if (!this.params.isSubscribedFlow) {
            payload['workflowId'] = this.params.workoutId
            payload['isWorkout'] = true
        }


        if (this.params.isChapterNotCompleted)
            payload.isChapterNotCompleted = true;

        // Apis.assessNodeRefresherCourse(payload, (err, res) => {

        //     if (callback)
        //         callback(err, res);

        // });

    }

    updateUserActivity(presentContent, resumeContent, answer, isReEnforcementCompleted, callback) {

        let payload = {
            nodeId: this.params.nodeId,
        };

        if (!this.params.isSubscribedFlow) {
            payload['workflowId'] = this.params.workoutId
            payload['isWorkout'] = true
        }


        if (presentContent)
            payload.eloActivity = { contentId: presentContent.id, answer: answer, eloId: presentContent.eloId }

        if (resumeContent)
            payload.resume = { contentId: resumeContent.id, eloId: resumeContent.eloId };


        if (isReEnforcementCompleted)
            payload.isReEnforcementCompleted = true;


        RefresherCourseApi.updateUserActivityRefresherCourse(payload).then((res) => {

            if (callback)
                callback(null, res);

        }).catch(err => {
            if (callback)
                callback(err, null);
        })

    }

    updateAnswerInAssessmentSolutionStep(question, answer) {

        if (this.state.questions.length > this.state.index) {
            this.state.questions[this.state.index].answer = answer;
        }

        if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex) { /** If question is multi step  */

            this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index], answer, null, (err, res) => {
                if (err) {
                    //alert('user activity update error');
                }
            });

        } else { /** If it normal question */

            if (this.state.questions[this.state.index] && answer) {
                if (this.state.questions[this.state.index].id != answer.id) {
                    return
                }
            }

            if (this.state.questions.length > this.state.index + 1) {

                this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index + 1], answer, null, (err, res) => {
                    if (err) {
                        //alert('user activity update error');
                    }
                });
            }
            else { /** If end of the elo reached */

                this.updateUserActivity(this.state.questions[this.state.index], null, answer, null, (err, res) => {

                    if (err) {
                        //alert('user activity update error');
                        return;
                    }
                });
            }
        }

    }

    nextContinueForSolutionSteps(question, branchQuesExit) {

        if (question.content.isMultiStep && question.content.steps.length - 1 > this.state.stepIndex && !branchQuesExit) { /** If question is multi step  */
            this.setState({ stepIndex: this.state.stepIndex + 1 });
        } else { /** If it normal question */

            if (this.state.stepIndex)
                this.resetStepIndex();

            if (this.state.questions.length > this.state.index + 1) {

                this.setState({ index: this.state.index + 1 });

            }
            else { /** If end of the elo reached */

                if (this.totalElos !== this.eloPosition) {
                    this.setState({ disableNext: true })
                    this.getAssessmentContent(true, (err, data) => {

                        if (err) {
                            //alert('get Content error');
                            this.setState({ disableNext: false })
                            return;
                        }

                        this.totalElos = data.totalElos;
                        this.eloPosition = data.eloPosition;

                        this.setState({
                            questions: this.state.questions.concat(data.content),
                            index: this.state.index + 1,
                            disableNext: false
                        });
                    });
                } else { /** Reached end of the node */

                    this.setState({ disableNext: true })
                    this.assessNode((err, res) => {

                        if (err) {
                            //alert('Node Assess Error');
                            this.setState({ disableNext: false })
                            return;
                        }

                        if (res.isVertical) {
                            this.params.status = Constants.TRY_AGIN_IN_PROGRESS;
                            this.getAssessmentContent(true, (err, data) => {

                                if (err) {
                                    this.setState({ disableNext: false })
                                    return
                                }

                                if (data.contentType == "LEARNING") {

                                    let elos = data.elos.map(elo => elo.id);

                                    this.getLearningContent(elos);
                                } else {

                                    this.totalElos = data.totalElos;
                                    this.eloPosition = data.eloPosition;

                                    this.setState({
                                        disableNext: false,
                                        questions: this.state.questions.concat(data.content),
                                        index: this.state.index + 1
                                    });
                                }
                            });
                        } else {
                            this.showSuccessFailureScreen(res);
                        }
                    });
                }

            }
        }
    }

    showSuccessFailureScreen = (data) => {

        console.log('success', data)

        this.setState({ disableNext: false })

        let payload = {
            workflowId: this.params.workoutId,
            workoutType: this.params.isSubscribedFlow ? 'SUBSCRIBED' : 'TRAIL',
            isRefresherCourse: true
        };

        WorkoutApi.getWorkoutProgress(payload).catch((res) => {

            console.log(res)

            this.progress = res;
            // this.successFailureScreenData = data;
            // this.successFailureScreenData.upComing = [];
            // if (data.isContinue) {
            //     let { node, isEnd } = this.getNextNode();
            //     if (!isEnd) { // CONDITION TO IDENTIFY END OF CHAPTER
            //         this.successFailureScreenData.upComing = node;
            //         this.successFailureScreenData.isContinue = true;
            //     } else {
            //         this.successFailureScreenData.upComing = [];
            //         this.successFailureScreenData.isEnd = true;
            //     }
            // }

            // this.successFailureScreenData.chapterKey = this.params.chapterKey;
            // this.successFailureScreenData.chapterTitle = this.params.title;
            // this.successFailureScreenData.isChapterNotCompleted = this.params.isChapterNotCompleted;

            // this.successFailureScreenData.nodeId = this.params.nodeId;
            // this.successFailureScreenData.isTrailWorkout = this.params.isSubscribedFlow ? false : true

            // this.successFailureScreenData.isTimeOut = Constants.WORKOUT_TIME > res.timeConsumed ? false : true
            // this.successFailureScreenData.totalDuration = this.params.totalDuration
            // this.successFailureScreenData.timeConsumed = res.timeConsumed ? res.timeConsumed : 0

            this.setState({ showSuccessFailureScreen: true }, () => {

                if (this.threshold && this.threshold.value <= this.progress.refresherCompletedProgress && !this.userInfo.isScollarShipEligible) {
                    this.updateStudentScollarShipEligible()
                }

                if (this.params && this.params.from != Constants.REWARD_EVENT_BASELINE && this.params.eventThreshold && this.params.eventThreshold <= this.progress.refresherCompletedProgress && !this.userInfo.isEventScholarshipEligible && this.params.eventId) {

                    let payload = {
                        type: Constants.SCHOLARSHIP_EVENT,
                        eventId: this.params.eventId
                    }

                    this.updateStudentScollarShipEligible(payload)
                }

                if (this.params && this.params.from == Constants.REWARD_EVENT_BASELINE && this.params.eventThreshold <= this.progress.refresherCompletedProgress && !this.userInfo.isRewardEventEligible && this.params.eventId && !this.params.isDeadLineOver) {
                    let payload = {
                        type: Constants.REWARD_EVENT,
                        eventId: this.params.eventId
                    }

                    this.updateStudentScollarShipEligible(payload)
                }

                if (this.params && this.params.from == Constants.TWO_STEP_EVENT_BASELINE && this.params.eventThreshold <= this.progress.refresherCompletedProgress && (!this.userInfo.twoStepEvent || (this.userInfo.twoStepEvent && !this.userInfo.twoStepEvent[this.params.eventId])) && this.params.eventId && !this.params.isDeadLineOver) {
                    let payload = {
                        type: Constants.TWO_STEP_EVENT,
                        eventId: this.params.eventId
                    }

                    this.updateStudentScollarShipEligible(payload)
                }


                if (this.params && this.params.isMplWorkout && this.params.mplEventThreshold && this.params.mplEventThreshold <= this.progress.refresherCompletedProgress) {
                    this.showMplQualificationPopup()
                }

            }).catch(err => {
                if (err)
                    return;
            })

        })

    }

    showMplQualificationPopup = () => {

        let today = new Date()

        if ((!this.userInfo.event || !this.userInfo.event[`${this.params.eventId}`] || !this.userInfo.event[`${this.params.eventId}`][`${this.params.qualifierEventIndex + 1}`] || (this.userInfo.event[`${this.params.eventId}`][`${this.params.qualifierEventIndex + 1}`].class != this.userInfo.class)) && (today <= new Date(this.params.event.endDate))) {
            if (this.userInfo.event[`${this.params.eventId}`]) {
                this.userInfo.event[`${this.params.eventId}`][`${this.params.qualifierEventIndex + 1}`] = { eligible: true, class: this.userInfo.class }
            } else if (!this.userInfo.event[`${this.params.eventId}`]) {
                this.userInfo.event = { [this.params.eventId]: { [this.params.qualifierEventIndex + 1]: { eligible: true, class: this.userInfo.class } } }
            }

            let congratulationMessage = this.params && this.params.event && this.params.event.staticText && this.params.event.staticText.eventQualifiedText ? this.params.event.staticText.eventQualifiedText : ""

            this.setState({ showMplQualificationPopup: true, congratulationMessage: congratulationMessage })
        }

    }

    hideMplQualificationPopup = () => {
        this.setState({ showMplQualificationPopup: false })
    }

    getCurrentNode = () => {
        let nodes = this.params.nodes;
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id == this.params.nodeId) {
                return nodes[i];
            }
        }
    }


    processDataToSetNodeEloStatus(data) {
        let currrentNodeId = this.params.nodeId;
        let currentConcept = this.currentConcept;
        let levels = currentConcept.levels;

        let nodeStatus = data.notCleared && data.notCleared.length ? Constants.ELO_STATUS_NOT_CLEARED : Constants.ELO_STATUS_CLEARED
        let clearedElos = data.cleared && data.cleared.length ? data.cleared.map((elo) => elo.id) : [];
        let notClearedElos = data.notCleared && data.notCleared.length ? data.notCleared.map((elo) => elo.id) : [];

        for (let i = 0; i < levels.length; i++) {
            let nodes = levels[i].nodes;
            for (let j = 0; j < nodes.length; j++) {
                //Find the active node and level
                let node = nodes[j]
                if (node.id == currrentNodeId) {
                    node.status = nodeStatus;
                    let elos = node.elos;
                    for (let k = 0; k < elos.length; k++) {
                        let elo = elos[k];
                        if (clearedElos.includes(elo.id)) {
                            elo.status = Constants.ELO_STATUS_CLEARED
                        } else if (notClearedElos.includes(elo.id)) {
                            elo.status = Constants.ELO_STATUS_NOT_CLEARED
                        }
                    }
                }
            }
        }
        console.log(currentConcept);
        return this.currentConcept;
    }


    hideSuccessFailureScreen = () => {
        this.setState({ showSuccessFailureScreen: false });
    }

    getNextNode = () => {

        let index = -1;

        let nodes = this.params.nodes;

        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id == this.params.nodeId) {
                index = i;
                break;
            }
        }

        //Idenfify End of chapter
        if (index == nodes.length - 1) {
            return { node: [], isEnd: true };
        }

        if (index != -1 && index < nodes.length - 1) {
            if (this.params.nodes[index + 1].elos.length &&
                this.params.nodes[index + 1].status == Constants.ELO_STATUS_LOCKED) {
                return { node: this.params.nodes[index + 1].elos, isEnd: false };
            } else {
                return { node: [], isEnd: false };
            }
        } else
            return { node: [], isEnd: false };
    }

    previous() {

        let question = this.state.questions[this.state.index].details;

        let answer = this.questionComponent && this.questionComponent.getAnswer();

        if (question.content.isMultiStep && this.state.stepIndex !== 0) {

            this.setState({ stepIndex: this.state.stepIndex - 1 });

            this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index], answer, null, (err, res) => {
                if (err) {
                    //alert('user activity update error');
                }
            });
        } else {

            if (this.state.index != 0) {
                this.setState({
                    index: this.state.index - 1,
                });

                if (!answer)
                    return;

                this.updateUserActivity(this.state.questions[this.state.index], this.state.questions[this.state.index - 1], answer, null, (err, res) => {
                    if (err) {
                        //alert('user activity update error');
                    }
                });
            }

        }


    }

    bookmark(event) {
        //alert("bookmark")
    }

    save(event) {
        //alert("save")
    }

    /** End of the learning content from revise flow */
    learningElosCompleted = (isRevise) => {

        if (isRevise) {
            if (this.params && this.params.isRefresherCourse) {
                // this.props.navigation.goBack();
                this.hideRefresherCourseExperience(true)
                return
            }

            if (this.params && this.params.isMplWorkout) {
                this.props.navigation.navigate('MPLWorkoutScreen', { meta: this.params.mplWorkoutMeta });
                return
            }
            this.props.navigation.navigate('WorkoutListScreen');
        }
        else {
            this.updateUserActivity(null, null, null, true, (err, res) => {
                if (err) {
                    //alert('Update user activity error');
                }

                this.reset();
                this.setState({ loading: true })
                this.params.status = Constants.TRY_AGIN_IN_PROGRESS; // Setting this because change in success screen flow
                this.getAssessmentContent(false, (err, data) => {

                    if (err) {
                        //alert('get content is error');
                        return;
                    }

                    this.moveToNextWorkflowNodeForAssessment()
                    // this.setQuestion(data);
                });
            });
        }
    }

    resetHint = () => {
        if (this.hintShowed)
            this.hintShowed = false;

        if (this.solvedExamplesShowed)
            this.solvedExamplesShowed = false;
    }

    resetStepIndex = () => {
        this.setState({ stepIndex: 0 });
    }

    validateAnswer = (question, answer, callback) => {

        let payload = { question, answer };

        // Apis.validateAnswer(payload, (err, res) => {
        //     callback(err, res);
        // });
    }

    showHint = () => {
        this.setState({ showHint: true });
    }

    hideHint = () => {
        this.setState({ showHint: false });
    }

    hintTryAgain = () => {
        let question = this.state.questions[this.state.index];
        if (question.details.content.isMultiStep && question.answer) {
            question.answer.steps[this.state.stepIndex] = null;
        } else {
            if (question.answer)
                question.answer = null;
        }
        this.questionComponent.resetAns();
        this.hideHint();
    }

    goBackToEloListScreen = () => {

        // if (this.landingScreenInstance && this.params && (this.params.from == constants.TWO_STEP_EVENT || this.params.from == constants.TWO_STEP_EVENT_BASELINE))
        //     this.landingScreenInstance.resumeUserAppTime()

        if (this.params && this.params.isRefresherCourse) {
            // this.props.navigation.goBack();
            this.hideRefresherCourseExperience()
            return
        }

        if (this.params && this.params.isMplWorkout) {
            this.props.navigation.navigate('MPLWorkoutScreen', { meta: this.params.mplWorkoutMeta });
            return
        }
        this.props.navigation.navigate('LandingScreen');
    }

    goToChapterList = () => {
        this.props.navigation.navigate('Chapters')
    }

    goToLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    showSolvedExamples = () => {
        this.setState({ showSolvedExamplesModal: true });
    }

    hideSolvedExamples = () => {
        this.setState({ showSolvedExamplesModal: false });
    }

    toggleInvalidAnswerModal = (value) => {
        this.setState({ showInvalidAnswerModal: value });
    }

    ShowCorrectIncorrectModel = (isCorrect) => {
        this.isCorrect = isCorrect;
        this.setState({ showCorrectIncorrectModal: true });
    }

    hideCorrectIncorrectModal() {
        this.setState({ showCorrectIncorrectModal: false });
    }

    onOk = () => {
        this.toggleInvalidAnswerModal(false);
    }

    showNewConceptModal = () => {
        this.setState({ showNewConceptModal: true })
    }

    hideNewConceptModal = () => {
        this.setState({ showNewConceptModal: false })
    }

    showContentList = () => {
        this.setState({ showContentList: true })
    }

    hideContentList = (eloTitle) => {
        this.setState({ showContentList: false })

        if (!this.state.isNodeActivityExist) {

            this.getAssessmentContent(false, (err, data) => {

                if (err) {
                    //alert('get content is error');
                    return;
                }
                this.setQuestion(data, true);
            }, true, eloTitle);
        }

    }

    handleBackButtonClick = () => {

        // if (this.landingScreenInstance && this.params && (this.params.from == constants.TWO_STEP_EVENT || this.params.from == constants.TWO_STEP_EVENT_BASELINE))
        // this.landingScreenInstance.resumeUserAppTime()

        this.props.navigation.navigate('LandingScreen');
        return true
    }

    onPressContinue = () => {

        if (this.params.isMplWorkout) {
            this.setState({ showMplQualificationPopup: false })
        } else {
            this.setState({ showScollarEligiblePopUp: false })

        }
    }

    showScollarEligiblePopUp = () => {
        // this.props.navigation.navigate('EventQualifiedScreen',{meta:{eventPayload : this.params.eventPayload}})
        this.setState({ showScollarEligiblePopUp: true })
        // moengage.logCustomEvent('Event', { 'User Id': this.userInfo.userId, 'Event Name': this.params && this.params.eventPayload && this.params.eventPayload.title, 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Qualified', 'Learning Status': '100% Completed', 'Viewed Baseline Report': CommonUtils.getBooleanValue(!this.params.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.params.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.params.eventPayload.viewedCertificate) })
        FirebaseEvent('Event', { 'User Id': this.userInfo.userId, 'Event Name': this.params && this.params.eventPayload && this.params.eventPayload.title, 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Qualified', 'Learning Status': '100% Completed', 'Viewed Baseline Report': CommonUtils.getBooleanValue(!this.params.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': CommonUtils.getBooleanValue(!this.params.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': CommonUtils.getBooleanValue(!this.params.eventPayload.viewedCertificate) })
        // Facebook.logCustomEvent('Event', { 'User Id': this.userInfo.userId, 'Event Name': this.params && this.params.eventPayload && this.params.eventPayload.title, 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Qualified', 'Learning Status': '100% Completed', 'Viewed Baseline Report': getBooleanValue(!this.params.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.params.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.params.eventPayload.viewedCertificate) })
        // BranchIO.logCustomEvent('Event', { 'User Id': this.userInfo.userId, 'Event Name': this.params && this.params.eventPayload && this.params.eventPayload.title, 'Assessment Baseline Status': 'Completed', 'Assessment Finishline Status': 'Qualified', 'Learning Status': '100% Completed', 'Viewed Baseline Report': getBooleanValue(!this.params.eventPayload.viewedBaselineReport), 'Viewed Finishline Report': getBooleanValue(!this.params.eventPayload.viewedFinishlineReport), 'Viewed Event Certificate': getBooleanValue(!this.params.eventPayload.viewedCertificate) })

        return
    }

    getScollarEligibilityThreshold = () => {

        CommonApi.getMiscellaneousList({ type: 'SCOLLARSHIP_ELIGIBILITY' }).then((err, data) => {

            if (data && data.response && data.response.length)
                this.threshold = data.response[0].threshold

        }).catch(err => {
            if (err) {
                return
            }
        })
    }

    updateStudentScollarShipEligible = (payload = {}) => {
        EventApi.updateStudentScollarShipEligible(payload).then((data) => {
            this.showScollarEligiblePopUp()
            this.userInfo = { ...this.userInfo, isScollarShipEligible: true }

            if (payload.type == Constants.SCHOLARSHIP_EVENT) {
                this.userInfo['isEventScholarshipEligible'] = true
            }

            if (payload.type == Constants.REWARD_EVENT) {
                this.userInfo['isRewardEventEligible'] = true
            }

            if (payload.type == Constants.TWO_STEP_EVENT) {

                if (this.userInfo["twoStepEvent"]) {
                    this.userInfo['twoStepEvent'][payload.eventId] = true
                } else {
                    this.userInfo['twoStepEvent'] = {
                        [payload.eventId]: true
                    }
                }
                // this.userInfo['isTwoStepEventEligible'] = true
            }

            SharedProfile.setProfile(this.userInfo);
        }).catch(err => {
            if (err)
                return
        })
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.close && this.props.delegate.close()
    }

    hideRefresherCourseExperience(isRevise) {
        this.props.delegate && this.props.delegate.hideRefresherCourseExperience && this.props.delegate.hideRefresherCourseExperience(isRevise)
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2 mb-2'>
                <span className="title-1 cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1 cursor-pointer' onClick={() => this.hideRefresherCourseExperience()} >{`Bridge Course >`}</span>
                <span className='pl-2 title-1 cursor-pointer' onClick={() => this.hideRefresherCourseExperience()}>{`${this.params.title} >`}</span>
                <span className='pl-2 title-1'>{`${this.state.learningElos && this.state.learningElos.length ? this.state.learningElos[0].eloTitle : ""}`}</span>
            </div>
        )
    }



    render() {

        if (this.state.showContentList) {
            return <ContentListScreen delegate={this}
                data={this.contentListData}
                chapterTitle={this.params.title}
                totalDuration={this.params.totalDuration}
                isChapterNotCompleted={this.params.isChapterNotCompleted} />
        }

        return (
            <div className='refresher-course-experience'>
                {!this.state.loading && this.renderBreadcrumbForChapterList()}
                {this.state.learningElos.length > 0 &&
                    <LearningContentWrapper
                        elo={this.state.learningElos}
                        delegate={this}
                        isRevise={this.isRevise}
                        title={this.params.title}
                        navigationInfo={this.params}
                        eloIds={this.eloIds}
                        noBlur={this.noBlur}
                        from={this.params && this.params.from}
                        estimatedLearningTime={this.params.totalDuration}
                        navigation={this.props.navigation}
                        isMplWorkout={this.params && this.params.isMplWorkout}
                    >
                    </LearningContentWrapper>}
                {
                    this.state.loading && <Loading />
                }
            </div>
        )

        // return (
        //     <>
        //         <StatusBar hidden={true} />
        //         {!this.state.showSuccessFailureScreen ? <>
        //             {this.state.learningElos.length === 0 && this.state.questions.length > 0 && <View style={Styles.home}>
        //                 <AssessmentHeader title={this.params.title ? this.params.title : ''} categoryThemeId={this.state.questions[this.state.index].categoryThemeId}></AssessmentHeader>
        //                 <View style={Styles.content}>
        //                     {this.state.questions && this.state.questions.length > 0 && <View>
        //                         <RefresherCourseContaxt.Provider value={{ disableNextRefresher: this.state.disableNext }}>
        //                             <Question delegate={this} ref={(ref) => { this.questionComponent = ref }}
        //                                 answer={this.state.questions[this.state.index].answer}
        //                                 content={this.state.questions[this.state.index].details}
        //                                 stepIndex={this.state.stepIndex} showHint={this.state.showHint}
        //                                 showSolvedExamplesModal={this.state.showSolvedExamplesModal}
        //                                 showInvalidAnswerModal={this.state.showInvalidAnswerModal}
        //                                 showCorrectIncorrectModal={this.state.showCorrectIncorrectModal}
        //                                 isAnswerCorrect={this.isCorrect}
        //                                 categoryThemeId={this.state.questions[this.state.index].categoryThemeId}
        //                             ></Question>
        //                         </RefresherCourseContaxt.Provider>
        //                     </View>}
        //                 </View>

        //             </View>}
        //             {this.state.learningElos.length > 0 && <LearningContentWrapper elo={this.state.learningElos} delegate={this} isRevise={this.isRevise} title={this.params.title ? this.params.title : ''} navigationInfo={this.params} eloIds={this.eloIds} from={this.params && this.params.from} estimatedLearningTime={this.params.totalDuration} noBlur={this.noBlur} navigation={this.props.navigation} isMplWorkout={this.params && this.params.isMplWorkout}></LearningContentWrapper>}
        //         </> : <WorkoutSuccessFailureScreen data={this.successFailureScreenData} delegate={this} title={this.params.title ? this.params.title : ''} categoryThemeId={this.params.categoryThemeId ? this.params.categoryThemeId : ""} progress={this.progress} key={this.currentConcept ? this.currentConcept.key : ""} masterDataId={this.params.masterDataId ? this.params.masterDataId : ""} chapterKey={this.params.chapterKey ? this.params.chapterKey : ''} navigation={this.props.navigation} from={this.params ? this.params.from : ''} isRefresherCourse={this.params && this.params.isRefresherCourse} isMplWorkout={this.params && this.params.isMplWorkout} />}
        //         {/* {this.state.showNewConceptModal && <NewConceptModal modal={this.state.showNewConceptModal} close={this.hideNewConceptModal} conceptName={this.state.newConceptTitle} />} */}
        //         {this.state.showScollarEligiblePopUp ? <ScollarShipPopUp modal={true} delegate={this} scholarshipEligibleStaticText={this.params.scholarshipEligibleStaticText} eventPayload={this.params.eventPayload} /> : <></>}
        //         {this.state.showMplQualificationPopup ? <MplEligiblePopUp modal={true} delegate={this} isMplWorkout={true} scholarshipEligibleStaticText={this.state.congratulationMessage} /> : <></>}
        //     </>

        // )
    }
}
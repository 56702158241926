import React from "react";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import './radio-group.scss';
import RadioButton from "./radio-button/radio-button";
import { ImageConstants } from "../../../constants/constants";
import { getImageSource } from "../../../utility/image-source";
import { Constants } from "../../../constants/constants";
import ImagePreview from "../../common/image-preview/image-preview";
export default class RadioGroup extends React.Component {
    constructor(props) {
        super(props);

        let optionImageExist = false;
        this.props.options.forEach(option => {
            if (option.contentType === Constants.CONTENT_TYPE_IMAGE) {
                optionImageExist = true
            }
        })

        this.state.optionImageExist = optionImageExist



    }

    state = { imagePreview: false, previewUrl: '' };
    charCode = 65;

    onOptionPressed(option) {

        //Added for View Correct Answer
        if (this.props.isReadOnly) {
            return;
        }
        this.props.options.map(opt => opt.isChecked = false);
        option.isChecked = true;
        this.props.onOptionPressed && this.props.onOptionPressed(option);
        this.forceUpdate();
    }

    openPreview = (previewUrl) => {
        this.setState({ imagePreview: true, previewUrl: previewUrl })
    }

    closePreview = () => {
        this.setState({ imagePreview: false });
    }

    //Added for View Correct Answer
    getCheckedStatus = (option) => {
        // console.log(option)
        if (this.props.isReadOnly) {
            return option.isCorrect ? true : false
        }
        else {
            return option.isChecked ? true : false
        }
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)



        return (
            <>
                {this.props.isFullScreenMode && !this.props.questionImageExist ? this.state.optionImageExist ?
                    <div className="row option-wrapper-for-clicker-practice ml-3">
                        {this.props.options && this.props.options.length && this.props.options.map((option, index) =>
                            <>
                                {
                                    option.isCorrect && this.props.isReadOnly ?
                                        <div className={`option-border fixed-column-width col-6 ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "col-6 justify-content-between" : this.props.isReadOnly ? "col-6 align-items-center" : ""}`}
                                            style={{ borderColor: this.theme ? `${!this.props.isReadOnly ? this.theme['secondaryColors']['assessmentOptionsBorder'] : "#FFFFFF"}` : '#353535' }}
                                            key={option.id}>
                                            <RadioButton
                                                label={option.content}
                                                isChecked={this.getCheckedStatus(option)}
                                                onOptionPressed={() => this.onOptionPressed(option)}
                                                contentType={option.contentType ? option.contentType : 1}
                                                isCheckPoint={this.props.isCheckPoint}
                                                categoryThemeId={this.props.categoryThemeId}
                                                alphabet={String.fromCharCode(this.charCode + index)}
                                            />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !this.props.isReadOnly ?
                                        <div className={`option-border fixed-column-width col-6 ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "col-6 justify-content-between" : this.props.isReadOnly ? "col-6 align-items-center" : ""}`}
                                            style={{ borderColor: this.theme ? `${!this.props.isReadOnly ? this.theme['secondaryColors']['assessmentOptionsBorder'] : "#FFFFFF"}` : '#353535' }}
                                            key={option.id}>
                                            <RadioButton
                                                label={option.content}
                                                isChecked={this.getCheckedStatus(option)}
                                                onOptionPressed={() => this.onOptionPressed(option)}
                                                contentType={option.contentType ? option.contentType : 1}
                                                isCheckPoint={this.props.isCheckPoint}
                                                categoryThemeId={this.props.categoryThemeId}
                                                alphabet={String.fromCharCode(this.charCode + index)}
                                            />
                                        </div>
                                        :
                                        <></>
                                }
                            </>

                        )}
                        {this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>}
                    </div>
                    :
                    <div className={`d-flex  option-wrapper-for-full-screen-for-practice ${this.props.isReadOnly ? '' : 'flex-column'}`}>
                        {this.props.options && this.props.options.length && this.props.options.map((option, index) =>
                            <>
                                {
                                    option.isCorrect && this.props.isReadOnly ?
                                        <div className={`option-border ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "d-flex justify-content-between" : this.props.isReadOnly ? "d-flex align-items-center" : ""}`}
                                            style={{ borderColor: this.theme ? `${!this.props.isReadOnly ? this.theme['secondaryColors']['assessmentOptionsBorder'] : "#FFFFFF"}` : '#353535' }}
                                            key={option.id}>
                                            <RadioButton
                                                label={option.content}
                                                isReadOnly={this.props.isReadOnly}
                                                isChecked={this.getCheckedStatus(option)}
                                                onOptionPressed={() => this.onOptionPressed(option)}
                                                contentType={option.contentType ? option.contentType : 1}
                                                isCheckPoint={this.props.isCheckPoint}
                                                categoryThemeId={this.props.categoryThemeId}
                                                alphabet={String.fromCharCode(this.charCode + index)}
                                            />

                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !this.props.isReadOnly ?
                                        <div className={`option-border ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "d-flex justify-content-between" : this.props.isReadOnly ? "d-flex align-items-center" : ""}`}
                                            style={{ borderColor: this.theme ? `${!this.props.isReadOnly ? this.theme['secondaryColors']['assessmentOptionsBorder'] : "#FFFFFF"}` : '#353535' }}
                                            key={option.id}>
                                            <RadioButton
                                                label={option.content}
                                                isChecked={this.getCheckedStatus(option)}
                                                onOptionPressed={() => this.onOptionPressed(option)}
                                                contentType={option.contentType ? option.contentType : 1}
                                                isCheckPoint={this.props.isCheckPoint}
                                                categoryThemeId={this.props.categoryThemeId}
                                                alphabet={String.fromCharCode(this.charCode + index)}
                                            />
                                        </div>
                                        :
                                        <></>
                                }
                            </>

                        )}
                        {this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>}
                    </div>
                    :
                    <div className="row option-wrapper-for-clicker-practice">
                        {this.props.options && this.props.options.length && this.props.options.map((option, index) =>
                            <>
                                {
                                    option.isCorrect && this.props.isReadOnly ?
                                        <div className={`option-border col-6 fixed-column-width ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "col-6 justify-content-between" : this.props.isReadOnly ? "col-6 justify-content-between align-items-center" : ""}`}
                                            style={{ borderColor: this.theme ? `${!this.props.isReadOnly ? this.theme['secondaryColors']['assessmentOptionsBorder'] : "#FFFFFF"}` : '#353535' }}
                                            key={option.id}>
                                            <RadioButton
                                                label={option.content}
                                                isChecked={this.getCheckedStatus(option)}
                                                onOptionPressed={() => this.onOptionPressed(option)}
                                                contentType={option.contentType ? option.contentType : 1}
                                                isCheckPoint={this.props.isCheckPoint}
                                                categoryThemeId={this.props.categoryThemeId}
                                                alphabet={String.fromCharCode(this.charCode + index)}
                                            />


                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !this.props.isReadOnly ?
                                        <div className={`option-border col-6 fixed-column-width ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "col-6 justify-content-between" : this.props.isReadOnly ? "col-6 justify-content-between align-items-center" : ""}`}
                                            style={{ borderColor: this.theme ? `${!this.props.isReadOnly ? this.theme['secondaryColors']['assessmentOptionsBorder'] : "#FFFFFF"}` : '#353535' }}
                                            key={option.id}>
                                            <RadioButton
                                                label={option.content}
                                                isChecked={this.getCheckedStatus(option)}
                                                onOptionPressed={() => this.onOptionPressed(option)}
                                                contentType={option.contentType ? option.contentType : 1}
                                                isCheckPoint={this.props.isCheckPoint}
                                                categoryThemeId={this.props.categoryThemeId}
                                                alphabet={String.fromCharCode(this.charCode + index)}
                                            />


                                        </div>
                                        :
                                        <></>
                                }
                            </>
                        )}
                        {this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>}
                    </div>
                }
            </>

        );
    }
}
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import './practice-question-popup.scss';
import CwContentViewer from "../../common/cw-content-viewer/cw-content-viewer";
import QuestionImage from "../../common/cw-question-image/cw-question-image";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedThemeCategory from "../../../../shared-storage/category-theme";

class PracticeQuestionModal extends React.Component {

    state = {
        showAnswer: false,
        showSolution: false,
    }

    componentDidMount() {
        if (this.props.isAnswer) {
            this.viewAnswer(this.props.content.question, this.props.index)
        }
        if (this.props.isSolution) {
            this.viewSolution(this.props.content.question, this.props.index)
        }

    }

    videoPlayerRefs = {};

    viewAnswer = (item, index) => {
        item.showAnswer = true;
        this.setState({ showAnswer: true, showSolution: false })
    }

    viewSolution = (item, index) => {
        item.showSolution = true;
        this.setState({ showAnswer: false, showSolution: true })
    }

    hideQuestionPopup() {
        this.props.hideQuestionPopup()
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        let selectedButtonBg = this.theme[`themeColor`]

        return (
            <Modal className="cw-custom-modal-right" backdrop={false} fade={true} toggle={() => this.hideQuestionPopup()} isOpen={true} size={'lg'}>
                <div className="pt-4 my-auto text-right"><img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.CROSS)} onClick={() => this.hideQuestionPopup()} /></div>
                <div className="abs-image">
                    <img className='header-image' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_POPUP_HEADER, this.props.categoryThemeId)} />
                </div>

                <ModalBody>
                    {/* <div className='d-flex justify-content-between'>
                        <div className='d-inline-flex content-width'>
                            <span className='mr-2 sr-no'>{`${this.props.index + 1}. `}</span>
                            <CwContentViewer content={this.props.content.question.title}></CwContentViewer>
                        </div>
                        <div className='mr-5'><img className='bookmark' src={getImageSource(ImageConstants.BOOKMARK_ICON)}></img></div>
                    </div>

                    {this.props.content.question.images && this.props.content.question.images.length ?
                        <div className="mb-3">
                            <QuestionImage images={this.props.content.question.images} index={this.props.index} />
                        </div>
                        :
                        ''
                    }

                    <img className="question-divider-image-width mb-3 mt-2" src={getImageSource(ImageConstants.QUESTION_DIVIDER)}></img> */}



                    <div className="d-flex row-width justify-content-center align-content-center">
                        {
                            this.state.showAnswer && this.props.content.question.showAnswer ?
                                <div className={`col view-button pt-2 text-center  border-top-right-radius`} 
                                style = {{backgroundColor : `${selectedButtonBg}`, color : '#FFFFFF'}}>{`View Answer`}</div>
                                :
                                <div className="col view-button pt-2 text-center " onClick={() => this.viewAnswer(this.props.content.question, this.props.index)}>
                                    View Answer
                                </div>
                        }
                        {
                            this.state.showSolution && this.props.content.question.showSolution ?
                                <div className="col view-button pt-2 text-center  border-top-left-radius"
                                style = {{backgroundColor : `${selectedButtonBg}`, color : '#FFFFFF'}}>View Solution</div>
                                :
                                <div className="col view-button pt-2 text-center " onClick={() => this.viewSolution(this.props.content.question, this.props.index)}>
                                    View Solution
                                </div>
                        }
                    </div>

                    {this.state.showAnswer && this.props.content.question.showAnswer ?
                        <div className="mt-3 view-answer" style={{backgroundColor : `${this.theme[`learningContentBackground`]}`}}>
                            <CwContentViewer content={this.props.content.question.answer}></CwContentViewer>
                        </div>
                        : <></>
                    }


                    {this.state.showSolution && this.props.content.question.showSolution ?
                        <div className="mt-3 view-solution">
                            <CwContentViewer content={this.props.content.question.solution}></CwContentViewer>
                        </div>
                        : <></>
                    }

                </ModalBody>
            </Modal>
        )
    }

}


export default PracticeQuestionModal;
import React from "react";
import './correct-incorrect-answer-screen.scss';
import SharedStaticText from "../../../../shared-storage/static-text-store";
import { Modal, ModalBody } from "reactstrap";
import { getThemeImageSource, getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import SummarySoultions from "../summary-solution/summary-solution";
import QuestionTitle from "../../common/question/question-title/question-title";
import QuestionImage from "../../common/cw-question-image/cw-question-image";


export default class CorrectIncorrectAnswerScreen extends React.Component {

    correctAnswerStaticText = {};
    inCorrectAnswerStaticText = {};

    state = {
        enableNextButton: false
    }

    constructor(props) {
        super(props);

        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');

        setTimeout(() => {
            this.enableNextButton()
        }, 15000)
    }

    enableNextButton() {
        this.setState({ enableNextButton: true })
    }

    onNext = () => {
        this.props.delegate && this.props.delegate.next && this.props.delegate.next();
    }

    onViewCorrectAnswer = () => {
        this.props.delegate && this.props.delegate.showCorrectAnswer && this.props.delegate.showCorrectAnswer();
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <div className="correct-incorrect-screen" >
                <div className="pt-4 my-auto text-right">
                    {/* <img className="challenge-header" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_HEADER, this.props.categoryThemeId)}></img> */}
                    {/* <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.closeModal()} /> */}
                </div>
                <div>
                    <div className="pratice-container">
                        <div className="d-flex flex-row align-items-center justify-content-center">
                            {
                                this.props.isCorrect ?
                                    <div>
                                        <img className="image-size" src={getImageSource(ImageConstants.CORRECT_ANSWER_TICK_MARK)} />
                                    </div>
                                    :
                                    <div>
                                        <img className="image-size" src={getImageSource(ImageConstants.WRONG_ANSWER_CROSS_MARK)} />
                                    </div>
                            }
                            {
                                this.props.isCorrect ?
                                    <div className="ml-1">
                                        <div className="name-header">{this.correctAnswerStaticText.title}</div>
                                        <div className="sub-title">{this.correctAnswerStaticText.subTitle}</div>
                                    </div>
                                    :
                                    <div className="ml-1">
                                        <div className="name-header">{this.inCorrectAnswerStaticText.title}</div>
                                        <div className="sub-title">{this.inCorrectAnswerStaticText.subTitle}</div>
                                    </div>
                            }

                        </div>
                        <div className="mt-3">
                            {!this.props.isCheckPoint && <QuestionTitle title={this.props.content.content.title} queNo={this.props.quesNo ? this.props.quesNo : null} hideBackground={this.props.isMultiStep ? true : false} categoryThemeId={this.props.categoryThemeId} isAssessment={this.props.isAssessment} delegate={this.props.delegate} />}
                            {!this.props.isCheckPoint && <QuestionImage images={this.props.content.content.images} categoryThemeId={this.props.categoryThemeId} />}
                        </div>
                        <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                            <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-start  mt-5 concepts-covered-wrapper">
                            <div>
                                <SummarySoultions solutionSteps={this.props.content ? this.props.content.content.solutionSteps : ""} isPopup="false" categoryId={this.props.categoryThemeId}></SummarySoultions>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-2">

                            {
                                this.props.isCorrect ?
                                    <div className="assessment-footer">

                                        <div className="d-flex justify-content-center button-wrapper">
                                            {
                                                this.state.enableNextButton ?
                                                    <div className="text-center next-button-2 cursor-pointer mr-5" style={{ background: this.theme['themeColor'] }}
                                                        onClick={this.onNext}>
                                                        {this.correctAnswerStaticText.buttonTitle}</div>
                                                    :
                                                    <div className="text-center next-button-2 text-center" style={{ background: "#d1d3d5", color: this.theme['themeColor'] }}
                                                    >
                                                        {this.correctAnswerStaticText.buttonTitle}</div>



                                            }
                                        </div>
                                        <div> <img className='footer-image-size' src={getThemeImageSource(ImageConstants.ASSESSMENT_FOOTER, this.props.categoryThemeId)} /></div>

                                    </div>

                                    :
                                    <div className="assessment-footer">

                                        <div className="d-flex justify-content-center button-wrapper">
                                            <div className="text-center finish-button cursor-pointer mr-5" style={{ background: this.theme['themeColor'], border: `2px solid ${this.theme['themeColor']}` }}
                                                onClick={this.onViewCorrectAnswer}>{this.inCorrectAnswerStaticText.viewCorrectAnswerButtonTitle}</div>


                                            {
                                                this.state.enableNextButton ?
                                                    <div className="text-center next-button-2 cursor-pointer mr-5" style={{ background: this.theme['themeColor'] }}
                                                        onClick={this.onNext}>
                                                        {this.inCorrectAnswerStaticText.nextButtonTitle}</div>
                                                    :
                                                    <div className="text-center next-button-2 text-center" style={{ background: "#d1d3d5", color: this.theme['themeColor'] }}
                                                    >
                                                        {this.inCorrectAnswerStaticText.nextButtonTitle}</div>



                                            }
                                        </div>
                                        <div> <img className='footer-image-size' src={getThemeImageSource(ImageConstants.ASSESSMENT_FOOTER, this.props.categoryThemeId)} /></div>

                                    </div>
                            }


                        </div>
                        {/* <div className="position">
                            <img className="challenge-footer" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_FOOTER, this.props.categoryThemeId)}></img>
                        </div> */}
                    </div>
                </div>

            </div>
        );
    }
}


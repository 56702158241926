import Moment from 'moment-timezone';

class CommonUtils {

    static simpleTemplateEngine = (tpl, data) => {
        var re = /<%([^%>]+)?%>/g, match;
        while (match = re.exec(tpl)) {
            tpl = tpl.replace(match[0], data[match[1]])
        }
        return tpl;
    }

    static getAlphabetForIndex = (index) => {
        let alphabet = ['a', 'b', 'c', 'd', 'e',
            'f', 'g', 'h', 'i', 'j',
            'k', 'l', 'm', 'n', 'o',
            'p', 'q', 'r', 's', 't',
            'u', 'v', 'w', 'x', 'y',
            'z'];

        return alphabet[index]
    }

    static getDate = (timeStamp, getYear) => {

        let dateFormat = new Date(timeStamp).toDateString();

        let date = dateFormat.split(" ")[2];

        let month = dateFormat.split(" ")[1];

        let year = dateFormat.split(" ")[3];

        if (getYear) {
            return date + " " + month + " " + year;
        } else {
            return date + " " + month;
        }
    }

    static getGUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    static getAlphabet = (index) => {

        let alphabets = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n",
            "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

        return alphabets[index];

    }

    static shuffleArray = (array) => {
        if (!array) {
            return array
        }
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }



    static validateEmail(value) {
        //console.log(value)
        let reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(value) === false) {
            return false
        } else {
            return true
        }
    }

    static isValidNumber = (text) => {

        if (!text)
            return true

        let numbers = /^[0-9]+$/;

        if (!text.match(numbers)) {
            return false
        } else {
            return true
        }

    }

    static isAllowMobileNumber = (value) => {
        let mobileNumberRexp = /^[0-9\b]+$/
        return !!mobileNumberRexp.test(value)
    }

    static getThemeBasedButtonBorder(borderColor) {
        return `2px solid ${borderColor}`
    }

    static isNumeric(str) {
        return !isNaN(str);
    }

    static getDeviceInformation = () => {

        let deviceInfo = {};

        let navigator = window.navigator

        deviceInfo.name = navigator.userAgentData?.platform;
        deviceInfo.model = navigator.userAgentData
        deviceInfo.type = navigator.userAgentData
        deviceInfo.manufacturer = navigator.userAgentData
        deviceInfo.os = navigator.userAgentData?.platform;
        deviceInfo.osVersion = navigator.userAgent;

        //console.log(deviceInfo)

        return deviceInfo
    }

    static validateMobileNumber(mobileNumber, validationDetails = {}) {

        //console.log(mobileNumber, validationDetails)

        if (!this.isNumeric(mobileNumber)) {
            return false
        }

        var numbers = /^[0-9]+$/;

        if (!mobileNumber.match(numbers)) {
            return false
        }

        let maxLength = validationDetails.length;
        let minLength = validationDetails.minLength;

        if (!maxLength || mobileNumber.length == 0) {
            return true;
        } else if (minLength && mobileNumber.length >= minLength && mobileNumber.length <= maxLength) {
            return true;
        } else if (mobileNumber.length == maxLength) {
            return true;
        } else {
            return false;
        }

    }

    static isAllowMobileNumber(value) {
        let mobileNumberRexp = /^[0-9\b]+$/
        return !!mobileNumberRexp.test(value)
    }

    static getDoubtTime(timeStampInfo) {

        var d = new Date();
        let timeStamp = new Date(timeStampInfo).getTime()
        let tempTime = d.setHours(d.getHours() - 24); /** 24 hours before */

        if (tempTime > timeStamp) {
            let dateFormat = new Date(timeStamp).toDateString();

            let date = dateFormat.split(" ")[2];

            let month = dateFormat.split(" ")[1];

            let year = dateFormat.split(" ")[3];

            let finalDate = date + " " + month + " " + year;

            return finalDate
        } else {

            let date1 = new Date(timeStamp);

            let date2 = new Date();

            var res = Math.abs(date1 - date2) / 1000;

            var days = Math.floor(res / 86400);

            // get hours
            var hours = Math.floor(res / 3600) % 24;

            // get minutes
            var minutes = Math.floor(res / 60) % 60;

            // get seconds
            var seconds = res % 60;

            if (hours)
                return hours + ' Hours ago'

            if (minutes)
                return minutes + ' Mins ago'

            if (seconds)
                return Math.round(seconds) + ' Seconds ago'

        }

    }


    static datePipe(givenDate, isTime = true) {
        givenDate = new Date(givenDate).toString()
        const [day, month, date, year, time] = givenDate.split(' ')
        return `${date} ${month}, ${year} ${isTime ? time.substr(0, 5) : ""}`

    }

    static convertToTitleCase(value) {
        if (!value) return ""
        return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()
    }

    static getMarkByComplexity(complex) {
        if (complex == "Low") return 1
        if (complex == "Medium") return 3
        if (complex == "Hegh") return 5
        if (complex == "High") return 5
    }

    static getPlainTextFromHtmlContent(html) {
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    static cleanHtmlOnPaste(html, isPlain) {

        if (isPlain)
            return this.getPlainTextFromHtmlContent(html)

        //Remove Image
        html = html.replace(/<img[^>]*src="*?"[^>]*>/gi, "");

        //Remove span on paste
        var re = new RegExp("(<span)([^>]*>)(.*?)(<\/span>)", "gi"); // Different because of a IE 5.0 error
        html = html.replace(re, "$3");

        return html;
    }


    static cwMathParsar(event) {

        let html = event.data.dataValue;
        let startIndex = html.indexOf("<cw-math");
        let endIndex = html.indexOf("</cw-math>");
        let mathtag = html.substring(startIndex, endIndex + 10);
        let mathStartIndex = mathtag.indexOf("data=");

        if (mathStartIndex > 0) {

            let ind = mathStartIndex + 6;
            let formula = "";

            for (let i = ind; i < mathtag.length; i++) {

                if (mathtag[i] == '"') break;
                formula += mathtag[i];

            }

            if (formula.includes('&amp;')) {
                formula = formula.replace(/&amp;/gi, '&')
            }

            return '<cw-math data="' + formula + '">' + formula + '</cw-math>'

        } else
            return null
    }
    static getRandomNumber() {
        return new Date().getTime() + ((Math.floor(Math.random() * 10000) + 10000).toString().substring(1));
    }
    static getMilliSeconds() {
        return new Date().getTime() + this.getRandomNumber();
    }
    static convertToFixed(value, decimalValue = 2) {
        if (!value) return 0;
        if (value % 1 !== 0) {
            return value.toFixed(decimalValue);
        }

        return value;
    }

    static decodeHTMLEntities = (text) => {


        const entities = {
            Aacute: 'Á',
            aacute: 'á',
            Abreve: 'Ă',
            abreve: 'ă',
            ac: '∾',
            acd: '∿',
            acE: '∾̳',
            Acirc: 'Â',
            acirc: 'â',
            acute: '´',
            Acy: 'А',
            acy: 'а',
            AElig: 'Æ',
            aelig: 'æ',
            af: '⁡',
            Afr: '𝔄',
            afr: '𝔞',
            Agrave: 'À',
            agrave: 'à',
            alefsym: 'ℵ',
            aleph: 'ℵ',
            Alpha: 'Α',
            alpha: 'α',
            Amacr: 'Ā',
            amacr: 'ā',
            amalg: '⨿',
            amp: '&',
            AMP: '&',
            andand: '⩕',
            And: '⩓',
            and: '∧',
            andd: '⩜',
            andslope: '⩘',
            andv: '⩚',
            ang: '∠',
            ange: '⦤',
            angle: '∠',
            angmsdaa: '⦨',
            angmsdab: '⦩',
            angmsdac: '⦪',
            angmsdad: '⦫',
            angmsdae: '⦬',
            angmsdaf: '⦭',
            angmsdag: '⦮',
            angmsdah: '⦯',
            angmsd: '∡',
            angrt: '∟',
            angrtvb: '⊾',
            angrtvbd: '⦝',
            angsph: '∢',
            angst: 'Å',
            angzarr: '⍼',
            Aogon: 'Ą',
            aogon: 'ą',
            Aopf: '𝔸',
            aopf: '𝕒',
            apacir: '⩯',
            ap: '≈',
            apE: '⩰',
            ape: '≊',
            apid: '≋',
            apos: "'",
            ApplyFunction: '⁡',
            approx: '≈',
            approxeq: '≊',
            Aring: 'Å',
            aring: 'å',
            Ascr: '𝒜',
            ascr: '𝒶',
            Assign: '≔',
            ast: '*',
            asymp: '≈',
            asympeq: '≍',
            Atilde: 'Ã',
            atilde: 'ã',
            Auml: 'Ä',
            auml: 'ä',
            awconint: '∳',
            awint: '⨑',
            backcong: '≌',
            backepsilon: '϶',
            backprime: '‵',
            backsim: '∽',
            backsimeq: '⋍',
            Backslash: '∖',
            Barv: '⫧',
            barvee: '⊽',
            barwed: '⌅',
            Barwed: '⌆',
            barwedge: '⌅',
            bbrk: '⎵',
            bbrktbrk: '⎶',
            bcong: '≌',
            Bcy: 'Б',
            bcy: 'б',
            bdquo: '„',
            becaus: '∵',
            because: '∵',
            Because: '∵',
            bemptyv: '⦰',
            bepsi: '϶',
            bernou: 'ℬ',
            Bernoullis: 'ℬ',
            Beta: 'Β',
            beta: 'β',
            beth: 'ℶ',
            between: '≬',
            Bfr: '𝔅',
            bfr: '𝔟',
            bigcap: '⋂',
            bigcirc: '◯',
            bigcup: '⋃',
            bigodot: '⨀',
            bigoplus: '⨁',
            bigotimes: '⨂',
            bigsqcup: '⨆',
            bigstar: '★',
            bigtriangledown: '▽',
            bigtriangleup: '△',
            biguplus: '⨄',
            bigvee: '⋁',
            bigwedge: '⋀',
            bkarow: '⤍',
            blacklozenge: '⧫',
            blacksquare: '▪',
            blacktriangle: '▴',
            blacktriangledown: '▾',
            blacktriangleleft: '◂',
            blacktriangleright: '▸',
            blank: '␣',
            blk12: '▒',
            blk14: '░',
            blk34: '▓',
            block: '█',
            bne: '=⃥',
            bnequiv: '≡⃥',
            bNot: '⫭',
            bnot: '⌐',
            Bopf: '𝔹',
            bopf: '𝕓',
            bot: '⊥',
            bottom: '⊥',
            bowtie: '⋈',
            boxbox: '⧉',
            boxdl: '┐',
            boxdL: '╕',
            boxDl: '╖',
            boxDL: '╗',
            boxdr: '┌',
            boxdR: '╒',
            boxDr: '╓',
            boxDR: '╔',
            boxh: '─',
            boxH: '═',
            boxhd: '┬',
            boxHd: '╤',
            boxhD: '╥',
            boxHD: '╦',
            boxhu: '┴',
            boxHu: '╧',
            boxhU: '╨',
            boxHU: '╩',
            boxminus: '⊟',
            boxplus: '⊞',
            boxtimes: '⊠',
            boxul: '┘',
            boxuL: '╛',
            boxUl: '╜',
            boxUL: '╝',
            boxur: '└',
            boxuR: '╘',
            boxUr: '╙',
            boxUR: '╚',
            boxv: '│',
            boxV: '║',
            boxvh: '┼',
            boxvH: '╪',
            boxVh: '╫',
            boxVH: '╬',
            boxvl: '┤',
            boxvL: '╡',
            boxVl: '╢',
            boxVL: '╣',
            boxvr: '├',
            boxvR: '╞',
            boxVr: '╟',
            boxVR: '╠',
            bprime: '‵',
            breve: '˘',
            Breve: '˘',
            brvbar: '¦',
            bscr: '𝒷',
            Bscr: 'ℬ',
            bsemi: '⁏',
            bsim: '∽',
            bsime: '⋍',
            bsolb: '⧅',
            bsol: '\\',
            bsolhsub: '⟈',
            bull: '•',
            bullet: '•',
            bump: '≎',
            bumpE: '⪮',
            bumpe: '≏',
            Bumpeq: '≎',
            bumpeq: '≏',
            Cacute: 'Ć',
            cacute: 'ć',
            capand: '⩄',
            capbrcup: '⩉',
            capcap: '⩋',
            cap: '∩',
            Cap: '⋒',
            capcup: '⩇',
            capdot: '⩀',
            CapitalDifferentialD: 'ⅅ',
            caps: '∩︀',
            caret: '⁁',
            caron: 'ˇ',
            Cayleys: 'ℭ',
            ccaps: '⩍',
            Ccaron: 'Č',
            ccaron: 'č',
            Ccedil: 'Ç',
            ccedil: 'ç',
            Ccirc: 'Ĉ',
            ccirc: 'ĉ',
            Cconint: '∰',
            ccups: '⩌',
            ccupssm: '⩐',
            Cdot: 'Ċ',
            cdot: 'ċ',
            cedil: '¸',
            Cedilla: '¸',
            cemptyv: '⦲',
            cent: '¢',
            centerdot: '·',
            CenterDot: '·',
            cfr: '𝔠',
            Cfr: 'ℭ',
            CHcy: 'Ч',
            chcy: 'ч',
            check: '✓',
            checkmark: '✓',
            Chi: 'Χ',
            chi: 'χ',
            circ: 'ˆ',
            circeq: '≗',
            circlearrowleft: '↺',
            circlearrowright: '↻',
            circledast: '⊛',
            circledcirc: '⊚',
            circleddash: '⊝',
            CircleDot: '⊙',
            circledR: '®',
            circledS: 'Ⓢ',
            CircleMinus: '⊖',
            CirclePlus: '⊕',
            CircleTimes: '⊗',
            cir: '○',
            cirE: '⧃',
            cire: '≗',
            cirfnint: '⨐',
            cirmid: '⫯',
            cirscir: '⧂',
            ClockwiseContourIntegral: '∲',
            CloseCurlyDoubleQuote: '”',
            CloseCurlyQuote: '’',
            clubs: '♣',
            clubsuit: '♣',
            colon: ':',
            Colon: '∷',
            Colone: '⩴',
            colone: '≔',
            coloneq: '≔',
            comma: ',',
            commat: '@',
            comp: '∁',
            compfn: '∘',
            complement: '∁',
            complexes: 'ℂ',
            cong: '≅',
            congdot: '⩭',
            Congruent: '≡',
            conint: '∮',
            Conint: '∯',
            ContourIntegral: '∮',
            copf: '𝕔',
            Copf: 'ℂ',
            coprod: '∐',
            Coproduct: '∐',
            copy: '©',
            COPY: '©',
            copysr: '℗',
            CounterClockwiseContourIntegral: '∳',
            crarr: '↵',
            cross: '✗',
            Cross: '⨯',
            Cscr: '𝒞',
            cscr: '𝒸',
            csub: '⫏',
            csube: '⫑',
            csup: '⫐',
            csupe: '⫒',
            ctdot: '⋯',
            cudarrl: '⤸',
            cudarrr: '⤵',
            cuepr: '⋞',
            cuesc: '⋟',
            cularr: '↶',
            cularrp: '⤽',
            cupbrcap: '⩈',
            cupcap: '⩆',
            CupCap: '≍',
            cup: '∪',
            Cup: '⋓',
            cupcup: '⩊',
            cupdot: '⊍',
            cupor: '⩅',
            cups: '∪︀',
            curarr: '↷',
            curarrm: '⤼',
            curlyeqprec: '⋞',
            curlyeqsucc: '⋟',
            curlyvee: '⋎',
            curlywedge: '⋏',
            curren: '¤',
            curvearrowleft: '↶',
            curvearrowright: '↷',
            cuvee: '⋎',
            cuwed: '⋏',
            cwconint: '∲',
            cwint: '∱',
            cylcty: '⌭',
            dagger: '†',
            Dagger: '‡',
            daleth: 'ℸ',
            darr: '↓',
            Darr: '↡',
            dArr: '⇓',
            dash: '‐',
            Dashv: '⫤',
            dashv: '⊣',
            dbkarow: '⤏',
            dblac: '˝',
            Dcaron: 'Ď',
            dcaron: 'ď',
            Dcy: 'Д',
            dcy: 'д',
            ddagger: '‡',
            ddarr: '⇊',
            DD: 'ⅅ',
            dd: 'ⅆ',
            DDotrahd: '⤑',
            ddotseq: '⩷',
            deg: '°',
            Del: '∇',
            Delta: 'Δ',
            delta: 'δ',
            demptyv: '⦱',
            dfisht: '⥿',
            Dfr: '𝔇',
            dfr: '𝔡',
            dHar: '⥥',
            dharl: '⇃',
            dharr: '⇂',
            DiacriticalAcute: '´',
            DiacriticalDot: '˙',
            DiacriticalDoubleAcute: '˝',
            DiacriticalGrave: '`',
            DiacriticalTilde: '˜',
            diam: '⋄',
            diamond: '⋄',
            Diamond: '⋄',
            diamondsuit: '♦',
            diams: '♦',
            die: '¨',
            DifferentialD: 'ⅆ',
            digamma: 'ϝ',
            disin: '⋲',
            div: '÷',
            divide: '÷',
            divideontimes: '⋇',
            divonx: '⋇',
            DJcy: 'Ђ',
            djcy: 'ђ',
            dlcorn: '⌞',
            dlcrop: '⌍',
            dollar: '$',
            Dopf: '𝔻',
            dopf: '𝕕',
            Dot: '¨',
            dot: '˙',
            DotDot: '⃜',
            doteq: '≐',
            doteqdot: '≑',
            DotEqual: '≐',
            dotminus: '∸',
            dotplus: '∔',
            dotsquare: '⊡',
            doublebarwedge: '⌆',
            DoubleContourIntegral: '∯',
            DoubleDot: '¨',
            DoubleDownArrow: '⇓',
            DoubleLeftArrow: '⇐',
            DoubleLeftRightArrow: '⇔',
            DoubleLeftTee: '⫤',
            DoubleLongLeftArrow: '⟸',
            DoubleLongLeftRightArrow: '⟺',
            DoubleLongRightArrow: '⟹',
            DoubleRightArrow: '⇒',
            DoubleRightTee: '⊨',
            DoubleUpArrow: '⇑',
            DoubleUpDownArrow: '⇕',
            DoubleVerticalBar: '∥',
            DownArrowBar: '⤓',
            downarrow: '↓',
            DownArrow: '↓',
            Downarrow: '⇓',
            DownArrowUpArrow: '⇵',
            DownBreve: '̑',
            downdownarrows: '⇊',
            downharpoonleft: '⇃',
            downharpoonright: '⇂',
            DownLeftRightVector: '⥐',
            DownLeftTeeVector: '⥞',
            DownLeftVectorBar: '⥖',
            DownLeftVector: '↽',
            DownRightTeeVector: '⥟',
            DownRightVectorBar: '⥗',
            DownRightVector: '⇁',
            DownTeeArrow: '↧',
            DownTee: '⊤',
            drbkarow: '⤐',
            drcorn: '⌟',
            drcrop: '⌌',
            Dscr: '𝒟',
            dscr: '𝒹',
            DScy: 'Ѕ',
            dscy: 'ѕ',
            dsol: '⧶',
            Dstrok: 'Đ',
            dstrok: 'đ',
            dtdot: '⋱',
            dtri: '▿',
            dtrif: '▾',
            duarr: '⇵',
            duhar: '⥯',
            dwangle: '⦦',
            DZcy: 'Џ',
            dzcy: 'џ',
            dzigrarr: '⟿',
            Eacute: 'É',
            eacute: 'é',
            easter: '⩮',
            Ecaron: 'Ě',
            ecaron: 'ě',
            Ecirc: 'Ê',
            ecirc: 'ê',
            ecir: '≖',
            ecolon: '≕',
            Ecy: 'Э',
            ecy: 'э',
            eDDot: '⩷',
            Edot: 'Ė',
            edot: 'ė',
            eDot: '≑',
            ee: 'ⅇ',
            efDot: '≒',
            Efr: '𝔈',
            efr: '𝔢',
            eg: '⪚',
            Egrave: 'È',
            egrave: 'è',
            egs: '⪖',
            egsdot: '⪘',
            el: '⪙',
            Element: '∈',
            elinters: '⏧',
            ell: 'ℓ',
            els: '⪕',
            elsdot: '⪗',
            Emacr: 'Ē',
            emacr: 'ē',
            empty: '∅',
            emptyset: '∅',
            EmptySmallSquare: '◻',
            emptyv: '∅',
            EmptyVerySmallSquare: '▫',
            emsp13: ' ',
            emsp14: ' ',
            emsp: ' ',
            ENG: 'Ŋ',
            eng: 'ŋ',
            ensp: ' ',
            Eogon: 'Ę',
            eogon: 'ę',
            Eopf: '𝔼',
            eopf: '𝕖',
            epar: '⋕',
            eparsl: '⧣',
            eplus: '⩱',
            epsi: 'ε',
            Epsilon: 'Ε',
            epsilon: 'ε',
            epsiv: 'ϵ',
            eqcirc: '≖',
            eqcolon: '≕',
            eqsim: '≂',
            eqslantgtr: '⪖',
            eqslantless: '⪕',
            Equal: '⩵',
            equals: '=',
            EqualTilde: '≂',
            equest: '≟',
            Equilibrium: '⇌',
            equiv: '≡',
            equivDD: '⩸',
            eqvparsl: '⧥',
            erarr: '⥱',
            erDot: '≓',
            escr: 'ℯ',
            Escr: 'ℰ',
            esdot: '≐',
            Esim: '⩳',
            esim: '≂',
            Eta: 'Η',
            eta: 'η',
            ETH: 'Ð',
            eth: 'ð',
            Euml: 'Ë',
            euml: 'ë',
            euro: '€',
            excl: '!',
            exist: '∃',
            Exists: '∃',
            expectation: 'ℰ',
            exponentiale: 'ⅇ',
            ExponentialE: 'ⅇ',
            fallingdotseq: '≒',
            Fcy: 'Ф',
            fcy: 'ф',
            female: '♀',
            ffilig: 'ﬃ',
            fflig: 'ﬀ',
            ffllig: 'ﬄ',
            Ffr: '𝔉',
            ffr: '𝔣',
            filig: 'ﬁ',
            FilledSmallSquare: '◼',
            FilledVerySmallSquare: '▪',
            fjlig: 'fj',
            flat: '♭',
            fllig: 'ﬂ',
            fltns: '▱',
            fnof: 'ƒ',
            Fopf: '𝔽',
            fopf: '𝕗',
            forall: '∀',
            ForAll: '∀',
            fork: '⋔',
            forkv: '⫙',
            Fouriertrf: 'ℱ',
            fpartint: '⨍',
            frac12: '½',
            frac13: '⅓',
            frac14: '¼',
            frac15: '⅕',
            frac16: '⅙',
            frac18: '⅛',
            frac23: '⅔',
            frac25: '⅖',
            frac34: '¾',
            frac35: '⅗',
            frac38: '⅜',
            frac45: '⅘',
            frac56: '⅚',
            frac58: '⅝',
            frac78: '⅞',
            frasl: '⁄',
            frown: '⌢',
            fscr: '𝒻',
            Fscr: 'ℱ',
            gacute: 'ǵ',
            Gamma: 'Γ',
            gamma: 'γ',
            Gammad: 'Ϝ',
            gammad: 'ϝ',
            gap: '⪆',
            Gbreve: 'Ğ',
            gbreve: 'ğ',
            Gcedil: 'Ģ',
            Gcirc: 'Ĝ',
            gcirc: 'ĝ',
            Gcy: 'Г',
            gcy: 'г',
            Gdot: 'Ġ',
            gdot: 'ġ',
            ge: '≥',
            gE: '≧',
            gEl: '⪌',
            gel: '⋛',
            geq: '≥',
            geqq: '≧',
            geqslant: '⩾',
            gescc: '⪩',
            ges: '⩾',
            gesdot: '⪀',
            gesdoto: '⪂',
            gesdotol: '⪄',
            gesl: '⋛︀',
            gesles: '⪔',
            Gfr: '𝔊',
            gfr: '𝔤',
            gg: '≫',
            Gg: '⋙',
            ggg: '⋙',
            gimel: 'ℷ',
            GJcy: 'Ѓ',
            gjcy: 'ѓ',
            gla: '⪥',
            gl: '≷',
            glE: '⪒',
            glj: '⪤',
            gnap: '⪊',
            gnapprox: '⪊',
            gne: '⪈',
            gnE: '≩',
            gneq: '⪈',
            gneqq: '≩',
            gnsim: '⋧',
            Gopf: '𝔾',
            gopf: '𝕘',
            grave: '`',
            GreaterEqual: '≥',
            GreaterEqualLess: '⋛',
            GreaterFullEqual: '≧',
            GreaterGreater: '⪢',
            GreaterLess: '≷',
            GreaterSlantEqual: '⩾',
            GreaterTilde: '≳',
            Gscr: '𝒢',
            gscr: 'ℊ',
            gsim: '≳',
            gsime: '⪎',
            gsiml: '⪐',
            gtcc: '⪧',
            gtcir: '⩺',
            // gt: '>',
            // GT: '>',
            Gt: '≫',
            gtdot: '⋗',
            gtlPar: '⦕',
            gtquest: '⩼',
            gtrapprox: '⪆',
            gtrarr: '⥸',
            gtrdot: '⋗',
            gtreqless: '⋛',
            gtreqqless: '⪌',
            gtrless: '≷',
            gtrsim: '≳',
            gvertneqq: '≩︀',
            gvnE: '≩︀',
            Hacek: 'ˇ',
            hairsp: ' ',
            half: '½',
            hamilt: 'ℋ',
            HARDcy: 'Ъ',
            hardcy: 'ъ',
            harrcir: '⥈',
            harr: '↔',
            hArr: '⇔',
            harrw: '↭',
            Hat: '^',
            hbar: 'ℏ',
            Hcirc: 'Ĥ',
            hcirc: 'ĥ',
            hearts: '♥',
            heartsuit: '♥',
            hellip: '…',
            hercon: '⊹',
            hfr: '𝔥',
            Hfr: 'ℌ',
            HilbertSpace: 'ℋ',
            hksearow: '⤥',
            hkswarow: '⤦',
            hoarr: '⇿',
            homtht: '∻',
            hookleftarrow: '↩',
            hookrightarrow: '↪',
            hopf: '𝕙',
            Hopf: 'ℍ',
            horbar: '―',
            HorizontalLine: '─',
            hscr: '𝒽',
            Hscr: 'ℋ',
            hslash: 'ℏ',
            Hstrok: 'Ħ',
            hstrok: 'ħ',
            HumpDownHump: '≎',
            HumpEqual: '≏',
            hybull: '⁃',
            hyphen: '‐',
            Iacute: 'Í',
            iacute: 'í',
            ic: '⁣',
            Icirc: 'Î',
            icirc: 'î',
            Icy: 'И',
            icy: 'и',
            Idot: 'İ',
            IEcy: 'Е',
            iecy: 'е',
            iexcl: '¡',
            iff: '⇔',
            ifr: '𝔦',
            Ifr: 'ℑ',
            Igrave: 'Ì',
            igrave: 'ì',
            ii: 'ⅈ',
            iiiint: '⨌',
            iiint: '∭',
            iinfin: '⧜',
            iiota: '℩',
            IJlig: 'Ĳ',
            ijlig: 'ĳ',
            Imacr: 'Ī',
            imacr: 'ī',
            image: 'ℑ',
            ImaginaryI: 'ⅈ',
            imagline: 'ℐ',
            imagpart: 'ℑ',
            imath: 'ı',
            Im: 'ℑ',
            imof: '⊷',
            imped: 'Ƶ',
            Implies: '⇒',
            incare: '℅',
            in: '∈',
            infin: '∞',
            infintie: '⧝',
            inodot: 'ı',
            intcal: '⊺',
            int: '∫',
            Int: '∬',
            integers: 'ℤ',
            Integral: '∫',
            intercal: '⊺',
            Intersection: '⋂',
            intlarhk: '⨗',
            intprod: '⨼',
            InvisibleComma: '⁣',
            InvisibleTimes: '⁢',
            IOcy: 'Ё',
            iocy: 'ё',
            Iogon: 'Į',
            iogon: 'į',
            Iopf: '𝕀',
            iopf: '𝕚',
            Iota: 'Ι',
            iota: 'ι',
            iprod: '⨼',
            iquest: '¿',
            iscr: '𝒾',
            Iscr: 'ℐ',
            isin: '∈',
            isindot: '⋵',
            isinE: '⋹',
            isins: '⋴',
            isinsv: '⋳',
            isinv: '∈',
            it: '⁢',
            Itilde: 'Ĩ',
            itilde: 'ĩ',
            Iukcy: 'І',
            iukcy: 'і',
            Iuml: 'Ï',
            iuml: 'ï',
            Jcirc: 'Ĵ',
            jcirc: 'ĵ',
            Jcy: 'Й',
            jcy: 'й',
            Jfr: '𝔍',
            jfr: '𝔧',
            jmath: 'ȷ',
            Jopf: '𝕁',
            jopf: '𝕛',
            Jscr: '𝒥',
            jscr: '𝒿',
            Jsercy: 'Ј',
            jsercy: 'ј',
            Jukcy: 'Є',
            jukcy: 'є',
            Kappa: 'Κ',
            kappa: 'κ',
            kappav: 'ϰ',
            Kcedil: 'Ķ',
            kcedil: 'ķ',
            Kcy: 'К',
            kcy: 'к',
            Kfr: '𝔎',
            kfr: '𝔨',
            kgreen: 'ĸ',
            KHcy: 'Х',
            khcy: 'х',
            KJcy: 'Ќ',
            kjcy: 'ќ',
            Kopf: '𝕂',
            kopf: '𝕜',
            Kscr: '𝒦',
            kscr: '𝓀',
            lAarr: '⇚',
            Lacute: 'Ĺ',
            lacute: 'ĺ',
            laemptyv: '⦴',
            lagran: 'ℒ',
            Lambda: 'Λ',
            lambda: 'λ',
            lang: '⟨',
            Lang: '⟪',
            langd: '⦑',
            langle: '⟨',
            lap: '⪅',
            Laplacetrf: 'ℒ',
            laquo: '«',
            larrb: '⇤',
            larrbfs: '⤟',
            larr: '←',
            Larr: '↞',
            lArr: '⇐',
            larrfs: '⤝',
            larrhk: '↩',
            larrlp: '↫',
            larrpl: '⤹',
            larrsim: '⥳',
            larrtl: '↢',
            latail: '⤙',
            lAtail: '⤛',
            lat: '⪫',
            late: '⪭',
            lates: '⪭︀',
            lbarr: '⤌',
            lBarr: '⤎',
            lbbrk: '❲',
            lbrace: '{',
            lbrack: '[',
            lbrke: '⦋',
            lbrksld: '⦏',
            lbrkslu: '⦍',
            Lcaron: 'Ľ',
            lcaron: 'ľ',
            Lcedil: 'Ļ',
            lcedil: 'ļ',
            lceil: '⌈',
            lcub: '{',
            Lcy: 'Л',
            lcy: 'л',
            ldca: '⤶',
            ldquo: '“',
            ldquor: '„',
            ldrdhar: '⥧',
            ldrushar: '⥋',
            ldsh: '↲',
            le: '≤',
            lE: '≦',
            LeftAngleBracket: '⟨',
            LeftArrowBar: '⇤',
            leftarrow: '←',
            LeftArrow: '←',
            Leftarrow: '⇐',
            LeftArrowRightArrow: '⇆',
            leftarrowtail: '↢',
            LeftCeiling: '⌈',
            LeftDoubleBracket: '⟦',
            LeftDownTeeVector: '⥡',
            LeftDownVectorBar: '⥙',
            LeftDownVector: '⇃',
            LeftFloor: '⌊',
            leftharpoondown: '↽',
            leftharpoonup: '↼',
            leftleftarrows: '⇇',
            leftrightarrow: '↔',
            LeftRightArrow: '↔',
            Leftrightarrow: '⇔',
            leftrightarrows: '⇆',
            leftrightharpoons: '⇋',
            leftrightsquigarrow: '↭',
            LeftRightVector: '⥎',
            LeftTeeArrow: '↤',
            LeftTee: '⊣',
            LeftTeeVector: '⥚',
            leftthreetimes: '⋋',
            LeftTriangleBar: '⧏',
            LeftTriangle: '⊲',
            LeftTriangleEqual: '⊴',
            LeftUpDownVector: '⥑',
            LeftUpTeeVector: '⥠',
            LeftUpVectorBar: '⥘',
            LeftUpVector: '↿',
            LeftVectorBar: '⥒',
            LeftVector: '↼',
            lEg: '⪋',
            leg: '⋚',
            leq: '≤',
            leqq: '≦',
            leqslant: '⩽',
            lescc: '⪨',
            les: '⩽',
            lesdot: '⩿',
            lesdoto: '⪁',
            lesdotor: '⪃',
            lesg: '⋚︀',
            lesges: '⪓',
            lessapprox: '⪅',
            lessdot: '⋖',
            lesseqgtr: '⋚',
            lesseqqgtr: '⪋',
            LessEqualGreater: '⋚',
            LessFullEqual: '≦',
            LessGreater: '≶',
            lessgtr: '≶',
            LessLess: '⪡',
            lesssim: '≲',
            LessSlantEqual: '⩽',
            LessTilde: '≲',
            lfisht: '⥼',
            lfloor: '⌊',
            Lfr: '𝔏',
            lfr: '𝔩',
            lg: '≶',
            lgE: '⪑',
            lHar: '⥢',
            lhard: '↽',
            lharu: '↼',
            lharul: '⥪',
            lhblk: '▄',
            LJcy: 'Љ',
            ljcy: 'љ',
            llarr: '⇇',
            ll: '≪',
            Ll: '⋘',
            llcorner: '⌞',
            Lleftarrow: '⇚',
            llhard: '⥫',
            lltri: '◺',
            Lmidot: 'Ŀ',
            lmidot: 'ŀ',
            lmoustache: '⎰',
            lmoust: '⎰',
            lnap: '⪉',
            lnapprox: '⪉',
            lne: '⪇',
            lnE: '≨',
            lneq: '⪇',
            lneqq: '≨',
            lnsim: '⋦',
            loang: '⟬',
            loarr: '⇽',
            lobrk: '⟦',
            longleftarrow: '⟵',
            LongLeftArrow: '⟵',
            Longleftarrow: '⟸',
            longleftrightarrow: '⟷',
            LongLeftRightArrow: '⟷',
            Longleftrightarrow: '⟺',
            longmapsto: '⟼',
            longrightarrow: '⟶',
            LongRightArrow: '⟶',
            Longrightarrow: '⟹',
            looparrowleft: '↫',
            looparrowright: '↬',
            lopar: '⦅',
            Lopf: '𝕃',
            lopf: '𝕝',
            loplus: '⨭',
            lotimes: '⨴',
            lowast: '∗',
            lowbar: '_',
            LowerLeftArrow: '↙',
            LowerRightArrow: '↘',
            loz: '◊',
            lozenge: '◊',
            lozf: '⧫',
            lpar: '(',
            lparlt: '⦓',
            lrarr: '⇆',
            lrcorner: '⌟',
            lrhar: '⇋',
            lrhard: '⥭',
            lrm: '‎',
            lrtri: '⊿',
            lsaquo: '‹',
            lscr: '𝓁',
            Lscr: 'ℒ',
            lsh: '↰',
            Lsh: '↰',
            lsim: '≲',
            lsime: '⪍',
            lsimg: '⪏',
            lsqb: '[',
            lsquo: '‘',
            lsquor: '‚',
            Lstrok: 'Ł',
            lstrok: 'ł',
            ltcc: '⪦',
            ltcir: '⩹',
            // lt: '<',
            // LT: '<',
            Lt: '≪',
            ltdot: '⋖',
            lthree: '⋋',
            ltimes: '⋉',
            ltlarr: '⥶',
            ltquest: '⩻',
            ltri: '◃',
            ltrie: '⊴',
            ltrif: '◂',
            ltrPar: '⦖',
            lurdshar: '⥊',
            luruhar: '⥦',
            lvertneqq: '≨︀',
            lvnE: '≨︀',
            macr: '¯',
            male: '♂',
            malt: '✠',
            maltese: '✠',
            Map: '⤅',
            map: '↦',
            mapsto: '↦',
            mapstodown: '↧',
            mapstoleft: '↤',
            mapstoup: '↥',
            marker: '▮',
            mcomma: '⨩',
            Mcy: 'М',
            mcy: 'м',
            mdash: '—',
            mDDot: '∺',
            measuredangle: '∡',
            MediumSpace: ' ',
            Mellintrf: 'ℳ',
            Mfr: '𝔐',
            mfr: '𝔪',
            mho: '℧',
            micro: 'µ',
            midast: '*',
            midcir: '⫰',
            mid: '∣',
            middot: '·',
            minusb: '⊟',
            minus: '−',
            minusd: '∸',
            minusdu: '⨪',
            MinusPlus: '∓',
            mlcp: '⫛',
            mldr: '…',
            mnplus: '∓',
            models: '⊧',
            Mopf: '𝕄',
            mopf: '𝕞',
            mp: '∓',
            mscr: '𝓂',
            Mscr: 'ℳ',
            mstpos: '∾',
            Mu: 'Μ',
            mu: 'μ',
            multimap: '⊸',
            mumap: '⊸',
            nabla: '∇',
            Nacute: 'Ń',
            nacute: 'ń',
            nang: '∠⃒',
            nap: '≉',
            napE: '⩰̸',
            napid: '≋̸',
            napos: 'ŉ',
            napprox: '≉',
            natural: '♮',
            naturals: 'ℕ',
            natur: '♮',
            nbsp: ' ',
            nbump: '≎̸',
            nbumpe: '≏̸',
            ncap: '⩃',
            Ncaron: 'Ň',
            ncaron: 'ň',
            Ncedil: 'Ņ',
            ncedil: 'ņ',
            ncong: '≇',
            ncongdot: '⩭̸',
            ncup: '⩂',
            Ncy: 'Н',
            ncy: 'н',
            ndash: '–',
            nearhk: '⤤',
            nearr: '↗',
            neArr: '⇗',
            nearrow: '↗',
            ne: '≠',
            nedot: '≐̸',
            NegativeMediumSpace: '​',
            NegativeThickSpace: '​',
            NegativeThinSpace: '​',
            NegativeVeryThinSpace: '​',
            nequiv: '≢',
            nesear: '⤨',
            nesim: '≂̸',
            NestedGreaterGreater: '≫',
            NestedLessLess: '≪',
            NewLine: '\n',
            nexist: '∄',
            nexists: '∄',
            Nfr: '𝔑',
            nfr: '𝔫',
            ngE: '≧̸',
            nge: '≱',
            ngeq: '≱',
            ngeqq: '≧̸',
            ngeqslant: '⩾̸',
            nges: '⩾̸',
            nGg: '⋙̸',
            ngsim: '≵',
            nGt: '≫⃒',
            ngt: '≯',
            ngtr: '≯',
            nGtv: '≫̸',
            nharr: '↮',
            nhArr: '⇎',
            nhpar: '⫲',
            ni: '∋',
            nis: '⋼',
            nisd: '⋺',
            niv: '∋',
            NJcy: 'Њ',
            njcy: 'њ',
            nlarr: '↚',
            nlArr: '⇍',
            nldr: '‥',
            nlE: '≦̸',
            nle: '≰',
            nleftarrow: '↚',
            nLeftarrow: '⇍',
            nleftrightarrow: '↮',
            nLeftrightarrow: '⇎',
            nleq: '≰',
            nleqq: '≦̸',
            nleqslant: '⩽̸',
            nles: '⩽̸',
            nless: '≮',
            nLl: '⋘̸',
            nlsim: '≴',
            nLt: '≪⃒',
            nlt: '≮',
            nltri: '⋪',
            nltrie: '⋬',
            nLtv: '≪̸',
            nmid: '∤',
            NoBreak: '⁠',
            NonBreakingSpace: ' ',
            nopf: '𝕟',
            Nopf: 'ℕ',
            Not: '⫬',
            not: '¬',
            NotCongruent: '≢',
            NotCupCap: '≭',
            NotDoubleVerticalBar: '∦',
            NotElement: '∉',
            NotEqual: '≠',
            NotEqualTilde: '≂̸',
            NotExists: '∄',
            NotGreater: '≯',
            NotGreaterEqual: '≱',
            NotGreaterFullEqual: '≧̸',
            NotGreaterGreater: '≫̸',
            NotGreaterLess: '≹',
            NotGreaterSlantEqual: '⩾̸',
            NotGreaterTilde: '≵',
            NotHumpDownHump: '≎̸',
            NotHumpEqual: '≏̸',
            notin: '∉',
            notindot: '⋵̸',
            notinE: '⋹̸',
            notinva: '∉',
            notinvb: '⋷',
            notinvc: '⋶',
            NotLeftTriangleBar: '⧏̸',
            NotLeftTriangle: '⋪',
            NotLeftTriangleEqual: '⋬',
            NotLess: '≮',
            NotLessEqual: '≰',
            NotLessGreater: '≸',
            NotLessLess: '≪̸',
            NotLessSlantEqual: '⩽̸',
            NotLessTilde: '≴',
            NotNestedGreaterGreater: '⪢̸',
            NotNestedLessLess: '⪡̸',
            notni: '∌',
            notniva: '∌',
            notnivb: '⋾',
            notnivc: '⋽',
            NotPrecedes: '⊀',
            NotPrecedesEqual: '⪯̸',
            NotPrecedesSlantEqual: '⋠',
            NotReverseElement: '∌',
            NotRightTriangleBar: '⧐̸',
            NotRightTriangle: '⋫',
            NotRightTriangleEqual: '⋭',
            NotSquareSubset: '⊏̸',
            NotSquareSubsetEqual: '⋢',
            NotSquareSuperset: '⊐̸',
            NotSquareSupersetEqual: '⋣',
            NotSubset: '⊂⃒',
            NotSubsetEqual: '⊈',
            NotSucceeds: '⊁',
            NotSucceedsEqual: '⪰̸',
            NotSucceedsSlantEqual: '⋡',
            NotSucceedsTilde: '≿̸',
            NotSuperset: '⊃⃒',
            NotSupersetEqual: '⊉',
            NotTilde: '≁',
            NotTildeEqual: '≄',
            NotTildeFullEqual: '≇',
            NotTildeTilde: '≉',
            NotVerticalBar: '∤',
            nparallel: '∦',
            npar: '∦',
            nparsl: '⫽⃥',
            npart: '∂̸',
            npolint: '⨔',
            npr: '⊀',
            nprcue: '⋠',
            nprec: '⊀',
            npreceq: '⪯̸',
            npre: '⪯̸',
            nrarrc: '⤳̸',
            nrarr: '↛',
            nrArr: '⇏',
            nrarrw: '↝̸',
            nrightarrow: '↛',
            nRightarrow: '⇏',
            nrtri: '⋫',
            nrtrie: '⋭',
            nsc: '⊁',
            nsccue: '⋡',
            nsce: '⪰̸',
            Nscr: '𝒩',
            nscr: '𝓃',
            nshortmid: '∤',
            nshortparallel: '∦',
            nsim: '≁',
            nsime: '≄',
            nsimeq: '≄',
            nsmid: '∤',
            nspar: '∦',
            nsqsube: '⋢',
            nsqsupe: '⋣',
            nsub: '⊄',
            nsubE: '⫅̸',
            nsube: '⊈',
            nsubset: '⊂⃒',
            nsubseteq: '⊈',
            nsubseteqq: '⫅̸',
            nsucc: '⊁',
            nsucceq: '⪰̸',
            nsup: '⊅',
            nsupE: '⫆̸',
            nsupe: '⊉',
            nsupset: '⊃⃒',
            nsupseteq: '⊉',
            nsupseteqq: '⫆̸',
            ntgl: '≹',
            Ntilde: 'Ñ',
            ntilde: 'ñ',
            ntlg: '≸',
            ntriangleleft: '⋪',
            ntrianglelefteq: '⋬',
            ntriangleright: '⋫',
            ntrianglerighteq: '⋭',
            Nu: 'Ν',
            nu: 'ν',
            num: '#',
            numero: '№',
            numsp: ' ',
            nvap: '≍⃒',
            nvdash: '⊬',
            nvDash: '⊭',
            nVdash: '⊮',
            nVDash: '⊯',
            nvge: '≥⃒',
            nvgt: '>⃒',
            nvHarr: '⤄',
            nvinfin: '⧞',
            nvlArr: '⤂',
            nvle: '≤⃒',
            nvlt: '<⃒',
            nvltrie: '⊴⃒',
            nvrArr: '⤃',
            nvrtrie: '⊵⃒',
            nvsim: '∼⃒',
            nwarhk: '⤣',
            nwarr: '↖',
            nwArr: '⇖',
            nwarrow: '↖',
            nwnear: '⤧',
            Oacute: 'Ó',
            oacute: 'ó',
            oast: '⊛',
            Ocirc: 'Ô',
            ocirc: 'ô',
            ocir: '⊚',
            Ocy: 'О',
            ocy: 'о',
            odash: '⊝',
            Odblac: 'Ő',
            odblac: 'ő',
            odiv: '⨸',
            odot: '⊙',
            odsold: '⦼',
            OElig: 'Œ',
            oelig: 'œ',
            ofcir: '⦿',
            Ofr: '𝔒',
            ofr: '𝔬',
            ogon: '˛',
            Ograve: 'Ò',
            ograve: 'ò',
            ogt: '⧁',
            ohbar: '⦵',
            ohm: 'Ω',
            oint: '∮',
            olarr: '↺',
            olcir: '⦾',
            olcross: '⦻',
            oline: '‾',
            olt: '⧀',
            Omacr: 'Ō',
            omacr: 'ō',
            Omega: 'Ω',
            omega: 'ω',
            Omicron: 'Ο',
            omicron: 'ο',
            omid: '⦶',
            ominus: '⊖',
            Oopf: '𝕆',
            oopf: '𝕠',
            opar: '⦷',
            OpenCurlyDoubleQuote: '“',
            OpenCurlyQuote: '‘',
            operp: '⦹',
            oplus: '⊕',
            orarr: '↻',
            Or: '⩔',
            or: '∨',
            ord: '⩝',
            order: 'ℴ',
            orderof: 'ℴ',
            ordf: 'ª',
            ordm: 'º',
            origof: '⊶',
            oror: '⩖',
            orslope: '⩗',
            orv: '⩛',
            oS: 'Ⓢ',
            Oscr: '𝒪',
            oscr: 'ℴ',
            Oslash: 'Ø',
            oslash: 'ø',
            osol: '⊘',
            Otilde: 'Õ',
            otilde: 'õ',
            otimesas: '⨶',
            Otimes: '⨷',
            otimes: '⊗',
            Ouml: 'Ö',
            ouml: 'ö',
            ovbar: '⌽',
            OverBar: '‾',
            OverBrace: '⏞',
            OverBracket: '⎴',
            OverParenthesis: '⏜',
            para: '¶',
            parallel: '∥',
            par: '∥',
            parsim: '⫳',
            parsl: '⫽',
            part: '∂',
            PartialD: '∂',
            Pcy: 'П',
            pcy: 'п',
            percnt: '%',
            period: '.',
            permil: '‰',
            perp: '⊥',
            pertenk: '‱',
            Pfr: '𝔓',
            pfr: '𝔭',
            Phi: 'Φ',
            phi: 'φ',
            phiv: 'ϕ',
            phmmat: 'ℳ',
            phone: '☎',
            Pi: 'Π',
            pi: 'π',
            pitchfork: '⋔',
            piv: 'ϖ',
            planck: 'ℏ',
            planckh: 'ℎ',
            plankv: 'ℏ',
            plusacir: '⨣',
            plusb: '⊞',
            pluscir: '⨢',
            plus: '+',
            plusdo: '∔',
            plusdu: '⨥',
            pluse: '⩲',
            PlusMinus: '±',
            plusmn: '±',
            plussim: '⨦',
            plustwo: '⨧',
            pm: '±',
            Poincareplane: 'ℌ',
            pointint: '⨕',
            popf: '𝕡',
            Popf: 'ℙ',
            pound: '£',
            prap: '⪷',
            Pr: '⪻',
            pr: '≺',
            prcue: '≼',
            precapprox: '⪷',
            prec: '≺',
            preccurlyeq: '≼',
            Precedes: '≺',
            PrecedesEqual: '⪯',
            PrecedesSlantEqual: '≼',
            PrecedesTilde: '≾',
            preceq: '⪯',
            precnapprox: '⪹',
            precneqq: '⪵',
            precnsim: '⋨',
            pre: '⪯',
            prE: '⪳',
            precsim: '≾',
            prime: '′',
            Prime: '″',
            primes: 'ℙ',
            prnap: '⪹',
            prnE: '⪵',
            prnsim: '⋨',
            prod: '∏',
            Product: '∏',
            profalar: '⌮',
            profline: '⌒',
            profsurf: '⌓',
            prop: '∝',
            Proportional: '∝',
            Proportion: '∷',
            propto: '∝',
            prsim: '≾',
            prurel: '⊰',
            Pscr: '𝒫',
            pscr: '𝓅',
            Psi: 'Ψ',
            psi: 'ψ',
            puncsp: ' ',
            Qfr: '𝔔',
            qfr: '𝔮',
            qint: '⨌',
            qopf: '𝕢',
            Qopf: 'ℚ',
            qprime: '⁗',
            Qscr: '𝒬',
            qscr: '𝓆',
            quaternions: 'ℍ',
            quatint: '⨖',
            quest: '?',
            questeq: '≟',
            quot: `\\"`,
            QUOT: "'",
            rAarr: '⇛',
            race: '∽̱',
            Racute: 'Ŕ',
            racute: 'ŕ',
            radic: '√',
            raemptyv: '⦳',
            rang: '⟩',
            Rang: '⟫',
            rangd: '⦒',
            range: '⦥',
            rangle: '⟩',
            raquo: '»',
            rarrap: '⥵',
            rarrb: '⇥',
            rarrbfs: '⤠',
            rarrc: '⤳',
            rarr: '→',
            Rarr: '↠',
            rArr: '⇒',
            rarrfs: '⤞',
            rarrhk: '↪',
            rarrlp: '↬',
            rarrpl: '⥅',
            rarrsim: '⥴',
            Rarrtl: '⤖',
            rarrtl: '↣',
            rarrw: '↝',
            ratail: '⤚',
            rAtail: '⤜',
            ratio: '∶',
            rationals: 'ℚ',
            rbarr: '⤍',
            rBarr: '⤏',
            RBarr: '⤐',
            rbbrk: '❳',
            rbrace: '}',
            rbrack: ']',
            rbrke: '⦌',
            rbrksld: '⦎',
            rbrkslu: '⦐',
            Rcaron: 'Ř',
            rcaron: 'ř',
            Rcedil: 'Ŗ',
            rcedil: 'ŗ',
            rceil: '⌉',
            rcub: '}',
            Rcy: 'Р',
            rcy: 'р',
            rdca: '⤷',
            rdldhar: '⥩',
            rdquo: '”',
            rdquor: '”',
            rdsh: '↳',
            real: 'ℜ',
            realine: 'ℛ',
            realpart: 'ℜ',
            reals: 'ℝ',
            Re: 'ℜ',
            rect: '▭',
            reg: '®',
            REG: '®',
            ReverseElement: '∋',
            ReverseEquilibrium: '⇋',
            ReverseUpEquilibrium: '⥯',
            rfisht: '⥽',
            rfloor: '⌋',
            rfr: '𝔯',
            Rfr: 'ℜ',
            rHar: '⥤',
            rhard: '⇁',
            rharu: '⇀',
            rharul: '⥬',
            Rho: 'Ρ',
            rho: 'ρ',
            rhov: 'ϱ',
            RightAngleBracket: '⟩',
            RightArrowBar: '⇥',
            rightarrow: '→',
            RightArrow: '→',
            Rightarrow: '⇒',
            RightArrowLeftArrow: '⇄',
            rightarrowtail: '↣',
            RightCeiling: '⌉',
            RightDoubleBracket: '⟧',
            RightDownTeeVector: '⥝',
            RightDownVectorBar: '⥕',
            RightDownVector: '⇂',
            RightFloor: '⌋',
            rightharpoondown: '⇁',
            rightharpoonup: '⇀',
            rightleftarrows: '⇄',
            rightleftharpoons: '⇌',
            rightrightarrows: '⇉',
            rightsquigarrow: '↝',
            RightTeeArrow: '↦',
            RightTee: '⊢',
            RightTeeVector: '⥛',
            rightthreetimes: '⋌',
            RightTriangleBar: '⧐',
            RightTriangle: '⊳',
            RightTriangleEqual: '⊵',
            RightUpDownVector: '⥏',
            RightUpTeeVector: '⥜',
            RightUpVectorBar: '⥔',
            RightUpVector: '↾',
            RightVectorBar: '⥓',
            RightVector: '⇀',
            ring: '˚',
            risingdotseq: '≓',
            rlarr: '⇄',
            rlhar: '⇌',
            rlm: '‏',
            rmoustache: '⎱',
            rmoust: '⎱',
            rnmid: '⫮',
            roang: '⟭',
            roarr: '⇾',
            robrk: '⟧',
            ropar: '⦆',
            ropf: '𝕣',
            Ropf: 'ℝ',
            roplus: '⨮',
            rotimes: '⨵',
            RoundImplies: '⥰',
            rpar: ')',
            rpargt: '⦔',
            rppolint: '⨒',
            rrarr: '⇉',
            Rrightarrow: '⇛',
            rsaquo: '›',
            rscr: '𝓇',
            Rscr: 'ℛ',
            rsh: '↱',
            Rsh: '↱',
            rsqb: ']',
            rsquo: '’',
            rsquor: '’',
            rthree: '⋌',
            rtimes: '⋊',
            rtri: '▹',
            rtrie: '⊵',
            rtrif: '▸',
            rtriltri: '⧎',
            RuleDelayed: '⧴',
            ruluhar: '⥨',
            rx: '℞',
            Sacute: 'Ś',
            sacute: 'ś',
            sbquo: '‚',
            scap: '⪸',
            Scaron: 'Š',
            scaron: 'š',
            Sc: '⪼',
            sc: '≻',
            sccue: '≽',
            sce: '⪰',
            scE: '⪴',
            Scedil: 'Ş',
            scedil: 'ş',
            Scirc: 'Ŝ',
            scirc: 'ŝ',
            scnap: '⪺',
            scnE: '⪶',
            scnsim: '⋩',
            scpolint: '⨓',
            scsim: '≿',
            Scy: 'С',
            scy: 'с',
            sdotb: '⊡',
            sdot: '⋅',
            sdote: '⩦',
            searhk: '⤥',
            searr: '↘',
            seArr: '⇘',
            searrow: '↘',
            sect: '§',
            semi: ';',
            seswar: '⤩',
            setminus: '∖',
            setmn: '∖',
            sext: '✶',
            Sfr: '𝔖',
            sfr: '𝔰',
            sfrown: '⌢',
            sharp: '♯',
            SHCHcy: 'Щ',
            shchcy: 'щ',
            SHcy: 'Ш',
            shcy: 'ш',
            ShortDownArrow: '↓',
            ShortLeftArrow: '←',
            shortmid: '∣',
            shortparallel: '∥',
            ShortRightArrow: '→',
            ShortUpArrow: '↑',
            shy: '­',
            Sigma: 'Σ',
            sigma: 'σ',
            sigmaf: 'ς',
            sigmav: 'ς',
            sim: '∼',
            simdot: '⩪',
            sime: '≃',
            simeq: '≃',
            simg: '⪞',
            simgE: '⪠',
            siml: '⪝',
            simlE: '⪟',
            simne: '≆',
            simplus: '⨤',
            simrarr: '⥲',
            slarr: '←',
            SmallCircle: '∘',
            smallsetminus: '∖',
            smashp: '⨳',
            smeparsl: '⧤',
            smid: '∣',
            smile: '⌣',
            smt: '⪪',
            smte: '⪬',
            smtes: '⪬︀',
            SOFTcy: 'Ь',
            softcy: 'ь',
            solbar: '⌿',
            solb: '⧄',
            sol: '/',
            Sopf: '𝕊',
            sopf: '𝕤',
            spades: '♠',
            spadesuit: '♠',
            spar: '∥',
            sqcap: '⊓',
            sqcaps: '⊓︀',
            sqcup: '⊔',
            sqcups: '⊔︀',
            Sqrt: '√',
            sqsub: '⊏',
            sqsube: '⊑',
            sqsubset: '⊏',
            sqsubseteq: '⊑',
            sqsup: '⊐',
            sqsupe: '⊒',
            sqsupset: '⊐',
            sqsupseteq: '⊒',
            square: '□',
            Square: '□',
            SquareIntersection: '⊓',
            SquareSubset: '⊏',
            SquareSubsetEqual: '⊑',
            SquareSuperset: '⊐',
            SquareSupersetEqual: '⊒',
            SquareUnion: '⊔',
            squarf: '▪',
            squ: '□',
            squf: '▪',
            srarr: '→',
            Sscr: '𝒮',
            sscr: '𝓈',
            ssetmn: '∖',
            ssmile: '⌣',
            sstarf: '⋆',
            Star: '⋆',
            star: '☆',
            starf: '★',
            straightepsilon: 'ϵ',
            straightphi: 'ϕ',
            strns: '¯',
            sub: '⊂',
            Sub: '⋐',
            subdot: '⪽',
            subE: '⫅',
            sube: '⊆',
            subedot: '⫃',
            submult: '⫁',
            subnE: '⫋',
            subne: '⊊',
            subplus: '⪿',
            subrarr: '⥹',
            subset: '⊂',
            Subset: '⋐',
            subseteq: '⊆',
            subseteqq: '⫅',
            SubsetEqual: '⊆',
            subsetneq: '⊊',
            subsetneqq: '⫋',
            subsim: '⫇',
            subsub: '⫕',
            subsup: '⫓',
            succapprox: '⪸',
            succ: '≻',
            succcurlyeq: '≽',
            Succeeds: '≻',
            SucceedsEqual: '⪰',
            SucceedsSlantEqual: '≽',
            SucceedsTilde: '≿',
            succeq: '⪰',
            succnapprox: '⪺',
            succneqq: '⪶',
            succnsim: '⋩',
            succsim: '≿',
            SuchThat: '∋',
            sum: '∑',
            Sum: '∑',
            sung: '♪',
            sup1: '¹',
            sup2: '²',
            sup3: '³',
            sup: '⊃',
            Sup: '⋑',
            supdot: '⪾',
            supdsub: '⫘',
            supE: '⫆',
            supe: '⊇',
            supedot: '⫄',
            Superset: '⊃',
            SupersetEqual: '⊇',
            suphsol: '⟉',
            suphsub: '⫗',
            suplarr: '⥻',
            supmult: '⫂',
            supnE: '⫌',
            supne: '⊋',
            supplus: '⫀',
            supset: '⊃',
            Supset: '⋑',
            supseteq: '⊇',
            supseteqq: '⫆',
            supsetneq: '⊋',
            supsetneqq: '⫌',
            supsim: '⫈',
            supsub: '⫔',
            supsup: '⫖',
            swarhk: '⤦',
            swarr: '↙',
            swArr: '⇙',
            swarrow: '↙',
            swnwar: '⤪',
            szlig: 'ß',
            Tab: '\t',
            target: '⌖',
            Tau: 'Τ',
            tau: 'τ',
            tbrk: '⎴',
            Tcaron: 'Ť',
            tcaron: 'ť',
            Tcedil: 'Ţ',
            tcedil: 'ţ',
            Tcy: 'Т',
            tcy: 'т',
            tdot: '⃛',
            telrec: '⌕',
            Tfr: '𝔗',
            tfr: '𝔱',
            there4: '∴',
            therefore: '∴',
            Therefore: '∴',
            Theta: 'Θ',
            theta: 'θ',
            thetasym: 'ϑ',
            thetav: 'ϑ',
            thickapprox: '≈',
            thicksim: '∼',
            ThickSpace: '  ',
            ThinSpace: ' ',
            thinsp: ' ',
            thkap: '≈',
            thksim: '∼',
            THORN: 'Þ',
            thorn: 'þ',
            tilde: '˜',
            Tilde: '∼',
            TildeEqual: '≃',
            TildeFullEqual: '≅',
            TildeTilde: '≈',
            timesbar: '⨱',
            timesb: '⊠',
            times: '×',
            timesd: '⨰',
            tint: '∭',
            toea: '⤨',
            topbot: '⌶',
            topcir: '⫱',
            top: '⊤',
            Topf: '𝕋',
            topf: '𝕥',
            topfork: '⫚',
            tosa: '⤩',
            tprime: '‴',
            trade: '™',
            TRADE: '™',
            triangle: '▵',
            triangledown: '▿',
            triangleleft: '◃',
            trianglelefteq: '⊴',
            triangleq: '≜',
            triangleright: '▹',
            trianglerighteq: '⊵',
            tridot: '◬',
            trie: '≜',
            triminus: '⨺',
            TripleDot: '⃛',
            triplus: '⨹',
            trisb: '⧍',
            tritime: '⨻',
            trpezium: '⏢',
            Tscr: '𝒯',
            tscr: '𝓉',
            TScy: 'Ц',
            tscy: 'ц',
            TSHcy: 'Ћ',
            tshcy: 'ћ',
            Tstrok: 'Ŧ',
            tstrok: 'ŧ',
            twixt: '≬',
            twoheadleftarrow: '↞',
            twoheadrightarrow: '↠',
            Uacute: 'Ú',
            uacute: 'ú',
            uarr: '↑',
            Uarr: '↟',
            uArr: '⇑',
            Uarrocir: '⥉',
            Ubrcy: 'Ў',
            ubrcy: 'ў',
            Ubreve: 'Ŭ',
            ubreve: 'ŭ',
            Ucirc: 'Û',
            ucirc: 'û',
            Ucy: 'У',
            ucy: 'у',
            udarr: '⇅',
            Udblac: 'Ű',
            udblac: 'ű',
            udhar: '⥮',
            ufisht: '⥾',
            Ufr: '𝔘',
            ufr: '𝔲',
            Ugrave: 'Ù',
            ugrave: 'ù',
            uHar: '⥣',
            uharl: '↿',
            uharr: '↾',
            uhblk: '▀',
            ulcorn: '⌜',
            ulcorner: '⌜',
            ulcrop: '⌏',
            ultri: '◸',
            Umacr: 'Ū',
            umacr: 'ū',
            uml: '¨',
            UnderBar: '_',
            UnderBrace: '⏟',
            UnderBracket: '⎵',
            UnderParenthesis: '⏝',
            Union: '⋃',
            UnionPlus: '⊎',
            Uogon: 'Ų',
            uogon: 'ų',
            Uopf: '𝕌',
            uopf: '𝕦',
            UpArrowBar: '⤒',
            uparrow: '↑',
            UpArrow: '↑',
            Uparrow: '⇑',
            UpArrowDownArrow: '⇅',
            updownarrow: '↕',
            UpDownArrow: '↕',
            Updownarrow: '⇕',
            UpEquilibrium: '⥮',
            upharpoonleft: '↿',
            upharpoonright: '↾',
            uplus: '⊎',
            UpperLeftArrow: '↖',
            UpperRightArrow: '↗',
            upsi: 'υ',
            Upsi: 'ϒ',
            upsih: 'ϒ',
            Upsilon: 'Υ',
            upsilon: 'υ',
            UpTeeArrow: '↥',
            UpTee: '⊥',
            upuparrows: '⇈',
            urcorn: '⌝',
            urcorner: '⌝',
            urcrop: '⌎',
            Uring: 'Ů',
            uring: 'ů',
            urtri: '◹',
            Uscr: '𝒰',
            uscr: '𝓊',
            utdot: '⋰',
            Utilde: 'Ũ',
            utilde: 'ũ',
            utri: '▵',
            utrif: '▴',
            uuarr: '⇈',
            Uuml: 'Ü',
            uuml: 'ü',
            uwangle: '⦧',
            vangrt: '⦜',
            varepsilon: 'ϵ',
            varkappa: 'ϰ',
            varnothing: '∅',
            varphi: 'ϕ',
            varpi: 'ϖ',
            varpropto: '∝',
            varr: '↕',
            vArr: '⇕',
            varrho: 'ϱ',
            varsigma: 'ς',
            varsubsetneq: '⊊︀',
            varsubsetneqq: '⫋︀',
            varsupsetneq: '⊋︀',
            varsupsetneqq: '⫌︀',
            vartheta: 'ϑ',
            vartriangleleft: '⊲',
            vartriangleright: '⊳',
            vBar: '⫨',
            Vbar: '⫫',
            vBarv: '⫩',
            Vcy: 'В',
            vcy: 'в',
            vdash: '⊢',
            vDash: '⊨',
            Vdash: '⊩',
            VDash: '⊫',
            Vdashl: '⫦',
            veebar: '⊻',
            vee: '∨',
            Vee: '⋁',
            veeeq: '≚',
            vellip: '⋮',
            verbar: '|',
            Verbar: '‖',
            vert: '|',
            Vert: '‖',
            VerticalBar: '∣',
            VerticalLine: '|',
            VerticalSeparator: '❘',
            VerticalTilde: '≀',
            VeryThinSpace: ' ',
            Vfr: '𝔙',
            vfr: '𝔳',
            vltri: '⊲',
            vnsub: '⊂⃒',
            vnsup: '⊃⃒',
            Vopf: '𝕍',
            vopf: '𝕧',
            vprop: '∝',
            vrtri: '⊳',
            Vscr: '𝒱',
            vscr: '𝓋',
            vsubnE: '⫋︀',
            vsubne: '⊊︀',
            vsupnE: '⫌︀',
            vsupne: '⊋︀',
            Vvdash: '⊪',
            vzigzag: '⦚',
            Wcirc: 'Ŵ',
            wcirc: 'ŵ',
            wedbar: '⩟',
            wedge: '∧',
            Wedge: '⋀',
            wedgeq: '≙',
            weierp: '℘',
            Wfr: '𝔚',
            wfr: '𝔴',
            Wopf: '𝕎',
            wopf: '𝕨',
            wp: '℘',
            wr: '≀',
            wreath: '≀',
            Wscr: '𝒲',
            wscr: '𝓌',
            xcap: '⋂',
            xcirc: '◯',
            xcup: '⋃',
            xdtri: '▽',
            Xfr: '𝔛',
            xfr: '𝔵',
            xharr: '⟷',
            xhArr: '⟺',
            Xi: 'Ξ',
            xi: 'ξ',
            xlarr: '⟵',
            xlArr: '⟸',
            xmap: '⟼',
            xnis: '⋻',
            xodot: '⨀',
            Xopf: '𝕏',
            xopf: '𝕩',
            xoplus: '⨁',
            xotime: '⨂',
            xrarr: '⟶',
            xrArr: '⟹',
            Xscr: '𝒳',
            xscr: '𝓍',
            xsqcup: '⨆',
            xuplus: '⨄',
            xutri: '△',
            xvee: '⋁',
            xwedge: '⋀',
            Yacute: 'Ý',
            yacute: 'ý',
            YAcy: 'Я',
            yacy: 'я',
            Ycirc: 'Ŷ',
            ycirc: 'ŷ',
            Ycy: 'Ы',
            ycy: 'ы',
            yen: '¥',
            Yfr: '𝔜',
            yfr: '𝔶',
            YIcy: 'Ї',
            yicy: 'ї',
            Yopf: '𝕐',
            yopf: '𝕪',
            Yscr: '𝒴',
            yscr: '𝓎',
            YUcy: 'Ю',
            yucy: 'ю',
            yuml: 'ÿ',
            Yuml: 'Ÿ',
            Zacute: 'Ź',
            zacute: 'ź',
            Zcaron: 'Ž',
            zcaron: 'ž',
            Zcy: 'З',
            zcy: 'з',
            Zdot: 'Ż',
            zdot: 'ż',
            zeetrf: 'ℨ',
            ZeroWidthSpace: '​',
            Zeta: 'Ζ',
            zeta: 'ζ',
            zfr: '𝔷',
            Zfr: 'ℨ',
            ZHcy: 'Ж',
            zhcy: 'ж',
            zigrarr: '⇝',
            zopf: '𝕫',
            Zopf: 'ℤ',
            Zscr: '𝒵',
            zscr: '𝓏',
            zwj: '‍',
            zwnj: '‌',
            '#39': "'",
        };

        let keys = Object.keys(entities);
        for (var i = 0, max = keys.length; i < max; ++i) {
            let entity = keys[i];
            text = text.replace(new RegExp('&' + entity + ';', 'g'), entities[keys[i]]);
        }

        return text;
    }


    static converTimeWithTimeZone = (date, timeZone, type) => {

        let convetedTime = ''

        if (type == 'datetime')
            convetedTime = Moment.tz(date, timeZone).format('Do MMM, hh:mm A')

        if (type == 'date')
            convetedTime = Moment.tz(date, timeZone).format('Do MMM')

        if (type == 'time')
            convetedTime = Moment.tz(date, timeZone).format('hh:mm A')

        if (type == 'datetimeyear')
            convetedTime = Moment.tz(date, timeZone).format('Do MMM YYYY, hh:mm A')

        if (type == 'datemonthyear')
            convetedTime = Moment.tz(date, timeZone).format('Do MMM YYYY')

        if (type == 'day') {
            convetedTime = Moment.tz(date, timeZone).format('dddd')
        }

        return convetedTime

    }

    static timeParsar = (text, timeZone) => {

        if (!text || !timeZone)
            return ''

        let tokens = text.split(' ')

        for (let i = 0; i < tokens.length; i++) {

            let token = tokens[i]

            if (token.startsWith("<time>")) {
                let date = token.replace(/<\/?time>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'time')
                tokens[i] = convertedTime
            }
            if (token.startsWith("<date>")) {
                let date = token.replace(/<\/?date>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'date')
                tokens[i] = convertedTime
            }
            if (token.startsWith("<datetime>")) {
                let date = token.replace(/<\/?datetime>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'datetime')
                tokens[i] = convertedTime
            }

            if (token.startsWith("<datetimeyear>")) {
                let date = token.replace(/<\/?datetimeyear>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'datetimeyear')
                tokens[i] = convertedTime
            }

            if (token.startsWith("<datemonthyear>")) {
                let date = token.replace(/<\/?datemonthyear>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'datemonthyear')
                tokens[i] = convertedTime
            }

            if (token.startsWith("<day>")) {
                let date = token.replace(/<\/?day>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'day')
                tokens[i] = convertedTime
            }

            if (token.startsWith("<time-comma>")) {
                let date = token.replace(/<\/?time-comma>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'time')
                tokens[i] = convertedTime + ','
            }
            if (token.startsWith("<date-comma>")) {
                let date = token.replace(/<\/?date-comma>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'date')
                tokens[i] = convertedTime + ','
            }
            if (token.startsWith("<datetime-comma>")) {
                let date = token.replace(/<\/?datetime-comma>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'datetime')
                tokens[i] = convertedTime + ','
            }

            if (token.startsWith("<datetimeyear-comma>")) {
                let date = token.replace(/<\/?datetimeyear-comma>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'datetimeyear')
                tokens[i] = convertedTime + ','
            }

            if (token.startsWith("<datemonthyear-comma>")) {
                let date = token.replace(/<\/?datemonthyear-comma>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'datemonthyear')
                tokens[i] = convertedTime + ','
            }

            if (token.startsWith("<day-comma>")) {
                let date = token.replace(/<\/?day-comma>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'day')
                tokens[i] = convertedTime + ','
            }

            if (token.startsWith("<time-dot>")) {
                let date = token.replace(/<\/?time-dot>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'time')
                tokens[i] = convertedTime + '.'
            }
            if (token.startsWith("<date-dot>")) {
                let date = token.replace(/<\/?date-dot>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'date')
                tokens[i] = convertedTime + '.'
            }
            if (token.startsWith("<datetime-dot>")) {
                let date = token.replace(/<\/?datetime-dot>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'datetime')
                tokens[i] = convertedTime + '.'
            }

            if (token.startsWith("<datetimeyear-dot>")) {
                let date = token.replace(/<\/?datetimeyear-dot>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'datetimeyear')
                tokens[i] = convertedTime + '.'
            }

            if (token.startsWith("<datemonthyear-dot>")) {
                let date = token.replace(/<\/?datemonthyear-dot>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'datemonthyear')
                tokens[i] = convertedTime + '.'
            }

            if (token.startsWith("<day-dot>")) {
                let date = token.replace(/<\/?day-dot>/g, '');
                let convertedTime = this.converTimeWithTimeZone(date, timeZone, 'day')
                tokens[i] = convertedTime + '.'
            }


        }

        return tokens.join(' ')
    }

    static getBooleanValue = (flag) => {

        if (!flag) {
            return "Yes"
        } else {
            return 'No'
        }
    
    }


    
    static getCategoryTitle = (themeId) => {
    
        switch (themeId) {
            case "1":
                return "Numbers Systems"
            
            case "2":
                return "Data handing"
        
            case "3":
                return "Geometry"
            
            case "4":
                return "Mathematics applications"
            
            case "5":
                return "Mensuration"
        
            case "6":
                return "Algebra"
        }
        
    }

    static toCamelCase = (str) => {

        let prepositions = [
            'of',
            'with',
            'at',
            'from',
            'into',
            'during',
            'including',
            'until',
            'against',
            'among',
            'throughout',
            'despite',
            'towards',
            'upon',
            'concerning',
            'to',
            'in',
            'for',
            'on',
            'by',
            'about',
            'like',
            'through',
            'over',
            'before',
            'between',
            'after',
            'since',
            'without',
            'under',
            'within',
            'along',
            'following',
            'across',
            'behind',
            'beyond',
            'plus',
            'except',
            'but',
            'up',
            'out',
            'around',
            'down',
            'off',
            'above',
            'near',
            'and',
            'or'
        ];
    
        return str.toLowerCase().split(' ').map(function (word) {
    
            if (!word)
                return ' ';
    
            if (prepositions.includes(word))
                return word;
    
            return word.replace(word[0], word[0].toUpperCase());
        }).join(' ');
    }


}

export default CommonUtils
import React, { Component } from 'react';
import './chapter-list.scss';
import { getImageSource } from '../../../utility/image-source';
import { ImageConstants, Constants } from '../../../constants/constants';
import SharedStaticText from "../../../../shared-storage/static-text-store";
import CommonApi from '../../../apis/common';
import ExamPrepKitApi from '../../../apis/exam-prep-kit';
import SharedProfile from '../../../../shared-storage/profile';
import Loading from '../../common/loading/loading';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default class ChapaterList extends Component {
    constructor(props) {
        super(props);
        // const { navigation } = this.props;
        // this.meta = navigation.getParam('meta');
        this.state = {
            loading: true,
            chapterList: [
            ],
            overallProgress: 0,
            mockTestPercentage: 0,
            disabled: false
        };
        this.userInfo = SharedProfile.getProfile();
        this.state.disabled = this.userInfo.isPrepkitFreeUser ? true : false;
        this.staticText = SharedStaticText.getStaticText('prepkitChaptersList');

        this.getAssessmentMeta()
        this.getMultipleChapterProgress()
        this.getChaptersList()

    }

    onPressAction = (item) => {
        this.props.delegate && this.props.delegate.showChapterTableOfContents && this.props.delegate.showChapterTableOfContents({
            chapterTitle: item.title, chapterKey: item.key, categoryThemeId: item.categoryThemeId, isLocked : item.isLocked,
            from: 'CHAPTERS_LIST', imageUrl: item.imageUrl ? item.imageUrl : '', masterDataId: this.userInfo.masterDataId
        })
        // this.props.navigation.navigate('ExamPrepKitTableOfContent', { meta: { chapterTitle: item.title, chapterKey: item.key, categoryThemeId: item.categoryThemeId, from: 'CHAPTERS_LIST' } });
    }

    getMultipleChapterProgress = () => {

        let payload = {
        }

        ExamPrepKitApi.getMultipleChapterProgress(payload).then((response) => {
            console.log(response)
            this.setState({ mockTestPercentage: response.percentage })
        }).catch(err => {
            console.log(err)
            if (err) {
                return
            }
        })

    }

    getChaptersList = () => {

        let payload = {
        }

        if (!this.state.loading)
            this.setState({ loading: true })

        ExamPrepKitApi.getPrepKitChapterList(payload).then((response) => {
            console.log('res', response)
            this.setState({
                chapterList: response.chapters,
                chapterKeys: response.chapterKeys,
                examDateInfo: response.examDateInfo,
                loading: false,
                overallProgress: response.overallProgress,
                chapters: response.chapters
            })
        }).catch(err => {
            console.log(err)

            if (err) {
                this.setState({ loading: false })
                return
            }

        })

    }

    getAssessmentMeta = () => {
        CommonApi.getMiscellaneousList({ type: 'ASSESSMENT_META' }).then((data) => {
            if (data) {
                this.setState({ assessmentMeta: data.response[0] })
            }
        }).catch(err => {
            console.log(err)
            if (err) {
                return
            }
        })
    }

    onPressMultipleChapters = () => {
        this.setState({ showSelectChapterModal: true })
    }

    goBack = () => {

        // this.props.navigation.goBack();
        this.props.navigation.navigate("LandingScreen");
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }


    onClickLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }


    renderChapterList = () => {

        let elements = []

        for (let i = 0; i < this.state.chapterList.length; i++) {

            let item = this.state.chapterList[i]

            elements.push(
                <div onClick={() => this.onPressAction(item)}
                    className='d-flex align-items-center justify-content-between chapter-card pl-0 pt-3 pb-3 cursor-pointer'
                    style={{backgroundColor : item.isLocked ? 'rgb(246, 245, 245)' : ''}}>
                    <div className='d-flex align-items-center'>
                        {/* <div className='mr-2' style={{ width: '30px', height: '30px' }}>
                            <CircularProgressbar value={item.percentage && item.percentage > 0 ? item.percentage : 0}
                                strokeWidth={15}
                            />
                        </div> */}
                        <div className='d-flex align-items-center'>
                            <div className='mr-2'>
                                {
                                    item.imageUrl ? <img className='chapter-icon' src={item.imageUrl}></img> :
                                        <img className='chapter-icon' src={getImageSource(ImageConstants.CHAPTER_ICN)} />
                                }
                            </div>
                            <div>
                                <div className='chapter-title'>{item.title}</div>
                            </div>
                        </div>
                    </div>
                    {
                        item.isLocked ?
                            <div className=''>
                                <img className='lock-icon-size mr-3' src={getImageSource(ImageConstants.LOCK)} />
                            </div>
                            :
                            <div>
                                <img className='arrow-image-size' src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                            </div>
                    }

                </div>
            )
        }

        return elements
    }


    showSelectChapterModal = () => {
        this.setState({ showSelectChapterModal: true })
    }

    hideSelectChapterModal = (callback) => {
        this.setState({ showSelectChapterModal: false }, () => {
            callback && callback()
        })
    }

    showSelectDuration = (data) => {
        this.setState({ showSelectDuration: true, selectedChapters: data })
    }

    hideSelectDuration = (value) => {
        this.setState({ showSelectDuration: false }, () => {

            // if (Platform.OS == 'ios') {

            //     setTimeout(() => {
            //         if (value)
            //             this.setState({ showSelectChapterModal: true })
            //     }, 500)
            // } else {
            //     if (value)
            //         this.setState({ showSelectChapterModal: true })
            // }

        })
    }

    editChapters = () => {
        this.props.navigation.navigate('ExamPrepKitChapterSelectionScreen', { meta: { chapters: this.state.chapterKeys, from: 'CHAPTERS_LIST' } })
    }

    editExamDate = () => {
        this.props.navigation.navigate('ExamDateCaptureScreen', { meta: { examDateInfo: this.state.examDateInfo ? this.state.examDateInfo : {}, from: 'CHAPTERS_LIST' } })
    }

    onStart = (data) => {
        this.props.navigation.navigate('StartAssessment', { meta: { chapterKey: data.chapterKey, chapterTitle: data.chapterTitle, categoryThemeId: data.categoryId } })
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2' >{`Prepare`}</span>
            </div>
        )
    }


    render() {

        return (
            <>
                {
                    !this.state.loading ?
                        <div className='prep-kit-chapter-list'>

                            <div className="text-right">
                                <img className="header-image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                            </div>
                            {
                                this.renderBreadcrumbForChapterList()
                            }
                            <div className='revise-text'>Choose a Chapter to revise</div>
                            <div className='mt-2'>
                                <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                            </div>
                            <div>
                                <div className='d-flex mt-3 align-items-center'>
                                    <div className='chapter-progress-bar'>
                                        <div className='chapter-inner-progress' style={{ width: `${this.state.overallProgress}%` }}>
                                        </div>
                                    </div>
                                    <div className='ml-2 progress-value'>{`${this.state.overallProgress}%`}</div>
                                </div>
                            </div>
                            <div className='mt-4 mb-5'>
                                {this.renderChapterList()}
                            </div>
                        </div>
                        :
                        <Loading />
                }
            </>

        )
    }

}

import React from 'react';
import './learning-footer.scss';
import { ImageConstants, FirebaseEvents, Constants } from '../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import SharedThemeCategory from '../../../../shared-storage/category-theme';
import FireBaseEvent from '../../../firebase-events/firebase-events';
import SharedProfile from '../../../../shared-storage/profile';
import ReactTooltip from 'react-tooltip';


export default class LearningFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.userInfo = SharedProfile.getProfile()

    }

    previous = () => {


        this.props.delegate.previousElo(this.props.position);

    }

    closePopup = () => {
        this.props.delegate && this.props.delegate.closePopup && this.props.delegate.closePopup();
    }

    next = () => {

        if (this.props.isChapterIntro) {
            this.props.delegate.hideChapterIntroduction();
        } else {

            if (!this.props.isRefresherCourse) {
                // Moengage.logCustomEvent('Elo', { "User Id":this.userInfo.userId, 'Elo Name': this.state.eloName, 'Chapter':this.props.title,'Elo Skill':this.state.eloSkill,'Elo Status':this.state.eloStatus,concept:this.props.currentConcept.title,'Starting Point':'Browse Chapters'});
                FireBaseEvent('Elo', { "User Id": this.userInfo.userId, 'Elo Name': this.props.title, 'Chapter': this.props.chapterTitle, 'Elo Skill': this.props.currentEloSkill, 'Elo Status': "CLEARED", concept: this.props.conceptTitle, 'Starting Point': 'Browse Chapters' });
                // Facebook.logCustomEvent('Elo', { "User Id":this.userInfo.userId, 'Elo Name': this.state.eloName, 'Chapter':this.props.title,'Elo Skill':this.state.eloSkill,'Elo Status':this.state.eloStatus,concept:this.props.currentConcept.title,'Starting Point':'Browse Chapters'});
                // BranchIO.logCustomEvent('Elo', { "User Id":this.userInfo.userId, 'Elo Name': this.state.eloName, 'Chapter':this.props.title,'Elo Skill':this.state.eloSkill,'Elo Status':this.state.eloStatus,concept:this.props.currentConcept.title,'Starting Point':'Browse Chapters'});
                FireBaseEvent(FirebaseEvents.ELO_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, eloName: this.props.title, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
                // Facebook.logCustomEvent(FirebaseEvents.ELO_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, eloName: this.props.data.cleared[0].title, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                // BranchIO.logCustomEvent(FirebaseEvents.ELO_COMPLETED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, eloName: this.props.data.cleared[0].title, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });

            }
        }

        this.props.delegate.nextElo(this.props.position);
    }

    // getView() {
    //     return <View style={this.props.fixedFooter ? Styles.fixedFooter : Styles.footer}>

    //         <ImageBackground source={getThemeImageSource(ImageConstants.FOOTER, this.props.categoryId)} style={Styles.background} resizeMode="cover" >

    //             <View style={Styles.iconsAlignment}>

    //                 {this.props.isPreviousEnabled ? <TouchableOpacity onPress={this.previous.bind(this)} style={[Styles.previousIcon]} hitSlop={{ top: 25, bottom: 15, left: 15, right: 15 }}>
    //                     <Image source={getThemeImageSource(ImageConstants.PREVIOUS_BUTTON, this.props.categoryId)} style={Styles.previous} resizeMode="cover" />
    //                 </TouchableOpacity>
    //                     :
    //                     <></>}

    //                 <View style={Styles.icons} hitSlop={Styles.hitSlop} ></View>





    //             </View>

    //         </ImageBackground>

    //     </View>

    // }

    render() {
        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)
        let buttonBorder = this.theme['themeColor']

        return (

            <>
                {
                    (this.props.isPopup && this.props.isLastElo) || this.props.isLateralMenuPopup ?
                        <div className='learning-footer'>
                            <div className="d-flex justify-content-end align-items-end button-wrapper">
                                {/* {startIndex != 0 ? <div className="text-center back-button cursor-pointer mr-5">Back</div> : <></>} */}

                                <div className="text-center next-button cursor-pointer"
                                    style={{ background: this.theme['themeColor'], border: `2px solid ${buttonBorder}` }}
                                    onClick={this.closePopup} >
                                    {'Next'}
                                </div>
                            </div>
                            <img className='footer-image-size' src={getImageSource(ImageConstants.ASSESSMENT_FOOTER_IMAGE)} />
                        </div>

                        :

                        <div className='learning-footer' onMouseEnter={ReactTooltip.rebuild}>

                            <div className="d-flex justify-content-end align-items-end button-wrapper">
                                {/* {startIndex != 0 ? <div className="text-center back-button cursor-pointer mr-5">Back</div> : <></>} */}
                                {
                                    this.props.blur && this.props.isRefresherCourse ?
                                        <div className="text-center next-button"
                                            style={{ border: "none", background: "#d1d3d5", color: this.theme['themeColor'] }}
                                            data-tip={`Please answer the above question(s) to continue`}>
                                            {'Next'}
                                        </div>
                                        :
                                        <div className="text-center next-button cursor-pointer"
                                            style={{ background: this.theme['themeColor'], border: `2px solid ${buttonBorder}` }}
                                            onClick={this.next.bind(this)} >
                                            {'Next'}
                                        </div>
                                }

                            </div>
                            <img className='footer-image-size' src={getImageSource(ImageConstants.ASSESSMENT_FOOTER_IMAGE)} />
                        </div>
                }

                <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                    multiline={true}
                    place='right'
                    type='light' effect='solid'
                    event="mouseover mouseenter"
                    eventOff="mouseleave mouseout scroll mousewheel blur" />

            </>
        );
    }
}


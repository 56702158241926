import React from "react";
import './chapter-list-popup.scss';
import { Modal, ModalBody } from "reactstrap";
import { getThemeImageSource, getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import Parser from "../../common/content-parser/content-parser";
import SharedThemeCategory from "../../../../shared-storage/category-theme";


class ChapterListPopup extends React.Component {

    staticText = {};

    constructor(props) {
        super(props);
        // this.parser = new Parser();
        // this.glossaryParser = new GlossaryParser();
        // //console.log(this.props.chapters)
    }
    state = {}

    closeModal = () => {
        this.props.close && this.props.close();
    }


    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (
            <Modal className="cw-custom-modal-center-for-chapter-list" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_HEADER, this.props.categoryThemeId)}></img>
                    <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.closeModal()} />
                </div>
                <ModalBody>
                    <div className="pratice-container">
                        <div className="text-center name-header">{'Chapter List'}</div>
                        <div className="d-flex justify-content-center flex-column concepts-covered-wrapper">
                            {this.props.chapters && this.props.chapters.length && this.props.chapters.map((item, index) =>
                                <div>
                                    <li className="elo-title">{item.title}</li>
                                    {/* {item.elos.length && item.elos.map((elo, eloIndex) =>
                                        <div>
                                            <li className="elo-title">
                                                {elo.title}
                                            </li>
                                        </div>
                                    )} */}
                                </div>
                            )}
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <div className="take-challenge cursor-pointer  text-center align-self-center pt-2" onClick={this.closeModal} style={{ backgroundColor: this.theme['themeColor'] }}>
                                {'OKAY'}
                            </div>
                        </div>
                        <div className="position">
                            <img className="challenge-footer" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_FOOTER, this.props.categoryThemeId)}></img>
                        </div>
                    </div>
                </ModalBody>


            </Modal>
        )
    }
}

export default ChapterListPopup;
import React, { Component } from 'react';
import CwMath from "../cw-math-component/cw-math-component.js";
import { getImageSource } from '../../../utility/image-source.js';
import { ImageConstants } from '../../../constants/constants.js';
let parse = require('html-react-parser');



class JsxParsar extends Component {

    jsx = '';

    constructor(props) {
        super(props);

        // console.log("parse", parse)

        console.log(this.props.from)

        // let counter = 0;
        let videoCount = 0;
        if (this.props.data) {
            this.jsx = parse.default(this.props.data, {
                replace: domNode => {
                    // console.log(domNode)

                    if (domNode.name == 'cw-math') {
                        return <CwMath mathExp={domNode.attribs.data}></CwMath>
                    }
                    if (domNode.name == 'video') {
                        return <video src={domNode.attribs.src} controls  ></video>
                    }


                    if (domNode.name == 'cw-step') {
                        // console.log(domNode)
                        return <p className={`cw-step ${this.props.from == "video_only" ? "display-none" : ''} `}>{domNode.children[0].data}</p>
                    }

                    if (this.props.from == "video_only" && domNode.name !== 'div' && domNode.attribs.class !== "ckeditor-html5-video") {
                        // console.log(domNode)
                        return <></>
                    }
                    if (this.props.from == "case_study" && domNode.name == 'p') {
                        // console.log(domNode)
                        return <p className='case_study_title'>{domNode.children[0].data}</p>
                    }

                    if (domNode.type === 'text' && domNode.data === ' ') {
                        console.log(domNode)
                        return (<></>)
                    }

                    if (domNode.name == 'div' && domNode.attribs.class == "ckeditor-html5-video") {
                        videoCount += 1

                        if (videoCount > 1 && this.props.showOnlyOneVideo) {
                            return <></>
                        }

                        return <div className={`video-content-alignment cursor-pointer ${this.props.from == "video_content" ? "display-none" : ''}`}>
                            <video
                                src={domNode.children[0].attribs.src}
                                controls
                                controlsList="nodownload"
                                poster={getImageSource(ImageConstants.VIDEO_POSTER)}
                                onEnded={() => this.props.videoEnded(domNode.children[0].attribs.src)}
                                onPlay={() => this.props.videoPlayed(domNode.children[0].attribs.src)}
                            >
                            </video>
                            {/* <ReactPlayer
                            controls
                            light={<img className="poster-image-size" src={getImageSource(ImageConstants.VIDEO_POSTER)} />}
                            playing={true}
                            playIcon={<img />}
                            url={domNode.children[0].attribs.src}
                          ></ReactPlayer> */}
                        </div>
                    }
                }
            });
        }

    }



    render() {
        return (<>{this.jsx}</>);
    }
}
export default JsxParsar;
import React, { Component } from 'react';
import './notification.scss';
import { Tooltip } from "reactstrap";
import { Constants, ImageConstants } from '../../../constants/constants';
import Utils from '../../../utility/common-utilities';
import HomeworkApi from '../../../apis/homework'
import { getImageSource } from '../../../utility/image-source'
import moment from 'moment';
import Dropdown from '../../common/dropdown-v2/dropdown';
import CommonApi from '../../../apis/common'
// import SessionCache from '../../../routes/session-cache';

class Reminder extends Component {

    state = {
        message: this.props.message,
        emails: [""],
        whatsapp: [{ countryCode: "+91" }],
        sms: [{ countryCode: "+91" }],
        type: this.props.type,
        countryCodeDropdown: [],
        invalidSms: {},
        invalidEmails: [],
        invalidWhatsapp: {}
    }
    notificationText = "An assignment has been created for you. You must complete this assignment on the Countingwell App. To view and complete the assignment, open this link on your mobile phone: "
    notificationLink = Constants.SCHOOL_ASSESSMENT_DEEPLINK
    countryCodeHash = {}

    componentDidMount() {
        this.getCountryCodes()

        // this.userInfo = SessionCache.getUser()
    }

    getCountryCodes(payload = {}) {
        CommonApi.getCountryCode(payload)
            .then(response => {
                let data = response.response
                if (data.length) {
                    this.setState({ countryList: data })
                    let countryCodeDropdown = this.buildCountryCodeDropdown(data)
                    this.setState({ countryCodeDropdown })
                }
            })
            .catch(err => { })
    }

    buildCountryCodeDropdown(list = []) {
        let countryCodeDropdown = []
        let localHash = {}
        for (let i = 0; i < list.length; i++) {
            let item = list[i]

            if (!localHash[item.dialCode]) {
                this.countryCodeHash[item.dialCode] = item
                localHash[item.dialCode] = item
                countryCodeDropdown.push({
                    title: `${item.name} ${item.dialCode}`,
                    addOn: item.name,
                    value: item.dialCode
                })
            }
        }
        this.setState({ hashedCountryCode: this.countryCodeHash, reloadDropdown: true }, () => {
            this.setState({ reloadDropdown: false })
        })
        return countryCodeDropdown
    }

    onClickBack = () => {
        this.props.back()
    }

    onClickInfo = () => {
        this.setState({ showInfoModal: true })
    }

    hideInfoModal = () => {
        this.setState({ showInfoModal: false })
    }

    getColor(skill) {
        let colors = Constants.SKILLS_COLOR
        let color = colors[skill]
        return color
    }

    onChangeHandler = (event, key) => {
        this.setState({ [key]: event?.target?.value })
    }

    reminderNote() {
        let count = this.props.notificationCount
        let day = moment(moment()).diff(moment(this.props.notificationDate), 'days')
        if (!day) {
            return `${count} Reminders sent already today`
        }
        return `${count} Reminders sent already, Last sent ${day} days back`
    }

    onChangeEmail(event, index) {
        let emails = this.state.emails || []
        emails[index] = event.target.value
        this.setState({ emails })
    }

    onChangeWhatsapp(event, index) {
        let whatsapp = this.state.whatsapp || []
        whatsapp[index].mobileNumber = event.target.value
        this.setState({ whatsapp })
    }

    onChangeSms(event, index) {
        let sms = this.state.sms || []
        sms[index].mobileNumber = event.target.value
        this.setState({ sms })
    }

    onClickAddEmail = () => {
        let emails = this.state.emails
        emails.push("")
        this.setState({ emails })
    }

    onClickAddWhatsapp = () => {
        let whatsapp = this.state.whatsapp
        whatsapp.push({ countryCode: "+91" })
        this.setState({ whatsapp })
    }
    onClickAddSms = () => {
        let sms = this.state.sms
        sms.push({ countryCode: "+91" })
        this.setState({ sms })
    }


    onClickRemoveEmail(index) {
        let emails = this.state.emails
        if (emails.length == 1) {
            emails = [""]
        } else {
            emails.splice(index, 1)
        }
        this.setState({ emails })
    }

    onClickRemoveWhatsapp(index) {
        let whatsapp = this.state.whatsapp
        if (whatsapp.length == 1) {
            whatsapp = [{ countryCode: "+91" }]
        } else {
            whatsapp.splice(index, 1)
        }
        this.setState({ whatsapp })
    }

    onClickRemoveSms(index) {
        let sms = this.state.sms
        if (sms.length == 1) {
            sms = [{ countryCode: "+91" }]
        } else {
            sms.splice(index, 1)
        }
        this.setState({ sms })
    }


    onClickChannel(type) {
        this.setState({ type })
        this.props.onChangeType(type)
    }

    onSelectDropdown(option, index, channel) {
        let chennal = this.state[channel]
        chennal[index].countryCode = option?.value
        this.setState({ [channel]: chennal })
    }

    shareLink = () => {

        if (!this.validatePayload()) return false
        let payload = {
            assessmentId: this.props.assessment?._id,
            type: this.state.type,
            from: this.props.from || "ASSESSMENT"
        }
        if (this.state.type == "EMAIL") {
            payload.emails = this.state.emails

        } else {
            payload.mobileNumbers = this.state.type == "SMS" ? this.state.sms : this.state.whatsapp
        }

        // //console.log(payload)

        HomeworkApi.homeworkDeeplink(payload).then(data => {
            this.saving = false
            this.props.onOk && this.props.onOk()
        }).catch(err => {
            this.saving = false
        })

    }

    validatePayload() {

        if (this.state.type == "EMAIL") {
            let emails = this.state.emails
            let invalidEmails = []
            for (let i = 0; i < emails.length; i++) {
                if (!Utils.validateEmail(emails[i])) {
                    invalidEmails.push(i)
                }
            }

            this.setState({ invalidEmails })
            return !invalidEmails.length

        } else if (this.state.type == "WHATSAPP") {
            let whatsapp = this.state.whatsapp
            let invalidWhatsapp = {}
            for (let i = 0; i < whatsapp.length; i++) {

                if (!whatsapp[i].countryCode) {
                    invalidWhatsapp[i] = "Please Select the Country Code"
                    continue
                }

                let countryCodeDetail = this.countryCodeHash[whatsapp[i].countryCode]
                if (!Utils.validateMobileNumber(whatsapp[i].mobileNumber, countryCodeDetail)) {
                    invalidWhatsapp[i] = "Please Enter Mobile Number"
                }
            }

            this.setState({ invalidWhatsapp })
            return !Object.keys(invalidWhatsapp).length
        } else if (this.state.type == "SMS") {
            let sms = this.state.sms
            let invalidSms = {}

            for (let i = 0; i < sms.length; i++) {

                if (!sms[i].countryCode) {
                    invalidSms[i] = "Please Select the Country Code"
                    continue
                }

                let countryCodeDetail = this.countryCodeHash[sms[i].countryCode]

                if (!Utils.validateMobileNumber(
                    sms[i].mobileNumber,
                    countryCodeDetail
                )) {
                    invalidSms[i] = "Please Enter Mobile Number"
                }
            }

            this.setState({ invalidSms })
            return !Object.keys(invalidSms).length
        }

        return false
    }

    onClickCopy = () => {
        let textField = document.createElement('textarea')
        textField.innerText = `${this.notificationText} ${this.notificationLink}?schoolCode=${this.userInfo?.schoolInfo?.schoolCode}`
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        this.showTooltip()
    }

    showTooltip() {
        this.setState({ tooltipOpen: true })
        setTimeout(() => {
            this.setState({ tooltipOpen: false })
        }, 1000)
    }

    render() {
        return (
            <div className='assessment-notification'>
                <div className="text-center pt-4">
                    <span className="note">By requesting the link, you agree to receiving communication from us via WhatsApp / SMS or other text messaging channels to the phone number you provide.</span>
                </div>

                {/* <div className='separator'>
                    <img src={getImageSource(ImageConstants.SEPARATOR)} />
                </div> */}

                {/* <div className='share-container mt-2 my-2'>
                    <div className='share-left'>
                        <div className='step'>1</div>
                    </div>
                    <div className='share-right'>
                        <div className='title'>COPY & SHARE</div>
                        <div className='description'>
                            Copy the link below and paste it in an email or WhatsApp and share
                            with Students
                        </div>
                    </div>
                </div>
                <div className='copy-container'>
                    <div className='share-link'>{`${Constants.SCHOOL_ASSESSMENT_DEEPLINK}?schoolCode=${this.userInfo?.schoolInfo?.schoolCode}`}</div>
                    <div
                        className='copy-button cursor-pointer'
                        onClick={this.onClickCopy}
                        id={`copy-tool-tip`}
                    >
                        <img src={getImageSource(ImageConstants.CLONE_WHITE)} />{' '}
                        <span>COPY</span>
                        <Tooltip placement="right" isOpen={this.state.tooltipOpen} autohide={false} target={`copy-tool-tip`}>
                            Copied
                        </Tooltip>
                    </div>
                </div>
                <div className='separator'>
                    {/* <img src={getImageSource(ImageConstants.SEPARATOR)} /> */}
                    {/* <span className='separator-text'>OR</span> */}
                {/* </div>  */}

                {/* <div className='share-container mt-2 my-2'>
                    <div className='share-left'>
                        <div className='step'>2</div>
                    </div>
                    <div className='share-right'>
                        <div className='title'>INSTANT SHARE ON {this.state.type == "EMAIL" ? "EMAIL" : "WHATSAPP"}</div>
                        <div className='description'>
                            Send to yourself by entering
                            your {this.state.type == "EMAIL" ? "Email" : "number"} and forward it to other users from your
                            own {this.state.type == "EMAIL" ? "Email" : "Whatsapp number"}.
                        </div>
                    </div>
                </div> */}

                <div className="message-wrapper">
                    {
                        this.state.type == "EMAIL" ?
                            <div>
                                <div className="row mt-4">
                                    <div className="col px-0 my-auto">
                                        {
                                            this.state.emails?.map((item, index) =>
                                                <div className={`mt-2 ${this.state.invalidEmails.includes(index) ? "invalid" : ""}`}>
                                                    <input className="cw-input" placeholder="Please Enter Email" value={item} onChange={event => this.onChangeEmail(event, index)} />
                                                    <img className="delete icon-20 mt-2 ml-2 cursor-pointer" onClick={() => this.onClickRemoveEmail(index)} src={getImageSource(ImageConstants.DELETE_RED)} />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="text-right text-primary add pt-2">
                                    <span className="cursor-pointer" onClick={this.onClickAddEmail}>+ Add Email</span>
                                </div>
                            </div>
                            : ""
                    }
                    {
                        this.state.type == "WHATSAPP" ?
                            <div>
                                <div className='mt-4'>
                                    <span className='country-code-text country-code-margin-left'>Country Code <span className='red-indication'>*</span></span><span className='country-code-text mobile-number-ml'>Mobile Number <span className='red-indication'>*</span></span>
                                </div>
                                <div className="row ">
                                    <div className="col px-0 my-auto">
                                        {
                                            this.state.whatsapp?.map((item, index) =>
                                                <div className={`mt-2 `}>
                                                    <div className={`row mobilenumber-wrapper px-0 ${this.state.invalidWhatsapp[index] ? "invalid" : ""}`}>
                                                        <div className="col-4 px-0">
                                                            {
                                                                !this.state.reloadDropdown ?
                                                                    <Dropdown
                                                                        placeholder='Select Country Code'
                                                                        options={this.state.countryCodeDropdown}
                                                                        onSelect={(option) => this.onSelectDropdown(option, index, "whatsapp")}
                                                                        text={"title"}
                                                                        value={item?.countryCode || ""}
                                                                        style={{ backgroundColor: "#F6F7F5" }}
                                                                    />
                                                                    : ""
                                                            }
                                                        </div>
                                                        <div className="col-8 px-0">
                                                            <input className="cw-input-for-app-link" placeholder="Please Enter Mobile" value={item.mobileNumber} onChange={event => this.onChangeWhatsapp(event, index)} />
                                                            <img className="delete icon-20 mt-2 ml-2 cursor-pointer" onClick={() => this.onClickRemoveWhatsapp(index)} src={getImageSource(ImageConstants.DELETE_RED)} />
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="text-right text-primary add pt-2">
                                    <span className="cursor-pointer" onClick={this.onClickAddWhatsapp}>+ ADD WHATSAPP NO.</span>
                                </div>
                            </div> : ""
                    }

                    <div className="text-center pt-4">
                        <span className="share cursor-pointer" onClick={this.shareLink}>Share Link</span>
                    </div>

                    {/* <div className="text-center pt-4">
                        <span className="note mr-2">Share link via other channels:</span>
                        {this.state.type != "EMAIL" ? <img className="icon-20 mr-2 cursor-pointer" onClick={() => this.onClickChannel("EMAIL")} src={getImageSource(ImageConstants.EMAIL)} /> : ""}
                        {this.state.type != "WHATSAPP" ? <img className="icon-20 mr-2 cursor-pointer" onClick={() => this.onClickChannel("WHATSAPP")} src={getImageSource(ImageConstants.WHATSAPP)} /> : ""}
                    </div> */}
                </div>

            </div >
        );
    }
}

export default Reminder;
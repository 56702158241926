import React from "react";
import './add-profile-image-popup.scss';
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedProfile from "../../../../shared-storage/profile";
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop'
import Apiprovider from "../../../apis/api-provider";
import CommonApi from "../../../apis/common";

class AddProfileImagePopup extends React.Component {


    constructor(props) {
        super(props)
    }

    state = {
        uploadedImage: '',
        crop: {
            unit: '%', // Can be 'px' or '%'
            x: 25,
            y: 25,
            width: 50,
            height: 50
        }
    }

    isProfileImageAvailable = false

    crop = {
        unit: '%', // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 50,
        height: 50
    }

    componentDidMount() {
        let userInfo = SharedProfile.getProfile()
        this.setState({ basePath: userInfo.basePath, image: userInfo.image, userInfo })
    }


    close = () => {
        this.props.delegate && this.props.delegate.hideEditProfileImagePopup && this.props.delegate.hideEditProfileImagePopup()
    }

    onSelectFile = (event) => {
        let file = event.target.files[0];

        if (!file) {
            return
        } else {
            document.getElementById("input1").value = null
        }

        let fileName = file.name.split(".")
        let extention = fileName[fileName.length - 1]
        fileName.length = fileName.length - 1
        let name = `${fileName.join("")}_${new Date().getTime()}.${extention}`
        this.setState({ uploadedImage: file })
        //console.log(name)
        let payload = {
            attachments: [name],
            type: 'PROFILE'
        };

        Apiprovider.getMultpleSignedUrls(payload)
            .then(response => {
                this.uploadFile(response.data[0], file);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    uploadFile = (signedUrl, file) => {
        let type = ""
        Apiprovider.uploadFileAwsS3(signedUrl, file)
            .then(response => {
                let splittedSignedUrl = signedUrl.split("?");
                splittedSignedUrl = splittedSignedUrl[0].split("/")
                let image = splittedSignedUrl[splittedSignedUrl.length - 1]
                let baseUrl = signedUrl.split(image)[0]
                this.setState({ image, basePath: baseUrl });
                this.isProfileImageAvailable = true
                this.updateProfile()
            })
            .catch(error => {
                //console.log(error);
            });
    };

    updateProfile = () => {

        let payload = {
            image: this.state.image
        }

        CommonApi.updateProfile(payload).then((response) => {

            
            this.setState({ image: this.state.image })
            let userInfo = { ...this.state.userInfo }
            userInfo['image'] = this.state.image;
            SharedProfile.setProfile(userInfo);
            // this.toggleLoading(false)
        }).catch(err => {
            if (err) {
                // this.toggleLoading(false)
                return;
            }
        })
    }


    setCrop(c) {
        //console.log(c)
    }

    onImageLoad = (e) => {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = centerCrop(
            makeAspectCrop(
                {
                    // You don't need to pass a complete crop into
                    // makeAspectCrop or centerCrop.
                    unit: '%',
                    width: 90,
                },
                16 / 9,
                width,
                height
            ),
            width,
            height
        )

        this.setCrop(crop)
    }



    render() {
        return (
            <Modal className="custom-add-profile-popup" isOpen={true}>
                <ModalBody>
                    <div className="">
                        <div className="header d-flex justify-content-between align-items-center p-4">
                            <div className="edit-title">Edit Picture</div>
                            <div>
                                <img className="close-icon cursor-pointer"
                                    onClick={this.close} src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <div className="profile-circle d-flex justify-content-center align-items-center">
                                {
                                    this.state.basePath ? <img className="std-image" src={`${this.state.basePath + this.state.image}`}></img> : <img className="std-image" src={getImageSource(ImageConstants.STUDENT_IMAGE)}></img>
                                }
                            </div>
                        </div>
                        {/* <ReactCrop crop={this.crop} onChange={c => this.setCrop(c)}
                        >
                            <img onLoad={this.onImageLoad} src={this.state.uploadedImage ? URL.createObjectURL(this.state.uploadedImage) : ''} />
                        </ReactCrop> */}
                        <div className="d-flex justify-content-center mt-2">
                            <div className="upload-button d-flex justify-content-center align-items-center">
                                <label className={`text-center mb-0 ml-1 cursor-pointer`}>
                                    Upload from PC
                                    <input type="file" style={{ "display": "none" }}
                                        accept='.png,.jpeg,.jpg' id="input1"
                                        onChange={(event) => this.onSelectFile(event)} />
                                </label>
                            </div>
                            
                        </div>

                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default AddProfileImagePopup;

export let getStaticText = (input) => {

    //console.log(input)

    let staticText = {};

    if (input && input.loginScreen) {
        staticText.loginScreen = {
            loginTitle: input.loginScreen.loginTitle,
            invalidEmailMessage: input.loginScreen.invalidEmailMessage,
            invalidPasswordMessage: input.loginScreen.invalidPasswordMessage,
            emailPlaceholder: input.loginScreen.emailPlaceholder,
            passwordPlaceholder: input.loginScreen.passwordPlaceholder,
            forgotPasswordTitle: input.loginScreen.forgotPasswordTitle,
        }
    } else { //DEFAULT
        staticText.loginScreen = {
            loginTitle: "LOGIN",
            invalidEmailMessage: "Enter valid email",
            invalidPasswordMessage: "Enter valid password",
            emailPlaceholder: "Email",
            passwordPlaceholder: "Password",
            forgotPasswordTitle: "Forgot Password?"
        }
    }

    if (input && input.signUpScreen) {
        staticText.signUpScreen = {
            signUpTitle: input.signUpScreen.signUpTitle,
            invalidEmailMessage: input.signUpScreen.invalidEmailMessage,
            invalidPasswordMessage: input.signUpScreen.invalidPasswordMessage,
            invalidConfirmPasswordMessage: input.signUpScreen.invalidConfirmPasswordMessage,
            namePlaceholder: input.signUpScreen.namePlaceholder,
            emailPlaceholder: input.signUpScreen.emailPlaceholder,
            passwordPlaceholder: input.signUpScreen.passwordPlaceholder,
            confirmPasswordPlaceholder: input.signUpScreen.confirmPasswordPlaceholder,
        }
    } else {
        staticText.signUpScreen = {
            signUpTitle: "REGISTER",
            invalidEmailMessage: "Enter valid email",
            invalidPasswordMessage: "Enter valid password(Minimum 6 Characters)",
            invalidConfirmPasswordMessage: "Enter valid confirm password",
            namePlaceholder: "Enter Your Full Name",
            emailPlaceholder: "Enter Your Email",
            passwordPlaceholder: "Minimum 6 characters",
            confirmPasswordPlaceholder: "Re-enter Password"
        }
    }

    if (input && input.forgotPasswordScreen) {
        staticText.forgotPasswordScreen = {
            title: input.forgotPasswordScreen.title,
            subTitle: input.forgotPasswordScreen.subTitle,
            sendButtonTitle: input.forgotPasswordScreen.sendButtonTitle,
            invalidEmailMessage: input.forgotPasswordScreen.invalidEmailMessage,
            unRegisteredEmailMessage: input.forgotPasswordScreen.unRegisteredEmailMessage,
            emailPlaceholder: input.forgotPasswordScreen.emailPlaceholder,
        }
    } else {
        staticText.forgotPasswordScreen = {
            title: "Forgot Password?",
            subTitle: "Enter your mobile no & you’ll receive an OTP in your mobile to reset your password",
            sendButtonTitle: "SEND",
            invalidPasswordMessage: "Please enter valid email id",
            invalidConfirmMessage: "This email is not registered with countingwell",
            emailPlaceholder: "Email"
        }
    }

    if (input && input.guardianforgotPassword) {
        staticText.guardianforgotPassword = {
            mobilePlaceHolder: input.guardianforgotPassword.mobilePlaceHolder,
            defaultCountryCode: input.guardianforgotPassword.defaultCountryCode,
            invalidMobileMessage: input.guardianforgotPassword.invalidMobileMessage,
            title: input.guardianforgotPassword.title,
            subTitle: input.guardianforgotPassword.subTitle,
            sendButtonTitle: input.guardianforgotPassword.sendButtonTitle,
            unRegisteredMobileNumberMessage: input.forgotPasswordScreen.unRegisteredMobileNumberMessage,
            mobileNumberTitle: input.forgotPasswordScreen.mobileNumberTitle,
            internetErrorMessage: input.forgotPasswordScreen.internetErrorMessage,
            serverErrorMessage: input.forgotPasswordScreen.serverErrorMessage,

        }
    } else {
        staticText.guardianforgotPassword = {
            mobilePlaceHolder: "Enter your Mobile no.",
            defaultCountryCode: "+91",
            invalidMobileMessage: "Enter valid Mobile number",
            title: "Forgot Password?",
            subTitle: "Enter your mobile number and you'll receive an OTP in your mobile number to reset your password",
            sendButtonTitle: "SEND",
            unRegisteredMobileNumberMessage: "This mobile number is not registered with countingwell",
            mobileNumberTitle: "MOBILE NO",
            internetErrorMessage: 'Please check your internet connection and try again',
            serverErrorMessage: 'OOPS! Something went wrong'
        }
    }

    if (input && input.resetPasswordScreen) {
        staticText.resetPasswordScreen = {
            title: input.resetPasswordScreen.title,
            subTitle: input.resetPasswordScreen.subTitle,
            invalidOtpMessage: input.resetPasswordScreen.invalidOtpMessage,
            invalidPasswordMessage: input.resetPasswordScreen.invalidPasswordMessage,
            invalidConfirmPasswordMessage: input.resetPasswordScreen.invalidConfirmPasswordMessage,
            changePasswordButtonText: input.resetPasswordScreen.changePasswordButtonText,
            passwordPlaceholder: input.resetPasswordScreen.passwordPlaceholder,
            confirmPasswordPlaceholder: input.resetPasswordScreen.confirmPasswordPlaceholder,
            unRegisteredMobileNumberMessage: input.resetPasswordScreen.unRegisteredMobileNumberMessage,
            invalidMobileMessage: input.resetPasswordScreen.invalidMobileMessage,
            internetErrorMessage: input.resetPasswordScreen.internetErrorMessage,
            serverErrorMessage: input.resetPasswordScreen.serverErrorMessage,
        }
    } else {
        staticText.resetPasswordScreen = {
            title: "Change password",
            subTitle: "We’ve sent an OTP to reset your password to",
            invalidOtpMessage: "Oh Snap! please enter a valid OTP",
            invalidPasswordMessage: "Please enter a valid password",
            invalidConfirmPasswordMessage: "Password you entred do not match",
            changePasswordButtonText: "CHANGE PASSWORD",
            passwordPlaceholder: "Minimum 6 characters",
            confirmPasswordPlaceholder: "Re-enter Password",
            unRegisteredMobileNumberMessage: "This mobile number is not registered with countingwell",
            invalidMobileMessage: "Enter valid Mobile number",
            internetErrorMessage: 'Please check your internet connection and try again',
            serverErrorMessage: 'OOPS! Something went wrong'
        }
    }

    if (input && input.forgotPasswordSuccessScreen) {
        staticText.forgotPasswordSuccessScreen = {
            title: input.forgotPasswordSuccessScreen.title,
            subTitle: input.forgotPasswordSuccessScreen.subTitle,
            loginButtonTitle: input.forgotPasswordSuccessScreen.loginButtonTitle,
        }
    } else {
        staticText.forgotPasswordSuccessScreen = {
            title: "Password Changed",
            subTitle: "Your password has been changed successfully",
            loginButtonTitle: "LOGIN"
        }
    }

    if (input && input.chapterListScreen) {
        staticText.chapterListScreen = {
            chapterProgressTitle: input.chapterListScreen.chapterProgressTitle,
            title: input.chapterListScreen.title
        }
    } else {
        staticText.chapterListScreen = {
            chapterProgressTitle: "SYLLABUS COMPLETED",
            title: "CHAPTERS"
        }
    }

    if (input && input.eloList) {
        staticText.eloList = {
            completedText: input.eloList.completedText,
            description: input.eloList.description,
            introductionText: input.eloList.introductionText,
        }
    } else {
        staticText.eloList = {
            completedText: "Completed",
            description: "In this chapter you will master the following concepts",
            introductionText: "Introduction to"
        }
    }


    if (input && input.pleaseAnswerTheQuestionPopUpScreen) {
        staticText.pleaseAnswerTheQuestionPopUpScreen = {
            title: input.pleaseAnswerTheQuestionPopUpScreen.title,
            buttonTitle: input.pleaseAnswerTheQuestionPopUpScreen.buttonTitle
        }
    } else {
        staticText.pleaseAnswerTheQuestionPopUpScreen = {
            title: "Please answer this question to proceed",
            buttonTitle: "Okay"
        }
    }

    if (input && input.wrongAnswerPopUpScreen) {
        staticText.wrongAnswerPopUpScreen = {
            title: input.wrongAnswerPopUpScreen.title,
            subTitle: input.wrongAnswerPopUpScreen.subTitle,
            viewCorrectAnswerButtonTitle: input.wrongAnswerPopUpScreen.viewCorrectAnswerButtonTitle,
            nextButtonTitle: input.wrongAnswerPopUpScreen.nextButtonTitle,
        }
    } else {
        staticText.wrongAnswerPopUpScreen = {
            title: "Oops! This is Incorrect",
            subTitle: "Let's look at the correct answer",
            viewCorrectAnswerButtonTitle: "VIEW CORRECT ANSWER",
            nextButtonTitle: "NEXT"
        }
    }

    if (input && input.correctAnswerPopUpScreen) {
        staticText.correctAnswerPopUpScreen = {
            title: input.correctAnswerPopUpScreen.title,
            subTitle: input.correctAnswerPopUpScreen.subTitle,
            buttonTitle: input.correctAnswerPopUpScreen.buttonTitle,
        }
    } else {
        staticText.correctAnswerPopUpScreen = {
            title: "Great! You got it right",
            subTitle: "Let's confirm your reasoning",
            buttonTitle: "NEXT"
        }
    }

    if (input && input.hintPopUpScreen) {
        staticText.hintPopUpScreen = {
            title: input.hintPopUpScreen.title,
            subTitle: input.hintPopUpScreen.subTitle,
            buttonTitle: input.hintPopUpScreen.buttonTitle,
        }
    } else {
        staticText.hintPopUpScreen = {
            title: "Let's help you !",
            subTitle: "Read hint & Try Again",
            buttonTitle: "TRY AGAIN"
        }
    }

    if (input && input.viewSolutionPopUpScreen) {
        staticText.viewSolutionPopUpScreen = {
            title: input.viewSolutionPopUpScreen.title,
            subTitle: input.viewSolutionPopUpScreen.subTitle,
            buttonTitle: input.viewSolutionPopUpScreen.buttonTitle,
        }
    } else {
        staticText.viewSolutionPopUpScreen = {
            title: "Oops! This is Incorrect",
            subTitle: "No worries you can do better next time",
            buttonTitle: "VIEW SOLUTION"
        }
    }

    if (input && input.navigationScreen) {
        staticText.navigationScreen = {
            title: input.navigationScreen.title,
            homeTitle: input.navigationScreen.homeTitle,
            searchTitle: input.navigationScreen.searchTitle,
            myCornerTitle: input.navigationScreen.myCornerTitle,
            introduction: input.navigationScreen.introduction,
            conceptListing: input.navigationScreen.conceptListing,
        }
    } else {
        staticText.navigationScreen = {
            title: "Navigation",
            homeTitle: "Home Screen",
            searchTitle: "Search",
            myCornerTitle: "My Corner",
            introduction: "Introduction to Chapter",
            conceptListing: "Concept Listing"
        }
    }

    if (input && input.successFailureScreens) {
        staticText.successFailureScreens = {
            oopsTitle: input.successFailureScreens.oopsTitle,
            greatWorkTitle: input.successFailureScreens.greatWorkTitle,
            conceptNotClearedTitle: input.successFailureScreens.conceptNotClearedTitle,
            nextConceptTitle: input.successFailureScreens.nextConceptTitle,
            completedText: input.successFailureScreens.completedText,
            gotoEloListPageTitle: input.successFailureScreens.gotoEloListPageTitle,
            continueButton: input.successFailureScreens.continueButton,
            tryAgainButton: input.successFailureScreens.tryAgainButton,
            goodWorkTitle: input.successFailureScreens.goodWorkTitle,
            multipleConceptNotClearedTitle: input.successFailureScreens.multipleConceptNotClearedTitle,
            multiplenextConceptTitle: input.successFailureScreens.multiplenextConceptTitle,
            multipleConceptsClearedTitle: input.successFailureScreens.multipleConceptsClearedTitle,
            conceptClearedTitle: input.successFailureScreens.conceptClearedTitle,
            recommendedText: input.successFailureScreens.recommendedText,
            failedSubTitleText: input.successFailureScreens.failedSubTitleText,
            subTitleText: input.successFailureScreens.subTitleText,
            clearConceptText: input.successFailureScreens.clearConceptText,
        }
    } else {
        staticText.successFailureScreens = {
            oopsTitle: "Oops!",
            greatWorkTitle: "Great Work",
            conceptNotClearedTitle: "CONCEPT NOT CLEARED",
            nextConceptTitle: "NEXT CONCEPT TO MASTER",
            completedText: "COMPLETED",
            gotoEloListPageTitle: "ALL LEARNING OUTCOMES",
            continueButton: "LET'S CONTINUE",
            tryAgainButton: "TRY AGAIN",
            goodWorkTitle: "Good Work",
            multipleConceptNotClearedTitle: "CONCEPTS NOT CLEARED",
            multiplenextConceptTitle: "NEXT CONCEPTS TO MASTER",
            multipleConceptsClearedTitle: "CONCEPTS CLEARED",
            conceptClearedTitle: "CONCEPT CLEARED",
            recommendedText: 'RECOMMENDED',
            subTitleText: "You have cleared the Learning Outcome",
            failedSubTitleText: "You have not cleared the Learning Outcome",
            clearConceptText: "CLEAR CONCEPT"
        }
    }

    if (input && input.multiStepQuestion) {
        staticText.multiStepQuestion = {
            instruction: input.multiStepQuestion.instruction,
            assessmentMultistepInstruction: input.multiStepQuestion.assessmentMultistepInstruction
        }
    } else {
        staticText.multiStepQuestion = {
            instruction: "Question has multiple sub questions",
            assessmentMultistepInstruction: "This is multi step qs. & marks would be given only when all the sub parts are correct"
        }
    }

    if (input && input.multiSelectQuestion) {
        staticText.multiSelectQuestion = {
            instruction: input.multiSelectQuestion.instruction
        }
    } else {
        staticText.multiSelectQuestion = {
            instruction: "There could be more than one answers"
        }
    }

    if (input && input.chapterStartPopUp) {
        staticText.chapterStartPopUp = {
            title: input.chapterStartPopUp.title,
            notYetButtonTitle: input.chapterStartPopUp.notYetButtonTitle,
            yesButtonTitle: input.chapterStartPopUp.yesButtonTitle,
        }
    } else {
        staticText.chapterStartPopUp = {
            title: "Has the Chapter been covered in your class?",
            notYetButtonTitle: "NOT YET",
            yesButtonTitle: "YES"
        }
    }

    if (input && input.landingScreen) {
        staticText.landingScreen = {
            chapterListingTitle: input.landingScreen.chapterListingTitle,
            message: input.landingScreen.message,
            syllabusCompletedText: input.landingScreen.syllabusCompletedText,
            browseChaptersTitle: input.landingScreen.browseChaptersTitle,
            browseChaptersText: input.landingScreen.browseChaptersText,
            continueAssessmentTitle: input.landingScreen.continueAssessmentTitle,
            continueAssessmentText: input.landingScreen.continueAssessmentText,
            resumeYourJourneyTitle: input.landingScreen.resumeYourJourneyTitle,
            exploreAppTitle: input.landingScreen.exploreAppTitle,
            exploreAppText: input.landingScreen.exploreAppText,
            haveADoubtTitle: input.landingScreen.haveADoubtTitle,
            haveADoubtText: input.landingScreen.haveADoubtText,
            shareMessage: input.landingScreen.shareMessage,
            learnTogetherMessage: input.landingScreen.learnTogetherMessage,
            shareButtonTitle: input.landingScreen.shareButtonTitle,
            linkMessage: input.landingScreen.linkMessage,
            examPrepText: input.landingScreen.examPrepText,
            upgradeText: input.landingScreen.upgradeText,
            unlockText: input.landingScreen.unlockText,
            updateClassTitle: input.landingScreen.updateClassTitle,
            updateClassText: input.landingScreen.updateClassText,
            excludedClassForUpgrade: input.landingScreen.excludedClassForUpgrade,
            scollarShipText: input.landingScreen.scollarShipText,
            scollarShipQualifiedText: input.landingScreen.scollarShipQualifiedText,
            scholarshipCompletedText: input.landingScreen.scholarshipCompletedText,
            resultAnnouncedText: input.landingScreen.resultAnnouncedText,
            ramanujamName: input.landingScreen.ramanujamName,
            ramanujamNameSubText: input.landingScreen.ramanujamNameSubText,
            refresherCourseText: input.landingScreen.refresherCourseText,
            refresherCourseSubText: input.landingScreen.refresherCourseSubText,
            studentsCompletedText: input.landingScreen.studentsCompletedText,
            studentQualifiedSubText: input.landingScreen.studentQualifiedSubText,
            scholarshipHeaderText: input.landingScreen.scholarshipHeaderText,
            workoutCardTitle: input.landingScreen.workoutCardTitle,
            startNewWorkoutTitle: input.landingScreen.startNewWorkoutTitle,
            setupDailyWorkoutTitle: input.landingScreen.setupDailyWorkoutTitle,
            resumeMyWorkoutTitle: input.landingScreen.resumeMyWorkoutTitle,
            workoutCardSubTitle: input.landingScreen.workoutCardSubTitle,
            browseChapterTitle: input.landingScreen.browseChapterTitle,
            browseChapterSubTitle: input.landingScreen.browseChapterSubTitle,
            myCornerTitle: input.landingScreen.myCornerTitle,
            myCornerSubTitle: input.landingScreen.myCornerSubTitle,
            practiceTitle: input.landingScreen.practiceTitle,
            practiceSubTitle: input.landingScreen.practiceSubTitle,
            exploreAppTitle: input.landingScreen.exploreAppTitle,
            exploreAppSubTitle: input.landingScreen.exploreAppSubTitle,
            myHomeworksTitle: input.landingScreen.myHomeworksTitle,
            myHomeworksSubTitle: input.landingScreen.myHomeworksSubTitle,
            examPrepkitTitle: input.landingScreen.examPrepkitTitle,
            examPrepkitSubTitle: input.landingScreen.examPrepkitSubTitle,
            examPrepkitTagLineText: input.landingScreen.examPrepkitTagLineText,
            examPrepkitStartedUsersText: input.landingScreen.examPrepkitStartedUsersText,
            upgradeClassTitle: input.landingScreen.upgradeClassTitle,
            upgradeClassSubTitle: input.landingScreen.upgradeClassSubTitle,
            upgradeClassTitle: input.landingScreen.upgradeClassTitle,
            upgradeClassSubTitle: input.landingScreen.upgradeClassSubTitle,
            courseTitle: input.landingScreen.courseTitle,
            courseImageUrl: input.landingScreen.courseImageUrl,
            courseVideoUrl: input.landingScreen.courseVideoUrl,
            showCourse: input.landingScreen.showCourse,
            assessmentTitle: input.landingScreen.assessmentTitle,
            assessmentSubTitle: input.landingScreen.assessmentSubTitle,
            assessmentTitle: input.landingScreen.assessmentTitle,
            assessmentSubTitle: input.landingScreen.assessmentSubTitle
        }
    } else {
        staticText.landingScreen = {
            chapterListingTitle: "Chapter Listing",
            message: "Happy Learning!",
            syllabusCompletedText: "SYLLABUS COMPLETED",
            browseChaptersTitle: "Browse Chapters",
            browseChaptersText: "A Quick snapshot of what we have got  for you in App",
            continueAssessmentTitle: 'Continue your Assessment',
            continueAssessmentText: "A Quick snapshot of what we have got  for you in App",
            resumeYourJourneyTitle: "Resume Your Journey",
            exploreAppTitle: 'Explore the App',
            exploreAppText: "A Quick snapshot of what we have got  for you in App",
            haveADoubtTitle: "Have a Doubt?",
            haveADoubtText: "Post it and get it answered by our Expert Teachers",
            shareMessage: "Share App with your friends",
            learnTogetherMessage: "and start learning together",
            shareButtonTitle: "SHARE",
            linkMessage: 'Hey! I am loving this new App for Learning Maths. You should try it out... , iOS :https://apps.apple.com/in/app/countingwell/id1508540117,\n Android:"https://play.google.com/store/apps/details?id=com.cwapp',
            examPrepText: 'Prepare for your Maths exams like a Boss! Get Quick Chapter Summaries, Formulae and Definitions, Important Questions and Revisions Tips',
            upgradeText: "UPGRADE TO PREMIUM & UNLOCK THE ENTIRE LEARNING CONTENT WITH NO TIME LIMIT",
            unlockText: "UNLOCK",
            updateClassTitle: 'Moved to Higher class?',
            updateClassText: 'Update your class now',
            excludedClassForUpgrade: 'Class 8',
            scollarShipText: "Complete the Refresher Course to Prepare and Qualify for Scholarship Test",
            scollarShipQualifiedText: "<p>You have qualified for the Scholarship Test.You can take the test <strong>anytime between 4:00-7:00 PM on Sunday</strong> 9th May 2021</p>",
            resultAnnouncedText: "<p>SCHOLARSHIP results are announced. Visit <link href=\"https://www.countingwell.com\">www.countingwell.com</link> to view results.</p>",
            scholarshipCompletedText: "<p><strong>SCHOLARSHIP</strong> results will be announced on 11th May. Visit <link href=\"https://www.countingwell.com\">www.countingwell.com </link> to view results</p>",
            ramanujamName: "RAMANUJAN",
            ramanujamNameSubText: "MATHS SCHOLARSHIP",
            refresherCourseText: "Refresher Course",
            refresherCourseSubText: "Get ready for new Academic Year",
            studentsCompletedText: "students have already completed the Refresher Course & qualified for the Scholarship Test",
            studentQualifiedSubText: "Yay! You have Qualified for the Scholarship Test",
            scholarshipHeaderText: "Ramanujan Math Scholarship",

            workoutCardTitle: "Start Your Workout",
            startNewWorkoutTitle: "Start new workout",
            setupDailyWorkoutTitle: "Setup my daily workouts",
            resumeMyWorkoutTitle: "Resume my workout",
            workoutCardSubTitle: "Daily Personalized learning",
            browseChapterTitle: "Browse Chapters",
            browseChapterSubTitle: "Learn Maths the easy & fun way",
            myCornerTitle: "My Corner",
            myCornerSubTitle: "Your Bookmarks & Assessments",
            practiceTitle: "Practice",
            practiceSubTitle: "A little more exercise for your brain",
            exploreAppTitle: "Explore the App",
            exploreAppSubTitle: "Get a good hang of all the features",
            myHomeworksSubTitle: "Reinforce learning with homeworks",
            myHomeworksTitle: "My Homeworks",
            examPrepkitTitle: "Exam PrepKit",
            examPrepkitSubTitle: "Improve your Final Exam Scores",
            examPrepkitTagLineText: 'Get ready to score more!',
            examPrepkitStartedUsersText: '<%count%> students have started the Prep kit',
            upgradeClassTitle: "Upgrade your class",
            upgradeClassSubTitle: "And get all the learning content for the new year",
            upgradeClassTitle: "Upgrade your class",
            upgradeClassSubTitle: "And get all the learning content for the new year",
            courseTitle: 'Financial Literacy Course',
            courseImageUrl: 'https://countingwell-assets-testing.s3.ap-south-1.amazonaws.com/e3fbc6bf-b54d-4160-b4d9-20c0afeebcd3-IMD%20LOGO%201.png',
            courseVideoUrl: '',
            showCourse: true,
            assessmentTitle: 'Take an Assessment',
            assessmentSubTitle: 'A little more exercise for your brain'
        }
    }

    if (input && input.hamburgerMenuModal) {
        staticText.hamburgerMenuModal = {
            myProfileTitle: input.hamburgerMenuModal.myProfileTitle,
            myPerformanceTitle: input.hamburgerMenuModal.myPerformanceTitle,
            myCommunityTitle: input.hamburgerMenuModal.myCommunityTitle,
            connectCodesTitle: input.hamburgerMenuModal.connectCodesTitle,
            referAFriendTitle: input.hamburgerMenuModal.referAFriendTitle,
            rateAppTitle: input.hamburgerMenuModal.rateAppTitle,
            feedbackTitle: input.hamburgerMenuModal.feedbackTitle,
            helpTitle: input.hamburgerMenuModal.helpTitle,
            settingsTitle: input.hamburgerMenuModal.settingsTitle,
            privacyPolicyTitle: input.hamburgerMenuModal.privacyPolicyTitle,
            logoutTitle: input.hamburgerMenuModal.logoutTitle,
            schoolConnectTitle: input.hamburgerMenuModal.schoolConnectTitle,
        }
    } else {
        staticText.hamburgerMenuModal = {
            myProfileTitle: "My Profile",
            myPerformanceTitle: "My Performance",
            myCommunityTitle: "My Community",
            connectCodesTitle: "School Code",
            referAFriendTitle: "Refer A Friend",
            rateAppTitle: "Rate App",
            feedbackTitle: "Feedback",
            helpTitle: "Help",
            settingsTitle: "Settings",
            privacyPolicyTitle: "Privacy Policy",
            logoutTitle: "Logout",
            schoolConnectTitle: "Connect School"
        }
    }

    if (input && input.guardianSignUp) {
        staticText.guardianSignUp = {
            guardianSignUpTitle: input.guardianSignUp.guardianSignUpTitle,
            fatherTitle: input.guardianSignUp.fatherTitle,
            motherTitle: input.guardianSignUp.motherTitle,
            otherTitle: input.guardianSignUp.otherTitle,
            nameTitle: input.guardianSignUp.nameTitle,
            mobileNumberTitle: input.guardianSignUp.mobileNumberTitle,
            namePlaceHolder: input.guardianSignUp.namePlaceHolder,
            mobilePlaceHolder: input.guardianSignUp.mobilePlaceHolder,
            signUpButtonTitle: input.guardianSignUp.signUpButtonTitle,
            verifyTitle: input.guardianSignUp.verifyTitle,
            defaultCountryCode: input.guardianSignUp.defaultCountryCode,
            invalidMobileMessage: input.guardianSignUp.invalidMobileMessage,
            invalidNameMessage: input.guardianSignUp.invalidNameMessage,
            userExistMessage: input.guardianSignUp.userExistMessage,
            passwordTitle: input.guardianSignUp.passwordTitle,
            confirmPasswordTitle: input.guardianSignUp.confirmPasswordTitle,
            passwordPlaceHolder: input.guardianSignUp.passwordPlaceHolder,
            otpSentText: input.guardianSignUp.otpSentText,
            invalidPasswordMessage: input.guardianSignUp.invalidPasswordMessage,
            passwordNotMatchMessage: input.guardianSignUp.passwordNotMatchMessage,
            agreeText: input.guardianSignUp.agreeText,
            termsAndConditionsText: input.guardianSignUp.termsAndConditionsText,
            internetErrorMessage: input.guardianSignUp.internetErrorMessage,
            serverErrorMessage: input.guardianSignUp.serverErrorMessage,
        }
    } else {
        staticText.guardianSignUp = {
            guardianSignUpTitle: "Relation",
            fatherTitle: "Father",
            motherTitle: "Mother",
            otherTitle: "Other",
            nameTitle: "NAME",
            mobileNumberTitle: "MOBILE NO",
            namePlaceHolder: "Enter your Full Name",
            mobilePlaceHolder: "Enter your Mobile no.",
            signUpButtonTitle: 'REGISTER',
            verifyTitle: "VERIFY",
            defaultCountryCode: "+91",
            invalidMobileMessage: "Enter valid Mobile number",
            invalidNameMessage: "Enter valid name",
            userExistMessage: "User already exists",
            passwordTitle: "PASSWORD",
            confirmPasswordTitle: "CONFIRM PASSWORD",
            passwordPlaceHolder: "Minimum 6 characters",
            otpSentText: "OTP sent to ",
            invalidPasswordMessage: "Please enter a valid password",
            passwordNotMatchMessage: "Passwords you entered do not match",
            agreeText: "By registering I agree to the ",
            termsAndConditionsText: 'terms and conditions',
            internetErrorMessage: 'Please check your internet connection and try again',
            serverErrorMessage: 'OOPS! Something went wrong'
        }
    }

    if (input && input.studentSignup) {
        staticText.studentSignup = {
            studentSignupTitle: input.studentSignup.studentSignupTitle,
            missTitle: input.studentSignup.missTitle,
            masterTitle: input.studentSignup.masterTitle,
            nameTitle: input.studentSignup.nameTitle,
            mobileNumberTitle: input.studentSignup.mobileNumberTitle,
            namePlaceHolder: input.studentSignup.namePlaceHolder,
            mobilePlaceHolder: input.studentSignup.mobilePlaceHolder,
            signUpButtonTitle: input.studentSignup.signUpButtonTitle,
            verifyTitle: input.studentSignup.verifyTitle,
            defaultCountryCode: input.studentSignup.defaultCountryCode,
            invalidMobileMessage: input.studentSignup.invalidMobileMessage,
            invalidNameMessage: input.studentSignup.invalidNameMessage,
            userExistMessage: input.studentSignup.userExistMessage,
            otpSentText: input.studentSignup.otpSentText,
            agreeText: input.studentSignup.agreeText,
            termsAndConditionsText: input.studentSignup.termsAndConditionsText,
            emailTitle: input.studentSignup.emailTitle,
            emailPlaceHolder: input.studentSignup.emailPlaceHolder,
            invalidEmailMessage: input.studentSignup.invalidEmailMessage,
            internetErrorMessage: input.studentSignup.internetErrorMessage,
            serverErrorMessage: input.studentSignup.serverErrorMessage,
            loginText: input.studentSignup.loginText,
        }
    } else {
        staticText.studentSignup = {
            studentSignupTitle: "Register",
            missTitle: "Miss",
            masterTitle: "Master",
            nameTitle: "NAME",
            mobileNumberTitle: "MOBILE NO",
            namePlaceHolder: "Enter your Full Name",
            mobilePlaceHolder: "Enter your Mobile no.",
            signUpButtonTitle: "REGISTER",
            verifyTitle: "VERIFY",
            defaultCountryCode: "+91",
            invalidMobileMessage: "Enter valid Mobile number",
            invalidNameMessage: "Enter valid name",
            userExistMessage: "User already exists",
            otpSentText: "OTP sent to ",
            agreeText: "By registering I agree to the ",
            termsAndConditionsText: 'terms and conditions',
            emailTitle: "EMAIL ID",
            emailPlaceHolder: 'Enter your Parent Email Id',
            invalidEmailMessage: 'Enter valid email',
            internetErrorMessage: 'Please check your internet connection and try again',
            serverErrorMessage: 'OOPS! Something went wrong',
            loginText: 'LOGIN'

        }
    }

    if (input && input.guardianLogin) {
        staticText.guardianLogin = {
            guardianLoginTitle: input.guardianLogin.guardianLoginTitle,
            passwordTitle: input.guardianLogin.passwordTitle,
            mobileNumberTitle: input.guardianLogin.mobileNumberTitle,
            passwordPlaceHolder: input.guardianLogin.passwordPlaceHolder,
            mobilePlaceHolder: input.guardianLogin.mobilePlaceHolder,
            forgotPasswordTitle: input.guardianLogin.forgotPasswordTitle,
            loginButtonTitle: input.guardianLogin.loginButtonTitle,
            defaultCountryCode: input.guardianLogin.defaultCountryCode,
            invalidMobileMessage: input.guardianLogin.invalidMobileMessage,
            unRegisteredMobileMessage: input.guardianLogin.unRegisteredMobileMessage,
            invalidPasswordMessage: input.guardianLogin.invalidPasswordMessage,
            loginFailedMessage: input.guardianLogin.loginFailedMessage,
            internetErrorMessage: input.guardianLogin.internetErrorMessage,
            serverErrorMessage: input.guardianLogin.serverErrorMessage,
        }
    } else {
        staticText.guardianLogin = {
            guardianLoginTitle: "Guardian Login",
            passwordTitle: "PASSWORD",
            mobileNumberTitle: "MOBILE NO",
            passwordPlaceHolder: "Minimum 6 characters",
            mobilePlaceHolder: "Enter your Mobile no.",
            forgotPasswordTitle: "Forgot Password",
            loginButtonTitle: "LOGIN",
            defaultCountryCode: "+91",
            invalidMobileMessage: "Enter valid Mobile number",
            unRegisteredMobileMessage: "This Mobile number is not registered with countingwell",
            invalidPasswordMessage: "Enter Valid Password",
            loginFailedMessage: "Enter valid Mobile number and Password",
            internetErrorMessage: 'Please check your internet connection and try again',
            serverErrorMessage: 'OOPS! Something went wrong'
        }
    }

    if (input && input.studentLogin) {
        staticText.studentLogin = {
            studentLoginTitle: input.studentLogin.studentLoginTitle,
            otpSentText: input.studentLogin.otpSentText,
            mobileNumberTitle: input.studentLogin.mobileNumberTitle,
            mobilePlaceHolder: input.studentLogin.mobilePlaceHolder,
            loginTitle: input.studentLogin.loginTitle,
            verifyTitle: input.studentLogin.verifyTitle,
            defaultCountryCode: input.studentLogin.defaultCountryCode,
            invalidMobileMessage: input.studentLogin.invalidMobileMessage,
            unRegisteredMobileMessage: input.studentLogin.unRegisteredMobileMessage,
            internetErrorMessage: input.studentLogin.internetErrorMessage,
            serverErrorMessage: input.studentLogin.serverErrorMessage,
            registerNewUserText: input.studentLogin.registerNewUserText,
            createNewUserQuestionText: input.studentLogin.createNewUserQuestionText,
            loginWithAnotherNumberText: input.studentLogin.loginWithAnotherNumberText,
            sectionCaptureFrequency: input.studentLogin.sectionCaptureFrequency,

        }
    } else {
        staticText.studentLogin = {
            studentLoginTitle: "Student Login",
            otpSentText: "OTP sent to ",
            mobileNumberTitle: "MOBILE NO",
            mobilePlaceHolder: "Enter your Mobile no.11",
            loginTitle: "LOGIN",
            verifyTitle: "VERIFY",
            defaultCountryCode: "+91",
            invalidMobileMessage: "Enter valid Mobile number",
            unRegisteredMobileMessage: "This Mobile number is not registered with countingwell",
            internetErrorMessage: 'Please check your internet connection and try again',
            serverErrorMessage: 'OOPS! Something went wrong',
            registerNewUserText: 'Yes - Register me with this number',
            createNewUserQuestionText: 'Do you want to create a new account with this Mobile number?',
            loginWithAnotherNumberText: 'I WILL LOGIN WITH ANOTHER NUMBER',
            sectionCaptureFrequency: 15
        }
    }

    if (input && input.linkExistingStudent) {
        staticText.linkExistingStudent = {
            linkExistingStudentTitle: input.linkExistingStudent.linkExistingStudentTitle,
            otpSentText: input.linkExistingStudent.otpSentText,
            mobileNumberTitle: input.linkExistingStudent.mobileNumberTitle,
            mobilePlaceHolder: input.linkExistingStudent.mobilePlaceHolder,
            continueTitle: input.linkExistingStudent.continueTitle,
            verifyTitle: input.linkExistingStudent.verifyTitle,
            defaultCountryCode: input.linkExistingStudent.defaultCountryCode,
            invalidMobileMessage: input.linkExistingStudent.invalidMobileMessage,
            unRegisteredMobileMessage: input.linkExistingStudent.unRegisteredMobileMessage,
            internetErrorMessage: input.linkExistingStudent.internetErrorMessage,
            serverErrorMessage: input.linkExistingStudent.serverErrorMessage,

        }
    } else {
        staticText.linkExistingStudent = {
            linkExistingStudentTitle: "Link Existing Student",
            otpSentText: "OTP sent to ",
            mobileNumberTitle: "MOBILE NO",
            mobilePlaceHolder: "Enter your Mobile no.",
            continueTitle: "CONTINUE",
            verifyTitle: "VERIFY",
            defaultCountryCode: "+91",
            invalidMobileMessage: "Enter valid Mobile number",
            unRegisteredMobileMessage: "This Mobile number is not registered with countingwell",
            internetErrorMessage: 'Please check your internet connection and try again',
            serverErrorMessage: 'OOPS! Something went wrong'

        }
    }

    if (input && input.selectUser) {
        staticText.selectUser = {
            continueAsTitle: input.selectUser.continueAsTitle,
            sectionCaptureFrequency: input.selectUser.sectionCaptureFrequency
        }
    } else {
        staticText.selectUser = {
            continueAsTitle: "Continue as",
            sectionCaptureFrequency: 15
        }
    }

    if (input && input.countryCodes) {
        staticText.countryCodes = {
            selectCountryTitle: input.countryCodes.selectCountryTitle,
            searchPlaceHolder: input.countryCodes.searchPlaceHolder
        }
    } else {
        staticText.countryCodes = {
            selectCountryTitle: "Select a Country",
            searchPlaceHolder: "Search"
        }
    }

    if (input && input.onBoarding) {
        staticText.onBoarding = {
            loginTitle: input.onBoarding.loginTitle,
            signUpTitle: input.onBoarding.signUpTitle,
            asAStudentTitle: input.onBoarding.asAStudentTitle,
            asAGuardianTitle: input.onBoarding.asAGuardianTitle,
            sectionCaptureFrequency: input.onBoarding.sectionCaptureFrequency
        }
    } else {
        staticText.onBoarding = {
            loginTitle: "LOGIN",
            signUpTitle: "REGISTER",
            asAStudentTitle: "AS A STUDENT",
            asAGuardianTitle: "AS A GUARDIAN",
            sectionCaptureFrequency: 15
        }
    }

    if (input && input.accountCreated) {
        staticText.accountCreated = {
            accountCreatedTitle: input.accountCreated.accountCreatedTitle,
            continueButtonTitle: input.accountCreated.continueButtonTitle,
            text: input.accountCreated.text,
        }
    } else {
        staticText.accountCreated = {
            accountCreatedTitle: "Account Created",
            continueButtonTitle: "CONTINUE",
            text: "can login into his account using the following Phone number:"
        }
    }

    if (input && input.addStudentDetails) {
        staticText.addStudentDetails = {
            classDetailsTitle: input.addStudentDetails.classDetailsTitle,
            boardTitle: input.addStudentDetails.boardTitle,
            classTitle: input.addStudentDetails.classTitle,
            schoolCodeTitle: input.addStudentDetails.schoolCodeTitle,
            schoolCodePlaceHolder: input.addStudentDetails.schoolCodePlaceHolder,
            createButtonTitle: input.addStudentDetails.createButtonTitle,
            message: input.addStudentDetails.message,
            otherMessage: input.addStudentDetails.otherMessage,
        }
    } else {
        staticText.addStudentDetails = {
            classDetailsTitle: "Class Details",
            boardTitle: "BOARD",
            classTitle: "CLASS",
            schoolCodeTitle: "SCHOOL CODE",
            schoolCodePlaceHolder: "Optional",
            createButtonTitle: "CREATE"
        }
    }

    if (input && input.classDetails) {
        staticText.classDetails = {
            classDetailsTitle: input.classDetails.classDetailsTitle,
            boardTitle: input.classDetails.boardTitle,
            classTitle: input.classDetails.classTitle,
            schoolCodeTitle: input.classDetails.schoolCodeTitle,
            schoolCodePlaceHolder: input.classDetails.schoolCodePlaceHolder,
            continueButtonTitle: input.classDetails.continueButtonTitle,
            message: input.classDetails.message,
            otherMessage: input.classDetails.otherMessage,
        }
    } else {
        staticText.classDetails = {
            classDetailsTitle: " Your Class Details",
            boardTitle: "BOARD",
            classTitle: "CLASS",
            schoolCodeTitle: "SCHOOL CODE",
            schoolCodePlaceHolder: "Optional",
            continueButtonTitle: "CONTINUE"
        }
    }

    if (input && input.linkedStudent) {
        staticText.linkedStudent = {
            linkedStudentTitle: input.linkedStudent.linkedStudentTitle,
            linkExistingStudentButtonTitle: input.linkedStudent.linkExistingStudentButtonTitle,
            createStudentButtonTitle: input.linkedStudent.createStudentButtonTitle,
            continueToDashboardButtonTitle: input.linkedStudent.continueToDashboardButtonTitle,
        }
    } else {
        staticText.linkedStudent = {
            linkedStudentTitle: "Linked Students",
            linkExistingStudentButtonTitle: "Link existing Student",
            createStudentButtonTitle: "Create new Student",
            continueToDashboardButtonTitle: "Continue to Dashboard"
        }
    }

    if (input && input.createStudent) {
        staticText.createStudent = {
            welcomeTitle: input.createStudent.welcomeTitle,
            missTitle: input.createStudent.missTitle,
            masterTitle: input.createStudent.masterTitle,
            nameTitle: input.createStudent.nameTitle,
            mobileNumberTitle: input.createStudent.mobileNumberTitle,
            namePlaceHolder: input.createStudent.namePlaceHolder,
            mobilePlaceHolder: input.createStudent.mobilePlaceHolder,
            continueTitle: input.createStudent.continueTitle,
            verifyTitle: input.createStudent.verifyTitle,
            defaultCountryCode: input.createStudent.defaultCountryCode,
            invalidMobileMessage: input.createStudent.invalidMobileMessage,
            invalidNameMessage: input.createStudent.invalidNameMessage,
            userExistMessage: input.createStudent.userExistMessage,
            otpSentText: input.createStudent.otpSentText,
            emailTitle: input.studentSignup.emailTitle,
            emailPlaceHolder: input.studentSignup.emailPlaceHolder,
            invalidEmailMessage: input.studentSignup.invalidEmailMessage,
            nickNamePlaceHolder: input.createStudent.nickNamePlaceHolder,
            internetErrorMessage: input.createStudent.internetErrorMessage,
            serverErrorMessage: input.createStudent.serverErrorMessage,

        }
    } else {
        staticText.createStudent = {
            welcomeTitle: "Welcome",
            otpSentText: "OTP sent to",
            missTitle: "MISS",
            masterTitle: "MASTER",
            nameTitle: "NAME",
            mobileNumberTitle: "MOBILE NO",
            namePlaceHolder: "Enter your Full Name",
            mobilePlaceHolder: "Enter your Mobile no.",
            continueTitle: "CONTINUE",
            verifyTitle: "VERIFY",
            defaultCountryCode: "+91",
            invalidMobileMessage: "Enter valid Mobile number",
            invalidNameMessage: "Enter valid name",
            userExistMessage: "User already exists",
            emailTitle: "EMAIL ID",
            emailPlaceHolder: 'Enter your Parent Email Id',
            invalidEmailMessage: 'Enter valid email',
            nickNamePlaceHolder: 'Enter your Nick Name',
            internetErrorMessage: 'Please check your internet connection and try again',
            serverErrorMessage: 'OOPS! Something went wrong'
        }
    }

    if (input && input.otpComponent) {
        staticText.otpComponent = {
            OTPTitle: input.otpComponent.OTPTitle,
            resendOtp: input.otpComponent.resendOtp,
            resendOtpIn: input.otpComponent.resendOtpIn,
            otpErrorMessage: input.otpComponent.otpErrorMessage,
            resendOtpTime: input.otpComponent.resendOtpTime,
        }
    } else {
        staticText.otpComponent = {
            OTPTitle: "OTP",
            resendOtp: "Resend OTP",
            otpErrorMessage: "Oh Snap! please enter a valid OTP",
            resendOtpIn: "Resend OTP in",
            resendOtpTime: 15
        }
    }

    if (input && input.myCorner) {
        staticText.myCorner = {
            title: input.myCorner.title,
            bookMark: input.myCorner.bookMark,
            doubts: input.myCorner.doubts,
            notes: input.myCorner.notes,
            inbox: input.myCorner.inbox,
            assessments: input.myCorner.assessments,
            mySettings: input.myCorner.mySettings,
            courses: input.myCorner.courses

        }
    } else {
        staticText.myCorner = {
            title: "MY CORNER",
            bookMark: "My Bookmarks",
            doubts: "My Doubts",
            notes: "My Notes",
            inbox: "My Inbox",
            assessments: 'My Assessments',
            mySettings: 'My Settings',
            courses: "My Courses"
        }
    }

    if (input && input.chapterCompletionScreen) {
        staticText.chapterCompletionScreen = {
            completedText: input.chapterCompletionScreen.completedText,
            chapterClearedText: input.chapterCompletionScreen.chapterClearedText,
            chapterNotClearedText: input.chapterCompletionScreen.chapterNotClearedText,
            takeAssessmentButton: input.chapterCompletionScreen.takeAssessmentButton,
            practicMoreQuestionsButton: input.chapterCompletionScreen.practicMoreQuestionsButton,
            chapterSummaryButton: input.chapterCompletionScreen.chapterSummaryButton,
            continueButton: input.chapterCompletionScreen.continueButton,
            letsClearChapterButton: input.chapterCompletionScreen.letsClearChapterButton,
            recommendedText: input.chapterCompletionScreen.recommendedText,

        }
    } else {
        staticText.chapterCompletionScreen = {
            completedText: "COMPLETED",
            chapterClearedText: "You have mastered the Chapter",
            chapterNotClearedText: "You have got a good hold on this chapter",
            takeAssessmentButton: "TAKE ASSESSMENT",
            practicMoreQuestionsButton: "PRACTICE MORE QUESTIONS",
            chapterSummaryButton: "CHAPTER SUMMARY",
            continueButton: "CONTINUE",
            letsClearChapterButton: "LET'S CLEAR THE CHAPTER",
            recommendedText: "RECOMMENDED"
        }
    }

    if (input && input.referFriendScreen) {
        staticText.referFriendScreen = {
            title: input.referFriendScreen.title,
            shareMessageText: input.referFriendScreen.shareMessageText,
            shareSubMessageText: input.referFriendScreen.shareSubMessageText,
            shareButtonTitle: input.referFriendScreen.shareButtonTitle,
            linkMessage: input.referFriendScreen.linkMessage,
        }
    } else {
        staticText.referFriendScreen = {
            title: "Refer A Friend",
            shareMessageText: "Share App with your friends",
            shareSubMessageText: "and start learning together",
            shareButtonTitle: "SHARE",
            linkMessage: 'Hey! I am loving this new App for Learning Maths. You should try it out... , iOS :https://apps.apple.com/in/app/countingwell/id1508540117,\n Android:"https://play.google.com/store/apps/details?id=com.cwapp'
        }
    }

    if (input && input.rateAppScreen) {
        staticText.rateAppScreen = {
            title: input.rateAppScreen.title,
            loveAppText: input.rateAppScreen.loveAppText,
            iosMessageText: input.rateAppScreen.iosMessageText,
            androidMessageText: input.rateAppScreen.androidMessageText,
            rateUsButtonTitle: input.rateAppScreen.rateUsButtonTitle,
        }
    } else {
        staticText.rateAppScreen = {
            title: "Rate App",
            loveAppText: "Love our App?",
            androidMessageText: "Give us 5 star rating on playstore",
            iosMessageText: "Give us 5 star rating on appstore",
            rateUsButtonTitle: "RATE US",
        }
    }

    if (input && input.appFeedbackScreen) {
        staticText.appFeedbackScreen = {
            title: input.appFeedbackScreen.title,
            messageText: input.appFeedbackScreen.messageText,
            inputPlaceHolder: input.appFeedbackScreen.inputPlaceHolder,
            sendButtonTitle: input.appFeedbackScreen.sendButtonTitle,
            attachMediaText: input.appFeedbackScreen.attachMediaText,
        }
    } else {
        staticText.appFeedbackScreen = {
            title: "Feedback",
            messageText: "Let us know how we can further Improve the App",
            inputPlaceHolder: "Enter your Feedback here",
            sendButtonTitle: "SEND",
            attachMediaText: "ATTACH MEDIA"
        }
    }

    if (input && input.feedbackSuccessModal) {
        staticText.feedbackSuccessModal = {
            messageText: input.feedbackSuccessModal.messageText,
            doneButtonTitle: input.feedbackSuccessModal.doneButtonTitle,
        }
    } else {
        staticText.feedbackSuccessModal = {
            messageText: "thanks a lot for your Feedback, Our Team will look into it",
            doneButtonTitle: "DONE",
        }
    }

    if (input && input.connectCodesScreen) {
        staticText.connectCodesScreen = {
            title: input.connectCodesScreen.title,
            messageText: input.connectCodesScreen.messageText,
            inputPlaceHolder: input.connectCodesScreen.inputPlaceHolder,
            saveButtonTitle: input.connectCodesScreen.saveButtonTitle,
            instructionText: input.connectCodesScreen.instructionText,
            schoolCodeTitle: input.connectCodesScreen.schoolCodeTitle,
        }
    } else {
        staticText.connectCodesScreen = {
            title: "School Code",
            messageText: "School Code helps you to connect with your class",
            inputPlaceHolder: "Enter School Code",
            saveButtonTitle: "SAVE",
            schoolCodeTitle: "SCHOOL CODE",
            instructionText: "Ask your class teacher for School Code"
        }
    }

    if (input && input.geometryErasemodal) {
        staticText.geometryErasemodal = {
            text: input.geometryErasemodal.text,
            cancelButtonTitle: input.geometryErasemodal.cancelButtonTitle,
            yesButtonTitle: input.geometryErasemodal.yesButtonTitle,

        }
    } else {
        staticText.geometryErasemodal = {
            text: "Are you sure, you want to clear the entire work?",
            cancelButtonTitle: 'Cancel',
            yesButtonTitle: 'Yes'
        }
    }

    if (input && input.newConceptModal) {
        staticText.newConceptModal = {
            text: input.newConceptModal.text,
            continueButtonTitle: input.newConceptModal.continueButtonTitle,

        }
    } else {
        staticText.newConceptModal = {
            text: "you’re now starting the next Concept",
            continueButtonTitle: 'CONTINUE',
        }
    }

    if (input && input.workoutSetupScreen) {
        staticText.workoutSetupScreen = {
            text: input.workoutSetupScreen.text,
            continueButtonTitle: input.workoutSetupScreen.continueButtonTitle,

        }
    } else {
        staticText.workoutSetupScreen = {
            text: "Let’s setup a daily workout plan for you",
            continueButtonTitle: 'CONTINUE',
        }
    }

    if (input && input.workoutStartScreen) {
        staticText.workoutStartScreen = {
            text: input.workoutStartScreen.text,
            continueButtonTitle: input.workoutStartScreen.continueButtonTitle,

        }
    } else {
        staticText.workoutStartScreen = {
            text: "workout plan is ready for",
            continueButtonTitle: 'CONTINUE',
            examPrepText: 'Download exam preparation kit for quick revision, It contains all the important content like theory, formulae & solved problems '
        }
    }

    if (input && input.subScribedworkoutStartScreen) {
        staticText.workoutStartScreen = {
            firstWorkoutText: input.subScribedworkoutStartScreen.firstWorkoutText,
            todayWorkoutText: input.subScribedworkoutStartScreen.todayWorkoutText,
            continueButtonTitle: input.subScribedworkoutStartScreen.continueButtonTitle,

        }
    } else {
        staticText.subScribedworkoutStartScreen = {
            firstWorkoutText: "Your first workout plan is ready for",
            todayWorkoutText: "Your workout plan for today is ready for",
            continueButtonTitle: 'CONTINUE',
        }
    }

    if (input && input.contentListPopup) {
        staticText.contentListPopup = {
            text: input.contentListPopup.text,
            continueButtonTitle: input.contentListPopup.continueButtonTitle,

        }
    } else {
        staticText.contentListPopup = {
            text: "You are starting with a new Learning Outcome",
            continueButtonTitle: "LET'S CONTINUE",
        }
    }

    if (input && input.workoutCompletedPopup) {
        staticText.workoutCompletedPopup = {
            timeUpText: input.workoutCompletedPopup.timeUpText,
            continueButtonTitle: input.workoutCompletedPopup.continueButtonTitle,
            suggestionText: input.workoutCompletedPopup.suggestionText,
            yesText: input.workoutCompletedPopup.yesText,
            notTodayText: input.workoutCompletedPopup.notTodayText,

        }
    } else {
        staticText.workoutCompletedPopup = {
            timeUpText: "You’ve completed your workout plan for the day. Do you want to push 5 more mins?",
            suggestionText: 'You have completed all the planned Learning Contents before 20 mins, So we have added <%elocount%> more Learning Contents for you to make use of full 20 mins time slot',
            continueButtonTitle: "LET'S CONTINUE",
            yesText: "YES",
            notTodayText: "NOT TODAY"
        }
    }

    if (input && input.workoutSuccessFailureScreen) {
        staticText.workoutSuccessFailureScreen = {
            oopsTitle: input.workoutSuccessFailureScreen.oopsTitle,
            greatWorkTitle: input.workoutSuccessFailureScreen.greatWorkTitle,
            conceptNotClearedTitle: input.workoutSuccessFailureScreen.conceptNotClearedTitle,
            nextConceptTitle: input.workoutSuccessFailureScreen.nextConceptTitle,
            completedText: input.workoutSuccessFailureScreen.completedText,
            gotoEloListPageTitle: input.workoutSuccessFailureScreen.gotoEloListPageTitle,
            continueButton: input.workoutSuccessFailureScreen.continueButton,
            tryAgainButton: input.workoutSuccessFailureScreen.tryAgainButton,
            goodWorkTitle: input.workoutSuccessFailureScreen.goodWorkTitle,
            multipleConceptNotClearedTitle: input.workoutSuccessFailureScreen.multipleConceptNotClearedTitle,
            multiplenextConceptTitle: input.workoutSuccessFailureScreen.multiplenextConceptTitle,
            multipleConceptsClearedTitle: input.workoutSuccessFailureScreen.multipleConceptsClearedTitle,
            conceptClearedTitle: input.workoutSuccessFailureScreen.conceptClearedTitle,
            recommendedText: input.workoutSuccessFailureScreen.recommendedText,
            failedSubTitleText: input.workoutSuccessFailureScreen.failedSubTitleText,
            subTitleText: input.workoutSuccessFailureScreen.subTitleText,
            clearConceptText: input.workoutSuccessFailureScreen.clearConceptText,
            learningOutcomesMasteredText: input.workoutSuccessFailureScreen.learningOutcomesMasteredText,
            minutesText: input.workoutSuccessFailureScreen.minutesText,
            greatTitle: input.workoutSuccessFailureScreen.greatTitle,
            youHaveCompletedText: input.workoutSuccessFailureScreen.youHaveCompletedText,
            youAreStillAtText: input.workoutSuccessFailureScreen.youAreStillAtText,
            clearedText: input.workoutSuccessFailureScreen.clearedText,
            notClearedText: input.workoutSuccessFailureScreen.notClearedText,

        }
    } else {
        staticText.workoutSuccessFailureScreen = {
            oopsTitle: "Oops!",
            greatWorkTitle: "Great Work",
            conceptNotClearedTitle: "CONCEPT NOT CLEARED",
            nextConceptTitle: "NEXT CONCEPT TO MASTER",
            completedText: "COMPLETED",
            gotoEloListPageTitle: "ALL LEARNING OUTCOMES",
            continueButton: "LET'S CONTINUE",
            tryAgainButton: "TRY AGAIN",
            goodWorkTitle: "Good Work",
            multipleConceptNotClearedTitle: "CONCEPTS NOT CLEARED",
            multiplenextConceptTitle: "NEXT CONCEPTS TO MASTER",
            multipleConceptsClearedTitle: "CONCEPTS CLEARED",
            conceptClearedTitle: "CONCEPT CLEARED",
            recommendedText: 'RECOMMENDED',
            subTitleText: "You have cleared the Learning Outcome",
            failedSubTitleText: "You have not cleared the Learning Outcome",
            clearConceptText: "CLEAR CONCEPT",
            learningOutcomesMasteredText: "Learning Outcomes Mastered",
            timeText: "TIME",
            consumedText: 'CONSUMED',
            minutesText: "mins",
            greatTitle: 'Great',
            youHaveCompletedText: 'You have completed ',
            youAreStillAtText: 'You are still at ',
            clearedText: 'You did cleared the Learning Outcome',
            notClearedText: 'You did not cleared the Learning Outcome',
        }
    }

    if (input && input.optionScreen) {
        staticText.optionScreen = {
            title: input.optionScreen.title,
            messageText: input.optionScreen.messageText,
            subscribeButtonText: input.optionScreen.subscribeButtonText,
            trialButtonText: input.optionScreen.trialButtonText,
        }
    } else {
        staticText.optionScreen = {
            title: "Subscribe",
            messageText: "And get access to unlimited content including solved Examples, Workouts, Videos & Assessments to master maths",
            subscribeButtonText: "SUBSCRIBE",
            trialButtonText: "Try 10 days free trial",
        }
    }

    if (input && input.homeScreen) {
        staticText.homeScreen = {
            appSlogan1: input.homeScreen.appSlogan1,
            appSlogan2: input.homeScreen.appSlogan2,
        }
    } else {
        staticText.homeScreen = {
            appSlogan1: 'Master Maths from home in just',
            appSlogan2: "20 mins a day",
        }
    }


    if (input && input.trialChapterListScreen) {
        staticText.trialChapterListScreen = {
            headerText: input.trialChapterListScreen.headerText
        }
    } else {
        staticText.trialChapterListScreen = {
            headerText: "Select the Chapter you want to assess for"
        }
    }

    if (input && input.appExploreScreen) {
        staticText.appExploreScreen = {
            title: input.appExploreScreen.title,
            exploreButtonTitle: input.appExploreScreen.exploreButtonTitle
        }
    } else {
        staticText.appExploreScreen = {
            title: 'Explore the App',
            exploreButtonTitle: "EXPLORE"
        }
    }

    if (input && input.trialFeaturesPopup) {
        staticText.trialFeaturesPopup = {
            message: input.trialFeaturesPopup.message,
            workoutTitle: input.trialFeaturesPopup.workoutTitle,
            featuresTitle: input.trialFeaturesPopup.featuresTitle,
            continueButtonTitle: input.trialFeaturesPopup.continueButtonTitle
        }
    } else {
        staticText.trialFeaturesPopup = {
            message: 'You are on a 10 days Free Trial',
            featuresTitle: "Your Free Trial includes the following:",
            workoutTitle: 'What are workouts ?',
            continueButtonTitle: "CONTINUE"
        }
    }

    if (input && input.doubtsLimitZeroPopUp) {
        staticText.doubtsLimitZeroPopUp = {
            message: input.doubtsLimitZeroPopUp.message
        }
    } else {
        staticText.doubtsLimitZeroPopUp = {
            message: `Your current subscription plan doesn't allow you to post doubts.`
        }
    }

    if (input && input.AssessmentReport) {
        staticText.AssessmentReport = {
            title: input.AssessmentReport.title,
            greetings: input.AssessmentReport.greetings,
            reportText: input.AssessmentReport.reportText,
            buttonTitle: input.AssessmentReport.buttonTitle,
            skillsTitle: input.AssessmentReport.skillsTitle,
            skillIndexText: input.AssessmentReport.skillIndexText,
            marksScoredText: input.AssessmentReport.marksScoredText,
            timeTakenText: input.AssessmentReport.timeTakenText,
            correctText: input.AssessmentReport.correctText,
            inCorrectText: input.AssessmentReport.inCorrectText,
            notAnsweredText: input.AssessmentReport.notAnsweredText,
            minutesText: input.AssessmentReport.minutesText,

        }
    } else {
        staticText.AssessmentReport = {
            title: "Diagnostic Assessment Report",
            greetings: 'We are glad that you have chosen to trial countingwell to learn maths.We suggest you to use countingwell regularly to build confidence and improve performance in maths.',
            reportText: "Here is the detailed report of your Diagnostic Assessment:",
            buttonTitle: 'VIEW REFRESHER COURSE',
            skillsTitle: 'SKILLS',
            skillIndexText: 'SKILL',
            marksScoredText: 'Marks Scored',
            timeTakenText: 'Time Taken',
            correctText: 'Correct',
            inCorrectText: 'Incorrect',
            notAnsweredText: 'Not Answered',
            minutesText: 'mins'

        }
    }

    if (input && input.RefresherCourse) {
        staticText.RefresherCourse = {
            refresherCourseTitle: input.RefresherCourse.refresherCourseTitle,
            courseCompletedText: input.RefresherCourse.courseCompletedText,
        }
    } else {
        staticText.RefresherCourse = {
            refresherCourseTitle: "Refresher Course",
            courseCompletedText: 'REFRESHER COURSE COMPLETED'
        }
    }

    if (input && input.DiagnosticAssessmentEndScreen) {
        staticText.DiagnosticAssessmentEndScreen = {
            marksScoredText: input.DiagnosticAssessmentEndScreen.marksScoredText,
            timeTakenText: input.DiagnosticAssessmentEndScreen.timeTakenText,
            correctText: input.DiagnosticAssessmentEndScreen.correctText,
            inCorrectText: input.DiagnosticAssessmentEndScreen.inCorrectText,
            notAnsweredText: input.DiagnosticAssessmentEndScreen.notAnsweredText,
            minutesText: input.DiagnosticAssessmentEndScreen.minutesText,
            viewAnswerButtonTitle: input.DiagnosticAssessmentEndScreen.viewAnswerButtonTitle,
            viewReportButtonTitle: input.DiagnosticAssessmentEndScreen.viewReportButtonTitle,
            assessmentCompletedText: input.DiagnosticAssessmentEndScreen.assessmentCompletedText,

        }
    } else {
        staticText.DiagnosticAssessmentEndScreen = {
            marksScoredText: 'Marks Scored',
            timeTakenText: 'Time Taken',
            correctText: 'Correct',
            inCorrectText: 'Incorrect',
            notAnsweredText: 'Not Answered',
            minutesText: 'mins',
            viewAnswerButtonTitle: 'VIEW ANSWERS',
            viewReportButtonTitle: 'VIEW DETAIL REPORT',
            assessmentCompletedText: 'You’re done !!!'
        }
    }

    if (input && input.settingsScreen) {
        staticText.settingsScreen = {
            mySettingsText: input.settingsScreen.mySettingsText,
            profileText: input.settingsScreen.profileText,
            classText: input.settingsScreen.classText,
            upgradeLimitWarningCard: input.settingsScreen.upgradeLimitWarningCard,
            daysWantToPracticeText: input.settingsScreen.daysWantToPracticeText,
            likeToPracticeText: input.settingsScreen.likeToPracticeText,
            dailyGoalText: input.settingsScreen.dailyGoalText
        }
    } else {
        staticText.settingsScreen = {
            mySettingsText: 'My Settings',
            profileText: "PROFILE",
            classText: "CLASS",
            upgradeLimitWarningCard: "You cannot update your class since you have already updated it  once. Reach out to <strong>support@countingwell.com</strong> for further help.",
            daysWantToPracticeText: 'DAYS I WANT TO PRACTICE MATHS',
            likeToPracticeText: "I LIKE TO PRACTICE IN",
            dailyGoalText: 'MY DAILY GOAL FOR USING THE APP'
        }
    }

    if (input && input.editClass) {
        staticText.editClass = {
            editClassText: input.editClass.editClassText,
            continueButton: input.editClass.continueButton,
            verifyButton: input.editClass.verifyButton,
            warningCard: input.editClass.warningCard
        }
    } else {
        staticText.editClass = {
            editClassText: 'Edit Class',
            continueButton: "CONTINUE",
            verifyButton: "VERIFY",
            warningCard: `Updating Class is allowed only once in a year, so please be careful. Once changed, you can’t revert back`
        }
    }

    if (input && input.BasicPlanFeatures) {
        staticText.BasicPlanFeatures = {
            headerText: input.BasicPlanFeatures.headerText,
            messageText: input.BasicPlanFeatures.messageText,
            happyLearning: input.BasicPlanFeatures.happyLearning,
            continueButtonTitle: input.BasicPlanFeatures.continueButtonTitle,
        }
    } else {
        staticText.BasicPlanFeatures = {
            headerText: "Hello, We're glad you're here",
            messageText: "Congrats, <%userName%>! You've been awarded 10 days of Special Access Pass, which means you can access the app for 20 minutes a day and after that, it reverts to a basic plan of 10 minutes a day.",
            happyLearning: 'Happy Learning!',
            continueButtonTitle: "CONTINUE",
        }
    }

    if (input && input.DailyTimeLimitPopup) {
        staticText.DailyTimeLimitPopup = input.DailyTimeLimitPopup
    } else {
        staticText.DailyTimeLimitPopup = [
            {
                twentyMinutesTimeUpMesasage: "You have exhausted your daily access limit",
                tenMinutesTimeUpMessage: "Whoops, looks like you're out of minutes for today!",
                twentyMinutesMessageText: "Upgrade to Premium for unlimited access or <strong>wait till tomorrow</strong> for a new session.",
                tenMinutesMessageText: "Upgrade to Premium for unlimited access or <strong>wait till tomorrow</strong> for a new workout session.",
                upgradeButtonTitle: "UPGRADE TO PREMIUM",
                continueButtonTitle: "CONTINUE TOMORROW"
            },
            {
                twentyMinutesTimeUpMesasage: "You have exhausted your daily access limit",
                tenMinutesTimeUpMessage: "You’ve reached your daily workout limit of 10 minutes",
                twentyMinutesMessageText: "Upgrade to Premium for unlimited access or <strong>wait till tomorrow</strong> for a new session.",
                tenMinutesMessageText: "Upgrade to Premium to continue learning",
                upgradeButtonTitle: "UPGRADE TO PREMIUM",
                continueButtonTitle: "CONTINUE TOMORROW"
            }
        ]
    }

    if (input && input.ReducedTimeLimitPopup) {
        staticText.ReducedTimeLimitPopup = {
            reducedTimeLimitMesasage: input.ReducedTimeLimitPopup.reducedTimeLimitMesasage,
            messageText: input.ReducedTimeLimitPopup.messageText,
            upgradeButtonTitle: input.ReducedTimeLimitPopup.upgradeButtonTitle,
            continueButtonTitle: input.ReducedTimeLimitPopup.continueButtonTitle,
        }
    } else {
        staticText.ReducedTimeLimitPopup = {
            reducedTimeLimitMesasage: "Your daily limit is reduced to 10 minutes while you continue to be on the basic plan",
            messageText: "Click upgrade, and you'll be upgraded to our premium plan in under a minute",
            upgradeButtonTitle: "UPGRADE",
            continueButtonTitle: "CONTINUE",

        }
    }

    if (input && input.StartTimeToaster) {
        staticText.StartTimeToaster = input.StartTimeToaster
    } else {
        staticText.StartTimeToaster = [
            {
                twentyMinutesText: "We're ready to go, your 20 minute workout starts now!",
                tenMinutesText: "10 minutes starts now. We'll see you at the finish line!",
                toasterSec: 6
            },
            {
                twentyMinutesText: "It is time to begin! Your 20 minutes starts now",
                tenMinutesText: "10 minutes starts now. We'll see you at the finish line!",
                toasterSec: 6
            }
        ]
    }

    if (input && input.groupTitlesListScreen) {
        staticText.groupTitlesListScreen = {
            letsAssessText: input.groupTitlesListScreen.letsAssessText,
        }
    } else {
        staticText.groupTitlesListScreen = {
            letsAssessText: "Let’s Assess your knowledge"
        }
    }

    if (input && input.SubscriptionEndPopup) {
        staticText.SubscriptionEndPopup = {
            headerText: input.SubscriptionEndPopup.headerText,
            messageText: input.SubscriptionEndPopup.messageText,
            upgradeText: input.SubscriptionEndPopup.upgradeText,
            notNowButtonTitle: input.SubscriptionEndPopup.notNowButtonTitle,
            upgradeButtonTitle: input.SubscriptionEndPopup.upgradeButtonTitle,
        }
    } else {
        staticText.SubscriptionEndPopup = {
            headerText: "Premium Membership Expired",
            messageText: "<%userName%>, Your Premium membership got expired on <%subscriptionEndDate%>, You are moved to Basic Plan as part of which you will have a daily access limit of only 10 mins",
            upgradeText: "Upgrade to Premium for unlimited access",
            upgradeButtonTitle: "UPGRADE",
            notNowButtonTitle: "NOT NOW",
        }
    }

    if (input && input.scollarship) {
        staticText.scollarship = {
            headerText: input.scollarship.headerText,
            description: input.scollarship.description,
            completeRefresherCourse: input.scollarship.completeRefresherCourse,
            completeRefresherCourseSubText: input.scollarship.completeRefresherCourseSubText,
            takeScollarShipText: input.scollarship.takeScollarShipText,
            takeScollarShipSubText: input.scollarship.takeScollarShipSubText,
            provideSchoolDetails: input.scollarship.provideSchoolDetails,
            provideSchoolDetailsSubText: input.scollarship.provideSchoolDetailsSubText,
        }
    } else {
        staticText.scollarship = {
            headerText: "Scholarship",
            description: `Participate in Countingwell Ramanujan Maths Scholarship to win cash prizes & rewards. Complete the Refresher Course to Prepare and Qualify for Scholarship Test.`,
            completeRefresherCourse: "Complete Refresher Course",
            completeRefresherCourseSubText: "Catch upon last year’s portion",
            takeScollarShipText: "Take a Scholarship Test",
            takeScollarShipSubText: "Date will be announced shortly",
            provideSchoolDetails: "Provide your School details",
            provideSchoolDetailsSubText: "Required to complete your profile",

        }
    }

    if (input && input.scollarshipStartScreen) {
        staticText.scollarshipStartScreen = {
            headerText: input.scollarshipStartScreen.headerText,
            commingSoon: input.scollarshipStartScreen.commingSoon,
            dateAnnoucedShortlyText: input.scollarshipStartScreen.dateAnnoucedShortlyText,
            redirectUrl: input.scollarshipStartScreen.redirectUrl,
            marksText: input.scollarshipStartScreen.marksText,
            questionsText: input.scollarshipStartScreen.questionsText,
            durationText: input.scollarshipStartScreen.durationText,
            instructionText: input.scollarshipStartScreen.instructionText,
            moreProTipsText: input.scollarshipStartScreen.moreProTipsText,
            startButtonTitle: input.scollarshipStartScreen.startButtonTitle,
            noAssessmentsText: input.scollarshipStartScreen.noAssessmentsText,
            agreeText: input.scollarshipStartScreen.agreeText,
            termsAndConditionsText: input.scollarshipStartScreen.termsAndConditionsText,

            scheduleText: input.scollarshipStartScreen.scheduleText,
            baselineSchedule: input.scollarshipStartScreen.baselineSchedule,
            scholarshipEventSchedule: input.scollarshipStartScreen.scholarshipEventSchedule,
        }
    } else {
        staticText.scollarshipStartScreen = {
            headerText: "Ramanujan Math Scholarship",
            commingSoon: "Coming Soon...",
            dateAnnoucedShortlyText: "Start test anytime between 4.00 - 7:00 PM, May 9th 2021",
            redirectUrl: "https://countingwell.com/scholarship.html",
            marksText: "Marks",
            questionsText: "Questions",
            durationText: "Duration",
            instructionText: "Please have pen & paper with you before you start the Assessment",
            moreProTipsText: "More Pro Tips",
            startButtonTitle: "START",
            noAssessmentsText: "Sorry, No Assessments available right now",
            agreeText: 'By starting up I agree to the ',
            termsAndConditionsText: 'terms and conditions',

            scheduleText: "Schedule",
            baselineSchedule: "Start test anytime between 4.00 - 7: 00 PM, May 20th 2021 ",
            scholarshipEventSchedule: "Start test anytime between 5.00 - 7: 00 PM, May 20th 2021"
        }
    }


    if (input && input.scollarShipEligiblePopUp) {
        staticText.scollarShipEligiblePopUp = {
            headerText: input.scollarShipEligiblePopUp.headerText,
            description: input.scollarShipEligiblePopUp.description,
        }
    } else {
        staticText.scollarShipEligiblePopUp = {
            headerText: "Congratulations!",
            description: `<p>Congratulations <%name%>, You are now eligible to take the <strong>SCHOLARSHIP TEST</strong>. Date will be announced shortly.</p><p></p><p>In the meantime, complete the refresher course to prepare for the next year.</p>`

        }
    }

    if (input && input.SubscriptionFailure) {
        staticText.SubscriptionFailure = {
            redirectUrl: input.SubscriptionFailure.redirectUrl,
            paymentFailed: input.SubscriptionFailure.paymentFailed,
            goToHomeText: input.SubscriptionFailure.goToHomeText,
            infoText1: input.SubscriptionFailure.infoText1,
            infoText2: input.SubscriptionFailure.infoText2,
            website: input.SubscriptionFailure.website,
        }
    } else {
        staticText.SubscriptionFailure = {
            redirectUrl: "https://countingwell.com/premium_subscription.html",
            paymentFailed: `Payment Failed`,
            goToHomeText: 'GO TO HOME',
            infoText1: 'Login to ',
            infoText2: ' for help ',
            website: 'countingwell.com'

        }
    }

    if (input && input.scholarshipEndScreen) {
        staticText.scholarshipEndScreen = {
            marksScoredText: input.scholarshipEndScreen.marksScoredText,
            timeTakenText: input.scholarshipEndScreen.timeTakenText,
            correctText: input.scholarshipEndScreen.correctText,
            inCorrectText: input.scholarshipEndScreen.inCorrectText,
            notAnsweredText: input.scholarshipEndScreen.notAnsweredText,
            minutesText: input.scholarshipEndScreen.minutesText,
            viewAnswerButtonTitle: input.scholarshipEndScreen.viewAnswerButtonTitle,
            viewReportButtonTitle: input.scholarshipEndScreen.viewReportButtonTitle,
            assessmentCompletedText: input.scholarshipEndScreen.assessmentCompletedText,
            resultInfoText: input.scholarshipEndScreen.resultInfoText,
            scholarshipTestName: input.scholarshipEndScreen.scholarshipTestName,
            scholarshipText: input.scholarshipEndScreen.scholarshipText,
            participationCertificate: input.scholarshipEndScreen.participationCertificate,
            resultAnnouncedText: input.scholarshipEndScreen.resultAnnouncedText,
            resultWillAnnounced: input.scholarshipEndScreen.resultWillAnnounced,

        }
    } else {
        staticText.scholarshipEndScreen = {
            marksScoredText: 'Marks Scored',
            timeTakenText: 'Time Taken',
            correctText: 'Correct',
            inCorrectText: 'Incorrect',
            notAnsweredText: 'Not Answered',
            minutesText: 'mins',
            viewAnswerButtonTitle: 'VIEW ANSWERS',
            viewReportButtonTitle: 'VIEW DETAIL REPORT',
            assessmentCompletedText: 'You’re done !!!',
            scholarshipTestName: 'Ramanujan Math Scholarship',
            participationCertificate: 'PARTICIPATION CERTIFICATE',
            scholarshipText: '<p><strong>SCHOLARSHIP TEST</strong> results will be announced on 11th May. Visit our website <link href="https://www.countingwell.com">www.countingwell.com</link> to view results.</p>',
            resultInfoText: " results will be announced on 11th May. Visit our website www.countingwell.com to view results.",
            resultAnnouncedText: '<p><strong>SCHOLARSHIP TEST</strong> results are announced.Visit our website <link href="https://www.countingwell.com">www.countingwell.com</link> to view results.</p>',
            resultWillAnnounced: [
                {
                    "msg": "SCHOLARSHIP",
                    "style": "bold"
                },
                {
                    "msg": "results will be announced on 11th May.Visit our website ",
                    "style": "text"
                },
                {
                    "msg": "www.countingwell.com",
                    "style": "link",
                    "url": "https://www.countingwell.com"
                },
                {
                    "msg": " to view results.",
                    "style": "text"
                }
            ],
            resultAnnouncedText: [
                {
                    "msg": "SCHOLARSHIP",
                    "style": "bold"
                },
                {
                    "msg": "SCHOLARSHIP results are announced",
                    "style": "text"
                },
                {
                    "msg": "Click here",
                    "style": "link",
                    "url": "https://www.countingwell.com"
                },
                {
                    "msg": " to view results.",
                    "style": "text"
                }
            ],
        }
    }


    if (input && input.scholarshipFeedback) {
        staticText.scholarshipFeedback = {
            headerText: input.scholarshipFeedback.headerText,
            description: input.scholarshipFeedback.description,
            buttonText: input.scholarshipFeedback.buttonText
        }
    } else {
        staticText.scholarshipFeedback = {
            headerText: "Scholarship Test is finished",
            description: "Before viewing the results & answer sheet, Please fill this quick survey about your product experience",
            buttonText: "VIEW SCHOLARSHIP TEST RESULT"
        }
    }

    if (input && input.addYourSchool) {
        staticText.addYourSchool = {
            headerText: input.addYourSchool.headerText,
            localityTitle: input.addYourSchool.localityTitle,
            localityPlaceholder: input.addYourSchool.localityPlaceholder,
            enterLocalityText: input.addYourSchool.enterLocalityText,
            schoolTitle: input.addYourSchool.schoolTitle,
            enterSchoolText: input.addYourSchool.enterSchoolText,
            schoolPlaceholder: input.addYourSchool.schoolPlaceholder,
            startButtonTitle: input.addYourSchool.startButtonTitle,
            continueButtonTitle: input.addYourSchool.continueButtonTitle,
            scholarshipHeaderText: input.addYourSchool.scholarshipHeaderText
        }
    } else {
        staticText.addYourSchool = {
            headerText: 'Add Your School',
            localityTitle: 'LOCALITY',
            localityPlaceholder: 'Enter Locality',
            enterLocalityText: 'Please enter locality',
            schoolTitle: 'SCHOOL NAME',
            enterSchoolText: 'Please enter school name',
            schoolPlaceholder: 'Enter School Name',
            startButtonTitle: 'START',
            continueButtonTitle: 'CONTINUE TO SCHOLARSHIP TEST',
            scholarshipHeaderText: 'Ramanujan Math Scholarship school'
        }
    }

    if (input && input.homeworkList) {
        staticText.homeworkList = {
            noHomeworkFoundText: input.homeworkList.noHomeworkFoundText,
            homeworkNotFoundMessage: input.homeworkList.homeworkNotFoundMessage,
            noResultFoundText: input.homeworkList.noResultFoundText
        }
    } else {
        staticText.homeworkList = {
            noHomeworkFoundText: "No Homework Found",
            homeworkNotFoundMessage: "All your Homeworks will appear here",
            noResultFoundText: "No Result Found"
        }
    }

    if (input && input.homeworkCard) {
        staticText.homeworkCard = {
            completedText: input.homeworkCard.completedText,
            notCompletedText: input.homeworkCard.notCompletedText,
            dueDateText: input.homeworkCard.dueDateText,
            submitDateText: input.homeworkCard.submitDateText,
            startButtonTitle: input.homeworkCard.startButtonTitle,
            resumeButtonTitle: input.homeworkCard.resumeButtonTitle,
            learnButtonTitle: input.homeworkCard.learnButtonTitle,
            questionsText: input.homeworkCard.questionsText,
            marksText: input.homeworkCard.marksText,
            timeText: input.homeworkCard.timeText,
            resultText: input.homeworkCard.resultText,
            marksScoredText: input.homeworkCard.marksScoredText,
            timeTakenText: input.homeworkCard.timeTakenText,
            studentsCompletedMessage: input.homeworkCard.studentsCompletedMessage,
            studentsCompletedParserMessage: input.homeworkCard.studentsCompletedParserMessage,
            overDueText: input.homeworkCard.overDueText,
            lastDateText: input.homeworkCard.lastDateText,
            daysToComplete: input.homeworkCard.daysToComplete,
            alternateChapterImageUrl: input.homeworkCard.alternateChapterImageUrl
        }
    } else {
        staticText.homeworkCard = {
            completedText: "Completed",
            notCompletedText: "Not Completed",
            dueDateText: "Due Date",
            submitDateText: "Submitted on",
            startButtonTitle: 'Start',
            resumeButtonTitle: 'Resume',
            learnButtonTitle: 'Learn',
            questionsText: 'Questions',
            marksText: 'Marks',
            timeText: 'Mins',
            resultText: 'Result',
            marksScoredText: 'Marks Scored',
            timeTakenText: 'Time Taken',
            studentsCompletedMessage: [
                {
                    "msg": "<%count%> Students ",
                    "style": "bold"
                },
                {
                    'msg': 'in your class have already completed the Homework',
                    "style": "text"

                }
            ],
            studentsCompletedParserMessage: '<strong><%count%> Students</strong> in your class have already completed the Homework',
            overDueText: "Overdue",
            lastDateText: "Due Today",
            daysToComplete: "days to Homework completion",
            alternateChapterImageUrl: ''
        }
    }

    if (input && input.homeworkConceptList) {
        staticText.homeworkConceptList = {
            clearedText: input.homeworkConceptList.clearedText,
            notClearedText: input.homeworkConceptList.notClearedText,
            notAttemptedText: input.homeworkConceptList.notAttemptedText,
            reviseButtonTitle: input.homeworkConceptList.reviseButtonTitle,
            startButtonTitle: input.homeworkConceptList.startButtonTitle,
            tryAgainButtonTitle: input.homeworkConceptList.tryAgainButtonTitle,
            continueButtonTitle: input.homeworkConceptList.continueButtonTitle,
            messageText: input.homeworkConceptList.messageText,
        }
    } else {
        staticText.homeworkConceptList = {
            clearedText: "Cleared",
            notClearedText: "Not Cleared",
            notAttemptedText: "Not Attempted",
            reviseButtonTitle: "Revise",
            tryAgainButtonTitle: "Try Again",
            startButtonTitle: 'Start',
            continueButtonTitle: 'Continue',
            messageText: 'Below is the list of all the Learning Outcomes from the Homework'
        }
    }

    if (input && input.homeworkCompletionScreen) {
        staticText.homeworkCompletionScreen = {
            homeworkCompletedText: input.homeworkCompletionScreen.homeworkCompletedText,
            learnButtonTitle: input.homeworkCompletionScreen.learnButtonTitle,
            goToHomeButtonTitle: input.homeworkCompletionScreen.goToHomeButtonTitle,
            oopsTitle: input.homeworkCompletionScreen.oopsTitle,
            greatTitle: input.homeworkCompletionScreen.greatTitle,
            retryElosText: input.homeworkCompletionScreen.retryElosText,
            reviseElosText: input.homeworkCompletionScreen.reviseElosText,
            yesText: input.homeworkCompletionScreen.yesText,
            viewAnswersText: input.homeworkCompletionScreen.viewAnswersText,
            clearedElosText: input.homeworkCompletionScreen.clearedElosText,
            notClearedElosText: input.homeworkCompletionScreen.notClearedElosText,
            moreText: input.homeworkCompletionScreen.moreText,
            lessText: input.homeworkCompletionScreen.lessText,
            continueLearningButtonTitle: input.homeworkCompletionScreen.continueLearningButtonTitle,
            foundGapsText: input.homeworkCompletionScreen.foundGapsText,
            viewDetailsText: input.homeworkCompletionScreen.viewDetailsText,
            fillGapsText: input.homeworkCompletionScreen.fillGapsText,
            continueButtonTitle: input.homeworkCompletionScreen.continueButtonTitle,
        }
    } else {
        staticText.homeworkCompletionScreen = {
            homeworkCompletedText: "Your Homework is completed",
            learnButtonTitle: "LEARN",
            goToHomeButtonTitle: "GO TO HOME",
            oopsTitle: "Oops",
            greatTitle: "Great",
            retryElosText: 'Do you want to master these uncleared learning outcomes?',
            reviseElosText: 'Do you want to revise all learning outcomes?',
            yesText: 'YES',
            viewAnswersText: 'VIEW ANSWERS',
            clearedElosText: 'Great! All the <%totalElos%> Learning Outcomes are cleared.',
            notClearedElosText: '<%elos%> out of <%totalElos%> Learning Outcomes are not cleared.',
            moreText: 'More',
            lessText: 'Less',
            continueLearningButtonTitle: "CONTINUE LEARNING",
            foundGapsText: "We have identified your Learning Gaps",
            viewDetailsText: "View Details",
            fillGapsText: "Let's fill these gaps",
            continueButtonTitle: " CONTINUE"

        }
    }

    if (input && input.standardHomeworkCompletionScreen) {
        staticText.standardHomeworkCompletionScreen = {
            oopsTitle: input.standardHomeworkCompletionScreen.oopsTitle,
            greatTitle: input.standardHomeworkCompletionScreen.greatTitle,
        }
    } else {
        staticText.standardHomeworkCompletionScreen = {
            oopsTitle: "Oops",
            greatTitle: "Great"
        }
    }


    if (input && input.eventCard) {
        staticText.eventCard = {
            headerText: input.eventCard.headerText,
            headerSubText: input.eventCard.headerSubText,
            eventParticipantsText: input.eventCard.eventParticipantsText,
            readyToParticipateText: input.eventCard.readyToParticipateText,
            baselineAssessmentLive: input.eventCard.baselineAssessmentLive,
            refresherStartText: input.eventCard.refresherStartText,
            refresherCompletedStudentsText: input.eventCard.refresherCompletedStudentsText,
            eventStartText: input.eventCard.eventStartText,
            scholarshipEventLiveText: input.eventCard.scholarshipEventLiveText,
            refresherDeadlineIsOver: input.eventCard.refresherDeadlineIsOver,
            eventDeadlineOverText: input.eventCard.eventDeadlineOverText,
            baselineDeadlineOver: input.eventCard.baselineDeadlineOver,
            eventFinishedText: input.eventCard.eventFinishedText,
            resultAnnouncedText: input.eventCard.resultAnnouncedText
        }
    } else {
        staticText.eventCard = {
            headerText: 'RAMANUJAN',
            headerSubText: 'MATHS SCHOLARSHIP',
            eventParticipantsText: "<%count%> students are interested in this contest",
            readyToParticipateText: "Be ready to compete with <%count%> students",
            baselineAssessmentLive: "Baseline Assessment is Live now. GO!",
            refresherStartText: "<%count%> students have already started Step 2",
            refresherCompletedStudentsText: "<%count%> students have already finished Step 2",
            eventStartText: "Be ready to compete with <%count%> students",
            scholarshipEventLiveText: "Scholarship Assessment is Live now.  GO!",
            refresherDeadlineIsOver: "Refresher Course deadline is over, Sorry! You can’t proceed",
            eventDeadlineOverText: "Assessment deadline is over, Sorry! You can’t proceed",
            baselineDeadlineOver: "Assessment deadline is over, Sorry! You can’t proceed",
            eventFinishedText: "Great that you have finished all 3 Steps! Results will be announced by 5:00 PM, 11 May",
            resultAnnouncedText: "Scholarship Test Results are announced Click here to view the Results"
        }
    }


    if (input && input.eventLandingScreen) {
        staticText.eventLandingScreen = {
            headerText: input.eventLandingScreen.headerText,
            description: input.eventLandingScreen.description,
            baselineAssessmentHeader: input.eventLandingScreen.baselineAssessmentHeader,
            baselineSubText: input.eventLandingScreen.baselineSubText,
            baselineSubText2: input.eventLandingScreen.baselineSubText2,
            refresherAssessmentheader: input.eventLandingScreen.refresherAssessmentheader,
            refresherSubText: input.eventLandingScreen.refresherSubText,
            refresherSubText2: input.eventLandingScreen.refresherSubText2,
            scholarshipAssessmentHeader: input.eventLandingScreen.scholarshipAssessmentHeader,
            eventSubText: input.eventLandingScreen.eventSubText,
            eventSubText2: input.eventLandingScreen.eventSubText2,
            baselineCompletedText: input.eventLandingScreen.baselineCompletedText,
            refresherCompletedText: input.eventLandingScreen.refresherCompletedText,
            eventCompletedText: input.eventLandingScreen.eventCompletedText,
            resultWillAnnounced: input.eventLandingScreen.resultWillAnnounced,
            resultAnnouncedText: input.eventLandingScreen.resultAnnouncedText,
            participationCertificate: input.eventLandingScreen.participationCertificate
        }
    } else {
        staticText.eventLandingScreen = {
            headerText: 'Ramanujan Maths Scholarship',
            description: "Participate in Countingwell Ramanujan Maths Scholarship to win cash prizes & rewards. Complete the Refresher Course to Prepare and Qualify for Scholarship Test.",
            baselineAssessmentHeader: "Take Baseline Assessment",
            baselineSubText: "<p>This would take approx. <strong>30 mins</strong></p>",
            baselineSubText2: "<p>Take it anytime between <strong>4-7 PM, 20th May</strong></p>",
            refresherAssessmentheader: "Complete Refesher Course",
            refresherSubText: "<p>This would take approx. <strong>60 mins</strong></p>",
            refresherSubText2: "<p>Complete it before <strong>9:00 PM, 23th May</strong></p>",
            scholarshipAssessmentHeader: "Take Scholarship Assessment",
            eventSubText: "<p>This would take approx. <strong>30 mins</strong></p>",
            eventSubText2: "<p>Take it anytime between <strong>4-7 PM, 25th May</strong></p>",
            baselineCompletedText: "Baseline Assessment is completed",
            refresherCompletedText: "Refresher Course is completed",
            eventCompletedText: "Scholarship Assessment is completed",
            resultWillAnnounced: [
                {
                    "msg": "SCHOLARSHIP",
                    "style": "bold"
                },
                {
                    "msg": "results will be announced on 11th May.Visit our website ",
                    "style": "text"
                },
                {
                    "msg": "www.countingwell.com",
                    "style": "link",
                    "url": "https://www.countingwell.com"
                },
                {
                    "msg": " to view results.",
                    "style": "text"
                }
            ],
            resultAnnouncedText: [
                {
                    "msg": "SCHOLARSHIP ",
                    "style": "bold"
                },
                {
                    "msg": "SCHOLARSHIP results are announced ",
                    "style": "text"
                },
                {
                    "msg": "Click here ",
                    "style": "link",
                    "url": "https://www.countingwell.com"
                },
                {
                    "msg": " to view results.",
                    "style": "text"
                }
            ],
            participationCertificate: "PARTICIPATON CERTIFICATE"
        }
    }

    if (input && input.assessmentEndPopUp) {
        staticText.assessmentEndPopUp = {
            flaggedText: input.assessmentEndPopUp.flaggedText,
            finishedText: input.assessmentEndPopUp.finishedText,
            questionsAreFlagged: input.assessmentEndPopUp.questionsAreFlagged,
            questionIsFlagged: input.assessmentEndPopUp.questionIsFlagged
        }
    } else {
        staticText.assessmentEndPopUp = {
            flaggedText: "Do you want to attempt flagged or unanswered questions ?",
            finishedText: "You have attempted all the Questions, Do you want to Finish the Test ?",
            questionsAreFlagged: "Questions are Flagged",
            questionIsFlagged: "Question is flagged"
        }
    }

    if (input && input.schoolsPopup) {
        staticText.schoolsPopup = {
            searchPlaceHolder: input.schoolsPopup.searchPlaceHolder,
            cantFindSchool: input.schoolsPopup.cantFindSchool,
            addYourSchoolText: input.schoolsPopup.addYourSchoolText,
            addYourSchoolDescription: input.schoolsPopup.addYourSchoolDescription,
            titleText: input.schoolsPopup.titleText
        }
    } else {
        staticText.schoolsPopup = {
            searchPlaceHolder: "Enter School Name",
            cantFindSchool: "Can’t find your School?",
            addYourSchoolText: "ADD YOUR SCHOOL",
            addYourSchoolDescription: "Looks like We dont have your school in our Database, Please Add your School ",
            titleText: 'School Name'
        }
    }


    if (input && input.addYourSchoolV2) {
        staticText.addYourSchoolV2 = {
            headerText: input.addYourSchoolV2.headerText,
            localityTitle: input.addYourSchoolV2.localityTitle,
            localityPlaceholder: input.addYourSchoolV2.localityPlaceholder,
            enterLocalityText: input.addYourSchoolV2.enterLocalityText,
            schoolTitle: input.addYourSchoolV2.schoolTitle,
            enterSchoolText: input.addYourSchoolV2.enterSchoolText,
            schoolPlaceholder: input.addYourSchoolV2.schoolPlaceholder,
            startButtonTitle: input.addYourSchoolV2.startButtonTitle,
            continueButtonTitle: input.addYourSchoolV2.continueButtonTitle,
            scholarshipHeaderText: input.addYourSchoolV2.scholarshipHeaderText,
            cityLabel: input.addYourSchoolV2.cityLabel,
            cityPlaceholder: input.addYourSchoolV2.cityPlaceholder,
            cityNote: input.addYourSchoolV2.cityNote,
            confirmButtonTitle: input.addYourSchoolV2.confirmButtonTitle
        }
    } else {
        staticText.addYourSchoolV2 = {
            headerText: 'Add your City & School before we start',
            localityTitle: 'LOCALITY',
            localityPlaceholder: 'Enter Locality',
            enterLocalityText: 'Please enter locality',
            schoolTitle: 'YOUR SCHOOL NAME',
            enterSchoolText: 'Please enter school name',
            schoolPlaceholder: 'Enter School Name',
            startButtonTitle: 'START',
            continueButtonTitle: 'CONTINUE',
            scholarshipHeaderText: 'Ramanujan Math Scholarship school',
            cityLabel: 'WHICH CITY IS YOUR SCHOOL IN',
            cityPlaceholder: "Enter City",
            cityNote: "Make sure this is the city where your School is located",
            confirmButtonTitle: "CONFIRM"
        }
    }

    if (input && input.schoolCapture) {
        staticText.schoolCapture = {
            headerText: input.schoolCapture.headerText,
            localityTitle: input.schoolCapture.localityTitle,
            localityPlaceholder: input.schoolCapture.localityPlaceholder,
            enterLocalityText: input.schoolCapture.enterLocalityText,
            schoolTitle: input.schoolCapture.schoolTitle,
            enterSchoolText: input.schoolCapture.enterSchoolText,
            schoolPlaceholder: input.schoolCapture.schoolPlaceholder,
            startButtonTitle: input.schoolCapture.startButtonTitle,
            continueButtonTitle: input.schoolCapture.continueButtonTitle,
            scholarshipHeaderText: input.schoolCapture.scholarshipHeaderText,
            yes: input.schoolCapture.yes,
            didYouMean: input.schoolCapture.didYouMean,
            note: input.schoolCapture.note,
            submitButton: input.schoolCapture.submitButton,
            schoolStatePlaceholder: input.schoolCapture.schoolStatePlaceholder,
            schoolState: input.schoolCapture.schoolState
        }
    } else {
        staticText.schoolCapture = {
            headerText: 'Add Your School',
            localityTitle: 'LOCALITY',
            localityPlaceholder: 'Enter School Locality',
            enterLocalityText: 'Please enter locality',
            schoolTitle: 'SCHOOL NAME',
            enterSchoolText: 'Please enter school name',
            schoolPlaceholder: 'Enter School Name',
            startButtonTitle: 'START',
            continueButtonTitle: 'CONTINUE',
            scholarshipHeaderText: 'Ramanujan Math Scholarship school',
            yes: 'YES',
            didYouMean: "Do You mean?",
            note: "Enter the full name of your school & locality",
            submitButton: "SUBMIT",
            schoolStatePlaceholder: "Enter School State",
            schoolState: "SCHOOL STATE"
        }
    }


    if (input && input.landingScreenTimeupTimer) {
        staticText.landingScreenTimeupTimer = {
            headerText: input.landingScreenTimeupTimer.headerText,
            hours: input.landingScreenTimeupTimer.hours,
            mins: input.landingScreenTimeupTimer.mins,
            sec: input.landingScreenTimeupTimer.sec,
            or: input.landingScreenTimeupTimer.or,
            buttonText: input.landingScreenTimeupTimer.buttonText
        }
    } else {
        staticText.landingScreenTimeupTimer = {
            headerText: 'Continue FREE learning in',
            hours: "HOURS",
            mins: "MINS",
            sec: "SECS",
            or: "OR",
            buttonText: "UPGRADE TO PREMIUM"
        }
    }

    if (input && input.dailyLimitOverPopUp) {
        staticText.dailyLimitOverPopUp = {
            twentyMinutesExhusted: input.dailyLimitOverPopUp.twentyMinutesExhusted,
            tenMinutesExhusted: input.dailyLimitOverPopUp.tenMinutesExhusted,
            buttonText: input.dailyLimitOverPopUp.buttonText,
        }
    } else {
        staticText.dailyLimitOverPopUp = {
            twentyMinutesExhusted: '<p>You have exhausted your daily access limit of 20 mins</p>',
            tenMinutesExhusted: '<p>You have exhausted your daily access limit of 10 mins</p>',
            buttonText: "Ok"
        }
    }

    if (input && input.dailyLimitWarningPopUp) {
        staticText.dailyLimitWarningPopUp = {
            title: input.dailyLimitWarningPopUp.title,
            buttonText: input.dailyLimitWarningPopUp.buttonText,
        }
    } else {
        staticText.dailyLimitWarningPopUp = {
            title: "<p>Only <strong>2 more mins left</strong> for today’s Free access to get over. HURRY UP!</p>",
            buttonText: "Ok"
        }
    }

    if (input && input.newSubscriptionPopUp) {
        staticText.newSubscriptionPopUp = {
            headerText: input.newSubscriptionPopUp.headerText,
            continueLearningInText: input.newSubscriptionPopUp.continueLearningInText,
            hours: input.newSubscriptionPopUp.hours,
            mins: input.newSubscriptionPopUp.mins,
            secText: input.newSubscriptionPopUp.secText,
            or: input.newSubscriptionPopUp.or,
            subText: input.newSubscriptionPopUp.subText,
            bottomText: input.newSubscriptionPopUp.bottomText,
            buttonText: input.newSubscriptionPopUp.buttonText,
            mostPopularText: input.newSubscriptionPopUp.mostPopularText,
            forceHighlightPlanText: input.newSubscriptionPopUp.forceHighlightPlanText,
            removePlan: input.newSubscriptionPopUp.removePlan,
        }
    } else {
        staticText.newSubscriptionPopUp = {
            headerText: 'Upgrade to Premium',
            continueLearningInText: 'Continue FREE Learning in',
            hours: "HOURS",
            mins: "MINS",
            secText: "SECS",
            or: "OR",
            subText: "<p>Get <strong>uninterrupted</strong> learning now</p>",
            bottomText: "Price mentioned is inclusive of GST",
            buttonText: "UPGRADE",
            mostPopularText: "Most Popular",
            forceHighlightPlanText: 'Exam Prepkit',
            removePlan: 'Exam Prepkit'
        }
    }

    if (input && input.crossLinking) {
        staticText.crossLinking = {
            didYouKnowText: input.crossLinking.didYouKnowText,
            skipButtonTitle: input.crossLinking.skipButtonTitle,
        }
    } else {
        staticText.crossLinking = {
            didYouKnowText: "Did you know ?",
            skipButtonTitle: "SKIP"
        }
    }

    if (input && input.overlayHelp) {
        staticText.overlayHelp = {
            bookmarkDescription: input.overlayHelp.bookmarkDescription,
            lateralMenuDescription: input.overlayHelp.lateralMenuDescription,
            okText: input.overlayHelp.okText,
        }
    } else {
        staticText.overlayHelp = {
            bookmarkDescription: "Bookmark useful content for quick revision and access it from My Corner",
            lateralMenuDescription: "Use the Lateral menu to access the recently accessed content & quick navigation to Home and My Corner",
            okText: 'OK'
        }
    }

    if (input && input.timerPauseToaster) {
        staticText.timerPauseToaster = {
            timerPauseText: input.timerPauseToaster.timerPauseText,
            timerResumeText: input.timerPauseToaster.timerResumeText,
            buttonTitle: input.timerPauseToaster.buttonTitle
        }
    } else {
        staticText.timerPauseToaster = {
            timerPauseText: "Remaining <%time%> minutes for today will not be counted while doing your Homework",
            timerResumeText: "Counter started again. <%time%> more minutes to go for today.",
            buttonTitle: "OK, GOT IT"
        }
    }

    if (input && input.schoolConnect) {
        staticText.schoolConnect = {
            headerText: input.schoolConnect.headerText,
            schoolCode: input.schoolConnect.schoolCode,
            schoolCodePlaceholder: input.schoolConnect.schoolCodePlaceholder,
            connectButton: input.schoolConnect.connectButton,
            note: input.schoolConnect.note,
            invalidSchoolCode: input.schoolConnect.invalidSchoolCode,
            connectLaterButton: input.schoolConnect.connectLaterButton
        }
    } else {
        staticText.schoolConnect = {
            headerText: "Connect your School",
            schoolCode: "SCHOOL CODE",
            schoolCodePlaceholder: "Enter your School code",
            connectButton: "CONNECT",
            note: "School Code is Optional. Enter School code only if it is shared with you by your school, If not dont bother.",
            invalidSchoolCode: "Invalid School code",
            connectLaterButton: "CONNECT LATER",
        }
    }

    if (input && input.schoolConnectPopup) {
        staticText.schoolConnectPopup = {
            headerText: input.schoolConnectPopup.headerText,
            notMySchoolButtonText: input.schoolConnectPopup.notMySchoolButtonText,
            confirmButton: input.schoolConnectPopup.confirmButton
        }
    } else {
        staticText.schoolConnectPopup = {
            headerText: "Confirm Your School Name",
            notMySchoolButtonText: "NOT MY SCHOOL",
            confirmButton: "CONFIRM"
        }
    }


    if (input && input.schoolConnected) {
        staticText.schoolConnected = {
            headerText: input.schoolConnected.headerText,
            schoolCode: input.schoolConnected.schoolCode,
            school: input.schoolConnected.school
        }
    } else {
        staticText.schoolConnected = {
            headerText: "Connected School",
            schoolCode: "SCHOOL CODE",
            school: "SCHOOL"
        }
    }

    if (input && input.schoolVerify) {
        staticText.schoolVerify = {
            headerText: input.schoolVerify.headerText,
            schoolCode: input.schoolVerify.schoolCode,
            confirmButton: input.schoolVerify.confirmButton,
            declineButton: input.schoolVerify.declineButton,
            invalidSchoolCode: input.schoolVerify.invalidSchoolCode,
            schoolChangeHeaderText1: input.schoolVerify.schoolChangeHeaderText1,
            schoolChangeHeaderText2: input.schoolVerify.schoolChangeHeaderText2,
            schoolChangeConfirmTitle: input.schoolVerify.schoolChangeConfirmTitle,
            schoolChangeConfirmText: input.schoolVerify.schoolChangeConfirmText,
            schoolChangeDeclineText: input.schoolVerify.schoolChangeDeclineText
        }
    } else {
        staticText.schoolVerify = {
            headerText: "Confirm your \nSchool",
            schoolCode: "SCHOOL CODE",
            confirmButton: "YES, THIS IS MY SCHOOL CODE",
            declineButton: "NOT MY SCHOOL",
            invalidSchoolCode: "Invalid School code",
            schoolChangeHeaderText1: "You are trying to connect to",
            schoolChangeHeaderText2: "But you are already connected to",
            schoolChangeConfirmTitle: "Do you wish to update the school?",
            schoolChangeConfirmText: "YES",
            schoolChangeDeclineText: "NO"
        }
    }

    if (input && input.SchoolConnectedToaster) {
        staticText.SchoolConnectedToaster = input.SchoolConnectedToaster
    } else {
        staticText.SchoolConnectedToaster = {
            successText: "School Connected"
        }
    }

    if (input && input.prepkitToaster) {
        staticText.prepkitToaster = {
            toasterMessage: input.prepkitToaster.toasterMessage,
            toasterSec: input.prepkitToaster.toasterSec
        }
    } else {
        staticText.prepkitToaster = {
            toasterMessage: '10 mins over from Exam prep free trial limit',
            toasterSec: 3
        }
    }

    if (input && input.examPrepkitFreeUsersPopup) {
        staticText.examPrepkitFreeUsersPopup = {
            basicUsersTimeStartMessage: input.examPrepkitFreeUsersPopup.basicUsersTimeStartMessage,
            monthlyUsersTimeStartMessage: input.examPrepkitFreeUsersPopup.monthlyUsersTimeStartMessage,
            basicUsersTimeRemainingMessage: input.examPrepkitFreeUsersPopup.basicUsersTimeRemainingMessage,
            basicUsersTimeExhaustedMessage: input.examPrepkitFreeUsersPopup.basicUsersTimeExhaustedMessage,
            monthlyUsersTimeExhaustedMessage: input.examPrepkitFreeUsersPopup.monthlyUsersTimeExhaustedMessage,
            buttonTitle: input.examPrepkitFreeUsersPopup.buttonTitle,
        }
    } else {
        staticText.examPrepkitFreeUsersPopup = {
            basicUsersTimeStartMessage: 'You have 30 Free Minutes to Explore the Exam Prep Kit',
            monthlyUsersTimeStartMessage: 'You have 30 Free Minutes to Explore the Exam Prep Kit',
            basicUsersTimeRemainingMessage: 'You could use <%time%> mins today to explore the Exam Prep Kit',
            basicUsersTimeExhaustedMessage: 'Your 30 mins limit for Exam prep free trial is exhausted',
            monthlyUsersTimeExhaustedMessage: 'Your 30 mins limit for Exam prep free trial is exhausted',
            buttonTitle: 'OK, Got It'
        }
    }

    if (input && input.prepkitChapterSelectionScreeen) {
        staticText.prepkitChapterSelectionScreeen = {
            selectChaptersText: input.prepkitChapterSelectionScreeen.selectChaptersText,
            noteText: input.prepkitChapterSelectionScreeen.noteText,
            buttonTitle: input.prepkitChapterSelectionScreeen.buttonTitle,
        }
    } else {
        staticText.prepkitChapterSelectionScreeen = {
            selectChaptersText: 'Select Chapters to be covered in Final Exams',
            noteText: 'NOTE: It can be edited later',
            buttonTitle: 'CONTINUE'
        }
    }

    if (input && input.examDateCaptureScreen) {
        staticText.examDateCaptureScreen = {
            headerText: input.examDateCaptureScreen.headerText,
            questionText: input.examDateCaptureScreen.questionText,
            tellExactDateText: input.examDateCaptureScreen.tellExactDateText,
            selectDateText: input.examDateCaptureScreen.selectDateText,
            dontHaveDateText: input.examDateCaptureScreen.dontHaveDateText,
        }
    } else {
        staticText.examDateCaptureScreen = {
            headerText: 'Let us know when are your Final Exams',
            questionText: 'When is your Maths Exam in School?',
            tellExactDateText: 'Tell us exact date of Exam',
            selectDateText: 'Select Date',
            dontHaveDateText: 'Dont have Exam dates yet'

        }
    }

    if (input && input.scorePredictorScreen) {
        staticText.scorePredictorScreen = {
            messageText: input.scorePredictorScreen.messageText,
            predictedScoreText: input.scorePredictorScreen.predictedScoreText,
            improveText: input.scorePredictorScreen.improveText,
            yesText: input.scorePredictorScreen.yesText,
            noText: input.scorePredictorScreen.noText,
            improvementForAbove60: input.scorePredictorScreen.improvementForAbove60,
            improvementForBelow60: input.scorePredictorScreen.improvementForBelow60,
        }
    } else {
        staticText.scorePredictorScreen = {
            messageText: '<%name%>, based on this performance your',
            predictedScoreText: 'Predicted score in final exams',
            improveText: 'Do you wish to Improve this score?',
            yesText: 'Yes',
            noText: 'No',
            improvementForAbove60: 10,
            improvementForBelow60: 15

        }
    }

    if (input && input.improveScorePopup) {
        staticText.improveScorePopup = {
            improveText: input.improveScorePopup.improveText,
            yesText: input.improveScorePopup.yesText,
            noText: input.improveScorePopup.noText,
        }
    } else {
        staticText.improveScorePopup = {
            improveText: 'Do you wish to Improve this score?',
            yesText: 'Yes',
            noText: 'No',
        }
    }

    if (input && input.thingsToRemember) {
        staticText.thingsToRemember = {
            note: input.thingsToRemember.note
        }
    } else {
        staticText.thingsToRemember = {
            note: "You need to complete all the section to mark this step completed"
        }
    }

    if (input && input.examPrepKitLeaningExpeirience) {
        staticText.examPrepKitLeaningExpeirience = {
            note: input.examPrepKitLeaningExpeirience.note
        }
    } else {
        staticText.examPrepKitLeaningExpeirience = {
            note: "You need to complete all the section to mark this step completed"
        }
    }

    if (input && input.approachToProblemSolving) {
        staticText.approachToProblemSolving = {
            note: input.approachToProblemSolving.note
        }
    } else {
        staticText.approachToProblemSolving = {
            note: "You need to complete all the section to mark this step completed"
        }
    }

    if (input && input.prepkitTableOfContents) {
        staticText.prepkitTableOfContents = {
            prepkitHeaderText: input.prepkitTableOfContents.prepkitHeaderText,
            tableOfContentsText: input.prepkitTableOfContents.tableOfContentsText,
            noteText: input.prepkitTableOfContents.noteText,
        }
    } else {
        staticText.prepkitTableOfContents = {
            prepkitHeaderText: 'Exam Prep Kit',
            tableOfContentsText: 'Table of Contents',
            noteText: 'You need to complete all the section to mark this step completed'
        }
    }


    if (input && input.prepkitChaptersList) {
        staticText.prepkitChaptersList = {
            mockTestText: input.prepkitChaptersList.mockTestText,
            mockTextForAllChaptersText: input.prepkitChaptersList.mockTextForAllChaptersText,
            examCompletedText: input.prepkitChaptersList.examCompletedText,
            addFinalExamDateText: input.prepkitChaptersList.addFinalExamDateText,
            remainingDaysText: input.prepkitChaptersList.remainingDaysText,
            editChaptersText: input.prepkitChaptersList.editChaptersText
        }
    } else {
        staticText.prepkitChaptersList = {
            mockTestText: 'Mock Test',
            mockTextForAllChaptersText: ' Mock Test for all Chapters',
            examCompletedText: 'Hope you did quite well in your Exams',
            addFinalExamDateText: 'Add Final Exams Date',
            remainingDaysText: 'days to go in Final Exams',
            editChaptersText: "Choose a chapter to revise"
        }
    }

    if (input && input.prepkitVideoContentScreen) {
        staticText.prepkitVideoContentScreen = {
            videoWithPrepkitGuarantee: input.prepkitVideoContentScreen.videoWithPrepkitGuarantee,
            videoWithouPrepkitGuarantee: input.prepkitVideoContentScreen.videoWithouPrepkitGuarantee,
            buttonTitle: input.prepkitVideoContentScreen.buttonTitle,
        }
    } else {
        staticText.prepkitVideoContentScreen = {
            videoWithPrepkitGuarantee: 'https://countingwell-assets-testing.s3.ap-south-1.amazonaws.com/b5bc65e9-b5af-4765-aa10-4568aa8dbe33-Pexels%20Videos%202795405.mp4',
            videoWithouPrepkitGuarantee: 'https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/workout_app_explore.mp4',
            buttonTitle: 'CONTINUE'
        }
    }

    if (input && input.section) {
        staticText.section = {
            title: input.section.title,
            save: input.section.save,
            sectionNotListed: input.section.sectionNotListed,
            noRecordTitle: input.section.noRecordTitle,
            noRecordDescription: input.section.noRecordDescription,
            ok: input.section.ok
        }
    } else {
        staticText.section = {
            title: "Select your Section",
            save: "SAVE",
            sectionNotListed: "My SECTION IS NOT LISTED",
            noRecordTitle: "Oops!",
            noRecordDescription: "No section is configured by School admin. Please contact you school teacher to help you configure the sections",
            ok: "OK"
        }
    }

    if (input && input.homeworkViewAnswerPopUp) {
        staticText.homeworkViewAnswerPopUp = {
            text: input.homeworkViewAnswerPopUp.text
        }
    } else {
        staticText.homeworkViewAnswerPopUp = {
            text: "Answers will be available after <time-comma><%time%></time-comma> <datemonthyear><%time%></datemonthyear>"
        }
    }


    if (input && input.prepkitMoneyBackScreen) {
        staticText.prepkitMoneyBackScreen = {
            buttonTitle: input.prepkitMoneyBackScreen.buttonTitle,
            termsAndConditionsText: input.prepkitMoneyBackScreen.termsAndConditionsText,
            termsAndConditionsUrl: input.prepkitMoneyBackScreen.termsAndConditionsUrl,
            descriptionText: input.prepkitMoneyBackScreen.descriptionText,
            guaranteeTextWithoutPredictedScore: input.prepkitMoneyBackScreen.guaranteeTextWithoutPredictedScore,
            guaranteeTextForScoreBelow50: input.prepkitMoneyBackScreen.guaranteeTextForScoreBelow50,
            guaranteeTextForScoreBetween50And70: input.prepkitMoneyBackScreen.guaranteeTextForScoreBetween50And70,
            guaranteeTextForScoreAbove70: input.prepkitMoneyBackScreen.guaranteeTextForScoreAbove70,
        }
    } else {
        staticText.prepkitMoneyBackScreen = {
            buttonTitle: "CONTINUE",
            termsAndConditionsText: "*Terms and Conditions apply",
            termsAndConditionsUrl: "https://countingwell.com/exam-prep-kit-terms-and-condition/index.html",
            descriptionText: 'Exam Prep Kit comes with Money back Guarantee',
            guaranteeTextWithoutPredictedScore: [
                {
                    "msg": "Score at least ",
                    "style": "text"
                },
                {
                    "msg": "10% ",
                    "style": "bold"
                },
                {
                    "msg": "higher than your Previous Year score or ",
                    "style": "text"
                },
                {
                    "msg": "70% ",
                    "style": "bold"
                },
                {
                    "msg": "marks in your final exams or get 100% refund.",
                    "style": "text"
                }
            ],
            guaranteeTextForScoreBelow50: [
                {
                    "msg": "Score at least ",
                    "style": "text"
                },
                {
                    "msg": "60% ",
                    "style": "bold"
                },
                {
                    "msg": "in your final exams or get 100% refund",
                    "style": "text"
                }
            ],
            guaranteeTextForScoreBetween50And70: [
                {
                    "msg": "Score at least ",
                    "style": "text"
                },
                {
                    "msg": "75% ",
                    "style": "bold"
                },
                {
                    "msg": "in your final exams or get 100% refund",
                    "style": "text"
                }
            ],
            guaranteeTextForScoreAbove70: [
                {
                    "msg": "Score at least ",
                    "style": "text"
                },
                {
                    "msg": "80% ",
                    "style": "bold"
                },
                {
                    "msg": "in your final exams or get 100% refund",
                    "style": "text"
                }
            ],

        }
    }

    if (input && input.appFirstPage) {
        staticText.appFirstPage = {
            title: input.appFirstPage.title,
            subTitle: input.appFirstPage.subTitle,
            homeworkSubTitle: input.appFirstPage.homeworkSubTitle,
            callToStepEvent: input.appFirstPage.callToStepEvent
        }
    } else {
        staticText.appFirstPage = {
            title: "Hey",
            subTitle: "What are you looking to do today on Countingwell?",
            homeworkSubTitle: "Your school teacher has assigned you the following homework",
            callToStepEvent: true
        }
    }

    if (input && input.takeChallengePopup) {
        staticText.takeChallengePopup = {
            title: input.takeChallengePopup.title,
            subTitle: input.takeChallengePopup.subTitle,
            buttonTitle: input.takeChallengePopup.buttonTitle,
            minAppTime: input.takeChallengePopup.minAppTime
        }
    } else {
        staticText.takeChallengePopup = {
            title: "Great <%name%>, You have finished all Practice questions for <%chapter%>.",
            subTitle: "Challenge yourself & see how much have you learnt",
            buttonTitle: "TAKE CHALLENGE",
            minAppTime: 15
        }
    }


    if (input && input.AssessmentEndScreen) {
        staticText.AssessmentEndScreen = {
            marksScoredText: input.AssessmentEndScreen.marksScoredText,
            timeTakenText: input.AssessmentEndScreen.timeTakenText,
            correctText: input.AssessmentEndScreen.correctText,
            inCorrectText: input.AssessmentEndScreen.inCorrectText,
            notAnsweredText: input.AssessmentEndScreen.notAnsweredText,
            minutesText: input.AssessmentEndScreen.minutesText,
            viewAnswerButtonTitle: input.AssessmentEndScreen.viewAnswerButtonTitle,
            viewReportButtonTitle: input.AssessmentEndScreen.viewReportButtonTitle,
            assessmentCompletedText: input.AssessmentEndScreen.assessmentCompletedText,
            predictedScoreText: input.AssessmentEndScreen.predictedScoreText,
            reviseConceptsText: input.AssessmentEndScreen.reviseConceptsText,
            continueButtonTitle: input.AssessmentEndScreen.continueButtonTitle,
            viewScoreText: input.AssessmentEndScreen.viewScoreText,

        }
    } else {
        staticText.AssessmentEndScreen = {
            marksScoredText: 'Marks Scored',
            timeTakenText: 'Time Taken',
            correctText: 'Correct',
            inCorrectText: 'Incorrect',
            notAnsweredText: 'Not Answered',
            minutesText: 'mins',
            viewAnswerButtonTitle: 'VIEW ANSWERS',
            viewReportButtonTitle: 'VIEW DETAIL REPORT',
            assessmentCompletedText: 'Assessment Completed!',
            predictedScoreText: 'Your Predicted score in final exams',
            reviseConceptsText: "Revise concepts for better scoring",
            continueButtonTitle: "CONTINUE",
            viewScoreText: "Let's look at your score"
        }
    }

    if (input && input.chapterRevisionScreen) {
        staticText.chapterRevisionScreen = {
            chaptersText: input.chapterRevisionScreen.chaptersText,
            removeFilterText: input.chapterRevisionScreen.removeFilterText,
        }
    } else {
        staticText.chapterRevisionScreen = {
            chaptersText: "CHAPTERS",
            removeFilterText: "Remove Filter",
        }
    }


    if (input && input.studentMetaScreen) {
        staticText.studentMetaScreen = {
            signUpButtonTitle: input.studentMetaScreen.signUpButtonTitle,
            genderSelectionText: input.studentMetaScreen.genderSelectionText,
            fullNameText: input.studentMetaScreen.fullNameText,
            fullNamePlaceHolder: input.studentMetaScreen.fullNamePlaceHolder,
            nickNameText: input.studentMetaScreen.nickNameText,
            nickNamePlaceHolder: input.studentMetaScreen.nickNamePlaceHolder,
            nickNameDescription: input.studentMetaScreen.nickNameDescription,
        }
    } else {
        staticText.studentMetaScreen = {
            signUpButtonTitle: "CONTINUE",
            genderSelectionText: 'I am a',
            fullNameText: "FULL NAME",
            fullNamePlaceHolder: "Enter your Full Name",
            nickNameText: "MY FRIENDS CALL ME",
            nickNamePlaceHolder: "Enter your Nick Name",
            nickNameDescription: "We need this to create a custom experience for you"
        }
    }

    if (input && input.studentSignUpContactDetails) {
        staticText.studentSignUpContactDetails = {
            signUpButtonTitle: input.studentSignUpContactDetails.signUpButtonTitle,
            emailTitle: input.studentSignUpContactDetails.emailTitle,
            emailPlaceHolder: input.studentSignUpContactDetails.emailPlaceHolder,
            mobileNumberTitle: input.studentSignUpContactDetails.mobileNumberTitle,
            mobilePlaceHolder: input.studentSignUpContactDetails.mobilePlaceHolder,
            defaultCountryCode: input.studentSignUpContactDetails.defaultCountryCode,
            invalidMobileMessage: input.studentSignUpContactDetails.invalidMobileMessage,
            invalidNameMessage: input.studentSignUpContactDetails.invalidNameMessage,
            userExistMessage: input.studentSignUpContactDetails.userExistMessage,
            otpSentText: input.studentSignUpContactDetails.otpSentText,
            userExistMessage: input.studentSignUpContactDetails.userExistMessage,
            agreeText: input.studentSignUpContactDetails.agreeText,
            termsAndConditionsText: input.studentSignUpContactDetails.termsAndConditionsText,
            invalidEmailMessage: input.studentSignUpContactDetails.invalidEmailMessage,
            internetErrorMessage: input.studentSignUpContactDetails.internetErrorMessage,
            verifyTitle: input.studentSignUpContactDetails.verifyTitle,
            cityAndContactsText: input.studentSignUpContactDetails.cityAndContactsText,
            optionalText: input.studentSignUpContactDetails.optionalText,
            cityText: input.studentSignUpContactDetails.cityText,
        }
    } else {
        staticText.studentSignUpContactDetails = {
            signUpButtonTitle: "SIGNUP",
            emailTitle: "EMAIL ID",
            emailPlaceHolder: "Enter Your Email",
            mobileNumberTitle: "MOBILE NO",
            mobilePlaceHolder: "Enter your Mobile no.",
            defaultCountryCode: "+91",
            invalidMobileMessage: "Enter valid Mobile number",
            invalidNameMessage: "Enter valid name",
            userExistMessage: "User already exists",
            otpSentText: "OTP sent to ",
            agreeText: "By registering I agree to the ",
            termsAndConditionsText: 'terms and conditions',
            invalidEmailMessage: 'Enter valid email',
            internetErrorMessage: 'Please check your internet connection and try again',
            verifyTitle: "VERIFY",
            cityAndContactsText: "My City & Contacts",
            optionalText: "OPTIONAL",
            cityText: 'CITY'
        }
    }

    if (input && input.newClassDetailsScreen) {
        staticText.newClassDetailsScreen = {
            classDetailsTitle: input.newClassDetailsScreen.classDetailsTitle,
            classSectionTitle: input.newClassDetailsScreen.classSectionTitle,
            boardTitle: input.newClassDetailsScreen.boardTitle,
            classTitle: input.newClassDetailsScreen.classTitle,
            schoolCodeTitle: input.newClassDetailsScreen.schoolCodeTitle,
            schoolCodePlaceHolder: input.newClassDetailsScreen.schoolCodePlaceHolder,
            continueButtonTitle: input.newClassDetailsScreen.continueButtonTitle,
            message: input.newClassDetailsScreen.message,
            otherMessage: input.newClassDetailsScreen.otherMessage,
            sectionsText: input.newClassDetailsScreen.sectionsText,
            selectClassText: input.newClassDetailsScreen.selectClassText,
        }
    } else {
        staticText.newClassDetailsScreen = {
            classDetailsTitle: "My Board & Class",
            classSectionTitle: 'My Class & Sections',
            boardTitle: "BOARD",
            classTitle: "CLASS",
            schoolCodeTitle: "SCHOOL CODE",
            schoolCodePlaceHolder: "Optional",
            continueButtonTitle: "CONTINUE",
            sectionsText: 'SECTIONS',
            selectClassText: 'Which class you will be in the coming academic year 2022-23 ?'

        }
    }


    if (input && input.schoolInfoConfirm) {
        staticText.schoolInfoConfirm = {
            title: input.schoolInfoConfirm.title,
            titleWhileOnboarding: input.schoolInfoConfirm.titleWhileOnboarding,
            schoolTitle: input.schoolInfoConfirm.schoolTitle,
            classTitle: input.schoolInfoConfirm.classTitle,
            sectionTitle: input.schoolInfoConfirm.sectionTitle,
            confirmButton: input.schoolInfoConfirm.confirmButton,
            incorrectDetailsButton: input.schoolInfoConfirm.incorrectDetailsButton
        }
    } else {
        staticText.schoolInfoConfirm = {
            title: "Are you from following school, class & section?",
            titleWhileOnboarding: "Are you <%name%> from following school, class & section?",
            schoolTitle: "SCHOOL",
            classTitle: "CLASS",
            sectionTitle: "SECTION",
            confirmButton: "CONFIRM",
            incorrectDetailsButton: "INCORRECT DETAILS"
        }
    }

    if (input && input.newSchoolConnectFlow) {
        staticText.newSchoolConnectFlow = {
            title: input.newSchoolConnectFlow.title,
            confirmButton: input.newSchoolConnectFlow.confirmButton,
            incorrectDetailsButton: input.newSchoolConnectFlow.incorrectDetailsButton
        }
    } else {
        staticText.newSchoolConnectFlow = {
            title: "Are you",
            confirmButton: "YES, THAT’S CORRECT",
            incorrectDetailsButton: "THAT’S NOT ME"
        }
    }

    if (input && input.schoolConfirmationScreen) {
        staticText.schoolConfirmationScreen = {
            questionText: input.schoolConfirmationScreen.questionText,
            correctText: input.schoolConfirmationScreen.correctText,
            inCorrectText: input.schoolConfirmationScreen.inCorrectText
        }
    } else {
        staticText.schoolConfirmationScreen = {
            questionText: "Are you from",
            correctText: "YES, THAT’S CORRECT",
            inCorrectText: "THAT’S NOT ME"
        }
    }


    if (input && input.selectDays) {
        staticText.selectDays = {
            headerText: input.selectDays.headerText,
            editLaterText: input.selectDays.editLaterText,
            saveText: input.selectDays.saveText,
            continueText: input.selectDays.continueText,
            daysText: input.selectDays.daysText,
            weekText: input.selectDays.weekText
        }
    } else {
        staticText.selectDays = {
            headerText: 'Days I want to Practice in a week',
            editLaterText: 'You can change it anytime later',
            saveText: 'SAVE',
            continueText: 'CONTINUE',
            daysText: 'days',
            weekText: 'in a week'
        }
    }

    if (input && input.selectTimeToPractice) {
        staticText.selectTimeToPractice = {
            headerText: input.selectTimeToPractice.headerText,
            editLaterText: input.selectTimeToPractice.editLaterText,
            saveText: input.selectTimeToPractice.saveText,
            continueText: input.selectTimeToPractice.continueText,
            daysText: input.selectTimeToPractice.daysText,
            weekText: input.selectTimeToPractice.weekText,
            morningDescription: input.selectTimeToPractice.morningDescription,
            eveningDescription: input.selectTimeToPractice.eveningDescription,
            nightDescription: input.selectTimeToPractice.nightDescription
        }
    } else {
        staticText.selectTimeToPractice = {
            headerText: 'I like to practice in',
            editLaterText: 'You can change it anytime later',
            saveText: 'SAVE',
            continueText: 'CONTINUE',
            daysText: 'days',
            weekText: 'in a week',
            morningDescription: '   Mornings, After I finish Glass of Milk',
            eveningDescription: '   Evenings, After I am done playing',
            nightDescription: '   Nights, Just after I finish yummy dinner',
        }
    }

    if (input && input.selectDailyGoal) {
        staticText.selectDailyGoal = {
            headerText: input.selectDailyGoal.headerText,
            editLaterText: input.selectDailyGoal.editLaterText,
            saveText: input.selectDailyGoal.saveText,
            continueText: input.selectDailyGoal.continueText,
            minsText: input.selectDailyGoal.minsText,
            perDayText: input.selectDailyGoal.perDayText,
            idealTimeText: input.selectDailyGoal.idealTimeText,
        }
    } else {
        staticText.selectDailyGoal = {
            headerText: 'My daily Goal is',
            editLaterText: 'You can change it anytime later',
            saveText: 'SAVE',
            continueText: 'CONTINUE',
            minsText: 'mins',
            perDayText: 'in a day',
            idealTimeText: "20 mins per day is Ideal"
        }
    }

    if (input && input.selectMathsLevel) {
        staticText.selectMathsLevel = {
            headerText: input.selectMathsLevel.headerText,
            continueText: input.selectMathsLevel.continueText,
            noviceDescription: input.selectMathsLevel.noviceDescription,
            intermediateDescription: input.selectMathsLevel.intermediateDescription,
            advanceDescription: input.selectMathsLevel.advanceDescription
        }
    } else {
        staticText.selectMathsLevel = {
            headerText: 'My current Level of Maths is',
            continueText: 'CONTINUE',
            noviceDescription: 'I usually score less than 50% in Maths exams',
            intermediateDescription: 'I usually score between 50-80% in Maths exams',
            advanceDescription: 'I usually score more than 80% in Maths exams',
        }
    }

    if (input && input.appWelcomeScreen) {
        staticText.appWelcomeScreen = {
            videoUrl: input.appWelcomeScreen.videoUrl,
            welcomeText: input.appWelcomeScreen.welcomeText,
            largestMathAppText: input.appWelcomeScreen.largestMathAppText,
            studentDetail: input.appWelcomeScreen.studentDetail,
            studentComment: input.appWelcomeScreen.studentComment,
            activeStudentsCount: input.appWelcomeScreen.activeStudentsCount,
            activeStudentsText: input.appWelcomeScreen.activeStudentsText,
            learningVideosCount: input.appWelcomeScreen.learningVideosCount,
            learningVideosText: input.appWelcomeScreen.learningVideosText,
            practicQuestionsCount: input.appWelcomeScreen.practicQuestionsCount,
            practicQuestionsText: input.appWelcomeScreen.practicQuestionsText,
            continueButtonTitle: input.appWelcomeScreen.continueButtonTitle,
        }
    } else {
        staticText.appWelcomeScreen = {
            videoUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
            welcomeText: 'Welcome to Coutingwell',
            largestMathAppText: 'India’s favourite Maths Learning App',
            studentComment: 'I am enjoying the app. Beside I am also learning. Now I am thinking if countingwell app was not there I would be bored.',
            studentDetail: 'Bidhyanshu Das, Class 7 Student from Kolkata',
            activeStudentsCount: '10000+',
            activeStudentsText: 'Active Students',
            learningVideosCount: '800+',
            learningVideosText: 'Learning Videos',
            practicQuestionsCount: '1000+',
            practicQuestionsText: 'Practice Questions',
            continueButtonTitle: 'CONTINUE'
        }
    }

    if (input && input.IncorrectSchoolDetails) {
        staticText.IncorrectSchoolDetails = {
            headerText: input.IncorrectSchoolDetails.headerText,
            schoolText: input.IncorrectSchoolDetails.schoolText,
            classText: input.IncorrectSchoolDetails.classText,
            sectionText: input.IncorrectSchoolDetails.sectionText,
            submitText: input.IncorrectSchoolDetails.submitText
        }
    } else {
        staticText.IncorrectSchoolDetails = {
            headerText: 'Let us know which details are incorrect',
            schoolText: 'SCHOOL',
            classText: 'CLASS',
            sectionText: 'SECTION',
            submitText: 'SUBMIT'
        }
    }

    if (input && input.ClassUpgraded) {
        staticText.ClassUpgraded = {
            classUpgradedText: input.ClassUpgraded.classUpgradedText,
            classUpgradedBySchoolText: input.ClassUpgraded.classUpgradedBySchoolText,
            okButtonTitle: input.ClassUpgraded.okButtonTitle,
        }
    } else {
        staticText.ClassUpgraded = {
            classUpgradedText: 'Your Class has been upgraded to <%upgradeClass%>th',
            classUpgradedBySchoolText: 'Your Class has been upgraded to <%upgradeClass%>th by your school teacher',
            okButtonTitle: 'OK'
        }
    }

    if (input && input.ClassUpgradeOption) {
        staticText.ClassUpgradeOption = {
            descriptionText: input.ClassUpgradeOption.descriptionText,
            upgradeText: input.ClassUpgradeOption.upgradeText,
            cancelButton: input.ClassUpgradeOption.cancelButton,
            yesButton: input.ClassUpgradeOption.yesButton,
        }
    } else {
        staticText.ClassUpgradeOption = {
            descriptionText: 'You are currently registered in Class <%currentClass%>th. With the new academic year starting, you can update your class to Class <%upgradeClass%>th to get all the learning content for the new year.',
            upgradeText: 'Upgrade to Class <%upgradeClass%>th',
            cancelButton: 'CANCEL',
            yesButton: 'YES'
        }
    }

    if (input && input.courses) {
        staticText.courses = {
            coursesTitle: input.courses.coursesTitle,
            newText: input.courses.newText,
            coursesSubTitle: input.courses.coursesSubTitle,
        }
    } else {
        staticText.courses = {
            coursesTitle: "Courses",
            newText: "New",
            coursesSubTitle: "Accquire new skills"
        }
    }


    if (input && input.coursesList) {
        staticText.coursesList = input.coursesList
    } else {
        staticText.coursesList = {
            title: 'Courses',
            noResultFoundText: "No Courses found",
            priceText: "Price",
            completionTimeText: "Estimated completion time",
            certificateOnCompletionText: "Cetificate on Completion",
            newCourses: "New & Ongoing",
            completedCourses: "Completed"
        }
    }

    if (input && input.defaultWorkoutStartScreen) {
        staticText.defaultWorkoutStartScreen = {
            title: input.defaultWorkoutStartScreen.title,
            text: input.defaultWorkoutStartScreen.text,
            buttonTitle: input.defaultWorkoutStartScreen.buttonTitle
        }
    } else {
        staticText.defaultWorkoutStartScreen = {
            title: "<%name%>, Lets do a short 10 mins Workout",
            text: 'And find out the gaps instantly',
            buttonTitle: "START"
        }
    }

    if (input && input.defaultWorkoutCompletedScreen) {
        staticText.defaultWorkoutCompletedScreen = {
            title: input.defaultWorkoutCompletedScreen.title,
            subTitle: input.defaultWorkoutCompletedScreen.subTitle,
            descriptionText: input.defaultWorkoutCompletedScreen.descriptionText,
            buttonTitle: input.defaultWorkoutCompletedScreen.buttonTitle
        }
    } else {
        staticText.defaultWorkoutCompletedScreen = {
            title: "Great!",
            subTitle: "Your Workout is completed",
            descriptionText: "Countingwell analyse every mistake that you do & creates a Personalised learning Plan for you",
            buttonTitle: "CONTINUE"
        }
    }

    if (input && input.defaultWorkoutReportScreen) {
        staticText.defaultWorkoutReportScreen = {
            title: input.defaultWorkoutReportScreen.title,
            descriptionText: input.defaultWorkoutReportScreen.descriptionText,
            buttonTitle: input.defaultWorkoutReportScreen.buttonTitle
        }
    } else {
        staticText.defaultWorkoutReportScreen = {
            title: "Workout Report",
            descriptionText: "<%name%>, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vitae massa eget ",
            buttonTitle: "CONTINUE"
        }
    }


    if (input && input.CourseDetails) {
        staticText.CourseDetails = input.CourseDetails
    } else {
        staticText.CourseDetails = {
            overwiewText: "Overview",
            learningOutcomesText: "Learning Outcomes",
            FAQText: "FAQ’S",
            myCertificateText: "MY CERTIFICATE",
            reviseText: "REVISE",
            buyCourseText: "BUY THE COURSE",
            tryCourseText: "TRY THE COURSE",
            startCourseText: "START THE COURSE",
            priceText: "Price",
            completionTimeText: "Estimated completion time",
            certificateOnCompletionText: "Cetificate on Completion",
            newCourses: "New & Ongoing",
            completedCourses: "Completed",
            skillYouMasterText: "SKILLS YOU'LL MASTER",
            moreText: 'more >'
        }
    }


    if (input && input.CourseSubscriptionFailure) {
        staticText.CourseSubscriptionFailure = {
            redirectUrl: input.CourseSubscriptionFailure.redirectUrl,
            paymentFailed: input.CourseSubscriptionFailure.paymentFailed,
            goToHomeText: input.CourseSubscriptionFailure.goToHomeText,
            infoText1: input.CourseSubscriptionFailure.infoText1,
            infoText2: input.CourseSubscriptionFailure.infoText2,
            website: input.CourseSubscriptionFailure.website,
        }
    } else {
        staticText.CourseSubscriptionFailure = {
            redirectUrl: "https://countingwell.com/premium_subscription.html",
            paymentFailed: `Payment Failed`,
            goToHomeText: 'GO TO HOME',
            infoText1: 'Login to ',
            infoText2: ' for help ',
            website: 'countingwell.com'

        }
    }

    if (input && input.CourseSubscriptionSuccess) {
        staticText.CourseSubscriptionSuccess = {
            successText: input.CourseSubscriptionSuccess.successText,
            buttonTitle: input.CourseSubscriptionSuccess.buttonTitle,
        }
    } else {
        staticText.CourseSubscriptionSuccess = {
            successText: 'Course Purchased Successfully',
            buttonTitle: 'START THE COURSE'

        }
    }

    if (input && input.CourseSubscriptionInfo) {
        staticText.CourseSubscriptionInfo = {
            PaidCoursesText: input.CourseSubscriptionInfo.PaidCoursesText,
        }
    } else {
        staticText.CourseSubscriptionInfo = {
            PaidCoursesText: 'You have paid for the following courses',
        }
    }


    if (input && input.media) {
        staticText.media = {
            attachImageText: input.media.attachImageText,
        }
    } else {
        staticText.media = {
            attachImageText: 'Upload Image of your working'
        }
    }

    if (input && input.httpClientStaticValues) {
        staticText.httpClientStaticValues = {
            stopExcludeUrl: input.httpClientStaticValues.stopExcludeUrl,
        }
    } else {
        staticText.httpClientStaticValues = {
            stopExcludeUrl: false
        }
    }

    if (input && input.eventsConatiner) {
        staticText.eventsConatiner = {
            title: input.eventsConatiner.title,
        }
    } else {
        staticText.eventsConatiner = {
            title: 'Events'
        }
    }


    if (input && input.mockTestEndScreen) {
        staticText.mockTestEndScreen = {
            marksScoredText: input.mockTestEndScreen.marksScoredText,
            timeTakenText: input.mockTestEndScreen.timeTakenText,
            correctText: input.mockTestEndScreen.correctText,
            inCorrectText: input.mockTestEndScreen.inCorrectText,
            notAnsweredText: input.mockTestEndScreen.notAnsweredText,
            minutesText: input.mockTestEndScreen.minutesText,
            viewAnswerButtonTitle: input.mockTestEndScreen.viewAnswerButtonTitle,
            viewReportButtonTitle: input.mockTestEndScreen.viewReportButtonTitle,
            assessmentCompletedText: input.mockTestEndScreen.assessmentCompletedText,
            continueButton: input.mockTestEndScreen.continueButton
        }
    } else {
        staticText.mockTestEndScreen = {
            marksScoredText: 'Marks Scored',
            timeTakenText: 'Time Taken',
            correctText: 'Correct',
            inCorrectText: 'Incorrect',
            notAnsweredText: 'Not Answered',
            minutesText: 'mins',
            viewAnswerButtonTitle: 'VIEW ANSWERS',
            viewReportButtonTitle: 'VIEW DETAIL REPORT',
            assessmentCompletedText: 'You’re done !!!',
            continueButton: "CONTINUE"
        }
    }

    if (input && input.eventListScreen) {
        staticText.eventListScreen = input.eventListScreen
    } else {
        staticText.eventListScreen = {
            title: 'Events',
            noResultFoundText : "No Events Found"
        }
    }

    return staticText;

}
import React from 'react';
import './new-concept-modal.scss';
import { Modal, ModalBody } from 'reactstrap';
import { ImageConstants } from '../../../constants/constants';
import { getThemeImageSource, getImageSource } from '../../../utility/image-source';
import SharedStaticText from '../../../../shared-storage/static-text-store';
import SharedProfile from '../../../../shared-storage/profile';
import CommonUtils from '../../../utility/common-utilities';


export default class NewConceptModal extends React.Component {

    staticText = {};

    constructor(props) {
        super(props);
        this.userInfo = SharedProfile.getProfile();
        // setCategoryThemeStyles(props.categoryThemeId)

        this.staticText = SharedStaticText.getStaticText('newConceptModal');

    }
    state = {

    }

    close = () => {
        this.props.close && this.props.close()
    }

    render() {
        return (
            <Modal className="cw-common-custom-modal-center-for-concept-popup" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                    {this.props.showTrialFeatures ? <img className='badge-image' src={getImageSource(ImageConstants.BADGE_IMAGE)} /> : ''}
                    {/* <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} /> */}
                </div>
                <ModalBody>
                    <div className="pratice-container">
                        <div className='header-text'>
                            Hey <strong>{this.userInfo.name}</strong>, {this.staticText.text}
                        </div>
                        <div className='name-header text-center'>{this.props.conceptName ? CommonUtils.toCamelCase(this.props.conceptName) : ''}</div>
                        <div className="d-flex justify-content-center"><img className='desktop-icon-size' src={getImageSource(ImageConstants.MALE_WITH_DESKTOP_ICON)} /></div>
                        {/* <div className='name-header mt-2 mb-4'>You can skip this in case you do not have a phone right now</div> */}
                        <div className="d-flex align-items-center justify-content-around mt-2">
                            {/* <div className="continue-button cursor-pointer text-center" onClick={() => this.onClickClose()}>
                                        {`OK`}
                                    </div> */}
                            <div className="take-challenge cursor-pointer  text-center" onClick={this.close}>
                                {this.staticText.continueButtonTitle}
                            </div>
                        </div>
                        <div className="position">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}



import React from 'react';
import './learning-header.scss';
import { getImageSource } from '../../../utility/image-source';
import Parser from "../../common/content-parser/content-parser";
import { ImageConstants } from '../../../constants/constants';
import ReactTooltip from "react-tooltip";

export default class LearningHeader extends React.Component {

    constructor(props) {
        super(props)
        this.parser = new Parser();
    }

    state = {
        showModal: false
    }

    showIcons = () => {
        this.props.delegate && this.props.delegate.showIcons();
    }

    onClickBookMarkActive = () => {
        this.props.delegate && this.props.delegate.onClickBookMarkActive && this.props.delegate.onClickBookMarkActive(this.props.bookMarkId)
    }

    onClickBookMark = () => {
        this.props.delegate && this.props.delegate.onClickBookMark && this.props.delegate.onClickBookMark()
    }


    render() {
        //console.log(this.props.data.headerIcon)
        return (
            <>
                <div className='learning-header mb-4 mt-2 d-flex justify-content-between align-items-center' onMouseEnter={ReactTooltip.rebuild}>

                    {/* {!this.props.removeHeaderIcon ? <div>
                        <img className='image-width' src={getImageSource(this.props.data.headerIcon)} />
                    </div> : <></>} */}

                    {!this.props.isCommonError ?
                        <div className='d-flex align-items-center'>
                            {!this.props.removeHeaderIcon ?
                                <img className='image-width' src={getImageSource(this.props.data.headerIcon)} />
                                : <></>}
                            {this.props.headerWithStyle ?
                                this.parser.parseLearningContent(this.props.data.title, this.props.delegate, this.props.glossary, null, null, this.props.categoryId) :
                                <span className='title ml-3'>{this.props.data.title}</span>}
                        </div>
                        :
                        <div >
                            {this.props.headerWithStyle ? this.parser.parseWithStyles(this.props.data.title, { p: { color: "#000000", fontWeight: "700", fontSize : '24px' } }, this.props.glossary, this.props.categoryId) :
                                <span className='title ml-3'>{this.props.data.title}</span>}
                        </div>}

                    {this.props.showBookMark && <div>
                        <div className='cursor-pointer' onClick={this.onClickBookMarkActive} data-tip={'BREAK UP'} >
                            <img className='bookmark-image-size mr-4' src={getImageSource(ImageConstants.BOOKMARK_ACTIVE)} />
                        </div>
                    </div>}

                    {this.props.showInactiveBookmark && <div>
                        <div className='cursor-pointer' onClick={this.onClickBookMark} data-tip={'BOOKMARK'}  >
                            <img className='bookmark-image-size mr-4' src={getImageSource(ImageConstants.BOOKMARK_ICON)} />
                        </div>
                    </div>}




                    {/* <View style={Styles.learningCardHeaderIcons}>
                        <TouchableOpacity onPress={this.showIcons}>
                            <Image style={Styles.icons} source={getImageSource(ImageConstants.LEARNING_CONTENT_MENU)} />
                        </TouchableOpacity>
                    </View> */}
                <ReactTooltip ref={el => this.tooltip = el} multiline={true} place='bottom' type='dark' effect='solid' event="mouseover mouseenter" eventOff="mouseleave mouseout scroll mousewheel blur" />
                </div>
                {/* {this.props.meta && <View style={[Styles.cardHeader, Styles.marginBottomHeader]}>
                    <View style={Styles.contentTypeIcon}></View>
                    <View style={Styles.contentType}>
                    {this.props.meta.chapterName ?<Text style={Styles.subText}>{this.props.meta.chapterName}</Text>:<></>}
                        <Text style={Styles.subText}>{this.props.meta.eloName}</Text>
                    </View>
                </View>
                } */}


                
            </>
        );
    }
}

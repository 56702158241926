import React from "react";
import './multi-select.scss';
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import QuestionImage from "../../cw-question-image/cw-question-image";
import QuestionTitle from "../question-title/question-title";
import CommonUtils from "../../../../utility/common-utilities";
import { Constants, ImageConstants } from "../../../../constants/constants";
import { getThemeImageSource, getImageSource } from "../../../../utility/image-source";
import AssessmentFooter from "../../assessment-footer/assessment-footer";
import Checkbox from "../../check-box/check-box";
import SummarySoultions from "../../summary-solution/summary-solution";
import DotPagination from "../../dot-pagination/dot-pagination";
import AnswerTab from "../../../my-assessment-result/question/answer-tab/answer-tab";
import ImagePreview from "../../image-preview/image-preview";
import ReactTooltip from "react-tooltip";

class MultiSelect extends React.Component {

    staticText = {};
    constructor(props) {
        super(props);

        this.staticText = SharedStaticText.getStaticText('multiSelectQuestion');
        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');
        this.originalContent = JSON.parse(JSON.stringify((this.props.content)));

        if (this.props.isReadOnly) {
            let content = JSON.stringify(this.props.content)
            this.state.content = JSON.parse(content);

        }
    }

    state = { imagePreview: false, previewUrl: '', scrollViewHeight: 0, content: null, showSolution: true, showOptions: false };
    answerTickCount = 0
    isValid = false;
    originalContent = {};

    componentDidMount() {
        if (this.props.isReadOnly) {
            this.setState({ status: `${this.props.userAnswerStatus ? "CORRECT" : "IN_CORRECT"}` })
        }

        if (!this.props.isReadOnly) {
            this.setState({ content: this.getSuffledItem(this.props.content) });
            this.startTimerforAnswer()
        }
    }

    startTimerforAnswer() {
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }
        this.timerIntervalId = setInterval(() => {

            this.answerTickCount += 1
        }, 1000)
    }

    getSuffledItem(content) {
        CommonUtils.shuffleArray(content.content.options);
        return content;
    }

    reset() {
        this.setState({ content: this.getSuffledItem(JSON.parse(JSON.stringify(this.originalContent))) });
    }

    onOptionPressed(option) {
        //console.log(option)

        //Added for View Correct Answer
        if (this.props.isReadOnly) {
            return;
        }

        option.isChecked = !option.isChecked;
        this.forceUpdate();

        let options = this.state.content.content.options.filter(opt => opt.isChecked);
        // this.props.delegate && this.props.delegate.onAnswer(this.props.content._id, options, this.props.content.content.type);
        if (options.length) {
            this.isValid = true;
            this.props.delegate && this.props.delegate.enableNavigation && this.props.delegate.enableNavigation();
        } else {
            this.isValid = false;
            this.props.delegate && this.props.delegate.disableNavigation && this.props.delegate.disableNavigation();
        }

    }

    getAnswer = () => {

        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId)
        }

        if (this.isValid) {
            let payload = [];
            let options = this.state.content.content.options;

            for (let i = 0; i < options.length; i++) {
                if (options[i].isChecked)
                    payload.push({ id: options[i].id })
            }
            return { payload, timeTakenToAnswer: this.answerTickCount };
        } else
            return null;
    }

    updateAnswer = (payload) => {

        let answeredIds = {};
        for (let i = 0; i < payload.answer.length; i++) {
            answeredIds[payload.answer[i].id] = payload.answer[i];
        }

        let options = this.props.content.content.options;

        for (let i = 0; i < options.length; i++) {
            if (answeredIds[options[i].id]) {
                options[i].isChecked = true;
            }
        }
        this.isValid = true;
        this.forceUpdate();
    }

    openPreview = (previewUrl) => {
        this.setState({ imagePreview: true, previewUrl: previewUrl })
    }

    closePreview = () => {
        this.setState({ imagePreview: false });
    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
    }


    getCheckedStatus = (option) => {
        if (this.props.isReadOnly) {
            return option.isCorrect ? true : false
        }
        else {
            return option.isChecked ? true : false
        }
    }

    onSelectImage = (type) => {
        this.props.delegate && this.props.delegate.showMedia(type)
    }

    renderAttachments = (item, index) => {

        if (item.type == 'IMAGE') {

            return (
                // <View>
                //     <TouchableOpacity style={styles.attachments} onPress={() => this.openPreview(item.url, 'IMAGE')}>
                //         <Image source={{ uri: item.url }} style={styles.imageAttachment} resizeMode="cover" />

                //     </TouchableOpacity>
                //     <TouchableOpacity onPress={() => this.removeAttachment(item)} style={styles.deleteIcon} hitSlop={styles.imageHitSlop}><Image source={getImageSource(ImageConstants.DELETE_ATTACHMENT)} /></TouchableOpacity>
                // </View>
                <></>

            )
        }
    }

    openPreview = (url, type) => {
        this.setState({ previewUrl: url, imagePreview: true, previewType: type })
    }

    removeAttachment = (item) => {

        this.props.delegate && this.props.delegate.removeAttachment(item)

    }

    updateAnswerForUserAnswer = (payload) => {

        let answeredIds = {};
        for (let i = 0; i < payload.answer.length; i++) {
            answeredIds[payload.answer[i].id] = payload.answer[i];
        }

        let content = this.state.content;

        let options = this.state.content.content.options;

        for (let i = 0; i < options.length; i++) {
            if (answeredIds[options[i].id]) {
                options[i].isCorrect = true;
            } else {
                options[i].isCorrect = false;
            }
        }

        this.setState({ content: content })

    }


    showMyAnswer = () => {
        if (this.props.userAnswer) {
            this.updateAnswerForUserAnswer(this.props.userAnswer)
        }
    }

    showCorrectAnswer = () => {
        let content = JSON.parse(JSON.stringify(this.props.content))
        //console.log(content)
        this.setState({ content: content }, () => {

        })
    }


    toggleViewAnswer = () => {
        this.setState({ showCorrectAnswer: !this.state.showCorrectAnswer, showSolution: !this.state.showSolution })
    }

    toggleViewSolution = () => {
        if (this.state.status === "CORRECT") {
            return;
        }
        this.setState({ showSolution: !this.state.showSolution, showCorrectAnswer: !this.state.showCorrectAnswer })
    }


    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions })
    }



    render() {

        // setCategoryThemeStyles(this.props.categoryThemeId);

        let content = this.state.content;

        // if (this.state.showMedia)
        // return <View style={{ flex: 1 }}><Media delegate={this} type={this.state.selectedMediaType} /></View>

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        return (

            content ? <div className="multi-select" onMouseEnter={ReactTooltip.rebuild}>
                <div className={`${"min-height"}`}>

                    {
                        this.props.isReadOnly && !this.props.isMultiStep ?
                            <>
                                <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                                    {
                                        this.props.userAnswerStatus ?
                                            <div className="correct-answer-bg d-flex">
                                                <div className="">
                                                    <img className="image-size" src={getImageSource(ImageConstants.WHITE_TICK_ICON_NEW)} />
                                                </div>
                                                <div className="ml-2">
                                                    <div className="name-header-correct">{"Correct"}</div>
                                                    {/* <div className="sub-title">{this.correctAnswerStaticText.subTitle}</div> */}
                                                </div>
                                            </div>

                                            :
                                            <div className="incorrect-answer-bg d-flex">
                                                <div className="">
                                                    <img className="image-size" src={getImageSource(ImageConstants.WHITE_CROSS_ICON)} />
                                                </div>
                                                <div className="ml-1">
                                                    <div className="name-header text-center">{"Incorrect"}</div>
                                                    {/* <div className="sub-title">{this.inCorrectAnswerStaticText.subTitle}</div> */}
                                                </div>
                                            </div>

                                    }

                                </div>
                                {/* <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                    <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                        <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                    </div>
                                </div> */}
                            </>
                            :
                            <></>
                    }


                    {this.props.totalSteps && this.props.quesNo && <DotPagination noOfDots={this.props.totalSteps} activeDot={this.props.quesNo} categoryThemeId={this.props.categoryThemeId} />}
                    {
                        !this.props.isCheckPoint && this.props.isMultiStep && !this.props.isReadOnly ?
                            <>
                                <QuestionTitle title={content.content.title}
                                    queNo={this.props.quesNo ? this.props.quesNo : null}
                                    noOfDots={this.props.totalSteps}
                                    activeDot={this.props.quesNo}
                                    hideBackground={this.props.isMultiStep ? true : false}
                                    categoryThemeId={this.props.categoryThemeId}
                                    isAssessment={this.props.isAssessment}
                                    delegate={this.props.delegate}
                                    isReadOnly={this.props.isReadOnly}
                                    isSchoolAssignment={true} />
                                <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                            </>

                            :
                            !this.props.isMultiStep && !this.props.isCheckPoint ?
                                <>
                                    <QuestionTitle title={content.content.title}
                                        queNo={this.props.quesNo ? this.props.quesNo : null}
                                        noOfDots={this.props.totalSteps}
                                        activeDot={this.props.quesNo}
                                        hideBackground={this.props.isMultiStep ? true : false}
                                        categoryThemeId={this.props.categoryThemeId}
                                        isAssessment={this.props.isAssessment}
                                        delegate={this.props.delegate}
                                        isReadOnly={this.props.isReadOnly}
                                        isSchoolAssignment={true}
                                        showOptions={this.state.showOptions}
                                        toggleOptions={() => this.toggleOptions()} />
                                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />
                                    {
                                        this.props.isReadOnly && this.state.showOptions ?
                                            <div className={`option-wrapper d-flex flex-column mt-3 option-margin-bottom`}>
                                                {content.content.options && content.content.options.map((option, index) => (
                                                    <div className={`option-border ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "d-flex justify-content-between" : this.props.isReadOnly ? "d-flex align-items-center" : ""}`}
                                                        style={{ borderColor: this.theme ? `${this.theme['secondaryColors']['assessmentOptionsBorder']}` : '#353535' }}
                                                        key={option.id}>
                                                        <Checkbox label={option.content}
                                                            // isChecked={this.getCheckedStatus(option)}
                                                            // onOptionPressed={() => this.onOptionPressed(option)}
                                                            contentType={option.contentType ? option.contentType : 1}
                                                            isCheckPoint={this.props.isCheckPoint}
                                                            categoryThemeId={this.props.categoryThemeId}
                                                        />

                                                        {option.contentType === Constants.CONTENT_TYPE_IMAGE &&
                                                            <div className="ml-5">
                                                                <img className="expand-icon cursor-pointer" onClick={() => this.openPreview(option.content)} src={getImageSource(ImageConstants.EXPAND)} />
                                                            </div>
                                                        }

                                                    </div>
                                                ))}
                                                {this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>}
                                            </div>
                                            :
                                            <></>
                                    }
                                </>

                                :
                                <></>
                    }


                    <div className="d-flex align-items-center mt-4">
                        <img className="bulb-image-size" src={getImageSource(ImageConstants.BULB_IMAGE)} />
                        <div className="instruction-text ml-3">
                            {/* {constants.MULTI_SELECT_INSTRUCTION_TEXT} */}
                            {this.staticText.instruction}
                        </div>
                    </div>

                    {!this.props.isCheckPoint && <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                        </div>
                        {
                            (this.props.isFromMyAssessment && !this.props.isMultiStep) ?
                                <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                    style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${content.content.mark} Marks`}>
                                    <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                    <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                </div> : <></>
                        }
                    </div>
                    }

                    {
                        !this.props.isReadOnly ?
                            <div className={`option-wrapper d-flex flex-column mt-3 option-margin-bottom`}>
                                {content.content.options && content.content.options.map((option, index) => (
                                    <div className={`option-border ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "d-flex justify-content-between" : this.props.isReadOnly ? "d-flex align-items-center" : ""}`}
                                        style={{ borderColor: this.theme ? `${!this.props.isReadOnly ? this.theme['secondaryColors']['assessmentOptionsBorder'] : "#FFFFFF"}` : '#353535' }}
                                        key={option.id}>
                                        <Checkbox label={option.content} isChecked={this.getCheckedStatus(option)}
                                            onOptionPressed={() => this.onOptionPressed(option)}
                                            contentType={option.contentType ? option.contentType : 1}
                                            isCheckPoint={this.props.isCheckPoint}
                                            categoryThemeId={this.props.categoryThemeId}
                                        />

                                        {option.contentType === Constants.CONTENT_TYPE_IMAGE &&
                                            <div className="ml-5">
                                                <img className="expand-icon cursor-pointer" onClick={() => this.openPreview(option.content)} src={getImageSource(ImageConstants.EXPAND)} />
                                            </div>
                                        }

                                    </div>
                                ))}
                                {this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>}
                            </div>
                            :
                            <></>
                    }

                    {
                        this.props.isReadOnly && !this.props.isMultiStep ?
                            <>
                                <div className="d-flex align-items-center mt-3 ml-3">
                                    <div className="d-flex">
                                        <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                            style={{
                                                backgroundColor: `${this.state.showSolution ? "#E1D9FF" : "#E6E8E8"}`,
                                                color: `${this.state.showSolution ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                            }} onClick={this.toggleViewSolution}>
                                            {
                                                this.state.showSolution ? 'Solution' : 'Solution'
                                            }
                                            {/* <span>View Answer</span> */}
                                            {/* <img className="arrow-size" src={getThemeImageSource(this.state.showSolution ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                        </div>
                                    </div>
                                    {
                                        this.props.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                            <div className="d-flex ml-4">
                                                <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                                    style={{
                                                        backgroundColor: `${this.state.showCorrectAnswer ? "#E1D9FF" : "#E6E8E8"}`,
                                                        color: `${this.state.showCorrectAnswer ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                                    }} onClick={this.toggleViewAnswer}>
                                                    {
                                                        this.state.showCorrectAnswer ? 'Answer' : 'Answer'
                                                    }
                                                    {/* <span>View Answer</span> */}
                                                    {/* <img className="arrow-size" src={getThemeImageSource(this.state.showCorrectAnswer ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                                <div className="solution-answer-border mb-4"></div>


                                {
                                    this.state.showSolution ?
                                        <SummarySoultions forQuestion={true} solutionSteps={!this.props.isMultiStep ? this.props.content.content.solutionSteps : this.props.mulipStepQuestion.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions>
                                        : <></>
                                }
                                {
                                    this.state.showCorrectAnswer ?
                                        <>
                                            {
                                                this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                    <AnswerTab onCorrectAnswer={this.showCorrectAnswer} onMyAnswer={this.showMyAnswer} status={this.state.status} categoryThemeId={this.props.categoryThemeId} answer={this.props.userAnswer}></AnswerTab>
                                                    : <></>
                                            }
                                            {
                                                this.state.status != "NOT_ATTEMPTED" && this.state.status != "CORRECT" ?
                                                    <div className="option-wrapper d-flex flex-column mt-3 justify-content-between option-margin-bottom">
                                                        {content.content.options && content.content.options.map((option, index) => (
                                                            <div className={`option-border ${option.contentType === Constants.CONTENT_TYPE_IMAGE && !this.props.isReadOnly ? "d-flex justify-content-between" : this.props.isReadOnly ? "d-flex align-items-center" : ""}`}
                                                                style={{ borderColor: this.theme ? `${!this.props.isReadOnly ? this.theme['secondaryColors']['assessmentOptionsBorder'] : "#FFFFFF"}` : '#353535' }}
                                                                key={option.id}>
                                                                <Checkbox label={option.content} isChecked={this.getCheckedStatus(option)}
                                                                    onOptionPressed={() => this.onOptionPressed(option)}
                                                                    contentType={option.contentType ? option.contentType : 1}
                                                                    isCheckPoint={this.props.isCheckPoint}
                                                                    categoryThemeId={this.props.categoryThemeId}
                                                                />

                                                                {option.contentType === Constants.CONTENT_TYPE_IMAGE &&
                                                                    <div className="ml-5">
                                                                        <img className="expand-icon cursor-pointer" onClick={() => this.openPreview(option.content)} src={getImageSource(ImageConstants.EXPAND)} />
                                                                    </div>
                                                                }

                                                            </div>
                                                        ))}
                                                        {this.state.imagePreview && <ImagePreview modal={this.state.imagePreview} source={this.state.previewUrl} closePreview={this.closePreview} categoryThemeId={this.props.categoryThemeId}></ImagePreview>}
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </>

                                        : <></>
                                }


                            </>
                            :
                            <></>
                    }


                    {/* {this.props.isReadOnly && !this.props.isMultiStep ? <div className="question-separator mt-3 mb-3 d-flex flex-row justify-content-center align-items-center">
                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                        </div> </div> : <></>} */}





                </div>



                {!this.props.isCheckPoint && !this.props.isMultiStep && <AssessmentFooter userAnswerStatus={this.props.userAnswerStatus} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} isReadOnly={this.props.isReadOnly} delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}
                <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                    multiline={true}
                    place='right'
                    type='light' effect='solid'
                    event="mouseover mouseenter"
                    eventOff="mouseleave mouseout scroll mousewheel blur" />

            </div> : <></>

        );
    }
}



export default MultiSelect;

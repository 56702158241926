import React from "react";
import './clicker-testing.scss';
import AssessmentApi from "../../apis/assessment";
import { getImageSource, getThemeImageSource } from "../../utility/image-source";
import { ImageConstants } from "../../constants/constants";
import Path from "../routes/routes-path";
import Loading from "../common/loading/loading";


class ClickerTesting extends React.Component {

    state = {
        // studentList: [
        //     {
        //         teacherUserId: '659b90fa21cb4f53081b46a9',
        //         studentNumber: '100',
        //         userDeviceMacId: 'bf:fc:e2:0e:93:e7',
        //         receivedAnswer: false,
        //         isTeacher: true,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a7',
        //         studentNumber: '21',
        //         userDeviceMacId: '58:2f:be:86:1c:ee',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a8',
        //         studentNumber: '22',
        //         userDeviceMacId: 'ef:3f:4d:11:ef:c7',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46aa',
        //         studentNumber: '3',
        //         userDeviceMacId: '26:05:e8:5b:ed:d1',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ab',
        //         studentNumber: '4',
        //         userDeviceMacId: '98:83:8f:a6:9c:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ac',
        //         studentNumber: '5',
        //         userDeviceMacId: 'b5:de:e9:93:c5:c2',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ad',
        //         studentNumber: '6',
        //         userDeviceMacId: 'c9:ee:61:02:61:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ae',
        //         studentNumber: '7',
        //         userDeviceMacId: '28:56:46:47:b8:f4',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46af',
        //         studentNumber: '8',
        //         userDeviceMacId: '98:95:c3:0d:4b:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b0',
        //         studentNumber: '9',
        //         userDeviceMacId: '39:76:8d:c4:b5:e3',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b1',
        //         studentNumber: '10',
        //         userDeviceMacId: 'ba:97:f2:01:91:ce',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b2',
        //         studentNumber: '11',
        //         userDeviceMacId: '05:d0:84:1f:a9:c9',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b3',
        //         studentNumber: '12',
        //         userDeviceMacId: 'bd:53:3b:30:15:ed',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b4',
        //         studentNumber: '13',
        //         userDeviceMacId: '7a:89:0f:87:42:eb',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b5',
        //         studentNumber: '14',
        //         userDeviceMacId: '72:87:f4:33:b9:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b6',
        //         studentNumber: '15',
        //         userDeviceMacId: '09:65:11:75:bd:de',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b7',
        //         studentNumber: '16',
        //         userDeviceMacId: '93:86:69:41:2f:fd',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b8',
        //         studentNumber: '17',
        //         userDeviceMacId: 'ca:b1:a7:e1:6c:dc',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b9',
        //         studentNumber: '18',
        //         userDeviceMacId: '17:0d:52:8c:8a:cb',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ba',
        //         studentNumber: '19',
        //         userDeviceMacId: '122',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46bb',
        //         studentNumber: '20',
        //         userDeviceMacId: '123',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a7',
        //         studentNumber: '1',
        //         userDeviceMacId: '7c:0c:d3:ef:9b:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a8',
        //         studentNumber: '2',
        //         userDeviceMacId: '65:05:df:31:dd:fe',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46aa',
        //         studentNumber: '3',
        //         userDeviceMacId: '26:05:e8:5b:ed:d1',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ab',
        //         studentNumber: '4',
        //         userDeviceMacId: '98:83:8f:a6:9c:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ac',
        //         studentNumber: '5',
        //         userDeviceMacId: 'b5:de:e9:93:c5:c2',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ad',
        //         studentNumber: '6',
        //         userDeviceMacId: 'c9:ee:61:02:61:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ae',
        //         studentNumber: '7',
        //         userDeviceMacId: '28:56:46:47:b8:f4',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46af',
        //         studentNumber: '8',
        //         userDeviceMacId: '98:95:c3:0d:4b:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b0',
        //         studentNumber: '9',
        //         userDeviceMacId: '39:76:8d:c4:b5:e3',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b1',
        //         studentNumber: '10',
        //         userDeviceMacId: 'ba:97:f2:01:91:ce',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b2',
        //         studentNumber: '11',
        //         userDeviceMacId: '05:d0:84:1f:a9:c9',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b3',
        //         studentNumber: '12',
        //         userDeviceMacId: 'bd:53:3b:30:15:ed',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b4',
        //         studentNumber: '13',
        //         userDeviceMacId: '7a:89:0f:87:42:eb',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b5',
        //         studentNumber: '14',
        //         userDeviceMacId: '72:87:f4:33:b9:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b6',
        //         studentNumber: '15',
        //         userDeviceMacId: '09:65:11:75:bd:de',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b7',
        //         studentNumber: '16',
        //         userDeviceMacId: '93:86:69:41:2f:fd',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b8',
        //         studentNumber: '17',
        //         userDeviceMacId: 'ca:b1:a7:e1:6c:dc',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b9',
        //         studentNumber: '18',
        //         userDeviceMacId: '17:0d:52:8c:8a:cb',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ba',
        //         studentNumber: '19',
        //         userDeviceMacId: '122',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46bb',
        //         studentNumber: '20',
        //         userDeviceMacId: '123',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a7',
        //         studentNumber: '1',
        //         userDeviceMacId: '7c:0c:d3:ef:9b:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46a8',
        //         studentNumber: '2',
        //         userDeviceMacId: '65:05:df:31:dd:fe',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46aa',
        //         studentNumber: '3',
        //         userDeviceMacId: '26:05:e8:5b:ed:d1',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ab',
        //         studentNumber: '4',
        //         userDeviceMacId: '98:83:8f:a6:9c:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ac',
        //         studentNumber: '5',
        //         userDeviceMacId: 'b5:de:e9:93:c5:c2',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ad',
        //         studentNumber: '6',
        //         userDeviceMacId: 'c9:ee:61:02:61:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ae',
        //         studentNumber: '7',
        //         userDeviceMacId: '28:56:46:47:b8:f4',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46af',
        //         studentNumber: '8',
        //         userDeviceMacId: '98:95:c3:0d:4b:fa',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b0',
        //         studentNumber: '9',
        //         userDeviceMacId: '39:76:8d:c4:b5:e3',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b1',
        //         studentNumber: '10',
        //         userDeviceMacId: 'ba:97:f2:01:91:ce',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b2',
        //         studentNumber: '11',
        //         userDeviceMacId: '05:d0:84:1f:a9:c9',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b3',
        //         studentNumber: '12',
        //         userDeviceMacId: 'bd:53:3b:30:15:ed',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b4',
        //         studentNumber: '13',
        //         userDeviceMacId: '7a:89:0f:87:42:eb',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b5',
        //         studentNumber: '14',
        //         userDeviceMacId: '72:87:f4:33:b9:d8',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b6',
        //         studentNumber: '15',
        //         userDeviceMacId: '09:65:11:75:bd:de',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b7',
        //         studentNumber: '16',
        //         userDeviceMacId: '93:86:69:41:2f:fd',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b8',
        //         studentNumber: '17',
        //         userDeviceMacId: 'ca:b1:a7:e1:6c:dc',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46b9',
        //         studentNumber: '18',
        //         userDeviceMacId: '17:0d:52:8c:8a:cb',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46ba',
        //         studentNumber: '19',
        //         userDeviceMacId: '122',
        //         receivedAnswer: false,
        //     },
        //     {
        //         userId: '659b90fa21cb4f53081b46bb',
        //         studentNumber: '20',
        //         userDeviceMacId: '123',
        //         receivedAnswer: false,
        //     }
        // ],
        isListening: false,
        isLoading: false
    }


    componentDidMount() {
        this.getClickerList()

    }

    getClickerList() {

        let payload = {
            limit : 50,
            skip : 0
        }

        this.setState({ isLoading: true })

        AssessmentApi.getClickerList(payload).then(res => {

            // console.log(res)

            this.setState({ studentList: res.clickerUserList }, () => {
                this.setState({ isLoading: false })
                this.handleStartListening()
            })

        }).catch(err => {
            this.setState({ isLoading: false })
            console.log(err)
        })

    }


    handleStartListening = () => {
        const { isListening } = this.state;

        if (window.electron && window.electron.remoteControl) {
            if (isListening) {
                this.setState({ isListening: false });
                window.electron.remoteControl.unsubscribeEvents();
                window.electron.remoteControl.close();
            } else {
                this.setState({ isListening: true });
                console.log('Opening remote control', window.electron.remoteControl.open);
                window.electron.remoteControl.open();

                window.electron.remoteControl.subscribeEvents((data) => {
                    console.log('Received data:', data);
                    // console.log(this.state.stopReceivingStudentSignal)

                    let studentList = this.state.studentList

                    let index = studentList.findIndex(item => item.userDeviceMacId === data.payload.id)

                    if (index != -1) {
                        if (studentList[index].isTeacher && data.payload.value === 8) {
                            // this.toggleLayoutBar()
                            // window.electron.ipcRenderer.send('toggle-fullscreen', ['ping']);
                        }

                        // if (studentList[index].isTeacher && data.payload.value === 1) {
                        //     this.next()
                        // }

                        // if (studentList[index].isTeacher && data.payload.value === 2) {
                        //     this.toggleTimer()
                        // }

                        // if (studentList[index].isTeacher && data.payload.value === 6) {
                        //     this.setState({ showStudentResponse: !this.state.showStudentResponse })
                        // }


                        studentList[index].receivedAnswer = true
                        // studentList[index].answerId = option.id
                        // this.userIdAnswerHash[studentList[index].userId] = { answer: { answer: [{ id: option.id }], id: this.state.content._id } }


                    }

                    console.log(this.state.studentList)

                });
            }
            window.electron.ipcRenderer.once('ipc-example', (arg) => {
                // eslint-disable-next-line no-console
                console.log(arg);
            });
            window.electron.ipcRenderer.send('ipc-example', ['ping']);
        }

    };

    goToHomeScreen() {

        this.props.history.replace({ pathname: Path.CLICKER_FLOW_QUIZ })
    }


    renderBreadcrumb() {
        return (

            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 cursor-pointer title-1' onClick={() => this.goToHomeScreen()}>{`Clicker Testing - FFF`}</span>

                {/* <span className='pl-2 title-1'>{`Start Clicker Flow`}</span> */}
            </div>

        )
    }





    render() {
        return (
            <div className="clicker-testing-student-answered-status-board">
                <div className="text-right">
                    <img className="image-size" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, 1)}></img>
                </div>
                <div className="header-for-practice-question">
                    {this.renderBreadcrumb()}
                    {/* <div>
                        <img className="chapter-icon mr-1" src={this.state.selectedChapter.chapterImage ? this.state.selectedChapter.chapterImage : getImageSource(ImageConstants.CHAPTER_ICN)} />
                        <span className="chapter-title">{this.state.selectedChapter.title}</span>
                    </div> */}
                    <div className="sub-header-text">{"Clicker Testing - FFF"}</div>
                </div>
                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, 1)}></img>
                </div>
                <div className="d-flex align-items-center button-wrapper mt-4">
                    {
                        this.state.studentList && this.state.studentList.length ?
                            this.state.studentList.map(student => (
                                student.isTeacher ? <></> :
                                    <div className={`student-roll-number-button ml-4 mb-4 ${student.receivedAnswer ? 'enable-button' : ''}`}>{student.studentNumber}</div>
                            ))
                            :
                            <>No Students Found</>
                    }
                </div>
                {
                    this.state.isLoading ? <Loading /> : <></>
                }
            </div>
        )
    }

}

export default ClickerTesting;
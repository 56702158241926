import React from "react";
import './grouping.scss';
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import CommonUtils from "../../../../utility/common-utilities";
import CWImageV2 from "../../../common/cw-image-2/cw-image-2";
// import QuestionTitle from "../question-title/question-title";
import QuestionImage from "../../../common/cw-question-image/cw-question-image";
import { getImageSource, getThemeImageSource } from "../../../../utility/image-source";
import { ImageConstants } from "../../../../constants/constants";
// import AssessmentFooter from "../../assessment-footer/assessment-footer";
import { Constants } from "../../../../constants/constants";
import Parser from "../../../common/content-parser/content-parser";
import SummarySoultions from "../../../common/summary-solution/summary-solution";
import DotPagination from "../../../common/dot-pagination/dot-pagination";


class Grouping extends React.Component {

    constructor(props) {
        super(props);
        this.parser = new Parser();
        this.dragItems = [];
        this.options = this.props.content.content.options;
        this.mapDragItems();

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId);
        this.staticText = SharedStaticText.getStaticText('multiStepQuestion');

    }

    state = {

        droppedItemsIndex: [],
        droppedZoneItems: [],
        draggedIndex: -1,
        dropIndex: -1,
        dragabbleItemsScroll: true,
        selectedItem: null,
        pan: { x: 0, y: 0 },
        scrollViewHeight: 0,
        activateResponder: false,
        stickyHeaderIndex: -1,
        dropZonePosition: [],
        dragOverIndex: -1,
        sourceX: null,
        sourceY: null,
        sourcePreviewVisible: false,
    }

    scrollViewRef;
    scrollViewMeasure = {};

    //pan = { x: 0, y: 0 }; // to hold array pan gesture items to be draged
    dropZone = []; // To hold droppable Zones on load
    dropZoneMeasure = []; //Presist droppable Zones on reaload
    panPosition = [] // store scroll view items position

    dropZoneNew = [];
    dropZoneMeasureNew = [];
    dropZoneHeights = [];

    dragItemScrollTouchXY = null
    dx = 0;
    dy = 0;

    dragOverInfo = {index : -1}

    isValid = false;

    componentDidMount() {

    }

    mapDragItems() {

        let dragItem = [];

        let options = this.props.content.content.options;

        for (let i = 0; i < options.length; i++) {
            dragItem = dragItem.concat(options[i].items);
        }
        //this.pan = dragItem.map(() => new Animated.ValueXY());
        this.dragItems = CommonUtils.shuffleArray(dragItem);
    };




    setSelectedAnswer(draggedIndex, dropIndex) {

        let droppedZoneItems = [...this.state.droppedZoneItems];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        if (droppedZoneItems[dropIndex] && droppedZoneItems[dropIndex].items)
            droppedZoneItems[dropIndex].items.push(this.dragItems[draggedIndex]);
        else
            droppedZoneItems[dropIndex] = { items: [this.dragItems[draggedIndex]] };


        if (this.dragItems[draggedIndex]) {
            droppedItemsIndex.push(this.dragItems[draggedIndex].id);
        }

        this.setState({
            droppedZoneItems: droppedZoneItems,
            droppedItemsIndex: droppedItemsIndex,
            dragabbleItemsScroll: true,
        })
        this.validateAndDelegate(droppedZoneItems);
        this.dragInfo = {}
        this.dragOverInfo = {index : -1}
    }

    validateAndDelegate(droppedZoneItems) {

        let total = 0;

        for (let i = 0; i < droppedZoneItems.length; i++) {
            if (droppedZoneItems[i])
                total = total + droppedZoneItems[i].items.length;
        }

        if (this.dragItems.length == total) {
            this.isValid = true;
            this.props.delegate && this.props.delegate.enableNavigation && this.props.delegate.enableNavigation();
        } else {
            this.isValid = false;
            this.props.delegate && this.props.delegate.disableNavigation && this.props.delegate.disableNavigation();
        }

    }

    getContentUsingContentType(option, style) {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let content = option.content.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");

        content = content.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");

        if (option.contentType == Constants.CONTENT_TYPE_TEXT) {
            return this.parser.parseWithStyles(`${content}`, { p: styles.p, 'cw-math': styles.p })
        } else if (option.contentType == Constants.CONTENT_TYPE_IMAGE) {
            return <CWImageV2 uri={option.content}></CWImageV2>
        }

    }

    onDragStart = (event, dragInfo, type) => {

        event.stopPropagation();


        if (event.type === 'touchstart') {
            event.preventDefault()
            this.setState({ sourcePreviewVisible: true });
        }
        this.dragType = type;
        this.dragInfo = dragInfo;
    };

    onDragOver = (event, dragOverInfo, type) => {

        event.stopPropagation();
        event.preventDefault()
        if (event.type === 'touchmove') {

            // Find the target element based on the touch event
            const targetElement = document.elementFromPoint(
                event.changedTouches[0].clientX,
                event.changedTouches[0].clientY
            );

            //console.log('drag over', targetElement)

            let dragOverIndex = { index: -1 }
            let index = +targetElement?.attributes[0]?.textContent
            if (!isNaN(index)) {
                dragOverIndex = { index: index }
            }

            this.dragOverInfo = dragOverIndex

            // Access touch coordinates
            const touchX = event.touches[0].clientX;
            const touchY = event.touches[0].clientY;

            // Update the source preview's position
            this.setState({
                sourceX: touchX,
                sourceY: touchY,
            });

        } else if (this.dragType == type && this.dragType == "ANSWER") {

            this.dragOverInfo = dragOverInfo;

            this.setState({ dragOverQuestionContentId: this.dragOverInfo.item.id });
            // //console.log("CONCEPT onDragOver", dragOverInfo);

        }

    };

    onDragEnd = (event) => {

        // //console.log("end reached", this.dragType, this.dragInfo, this.dragOverInfo)
        // if (this.dragType == "ANSWER") {

        //     if (this.dragInfo) {

        //         // if (this.dragOverInfo.index != -1) {
        //             // this.setSelectedAnswer(index, dropIndex);
        //             this.setSelectedAnswer(this.hashedOriginalOptions[this.dragInfo.item.id], this.dragOverInfo.index, this.dragInfo.index)
        //         // }

        //         //console.log(this.dragInfo, this.dragOverInfo)

        //     }
        // }

    };

    dropItem = (event) => {

        event.preventDefault()
        this.setState({ sourcePreviewVisible: false });

        if (this.dragType == "ANSWER") {

            if (this.dragInfo) {

                if (this.dragOverInfo.index != -1) {
                    // this.setSelectedAnswer(index, dropIndex);
                    this.setSelectedAnswer(this.dragInfo.index, this.dragOverInfo.index)
                }

                //console.log(this.dragInfo, this.dragOverInfo)

            }
        }
    }



    getOptionItem = (index, option) => {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]


        return (
            !(this.state.droppedItemsIndex.includes(option.id)) &&
            <div key={option.id} className="answer-card cursor-pointer" style={{ border: `1.5px solid ${borderColor}`, backgroundColor: bgColor }}
                onDragStart={e => this.onDragStart(e, { index: index, item: option }, "ANSWER")}
                onTouchStart={e => this.onDragStart(e, { index: index, item: option }, "ANSWER")}
                onTouchMove={(e) => this.onDragOver(e, { index: index, item: option }, "")}
                onTouchEnd={this.dropItem}
                onDragEnd={this.onDragEnd}
                draggable={true}>
                <div className="align-self-start mb-2"><img className="drag-image" src={getThemeImageSource(ImageConstants.DRAG_ICON, this.props.categoryThemeId)} /></div>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                </div>
            </div>

        )
    }




    renderDropItem = (index) => {

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]

        if (this.state.droppedZoneItems[index] && this.state.droppedZoneItems[index].items) {
            let options = this.state.droppedZoneItems[index].items;
            //console.log(options)
            return (
                options.map((option, i) => (
                    <div id={index} className="answer-card d-flex align-items-center justify-content-center position-relative"
                        style={{ border: `1.5px solid ${borderColor}`, backgroundColor: bgColor }}>
                        <div className="close-icon-wrapper">
                            <img className="close-icon"
                                onClick={() => this.onDroppedItemRemove(index, option.id, i)}
                                src={getThemeImageSource(ImageConstants.THEME_CLOSE_ICON, this.props.categoryThemeId)} />
                        </div>
                        <div className="">
                            <div className="text-center">{this.getContentUsingContentType(option, 'answer')}</div>
                        </div>
                    </div>
                ))
            )
        }
    }

    onDroppedItemRemove(dropZoneIndex, itemId, itemIndex) {
        let droppedZoneItems = [...this.state.droppedZoneItems];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        droppedItemsIndex = droppedItemsIndex.filter((item) => item != itemId);

        if (droppedZoneItems[dropZoneIndex] && droppedZoneItems[dropZoneIndex].items) {
            droppedZoneItems[dropZoneIndex].items.splice(itemIndex, 1);
        }

        this.setState({
            droppedZoneItems: droppedZoneItems,
            droppedItemsIndex: droppedItemsIndex
        })
        this.validateAndDelegate(droppedZoneItems);
    }

    getAnswer = () => {
        if (this.isValid) {

            let options = this.props.content.content.options;

            let payload = [];

            for (let i = 0; i < this.state.droppedZoneItems.length; i++) {

                let selected = [];
                if (this.state.droppedZoneItems[i]) {
                    selected = this.state.droppedZoneItems[i].items.map(opt => ({ id: opt.id }));
                }
                payload.push({ id: options[i].id, selected: selected });
            }
            return payload;

        } else {
            return null;
        }
    }

    updateAnswer = (payload) => {

        let droppedZoneItems = [...this.state.droppedZoneItems];
        let droppedItemsIndex = [...this.state.droppedItemsIndex];

        let dragItems = {};
        this.dragItems.map((item) => { { dragItems[item.id] = item } });

        for (let i = 0; i < payload.answer.length; i++) {

            let items = payload.answer[i].selected.map((item) => {
                if (dragItems[item.id]) {
                    droppedItemsIndex.push(item.id);
                    return dragItems[item.id];
                }
            });

            droppedZoneItems[i] = { items: items };
        }
        this.isValid = true;
        this.setState({ droppedZoneItems: droppedZoneItems, droppedItemsIndex: droppedItemsIndex });

    }


    reset = () => {
        this.setState({
            droppedItemsIndex: [],
            droppedZoneItems: []
        });
    }

   

  





    render() {

        let styles = {
            p: { fontSize: '20px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "500" }
        }

        let content = this.props.content;

        let borderColor = this.theme[`secondaryColors`][`dragItemBorder`]
        let linearGradientBg = `linear-gradient(${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][0]}, ${this.theme[`linearGradient`][`groupingMatchingQuestionDragBox`][1]})`
        let bgColor = this.theme[`secondaryColors`][`chapterCardColor`]

        const { sourceX, sourceY, sourcePreviewVisible } = this.state;

        return (

            !this.props.isReadOnly ?
                content ? <div className="grouping-question-type-for-practice">
                    {sourcePreviewVisible && (

                        <div className="source-preview answer-card cursor-pointer" style={{
                            border: `2px solid ${borderColor}`, backgroundColor: bgColor,
                            left: sourceX + 30 + 'px',
                            top: sourceY - 50 + 'px'
                        }}
                        >
                            {this.getContentUsingContentType(this.dragInfo?.item, 'answer')}
                        </div>
                    )}
                    <div className="min-height">

                        <div className="d-flex flex-row  answer-card-container mt-4">
                            {
                                this.dragItems.map((option, index) => this.getOptionItem(index, option))
                            }
                        </div>

                        <img className="question-divider-image-width mt-4" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img>
                        <div className={`matching-question-container d-flex flex-row mt-4 ${this.state.droppedItemsIndex.length ? "matching-question-container-min-height-2" : ""}`}>
                            {this.options.map((option, index) => (
                                <div id={index} key={"dropZone" + index} className="drop-zone" style={{ backgroundImage: linearGradientBg, border: `1.5px solid ${borderColor}` }}
                                    onDragOver={(e) => this.onDragOver(e, { index: index, item: option }, "ANSWER")}
                                    onDrop={this.dropItem}
                                >
                                    {/* <Text style={styles.dropContainerHeaderText}>{option.title}</Text> */}
                                    <div className="text-center p-1 drop-zone-title" style={{ background: borderColor }}>
                                        {this.parser.parseWithStyles(`<p>${option.title}</p>`, { p: styles.p, 'cw-math': styles.p })}
                                    </div>

                                    <div id={index} className="d-flex flex-row answer-wrapper justify-content-center">
                                        {
                                            this.renderDropItem(index)
                                        }
                                    </div>

                                </div>
                            ))
                            }
                        </div>
                    </div>


                    {/* {!this.props.isCheckPoint && <AssessmentFooter delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>} */}


                </div> : <></>

                :

                <></>


        );
    }



}


export default Grouping;
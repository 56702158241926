import React, { Component } from 'react';
import './header-pagination.scss';


// const deviceWidth = Dimensions.get('window').width;
// const deviceHeight = Dimensions.get('window').height;
// const screenWidth = Dimensions.get('screen').width;
// const screenHeight = Dimensions.get('screen').height;

export default class LearningContentHeaderPagination extends Component {
    constructor(props) {
        super(props);

        let contents = []
        
        for (let i = 0; i < this.props.totalPage; i++) {
            contents.push({})
        }
        this.state.contents = contents;
    }

    activeIndex = 0
    pagesRef = {}

    state = {
        contents: [],
        index: 1
    }

    getActiveLine = (i) => {

        let bgColor = `linear-gradient(90deg,#05C5D9 0%,#5FD590 100%)`
        return (
            <div className='gradient-border-height' style={{ backgroundImage: bgColor }} >

            </div>
        )
    }

    getInactiveLine = () => {

        return (
            <div className='gradient-border-height'></div>
        )

    }


    getPages = () => {

        let render = [];
        let bgColor = `linear-gradient(90deg,#05C5D9 0%,#5FD590 100%)`

        for (let i = 0; i < this.state.contents.length; i++) {

            if (this.props.activeIndex == i) {
                render.push(
                    <div ref={ref => { this.pagesRef[i] = ref }} key={i} className='dot-line-container d-flex align-items-center'>
                        {this.getActiveLine(i)}
                        <div>
                            <div className='index-container' style={{ backgroundImage: bgColor }} >
                                <span className='active-index'>{i + 1}</span>
                            </div>
                        </div>

                    </div>
                )

            } else if (i <= this.props.navigateTill) {
                render.push(
                    <div ref={ref => { this.pagesRef[i] = ref }} key={i} className='dot-line-container d-flex align-items-center cursor-pointer' >
                        {this.getActiveLine(i)}
                        <div className='' onClick={() => this.navigate(i, this.props.navigateTill)}>
                            <div className='small-index-container' style={{ backgroundImage: bgColor }} >
                                <span className='inactive-index'>{i + 1}</span>
                            </div>
                        </div>
                        {/* {i < this.props.navigateTill ? this.getActiveHitslopAdjustmentLine() : this.getInactiveHitslopAdjustmentLine()} */}
                    </div>
                )
            } else if (i > this.props.navigateTill) {
                render.push(

                    <div ref={ref => { this.pagesRef[i] = ref }} key={i} className='dot-line-container d-flex align-items-center'>
                        {this.getInactiveLine()}
                        <div className='small-index-container' >
                            <span className='inactive-index' >{i + 1}</span>
                        </div>
                    </div>
                );
            }

        }


        return render;
    }


    navigate=(index)=>{

        if(this.props.activeIndex > this.activeIndex ){
            this.activeIndex = this.props.activeIndex
        }
    
        this.props.delegate && this.props.delegate.navigate(index,this.activeIndex);

    }

    // scrollTo = () => {
            
    //     // this.scroll.scrollTo({x:this.pagesRef[this.props.activeIndex].x,animated:true})
         
    // }

    scrollTo = () => {

        // this.scroll.scrollTo({ x: this.pagesRef[this.props.activeIndex].x, animated: true })
        //console.log('scrolled', this.pagesRef[this.props.activeIndex])
        // console.log("width....",this.scroll.current.scrollWidth)

        let keys = Object.keys(this.pagesRef)

        // console.log(keys, this.props.activeIndex)

        if (this.pagesRef && this.props.activeIndex && this.pagesRef[this.props.activeIndex - 1]) {

            let singlePageNumberWidth = this.scroll.current.scrollWidth / keys.length



            // let scrollLeftPosition =  this.props.activePage * this.pagesRef[this.props.activePage - 1].offsetWidth 

            let scrollLeftPosition = (this.props.activeIndex - 1) * singlePageNumberWidth

            console.log(this.scroll.current.scrollLeft, scrollLeftPosition)


            this.scroll.current.scrollLeft = scrollLeftPosition

            let element = this.pagesRef[this.props.activeIndex]

            if (element) {
                element.scrollIntoView({ behavior: "instant", block: 'center' })
            }


        }




    }

    render() {

        return (
            
            <div className={`header-pagination d-flex justify-content-center`}
            style={{width : `${this.state.contents.length > 18 ? '85%' : ''}`}}>
                <div ref={this.scroll} className='pagination-container d-flex align-items-center'>
                    {this.getPages()}
                </div>
            </div>
        )
    }


}

export const Constants = {

    ASYNC_UNIQUE_KEY: 'unique_key',
    SCHOOL_ASSIGNMENT: "School Assignment",
    CLASS_SPECIFIC_CONFIGURATION : ['Class 10'],
    ASSESSMENT_TYPE_REFRESHER_COURSE: 'REFRESHER_COURSE',
    ASSESSMENT_TYPE_MULTIPLE_CHAPTERS: 'MULTIPLE_CHAPTERS',

    ELO_STATUS_CLEARED: 'CLEARED',
    ELO_STATUS_NOT_CLEARED: 'NOT_CLEARED',
    ELO_STATUS_IN_PROGRESS: 'IN_PROGRESS',
    ELO_STATUS_START: 'START',
    ELO_STATUS_LOCKED: 'LOCKED',
    TRY_AGIN_IN_PROGRESS: 'TRY_AGAIN_IN_PROGRESS',

    IS_CHAPTER_NOT_COMPLETED: 'IS_CHAPTER_NOT_COMPLETED',
    IS_CHAPTER_COMPLETED: 'IS_CHAPTER_COMPLETED',
    IS_CHAPTER_NOT_STARTED: 'IS_CHAPTER_NOT_STARTED',

    SIGNLE_SELECT: 1,
    MULTI_SELECT: 2,
    FILL_IN_THE_BLANK: 3,
    MATCHING: 4,
    SEQUENCE: 5,
    GROUPING: 6,
    LABELING: 7,
    BINARY: 8,
    GEOMETRY_QUESTION: 10,
    DESCRIPTIVE : 12,

    CONTENT_TYPE_TEXT: 1,
    CONTENT_TYPE_IMAGE: 2,
    CONTENT_TYPE_BLANK: 3,
    CONTENT_TYPE_DROP_DOWN: 4,

    HIDE_DETAILED_SOLUTION: 'Hide Detailed Solution',
    SHOW_DETAILED_SOLUTION: 'Show Detailed Solution',
    VIEW_DETAILED_SOLUTION_TEXT: 'View Detailed Solution',
    ACTUAL_STEP: 'Actual Step',
    SOLUTION: 'Solution :',
    CONCLUSION: 'Conclusion',
    THATS_CORRECT: "That's Correct!",
    THATS_INCORRECT: "That's Incorrect!",

    LETS_CHECK: "LET'S CHECK",
    REFRESHER_COURSE: 'REFRESHER_COURSE',
    CASE_STUDY : 'CASE_STUDY',
    APP_STATE_BACKGROUND: 'background',
    APP_STATE_ACTIVE: 'active',
    APP_STATE_IN_ACTIVE: 'inactive',
    BASIC_PLAN: "Basic",
    PREMIUM_PLAN: 'Paid',
    REFRESHER_COURSE_START_STATUS: ["START", "LOCKED"],
    GTM_CONVERSION: 'AW-433550469/YzI3CO79mv0BEIXp3c4B',

    SESSION_EXPIRED: 12000,
    SCHOLARSHIP_MAY_9_2021: 'SCHOLARSHIP_MAY_9_2021',

    MOCK_TEST: "MOCK_TEST",
    TWO_STEP_EVENT_FINAL: 'TWO_STEP_EVENT_FINAL',

    TWO_STEP_EVENT: "TWO_STEP_EVENT",

    EVENT_BASELINE: 'EVENT_BASELINE',
    SCHOLARSHIP_EVENT: 'SCHOLARSHIP_EVENT',
    TWO_STEP_EVENT_BASELINE: 'TWO_STEP_EVENT_BASELINE',
    MPL_EVENT: 'MPL_EVENT',
    FINAL_EVENT: 'FINAL_EVENT',

    REWARD_EVENT_FINAL: 'REWARD_EVENT_FINAL',
    TWO_STEP_EVENT_FINAL: 'TWO_STEP_EVENT_FINAL',
    REWARD_EVENT_BASELINE: "REWARD_EVENT_BASELINE",
    REWARD_EVENT: "REWARD_EVENT",

    SCOREBOARD_LIST_SCREEN: 'SCOREBOARD_LIST_SCREEN',


    REQUEST_FROM_STUDENT_PORTAL: true,


    ELO_STATUS_CLEARED: 'CLEARED',
    ELO_STATUS_NOT_CLEARED: 'NOT_CLEARED',
    ELO_STATUS_IN_PROGRESS: 'IN_PROGRESS',
    ELO_STATUS_START: 'START',
    ELO_STATUS_LOCKED: 'LOCKED',
    TRY_AGIN_IN_PROGRESS: 'TRY_AGAIN_IN_PROGRESS',


    CURRENT_EVENT: "62380f4cc51cbe72eb270255",
    IMD_EVENT_ID: "61a58593c2d30d1bfc227766",
    GMC_EVENT_ID: "61e7ef120898f35c01753c24",
    MAX_GRAGE_LIMIT: 5,
    SLOGAN: "Redefining Maths Learning",
    WELCOME: 'Welcome to Countingwell',
    LOGOUT: 'Logout',
    APPROVAL_TEXT: 'You could access the reports once your account is approved by your',
    COUNTINGWELL_ADMIN: 'Countingwell Admin',
    MATHS_DAY_2021_SIGNUP_SLOGAN: 'School Registration for India Maths day, 2021',
    SIGNUP_SLOGAN: "School Registration for India Maths day, 2021",
    IMPORTANT_DATES: "important dates",

    ACTIVE_TAB_SIGN_UP: 1,
    ACTIVE_TAB_LOGIN: 0,

    /** Forgot password flow */
    SEND_EMAIL_PAGE: 1,
    RESET_PASSWORD_PAGE: 2,
    RESET_PASSWORD_SUCCESS_PAGE: 3,

    INVALID_EMAIL_ID: 1500,
    SECTION_DROPDOWN: [
        {
            title: '1',
            value: 1
        },
        {
            title: '2',
            value: 2
        },
        {
            title: '3',
            value: 3
        },
        {
            title: '4',
            value: 4
        },
        {
            title: '5',
            value: 5
        },
        {
            title: '6',
            value: 6
        },
        {
            title: '7',
            value: 7
        },
        {
            title: '8',
            value: 8
        },
        {
            title: '9',
            value: 9
        },
        {
            title: '10',
            value: 10
        },
    ],

    SCHOOL_ONBOARD: {
        TYPES_LIST: [{ title: "CO-ED", value: "CO-ED" }, { title: "Only Boys", value: "BOYS" }, { title: "Only Girls", value: "GIRLS" },],
        MEDIUM_LIST: [{ title: "English", value: "ENGLISH" }, { title: "Hindi", value: "HINDI" }, { title: "Regional", value: "REGIONAL" },],
        FEE_STRUCTURE_LIST: [{ title: "INR 20,000 - INR 59,000", value: "20,000 - 59,000" }, { title: "INR 60,000 - INR 90,000", value: "60,000 - 90,000" }, { title: "INR 90,000+", value: "90,000+" },],
        PLAN_LIST: [{ title: "B2B", value: "B2B" }, { title: "B2B2C", value: "B2B2C" },],
        GENDER_LIST: [{ title: "Mr.", value: "Mr." }, { title: "Mrs.", value: "Mrs." }, { title: "Miss.", value: "Miss." }],
        IMPORT_TYPE_LIST: [{ title: "Add Excel", value: "EXCEL" },]
    },


    PLAN_TYPES: {
        B2B: "B2B",
        B2B2C: "B2B2C"
    },

    SCHOOL_STATUS: {
        ACCEPTED: "ACCEPTED",
        INVITE_SENT: "Invite Sent",
        NOT_ACCEPTED: "Not Accepted"
    },

    SKILLS_COLOR: {
        KNOWLEDGE: "#ECBE6B",
        CALCULATION: "#FF9501",
        MODELLING: "#7A9AFA",
        COMPREHENSION: "#05C5D9"
    },

    TOOLBAR: [
        { name: 'clipboard', items: ['Undo', 'Redo'] },
        { name: 'Basicstyles', groups: ['Basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'underline', 'Strike'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight'] },
    ],

    ALTERNATE_STUDENT_IMAGE: "https://countingwell-doubts-assets-dev.s3.ap-south-1.amazonaws.com/366a7097-1ebe-4fc7-9c02-15eb91b53d49-user%20image.png",

    SCHOOL_ASSESSMENT_DEEPLINK: "https://t2m3p.app.link/idcLs5rNInb",

    STAFF_EXCEL_FORMAT: 'https://docs.google.com/spreadsheets/d/1dOCC7kf0QJoD8VRlccvg6NguHtl8S1Hd5gka9G8h-K4/edit?usp=sharing',
    STUDENT_EXCEL_FORMAT_1: 'https://docs.google.com/spreadsheets/d/1OYULfZsChhNw7d5MNbpomNVlTk6crlbxSU6FcUjN8Mk/edit?usp=sharing',
    STUDENT_EXCEL_FORMAT_2: "https://docs.google.com/spreadsheets/d/1aDf06gbHlasiEvihpDDyQo_7JDX4vtXSSN5lDkQaKnU/edit?usp=sharing",

    STUDENT_FORM_FILL_SECRET_KEY: "COUNTINGWELL_NIRMAL_RAVI_CASH",
    STUDENT_FORM_FILL_SECRET_MESSAGE: "COUNTINGWELL_CASH_PRICE_CLAIM_RAVI_NIRMAL"
};


export const ImageConstants = {
    BROWSE_CHAPTER_CROSS_LINK : 'browse-chapter-cross-link',
    WORKOUT_CROSS_LINK : 'workout-cross-link',
    GIFT_BOX :'gift_box',
    SUBJECT_CHANGE_ICON : 'subject_change_icon',
    CHEMISTRY_ICON : 'chemistry_icon',
    MATHS_ICON : 'maths_icon',
    WHITE_UP_ANGLE_ARROW : 'white_up_angle_arrow',
    WHITE_DOWN_ANGLE_ARROW : 'white_down_angle_arrow',
    PAUSE_BUTTON :'pause_button',
    BLACK_STUDENTS_COUNT_IMAGE : 'black_student_count_image',
    CHEAT_SHEET_ICON : 'cheat_sheet_icon',
    APPROACH_TO_PROBLEM_SOLVING : 'approach_to_problem_solving',
    COMMON_ERRORS : 'common_errors',
    MOCK_TEST_ICON : 'mock_test_icon',
    PRACTICE_QUESTION_ICON : 'practice_question_icon',
    SOLVED_EXAMPLES_ICON : 'solved_examples_icon',
    THINGS_TO_REMEMBER_ICON : 'things_to_remember_icon',
    TIPS_AND_TRICKS_ICON : 'tips_and_tricks_icon',
    PREP_KIT_BG : 'prep_kit_bg',
    PRACTICE_CASE_STUDY_ICON : 'practice_case_study_icon',
    GRAY_QUESTION_MARK_ICON : 'gray_question_mark_icon',
    GRAY_CLOCK_ICON : 'gray_clock_icon',
    MCQ_ICON : 'mcq_icon',
    CASE_STUDY_ICON : 'case_study_icon',
    SRQ_ICON : 'srq_icon',
    MOCK_TEST : "mock_test_icon",
    PRACTICE_ICON : 'practice_icon',
    EXAM_PREP_ICON : 'exam_prep_icon',
    GREEN_BG_TICK_MARK : 'green_bg_tick_mark',
    RED_BG_CROSS_MARK : 'red_bg_cross_mark',
    WORKSHEET_ACTIVE : 'worksheet_active',
    WORKSHEET_INACTIVE : 'worksheet_inactive',
    ASSESSMENTS_ACTIVE : 'assessments_active',
    ASSESSMENTS_INACTIVE : 'assessments_inactive',
    PRACTICE_ACTIVE : 'practice-active',
    PRACTICE_INACTIVE : 'practice_inactive',
    VIDEO_LIBRARY_ACTIVE : 'video_library_active',
    VIDEO_LIBRARY_INACTIVE : 'video_library_inactive',
    BROWSE_CHAPTER_ACTIVE : 'browse_chapter_active',
    BROWSE_CHAPTER_INACTIVE : 'browse_chapter_inactive',
    NO_WORKSHEET_IMAGE : 'no_worksheet_image',
    DOWNLOAD_ICON_NEW : 'download_icon_new',
    INACTIVE_LOCK_ICON : 'inactive_lock_icon',
    ACTIVE_LOCK_ICON : 'active_lock_icon',
    LC_BLUR : 'learning_content_blur',
    EYE_CONTENT_BLOCK : 'eye-content-block',
    TROPHY : 'trophy',
    CONTENT_NAVIGATION : 'content-navigation',
    GRAY_UP_ARROW : 'gray_up_arrow',
    GRAY_DOWN_ARROW : 'gray_down_arrow',
    CHAPTER_LOCK : 'chapter_lock',
    LOCK : 'lock',
    YEELOW_BULLET: 'yellow_bullet',
    GREEN_BULLET: 'green_bullet',
    LIGHT_GREEN_BULLET: 'light_green_bullet',
    ORANGE_BULLET: 'orange_bullet',
    BLUE_BULLET: 'blue_bullet',
    RED_BULLET: 'red_bullet',
    INDIGO_BULLET: 'indigo_bullet',
    SELECTED_RADIO_IMAGE: 'selected_radio_image',
    CIRCLE_UP_ARROW: 'circle_up_arrow',
    CIRCLE_DOWN_ARROW: 'circle_down_arrow',
    PLAY_ICON_ORANGE: 'play_icon_orange',
    FILTER_ICON_NEW: 'filter_icon_new',
    FILTER_ICON_ACTIVE: 'filter_icon_active',
    ERROR_NOTES_NEW: 'error_notes_new',
    SUCCESS_NOTES_NEW: 'success_note_new',
    CONTENT_DIVIDER_GRAY: 'content_divider_gray',
    NAV_PREV_KNOWLEDGE: 'nav_prev_kg',
    PLUS_ICON: 'plus_icon',
    LOADING_IMG_LOGO: 'loading_img_logo',
    LOADING_IMG_GIF: 'loading_img_gif',
    RELOAD: 'reload',
    USER_NAME_ICON: 'user_name_icon',
    SUCCESS_NOTES: 'success_notes',
    ERROR_NOTES: 'error_notes',
    PRACTICE: 'practice_new',
    SOLVED_EXAMPLES: 'solved_examples',
    SUMMARY_INNOVATION: "summary_innovation",
    SUMMARY_YELLOW_TICK: 'summary_yellow_tick',
    SUMMARY_RED_TICK: 'summary_red_tick',
    SUMMARY_GREEN_TICK: 'summary_green_tick',
    SUMMARY_BLUE_TICK: 'summary_blue_tick',
    THINGS_TO_REMEMBER: 'things_to_remember',
    INTRODUCTION: 'introduction',
    WHITE_TICK_ICON_NEW: 'white_tick_icon_new',
    WHITE_CROSS_ICON: 'white_cross_icon',
    SCHOOL_ASSIGNMENT_ACTIVE_ICON: "school_assignment_active_icon",
    SCHOOL_ASSIGNMENT_IN_ACTIVE_ICON: "school_assignment_in_active_icon",
    PRACTICE_ACTIVE_ICON: "practice_active_icon",
    PRACTICE_IN_ACTIVE_ICON: "practice_in_active_icon",
    EVENTS_MENU_ACTIVE_ICON: "events_menu_active_icon",
    EVENTS_MENU_IN_ACTIVE_ICON: "events_menu_in_active_icon",
    CERTIFICATE_ACTIVE_ICON: "certificate_active_icon",
    CERTIFICATE_IN_ACTIVE_ICON: "certificate_in_active_icon",
    RIGHT_ARROW_ORGANGE: "right_arrow_orange",
    LEFT_ARROW_ORGANGE: "left_arrow_orange",
    DARK_GREEN_ICON: "dark_green_icon",
    ASSESSMENT_FOOTER_IMAGE: "assessment_footer_image",
    HAND_ICON: "hand_icon",
    GREEN_TICK_ICON_NEW: "green_tick_icon_new",
    WHITE_TICK_ICON: "white_tick_icon",
    CLOSE_ICON_NEW: "close_icon_new",
    SUCCESS_BG_STARS: "success_bg_stars",
    GREEN_HEADER: "green_header",
    GREEN_FOOTER: "green_footer",
    RED_WRONG_ICON: "red_wrong_icon",
    RED_CLOSE_ICON_NEW: "red_close_icon_new",
    RED_HEADER: "red_header",
    RED_FOOTER: "red_footer",
    RED_CLOSE_ICON_WITH_HAND: "red_close_icon_with_hand",
    GREEN_TICK_ICON_WITH_HAND: "green_tick_icon_with_hand",
    BLUE_INFO_ICON: "blue_info_icon",
    BLUE_ARROW_ICON: "blue_arrow_icon",
    WHITE_STAR_ICON: "white_star_icon",
    BLUE_EXAM_PAD_ICON: "blue_exam_pad_icon",
    CUP_ICON: "cup_icon",
    EXPAND: "expand",
    LOGOUT_ICON_RED: "logout_icon_red",
    FEEDBACK_LEFT: "feedback_left",
    FEEDBACK_RIGHT: "feedback_right",
    FEEDBACK_STARS: "feedback_stars",
    YES_ICON: 'yes_icon',
    NO_ICON: 'no_icon',
    STATEMENT_QUOTE_UP: "statement-quote-up",
    STATEMENT_QUOTE_DOWN: "statement-quote-down",
    DEFINITION_BORDER_DOWN: "definition-border-down",
    DEFINITION_BORDER_UP: "definition-border-up",
    DEFINITION: 'Definition',
    STATEMENT: "Statement",
    PROPERTIES: 'props',
    FORMULA: 'formula',
    ACTIVE_RADIO_BUTTON: 'active_radio_button',
    INFO_RED_1: 'info_red_1',
    PEN_EDIT_ICON: 'pen_edit_icon',
    STUDENT_IMAGE: 'student_image',
    MARKS_CARD_BG: 'marks_card_bg',
    ASSESSMENT_END_SCREEN_VERTICAL_LINE: 'assessment_end_screen_vertical_line',
    ASSESSMENT_END_SCREEN_FOOTER: 'assessment_end_screen_footer',
    HEADER_DIVIDER_LINE: 'header_divider_line',
    THEME_CLOCK_IMAGE: 'theme_clock_image',
    FLAGGED_CARD_FOOTER: 'flagged_card_footer',
    FLAG_POPUP_HEADER: 'flag_popup_header',
    FLAG_POPUP_FOOTER: 'flag_popup_footer',
    THEME_CLOSE_ICON: 'theme_close_icon',
    DRAG_ICON: 'drag_icon',
    ARROW: 'arrow',
    HOMEWORK_CARD_FOOTER: 'homework_card_footer',
    HOMEWORK_COMPLETION_SCREEN_HEADER: 'homework_completion_screen_header',
    HOMEWORK_COMPLETION_SCREEN_FOOTER: 'homework_completion_screen_footer',
    THEME_DOWN_ARROW: 'down_arrow',
    THEME_UP_ARROW: 'up_arrow',
    POLYCON_ICON: 'polycon_icon',
    COURSES_ELLIPSE_ICON: 'courses_ellipse_icon',
    ELLIPSE_ICON: 'ellipse_icon',
    EVENTS_POLYCON_ICON: 'events_polygon_icon',
    ACTIVE_EVENTS_ICON: 'active_events_icon',
    PAPER_ICON: 'paper_icon',
    EVENT_CUP_ICON: 'event_cup_icon',
    RAMANUJAN_CIRCLE_LOGO: 'ramanujan_circle_logo',
    WHITE_QUESTION_MARK: 'white_question_mark',
    KNOW_MORE_HEADER: 'know_more_header',
    KNOW_MORE_FOOTER: 'know_more_footer',
    CREDIT_CARD_IMAGE: 'credit_card_image',
    PAID_EVENT_FLAG: 'paid_event_flag',
    SHARE_ICON: "share_icon",
    EVENT_ASSESSMENT_END_SCREEN_BG: 'event_assessment_end_screen_bg',
    NOTES_IMAGE: 'notes_image',
    YELLOW_BULB_IMAGE: 'yellow_bulb_image',
    BROWSE_CHAPTER_IMAGE: 'browse_chapter_image',
    PERFORMANCE_BG: 'performance_bg',
    ASSESSMENT_REPORT_MARKS_CARD_BG: 'assessment_report_marks_card_bg',
    FAILED_SKILL_HEADER: 'failed_skill_header',
    PERFORMANCE_CARD_HEADER: 'performance_card_header',
    WHITE_DOWNLOAD_ICON: 'white_download_icon',
    TICK_BUTTON: 'tick_button',
    PAID_LANDING_SCREEN_FOOTER: 'paid_landing_screen_footer',
    MISSED_EVENT_FLAG: 'missed_event_flag',
    EVENT_CARD_SUCCESS_BG: "event_card_success_bg",
    PRACTICE_CROSS_LINK: "practice_cross_link",
    FILLTER_HEADER: 'filter_header',
    FILLTER_FOOTER: 'filter_footer',
    GREEN_TICK_ICON: 'green-tick-icon',
    EVENT_CARD_BG_IMAGE: 'event_card_bg_image',
    EVENT_DISABLED: 'event_disabled',
    MOBILE_APP_ICON: 'mobile_app_icon',
    APP_STORE_ICON: 'app_store_icon',
    PLAY_STORE_ICON: 'play_store_icon',
    PREMIUM_USER_BANNER: 'premium_user_banner',
    BASIC_USER_BANNER: 'basic_user_banner',
    BASIC_USER_BANNER_TWO: 'basic_user_two',
    APP_IMAGE: 'app_image',
    APP_IMAGE_WITH_TEXT: 'app_image_with_text',
    INFO_ICON_RED: "info_icon_red",
    PREMIUM_USER_BANNER_START_ASSESSMENT: "premium_user_banner_start_assessment",



    FILLTER_ICON: 'filter_icon',
    FILLTER_ACTIVE: 'filter_active',
    QUESTION_MARK_ICON: 'question_mark_icon',
    DATE_ICON: 'date_icon',
    RED_RIPPLE_ICON: 'red_ripple_icon',
    CORRECT_ANSWER_TICK_MARK: 'correct_answer_tick_mark',
    WRONG_ANSWER_CROSS_MARK: 'wrong_answer_cross_mark',
    INVALID_ANSWER: 'invalid_answer',
    RED_INFO_ICON: 'red_info_icon',
    ACTIVE_ROUTE_IMAGE: 'active_route_image',
    HOME_ICON: 'home_icon',
    HOME_ECLIPSE: 'home_eclipse',
    HOMEWORK_BOOK_ICON: 'homework_book_icon',
    ACTIVE_HOMEWORK_ICON: 'active_homework_icon',
    COURSES_CARD_IMAGE: 'courses_card_image',
    EVENTS_CARD_IMAGE: 'events_card_image',
    CHAPTERS_LIST_IMAGE: 'chapters_list_image',
    FUN_ZONE_IMAGE: 'fun_zone_image',
    HOME_SCREEN_FOOTER: 'home_screen_footer',
    HEADER_BANNER_IMAGE: 'header_banner_image',

    YELLOW_TICK: 'yellow',
    GREEN_TICK_V3: 'green',
    RED_TICK: 'red',
    BLUE_TICK: 'blue',
    LOCK_ICON: 'lock_icon',
    SUCCESS_ICON: 'success_icon',
    CLASSWORK_COMPLETION_SCREEN_BG: 'classwork_completion_screen_bg',
    DIVIDER_GRAY: 'divider_gray',
    LEARNING_CONTENT_SEPARATOR: 'learning_content_separator',
    NO_CLASSWORK_IMAGE: 'no_classwork_image',


    ASSESSMENT_FOOTER: 'assessment_footer_bg',
    TICK_MARK_ICON: 'tick_mark_icon',
    OPTION_SEPARATOR: 'option_separator',
    DROPDOWN_ARROW_BLACK: 'dropdown_arrow_black',
    REVIEW_ANSWERS_IMAGE: 'review_answers_image',
    TIMEUP_IMAGE: 'timeup_image',
    ASSESSMENT_END_SCREEN_BG: 'assessment_end_screen_bg',
    EXAM_PAD_ICON_GRAY: 'exam_pad_icon_gray',
    CLOCK_ICON_GRAY: 'clock_icon_gray',
    MATCHING_QUESTION_DIVIDER: 'matching_question_divider',
    CORRECT_ANSWER_ICON: 'correct_answer_icon',
    INCORRECT_ANSWER_ICON: 'incorrect_answer_icon',
    FLAG_ICON: 'flag_icon',


    INFO_ICON: 'info_icon',
    CHECKBOX_ACTIVE: 'checkbox_active',
    CHECKBOX_INACTIVE: 'checkbox_inactive',
    BULB_IMAGE: 'bulb',
    NEW_FOOTER: 'new-footer',

    MALE_WITH_DESKTOP_ICON: 'male_with_desktop_icon',
    VERTICAL_LINE: 'vertical_line',
    EXAM_PAD_ICON_BLOCK: 'exam_pad_icon',
    CLOCK_IMAGE: 'clock_image',
    QUESTION_MARK: 'question_mark',
    TIMER_ICON: 'timer_icon',
    HEADER_BACKGROUND: 'header-background',

    RIGHT_ARROW_ENABLED: 'right_arrow_enabled',
    RIGHT_ARROW_DISABLED: 'right_arrow_disabled',
    LEFT_ARROW_ENABLED: 'left_arrow_enabled',
    LEFT_ARROW_DISABLED: 'left_arrow_disabled',
    FLAG_DISABLED: 'flag_disabled',
    FLAG_ENABLED: 'flag_enabled',



    TWO_DAYS: 'two_days',
    THREE_DAYS: 'three_days',
    FIVE_DAYS: 'five_days',
    TWO_DAYS_ACTIVE: 'two_days_active',
    THREE_DAYS_ACTIVE: 'three_days_active',
    FIVE_DAYS_ACTIVE: 'five_days_active',

    MORNING: 'morning',
    MORNING_ACTIVE: 'morning_active',
    EVENING: 'evening',
    EVENING_ACTIVE: 'evening_active',
    NIGHT: 'night',
    NIGHT_ACTIVE: 'night_active',

    INTERMEDIATE: 'intermediate',
    NOVICE: 'novice',
    ADVANCED: 'advanced',

    DAILY_GOAL_BG: 'daily_goal_bg',
    ROCKET: 'rocket',

    MASTER_INACTIVE: 'master_inactive',
    MASTER_ACTIVE: 'master_active',
    MISS_ACTIVE: 'miss_active',
    MISS_INACTIVE: 'miss_inactive',

    FEMALE_IMAGE: 'female_image',

    QUOTE_UP: 'quote_up',
    QUOTE_DOWN: 'quote_down',

    ACTIVE_STUDENTS: 'active_students',
    VIDEO_PLAY_ICON: 'video_play_icon',
    EXAM_PAD_ICON: 'exam_pad_icon',
    VIDEO_POSTER: 'video_poster',

    LOGIN_HEADER: 'login_header',
    LOGIN_FOOTER: 'login_footer',
    COUNTINGWELL_LOGO: 'countingwell_logo',
    FEMALE_WORKOUT_IMAGE: 'female_workout_image',
    DOWN_ARROW: 'down_arrow',
    COUNTRY_SEPARATOR: 'separator',
    EDIT_ICON_FOR_LOGIN: 'edit_icon_for_login',

    COUNTRY_DROPDOWN_FOOTER: 'country_dropdown_footer',
    COUNTRY_DROPDOWN_HEADER: 'country_dropdown_header',
    COUNTRY_CODE_SEARCH: 'country_code_search',
    COUNTRY_CLOSE_ICON: 'country_close_icon',

    PROFILE_PICTURE: 'profile_picture',
    GREEN_TICK_MARK: 'green_tick_mark',

    BACK_ARROW: 'back_arrow',

    CONFIRMATION_MODAL_HEADER: 'confirmation_modal_header',
    CONFIRMATION_MODAL_FOOTER: 'confirmation_modal_footer',

    DAILY_TIME_LIMIT_IMAGE: 'daily_time_limit_image',
    DOUBTS: 'doubts',
    BADGE_IMAGE: 'badge_image',





    EXAM_PAD_THEME_BASED: 'exam_pad',
    BOOKMARK_ACTIVE: "bookmark_active",
    WHITE_TICK: 'white_tick',
    TAKE_CHALLENGE_POPUP_HEADER: "take_challenge_popup_header",
    TAKE_CHALLENGE_POPUP_FOOTER: "take_challenge_popup_footer",
    TAKE_CHALLENGE_POPUP_MALE_IMAGE: "take_challenge_popup_male_image",
    PRACTICE_QUESTION_POPUP_HEADER: "practice_question_popup_header",
    PRACTICE_QUESTION_DIVIDER: "practice_question_divider",
    PRACTICE_QUESTION_HEADER_DIVIDER: 'practice_question_header_divider',
    PRACTICE_QUESTION_HEADER: 'practice_question_header',
    RIGHT_ARROW_BLUE: 'right_arrow_blue',
    CHALLENGE_POPUP_HEADER: 'challenge_popup_header',
    CHALLENGE_POPUP_FOOTER: "challenge_popup_footer",
    RIGHT_DARK_ARROW: "right_dark_arrow",
    TAKE_CHALLENGE_IMAGE: 'take_challenge_image',
    POPUP_HEADER_IMAGE: 'popup_header_image',
    QUESTION_DIVIDER: 'question_divider',
    BOOKMARK_ICON: 'bookmark-icon',
    TITLE_DIVIDER: 'title-divider',
    HEADER_BG_IMAGE: 'header-bg-image',
    CHAPTER_SEARCH_ICON: 'chapter-search-icon',
    SIDE_MENU_LIST_IMAGE: 'side-menu-list-icon',
    NEW_ASSESSMENT_BANNER: "new-assessment-banner",
    TICK_WHITE: "tick-white",
    GRID_ICN: "grid-icn",
    TREE_ICN: "tree-icn",
    MAIL: "mail",
    ONBOARDED: "onboarded",
    INVITE_SENT: "invite-sent",
    ONBOARDED_NEW: "onboarded-new",
    INVITE_SENT_NEW: "invite-sent-new",
    ARROW_UP_ANGLE: "arrow_up_angle",
    ARROW_DOWN_ANGLE: "arrow_down_angle",
    WHITE_VIDEO_ICON: "white_video_icon",

    WHITE_TICK_MARK: "white_tick_mark",
    EYE_ICON: "eye_icon",
    CIRCLE_INFO_ICON: "circle_info_icon",

    VIDEO_ICON: "video_icon",
    BOOK_ICON: "book_icon",
    WHITE_CLOSE_ICON: "white_close_icon",



    STEM_QUESTION_EXAMPLE: "stem-question-example",
    TREE_LINE_ICN_BLACK: "tree-line-icn-balck",
    TREE_LINE_ICN: "tree-line-icn",
    TREE_LINE_SINGLE_ICN_BLACK: "tree-line-single-icn-black",
    CAMERA: "camera",
    PDF_ICON: "pdf_icon",
    SKILL_LOW_ICN: 'skill-low-icn',
    SKILL_MEDIUM_ICN: 'skill-medium-icn',
    SKILL_HIGH_ICN: 'skill-high-icn',
    MARK_ICN: 'mark-icn',
    GIFTBOX: 'giftbox',
    CLAIM_SCHOLARSHIP_BANNER: 'claim-banner',
    HOMEWORK_SUCCESS_LEFT: 'homework-success-banner-left',
    HOMEWORK_SUCCESS_RIGHT: 'homework-success-banner-right',
    NOTIFICATION_HEADER_BG: 'notification-header-bg',
    NOTIFICATION_BANNER: 'notification-banner',
    SIGNUP_SUCCESS_BANNER: 'signup-success-banner',
    SUCCESS_GREEN: 'success-green',
    DOWN_CARET_DROPDOWN: 'down-caret-dropdown',
    CW_LOGO: 'cw-logo',
    CW_IMAGE: "cw-image",
    WELCOME: "welcome",
    HEADER_LOGO: 'header-logo',
    USER_PLACEHOLDER: 'user',
    LOGOUT: "logout",
    REMOVE_CIRCLE: 'remove-circle',
    RED_REMOVE: "red-remove",
    FORGOT_PASSWORD: 'forgot-password',
    REPORT_BG_1: 'report-bg1',
    REPORT_BG_2: 'report-bg2',
    HR_LINE: 'line',
    DROPDOWN_ARROW: 'dropdown-arrow',
    LOADER_HEADER: 'loader-header',
    LOADER_FOOTER: 'loader-footer',
    LOADING_GIF: 'loading-gif',
    PRE_LOADER_GIF: 'pre-loader-gif',
    PROFILE_VECTOR: 'profile-vector',
    REPORT_WELCOME_ICON: 'report-welcome-icon',
    EXCEL_ICON: 'excel',
    CLEAR_ICON: 'clear',
    MATHS_DAY_POS: 'maths-days-pos',
    MATHS_DAY_POS_LOGO: 'maths-days-pos-logo',
    SIGNUP_LOGO: 'signup-logo',
    SIGNUP_LOGO_RAMANUJAN: 'signup-logo-ramanujan',
    SIGNUP_LOGO_MATHS_OLYMPIAD: 'signup-logo-maths-olympiad',
    DOWNLOAD_APP_ICON: 'download-app-icn',
    IMD_CLOCK_ICON: 'imd-clock-icn',
    IMD_CALENDAR_ICON: 'imd-calendar-icn',
    GULF_CALENDAR_ICON: "gulf-calendar-icon",
    GULF_CALENDAR_ICON_1: "gulf-calendar-icon-1",
    PRE_ASSESMENT_APP_ICON: 'pre-assesment-app-icn',
    PRE_ASSESMENT_APP_ICON_1: 'pre-assesment-app-icn-1',
    ANDROID_DOWNLOAD: 'android-download',
    IOS_DOWNLOAD: 'ios-android',

    NOTIFICATION_BELL_ACTIVE: 'notification-bell-active',
    DASHBOARD_IN_ACTIVE: 'dashboard-in-active',
    PERFORMANCE_IN_ACTIVE: 'performance-in-active',
    ADMIN_USER: 'admin-user',
    CONTACTS: 'contacts',
    TEACHERS: 'teachers',
    CLASSES: 'classes',
    SETTINGS: 'settings',
    DASHBOARD_ACTIVE: 'dashboard-active',
    PERFORMANCE_ACTIVE: 'performance-active',
    ADMIN_USER_ACTIVE: 'admin-user-active',
    CONTACTS_ACTIVE: 'contacts-active',
    TEACHERS_ACTIVE: 'teachers-active',
    CLASSES_ACTIVE: 'classes-active',
    SETTINGS_ACTIVE: 'settings-active',
    PROFILE_ACTIVE: 'profile_active',
    ONBOARD_TOP_RIGHT: "onboard-top-right",
    ONBOARD_BOTTOM_RIGHT: "onboard-bottom-right",
    CROSS: "cross",
    CROSS_BLACK: "cross_black",
    CHAPTER_ICN: "chapter-icn",
    ADD: "add",
    ADD_BLUE: "add_blue",
    ADD_GRAY: "add_gray",
    EDIT: "edit",
    DELETE: "delete",
    BAR_CHART: 'bar-chart',
    DATA_BAR: 'data_bar',
    CORRECT: "correct",
    NO_RECORDS: 'no-records',
    ENVELOPE: 'envelope',
    MESSAGE: 'message',
    WHATSAPP: 'whatsapp',
    ENVELOPE_WHITE: 'envelope-white',
    MESSAGE_WHITE: 'message-white',
    WHATSAPP_WHITE: 'whatsapp-white',
    BACK_ICON: 'back-icon',
    ACTIVE_TICK: 'active-tick',
    INACTIVE_TICK: 'inactive-tick',
    RECT_TICK: "rect-tick",

    RECT: "rect",
    RECT_BLACK: "rect-black",

    RECT_BLACK1: "rect-black1",

    HOMEWORK_ACTIVE: 'homework-active',
    HOMEWORK_IN_ACTIVE: 'homework-in-active',

    STANDARD_HOMEWORK: 'standard-homework',
    ADAPTIVE_HOMEWORK: 'adaptive-homework',
    RIGHT_ARROW: 'right-arrow',
    COMPLETION_REPORT: 'completion-report',
    HOMEWORK_EVALUATION_REPORT: 'homework-evalution-report',
    MOST_WRONGLY_ANSWERED: 'most-wrongly-answered',
    LEVEL_PROGRESSION_REPORT: 'level-progression-report',
    LEVEL_IMPROVEMENT_REPORT: 'level-improvement-report',
    DOWNLOAD_ICON: 'download-icon',
    DOWNLOAD_ICON_BLUE: 'download-icon-blue',
    EDIT_ICON: 'edit-icon',
    ARCHIVE: 'archive',
    REMAINDER: 'remainder',
    FILTER_SEPERATOR: 'filter-seperartor',
    DELETE_WHITE: "delete-white",
    HAMBARGUR: "hambargur",
    HAMBARGUR1: "hambargur1",
    HAMBARGUR2: "hambargur2",
    INFO: "info",
    INFO1: "info1",
    SEARCH: "search",

    // COUNTINGWELL_LOGO :"countingwell-logo",

    HEADER1: "header1",
    PDF: "pdf",
    PDF_WHITE: "pdf-white",
    PRINT: "print",
    DRAG: "drag",
    DRAG_LOADING: "drag-loading",
    REPORT_CARD_INFO: 'report-card-info',
    INFO_CIRCLE: 'info-circle',
    EQUAL_ICON: 'equal-icon',
    UP_ARROW: 'up-arrow',
    DOWN_ARROW: 'down-arrow',
    MATCHING_ARROW: 'matching-arrow',

    TEST_ACTIVE: 'test-active',
    TEST_INACTIVE: 'test-inactive',
    TEST_RESULTS_ACTIVE: 'test-results-active',
    TEST_RESULTS_INACTIVE: 'test-results-inactive',
    MENU_ICON: 'menu-icon',
    ANSWER_ICON: 'answer-icon',
    SEPARATOR: 'separator',
    CORRECT_ANSWER: 'correct-answer',
    WRONG_ANSWER: 'wrong-answer',
    COMPLETION_REPORT_ACTIVE: 'completion-report-active',
    MOST_WRONGLY_ANSWERED_ACTIVE: 'most-wrongly-answered-active',
    CARD_SEPARATOR: 'card-separator',
    GREEN_TICK: 'green-tick',
    GREEN_TICK_V1: 'green-tick-v1',
    GREEN_TICK_V2: 'green-tick-v2',
    CALENDAR: 'calendar',
    CALENDAR_WHITE: 'calendar-white',
    DOWNLOAD_ACTIVE: 'download-active',
    STUDENTS: 'students',
    EXAM_PAD: 'exam_pad',
    VIDEOS: 'videos',
    QUOTE_LEFT: 'quote_left',
    QUOTE_RIGHT: 'quote_right',
    RIGHT_ARROW_WHITE: 'right_arrow_white',
    RIGHT_ARROW_GRAY: 'right_arrow_gray',
    RIGHT_ARROW_GRAY1: 'right_arrow_gray_1',
    TICK: 'tick',
    TICK_GREEN: 'tick_green',
    TICK_GRAY: 'tick_gray',
    TICK_BLACK: 'tick_black',
    CLASS_SECTION_ICON: 'class_section_icon',
    CLASS_SECTION_ICON_WHITE: 'class_section_icon_white',
    WRONG: 'wrong',
    NO_DATA: 'no_data',
    FILTER: "filter",
    BULB: "BULB",
    RADIO_ACTIVE: "radio_active",
    RADIO_INACTIVE: "radio_inactive",

    GRAPHIC2: "GRAPHIC2",
    GRAPHIC1: "GRAPHIC1",

    STRENGTH_ICN: "strength-icn",
    WEAKNESS_ICN: "weakness-icn",
    SCHOLAR_BLACK: "scholar-black",
    SCHOLAR_WHITE: "scholar-white",
    KNOWLEDGE: "knowledge",
    MODELING: "modeling",
    CALCULATION: "calculation",
    COMPREHENSION: "comprehension",

    CLONE_ICON: "clone_icn",
    CLONE_WHITE: "clone_white",
    PENCIL_ICON: "pencil_icn",
    INDIA_MAP_ICON: "india_map_icn",
    INDIA_MAP_PIN: "india_map_pin",
    AD_BANNER: "ad_banner",
    AD_BANNER_1: "ad_banner_1",
    AD_BANNER_3: "ad_banner_3",
    VIDEO_BANNER: "video_banner",
    PEOPLE: "people",
    PLAY_RED: "play_red",
    NOTES: "notes",
    XLS_BLUE: "xls_blue",
    STUDENT_WINNERS_BG: "student_winners_bg",
    FLUENT_CERTIFICATE: "fluent-certificate",
    ARROW_HORIZONTAL: "arrow-horizontal",

    SIGNUP_WEB: "signup-web",
    SIGNUP_MOBILE: "signup-mobile",
    EMAIL: "email",
    SMS: "sms",
    CLONE: "clone",

    INFO_RED: "info-red",
    INFO_GREY: "info-grey",

    DELETE_RED: "delete-red",
    INFO_RED_FILL: "info_red_fill",
    RECT_INACTIVE: "rect-inactive",

    EXTERNAL_LINK: "external_link",
    DIVIDER: "divider",
    HELP_BLUE: "help-blue",

    LOGO_WHITE: "logo-white",
    LOGO_ICON: "logo_icon",

    ELLIPSE: "ellipse",
    ELLIPSE_INACTIVE: "ellipse-inactive",
    ELLIPSE_ACTIVE: "ellipse-active",

    MPL: "mpl",
    FLC: "flc",
    MSF: "msf",
    CURRENCY: "currency",
    REWARD1: "reward1",
    REWARD2: "reward2",
    REWARD3: "reward3",

    FLIPKART: "flipkart",
    AMAZON: "amazon",
    CROMA: "croma",

    USER1: "user1",
    BULLET_LIST: "bullet-list"
}




export const AccessRights = {
    ADD_ADMIN: "ADD_ADMIN",
    EDIT_ADMIN: "EDIT_ADMIN",
    DELETE_ADMIN: "DELETE_ADMIN",
    INVITE_ADMIN: "INVITE_ADMIN",
    ADD_CONTACT: "ADD_CONTACT",
    EDIT_CONTACT: "EDIT_CONTACT",
    DELETE_CONTACT: "DELETE_CONTACT",
    INVITE_CONTACT: "INVITE_CONTACT",
    BULK_UPLOAD_CONTACT: "BULK_UPLOAD_CONTACT",
    ADD_TEACHER: "ADD_TEACHER",
    EDIT_TEACHER: "EDIT_TEACHER",
    DELETE_TEACHER: "DELETE_TEACHER",
    BULK_UPLOAD_TEACHER: "BULK_UPLOAD_TEACHER",
    ADD_CLASS: "ADD_CLASS",
    EDIT_CLASS: "EDIT_CLASS",
    DELETE_CLASS: "DELETE_CLASS",
    DELETE_SECTION: "DELETE_SECTION",
    ADD_STUDENT: "ADD_STUDENT",
    BULK_UPLOAD_STUDENT: "BULK_UPLOAD_STUDENT",
    INVITE_STUDENT: "INVITE_STUDENT",
    EDIT_STUDENT: "EDIT_STUDENT",
    DELETE_STUDENT: "DELETE_STUDENT",
    STANDARD_HOMEWORK_VIEW: "STANDARD_HOMEWORK_VIEW",
    STANDARD_HOMEWORK_CREATE: "STANDARD_HOMEWORK_CREATE",
    STANDARD_HOMEWORK_EDIT: "STANDARD_HOMEWORK_EDIT",
    STANDARD_HOMEWORK_ARCHIVE: "STANDARD_HOMEWORK_ARCHIVE",
    STANDARD_HOMEWORK_REMINDER: "STANDARD_HOMEWORK_REMINDER",

    ADAPTIVE_HOMEWORK_VIEW: "ADAPTIVE_HOMEWORK_VIEW",
    ADAPTIVE_HOMEWORK_CREATE: "ADAPTIVE_HOMEWORK_CREATE",
    ADAPTIVE_HOMEWORK_EDIT: "ADAPTIVE_HOMEWORK_EDIT",
    ADAPTIVE_HOMEWORK_ARCHIVE: "ADAPTIVE_HOMEWORK_ARCHIVE",
    ADAPTIVE_HOMEWORK_REMINDER: "ADAPTIVE_HOMEWORK_REMINDER",

    TEST_ARCHIVED: "TEST_ARCHIVE",
    TEST_REMINDER: "TEST_REMINDER",
    TEST_CREATE: "TEST_CREATE",
    SETUP_SECTION: "SETUP_SECTION"





}

export const StatusText = {
    CORRECT: "Correct",
    IN_CORRECT: "Incorrect",
    NOT_ATTEMPTED: "Not Attempted"
}

export const FirebaseEvents = {
    'REGISTER': 'REGISTERED',
    'SUBSCRIBE': 'SUBSCRIBE',
    'SUBSCRIPTION_FAILED': 'SUBSCRIPTION_FAILED',
    'SUBSCRIPTION_SUCCESSFUL': 'SUBSCRIPTION_SUCCESSFUL',
    'FIRST_WORKOUT_COMPLETE': 'FIRST_WORKOUT_COMPLETE',
    "PARENT_SIGNUP": 'PARENT_SIGNUP',
    "FIRST_DOUBT_RAISED": 'FIRST_DOUBT_RAISED',
    "APP_EXPLORED": 'APP_EXPLORED',
    "REFERAL": 'REFERAL',
    "VIEW_CHAPTER_SUMMARY": 'VIEW_CHAPTER_SUMMARY',
    "APP_EXPLORED": "APP_EXPLORED",
    'SESSION_START': 'SESSION_START',
    'SESSION_END': 'SESSION_END',
    "CHAPTER_INTRO_COMPLETED": "CHAPTER_INTRO_COMPLETED",
    "WORKOUT_START": 'WORKOUT_START',
    "WORKOUT_COMPLETED": "WORKOUT_COMPLETED",
    "FIRST_DOUBT_RAISED": 'FIRST_DOUBT_RAISED',
    "FIRST_WORKOUT_COMPLETED": 'FIRST_WORKOUT_COMPLETED',
    "DIAGNOSTIC_ASSESSMENT_STARTED": "DIAGNOSTIC_ASSESSMENT_STARTED",
    "DIAGNOSTIC_ASSESSMENT_COMPLETED": "DIAGNOSTIC_ASSESSMENT_COMPLETED",
    "SUBSCRIPTION_START": 'SUBSCRIPTION_START',
    "SUBSCRIPTION_SUCCESS": 'SUBSCRIPTION_SUCCESS',
    "SUBSCRIPTION_FAIL": 'SUBSCRIPTION_FAIL',
    'OTP_REQUESTED': 'OTP_REQUESTED',
    "PARENT_LOGIN": 'PARENT_FIRST_LOGIN',
    "ELO_START": "ELO_START",
    "ASSESSMENT_START": "ASSESSMENT_START",
    "ASSESSMENT_COMPLETED": "ASSESSMENT_COMPLETED",
    "ELO_COMPLETED": "ELO_COMPLETED",
    "REFRESHER_COURSE_START": "REFRESHER_COURSE_START",
    "REFRESHER_COURSE_COMPLETED": "REFRESHER_COURSE_COMPLETED",
    "BACK_TO_SCHOOL_ASSESSMENT_START": "BACK_TO_SCHOOL_ASSESSMENT_START",
    "BACK_TO_SCHOOL_ASSESSMENT_COMPLETED": "BACK_TO_SCHOOL_ASSESSMENT_COMPLETED",
    "BACK_TO_SCHOOL_LEARNING": 'BACK_TO_SCHOOL_LEARNING',
    "CONVERSION": "Conversion",
    "MPL_FRIEND_INVITED": "MPL_FRIEND_INVITED",
    "MY_CORNER": "MY_CORNER",
    "MY_PRACTICE": "MY_PRACTICE",
    "REFER_A_FRIEND": "REFER_A_FRIEND",
    "MPL": "MPL",
    "MY_ASSESSMENTS": "MY_ASSESSMENTS",
    "MY_PROFILE": "MY_PROFILE",
    "MY_DOUBTS": "MY_DOUBTS",
    "RATE_APP": "RATE_APP",
    "MY_PERFORMANCE": "MY_PERFORMANCE",
    "WORKOUT": "WORKOUT",
    "CROSS_WORKOUT_STARTED": 'CROSS_WORKOUT_STARTED',
    'CROSS_BROWSE_CHAPTER': 'CROSS_BROWSE_CHAPTER',
    "CROSS_PRACTICE": 'CROSS_PRACTICE',
    "EXAM_PREP_KIT_TRIAL_INITIATED": 'EXAM_PREP_KIT_TRIAL_INITIATED',
    "EXAM_PREP_KIT_TRIAL_EXHAUSTED": 'EXAM_PREP_KIT_TRIAL_EXHAUSTED',
    "MOCK_TEST_COMPLETED": "MOCK_TEST_COMPLETED"
}

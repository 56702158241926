

import React from 'react';
import './learning-content-header.scss';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import { ImageConstants } from '../../../constants/constants';
import SharedThemeCategory from '../../../../shared-storage/category-theme';
import ReactTooltip from "react-tooltip";
export default class LearningContentHeader extends React.Component {
    constructor(props) {
        super(props);

    }

    state = {
        showLateralMenu: false
    }

    goBack = () => {

        if (this.props.isChapterIntro) {
            this.props.delegate && this.props.delegate.goToChapterList && this.props.delegate.goToChapterList();
        }
        else {
            this.props.delegate && this.props.delegate.goBackToEloList && this.props.delegate.goBackToEloList();
        }
    }

    togglePopup = () => {
        this.props.delegate && this.props.delegate.togglePopup && this.props.delegate.togglePopup(true);
    }

    toggleLateralMenu = (flag) => {
        // this.setState({ showLateralMenu: flag });
        // NavigationService.push("LateralMenu")
    }

    onPressAskDoubt = () => {
        this.props.delegate && this.props.delegate.onPressAskDoubt()
    }

    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId);

        // setCategoryThemeStyles(this.props.categoryId);

        return (
            <div className='learning-content-header' onMouseEnter={ReactTooltip.rebuild} >
                <div className=''>
                    <img className='learning-content-header-image-size' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, this.props.categoryId)} />
                </div>


                <div>

                    {/* {
                        this.props.isScrolled ?
                            <View style={Styles.titleContainer}>

                            </View>
                            : <></>
                    } */}
                    <div>



                        {/* <TouchableOpacity onPress={() => { this.toggleLateralMenu(true) }} style={Styles.actionContainerItems} hitSlop={Styles.hitSlop}>
                            <View>
                                <Image source={getImageSource(ImageConstants.NAV_LATERAL_MENU)} />
                            </View>
                        </TouchableOpacity> */}

                        <div className={`d-flex justify-content-between ${this.props.isChapterIntro ? 'mt-5' : ''}`}>


                            {
                                !this.props.isScrolled ?
                                    <div>
                                        <div className=''>
                                            <span className='elo-title-text'>{this.props.title ? this.props.title : this.props.chapterTitle} {`${this.props.isChapterIntro ? ' - Introduction' : '' }`}</span>
                                        </div>
                                        {
                                            !this.props.isChapterIntro ?
                                                <div >
                                                    <div className='sub-title-text'>
                                                        {this.props.chapterTitle} {`${this.props.conceptTitle ? ' - ' + this.props.conceptTitle : ''}`}
                                                    </div>
                                                </div>
                                                : <></>
                                        }

                                    </div>
                                    : <></>
                            }
                            <div className='d-flex align-items-center'>
                                {this.props.isPKExist ?
                                    <div onClick={this.togglePopup} >
                                        <div data-tip={'READ PREVIOUS KNOWLEDGE'}>
                                            <img className='prev-knowledge-image cursor-pointer' src={getImageSource(ImageConstants.NAV_PREV_KNOWLEDGE)} />
                                        </div>
                                    </div>
                                    :
                                    <>

                                    </>
                                }
                                {/* <div className='ask-doubt-button' onPress={this.onPressAskDoubt}>
                                    <img className='ask-doubt-image-size' src={getImageSource(ImageConstants.PLUS_ICON)}></img>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>

                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.state.categoryThemeId)}></img>
                </div>

                {/* {this.state.showLateralMenu && <LateralMenuPopup delegate={this} ></LateralMenuPopup>} */}
            </div>
        );
    }
}

import React from 'react';
import { ReactDOM } from 'react';
import './learning-card.scss';
import Parser from "../../common/content-parser/content-parser-v2";
import LearningHeader from '../learning-header/learning-header';
import VideoCheckpointQuestionModal from './video-checkpoint-question-popup/video-checkpoint-question-popup';
import PracticeQuestions from '../check-point-questions/check-point-questions';


export default class LearningCard extends React.Component {

    constructor(props) {

        super(props);
        // this.parser = new Parser();

    }

    state = {
        showIcons: false,
        answeredQuestion: [],
        currentVideoCheckpointInfo: {},
        showVideoCheckpointQuestionModal: false,
        videoIsInFullScreen: false,
        currectVideoSourceUrl: ''
    }

    showIcons = () => {
        this.setState({ showIcons: !this.state.showIcons })
    }

    onClickBookMarkActive = () => {
        this.props.delegate && this.props.delegate.onClickBookMarkActive && this.props.delegate.onClickBookMarkActive(this.props.bookMarkId, this.props.selectedIndex)
    }

    onClickBookMark = () => {
        this.props.delegate && this.props.delegate.onClickBookMark && this.props.delegate.onClickBookMark(this.props.contentObj, this.props.selectedIndex)
    }

    // getElement(currentVideoCheckpointInfo) {

    //     let content = currentVideoCheckpointInfo.content

    //     return (<div className="pratice-container">
    //         <PracticeQuestions
    //             content={content}
    //             groupTitle={this.props.groupTitle}
    //             delegate={this.props.delegate}
    //             glossary={this.props.glossary}
    //             showTitle={this.props.showTitle}
    //             categoryId={this.props.categoryId}
    //             showBookMark={this.props.showBookMark}
    //             bookMarkId={this.props.bookMarkId}
    //             contentObj={this.props.contentObj}
    //             showInactiveBookmark={this.props.showInactiveBookmark}
    //             selectedIndex={this.props.selectedIndex}
    //             meta={this.props.meta}
    //             enableContinue={() => this.enableContinueButton()} />


    //         {/* <div className="d-flex align-items-center justify-content-around mt-2 mb-5">

    //             {
    //                 this.state.enableContinue ?
    //                     <div className="take-challenge cursor-pointer  text-center" onClick={() => this.onClickClose()}>
    //                         {`Continue Watching Video`}
    //                     </div>
    //                     :
                        
    //                     <></>

    //             }
    //         </div> */}

    //     </div>)
    // }


    updateTimePlayed(videoRef, sourceUrl, index) {

        // get the video URLS from the Content

        let contentDescription = this.props.data.content.replace(/"/g, "'");
        const srcRegex = /<video[^>]*\s+src=['"]([^'"]*?)['"]/gi;
        const srcMatches = [];
        let match;
        while ((match = srcRegex.exec(contentDescription)) !== null) {
            srcMatches.push(match[1]);
        }

        // get the current video URL index

        let checkpointInfoIndex = srcMatches.findIndex(src => src === sourceUrl)

        // get the current video checkpoint questions

        let videoCheckpointInfo = this.props.data.videoCheckpointInfo ? this.props.data.videoCheckpointInfo[checkpointInfoIndex] : {}

        // Process and stop video

        if (videoCheckpointInfo && Math.floor(videoRef[sourceUrl].currentTime)) {
            const currentTime = Math.floor(videoRef[sourceUrl].currentTime)
            console.log(currentTime)
            // const seconds = currentTime % 60;
            const minutes = Math.floor(currentTime / 60);
            console.log('success', minutes)

            let videoStopInfo = videoCheckpointInfo.stopVideoAt

            console.log(videoStopInfo)

            let stopVideoAt = 0, currentVideoCheckpointInfo = ''

            for (let i = 0; i < videoStopInfo.length; i++) {

                let videoInfo = videoStopInfo[i]
                // console.log(videoInfo)

                let mins = Math.floor(videoInfo.time)

                let secs = videoInfo.time - mins

                secs = secs * 100

                let timeInSecs = mins * 60 + secs

                // let timeInMins = videoInfo.time
                currentVideoCheckpointInfo = videoInfo
                
                console.log(timeInSecs)

                if (timeInSecs === currentTime) {
                    stopVideoAt = timeInSecs
                    break;
                }
            }

            console.log(currentVideoCheckpointInfo.content, this.state.answeredQuestion,)

            if (stopVideoAt === currentTime && !this.state.answeredQuestion[checkpointInfoIndex]) {

                if (document.fullscreenElement) {
                    this.setState({ videoIsInFullScreen: true })
                    document.exitFullscreen()
                }
                this.pauseVideo(videoRef[sourceUrl], currentVideoCheckpointInfo, checkpointInfoIndex, sourceUrl)
            } else if (stopVideoAt === currentTime && !this.state.answeredQuestion[checkpointInfoIndex][currentVideoCheckpointInfo.time]) {
                if (document.fullscreenElement) {
                    document.exitFullscreen()
                }
                this.pauseVideo(videoRef[sourceUrl], currentVideoCheckpointInfo, checkpointInfoIndex, sourceUrl)
            }

            //   this.setState({ secondsPlayed: currentTime });
        }

    };

    pauseVideo = (videoRef, currentVideoCheckpointInfo, checkpointIndex, sourceUrl) => {
        if (videoRef) {

            videoRef.style['pointer-events'] = 'none'
            // console.log(videoRef)

            

            videoRef.pause();
            
            let answeredQuestion = this.state.answeredQuestion
            let time = currentVideoCheckpointInfo['time']
            if (!answeredQuestion[checkpointIndex]) {
                answeredQuestion[checkpointIndex] = {
                    [time]: true
                }
            } else if (!answeredQuestion[checkpointIndex][time]) {
                answeredQuestion[checkpointIndex] = {
                    ...answeredQuestion[checkpointIndex], [time]: true
                }
            }
            this.setState({ showVideoCheckpointQuestionModal: false, currentVideoCheckpointInfo, videoRef, checkpointIndex, currectVideoSourceUrl: sourceUrl }, () => {

                // let newElement = <div>{this.getElement(currentVideoCheckpointInfo)}</div>
                // let jsx = this.getElement(currentVideoCheckpointInfo)
                // let newElement = React.createElement('div',{jsx})

                // ReactDOM.render(newElement, videoRef)

                // const newElementHTML = ReactDOMServer.renderToStaticMarkup(newElement);

                // videoRef.insertAdjacentElement('afterend', newElement)

                let element = document.getElementById(currentVideoCheckpointInfo.content._id.toString())
                console.log(element)
                if(element) {
                    element.scrollIntoView({ behavior: "instant", block: 'center' })
                }
            })
        }
    };

    playVideo = (videoRef) => {
        if (videoRef) {
            videoRef.scrollIntoView({ behavior: "instant", block: 'center' })
            videoRef.play();
        }
    };

    hideVideoCheckpointModal = () => {
        if (this.state.videoRef && this.state.videoIsInFullScreen) {
            this.state.videoRef.requestFullscreen();
        }

        let videoRef = this.state.videoRef
        videoRef.style['pointer-events'] = 'auto'
        console.log(videoRef.style['pointer-events'])

        this.setState({ showVideoCheckpointQuestionModal: false, videoRef, currentVideoCheckpointInfo : {}, currectVideoSourceUrl : '' }, () => {
            this.playVideo(videoRef)
        })
    }


    playSingleVideo(videoRef, sourceUrl) {

        // if(this.state.videoRef !== videoRef[sourceUrl]) {
        //     this.state.videoRef.pause()
        // }

    }


    render() {

        // setCategoryThemeStyles(this.props.categoryId);

        // console.log(this.parser.parseLearningContent(this.props.data.content, this.props.delegate, this.props.glossary, null, null, this.props.categoryId))

        return (

            <div className='learning-card'>
                {this.props.data.showTitle &&
                    <LearningHeader
                        data={
                            {
                                headerIcon: this.props.data.headerIcon,
                                title: this.props.data.title
                            }
                        } delegate={this}
                        showBookMark={this.props.showBookMark}
                        showInactiveBookmark={this.props.showInactiveBookmark}
                        meta={this.props.meta}
                        removeHeaderIcon={this.props.removeHeaderIcon}>
                    </LearningHeader>
                }

                {/* {this.state.showIcons &&
                    <CardActions />
                } */}

                <div className='min-height'>
                    <Parser
                        content={this.props.data.content}
                        delegate={this.props.delegate}
                        glossary={this.props.glossary}
                        categoryId={this.props.categoryId}
                        // updateTimePlayed={(videoRef, sourceUrl) => this.updateTimePlayed(videoRef, sourceUrl)}
                        // playSingleVideo={(videoRef, sourceUrl) => this.playSingleVideo(videoRef, sourceUrl)}
                        // videoCheckpointInfo={this.props.data.videoCheckpointInfo}
                        // currentVideoCheckpointInfoTime={this.state.currentVideoCheckpointInfo.time}
                        // currectVideoSourceUrl={this.state.currectVideoSourceUrl}
                        // hideVideoCheckpointModal={() => this.hideVideoCheckpointModal()}
                        // width = {'700px'}
                        // height = {'400px'}
                    />
                    {/* {this.parser.parseLearningContent(this.props.data.content, this.props.delegate, this.props.glossary, null, null, this.props.categoryId)} */}
                </div>
                {
                    this.state.showVideoCheckpointQuestionModal &&
                    <VideoCheckpointQuestionModal
                        delegate={this}
                        content={this.state.currentVideoCheckpointInfo.content}
                    />
                }
            </div>


        );
    }
}




import Axios from 'axios';
import HttpClient from './http-client';
import URL from './url';

export default class WorkoutApi {

    static updateWorkout(payload) {
        return HttpClient.put(URL.BASE_URL + URL.SUBSCRIBED, null, payload);
    }

    static getWorkoutProgress(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.WORKOUT_PROGRESS + "?" + params.join('&') : URL.WORKOUT_PROGRESS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    

    
}



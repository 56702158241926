import React from "react";
import './watch-video.scss';
import { getImageSource } from "../../utility/image-source";
import { ImageConstants, Constants } from "../../constants/constants";
import VideoSchoolAssignmentApi from "../../apis/video-school-assignment";
import AuthenticateAPI from "../../apis/authenticate";
import CommonUtils from "../../utility/common-utilities";
import CwContentViewer from "../common/cw-content-viewer/cw-content-viewer";
import PaginationComponent from "../common/cw-pagination/cw-pagination-v2";
import Loading from "../common/loading/loading";


class WatchVideo extends React.Component {

    state = {
        showVideoList: [],
        videoList: [],
        videoLearningContentLength: 0,
        isLoading: true
    }


    videoActivityId = ''
    deviceInfo = {}
    currentPage = 1;

    videoWatchedInfo = {}

    componentDidMount() {
        this.deviceInfo = CommonUtils.getDeviceInformation()
        let urlParams = new URLSearchParams(window.location.search);
        this.videoActivityId = urlParams.get('id')
        console.log('homework activity id', this.videoActivityId)
        if (this.videoActivityId) {
            this.autoLoginUser()
        }
    }


    autoLoginUser() {
        let payload = {
            deviceId: "STUDENT_PORTAL_DEVICE_ID",
            deviceInfo: this.deviceInfo,
            // currentVersion: 10,
            platform: this.deviceInfo.os,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL,
            videoActivityId: this.videoActivityId
        };

        AuthenticateAPI.loginWithId(payload).then(res => {
            console.log(res)
            this.uniqueKey = res.data.uniqueKey;
            this.deviceId = "STUDENT_PORTAL_DEVICE_ID"
            //console.log(//console.log(this.uniqueKey))
            // AsyncStore.setUniqueKey(this.uniqueKey);

            if (!res.data.uniqueKey) {
                this.getVideoContent(false)
            } else {
                this.verifyDevice()
            }

        }).catch(err => {
            if (err) {
                console.log(err);
                return;
            }
        })
    }

    verifyDevice = async () => {

        let payload = {
            uniqueKey: this.uniqueKey,
            deviceId: this.deviceId,
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL
        };

        AuthenticateAPI.verifyDevice(payload).then((res) => {

            console.log(res)
            // SessionStore.sharedInstance().setSession(res.session);
            // SharedProfile.setProfile(res.data.profile);

            if (res.data.profile.isAuthenticated) {
                this.getVideoContent(false)
            }


        }).catch(err => {
            if (err) {
                return;
            }

        })
    }

    getVideoContent(updatedVideoWatched) {
        let payload = {
            videoActivityId: this.videoActivityId
        }

        if (!payload.videoActivityId) return;

        if (this.uniqueKey) {
            VideoSchoolAssignmentApi.getUserVideoSchoolAssignmentActivities(payload).then(res => {
                console.log(res)
                let showVideoList = this.state.showVideoList;
                showVideoList[0] = true


                if (!updatedVideoWatched) {
                    this.setState({
                        videoList: res.videoContent,
                        chapterTitle: res.chapterTitle,
                        conceptTitle: res.conceptTitle,
                        eloTitle: res.eloTitle,
                        showVideoList,
                        selectedVideoIndex: 0,
                        videoLearningContentLength: res.videoContent.length,
                        showVideoDetail: false,
                        isLoading: false
                    })
                }

                if (res.videoWatchedInfo) {
                    this.videoWatchedInfo = res.videoWatchedInfo
                }


            }).catch(err => {
                console.log(err)
            })
        } else {
            VideoSchoolAssignmentApi.getUserVideoSchoolAssignmentActivitiesUsingMobileNumber(payload).then(res => {
                console.log(res)
                let showVideoList = this.state.showVideoList;
                showVideoList[0] = true


                if (!updatedVideoWatched) {
                    this.setState({
                        videoList: res.videoContent,
                        chapterTitle: res.chapterTitle,
                        conceptTitle: res.conceptTitle,
                        eloTitle: res.eloTitle,
                        showVideoList,
                        selectedVideoIndex: 0,
                        videoLearningContentLength: res.videoContent.length,
                        showVideoDetail: false,
                        isLoading: false
                    })
                }

                if (res.videoWatchedInfo) {
                    this.videoWatchedInfo = res.videoWatchedInfo
                }


            }).catch(err => {
                console.log(err)
            })
        }



    }

    onPageSelected = (selectedPage, startIndex) => {

        console.log(selectedPage, startIndex)

        let currentIndex = selectedPage - 1

        let showVideoList = this.state.showVideoList;
        this.state.videoList.forEach((videoContent, idx) => {
            if (idx !== currentIndex) {
                showVideoList[idx] = false
            }
        })

        showVideoList[currentIndex] = showVideoList[currentIndex] ? false : true

        this.setState({ showVideoList, selectedVideoIndex: currentIndex, showVideoDetail: false })



        // let payload = {
        //     page: selectedPage,
        // }
        // this.startIndex = startIndex
        // this.filter.page = selectedPage
        // this.setState({ selectedPage, selectedItemIndex: null, selectedItemGroupIndex: null })
        // this.getHomeworkList(this.filter)
    }

    showVideoDetail() {
        this.setState({ showVideoDetail: !this.state.showVideoDetail })
    }

    videoPlayed(srcURL, videoContent) {
        console.log(srcURL, videoContent)


        let valid = this.checkVideoWatchedStatus(srcURL, videoContent, 'STARTED')

        console.log(valid)

        if (valid) {
            let payload = {
                videoContentId: videoContent._id,
                videoActivityId: this.videoActivityId,
                status: 'STARTED',
                videoSrcURL: srcURL
            }

            this.updateVideoWatchedDetails(payload)
        }


    }

    videoEnded(srcURL, videoContent) {

        let valid = this.checkVideoWatchedStatus(srcURL, videoContent, 'FINISHED')

        if (valid) {
            let payload = {
                videoContentId: videoContent._id,
                videoActivityId: this.videoActivityId,
                status: 'FINISHED',
                videoSrcURL: srcURL
            }

            this.updateVideoWatchedDetails(payload)
        }
    }


    updateVideoWatchedDetails(payload = {}) {

        if (!payload) return;

        if (this.uniqueKey) {
            VideoSchoolAssignmentApi.updateVideoWatchedDetails(payload).then(res => {
                console.log(res)

                this.getVideoContent(true)

            }).catch(err => {
                console.log(err)
            })
        } else {
            VideoSchoolAssignmentApi.updateVideoWatchedDetailsUsingMobileNumber(payload).then(res => {
                console.log(res)

                this.getVideoContent(true)

            }).catch(err => {
                console.log(err)
            })
        }


    }


    checkVideoWatchedStatus(srcURL, videoContent, checkForExistingStatus) {
        let videoWatchedInfo = this.videoWatchedInfo
        let videoWatchedDetails = []

        console.log(videoWatchedInfo[videoContent._id])

        if (videoWatchedInfo[videoContent._id] && videoWatchedInfo[videoContent._id].length) {
            videoWatchedDetails = videoWatchedInfo[videoContent._id].filter(content => content.videoSrcURL === srcURL)
        } else {
            return true;
        }

        if (videoWatchedDetails.length) {
            let videoContent = videoWatchedDetails[0]
            if (videoContent.status === "FINISHED" || videoContent.status === checkForExistingStatus) {
                return false
            } else {
                return false
            }
        } else {
            return true;
        }
    }





    render() {
        return (
            <div className='container-fluid watch-video'>
                <div className='header row no-gutters'>
                    <div className='col-6'>
                        <a href='https://countingwell.com'>
                            <img
                                alt=''
                                className='logo'
                                src={getImageSource(ImageConstants.CW_LOGO)}
                            />
                        </a>
                    </div>
                </div>
                <div>
                    {
                        !this.state.isLoading ?
                            <>
                                <div className="d-flex justify-content-between align-items-center heading ">
                                    <div className="d-flex align-items-center">
                                        <div className="mr-2">
                                            <img className="book-with-apple" src={getImageSource(ImageConstants.PLAY_ICON_ORANGE)} />
                                        </div>
                                        <div className="">{this.state.chapterTitle}<div className="heading-subtext">{this.state.conceptTitle}{this.state.eloTitle ? ` > ${this.state.eloTitle}` : ''}</div></div>
                                    </div>
                                </div>
                                <div className="mb-4 mt-4">
                                    {this.state.videoList?.map((videoContent, index) => (
                                        <>
                                            {this.state.showVideoList[index] && this.state.selectedVideoIndex === index &&
                                                <>
                                                    <div className="row">
                                                        <div className="col mb-2"><CwContentViewer from={'video_only'} content={videoContent.content.description}
                                                            videoEnded={(srcURL) => this.videoEnded(srcURL, videoContent)}
                                                            videoPlayed={(srcURL) => this.videoPlayed(srcURL, videoContent)} />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="read-more-wrapper">
                                                            <span className="read-more cursor-pointer" onClick={() => this.showVideoDetail()}>{`${this.state.showVideoList[index] && this.state.selectedVideoIndex === index && this.state.showVideoDetail == true ? 'Hide More' : 'Read More'}`}</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.showVideoList[index] && this.state.selectedVideoIndex === index && this.state.showVideoDetail == true ?
                                                            <div className="p-4 position-relative">
                                                                <img className="video-detail-header-image" src={getImageSource(ImageConstants.VIDEO_DETAIL_HEADER_IMAGE)} />
                                                                <div className="video-detail-wrapper">
                                                                    <div className="elo-title p-4">
                                                                        {this.state.eloTitle}
                                                                    </div>
                                                                    <div className="video-detail-divider"></div>
                                                                    <div className="pl-4 pr-4">
                                                                        <div className="d-flex video-learning-content-wrapper mt-4 mb-4 pb-2">
                                                                            <div className='video-learning-content row '>
                                                                                <div className="col content-alignment mt-2">
                                                                                    <div className='d-flex video-learning-content-title'>
                                                                                        <div className='mr-1 sr-no '><span className='mt-3 sr-no-bg-color'>{`${index + 1}.`}</span></div>
                                                                                        <div className='align-self-center sr-no'>{videoContent.title} </div>
                                                                                    </div>
                                                                                    <div className="video-content-wrapper">
                                                                                        {<CwContentViewer from={'video_content'} content={videoContent.content.description} />}
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            : <></>
                                                    }


                                                </>

                                            }
                                        </>

                                    ))}
                                </div>

                                <div className='card-footer'>
                                    {
                                        this.state.videoLearningContentLength ?
                                            <PaginationComponent startIndex={this.startIndex} selectedPage={this.currentPage} totalPages={this.state.videoLearningContentLength} onPageSelected={this.onPageSelected.bind(this)} /> : ''
                                    }
                                </div>
                            </>
                            :
                            <Loading />
                    }
                </div>
            </div>

        )
    }

}

export default WatchVideo;
import React, { Component } from 'react';
import './solved-example-modal.scss';
import LearningContentWrapper from '../exam-prep-kit-learning-content-wrapper/exam-prep-kit-learning-content-wrapper';
import SharedProfile from '../../../../../shared-storage/profile';
import Loading from '../../../common/loading/loading';
import { getImageSource } from '../../../../utility/image-source';
import { ImageConstants } from '../../../../constants/constants';
import AssessmentApi from '../../../../apis/assessment';
import ExamPrepKitApi from '../../../../apis/exam-prep-kit';

export default class SolvedExampleModal extends Component {

    workflowEngine = null;
    worflowNodes = [];
    questions = [];
    state = {
        learningElos: [
        ],
        loading: true,
        resumeIndex: 0,
        status: ''
    };

    CONTENT_FETCH_LIMIT = 10
    contentHash = {}

    constructor(props) {

        super(props);
        let { navigation } = this.props;
        this.params = this.props.params
        this.headerTitle = this.params && this.params.headerTitle ? this.params.headerTitle : ""
        this.progress = null;
        this.userInfo = SharedProfile.getProfile();
        this.contents = []
        this.lastResumeId = {}
        this.elosCompleted = {}

        if (this.params.progress && this.params.progress[this.params.eloId]) {

            this.solvedExampleProgress = this.params.progress[this.params.eloId]

            if (this.params.linkedElo)
                this.linkedEloProgress = this.params.progress[this.params.linkedElo]

            if (this.solvedExampleProgress && this.solvedExampleProgress.status == 'COMPLETED') {
                this.elosCompleted[this.params.eloId] = { status: "COMPLETED" }
            }

            if (this.linkedEloProgress && this.linkedEloProgress.status == 'COMPLETED') {
                this.elosCompleted[this.params.linkedElo] = { status: "COMPLETED" }
            }

            if (this.solvedExampleProgress.status != 'COMPLETED' && this.solvedExampleProgress.resumeId) {
                this.state.resumeIndex = this.solvedExampleProgress.resumeId
                this.lastResumeId[this.params.eloId] = this.solvedExampleProgress.resumeId
            } else if (this.solvedExampleProgress &&
                this.solvedExampleProgress.status == 'COMPLETED' &&
                (this.linkedEloProgress && this.linkedEloProgress.status != 'COMPLETED')) {
                this.state.resumeIndex = (this.solvedExampleProgress.resumeId + (this.linkedEloProgress.resumeId ? this.linkedEloProgress.resumeId + 1 : 1))
            }

            if (this.solvedExampleProgress.status == 'COMPLETED') {

                if (!this.params.linkedElo) {
                    this.state.status = 'COMPLETED'
                } else if (this.params.progress[this.params.linkedElo] && this.params.progress[this.params.linkedElo].status == 'COMPLETED') {
                    this.state.status = 'COMPLETED'
                }
            }
        }

        this.getSolvedExampleContent()
    }

    learnMoreParam = {}

    componentDidMount() {
        // this.props.navigation.addListener("didFocus", () => {

        //     BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });

        // this.props.navigation.addListener("didBlur", () => {
        //     BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });
    }

    handleBackButtonClick = () => {

    }

    buildLearningContent = (contents, index, callback) => {

        this.getContents(contents, index, (err, response) => {

            if (err) {
                this.setState({ loading: false })
                return
            }

            for (let i = 0; i < response.length; i++) {
                let content = response[i]
                this.contentHash[content._id] = content
            }

            let learningElos = this.state.learningElos;

            for (let i = 0; i < contents.length; i++) {
                let content = contents[i]

                if (this.contentHash[content.contentId]) {
                    learningElos.push({
                        eloId: content.eloId,
                        eloTitle: content.eloTitle,
                        categoryThemeId: content.categoryThemeId,
                        contentId: content.contentId,
                        contents: [{ title: "", contents: [this.contentHash[content.contentId]] }],
                        bookMarkedItem: content.bookMarkedItem ? content.bookMarkedItem : null
                    })
                } else {
                    learningElos.push({
                        eloId: content.eloId,
                        eloTitle: content.eloTitle,
                        categoryThemeId: content.categoryThemeId,
                        contentId: content.contentId,
                        contents: [],
                        bookMarkedItem: content.bookMarkedItem ? content.bookMarkedItem : null
                    })
                }
            }

            this.setState({ learningElos }, () => {
                if (callback)
                    callback()

                this.setState({ loading: false })
            })
        })
    }

    getSolvedExampleContent = () => {


        let payload = {
            eloId: this.params.eloId
        }

        if (this.props.isPrimary) {
            payload.isPrimary = true
        }

        if (!this.state.loading)
            this.setState({ loading: true })

        ExamPrepKitApi.getPrepKitSolvedExample(payload).then((response) => {
            if (!response.length) {
                this.setState({ loading: false })
                this.props.close && this.props.close()
            }

            this.contents = response

            this.elos = []
            this.eloHash = {}

            for (let i = 0; i < this.contents.length; i++) {
                let eloId = this.contents[i].eloId

                if (!this.eloHash[eloId]) {
                    this.eloHash[eloId] = 1
                    this.elos.push(eloId)
                } else {
                    this.eloHash[eloId] = i + 1
                }
            }

            if (this.contents) {
                this.buildLearningContent(this.contents, 0)

                if (!this.state.resumeIndex && this.state.status != 'COMPLETED') {

                    if (this.contents && this.contents.length && this.params.linkedElo && this.params.linkedElo == this.contents[0].eloId) {
                        this.updatePrepKitActivity(0, this.params.linkedElo, true)
                        return
                    }

                    this.updatePrepKitActivity(0, this.params.eloId, true)
                }
            } else
                this.setState({ loading: false })

        }).catch(err => {
            console.log(err)
            if (err) {
                this.setState({ loading: false }, () => {
                    this.learningElosCompleted()
                })

                return
            }
        })
    }

    getContents = (questions, index, callback) => {


        let ids = [];

        for (let i = 0; i < questions.length; i++) {

            if (index <= i) {
                if (ids.length < this.CONTENT_FETCH_LIMIT) {
                    if (!this.contentHash[questions[i].contentId])
                        ids.push(questions[i].contentId)
                }
            }
        }

        let payload = { _id: ids }

        AssessmentApi.getContentList(payload).then((res) => {
            callback && callback(null, res.response)
        }).catch(err => {
            console.log(err);
            if (err) {
                callback && callback(err, null)
                return;
            }
        })
    }

    onPressNext = (activeIndex, callback) => {
        if (this.contents[activeIndex + 1] && (!this.contents[activeIndex + 1].status || this.contents[activeIndex + 1].status != 'COMPLETED')) {

            let index = activeIndex + 1;
            if (this.elos.length > 1 && this.elos[1] == this.contents[activeIndex + 1].eloId) {
                index = index - this.eloHash[this.elos[0]]
            }
            this.updatePrepKitActivity(index, this.contents[activeIndex + 1].eloId)
        }

        if ((this.contents.length - 1) <= (activeIndex + 1)) {
            this.updateEloCompletedCompleted(this.contents[activeIndex + 1].eloId, activeIndex + 1)
        } else {
            if (this.contents[activeIndex].eloId != this.contents[activeIndex + 1].eloId) {
                this.updateEloCompletedCompleted(this.contents[activeIndex].eloId, activeIndex)
            }
        }
        if (this.contents[activeIndex + 1] && this.contentHash[this.contents[activeIndex + 1].contentId]) {
            callback && callback()
        } else {

            this.getContents(this.contents, activeIndex + 1, (err, response) => {

                if (err) {
                    return
                }

                for (let i = 0; i < response.length; i++) {
                    let content = response[i]
                    this.contentHash[content._id] = content
                }

                let learningElos = this.state.learningElos;

                for (let i = activeIndex + 1; i < learningElos.length; i++) {
                    let lerningContent = learningElos[i]

                    if (lerningContent.contentId && !lerningContent.contents.length && this.contentHash[lerningContent.contentId]) {
                        lerningContent.contents = [{ title: "", contents: [this.contentHash[lerningContent.contentId]] }]
                    }
                }

                this.setState({ learningElos }, () => {
                    if (callback)
                        callback(true)
                })
            })
        }
    }

    learningElosCompleted = () => {
        this.props.close && this.props.close(this.lastResumeId, this.elosCompleted)
    }


    close = () => {
        this.props.close && this.props.close(this.lastResumeId, this.elosCompleted)
    }

    goToLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    closeLearnMore = () => {
        this.setState({ showLearnMore: false })
    }

    updateEloCompletedCompleted = (eloId, index) => {

        this.elosCompleted[eloId] = { status: "COMPLETED" }

        let payload = {
            chapterKey: this.params.chapterKey,
            type: "SOLVED_EXAMPLE_ELO_COMPLETED",
            eloId
        }
        ExamPrepKitApi.updatePrepKitProgress(payload).then((response) => {
            this.contents[index].status = 'COMPLETED'
        }).catch(err => {
            console.log(err)
            if (err) {
                return
            }

        })
    }
    updatePrepKitActivity = (index, eloId, initiate) => {

        this.lastResumeId[eloId] = index

        let payload = {
            chapterKey: this.params.chapterKey,
            resumeId: index,
            type: "SOLVED_EXAMPLE_ELO_COMPLETED",
            eloId
        }

        ExamPrepKitApi.updatePrepKitActivity(payload).then((response) => {

            

            if (this.contents.length == 1 && initiate) {
                this.updateEloCompletedCompleted(eloId, index)
            }
        }).catch(err => {
            console.log(err)
            if (err) {
                return
            }
        })
    }


    onPressLearnMore = (doc) => {

        this.learnMoreParam = {
            eloId: doc.eloId,
            title: this.params.title,
            headerTitle: doc.eloTitle,
            chapterKey: this.params.chapterKey
        }

        this.setState({ showLearnMore: true })
    }

    render() {

        return (
            <>

                <div>
                    
                    {this.state.learningElos.length > 0 ? <LearningContentWrapper
                        elo={this.state.learningElos}
                        delegate={this}
                        navigation={this.props.navigation}
                        isRevise={this.isRevise}
                        title={this.params.title ? this.params.title : ''}
                        navigationInfo={this.params}
                        eloIds={this.eloIds}
                        noBlur={true}
                        headerIcon={getImageSource(ImageConstants.SOLVED_EXAMPLES)}
                        headerTitle={this.params.headerTitle}
                        removeHeaderIcon
                        onPressNext={this.onPressNext}
                        activeIndex={this.state.resumeIndex > (this.state.learningElos.length - 1) ? (this.state.learningElos.length - 1) : this.state.resumeIndex}
                        allPagesActive={this.state.status == 'COMPLETED' ? true : false}
                        hideLateralMenu
                    >
                    </LearningContentWrapper> : <Loading />}

                </div>

            </>
        )
    }
}
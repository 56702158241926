import React from "react";
import './country-code-dropdown.scss'
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";


class CountryCodeDropdown extends React.Component {


    // value={this.state.search} onChange={(event) => { this.onSearch(event) }}

    render() {
        return (
            <Modal className="cw-custom-modal-center-for-country" isOpen={true} toggle='' size={'md'}>
                <ModalBody>
                    <div className="country-code-drop-down" id="st-3">
                        <div className="">
                            <div className="country-header-div">
                                <img className="country-header-img" src={getImageSource(ImageConstants.COUNTRY_DROPDOWN_HEADER)} />
                            </div>
                            <div className="search">
                                <input className="form-control" placeholder={`Search`}  />
                            </div>
                            <div className="p-2">
                                {this.props.dropDownList.length ?
                                    this.props.dropDownList.map(dropdown => (
                                        <div className="dropdown-list cursor-pointer px-3 py-1" onClick={() => { this.onSelectCode(dropdown) }}>
                                            <div className="row country-name cursor-pointer">
                                                <div className="col-8"> {dropdown.addOn}</div>
                                                <div className="col text-right">  {dropdown.text}</div>
                                            </div>
                                        </div>

                                    ))
                                    : <div className="text-center py-4 no-data">
                                        <label> No Records found</label>
                                    </div>
                                }</div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }


}

export default CountryCodeDropdown;
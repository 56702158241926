import React from "react";
import './intro-video-screen.scss';
import SharedProfile from "../../../../shared-storage/profile";
import ReactPlayer from 'react-player'
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import AuthenticateAPI from "../../../apis/authenticate";

class AppIntroVideo extends React.Component {

    constructor(props) {
        super(props);

        // const { navigation } = this.props;
        // this.meta = navigation.getParam('meta');

        // if (this.meta && this.meta.course) {
        //     this.state.course = this.meta.course
        // }

        // this.userInfo = SharedProfile.getProfile()

        // this.state.videoUrl = this.userInfo.video && this.userInfo.video.url;
        // this.state.tumbnail = this.userInfo.video && this.userInfo.video.tumbnail;
        // this.state.timeToShowSkip = this.userInfo.video && this.userInfo.video.skipOn
    }

    state = {
        videoUrl: "",
        tumbnail: "",
        showSkipButton: false,
        currentTime: 0,
        timeToShowSkip: 5
    }

    onProgress = (currentTime) => {

        //console.log(currentTime)
        this.setState({ currentTime }, () => {
            if (!this.state.showSkipButton && currentTime.playedSeconds > this.state.timeToShowSkip) {
                this.setState({ showSkipButton: true })
            }
        })
    }


    onPressSkip = () => {

        AuthenticateAPI.updateDefaultWorkflowStage({ isVideoViewed: true }).then((res) => {
            this.props.onPressSkip && this.props.onPressSkip()
        }).catch(err => {
            //console.log(err)
            if (err)
                return
        })
    }





    render() {
        return (
            <div className="intro-video">
                <div className="d-flex justify-content-center">
                    <div className="text-center video-heading">
                        <span className="countingwell-text">Countingwell = </span>
                        <span className="learning-text">Maths Learning<span className="plus"> + </span>Practice<span className="plus"> + </span>Special Courses<span className="plus"> + </span>Competitions
                        </span>
                    </div>
                </div>

                <div className="video-wrapper d-flex justify-content-center flex-column align-items-center mt-4">
                    <ReactPlayer
                        controls
                        light={<img className="poster-image-size" src={getImageSource(ImageConstants.VIDEO_POSTER)} />}
                        playing={true}
                        playIcon={<img />}
                        onProgress={this.onProgress}
                        url='https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/47347552-4f0b-43de-995a-1a3e0399bb6f_CW_Video_for_WhatsApp.mp4' />
                    <div className="play-text text-center mt-3">Play this video to take a quick look</div>
                </div>

                <div className="d-flex justify-content-center">
                    {
                        this.state.showSkipButton ?
                            <div className="text-center skip-button cursor-pointer" onClick={() => this.onPressSkip()}>Skip</div>
                            :
                            <div className="text-center skip-button skip-button-disabled">Skip</div>
                    }

                    {/* {this.getButtons()} */}
                </div>
            </div>
        )
    }

}

export default AppIntroVideo;
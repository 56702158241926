import React from "react";
import './standard-classwork-view-answer.scss';
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import Loading from "../../common/loading/loading";
import { ImageConstants } from "../../../constants/constants";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import QuestionPagination from "../../my-assessment-result/questions-pagination/questions-pagination";
import Question from "../../my-assessment-result/question/question";
import AssessmentSolution from "../../common/assessment-solution/assessment-solution";
import HomeworkApi from "../../../apis/homework";
import AssessmentApi from "../../../apis/assessment";
import Path from "../../routes/routes-path";


export default class MyAssessmentsResult extends React.Component {



    payload = {}
    page = 1;
    totalPages = 0;
    onEndReachedCalledDuringMomentum = true
    PAGE_SIZE = 1
    CONTENT_FETCH_LIMIT = 100
    tabQuestionDetails = {}
    contentScrollHeight = 0
    headerHeight = 0
    isScrollStart = false


    intialQuestionGroup = {}
    allQuestionsHash = {}
    selectedTabLocal = "ALL"

    positionHash = {}

    state = {
        showShadow: false,
        loading: false,
        fullScreenLoading: true,
        selectedTab: "ALL",
        renderQuestions: [],
        allQuestions: [],
        scrollViewHeight: 0,

    }

    constructor(props) {
        super(props);

        // const { navigation } = this.props;
        // this.meta = navigation.getParam('meta');

        // this.chapterKey = this.meta.chapterKey;
        // this.chapterTitle = this.meta.chapterTitle;
        this.homeworkId = this.props.metaForViewAnswer.homeworkId;
        this.categoryThemeId = this.props.metaForViewAnswer.categoryThemeId;
        this.fromAssess = this.props.metaForViewAnswer.fromAssess;
    }

    componentDidMount() {
        this.getMyAssessmentResult();
    }

    getMyAssessmentResult = (payload) => {

        //payload = payload ? payload : {}

        payload = { homeworkId: this.homeworkId }

        this.setState({ fullScreenLoading: true });

        HomeworkApi.getStandardHomeworkResult(payload).then((res) => {
            this.setState({ fullScreenLoading: false });
            if (res && res.questions && res.questions.length) {
                let questions = res.questions.map((question, index) => {
                    question.index = index;
                    return question
                })

                this.questions = questions
                let questionGroup = this.groupbyStatus(questions)
                this.intialQuestionGroup = questionGroup;

                this.setState({ allQuestions: questions, questionGroup })
                this.getContents(questions);
            }
        }).catch(err => {
            this.setState({ fullScreenLoading: false });
            if (err) {
                return;
            }

        })
    }

    groupbyStatus = (questions) => {
        let questionGroup = { "ALL": questions }
        let groupKeys = ["ALL"];

        if (questions && questions.length) {

            for (let i = 0; i < questions.length; i++) {
                let question = questions[i]

                this.allQuestionsHash[question.id] = question;

                if (questionGroup[question.status]) {
                    questionGroup[question.status].push(question)

                } else {
                    questionGroup[question.status] = [question]
                    groupKeys.push(question.status);
                }
            }
        }

        for (let j = 0; j < groupKeys.length; j++) {
            let key = groupKeys[j];
            let groupQuestions = questionGroup[key];
            let totalRecords = groupQuestions.length
            let totalPages = Math.ceil(groupQuestions.length / 2);

            this.tabQuestionDetails[key] = {
                totalRecords,
                totalPages,
                currentPage: 0
            }
        }
        return questionGroup
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    getContents = (questions) => {
        let ids = [];

        for (let i = 0; i < questions.length; i++) {
            ids.push(questions[i].id)
        }

        let payload = { _id: ids }

        if (ids && ids.length) {

            if (this.state.questions && this.state.questions.length)
                this.toggleLoading(true)
            else
                this.toggleFullScreenLoading(true)

            AssessmentApi.getContentList(payload).then((res) => {



                if (res.response && res.response.length) {

                    let questionFetched = [];

                    let questionsHash = {}

                    for (let j = 0; j < res.response.length; j++) {
                        let question = res.response[j];
                        let qDetails = this.allQuestionsHash[question._id];

                        qDetails.details = question
                        qDetails.details.id = question._id
                        questionFetched.push(qDetails);

                    }

                    questionFetched = questionFetched.sort((a, b) => a.index - b.index)

                    for (let i = 0; i < questionFetched.length; i++) {
                        let question = questionFetched[i]
                        if (!questionsHash[question.status]) {
                            questionsHash[question.status] = [question]
                        } else {
                            questionsHash[question.status].push(question)
                        }

                        if (!questionsHash[this.state.selectedTab]) {
                            questionsHash[this.state.selectedTab] = [question]
                        } else {
                            questionsHash[this.state.selectedTab].push(question)
                        }

                    }

                    this.setState({ questionsHash: questionsHash, questions: questionsHash[this.state.selectedTab], fullScreenLoading: false })

                }
            }).catch(err => {
                if (err) {
                    if (this.state.loading)
                        this.toggleLoading(false)

                    if (this.state.fullScreenLoading)
                        this.toggleFullScreenLoading(false)
                    return;
                }
            })
        }
    }

    toggleLoading = (value, callback) => {
        this.setState({ loading: value }, () => {
            callback && callback()
        });
    }

    toggleFullScreenLoading = (value, callback) => {
        this.setState({ fullScreenLoading: value }, () => {
            callback && callback()
        })
    }

    onClose = () => {

        this.props.hideViewAnswerScreen && this.props.hideViewAnswerScreen()
    }

    scollEndReached = () => {

        let tabQuestionDetails = this.tabQuestionDetails[this.state.selectedTab]

        let currentPage = tabQuestionDetails.currentPage;
        let totalPages = tabQuestionDetails.totalPages;
        let totalRecords = tabQuestionDetails.totalRecords;

        //alert(currentPage <= totalPages)

        if (currentPage >= totalPages)
            return;

        currentPage = currentPage + 1;

        tabQuestionDetails.currentPage = currentPage;

        let questionsToFetch = [];
        if (currentPage >= 1) {

            let questions = this.intialQuestionGroup[this.state.selectedTab];

            let startIndex = currentPage * this.CONTENT_FETCH_LIMIT - this.CONTENT_FETCH_LIMIT;
            let endIndex = currentPage * this.CONTENT_FETCH_LIMIT;

            if (endIndex > totalRecords) {
                endIndex = totalRecords;
            }

            for (let i = startIndex; i < endIndex; i++) {
                let question = questions[i];
                questionsToFetch.push(question);
            }

            ////console.log("questionsToFetch******************", questionsToFetch, questions);


            this.getContents(questionsToFetch, this.state.selectedTab)
        }



    }

    renderItem(item, index) {
        //console.log(item)
        let status = item.status
        return (
            <div className="mt-3" id = {item.details._id}>
                {/* <Text>{index}</Text> */}
                <div className=" question-info row align-items-center justify-content-center mr-0 mb-3" >
                    <div className="col-1 question-number-wrapper">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="question-number-text text-center" 
                            style={{backgroundColor : this.theme[`themeColor`]}}>{item.index + 1}</div>
                        </div>
                    </div>
                    <div className="header-wrapper d-flex  align-items-center justify-content-start mt-1 col">
                        {status == "IN_CORRECT" ?
                            <div className="not-attempted-wrapper">
                                <span className="question-status-text  question-incorrect">
                                    {/* {"YOU GOT IT WRONG"} */}
                                    {"INCORRECT"}
                                </span>
                                <img className="tick-icon ml-2 mb-1" src={getImageSource(ImageConstants.INCORRECT_ANSWER_ICON)} />
                            </div>
                            :
                            status == "CORRECT" ?
                                <div className="not-attempted-wrapper">
                                    <span className="question-status-text question-correct">
                                        {/* {"YOU GOT IT RIGHT"} */}
                                        {"CORRECT"}
                                    </span>
                                    <img className="tick-icon ml-2 mb-1" src={getImageSource(ImageConstants.CORRECT_ANSWER_ICON)} />
                                </div>
                                :
                                <div className="not-attempted-wrapper">
                                    <span className="question-status-text question-not-attempted">
                                        {"NOT ATTEMPTED"}
                                    </span>
                                    <img className="flag-icon ml-2 mb-1" src={getImageSource(ImageConstants.FLAG_ICON)} />
                                </div>
                        }
                    </div>
                    {/* <div className="mark-container col-2 text-center mt-1">
                        <img className="exam-pad-icon" src={getImageSource(ImageConstants.EXAM_PAD_ICON_BLOCK)} />
                        <span className="marks">{status == "CORRECT" ? item.details.content.mark : 0}/{item.details.content.mark}</span>
                    </div> */}
                </div>



                <Question content={item.details} key={item.details._id} 
                answer={item.answer} 
                status={item.status} 
                categoryThemeId={this.categoryThemeId} 
                questionNumber={item.index + 1}>

                </Question>

                {item.details && item.details.content && !item.details.content.isMultiStep ? <AssessmentSolution content={item.details} categoryThemeId={this.categoryThemeId}></AssessmentSolution> : <></>}
                {
                    index != this.state.questions.length - 1 ?
                        <div className="mt-3">
                            <img className="content-separator-size" src={getImageSource(ImageConstants.LEARNING_CONTENT_SEPARATOR)} />
                        </div>
                        :
                        <></>
                }
                {
                    this.state.questions && this.state.questionGroup[this.state.selectedTab] && this.state.questionGroup[this.state.selectedTab].length == this.state.questions.length && index == this.state.questionGroup[this.state.selectedTab].length - 1 ?
                        <div className="d-flex justify-content-center">
                            <div className="done-button cursor-pointer" style={{backgroundColor : this.theme[`themeColor`], borderColor : this.theme[`themeColor`]}} onClick={this.onClose}>Done</div>
                        </div>
                        :
                        <></>
                }
            </div>
        )
    }




    readerHeaderTab() {
        let tabJsx = [];

        tabJsx.push(
            <>
                <div className={`col text-center answer-status-text border-top-left-radius p-3 ${this.state.selectedTab == "ALL" ? '' : ''}`}
                    style={{
                        backgroundColor: `${this.state.selectedTab === "ALL" ? this.theme[`themeColor`] : ''}`
                        , color: `${this.state.selectedTab === "ALL" ? '#FFFFFF' : ''}`
                    }}
                    onClick={() => this.onTabSwitch("ALL")} key={"ALL"}>
                    <div>
                        {"ALL: "}{this.state.questionGroup["ALL"].length}
                    </div>
                </div>
                {this.state.questionGroup["CORRECT"] && this.state.questionGroup["CORRECT"].length ? <span className="verticleLine"></span> : ""}
            </>

        )

        if (this.state.questionGroup["CORRECT"] && this.state.questionGroup["CORRECT"].length) {
            tabJsx.push(
                <>
                    <div className={`col text-center answer-status-text p-3 
                    ${this.state.selectedTab == "CORRECT" ? '' : ''}
                    ${this.state.questionGroup["IN_CORRECT"] && this.state.questionGroup["IN_CORRECT"].length ? "" : 'border-top-right-radius'}`}
                        style={{
                            backgroundColor: `${this.state.selectedTab === "CORRECT" ? this.theme[`themeColor`] : ''}`
                            , color: `${this.state.selectedTab === "CORRECT" ? '#FFFFFF' : ''}`
                        }}
                        onClick={() => this.onTabSwitch("CORRECT")} key={"CORRECT"}>
                        <div>
                            {"CORRECT: "}{this.state.questionGroup["CORRECT"].length}
                        </div>
                    </div>
                    {this.state.questionGroup["IN_CORRECT"] && this.state.questionGroup["IN_CORRECT"].length ? <span className="verticleLine"></span> : ""}
                </>
            )
        }

        if (this.state.questionGroup["IN_CORRECT"] && this.state.questionGroup["IN_CORRECT"].length) {
            tabJsx.push(
                <>
                    <div className={`col text-center answer-status-text p-3 
                ${this.state.selectedTab == "IN_CORRECT" ? '' : ''}
                ${this.state.questionGroup["NOT_ATTEMPTED"] && this.state.questionGroup["NOT_ATTEMPTED"].length ? "" : 'border-top-right-radius'}`}
                        style={{
                            backgroundColor: `${this.state.selectedTab === "IN_CORRECT" ? this.theme[`themeColor`] : ''}`
                            , color: `${this.state.selectedTab === "IN_CORRECT" ? '#FFFFFF' : ''}`
                        }}
                        onClick={() => this.onTabSwitch("IN_CORRECT")} key={"IN_CORRECT"}>
                        <div>
                            {"INCORRECT: "}{this.state.questionGroup["IN_CORRECT"].length}
                        </div>
                    </div>
                    {this.state.questionGroup["NOT_ATTEMPTED"] && this.state.questionGroup["NOT_ATTEMPTED"].length ? <span className="verticleLine"></span> : ""}
                </>

            )

        }

        if (this.state.questionGroup["NOT_ATTEMPTED"] && this.state.questionGroup["NOT_ATTEMPTED"].length) {
            tabJsx.push(
                <div className={`col text-center answer-status-text border-top-right-radius p-3 ${this.state.selectedTab == "NOT_ATTEMPTED" ? '' : ''}`}
                    style={{
                        backgroundColor: `${this.state.selectedTab === "NOT_ATTEMPTED" ? this.theme[`themeColor`] : ''}`
                        , color: `${this.state.selectedTab === "NOT_ATTEMPTED" ? '#FFFFFF' : ''}`
                    }}
                    onClick={() => this.onTabSwitch("NOT_ATTEMPTED")} key={"NOT_ATTEMPTED"}>
                    <div>
                        {"NOT ATTEMPTED: "}{this.state.questionGroup["NOT_ATTEMPTED"].length}
                    </div>
                </div>
            )

        }

        return tabJsx

    }

    renderHeader() {

        return (
            <div>

                <div>
                    {/* <View style={Styles.headerContainer}>
                        <TouchableOpacity onPress={this.onClose} hitSlop={Styles.hitSlop}>
                            <View style={Styles.headerTouchPadding}>
                                <Image source={getImageSource(ImageConstants.CLOSE_LEARNING_POPUP)} />
                            </View>
                        </TouchableOpacity>
                    </View> */}
                    <div className="row row-max-width justify-content-start align-content-center mr-0 ml-0 mt-3">
                        {this.readerHeaderTab()}
                    </div>
                    <div>
                        <QuestionPagination questions={this.state.questionGroup[this.state.selectedTab]} onClickPage={this.onClickPage} categoryThemeId={this.categoryThemeId}></QuestionPagination>
                    </div>
                </div>

            </div >

        )
    }

    getItemLayout = (data, index) => {
        return { length: 50, offset: 50 * index, index }
    }

    onClickPage = (index) => {
        let question = this.state.questions[index].details

        // if (index)
        //     this.scroll.scrollToOffset({ animated: true, offset: this.positionHash[question._id] - 150 });

        let id = question._id

        const element = document.getElementById(id);
        //console.log(id, element)
        element.scrollIntoView({ behavior: "instant", block: 'center' })

    }

    onTabSwitch(status) {

        // if (this.tabQuestionDetails[status] && this.tabQuestionDetails[status].currentPage == 0) {
        //     this.setState({ selectedTab: status }, () => { this.scollEndReached(status) })
        // } else {
        //     this.setState({ selectedTab: status, questions: this.state.renderQuestions[status] })
        // }

        this.setState({ selectedTab: status, questions: this.state.questionsHash[status] })

        // this.scroll.scrollToOffset({ animated: false, offset: 0 });
    }

    goToHomeScreen() {

        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }



    renderBreadcrumb = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 cursor-pointer title-1' onClick={this.onClose}>{`${this.props.metaForViewAnswer.from === "HOMEWORK" ? "My Homeworks" : "My Classwork"}`} {`>`}</span>
                {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                <span className='pl-2 title-4'>{`View Answers`}</span>
            </div>
        )
    }



    render = () => {

        this.theme = SharedThemeCategory.getThemeCategory(this.categoryThemeId);

        //console.log(this.state.questions)


        return (

            !this.state.fullScreenLoading ?
                <div>
                    {
                        this.state.questions && this.state.questions.length ?
                            <div className="my-classwork-result">
                                <div className="d-inline">
                                    {
                                        this.renderBreadcrumb()
                                    }
                                    <img className="image-size" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, this.categoryThemeId)}></img>
                                </div>

                                <div className="header-text">View Answers</div>
                                {
                                    this.state.questions && this.state.questions.length ?
                                        <>{this.renderHeader()}</>
                                        : <></>
                                }
                                <div>
                                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.categoryThemeId)}></img>
                                </div>

                                {
                                    this.state.questions.map((item, index) => (
                                        this.renderItem(item, index)
                                    ))
                                }




                            </div>
                            :

                            <></>
                    }
                </div>

                :
                <Loading />
        )
    }

}
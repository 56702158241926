import React from "react";
import './start-assessment.scss';
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { Constants, FirebaseEvents, ImageConstants } from "../../../constants/constants";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import AssessmentApi from "../../../apis/assessment";
import Loading from "../loading/loading";
import Path from "../../routes/routes-path";
import ChapterListPopup from "../../my-classwork/chapter-list-popup/chapter-list-popup";
import FireBaseEvent from '../../../firebase-events/firebase-events';
import SharedProfile from "../../../../shared-storage/profile";

class StartAssessment extends React.Component {


    constructor(props) {
        super(props)

        this.meta = this.props.meta

        if (!this.meta.selectedChapters) {
            this.chapterKey = this.meta.chapterKey
            this.state.chapterTitle = this.meta.chapterTitle
            this.state.categoryId = this.meta.categoryThemeId
        } else {

            for (let i = 0; i < this.meta.selectedChapters.length; i++) {
                this.chapterKeys.push(this.meta.selectedChapters[i].key)
                this.chapterTitles.push({ title: this.meta.selectedChapters[i].title, categoryThemeId: this.meta.selectedChapters[i].categoryThemeId ? this.meta.selectedChapters[i].categoryThemeId : this.meta.selectedChapters[i].categoryId, key: this.meta.selectedChapters[i].key })
                this.categoryIds.push(this.meta.selectedChapters[i].categoryId)
                this.multipleChapters.push(this.meta.selectedChapters[i].title)
            }

            // this.chapterKey = this.meta.chapterKey;
            this.chapterKey = this.chapterKeys.join(',')
            this.state.chapterTitle = 'Multiple Chapters Assessment';
            this.state.categoryId = '1';
            this.state.selectedDuration = this.meta.duration

            if (this.meta && this.meta.isFromMyHomework) {
                this.state.categoryId = this.meta.categoryThemeId
            }
        }

        this.userInfo = SharedProfile.getProfile();

        // SharedThemeCategory.setThemeCategory(this.state.categoryId)

        if (this.meta.showQuestionSetSelection) {
            this.getChapterAssessmentQuestionSetList()
        } else {
            this.getMyAssessmentInfo()
        }



    }

    chapterKeys = [];
    chapterTitles = [];
    categoryIds = [];
    multipleChapters = []


    state = {
        categoryThemeId: 1,
        questionCount: -1,
        duration: 0,
        showQuestionSetList: false,
        questionSetList: [],
        list: [
            {
                tip: "Please have pen & paper with you before you start the Assessment."
            },
            {
                tip: "You can go back to any attempted Question just by tapping on the Question number in the top bar."
            },
            {
                tip: "You can flag any question and come back to answer it later, before finishing the Assessment."
            }
        ]

    }

    getMyAssessmentInfo(questionSetId) {

        let payload = {}
        this.toggleLoading(true)

        if (this.meta.type == 'EVENT') {
            payload.type = 'EVENT'
            payload.assessmentId = this.meta.assessmentId
            payload.eventId = this.meta.eventId
        } else if (this.meta && this.meta.from == Constants.REFRESHER_COURSE) {
            payload.type = Constants.REFRESHER_COURSE
        } else if (this.meta && this.meta.from == Constants.SCHOLARSHIP_MAY_9_2021) {
            payload.type = Constants.SCHOLARSHIP_MAY_9_2021
        } else if (this.meta.from == 'CLASS_MOCK_TEST') {
            payload.type = this.meta.from
        } else if (this.meta.from == 'SELECTED_CHAPTER_MOCK_TEST' && this.meta.questionSetId) {
            payload.selectedQuestionSetId = this.meta.questionSetId
            payload.type = this.meta.from
        } else if (this.meta.from == 'SCIENCE_CLASS_MOCK_TEST') {
            payload.selectedSubject = 'SCIENCE_CLASS_MOCK_TEST'
            payload.type = 'CLASS_MOCK_TEST'
        } else {
            if (!this.chapterKey)
                return;

            payload = {
                chapterKey: this.chapterKey,
                duration: this.state.selectedDuration
            }

            if (this.meta.showQuestionSetSelection && questionSetId) {
                payload.selectedQuestionSetId = questionSetId
            }

        }

        if (this.meta && this.meta.isFromMyHomework) {
            payload.isFromMyHomework = true;
            payload.homeworkActivityId = this.meta.homeworkActivityId
        }
        console.log(payload)
        AssessmentApi.getMyAssessmentInfo(payload).then((res) => {
            this.setState({
                questionCount: res.noOfQuestion,
                questionSetId: res.questionSetId,
                duration: Math.round(res.time / 60),
                totalMarks: res.totalMarks,
                showQuestionSetList: false,
            })
            this.toggleLoading(false)
        }).catch(err => {
            //console.log(err)
            if (err) {
                this.toggleLoading(false)

                if (err.response && err.response.data && err.response.data.code == 1801) {
                    // this.props.navigation.navigate('EventEndScreen', {
                    //     meta: {
                    //         title: this.meta.chapterTitle,
                    //         categoryThemeId: '1',
                    //         assessmentId: this.meta.assessmentId,
                    //         eventId: this.meta.eventId
                    //     }
                    // })
                }
                return;
            }
        })
    }

    getChapterAssessmentQuestionSetList() {

        let payload = {}
        if (!this.chapterKey)
            return;

        this.toggleLoading(true)

        payload = {
            chapterKey: this.chapterKey,
            duration: this.state.selectedDuration
        }
        AssessmentApi.getChapterAssessmentQuestionSetList(payload).then(res => {
            console.log(res)

            if (res.questionSetList && res.questionSetList.length) {
                this.setState({ showQuestionSetList: true, questionSetList: res.questionSetList, duration: res.duration })
            } else {
                this.setState({ questionCount: 0, duration: 0 })
            }

            this.toggleLoading(false)
        }).catch(err => {
            this.toggleLoading(false)
            console.log(err)
        })
    }

    onPressAction(item) {

        if (item.status === 'COMPLETED') return;

        if (item.status === 'START') {
            this.toggleLoading(true)
            this.getMyAssessmentInfo(item._id)
        }

        if (item.status === 'IN_PROGRESS') {
            item.chapterKey = this.chapterKey
            item.chapterTitle = this.state.chapterTitle
            item.categoryId = this.state.categoryId
            item._id = item.assessmentActivityId
            this.props.delegate && this.props.delegate.onClickCard(item)
        }

    }

    toggleLoading(value) {
        this.setState({ loading: value })
    }


    showMore() {
        let viewMoreText = document.getElementById('show-more')
        let viewTableRows = [...document.getElementsByClassName('hide-list')]

        //console.log(viewMoreText.innerHTML)
        if (viewMoreText.innerHTML == "See More") {
            //console.log('1')
            viewMoreText.innerHTML = "See Less"
            viewTableRows.forEach(element => element.style.display = 'list-item')

        } else {
            //console.log('2')

            viewMoreText.innerHTML = "See More"
            viewTableRows.forEach(element => element.style.display = 'none')
        }
    }

    goBack() {
        this.props.delegate && this.props.delegate.hideStartAssessmentScreen && this.props.delegate.hideStartAssessmentScreen()
    }

    getAssessmentStartingPoint = () => {
        if (this.meta && this.meta.isFromMyHomework) {
            return Constants.SCHOOL_ASSIGNMENT
        }
        if (this.meta && (this.meta.from == Constants.REWARD_EVENT_BASELINE || this.meta.from == Constants.REFRESHER_COURSE || (this.meta.from == Constants.EVENT_BASELINE))) {
            return 'Event Baseline Assessment'
        }

        if (this.meta && (this.meta.from == Constants.SCHOLARSHIP_MAY_9_2021 || this.meta.from == Constants.REWARD_EVENT_FINAL || this.meta.from == Constants.REFRESHER_COURSE || (this.meta.from == Constants.SCHOLARSHIP_EVENT))) {
            return 'Event Finishline Assessment'
        }

        if (this.meta && this.meta.from == Constants.MPL_EVENT) {
            return 'MPL Event'
        }
        return "Chapter Assessment"
    }


    onClickStart = () => {

        if (this.meta && this.meta.from == Constants.REFRESHER_COURSE) {
            FireBaseEvent(FirebaseEvents.DIAGNOSTIC_ASSESSMENT_STARTED,
                {
                    name: this.userInfo.name,
                    phone: this.userInfo.mobileNumber,
                    countryCode: this.userInfo.countryCode,
                    gender: this.userInfo.gender,
                    class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN
                });
            // Facebook.logCustomEvent(FirebaseEvents.DIAGNOSTIC_ASSESSMENT_STARTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
            // BranchIO.logCustomEvent(FirebaseEvents.DIAGNOSTIC_ASSESSMENT_STARTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });

        } else {
            FireBaseEvent(FirebaseEvents.ASSESSMENT_START, {
                'User Id': this.userInfo.userId,
                Status: 'Start',
                name: this.userInfo.name,
                phone: this.userInfo.mobileNumber,
                countryCode: this.userInfo.countryCode,
                gender: this.userInfo.gender,
                'School': this.userInfo.schoolName,
                class: this.userInfo.class,
                plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN,
                chapters: this.meta.selectedChapters ? this.multipleChapters.toString() : this.state.chapterTitle,
                'Starting Point': this.getAssessmentStartingPoint(),
                "Assignment Type": this.meta.homework && this.meta.homework.type === "ASSESSMENT" ? "STANDARD ASSESSMENT" : this.meta.homework && this.meta.homework.type ? this.meta.homework.type.replace('_', " ") : 'Chapter Assessment',
            });
            //     Facebook.logCustomEvent(FirebaseEvents.ASSESSMENT_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
            //     BranchIO.logCustomEvent(FirebaseEvents.ASSESSMENT_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
            //     moengage.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Start', Chapters: this.meta.selectedChapters ? this.multipleChapters.toString() : this.state.chapterTitle, 'Starting Point': this.getAssessmentStartingPoint() })
        }
        this.props.delegate && this.props.delegate.showAssessmentEngine && this.props.delegate.showAssessmentEngine({
            chapterKey: this.chapterKey,
            chapterTitle: this.state.chapterTitle,
            questionSetId: this.state.questionSetId,
            categoryThemeId: this.state.categoryId,
            type: this.meta.type,
            assessmentId: this.meta.assessmentId,
            eventId: this.meta.eventId,
            from: this.meta.from,
            selectedDuration: this.state.selectedDuration,
            isExamPrepKit: this.meta && this.meta.isExamPrepKit ? this.meta.isExamPrepKit : "",
            selectedChapters: this.chapterTitles,
            homeworkChapters: this.meta.homeworkChapters ? this.meta.homeworkChapters : '',
            homework: this.meta.homework,
            isFromMyHomework: this.meta.isFromMyHomework,
            homeworkActivityId: this.meta.homeworkActivityId,
            isDueDatePassed: this.meta && this.meta.isDueDatePassed,
            viewAnswerTime: this.meta && this.meta.viewAnswerTime,
            // from: this.meta.from
        })
    }

    showChapters = () => {
        this.setState({ showChaptersPopup: true })
    }

    hideChapters = () => {
        this.setState({ showChaptersPopup: false })
    }


    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goToDiagnosticAssessmentList() {
        return this.props.delegate?.props.history.replace({ pathname: Path.DIAGNOSTIC_ASSESSMENT_LIST })
    }

    hideMockTest() {
        this.props.delegate && this.props.delegate.hideMockTest && this.props.delegate.hideMockTest()
    }

    goToHome() {
        this.props.delegate && this.props.delegate.goToHome && this.props.delegate.goToHome()
    }


    renderBreadcrumb() {

        if (this.meta.isFromMyHomework) {
            return (
                <div className='breadcrumb-for-classwork mt-2'>
                    <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 cursor-pointer title-1' onClick={() => this.goBack()}>{`${this.meta.isFromMyClasswork ? "School Assignments" : 'My Homeworks'}`} {`>`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                    <span className='pl-2 title-1'>{`Start Assessment`}</span>
                </div>
            )
        }

        if (this.meta.from === Constants.REFRESHER_COURSE) {
            return (
                <div className='breadcrumb-for-classwork mt-2'>
                    <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 cursor-pointer title-1' onClick={() => this.goBack()}>{this.meta.chapterTitle} {`>`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                    <span className='pl-2 title-1'>{`Start Assessment`}</span>
                </div>
            )
        }

        if (this.meta.from === 'CLASS_MOCK_TEST') {
            return (
                <div className='breadcrumb mt-2'>
                    <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 cursor-pointer title-1' onClick={() => this.goToDiagnosticAssessmentList()} >{this.meta.chapterTitle} {`>`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                    <span className='pl-2 title-1'>{`Start Assessment`}</span>
                </div>
            )
        }

        if (this.meta.from === 'SCIENCE_CLASS_MOCK_TEST') {
            return (
                <div className='breadcrumb mt-2'>
                    <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 cursor-pointer title-1' onClick={() => this.goToDiagnosticAssessmentList()}>{this.meta.chapterTitle} {`>`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                    <span className='pl-2 title-1'>{`Start Assessment`}</span>
                </div>
            )
        }

        if (this.meta.from === 'CHAPTER_ACTIVITY') {
            return <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHome()}>{`Home >`}</span>
                <span className='pl-2 cursor-pointer title-1' onClick={() => this.hideMockTest()}>{this.meta.chapterTitle} {`>`}</span>
                {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                <span className='pl-2 title-1'>{`Mock Test List`}</span>
            </div>
        }

        return (

            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 cursor-pointer title-1' onClick={() => this.goBack()}>{`Mock Test`} {`>`}</span>
                {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                <span className='pl-2 title-1'>{`Start Mock Test`}</span>
            </div>

        )
    }


    getAssessmentDetailCard() {
        return (
            <div className="d-flex justify-content-center align-items-center mt-2">
                <div className="row justify-content-between align-items-center assessment-detail-card pl-2 pr-2">
                    <div className="col d-flex justify-content-center">
                        <div>
                            <div className="text-center">Duration</div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div><img className="clock-image-size mr-2" src={getImageSource(ImageConstants.CLOCK_IMAGE)} /></div>
                                <div className="min-text">{(this.meta.selectedChapters && this.meta.selectedChapters.length > 1) ? this.state.selectedDuration : this.state.duration} min</div>
                            </div>
                        </div>
                    </div>
                    <div className="line-wrapper"><img className="vertical-line" src={getImageSource(ImageConstants.VERTICAL_LINE)} /></div>
                    <div className="col-3 d-flex justify-content-center">
                        <div>
                            <div className="text-center">Marks</div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div><img className="clock-image-size mr-2" src={getImageSource(ImageConstants.EXAM_PAD_ICON_BLOCK)} /></div>
                                <div className="min-text">{this.state.totalMarks}</div>
                            </div>
                        </div>

                    </div>
                    <div className="line-wrapper"><img className="vertical-line" src={getImageSource(ImageConstants.VERTICAL_LINE)} /></div>
                    <div className="col d-flex justify-content-center">
                        <div className="">
                            <div className="text-center">Questions</div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div><img className="clock-image-size mr-2" src={getImageSource(ImageConstants.QUESTION_MARK)} /></div>
                                <div className="min-text">{this.state.questionCount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getButton(item) {
        if (item.status === 'START') {
            return 'Start'
        }
        if (item.status === 'IN_PROGRESS') {
            return 'Resume'
        }
        if (item.status === 'COMPLETED') {
            return 'Completed'
        }
    }

    showCompletedAssessments() {
        this.props.delegate && this.props.delegate.showCompletedAssessments && this.props.delegate.showCompletedAssessments()
    }

    renderQuestionSetList() {
        return (
            <div>
                <div className="mock-test-header">Select a Mock Test</div>
                {
                    this.state.questionSetList.map((item, index) => (
                        <div onClick={() => this.onPressAction(item)}
                            className='d-flex align-items-center justify-content-between chapter-card pl-0 pt-3 pb-3 cursor-pointer'>
                            <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <div className='chapter-title'>Mock Test {index + 1} {this.state.duration ? <span className="mins-text">({this.state.duration}mins)</span> : <></>}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="take-challenge text-center">{this.getButton(item)}</div>
                            </div>
                            {/* <div>
                                <img className='arrow-image-size' src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                            </div> */}
                        </div>
                    ))
                }
            </div>
        )
    }



    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.state.categoryId)

        return (
            <div className="start-assessment container-fluid p-0">
                <div className="text-right">
                    <img className="image-size" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, this.state.categoryId)}></img>
                </div>
                <div className="header-for-practice-question">
                    {this.renderBreadcrumb()}
                    {/* <div>
                        <img className="chapter-icon mr-1" src={this.state.selectedChapter.chapterImage ? this.state.selectedChapter.chapterImage : getImageSource(ImageConstants.CHAPTER_ICN)} />
                        <span className="chapter-title">{this.state.selectedChapter.title}</span>
                    </div> */}

                    {
                        this.meta.from === 'CHAPTER_ACTIVITY' ?
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="sub-header-text">Mock Test</div>
                                <div className="mr-5">
                                    <div className="insights-button cursor-pointer" onClick={() => this.showCompletedAssessments()}>My Mock Test</div>
                                </div>
                            </div>
                            :
                            <div className="sub-header-text">{this.meta.from == 'CLASS_MOCK_TEST' || this.meta.from == 'SCIENCE_CLASS_MOCK_TEST' ? this.meta.chapterTitle : 'Mock Test'}</div>
                    }
                </div>
                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.state.categoryId)}></img>
                </div>

                {this.state.loading && <Loading />}

                {
                    this.state.questionCount > 0 ?
                        <>
                            <div className="row mr-0">
                                <div className="col text-center"><img className="desktop-image-size" src={getImageSource(ImageConstants.MALE_WITH_DESKTOP_ICON)} /></div>
                            </div>


                            {
                                this.meta.selectedChapters && this.meta.selectedChapters.length > 1 ?
                                    <div className="row mr-0 mt-2" >
                                        <div className="col text-center chapter-title cursor-pointer" onClick={this.showChapters}>{this.state.chapterTitle} <img className="arrow-size  ml-2" src={getImageSource(ImageConstants.RIGHT_ARROW)} /></div>
                                    </div>
                                    :
                                    <div className="row mr-0 mt-2">
                                        <div className="col text-center chapter-title">{this.state.chapterTitle}</div>
                                    </div>
                            }



                            {this.getAssessmentDetailCard()}
                            <div className="pro-tips mt-3 p-3">
                                <div><img className="bulb-image-size" src={getImageSource(ImageConstants.BULB_IMAGE)} /> <span className="pro-title">Pro tips:</span></div>

                                <ul>
                                    {
                                        this.state.list.map((tip, index) => (
                                            <li className={`tips-text ${index >= 1 ? 'hide-list' : ''}`}>{tip.tip}</li>
                                        ))
                                    }
                                </ul>

                                {
                                    this.state.list.length > 1 ?
                                        <>
                                            {
                                                this.state.list.map((tip, index) => (
                                                    <div className="cursor-pointer" onClick={() => this.showMore()}>
                                                        {index === 1 ? <div id='show-more'>{`See More`}</div> : ''}
                                                    </div>
                                                ))
                                            }
                                        </>

                                        :

                                        ''
                                }

                            </div>

                            <div className="d-flex justify-content-center">
                                {/* <div className="text-center back-button cursor-pointer mr-5"
                                    style={{ color: this.theme ? `${this.theme[`themeColor`]}` : '', border: `2px solid ${this.theme[`themeColor`]}` }}
                                    onClick={() => this.goBack()}>Back</div> */}

                                <div className="text-center continue-button cursor-pointer"
                                    style={{ backgroundColor: this.theme ? `${this.theme[`themeColor`]}` : '' }}
                                    onClick={() => this.onClickStart()}>
                                    Start
                                </div>
                            </div>
                        </>

                        : ''
                }

                {!this.state.loading && this.state.questionCount === 0 ?
                    <div className="text-center">Sorry, No Assessments available right now</div> : <></>}

                {
                    !this.state.loading && this.state.showQuestionSetList ?
                        <>{this.renderQuestionSetList()}</>
                        :
                        <></>
                }


                {this.state.showChaptersPopup && <ChapterListPopup modal={true} close={this.hideChapters} delegate={this} chapters={this.chapterTitles} categoryThemeId={this.state.categoryId} />}
            </div>
        )
    }
}


export default StartAssessment;
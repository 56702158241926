import React from 'react';
import './cheat-sheet.scss';
import SharedProfile from '../../../../shared-storage/profile';
import { getImageSource } from '../../../utility/image-source';
import { ImageConstants } from '../../../constants/constants';
import Loading from '../../common/loading/loading';
import ExamPrepKitApi from '../../../apis/exam-prep-kit';
// import PDFViewer from 'pdf-viewer-reactjs';


export default class CheatSheet extends React.Component {
    constructor(props) {
        super(props);
        // const { navigation } = this.props;
        // this.meta = navigation.getParam('meta');
        this.meta = this.props.metaInfo
        this.state = {
            url: "",
            loading: true
        };
        this.userInfo = SharedProfile.getProfile();

        // this.props.navigation.addListener('didFocus', (data) => {
        this.getCheatSheet()


        // if (this.meta && this.meta.isDeeplink)
        //     return;

        this.updateCheatSheetCompleted()
        // });
    }

    getCheatSheet = () => {


        let payload = {
            chapterKey: this.meta.chapterKey
        }

        this.setState({ loading: true })

        ExamPrepKitApi.getCheatSheet(payload).then((response) => {

            this.setState({ loading: false })

            if (!response || !response.url) {
                this.setState({ loading: false })
                this.goBack()
                return
            }

            if (this.meta && this.meta.isDeeplink) {
                this.meta.title = response.title;
                this.meta.categoryThemeId = response.categoryThemeId
            }

            window.open(response.url)

            this.setState({ url: response.url })
            this.setState({ loading: false })

        }).catch(err => {
            console.log('err')
            if (err) {
                this.setState({ loading: false })
                this.goBack()
                return
            }
        })
    }

    updateCheatSheetCompleted = () => {

        let payload = {
            chapterKey: this.meta.chapterKey,
            type: "CHEAT_SHEET"
        }

        ExamPrepKitApi.updatePrepKitProgress(payload).then((response) => {
            this.goBack()
        }).catch(err => {
            console.log(err)
            if (err) {
                return
            }
        })
    }

    goBack = () => {
        // this.props.navigation.goBack();
        this.setState({ loading: true })
        this.props.delegate && this.props.delegate.hideCheatSheet && this.props.delegate.hideCheatSheet()
        this.setState({ loading: false })
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    // async downloadFile(url) {

    //     this.setState({ fullScreenSpinner: true })

    //     let dirs = RNFetchBlob.fs.dirs;

    //     let config = {}

    //     if (Platform.OS == 'ios') {
    //         config = {
    //             path: dirs.DocumentDir + '/certificate.pdf',
    //         }
    //     } else {
    //         try {
    //             const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE);
    //             if (granted !== PermissionsAndroid.RESULTS.GRANTED) {
    //                 this.setState({ fullScreenSpinner: false })
    //                 return
    //             }
    //         } catch (err) {
    //             this.setState({ fullScreenSpinner: false })
    //             return
    //         }

    //         config = {
    //             addAndroidDownloads: {
    //                 useDownloadManager: true,
    //                 notification: true,
    //                 mime: 'application/pdf',
    //                 path: `${dirs.DownloadDir}/cheat-sheet.pdf`
    //                 // description: 'File downloaded by download manager.'
    //             }
    //         }
    //     }
    //     RNFetchBlob
    //         .config(config)
    //         .fetch('GET', url, {

    //         })
    //         .then((res) => {
    //             this.setState({ fullScreenSpinner: false })
    //             let status = res.info().status;
    //             if (status == 200 && Platform.OS == 'ios') {
    //                 RNFetchBlob.ios.previewDocument(config.path);
    //             }
    //         })
    //         // Something went wrong:
    //         .catch((errorMessage, statusCode) => {
    //             // error handling
    //             this.setState({ fullScreenSpinner: false })
    //         })
    // }


    // sharePDFWithIOS(fileUrl, type) {

    //     this.setState({ fullScreenSpinner: true })

    //     let dirs = RNFetchBlob.fs.dirs

    //     let filePath = null;
    //     let file_url_length = fileUrl.length;
    //     const configOptions = {
    //         fileCache: true,
    //         path:
    //             dirs.DocumentDir + '/cheat-sheet.pdf'  // no difference when using jpeg / jpg / png /
    //     };
    //     RNFetchBlob.config(configOptions)
    //         .fetch('GET', fileUrl)
    //         .then(async resp => {
    //             filePath = resp.path();
    //             let options = {
    //                 type: type,
    //                 url: filePath // (Platform.OS === 'android' ? 'file://' + filePath)
    //             };

    //             this.setState({ fullScreenSpinner: false })
    //             await Share.open(options);
    //         })
    //         .catch((err) => {
    //             this.setState({ fullScreenSpinner: false })
    //         })
    // }

    // sharePDFWithAndroid(fileUrl, type) {

    //     this.setState({ fullScreenSpinner: true })

    //     let filePath = null;
    //     let file_url_length = fileUrl.length;
    //     const configOptions = { fileCache: true };
    //     RNFetchBlob.config(configOptions)
    //         .fetch('GET', fileUrl)
    //         .then(resp => {
    //             filePath = resp.path();
    //             return resp.readFile('base64');
    //         })
    //         .then(async base64Data => {
    //             this.setState({ fullScreenSpinner: false })
    //             base64Data = `data:${type};base64,` + base64Data;
    //             await Share.open({ url: base64Data });
    //         })
    //         .catch((err) => {
    //             this.setState({ fullScreenSpinner: false })
    //         })
    // }

    onPressShare = () => {

        if (!this.state.url)
            return;

        // if (Platform.OS == 'ios') {
        //     this.sharePDFWithIOS(this.state.url, 'application/pdf')
        // } else {
        //     this.sharePDFWithAndroid(this.state.url, 'application/pdf')
        // }
    }


    onPressDownload = () => {

        if (!this.state.url)
            return;

        this.downloadFile(this.state.url)
    }

    onClickLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu', { meta: { from: 'PREPKIT' } })
    }

    next = () => {
        this.goBack()
    }


    render() {

        return (
            <>
                {
                    !this.state.loading ?
                        <div>...</div>
                        :
                        <Loading />
                }
            </>

        )
    }

}

{/* <View>
                        <ScrollView showsVerticalScrollIndicator={false} scrollEventThrottle={16}
                            stickyHeaderIndices={[0]}
                            onScroll={({ nativeEvent }) => {
                                const offset = nativeEvent.contentOffset.y;
                                if (offset > 2 && !this.state.showShadow) {
                                    this.toggleShadow();
                                } else if (offset < 2 && this.state.showShadow) {
                                    this.toggleShadow();
                                }
                            }}
                        >

                            <CheatSheetHeader chapterTitle={this.meta.title} isScrolled={this.state.showShadow} categoryId={this.meta.categoryThemeId} delegate={this} />

                            <View style={Styles.contentContainer}>
                                <ScrollView>

                                    {this.state.showActions ? <View style={Styles.iconWrapper}>
                                        <TouchableOpacity onPress={this.onPressDownload}><Image source={getImageSource(ImageConstants.DOWNLOAD_ICON)}></Image></TouchableOpacity>
                                        <TouchableOpacity onPress={this.onPressShare}><Image source={getImageSource(ImageConstants.SHARE)} style={Styles.shareIcon}></Image></TouchableOpacity>
                                    </View> : <></>}

                                    <View style={Styles.cetificateContainer}>
                                        <Pdf
                                            source={{ uri: this.state.url }}
                                            style={Styles.pdfViewer}
                                            onLoadComplete={() => { this.setState({ showActions: true }) }}
                                            onError={(err) => { console.log(err) }}
                                        />
                                    </View>
                                </ScrollView>
                            </View>

                            <ExamPrepKitFooter categoryThemeId={this.meta.categoryThemeId} delegate={this}></ExamPrepKitFooter>

                        </ScrollView>

                    </View> */}

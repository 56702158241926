import React from "react";
import { Modal, ModalBody } from "reactstrap";
import './city-modal.scss';
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import SharedStaticText from "../../../../shared-storage/static-text-store";
import CommonApi from "../../../apis/common";

class CityModal extends React.Component {


    state = {
        cities: []
    }

    constructor(props) {
        super(props)
        this.staticText = SharedStaticText.getStaticText('countryCodes');
        // this.getCities()
    }

    onChangeText = (event) => {

        this.setState({
            searchText: event.target.value
        })

        let text = event.target.value.toLowerCase();

        if (text && text.length > 2) {
            this.getCities(text)
        } else {
            this.setState({ cities: [], city: event.target.value, enableSaveButton: false})
        }
    }



    getCities = (searchText) => {
        let payload = {
            limit: 100
        }

        if (this.props.countryCode) {
            payload['countryCode'] = encodeURIComponent(this.props.countryCode)
        }


        if (this.props.country) {
            payload['country'] = this.props.country
        }

        if (searchText) {
            payload.name = `/${searchText}`
        }

        CommonApi.getCities(payload).then((response) => {
            this.setState({ cities: response.response, city: searchText })
        }).catch(err => {
            //console.log(err)
        })
    }

    onSelectCity = (item) => {

        if (this.props.showSaveButton) {
            this.setState({ city: `${item.name}, ${item.state}, ${item.country}`, enableSaveButton: true })
            this.props.delegate && this.props.delegate.onChangeCity(item)
            // this.props.delegate && this.props.delegate.closeCityModal();
        } else {
            this.props.delegate && this.props.delegate.onChangeCity(item)
            this.props.delegate && this.props.delegate.closeCityModal();
        }

    }

    closeModal = () => {
        this.props.delegate && this.props.delegate.closeCityModal();
    }

    updateProfile = () => {
        this.props.delegate && this.props.delegate.updateProfile && this.props.delegate.updateProfile()
    }


    render() {
        return (
            <Modal className="cw-common-custom-modal-for-city" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                    <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.closeModal()} />
                </div>
                <ModalBody>
                    <div className="pratice-container">
                        <div className="select-country">Search and Select Your City</div>
                        <div className="country-search-div">
                            <img className="country-search" src={getImageSource(ImageConstants.COUNTRY_CODE_SEARCH)}></img>
                        </div>
                        <div className="search">
                            <input className="form-control country-search" placeholder={`Search (Type Minimum 3 letters)`} 
                            value={this.state.city} 
                            onChange={(event) => { this.onChangeText(event) }} />
                        </div>
                        <div className="p-2 dropdown-scroll">
                            {this.state.cities.length ?
                                this.state.cities.map(item => (
                                    <div className="dropdown-list cursor-pointer px-3 py-1" onClick={() => { this.onSelectCity(item) }}>
                                        <div className="country-name cursor-pointer">
                                            <div className="">
                                                {item.name}, {item.state}, {item.country}
                                            </div>
                                        </div>
                                    </div>

                                ))
                                : <div className="text-center py-4 no-data">
                                    <label> Search Your City</label>
                                </div>
                            }
                        </div>
                        {
                            !this.state.enableSaveButton ?
                                <div className="d-flex justify-content-center mt-5">
                                    <div className="active-button save-button-disabled d-flex justify-content-center">
                                        SAVE
                                    </div>
                                </div>

                                :

                                <div className="d-flex justify-content-center mt-5">
                                    <div className="active-button d-flex justify-content-center cursor-pointer" onClick={this.updateProfile}>
                                        SAVE
                                    </div>
                                </div>
                        }

                    </div>

                </ModalBody>
                <div className="position">
                    <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                </div>
            </Modal>
        )
    }

}

export default CityModal;
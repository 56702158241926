import React from "react";
import './assessment-end-screen.scss';
import SharedProfile from "../../../../shared-storage/profile";
import SharedStaticText from "../../../../shared-storage/static-text-store";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import CwConfirmationModal from "../../common/cw-confirmation-modal/cw-confirmation-modal";
import Path from "../../routes/routes-path";
import ViewAnswerTimer from "../../my-classwork/view-answer-timer-popup-for-classwork/view-answer-timer";
import ChapterListPopup from "../../my-classwork/chapter-list-popup/chapter-list-popup";
import ClassMockTestPerformanceReport from "../../class-mock-test-performance-report/class-mock-test-performance-report";



export default class AssessmentEndScreen extends React.Component {
    constructor(props) {
        super(props);

        //console.log(this.props)



        // this.state.data = {
        //     "correct": 1,
        //     "inCorrect": 1,
        //     "notAttempted": 0,
        //     "totalMark": 2,
        //     "markScored": 1,
        //     "timeTaken": 9,
        //     "marksArray": [
        //         {
        //             "marks": "1",
        //             "count": 1
        //         }
        //     ],
        //     "percentage": 100,
        //     "predictedScore": 45,
        //     "skillWisePercentage": {
        //         "": 50
        //     }
        // }

        this.meta = this.props.metaInfo

        console.log(this.props.metaInfo)

        this.state.data = this.meta.data;
        this.chapterTitle = this.meta.chapterTitle;
        this.categoryThemeId = this.meta.categoryThemeId;
        this.assessmentActivityId = this.meta.assessmentActivityId;
        this.chapterKey = this.meta.chapterKey;
        this.multipleChapters = []

        // this.getChapterList();

        this.userInfo = SharedProfile.getProfile()

        this.staticText = SharedStaticText.getStaticText('AssessmentEndScreen');

        // if (!this.userInfo.prepkitGuaranteeAssessmentActivity || (this.userInfo.prepkitGuaranteeAssessmentActivity && this.userInfo.prepkitGuaranteeAssessmentActivity && this.userInfo.prepkitGuaranteeAssessmentActivity && this.userInfo.prepkitGuaranteeAssessmentActivity.class != this.userInfo.class)) {
        //    this.state.showPredictor = true
        // }
        this.theme = SharedThemeCategory.getThemeCategory(this.categoryThemeId)
    }

    state = {
        data: {
            markScored: '',
            notAttempted: '',
            timeTaken: '',
            correct: '',
            inCorrect: '',
            totalMark: '',

        },
        showPredictor: false,
        rangeText: ''
    }

    componentDidMount() {
        // this.props.navigation.addListener("didFocus", () => {

        //     BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });

        // this.props.navigation.addListener("didBlur", () => {

        //     BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });
        // moengage.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Completed', Chapters: this.meta.selectedChapters && this.meta.selectedChapters.length ? this.multipleChapters.toString() : this.chapterTitle, "Marks Scored": this.state.data.markScored, "Total Marks": this.state.data.totalMark, "Starting Point": this.getAssessmentStartingPoint() })
        // FireBaseEvent.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Completed', Chapters: this.meta.selectedChapters && this.meta.selectedChapters.length ? this.multipleChapters.toString() : this.chapterTitle, "Marks Scored": this.state.data.markScored, "Total Marks": this.state.data.totalMark, "Starting Point": this.getAssessmentStartingPoint() })
        // Facebook.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Completed', Chapters: this.meta.selectedChapters && this.meta.selectedChapters.length ? this.multipleChapters.toString() : this.chapterTitle, "Marks Scored": this.state.data.markScored, "Total Marks": this.state.data.totalMark, "Starting Point": this.getAssessmentStartingPoint() })
        // BranchIO.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Completed', Chapters: this.meta.selectedChapters && this.meta.selectedChapters.length ? this.multipleChapters.toString() : this.chapterTitle, "Marks Scored": `${this.state.data.markScored}`, "Total Marks": `${this.state.data.totalMark}`, "Starting Point": this.getAssessmentStartingPoint() })
    }

    // getUserExamPrepkitData = () => {
    //     let payload = {}

    //     Api.getUserExamPrepkitData(payload, (err, res) => {

    //         if (err) {
    //             return;
    //         }

    //         if (res && res.length) {
    //             let userExamPrepkitData = res[0]

    //             this.setState({userExamPrepkitData})

    //         }




    //     })

    // }


    handleBackButtonClick = () => {

        if (this.meta && this.meta.isFromMyHomework) {
            this.props.navigation.navigate('MyHomeworks')
            return
        }

        if (this.meta && this.meta.isExamPrepKit) {
            this.props.navigation.navigate('ExamPrepKitTableOfContent', {
                meta: {
                    chapterTitle: this.chapterTitle,
                    chapterKey: this.chapterKey,
                    categoryThemeId: this.categoryThemeId,
                    from: 'CHAPTERS_LIST'
                }
            });
            return;
        }
        this.props.navigation.navigate('MyAssessments')
        return true
    }



    closeViewAnswerPopup = () => {
        this.setState({ showViewAnswerPopUp: false })
    }

    getAssessmentStartingPoint = () => {
        // if (this.meta && this.meta.isFromMyHomework) {
        //     return 'Homework'
        // }
        // if (this.meta && (this.meta.from == constants.REWARD_EVENT_BASELINE || this.meta.from == constants.REFRESHER_COURSE || (this.meta.from == constants.EVENT_BASELINE))) {
        //     return 'Event Baseline Assessment'
        // }

        // if (this.meta && (this.meta.from == constants.SCHOLARSHIP_MAY_9_2021 || this.meta.from == constants.REWARD_EVENT_FINAL || this.meta.from == constants.REFRESHER_COURSE || (this.meta.from == constants.SCHOLARSHIP_EVENT))) {
        //     return 'Event Finishline Assessment'
        // }

        // if (this.meta && this.meta.from == constants.MPL_EVENT ) {
        //     return 'MPL Event'
        // }
        return "Chapter Assessment"
    }


    toggleLateralMenu = () => {

        this.props.navigation.navigate('LateralMenu')
    }

    onPressCheckYourRank = () => {
        this.props.navigation.navigate('LeaderBoard', { meta: { eventId: this.meta.eventId } })
    }

    hideChaptersPopup = () => {
        this.setState({ showChaptersPopup: false })
    }

    showChaptersPopup = () => {
        this.setState({ showChaptersPopup: true })
    }

    // getChapterList = () => {

    //     if (this.meta.selectedChapters && this.meta.selectedChapters.length) {
    //         return
    //     } else if (this.meta.chapterKey) {


    //         let payload = {}


    //         Api.getChapterList(payload, (err, res) => {

    //             if (err) {
    //                 this.setState({ loading: false })
    //                 return;
    //             }

    //             let allChapters = res.items

    //             let chapters = res.items.filter(chapter => chapter.key == this.meta.chapterKey)

    //             this.setState({
    //                 allChapters,
    //                 chapter: chapters[0],
    //                 masterDataId: res.masterDataId,
    //                 overallProgress: res.overallProgress,
    //                 categories: res.categories,
    //                 loading: false
    //             })

    //             this.separateChapters(this.state.chapters);
    //         })
    //     }
    // }

    getBorderColor = () => {


        if (this.state.data.percentage >= 0 && this.state.data.percentage <= 30) {
            return '#E96A6A'
        }

        if (this.state.data.percentage >= 31 && this.state.data.percentage <= 70) {
            return '#EEC865'
        }

        if (this.state.data.percentage >= 71 && this.state.data.percentage <= 100) {
            // return '#87CA9E'

            return '#0AC6D6'
        }

    }

    onPressContinue = () => {
        if (this.state.showPredictor) {
            let isTrial = this.userInfo.isPrepkitFreeUser

            let percentage = this.state.data.percentage
            let predictedScore = this.state.predictedScore


            // if (this.state.userExamPrepkitData && this.state.userExamPrepkitData.chapters && this.state.userExamPrepkitData.chapters.length && this.state.userExamPrepkitData.examDate) {
            //     this.props.navigation.navigate('PrepKitChapterList', { meta: { title: "Circles" } });

            // } else if (this.state.userExamPrepkitData && (!this.state.userExamPrepkitData.chapters || !this.state.userExamPrepkitData.chapters.length) && this.state.userExamPrepkitData.examDate) {
            //     this.props.navigation.navigate('ExamPrepKitChapterSelectionScreen')

            // }
            // // else if (this.state.userExamPrepkitData && this.state.userExamPrepkitData.chapters && this.state.userExamPrepkitData.chapters.length && !this.state.userExamPrepkitData.examDate) {
            // //     this.props.navigation.navigate('ExamDateCaptureScreen')
            // // }
            // else {

            this.props.navigation.navigate('ExamPrepKitIntroScreen', { meta: { from: "LANDING_SCREEN", showGuarantee: isTrial ? true : false, guaranteedScore: 10, percentage: percentage ? percentage : 5 } });
            // }



        } else {

            let revisionChapterKeys = []

            if (this.meta.selectedChapters && this.meta.selectedChapters.length) {
                for (let i = 0; i < this.meta.selectedChapters.length; i++) {
                    revisionChapterKeys.push(this.meta.selectedChapters[i].key)
                }

                this.props.navigation.navigate('RevisionChaptersList', { meta: { revisionChapterKeys: revisionChapterKeys } });
            } else if (this.meta.chapterKey) {

                let chapter = this.state.chapter

                //console.log(chapter)

                // if (chapter.status == constants.IS_CHAPTER_NOT_STARTED) {  
                //     this.toggleChapterCompletedModal(true, chapter.title, chapter.key, chapter.categoryId, chapter.lockedForTrial, chapter)   
                // } else {
                //     this.navigate(chapter.key,chapter.title,chapter.status == constants.IS_CHAPTER_NOT_COMPLETED ? true : false,chapter.categoryId,chapter.lockedForTrial,chapter)
                // }


            }

        }
    }


    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goToClassworkListScreen() {
        return this.props.delegate && this.props.delegate.hideAssessmentEndScreen && this.props.delegate.hideAssessmentEndScreen()
    }

    goToDiagnosticAssessmentList() {
        return this.props.delegate?.props.history.replace({ pathname: Path.DIAGNOSTIC_ASSESSMENT_LIST })
    }



    renderBreadcrumb = () => {

        if (this.meta && this.meta.from === "CLASS_MOCK_TEST") {
            return (
                <div className='breadcrumb mt-2'>
                    <span className="home cursor-pointer title-1" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToDiagnosticAssessmentList()}>{`Maths Diagnostic Assessment`} {`>`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                    <span className='pl-2 title-1'>{`Assessment Completed`}</span>
                </div>
            )
        } 

        if (this.meta && this.meta.from === "SCIENCE_CLASS_MOCK_TEST") {
            return (
                <div className='breadcrumb mt-2'>
                    <span className="home cursor-pointer title-1" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                    <span className='pl-2 title-1 cursor-pointer' onClick={() => this.goToDiagnosticAssessmentList()}>{`Science Diagnostic Assessment`} {`>`}</span>
                    {/* <span className='pl-2 title-2'>{`Start Assessment`} {`>`}</span>
                    <span className='pl-2 title-3'>{`Assessment Completed`} {`>`}</span> */}
                    <span className='pl-2 title-1'>{`Assessment Completed`}</span>
                </div>
            )
        } 
    }

    navigateToViewAnswer = () => {


        if (this.meta && this.meta.isFromMyHomework && !this.meta.isDueDatePassed && this.meta.viewAnswerTime) {
            // this.setState({ showViewAnswerPopUp: true, viewAnswerTime: this.meta.viewAnswerTime })
            this.props.delegate && this.props.delegate.showViewAnswerTimerScreen &&
                this.props.delegate.showViewAnswerTimerScreen(
                    {
                        viewAnswerTime: this.meta.viewAnswerTime,
                        categoryThemeId: this.categoryThemeId,
                        from: this.meta && this.meta.from == 'HOMEWORK' ? 'HOMEWORK' : 'CLASSWORK'
                    }
                )
            return
        }

        // this.props.navigation.navigate('MyAssessmentResult', {
        //     meta: {
        //         categoryThemeId: this.categoryThemeId,
        //         chapterTitle: this.chapterTitle,
        //         chapterKey: this.chapterKey,
        //         assessmentActivityId: this.assessmentActivityId,
        //         fromAssess: true,
        //         type: this.meta.type,
        //         from: this.meta && this.meta.isFromMyHomework ? 'HOMEWORK' : this.meta.from,
        //         isExamPrepKit: this.meta && this.meta.isExamPrepKit ? this.meta.isExamPrepKit : ""
        //     }
        // })
        this.props.showViewAnswerScreen && this.props.showViewAnswerScreen({
            meta: {
                categoryThemeId: this.categoryThemeId,
                chapterTitle: this.chapterTitle,
                chapterKey: this.chapterKey,
                assessmentActivityId: this.assessmentActivityId,
                fromAssess: true,
                type: this.meta.type,
                from: this.meta && this.meta.from
            }
        })
    }

    showInsightsModal = () => {
        this.setState({ showInsights: true })
    }

    hideInsightsModal() {
        this.setState({ showInsights: false })
    }

    showPerformanceReport = () => {
        this.setState({showPerformanceReport : true})
    }

    hidePerformanceReport = () => {
        this.setState({showPerformanceReport : false})
    }




    render() {


        if(this.state.showPerformanceReport) {
            return(
                <ClassMockTestPerformanceReport 
                meta = {{assessmentActivityId : this.assessmentActivityId, from : this.meta.from , selectedMockTestTitle : 'Assessment'}} 
                delegate = {this} />
            )
        }




        return (
            <div className="assessment-end-screen">
                {this.renderBreadcrumb()}
                <img className="screen-bg" src={getImageSource(ImageConstants.ASSESSMENT_END_SCREEN_BG)} />
                <div className="text-center header-text">Assessment Completed!</div>
                <div>
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.categoryThemeId)}></img>
                </div>
                {this.meta.isFromMyHomework && this.meta.selectedChapters && this.meta.selectedChapters.length == 1 ? <div className="chapter-title mt-2">{this.chapterTitle}</div> : <>
                    {this.meta && this.meta.selectedChapters && this.meta.selectedChapters.length ?
                        <div className="chapter-title mt-2 cursor-pointer" onClick={this.showChaptersPopup}>{`Multiple Chapters Assessment`}
                            <img className="arrow-size  ml-2" src={getImageSource(ImageConstants.RIGHT_ARROW)} /></div>
                        :
                        <div className="chapter-title mt-2">{this.chapterTitle}</div>
                    }
                </>}

                <div className="look-text mt-1">{this.staticText.viewScoreText ? this.staticText.viewScoreText : "Let's look at your score"}</div>
                <div className="row ml-0 mr-0">
                    <div className="col mt-4 ml-5">

                        <img className="marks-card-bg" src={getThemeImageSource(ImageConstants.MARKS_CARD_BG, this.categoryThemeId)} />
                        <div className="marks-card" style={{ borderColor: this.theme[`themeColor`] }}>
                            <div className="d-flex flex-row justify-content-around mt-5 ml-3">
                                <div className="mark-scored">{this.state.data.markScored}/{this.state.data.totalMark}</div>
                                <div className="time-taken">{Math.ceil( this.state.data.timeTaken / 60)} {this.staticText.minutesText ? this.staticText.minutesText : "mins"}</div>
                            </div>
                            <div className="d-flex flex-row justify-content-around mt-4">
                                <div className=" d-flex justify-content-center align-items-center">
                                    <div>
                                        <img className="exam-pad-icon" src={getImageSource(ImageConstants.EXAM_PAD_ICON_GRAY)} />
                                    </div>
                                    <div className="text-center">
                                        <span className="mark-scored-text">{this.staticText.marksScoredText ? this.staticText.marksScoredText : 'Marks Scored'}</span>
                                    </div>
                                </div>
                                <div className=" d-flex justify-content-center align-items-center"><div>
                                    <img className="exam-pad-icon" src={getImageSource(ImageConstants.CLOCK_ICON_GRAY)} />
                                </div>
                                    <div className="text-center">
                                        <span className="mark-scored-text">{this.staticText.timeTakenText ? this.staticText.timeTakenText : "Time Taken"}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-5">
                                {/* <div className="row"> */}
                                <div className=" correct-bg p-2 pl-3 pr-3">{this.staticText.correctText ? this.staticText.correctText : "Correct:"} {this.state.data.correct}</div>
                                <div className=" incorrect-bg p-2 pl-3 pr-3 ml-5">{this.staticText.inCorrectText ? this.staticText.inCorrectText : "Incorrect:"} {this.state.data.inCorrect}</div>
                                <div className=" not-answered-bg p-2 pl-3 pr-3 ml-5">{this.staticText.notAnsweredText ? this.staticText.notAnsweredText : "Not Answered:"} {this.state.data.notAttempted}</div>
                                {/* </div> */}
                            </div>
                        </div>
                        <div className="d-flex justify-content-center button-wrapper">
                            <div className="d-flex align-item-center justify-content-center view-answers-button cursor-pointer mr-5" style={{ backgroundColor: this.theme[`themeColor`] }}
                                onClick={this.navigateToViewAnswer} >
                                {this.staticText.viewAnswerButtonTitle ? this.staticText.viewAnswerButtonTitle : "VIEW ANSWERS"}
                            </div>
                            <div className="d-flex align-item-center justify-content-center insights-button cursor-pointer" style={{ color: this.theme['themeColor'], borderColor: this.theme['themeColor'] }}
                                onClick={this.showPerformanceReport} >
                                View Report
                            </div>
                            {/* <div className="text-center insights-button cursor-pointer" style={{ color: this.theme['themeColor'], borderColor: this.theme['themeColor'] }}
                                onClick={this.showInsightsModal} >
                                INSIGHTS
                            </div> */}
                        </div>
                    </div>
                    <img className="vertical-line" src={getThemeImageSource(ImageConstants.ASSESSMENT_END_SCREEN_VERTICAL_LINE, this.categoryThemeId)}></img>
                    <div className="col-5 text-center">
                        <div className="revise-concept pt-3 d-flex justify-content-center">Revise concepts for better scoring</div>
                        <img className="workout-male-image" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_MALE_IMAGE, this.categoryThemeId)} />
                        <div className="d-flex justify-content-center button-wrapper ml-3">
                            <div className="text-center view-answers-button cursor-pointer" style={{ backgroundColor: this.theme['themeColor'] }}
                                onClick={this.showInsightsModal} >REVISE NOW</div>
                        </div>
                    </div>
                </div>
                {/* <img className="assessment-end-screen-footer" src={getThemeImageSource(ImageConstants.ASSESSMENT_END_SCREEN_FOOTER, this.categoryThemeId)} /> */}
                {this.state.showChaptersPopup && <ChapterListPopup modal={true} delegate={this} close={this.hideChaptersPopup} chapters={this.meta.selectedChapters ? this.meta.selectedChapters : []} categoryThemeId={this.meta.categoryThemeId} />}
                {
                    this.state.showInsights && <CwConfirmationModal showInsights={true} hideInsightsModal={() => this.hideInsightsModal()} />
                }
            </div>
        )
    }
}
